import { AxiosResponse } from 'axios';

import { IBestSellingProductItem, ISalesByProductItem } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchBestSellingProductsAPI = (): Promise<AxiosResponse<IBestSellingProductItem[]>> =>
  axios.get(`${VITE_API_URL}/reports/sales/invoices/bestSellingProducts`);

export const fetchSalesReportByProductAPI = (
  dateFrom: string,
  dateTo: string,
): Promise<AxiosResponse<ISalesByProductItem[]>> =>
  axios.get(
    `${VITE_API_URL}/reports/sales/invoices/byProduct?dateFrom=${dateFrom}&dateTo=${dateTo}`,
  );
