import React from 'react';

import {
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerFooter,
  DrawerOverlay,
  Flex,
  ModalCloseButton,
  Text,
} from '@chakra-ui/react';

import { IconCancel } from '@laudus/icons';

import { ModalButton } from './ModalButton';
import { ModalSize } from './types';

interface IModalDrawerProps {
  acceptIcon?: React.ReactElement;
  acceptButtonVariant?: string;
  cancelButtonVariant?: string;
  acceptText?: string;
  backgroundColor?: string;
  cancelIcon?: React.ReactElement;
  cancelText?: string;
  disabled?: boolean;
  children?: JSX.Element | JSX.Element[] | string;
  closeIcon?: boolean;
  isOpen: boolean;
  fontSize?: string;
  title?: string;
  nextIcon?: React.ReactElement;
  widthAlertButton?: string;
  heightAlertButton?: string;
  drawerBorderTopRadius?: number | string | { base?: string; md?: string; lg?: string };
  drawerBorderBottomRadius?: number | string | { base?: string; md?: string; lg?: string };
  drawerPlacement?: 'top' | 'right' | 'bottom' | 'left';
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  size?: ModalSize;
  onAccept?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export const ModalDrawer = ({
  acceptIcon,
  acceptButtonVariant = 'primary',
  cancelButtonVariant = 'outline',
  acceptText,
  backgroundColor,
  cancelIcon,
  cancelText,
  children,
  closeIcon = true,
  disabled,
  isOpen,
  title,
  nextIcon,
  drawerBorderBottomRadius = { base: 'none', md: '0.4rem' },
  drawerBorderTopRadius = { base: '1.7rem', md: '0.4rem' },
  drawerPlacement = 'bottom',
  height: _height,
  minHeight,
  maxHeight,
  size,
  onAccept,
  onCancel,
  onClose,
}: IModalDrawerProps) => {
  let height = _height;
  if (size === 'sm') {
    height = '30%';
  } else if (size === 'md') {
    height = '50%';
  } else if (size === 'lg') {
    height = '70%';
  }

  return (
    <Drawer
      closeOnOverlayClick={false}
      isOpen={isOpen}
      onClose={() => onClose?.()}
      placement={drawerPlacement}
    >
      <DrawerOverlay />
      <DrawerContent
        maxWidth="100%"
        minHeight={minHeight ?? '40%'}
        maxHeight={maxHeight ?? '100%'}
        height={height ?? 'unset'}
        backgroundColor={backgroundColor ?? 'modalBackground'}
        borderBottomRadius={drawerBorderBottomRadius}
        borderTopRadius={drawerBorderTopRadius}
        filter="grayscale(26%)"
      >
        {closeIcon && <ModalCloseButton tabIndex={-1} color="modalClose" />}
        <Flex alignItems="center" direction="column" textAlign="center" margin="1.6rem 2.4rem">
          {title && (
            <Text fontWeight="600" fontSize="2.4rem" paddingBottom="1rem">
              {title}
            </Text>
          )}
        </Flex>
        <DrawerBody margin="0 2.4rem" fontSize="1.4rem">
          {children}
        </DrawerBody>
        {(onCancel && cancelText) || (onAccept && acceptText) ? (
          <DrawerFooter margin="0.8rem">
            <Flex
              display="flex"
              justifyContent={!onCancel ? 'center' : 'space-between'}
              gap="4rem"
              width="100%"
            >
              {onCancel ? (
                <ModalButton
                  text={cancelText ?? cancelButtonVariant}
                  leftIcon={cancelIcon ?? <IconCancel />}
                  backgroundColor="secondaryButtonBackground"
                  variant={cancelButtonVariant}
                  onClick={onCancel}
                />
              ) : undefined}

              {onAccept ? (
                <ModalButton
                  leftIcon={acceptIcon ? acceptIcon : undefined}
                  rightIcon={nextIcon ?? undefined}
                  text={acceptText}
                  variant={acceptButtonVariant ?? nextIcon}
                  disabled={disabled}
                  onClick={onAccept}
                />
              ) : undefined}
            </Flex>
          </DrawerFooter>
        ) : undefined}
      </DrawerContent>
    </Drawer>
  );
};
