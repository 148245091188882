import { createReducer } from '@reduxjs/toolkit';

import { setFeatures } from './actions';
import { featureFlags } from './types';

export const initialFeaturesState = featureFlags;

export const featuresReducer = createReducer(initialFeaturesState, (builder) => {
  builder
    .addCase(setFeatures, (state, action) => {
      return { ...state, ...action.payload };
    })
    .addDefaultCase((state) => state);
});
