import { NodeModel } from '@minoru/react-dnd-treeview';
import { createSelector } from '@reduxjs/toolkit';

import { IAccount } from '@laudus/types';

import { AppState, IAccountsState } from '../..';
import { getSettingsByName } from '../settings';

export const getAccountsSlice = (state: AppState): IAccountsState => state.accounts;

export const getAccountsList = createSelector([getAccountsSlice], (state) => state.list);

export const getAccount = createSelector([getAccountsSlice], (state) => state.current);

export const getAccountDraft = createSelector([getAccountsSlice], (state) => state.draft);

export const getAccountFormState = createSelector([getAccountsSlice], (state) => state.formState);

export const getChargeableAccountList = createSelector(
  [getAccountsList, getSettingsByName<number | undefined>('chargeableAccountLevel')],
  (list, maxLevelAccount) => {
    if (!maxLevelAccount) {
      return list;
    }

    return list.filter((account) => account.accountNumber?.length === maxLevelAccount);
  },
);

export function getFilteredAccountsList({
  showOnlyFinalAccounts,
}: {
  showOnlyFinalAccounts: boolean;
}) {
  return createSelector([getAccountsList, getChargeableAccountList], (list, chargeableList) => {
    if (showOnlyFinalAccounts) {
      return chargeableList;
    }

    return list;
  });
}

export const getAccountsTreeData = createSelector(
  [getAccountsList],
  (accountsList): NodeModel[] => {
    let index = 0;
    let currentLevel = 0;
    let currentParent: IAccount | undefined;
    const childrenByLevel: IAccount[][] = [];
    const treeNodes: NodeModel[] = [];

    while (index < accountsList.length) {
      const currentAccount = accountsList[index];
      // Initialize the current level with the current account
      childrenByLevel[currentLevel] = [currentAccount];
      index++;

      // If there are no more accounts, exit the loop
      if (index >= accountsList.length) {
        break;
      }

      // Create the node for the current account.
      treeNodes.push({
        id: currentAccount.accountId ?? 0,
        text: `${currentAccount.accountNumber} - ${currentAccount.name}`,
        parent: currentParent?.accountId ?? '',
        droppable: true,
      });

      // Increase the level to process its children
      currentLevel++;

      // Process the child accounts (their accountNumber starts with the current account's accountNumber)
      let nextAccount = accountsList[index];
      while (
        index < accountsList.length &&
        nextAccount &&
        nextAccount.accountNumber?.startsWith('' + currentAccount.accountNumber) &&
        (!childrenByLevel[currentLevel] ||
          childrenByLevel[currentLevel].every(
            (child) => !nextAccount.accountNumber?.startsWith('' + child.accountNumber),
          ))
      ) {
        if (!childrenByLevel[currentLevel]) {
          childrenByLevel[currentLevel] = [];
        }
        childrenByLevel[currentLevel].push(nextAccount);

        treeNodes.push({
          id: nextAccount.accountId ?? 0,
          text: `${nextAccount.accountNumber} - ${nextAccount.name}`,
          parent: currentAccount.accountId ?? '',
          droppable: true,
        });
        index++;
        nextAccount = accountsList[index];
      }

      // Increase the level for the next iteration
      currentLevel++;

      // Find the new parent by comparing previous levels
      currentParent = undefined;
      for (let lvl = currentLevel - 1; lvl >= 0; lvl--) {
        if (index < accountsList.length) {
          const currentIndex = index;
          const candidateParents = childrenByLevel[lvl]?.filter((child) =>
            accountsList[currentIndex].accountNumber?.startsWith('' + child.accountNumber),
          );
          if (candidateParents && candidateParents.length > 0) {
            currentParent = candidateParents[0];
            break;
          }
        }
      }
    }
    return treeNodes;
  },
);
