import { createAsyncThunk } from '@reduxjs/toolkit';

import {
  clearAlert as globalClearAlert,
  clearAllAlerts as globalClearAllAlerts,
  globalStore,
  IShowErrorParams,
  showAlert as globalShowAlert,
  showErrorAlert as globalShowErrorAlert,
  showToastAlert as globalShowToastAlert,
} from '@laudus/redux-global';
import { IAlert } from '@laudus/types';

export const clearAlert = createAsyncThunk<void, IAlert>('ALERTS/CLEAR_ALERT', async (payload) => {
  globalStore.dispatch(globalClearAlert(payload));
});

export const clearAllAlerts = createAsyncThunk<void, void>('ALERTS/CLEAR_ALL_ALERTS', async () => {
  globalStore.dispatch(globalClearAllAlerts());
});

export const showAlert = createAsyncThunk<void, IAlert>('ALERTS/SET_ALERT', async (payload) => {
  globalStore.dispatch(globalShowAlert(payload));
});

export const showToastAlert = createAsyncThunk<void, IAlert>(
  'ALERTS/SET_TOAST_ALERT',
  async (payload) => {
    globalStore.dispatch(globalShowToastAlert(payload));
  },
);

export const showErrorAlert = createAsyncThunk<void, IShowErrorParams>(
  'ALERTS/SHOW_ERROR',
  async (payload) => {
    globalStore.dispatch(globalShowErrorAlert(payload));
  },
);
