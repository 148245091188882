import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { createLocalStorageManager } from '@chakra-ui/react';
import { PersistGate } from 'redux-persist/integration/react';

import { FeaturesProvider, featuresReducer } from '@laudus/features';
import { IntlProvider } from '@laudus/intl';
import { getGlobalStore, globalPersistor, globalStoreContext } from '@laudus/redux-global';
import { setAxios } from '@laudus/services';
import { LaudusLoader, ThemeProvider } from '@laudus/shared-ui';

import { AlertProvider } from './shared/components/Alerts/AlertProvider';
import { AppRouter } from './AppRouter';
import { createTheme } from './theme';

import './theme/css/index.css';
import './theme/scss/index.scss';

const colorModeKey = 'erp-chakra-ui-color-mode';
const colorModeManager = createLocalStorageManager(colorModeKey);

const store = getGlobalStore('erp-global', [['features', featuresReducer]]);
setAxios(store);

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <IntlProvider>
      <Provider context={globalStoreContext} store={store}>
        <PersistGate loading={<LaudusLoader />} persistor={globalPersistor}>
          <BrowserRouter>
            <FeaturesProvider>
              <ThemeProvider colorModeManager={colorModeManager} createTheme={createTheme}>
                <AppRouter />
                <AlertProvider />
              </ThemeProvider>
            </FeaturesProvider>
          </BrowserRouter>
        </PersistGate>
      </Provider>
    </IntlProvider>
  </React.StrictMode>,
);
