import { createContext, ReactNode, useMemo, useState } from 'react';

import {
  ChakraProvider,
  ColorMode,
  ColorModeProvider,
  ColorModeProviderProps,
  ColorModeScript,
} from '@chakra-ui/react';

import { getThemeVariables } from './getThemeVariables';
import { ThemeColorMode } from './ThemeColorMode';

export interface ThemeContextValue {
  colors: Record<string, string>;
}

export const initialThemeContextValue = {
  colors: {},
};

export const ThemeContext = createContext<ThemeContextValue>(initialThemeContextValue);

export interface ThemeProviderProps {
  children: ReactNode;
  colorModeManager: ColorModeProviderProps['colorModeManager'];
  createTheme: (colors: Record<string, string>) => Record<string, any>;
}

export const ThemeProvider = ({ children, colorModeManager, createTheme }: ThemeProviderProps) => {
  const [colorMode, setColorMode] = useState<ColorMode>(colorModeManager?.get() ?? 'light');

  const handleColorModeChange = (colorMode: ColorMode) => {
    setColorMode(colorMode);
  };

  const [colors, theme] = useMemo(() => {
    const colors = getThemeVariables({ colorMode, variablePrefix: '--laudus-color-' });
    const theme = createTheme(colors);
    return [colors, theme];
  }, [colorMode, createTheme]);

  return (
    <ThemeContext.Provider value={{ colors }}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ChakraProvider theme={theme} colorModeManager={colorModeManager}>
        <ColorModeProvider colorModeManager={colorModeManager} options={theme.config}>
          <ThemeColorMode onChange={handleColorModeChange} />
          {children}
        </ColorModeProvider>
      </ChakraProvider>
    </ThemeContext.Provider>
  );
};
