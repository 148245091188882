import { createReducer } from '@reduxjs/toolkit';

import { ICustomField } from '@laudus/types';

import { clearCustomFields, setCustomFieldsList } from './actions';

export interface ICustomFieldsState {
  list: ICustomField[];
}

export const initialCustomFieldsState: ICustomFieldsState = {
  list: [],
};

export const customFieldsReducer = createReducer(initialCustomFieldsState, (builder) => {
  builder
    .addCase(setCustomFieldsList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(clearCustomFields, (state) => {
      return { ...state, list: [] };
    })
    .addDefaultCase((state) => state);
});
