import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import {
  IFetchWithEtagParams,
  IOpportunity,
  IOpportunityActivity,
  ISalesQuote,
  IStartEditingParams,
} from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const addHomeOpportunityTab = () =>
  addTab({
    tab: {
      id: 'opportunities',
      title: intl.formatMessage({ id: 'opportunities.tabTitle' }),
      path: 'pages/Opportunities/Opportunities',
      isRemovable: true,
    },
  });

export const startEditingOpportunity = createAsyncThunk<
  void,
  IStartEditingParams | undefined,
  AppThunkConfig
>('OPPORTUNITIES/START_EDITING', async (params, ThunkAPI) => {
  const { isNew } = params ?? {};
  const { dispatch } = ThunkAPI;
  dispatch(setOpportunityEditing(true));
  dispatch(
    addTab({
      tab: {
        id: 'opportunities',
        title: intl.formatMessage({ id: 'opportunities.tabTitle' }),
        path: isNew ? 'pages/Opportunities/OpportunityNew' : 'pages/Opportunities/OpportunityEdit',
        isRemovable: true,
      },
    }),
  );
});

export const startEditingOpportunityNew = createAsyncThunk<void, void, AppThunkConfig>(
  'OPPORTUNITIES/START_EDITING_NEW',
  async (_, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(startEditingOpportunity({ isNew: true }));
  },
);

export const stopEditingOpportunity = createAsyncThunk<void, number | undefined, AppThunkConfig>(
  'OPPORTUNITIES/STOP_EDITING',
  async (id, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(setOpportunityEditing(false));
    dispatch(
      addTab({
        tab: {
          id: 'opportunities',
          title: intl.formatMessage({ id: 'opportunities.tabTitle' }),
          path: 'pages/Opportunities/OpportunityView',
          props: { id },
          isRemovable: true,
        },
      }),
    );
  },
);

export const clearOpportunity = createAction('OPPORTUNITIES/CLEAR');

export const clearOpportunityDraft = createAction('OPPORTUNITIES/CLEAR_DRAFT');

export const setOpportunity = createAction<IOpportunity>('OPPORTUNITIES/SET_OPPORTUNITY');

export const setOpportunityDraft = createAction<IOpportunity>(
  'OPPORTUNITIES/SET_OPPORTUNITY_DRAFT',
);

export const setOpportunityDraftValues = createAction<Partial<IOpportunity>>(
  'OPPORTUNITIES/SET_OPPORTUNITY_DRAFT_VALUE',
);

export const setOpportunitiesList = createAction<IOpportunity[]>('OPPORTUNITIES/SET_LIST');

export const updateOpportunitiesList = createAction<IOpportunity>('OPPORTUNITIES/UPDATE_LIST');

export const removeOpportunityFromList = createAction<number>('OPPORTUNITIES/REMOVE_FROM_LIST');

export const duplicateOpportunity = createAction<IOpportunity>('OPPORTUNITIES/DUPLICATE');

export const setOpportunityActivityDraft = createAction<IOpportunityActivity>(
  'OPPORTUNITIES/SET_ACTIVITY_DRAFT',
);

export const clearOpportunityActivityDraft = createAction(
  'OPPORTUNITIES/CLEAR_OPPORTUNITY_ACTIVITY_DRAFT',
);

export const setOpportunityActivityDraftValues = createAction<Partial<IOpportunityActivity>>(
  'OPPORTUNITIES/SET_ACTIVITY_DRAFT_VALUE',
);

export const setSelectedQuote = createAction<ISalesQuote>('OPPORTUNITIES/SET_SELECTED_QUOTE');

export const setOpportunityEditing = createAction<boolean>('OPPORTUNITIES/SET_EDITING');

export const fetchSelectedQuote = createAsyncThunk<void, number, AppThunkConfig>(
  'OPPORTUNITIES/FETCH_SELECTED_QUOTE',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('opportunities'));
    try {
      const { data } = await api.salesQuotes.fetchSalesQuoteFromAPI(id);
      dispatch(setSelectedQuote(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'opportunities',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('opportunities'));
    }
  },
);

export const fetchOpportunitiesList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'OPPORTUNITIES/FETCH_OPPORTUNITIES_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    if (!silent) {
      dispatch(startRequest('opportunities'));
    }

    try {
      const { data } = await api.opportunities.fetchOpportunitiesListAPI();
      dispatch(setOpportunitiesList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'opportunities',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('opportunities'));
      }
    }
  },
);

export const fetchOpportunity = createAsyncThunk<void, number, AppThunkConfig>(
  'OPPORTUNITIES/FETCH_OPPORTUNITY',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('opportunities'));
    try {
      const { data } = await api.opportunities.fetchOpportunityAPI(id);
      dispatch(setOpportunity(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'opportunities',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('opportunities'));
    }
  },
);
