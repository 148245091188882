import { Link as RouterLink } from 'react-router-dom';

import { Flex, Link, Text } from '@chakra-ui/react';

import { useIntl } from '@laudus/intl';
import { colors } from '@laudus/shared-ui';

import { PasswordRequestResetForm } from './Forms/PasswordRequestResetForm';

interface IPasswordResetRequestProps {
  formError: string;
}

export const PasswordResetRequest = ({ formError }: IPasswordResetRequestProps) => {
  const intl = useIntl();

  return (
    <>
      <Flex
        alignItems={{ base: 'center', md: 'center' }}
        flexDirection="column"
        justifyContent={{ base: 'center', md: 'flex-start' }}
        mb="2rem"
        position="relative"
        w="100%"
      >
        <Text
          align="center"
          color={{ base: 'white', md: 'black' }}
          _dark={{ color: colors.white }}
          fontSize="2rem"
          fontWeight="700"
          w="100%"
        >
          {intl.formatMessage({ id: 'resetPassword.title.forgotPassword' })}
        </Text>

        <Text
          align="center"
          color={colors.primary500}
          mt="0.6rem"
          textStyle={{ md: 'h6', lg: 'h5' }}
        >
          {intl.formatMessage({ id: 'resetPassword.subtitle.fillUser' })}
        </Text>
      </Flex>
      <Flex flexDirection="column" maxW="33rem" w={{ base: '90%', md: '100%' }}>
        <PasswordRequestResetForm formError={formError} />
        <Link
          color={{ base: colors.neutral500, md: colors.black }}
          fontSize={{ base: '1.5rem', md: '1.3rem', lg: '1.3rem' }}
          fontWeight="600"
          mb="1.2rem"
          mt="2rem"
          textAlign="center"
          w="100%"
          whiteSpace="nowrap"
        >
          <RouterLink to="/login">
            <Text whiteSpace="nowrap" _dark={{ color: colors.white }}>
              {`< ${intl.formatMessage({
                id: 'resetPassword.button.backToLogin',
              })}`}
            </Text>
          </RouterLink>
        </Link>
      </Flex>
    </>
  );
};
