import { IPurchaseWaybillItem } from '@laudus/types';

import { roundDecimals } from '../../format/numbers/roundDecimals';

export function transformsAndCalculatePurchaseWaybills(
  purchaseWaybillsItems: IPurchaseWaybillItem[],
  mainCurrencyDecimals: number,
): IPurchaseWaybillItem[] {
  return purchaseWaybillsItems.map((item) => {
    const { product, itemDescription, unitCost, quantity } = item;

    const productAllowsFreeDescription = Boolean(product?.allowFreeDescription);
    const itemDescriptionIsFilled = Boolean(itemDescription);

    const itemNet = roundDecimals(unitCost * quantity, mainCurrencyDecimals);

    if (!productAllowsFreeDescription || !itemDescriptionIsFilled) {
      return {
        ...item,
        itemDescription: product?.description ?? '',
        net: itemNet,
      };
    }

    return { ...item, itemDescription, net: itemNet };
  });
}
