export const printPDF = (pdfURL: string) => {
  const iframe = document.createElement('iframe');
  iframe.src = pdfURL;
  iframe.style.display = 'none';
  iframe.onload = () => {
    iframe.focus();
    iframe?.contentWindow?.print();
  };
  document.body.appendChild(iframe);
};
