import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { getMessageFromError, getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  IntRange,
  IProductListItem,
  IRecurringSalesInvoice,
  IRecurringSalesInvoiceListItem,
  IRecurringSalesInvoicesItem,
} from '@laudus/types';

import { AppState, AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { getSettingsByName } from '../settings';
import { addTab } from '../tabs';

import { getRecurringSalesInvoiceDraft } from './selectors';

export const RECURRING_SALES_INVOICES_TAB_ID = 'recurringSalesInvoices';

export const addHomeRecurringSalesInvoiceTab = () =>
  addTab({
    tab: {
      id: RECURRING_SALES_INVOICES_TAB_ID,
      title: intl.formatMessage({ id: 'recurringSalesInvoices.tabTitle' }),
      path: 'pages/RecurringSalesInvoices/RecurringSalesInvoices',
      isRemovable: true,
    },
  });

export const addViewRecurringSalesInvoiceTab = (id?: number) =>
  addTab({
    tab: {
      id: RECURRING_SALES_INVOICES_TAB_ID,
      title: intl.formatMessage({ id: 'recurringSalesInvoices.tabTitle' }),
      path: 'pages/RecurringSalesInvoices/RecurringSalesInvoiceView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewRecurringSalesInvoiceTab = () =>
  addTab({
    tab: {
      id: RECURRING_SALES_INVOICES_TAB_ID,
      title: intl.formatMessage({ id: 'recurringSalesInvoices.tabTitle' }),
      path: 'pages/RecurringSalesInvoices/RecurringSalesInvoiceNew',
      isRemovable: true,
    },
  });

export const addEditRecurringSalesInvoiceTab = () =>
  addTab({
    tab: {
      id: RECURRING_SALES_INVOICES_TAB_ID,
      title: intl.formatMessage({ id: 'recurringSalesInvoices.tabTitle' }),
      path: 'pages/RecurringSalesInvoices/RecurringSalesInvoiceEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearRecurringSalesInvoice = createAction(`RECURRING_SALES_INVOICES/CLEAR`);

export const clearRecurringSalesInvoiceDraft = createAction(`RECURRING_SALES_INVOICES/CLEAR_DRAFT`);

export const setRecurringSalesInvoice = createAction<IRecurringSalesInvoice>(
  `RECURRING_SALES_INVOICES/SET`,
);

export const setRecurringSalesInvoiceDraft = createAction<IRecurringSalesInvoice>(
  `RECURRING_SALES_INVOICES/SET_DRAFT`,
);

export const setRecurringSalesInvoiceDraftValues = createAction<Partial<IRecurringSalesInvoice>>(
  `RECURRING_SALES_INVOICES/SET_DRAFT_VALUES`,
);

export const setRecurringSalesInvoiceDraftRepetitionWeeklyPattern = createAction<IntRange<1, 7>[]>(
  `RECURRING_SALES_INVOICES/SET_DRAFT_REPETITION_WEEKLY_PATTERN`,
);

export const setRecurringSalesInvoiceDraftRepetitionMonthlyPattern = createAction<
  IntRange<1, 31>[]
>(`RECURRING_SALES_INVOICES/SET_DRAFT_REPETITION_MONTHLY_PATTERN`);

export const setRecurringSalesInvoiceDraftRepetitionYearlyPattern = createAction<{
  yearMonth: IntRange<1, 12>;
  yearDayOfMonth: IntRange<1, 31>;
}>(`RECURRING_SALES_INVOICES/SET_DRAFT_REPETITION_YEARLY_PATTERN`);

export const setRecurringSalesInvoiceDraftRepetitionToAlways = createAction(
  `RECURRING_SALES_INVOICES/SET_DRAFT_REPETITION_TO_ALWAYS`,
);

export const setRecurringSalesInvoiceDraftRepetitionToDate = createAction<string>(
  `RECURRING_SALES_INVOICES/SET_DRAFT_REPETITION_TO_DATE`,
);

export const setRecurringSalesInvoiceDraftRepetitionToTimes = createAction<number>(
  `RECURRING_SALES_INVOICES/SET_DRAFT_REPETITION_TO_TIMES`,
);

export const setRecurringSalesInvoiceList = createAction<IRecurringSalesInvoiceListItem[]>(
  `RECURRING_SALES_INVOICES/SET_LIST`,
);

export const updateRecurringSalesInvoiceList = createAction<IRecurringSalesInvoice>(
  `RECURRING_SALES_INVOICES/UPDATE_LIST`,
);

export const removeRecurringSalesInvoiceFromList = createAction<number>(
  `RECURRING_SALES_INVOICES/REMOVE_FROM_LIST`,
);

export const duplicateRecurringSalesInvoice = createAction<IRecurringSalesInvoice>(
  `RECURRING_SALES_INVOICES/DUPLICATE`,
);

// Complex actions
export const fetchRecurringSalesInvoicesList = createAsyncThunk<void, void, AppThunkConfig>(
  `RECURRING_SALES_INVOICES/FETCH_LIST`,
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('recurring-sales-invoices'));
    try {
      const { data } = await api.recurringSalesInvoices.fetchRecurringSalesInvoiceListFromAPI();
      dispatch(setRecurringSalesInvoiceList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'recurringSalesInvoices',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('recurring-sales-invoices'));
    }
  },
);

export const fetchRecurringSalesInvoice = createAsyncThunk<void, number, AppThunkConfig>(
  `RECURRING_SALES_INVOICES/FETCH`,
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('recurring-sales-invoices'));
    try {
      const { data } = await api.recurringSalesInvoices.fetchRecurringSalesInvoiceFromAPI(id);
      dispatch(setRecurringSalesInvoice(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'recurringSalesInvoices',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('recurring-sales-invoices'));
    }
  },
);

export const addNewItemToRecurringSalesInvoiceDraft = createAsyncThunk<
  void,
  IProductListItem,
  AppThunkConfig
>(`RECURRING_SALES_INVOICES/FETCH`, async (product, ThunkAPI) => {
  const { dispatch, getState, extra } = ThunkAPI;
  const { api } = extra;
  const state = getState() as AppState;

  const entity = getRecurringSalesInvoiceDraft(state);
  const maxItemsInvoices = getSettingsByName<number>('maxItemsInvoices')(state);

  if (maxItemsInvoices && entity.items.length + 1 > maxItemsInvoices) {
    dispatch(
      showAlert({
        type: 'error',
        title: intl.formatMessage({ id: 'sales.maxItems' }),
        message: `${intl.formatMessage({
          id: 'sales.maxItemsWarning1',
        })} ${maxItemsInvoices} ${intl.formatMessage({
          id: 'sales.maxItemsWarning2',
        })}`,
      }),
    );
    return;
  }

  dispatch(startRequest('recurring-sales-invoices'));
  try {
    // Made object with query params to pass into the salesPrice API
    const priceQueryParams = {
      quantity: 1,
      priceListId: undefined,
      customerId: entity.customer?.customerId ?? undefined,
      date: entity.repetition.dateFrom ?? undefined,
    };

    // Get price of the product
    const { data: productSalesPrice } = await api.products.fetchProductSalesPriceAPI(
      '' + product.productId,
      priceQueryParams,
    );

    // Create a new item
    const newRow: IRecurringSalesInvoicesItem = {
      itemId: getNegativeUniqueNumericId(),
      product: {
        description: product.description,
        productId: product.productId,
        sku: product.sku,
        unitOfMeasure: product.unitOfMeasure,
      },
      itemDescription: product.description,
      quantity: 1,
      originalUnitPrice: productSalesPrice.originalUnitPrice,
      currencyCode: productSalesPrice.currencyCode,
      discountPercentage: productSalesPrice.discount,
      costCenter: entity.costCenter ?? null,
      account: entity.account ?? null,
    };

    // Add the new item
    dispatch(setRecurringSalesInvoiceDraftValues({ items: [...entity.items, newRow] }));
  } catch (error) {
    dispatch(
      showToastAlert({
        type: 'error',
        title: '',
        message: getMessageFromError({
          error,
        }),
      }),
    );
  } finally {
    dispatch(endRequest('recurring-sales-invoices'));
  }
});
