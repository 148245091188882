import { AxiosResponse } from 'axios';

import { IGroup, IRight, IUser } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchGroupListFromAPI = (
  companyAccessToken?: string,
): Promise<AxiosResponse<IGroup[]>> =>
  axios.post(
    `${VITE_API_URL}/security/groups/list`,
    {
      fields: ['name', 'groupId', 'notes'],
      orderBy: [{ field: 'name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
        ...(companyAccessToken
          ? {
              'Authorization': `Bearer ${companyAccessToken}`,
              'X-Source': 'LaudusApp',
            }
          : {}),
      },
    },
  );

export const fetchRightListFromAPI = (): Promise<AxiosResponse<IRight[]>> =>
  axios.get(`${VITE_API_URL}/security/items`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const fetchGroupFromAPI = (groupId: string): Promise<AxiosResponse<IGroup>> =>
  axios.get(`${VITE_API_URL}/security/groups/${groupId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createGroupFromAPI = (group: IGroup): Promise<AxiosResponse<IGroup>> =>
  axios.post(`${VITE_API_URL}/security/groups`, group);

export const updateGroupFromAPI = (group: IGroup): Promise<AxiosResponse<IGroup>> =>
  axios.put(`${VITE_API_URL}/security/groups/${group.groupId}`, group);

export const deleteGroupFromAPI = (groupId: string): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/security/groups/${groupId}`);

export const fetchGroupUserListFromAPI = (groupId: string): Promise<AxiosResponse<IUser[]>> =>
  axios.post(
    `${VITE_API_URL}/security/users/list`,
    {
      fields: ['name', 'loginName'],
      filterBy: [
        {
          field: 'group.groupId',
          operator: '=',
          value: groupId,
        },
      ],
      orderBy: [
        {
          field: 'name',
          direction: 'ASC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
