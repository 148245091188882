import { createSelector } from '@reduxjs/toolkit';

import { IPosAdjustment } from '@laudus/types';

import { AppState } from '../../store';

import { IPosAdjustmentState } from './reducer';

export const getPosAdjustmentsSlice = (state: AppState): IPosAdjustmentState => state.posAdjustment;

export const getPosAdjustmentsList = createSelector(
  [getPosAdjustmentsSlice],
  (state) => state.list,
);

export const getPosAdjustment = createSelector([getPosAdjustmentsSlice], (state) => state.current);

export const getPosAdjustmentDraft = createSelector(
  [getPosAdjustmentsSlice],
  (state) => state.draft,
);

export const getPosAdjustmentsFormState = createSelector(
  [getPosAdjustmentsSlice],
  (state) => state.formState,
);

export const getPosAdjustmentsGroupedByDate = createSelector([getPosAdjustmentsList], (list) =>
  [...list]
    .sort((adjA, adjB) => {
      if (!adjA.date || !adjB.date) {
        return 0;
      }
      return adjA.date > adjB.date ? -1 : 1;
    })
    .reduce(
      (acc, currentValue) => {
        const date = new Date(currentValue.date ?? '');
        const dateWithoutTime = new Date(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
        ).toString();

        acc[dateWithoutTime] = acc[dateWithoutTime] ?? [];
        acc[dateWithoutTime].push(currentValue);
        return acc;
      },
      {} as Record<string, IPosAdjustment[]>,
    ),
);
