import { createReducer } from '@reduxjs/toolkit';

import { IStockControlFilters, IStockControlItems } from '@laudus/types';

import {
  clearStockControl,
  clearStockControlFilter,
  setStockControl,
  setStockControlFilter,
} from './actions';

export interface IStockControlState {
  list: IStockControlItems[];
  filters: IStockControlFilters;
}

export const STOCKCONTROL_EMPTY: IStockControlItems[] = [];

export const STOCKCONTROL_FILTERS_EMPTY: IStockControlFilters = {
  product: {
    productId: 0,
    name: '',
  },
  warehouseId: '',
  dateTo: '',
  valorizationMethod: '',
  calculateTransformations: false,
};

export const initialStockControlState: IStockControlState = {
  list: STOCKCONTROL_EMPTY,
  filters: STOCKCONTROL_FILTERS_EMPTY,
};

export const STOCKCONTROL_TAB_ID = 'stockControl';

export const stockControlReducer = createReducer(initialStockControlState, (builder) => {
  builder
    .addCase(clearStockControl, (state) => {
      return { ...state, list: STOCKCONTROL_EMPTY };
    })
    .addCase(setStockControl, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(clearStockControlFilter, (state) => {
      return { ...state, filters: STOCKCONTROL_FILTERS_EMPTY };
    })
    .addCase(setStockControlFilter, (state, action) => {
      return { ...state, filters: { ...state.filters, ...action.payload } };
    })
    .addDefaultCase((state) => state);
});
