import { AxiosResponse } from 'axios';

import { IReceipts } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchReceiptListAPI = (): Promise<AxiosResponse<IReceipts[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/receipts/list`,
    {
      fields: [
        'receiptId',
        'issuedDate',
        'dueDate',
        'salesInvoices.docNumber',
        'receiptType.description',
        'salesInvoices.amount',
        'bankOfDeposit.name',
        'salesInvoices.customer.name',
      ],
      orderBy: [
        {
          field: 'issuedDate',
          direction: 'DESC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchReceiptAPI = (receiptId: number): Promise<AxiosResponse<IReceipts>> =>
  axios.get(`${VITE_API_URL}/sales/receipts/${receiptId}`);

export const createReceiptAPI = (receipts: IReceipts): Promise<AxiosResponse<IReceipts>> =>
  axios.post(`${VITE_API_URL}/sales/receipts`, receipts);

export const updateReceiptAPI = (receipts: IReceipts): Promise<AxiosResponse<IReceipts>> =>
  axios.put(`${VITE_API_URL}/sales/receipts/${receipts.receiptId}`, receipts);

export const deleteReceiptAPI = (receiptId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/receipts/${receiptId}`);
