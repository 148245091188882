import { createReducer } from '@reduxjs/toolkit';

import { IUser } from '@laudus/types';

import {
  clearUser,
  clearUserDraft,
  duplicateUser,
  removeUserFromList,
  setUser,
  setUserDraft,
  setUserDraftValues,
  setUsersList,
  updateUsersList,
} from './actions';

export const USERS_TAB_ID = 'users';

export interface IUsersState {
  current: IUser;
  draft: IUser;
  list: IUser[];
}

export const USERS_EMPTY: IUser = {
  userId: undefined,
  name: '',
  group: {
    groupId: '',
    name: '',
  },
  loginName: '',
  passwordMinimumLength: 0,
  passwordChangeEveryDays: 0,
  locked: false,
  attempts: 0,
  discontinued: false,
  notes: '',
  VATId: '',
  allowElectronicFactoring: false,
  DTEEmail: '',
  email: '',
  smtp: null,
  restrictToCostCenter: null,
  restrictToSalesman: null,
  restrictToBranch: null,
  glUserId: 0,
};

export const initialUsersState: IUsersState = {
  current: USERS_EMPTY,
  draft: USERS_EMPTY,
  list: [],
};

export const usersReducer = createReducer(initialUsersState, (builder) => {
  builder
    .addCase(clearUser, (state) => {
      return { ...state, current: USERS_EMPTY };
    })
    .addCase(clearUserDraft, (state) => {
      return { ...state, draft: USERS_EMPTY };
    })
    .addCase(setUser, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setUserDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setUserDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setUsersList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateUsersList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.userId !== action.payload.userId), action.payload],
      };
    })
    .addCase(removeUserFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.userId !== action.payload),
      };
    })
    .addCase(duplicateUser, (state, action) => {
      const { userId: _, ...duplicatedUser } = action.payload;
      return { ...state, draft: duplicatedUser };
    })
    .addDefaultCase((state) => state);
});
