import { Flex, FormLabel as CharkraFormLabel, Text } from '@chakra-ui/react';

import { colors, InfoTooltip, useWhiteLabel } from '@laudus/shared-ui';

export type FormLabelVariant =
  | 'small'
  | 'medium'
  | 'balanceSheetLeftMenuLabel'
  | 'hidden'
  | 'dark-background';

interface IFormLabelProps {
  elipsis?: boolean;
  label: string;
  variant?: FormLabelVariant | '';
  tooltipText?: string | JSX.Element;
  whiteSpace?: boolean;
  color?: string;
  htmlFor?: string;
}

export const FormLabel = ({
  elipsis = true,
  label,
  tooltipText,
  variant = 'small',
  whiteSpace = true,
  color,
  htmlFor,
}: IFormLabelProps) => {
  const { whiteLabel } = useWhiteLabel();
  return (
    <CharkraFormLabel {...{ variant }} htmlFor={htmlFor}>
      <Flex alignItems="center">
        <Text
          casing="none"
          className={elipsis ? 'elipsis-label' : ''}
          variant={variant}
          whiteSpace={whiteSpace ? 'pre-wrap' : 'nowrap'}
          width="fit-content"
          color={color || whiteLabel ? colors.white : colors.black}
          _dark={{ color: colors.secondary100 }}
        >
          {label}
        </Text>
        {tooltipText && <InfoTooltip {...{ tooltipText }} />}
      </Flex>
    </CharkraFormLabel>
  );
};
