import { AxiosResponse } from 'axios';

import { intl } from '@laudus/intl';
import { dateToLocalISOString, getMessageFromError } from '@laudus/shared-utils';
import {
  IDTEDoc,
  ISalesInvoice,
  ISalesInvoiceList,
  ISalesInvoicesFilters,
  SendDTEEmail,
} from '@laudus/types';

import { client as axios } from '../utils/axios';

import { postDTESendEmailAPI, postDTEToSIIAPI } from './dte';

const { VITE_API_URL } = import.meta.env;
const defaultFilters: ISalesInvoicesFilters = {
  options: { limit: 200 },
  orderBy: [
    {
      field: 'issuedDate',
      direction: 'DESC',
    },
  ],
  fields: [
    'salesInvoiceId',
    'customer.name',
    'customer.customerId',
    'docType.name',
    'docNumber',
    'issuedDate',
    'totals.total',
    'posId',
  ],
};

export const fetchSalesInvoiceListFromAPI = (
  filters: Partial<ISalesInvoicesFilters> = defaultFilters,
): Promise<AxiosResponse<ISalesInvoiceList>> =>
  axios.post(
    `${VITE_API_URL}/sales/invoices/list`,
    { ...defaultFilters, ...filters },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const createSalesInvoiceFromAPI = (
  invoice: ISalesInvoice,
): Promise<AxiosResponse<ISalesInvoice>> => axios.post(`${VITE_API_URL}/sales/invoices`, invoice);

export const fetchSalesInvoicePDFFromAPI = async (
  salesInvoiceId: string,
  numberOfCopies: number,
  // download = false,
): Promise<AxiosResponse> =>
  axios.get(
    `${VITE_API_URL}/sales/invoices/${salesInvoiceId}/pdf?contentDisposition=attachment&numberOfCopies=${numberOfCopies}`,
    // TODO: check if necesary
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
      },
    },
  );

export const fetchSalesInvoiceFromAPI = (
  salesInvoiceId: string,
): Promise<AxiosResponse<ISalesInvoice>> =>
  axios.get(`${VITE_API_URL}/sales/invoices/${salesInvoiceId}`);

export async function sendSalesInvoiceToSII(salesInvoice: ISalesInvoice): Promise<ISalesInvoice> {
  const { docNumber, docType } = salesInvoice;

  if (!docNumber || !docType) {
    console.error(
      `Invoice doesn't have the necessary information to be sent to SII: ${salesInvoice}`,
    );

    throw new Error(intl.formatMessage({ id: 'invoices.sendToSII.fail' }));
  }

  const { docTypeId } = docType;

  if (!docTypeId) {
    console.error(`Invoice need to have a docTypeId to be sent to SII: ${docTypeId}`);

    throw new Error(intl.formatMessage({ id: 'invoices.sendToSII.fail' }));
  }

  const documentToSend: IDTEDoc = {
    docNumber: docNumber,
    docTypeId: docTypeId,
  };
  const requestSendSII = { docs: [documentToSend] };

  const requestSendEmail: SendDTEEmail = {
    ...documentToSend,
    sendPDF: true,
  };

  // DTE/sendToSII has to be finished before calling to DTE/sendEmail
  // This is because we have to have the trackId in the database before we can send the PDF to the customer.
  // If we do not have the trackId, the electronic barCode is not going to get printed in the PDF
  const responseFromSII = await postDTEToSIIAPI(requestSendSII);

  let sentToCustomerAt: string | undefined = undefined;
  try {
    await postDTESendEmailAPI(requestSendEmail);
    sentToCustomerAt = dateToLocalISOString(new Date());
  } catch (error) {
    console.error(
      `${intl.formatMessage({
        id: 'invoices.sendEmail.fail',
      })}: ${getMessageFromError({
        error,
      })}`,
    );
  }

  const { data } = responseFromSII;
  const { trackId } = data;

  const updatedInvoice = {
    ...salesInvoice,
    DTE: {
      trackId: trackId ?? null,
      documentStatus: salesInvoice.DTE?.documentStatus ?? null,
      uploadStatus: salesInvoice.DTE?.uploadStatus ?? null,
      sentToCustomerAt: sentToCustomerAt ?? undefined,
    },
  };

  return updatedInvoice;
}
