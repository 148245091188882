import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { addDaysToDate, dateToLocalISOString } from '@laudus/shared-utils';
import {
  IAccountType,
  IBankReconciliation,
  IBankReconciliationFilter,
  IBankStatementsReconciliationItem,
  IJournalReconciliationItem,
  IncludeOtherBankStatementsTypeEnum,
  IPurchasePayment,
  IPurchasePaymentReconciliationCandidate,
  IWithdrawalReconciliation,
} from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { JOURNALENTRY_EMPTY } from '../journalEntries';
import { PURCHASE_PAYMENT_EMPTY } from '../purchasePayments/reducer';
import { addTab } from '../tabs';

import { getBankReconciliationFilter, getGenericSuppliersAccount } from './selectors';

// Bank reconciliation tab id.
export const BANK_RECONCILIATION_TAB_ID = 'bankReconciliation';

export const addBankReconciliationTab = () =>
  addTab({
    tab: {
      id: BANK_RECONCILIATION_TAB_ID,
      title: intl.formatMessage({ id: 'bankReconciliation.tabTitle' }),
      path: 'pages/BankReconciliation/BankReconciliation',
      isRemovable: true,
    },
  });

// Bank reconciliation action creators
export const clearBankReconciliation = createAction('BANK_RECONCILIATION/CLEAR');

export const setBankReconciliationFilterValues = createAction<Partial<IBankReconciliationFilter>>(
  'BANK_RECONCILIATION/SET_CURRENT_BANK_STATEMENT',
);

export const setBankReconciliationBankStatementNotInJournal = createAction<
  IBankReconciliation<IBankStatementsReconciliationItem>
>('BANK_RECONCILIATION/SET_BANK_STATEMENT_NOT_IN_JOURNAL');

export const setBankReconciliationJournalNotInBankStatement = createAction<
  IBankReconciliation<IJournalReconciliationItem>
>('BANK_RECONCILIATION/SET_JOURNAL_NOT_IN_BANK_STATEMENT');

export const clearBankReconciliationWithdrawal = createAction(
  'BANK_RECONCILIATION/CLEAR_WITHDRAWAL)',
);

export const setBankReconciliationWithdrawalValues = createAction<
  Partial<IWithdrawalReconciliation>
>('BANK_RECONCILIATION/WITHDRAWAL_VALUES');

export const setGenericSuppliersAccount = createAction<IAccountType>(
  'BANK_RECONCILIATION/SET_GENERIC_SUPPLIERS_ACCOUNT',
);

export interface FetchBankReconciliationInfoParams {
  bankStatementId: number;
  includeOtherBankStatements: IncludeOtherBankStatementsTypeEnum;
}

export const fetchBankReconciliationInfo = createAsyncThunk<
  void,
  FetchBankReconciliationInfoParams,
  AppThunkConfig
>(
  'BANK_RECONCILIATION/FETCH_BANK_RECONCILIATION_INFO',
  async ({ bankStatementId, includeOtherBankStatements }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('bank-reconciliation'));
    try {
      const [{ data: bankStatementNotInJournal }, { data: journalNotInBankStatement }] =
        await Promise.all([
          api.bankReconciliation.fetchBankStatementNotInJournalFromAPI(
            bankStatementId,
            includeOtherBankStatements,
          ),
          api.bankReconciliation.fetchBankStatementNotInJournalFromAPI(
            bankStatementId,
            includeOtherBankStatements,
          ),
        ]);

      dispatch(setBankReconciliationBankStatementNotInJournal(bankStatementNotInJournal));
      dispatch(setBankReconciliationBankStatementNotInJournal(journalNotInBankStatement));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'bankReconciliation',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('bank-reconciliation'));
    }
  },
);

export const startWithdrawalBankReconciliation = createAsyncThunk<
  void,
  IBankStatementsReconciliationItem,
  AppThunkConfig
>('BANK_RECONCILIATION/RECONCILIATE_WITHDRAWAL', async (bankStatementLine, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;

  const state = getState();
  const { bankStatement } = getBankReconciliationFilter(state);

  dispatch(
    setBankReconciliationWithdrawalValues({
      withdrawal: bankStatementLine,
      candidatePurchaseInvoices: [],
      candidatePayments: [],
    }),
  );
  dispatch(fetchBankReconciliationWithdrawalCandidateInvoices(bankStatementLine.withdrawal));
  const startDate = dateToLocalISOString(
    addDaysToDate(new Date(bankStatement?.dateFrom || bankStatementLine.date), -90),
  );
  const endDate = dateToLocalISOString(
    addDaysToDate(new Date(bankStatement?.dateTo || bankStatementLine.date), 90),
  );
  dispatch(
    fetchBankReconciliationWithdrawalCandidatePayments({
      total: bankStatementLine.withdrawal,
      document: bankStatementLine.document,
      startDate,
      endDate,
    }),
  );
});

export const endWithdrawalBankReconciliation = createAsyncThunk<void, void, AppThunkConfig>(
  'BANK_RECONCILIATION/RECONCILIATE_WITHDRAWAL_END',
  async (_, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;

    const state = getState();
    const { bankStatement, includeOtherBankStatements } = getBankReconciliationFilter(state);

    dispatch(clearBankReconciliationWithdrawal());
    if (bankStatement) {
      dispatch(
        fetchBankReconciliationInfo({
          bankStatementId: bankStatement.bankStatementId,
          includeOtherBankStatements,
        }),
      );
    }
  },
);

export const fetchBankReconciliationWithdrawalCandidateInvoices = createAsyncThunk<
  void,
  number,
  AppThunkConfig
>('BANK_RECONCILIATION/FETCH_WITHDRAWAL_CANDIDATE_INVOICES', async (total, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('bank-reconciliation'));
  try {
    const { data } =
      await api.purchaseInvoices.fetchBankReconciliationWithdrawalCandidateInvoicesFromAPI(total);
    dispatch(
      setBankReconciliationWithdrawalValues({
        candidatePurchaseInvoices: data,
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'bankReconciliationCandidateInvoices',
        action: 'read',
      }),
    );
  } finally {
    dispatch(endRequest('bank-reconciliation'));
  }
});

interface IWithdrawalReconciliationCandidatePaymentsParams {
  total: number;
  document: string;
  startDate: string;
  endDate: string;
}
export const fetchBankReconciliationWithdrawalCandidatePayments = createAsyncThunk<
  void,
  IWithdrawalReconciliationCandidatePaymentsParams,
  AppThunkConfig
>(
  'BANK_RECONCILIATION/FETCH_WITHDRAWAL_CANDIDATE_PAYMENTS',
  async ({ total, document, startDate, endDate }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('bank-reconciliation'));
    try {
      const { data } =
        await api.purchasePayments.fetchBankReconciliationWithdrawalCandidatePaymentsFromAPI(
          startDate,
          endDate,
          document,
        );

      const totalizedData = data.reduce(
        (
          acc,
          {
            paymentId,
            issuedDate,
            document,
            paymentType_description,
            purchaseInvoices_amount,
            purchaseInvoices_supplier_legalName,
            otherDocuments_amount,
          },
        ) => {
          let paymentIndex = acc.findIndex((payment) => payment.paymentId === paymentId);
          if (paymentIndex === -1) {
            acc.push({
              paymentId,
              issuedDate,
              document,
              paymentType_description,
              purchaseInvoices_supplier_legalName,
              total: 0,
            });
            paymentIndex = acc.length - 1;
          }
          acc[paymentIndex].total =
            (acc[paymentIndex].total ?? 0) +
            (purchaseInvoices_amount !== 0
              ? purchaseInvoices_amount ?? 0
              : otherDocuments_amount ?? 0);

          return acc;
        },
        [] as IPurchasePaymentReconciliationCandidate[],
      );
      dispatch(
        setBankReconciliationWithdrawalValues({
          candidatePayments: totalizedData.filter((payment) => payment.total === total),
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'bankReconciliationCandidatePayments',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('bank-reconciliation'));
    }
  },
);

interface ICreatePaymentForWithdrawalReconciliationParams {
  withdrawalBankStatementId: number;
  withdrawalDate: string;
  withdrawalAmount: number;
  withdrawalDocument: string;
  purchaseInvoiceId: number;
  paymentTypeCode: string;
}

export const createPaymentForWithdrawalReconciliation = createAsyncThunk<
  void,
  ICreatePaymentForWithdrawalReconciliationParams,
  AppThunkConfig
>(
  `BANK_RECONCILIATION/CREATE_WITHDRAWAL_PAYMENT`,
  async (
    {
      withdrawalBankStatementId,
      withdrawalDate,
      withdrawalAmount,
      withdrawalDocument,
      purchaseInvoiceId,
      paymentTypeCode,
    },
    ThunkAPI,
  ) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('bank-reconciliation'));
    try {
      // Get bank statement details.
      const { data: bankStatement } =
        await api.bankStatements.fetchBankStatementFromAPI(withdrawalBankStatementId);

      // Get purchase invoice details
      const { data: purchaseInvoice } =
        await api.purchaseInvoices.fetchPurchaseInvoiceFromAPI(purchaseInvoiceId);

      const newPayment: IPurchasePayment = {
        ...PURCHASE_PAYMENT_EMPTY,
        paymentType: {
          code: paymentTypeCode,
          description: '',
        },
        issuedDate: withdrawalDate,
        dueDate: withdrawalDate,
        bank: {
          bankId: bankStatement.bank.bankId,
          name: '',
        },
        createAccounting: true,
        document: withdrawalDocument,
        purchaseInvoices: [
          {
            itemId: 0,
            purchaseInvoiceId,
            docType: null,
            docNumber: purchaseInvoice.docNumber,
            supplier: null,
            originalAmount: withdrawalAmount,
            currencyCode: purchaseInvoice.totals?.currencyCode || 'CLP',
            parityToMainCurrency: 1,
            amount: withdrawalAmount,
            costCenter: null,
          },
        ],
      };
      await api.purchasePayments.createPurchasePaymentAPI(newPayment);
      dispatch(endWithdrawalBankReconciliation());
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'bankReconciliationCreatePayment',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('bank-reconciliation'));
    }
  },
);

interface IUpdatePaymentForWithdrawalReconciliationParams {
  withdrawalDate: string;
  paymentId: number;
}
export const updatePaymentForWithdrawalReconciliation = createAsyncThunk<
  void,
  IUpdatePaymentForWithdrawalReconciliationParams,
  AppThunkConfig
>(
  `BANK_RECONCILIATION/UPDATE_WITHDRAWAL_PAYMENT`,
  async ({ withdrawalDate, paymentId }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('bank-reconciliation'));
    try {
      // Get purchase payment details
      const { data: purchasePayment } =
        await api.purchasePayments.fetchPurchasePaymentAPI(paymentId);

      await api.purchasePayments.updatePurchasePaymentAPI({
        ...purchasePayment,
        issuedDate: withdrawalDate,
        dueDate: withdrawalDate,
      });
      dispatch(endWithdrawalBankReconciliation());
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'bankReconciliationUpdatePayment',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('bank-reconciliation'));
    }
  },
);

interface ICreateJournalEntryForWithdrawalReconciliationParams {
  withdrawalBankStatementId: number;
  withdrawalDescription: string;
  credit: number;
  date: string;
  document: string;
  journalEntryType: string;
  supplierId: number;
}
export const createJournalEntryForWithdrawalReconciliation = createAsyncThunk<
  void,
  ICreateJournalEntryForWithdrawalReconciliationParams,
  AppThunkConfig
>(
  `BANK_RECONCILIATION/UPDATE_WITHDRAWAL_PAYMENT`,
  async (
    {
      withdrawalBankStatementId,
      withdrawalDescription,
      credit,
      date,
      document,
      journalEntryType,
      supplierId,
    },
    ThunkAPI,
  ) => {
    const { dispatch, extra, getState } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('bank-reconciliation'));
    try {
      let genericSuppliersAccount = getGenericSuppliersAccount(getState());

      // Get BankStatement and supplier details
      const [{ data: bankStatement }, { data: supplier }] = await Promise.all([
        api.bankStatements.fetchBankStatementFromAPI(withdrawalBankStatementId),
        api.suppliers.fetchSupplierAPI(supplierId),
      ]);

      if (!supplier.account?.accountId && !genericSuppliersAccount) {
        const { data } = await api.accountTypes.fetchAccountTypeFromAPI('PRV_C');
        dispatch(setGenericSuppliersAccount(data));
        genericSuppliersAccount = data;
      }

      // Get BAnk details
      const { data: bank } = await api.banks.fetchBankAPI(bankStatement.bank.bankId);

      // Create the journal entry.
      await api.journalEntries.createJournalEntryAPI({
        ...JOURNALENTRY_EMPTY,
        date,
        type: journalEntryType,
        lines: [
          {
            account: {
              accountId: supplier.account?.accountId || genericSuppliersAccount?.account?.accountId,
            },
            description: withdrawalDescription,
            debit: credit,
            credit: 0,
            currencyCode: bank.currencyCode,
            parityToMainCurrency: 1,
            originalDebit: credit,
            originalCredit: 0,
            document,
            relatedTo: {
              relatedId: supplier.supplierId,
              type: 'S',
              name: supplier.name,
              VATId: supplier.VATId,
            },
          },
          {
            account: {
              accountId: bank.account.accountId,
            },
            description: withdrawalDescription,
            debit: 0,
            credit,
            currencyCode: bank.currencyCode,
            parityToMainCurrency: 1,
            originalDebit: 0,
            originalCredit: credit,
            document,
          },
        ],
      });
      dispatch(endWithdrawalBankReconciliation());
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'bankReconciliationCreateJournalEntry',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('bank-reconciliation'));
    }
  },
);
