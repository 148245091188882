import { AxiosResponse } from 'axios';

import { IUnion } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

//TODO add 'electronicBookId' when API doesn't respond error with this field
export const fetchUnionsListFromAPI = (): Promise<AxiosResponse<IUnion[]>> =>
  axios.post(
    `${VITE_API_URL}/HR/unions/list`,
    {
      fields: ['unionId', 'name', 'VATId'],
      orderBy: [{ field: 'name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchUnionFromAPI = (unionId: number): Promise<AxiosResponse<IUnion>> =>
  axios.get(`${VITE_API_URL}/HR/unions/${unionId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createUnionFromAPI = (union: IUnion): Promise<AxiosResponse<IUnion>> =>
  axios.post(`${VITE_API_URL}/HR/unions`, union);

export const updateUnionFromAPI = (union: IUnion): Promise<AxiosResponse<IUnion>> =>
  axios.put(`${VITE_API_URL}/HR/unions/${union.unionId}`, union);

export const deleteUnionFromAPI = (unionId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/HR/unions/${unionId}`);
