import { createSelector } from '@reduxjs/toolkit';

import { AppState, ISuppliersState } from '../..';

export const getSupplierSlice = (state: AppState): ISuppliersState => state.suppliers;

export const getSuppliersList = createSelector([getSupplierSlice], (state) => state.list);

export const getSupplier = createSelector([getSupplierSlice], (state) => state.current);

export const getSupplierDraft = createSelector([getSupplierSlice], (state) => state.draft);
