import {
  ChangeEvent,
  forwardRef,
  KeyboardEvent,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { IFloatingFilterParams } from '@ag-grid-community/core';
import { IFloatingFilterReactComp } from '@ag-grid-community/react';
import { useColorMode } from '@chakra-ui/react';

import { KEY_ENTER } from '@laudus/shared-ui';

const CustomTextFloatingFilterComponent: React.ForwardRefRenderFunction<
  IFloatingFilterReactComp,
  IFloatingFilterParams
  // eslint-disable-next-line react/function-component-definition
> = (props, ref) => {
  const [filterValue, setFilterValue] = useState<string>('');
  const inputRef = useRef<HTMLInputElement>(null);
  const { colorMode } = useColorMode();

  // expose AG Grid Filter Lifecycle callbacks
  useImperativeHandle(ref, () => {
    return {
      onParentModelChanged(parentModel: any) {
        // When the filter is empty we will receive a null value here
        if (!parentModel?.filter) {
          setFilterValue('');
        } else {
          setFilterValue(parentModel.filter);
        }
      },
    };
  });

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.currentTarget.value === '') {
      // Clear the filter
      props.parentFilterInstance((instance) => {
        instance.onFloatingFilterChanged(null, null);
      });
      return;
    }

    setFilterValue(e.currentTarget.value);
    props.parentFilterInstance((instance) => {
      instance.onFloatingFilterChanged('contains', e.currentTarget.value);
    });
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    // Focus Grid with the next key
    if (e.key === KEY_ENTER) {
      if (
        props.api.getSelectedNodes().length === 0 ||
        !props.api.getSelectedNodes().find((node) => node.displayed === true)
      ) {
        e.preventDefault();
        e.stopPropagation();
        props.api.setFocusedCell(0, props.column);
      }
    }
  };

  const isDisabled = props.filterParams?.colDef?.floatingFilterComponentParams?.disabled;
  return (
    <input
      className={`ag-input-field-input ag-text-field-input ${colorMode === 'dark' ? 'ag-text-field-input-dark ' : ''}`}
      ref={inputRef}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      type="text"
      value={filterValue}
      disabled={isDisabled ?? false}
    />
  );
};

export const CustomTextFloatingFilter = forwardRef(CustomTextFloatingFilterComponent);
