import { createSelector } from '@reduxjs/toolkit';

import { DOC_TYPES_ID } from '@laudus/sales-utils';

import { AppState } from '../../store';
import { getPosOtherSalesDocumentAllowed } from '../pos';
import { getSettingsByName } from '../settings';

import { IDocTypesState } from './reducer';

export const getDocTypesSlice = (state: AppState): IDocTypesState => state.docTypes;

export const getDocTypeList = createSelector([getDocTypesSlice], (state) => state.list);

export const getDocTypeFromDocTypeId = (docTypeId: number | null | undefined) =>
  createSelector([getDocTypeList], (list) => {
    return list.find((docType) => docType.docTypeId === docTypeId);
  });

export const getIsAnElectronicDocument = (docTypeId: number) =>
  createSelector([getDocTypeFromDocTypeId(docTypeId)], (docType) => {
    return docType?.electronic ?? false;
  });

export const getSalesInvoicesDocTypesByIds = (docTypeIds: number[]) =>
  createSelector([getDocTypeList], (list) => {
    return list.filter((docType) => docTypeIds.includes(docType.docTypeId));
  });

export const getDocTypesInvoices = (docTypeIds: number[]) =>
  createSelector(
    [
      getSalesInvoicesDocTypesByIds(docTypeIds),
      getSettingsByName<boolean>('DTE_allow'),
      getPosOtherSalesDocumentAllowed,
    ],
    (docTypes, isDTEAllowed, isOtherSalesDocumentAllowed) => {
      const filteredDocTypes = docTypes.filter((docType) =>
        isDTEAllowed ? docType.electronic : !docType.electronic,
      );

      const otherDocumentExistOnFilteredDocTypes = filteredDocTypes.some(
        (docType) => docType.docTypeId === DOC_TYPES_ID.OTHER_SALES_DOCUMENT,
      );

      // Check if the other sales document is allowed and if it is not already in the list and if it is not, add it
      if (isOtherSalesDocumentAllowed && !otherDocumentExistOnFilteredDocTypes) {
        const otherDocument = docTypes.find(
          (docType) => docType.docTypeId === DOC_TYPES_ID.OTHER_SALES_DOCUMENT,
        );
        if (otherDocument) {
          filteredDocTypes.push(otherDocument);
        }
        // Check if the other sales document is not allowed and if it is in the list and if it is, remove it
      } else if (!isOtherSalesDocumentAllowed && otherDocumentExistOnFilteredDocTypes) {
        return filteredDocTypes.filter(
          (docType) => docType.docTypeId !== DOC_TYPES_ID.OTHER_SALES_DOCUMENT,
        );
      }

      return filteredDocTypes.sort((a, b) => {
        return docTypeIds.indexOf(a.docTypeId) - docTypeIds.indexOf(b.docTypeId);
      });
    },
  );
