export const messages: Record<string, string> = {
  // DYNAMIC DATA FIELDS (from network payloads)
  DTE_SII_trackId: 'ID de envío',
  DTE_documentStatus: 'Estado',
  accountName: 'Cuenta',
  accountNumber: 'Nº de cuenta',
  addition: 'Suma',
  allowNonContinuousDates: 'Permitir fechas saltadas',
  allowOverCreditLimit: 'Permitir sobre límite de crédito',
  allowUnderCost: 'Permitir por debajo del costo',
  allowUserChangePrices: 'Permitir modificar precios',
  allowWithOverdueInvoices: 'Facturas morosas  |   Permitir',
  allowWithoutStock: 'Permitir sin stock',
  amountToBePaid: 'Líquido a pagar',
  assets: 'Activo',
  authorizationDate: 'Fecha',
  averageTicket: 'Media',
  balance: 'Saldo',
  bank_name: 'Banco',
  branch: 'Sucursal',
  branch_name: 'Sucursal',
  comissions: 'Comisión',
  cost: 'Costo',
  costCenter_name: 'Centro costo',
  costCenterId: 'Id Centro costo',
  costCenterName: 'Centro costo',
  county: 'Comuna',
  create: 'Agregar',
  credit: 'Haber',
  creditBalance: 'Saldo acreedor',
  currencyCode: 'Moneda',
  customerCategoryName: 'Familia de clientes',
  customerId: 'ID de cliente',
  customerName: 'Cliente',
  customerVATId: 'RUT',
  customer_name: 'Cliente',
  date: 'Fecha',
  dateFrom: 'Fecha desde',
  dateTo: 'Fecha hasta',
  dayOfMonth: 'Dia del mes',
  dayOfWeek: 'Dia de al semana',
  dayofmonth: 'Día del mes',
  dayofweek: 'Día de la semana',
  dealer_name: 'Distribuidor',
  debit: 'Debe',
  debitBalance: 'Saldo deudor',
  delete: 'Borrado',
  deposit: 'Depósitos',
  description: 'Descripción',
  sku: 'Producto',
  discountPercentage: 'Descuento %',
  discounts: 'Descuentos',
  discountsPercentage: 'Descuento %',
  docNumber: 'Número',
  docNumberFrom: 'Desde',
  docNumberTo: 'Hasta',
  docTypeId: 'Tipo',
  docTypeName: 'Tipo',
  docType_name: 'Tipo',
  document: 'Nº Doc',
  bankStatementDocument: 'Documento',
  documentStatus: 'Estado',
  due: 'Pendiente',
  dueDate: 'Vencimiento',
  employee_name: 'Empleado',
  errorMessage: 'Error',
  expenses: 'Pérdidas',
  fullPath: 'Familia',
  incomes: 'Ganancias',
  initialBalance: 'Saldo inicial',
  inventoryDifference: 'Diferencia',
  issuedDate: 'Emisión',
  items_account_name: 'Cuenta',
  items_costCenter_name: 'C. Costo',
  items_currencyCode: 'Moneda',
  items_discountPercentage: 'Descuento %',
  items_net: 'Neto',
  items_originalUnitCost: 'Coste Un. Orig.',
  items_parityToMainCurrency: 'T/C',
  items_product_SKU: 'Producto',
  items_product_description: 'Descripción',
  items_quantity: 'Cantidad',
  items_unitCost: 'Coste Un.',
  items_unitPrice: 'Precio unitario',
  items_product_sku: 'Producto',
  items_product_productCategory_name: 'Familia',
  journalEntryId: 'Id comprobante',
  journalEntryNumber: 'Número',
  liabilities: 'Pasivo',
  lineId: 'línea',
  linkedToName: 'Nombre',
  linkedToVATId: 'RUT',
  lot: 'Lote',
  margin: 'Margen',
  marginChangePercentage: 'Margen',
  marginChangeYoYPercentage: 'Margen variación %',
  marginPercentage: 'Margen %',
  marginpercentage: 'Margen %',
  month: 'Mes',
  monthlyPosition: 'Corr. mensual',
  multipleCurrencies: 'DEV: multimoneda en facturas',
  net: 'Neto',
  netChangePercentage: 'Variación',
  netChangeYoYPercentage: 'Variación %',
  netPercentage: 'Neto %',
  netchangepercentage: 'Variación %',
  notes: 'Notas',
  numberOfDocuments: 'Nº Documentos',
  numberofdocuments: 'Nº documentos',
  originalCredit: 'Haber orginal',
  originalDebit: 'Debe original',
  overdueDays: 'Días mora',
  paid: 'Cancelado',
  parity: 'T/C',
  parityToMainCurrency: 'T/C',
  payrollId: 'Id liquidación',
  percentage: 'Porcentaje',
  productCategoryName: 'Familia de productos',
  productCategoryname: 'Familia de productos',
  productDescription: 'Descripción',
  productSKU: 'SKU',
  productSku: 'SKU',
  period: 'Periodo',
  quantity: 'Unidades',
  quantityAlt: 'Cantidad alternativa',
  read: 'Lectura',
  remunerationConceptDescription: 'Desde',
  remunerationConceptTypeDescription: 'Tipo documento',
  returns: 'Devolución',
  returnsPercentage: 'Devolución %',
  saldo: 'Saldo',
  salesInvoiceId: 'Id doc. ventas',
  salesman: 'Vendedor',
  salesmanId: 'Id vendedor',
  salesmanName: 'Vendedor',
  salesman_name: 'Vendedor',
  salesmanname: 'Vendedor',
  state: 'Región',
  stock: 'Stock',
  stockControlDescription: 'Concepto',
  stockControlTotalCost: 'Valor Tot. Stock',
  stockControlTotalUnitCost: 'Valor Un. Stock',
  stockControlQuantity: 'Un',
  supplierName: 'Proveedor',
  supplierVATId: 'RUT',
  term_name: 'Forma de pago',
  total: 'Venta',
  totalCost: 'Costo total',
  totalUnitCost: 'Costo total unitario',
  totals_net: 'Neto',
  totals_total: 'Total',
  totals_vat: 'IVA',
  trackId: 'ID de envío',
  unitCost: 'Costo Un',
  unitOfMeasure: 'Unidad de medida',
  unitOfMeasureAlt: 'Unidad de medida alternativa',
  unitOfMeasurealt: 'Unidad de medida alternativa',
  unitPrice: 'Precio Un',
  warehouseId: 'Bodega',
  withdrawal: 'Cargos',
  write: 'Modificar',
  year: 'Año',
  productId: 'Id producto',
  customer_VATId: 'VAT Cliente',
  sourceOrderId: 'Pedido',
  totalsOriginalCurrency_net: 'Neto (moneda original)',
  totalsOriginalCurrency_currencyCode: 'Moneda original',
  customFields_fecha_recepción_cliente_: 'Fecha recepción cliente',
};
