import { SystemStyleObjectRecord } from '@chakra-ui/react';

export const textStyles: SystemStyleObjectRecord = {
  hero: {
    fontSize: ['48px', '64px'],
    bold: {
      fontSize: ['48px', '64px'],
      fontWeight: '700',
    },
    light: {
      fontSize: ['48px', '64px'],
      fontWeight: '300',
    },
  },
  h1: {
    fontSize: ['32px', '48px'],
    bold: {
      fontSize: ['32px', '48px'],
      fontWeight: '700',
    },
    light: {
      fontSize: ['32px', '48px'],
      fontWeight: '300',
    },
  },
  h2: {
    fontSize: ['24px', '32px'],
    bold: {
      fontSize: ['24px', '32px'],
      fontWeight: '700',
    },
    light: {
      fontSize: ['24px', '32px'],
      fontWeight: '300',
    },
  },
  h3: {
    fontSize: ['18px', '24px'],
    bold: {
      fontSize: ['18px', '24px'],
      fontWeight: '700',
    },
    light: {
      fontSize: ['18px', '24px'],
      fontWeight: '300',
    },
  },
  h4: {
    fontSize: ['1.6rem', '1.6rem'],
    bold: {
      fontSize: ['1.6rem', '1.6rem'],
      fontWeight: '700',
    },
    light: {
      fontSize: ['1.6rem', '1.6rem'],
      fontWeight: '300',
    },
  },
  h5: {
    fontSize: ['14px', '14px'],
    bold: {
      fontSize: ['14px', '14px'],
      fontWeight: '700',
    },
    light: {
      fontSize: ['14px', '14px'],
      fontWeight: '300',
    },
  },
  h6: {
    fontSize: ['1.2rem', '1.2rem'],
    bold: {
      fontSize: ['1.2rem', '1.2rem'],
      fontWeight: '700',
    },
    light: {
      fontSize: ['1.2rem', '1.2rem'],
      fontWeight: '300',
    },
  },
  body: {
    fontSize: ['14px', '1.6rem'],
    bold: {
      fontSize: ['14px', '1.6rem'],
      fontWeight: '700',
    },
    light: {
      fontSize: ['14px', '1.6rem'],
      fontWeight: '300',
    },
  },
  note: {
    fontSize: ['0.8rem', '1rem'],
    bold: {
      fontSize: ['0.8rem', '1rem'],
      fontWeight: '700',
    },
    light: {
      fontSize: ['0.8rem', '1rem'],
      fontWeight: '300',
    },
  },
};
