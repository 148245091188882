import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IRemunerationConceptType, IRemunerationConceptTypeListItem } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';

export const REMUNERATION_CONCEPT_TYPES_TAB_ID = 'remunerationConceptTypes';

export const setRemunerationConceptTypeList = createAction<IRemunerationConceptTypeListItem[]>(
  'REMUNERATION_CONCEPT_TYPES/SET_LIST',
);

export const setRemunerationConceptTypeDraftList = createAction<IRemunerationConceptTypeListItem[]>(
  'REMUNERATION_CONCEPT_TYPES/SET_DRAFT_LIST',
);

export const fetchRemunerationConceptTypeList = createAsyncThunk<void, void, AppThunkConfig>(
  'REMUNERATION_CONCEPT_TYPES/FETCH_REMUNERATION_CONCEPT_TYPE_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('remuneration-concept-types'));
    try {
      const { data } =
        await api.remunerationConceptsTypes.fetchRemunerationConceptTypeListFromAPI();
      dispatch(setRemunerationConceptTypeList(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'wagesParameters',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('remuneration-concept-types'));
    }
  },
);

export const updateRemunerationConceptType = createAsyncThunk<
  void,
  IRemunerationConceptType,
  AppThunkConfig
>('REMUNERATION_CONCEPT_TYPES/UPDATE', async (remunerationConceptType, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('remuneration-concept-types'));
  try {
    await api.remunerationConceptsTypes.updateRemunerationConceptTypeFromAPI(
      remunerationConceptType,
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'remunerationConceptTypes',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('remuneration-concept-types'));
  }
});
