import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString, getNegativeUniqueNumericId } from '@laudus/shared-utils';
import { IInput } from '@laudus/types';

import {
  clearInput,
  clearInputDraft,
  duplicateInput,
  removeInputFromList,
  setInput,
  setInputDraft,
  setInputDraftValues,
  setInputsList,
  updateInputsList,
} from './actions';

export const INPUTS_TAB_ID = 'input';

export interface IInputsState {
  current: IInput;
  draft: IInput;
  list: IInput[];
}

export const INPUT_EMPTY: IInput = {
  createdAt: '',
  createdBy: {},
  date: dateToLocalISOString(new Date()),
  inputId: 0,
  invoiceNumber: '',
  items: [],
  modifiedAt: '',
  modifiedBy: {},
  notes: '',
  supplier: {},
  warehouse: {},
};

export const initialInputsState: IInputsState = {
  current: INPUT_EMPTY,
  draft: INPUT_EMPTY,
  list: [],
};

export const inputsReducer = createReducer(initialInputsState, (builder) => {
  builder
    .addCase(clearInput, (state) => {
      return { ...state, current: INPUT_EMPTY };
    })
    .addCase(clearInputDraft, (state) => {
      return { ...state, draft: INPUT_EMPTY };
    })
    .addCase(setInput, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setInputDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setInputDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setInputsList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateInputsList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.inputId !== action.payload.inputId), action.payload],
      };
    })
    .addCase(removeInputFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.inputId !== action.payload),
      };
    })
    .addCase(duplicateInput, (state, action) => {
      const { inputId, ...duplicatedInput } = action.payload;
      const duplicatedItems = [...duplicatedInput.items].map((item) => {
        return { ...item, itemId: getNegativeUniqueNumericId() };
      });
      return {
        ...state,
        draft: { ...duplicatedInput, items: [...duplicatedItems] },
      };
    })
    .addDefaultCase((state) => state);
});
