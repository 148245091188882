import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { showAlert } from '@laudus/redux-global';
import { getVatIdFromUrlPath } from '@laudus/shared-utils';
import { IAPIError, IGlobalCompany, IGlobalUser, IGroup, IUser } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';
import { USERS_EMPTY } from '../users';

import { getAddingCompanyAccessToken } from './selectors';

// Simple actions
export const clearGlobalUser = createAction('GLOBAL_USERS/CLEAR');

export const clearGlobalUserDraft = createAction(`GLOBAL_USERS/CLEAR_DRAFT`);

export const clearCompaniesOfGlobalUser = createAction(`GLOBAL_USERS/CLEAR_COMPANIES`);

export const setGlobalUser = createAction<IGlobalUser>('GLOBAL_USERS/SET');

export const setGlobalUserDraft = createAction<IGlobalUser>('GLOBAL_USERS/SET_DRAFT');

export const setCompaniesOfGlobalUser = createAction<IGlobalCompany[]>(
  'GLOBAL_USERS/SET_COMPANIES',
);

export const setGlobalUserDraftValues = createAction<Partial<IGlobalUser>>(
  'GLOBAL_USERS/SET_DRAFT_VALUE',
);

export const setGlobalUsersList = createAction<IGlobalUser[]>('GLOBAL_USERS/SET_LIST');

export const updateGlobalUsersList = createAction<IGlobalUser>('GLOBAL_USERS/UPDATE_LIST');

export const removeGlobalUserFromList = createAction<number>('GLOBAL_USERS/REMOVE_FROM_LIST');

export const clearAddingCompanyInfo = createAction('GLOBAL_USERS/CLEAR_ADDING_COMPANY_INFO');

export const setAddingCompanyAccessToken = createAction<string>(
  'GLOBAL_USERS/SET_ADDING_COMPANY_TOKEN',
);

export const setAddingCompanyGroups = createAction<IGroup[]>(
  'GLOBAL_USERS/SET_ADDING_COMPANY_GROUPS',
);

//Complex actions
export const fetchGlobalUsersList = createAsyncThunk<void, void, AppThunkConfig>(
  'GLOBAL_USERS/FETCH_GLOBAL_USERS_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('global-users-list-read'));
    try {
      const { data } = await api.globalUsers.fetchGlobalUsersListFromAPI();
      dispatch(setGlobalUsersList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'glUsers',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('global-users-list-read'));
    }
  },
);

export const fetchGlobalUser = createAsyncThunk<void, number, AppThunkConfig>(
  'GLOBAL_USERS/FETCH',
  async (glUserId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('global-user-read'));
    try {
      const { data } = await api.globalUsers.fetchGlobalUserFromAPI(glUserId);

      dispatch(setGlobalUser(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'glUsers',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('global-user-read'));
    }
  },
);

export const fetchCompaniesOfGlobalUser = createAsyncThunk<void, number, AppThunkConfig>(
  'GLOBAL_USERS/FETCH_COMPANIES_LIST',
  async (glUserId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('companies-global-users-list-read'));
    try {
      const { data } = await api.globalCompanies.getCompaniesListByUserAPI(glUserId);
      dispatch(setCompaniesOfGlobalUser(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'glUsers',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('companies-global-users-list-read'));
    }
  },
);

export const fetchCompanyGroups = createAsyncThunk<void, string, AppThunkConfig>(
  'GLOBAL_USERS/FETCH_COMPANY_GROUPS',
  async (globalCompanyVATId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('global-user'));
    try {
      // Login with token in company
      const currentVATId = getVatIdFromUrlPath();
      let token = '';
      if (currentVATId !== globalCompanyVATId) {
        const { data } = await api.auth.loginWithToken({ companyVatId: globalCompanyVATId });
        token = data.token;
      }
      dispatch(setAddingCompanyAccessToken(token));

      const { data } = await api.groups.fetchGroupListFromAPI(token);
      dispatch(setAddingCompanyGroups(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'glUsers',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('global-user'));
    }
  },
);

export interface IAddCompanyToGlobalUserParams {
  globalUser: IGlobalUser;
  globalCompany: IGlobalCompany;
  groupId: string;
}
export const addCompanyToGlobalUser = createAsyncThunk<
  void,
  IAddCompanyToGlobalUserParams,
  AppThunkConfig
>('GLOBAL_USERS/ADD_COMPANY', async ({ globalUser, globalCompany, groupId }, ThunkAPI) => {
  const { dispatch, extra, getState } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('global-user'));
  try {
    const state = getState();
    const token = getAddingCompanyAccessToken(state);

    // Verify if user already exists in company
    let user: IUser | undefined;
    try {
      const { data } = await api.users.fetchUserByLoginNameFromAPI({
        loginName: globalUser.loginName,
        companyAccessToken: token,
      });
      user = data;
    } catch (error) {
      if ((error as IAPIError).response?.status !== 404) {
        throw error;
      }
    }
    if (user) {
      if (user.glUserId && user.glUserId !== globalUser.glUserId) {
        dispatch(
          showAlert({
            type: 'error',
            title: intl.formatMessage({ id: 'accounts.global.companies.existingUserError' }),
          }),
        );
        return;
      }
      if (user.discontinued) {
        await api.users.updateUserFromAPI(
          {
            ...user,
            discontinued: false,
            group: {
              groupId,
              name: '',
            },
          },
          token,
        );
      }
    } else {
      // Add user to company
      const newCompanyUser: IUser = {
        ...USERS_EMPTY,
        name: globalUser.loginName,
        loginName: globalUser.loginName,
        email: globalUser.email,
        glUserId: globalUser.glUserId,
        discontinued: false,
        group: {
          groupId,
          name: '',
        },
      };
      await api.users.createUserFromAPI(newCompanyUser, token);
    }

    // Link global user to company
    await api.globalCompanies.addCompanyToGlobalUserAPI(
      globalUser.glUserId,
      globalCompany.glCompanyId,
    );

    // Refresh global user companies
    dispatch(fetchCompaniesOfGlobalUser(globalUser.glUserId));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'glUsers',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('global-user'));
  }
});

export interface IRemoveCompanyFromGlobalUserParams {
  globalUser: IGlobalUser;
  globalCompany: IGlobalCompany;
}
export const removeCompanyFromGlobalUser = createAsyncThunk<
  void,
  IRemoveCompanyFromGlobalUserParams,
  AppThunkConfig
>('GLOBAL_USERS/REMOVE_COMPANY', async ({ globalUser, globalCompany }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('global-user'));
  try {
    // Login with token in company
    const currentVATId = getVatIdFromUrlPath();
    let token = '';
    if (currentVATId !== globalCompany.VATId) {
      const { data } = await api.auth.loginWithToken({ companyVatId: globalCompany.VATId });
      token = data.token;
    }

    // Verify if user already exists in company (it should)
    let user: IUser | undefined;
    try {
      const { data } = await api.users.fetchUserByLoginNameFromAPI({
        loginName: globalUser.loginName,
        companyAccessToken: token,
      });
      user = data;
    } catch (error) {
      if ((error as IAPIError).response?.status !== 404) {
        throw error;
      }
    }
    if (user?.userId && user.glUserId && user.glUserId === globalUser.glUserId) {
      await api.users.updateUserFromAPI({ ...user, discontinued: true }, token);
    }

    // Unlink global user to company
    await api.globalCompanies.deleteCompanyFromGlobalUserAPI(
      globalUser.glUserId,
      globalCompany.glCompanyId,
    );

    // Refresh global user companies
    dispatch(fetchCompaniesOfGlobalUser(globalUser.glUserId));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'glUsers',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('global-user'));
  }
});
