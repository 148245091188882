import { lazy, Suspense, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { useIntl } from '@laudus/intl';
import { showErrorAlert } from '@laudus/redux-app';
import {
  getAccessError,
  getAccessToken,
  getAuthCompanyList,
  getGlobalUser,
  loginCompany,
  setAccessError,
  setLastVatId,
  showAlert,
  useGlobalDispatch,
  useGlobalSelector,
} from '@laudus/redux-global';
import { services } from '@laudus/services';
import { LaudusLoader } from '@laudus/shared-ui';
import { isAPIError } from '@laudus/types';

import { LoginCompanyList } from './pages/Login/LoginCompanyList';
import { SELECT_COMPANY_PATH, SELECT_COMPANY_QUERY_PARAM } from './router/constants';

const App = lazy(() => import('./App'));

export const AppMounter = () => {
  const intl = useIntl();
  const dispatch = useGlobalDispatch();
  const navigate = useNavigate();

  const { VATId } = useParams();
  const accessToken = useGlobalSelector(getAccessToken(VATId));
  const accessError = useGlobalSelector(getAccessError);
  const globalUser = useGlobalSelector(getGlobalUser);
  const companyList = useGlobalSelector(getAuthCompanyList);
  const [isTokenChecked, setIsTokenChecked] = useState(false);

  useEffect(() => {
    async function checkAccessTokenValid() {
      try {
        await services.api.auth.checkToken();

        if (VATId) {
          dispatch(setLastVatId(VATId));
        }

        setIsTokenChecked(true);
      } catch (error) {
        // If not an auth error show alert.
        if (isAPIError(error)) {
          dispatch(
            showErrorAlert({
              error,
              prefix: 'login',
              action: 'read',
            }),
          );
        }
      }
    }

    if (VATId === SELECT_COMPANY_QUERY_PARAM && companyList.length === 1) {
      const selectedVatId = companyList[0].VATId;
      dispatch(
        loginCompany({
          VATId: selectedVatId,
          redirectToSuccessPath: `/${selectedVatId}`,
        }),
      );
      return;
    }

    // If there's no VATId, abort nothing to do
    if (!VATId || VATId === SELECT_COMPANY_QUERY_PARAM) {
      return;
    }

    // If no access token, attempt to login and retrieve a new one
    if (!accessToken && !accessError && VATId) {
      dispatch(loginCompany({ VATId }));
    }

    // If access token exists but not validated, then validate it
    if (accessToken && !accessError && !isTokenChecked) {
      checkAccessTokenValid();
    }
  }, [accessToken, isTokenChecked, VATId]);

  useEffect(() => {
    if (accessError) {
      dispatch(setAccessError(null));
      navigate(SELECT_COMPANY_PATH);
      dispatch(showAlert({ type: 'error', isCritical: true, ...accessError }));
    }
  }, [accessError, dispatch, navigate]);

  // Check the basic conditions are valid, otherwise display error
  if (!VATId || VATId === 'null' || !accessToken || accessError || !globalUser) {
    return (
      <LoginCompanyList
        onClose={() => undefined}
        show={VATId === SELECT_COMPANY_QUERY_PARAM && companyList.length > 1}
      />
    );
  }

  // We must not allow the App to mount until we know the token is valid.
  // Otherwise network errors will be thrown from various fetcher actions.
  // Reasons for invalid tokens are expiration and Ip change detection.
  return !isTokenChecked ? (
    <LaudusLoader />
  ) : (
    <Suspense fallback={<LaudusLoader />}>
      <App userId={'' + globalUser.glUserId} VATId={VATId} />
    </Suspense>
  );
};
