import { createSelector } from '@reduxjs/toolkit';

import { IUser } from '@laudus/types';

import { AppState, IUsersState } from '../..';

import { doesUserMatchDiscontinuedFilter } from './utils';

export const getUsersSlice = (state: AppState): IUsersState => state.users;

export const getUsersList = createSelector([getUsersSlice], (state) => state.list);

export const getUsersListForUserSearchGrid = ({
  showDiscontinued,
}: {
  showDiscontinued: boolean;
}) =>
  createSelector([getUsersList], (list) => {
    return list.reduce((acc, user) => {
      const matchDiscontinued = doesUserMatchDiscontinuedFilter({
        user,
        showDiscontinued,
      });

      if (matchDiscontinued) {
        acc.push(user);
      }
      return acc;
    }, [] as IUser[]);
  });

export const getUser = createSelector([getUsersSlice], (state) => state.current);

export const getUserDraft = createSelector([getUsersSlice], (state) => state.draft);
