import { createReducer } from '@reduxjs/toolkit';

import {
  ISalesTicket,
  ISalesTicketActivity,
  ISalesTicketItemOnRepair,
  ISalesTicketItemOnRepairReception,
  ISalesTicketItemOnRepairReparation,
  SalesTicketActivityStatus,
} from '@laudus/types';

import {
  clearSalesTicket,
  clearSalesTicketDraft,
  duplicateSalesTicket,
  removeSalesTicketFromList,
  setSalesTicket,
  setSalesTicketDraft,
  setSalesTicketDraftValues,
  setSalesTicketEditing,
  setSalesTicketList,
  updateSalesTicketList,
} from './actions';

export interface ISalesTicketsState {
  current: ISalesTicket;
  draft: ISalesTicket;
  list: ISalesTicket[];
  editing: boolean;
}

export const SALES_TICKET_ITEM_ON_REPAIR_RECEPTION_EMPTY: ISalesTicketItemOnRepairReception = {
  receivedAt: null,
  receivedBy: null,
  description: '',
};

export const SALES_TICKET_ITEM_ON_REPAIR_REPARATION_EMPTY: ISalesTicketItemOnRepairReparation = {
  repairedAt: null,
  repairedBy: null,
  description: '',
};

export const SALES_TICKET_ITEM_ON_REPAIR_EMPTY: ISalesTicketItemOnRepair = {
  itemId: undefined,
  product: null,
  description: '',
  reception: SALES_TICKET_ITEM_ON_REPAIR_RECEPTION_EMPTY,
  reparation: SALES_TICKET_ITEM_ON_REPAIR_REPARATION_EMPTY,
  dueDate: null,
  deliveryDate: null,
  serialNumber: null,
  spares: [],
};

export const SALES_TICKET_ACTIVITY_EMPTY: ISalesTicketActivity = {
  subject: '',
  description: '',
  issuedDate: null,
  dueDate: null,
  status: SalesTicketActivityStatus.PROCESSING,
  assignedTo: null,
  contact: null,
};

export const SALES_TICKET_EMPTY: ISalesTicket = {
  subject: '',
  description: '',
  solution: '',
  category: null,
  status: null,
  assignedTo: null,
  issuedDate: null,
  completitionDate: null,
  dueDate: null,
  customer: null,
  submittedBy: '',
  submittedByContactInfo: '',
  createdBy: null,
  createdAt: '',
  modifiedBy: null,
  modifiedAt: '',
  activities: [],
  itemOnRepair: SALES_TICKET_ITEM_ON_REPAIR_EMPTY,
};

export const initialSalesTicketsState: ISalesTicketsState = {
  current: SALES_TICKET_EMPTY,
  draft: SALES_TICKET_EMPTY,
  list: [],
  editing: false,
};

export const salesTicketsReducer = createReducer(initialSalesTicketsState, (builder) => {
  builder
    .addCase(clearSalesTicket, (state) => {
      return { ...state, current: SALES_TICKET_EMPTY };
    })
    .addCase(clearSalesTicketDraft, (state) => {
      return { ...state, draft: SALES_TICKET_EMPTY };
    })
    .addCase(setSalesTicket, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setSalesTicketDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setSalesTicketDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setSalesTicketList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateSalesTicketList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((d) => d.ticketId !== action.payload.ticketId), action.payload],
      };
    })
    .addCase(removeSalesTicketFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.ticketId !== action.payload),
      };
    })
    .addCase(duplicateSalesTicket, (state, action) => {
      const { ticketId, ...duplicatedSalesTicket } = action.payload;

      return {
        ...state,
        draft: {
          ...duplicatedSalesTicket,
          itemOnRepair: {
            ...(duplicatedSalesTicket.itemOnRepair ?? SALES_TICKET_ITEM_ON_REPAIR_EMPTY),
            itemId: 0,
          },
        },
      };
    })
    .addCase(setSalesTicketEditing, (state, action) => {
      return { ...state, editing: action.payload };
    })
    .addDefaultCase((state) => state);
});
