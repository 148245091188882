import { ReactNode } from 'react';

import {
  Box,
  Button,
  Flex,
  Link,
  Popover as ChakraPopover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  useDisclosure,
} from '@chakra-ui/react';

import { IconLightBulb } from '@laudus/icons';
import { useIntl } from '@laudus/intl';

import { Underline, useThemeContext } from '../..';

interface IPopoverProps {
  children: ReactNode;
  title?: string;
  text?: string | JSX.Element;
  buttonLinkText?: string;
  buttonLinkHref?: string;
  buttonText?: string;
  buttonOnClick?: () => void;
}

export const Tooltip = ({
  buttonLinkText,
  buttonLinkHref,
  buttonText,
  buttonOnClick,
  children,
  text,
  title,
}: IPopoverProps) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const intl = useIntl();
  const { colors } = useThemeContext();

  return (
    <ChakraPopover isOpen={isOpen} onClose={onClose} placement="top">
      <PopoverTrigger>
        <Button height="auto" onMouseEnter={onOpen} variant="trigger">
          {children}
        </Button>
      </PopoverTrigger>
      <Portal>
        <PopoverContent
          _focus={{ outline: 'none' }}
          bg="#F0F0F0"
          border=".03rem solid"
          borderColor="rgba(66, 66, 66, 0.48)"
          boxShadow="0 .3rem 1.6rem rgba(183, 191, 204, 0.53)"
          minWidth="20.5rem"
          width="min-content"
        >
          <PopoverArrow
            bg="#F0F0F0"
            borderBottom=".03rem solid"
            borderColor="rgba(66, 66, 66, 0.48)"
            borderRight=".03rem solid"
            boxShadow="0 .3rem 1.6rem rgba(183, 191, 204, 0.53)"
          />
          <Underline />
          {title && (
            <PopoverHeader fontWeight="semibold">{intl.formatMessage({ id: title })}</PopoverHeader>
          )}
          <PopoverBody p="1rem">
            {text && (
              <Flex alignItems="center" gap="2rem">
                <Box alignSelf="self-start" marginTop="1rem" marginLeft="1rem">
                  <IconLightBulb color={colors?.tertiary500} fontSize="1.6rem" />
                </Box>

                <Box>
                  {text ? <Text variant="tooltip-text">{text}</Text> : undefined}
                  {buttonLinkText && (
                    <Button variant="secondary-link">
                      <Link href={buttonLinkHref} m={0} p={0} target="_blank">
                        {intl.formatMessage({ id: buttonLinkText })}
                      </Link>
                    </Button>
                  )}
                </Box>
              </Flex>
            )}
            {buttonText && (
              <Button colorScheme="blue" onClick={buttonOnClick}>
                {intl.formatMessage({ id: buttonText })}
              </Button>
            )}
          </PopoverBody>
        </PopoverContent>
      </Portal>
    </ChakraPopover>
  );
};
