import { createReducer } from '@reduxjs/toolkit';

import { ISavingsPlansProvider } from '@laudus/types';

import {
  clearSavingsPlansProvider,
  clearSavingsPlansProviderDraft,
  removeSavingsPlansProviderFromList,
  setSavingsPlansProvider,
  setSavingsPlansProviderDraft,
  setSavingsPlansProviderDraftValues,
  setSavingsPlansProvidersList,
  updateSavingsPlansProvidersList,
} from '.';

export const SAVINGSPLANSPROVIDERS_TAB_ID = 'savingsPlansProviders';

export interface ISavingsPlansProvidersState {
  current: ISavingsPlansProvider;
  draft: ISavingsPlansProvider;
  list: ISavingsPlansProvider[];
}

export const SAVINGSPLANSPROVIDER_EMPTY: ISavingsPlansProvider = {
  savingsPlansProviderId: '',
  name: '',
  legalName: '',
  previredId: '',
  notes: '',
};

export const initialSavingsPlansProvidersState: ISavingsPlansProvidersState = {
  current: SAVINGSPLANSPROVIDER_EMPTY,
  draft: SAVINGSPLANSPROVIDER_EMPTY,
  list: [],
};

export const savingsPlansProvidersReducer = createReducer(
  initialSavingsPlansProvidersState,
  (builder) => {
    builder
      .addCase(clearSavingsPlansProvider, (state) => {
        return {
          ...state,
          current: SAVINGSPLANSPROVIDER_EMPTY,
        };
      })
      .addCase(clearSavingsPlansProviderDraft, (state) => {
        return {
          ...state,
          draft: SAVINGSPLANSPROVIDER_EMPTY,
        };
      })
      .addCase(setSavingsPlansProvider, (state, action) => {
        return { ...state, current: action.payload };
      })
      .addCase(setSavingsPlansProviderDraft, (state, action) => {
        return { ...state, draft: action.payload };
      })
      .addCase(setSavingsPlansProviderDraftValues, (state, action) => {
        return { ...state, draft: { ...state.draft, ...action.payload } };
      })
      .addCase(setSavingsPlansProvidersList, (state, action) => {
        return { ...state, list: action.payload };
      })
      .addCase(updateSavingsPlansProvidersList, (state, action) => {
        return {
          ...state,
          list: [
            ...state.list.filter(
              (p) => p.savingsPlansProviderId !== action.payload.savingsPlansProviderId,
            ),
            action.payload,
          ],
        };
      })
      .addCase(removeSavingsPlansProviderFromList, (state, action) => {
        return {
          ...state,
          list: state.list.filter((d) => d.savingsPlansProviderId !== action.payload),
        };
      })
      .addDefaultCase((state) => state);
  },
);
