import { createSelector } from '@reduxjs/toolkit';

import { AppState, ICarrierState } from '../..';

export const getCarriersSlice = (state: AppState): ICarrierState => state.carriers;

export const getCarriersList = createSelector([getCarriersSlice], (state) => state.list);

export const getCarrier = createSelector([getCarriersSlice], (state) => state.current);

export const getCarrierDraft = createSelector([getCarriersSlice], (state) => state.draft);
