import { createReducer } from '@reduxjs/toolkit';

import { setSendEmailLoading } from './actions';
import { ISystemsState } from './types';

export const initialSystemState: ISystemsState = {
  loading: false,
};

export const systemsReducer = createReducer(initialSystemState, (builder) => {
  builder
    .addCase(setSendEmailLoading, (state, action) => {
      return { ...state, loading: action.payload };
    })
    .addDefaultCase((state) => state);
});
