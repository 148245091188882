import { createSelector } from '@reduxjs/toolkit';

import { AppState, IBranchesState } from '../..';

export const getBranchesSlice = (state: AppState): IBranchesState => state.branches;

export const getBranchesLoading = createSelector([getBranchesSlice], (state) => state.loading);

export const getBranchesList = createSelector([getBranchesSlice], (state) => state.list);

export const getBranch = createSelector([getBranchesSlice], (state) => state.current);

export const getBranchProductsWithPriceOverride = createSelector(
  [getBranchesSlice],
  (state) => state.productsWithPricesOverride,
);

export const getBranchDraft = createSelector([getBranchesSlice], (state) => state.draft);
