import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IInput } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

export const addHomeInputTab = () =>
  addTab({
    tab: {
      id: 'input',
      title: intl.formatMessage({ id: 'input.tabTitle' }),
      path: 'pages/Input/Input',
      isRemovable: true,
    },
  });

export const addViewInputTab = (id?: number) =>
  addTab({
    tab: {
      id: 'input',
      title: intl.formatMessage({ id: 'input.tabTitle' }),
      path: 'pages/Input/InputView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewInputTab = () =>
  addTab({
    tab: {
      id: 'input',
      title: intl.formatMessage({ id: 'input.tabTitle' }),
      path: 'pages/Input/InputNew',
      isRemovable: true,
    },
  });

export const addEditInputTab = () =>
  addTab({
    tab: {
      id: 'input',
      title: intl.formatMessage({ id: 'input.tabTitle' }),
      path: 'pages/Input/InputEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearInput = createAction('INPUTS/CLEAR');

export const clearInputDraft = createAction('INPUTS/CLEAR_DRAFT');

export const setInput = createAction<IInput>('INPUTS/SET_INPUT');

export const setInputDraft = createAction<IInput>('INPUTS/SET_INPUT_DRAFT');

export const setInputDraftValues = createAction<Partial<IInput>>('INPUTS/SET_INPUT_DRAFT_VALUE');

export const setInputsList = createAction<IInput[]>('INPUTS/SET_LIST');

export const updateInputsList = createAction<IInput>('INPUTS/UPDATE_LIST');

export const removeInputFromList = createAction<number>('INPUTS/REMOVE_FROM_LIST');

export const duplicateInput = createAction<IInput>('INPUTS/DUPLICATE');

// Complex actions
export const fetchInputsList = createAsyncThunk<void, void, AppThunkConfig>(
  'INPUTS/FETCH_INPUTS_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('inputs'));
    try {
      const { data } = await api.inputs.fetchInputsListAPI();
      dispatch(setInputsList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'input',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('inputs'));
    }
  },
);

export const fetchInput = createAsyncThunk<void, number, AppThunkConfig>(
  'INPUTS/FETCH_INPUT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('inputs'));
    try {
      const { data } = await api.inputs.fetchInputAPI(id);
      dispatch(setInput(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'input',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('inputs'));
    }
  },
);
