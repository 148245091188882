import { createSelector } from '@reduxjs/toolkit';

import { IRemunerationConcept, RemunerationConceptType } from '@laudus/types';

import { AppState } from '../../store';

import { IRemunerationConceptState } from './reducer';

export const getRemunerationConceptsSlice = (state: AppState): IRemunerationConceptState =>
  state.remunerationConcepts;

export const getRemunerationConceptList = createSelector(
  [getRemunerationConceptsSlice],
  (state) => state.list,
);

export const getRemunerationConcept = createSelector(
  [getRemunerationConceptsSlice],
  (state) => state.current,
);

export const getRemunerationConceptDraft = createSelector(
  [getRemunerationConceptsSlice],
  (state) => state.draft,
);

export const getRemunerationConceptListLevelZeroOrdered = createSelector(
  [getRemunerationConceptList],
  (state) =>
    state
      .filter((pc) => pc.parentId === null || pc.parentId === '')
      .sort((a, b) => a.name.localeCompare(b.name)),
);

export const getRemunerationConceptByRemunerationConceptId = (
  remunerationConceptId: string | null,
) =>
  createSelector([getRemunerationConceptList], (productCategoriesList) =>
    productCategoriesList.find(
      (remunerationConcept) => remunerationConcept.remunerationConceptId === remunerationConceptId,
    ),
  );

export const getRemunerationConceptSelectedNode = createSelector(
  [getRemunerationConceptsSlice],
  (state) => state.selectedNode,
);

export const getSortedRemunerationConceptList = createSelector(
  [getRemunerationConceptsSlice],
  (state) => sortRemunerationConceptList(state.list),
);

export const getRemunerationConceptListByType = (
  remunerationConceptType: RemunerationConceptType,
) =>
  createSelector([getRemunerationConceptList], (state) =>
    state.filter((remunerationConcept) => remunerationConcept.type === remunerationConceptType),
  );

/**
 * Generate a record whose key are remunerationConceptId's that have to be
 * taken into account to calculate the salary net total. The value is 1 if
 * that remuneration concepts adds and -1 if it subtracts.
 */
export const getValuationFactorsForRemunerationConcepts = createSelector(
  [getRemunerationConceptList],
  (state) =>
    state.reduce(
      (valuations, concept) => {
        if (
          concept.remunerationConceptId &&
          concept.type &&
          !concept.parentId &&
          ['TH', 'TD'].includes(concept.type) &&
          !concept.parentId
        ) {
          valuations[concept.remunerationConceptId] = concept.type === 'TH' ? 1 : -1;
        }
        return valuations;
      },
      {} as Record<string, number>,
    ),
);

// Utility functions

/**
 * Order remuneration concepts by type then by itemOrder and with descendants
 * following his parent.
 */
function sortRemunerationConceptList(list: IRemunerationConcept[]) {
  const index: Record<string, IRemunerationConcept> = {};
  list.forEach((concept) => {
    if (concept.remunerationConceptId !== undefined) {
      index[concept.remunerationConceptId] = concept;
    }
  });

  const typeOrder = ['TP', 'TH', 'TD', 'E'];

  const generateKey = (concept: IRemunerationConcept, key: string): string => {
    if (!concept.parentId) {
      return `${typeOrder.indexOf(concept.type || '')}/${String(concept.itemOrder).padStart(
        3,
        '0',
      )}/${key}`;
    }
    return generateKey(
      index[concept.parentId],
      `${String(concept.itemOrder).padStart(3, '0')}/${key}`,
    );
  };

  return [...list].sort((a, b) => {
    const aKey = generateKey(a, '');
    const bKey = generateKey(b, '');
    return aKey.localeCompare(bKey);
  });
}
