export enum FeatureFlags {
  /* special required feature */
  isFeaturesMenuActive = 'isFeaturesMenuActive',
  /* transient features */
  isPrintPDFPreviewInLedger = 'isPrintPDFPreviewInLedger',
  isColorModeButton = 'isColorModeButton',
  isBarcodeTestInputActive = 'isBarcodeTestInputActive',
  isBankReconciliationActive = 'isBankReconciliationActive',
  isBankStatementsNewTableActive = 'isBankStatementsNewTableActive',
  isInventoryExpandActive = 'isInventoryExpandActive',
  isPayrollEmployeesRemunerationNewTableActive = 'isPayrollEmployeesRemunerationNewTableActive',
  isSalesInvoicesNewTableActive = 'isSalesInvoicesNewTableActive',
  isSalesOrdersNewTableActive = 'isSalesOrdersNewTableActive',
  isSalesQuotesNewTableActive = 'isSalesQuotesNewTableActive',
  isSalesWaybillsNewTableActive = 'isSalesWaybillsNewTableActive',
  isNewStatisticsPageActive = 'isNewStatisticsPageActive',
  isRecurringSalesInvoicesMenuActive = 'isRecurringSalesInvoicesMenuActive',
  isSendPDFByEmailActive = 'isSendPDFByEmailActive',
}

export const sharedFlags = { isColorModeButton: false };

export const webAppFlags = {
  isFeaturesMenuActive: false,
  isPrintPDFPreviewInLedger: false,
  isBankReconciliationActive: false,
  isBankStatementsNewTableActive: false,
  isInventoryExpandActive: false,
  isPayrollEmployeesRemunerationNewTableActive: false,
  isSalesInvoicesNewTableActive: false,
  isSalesOrdersNewTableActive: true,
  isSalesQuotesNewTableActive: false,
  isSalesWaybillsNewTableActive: false,
  isRecurringSalesInvoicesMenuActive: false,
  isNewStatisticsPageActive: false,
  isSendPDFByEmailActive: false,
};

export const posAppFlags = {
  isBarcodeTestInputActive: false,
};

export const featureFlags: Record<FeatureFlags, boolean> = {
  ...sharedFlags,
  ...webAppFlags,
  ...posAppFlags,
};

export type IFeaturesState = typeof featureFlags;

export interface IFeaturesStateSlice {
  features: IFeaturesState;
}
