import { AxiosResponse } from 'axios';

import {
  IBalanceSheet8Columns,
  IBalanceSheetFormattedFilters,
  IBalanceSheetStandard,
  IBalanceSheetTotal,
} from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchBalanceSheetTotalAPI = (
  filter: IBalanceSheetFormattedFilters,
): Promise<AxiosResponse<IBalanceSheetTotal>> => {
  return axios.get(`${VITE_API_URL}/accounting/balanceSheet/totals`, {
    params: filter,
  });
};

export const fetchBalanceSheetStandardAPI = (
  filter: IBalanceSheetFormattedFilters,
): Promise<AxiosResponse<IBalanceSheetStandard>> => {
  return axios.get(`${VITE_API_URL}/accounting/balanceSheet/standard`, {
    params: filter,
  });
};

export const fetchBalanceSheet8ColumnsAPI = (
  filter: IBalanceSheetFormattedFilters,
): Promise<AxiosResponse<IBalanceSheet8Columns>> => {
  return axios.get(`${VITE_API_URL}/accounting/balanceSheet/8Columns`, {
    params: filter,
  });
};
