import { ComponentSingleStyleConfig } from '@chakra-ui/react';

import { colors } from '@laudus/shared-ui';

export const Button: ComponentSingleStyleConfig = {
  baseStyle: {
    border: 'none',
    borderRadius: '0.4rem',
    color: 'white',
    fontSize: '1.4rem',
    fontWeight: '600',
    paddingX: '18px',
    _focus: {
      boxShadow: 'none',
    },
  },
  sizes: {
    md: {
      fontSize: '14px',
      h: '32px',
    },
  },
  variants: {
    'action-bar': {
      bg: 'transparent',
      borderRadius: '0.4rem',
      fontWeight: 'normal',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        bg: 'secondary300',
        _dark: {
          bg: colors.darkGrey300,
        },
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'cancel': {
      bg: 'danger500',
      borderRadius: '0.4rem',
      marginTop: '0.4rem',
      color: 'white',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        color: 'delete',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'outline-secondary': {
      bg: 'transparent',
      borderRadius: '0.4rem',
      border: '1px solid',
      borderColor: 'black',
      color: 'black',
      padding: '0.7rem 2.5rem',
      boxSizing: 'border-box',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        bg: 'primary500',
        color: 'white',
        borderColor: 'primary500',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'outline-cancel': {
      bg: 'transparent',
      fontSize: '1.4rem',
      lineHeight: '0.17rem',
      color: 'danger500',
      padding: '0.7rem 2.5rem',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        bg: 'transparent',
        color: 'danger500',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'group': {
      bg: 'primary500',
      color: 'white',
      padding: '1rem 1.6rem 1rem 15px',
      _disabled: {
        opacity: '0.5',
      },
      _hover: {
        bg: 'primary400',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'home-info': {
      bg: 'secondary400',
      borderRadius: '0.4rem',
      fontWeight: 'normal',
      padding: '2rem 1rem 2rem 1rem',
      _disabled: {
        bg: 'secondary400',
      },
      _hover: {
        bg: 'secondary300',
        _disabled: {
          bg: 'secondary300',
        },
      },
    },
    'search-field': {
      bg: 'neutral200',
      borderRadius: '0.4rem',
      fontWeight: 'normal',
      height: '36px',
      padding: '2rem 2rem 2rem 2rem',
      width: '36px',
      _disabled: {
        color: 'neutral600',
        _hover: {
          bg: 'neutral200',
          color: 'neutral600',
        },
      },
      _hover: {
        bg: 'primary400',
        color: 'white',
      },
      _focus: {
        border: '2px solid',
        borderColor: 'primary500',
      },
    },
    'dashboard': {
      bg: 'transparent',
      borderRadius: '0.4rem',
      border: '0px solid',
      color: 'black',
      boxSizing: 'border-box',
      fontWeight: 'normal',
      height: '24px',
      width: '26.57px',
      _disabled: {
        color: 'neutral600',
        _hover: {
          bg: '#E9ECF0',
          color: 'neutral600',
        },
      },
      _hover: {
        color: 'primary500',
      },
      _focus: {
        bg: '#E9ECF0',
        color: 'black',
      },
    },
    'icon-action-bar': {
      bg: 'transparent',
      borderRadius: '0.4rem',
      marginRight: { base: 0, md: '-1.6rem' },
      _dark: {
        bg: colors.darkTransparentWhite38,
      },
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        bg: 'secondary300',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'icon-action-bar-dark': {
      bg: 'transparent',
      borderRadius: '0.4rem',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        bg: 'neutral50',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'icon-action-bar-dashboard': {
      bg: 'transparent',
      color: 'black',
      borderRadius: '0.4rem',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        color: 'primary500',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'expandable-filter': {
      bg: 'secondary200',
      borderRadius: '0.4rem',
      border: '1px solid black',
      color: 'white',
      _disabled: {
        opacity: '20%',
      },
      _hover: {
        opacity: '80%',
      },
    },
    'filter': {
      bg: 'white',
      borderRadius: '0.4rem',
      border: '1px solid black',
      color: 'black',
      _disabled: {
        opacity: '20%',
      },
      _hover: {
        bg: 'white',
        color: 'primary500',
        _disabled: {
          opacity: '20%',
        },
      },
    },
    'link': {
      bg: 'transparent',
      borderRadius: '0.4rem',
      color: 'neutral700',
      fontWeight: '600',
      _dark: {
        color: colors.white,
      },
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        bg: 'transparent',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'secondary-link': {
      bg: 'transparent',
      borderRadius: '0.4rem',
      color: 'primary500',
      fontWeight: '600',
      fontSize: '1.3rem',
      lineHeight: '140%',
      textDecoration: 'underline',
      margin: 0,
      padding: 0,
      height: 'initial',
      _hover: {
        bg: 'transparent',
        color: 'primary400',
      },
    },
    'login': {
      bg: 'primary500',
      color: 'white',
      borderRadius: '32px',
      height: '42px',
      width: '100%',
      _disabled: {
        opacity: '0.5',
      },
      _hover: {
        bg: 'primary400',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'menu': {
      'backgroundColor': 'transparent',
      'color': 'white',
      'height': '29px',
      '_hover': {
        backgroundColor: 'transparentWhite20',
      },
      '&.active': {
        backgroundColor: 'transparentWhite20',
      },
    },
    'mobile-menu': {
      backgroundColor: 'white',
      border: 'none',
      borderRadius: 0,
      height: '48px',
    },
    'cancel-negative': {
      bg: 'transparent',
      border: '0.1rem solid',
      borderRadius: '0.4rem',
      borderColor: 'buttonBorder',
      color: 'textPrimary',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        bg: 'neutral300',
        color: 'black',
        borderColor: 'neutral500',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'outline': {
      bg: 'transparent',
      color: 'textPrimary',
      borderRadius: '0.4rem',
      borderColor: 'buttonBorder',
      _disabled: {
        bg: 'buttonBackgroundDisabled',
      },
      _hover: {
        'bg': 'buttonBackgroundAlt',
        'color': 'buttonTextHover',
        'borderColor': 'buttonBorderAlt',
        '_disabled': {
          bg: 'buttonBackgroundDisabled',
        },
        '.delete-icon': {
          color: 'buttonTextHover',
        },
      },
      sx: {
        '.delete-icon': {
          color: 'buttonBackgroundAlt',
        },
      },
    },
    'outline-positive': {
      bg: 'transparent',
      border: '1px solid',
      borderRadius: '0.4rem',
      borderColor: 'buttonBorder',
      color: 'textPrimary',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        bg: 'primary500',
        color: 'white',
        borderColor: 'primary500',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'outline-negative': {
      bg: 'transparent',
      borderRadius: '0.4rem',
      marginTop: '0.4rem',
      border: '1px solid',
      borderColor: 'white',
      color: 'white',
      padding: '1rem 2rem 1rem 27px',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        bg: 'secondary200',
        borderColor: 'secondary200',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'dashboard-negative': {
      bg: 'transparent',
      borderRadius: '0.4rem',
      marginTop: '0px',
      border: 'none',
      color: 'white',
      padding: '1rem 2rem',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        textDecoration: 'underline',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'dashboard-negative-selected': {
      bg: 'secondary200',
      marginTop: '0px',
      border: 'none',
      borderRadius: '0px',
      color: 'white',
      padding: '1rem 2rem',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        bg: 'secondary200',
        borderColor: 'secondary200',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'outline-forAndBack': {
      bg: 'transparent',
      color: 'black',
      borderRadius: '0.4rem',
      marginTop: '0.4rem',
      border: '1px solid black',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        bg: 'primary400',
        color: 'white',
        border: 'none',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'outline-secondary-disabled': {
      bg: 'transparent',
      borderRadius: '0.4rem',
      border: '1px solid',
      borderColor: 'black',
      color: 'black',
      padding: '0.7rem 2.5rem',
      margin: 0,
      boxSizing: 'border-box',
      fontWeight: '600',
      fontSize: '1.4rem',
      cursor: 'pointer',
      opacity: '0.5',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'primary500',
        color: 'white',
        borderColor: 'primary500',
      },
    },
    'primary': {
      bg: 'primary500',
      color: 'white',
      padding: '1rem 1.6rem 1rem 15px',
      _disabled: {
        opacity: '0.5',
      },
      _hover: {
        bg: 'primary400',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'secondary': {
      bg: 'secondary500',
      color: 'white',
      padding: '1.1rem',
      height: '3.3rem',
      _disabled: {
        opacity: '0.5',
      },
      _hover: {
        bg: 'secondary400',
        _disabled: {
          bg: 'secondary200',
        },
      },
    },
    'chart-button': {
      bg: 'white',
      color: 'black',
      padding: '1rem 11px',
      border: '1px solid',
      borderColor: '#E9ECF0',
      _focus: {
        bg: 'white',
        border: '1px solid #000000',
      },
      _hover: {
        bg: '#FE9ECF0',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'primary-home': {
      bg: 'primary500',
      color: 'white',
      padding: '2rem 2rem 2rem 2rem',
      _disabled: {
        opacity: '0.5',
      },
      _hover: {
        bg: 'primary400',
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'trigger': {
      bg: 'none',
      border: 'none',
      padding: 0,
    },
    'treeview-icon-bar': {
      color: 'black',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'treeview-icon-bar-delete': {
      color: 'danger500',
      _disabled: {
        bg: 'primary200',
      },
      _hover: {
        _disabled: {
          bg: 'primary200',
        },
      },
    },
    'success-toast': {
      color: 'secondary500',
    },
    'error-toast': {
      color: 'secondary500',
    },
    'warning-toast': {
      color: 'secondary500',
    },
    'excel-button': {
      background: 'tansparent',
      border: '1px solid',
      borderColor: 'neutral300',
      borderRadius: '0.4rem',
      color: 'black',
      _hover: {
        color: 'success600',
      },
      _disabled: {
        color: 'neutral400',
      },
    },
    'print-button': {
      background: 'tansparent',
      border: '1px solid',
      borderColor: 'neutral300',
      borderRadius: '0.4rem',
      color: 'black',
      _hover: {
        color: 'primary500',
      },
      _disabled: {
        color: 'neutral400',
      },
    },
    'upload-image': {
      background: 'tansparent',
      border: '1px solid',
      borderColor: 'neutral500',
      borderRadius: '0.4rem',
      width: '54.2rem',
      height: '3.5rem',
      color: 'black',
      _hover: {
        color: 'primary500',
      },
      _disabled: {
        color: 'neutral400',
      },
    },
    'transparent-button': {
      background: 'none',
      color: 'black',
      _hover: {
        background: 'neutral50',
      },
    },
  },
  defaultProps: {
    variant: 'primary',
  },
};
