import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const Tabs: ComponentMultiStyleConfig = {
  parts: ['root', 'tab', 'tablist', 'tabpanel'],
  variants: {
    base: {},
    form: {
      tab: {
        borderRadius: '0.4rem',
        color: 'white',
        fontWeight: '600',
        justifyContent: 'left',
        padding: '1.2rem 5px 1.2rem 1.2rem',
        position: 'relative',
        textAlign: 'left',
        _selected: {
          background: 'primary500',
          boxShadow: 'none',
          color: 'white',
          _after: {
            border: 'solid transparent',
            borderColor: 'transparent',
            borderLeftColor: 'primary500',
            borderWidth: '5px',
            content: '""',
            height: 0,
            left: '100%',
            marginTop: '-5px',
            pointerEvents: 'none',
            position: 'absolute',
            top: '50%',
            width: 0,
          },
        },
        _active: {
          background: 'primary500',
          boxShadow: 'none',
          color: 'white',
        },
      },
      tablist: {
        padding: '1rem',
      },
      tabpanel: {
        padding: 0,
      },
    },
    shorter: {
      tab: {
        borderRadius: '0.4rem',
        color: 'white',
        fontWeight: '400',
        fontSize: '14px',
        justifyContent: 'left',
        padding: '5px 5px 5px 1.2rem',
        position: 'relative',
        textAlign: 'left',
        _selected: {
          background: 'primary500',
          boxShadow: 'none',
          color: 'white',
          fontWeight: 'semibold',
          _after: {
            border: 'solid transparent',
            borderColor: 'transparent',
            borderLeftColor: 'primary500',
            borderWidth: '5px',
            content: '""',
            height: 0,
            left: '100%',
            marginTop: '-5px',
            pointerEvents: 'none',
            position: 'absolute',
            top: '50%',
            width: 0,
            fontWeight: 'semibold',
          },
          _hover: {
            background: 'primary500',
            boxShadow: 'none',
            color: 'white',
          },
        },
        _active: {
          background: 'primary500',
          boxShadow: 'none',
          color: 'white',
        },
        _disabled: {
          opacity: '1',
          cursor: 'pointer',
        },
      },

      tablist: {
        padding: '5px 1rem 0 1rem',
        marginBottom: 0,
      },
      tabpanel: {
        padding: 0,
        marginBottom: 0,
      },
    },
  },
  sizes: {
    sm: {
      tab: {
        fontSize: '1.4rem',
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'base',
  },
};
