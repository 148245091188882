export const roundDecimals = (num: string | number, decimals: number): number => {
  if (typeof num === 'string') {
    const formatedValue = num.replaceAll(',', '.');
    const result = Number(parseFloat(formatedValue).toFixed(decimals));
    return result;
  }
  if (typeof num === 'number') {
    return Number(num.toFixed(decimals));
  }
  return num;
};
