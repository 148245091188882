import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IAccount, IFetchWithEtagParams } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const ACCOUNTS_TAB_ID = 'accounts';

interface IAccountsTabParams {
  title?: string;
}

export const addHomeAccountTab = (accountTab?: IAccountsTabParams) =>
  addTab({
    tab: {
      id: ACCOUNTS_TAB_ID,
      title: accountTab?.title ?? intl.formatMessage({ id: 'accounts.tabTitle' }),
      path: 'pages/AccountsPlans/AccountsPlans',
      isRemovable: true,
    },
  });

// Simple actions
export const clearAccount = createAction('ACCOUNTS/CLEAR');

export const setAccount = createAction<IAccount>('ACCOUNTS/SET');

export const setAccounts = createAction<IAccount[]>('ACCOUNTS/SET_LIST');

export const updateAccountList = createAction<IAccount>('ACCOUNTS/UPDATE_LIST');

export const clearAccountDraft = createAction(`ACCOUNTS/CLEAR_DRAFT`);

export const setAccountDraft = createAction<IAccount>('ACCOUNTS/SET_DRAFT');

export const setAccountDraftValues = createAction<Partial<IAccount>>('ACCOUNTS/SET_DRAFT_VALUE');

export const resetAccountSubmit = createAction('ACCOUNTS/RESET_SUBMIT');

export const setAccountSubmitting = createAction('ACCOUNTS/SET_SUBMITTING');

export const setAccountSubmitError = createAction('ACCOUNTS/SET_SUBMIT_ERROR');

export const setAccountSubmitSuccess = createAction('ACCOUNTS/SET_SUBMIT_SUCCESS');

//Complex actions
export const removeAccountFromList = createAction<number>('ACCOUNTS/REMOVE_FROM_LIST');

export const fetchAccountsList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'ACCOUNTS/FETCH_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(startRequest('accounts'));
      }

      const { data } = await api.accounts.fetchAccountsFromAPI();
      dispatch(setAccounts(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'accounts',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('accounts'));
      }
    }
  },
);

export const fetchAccount = createAsyncThunk<void, number, AppThunkConfig>(
  'ACCOUNTS/FETCH',
  async (accountId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('accounts'));
    try {
      const { data } = await api.accounts.fetchAccountFromAPI(accountId);

      dispatch(setAccount(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'accounts',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('accounts'));
    }
  },
);

export const createAccount = createAsyncThunk<void, IAccount, AppThunkConfig>(
  'ACCOUNTS/CREATE',
  async (account, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('accounts'));
    dispatch(setAccountSubmitting());
    try {
      const { data } = await api.accounts.createAccountFromAPI(account);

      dispatch(setAccountDraft(data));
      dispatch(updateAccountList(data));
      dispatch(setAccountSubmitSuccess());

      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'accounts.successToast.save' }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'accounts',
          action: 'save',
        }),
      );
      dispatch(setAccountSubmitError());
    } finally {
      dispatch(endRequest('accounts'));
    }
  },
);

export const updateAccount = createAsyncThunk<void, IAccount, AppThunkConfig>(
  'ACCOUNTS/UPDATE',
  async (account, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('accounts'));
    dispatch(setAccountSubmitting());
    try {
      const { data } = await api.accounts.updateAccountFromAPI(account);

      dispatch(setAccountDraft(data));
      dispatch(updateAccountList(data));
      dispatch(setAccountSubmitSuccess());

      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'accounts.successToast.save' }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'accounts',
          action: 'save',
        }),
      );
      dispatch(setAccountSubmitError());
    } finally {
      dispatch(endRequest('accounts'));
    }
  },
);

export const deleteAccount = createAsyncThunk<void, number, AppThunkConfig>(
  'ACCOUNTS/DELETE',
  async (accountId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('accounts'));
    try {
      await api.accounts.deleteAccountFromAPI(accountId);

      dispatch(clearAccount());
      dispatch(removeAccountFromList(accountId));

      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'accounts.successToast.delete' }),
          message: '',
          type: 'success',
        }),
      );
      dispatch(addHomeAccountTab());
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'accounts',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('accounts'));
    }
  },
);
