import { useEffect } from 'react';

import { ColorMode, useColorMode } from '@chakra-ui/react';

export interface ThemeColorModeProps {
  onChange?: (colorMode: ColorMode) => void;
}

export const ThemeColorMode = ({ onChange }: ThemeColorModeProps) => {
  const { colorMode } = useColorMode();

  useEffect(() => {
    onChange?.(colorMode);
  }, [colorMode, onChange]);

  return null;
};
