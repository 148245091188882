import { AxiosResponse } from 'axios';

import { IGlobalCompany } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

const DEFAULT_USER_COMPANY_RIGHTS = {
  rights: {
    read: true,
    write: true,
  },
};

export function getCompaniesListAPI(): Promise<AxiosResponse<IGlobalCompany[]>> {
  return axios.post(`${VITE_API_URL}/accounts/security/glcompanies/list`);
}

export function getGlobalCompanyById(glCompanyId: number): Promise<AxiosResponse<IGlobalCompany>> {
  return axios.get(`${VITE_API_URL}/accounts/security/glcompanies/${glCompanyId}`, {
    headers: {
      Accept: 'application/json',
    },
  });
}

export function getCompaniesListByUserAPI(
  glUserId: number,
): Promise<AxiosResponse<IGlobalCompany[]>> {
  return axios.get(`${VITE_API_URL}/accounts/security/glusers/${glUserId}/glCompanies`, {
    headers: {
      Accept: 'application/json',
    },
  });
}

export function addCompanyToGlobalUserAPI(
  glUserId: number,
  glCompanyId: number,
): Promise<AxiosResponse<IGlobalCompany>> {
  return axios.post(
    `${VITE_API_URL}/accounts/security/glusers/${glUserId}/glCompanies/${glCompanyId}`,
    DEFAULT_USER_COMPANY_RIGHTS,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
}

export function deleteCompanyFromGlobalUserAPI(
  glUserId: number,
  glCompanyId: number,
): Promise<AxiosResponse> {
  return axios.delete(
    `${VITE_API_URL}/accounts/security/glusers/${glUserId}/glCompanies/${glCompanyId}`,
  );
}
