import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IGlobalCompaniesState } from './reducer';

export const getGlobalCompaniesSlice = (state: AppState): IGlobalCompaniesState =>
  state.globalCompanies;

export const getGlobalCompany = createSelector([getGlobalCompaniesSlice], (state) => state.current);

export const getGlobalCompanyDraft = createSelector(
  [getGlobalCompaniesSlice],
  (state) => state.draft,
);

export const getGlobalCompaniesList = createSelector(
  [getGlobalCompaniesSlice],
  (state) => state.list,
);

export const getSortedGlobalCompaniesList = createSelector(
  [getGlobalCompaniesList],
  (globalCompaniesList) => {
    return [...globalCompaniesList].sort((companyA, companyB) =>
      companyA.name.localeCompare(companyB.name),
    );
  },
);
