import { createSelector } from '@reduxjs/toolkit';

import { AppState, ITabsState } from '../..';

export const getTabsSlice = (state: AppState): ITabsState => state.tabs;

export const getTabsList = createSelector([getTabsSlice], (state) => state?.list);

export const getSelectedTab = createSelector([getTabsSlice], (state) => state?.selectedTab);

export const getSelectedTabIndex = createSelector([getTabsSlice], (state) =>
  state.list.findIndex((tab) => tab.id === state.selectedTab?.id),
);
