import { ComponentMultiStyleConfig } from '@chakra-ui/react';

import { colors } from '@laudus/shared-ui';

export const Modal: ComponentMultiStyleConfig = {
  parts: ['body', 'footer', 'dialog', 'header', 'closeButton'],
  sizes: {
    '2.5xl': { dialog: { margin: '45px 45px 45px 45px' } },
    '3xl': { dialog: { margin: '142px 445px 142px 445px' } },
  },
  variants: {
    message: {
      dialog: {
        bg: colors.white,
        color: colors.black,
        _dark: {
          bg: colors.darkGrey500,
          color: colors.white,
        },
      },
    },
  },
  defaultProps: {
    size: '2.5xl',
    variant: 'message',
  },
  baseStyle: {
    closeButton: {
      fontSize: '1.2rem',
      width: '32px',
      height: '32px',
      top: '0.5rem',
      right: '0.75rem',
    },
  },
};
