import { AxiosResponse } from 'axios';

import { IEmployeeHistory } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchEmployeesHistoryListFromAPI = (
  employeeHistoryId: number,
): Promise<AxiosResponse<IEmployeeHistory[]>> =>
  axios.get(`${VITE_API_URL}/HR/employees/${employeeHistoryId}/history`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const fetchEmployeeHistoryFromAPI = (
  employeeHistoryId: number,
  historyId: number,
): Promise<AxiosResponse<IEmployeeHistory>> =>
  axios.get(`${VITE_API_URL}/HR/employees/${employeeHistoryId}/history/${historyId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createEmployeeHistoryFromAPI = (
  employeeHistoryId: number,
  history: IEmployeeHistory,
): Promise<AxiosResponse<IEmployeeHistory>> =>
  axios.post(`${VITE_API_URL}/HR/employees/${employeeHistoryId}/history`, history);

export const updateEmployeeHistoryFromAPI = (
  employeeHistoryId: number,
  history: IEmployeeHistory,
): Promise<AxiosResponse<IEmployeeHistory>> => {
  return axios.put(
    `${VITE_API_URL}/HR/employees/${employeeHistoryId}/history/${history.employeeHistoryId}`,
    history,
  );
};

export const deleteEmployeeHistoryFromAPI = (
  employeeHistoryId: number,
  historyId: number,
): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/HR/employees/${employeeHistoryId}/history/${historyId}`);
