import { AxiosResponse } from 'axios';

import { ICarrier } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchCarriersListAPI = (): Promise<AxiosResponse<ICarrier[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/carriers/list`,
    {
      fields: ['name', 'carrierId', 'legalName', 'address'],
      orderBy: [{ field: 'name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchCarrierAPI = (carrierId: string): Promise<AxiosResponse<ICarrier>> =>
  axios.get(`${VITE_API_URL}/sales/carriers/${carrierId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createCarrierAPI = (carrierId: ICarrier): Promise<AxiosResponse<ICarrier>> =>
  axios.post(`${VITE_API_URL}/sales/carriers`, carrierId);

export const updateCarrierAPI = (carrier: ICarrier): Promise<AxiosResponse<ICarrier>> =>
  axios.put(`${VITE_API_URL}/sales/carriers/${carrier.carrierId}`, carrier);

export const deleteCarrierAPI = (carrierId: string): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/carriers/${carrierId}`);
