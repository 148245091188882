import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IStagesState } from './reducer';

export const getStagesSlice = (state: AppState): IStagesState => state.stages;

export const getStagesList = createSelector([getStagesSlice], (state) => state.list);

export const getStage = createSelector([getStagesSlice], (state) => state.current);

export const getStageDraft = createSelector([getStagesSlice], (state) => state.draft);
