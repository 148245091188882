import { useRef, useState } from 'react';

import { chakra, Flex, FormControl, Input } from '@chakra-ui/react';
import { Field, FieldProps } from 'formik';

import { IconArrowDown, IconArrowUp } from '@laudus/icons';
import { colors, CustomNumberInput } from '@laudus/shared-ui';
import { debounce } from '@laudus/shared-utils';

import { RESET_LOCAL_VALUE_DEBOUNCE_MS } from '../constants';

import { FormLabel } from './FormLabel';

export const StyledStepper = chakra('button', {
  baseStyle: {
    borderRadius: '0.3rem',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    transitionProperty: 'common',
    transitionDuration: 'normal',
    userSelect: 'none',
    cursor: 'pointer',
    lineHeight: 'normal',
    backgroundColor: 'neutral300',
    maxHeight: '1.4rem',
    _hover: {
      backgroundColor: 'neutral400',
    },
    _disabled: {
      backgroundColor: 'neutral300',
      cursor: 'not-allowed',
      opacity: '0.4',
    },
  },
});

export interface INumberFieldProps {
  // TODO: add prop to indicate input should round values
  arrowControls?: boolean;
  arrowControlsWidth?: string;
  direction?: 'row' | 'row-reverse' | 'column' | 'column-reverse';
  disabled?: boolean;
  elipsis?: boolean;
  justifyContent?: string;
  label?: string;
  labelStyles?: React.CSSProperties;
  suffix?: string;
  suffixStyles?: React.CSSProperties;
  horizontalSpacing?: string;
  maxValue?: number;
  minValue?: number;
  name: string;
  returnNull?: boolean;
  returnZero?: boolean;
  tooltipText?: string;
  precision?: number;
  textAlign?: 'left' | 'right' | 'center' | 'justify';
  setFieldValue?: (name: string, value: number | undefined | null) => void;
  style?: React.CSSProperties;
  thousandsSeparator?: boolean;
}

export const NumberField = ({
  arrowControls = true,
  arrowControlsWidth = '4rem',
  direction = 'row',
  disabled,
  elipsis = true,
  label,
  labelStyles,
  suffix,
  suffixStyles,
  maxValue,
  minValue,
  horizontalSpacing,
  name,
  returnNull = false,
  returnZero = false,
  tooltipText,
  precision = 0,
  textAlign = 'right',
  justifyContent = 'flex-start',
  setFieldValue = () => undefined,
  style,
  thousandsSeparator = true,
}: INumberFieldProps) => {
  const ref = useRef<HTMLInputElement>(null);

  const [localValue, setLocalValue] = useState<string | undefined>();
  const resetLocalValue = () => {
    setLocalValue(undefined);
  };

  const parse = (val: string) => {
    if (val === '') {
      if (returnNull) {
        return null;
      }
      if (returnZero) {
        return Number(val);
      }
      if (!returnNull && !returnZero) {
        return 0;
      }
    } else {
      return parseFloat(val);
    }
  };

  const handleBlur = () => {
    if (localValue !== undefined) {
      setFieldValue(name, localValue ? parse(localValue) : null);
      debounce(resetLocalValue, RESET_LOCAL_VALUE_DEBOUNCE_MS);
    }
  };

  return (
    <Field isDisabled={disabled} name={name} type="text">
      {({ field }: FieldProps) => {
        const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
          setLocalValue(event.target.value);
        };

        return (
          <FormControl>
            <Flex
              alignItems={direction === 'column' ? 'flex-start' : 'center'}
              direction={{ base: 'column', md: direction }}
              justifyContent={justifyContent}
              width="100%"
              gap={direction === 'column' ? 0 : '0.5rem'}
            >
              {label && (
                <span style={labelStyles}>
                  <FormLabel
                    {...{
                      label,
                      elipsis,
                      tooltipText,
                      variant: direction === 'column' ? 'small' : 'medium',
                    }}
                  />
                </span>
              )}
              <Flex direction="row" minWidth={direction === 'column' ? '100%' : '10.8rem'}>
                <Input
                  {...field}
                  _focus={{
                    'borderWidth': '0.2rem',
                    'borderColor': 'primary500',
                    'caretColor': 'primary500',
                    '+.chakra-select__icon-wrapper': {
                      color: 'error.100',
                    },
                  }}
                  _disabled={{ color: colors.neutral500 }}
                  as={CustomNumberInput}
                  height="3.5rem"
                  isDisabled={disabled}
                  max={maxValue}
                  min={minValue}
                  onBlur={handleBlur}
                  onChange={handleFieldChange}
                  padding="0.8rem"
                  marginLeft={horizontalSpacing}
                  precision={precision}
                  ref={ref}
                  style={{
                    ...style,
                    width: direction === 'column' ? '100%' : '5.6rem',
                  }}
                  textAlign={textAlign}
                  thousandsSeparator={thousandsSeparator}
                  _dark={{
                    backgroundColor: colors.darkGrey500,
                    border: `0.05rem solid ${colors.darkGrey200}`,
                    color: colors.white,
                    _selected: {
                      border: `0.05rem solid ${colors.primary500}`,
                    },
                    _focus: {
                      border: `0.05rem solid ${colors.primary500}`,
                    },
                    _disabled: { color: colors.secondary100 },
                  }}
                />
                {arrowControls && (
                  <Flex
                    direction="column"
                    height="3.5rem"
                    justifyContent="space-around"
                    margin="0 0.1rem 0 1rem"
                    minWidth={arrowControlsWidth}
                    _dark={{ color: colors.white }}
                  >
                    <StyledStepper
                      _dark={{
                        color: colors.white,
                        background: colors.darkGrey300,
                      }}
                      disabled={
                        disabled || (maxValue !== undefined && Number(field.value) >= maxValue)
                      }
                      onPointerDown={(event) => {
                        if (!disabled) {
                          ref.current?.focus();
                          event.preventDefault();
                          ref.current?.stepUp();
                        }
                      }}
                      type="button"
                    >
                      <IconArrowUp size={20} />
                    </StyledStepper>
                    <StyledStepper
                      _dark={{ color: colors.white, background: colors.darkGrey300 }}
                      disabled={
                        disabled || (minValue !== undefined && Number(field.value) <= minValue)
                      }
                      onPointerDown={(event) => {
                        if (!disabled) {
                          ref.current?.focus();
                          event.preventDefault();
                          ref.current?.stepDown();
                        }
                      }}
                      type="button"
                    >
                      <IconArrowDown size={20} />
                    </StyledStepper>
                  </Flex>
                )}
              </Flex>
              {suffix && (
                <span style={suffixStyles}>
                  <FormLabel
                    {...{
                      label: suffix,
                      elipsis,
                      variant: direction === 'column' ? 'small' : 'medium',
                    }}
                  />
                </span>
              )}
            </Flex>
          </FormControl>
        );
      }}
    </Field>
  );
};
