import { useState } from 'react';

import { Button } from '@chakra-ui/react';

import { Form, Formik, FormikProps } from '@laudus/formik';
import { useIntl } from '@laudus/intl';
import { clearAuthError, updatePassword, useGlobalDispatch } from '@laudus/redux-global';

import { LOGIN_PATH } from '../../../router/constants';
import { PasswordInput } from '../../../shared/components/PasswordInput';
import { NewPasswordSchema } from '../schemas';

interface PasswordRequestUpdateFormValues {
  password: string;
  confirmPassword: string;
}
interface INewPasswordFormProps {
  formError: string;
  loginNameParam: string;
  tokenParam: string;
}

export const NewPasswordForm = ({
  formError,
  loginNameParam,
  tokenParam,
}: INewPasswordFormProps) => {
  const intl = useIntl();
  const dispatch = useGlobalDispatch();

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const handleSubmit = async ({ password }: PasswordRequestUpdateFormValues) => {
    dispatch(clearAuthError());
    dispatch(
      updatePassword({
        loginName: loginNameParam,
        resetToken: tokenParam,
        newPassword: password,
        redirectToSuccessPath: LOGIN_PATH,
      }),
    );
  };

  return (
    <Formik
      initialValues={{ password: '', confirmPassword: '' }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={NewPasswordSchema}
    >
      {({ handleChange, errors, isSubmitting }: FormikProps<PasswordRequestUpdateFormValues>) => {
        return (
          <Form>
            <PasswordInput
              error={errors.confirmPassword}
              formError={formError}
              handleIconClick={handleClick}
              id="password"
              label={intl.formatMessage({
                id: 'resetPassword.input.label.password',
              })}
              onChange={handleChange}
              showPassword={show}
            />
            <PasswordInput
              error={errors.confirmPassword}
              formError={formError}
              handleIconClick={handleClick}
              id="confirmPassword"
              label={intl.formatMessage({
                id: 'resetPassword.input.label.repeatPassword',
              })}
              onChange={handleChange}
              showPassword={show}
            />
            <Button
              isLoading={isSubmitting}
              mb={{ base: '10', md: '1rem' }}
              mt={{ base: '5' }}
              type="submit"
              variant="login"
            >
              {intl.formatMessage({
                id: 'resetPassword.button.requestNewPassword',
              })}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
