export const KEY_ESC = 'Escape';

export const KEY_LEFT = 'ArrowLeft';

export const KEY_UP = 'ArrowUp';

export const KEY_RIGHT = 'ArrowRight';

export const KEY_DOWN = 'ArrowDown';

export const KEY_PAGE_UP = 'PageUp';

export const KEY_PAGE_DOWN = 'PageDown';

export const KEY_COMMA = ',';

export const KEY_ENTER = 'Enter';

export const KEY_HOME = 'Home';

export const KEY_END = 'End';

export const KEY_TAB = 'Tab';

export const KEY_BACKSPACE = 'Backspace';

export const KEY_SPACE = 'Space';

export const KEY_SPACE_ALT = ' ';

export const KEY_DELETE = 'Delete';
