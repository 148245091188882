import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { InfraServices } from '@laudus/services';
import { ISettingsDTECertificate, ISettingsDTECertificateInfo } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';

export const clearDTECertificate = createAction('USERS_CERTIFICATE/CLEAR');

export const clearDTECertificateDraft = createAction('USERS_CERTIFICATE/CLEAR_DRAFT');

export const setDTECertificateInfo = createAction<ISettingsDTECertificateInfo>(
  'USERS_CERTIFICATE/SET_DTE_CERTIFICATE_INFO',
);

export const setDTECertificate = createAction<ISettingsDTECertificate>(
  'USERS_CERTIFICATE/SET_DTE_CERTIFICATE',
);

export const setDTECertificateImport = createAction<any>(
  'USERS_CERTIFICATE/SET_DTE_CERTIFICATE_IMPORT',
);

export const fetchSettingsDTECertificate = createAsyncThunk<
  void,
  { showErrorOnFail: boolean },
  AppThunkConfig
>('USERS_CERTIFICATE/FETCH_USER_CERTIFICATE', async ({ showErrorOnFail }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('system-fetch-dte-certificate'));
  try {
    const { data } = await api.systems.fetchSettingsDTECertificateFromAPI();
    dispatch(setDTECertificateInfo(data));
  } catch (error) {
    if (showErrorOnFail) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'DTECertificate',
          action: 'read',
        }),
      );
    }
  } finally {
    dispatch(endRequest('system-fetch-dte-certificate'));
  }
});

export const createSettingsDTECertificates = createAsyncThunk<
  void,
  ISettingsDTECertificate,
  { extra: InfraServices }
>('SYSTEM/CREATE_DTE_CERTIFICATE', async (DTECertificate, ThunkAPI) => {
  const { extra, dispatch } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('system-create-dte-certificate'));
  try {
    await api.systems.createSettingsDTECertificatesFromAPI(DTECertificate);
    dispatch(
      showAlert({
        type: 'success',
        title: intl.formatMessage({ id: 'DTECertificate.success.create' }),
        message: intl.formatMessage({
          id: 'DTECertificate.success.message',
        }),
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'DTECertificate',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('system-create-dte-certificate'));
  }
});
