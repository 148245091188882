import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString, getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  IPurchasePayment,
  IPurchasePaymentsInvoices,
  IPurchasePaymentsOtherDocuments,
} from '@laudus/types';

import {
  clearPurchasePayment,
  clearPurchasePaymentDraft,
  clearPurchasePaymentOtherDocumentDraft,
  duplicatePurchasePayment,
  removePurchasePaymentFromList,
  setPurchasePayment,
  setPurchasePaymentDraft,
  setPurchasePaymentDraftValues,
  setPurchasePaymentInvoicesDraft,
  setPurchasePaymentInvoicesDraftValues,
  setPurchasePaymentIsEditing,
  setPurchasePaymentList,
  setPurchasePaymentOtherDocumentDraft,
  setPurchasePaymentOtherDocumentDraftValues,
  updatePurchasePaymentList,
} from './actions';

export const PURCHASES_PAYMENTS_TAB_ID = 'purchasesPayments';

export interface IPurchasePaymentState {
  current: IPurchasePayment;
  draft: IPurchasePayment;
  list: IPurchasePayment[];
  otherDocumentDraft: IPurchasePaymentsOtherDocuments;
  purchasesInvoicesDraft: IPurchasePaymentsInvoices;
  editing: boolean;
}

export const PURCHASES_PAYMENTS_LIST_EMPTY = [];

export const PURCHASE_PAYMENT_EMPTY: IPurchasePayment = {
  paymentId: 0,
  paymentType: {
    code: '',
    description: '',
  },
  issuedDate: dateToLocalISOString(new Date()),
  dueDate: dateToLocalISOString(new Date()),
  document: '',
  deposited: false,
  bank: {
    bankId: '',
    name: '',
  },
  pos: {
    posId: 0,
    name: '',
  },
  noteOfCredit: null,
  accountOther: {
    accountId: 0,
    accountNumber: '',
    name: '',
  },
  createAccounting: false,
  journalEntryIssued: {
    journalEntryId: 0,
    journalEntryNumber: 0,
    type: '',
  },
  journalEntryDeposited: {
    journalEntryId: 0,
    journalEntryNumber: 0,
    type: '',
  },
  notes: '',
  createdBy: {
    userId: '',
    name: '',
  },
  createdAt: '',
  modifiedBy: {
    userId: '',
    name: '',
  },
  modifiedAt: '',
  advanceToRenderRecipient: {
    relatedId: 0,
    type: '',
    name: '',
    VATId: '',
  },
  purchaseInvoices: [],
  otherDocuments: [],
};

export const PURCHASE_PAYMENT_OTHER_DOCUMENT_EMPTY: IPurchasePaymentsOtherDocuments = {
  itemId: 0,
  document: '',
  description: '',
  relatedTo: {
    relatedId: 0,
    type: '',
    name: '',
    VATId: '',
  },
  account: {
    accountId: 0,
    accountNumber: '',
    name: '',
  },
  category: {
    code: '',
    description: '',
  },
  originalAmount: 0,
  currencyCode: 'CLP',
  parityToMainCurrency: 1,
  amount: 0,
  costCenter: null,
};

export const PURCHASE_PAYMENT_PURCHASE_INVOICE_EMPTY: IPurchasePaymentsInvoices = {
  itemId: 0,
  purchaseInvoiceId: 0,
  docType: null,
  docNumber: 0,
  supplier: null,
  originalAmount: 0,
  currencyCode: 'CLP',
  parityToMainCurrency: 1,
  amount: 0,
  costCenter: null,
};

export const initialPurchasePaymentState: IPurchasePaymentState = {
  current: PURCHASE_PAYMENT_EMPTY,
  draft: PURCHASE_PAYMENT_EMPTY,
  list: [],
  otherDocumentDraft: PURCHASE_PAYMENT_OTHER_DOCUMENT_EMPTY,
  purchasesInvoicesDraft: PURCHASE_PAYMENT_PURCHASE_INVOICE_EMPTY,
  editing: false,
};

export const purchasePaymentReducer = createReducer(initialPurchasePaymentState, (builder) => {
  builder
    .addCase(clearPurchasePayment, (state) => {
      return { ...state, current: PURCHASE_PAYMENT_EMPTY, error: undefined };
    })
    .addCase(clearPurchasePaymentDraft, (state) => {
      return { ...state, draft: PURCHASE_PAYMENT_EMPTY, error: undefined };
    })
    .addCase(clearPurchasePaymentOtherDocumentDraft, (state) => {
      return {
        ...state,
        otherDocumentDraft: PURCHASE_PAYMENT_OTHER_DOCUMENT_EMPTY,
      };
    })
    .addCase(setPurchasePayment, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setPurchasePaymentDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setPurchasePaymentDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setPurchasePaymentOtherDocumentDraft, (state, action) => {
      return { ...state, otherDocumentDraft: action.payload };
    })
    .addCase(setPurchasePaymentOtherDocumentDraftValues, (state, action) => {
      return {
        ...state,
        otherDocumentDraft: {
          ...state.otherDocumentDraft,
          ...action.payload,
        },
      };
    })
    .addCase(setPurchasePaymentInvoicesDraft, (state, action) => {
      return { ...state, purchasesInvoicesDraft: action.payload };
    })
    .addCase(setPurchasePaymentInvoicesDraftValues, (state, action) => {
      return {
        ...state,
        purchasesInvoicesDraft: {
          ...state.purchasesInvoicesDraft,
          ...action.payload,
        },
      };
    })
    .addCase(setPurchasePaymentList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updatePurchasePaymentList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.paymentId !== action.payload.paymentId),
          action.payload,
        ],
      };
    })
    .addCase(removePurchasePaymentFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.paymentId !== action.payload),
      };
    })
    .addCase(duplicatePurchasePayment, (state, action) => {
      const { paymentId, ...duplicatedPurchasePayment } = action.payload;
      const duplicatedPurchasesInvoices = duplicatedPurchasePayment.purchaseInvoices
        ? [...duplicatedPurchasePayment.purchaseInvoices].map((purchaseInvoices) => {
            return {
              ...purchaseInvoices,
              itemId: getNegativeUniqueNumericId(),
            };
          })
        : [];
      const duplicatedOtherDocuments = duplicatedPurchasePayment.otherDocuments
        ? [...duplicatedPurchasePayment.otherDocuments].map((otherDocuments) => {
            return {
              ...otherDocuments,
              itemId: getNegativeUniqueNumericId(),
            };
          })
        : [];
      return {
        ...state,
        draft: {
          ...duplicatedPurchasePayment,
          purchaseInvoices: [...duplicatedPurchasesInvoices],
          otherDocuments: [...duplicatedOtherDocuments],
        },
      };
    })
    .addCase(setPurchasePaymentIsEditing, (state, action) => {
      return { ...state, editing: action.payload };
    })
    .addDefaultCase((state) => state);
});
