import { createReducer } from '@reduxjs/toolkit';
import cloneDeep from 'lodash/cloneDeep';

import { renameKeyPreservingOrder } from '@laudus/shared-utils';
import {
  IBestSellingProductItem,
  IProduct,
  IProductAdvancedSearchFilters,
  IProductList,
  IProductListGridItem,
  IProductListItem,
  IProductStock,
} from '@laudus/types';

import {
  clearProduct,
  clearProductDraft,
  clearProductsDraftList,
  duplicateProduct,
  removeProductFromList,
  removeProductStock,
  renameProductsImportListColumn,
  setProduct,
  setProductDraft,
  setProductDraftValues,
  setProductEditing,
  setProductImage,
  setProductsAdvancedSearchFilters,
  setProductsImportList,
  setProductsImportSucceeded,
  setProductsList,
  setProductsSalesLists,
  setProductStock,
  setProductValues,
  updateProductInDraftList,
  updateProductsList,
} from './actions';
import { PRODUCT_EMPTY } from './constants';

export const PRODUCTS_TAB_ID = 'products';

export interface IProductsState {
  current: IProduct;
  draft: IProduct;
  list: IProductList;
  editing: boolean;
  draftList: IProductList;
  importList: any[];
  importSucceeded: boolean;
  stockList: IProductStock[];
  advancedSearchFilters: IProductAdvancedSearchFilters;
  productsBestSellingList: IBestSellingProductItem[];
  productsGridList: IProductListGridItem[];
}

export const initialProductsState: IProductsState = {
  current: PRODUCT_EMPTY,
  draft: PRODUCT_EMPTY,
  list: [],
  editing: false,
  draftList: [],
  importList: [],
  importSucceeded: false,
  stockList: [],
  advancedSearchFilters: {
    showDiscontinued: false,
  },
  productsBestSellingList: [],
  productsGridList: [],
};

export const productsReducer = createReducer(initialProductsState, (builder) => {
  builder
    .addCase(clearProduct, (state) => {
      return { ...state, current: PRODUCT_EMPTY };
    })
    .addCase(clearProductDraft, (state) => {
      return { ...state, draft: PRODUCT_EMPTY };
    })
    .addCase(setProduct, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setProductDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setProductDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setProductValues, (state, action) => {
      return { ...state, current: { ...state.current, ...action.payload } };
    })
    .addCase(setProductsList, (state, action) => {
      return {
        ...state,
        list: action.payload,
      };
    })
    .addCase(updateProductsList, (state, action) => {
      const {
        productId,
        sku,
        description,
        productCategory,
        unitOfMeasure,
        unitPrice,
        unitPriceWithTaxes,
        discontinued,
        canBeSold,
        canBePurchased,
        barCode,
      } = action.payload;

      const oldProductInList = state.list.find((d) => d.productId === productId);

      if (!oldProductInList) {
        return state;
      }

      const updatedProductListItem: IProductListItem = {
        ...oldProductInList,
        productId: productId ?? 0,
        sku: sku ?? '',
        description: description ?? '',
        productCategory_name: productCategory?.name ?? '',
        unitOfMeasure: unitOfMeasure ?? '',
        unitPrice: unitPrice ?? 0,
        unitPriceWithTaxes: unitPriceWithTaxes ?? 0,
        discontinued,
        canBeSold,
        canBePurchased,
        barCode: barCode ?? '',
      };
      return {
        ...state,
        list: [...state.list.filter((d) => d.productId !== productId), updatedProductListItem],
      };
    })
    .addCase(removeProductFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.productId !== action.payload),
      };
    })
    .addCase(setProductsImportList, (state, action) => {
      return { ...state, importList: action.payload };
    })
    .addCase(renameProductsImportListColumn, (state, action) => {
      const { newColumnName, oldColumnName } = action.payload;
      return {
        ...state,
        importList: state.importList.map((row) =>
          renameKeyPreservingOrder(row, oldColumnName, newColumnName),
        ),
      };
    })
    .addCase(setProductsImportSucceeded, (state, action) => {
      return { ...state, importSucceeded: action.payload };
    })
    .addCase(duplicateProduct, (state, action) => {
      const { productId, ...duplicatedProduct } = action.payload;
      duplicatedProduct.volumeDiscounts = cloneDeep(duplicatedProduct.volumeDiscounts)?.map(
        ({ volumeDiscountId, ...volumeDiscount }) => ({
          volumeDiscountId: 0,
          ...volumeDiscount,
        }),
      );

      duplicatedProduct.suppliers = [];
      return { ...state, draft: duplicatedProduct };
    })
    .addCase(setProductStock, (state, action) => {
      return {
        ...state,
        stockList: [
          ...state.stockList.filter(
            (productStock) => productStock.productId !== action.payload.productId,
          ),
          action.payload,
        ],
      };
    })
    .addCase(removeProductStock, (state, action) => {
      return {
        ...state,
        stockList: [
          ...state.stockList.filter((productStock) => productStock.productId !== action.payload),
        ],
      };
    })
    .addCase(setProductsSalesLists, (state, action) => {
      return {
        ...state,
        list: action.payload.productsList,
        productsBestSellingList: action.payload.productsBestSellingList,
        productsGridList: action.payload.productsList.reduce((acc, product) => {
          acc.push({
            ...product,
            quantity:
              action.payload.productsBestSellingList.find(
                (item) => item.productId === product.productId,
              )?.quantity ?? 0,
          });

          return acc;
        }, [] as IProductListGridItem[]),
      };
    })
    .addCase(setProductImage, (state, action) => {
      const updateProductPictureInfo = <T extends IProductListItem>(item: T): T =>
        item.productId === action.payload.productId
          ? {
              ...item,
              pictures_url: action.payload.picture.url,
              pictures_fileId: action.payload.picture.fileId,
            }
          : item;

      return {
        ...state,
        list: state.list.reduce((acc, item) => {
          acc.push(updateProductPictureInfo(item));
          return acc;
        }, [] as IProductListItem[]),
        productsGridList: state.productsGridList.reduce((acc, item) => {
          acc.push(updateProductPictureInfo(item));
          return acc;
        }, [] as IProductListGridItem[]),
      };
    })
    .addCase(setProductsAdvancedSearchFilters, (state, action) => {
      return {
        ...state,
        advancedSearchFilters: {
          ...state.advancedSearchFilters,
          ...action.payload,
        },
      };
    })
    .addCase(updateProductInDraftList, (state, action) => {
      const { productId, description, unitPrice, unitPriceWithTaxes, sku, unitOfMeasure, barCode } =
        action.payload;
      const isProductInDraftList = state.draftList.some(
        (d) => d.productId === action.payload.productId,
      );

      // TODO: Javier said that he was review the API to send only the fields that are needed to be updated
      const productToSendToBulkUpdate: Partial<IProductListItem> = {
        productId,
        sku,
        description,
        unitPrice,
        unitPriceWithTaxes,
        unitOfMeasure,
        barCode,
      };

      if (!isProductInDraftList) {
        state.draftList.push(productToSendToBulkUpdate as IProductListItem);
      } else {
        return {
          ...state,
          draftList: state.draftList.map((item) =>
            item.productId === productId
              ? {
                  ...item,
                  ...productToSendToBulkUpdate,
                }
              : item,
          ),
        };
      }
    })
    .addCase(clearProductsDraftList, (state) => {
      return {
        ...state,
        draftList: [],
      };
    })
    .addCase(setProductEditing, (state, action) => {
      return {
        ...state,
        editing: action.payload,
      };
    })
    .addDefaultCase((state) => state);
});
