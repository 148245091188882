import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IInvoicesReconciliationState } from './reducer';

export const getInvoicesReconciliationSlice = (state: AppState): IInvoicesReconciliationState =>
  state.invoicesReconciliation;

export const getInvoicesReconciliationFilter = createSelector(
  [getInvoicesReconciliationSlice],
  (state) => state.filter,
);

export const getInvoicesReconciliationInfo = createSelector(
  [getInvoicesReconciliationSlice],
  (state) => state.invoicesReconciliationInfo,
);
