import { AxiosResponse } from 'axios';

import { IJournalEntry } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchJournalEntriesListAPI = (): Promise<AxiosResponse<IJournalEntry[]>> =>
  axios.post(
    `${VITE_API_URL}/accounting/journal/entries/list`,
    {
      fields: [
        'journalEntryNumber',
        'type',
        'typeNumber',
        'date',
        'lines.account.accountNumber',
        'lines.description',
        'lines.debit',
        'lines.credit',
        'lines.document',
        'approved',
        'journalEntryId',
        'lines.costCenter.name',
      ],
      orderBy: [{ field: 'date', direction: 'DESC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchJournalEntryAPI = (
  journalEntryId: number,
): Promise<AxiosResponse<IJournalEntry>> =>
  axios.get(`${VITE_API_URL}/accounting/journal/entries/${journalEntryId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createJournalEntryAPI = (
  journalEntry: IJournalEntry,
): Promise<AxiosResponse<IJournalEntry>> =>
  axios.post(`${VITE_API_URL}/accounting/journal/entries`, journalEntry);

export const updateJournalEntryAPI = (
  journalEntry: IJournalEntry,
): Promise<AxiosResponse<IJournalEntry>> =>
  axios.put(
    `${VITE_API_URL}/accounting/journal/entries/${journalEntry.journalEntryId}`,
    journalEntry,
  );

export const deleteJournalEntryAPI = (journalEntryId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/accounting/journal/entries/${journalEntryId}`);
