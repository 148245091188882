import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, ITerm } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

// Terms Tab action creators
export const addHomeTermTab = () =>
  addTab({
    tab: {
      id: 'term',
      title: intl.formatMessage({ id: 'terms.tabTitle' }),
      path: 'pages/Terms/Terms',
      isRemovable: true,
    },
  });

export const addViewTermTab = (id?: string) =>
  addTab({
    tab: {
      id: 'term',
      title: intl.formatMessage({ id: 'terms.tabTitle' }),
      path: 'pages/Terms/TermView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewTermTab = () =>
  addTab({
    tab: {
      id: 'term',
      title: intl.formatMessage({ id: 'terms.tabTitle' }),
      path: 'pages/Terms/TermNew',
      isRemovable: true,
    },
  });

export const addEditTermTab = () =>
  addTab({
    tab: {
      id: 'term',
      title: intl.formatMessage({ id: 'terms.tabTitle' }),
      path: 'pages/Terms/TermEdit',
      isRemovable: true,
    },
  });

// Terms action creators
export const clearTerm = createAction('TERMS/CLEAR');

export const clearTermDraft = createAction('TERMS/CLEAR_DRAFT');

export const setTerm = createAction<ITerm>('TERMS/SET_TERM');

export const setTermDraft = createAction<ITerm>('TERMS/SET_TERM_DRAFT');

export const setTermDraftValues = createAction<Partial<ITerm>>('TERMS/SET_TERM_DRAFT_VALUE');

export const setTermsList = createAction<ITerm[]>('TERMS/SET_LIST');

export const updateTermList = createAction<ITerm>('TERMS/UPDATE_LIST');

export const removeTermFromList = createAction<string>('TERMS/REMOVE_FROM_LIST');

export const duplicateTerm = createAction<ITerm>('TERMS/DUPLICATE');

export interface IRemoveTermFromListParams {
  termId: string;
}

export const fetchTermsList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'TERMS/FETCH_TERM_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(startRequest('terms'));
      }
      const { data } = await api.terms.fetchTermsListFromAPI();
      dispatch(setTermsList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'term',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('terms'));
      }
    }
  },
);

export const fetchTerm = createAsyncThunk<void, string, AppThunkConfig>(
  'TERMS/FETCH_TERM',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('terms'));
    try {
      const { data } = await api.terms.fetchTermFromAPI(id);
      dispatch(setTerm(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'term',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('terms'));
    }
  },
);
