import { ObjectValues } from '../utilities';

export const GLOBAL_USER_ACCOUNT = {
  FULL: 'F',
  BASIC: 'B',
};

export type GlobalUserAccountType = ObjectValues<typeof GLOBAL_USER_ACCOUNT>;

export interface IGlobalUser {
  glUserId: number;
  loginName: string;
  accountType: GlobalUserAccountType;
  email: string;
  VATId: string;
  glAccountId: number;
  discontinued: boolean;
  createdAt: string;
  isAdmin: boolean;
}
