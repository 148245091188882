import { IPurchaseWaybill } from '@laudus/types';

export const PURCHASES_WAYBILL_EMPTY: IPurchaseWaybill = {
  items: [],
  docType: {
    docTypeId: null,
    name: null,
  },
  docNumber: 0,
  supplier: {
    supplierId: 0,
    name: '',
    legalName: '',
    VATId: '',
  },
  warehouse: null,
  nullDoc: false,
  notes: null,
  description: '',
};
