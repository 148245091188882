import { createReducer } from '@reduxjs/toolkit';

import { IAccount, IFormState } from '@laudus/types';

import {
  clearAccount,
  clearAccountDraft,
  removeAccountFromList,
  resetAccountSubmit,
  setAccount,
  setAccountDraft,
  setAccountDraftValues,
  setAccounts,
  setAccountSubmitError,
  setAccountSubmitSuccess,
  setAccountSubmitting,
  updateAccountList,
} from '.';

const initialFormState = {
  submitting: false,
  submitError: false,
  submitSuccess: false,
};

export interface IAccountsState {
  current: IAccount;
  draft: IAccount;
  list: IAccount[];
  formState: IFormState;
}

export const ACCOUNT_EMPTY: IAccount = {
  accountId: 0,
  accountNumber: '',
  name: '',
  notes: '',
};

export const initialAccountsState: IAccountsState = {
  current: ACCOUNT_EMPTY,
  draft: ACCOUNT_EMPTY,
  list: [],
  formState: initialFormState,
};

export const accountsReducer = createReducer(initialAccountsState, (builder) => {
  builder
    .addCase(clearAccount, (state) => {
      return { ...state, current: ACCOUNT_EMPTY };
    })
    .addCase(setAccount, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setAccounts, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(clearAccountDraft, (state) => {
      return { ...state, draft: ACCOUNT_EMPTY };
    })
    .addCase(setAccountDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setAccountDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(updateAccountList, (state, action) => {
      const list = [
        ...state.list.filter((b) => b.accountId !== action.payload.accountId),
        action.payload,
      ];
      function compare(a: IAccount, b: IAccount) {
        if ((a.accountNumber || '') < (b.accountNumber || '')) {
          return -1;
        }
        if ((a.accountNumber || '') > (b.accountNumber || '')) {
          return 1;
        }
        return 0;
      }
      list.sort(compare);
      return {
        ...state,
        list: list,
      };
    })
    .addCase(removeAccountFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((b) => b.accountId !== action.payload),
      };
    })
    .addCase(resetAccountSubmit, (state) => {
      return { ...state, formState: initialFormState };
    })
    .addCase(setAccountSubmitting, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitting: true },
      };
    })
    .addCase(setAccountSubmitError, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitError: true },
      };
    })
    .addCase(setAccountSubmitSuccess, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitSuccess: true },
      };
    })
    .addDefaultCase((state) => state);
});
