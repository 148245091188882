import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';
import { getSettingsByName } from '../settings';

import { ICurrenciesState } from './reducer';

export const getCurrenciesSlice = (state: AppState): ICurrenciesState => state.currencies;

export const getCurrencyList = createSelector([getCurrenciesSlice], (state) => state.list);

export const getMainCurrency = createSelector(
  [getCurrencyList, getSettingsByName<string>('mainCurrencyId')],
  (currencies, mainCurrencyId) => {
    const mainCurrency = currencies.find((currency) => currency.currencyId === mainCurrencyId);
    const mainCurrencyDecimals = mainCurrency?.decimals ?? 0;
    const mainCurrencyCode = mainCurrency?.code ?? 'CLP';

    return {
      mainCurrency,
      mainCurrencyDecimals,
      mainCurrencyCode,
    };
  },
);

export const getCurrency = createSelector([getCurrenciesSlice], (state) => state.current);

export const getCurrencyDraft = createSelector([getCurrenciesSlice], (state) => state.draft);
