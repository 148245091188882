import { AxiosResponse } from 'axios';

import { IIsapre } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchIsapresListFromAPI = (): Promise<AxiosResponse<IIsapre[]>> =>
  axios.post(
    `${VITE_API_URL}/HR/isapres/list`,
    {
      fields: ['isapreId', 'name', 'legalName', 'VATId', 'previredId', 'electronicBookId', 'notes'],
      orderBy: [{ field: 'name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchIsapreFromAPI = (isapreId: string): Promise<AxiosResponse<IIsapre>> =>
  axios.get(`${VITE_API_URL}/HR/isapres/${isapreId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createIsapreFromAPI = (isapre: IIsapre): Promise<AxiosResponse<IIsapre>> =>
  axios.post(`${VITE_API_URL}/HR/isapres`, isapre);

export const updateIsapreFromAPI = (isapre: IIsapre): Promise<AxiosResponse<IIsapre>> =>
  axios.put(`${VITE_API_URL}/HR/isapres/${isapre.isapreId}`, isapre);

export const deleteIsapreFromAPI = (isapreId: string): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/HR/isapres/${isapreId}`);
