import { createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IShowErrorParams } from '@laudus/redux-global';
import { IEmail, IEmailSalesDocumentDataForm } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { createEmail } from '../systems';

interface ISalesInvoicPDFEmailParams {
  blobURL: string;
  data: IEmailSalesDocumentDataForm;
  onEmailCreated: VoidFunction;
}

export const sendSalesDocumentbyEmail = createAsyncThunk<
  void,
  ISalesInvoicPDFEmailParams,
  AppThunkConfig
>('SALES_DOCUMENT/SEND_BY_EMAIL', async ({ blobURL, data, onEmailCreated }, ThunkAPI) => {
  const { dispatch } = ThunkAPI;

  try {
    const PDFBlob = await fetch(blobURL).then((r) => r.blob());

    const reader = new FileReader();
    if (!PDFBlob) {
      throw new Error(intl.formatMessage({ id: 'invoices.errorToast.sendByEmail' }));
    }
    reader.readAsDataURL(PDFBlob);
    reader.onloadend = () => {
      if (reader.result) {
        const base64data = reader.result.toString().replace('data:', '').replace(/^.+,/, '');

        const fileName = `${data.subject.replace(' ', '-')}.pdf`;

        // TODO: Make template
        const htmlMessage = `
        <html>
          <head>
            <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
            <meta name="viewport; initial-scale=1.0" />
          </head>
          <body style="font-family: Open Sans, sans-serif">
            ${data.message}
          </body>
        </html>`;

        const emailData: IEmail = {
          from: {
            address: 'info@laudus.cl',
            displayName: 'Laudus',
          },
          to: [
            {
              address: data.email,
              displayName: data.email,
            },
          ],
          CC: [
            {
              address: data.cc,
              displayName: data.cc,
            },
          ],
          BCC: [],
          subject: data.subject,
          body: window.btoa(htmlMessage),
          attachments: [
            {
              fileName: fileName,
              fileContents: base64data,
            },
          ],
        };

        dispatch(createEmail(emailData));
        onEmailCreated();
      }
    };
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'salesInvoices',
        action: 'sendByEmail' as IShowErrorParams['action'],
      }),
    );
  }
});
