import { createReducer } from '@reduxjs/toolkit';

import { IEmployee } from '@laudus/types';

import {
  clearEmployee,
  clearEmployeeDraft,
  duplicateEmployee,
  removeEmployeeFromList,
  setEmployee,
  setEmployeeDraft,
  setEmployeeDraftValues,
  setEmployeesList,
  updateEmployeesList,
} from './actions';

export interface IEmployeeState {
  current: IEmployee;
  draft: IEmployee;
  list: Partial<IEmployee>[];
}

export const EMPLOYEE_EMPTY: IEmployee = {
  employeeId: 0,
  firstName: '',
  lastName1: '',
  lastName2: '',
  VATId: '',
  maritalStatus: '',
  gender: '',
  position: {
    code: '',
    description: '',
  },
  costCenter: {
    costCenterId: '',
    name: '',
  },
  department: '',
  email: '',
  workPhone: '',
  extension: '',
  homePhone: '',
  mobile: '',
  address: '',
  county: '',
  zipCode: '',
  city: '',
  state: '',
  birthDate: '',
  contractStartDate: '',
  contractType: '',
  contractTerminationDate: '',
  contractTerminationType: {
    code: '',
    description: '',
  },
  contractExpirationDate: '',
  type: '',
  AFP: {
    AFPId: '',
    previredId: '',
    name: '',
  },
  unemploymentInsuraceAFP: {
    AFPId: '',
    previredId: '',
    name: '',
  },
  healthPlan: {
    isapre: {
      isapreId: '',
      previredId: '',
      name: '',
    },
    planType: '',
    planAmount: 0,
    planAmountUF: 0,
    contractNumber: '',
  },
  exCaja: {
    exCajaId: 0,
    previredId: '',
    name: '',
  },
  savingsPlan: {
    provider: {
      savingsPlansProviderId: '',
      previredId: '',
      name: '',
    },
    planType: '',
    planAmount: 0,
  },
  savingsPlanAPV1: {
    provider: {
      savingsPlansProviderId: '',
      previredId: '',
      name: '',
    },
    planType: '',
    planAmount: 0,
  },
  savingsPlanAPV2: {
    provider: {
      savingsPlansProviderId: '',
      previredId: '',
      name: '',
    },
    planType: '',
    planAmount: 0,
  },
  savingsPlanAgreedDeposits: {
    provider: {
      savingsPlansProviderId: '',
      previredId: '',
      name: '',
    },
    planType: '',
    planAmount: 0,
  },
  savingsPlanGroup: {
    provider: {
      savingsPlansProviderId: '',
      previredId: '',
      name: '',
    },
    planType: '',
    companyAmount: 0,
    employeeAmount: 0,
    contractNumber: '',
  },
  dependentsStandard: 0,
  dependentsPregnancy: 0,
  dependentsWithDisabilities: 0,
  amountPerDepedent: 0,
  paymentType: {
    code: '',
    description: '',
  },
  bank: {
    bankId: '',
    name: '',
  },
  accountNumber: '',
  ePayment: {
    code: '',
    description: '',
  },
  foreigner: false,
  countryOfOrigin: '',
  notes: '',
  createdBy: {
    userId: '',
    name: '',
  },
  createdAt: '',
  modifiedBy: {
    userId: '',
    name: '',
  },
  modifiedAt: '',
  workingDayType: '',
  workingDaysPerMonth: 0,
  workingDaysPerWeek: 0,
  profession: {
    code: '',
    description: '',
  },
  degree: {
    code: '',
    description: '',
  },
  degreeDate: '',
  qualification: {
    code: '',
    description: '',
  },
  contractualSalary: 0,
  hasAccessToWebPortal: true,
  holidaysPerYear: 0,
  sector: {
    code: '',
    description: '',
  },
  restrictedAccess: false,
  heavyWorkRate: 0,
  heavyWorkDescription: '',
  progressiveHolidaysDateFrom: '',
  disabilityType: '',
  youngEmployeeBenefit: false,
  youngEmployeeBenefitDateTo: '',
  union: {
    unionId: 0,
    name: '',
  },
  remuneration: [
    {
      remunerationId: 0,
      concept: {
        remunerationConceptId: '',
        name: '',
      },
      expression: '',
      account: {
        accountId: 0,
        accountNumber: '',
        name: '',
      },
      costCenter: {
        costCenterId: '',
        name: '',
      },
    },
  ],
  customFields: {
    additionalProp1: '',
    additionalProp2: '',
    additionalProp3: '',
  },
};

export const initialEmployeesState: IEmployeeState = {
  current: EMPLOYEE_EMPTY,
  draft: EMPLOYEE_EMPTY,
  list: [],
};

export const employeesReducer = createReducer(initialEmployeesState, (builder) => {
  builder
    .addCase(clearEmployee, (state) => {
      return { ...state, current: EMPLOYEE_EMPTY };
    })
    .addCase(clearEmployeeDraft, (state) => {
      return { ...state, draft: EMPLOYEE_EMPTY };
    })
    .addCase(setEmployee, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setEmployeeDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setEmployeeDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setEmployeesList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateEmployeesList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((b) => b.employeeId !== action.payload.employeeId),
          action.payload,
        ],
      };
    })
    .addCase(removeEmployeeFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((b) => b.employeeId !== action.payload),
      };
    })
    .addCase(duplicateEmployee, (state, action) => {
      const { employeeId, ...duplicatedEmployee } = action.payload;
      return { ...state, draft: duplicatedEmployee };
    })
    .addDefaultCase((state) => state);
});
