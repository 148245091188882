import { createReducer } from '@reduxjs/toolkit';

import { addTab, removeTab, selectTab, updateTabs } from './actions';

export interface ITab {
  icon?: string;
  id: string;
  isRemovable: boolean;
  path?: string;
  props?: Record<string, unknown>;
  title?: string;
}

const homeTab: ITab = {
  path: 'pages/Home/Home',
  props: undefined,
  icon: 'IoMdHome',
  id: 'home',
  isRemovable: false,
  title: 'Home',
};

export interface ITabsState {
  list: ITab[];
  selectedTab: ITab;
}

export const initialTabsState: ITabsState = {
  list: [homeTab],
  selectedTab: homeTab,
};

export const tabsReducer = createReducer(initialTabsState, (builder) => {
  builder
    .addCase(addTab, (state, action) => {
      const foundTab = state.list.find((tab) => tab.id === action.payload.tab.id);
      if (foundTab) {
        return {
          ...state,
          list: !action.payload.isMenu
            ? state.list.map((tab) => (tab.id === action.payload.tab.id ? action.payload.tab : tab))
            : state.list,
          selectedTab: foundTab,
        };
      } else {
        return {
          ...state,
          list: [...state.list, action.payload.tab],
          selectedTab: action.payload.tab,
        };
      }
    })
    .addCase(selectTab, (state, action) => ({
      ...state,
      selectedTab: state.list.find((tab) => tab.id === action.payload?.id) ?? homeTab,
    }))
    .addCase(removeTab, (state, action) => {
      const foundTab = state.list.find((tab) => tab.id === action.payload.id);
      if (foundTab?.isRemovable) {
        return {
          ...state,
          list: state.list.filter((tab) => tab.id !== action.payload.id),
          selectedTab: foundTab.id === state.selectedTab.id ? homeTab : state.selectedTab,
        };
      }
      return state;
    })
    .addCase(updateTabs, (state, action) => ({
      ...state,
      list: action.payload,
    }))
    .addDefaultCase((state) => state);
});
