import { AxiosResponse } from 'axios';

import { ISetting } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export type GetSettingsListAPI = () => Promise<AxiosResponse<ISetting[]>>;

export const fetchSettingsListAPI: GetSettingsListAPI = () => {
  return axios.post(
    `${VITE_API_URL}/system/settings/list`,
    {
      fields: ['keyName', 'dataType', 'keyValue', 'systemKey', 'readOnly'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
};

export const updateSettingAPI = (setting: ISetting): Promise<AxiosResponse<ISetting>> =>
  axios.put(`${VITE_API_URL}/system/settings/${setting.keyName}`, setting);
