import { createReducer } from '@reduxjs/toolkit';

import { IGroup, IRight, IUser } from '@laudus/types';

import {
  clearGroup,
  clearGroupDraft,
  duplicateGroup,
  removeGroupFromList,
  setGroup,
  setGroupDraft,
  setGroupDraftValues,
  setGroupsList,
  setGroupsRightList,
  setGroupUserList,
  updateGroupsList,
} from './actions';

export const GROUPS_TAB_ID = 'groups';

export interface IGroupsState {
  current: IGroup;
  draft: IGroup;
  list: IGroup[];
  rights: IRight[];
  groupUserList: IUser[];
}

export const GROUP_EMPTY: IGroup = {
  groupId: '',
  name: '',
  notes: '',
  createdBy: {
    userId: '',
    name: '',
  },
  createdAt: '',
  modifiedBy: {
    userId: '',
    name: '',
  },
  modifiedAt: '',
  rights: [],
};

export const initialGroupsState: IGroupsState = {
  current: GROUP_EMPTY,
  draft: GROUP_EMPTY,
  list: [],
  rights: [],
  groupUserList: [],
};

export const groupsReducer = createReducer(initialGroupsState, (builder) => {
  builder
    .addCase(clearGroup, (state) => {
      return {
        ...state,
        current: GROUP_EMPTY,
        groupUserList: [],
      };
    })
    .addCase(clearGroupDraft, (state) => {
      return { ...state, draft: GROUP_EMPTY };
    })
    .addCase(setGroup, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setGroupDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setGroupDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setGroupsList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(setGroupsRightList, (state, action) => {
      return { ...state, rights: action.payload };
    })
    .addCase(setGroupUserList, (state, action) => {
      return { ...state, groupUserList: action.payload };
    })
    .addCase(updateGroupsList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.groupId !== action.payload.groupId), action.payload],
      };
    })
    .addCase(removeGroupFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.groupId !== action.payload),
      };
    })
    .addCase(duplicateGroup, (state, action) => {
      const { groupId, ...duplicatedGroup } = action.payload;
      const duplicatedGroupRights = [...duplicatedGroup.rights].map((rights) => {
        return { ...rights, rightId: 0 };
      });
      return {
        ...state,
        draft: { ...duplicatedGroup, rights: [...duplicatedGroupRights] },
      };
    })
    .addDefaultCase((state) => state);
});
