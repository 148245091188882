import { createReducer } from '@reduxjs/toolkit';

import { IDashboard, IDashboardCard, IDashboardCardPositionObject } from '@laudus/types';

import {
  clearDashboardList,
  endDashboardCardFetching,
  setDashboardList,
  startDashboardCardFetching,
  updateDashboardList,
} from './actions';

export const DEFAULT_DASHBOARD_CARD_POSITION = {
  md: { x: 0, y: 0, w: 6, h: 4 },
  xs: { x: 0, y: 0, w: 1, h: 4 },
} as IDashboardCardPositionObject;

export const DASHBOARD_CARD_EMPTY: IDashboardCard = {
  cardId: 0,
  position: DEFAULT_DASHBOARD_CARD_POSITION,
  statisticId: 0,
  content: {
    graph: {
      type: 'area',
      theme: '',
    },
    contentType: 'graph',
  },
  filters: [
    {
      filterTag: 'period',
      filterValue: 'all',
    },
  ],
};

export interface IDashboardsState {
  list: IDashboard[];
  listFetchTimestamp: number;
  cardsLoading: number[];
}

export const initialDashboardsState: IDashboardsState = {
  list: [],
  listFetchTimestamp: 0,
  cardsLoading: [],
};

export const dashboardsReducer = createReducer(initialDashboardsState, (builder) => {
  builder
    .addCase(clearDashboardList, (state) => {
      return { ...state, list: [], listFetchTimestamp: 0 };
    })
    .addCase(startDashboardCardFetching, (state, action) => {
      return { ...state, cardsLoading: [...state.cardsLoading, action.payload] };
    })
    .addCase(endDashboardCardFetching, (state, action) => {
      return { ...state, cardsLoading: state.cardsLoading.filter((id) => id !== action.payload) };
    })
    .addCase(setDashboardList, (state, action) => {
      return { ...state, list: action.payload, listFetchTimestamp: Date.now() };
    })
    .addCase(updateDashboardList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((d) => d.dashboardId !== action.payload.dashboardId),
          action.payload,
        ],
      };
    })
    .addDefaultCase((state) => state);
});
