import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { ICurrencyRate } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { removeCurrencyFromList, updateCurrencyList } from '../currencies';
import { endRequest, startRequest } from '../global/actions';

// Currency rates action creators
export const clearCurrencyRate = createAction(`CURRENCY_RATES/CLEAR`);

export const clearCurrencyRateDraft = createAction(`CURRENCY_RATES/CLEAR_DRAFT`);

export const setCurrencyRate = createAction<ICurrencyRate>(`CURRENCY_RATES/SET`);

export const setCurrencyRateDraft = createAction<ICurrencyRate>(`CURRENCY_RATES/SET_DRAFT`);

export const setCurrencyRateDraftValues = createAction<Partial<ICurrencyRate>>(
  `CURRENCY_RATES/SET_DRAFT_VALUES`,
);

export const setCurrencyRateList = createAction<ICurrencyRate[]>('CURRENCY_RATES/SET_LIST');

export const updateCurrencyRateList = createAction<ICurrencyRate>(`CURRENCY_RATES/UPDATE_LIST`);

export const removeCurrencyRateFromList = createAction<string>(`CURRENCY_RATES/REMOVE_FROM_LIST`);

export const updateCurrencyRateListAndCurrencyList = createAsyncThunk<
  void,
  ICurrencyRate,
  AppThunkConfig
>('CURRENCY_RATES/UPDATE_LIST_AND_CURRENCY_LIST', async (currencyRate, ThunkAPI) => {
  const { dispatch } = ThunkAPI;

  dispatch(updateCurrencyRateList(currencyRate));
  dispatch(updateCurrencyList(currencyRate.currency));
});

export const removeCurrencyRateFromListAndCurrencyList = createAsyncThunk<
  void,
  string,
  AppThunkConfig
>('CURRENCY_RATES/REMOVE_FROM_LIST_AND_CURRENCY_LIST', async (currencyId, ThunkAPI) => {
  const { dispatch } = ThunkAPI;

  dispatch(removeCurrencyRateFromList(currencyId));
  dispatch(removeCurrencyFromList(currencyId));
});

export const duplicateCurrencyRate = createAction<ICurrencyRate>(`CURRENCY_RATES/DUPLICATE`);

export const fetchCurrencyRate = createAsyncThunk<void, string, AppThunkConfig>(
  'CURRENCY_RATES/FETCH',
  async (currencyId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('currencies'));
    try {
      const { data } = await api.currencyRates.fetchCurrencyRateFromAPI(currencyId);
      dispatch(setCurrencyRate(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'currencies',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('currencies'));
    }
  },
);
