import {
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

import { Form, Formik, FormikProps } from '@laudus/formik';
import { IconUser } from '@laudus/icons';
import { useIntl } from '@laudus/intl';
import { clearAuthError, requestPasswordReset, useGlobalDispatch } from '@laudus/redux-global';
import { colors } from '@laudus/shared-ui';

import { RequestPasswordSchema } from '../schemas';

interface PasswordRequestResetFormValues {
  loginName: string;
}

interface IPasswordRequestResetFormProps {
  formError: string;
}

export const PasswordRequestResetForm = ({ formError }: IPasswordRequestResetFormProps) => {
  const intl = useIntl();
  const dispatch = useGlobalDispatch();

  // Handler submit for requesting password reset
  const handleSubmit = async ({ loginName }: PasswordRequestResetFormValues) => {
    // Here we need to return a promise (?) for Formik isSubmitting to trigger
    return Promise.all([dispatch(clearAuthError()), dispatch(requestPasswordReset({ loginName }))]);
  };

  return (
    <Formik
      initialValues={{ loginName: '' }}
      onSubmit={handleSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={RequestPasswordSchema}
    >
      {({ handleChange, errors, isSubmitting }: FormikProps<PasswordRequestResetFormValues>) => {
        return (
          <Form>
            <FormControl id="loginName" isInvalid={!!errors.loginName} mb="1.2rem">
              <FormLabel
                _dark={{ color: colors.secondary100 }}
                color={{ base: colors.secondary200, md: colors.neutral700 }}
                variant="small"
              >
                {intl.formatMessage({ id: 'resetPassword.input.label.user' })}
              </FormLabel>
              <InputGroup>
                <InputRightElement>
                  <div className="icon-color">
                    <IconUser size={18} />
                  </div>
                </InputRightElement>
                <Input
                  bg={{ base: 'secondary600', md: 'white' }}
                  _dark={{
                    bg: colors.darkGrey500,
                    border: `0.05rem solid ${colors.darkGrey200}`,
                    color: colors.white,
                    _selected: {
                      border: `0.05rem solid ${colors.primary500}`,
                    },
                    _focus: {
                      border: `0.05rem solid ${colors.primary500}`,
                    },
                    _disabled: { color: colors.secondary100 },
                  }}
                  borderColor={{
                    base: formError ? colors.danger400 : colors.secondary200,
                    md: formError ? colors.danger400 : colors.neutral500,
                  }}
                  color={{ base: colors.white, md: colors.black }}
                  onChange={handleChange}
                  type="text"
                />
              </InputGroup>
              {!!errors.loginName && (
                <FormErrorMessage fontSize="1.5rem">{errors.loginName}</FormErrorMessage>
              )}
            </FormControl>
            <Button
              isLoading={isSubmitting}
              mb={{ base: '10', md: '1rem' }}
              mt={{ base: '5' }}
              type="submit"
              variant="login"
            >
              {intl.formatMessage({
                id: 'resetPassword.button.requestNewPassword',
              })}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};
