import { forwardRef } from 'react';

import { intl } from '@laudus/intl';

const SelectNoteOfCreditRendererComponent: React.ForwardRefRenderFunction<any, any> = (props) => {
  const noteOfCreditTypes = [
    {
      label: intl.formatMessage({ id: 'invoices.generalDataTab.items.noteOfCreditType.discount' }),
      value: 'D',
    },
    {
      label: intl.formatMessage({ id: 'invoices.generalDataTab.items.noteOfCreditType.return' }),
      value: 'R',
    },
  ];

  return <span>{noteOfCreditTypes?.find((o) => o.value === props.value)?.label}</span>;
};

SelectNoteOfCreditRendererComponent.displayName = 'SelectNoteOfCreditRendererComponent';

export const SelectNoteOfCreditRenderer = forwardRef(SelectNoteOfCreditRendererComponent);
