import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { ILot } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

export const addHomeLotTab = () =>
  addTab({
    tab: {
      id: 'lot',
      title: intl.formatMessage({ id: 'lot.tabTitle' }),
      path: 'pages/Lots/Lot',
      isRemovable: true,
    },
  });

export const addViewLotTab = (id?: number) =>
  addTab({
    tab: {
      id: 'lot',
      title: intl.formatMessage({ id: 'lot.tabTitle' }),
      path: 'pages/Lots/LotView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewLotTab = () =>
  addTab({
    tab: {
      id: 'lot',
      title: intl.formatMessage({ id: 'lot.tabTitle' }),
      path: 'pages/Lots/LotNew',
      isRemovable: true,
    },
  });

export const addEditLotTab = () =>
  addTab({
    tab: {
      id: 'lot',
      title: intl.formatMessage({ id: 'lot.tabTitle' }),
      path: 'pages/Lots/LotEdit',
      isRemovable: true,
    },
  });

export const clearLot = createAction('LOTS/CLEAR');

export const clearLotDraft = createAction('LOTS/CLEAR_DRAFT');

export const setLot = createAction<ILot>('LOTS/SET_LOT');

export const setLotDraft = createAction<ILot>('LOTS/SET_LOT_DRAFT');

export const setLotsList = createAction<ILot[]>('LOTS/SET_LIST');

export const updateLotsList = createAction<ILot>('LOTS/UPDATE_LIST');

export const removeLotFromList = createAction<number>('LOTS/REMOVE_FROM_LIST');

export const duplicateLot = createAction<ILot>('LOTS/DUPLICATE');

export const setLotDraftValues = createAction<Partial<ILot>>('LOTS/SET_LOT_DRAFT_VALUE');

export const fetchLotsList = createAsyncThunk<void, void, AppThunkConfig>(
  'LOTS/FETCH_LOTS_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('lots'));
    try {
      const { data } = await api.lots.fetchLotsListAPI();
      dispatch(setLotsList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'lot',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('lots'));
    }
  },
);

export const fetchLot = createAsyncThunk<void, number, AppThunkConfig>(
  'LOTS/FETCH_LOT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('lots'));
    try {
      const { data } = await api.lots.fetchLotAPI(id);
      dispatch(setLot(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'lot',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('lots'));
    }
  },
);
