import { ReactNode } from 'react';

import { IconButton, useColorMode } from '@chakra-ui/react';

import { IconMenuKebab } from '@laudus/icons';
import { colors, Menu } from '@laudus/shared-ui';

interface IRowActionMenuProps {
  children: ReactNode;
  className?: string;
  variant?: string;
  dark?: boolean;
  isSquare?: boolean;
  marginLeft?: string;
}

export const RowActionMenu = ({
  children,
  className = 'szh-menu--large',
  variant,
  dark = false,
  isSquare,
  marginLeft = '2rem',
}: IRowActionMenuProps) => {
  const { colorMode } = useColorMode();
  const iconVariant = variant || (dark ? 'icon-action-bar-dark' : 'icon-action-bar');
  const iconColor = colorMode === 'dark' ? colors.white : colors.black;

  return (
    <Menu
      align="end"
      className={className}
      menuButton={
        <IconButton
          aria-label="icon-action-bar-submenu"
          fontSize="2rem"
          height={{ base: '4rem', md: isSquare ? '28px' : 'auto' }}
          icon={<IconMenuKebab color={iconColor} />}
          marginLeft={{ base: undefined, md: marginLeft }}
          variant={iconVariant}
          _dark={{ background: colors.darkTransparentWhite38 }}
        />
      }
      portal={true}
    >
      {children}
    </Menu>
  );
};
