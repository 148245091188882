import { AxiosResponse } from 'axios';

import { ICustomField } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export type GetCustomFieldsListAPI = () => Promise<AxiosResponse<ICustomField[]>>;

export const fetchCustomFieldsListAPI: GetCustomFieldsListAPI = () => {
  return axios.post(
    `${VITE_API_URL}/system/customFields/list`,
    {
      fields: [
        'customFieldId',
        'entity',
        'name',
        'label',
        'type',
        'length',
        'percision',
        'nullable',
        'defaultValue',
        'enumValues',
        'required',
        'tabIndex',
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
};
