import { createSelector } from '@reduxjs/toolkit';

import { transformsAndCalculatePurchaseWaybills } from '@laudus/shared-utils';
import { IPurchaseWaybill, IPurchaseWaybillItemTableRow } from '@laudus/types';

import { AppState } from '../../store';
import { getMainCurrency } from '../currencies';

import { IPurchaseWaybillsState } from './reducer';

export const getPurchaseWaybillsSlice = (state: AppState): IPurchaseWaybillsState =>
  state.purchaseWaybills;

export const getPurchaseWaybill = createSelector(
  [getPurchaseWaybillsSlice, (appState) => getMainCurrency(appState)],
  (state, { mainCurrencyDecimals }) => {
    // This prepare the purchaseWaybill document to be sent to the backend once the draft has been completed
    const transformedItems = transformsAndCalculatePurchaseWaybills(
      state.current.items,
      mainCurrencyDecimals,
    );

    const waybill: IPurchaseWaybill = {
      ...state.current,
      items: transformedItems,
    };

    return waybill;
  },
);

export const getPurchaseWaybillDraft = createSelector(
  [getPurchaseWaybillsSlice, (appState) => getMainCurrency(appState)],
  (state, { mainCurrencyDecimals }) => {
    // This prepare the purchaseWaybill document to be sent to the backend once the draft has been completed
    const transformedItems = transformsAndCalculatePurchaseWaybills(
      state.draft.items,
      mainCurrencyDecimals,
    );

    const waybill: IPurchaseWaybill = {
      ...state.draft,
      items: transformedItems,
    };

    return waybill;
  },
);

export const getPurchaseWaybillsList = createSelector(
  [getPurchaseWaybillsSlice],
  (state) => state.list,
);

export const getPurchaseWaybillsItems = createSelector(
  [getPurchaseWaybill],
  (state) => state.items,
);

export const getPurchaseWaybillIsEditing = createSelector(
  [getPurchaseWaybillsSlice],
  (state) => state.editing,
);

export const getPurchaseWaybillEntity = createSelector(
  [getPurchaseWaybillDraft, getPurchaseWaybill, getPurchaseWaybillIsEditing],
  (draft, current, editing) => {
    return editing ? draft : current;
  },
);

export const getPurchaseWaybillTableData = createSelector([getPurchaseWaybillEntity], (entity) => {
  const items: IPurchaseWaybillItemTableRow[] = entity.items.map((item) => ({
    ...item,
    itemDescription: item.itemDescription ?? item.product?.description ?? '',
    net: item.quantity * item.unitCost,
  }));

  return items;
});
