import { ColDef } from '@laudus/ag-grid';

export const columnDefs: ColDef[] = [
  {
    field: 'VATId',
    headerName: 'RUT',
    minWidth: 120,
    resizable: false,
    sortable: true,
    unSortIcon: true,
    cellStyle: { border: 'none', textAlign: 'center' },
  },
  {
    field: 'name',
    headerName: 'Nombre',
    minWidth: 680,
    resizable: true,
    sortable: true,
    unSortIcon: true,
    cellStyle: { border: 'none', textAlign: 'left' },
  },
];
