import { forwardRef } from 'react';

import { SelectEditor } from './SelectEditor';

const SelectCustomFieldEditorComponent: React.ForwardRefRenderFunction<any, any> = (props, ref) => {
  return <SelectEditor {...props} options={props.options} ref={ref} />;
};

SelectCustomFieldEditorComponent.displayName = 'SelectCustomFieldEditor';

export const SelectCustomFieldEditor = forwardRef(SelectCustomFieldEditorComponent);
