import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IFixedAssetsState } from './reducer';

export const getFixedAssetsSlice = (state: AppState): IFixedAssetsState => state.fixedAssets;

export const getFixedAssetsList = createSelector([getFixedAssetsSlice], (state) => state.list);

export const getFixedAsset = createSelector([getFixedAssetsSlice], (state) => state.current);

export const getFixedAssetDraft = createSelector([getFixedAssetsSlice], (state) => state.draft);
