import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString } from '@laudus/shared-utils';
import { IAccountRelatedTo, IJournalEntry } from '@laudus/types';

import {
  clearJournalEntry,
  clearJournalEntryDraft,
  removeJournalEntryFromList,
  setJournalEntriesList,
  setJournalEntry,
  setJournalEntryDraft,
  setJournalEntryDraftValues,
  setJournalEntryEditing,
  setJournalEntryRelatedToSearchResult,
  updateJournalEntriesList,
} from './actions';

export interface IJournalEntryRelatedToSearchResult {
  typeLabel: string;
  name: string;
  relatedToId: number;
  type: string;
  VATId: string;
}

export interface IJournalEntriesState {
  current: IJournalEntry;
  draft: IJournalEntry;
  list: IJournalEntry[];
  editing: boolean;
  accountRelatedToResult?: IAccountRelatedTo;
}

export const JOURNALENTRY_EMPTY: IJournalEntry = {
  journalEntryId: 0,
  journalEntryNumber: 0,
  date: dateToLocalISOString(new Date()),
  type: '',
  typeNumber: 0,
  approved: false,
  approvedBy: {
    userId: '',
    name: '',
  },
  generated: false,
  generatedBy: '',
  bookId: '',
  notes: '',
  createdBy: {
    userId: '',
    name: '',
  },
  createdAt: '',
  modifiedBy: {
    userId: '',
    name: '',
  },
  modifiedAt: '',
  lines: [],
};

export const initialJournalEntriesState: IJournalEntriesState = {
  current: JOURNALENTRY_EMPTY,
  draft: JOURNALENTRY_EMPTY,
  list: [],
  editing: false,
  accountRelatedToResult: undefined,
};

export const JOURNALENTRIES_TAB_ID = 'journalEntries';

export const journalEntriesReducer = createReducer(initialJournalEntriesState, (builder) => {
  builder
    .addCase(setJournalEntryEditing, (state, action) => {
      return { ...state, editing: action.payload };
    })
    .addCase(clearJournalEntry, (state) => {
      return { ...state, current: JOURNALENTRY_EMPTY };
    })
    .addCase(clearJournalEntryDraft, (state) => {
      return { ...state, draft: JOURNALENTRY_EMPTY };
    })
    .addCase(setJournalEntry, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setJournalEntryDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setJournalEntryDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setJournalEntriesList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateJournalEntriesList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((b) => b.journalEntryId !== action.payload.journalEntryId),
          action.payload,
        ],
      };
    })
    .addCase(removeJournalEntryFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((b) => b.journalEntryId !== action.payload),
      };
    })
    .addCase(setJournalEntryRelatedToSearchResult, (state, action) => {
      return { ...state, accountRelatedToResult: action.payload };
    })
    .addDefaultCase((state) => state);
});
