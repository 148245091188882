import { createReducer } from '@reduxjs/toolkit';

import { PURCHASES_INVOICE_EMPTY } from '@laudus/sales-utils';
import {
  addDaysToDate,
  calculateDatesDifference,
  dateToLocalISOString,
  getNegativeUniqueNumericId,
} from '@laudus/shared-utils';
import { IPurchaseInvoice, IPurchaseInvoicesItem } from '@laudus/types';

import {
  clearPurchaseInvoice,
  clearPurchaseInvoiceDraft,
  duplicatePurchaseInvoice,
  removePurchaseInvoiceFromList,
  setPurchaseInvoice,
  setPurchaseInvoiceDraft,
  setPurchaseInvoiceDraftValues,
  setPurchaseInvoiceList,
  updatePurchaseInvoiceList,
} from './actions';

export interface IPurchaseInvoicesState {
  current: IPurchaseInvoice;
  draft: IPurchaseInvoice;
  list: IPurchaseInvoice[];
}

export const initialPurchaseInvoicesState: IPurchaseInvoicesState = {
  current: PURCHASES_INVOICE_EMPTY,
  draft: PURCHASES_INVOICE_EMPTY,
  list: [],
};

export const purchasesInvoicesReducer = createReducer(initialPurchaseInvoicesState, (builder) => {
  builder
    .addCase(clearPurchaseInvoice, (state) => {
      return {
        ...state,
        current: PURCHASES_INVOICE_EMPTY,
      };
    })
    .addCase(clearPurchaseInvoiceDraft, (state) => {
      return { ...state, draft: PURCHASES_INVOICE_EMPTY };
    })
    .addCase(setPurchaseInvoice, (state, action) => {
      return {
        ...state,
        current: computePurchaseInvoicePropertiesFromItems(action.payload),
      };
    })
    .addCase(setPurchaseInvoiceDraft, (state, action) => {
      return {
        ...state,
        draft: computePurchaseInvoicePropertiesFromItems(action.payload),
      };
    })
    .addCase(setPurchaseInvoiceDraftValues, (state, action) => {
      return {
        ...state,
        draft: computePurchaseInvoicePropertiesFromItems({
          ...state.draft,
          ...action.payload,
        }),
      };
    })
    .addCase(setPurchaseInvoiceList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updatePurchaseInvoiceList, (state, action) => {
      const { purchaseInvoiceId } = action.payload;

      if (!state.list.some((d) => d.purchaseInvoiceId === purchaseInvoiceId)) {
        return state;
      }

      return {
        ...state,
        list: [
          ...state.list.filter((d) => d.purchaseInvoiceId !== purchaseInvoiceId),
          {
            ...action.payload,
          },
        ],
      };
    })
    .addCase(removePurchaseInvoiceFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.purchaseInvoiceId !== action.payload),
      };
    })
    .addCase(duplicatePurchaseInvoice, (state, action) => {
      const {
        purchaseInvoiceId: purchasesInvoiceIdTmp,
        docNumber,
        journalEntry,
        items,
        ...duplicatedPurchaseInvoice
      } = action.payload;

      duplicatedPurchaseInvoice.DTE = null;

      // issued and due dates
      const datesDifference = calculateDatesDifference(
        duplicatedPurchaseInvoice.issuedDate,
        duplicatedPurchaseInvoice.dueDate,
      );
      duplicatedPurchaseInvoice.issuedDate = dateToLocalISOString(new Date());
      duplicatedPurchaseInvoice.dueDate = addDaysToDate(new Date(), datesDifference).toISOString();

      // Other properties to overwrite
      duplicatedPurchaseInvoice.references = null;
      // TODO: check fields to reset

      // Reset all itemIds
      const newItems: IPurchaseInvoicesItem[] = items.map((item) => ({
        ...item,
        itemId: getNegativeUniqueNumericId(),
        traceFrom: null,
      }));

      // Build new draft and store the changes
      const newPurchaseInvoice: IPurchaseInvoice = {
        ...duplicatedPurchaseInvoice,
        items: newItems,
        docNumber: 0,
      };

      return {
        ...state,
        draft: computePurchaseInvoicePropertiesFromItems(newPurchaseInvoice),
      };
    })
    .addDefaultCase((state) => state);
});

/**
 * Compute some properties from items and put them into invoice so they can be directly edited in a single input
 *
 */
function computePurchaseInvoicePropertiesFromItems(
  purchaseInvoice: IPurchaseInvoice,
): IPurchaseInvoice {
  const accountFromItems = purchaseInvoice.items.find(
    (item) => Boolean(item.account) && Boolean(item.account?.accountId),
  )?.account;

  return {
    ...purchaseInvoice,
    account: purchaseInvoice.account ?? accountFromItems,
  };
}
