import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IEtag } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { endRequest, startRequest } from '../global/actions';

export const setEtagsCurrentEtag = createAction<IEtag | undefined>('ETAGS/SET_CURRENT_ETAG');

export const setEtagsNext = createAction<IEtag[]>('ETAGS/SET_NEXT');

export const setEtagsError = createAction<boolean>('ETAGS/SET_ERROR');

export const clearEtagsError = createAction<void>('ETAGS/CLEAR_ERROR');

export const fetchEtags = createAsyncThunk<void, string[], AppThunkConfig>(
  'ETAGS/FETCH_CURRENT_ETAG',
  async (entityList, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('etags'));

    try {
      const { data } = await api.etags.fetchEtagsAPI(entityList);
      dispatch(setEtagsNext(Array.isArray(data) ? data : []));
    } catch (e: unknown) {
      dispatch(setEtagsError(true));
    } finally {
      dispatch(endRequest('etags'));
    }
  },
);
