import { AxiosResponse } from 'axios';

import { removeNegativeItemIdsFromEntity } from '@laudus/shared-utils';
import { IStockTransfer } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export interface IStockTransferAPIListItem {
  stockTransferId: number;
  warehouseFrom_name: string;
  warehouseTo_name: string;
}

export const mapFromAPIListItem = (apiListItem: IStockTransferAPIListItem): IStockTransfer => {
  return {
    stockTransferId: apiListItem.stockTransferId,
    warehouseFrom: {
      name: apiListItem.warehouseFrom_name,
      warehouseId: '',
    },
    warehouseTo: {
      name: apiListItem.warehouseTo_name,
      warehouseId: '',
    },
    items: [],
  };
};

export const fetchStockTransferListFromAPI = (): Promise<
  AxiosResponse<IStockTransferAPIListItem[]>
> =>
  axios.post(
    `${VITE_API_URL}/production/stockTransfers/list`,
    {
      fields: ['stockTransferId', 'warehouseFrom.name', 'warehouseTo.name'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchStockTransferFromAPI = (
  stockTransferId: number,
): Promise<AxiosResponse<IStockTransfer>> =>
  axios.get(`${VITE_API_URL}/production/stockTransfers/${stockTransferId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createStockTransferFromAPI = (
  stockTransfer: IStockTransfer,
): Promise<AxiosResponse<IStockTransfer>> =>
  axios.post(
    `${VITE_API_URL}/production/stockTransfers`,
    removeNegativeItemIdsFromEntity(stockTransfer),
  );

export const updateStockTransferFromAPI = (
  stockTransfer: IStockTransfer,
): Promise<AxiosResponse<IStockTransfer>> =>
  axios.put(
    `${VITE_API_URL}/production/stockTransfers/${stockTransfer.stockTransferId}`,
    removeNegativeItemIdsFromEntity(stockTransfer),
  );

export const deleteStockTransferFromAPI = (stockTransferId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/production/stockTransfers/${stockTransferId}`);
