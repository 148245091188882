import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IPurchaseInvoice } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const PURCHASES_INVOICES_TAB_ID = 'purchaseInvoices';
export const purchasesInvoicesPrefix = 'PURCHASES_INVOICES';

export const addHomePurchaseInvoicesTab = () =>
  addTab({
    tab: {
      id: PURCHASES_INVOICES_TAB_ID,
      title: intl.formatMessage({ id: 'purchaseInvoices.tabTitle' }),
      path: 'pages/PurchaseInvoices/PurchaseInvoices',
      isRemovable: true,
    },
  });

export const addViewPurchaseInvoicesTab = (id?: number) =>
  addTab({
    tab: {
      id: PURCHASES_INVOICES_TAB_ID,
      title: intl.formatMessage({ id: 'purchaseInvoices.tabTitle' }),
      path: 'pages/PurchaseInvoices/PurchaseInvoicesView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewPurchaseInvoicesTab = () =>
  addTab({
    tab: {
      id: PURCHASES_INVOICES_TAB_ID,
      title: intl.formatMessage({ id: 'purchaseInvoices.tabTitle' }),
      path: 'pages/PurchaseInvoices/PurchaseInvoicesNew',
      isRemovable: true,
    },
  });

export const addEditPurchaseInvoicesTab = (id?: number) =>
  addTab({
    tab: {
      id: PURCHASES_INVOICES_TAB_ID,
      title: intl.formatMessage({ id: 'purchaseInvoices.tabTitle' }),
      path: 'pages/PurchaseInvoices/PurchaseInvoicesEdit',
      props: { id },
      isRemovable: true,
    },
  });

// Simple actions
export const clearPurchaseInvoice = createAction(`${purchasesInvoicesPrefix}/CLEAR`);
export const clearPurchaseInvoiceDraft = createAction(`${purchasesInvoicesPrefix}/CLEAR_DRAFT`);

export const setPurchaseInvoice = createAction<IPurchaseInvoice>(`${purchasesInvoicesPrefix}/SET`);

export const setPurchaseInvoiceDraft = createAction<IPurchaseInvoice>(
  `${purchasesInvoicesPrefix}/SET_DRAFT`,
);

export const setPurchaseInvoiceDraftValues = createAction<Partial<IPurchaseInvoice>>(
  `${purchasesInvoicesPrefix}/SET_DRAFT_VALUES`,
);

export const setPurchaseInvoiceList = createAction<IPurchaseInvoice[]>(
  `${purchasesInvoicesPrefix}/SET_LIST`,
);
export const updatePurchaseInvoiceList = createAction<IPurchaseInvoice>(
  `${purchasesInvoicesPrefix}/UPDATE_LIST`,
);
export const removePurchaseInvoiceFromList = createAction<number>(
  `${purchasesInvoicesPrefix}/REMOVE_FROM_LIST`,
);

export const duplicatePurchaseInvoice = createAction<IPurchaseInvoice>(
  `${purchasesInvoicesPrefix}/DUPLICATE`,
);
export const setPurchaseInvoiceFetchingOne = createAction<boolean>(
  'PURCHASES_INVOICES/LOADING_ONE',
);

//Complex actions
export const fetchPurchaseInvoiceList = createAsyncThunk<void, void, AppThunkConfig>(
  `${purchasesInvoicesPrefix}/FETCH_LIST`,
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('purchase-invoices'));

    try {
      const { data } = await api.purchaseInvoices.fetchPurchaseInvoiceListFromAPI({});
      dispatch(setPurchaseInvoiceList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'purchaseInvoices',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('purchase-invoices'));
    }
  },
);

export const fetchPurchaseInvoice = createAsyncThunk<void, number, AppThunkConfig>(
  `${purchasesInvoicesPrefix}/FETCH`,
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('purchase-invoices'));
      dispatch(setPurchaseInvoiceFetchingOne(true));

      const { data } = await api.purchaseInvoices.fetchPurchaseInvoiceFromAPI(id);

      if (!data.items) {
        data.items = [];
      }

      dispatch(setPurchaseInvoice(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'purchaseInvoices',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('purchase-invoices'));
      dispatch(setPurchaseInvoiceFetchingOne(false));
    }
  },
);
