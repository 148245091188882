import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { getMessageFromError, getTitleFromError } from '@laudus/shared-utils';
import { ICafDte, IEtag } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

export const CAFDTE_TAB_ID = 'cafdte';
const entityPrefix = 'CAF_DTE';

interface ICafDTETabCreatorArgs {
  title?: string;
  authorizedDocumentsRangeId?: string;
}

export const addViewCafDTE = ({ title, authorizedDocumentsRangeId }: ICafDTETabCreatorArgs) =>
  addTab({
    tab: {
      id: CAFDTE_TAB_ID,
      title: title ?? intl.formatMessage({ id: 'cafdte.tabTitle' }),
      path: 'pages/CafDte/CafDteView',
      props: { authorizedDocumentsRangeId },
      isRemovable: true,
    },
  });

// CafDTE action creators
export const clearCafDTE = createAction(`${entityPrefix}/CLEAR`);

export const setCafDTE = createAction<ICafDte>(`${entityPrefix}/SET`);

export const setCafDTEDraft = createAction<ICafDte>(`${entityPrefix}/SET_DRAFT`);

export const setCafDTEList = createAction<ICafDte[]>(`${entityPrefix}/SET_LIST`);

export const updateCafDTEList = createAction<ICafDte>(`${entityPrefix}/UPDATE_LIST`);

export const duplicateCafDTE = createAction<ICafDte>(`${entityPrefix}/DUPLICATE`);

export interface IRemoveCafDTEFromListParams {
  authorizedDocumentsRangeId: number;
}

export const removeCafDTEFromList = createAction<IRemoveCafDTEFromListParams>(
  `${entityPrefix}/REMOVE_FROM_LIST`,
);

export interface IFetchBanksParams {
  eTag: IEtag;
}

export const fetchCafDTEList = createAsyncThunk<void, void, AppThunkConfig>(
  `${entityPrefix}/FETCH_LIST`,
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('caf-dte'));
    try {
      const { data } = await api.dte.fetchCafDTEListFromAPI();
      const newData = () => {
        if (Array.isArray(data)) {
          return data.map((row: ICafDte) => {
            return {
              ...row,
              quantity: (row?.docNumberTo ?? 0) - (row?.docNumberFrom ?? 0) + 1,
            };
          });
        } else {
          return [];
        }
      };
      dispatch(setCafDTEList(newData()));
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: getTitleFromError({ error }),
          message: getMessageFromError({ error }),
        }),
      );

      dispatch(
        showErrorAlert({
          error,
          prefix: 'branches',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('caf-dte'));
    }
  },
);

export interface IChangeCafDTEParams {
  cafDte: ICafDte;
}

export const createCafDTE = createAsyncThunk<void, IChangeCafDTEParams, AppThunkConfig>(
  `${entityPrefix}/CREATE`,
  async ({ cafDte }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('caf-dte'));
    try {
      const { data } = await api.dte.createCafDTEFromAPI(cafDte);

      dispatch(setCafDTE(data));
      dispatch(updateCafDTEList(data));

      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'cafDte.successToast.save' }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: getTitleFromError({ error }),
          message: getMessageFromError({ error }),
        }),
      );
    } finally {
      dispatch(endRequest('caf-dte'));
    }
  },
);

export interface IDeleteCafDTEParams {
  authorizedDocumentsRangeId: number;
}

export const deleteCafDTE = createAsyncThunk<void, IDeleteCafDTEParams, AppThunkConfig>(
  `${entityPrefix}/DELETE`,
  async ({ authorizedDocumentsRangeId }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('caf-dte'));
    try {
      await api.dte.deleteCafDTEFromAPI(authorizedDocumentsRangeId);

      dispatch(clearCafDTE());
      dispatch(removeCafDTEFromList({ authorizedDocumentsRangeId }));
      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'cafDte.successToast.delete' }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'cafDte',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('caf-dte'));
    }
  },
);
