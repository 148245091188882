import { createSelector } from '@reduxjs/toolkit';

import { AppState, ICostCenterState } from '../..';

export const getCostCentersSlice = (state: AppState): ICostCenterState => state.costCenters;

export const getCostCenterList = createSelector([getCostCentersSlice], (state) => state.list);

export const getCostCenter = createSelector([getCostCentersSlice], (state) => state.current);

export const getCostCenterDraft = createSelector([getCostCentersSlice], (state) => state.draft);

export const getCostCenterFormState = createSelector(
  [getCostCentersSlice],
  (state) => state.formState,
);
