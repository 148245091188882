import { createReducer } from '@reduxjs/toolkit';

import { ISettingsDTECertificate, ISettingsDTECertificateInfo } from '@laudus/types';

import {
  clearDTECertificate,
  clearDTECertificateDraft,
  setDTECertificate,
  setDTECertificateImport,
  setDTECertificateInfo,
} from './actions';

export interface IDTECertificateState {
  current: ISettingsDTECertificateInfo;
  draft: ISettingsDTECertificateInfo;
  certificate: ISettingsDTECertificate;
  importCertificate: any;
}

export const DTE_CERTIFICATE_EMPTY: ISettingsDTECertificate = {
  certificatePFX: '',
  password: '',
};

export const DTE_CERTIFICATE_INFO_EMPTY: ISettingsDTECertificateInfo = {
  issuedAt: '',
  expiration: '',
  subject: '',
};

export const initialSettingsDTECertificateState: IDTECertificateState = {
  current: DTE_CERTIFICATE_INFO_EMPTY,
  draft: DTE_CERTIFICATE_INFO_EMPTY,
  certificate: DTE_CERTIFICATE_EMPTY,
  importCertificate: [],
};

export const settingsDTECertificateReducer = createReducer(
  initialSettingsDTECertificateState,
  (builder) => {
    builder
      .addCase(clearDTECertificate, (state) => {
        return {
          ...state,
          current: DTE_CERTIFICATE_INFO_EMPTY,
        };
      })
      .addCase(clearDTECertificateDraft, (state) => {
        return {
          ...state,
          draft: DTE_CERTIFICATE_INFO_EMPTY,
        };
      })
      .addCase(setDTECertificateInfo, (state, action) => {
        return { ...state, current: action.payload };
      })
      .addCase(setDTECertificate, (state, action) => {
        return { ...state, certificate: action.payload };
      })
      .addCase(setDTECertificateImport, (state, action) => {
        return { ...state, importCertificate: action.payload };
      })
      .addDefaultCase((state) => state);
  },
);
