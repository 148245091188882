import { createSelector } from '@reduxjs/toolkit';

import { calculateMonthsBetweenDates } from '@laudus/shared-utils';

import { AppState, IEmployeeLoansState } from '../..';

export const getEmployeeLoansSlice = (state: AppState): IEmployeeLoansState => state.employeeLoans;

export const getEmployeesLoansList = createSelector([getEmployeeLoansSlice], (state) => state.list);

export const getEmployeeLoanDraft = createSelector([getEmployeeLoansSlice], (state) => state.draft);

export const getEmployeesLoansTableData = createSelector([getEmployeesLoansList], (state) => {
  const currentDate = new Date();
  return state.map((employeeLoan) => {
    const dateFrom = new Date(employeeLoan.dateFrom);
    const dateTo = new Date(employeeLoan.dateTo);
    const payments = calculateMonthsBetweenDates(dateFrom, dateTo) + 1;
    const total = employeeLoan.monthlyAmount * payments;
    // Calculate outstanding payments
    let outstandingPayments = 0;
    // Calculate pending payment
    let pendingPayment = 0;

    if (currentDate >= dateFrom && currentDate <= dateTo) {
      pendingPayment =
        employeeLoan.monthlyAmount * (calculateMonthsBetweenDates(currentDate, dateTo) + 1);
    } else if (currentDate < dateFrom) {
      pendingPayment =
        employeeLoan.monthlyAmount * (calculateMonthsBetweenDates(dateFrom, dateTo) + 1);
    }

    if (currentDate >= dateFrom && currentDate <= dateTo) {
      outstandingPayments = payments;
    } else if (currentDate < dateFrom) {
      outstandingPayments = payments;
    }
    return {
      ...employeeLoan,
      payments,
      total,
      pendingPayment,
      outstandingPayments,
    };
  });
});
