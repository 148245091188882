import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IRemunerationConceptTypesState } from './reducer';

export const getRemunerationConceptTypesSlice = (state: AppState): IRemunerationConceptTypesState =>
  state.remunerationConceptTypes;

export const getRemunerationConceptTypeList = createSelector(
  [getRemunerationConceptTypesSlice],
  (state) => state.list,
);

export const getRemunerationConceptTypeDraftList = createSelector(
  [getRemunerationConceptTypesSlice],
  (state) => state.draftList,
);
