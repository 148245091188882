import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { cloneDeep } from 'lodash';

import { intl } from '@laudus/intl';
import { showToastAlert } from '@laudus/redux-global';
import { getMessageFromError, getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  IAccountRelatedTo,
  IEtag,
  IJournalEntry,
  IJournalEntryLine,
  IStartEditingParams,
} from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

import { getJournalEntryDraft, getJournalEntryTotalDifference } from './selectors';

export const addHomeJournalEntryTab = () =>
  addTab({
    tab: {
      id: 'journalEntries',
      title: intl.formatMessage({ id: 'journalEntries.tabTitle' }),
      path: 'pages/JournalEntries/JournalEntry',
      isRemovable: true,
    },
  });

export const startEditingJournalEntry = createAsyncThunk<
  void,
  IStartEditingParams | undefined,
  AppThunkConfig
>('JOURNAL_ENTRIES/START_EDITING', async (params, ThunkAPI) => {
  const { isNew } = params ?? {};
  const { dispatch } = ThunkAPI;
  dispatch(setJournalEntryEditing(true));
  dispatch(
    addTab({
      tab: {
        id: 'journalEntries',
        title: intl.formatMessage({ id: 'journalEntries.tabTitle' }),
        path: isNew
          ? 'pages/JournalEntries/JournalEntryNew'
          : 'pages/JournalEntries/JournalEntryEdit',
        isRemovable: true,
      },
    }),
  );
});

export const startEditingJournalEntryNew = createAsyncThunk<void, void, AppThunkConfig>(
  'JOURNAL_ENTRIES/START_EDITING_NEW',
  async (_, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(startEditingJournalEntry({ isNew: true }));
  },
);

export const stopEditingJournalEntry = createAsyncThunk<void, number | undefined, AppThunkConfig>(
  'JOURNAL_ENTRIES/STOP_EDITING',
  async (id, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(setJournalEntryEditing(false));
    dispatch(
      addTab({
        tab: {
          id: 'journalEntries',
          title: intl.formatMessage({ id: 'journalEntries.tabTitle' }),
          path: 'pages/JournalEntries/JournalEntryView',
          props: { id },
          isRemovable: true,
        },
      }),
    );
  },
);

// JournalEntries action creators
export const setJournalEntryEditing = createAction<boolean>('JOURNAL_ENTRIES/SET_EDITING');

export const clearJournalEntry = createAction('JOURNAL_ENTRIES/CLEAR');
export const clearJournalEntryDraft = createAction('JOURNAL_ENTRIES/CLEAR_DRAFT');

export const setJournalEntry = createAction<IJournalEntry>('JOURNAL_ENTRIES/SET');
export const setJournalEntryDraft = createAction<IJournalEntry>(
  'JOURNAL_ENTRIES/SET_JOURNALENTRY_DRAFT',
);
export const setJournalEntryDraftValues = createAction<Partial<IJournalEntry>>(
  'JOURNAL_ENTRIES/SET_JOURNALENTRY_DRAFT_VALUE',
);

export const setJournalEntriesList = createAction<IJournalEntry[]>('JOURNAL_ENTRIES/SET_LIST');

export const updateJournalEntriesList = createAction<IJournalEntry>('JOURNAL_ENTRIES/UPDATE_LIST');
export const removeJournalEntryFromList = createAction<number>('JOURNAL_ENTRIES/REMOVE_FROM_LIST');
export const duplicateJournalEntry = createAction<IJournalEntry>('JOURNALENTRIES/DUPLICATE');

export const setJournalEntryRelatedToSearchResult = createAction<IAccountRelatedTo | undefined>(
  'JOURNAL_ENTRIES/SET_RELATED_TO_SEARCH_RESULT',
);

export interface IFetchJournalEntriesParams {
  eTag?: IEtag;
  silent?: boolean;
}

export const fetchJournalEntriesList = createAsyncThunk<
  void,
  IFetchJournalEntriesParams,
  AppThunkConfig
>('JOURNAL_ENTRIES/FETCH_LIST', async ({ eTag, silent = false }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  if (!silent) {
    dispatch(startRequest('journal-entries'));
  }

  try {
    const { data } = await api.journalEntries.fetchJournalEntriesListAPI();
    dispatch(setJournalEntriesList(Array.isArray(data) ? data : []));

    if (eTag) {
      dispatch(setEtagsCurrentEtag(eTag));
    }
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'journalEntries',
        action: 'list',
      }),
    );
  } finally {
    if (!silent) {
      dispatch(endRequest('journal-entries'));
    }
  }
});

export const fetchJournalEntry = createAsyncThunk<void, number, AppThunkConfig>(
  'JOURNAL_ENTRIES/FETCH',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('journal-entries'));
    try {
      const { data } = await api.journalEntries.fetchJournalEntryAPI(id);
      dispatch(setJournalEntry(data));

      // return data;
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'journalEntries',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('journal-entries'));
    }
  },
);

export const duplicateJournalEntryLine = createAsyncThunk<void, number, AppThunkConfig>(
  'JOURNAL_ENTRIES/DUPLICATE_JOURNAL_ENTRY_LINE',
  async (id, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;

    const entity = getJournalEntryDraft(getState());
    const nextLines = cloneDeep(entity.lines);
    nextLines.push({
      ...nextLines.find((line) => line.lineId === id),
      lineId: getNegativeUniqueNumericId(),
    });
    dispatch(setJournalEntryDraftValues({ lines: nextLines }));
  },
);

export const invertJournalEntries = createAsyncThunk<void, void, AppThunkConfig>(
  'JOURNAL_ENTRIES/INVERT_JOURNAL_ENTRIES',
  async (id, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;

    const entity = getJournalEntryDraft(getState());

    const updateRowData = entity.lines.map((line) => {
      return {
        ...line,
        debit: line.credit,
        originalDebit: line.originalCredit,
        credit: line.debit,
        originalCredit: line.originalDebit,
      };
    });
    dispatch(setJournalEntryDraftValues({ lines: updateRowData }));
  },
);

export const balanceJournalEntryLine = createAsyncThunk<void, number, AppThunkConfig>(
  'JOURNAL_ENTRIES/BALANCE_JOURNAL_ENTRY_LINE',
  async (id, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;

    const entity = getJournalEntryDraft(getState());
    const totalDifference = getJournalEntryTotalDifference(getState());

    if (totalDifference < 0) {
      const nextLines = entity.lines.map((line) => {
        if (id === line.lineId) {
          return {
            ...line,
            debit: (line.debit || 0) + totalDifference * -1,
            originalDebit:
              ((line.debit || 0) + totalDifference * -1) * (line.parityToMainCurrency || 1),
            credit: 0,
            originalCredit: 0,
          };
        }
        return line;
      });
      dispatch(setJournalEntryDraftValues({ lines: nextLines }));
    } else {
      if (totalDifference > 0) {
        const nextLines = entity.lines.map((line) => {
          if (id === line.lineId) {
            return {
              ...line,
              credit: (line.credit || 0) + totalDifference,
              originalCredit:
                ((line.credit || 0) + totalDifference) * (line.parityToMainCurrency || 1),
              debit: 0,
              originalDebit: 0,
            };
          }
          return line;
        });
        dispatch(setJournalEntryDraftValues({ lines: nextLines }));
      }
    }
  },
);

export const updateJournalEntryRelatedToVatId = createAsyncThunk<
  void,
  IJournalEntryLine,
  AppThunkConfig
>('JOURNAL_ENTRIES/LOOKUP_JOURNAL_ENTRY_RELATED_TO_VAT_ID', async (updatedRowData, ThunkAPI) => {
  const { dispatch, extra, getState } = ThunkAPI;
  const { api } = extra;

  const entity = getJournalEntryDraft(getState());
  const nextLines = cloneDeep(entity.lines);
  const lineIndex = nextLines.findIndex(({ lineId }) => lineId === updatedRowData.lineId);

  // No VATId, reset related to fields
  const VATId = updatedRowData.relatedTo?.VATId?.trim();
  if (!VATId) {
    updatedRowData = {
      ...updatedRowData,
      relatedTo: { type: '', name: '', relatedId: 0, VATId: '' },
    };
    nextLines.splice(lineIndex, 1, updatedRowData);
    dispatch(setJournalEntryDraftValues({ lines: nextLines }));
    return;
  }

  let data;
  try {
    dispatch(startRequest('journal-entry-grid'));

    const resp = await api.accounts.getRelatedToAPI(VATId);
    data = resp.data;
  } catch (error) {
    dispatch(
      showToastAlert({
        type: 'error',
        title: '',
        message: getMessageFromError({
          error,
        }),
      }),
    );
  } finally {
    dispatch(endRequest('journal-entry-grid'));
  }

  if (!data) {
    updatedRowData = {
      ...updatedRowData,
      relatedTo: { type: '', name: '', relatedId: 0, VATId: '' },
    };
    nextLines.splice(lineIndex, 1, updatedRowData);
    dispatch(setJournalEntryDraftValues({ lines: nextLines }));
    return;
  }

  const numberOfRelatedTo = Object.values(data).reduce(
    (acc, value) => acc + (value?.length ?? 0),
    0,
  );

  // No related entities exist, void any related to data
  if (!numberOfRelatedTo) {
    updatedRowData = {
      ...updatedRowData,
      relatedTo: { type: '', name: '', relatedId: 0, VATId: '' },
    };
    nextLines.splice(lineIndex, 1, updatedRowData);
    dispatch(setJournalEntryDraftValues({ lines: nextLines }));
    return;
  }

  // Exactly one related to entity exists, auto select
  if (numberOfRelatedTo === 1) {
    const { contacts, customers, suppliers, employees } = data;

    let relatedToType;
    let relatedToName;
    let relatedToId;
    if (contacts?.length) {
      relatedToType = 'O';
      relatedToName = contacts[0].firstName + ' ' + contacts[0].lastName;
      relatedToId = contacts[0].contactId;
    } else if (customers?.length === 1 && !contacts && !suppliers && !employees) {
      relatedToType = 'C';
      relatedToName = customers[0].name;
      relatedToId = customers[0].customerId;
    } else if (suppliers?.length === 1 && !contacts && !customers && !employees) {
      relatedToType = 'S';
      relatedToName = suppliers[0].name;
      relatedToId = suppliers[0].supplierId;
    } else if (employees.length === 1 && !contacts && !suppliers && !customers) {
      relatedToType = 'E';
      relatedToName =
        employees[0].firstName + ' ' + employees[0].lastName1 + ' ' + employees[0].lastName2;
      relatedToId = employees[0].employeeId;
    }
    updatedRowData = {
      ...updatedRowData,
      relatedTo: { type: relatedToType, name: relatedToName, relatedId: relatedToId, VATId },
    };
    nextLines.splice(lineIndex, 1, updatedRowData);
    dispatch(setJournalEntryDraftValues({ lines: nextLines }));

    return;
  }

  // Multiple related to entities exist, save results for ui to handle.
  dispatch(setJournalEntryRelatedToSearchResult(data));
});
