import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IUnion } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Simple actions
export const clearUnion = createAction('UNION/CLEAR');

export const clearUnionDraft = createAction('UNION/CLEAR_DRAFT');

export const setUnion = createAction<IUnion>('UNION/SET_OUTPUT');

export const setUnionDraft = createAction<IUnion>('UNION/SET_DRAFT');

export const setUnionDraftValues = createAction<Partial<IUnion>>('UNION/SET_DRAFT_VALUE');

export const setUnionsList = createAction<IUnion[]>('UNION/SET_LIST');

export const updateUnionsList = createAction<IUnion>('UNION/UPDATE_LIST');

export const removeUnionFromList = createAction<number>('UNION/REMOVE_FROM_LIST');

export const duplicateUnion = createAction<IUnion>('UNION/DUPLICATE');

// Complex actions
export const fetchUnionsList = createAsyncThunk<void, void, AppThunkConfig>(
  'UNION/FETCH_UNION_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('unions'));
    try {
      const { data } = await api.unions.fetchUnionsListFromAPI();
      dispatch(setUnionsList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('unions'));
    }
  },
);

export const fetchUnion = createAsyncThunk<void, number, AppThunkConfig>(
  'UNION/FETCH_INPUT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('unions'));
    try {
      const { data } = await api.unions.fetchUnionFromAPI(id);
      dispatch(setUnion(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('unions'));
    }
  },
);

export const createUnion = createAsyncThunk<void, IUnion, AppThunkConfig>(
  'UNION/CREATE',
  async (union, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('unions'));
    try {
      const { data } = await api.unions.createUnionFromAPI(union);
      dispatch(setUnion(data));
      dispatch(fetchUnionsList());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('unions'));
    }
  },
);

export const updateUnion = createAsyncThunk<void, IUnion, AppThunkConfig>(
  'UNION/FETCH',
  async (union, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('unions'));
    try {
      const { data } = await api.unions.updateUnionFromAPI(union);
      dispatch(setUnion(data));
      dispatch(fetchUnionsList());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('unions'));
    }
  },
);

export const deleteUnion = createAsyncThunk<void, number, AppThunkConfig>(
  'UNION/DELETE_UNION',
  async (unionId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('unions'));
    try {
      await api.unions.deleteUnionFromAPI(unionId);
      dispatch(removeUnionFromList(unionId));
      dispatch(clearUnion());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.delete',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('unions'));
    }
  },
);

export const saveUnion = createAsyncThunk<void, IUnion, AppThunkConfig>(
  'UNION/SAVE',
  async (union, ThunkAPI) => {
    const { dispatch } = ThunkAPI;

    // if the union has an id, it means it already exists and we should update it
    if (union.unionId) {
      dispatch(updateUnion(union));
    } else {
      // if the union does not have an id, it means it is a new union and we should create it
      dispatch(createUnion(union));
    }
    dispatch(clearUnionDraft());
  },
);
