import { forwardRef, useImperativeHandle } from 'react';

import { Box, Flex, IconButton } from '@chakra-ui/react';
import { MenuItem } from '@szhsin/react-menu';

import { IconCopy, IconInventory, IconRemoveCircle } from '@laudus/icons';
import { useIntl } from '@laudus/intl';

import { RowActionMenu } from '../RowActionMenu';

const ProductsDeleteAndActionButtonRendererComponent: React.ForwardRefRenderFunction<any, any> = (
  props,
  ref,
) => {
  const intl = useIntl();

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return undefined;
      },
    };
  });

  const handleRemove = () => {
    props.context.onRemoveRow(props.rowIndex);
  };

  const handleDuplicateRow = () => {
    props.context.addDuplicateRow(props.rowIndex);
  };

  const handleShowProductStock = () => {
    props.context.showProductStock?.(props.rowIndex);
  };

  return (
    <Flex width="4rem">
      <IconButton
        aria-label="delete button"
        color="black"
        fontSize="17px"
        icon={<IconRemoveCircle className="delete-icon" />}
        mb="3px"
        ml="1px"
        mt="3px"
        onClick={handleRemove}
        variant="secondary-link"
      />
      {props.actionMenu && (
        <RowActionMenu dark={true} marginLeft="0px">
          <Box>
            <MenuItem onClick={handleDuplicateRow}>
              <i>
                <IconCopy />
              </i>
              {intl.formatMessage({ id: 'action.duplicate' })}
            </MenuItem>
            <MenuItem onClick={handleShowProductStock}>
              <i>
                <IconInventory />
              </i>
              {intl.formatMessage({ id: 'action.viewStock' })}
            </MenuItem>
          </Box>
        </RowActionMenu>
      )}
    </Flex>
  );
};

ProductsDeleteAndActionButtonRendererComponent.displayName =
  'ProductsDeleteAndActionButtonRendererComponent';

export const ProductsDeleteAndActionButtonRenderer = forwardRef(
  ProductsDeleteAndActionButtonRendererComponent,
);
