import { createReducer } from '@reduxjs/toolkit';

import {
  IAccountType,
  IBankReconciliation,
  IBankReconciliationFilter,
  IBankStatementsReconciliationItem,
  IJournalReconciliationItem,
  IncludeOtherBankStatementsTypeEnum,
  IWithdrawalReconciliation,
} from '@laudus/types';

import {
  clearBankReconciliation,
  clearBankReconciliationWithdrawal,
  setBankReconciliationBankStatementNotInJournal,
  setBankReconciliationFilterValues,
  setBankReconciliationJournalNotInBankStatement,
  setBankReconciliationWithdrawalValues,
  setGenericSuppliersAccount,
} from './actions';

export interface IBankReconciliationState {
  filter: IBankReconciliationFilter;
  bankStatementNotInJournal: IBankReconciliation<IBankStatementsReconciliationItem> | null;
  journalNotInBankStatement: IBankReconciliation<IJournalReconciliationItem> | null;
  withdrawalReconciliation: IWithdrawalReconciliation;
  // Javier and I discussed about having a set of generic accounts managed in a separately slice and
  // when to fetch / update them but by the moment this account will remain here and will be downloaded
  // only when it's not present. This may change in the future.
  genericSuppliersAccount: IAccountType | null;
}

export const BANK_RECONCILIATION_FILTER_EMPTY: IBankReconciliationFilter = {
  bankStatement: null,
  includeOtherBankStatements: IncludeOtherBankStatementsTypeEnum.None,
};

export const WITHDRAWAL_RECONCILIATION_EMPTY: IWithdrawalReconciliation = {
  withdrawal: null,
  candidatePurchaseInvoices: [],
  candidatePayments: [],
};

export const initialBankReconciliationState: IBankReconciliationState = {
  filter: BANK_RECONCILIATION_FILTER_EMPTY,
  bankStatementNotInJournal: null,
  journalNotInBankStatement: null,
  withdrawalReconciliation: WITHDRAWAL_RECONCILIATION_EMPTY,
  genericSuppliersAccount: null,
};

export const bankReconciliationReducer = createReducer(
  initialBankReconciliationState,
  (builder) => {
    builder
      .addCase(clearBankReconciliation, (state) => {
        return {
          ...state,
          filter: BANK_RECONCILIATION_FILTER_EMPTY,
          bankStatementNotInJournal: null,
          journalNotInBankStatement: null,
          withdrawalReconciliation: WITHDRAWAL_RECONCILIATION_EMPTY,
        };
      })
      .addCase(setBankReconciliationFilterValues, (state, action) => {
        return {
          ...state,
          filter: { ...state.filter, ...action.payload },
        };
      })
      .addCase(setBankReconciliationBankStatementNotInJournal, (state, action) => {
        return {
          ...state,
          bankStatementNotInJournal: action.payload,
        };
      })
      .addCase(setBankReconciliationJournalNotInBankStatement, (state, action) => {
        return {
          ...state,
          journalNotInBankStatement: action.payload,
        };
      })
      .addCase(clearBankReconciliationWithdrawal, (state) => {
        return {
          ...state,
          withdrawalReconciliation: WITHDRAWAL_RECONCILIATION_EMPTY,
        };
      })
      .addCase(setBankReconciliationWithdrawalValues, (state, action) => {
        return {
          ...state,
          withdrawalReconciliation: {
            ...state.withdrawalReconciliation,
            ...action.payload,
          },
        };
      })
      .addCase(setGenericSuppliersAccount, (state, action) => {
        return {
          ...state,
          genericSuppliersAccount: action.payload,
        };
      })
      .addDefaultCase((state) => state);
  },
);
