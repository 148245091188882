import * as Yup from 'yup';

import { intl } from '@laudus/intl';

export const RequestPasswordSchema = Yup.object().shape({
  loginName: Yup.string().required(intl.formatMessage({ id: 'forms.required' })),
});

export const NewPasswordSchema = Yup.object().shape({
  password: Yup.string().required(intl.formatMessage({ id: 'forms.required' })),
  confirmPassword: Yup.string()
    .required(intl.formatMessage({ id: 'forms.required' }))
    .oneOf([Yup.ref('password')], intl.formatMessage({ id: 'resetPassword.match' })),
});
