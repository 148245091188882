import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IShowErrorParams } from '@laudus/redux-global';
import {
  createSalesOrderFromSalesQuote,
  generateSalesOrderPDF,
  updateDraftSalesItems,
} from '@laudus/sales-utils';
import {
  getMessageFromError,
  getMessageFromSpecialAPIErrorCode,
  printPDF,
} from '@laudus/shared-utils';
import { IPrintTemplateSettings, templateIndex } from '@laudus/templates-pdf';
import {
  IAPIError,
  ISalesOrder,
  ISalesOrderItem,
  ISalesOrderList,
  ITraceFrom,
} from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert, showToastAlert } from '../alerts';
import { getMainCurrency } from '../currencies';
import { resetCustomer } from '../customers';
import { getCustomFieldsByEntity } from '../customFields';
import { endRequest, startRequest } from '../global/actions';
import {
  clearSalesInvoice,
  clearSalesInvoiceDraft,
  clearSalesInvoicePDFUrl,
} from '../salesInvoices';
import { getPrintTemplateSettings, getSettings, getSettingsByName } from '../settings';
import { addTab } from '../tabs';
import { getUser } from '../users';

import { getSalesOrderDraft } from './selectors';

export const SALESORDERS_TAB_ID = 'salesOrders';

// SalesOrders Tab action creators

export const addHomeSalesOrderTab = () =>
  addTab({
    tab: {
      id: SALESORDERS_TAB_ID,
      title: intl.formatMessage({ id: 'salesOrders.tabTitle' }),
      path: 'pages/SalesOrders/SalesOrders',
      isRemovable: true,
    },
  });

export const addViewSalesOrderTab = (id?: number) =>
  addTab({
    tab: {
      id: SALESORDERS_TAB_ID,
      title: intl.formatMessage({ id: 'salesOrders.tabTitle' }),
      path: 'pages/SalesOrders/SalesOrdersView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewSalesOrderTab = () =>
  addTab({
    tab: {
      id: SALESORDERS_TAB_ID,
      title: intl.formatMessage({ id: 'salesOrders.tabTitle' }),
      path: 'pages/SalesOrders/SalesOrdersNew',
      isRemovable: true,
    },
  });

export const addEditSalesOrderTab = (id?: number) =>
  addTab({
    tab: {
      id: SALESORDERS_TAB_ID,
      title: intl.formatMessage({ id: 'salesOrders.tabTitle' }),
      path: 'pages/SalesOrders/SalesOrdersEdit',
      props: { id },
      isRemovable: true,
    },
  });

// Simple actions
export const clearSalesOrder = createAction('SALES_ORDERS/CLEAR');
export const clearSalesOrderDraft = createAction('SALES_ORDERS/CLEAR_DRAFT');
export const clearSalesOrderPDFUrl = createAction(`$SALES_ORDERS/CLEAR_PDF_URL`);

export const setSalesOrder = createAction<ISalesOrder>('SALES_ORDERS/SET');
export const setSalesOrderDraft = createAction<ISalesOrder>('SALES_ORDERS/SET_DRAFT');
export const setSalesOrderDraftValues = createAction<Partial<ISalesOrder>>(
  'SALES_ORDERS/SET_DRAFT_VALUES',
);

export const setSalesOrderPDFUrl = createAction<string>(`SALES_ORDERS/SET_PDF_URL`);

export const setSalesOrderList = createAction<ISalesOrderList>('SALES_ORDERS/SET_LIST');

export const setSalesOrderTraces = createAction<ITraceFrom[]>('SALES_ORDERS/SET_TRACES');

export const updateSalesOrderList = createAction<ISalesOrder>('SALES_ORDERS/UPDATE_LIST');

export const removeSalesOrderFromList = createAction<number>('SALES_ORDERS/REMOVE_FROM_LIST');

export const duplicateSalesOrder = createAction<ISalesOrder>('SALES_ORDERS/DUPLICATE');

// Complex actions
export const fetchSalesOrderList = createAsyncThunk<void, void, AppThunkConfig>(
  'SALES_ORDERS/FETCH_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('sale-orders'));
    try {
      const { data } = await api.salesOrders.fetchSalesOrderListFromAPI();
      const salesOrderList = Array.isArray(data) ? data : [];
      const nonNullSalesOrder = salesOrderList.filter((salesOrder) => salesOrder.nullDoc !== true);
      dispatch(setSalesOrderList(nonNullSalesOrder));
    } catch (error) {
      dispatch(
        showToastAlert({
          type: 'error',
          title: intl.formatMessage({ id: 'salesOrders.errorToast.list' }),
          message: getMessageFromError({ error }),
        }),
      );
    } finally {
      dispatch(endRequest('sale-orders'));
    }
  },
);

export const fetchSalesOrder = createAsyncThunk<void, number, AppThunkConfig>(
  'SALES_ORDERS/FETCH',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('sale-orders'));
      const { data } = await api.salesOrders.fetchSalesOrderFromAPI(id);

      if (!data.items) {
        data.items = [];
      }

      dispatch(setSalesOrder(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesOrders',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('sale-orders'));
    }
  },
);

export const updateSalesOrderDraftItem = createAsyncThunk<void, ISalesOrderItem, AppThunkConfig>(
  'SALES_ORDERS/UPDATE_SALES_ORDER_DRAFT_ITEM',
  async (updatedItem, ThunkAPI) => {
    const { dispatch, getState, extra } = ThunkAPI;
    const { api } = extra;

    const { mainCurrencyDecimals } = getMainCurrency(getState());

    const draft = getSalesOrderDraft(getState());
    const updateDraftItems = await updateDraftSalesItems(
      updatedItem,
      draft,
      'orders',
      mainCurrencyDecimals,
      api.products.fetchProductSalesPriceAPI,
    );

    dispatch(setSalesOrderDraftValues({ items: updateDraftItems }));
  },
);

export const createSalesOrderFromInvoiceItems = createAsyncThunk<void, ISalesOrder, AppThunkConfig>(
  'SALES_INVOICES/CREATE_FROM_INVOICE_ITEMS',
  async (salesOrder, ThunkAPI) => {
    const { extra, dispatch } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('sales-orders'));
    try {
      const { data } = await api.salesOrders.createSalesOrderFromAPI(salesOrder);

      dispatch(resetCustomer());
      dispatch(clearSalesInvoice());
      dispatch(clearSalesInvoicePDFUrl());
      dispatch(clearSalesInvoiceDraft());

      dispatch(setSalesOrder(data));

      dispatch(printPOSSalesOrderPDF(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesOrders',
          action: 'createFromInvoiceItems' as IShowErrorParams['action'],
        }),
      );
    } finally {
      dispatch(endRequest('sales-orders'));
    }
  },
);

// PDF
export const printDefaultPOSSalesOrderPDF = createAsyncThunk<void, ISalesOrder, AppThunkConfig>(
  'SALES_ORDER/POS_PRINT_DEFAULT_PDF',
  async (salesOrder, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;
    const state = getState();

    const printTemplateSettings = getPrintTemplateSettings('salesOrder')(state);

    if (!printTemplateSettings) {
      // If there is not template defined in setting, we use the default one generated in the front
      const companyName = getSettingsByName<string>('name')(state);
      const companyAddress = getSettingsByName<string>('address')(state);
      const companyVATId = getSettingsByName<string>('VATId')(state);
      const DEFAULT_TEMPLATE: IPrintTemplateSettings = {
        source: 'front',
        template: 'salesOrder_1',
        options: {
          logo: '',
          header: btoa(
            `<table><tr><td>${companyName}</td></tr><tr><td>${companyAddress}</td></tr><tr><td>${companyVATId}</td></tr></table>`,
          ),
          numberOfCopies: 1,
        },
      };
      dispatch(
        createSalesOrderPOSPDFInFrontEnd({
          salesOrder,
          printTemplateSettings: DEFAULT_TEMPLATE,
        }),
      );
    }
  },
);

export const printPOSSalesOrderPDF = createAsyncThunk<void, ISalesOrder, AppThunkConfig>(
  'SALES_ORDER/POS_PRINT_PDF',
  async (salesOrder, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;
    const state = getState();

    const salesOrderId = salesOrder.salesOrderId ?? 0;
    const printTemplateSettings = getPrintTemplateSettings('salesOrder')(state);

    if (!printTemplateSettings) {
      // If there is not template defined in setting, we use the default one generated in the front
      dispatch(printDefaultPOSSalesOrderPDF(salesOrder));
    } else if (typeof printTemplateSettings === 'string') {
      // If the template in settings is defined for the original Desktop EPR (as a string), we fetch the PDF from the back
      dispatch(fetchPOSSalesOrderPDFFromBackEnd(salesOrderId));
    } else if (printTemplateSettings?.source === 'front') {
      // If the template in settings is defined as an object and the source is 'front', we render the PDF in the front
      dispatch(
        createSalesOrderPOSPDFInFrontEnd({
          salesOrder,
          printTemplateSettings,
        }),
      );
    } else if (printTemplateSettings?.source === 'back') {
      // If the template in settings is defined as an object and the source is 'back', we fetch the PDF from the back
      dispatch(fetchPOSSalesOrderPDFFromBackEnd(salesOrderId));
    } else {
      dispatch(
        showAlert({
          title: intl.formatMessage({ id: 'printer.error.title' }),
          message: intl.formatMessage({ id: 'printer.error.template.source.message' }),
          type: 'error',
        }),
      );
    }
  },
);

export const fetchPOSSalesOrderPDFFromBackEnd = createAsyncThunk<void, number, AppThunkConfig>(
  'SALES_ORDER/FETCH_PDF_FROM_BACKEND',
  async (salesOrderId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('fetch-sales-order-pdf-from-backend'));
    try {
      const urlBlob = await api.salesOrders.fetchSalesOrderPDFFromAPI(salesOrderId);
      printPDF(urlBlob);
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesOrders',
          action: 'readPDF' as IShowErrorParams['action'],
        }),
      );
    } finally {
      dispatch(endRequest('fetch-sales-order-pdf-from-backend'));
    }
  },
);

interface IcreateSalesOrderPOSPDFInFrontEndParams {
  salesOrder: ISalesOrder;
  printTemplateSettings: IPrintTemplateSettings;
}
export const createSalesOrderPOSPDFInFrontEnd = createAsyncThunk<
  void,
  IcreateSalesOrderPOSPDFInFrontEndParams,
  AppThunkConfig
>('SALES_ORDER/CREATE_PDF_IN_FRONTEND', async ({ salesOrder, printTemplateSettings }, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;
  const state = getState();
  const settingCompanyVATId = getSettingsByName<string>('VATId')(state);
  const VATRateFromSettings = getSettingsByName<number>('VATRate')(state);
  const { mainCurrencyDecimals } = getMainCurrency(state);

  dispatch(startRequest('fetch-sales-order-pdf-from-frontend'));
  try {
    const { renderPDF } = await templateIndex[printTemplateSettings.template]();
    const url = await renderPDF({
      pageWidth: printTemplateSettings.options.pageWidth,
      pageHeight: printTemplateSettings.options.pageHeight,
      logo: printTemplateSettings.options.logo,
      header: atob(printTemplateSettings.options.header),
      data: salesOrder,
      settingCompanyVATId,
      barcode: '',
      mainCurrencyDecimals,
      VATRateFromSettings,
    });

    printPDF(url);
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'salesOrders',
        action: 'readPDF' as IShowErrorParams['action'],
      }),
    );
  } finally {
    dispatch(endRequest('fetch-sales-order-pdf-from-frontend'));
  }
});

// CREATE ORDER FROM OTHER SALES DOCUMENTS
export const convertSalesQuoteIntoSalesOrderDraft = createAsyncThunk<
  void,
  {
    initialOrder: ISalesOrder;
    salesQuoteId: number;
    shouldRedirectToNewView: boolean;
  },
  AppThunkConfig
>(
  'SALES_ORDERS/CREATE_SALES_ORDER_FROM_SALES_QUOTE',
  async ({ initialOrder, salesQuoteId, shouldRedirectToNewView }, ThunkAPI) => {
    const { dispatch, getState, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('sales-orders'));

    // State from Redux
    const state = getState();

    const user = getUser(state);
    const settings = getSettings(state);

    const customFieldsForSalesOrders = getCustomFieldsByEntity('salesOrders')(state);
    const customFieldsForSalesOrdersItems = getCustomFieldsByEntity('salesOrdersItems')(state);
    const customFieldsForSalesQuotes = getCustomFieldsByEntity('salesQuotes')(state);
    const customFieldsForSalesQuotesItems = getCustomFieldsByEntity('salesQuotesItems')(state);

    try {
      // Derived state
      const shouldCopyNotesToOrder = settings.sales_orders_copyNotes?.keyValue === true;

      const doesSalesQuoteHaveToBeApproved =
        settings.sales_quotes_haveToBeApproved?.keyValue === true;
      const doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts =
        settings.sales_quotes_haveToBeApprovedOnlyWhenDiscounts?.keyValue === true;

      // Get the sales quote
      const { data: salesQuote } = await api.salesQuotes.fetchSalesQuoteFromAPI(salesQuoteId);

      if (!salesQuote) {
        throw new Error(
          `No se ha conseguido encontrar una cotización de ventas con el id ${salesQuoteId}`,
        );
      }

      // Get how much is delivered in this sales quote
      const { data: quantityDeliveredResponse } =
        await api.salesQuotes.getQuantityDeliveredBySalesQuoteFromAPI(salesQuoteId);

      const quantityDeliveredFromQuote =
        !!quantityDeliveredResponse && Array.isArray(quantityDeliveredResponse)
          ? quantityDeliveredResponse
          : [];

      // Create the order from the quote
      const orderFromQuote = await createSalesOrderFromSalesQuote({
        initialOrder,
        salesQuote,
        user,
        customFieldsForSalesOrders,
        customFieldsForSalesOrdersItems,
        customFieldsForSalesQuotes,
        customFieldsForSalesQuotesItems,
        shouldCopyNotesToOrder,
        quantityDeliveredFromQuote,
        doesSalesQuoteHaveToBeApproved,
        doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts,
      });

      // Update Redux
      dispatch(setSalesOrderDraft(orderFromQuote));

      // Alert about some items being already delivered
      const isSomeOrderItemAlreadyDelivered = quantityDeliveredFromQuote.some(
        (quantityDelivered) => quantityDelivered.delivered > 0,
      );

      if (isSomeOrderItemAlreadyDelivered) {
        dispatch(
          showAlert({
            type: 'info',
            title: intl.formatMessage({
              id: 'salesOrders.infoToast.createFromSalesQuote.itemsAlreadyDelivered',
            }),
            message: '',
          }),
        );
      }

      // Redirect if neccesary
      if (shouldRedirectToNewView) {
        dispatch(addNewSalesOrderTab());
      }
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'salesOrders.errorToast.createFromSalesQuote',
          }),
          // Try to get message by looking for special code
          message:
            getMessageFromSpecialAPIErrorCode(error as IAPIError) ?? getMessageFromError({ error }),
        }),
      );
    } finally {
      dispatch(endRequest('sales-orders'));
    }
  },
);

export const createSalesOrderAndPrint = createAsyncThunk<void, ISalesOrder, AppThunkConfig>(
  `SALES_ORDERS/CREATE_AND_PRINT`,
  async (salesOrder, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('sales-orders'));

    try {
      const { data: newSalesOrder } = await api.salesOrders.createSalesOrderFromAPI(salesOrder);
      dispatch(setSalesOrder(newSalesOrder));
      dispatch(updateSalesOrderList(newSalesOrder));
      dispatch(
        printERPSalesOrderPDF({
          salesOrder: newSalesOrder,
          onGetURL: () => {
            dispatch(addViewSalesOrderTab());
          },
        }),
      );
    } catch (error) {
      dispatch(endRequest('sales-orders'));
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesOrders',
          action: 'save',
        }),
      );
    }
  },
);

export const updateSalesOrderAndPrint = createAsyncThunk<void, ISalesOrder, AppThunkConfig>(
  `SALES_ORDERS/UPDATE_AND_PRINT`,
  async (salesOrder, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('sales-orders'));

    try {
      const { data: updatedSalesOrder } = await api.salesOrders.updateSalesOrderFromAPI(salesOrder);
      dispatch(setSalesOrder(updatedSalesOrder));
      dispatch(updateSalesOrderList(updatedSalesOrder));
      dispatch(
        printERPSalesOrderPDF({
          salesOrder: updatedSalesOrder,
          onGetURL: () => {
            dispatch(addViewSalesOrderTab());
          },
        }),
      );
    } catch (error) {
      dispatch(endRequest('sales-orders'));
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesOrders',
          action: 'save',
        }),
      );
    }
  },
);

interface IPrintERPSalesOrderPDF {
  salesOrder: ISalesOrder;
  onGetURL: (url: string) => void;
}

const printDefaultERPSalesOrderPDF = createAsyncThunk<void, IPrintERPSalesOrderPDF, AppThunkConfig>(
  'SALES_ORDERS/ERP_PRINT_DEFAULT_PDF',
  async ({ salesOrder, onGetURL }, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;
    const state = getState();

    const settingCompanyName = getSettingsByName<string>('name')(state);
    const settingCompanyVATId = getSettingsByName<string>('VATId')(state);
    const settingCompanyVATRate = getSettingsByName<string>('VATRate')(state);
    const { mainCurrencyCode, mainCurrencyDecimals } = getMainCurrency(state);
    const decimalsUnitPriceSales = getSettingsByName<number>('decimals_unitPrice_sales')(state);

    const pdf = generateSalesOrderPDF({
      salesOrder,
      companyName: `${settingCompanyName ?? ''}`,
      companyVATId: `${settingCompanyVATId ?? ''}`,
      companyVATRate: settingCompanyVATRate ? Number(settingCompanyVATRate) : 0,
      mainCurrencyDecimals,
      mainCurrencyCode,
      decimalsUnitPrice: decimalsUnitPriceSales,
    });
    const urlBlob = URL.createObjectURL(pdf);
    dispatch(setSalesOrderPDFUrl(urlBlob));
    onGetURL(urlBlob);
  },
);

const fetchERPSalesOrderPDFFromBackEnd = createAsyncThunk<
  void,
  IPrintERPSalesOrderPDF,
  AppThunkConfig
>('SALES_ORDER/FETCH_ERP_PDF_FROM_BACKEND', async ({ salesOrder, onGetURL }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;
  const salesOrderId = salesOrder.salesOrderId ?? 0;
  try {
    dispatch(startRequest('fetch-sales-order-pdf-from-backend'));
    const urlBlob = await api.salesOrders.fetchSalesOrderPDFFromAPI(salesOrderId);
    dispatch(setSalesOrderPDFUrl(urlBlob));
    onGetURL(urlBlob);
  } catch (error) {
    dispatch(
      showAlert({
        type: 'error',
        title: intl.formatMessage({ id: 'pdfViewer.notFound' }),
        message: getMessageFromError({ error }),
      }),
    );
  } finally {
    dispatch(endRequest('fetch-sales-order-pdf-from-backend'));
  }
});

export const printERPSalesOrderPDF = createAsyncThunk<void, IPrintERPSalesOrderPDF, AppThunkConfig>(
  `SALES_ORDERS/ERP_PRINT_PDF`,
  async ({ salesOrder, onGetURL }, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;
    const state = getState();

    const printTemplateSettings = getPrintTemplateSettings('salesOrder')(state);

    if (!printTemplateSettings) {
      // If there is not template defined in setting, we use the default one generated in the front
      dispatch(printDefaultERPSalesOrderPDF({ salesOrder, onGetURL }));
    } else if (typeof printTemplateSettings === 'string') {
      // If the template in settings is defined for the original Desktop EPR (as a string), we fetch the PDF from the back
      dispatch(fetchERPSalesOrderPDFFromBackEnd({ salesOrder, onGetURL }));
    } else if (printTemplateSettings?.source === 'front') {
      // If the template in settings is defined as an object and the source is 'front', we render the PDF in the front
      //TODO: In the future this template shouldn't be the default, we need to define how to handle the different templates of both app in the settings
      dispatch(printDefaultERPSalesOrderPDF({ salesOrder, onGetURL }));
    } else if (printTemplateSettings?.source === 'back') {
      // If the template in settings is defined as an object and the source is 'back', we fetch the PDF from the back
      dispatch(fetchERPSalesOrderPDFFromBackEnd({ salesOrder, onGetURL }));
    } else {
      dispatch(
        showAlert({
          title: intl.formatMessage({ id: 'printer.error.title' }),
          message: intl.formatMessage({ id: 'printer.error.template.source.message' }),
          type: 'error',
        }),
      );
    }
  },
);

export const fetchSalesOrderTraces = createAsyncThunk<void, number, AppThunkConfig>(
  `SALES_ORDERS/FETCH_TRACES`,
  async (salesOrderId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('sales-orders'));
      const { data } = await api.salesOrders.fetchSalesOrderTracesFromAPI(salesOrderId);

      const traces = Array.isArray(data) ? data : [];
      dispatch(setSalesOrderTraces(traces));
    } catch (error) {
      dispatch(setSalesOrderTraces([]));
    } finally {
      dispatch(endRequest('sales-orders'));
    }
  },
);
