import { AxiosResponse } from 'axios';

import { IWarehouse } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchWarehouseListFromAPI = (): Promise<AxiosResponse<IWarehouse[]>> =>
  axios.post(
    `${VITE_API_URL}/production/warehouses/list`,
    {
      fields: ['name', 'warehouseId', 'address'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchWarehouseFromAPI = (warehouseId: string): Promise<AxiosResponse<IWarehouse>> =>
  axios.get(`${VITE_API_URL}/production/warehouses/${warehouseId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createWarehouseFromAPI = (warehouse: IWarehouse): Promise<AxiosResponse<IWarehouse>> =>
  axios.post(`${VITE_API_URL}/production/warehouses`, warehouse);

export const updateWarehouseFromAPI = (warehouse: IWarehouse): Promise<AxiosResponse<IWarehouse>> =>
  axios.put(`${VITE_API_URL}/production/warehouses/${warehouse.warehouseId}`, warehouse);

export const deleteWarehouseFromAPI = (warehouseId: string): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/production/warehouses/${warehouseId}`);
