import { createReducer } from '@reduxjs/toolkit';

import { IPos } from '@laudus/types';

import {
  clearPos,
  clearPosDraft,
  duplicatePos,
  removePosFromList,
  setPosCurrent,
  setPosDraft,
  setPosDraftValues,
  setPosList,
  updatePosList,
} from './actions';

export const POS_TAB_ID = 'pos';

export interface IPosState {
  current: IPos;
  draft: IPos;
  list: IPos[];
}

export const POS_EMPTY: IPos = {
  posId: 0,
  name: '',
  branch: {
    branchId: 0,
    name: '',
  },
  ticketsPrinter: '',
  ticketsPrintAfterSave: false,
  ticketsPromptForPrinter: false,
  invoicesPrinter: '',
  invoicesPrintAfterSave: false,
  invoicesPromptForPrinter: false,
  cardReceiptsPrinter: '',
  cardReceiptsPrintAfterSave: false,
  cardReceiptsPromptForPrinter: false,
  creditCardTerminalNumber: '',
  transbankTerminal: false,
  documentTypes: [
    {
      posDocTypeId: 0,
      docType: {
        docTypeId: 0,
        name: '',
      },
    },
  ],
};

export const initialPosState: IPosState = {
  current: POS_EMPTY,
  draft: POS_EMPTY,
  list: [],
};

export const posReducer = createReducer(initialPosState, (builder) => {
  builder
    .addCase(clearPos, (state) => {
      return { ...state, current: POS_EMPTY };
    })
    .addCase(clearPosDraft, (state) => {
      return { ...state, draft: POS_EMPTY };
    })
    .addCase(setPosCurrent, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setPosDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setPosDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setPosList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updatePosList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.posId !== action.payload.posId), action.payload],
      };
    })
    .addCase(removePosFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.posId !== action.payload),
      };
    })
    .addCase(duplicatePos, (state, action) => {
      const { posId, ...duplicatedPos } = action.payload;
      const duplicatedDocumentTypes = duplicatedPos.documentTypes
        ? [...duplicatedPos.documentTypes].map((doc) => {
            return { ...doc, posDocTypeId: 0 };
          })
        : [];
      return {
        ...state,
        draft: {
          ...duplicatedPos,
          documentTypes: [...duplicatedDocumentTypes],
        },
      };
    })
    .addDefaultCase((state) => state);
});
