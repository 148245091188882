import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ICellEditorParams } from '@ag-grid-community/core';
import { Box } from '@chakra-ui/react';

import {
  colors,
  KEY_DOWN,
  KEY_END,
  KEY_ENTER,
  KEY_HOME,
  KEY_LEFT,
  KEY_RIGHT,
  KEY_TAB,
  KEY_UP,
} from '@laudus/shared-ui';

interface ICheckboxEditorComponentProps extends ICellEditorParams {
  name: string;
}

const CheckboxEditorComponent: React.ForwardRefRenderFunction<
  any,
  ICheckboxEditorComponentProps
> = (props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [localValue, setLocalValue] = useState<boolean | null>(null);
  const [commitValue, setCommitValue] = useState<boolean>(!!props.value);
  const value = localValue ?? commitValue;

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return commitValue;
      },
    };
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCommitValue(!!event.target.checked);
  };

  const handleKeyDown = (event: any) => {
    // Hitting enter will check/ uncheck the input, but will not commit the change so that the cell
    // is not re-rendered unnecessarily... the change is only committed when the cell loses focus.
    if (event.key === KEY_ENTER) {
      event.preventDefault();
      setLocalValue(!value);
      return;
    }

    // Any of these keys represent the users intent to movement away from the current cell and
    // therefore we must commit the value so that it's persisted to state
    if (
      event.key === KEY_TAB ||
      event.key === KEY_LEFT ||
      event.key === KEY_RIGHT ||
      event.key === KEY_UP ||
      event.key === KEY_DOWN ||
      event.key === KEY_HOME ||
      event.key === KEY_END
    ) {
      event.preventDefault();
      setCommitValue(value);
      props.context.handleKeyPress(event);
      return;
    }
  };

  useEffect(() => {
    inputRef?.current?.focus();

    inputRef?.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      inputRef?.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [value]);

  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        textAlign: 'right',
        paddingRight: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <input
        checked={value}
        name={props.name}
        onChange={handleChange}
        ref={inputRef}
        style={{ accentColor: colors.primary500, width: '1.6rem', height: '1.6rem' }}
        type="checkbox"
      />
    </Box>
  );
};

CheckboxEditorComponent.displayName = 'CheckboxEditor';

export const CheckboxEditor = forwardRef(CheckboxEditorComponent);
