import { roundDecimals } from '@laudus/shared-utils';
import { ISalesItem } from '@laudus/types';

export const calculateSalesItemNet = (item: ISalesItem, mainCurrencyDecimals: number): number => {
  const { unitPrice, discountPercentage, quantity } = item;

  const itemNet = roundDecimals(
    ((unitPrice * (100 - discountPercentage)) / 100) * quantity,
    mainCurrencyDecimals,
  );

  return itemNet;
};

export const calculateSalesDocumentNet = (
  items: ISalesItem[],
  mainCurrencyDecimals: number,
): number => {
  let totalOfAllItems = 0;

  items?.forEach((item) => {
    totalOfAllItems += calculateSalesItemNet(item, mainCurrencyDecimals);
  });

  return totalOfAllItems;
};
