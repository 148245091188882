import {
  ComponentPropsWithRef,
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { KEY_BACKSPACE, KEY_DELETE, KEY_DOWN, KEY_UP } from '../../constants/keyboard';

// Utility functions, perhaps they should go to a different file.

const getCurrentSelection = (target: HTMLInputElement) => {
  return {
    start: target.selectionStart ?? -1,
    end: target.selectionEnd ?? -1,
  };
};

const removeSelection = (value: string, start: number, end: number) => {
  if (start === end) {
    return value;
  }

  return (
    (start > 0 ? value.substring(0, start) : '') + (end < value.length ? value.substring(end) : '')
  );
};

const getDiffValue = (prevValue: string, newValue: string) => {
  let i = 0,
    j = 0;
  const prevLength = prevValue.length;
  const newLength = newValue.length;
  while (prevValue[i] === newValue[i] && i < prevLength) i++;

  //check what has been changed from last
  while (
    prevValue[prevLength - 1 - j] === newValue[newLength - 1 - j] &&
    newLength - j > i &&
    prevLength - j > i
  ) {
    j++;
  }

  return newValue.substring(i, newLength - j);
};

const formatNumber = (value: string, precision?: number) => {
  const m = /^([-])?(\d*)(?:\.(\d*))?$/.exec(value);
  const [, sign, integer, decimals] = m || ['0', '', '0', '0'];
  const formattedSign = sign === '-' ? '-' : '';
  const formattedInteger = integer && integer.length ? integer.replace(/^[-]?0+(?=\d)/, '') : '0';
  let formattedDecimals = '';
  if (precision) {
    if (!decimals) {
      formattedDecimals = '.'.padEnd(precision + 1, '0');
    } else {
      formattedDecimals = `.${
        decimals.length < precision ? decimals.padEnd(precision, '0') : decimals.slice(0, precision)
      }`;
    }
  }
  const formattedValue = `${formattedSign}${formattedInteger}${formattedDecimals}`;

  return formattedValue;
};

const formatDisplayString = (
  value: string,
  precision: number,
  isFocused: boolean,
  thousandsSeparator: boolean,
) => {
  if (isFocused || !thousandsSeparator) {
    return String(value).replace('.', ',');
  }
  const numberToFormat = parseFloat(String(value));
  return !isNaN(numberToFormat)
    ? new Intl.NumberFormat('de-DE', {
        minimumFractionDigits: precision,
        maximumFractionDigits: 20,
      }).format(numberToFormat)
    : '0';
};

const normalizeCursorOnDeletion = (value: string, currentPosition: number) => {
  if (currentPosition < 0) {
    return 0;
  } else if (currentPosition > value.length) {
    return value.length;
  } else if (/^-?0\./.test(value) && currentPosition <= 1) {
    return value.startsWith('-') ? 2 : 1;
  }
  return currentPosition;
};

const secureStep = (
  action: TriggerStepAction,
  value: number,
  step: number,
  precision: number,
  min?: number,
  max?: number,
) => {
  if (step < Math.pow(10, -1 * precision)) {
    return value;
  }

  const factor = Math.pow(10, Math.trunc(precision));

  let newValue =
    action === 'increment'
      ? Math.round(value * factor + step * factor) / factor
      : Math.round(value * factor - step * factor) / factor;

  if (max !== undefined && action === 'increment' && newValue > max) {
    newValue = max;
  } else if (min !== undefined && action === 'decrement' && newValue < min) {
    newValue = min;
  }

  return newValue;
};

interface IInputSelection {
  start: number;
  end: number;
}

export type TriggerStepAction = 'increment' | 'decrement';

export interface IStepsHandle extends HTMLInputElement {
  stepUp: (n?: number) => void;
  stepDown: (n?: number) => void;
}

export interface ICustomNumberInputProps extends ComponentPropsWithRef<'input'> {
  min?: number;
  max?: number;
  step?: number;
  disableStepAction?: boolean;
  precision?: number;
  thousandsSeparator?: boolean;
  value?: number;
}

const CustomNumberInputComponent: React.ForwardRefRenderFunction<
  IStepsHandle,
  ICustomNumberInputProps
> = (
  {
    disabled,
    readOnly,
    min,
    max,
    step = 1,
    onFocus,
    onBlur,
    onChange,
    disableStepAction,
    precision = 4,
    thousandsSeparator = true,
    value,
    ...rest
  },
  ref,
) => {
  // Reference to DOM input element
  const inputRef = useRef<HTMLInputElement>(null);
  // String representation of the value to show in the input
  const [inputValue, setInputValue] = useState(formatNumber('' + (value ?? 0), precision));
  // Text selection or caret position if start === end
  const [inputSelection, setInputSelection] = useState<IInputSelection>({
    start: 0,
    end: 0,
  });
  // Controls how to visualize the value (inherited from old NumberField)
  const [isFocused, setIsFocused] = useState(false);
  // Ref to emitted value
  const emittedValue = useRef<number>();

  const generateChangeEvent = (value: string) => {
    const nativeInputValueSetter = Object.getOwnPropertyDescriptor(
      window.HTMLInputElement.prototype,
      'value',
    )?.set;
    //emittedValue.current = Number(value);
    // Here we have to put a different value from input value to keep react preventing
    // the event.
    nativeInputValueSetter?.call(inputRef.current, value);
    const event = new Event('input', { bubbles: true });
    inputRef.current?.dispatchEvent(event);
  };

  const setSelection = (start: number, end: number) => {
    if (inputRef.current) {
      inputRef.current.selectionStart = start;
      inputRef.current.selectionEnd = end;
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(true);
    setInputSelection({
      start: 0,
      end: inputValue.length,
    });
    onFocus?.(event);
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    setIsFocused(false);
    onBlur?.(event);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const el = event.target as HTMLInputElement;
    const { start } = getCurrentSelection(el);
    const targetValue = el.value.replaceAll(',', '.');
    const diff = getDiffValue(inputValue, targetValue);

    let newInputValue = targetValue;
    let newCaretPosition = start;

    if (targetValue === '' || targetValue === '-' || /^-?\./.test(targetValue)) {
      // Normalize strings.
      newInputValue = formatNumber(targetValue, precision);
      newCaretPosition = normalizeCursorOnDeletion(newInputValue, start);
    } else if (targetValue.length < inputValue.length || diff === targetValue || diff.length > 1) {
      newInputValue = formatNumber(targetValue, precision);
    } else if (diff === '-') {
      // From here on the diff length is
      // Change number's sign
      event.preventDefault();

      if (inputValue.startsWith('-')) {
        // Make the number positive.
        newInputValue = inputValue.substring(1);
        newCaretPosition = newCaretPosition > 2 ? newCaretPosition - 2 : 0;
      } else {
        // Make the number negative.
        newInputValue = '-' + inputValue;
      }
    } else if (/^[0-9.,]$/.test(diff)) {
      // Digits, comma and period.
      const decimalPosition = precision !== 0 ? targetValue.search(/\./) : targetValue.length;
      const caretPosition = start - 1;

      const newChar = diff === ',' ? '.' : diff;

      if (newChar === '.') {
        newInputValue = formatNumber(
          removeSelection(targetValue, caretPosition, caretPosition + 1),
          precision,
        );
        const decimalPosition = precision !== 0 ? newInputValue.search(/\./) : newInputValue.length;
        if (caretPosition !== decimalPosition) {
          newCaretPosition--;
        }
      } else {
        // newChar is a digit
        if (caretPosition > decimalPosition) {
          newInputValue = formatNumber(
            removeSelection(targetValue, caretPosition + 1, caretPosition + 2),
            precision,
          );
        } else {
          newInputValue = formatNumber(targetValue, precision);
          if (/^-?0\d\./.test(targetValue)) {
            newCaretPosition--;
          }
        }
      }
    } else {
      // Ignore changes
      newInputValue = inputValue;
      newCaretPosition--;
    }

    if (newInputValue !== inputValue) {
      if (
        !(min !== undefined && Number(newInputValue) < min) &&
        !(max !== undefined && Number(newInputValue) > max)
      ) {
        setInputValue(newInputValue);
        //setInputSelection({ start: newCaretPosition, end: newCaretPosition });
        event.target.value = newInputValue;
        emittedValue.current = Number(newInputValue);
        onChange?.(event);
      }
    }
    setInputSelection({ start: newCaretPosition, end: newCaretPosition });
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { start, end } = getCurrentSelection(event.currentTarget);
    const key = event.key;

    if (key === KEY_BACKSPACE && start === end && inputValue.charAt(start - 1) === '.') {
      event.preventDefault();
      setInputSelection({ start: start - 1, end: end - 1 });
    } else if (key === KEY_DELETE && start === end && inputValue.charAt(start) === '.') {
      event.preventDefault();
      setInputSelection({ start: start + 1, end: end + 1 });
    } else if ((key === KEY_UP || key === KEY_DOWN) && !disableStepAction) {
      event.preventDefault();
      const newInputValue = formatNumber(
        '' +
          secureStep(
            key === KEY_UP ? 'increment' : 'decrement',
            Number(inputValue),
            step,
            precision,
            min,
            max,
          ),
        precision,
      );

      generateChangeEvent(newInputValue);
    }
  };

  /**
   * Temporary disabled, it's causing infinite loops. I don't know why.
   */
  // const handleWheel = ({ deltaY }: React.WheelEvent) => {
  //   if (deltaY !== 0) {
  //     setInputValue(
  //       formatNumber(
  //         '' +
  //           secureStep(
  //             deltaY > 0 ? 'increment' : 'decrement',
  //             Number(inputValue),
  //             step,
  //             precision,
  //             min,
  //             max,
  //           ),
  //         precision,
  //       ),
  //     );
  //   }
  // };

  useImperativeHandle(ref, () => {
    if (inputRef.current) {
      inputRef.current.stepUp = (n?: number) => {
        const newInputValue = formatNumber(
          '' + secureStep('increment', Number(inputValue), (n ?? 1) * step, precision, min, max),
          precision,
        );
        generateChangeEvent(newInputValue);
      };
      inputRef.current.stepDown = (n?: number) => {
        const newInputValue = formatNumber(
          '' + secureStep('decrement', Number(inputValue), (n ?? 1) * step, precision, min, max),
          precision,
        );
        generateChangeEvent(newInputValue);
      };
    }
    return inputRef.current as IStepsHandle;
  });

  // Side effects

  useEffect(() => {
    if (value !== undefined && Number(value) !== emittedValue.current) {
      let newValue = value;
      if (max !== undefined && newValue > max) {
        newValue = max;
      } else if (min !== undefined && newValue < min) {
        newValue = min;
      }
      setInputValue(formatNumber('' + value, precision));
    }
  }, [value, precision, min, max]);

  useEffect(() => {
    if (isFocused && inputSelection) {
      setSelection(inputSelection.start, inputSelection.end);
      setTimeout(() => setSelection(inputSelection.start, inputSelection.end), 0);
    }
  }, [isFocused, inputSelection]);

  return (
    <input
      {...rest}
      disabled={disabled}
      readOnly={readOnly}
      onBlur={handleBlur}
      onChange={handleChange}
      onFocus={handleFocus}
      onKeyDown={handleKeyDown}
      // Temporary disabled
      // onWheel={handleWheel}
      ref={inputRef}
      type="text"
      inputMode="numeric"
      value={formatDisplayString(inputValue, precision, isFocused, thousandsSeparator)}
    />
  );
};

CustomNumberInputComponent.displayName = 'CustomNumberInput';

export const CustomNumberInput = forwardRef(CustomNumberInputComponent);
