import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString, getNegativeUniqueNumericId } from '@laudus/shared-utils';
import { IPurchaseOrder, IPurchaseOrderExtended } from '@laudus/types';

import {
  clearPurchaseOrder,
  clearPurchaseOrderDraft,
  duplicatePurchaseOrder,
  removePurchaseOrderFromList,
  setPurchaseOrder,
  setPurchaseOrderDraft,
  setPurchaseOrderDraftValues,
  setPurchasesOrdersList,
  updatePurchasesOrdersList,
} from './actions';

export const PURCHASES_ORDERS_TAB_ID = 'purchaseOrders';

export interface IPurchaseOrderState {
  current: IPurchaseOrderExtended;
  draft: IPurchaseOrderExtended;
  list: IPurchaseOrder[];
}

export const PURCHASE_ORDER_EMPTY: IPurchaseOrderExtended = {
  purchaseOrderId: 0,
  supplier: { supplierId: 0, name: '', legalName: '', VATId: '' },
  contact: null,
  issuedDate: dateToLocalISOString(new Date()),
  dueDate: '',
  nullDoc: false,
  status: '',
  supplierQuote: '',
  notesForSupplier: '',
  warehouse: null,
  term: null,
  notes: '',
  archived: false,
  approvedByLevel1: null,
  approvedAtLevel1: null,
  isApprovedAtLevel1: false,
  approvedByLevel2: null,
  approvedAtLevel2: null,
  isApprovedAtLevel2: false,
  approvedByLevel3: null,
  approvedAtLevel3: null,
  isApprovedAtLevel3: false,
  createdBy: null,
  createdAt: '',
  modifiedBy: null,
  modifiedAt: '',
  customFields: {
    additionalProp1: '',
    additionalProp2: '',
    additionalProp3: '',
  },
  items: [],
};

export const initialPurchaseOrderState: IPurchaseOrderState = {
  current: PURCHASE_ORDER_EMPTY,
  draft: PURCHASE_ORDER_EMPTY,
  list: [],
};

export const purchaseOrderReducer = createReducer(initialPurchaseOrderState, (builder) => {
  builder
    .addCase(clearPurchaseOrder, (state) => {
      return { ...state, current: PURCHASE_ORDER_EMPTY };
    })
    .addCase(clearPurchaseOrderDraft, (state) => {
      return { ...state, draft: PURCHASE_ORDER_EMPTY };
    })
    .addCase(setPurchaseOrder, (state, action) => {
      return { ...state, current: { ...action.payload, items: action.payload.items ?? [] } };
    })
    .addCase(setPurchaseOrderDraft, (state, action) => {
      return { ...state, draft: { ...action.payload, items: action.payload.items ?? [] } };
    })
    .addCase(setPurchaseOrderDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setPurchasesOrdersList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updatePurchasesOrdersList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.purchaseOrderId !== action.payload.purchaseOrderId),
          action.payload,
        ],
      };
    })
    .addCase(removePurchaseOrderFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.purchaseOrderId !== action.payload),
      };
    })
    .addCase(duplicatePurchaseOrder, (state, action) => {
      const { purchaseOrderId, ...duplicatedPurchaseOrder } = action.payload;
      const duplicatedItems = [...duplicatedPurchaseOrder.items].map((item) => {
        return { ...item, itemId: getNegativeUniqueNumericId() };
      });
      return {
        ...state,
        draft: { ...duplicatedPurchaseOrder, items: [...duplicatedItems] },
      };
    })
    .addDefaultCase((state) => state);
});
