import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IPurchaseOrder, IPurchaseOrderWithCostCenter } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

// PurchasesOrders Tab action creators
export const addHomePurchaseOrderTab = () =>
  addTab({
    tab: {
      id: 'purchaseOrders',
      title: intl.formatMessage({ id: 'purchaseOrders.tabTitle' }),
      path: 'pages/PurchasesOrders/PurchasesOrders',
      isRemovable: true,
    },
  });

export const addViewPurchaseOrderTab = (id?: number) =>
  addTab({
    tab: {
      id: 'purchaseOrders',
      title: intl.formatMessage({ id: 'purchaseOrders.tabTitle' }),
      path: 'pages/PurchasesOrders/PurchasesOrdersView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewPurchaseOrderTab = () =>
  addTab({
    tab: {
      id: 'purchaseOrders',
      title: intl.formatMessage({ id: 'purchaseOrders.tabTitle' }),
      path: 'pages/PurchasesOrders/PurchasesOrdersNew',
      isRemovable: true,
    },
  });

export const addEditPurchaseOrderTab = () =>
  addTab({
    tab: {
      id: 'purchaseOrders',
      title: intl.formatMessage({ id: 'purchaseOrders.tabTitle' }),
      path: 'pages/PurchasesOrders/PurchasesOrdersEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearPurchaseOrder = createAction('PURCHASES_ORDERS/CLEAR');

export const clearPurchaseOrderDraft = createAction('PURCHASES_ORDERS/CLEAR_DRAFT');

export const setPurchaseOrder = createAction<IPurchaseOrder>('PURCHASES_ORDERS/SET_PURCHASE_ORDER');

export const setPurchaseOrderDraft = createAction<IPurchaseOrder>(
  'PURCHASES_ORDERS/SET_PURCHASE_ORDER_DRAFT',
);
export const setPurchaseOrderDraftValues = createAction<Partial<IPurchaseOrderWithCostCenter>>(
  'PURCHASES_ORDERS/SET_PURCHASE_ORDER_DRAFT_VALUE',
);

export const setPurchasesOrdersList = createAction<IPurchaseOrder[]>('PURCHASES_ORDERS/SET_LIST');

export const updatePurchasesOrdersList = createAction<IPurchaseOrder>(
  'PURCHASES_ORDERS/UPDATE_LIST',
);

export const removePurchaseOrderFromList = createAction<number>(
  'PURCHASES_ORDERS/REMOVE_FROM_LIST',
);

export const duplicatePurchaseOrder = createAction<IPurchaseOrder>('PURCHASES_ORDERS/DUPLICATE');

//Complex actions
export const fetchPurchasesOrdersList = createAsyncThunk<void, void, AppThunkConfig>(
  'PURCHASES_ORDERS/FETCH_PURCHASES_ORDERS_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('purchase-orders'));
    try {
      const { data } = await api.purchaseOrders.fetchPurchasesOrdersListAPI();
      dispatch(setPurchasesOrdersList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'purchaseOrders',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('purchase-orders'));
    }
  },
);

export const fetchPurchaseOrder = createAsyncThunk<void, number, AppThunkConfig>(
  'PURCHASES_ORDERS/FETCH_PURCHASE_ORDER',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('purchase-orders'));
    try {
      const { data } = await api.purchaseOrders.fetchPurchaseOrderAPI(id);
      dispatch(setPurchaseOrder(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'purchaseOrders',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('purchase-orders'));
    }
  },
);
