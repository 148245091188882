import { AxiosResponse } from 'axios';

import { removeNegativeItemIdsFromEntity } from '@laudus/shared-utils';
import { IRecipe } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchRecipesListAPI = (): Promise<AxiosResponse<IRecipe[]>> =>
  axios.post(
    `${VITE_API_URL}/production/recipes/list`,
    {
      fields: ['recipeId', 'name', 'description'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchRecipeAPI = (recipeId: number): Promise<AxiosResponse<IRecipe>> =>
  axios.get(`${VITE_API_URL}/production/recipes/${recipeId}`);

export const createRecipeAPI = (recipe: IRecipe): Promise<AxiosResponse<IRecipe>> =>
  axios.post(`${VITE_API_URL}/production/recipes`, removeNegativeItemIdsFromEntity(recipe));

export const updateRecipeAPI = (recipe: IRecipe): Promise<AxiosResponse<IRecipe>> =>
  axios.put(
    `${VITE_API_URL}/production/recipes/${recipe.recipeId}`,
    removeNegativeItemIdsFromEntity(recipe),
  );

export const deleteRecipeAPI = (recipeId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/production/recipes/${recipeId}`);
