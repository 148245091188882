import { createSelector } from '@reduxjs/toolkit';

import { AppState, IWorkersCompensationInsurancesState } from '../..';

export const getWorkersCompensationInsurancesSlice = (
  state: AppState,
): IWorkersCompensationInsurancesState => state.workersCompensationInsurances;

export const getWorkersCompensationInsurancesList = createSelector(
  [getWorkersCompensationInsurancesSlice],
  (state) => state.list,
);

export const getWorkersCompensationInsurance = createSelector(
  [getWorkersCompensationInsurancesSlice],
  (state) => state.current,
);

export const getWorkersCompensationInsuranceDraft = createSelector(
  [getWorkersCompensationInsurancesSlice],
  (state) => state.draft,
);
