import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { ICurrency, IFetchWithEtagParams } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const CURRENCIES_TAB_ID = 'currencies';

// Currencies Tab action creators
export const addHomeCurrenciesTab = (id?: string) =>
  addTab({
    tab: {
      id: CURRENCIES_TAB_ID,
      title: intl.formatMessage({ id: 'currencies.tabTitle' }),
      path: 'pages/Currencies/Currencies',
      props: { id },
      isRemovable: true,
    },
  });

export const addViewCurrenciesTab = (id?: string) =>
  addTab({
    tab: {
      id: CURRENCIES_TAB_ID,
      title: intl.formatMessage({ id: 'currencies.tabTitle' }),
      path: 'pages/Currencies/CurrenciesView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewCurrenciesTab = () =>
  addTab({
    tab: {
      id: CURRENCIES_TAB_ID,
      title: intl.formatMessage({ id: 'currencies.tabTitle' }),
      path: 'pages/Currencies/CurrenciesNew',
      isRemovable: true,
    },
  });

export const addEditCurrenciesTab = () =>
  addTab({
    tab: {
      id: CURRENCIES_TAB_ID,
      title: intl.formatMessage({ id: 'currencies.tabTitle' }),
      path: 'pages/Currencies/CurrenciesEdit',
      isRemovable: true,
    },
  });

// Currencies action creators
export const clearCurrency = createAction(`CURRENCIES/CLEAR`);

export const clearCurrencyDraft = createAction(`CURRENCIES/CLEAR_DRAFT`);

export const setCurrency = createAction<ICurrency>(`CURRENCIES/SET`);

export const setCurrencyDraft = createAction<ICurrency>(`CURRENCIES/SET_DRAFT`);

export const setCurrencyDraftValues = createAction<Partial<ICurrency>>(
  `CURRENCIES/SET_DRAFT_VALUES`,
);

export const setCurrencyList = createAction<ICurrency[]>('CURRENCIES/SET_LIST');

export const updateCurrencyList = createAction<ICurrency>(`CURRENCIES/UPDATE_LIST`);

export const removeCurrencyFromList = createAction<string>(`CURRENCIES/REMOVE_FROM_LIST`);

export const duplicateCurrency = createAction<ICurrency>(`CURRENCIES/DUPLICATE`);

export const fetchCurrencyList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'CURRENCIES/FETCH_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    if (!silent) {
      dispatch(startRequest('currencies'));
    }
    try {
      const { data } = await api.currencies.fetchCurrencyListFromAPI();
      dispatch(setCurrencyList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'currencies',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('currencies'));
      }
    }
  },
);

export const fetchCurrency = createAsyncThunk<void, string, AppThunkConfig>(
  'CURRENCIES/FETCH',
  async (currencyId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('currencies'));
    try {
      const { data } = await api.currencies.fetchCurrencyFromAPI(currencyId);
      dispatch(setCurrency(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'currencies',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('currencies'));
    }
  },
);
