import { createSelector } from '@reduxjs/toolkit';

import { AppState, IDealersState } from '../..';

export const getDealersSlice = (state: AppState): IDealersState => state.dealers;

export const getDealerList = createSelector([getDealersSlice], (state) => {
  const list = state.list;
  return [...list].sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
});

export const getDealer = createSelector([getDealersSlice], (state) => state.current);

export const getDealerDraft = createSelector([getDealersSlice], (state) => state.draft);
