import { forwardRef } from 'react';

import { intl } from '@laudus/intl';

import { SelectEditor } from './SelectEditor';

const SelectRecipesTypeEditorComponent: React.ForwardRefRenderFunction<any, any> = (props, ref) => {
  const options = [
    {
      value: 'I',
      label: intl.formatMessage({ id: 'recipes.selectField.input' }),
    },
    {
      value: 'O',
      label: intl.formatMessage({ id: 'recipes.selectField.output' }),
    },
  ];

  return <SelectEditor {...props} options={options} ref={ref} />;
};

SelectRecipesTypeEditorComponent.displayName = 'SelectRecipesTypeEditorComponent';

export const SelectRecipesTypeEditor = forwardRef(SelectRecipesTypeEditorComponent);
