import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, IStage } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

// Stages Tab action creators

export const addHomeStagesTab = () =>
  addTab({
    tab: {
      id: 'stages',
      title: intl.formatMessage({ id: 'stages.tabTitle' }),
      path: 'pages/Stages/Stages',
      isRemovable: true,
    },
  });

export const addViewStagesTab = (id?: number) =>
  addTab({
    tab: {
      id: 'stages',
      title: intl.formatMessage({ id: 'stages.tabTitle' }),
      path: 'pages/Stages/StagesView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewStagesTab = () =>
  addTab({
    tab: {
      id: 'stages',
      title: intl.formatMessage({ id: 'stages.tabTitle' }),
      path: 'pages/Stages/StagesNew',
      isRemovable: true,
    },
  });

export const addEditStagesTab = () =>
  addTab({
    tab: {
      id: 'stages',
      title: intl.formatMessage({ id: 'stages.tabTitle' }),
      path: 'pages/Stages/StagesEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearStage = createAction('STAGES/CLEAR');

export const clearStageDraft = createAction('STAGES/CLEAR_DRAFT');

export const setStage = createAction<IStage>('STAGES/SET_STAGES');

export const setStageDraft = createAction<IStage>('STAGES/SET_STAGES_DRAFT');

export const setStageDraftValues = createAction<Partial<IStage>>('STAGES/SET_STAGES_DRAFT_VALUE');

export const setStagesList = createAction<IStage[]>('STAGES/SET_LIST');

export const updateStageList = createAction<IStage>('STAGES/UPDATE_LIST');

export const removeStageFromList = createAction<number>('STAGES/REMOVE_FROM_LIST');

export const duplicateStage = createAction<IStage>('STAGES/DUPLICATE');

export const fetchStagesList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'STAGES/FETCH_STAGES_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(startRequest('stages'));
      }

      const { data } = await api.stages.fetchStagesListFromAPI();
      dispatch(setStagesList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'stages',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('stages'));
      }
    }
  },
);

export const fetchStage = createAsyncThunk<void, number, AppThunkConfig>(
  'STAGES/FETCH_STAGE',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('stages'));
      const { data } = await api.stages.fetchStageFromAPI(id);
      dispatch(setStage(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'stages',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('stages'));
    }
  },
);
