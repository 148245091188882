import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IStatisticsState } from './reducer';

export const getStatisticsSlice = (state: AppState): IStatisticsState => state.statistics;

export const getStatisticsSelectedId = createSelector(
  [getStatisticsSlice],
  (state) => state.selectedId,
);

export const getStatisticsFilters = createSelector([getStatisticsSlice], (state) => state.filters);

export const getStatisticsData = createSelector([getStatisticsSlice], (state) => state.data);

export const getStatisticsDashboardCardData = (cardId: number) =>
  createSelector([getStatisticsSlice], (state) => state.dashboardCardsData[cardId]?.data);

export const getStatisticsDashboardCardFields = (cardId: number) =>
  createSelector([getStatisticsSlice], (state) => state.dashboardCardsData[cardId]?.fields ?? []);

export const getStatisticsFields = createSelector([getStatisticsSlice], (state) => state.fields);
