import { ComponentType } from 'react';

import { Flex, Heading, Text, useColorMode } from '@chakra-ui/react';

import { IconBaseProps, IconWarning } from '@laudus/icons';
import { colors } from '@laudus/shared-ui';
import { IAlertType } from '@laudus/types';

import { Alert } from './Alert';

interface IAlertWarningProps {
  acceptIcon?: React.ReactElement;
  acceptText: string;
  children?: JSX.Element | JSX.Element[] | string;
  isOpen: boolean;
  title?: string;
  type?: IAlertType;
  AlertIcon?: ComponentType<IconBaseProps> | null;
  onAccept?: () => void;
}

const AlertWarningHeader = ({ title, AlertIcon = IconWarning }: Partial<IAlertWarningProps>) => {
  return (
    <Flex direction="column" align="center" justifyContent="center">
      {AlertIcon && <AlertIcon color="#CCCCCC" size={70} />}
      {title ? (
        <Heading
          as="h2"
          _dark={{ color: colors.white }}
          fontSize="1.9rem"
          margin="1.6rem 0 0.4rem"
          textAlign="center"
        >
          {title}
        </Heading>
      ) : undefined}
    </Flex>
  );
};

export const AlertWarning = (props: IAlertWarningProps) => {
  const { children, isOpen, acceptIcon, acceptText, onAccept } = props;
  const { colorMode } = useColorMode();

  return (
    <Alert
      header={<AlertWarningHeader {...props} />}
      acceptIcon={acceptIcon}
      acceptText={acceptText}
      isOpen={isOpen}
      heightAlertButton="4.2rem"
      widthAlertButton="100%"
      onAccept={onAccept}
    >
      <Flex
        height="100%"
        alignItems="center"
        direction="column"
        textAlign="center"
        overflowY="scroll"
      >
        <Text marginTop={4} color={colorMode === 'dark' ? colors.white : colors.black}>
          {children}
        </Text>
      </Flex>
    </Alert>
  );
};
