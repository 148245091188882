import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IStockTransfersState } from './reducer';

export const getStockTransfersSlice = (state: AppState): IStockTransfersState =>
  state.stockTransfers;

export const getStockTransferList = createSelector([getStockTransfersSlice], (state) => state.list);

export const getStockTransfer = createSelector([getStockTransfersSlice], (state) => state.current);

export const getStockTransferDraft = createSelector(
  [getStockTransfersSlice],
  (state) => state.draft,
);
