import { createSelector } from '@reduxjs/toolkit';

import {
  dateToLocalISOString,
  generateBinaryStringFromArrayOfFilledPositions,
} from '@laudus/shared-utils';
import {
  IReportConfig,
  IReportFiltersItems,
  IReportPrimitiveFiltersItems,
  IReportsFilter,
  IReportsLeftMenuItem,
} from '@laudus/types';

import { AppState, IReportsState } from '../..';
import { getLoggedUserRights } from '../../slices/loggedUser';
import { getSettingsByName } from '../../slices/settings';

import { buildReportsTreeNodeData, getUsableReportMenuFromConfiguration } from './utils';

export const getReportsSlice = (state: AppState): IReportsState => state.reports;

export const getReportFilters = createSelector([getReportsSlice], (state) => state.filters);

export const getReportResult = createSelector([getReportsSlice], (state) => state.result);

export const getReportConfig = createSelector([getReportsSlice], (state) => state.config);

export const getReportError = createSelector([getReportsSlice], (state) => state.error);

export const getReportsTreeNodeData = createSelector(
  [getLoggedUserRights, (_, menuConfiguration: IReportsLeftMenuItem[]) => menuConfiguration],
  (userRights, menuConfiguration) => {
    const reportMenuVisibleByUser = getUsableReportMenuFromConfiguration({
      menuConfiguration,
      userRights,
    });

    return buildReportsTreeNodeData(reportMenuVisibleByUser);
  },
);

export const getReportFiltersWithDefaultValues = createSelector(
  [getReportFilters, getSettingsByName<number>('chargeableAccountLevel')],
  (filters, maxLevelAccount) => {
    const today = new Date();
    const currentMonth = today.getMonth() + 1;
    const currentYear = today.getFullYear();

    // Put here the value the filters should have by default when the user selects the report but doesn't touch the filters in the UI
    const defaultValues: IReportFiltersItems = {
      date: dateToLocalISOString(today),
      dateFrom: dateToLocalISOString(new Date('1990-01-01T00:00:00')),
      dateTo: dateToLocalISOString(today),
      year: currentYear,
      month: currentMonth,
      startPage: 0,
      endPage: 0,
      printHeader: {
        leaveSpaceForHeader: false,
        printLegalHeader: false,
      },
      showLevels:
        maxLevelAccount && maxLevelAccount > 0
          ? generateBinaryStringFromArrayOfFilledPositions({
              filledPositions: Array.from({ length: maxLevelAccount }).map((_, i) => i + 1),
              stringSize: maxLevelAccount,
            })
          : undefined,
      currencyType: 'all',
    };

    const filledFilters = Object.fromEntries(
      Object.entries(filters).filter(([_, value]) => value !== undefined),
    );

    return {
      ...defaultValues,
      ...filledFilters,
    };
  },
);

// Returns only the filled filters that are present in the report config
export const getSelectedReportFilledFilters = createSelector(
  [getReportConfig, getReportFiltersWithDefaultValues],
  (reportConfig, filters) => {
    if (!reportConfig) {
      return {};
    }

    const formatFilters = (reportsFilters: IReportsFilter[]): IReportsFilter[] => {
      return reportsFilters
        .map((filter) => (typeof filter === 'string' ? filter : formatFilters(filter.filters)))
        .flat();
    };

    const formattedReportConfig = {
      ...reportConfig,
      filters: formatFilters(reportConfig.filters),
    };

    return getReportConfigFilledFilters({
      reportConfig: formattedReportConfig,
      filledFilters: filters,
    });
  },
);

function getReportConfigFilledFilters<FilterName extends keyof IReportFiltersItems>({
  reportConfig,
  filledFilters,
}: {
  reportConfig: IReportConfig;
  filledFilters: IReportFiltersItems;
}): Partial<IReportFiltersItems> {
  return reportConfig.filters.reduce<Partial<IReportFiltersItems>>(
    (reportConfigFilledFilters, reportConfigFilter) => {
      const filterName = reportConfigFilter as FilterName;
      const filterValue = filledFilters[filterName];

      reportConfigFilledFilters[filterName] = filterValue;

      return reportConfigFilledFilters;
    },
    {},
  );
}

export const getSelectedReportPrimitiveFilledFilters = createSelector(
  [getSelectedReportFilledFilters],
  (filters) => {
    // Ensure your filter is correctly mapped to the primitive filter, otherwise it will not be sent to the API
    // e.g. from object to primitive => costCenterId: filters.costCenter?.costCenterId
    // e.g. from primitive to primitive => year: filters.year
    const primitiveFilters: IReportPrimitiveFiltersItems = {
      costCenterId: filters.costCenter?.costCenterId,
      date: filters.date,
      dateFrom: filters.dateFrom,
      dateTo: filters.dateTo,
      accountId: filters.account?.accountId ?? undefined,
      accountNumberFrom: filters.accountFrom?.accountNumber ?? undefined,
      accountNumberTo: filters.accountTo?.accountNumber ?? undefined,
      year: filters.year,
      month: filters.month,
      startPage: filters.startPage,
      endPage: filters.endPage,
      leaveSpaceForHeader: filters.printHeader?.leaveSpaceForHeader,
      printLegalHeader: filters.printHeader?.printLegalHeader,
      location: filters.location,
      bookId: filters.bookId,
      showAccountsWithZeroBalance: filters.showAccountsWithZeroBalance,
      showOnlyAccountsWithActivity: filters.showOnlyAccountsWithActivity,
      showLevels: filters.showLevels,
      journalEntryNumberFrom: filters.journalEntryFrom?.journalEntryNumber ?? undefined,
      journalEntryNumberTo: filters.journalEntryTo?.journalEntryNumber ?? undefined,
      printJournalSums: filters.printJournalSums,
      customerId: filters.customer?.customerId ?? undefined,
      salesmanId: filters.salesman?.salesmanId ?? undefined,
      branchId: filters.branch?.branchId ?? undefined,
      currencyType: filters.currencyType,
    };

    return primitiveFilters;
  },
);
