import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString } from '@laudus/shared-utils';
import { IFormState, IPosAdjustment } from '@laudus/types';

import {
  clearPosAdjustment,
  clearPosAdjustmentDraft,
  duplicatePosAdjustment,
  removePosAdjustmentFromList,
  resetPosAdjustmentSubmit,
  setPosAdjustment,
  setPosAdjustmentDraft,
  setPosAdjustmentDraftValues,
  setPosAdjustmentsList,
  setPosAdjustmentSubmitError,
  setPosAdjustmentSubmitSuccess,
  setPosAdjustmentSubmitting,
  updatePosAdjustmentsList,
} from './actions';

export const POS_ADJUSTMENTS_TAB_ID = 'posAdjustment';

const initialFormState = {
  submitting: false,
  submitError: false,
  submitSuccess: false,
};

export interface IPosAdjustmentState {
  current: IPosAdjustment;
  draft: IPosAdjustment;
  list: IPosAdjustment[];
  formState: IFormState;
}

export const POS_ADJUSTMENT_EMPTY: IPosAdjustment = {
  adjustmentId: 0,
  pos: {
    posId: 0,
    name: '',
  },
  date: dateToLocalISOString(new Date()),
  direction: '',
  amount: 0,
  type: '',
  category: {
    code: '',
    description: '',
  },
  notes: '',
};

export const initialPosAdjustmentState: IPosAdjustmentState = {
  current: POS_ADJUSTMENT_EMPTY,
  draft: POS_ADJUSTMENT_EMPTY,
  list: [],
  formState: initialFormState,
};

export const posAdjustmentReducer = createReducer(initialPosAdjustmentState, (builder) => {
  builder
    .addCase(clearPosAdjustment, (state) => {
      return { ...state, current: POS_ADJUSTMENT_EMPTY };
    })
    .addCase(clearPosAdjustmentDraft, (state) => {
      return { ...state, draft: POS_ADJUSTMENT_EMPTY };
    })
    .addCase(setPosAdjustment, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setPosAdjustmentDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setPosAdjustmentDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setPosAdjustmentsList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updatePosAdjustmentsList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.adjustmentId !== action.payload.adjustmentId),
          action.payload,
        ],
      };
    })
    .addCase(removePosAdjustmentFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.adjustmentId !== action.payload),
      };
    })
    .addCase(duplicatePosAdjustment, (state, action) => {
      const { adjustmentId, ...duplicatedPosAdjustment } = action.payload;
      return { ...state, draft: duplicatedPosAdjustment };
    })
    .addCase(resetPosAdjustmentSubmit, (state) => {
      return { ...state, formState: initialFormState };
    })
    .addCase(setPosAdjustmentSubmitting, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitting: true },
      };
    })
    .addCase(setPosAdjustmentSubmitError, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitError: true },
      };
    })
    .addCase(setPosAdjustmentSubmitSuccess, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitSuccess: true },
      };
    })
    .addDefaultCase((state) => state);
});
