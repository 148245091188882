import { IUser } from '@laudus/types';

export function doesUserMatchDiscontinuedFilter({
  user,
  showDiscontinued,
}: {
  user: IUser;
  showDiscontinued: boolean;
}): boolean {
  if (showDiscontinued) {
    // Show both discontinued and non-discontinued users
    return true;
  }

  // Show only non-discontinued users
  return user.discontinued === false;
}
