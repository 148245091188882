import { createReducer } from '@reduxjs/toolkit';

import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import { IBank, IBankStatement, IBankStatementLine, IBankStatementListItem } from '@laudus/types';

import {
  clearBankStatement,
  clearBankStatementDraft,
  clearImportBank,
  duplicateBankStatement,
  removeBankStatementFromList,
  renameBankStatementsImportListColumn,
  setBankStatement,
  setBankStatementDraft,
  setBankStatementDraftValues,
  setBankStatementList,
  setBankStatementsImportList,
  setImportBank,
  updateBankStatementList,
} from './actions';

export interface IBankStatementsState {
  list: IBankStatementListItem[];
  current: IBankStatement;
  draft: IBankStatement;
  importList: Record<string, string>[];
  importBank: IBank | null;
}

export const BANK_STATEMENT_EMPTY: IBankStatement = {
  bankStatementId: 0,
  number: 0,
  year: new Date().getFullYear(),
  bank: {
    bankId: '',
    name: '',
  },
  dateFrom: '',
  dateTo: '',
  initialBalance: 0,
  createdBy: {
    userId: '',
    name: '',
  },
  createdAt: '',
  modifiedBy: {
    userId: '',
    name: '',
  },
  modifiedAt: '',
  lines: [],
};

export const BANK_STATEMENT_LINE_EMPTY: IBankStatementLine = {
  lineId: 0,
  date: '',
  description: '',
  document: '',
  deposit: 0,
  withdrawal: 0,
};

export const initialBankStatementsState: IBankStatementsState = {
  list: [],
  current: BANK_STATEMENT_EMPTY,
  draft: BANK_STATEMENT_EMPTY,
  importList: [],
  importBank: null,
};

export const bankStatementsReducer = createReducer(initialBankStatementsState, (builder) => {
  builder
    .addCase(clearBankStatement, (state) => {
      return { ...state, current: BANK_STATEMENT_EMPTY };
    })
    .addCase(clearBankStatementDraft, (state) => {
      return { ...state, draft: BANK_STATEMENT_EMPTY };
    })
    .addCase(setBankStatement, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setBankStatementDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setBankStatementDraftValues, (state, action) => {
      return {
        ...state,
        draft: {
          ...state.draft,
          ...action.payload,
        },
      };
    })
    .addCase(setBankStatementList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateBankStatementList, (state, action) => {
      const {
        bankStatementId,
        number,
        year,
        bank: { bankId, name },
        dateFrom,
        dateTo,
        initialBalance,
      } = action.payload;
      return {
        ...state,
        list: [
          ...state.list.filter((b) => b.bankStatementId !== action.payload.bankStatementId),
          {
            bankStatementId,
            number,
            year,
            bank_bankId: bankId,
            bank_name: name,
            dateFrom,
            dateTo,
            initialBalance,
          },
        ],
      };
    })
    .addCase(removeBankStatementFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((b) => b.bankStatementId !== action.payload),
      };
    })
    .addCase(duplicateBankStatement, (state, action) => {
      return {
        ...state,
        draft: {
          ...action.payload,
          bankStatementId: 0,
          ...(action.payload.lines
            ? {
                lines: action.payload.lines.map((line) => ({
                  ...line,
                  lineId: getNegativeUniqueNumericId(),
                })),
              }
            : {}),
        },
      };
    })
    .addCase(clearImportBank, (state) => {
      return {
        ...state,
        importBank: null,
      };
    })
    .addCase(setImportBank, (state, action) => {
      return {
        ...state,
        importBank: action.payload,
      };
    })
    .addCase(setBankStatementsImportList, (state, action) => {
      return {
        ...state,
        importList: action.payload,
      };
    })
    .addCase(renameBankStatementsImportListColumn, (state, action) => {
      const { newColumnName, oldColumnName } = action.payload;
      return {
        ...state,
        importList: state.importList.map((row) => {
          const newRow = Object.fromEntries(
            Object.entries(row).map(([currentColumnName, value]) => {
              let returnColumnName = currentColumnName;
              if (currentColumnName === oldColumnName) {
                returnColumnName = newColumnName;
              } else if (currentColumnName === newColumnName) {
                returnColumnName = oldColumnName;
              }

              return [returnColumnName, value];
            }),
          );
          return newRow;
        }),
      };
    })
    .addDefaultCase((state) => state);
});
