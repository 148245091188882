export interface IReferencesDocType {
  name: string;
  docTypeId: number;
}

export const referencesDocTypes: IReferencesDocType[] = [
  {
    name: 'Orden de compra',
    docTypeId: 801,
  },
  {
    name: 'Nota de Pedido',
    docTypeId: 802,
  },
  {
    name: 'HES',
    docTypeId: 801801,
  },
];
