import { intl } from '@laudus/intl';
import { IReportsLeftMenuItem, ReportsLeftMenuItemTypes } from '@laudus/types';

export const ACCOUNTING_LEDGER_REPORTS: IReportsLeftMenuItem[] = [
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.mayorSinAgrupar' }),
    config: {
      name: 'mayorSinAgrupar',
      type: 'pdf',
      filters: [
        'accountFrom',
        'accountTo',
        'dateFrom',
        'dateTo',
        'costCenter',
        'bookId',
        {
          label: intl.formatMessage({ id: 'reports.report.header.foiled' }),
          filters: ['printHeader', 'startPage'],
        },
      ],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.mayorSinAgrupar.compact' }),
    config: {
      name: 'mayor sin agrupar, compacto',
      type: 'pdf',
      filters: [
        'accountFrom',
        'accountTo',
        'dateFrom',
        'dateTo',
        'costCenter',
        'bookId',
        {
          label: intl.formatMessage({ id: 'reports.report.header.foiled' }),
          filters: ['printHeader', 'startPage'],
        },
      ],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.mayorSinAgrupar.costCenter' }),
    config: {
      name: 'mayor sin agrupar, por centro de costes',
      type: 'pdf',
      filters: ['accountFrom', 'accountTo', 'dateFrom', 'dateTo', 'costCenter', 'bookId'],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.mayorSinAgrupar.fullInfo' }),
    config: {
      name: 'mayorsinagrupar info completa',
      type: 'pdf',
      filters: ['accountFrom', 'accountTo', 'dateFrom', 'dateTo', 'costCenter', 'bookId'],
    },
  },
];
