import { AxiosResponse } from 'axios';

import { createQueryParams } from '@laudus/shared-utils';
import {
  IProduct,
  IProductImportResult,
  IProductList,
  IProductPictureUploadResponse,
  IProductSalesPrice,
  IProductsFilters,
  IProductStock,
  IStockControlItems,
} from '@laudus/types';

import { client as axios } from '../utils/axios';

interface IProductSalesPriceParams {
  [key: string]: string | number | undefined | Date;
  productId?: number;
  quantity?: number;
  customerId?: number;
  priceListId?: number;
  VATRate?: number;
  date?: string;
}

const defaultRequest: IProductsFilters = {
  options: {},
  orderBy: [{ field: 'sku', direction: 'ASC' }],
  fields: [],
};

const { VITE_API_URL } = import.meta.env;

export const fetchProductsListAPI = (
  filters: Partial<IProductsFilters> = defaultRequest,
): Promise<AxiosResponse<IProductList>> =>
  axios.post(
    `${VITE_API_URL}/production/products/list`,
    { ...defaultRequest, ...filters },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchProductAPI = (productId: number): Promise<AxiosResponse<IProduct>> =>
  axios.get(`${VITE_API_URL}/production/products/${productId}`);

export const createProductAPI = (product: IProduct): Promise<AxiosResponse<IProduct>> =>
  axios.post(`${VITE_API_URL}/production/products`, product);

export const createProductBulkAPI = (
  products: Partial<IProduct>[],
): Promise<AxiosResponse<IProductImportResult[]>> =>
  axios.post(`${VITE_API_URL}/production/products/bulk`, products);

export const updateProductAPI = (product: IProduct): Promise<AxiosResponse<IProduct>> =>
  axios.put(`${VITE_API_URL}/production/products/${product.productId}`, product);

export const deleteProductAPI = (productId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/production/products/${productId}`);

export const fetchProductSalesPriceAPI = (
  productId: string,
  params?: IProductSalesPriceParams,
): Promise<AxiosResponse<IProductSalesPrice>> => {
  const queryParams = params ? createQueryParams(params) : '';
  return axios.get(`${VITE_API_URL}/production/products/${productId}/salesPrice${queryParams}`);
};

export const fetchProductStockControlAPI = (
  productId: number,
  filter: Record<string, string | boolean>,
): Promise<AxiosResponse<IStockControlItems>> => {
  const queryParams = filter ? createQueryParams(filter) : '';
  return axios.get(`${VITE_API_URL}/production/products/${productId}/kardex${queryParams}`);
};

export const fetchProductStockByWarehousesAPI = (
  productId: number,
  dateTo?: string,
): Promise<AxiosResponse<IProductStock>> => {
  const queryParams = dateTo ? `?${dateTo}` : '';
  return axios.get(
    `${VITE_API_URL}/production/products/${productId}/stockByWarehouses${queryParams}`,
  );
};

export const getVATRateFromProductsFromAPI = (
  productIds: number[],
): Promise<AxiosResponse<IProduct[]>> =>
  axios.post(
    `${VITE_API_URL}/production/products/list`,
    {
      fields: ['productId', 'sku', 'description', 'applyGeneralVATRate', 'VATRate'],
      filterBy: [
        {
          field: 'productId',
          operator: '=',
          value: productIds,
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const uploadProductPicture = async (
  productId: number,
  picture: File,
): Promise<AxiosResponse<IProductPictureUploadResponse>> => {
  const formData = new FormData();
  formData.append('file', picture);
  formData.append('description', picture?.name ?? '');

  return axios.post<IProductPictureUploadResponse>(
    `${VITE_API_URL}/production/products/${productId}/pictures`,
    formData,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
};

export const deleteProductPicture = async (
  productId: number,
  fileId: number,
): Promise<AxiosResponse<void>> => {
  return axios.delete(`${VITE_API_URL}/production/products/${productId}/pictures/${fileId}`);
};
