import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IProductCategoryState } from './reducer';

export const getProductCategoriesSlice = (state: AppState): IProductCategoryState =>
  state.productCategories;

export const getProductCategoryList = createSelector(
  [getProductCategoriesSlice],
  (state) => state.list,
);

export const getProductCategory = createSelector(
  [getProductCategoriesSlice],
  (state) => state.current,
);

export const getProductCategoryDraft = createSelector(
  [getProductCategoriesSlice],
  (state) => state.draft,
);

export const getProductCategoryFormState = createSelector(
  [getProductCategoriesSlice],
  (state) => state.formState,
);

export const getProductCategoriesListLevelZeroOrdered = createSelector(
  [getProductCategoryList],
  (state) =>
    state
      .filter((pc) => pc.parentId === null || pc.parentId === '')
      .sort((a, b) => a.name.localeCompare(b.name)),
);

export const getProductCategoryByProductCategoryId = (productCategoryId: string | null) =>
  createSelector([getProductCategoryList], (productCategoriesList) =>
    productCategoriesList.find((category) => category.productCategoryId === productCategoryId),
  );
