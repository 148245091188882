import { createReducer } from '@reduxjs/toolkit';

import { PURCHASES_WAYBILL_EMPTY } from '@laudus/sales-utils';
import {
  addDaysToDate,
  calculateDatesDifference,
  dateToLocalISOString,
  getNegativeUniqueNumericId,
} from '@laudus/shared-utils';
import { IPurchaseWaybill, IPurchaseWaybillItem } from '@laudus/types';

import {
  clearPurchaseWaybill,
  clearPurchaseWaybillDraft,
  duplicatePurchaseWaybill,
  removePurchaseWaybillFromList,
  setPurchaseWaybill,
  setPurchaseWaybillDraft,
  setPurchaseWaybillDraftValues,
  setPurchaseWaybillIsEditing,
  setPurchaseWaybillList,
  updatePurchaseWaybillList,
} from './actions';

export interface IPurchaseWaybillsState {
  current: IPurchaseWaybill;
  draft: IPurchaseWaybill;
  list: IPurchaseWaybill[];
  editing: boolean;
}

export const initialPurchaseWaybillsState: IPurchaseWaybillsState = {
  current: PURCHASES_WAYBILL_EMPTY,
  draft: PURCHASES_WAYBILL_EMPTY,
  list: [],
  editing: false,
};

export const purchasesWaybillsReducer = createReducer(initialPurchaseWaybillsState, (builder) => {
  builder
    .addCase(clearPurchaseWaybill, (state) => {
      return {
        ...state,
        current: PURCHASES_WAYBILL_EMPTY,
      };
    })
    .addCase(clearPurchaseWaybillDraft, (state) => {
      return { ...state, draft: PURCHASES_WAYBILL_EMPTY };
    })
    .addCase(setPurchaseWaybill, (state, action) => {
      return {
        ...state,
        current: computePurchaseWaybillPropertiesFromItems(action.payload),
      };
    })
    .addCase(setPurchaseWaybillDraft, (state, action) => {
      return {
        ...state,
        draft: computePurchaseWaybillPropertiesFromItems(action.payload),
      };
    })
    .addCase(setPurchaseWaybillDraftValues, (state, action) => {
      return {
        ...state,
        draft: computePurchaseWaybillPropertiesFromItems({
          ...state.draft,
          ...action.payload,
        }),
      };
    })
    .addCase(setPurchaseWaybillList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updatePurchaseWaybillList, (state, action) => {
      const { purchaseWaybillId } = action.payload;

      if (!state.list.some((d) => d.purchaseWaybillId === purchaseWaybillId)) {
        return state;
      }

      return {
        ...state,
        list: [
          ...state.list.filter((d) => d.purchaseWaybillId !== purchaseWaybillId),
          {
            ...action.payload,
          },
        ],
      };
    })
    .addCase(removePurchaseWaybillFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.purchaseWaybillId !== action.payload),
      };
    })
    .addCase(duplicatePurchaseWaybill, (state, action) => {
      const {
        purchaseWaybillId: purchasesWaybillIdTmp,
        docNumber,
        items,
        ...duplicatedPurchaseWaybill
      } = action.payload;

      duplicatedPurchaseWaybill.DTE = null;

      // issued and due dates
      const datesDifference = calculateDatesDifference(
        duplicatedPurchaseWaybill.issuedDate,
        duplicatedPurchaseWaybill.dueDate,
      );
      duplicatedPurchaseWaybill.issuedDate = dateToLocalISOString(new Date());
      duplicatedPurchaseWaybill.dueDate = addDaysToDate(new Date(), datesDifference).toISOString();

      // Reset all itemIds
      const newItems: IPurchaseWaybillItem[] = items.map((item) => ({
        ...item,
        itemId: getNegativeUniqueNumericId(),
        traceFrom: null,
      }));

      // Build new draft and store the changes
      const newPurchaseWaybill: IPurchaseWaybill = {
        ...duplicatedPurchaseWaybill,
        items: newItems,
        docNumber: 0,
      };

      return {
        ...state,
        draft: computePurchaseWaybillPropertiesFromItems(newPurchaseWaybill),
      };
    })
    .addCase(setPurchaseWaybillIsEditing, (state, action) => {
      return { ...state, editing: action.payload };
    })
    .addDefaultCase((state) => state);
});

/**
 * Compute some properties from items and put them into waybill so they can be directly edited in a single input
 *
 */
function computePurchaseWaybillPropertiesFromItems(
  purchaseWaybill: IPurchaseWaybill,
): IPurchaseWaybill {
  const costCenterFromItems = purchaseWaybill.items.find(
    (item) => Boolean(item.costCenter) && Boolean(item.costCenter?.costCenterId),
  )?.costCenter;

  return {
    ...purchaseWaybill,
    costCenter: purchaseWaybill.costCenter ?? costCenterFromItems,
  };
}
