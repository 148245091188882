export const months = [
  'Enero',
  'Febrero',
  'Marzo',
  'Abril',
  'Mayo',
  'Junio',
  'Julio',
  'Agosto',
  'Septiembre',
  'Octubre',
  'Noviembre',
  'Diciembre',
];

export const cMonth = (month: number) => (month >= 0 && month <= 11 ? months[month] : '');

export const setDaysDates = (daysBefore: number) => {
  const currentDate = new Date();
  const currentTime = currentDate.getTime();
  const miliseconds = daysBefore * 24 * 60 * 60 * 1000;
  currentDate.setTime(currentTime - miliseconds);
  return currentDate;
};

export const setStatDatesYear = (years: number) => {
  const today = new Date();
  const day = today.getDate();
  const month = today.getMonth() + 1;
  const year = today.getFullYear();

  const finalDay = day < 10 ? `0${day}` : day;
  const finalMonth = month < 10 ? `0${month}` : month;

  switch (years) {
    case -1: {
      const dateFromValue = new Date('2010-01-01T00:00:00Z');
      const dateToValue = new Date(`${year}-${finalMonth}-${finalDay}T00:00:00Z`);
      return ['01-01-2010', `${finalDay}-${finalMonth}-${year}`, dateFromValue, dateToValue];
    }

    case 0: {
      return [`01-01-${year}`, `${finalDay}-${finalMonth}-${year}`];
    }

    case 1: {
      return [`01-01-${year}`, `31-12-${year - 1}`];
    }

    default: {
      return ['', ''];
    }
  }
};

export const setStatDates = (daysBefore: number) => {
  const date = new Date();
  const day = date.getDate() + daysBefore;
  const month = date.getMonth() + 1;
  const year = date.getFullYear();

  const finalDay = day < 10 ? `0${day}` : day;
  const finalMonth = month < 10 ? `0${month}` : month;
  const stringDate = `${finalDay}-${finalMonth}-${year}`;
  return [stringDate, stringDate];
};

export const dateToStringDDMMYYYY = (realDate: Date, mask: string) => {
  const day = realDate.getDate();
  const month = realDate.getMonth() + 1;
  const year = realDate.getFullYear();

  const finalDay = day < 10 ? `0${day}` : day;
  const finalMonth = month < 10 ? `0${month}` : month;
  const dateString =
    mask === 'ddmmyyyy' ? `${finalDay}-${finalMonth}-${year}` : `${year}-${finalMonth}-${finalDay}`;
  return dateString;
};

export const timeMonthCaption = (numberOfMonths: number) => {
  const usedDate = new Date();
  usedDate.setMonth(usedDate.getMonth() + numberOfMonths);
  return `${cMonth(usedDate.getMonth())}-${usedDate.getFullYear()}`;
};
