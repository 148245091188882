/*
const updateProductPrice = (product: any, newData: any) => {
  // console.log('new vat rate', newData.vatRate);
  return {
    ...product,
    ...newData,
    quantity: newData.quantity,
    discountPercentage: newData.discount,
    discount: undefined,
    net: newData.unitPrice * (1 - newData.discount / 100) * newData.quantity,
    VAT:
      newData.unitPrice * newData.quantity * (1 - newData.discount / 100) * (newData.vatRate / 100),
  };
};

export default updateProductPrice;
// unitPrice * discountPercentage * quantity
*/
//JBG: discountPercentage se toma de rowData ya que en la
// primera asignación toma por defecto el descuento obtenido en endpoint
// pero el usuario puede modificar ese porcentaje por lo cual no hay que forzarlo siempre
// si los descuentos no están bloqueados

import { cloneDeep } from 'lodash';

import { IProductSalesPrice, ISalesDocument, ISalesItem, SalesDocumentsType } from '@laudus/types';

import { calculateVAT } from './calculateInvoiceTotals';

export const updateSalesItemByProductPrice = ({
  salesItem,
  price,
  salesDocumentType,
  mainCurrencyDecimals,
}: {
  salesItem: ISalesItem;
  price: IProductSalesPrice;
  salesDocumentType: SalesDocumentsType;
  mainCurrencyDecimals: number;
}) => ({
  ...salesItem,
  product: {
    ...salesItem.product,
    hasVolumeDiscounts: price.hasVolumeDiscounts,
  },
  quantity: price.quantity === null ? 0 : price.quantity,
  //TODO: ¿se calcula en función de las opciones de usuario?
  originalUnitPrice: price.originalUnitPrice,
  currencyCode: price.currencyCode, // consultar de dónde sacar esta prop, si de producto o de precio
  parityToMainCurrency: price.parityToMainCurrency,
  unitPrice: price.unitPrice,
  VAT:
    salesDocumentType === 'invoices'
      ? calculateVAT({
          mainCurrencyDecimals,
          unitPrice: price.unitPrice,
          discountPercentage: price.discount,
          quantity: price.quantity ?? 0,
          VATRate: price.VATRate,
        })
      : undefined, // se visualiza dependiendo de OPCIONES de usuario // se calcula
  VATRate: salesDocumentType === 'invoices' ? price.VATRate : undefined,
  discountPercentage: price.discount, // TODO: Chequear este dato
});

export const addVatToProductPrice = (productPrice: number, vat: number) => {
  return productPrice + productPrice * (vat ? vat / 100 : 0);
};

export const removeVatFromProductPrice = (productPrice: number, vat: number) => {
  return productPrice / (1 + (vat ? vat / 100 : 0));
};

export function updateSalesItemToMatchNewNet({
  newNet,
  oldItem,
}: {
  newNet: number;
  oldItem: ISalesItem;
}): ISalesItem {
  // First let's see if item has a discount applied
  const hasDiscount = oldItem.discountPercentage > 0;

  // If item doesn't have a discount, we have to change the original unit price so it match the new net
  if (!hasDiscount) {
    const newOriginalUnitPrice = oldItem.quantity > 0 ? newNet / oldItem.quantity : newNet;
    return { ...oldItem, originalUnitPrice: newOriginalUnitPrice };
  }

  // If item has a discount, let's change it to match the new net
  let newDiscount = 100 * (1 - newNet / oldItem.quantity / oldItem.originalUnitPrice);

  // If new discount is still valid, update item with the new discount
  if (newDiscount >= 0) {
    return { ...oldItem, discountPercentage: newDiscount };
  }

  // If new discount would be invalid (negative discount), let's set a discount of 0 and increment the unit price instead
  newDiscount = 0;
  const newOriginalUnitPrice = oldItem.quantity > 0 ? newNet / oldItem.quantity : newNet;

  return {
    ...oldItem,
    originalUnitPrice: newOriginalUnitPrice,
    discountPercentage: newDiscount,
  };
}

export async function updateDraftSalesItems(
  updatedItem: ISalesItem,
  draft: ISalesDocument,
  salesDocumentType: SalesDocumentsType,
  mainCurrencyDecimals: number,
  fetchProductSalesPriceAPI: any,
) {
  const rowIndex = draft?.items?.findIndex((item) => item.itemId === updatedItem.itemId);
  const oldItem = draft.items?.find((item) => item.itemId === updatedItem.itemId);
  const draftItems = cloneDeep(draft.items);
  draftItems.splice(rowIndex, 1, updatedItem);

  // No item has been changed, which means a new item has been introduced by duplicating another
  if (!oldItem) {
    return draftItems;
  }

  /* Let's check if net property was updated */
  const isUpdatingNet = updatedItem?.net !== oldItem?.net;
  if (isUpdatingNet) {
    // Net has changed, so let's modify the rest of the item properties
    draftItems.splice(
      rowIndex,
      1,
      updateSalesItemToMatchNewNet({
        oldItem,
        newNet: updatedItem.net ?? 0,
      }),
    );
    return draftItems;
  }

  /* Let's check if we COULD ask the API for the new price of the product */
  const newRowDataHasProductId = Boolean(updatedItem.product?.productId);
  const newRowDataHasQuantity = Boolean(updatedItem.quantity);
  const canAPIBeCalled = newRowDataHasProductId && newRowDataHasQuantity;

  // We can't call the API or the product price so it doesn't make sense to keep going
  if (!canAPIBeCalled) {
    return draftItems;
  }

  /* Let's check if we SHOULD ask the API for the new price of the product */
  const isUpdatingQuantity = updatedItem.quantity !== oldItem.quantity;
  const productHaveVolumeDiscounts = Boolean(updatedItem.product?.hasVolumeDiscounts);
  const shouldAskAPIAboutUpdatedPrice = productHaveVolumeDiscounts && isUpdatingQuantity;

  // The product price doesn't need to be asked to the API
  if (!shouldAskAPIAboutUpdatedPrice) {
    return draftItems;
  }

  // Fetch product sales prices from api
  const { data: productSalesPrice } = await fetchProductSalesPriceAPI(
    updatedItem.product?.productId as unknown as string,
    {
      quantity: updatedItem.quantity,
      priceListId: draft.priceList?.priceListId ?? undefined,
      customerId: draft.customer?.customerId ?? undefined,
    },
  );
  draftItems.splice(
    rowIndex,
    1,
    updateSalesItemByProductPrice({
      salesItem: updatedItem,
      price: productSalesPrice,
      salesDocumentType,
      mainCurrencyDecimals,
    }),
  );
  return draftItems;
}
