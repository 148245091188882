import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { ICustomerCategory, IFetchWithEtagParams } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const CUSTOMERCATEGORY_TAB_ID = 'customerCategory';

// Customer Categories Tab action creators
interface ICustomerCategoriesTabParams {
  title?: string;
}

export const addHomeCustomerCategoriesTab = (
  customerCategoriesTab?: ICustomerCategoriesTabParams,
) =>
  addTab({
    tab: {
      id: CUSTOMERCATEGORY_TAB_ID,
      title:
        customerCategoriesTab?.title ?? intl.formatMessage({ id: 'customerCategory.tabTitle' }),
      path: 'pages/CustomerCategory/CustomerCategory',
      isRemovable: true,
    },
  });

// Simple actions
export const clearCustomerCategory = createAction(`CUSTOMER_CATEGORY/CLEAR`);

export const setCustomerCategory = createAction<ICustomerCategory>(`CUSTOMER_CATEGORY/SET`);

export const setCustomerCategoryList = createAction<ICustomerCategory[]>(
  `CUSTOMER_CATEGORY/SET_LIST`,
);

export const updateCustomerCategoryList = createAction<ICustomerCategory>(
  `CUSTOMER_CATEGORY/UPDATE_LIST`,
);

export const removeCustomerCategoryFromList = createAction<string>(
  `CUSTOMER_CATEGORY/REMOVE_FROM_LIST`,
);

export const duplicateCustomerCategory = createAction<ICustomerCategory>(
  `CUSTOMER_CATEGORY/DUPLICATE`,
);

export const clearCustomerCategoryDraft = createAction(`CUSTOMER_CATEGORY/CLEAR_DRAFT`);

export const setCustomerCategoryDraft = createAction<ICustomerCategory>(
  `CUSTOMER_CATEGORY/SET_DRAFT`,
);

export const setCustomerCategoryDraftValues = createAction<Partial<ICustomerCategory>>(
  `CUSTOMER_CATEGORY/SET_DRAFT_VALUE`,
);

export const resetCustomerCategorySubmit = createAction(`CUSTOMER_CATEGORY/RESET_SUBMIT`);

export const setCustomerCategorySubmitting = createAction(`CUSTOMER_CATEGORY/SET_SUBMITTING`);

export const setCustomerCategorySubmitError = createAction(`CUSTOMER_CATEGORY/SET_SUBMIT_ERROR`);

export const setCustomerCategorySubmitSuccess = createAction(
  `CUSTOMER_CATEGORY/SET_SUBMIT_SUCCESS`,
);

// Complex actions

export const fetchCustomerCategoryList = createAsyncThunk<
  void,
  IFetchWithEtagParams,
  AppThunkConfig
>(`CUSTOMER_CATEGORY/FETCH_LIST`, async ({ eTag, silent }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  try {
    if (!silent) {
      dispatch(startRequest('customer-categories'));
    }

    const { data } = await api.customerCategories.fetchCustomerCategoryListFromAPI();
    dispatch(setCustomerCategoryList(Array.isArray(data) ? data : []));

    dispatch(setEtagsCurrentEtag(eTag));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'customerCategory',
        action: 'list',
      }),
    );
  } finally {
    if (!silent) {
      dispatch(endRequest('customer-categories'));
    }
  }
});

export const createCustomerCategory = createAsyncThunk<void, ICustomerCategory, AppThunkConfig>(
  `CUSTOMER_CATEGORY/CREATE`,
  async (customerCategory, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('customer-categories'));
    dispatch(setCustomerCategorySubmitting());
    try {
      const { data } = await api.customerCategories.createCustomerCategoryFromAPI(customerCategory);

      dispatch(setCustomerCategoryDraft(data));
      dispatch(updateCustomerCategoryList(data));
      dispatch(setCustomerCategorySubmitSuccess());

      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'category.customerCategory.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'customerCategory',
          action: 'save',
        }),
      );
      dispatch(setCustomerCategorySubmitError());
    } finally {
      dispatch(endRequest('customer-categories'));
    }
  },
);

export const updateCustomerCategory = createAsyncThunk<void, ICustomerCategory, AppThunkConfig>(
  `CUSTOMER_CATEGORY/UPDATE`,
  async (customerCategory, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('customer-categories'));
    dispatch(setCustomerCategorySubmitting());
    try {
      const { data } = await api.customerCategories.updateCustomerCategoryFromAPI(customerCategory);

      dispatch(setCustomerCategoryDraft(data));
      dispatch(updateCustomerCategoryList(data));
      dispatch(setCustomerCategorySubmitSuccess());

      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'category.customerCategory.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'customerCategory',
          action: 'save',
        }),
      );
      dispatch(setCustomerCategorySubmitError());
    } finally {
      dispatch(endRequest('customer-categories'));
    }
  },
);

export const deleteCustomerCategory = createAsyncThunk<void, string, AppThunkConfig>(
  `CUSTOMER_CATEGORY/DELETE`,
  async (customerCategoryId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('customer-categories'));
    try {
      await api.customerCategories.deleteCustomerCategoryFromAPI(customerCategoryId);

      dispatch(clearCustomerCategory());
      dispatch(removeCustomerCategoryFromList(customerCategoryId));

      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'category.customerCategory.successToast.delete',
          }),
          message: '',
          type: 'success',
        }),
      );
      dispatch(addHomeCustomerCategoriesTab());
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'customerCategory',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('customer-categories'));
    }
  },
);
