import { AxiosResponse } from 'axios';

import { removeNegativeItemIdsFromEntity } from '@laudus/shared-utils';
import { IProcess } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchProcessesListAPI = (): Promise<AxiosResponse<IProcess[]>> =>
  axios.post(
    `${VITE_API_URL}/production/processes/list`,
    {
      fields: [
        'processId',
        'createdAt',
        // 'recipe.name',
        'notes',
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchProcessAPI = (processId: number): Promise<AxiosResponse<IProcess>> =>
  axios.get(`${VITE_API_URL}/production/processes/${processId}`);

export const createProcessAPI = (process: IProcess): Promise<AxiosResponse<IProcess>> =>
  axios.post(`${VITE_API_URL}/production/processes`, {
    ...process,
    input: removeNegativeItemIdsFromEntity(process.input),
    output: removeNegativeItemIdsFromEntity(process.output),
  });

export const updateProcessAPI = (process: IProcess): Promise<AxiosResponse<IProcess>> =>
  axios.put(`${VITE_API_URL}/production/processes/${process.processId}`, {
    ...process,
    input: removeNegativeItemIdsFromEntity(process.input),
    output: removeNegativeItemIdsFromEntity(process.output),
  });

export const deleteProcessAPI = (processId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/production/processes/${processId}`);
