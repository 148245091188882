import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IGlobalCompany } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Simple actions
export const clearGlobalCompany = createAction('GLOBAL_COMPANIES/CLEAR');

export const clearGlobalCompanyDraft = createAction(`GLOBAL_COMPANIES/CLEAR_DRAFT`);

export const setGlobalCompany = createAction<IGlobalCompany>('GLOBAL_COMPANIES/SET');

export const setGlobalCompanyDraft = createAction<IGlobalCompany>('GLOBAL_COMPANIES/SET_DRAFT');

export const setGlobalCompanyDraftValues = createAction<Partial<IGlobalCompany>>(
  'GLOBAL_COMPANIES/SET_DRAFT_VALUE',
);

export const setGlobalCompaniesList = createAction<IGlobalCompany[]>('GLOBAL_COMPANIES/SET_LIST');

export const updateGlobalCompaniesList = createAction<IGlobalCompany>(
  'GLOBAL_COMPANIES/UPDATE_LIST',
);

export const removeGlobalCompanyFromList = createAction<number>(
  'GLOBAL_COMPANIES/REMOVE_FROM_LIST',
);

//Complex actions
export const fetchGlobalCompaniesList = createAsyncThunk<void, void, AppThunkConfig>(
  'GLOBAL_COMPANIES/FETCH_GLOBAL_COMPANIES_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('global-users-list-read'));
    try {
      const { data } = await api.globalCompanies.getCompaniesListAPI();
      dispatch(setGlobalCompaniesList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'glCompanies',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('global-users-list-read'));
    }
  },
);

export const fetchGlobalCompany = createAsyncThunk<void, number, AppThunkConfig>(
  'GLOBAL_COMPANIES/FETCH',
  async (glUserId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('global-company-read'));
    try {
      const { data } = await api.globalCompanies.getGlobalCompanyById(glUserId);

      dispatch(setGlobalCompany(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'glCompanies',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('global-company-read'));
    }
  },
);
