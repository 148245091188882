import { ICurrency } from '@laudus/types';

interface ICurrencyParams {
  value: number;
  showSymbol?: boolean;
  mainCurrency: ICurrency | undefined;
  mainCurrencyDecimals: number;
}

export const currency = ({
  value,
  showSymbol = false,
  mainCurrency,
  mainCurrencyDecimals,
}: ICurrencyParams): string => {
  const numberValue = new Intl.NumberFormat('es-CL', {
    style: 'currency',
    currency: 'CLP',
    currencyDisplay: 'code',
    maximumFractionDigits: mainCurrencyDecimals,
    minimumFractionDigits: mainCurrencyDecimals,
  })
    .format(value)
    .replace('CLP', '')
    .trim()
    .toString();

  const symbolValue = mainCurrency?.symbol ?? '';

  if (showSymbol) {
    return `${symbolValue} ${numberValue}`;
  } else {
    return numberValue;
  }
};
