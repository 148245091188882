import { intl } from '@laudus/intl';
import { USER_RIGHTS } from '@laudus/shared-utils';
import { IReportsLeftMenuItemWithReport, ReportsLeftMenuItemTypes } from '@laudus/types';

export const ACCOUNTING_REPORTS_WITHOUT_SECTION: IReportsLeftMenuItemWithReport[] = [
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.f29Ta' }),
    config: {
      name: 'F29_ta.frx',
      type: 'pdf',
      filters: ['year', 'month'],
    },
    userRights: {
      [USER_RIGHTS.SALES.INVOICES.GENERAL]: {
        read: true,
      },
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.numberedSheets' }),
    config: {
      name: 'hojas numeradas_ta',
      type: 'pdf',
      filters: ['startPage', 'endPage'],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.feesCertificates' }),
    config: {
      name: 'certificados de honorarios_ta',
      type: 'pdf',
      filters: ['year'],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.feesBook' }),
    config: {
      name: 'libro de honorarios_ta',
      type: 'pdf',
      filters: ['printHeader', 'month', 'year'],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({
      id: 'reports.report.title.exchangeRateDifferencesInPurchases',
    }),
    config: {
      name: 'diferencias por tipo de cambio, compras_ta',
      type: 'pdf',
      filters: ['dateFrom', 'dateTo'],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.exchangeRateDifferencesInSales' }),
    config: {
      name: 'diferencias por tipo de cambio, ventas_ta',
      type: 'pdf',
      filters: ['dateFrom', 'dateTo'],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({
      id: 'reports.report.title.summaryOfExpensesAndIncomeByCostCenter',
    }),
    config: {
      name: 'centros de costes, gastos e ingresos_ta',
      type: 'pdf',
      filters: ['dateFrom', 'dateTo', 'costCenter'],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.booksOfBillsOfExchange' }),
    config: {
      name: 'libro de letras de cambio_ta',
      type: 'pdf',
      filters: ['dateFrom', 'dateTo', 'printHeader'],
    },
  },
];
