import { isNegativeNumber } from './asserts';

/**
 * Returns a negative unique id that is within the Int32 range.
 * It uses a certain randomization to avoid duplicated ids when used inside a loop.
 */
export function getNegativeUniqueNumericId(): number {
  // Int32 range for negative numbers
  const max = 0;
  const min = -2147483648;

  return Math.floor(Math.random() * (max - min + 1) + min);
}

export const TextNegativeNumber = () => {
  return <div>{getNegativeUniqueNumericId()}</div>;
};

interface ItemWithNumericItemId {
  itemId?: number | undefined;
}

interface EntityWithItems {
  items: ItemWithNumericItemId[];
}

export function removeNegativeItemIdsFromEntity<T extends EntityWithItems>(entity: T): T {
  return {
    ...entity,
    items: entity.items.map((item) => {
      const { itemId } = item;

      if (!itemId || isNegativeNumber(itemId)) {
        return { ...item, itemId: undefined };
      }

      return item;
    }),
  };
}

interface LineWithNumericLineId {
  lineId?: number | undefined;
}

interface EntityWithLines {
  lines?: LineWithNumericLineId[];
}

export function removeNegativeLineIdsFromEntity<T extends EntityWithLines>(entity: T): T {
  return {
    ...entity,
    lines: entity.lines?.map((line) => {
      const { lineId } = line;

      if (!lineId || isNegativeNumber(lineId)) {
        return { ...line, lineId: undefined };
      }

      return line;
    }),
  };
}
