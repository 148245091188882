import { AxiosResponse } from 'axios';

import { IExCaja } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchExCajasListFromAPI = (): Promise<AxiosResponse<IExCaja[]>> =>
  axios.post(
    `${VITE_API_URL}/HR/exCajas/list`,
    {
      fields: [
        'exCajaId',
        'name',
        'percentage',
        'previredId',
        'electronicBookId',
        'IPSId',
        'regimeIPS',
        'notes',
      ],
      orderBy: [{ field: 'name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchExCajaFromAPI = (exCajaId: number): Promise<AxiosResponse<IExCaja>> =>
  axios.get(`${VITE_API_URL}/HR/exCajas/${exCajaId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createExCajaFromAPI = (exCaja: IExCaja): Promise<AxiosResponse<IExCaja>> =>
  axios.post(`${VITE_API_URL}/HR/exCajas`, exCaja);

export const updateExCajaFromAPI = (exCaja: IExCaja): Promise<AxiosResponse<IExCaja>> =>
  axios.put(`${VITE_API_URL}/HR/exCajas/${exCaja.exCajaId}`, exCaja);

export const deleteExCajaFromAPI = (exCajaId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/HR/exCajas/${exCajaId}`);
