import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { ICcaf } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Simple actions
export const clearCcaf = createAction('CCAF/CLEAR');

export const clearCcafDraft = createAction('CCAF/CLEAR_DRAFT');

export const setCcaf = createAction<ICcaf>('CCAF/SET_OUTPUT');

export const setCcafDraft = createAction<ICcaf>('CCAF/SET_DRAFT');

export const setCcafDraftValues = createAction<Partial<ICcaf>>('CCAF/SET_DRAFT_VALUE');

export const setCcafsList = createAction<ICcaf[]>('CCAF/SET_LIST');

export const updateCcafsList = createAction<ICcaf>('CCAF/UPDATE_LIST');

export const removeCcafFromList = createAction<string>('CCAF/REMOVE_FROM_LIST');

export const duplicateCcaf = createAction<ICcaf>('CCAF/DUPLICATE');

// Complex actions
export const fetchCcafsList = createAsyncThunk<void, void, AppThunkConfig>(
  'CCAF/FETCH_CCAF_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('ccafs'));
    try {
      const { data } = await api.ccafs.fetchCcafsListFromAPI();
      dispatch(setCcafsList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('ccafs'));
    }
  },
);

export const fetchCcaf = createAsyncThunk<void, string, AppThunkConfig>(
  'CCAF/FETCH_INPUT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('ccafs'));
    try {
      const { data } = await api.ccafs.fetchCcafFromAPI(id);
      dispatch(setCcaf(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('ccafs'));
    }
  },
);

export const createCcaf = createAsyncThunk<void, ICcaf, AppThunkConfig>(
  'CCAF/CREATE',
  async (ccaf, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('ccafs'));
    try {
      const { data } = await api.ccafs.createCcafFromAPI(ccaf);
      dispatch(setCcaf(data));
      dispatch(fetchCcafsList());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.save',
          }),
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('ccafs'));
    }
  },
);

export const updateCcaf = createAsyncThunk<void, ICcaf, AppThunkConfig>(
  'CCAF/FETCH',
  async (ccaf, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('ccafs'));
    try {
      const { data } = await api.ccafs.updateCcafFromAPI(ccaf);
      dispatch(setCcaf(data));
      dispatch(fetchCcafsList());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.save',
          }),
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('ccafs'));
    }
  },
);

export const deleteCcaf = createAsyncThunk<void, string, AppThunkConfig>(
  'CCAF/DELETE_CCAF',
  async (CCAFId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('ccafs'));
    try {
      await api.ccafs.deleteCcafFromAPI(CCAFId);
      dispatch(removeCcafFromList(CCAFId));
      dispatch(clearCcaf());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.delete',
          }),
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('ccafs'));
    }
  },
);

export const saveCcaf = createAsyncThunk<void, ICcaf, AppThunkConfig>(
  'CCAF/SAVE',
  async (ccaf, ThunkAPI) => {
    const { dispatch } = ThunkAPI;

    if (ccaf.CCAFId) {
      dispatch(updateCcaf(ccaf));
    } else {
      dispatch(createCcaf(ccaf));
    }
    dispatch(clearCcafDraft());
  },
);
