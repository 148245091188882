import { AxiosResponse } from 'axios';

import { IRemunerationConcept } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchRemunerationConceptListFromAPI = (): Promise<
  AxiosResponse<IRemunerationConcept[]>
> =>
  axios.post(
    `${VITE_API_URL}/HR/RemunerationConcepts/list`,
    {
      fields: [
        'name',
        'remunerationConceptId',
        'parentId',
        'type',
        'itemOrder',
        'dataType',
        'decimals',
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchRemunerationConceptFromAPI = (
  remunerationConceptId: string,
): Promise<AxiosResponse<IRemunerationConcept>> =>
  axios.get(`${VITE_API_URL}/HR/RemunerationConcepts/${remunerationConceptId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createRemunerationConceptFromAPI = (
  remunerationConcept: IRemunerationConcept,
): Promise<AxiosResponse<IRemunerationConcept>> =>
  axios.post(`${VITE_API_URL}/HR/RemunerationConcepts`, remunerationConcept);

export const updateRemunerationConceptFromAPI = (
  remunerationConcept: IRemunerationConcept,
): Promise<AxiosResponse<IRemunerationConcept>> =>
  axios.put(
    `${VITE_API_URL}/HR/RemunerationConcepts/${remunerationConcept.remunerationConceptId}`,
    remunerationConcept,
  );

export const deleteRemunerationConceptFromAPI = (
  remunerationConceptId: string,
): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/HR/RemunerationConcepts/${remunerationConceptId}`);
