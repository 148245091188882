import { forwardRef } from 'react';

import { getCurrencyList, useAppSelector } from '@laudus/redux-app';

import { SelectEditor } from './SelectEditor';

const SelectCurrencyEditorComponent: React.ForwardRefRenderFunction<any, any> = (props, ref) => {
  const currencies = useAppSelector(getCurrencyList);

  const options = currencies.map((currency) => ({
    label: currency.code,
    value: currency.code,
  }));

  return <SelectEditor {...props} options={options} ref={ref} />;
};

SelectCurrencyEditorComponent.displayName = 'SelectCurrencyEditor';

export const SelectCurrencyEditor = forwardRef(SelectCurrencyEditorComponent);
