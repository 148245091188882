import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IStockControlFilters, IStockControlItems } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const addHomeStockControlTab = () =>
  addTab({
    tab: {
      id: 'stockControl',
      title: intl.formatMessage({ id: 'stockControl.tabTitle' }),
      path: 'pages/StockControl/StockControl',
      isRemovable: true,
    },
  });

export const addViewStockControlTab = () =>
  addTab({
    tab: {
      id: 'stockControl',
      title: intl.formatMessage({ id: 'stockControl.tabTitle' }),
      path: 'pages/StockControl/StockControlView',
      isRemovable: true,
    },
  });

export const clearStockControl = createAction('STOCKCONTROL/CLEAR');
export const setStockControl = createAction<IStockControlItems[]>('STOCKCONTROL/SET');
export const clearStockControlFilter = createAction('STOCKCONTROL/CLEAR_FILTERS');
export const setStockControlFilter = createAction<Partial<IStockControlFilters>>(
  'STOCKCONTROL/SET_FILTERS',
);

interface FetchStockControlProps {
  productId: number;
  filters: IStockControlFilters;
}

export const fetchStockControl = createAsyncThunk<void, FetchStockControlProps, AppThunkConfig>(
  'STOCKCONTROL/FETCH',
  async (stockControlProps, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('stock-control'));
    try {
      const { product, ...filters } = stockControlProps.filters;
      const { data } = await api.products.fetchProductStockControlAPI(
        stockControlProps.productId,
        filters,
      );
      dispatch(setStockControl(Array.isArray(data) ? data : []));

      // return data;
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'stockControl',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('stock-control'));
    }
  },
);
