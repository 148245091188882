import { createSelector } from '@reduxjs/toolkit';

import { IReceiptType } from '@laudus/types';

import { AppState } from '../../store';
import { CodeCategories } from '../codeCategories/constants';

import { ICodesState } from './reducer';

export const getCodesSlice = (state: AppState): ICodesState => state.codes;

export const getCodesList = createSelector([getCodesSlice], (state) => state.list);

export const getCodesListByCategoryName = createSelector(
  [getCodesList, (_, categoryName: string) => categoryName],
  (codes, categoryName) => codes.filter((code) => code.category_description === categoryName),
);

export const getCodesListByCategoryNameAlphabetically = createSelector(
  [getCodesList, (_, categoryName: string) => categoryName],
  (codes, categoryName) => {
    return codes
      .filter((code) => code.category_description === categoryName)
      .sort((a, b) => (a.description ?? '').localeCompare(b.description ?? ''));
  },
);

export const getCodesListByCategoryId = createSelector(
  [getCodesList, (_, categoryId: number) => categoryId],
  (codes, categoryId) => codes.filter((code) => code.category_codeCategoryId === categoryId),
);

export const getPaymentMethods = createSelector(
  [(state: AppState) => getCodesListByCategoryId(state, CodeCategories.CHARGES_TYPES.id)],
  (codes) =>
    codes.map((code) => {
      const receiptType: IReceiptType = { code: code.code, description: code.description };

      return receiptType;
    }),
);

export const getAdditionalPaymentMethods = createSelector(
  [(state: AppState) => getCodesListByCategoryId(state, CodeCategories.CHARGES_TYPES.id)],
  (codes) =>
    codes
      .filter((code) => code.allowChanges === true)
      .map((code) => {
        const receiptType: IReceiptType = { code: code.code, description: code.description };

        return receiptType;
      }),
);
