import { createSelector } from '@reduxjs/toolkit';

import { AppState, IBanksState } from '../..';
import { getCurrencyList } from '../../slices/currencies';

export const getBanksSlice = (state: AppState): IBanksState => state.banks;

export const getBanksList = createSelector([getBanksSlice], (state) => state.list);

export const getBank = createSelector([getBanksSlice], (state) => state.current);

export const getBankDraft = createSelector([getBanksSlice], (state) => state.draft);

export const getBankCurrencyById = (bankId: string) =>
  createSelector([getBanksList, getCurrencyList], (banks, currencies) => {
    const bankCurrencyCode = banks.find((bank) => bankId === bank.bankId)?.currencyCode;
    return currencies.find((currency) => currency.code === bankCurrencyCode);
  });
