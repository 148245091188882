import { createSelector } from '@reduxjs/toolkit';

import { IAuthAccessToken } from '@laudus/types';

import { GlobalState } from '../../store';

import { IAuthState } from './reducer';

export const getAuthSlice = (state: GlobalState): IAuthState => state.auth;

export const getAuthToken = createSelector([getAuthSlice], (state) => state.authToken);

export const getIsAuthenticated = createSelector([getAuthSlice], (state) => !!state.authToken);

export const getAccessError = createSelector([getAuthSlice], (state) => state.accessError);

export const hasAccessError = createSelector([getAuthSlice], (state) => !!state.accessError);

export const getAccessTokens = createSelector([getAuthSlice], (state) => state.accessTokens);

export const getAccessToken = (VATId: string | undefined) => {
  return createSelector([getAccessTokens], (tokens) =>
    tokens.find((t: IAuthAccessToken) => t.VATId === VATId),
  );
};

export const getAuthLoginName = createSelector([getAuthSlice], (state) => state.loginName);

export const getGlobalUser = createSelector([getAuthSlice], (state) => state.globalUser);

export const getGlobalUserAccountType = createSelector(
  [getGlobalUser],
  (globalUser) => globalUser.accountType,
);

export const hasAuthError = createSelector([getAuthSlice], (state) => !!state.authError);

export const getAuthError = createSelector([getAuthSlice], (state) => state.authError);

export const getAuthState = createSelector(
  [getIsAuthenticated, getAuthError],
  (isLoggedIn, error) => ({
    isLoggedIn,
    error,
  }),
);

export const getIsAuthorised = createSelector(
  [getIsAuthenticated, getAccessTokens],
  (isAuthenticated, hasAcccessTokens) => isAuthenticated && !!hasAcccessTokens.length,
);

export const getLastVatId = createSelector([getAuthSlice], (state) => state.lastVatId);

export const getAuthFormState = createSelector([getAuthSlice], (state) => state.formState);

export const getPasswordResetSent = createSelector(
  [getAuthSlice],
  (state) => state.passwordResetSent,
);

export const getAuthCompanyList = createSelector([getAuthSlice], (state) => state.companyList);

export const getAuthPosCompanyList = createSelector([getAuthCompanyList], (state) =>
  state.filter((item) => item.POS),
);

export const getGlobalUserAccountId = createSelector([getGlobalUser], (state) => state.glAccountId);

export const getIsAccountAdmin = createSelector([getGlobalUser], (state) => state.isAdmin);

export const getSortedAuthPosCompanyList = createSelector(
  [getAuthPosCompanyList],
  (authPosCompanyList) => {
    return [...authPosCompanyList].sort((companyA, companyB) =>
      companyA.name.localeCompare(companyB.name),
    );
  },
);
