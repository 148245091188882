import { AxiosResponse } from 'axios';

import { ICostCenter } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchCostCenterListFromAPI = (): Promise<AxiosResponse<ICostCenter[]>> =>
  axios.post(
    `${VITE_API_URL}/purchases/costcenters/list`,
    {
      fields: ['costCenterId', 'fullPath', 'name', 'parentId', 'code', 'notes', 'discontinued'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchCostCenterFromAPI = (costCenterId: string): Promise<AxiosResponse<ICostCenter>> =>
  axios.get(`${VITE_API_URL}/purchases/costcenters/${costCenterId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createCostCenterFromAPI = (
  costCenter: ICostCenter,
): Promise<AxiosResponse<ICostCenter>> =>
  axios.post(`${VITE_API_URL}/purchases/costcenters`, costCenter);

export const updateCostCenterFromAPI = (
  costCenter: ICostCenter,
): Promise<AxiosResponse<ICostCenter>> =>
  axios.put(`${VITE_API_URL}/purchases/costcenters/${costCenter.costCenterId}`, costCenter);

export const deleteCostCenterFromAPI = (costCenterId: string): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/purchases/costcenters/${costCenterId}`);
