import { messages as fieldMessages } from './fields';

export const es: Record<string, string> = {
  ...fieldMessages,

  // generic actions
  'action.accept': 'Aceptar',
  'action.cancel': 'Cancelar',
  'action.select': 'Seleccionar',
  'action.back': 'Volver',
  'action.exportToExcel': 'Exportar a Excel',
  'action.pasteFromExcel': 'Pegar desde Excel',
  'action.viewVideo': 'Ver video',
  'action.hour': 'Hora',

  // generic form
  'form.error.required': 'Este campo es obligatorio',
  'form.error.serverError': 'Error del Servidor',
  'form.error.min': 'El valor mínimo es {min}',
  'form.field.salesmen': 'Vendedor',

  // special API error codes
  'api.error.alert.T1': 'El RUT de la empresa no existe',
  'api.error.alert.T4': 'La API no está activada para la empresa',
  'api.error.alert.T5': 'El usuario no existe en la empresa',
  'api.error.alert.T51': 'Hay más de un usuario con el mismo ID en la empresa',
  'api.error.alert.T6': 'El usuario está bloqueado o discontinuado',
  'api.error.alert.T7': 'Usuario/contraseña incorrecto',
  'api.error.alert.TA1': 'El RUT de la empresa no existe',
  'api.error.alert.TA4': 'El usuario no existe en la empresa, o está repetido',
  'api.error.alert.TA5': 'La API no está activada para la empresa',
  'api.error.alert.TA6': 'Nombre de usuario no permitido',

  // login
  'login.form.loginName': 'Usuario',
  'login.form.password': 'Contraseña',
  'login.form.submit': 'Aceptar',
  'login.form.title': 'Bienvenido al Servicio de Punto de Venta de Laudus',
  'login.error.notAuthorized':
    'Su empresa no está autorizada para la aplicación de Punto de Venta, por favor consulte a su administrador',
  'login.showPassword': 'Mostrar contraseña',
  'login.hidePassword': 'Ocultar contraseña',

  // reset password
  'resetPassword.button.backToLogin': 'Volver al Login',
  'resetPassword.button.createNewPassword': 'Crear nueva contraseña',
  'resetPassword.button.requestNewPassword': 'Restablecer contraseña',
  'resetPassword.error': 'Ha ocurrido un error al restablecer la contraseña',
  'resetPassword.form.error.passwordNotMatch': 'Las contraseñas no coinciden',
  'resetPassword.forgotPassword': '¿Has olvidado la contraseña?',
  'resetPassword.input.label.password': 'Nueva contraseña',
  'resetPassword.input.label.repeatPassword': 'Repita nueva contraseña',
  'resetPassword.input.label.user': 'Usuario',
  'resetPassword.match': 'Las contraseñas no coinciden',
  'resetPassword.recoverPassword': 'Recuperar contraseña',
  'resetPassword.subtitle.fillUser':
    'Rellene los datos a continuación para poder restablecer su contraseña',
  'resetPassword.subtitle.stepsToFollow':
    'Le hemos enviado un correo electrónico a su email con los pasos a seguir',
  'resetPassword.title.checkEmail': '¡Revise su email!',
  'resetPassword.title.forgotPassword': '¿Ha olvidado su contraseña?',
  'resetPassword.title.newPassword': 'Ingrese una nueva contraseña',
  'resetPassword.toast.success': 'La contraseña ha sido restablecida correctamente',

  // login company
  'login.company.title': 'Seleccione una empresa',

  // login account
  'login.account.form.title': 'Especifique la Caja y el Vendedor/a',
  'login.account.form.pos.label': 'Caja',
  'login.account.form.pos.placeholder': 'Seleccione la caja',
  'login.account.form.salesperson.label': 'Vendedor',
  'login.account.form.salesperson.placeholder': 'Seleccione el vendedor',
  'login.account.form.branch.label': 'Sucursal',
  'login.account.form.branch.placeholder': 'Seleccione la sucursal',
  'login.account.form.submit': 'A Vender!',
  'login.account.button.back': 'Volver al login',

  // shift start
  'start.modal.title': 'Apertura de Caja',
  'start.modal.description': `Ingrese el Efectivo disponible en Caja
    en el momento de la apertura`,
  'start.modal.enterAmount': 'Introduzca la cantidad',

  // shift close
  'close.modal.title': 'Cierre de Caja',
  'close.modal.description':
    'Complete los campos e Introduzca el dinero en efectivo disponible en Caja',
  'close.modal.enterAmount': 'Introduzca la cantidad',

  // not found
  'notFound.label': 'Haga clic para ir a casa',

  // navigation
  'nav.order': 'Pedidos',
  'nav.ticket': 'Facturas y Boletas',
  'nav.history': 'Histórico y Devoluciones',
  'nav.cash': 'Entradas y Salidas de Caja',
  'nav.drafts': 'Borradores',
  'nav.settings': 'Ajustes de al cuenta',
  'nav.logout': 'Cerrar sesión',
  'nav.close': 'Cerrar Caja',
  'nav.open': 'Abrir Caja',
  'nav.back': 'Volver',
  'nav.selectCustomer': 'Seleccionar cliente',
  'nav.saveDraft': 'Guardar borrador',
  'nav.cancel': 'Cancelar',
  'nav.printSalesInvoice': 'Imprimir factura',
  'nav.finish': 'Finalizar',
  'nav.company': 'EMPRESA',
  'nav.salesperson': 'VENDEDOR/A',
  'nav.pos': 'CAJA',
  'nav.branch': 'SUCURSAL',
  'nav.shifts': 'Turnos',
  'nav.cancelTicket.confirmationModal.button.confirm': 'Sí, cancelar',
  'nav.cancelTicket.confirmationModal.button.cancel': 'No',
  'nav.cancelTicket.confirmationModal.title': 'Cancelar venta',
  'nav.cancelTicket.confirmationModal.subtitle': '¿Desea cancelar la venta?',

  'nav.cancelUpdateCatalog.confirmationModal.button.confirm': 'Sí',
  'nav.cancelUpdateCatalog.confirmationModal.button.cancel': 'No',
  'nav.cancelUpdateCatalog.confirmationModal.title': 'Cancelar edición del catálogo',
  'nav.cancelUpdateCatalog.confirmationModal.subtitle': '¿Desea cancelar la edición del catalogo?',
  'catalogGridItem.label.sku': 'Código',
  'catalogGridItem.label.description': 'Descripción',
  'catalogGridItem.label.barCode': 'Código de barras',
  'catalogGridItem.label.unitPriceWithTaxes': 'Precio con IVA',
  'nav.pos.settings': 'Ajustes',
  'nav.cancelUpdateSettings.confirmationModal.button.confirm': 'Sí',
  'nav.cancelUpdateSettings.confirmationModal.button.cancel': 'No',
  'nav.cancelUpdateSettings.confirmationModal.title': 'Cancelar edición de ajustes',
  'nav.cancelUpdateSettings.confirmationModal.subtitle': '¿Desea cancelar la edición de ajustes?',
  // orders
  'orders.title': 'Pedidos',
  'orders.placeOrder': 'Hacer pedido',
  'orders.error.noCustomer.title': 'Error al crear el pedido',
  'orders.error.noCustomer.message': 'Seleccione un cliente para continuar con el pedido.',
  'orders.success.title': 'Confirmación del pedido',
  'orders.success.message': 'El pedido {orderId} ha sido confirmado.',
  'orders.error.generalVAT.message':
    'No se pueden tomar pedidos con productos sin tasa de IVA genérica',

  // tickets
  'tickets.title': 'Facturas y Boletas',
  'ticket.payInFull': 'Pagar Total',
  'ticket.closed': 'La Caja Esta Cerrada',
  'ticket.title': 'Pago',
  'ticket.customer.required': 'Debe seleccionar un cliente para continuar',

  // history
  'history.title': 'Histórico y Devoluciones',
  'history.drawerTitle': 'Búsqueda avanzada',
  'history.grid.docType': 'Tipo',
  'history.grid.docNumber': 'Número',
  'history.grid.issuedDate': 'Emisión',
  'history.grid.customer': 'Cliente',
  'history.grid.total': 'Total',

  // cash
  'cash.title': 'Caja',
  'cash.action.newIngress': 'Nueva entrada a Caja',
  'cash.action.newEgress': 'Nueva salida de Caja',

  // pos
  'pos.errorToast.read': 'Ha habido un error leyendo la caja',
  'pos.warning.errorMessage': 'Ha habido un error genérico en la llamada a la API',

  // POS - Missing minimum user rights
  'pos.missingMinimumUserRights.title': 'No tiene suficientes permisos',
  'pos.missingMinimumUserRights.contactAdmin':
    'Por favor póngase en contacto con su administrador de Laudus POS para que le otorgue dichos permisos.',

  'pos.missingMinimumUserRights.06I.read': 'Necesita el permiso de lectura para acceder a Cajas.',
  'pos.missingMinimumUserRights.06O.read':
    'Necesita el permiso de lectura para acceder a Aperturas de Caja.',
  'pos.missingMinimumUserRights.06O.write':
    'Necesita el permiso de escritura para acceder a Aperturas de Caja.',
  'pos.missingMinimumUserRights.016.read':
    'Necesita el permiso de lectura para acceder a Vendedores.',
  'pos.missingMinimumUserRights.009.read':
    'Necesita el permiso de lectura para acceder a Productos.',
  'pos.missingMinimumUserRights.025.read':
    'Necesita el permiso de lectura para acceder a Familias de Productos.',
  'pos.missingMinimumUserRights.002.read':
    'Necesita el permiso de lectura para acceder a Clientes.',
  'pos.missingMinimumUserRights.051.read':
    'Necesita el permiso de lectura para acceder a Sucursales.',
  'pos.missingMinimumUserRights.04W.read':
    'Necesita el permiso de lectura para acceder a Listas de Precios.',
  'pos.missingMinimumUserRights.05M.read':
    'Necesita el permiso de lectura para acceder a Impuestos.',
  'pos.missingMinimumUserRights.04O.read':
    'Necesita el permiso de lectura para acceder a Formas de Pago.',
  'pos.missingMinimumUserRights.001.read':
    'Necesita el permiso de lectura para acceder a Facturas.',
  'pos.missingMinimumUserRights.001.write':
    'Necesita el permiso de escritura para acceder a Facturas.',
  'pos.missingMinimumUserRights.030.read': 'Necesita el permiso de lectura para acceder a Boletas.',
  'pos.missingMinimumUserRights.030.write':
    'Necesita el permiso de escritura para acceder a Boletas.',

  // posAdjustments
  'posAdjustment.direction.input': 'Entrada en caja',
  'posAdjustment.direction.output': 'Salida de caja',
  'posAdjustment.option.showInputsOutputs': 'Ver entradas y salidas',
  'posAdjustment.option.showInputs': 'Ver entradas',
  'posAdjustment.option.showOutputs': 'Ver salidas',
  'posAdjustment.form.category': 'Concepto',
  'posAdjustment.form.amount': 'Cantidad',
  'posAdjustment.form.notes': 'Notas',

  // drafts
  'drafts.menu.noTicketsSaved': 'No hay tickets guardados',
  'drafts.overwriteTicket.errorMessage':
    '¿Desea recuperar el Borrador (se borrarán los productos seleccionados)?',
  'drafts.products': `{itemsCount, plural,
  one {1 producto}
  other {{itemsCount} productos}
}`,
  'drafts.restore': 'Recuperar borrador',
  'drafts.title': 'Borradores',
  'drafts.saveError.title': 'Error guardando borrador',
  'drafts.saveError.noProductSelected':
    'Para guardar un borrador nuevo, debe seleccionar algún producto',
  'drafts.savedMessage': 'Se guardó el borrador',

  // salesmen
  'salesmen.errorToast.list': 'Ha habido un error leyendo el listado de vendedores',
  // 'salesmen.warning.errorMessage':
  //   'Ha habido un error genérico en la operación con el vendedor',

  // settings
  'settings.title': 'Ajustes',
  'settings.rounding.error': 'Error al redondear el total',
  'settings.roundingProduct.notFoundInSettings':
    'No se ha encontrado el producto de redondeo en los parametros de configuración',
  'settings.roundingProduct.notFoundInProductList':
    'No se ha encontrado el producto de redondeo en el listado de productos',
  'settings.defaultCustomer.search': 'Buscar cliente',
  'settings.roundingProduct.search': 'Buscar producto',

  // products
  'products.empty': 'No se encontraron productos',
  'products.errorToast.list': 'Error al obtener la lista de productos',
  'products.warning.errorMessage': 'Ha ocurrido un error generico al obtener la lista de productos',
  'productsSales.errorToast.list': 'Error al obtener la lista de ventas de productos',
  'productsSales.warning.errorMessage':
    'Ha ocurrido un error generico al obtener la lista de ventas de productos',
  'products.picture.error': 'Error al cargar la imagen del producto',
  'products.picture.upload.error': 'Error al subir la imagen del producto',
  'products.picture.delete.error': 'Error al eliminar la imagen del producto',
  'products.picture.errorMessage':
    'Se ha producido un error genérico al cargar la imagen del producto.',
  'products.picture.add': 'Arrastre una imagen',
  'products.picture.or': 'o',
  'products.picture.selectFile': 'Seleccione un archivo',

  // search bar
  'searchBar.searchProduct': 'Buscar productos',
  'searchBar.advanceSearch': 'Búsqueda avanzada',
  'searchBar.orderBy': 'Ordenar por',
  'searchBar.view': 'Vista',

  // product order by
  'productOrderBy.alphabetical': 'De la A a la Z',
  'productOrderBy.lowestPrice': 'Precio: de más barato a más caro',
  'productOrderBy.highestPrice': 'Precio:  de más caro a más barato',
  'productOrderBy.topSellers': 'Más vendidos',

  // product view type
  'productViewType.largeGrid': 'Mosaico grande',
  'productViewType.list': 'Lista sin foto',
  'productViewType.mediumGrid': 'Mosaico mediano',
  'productViewType.picturedList': 'Lista con foto',
  'productViewType.smallGrid': 'Mosaico pequeño',

  // ticket item
  'ticketItem.price': 'precio',
  'ticketItem.total': 'total',
  'ticketItem.discount': 'Dto',

  // ticket panel
  'ticketPanel.totalDiscount': 'Descuento total',
  'ticketPanel.taxes': 'Impuestos',
  'ticketPanel.subtotal': 'Subtotal: {subTotal}',
  'ticketPanel.noitems.title': 'No hay items agregados',
  'ticketPanel.noitems.message': 'Por favor, seleccione productos para agregar a la Boleta/Factura',
  'ticketPanel.pos.notInvoiceable':
    'No puede emitir este tipo de documento. Por favor, verifique que su Caja pueda emitirlo, o bien que la empresa tenga habilitado este tipo de documento.',
  'ticketMixedPayment.error.amountGreaterThanTotalInvoice':
    'El monto ingresado es mayor al total de la factura',

  // create invoice modal
  'createInvoiceModal.title': 'Crear Factura de un Pedido',
  'createInvoiceModal.form.orderNumber': 'Número de pedido',

  // product categories
  'productCategories.errorToast.list': 'Error al obtener la lista de categorías de productos',
  'productCategories.warning.errorMessage':
    'Ha ocurrido un error generico al obtener la lista de categorías de productos',
  'productCategories.viewAll': 'Ver todas',

  // codes
  'codes.errorToast.read': 'Ha habido un error leyendo la lista de códigos',
  'codes.warning.errorMessage':
    'Ha habido un error genérico en la operación con la lista de códigos',

  // customers
  'customers.errorToast.list': 'Error al obtener la lista de clientes',
  'customers.warning.errorMessage': 'Ha habido un error genérico en la operación con el cliente',
  'customers.save.error': 'Error al guardar el cliente',
  'customers.errorToast.read': 'Error al obtener el cliente',
  'customers.drawerTitle': 'Seleccionar cliente',
  'customers.new': 'Nuevo cliente',
  'customers.saveAndSelect': 'Aceptar',
  'customers.cancel': 'Cancelar',
  'customers.form.rut': 'RUT',
  'customers.form.name': 'Cliente',
  'customers.form.legalName': 'Razón social',
  'customers.form.email': 'Email',
  'customers.form.phone1': 'Teléfono',
  'customers.form.address': 'Dirección',
  'customers.form.county': 'Comuna',
  'customers.form.city': 'Ciudad',
  'customers.form.state': 'Región',
  'customers.form.activityName': 'Giro',
  'customers.form.addressBilling': 'Dirección',
  'customers.form.countyBilling': 'Comuna',
  'customers.form.cityBilling': 'Ciudad',
  'customers.form.stateBilling': 'Región de facturación',

  // customers grid
  'customers.grid.rut': 'RUT',
  'customers.grid.name': 'NOMBRE',
  'customers.grid.legalName': 'RAZÓN SOCIAL',
  'customers.grid.email': 'EMAIL',
  'customers.grid.phone1': 'TELÉFONO',

  // customersInvoices

  'customerInvoices.errorToast.list': 'Error al obtener la lista de facturas del cliente',
  'customerInvoices.errorToast.read': 'Error al obtener el detalle de la factura de cliente',
  'customerInvoices.warning.errorMessage':
    'Ha habido un error genérico en la operación con la factura de cliente',

  // currencies
  'currency.code': 'Código',
  'currency.name': 'Nombre',
  'currencies.headerData.code': 'Nombre',
  'currencies.headerData.name': 'Descripción',
  'currencies.headerData.symbol': 'Símbolo',
  'currencies.headerData.decimals': 'Decimales',
  'currencies.headerData.codeDTE': 'Código Factura Electrónica',
  'currencies.headerData.nameDTE': 'Glosa Factura Electrónica',
  'currencies.tabTitle': 'Monedas',
  'currencies.confirmWindow.title': 'Eliminar Moneda',
  'currencies.confirmWindow.subtitle': '¿Desea Eliminar esta Moneda definitivamente?',
  'currencies.errorToast.save': 'Error al guardar la moneda',
  'currencies.errorToast.delete': 'Error al eliminar la moneda',
  'currencies.successToast.delete': 'Moneda borrada correctamente',
  'currencies.errorToast.list': 'Error al obtener la lista de monedas',
  'currencies.errorToast.read': 'Error al obtener la moneda',
  'currencies.warning.errorMessage': 'Ha ocurrido un error generico al obtener la lista de monedas',
  'currencies.T/C': 'T/C',
  'currencyType.all': 'Todos los movimientos',
  'currencyType.main': 'Sólo moneda nacional',
  'currencyType.foreign': 'Sólo moneda extranjera',

  // pay detail panel
  'payDetailPanel.totalDiscount': 'Descuento total: {discount}%',

  // ticket pay
  'ticketPay.title': 'Pago',
  'ticketPay.docTypesTitle': '¿Quiere factura o boleta?',

  'ticketPayBillingData.edit.customer': 'Editar datos de cliente',
  'ticketPayBillingData.title': 'Datos de facturación',
  'ticketPayBillingData.edit': 'Editar',
  'ticketPayBillingData.cancel': 'Cancelar',
  'ticketPayBillingData.save': 'Guardar',

  'ticketPayTerms.anotherAmount': 'Otra cantidad',
  'ticketPayTerms.title': 'Formas de pago',
  'ticketPayTerms.cash': 'Efectivo',
  'ticketPayTerms.cash.total': 'Total',
  'ticketPayTerms.cash.toReturn': 'Vuelto',
  'ticketPayTerms.creditCard': 'Tarjeta de crédito',
  'ticketPayTerms.transfers': 'Transferencia / Ingreso en cuenta',
  'ticketPayTerms.debitCard': 'Tarjeta de débito',
  'ticketPayTerms.check': 'Cheque',
  'ticketPayPrint.total': 'Total',
  'ticketPayTerms.mixedPayment': 'Pago mixto',

  'ticketPayInvoiceSendOptions.title': '¿Cómo desea la Boleta / Factura?',
  'ticketPayInvoiceSendOptions.printToPaper': 'Impresa en papel',
  'ticketPayInvoiceSendOptions.print': 'Imprimir',
  'ticketPayInvoiceSendOptions.sendByEmail': 'Enviar por correo electrónico',
  'ticketPayInvoiceSendOptions.changeTicket': '¿Desea ticket de cambio?',
  'ticketPayInvoiceSendOptions.changeTicket.print.title': 'TICKET PARA EL CAMBIO',
  'ticketPayInvoiceSendOptions.changeTicket.print.docNumber': 'Boleta',
  'ticketPayInvoiceSendOptions.changeTicket.print.date': 'Fecha',
  'ticketPayInvoiceSendOptions.changeTicket.print.branch': 'Sucursal',
  'ticketPayInvoiceSendOptions.changeTicket.print.salesman': 'Vendedor',
  'ticketPayInvoiceSendOptions.changeTicket.print.thanks': '¡MUCHAS GRACIAS!',
  'ticketPayInvoiceSendOptions.send': 'Enviar',
  'ticketPayInvoiceSendOptions.placeholder': 'Ingrese la dirección de correo electrónico',

  'ticketPayPrint.success.title': '¡Enhorabuena!',
  'ticketPayPrint.success.subtitle': 'El pago se ha realizado con éxito',
  'ticketPayPrint.paymentMethod': 'Método de pago',
  'ticketPayCustomerModal.noCustomerSelected.error':
    'No hay cliente seleccionado, debe seleccionar un cliente para poder editarlo',
  'ticketPayCustomerModal.EditDefaultCustomerSelected.error':
    'No se puede editar el Cliente por Defecto para Boletas',

  // history
  'searchBar.historySearch': 'Buscar factura/boleta',
  'history.empty': 'No se encontraron facturas/boletas',

  // settings
  'settings.warning.errorMessage':
    'Ha ocurrido un error generico al intentar obtener los ajustes de la cuenta',
  'history.creditNote.create': 'Emitir Nota de Crédito',
  'settings.image.fit.title': 'Ajuste de imagen',
  'settings.image.fit.text':
    'Seleccione la manera en la que desea que se comporten las imágenes de su catálogo:',
  'settings.image.fit.cover':
    'Con esta opción la imagen se ajustará automáticamente para cubrir todo el espacio disponible.',

  'settings.image.fit.contain':
    'Con esta opción se respetará la proporción de su imagen y se rellenarán de blanco los huecos libres del espacio.',

  'settings.ticket.title': 'Ticket de cambio',
  'settings.ticket.text': 'Escriba el texto que desea que aparezca en sus tickets de cambio:',

  'settings.printer.title':
    '¿Desea imprimir automáticamente la factura o boleta después de la pantalla de pago?',
  'settings.printer.text':
    'Al activar esta opción, no visualizará la pantalla final con la previsualización de la factura o boleta y las opciones de enviar por email e imprimir ticket de cambio',

  'settings.transbank.text':
    '¿Desea utilizar el terminal de Punto de Venta integrado de Transbank?',

  'settings.transbank.info':
    'El POS integrado de Transbank es un terminal específico que hay que solicitar a Transbank. No es un equipo adicional, es simplemente un terminal diferente al terminal clásico, y permite integrarse con software externo, como el POS de Laudus. En esta modalidad, el software le envía el importe de la compra automáticamente al terminal de Transbank, y espera la respuesta. Al finalizar la operación, el software recibe la confirmación de la compra, o denegación de la misma. Es más cómodo, seguro y rápido. Una vez que Transbank le ha entregado el equipo, debe instalar el Agente de Conexión, que se puede bajar desde aquí en sus versiones de ',
  'settings.transbank.windows': 'Windows',
  'settings.transbank.or': 'o',
  'settings.transbank.mac': 'Mac OSX.',
  'settings.label.global': 'Ajustes globales',
  'settings.label.pos': 'Ajustes de esta Caja',
  'settings.generic.customer': 'Cliente genérico para boleta',
  'settings.generic.customer.text':
    'Seleccione el cliente genérico que desea utilizar cuando no agregue los datos del cliente',
  'settings.generic.customer.placeholder': 'Buscar cliente',
  'settings.rounding.product': 'Producto para el redondeo',
  'settings.rounding.product.text':
    'Seleccione el producto a utilizar para el redondeo automático cuando el cliente pague en efectivo por la Norma del redondeo',
  'settings.rounding.product.placeholder': 'Buscar producto',
  'settings.rounding.product.drawerTitle': 'Seleccione el producto de redondeo',
  'settings.transbank.videoTitle': 'Cómo funciona el POS Integrado de Transbank',
  'settings.transbank.showVideo': 'Ver vídeo',
  'settings.transbank.printer': 'Impresora',
  'settings.transbank.transbank': 'Transbank',
  // credit note
  'creditNote.errorToast.save': 'Error al crear la nota de crédito',
  'creditNote.warning.errorMessage': 'Ha ocurrido un error generico al crear la nota de crédito',
  'creditNote.pickProducts': 'Seleccione los productos que desea devolver',
  'creditNote.title': 'Nota de crédito',
  'creditNote.grid.description': 'Descripción',
  'creditNote.grid.discount': 'Descuento',
  'creditNote.grid.sku': 'Referencia',
  'creditNote.grid.precioud': 'Precio Unitario',
  'creditNote.grid.total': 'Total',
  'historyCreditNotePanel.noitems.message':
    'Por favor, seleccione productos para agregar a la Nota de Crédito',
  // credit note print
  'creditNotePrint.title': 'Nota de crédito Nº {creditNoteNumber}',
  'creditNotePrint.success.subtitle': 'La Nota de Crédito se ha emitido con éxito',
  'creditNoteSendOptions.title': '¿Cómo desea la Nota de Crédito?',
  'creditNoteSendOptions.printToPaper': 'Impresa en papel',
  'creditNoteSendOptions.print': 'Imprimir',
  'creditNoteSendOptions.sendByEmail': 'Dirección de correo electrónico',
  'creditNoteSendOptions.placeholder': 'Ingrese la dirección de correo electrónico',
  'creditNoteSendOptions.send': 'Enviar',
  'historyCreditNote.emitCreditNote': 'Emitir Nota de Crédito',
  'historyCreditNote.notAllowed':
    'No puede emitir este tipo de documento. Por favor, verifique que su Caja pueda emitirlo, o bien que la empresa tenga habilitado este tipo de documento.',
  'historyCreditNote.docTypeNotAllowed':
    'No se puede hacer una Nota de Crédito del tipo de documento seleccionado',
  'historyCreditNotePanel.items.badQuantity':
    'No se puede devolver más productos de los que se vendieron originalmente',
  'historyCreditNotePanel.items.positiveQuantity':
    'La cantidad de productos a devolver debe ser menor a 0',

  // Products
  'products.drawerTitle': 'Búsqueda avanzada',
  'products.drawerShowDiscontinued': 'Mostrar productos descontinuados',

  // Products advance search grid
  'products.grid.sku': 'REFERENCIA',
  'products.grid.description': 'DESCRIPCIÓN',
  'products.grid.barCode': 'CÓDIGO DE BARRAS',
  'products.grid.unitPrice': 'PRECIO UN.',
  'products.grid.unitPriceWithTaxes': 'PRECIO UN. CON IVA',
  'products.grid.unitOfMeasure': 'UDS.',
  'products.grid.productCategory': 'FAMILIA',

  // sales documents
  'salesDocument.cannotEditIfSentToSII':
    'No se puede modificar un documento que ya ha sido enviado al SII',

  // sales invoices
  'salesInvoices.errorToast.list': 'Error al obtener lista de factura',
  'salesInvoices.errorToast.read': 'Error al obtener factura',
  'salesInvoices.errorToast.readPDF': 'Error al obtener PDF',
  'salesInvoices.errorToast.save': 'Error al crear la factura',
  'salesInvoices.errorToast.delete': 'Error al Eliminar la factura',
  'salesInvoices.warning.errorMessage': 'Ha ocurrido un error generico al crear la factura',
  'salesInvoices.warning.errorMessagePDF': 'Ha ocurrido un error generico al obtener el PDF',
  'salesInvoices.errorToast.sendByEmail': 'Error al enviar la factura por email',
  'salesInvoices.errorMessage.sendByEmail':
    'Ha ocurrido un error generico al enviar la factura por email',
  'salesInvoices.updateSalesInvoicesDraftItemsPrice.error':
    'Error al actualizar el precio del producto {productDescription}',
  'salesInvoices.updateSalesInvoicesDraftItemsPrice.error.message':
    'Se ha producido un error al actualizar el precio del producto {productDescription} intentelo de nuevo',
  'salesInvoices.priceUpdated': 'Los precios de los productos seleccionados han sido actualizados',

  // system
  'system.email.success.create': 'E-mail enviado correctamente',
  'system.email.success.message': 'El e-mail ha sido correctamente enviado a su destinatario',
  'system.email.error.create': 'Ha ocurrido un error al enviar el e-mail',
  'system.email.warning.errorMessage': 'Ha ocurrido un error genérico al enviar e-mail',
  'system.email.emailTemplate.headerTitle': 'Aquí tiene su',
  'system.email.emailTemplate.greeting': 'Estimado/a, ',
  'system.email.emailTemplate.description':
    'Puede ver los datos de su factura/boleta a continuación. Recuerde que puede contactar con nosotros en caso de que tenga cualquier duda, estaremos encantados de resolverla.',
  'system.email.emailTemplate.regards': 'Atentamente,',
  'system.email.emailTemplate.docType': 'Tipo de documento',
  'system.email.emailTemplate.folio': 'Folio',
  'system.email.emailTemplate.issueDate': 'Fecha de emisión',
  'system.email.emailTemplate.dueDate': 'Fecha de vencimiento',
  'system.email.emailTemplate.paymentMethod': 'Método de pago',
  'system.email.emailTemplate.net': 'NETO',
  'system.email.emailTemplate.vat': 'IVA',
  'system.email.emailTemplate.total': 'TOTAL',
  'system.email.emailTemplate.attachmentInfo':
    'Recuerde que puede encontrar el documento al completo en los archivos adjuntos.',
  'system.email.emailTemplate.footer.description':
    'Documento electrónico generado por software Laudus®.',
  // select
  'select.noOptions': 'No hay opciones para mostrar',
  'select.loading': 'Búsqueda',

  // ERP
  '400': 'Error de usuario',
  '401': 'Token expirado',
  '403': 'Acceso denegado',
  'accessDenied': 'Acceso denegado, compruebe si tiene permisos de al menos lectura',
  '404': 'No encontrado',
  '422': 'Error de validación',
  '500': 'Error del sevidor',
  'action.configurate': 'Configurar',
  'action.checkSIIState': 'Comprobar estado',
  'action.delete': 'Eliminar',
  'action.duplicate': 'Duplicar',
  'action.edit': 'Editar',
  'action.email': 'Email',
  'action.erase': 'Borrar',
  'action.excel': 'Excel',
  'action.invert': 'Invertir',
  'action.new': 'Nuevo',
  'action.genderFemale.new': 'Nueva',
  // 'action.cancel': 'Cancelar',
  'action.preview': 'Vista previa',
  'action.print': 'Imprimir',
  'action.print.on.device': 'Imprimir en POS',
  'action.saveAndCreateFem': 'Guardar y crear nueva',
  'action.saveAndCreateNew': 'Guardar y crear nuevo',
  'action.search': 'Buscar',
  'action.searchCostCenter': 'Buscar Centro de Costos',
  'action.searchAccount': 'Buscar Cuenta',
  'action.searchBranch': 'Buscar Sucursal',
  'action.searchCustomer': 'Buscar cliente',
  'action.searchCustomerCategory': 'Buscar familia de clientes',
  'action.searchContact': 'Buscar Contacto',
  'action.searchSalesman': 'Buscar Vendedor',
  'action.searchDealer': 'Buscar Distribuidor',
  'action.searchProduct': 'Buscar producto',
  'action.searchProductDiscontinued': 'Mostrar discontinuados',
  'action.searchUserDiscontinued': 'Mostrar discontinuados',
  'action.searchTerminatedEmployee': 'Mostrar finiquitados',
  'action.searchProductCategory': 'Buscar familia de productos',
  'action.searchSupplier': 'Buscar proveedor',
  'action.searchWarehouse': 'Buscar bodega',
  'action.searchSalesOrder': 'Buscar Pedido',
  'action.searchRecipe': 'Buscar Receta',
  'action.searchTerm': 'Buscar Forma de pago',
  'action.searchPurchaseWaybill': 'Buscar Guía de despacho de compras',
  'action.searchPurchaseOrder': 'Buscar Orden de compra',
  // 'action.select': 'Seleccionar',
  // 'action.selectConcept': 'Elegir un concepto',
  'action.send': 'Enviar',
  'action.sendToSII': 'Enviar al SII',
  'action.square': 'Cuadrar',
  'action.viewStock': 'Ver Stock',
  'alerts.accept': 'Aceptar',
  'action.treeview.closeAllNodesFemenine': 'Cerrar todas',
  'action.treeview.closeAllNodesMasculine': 'Cerrar todos',
  'action.treeview.openAllNodesFemenine': 'Abrir todas',
  'action.treeview.openAllNodesMasculine': 'Abrir todos',

  // MAIN MENU
  'navmenu.sales': 'Ventas',
  'navmenu.sales.customers': 'Clientes',
  'navmenu.sales.clientFamilies': 'Familias de Clientes',
  'navmenu.sales.priceLists': 'Listas de Precios',
  'navmenu.sales.opportunities': 'Oportunidades',
  'navmenu.sales.quotes': 'Cotizaciones',
  'navmenu.sales.orders': 'Pedidos',
  'navmenu.sales.dispatchGuides': 'Guías de Despacho',
  'navmenu.sales.invociesAndReceipts': 'Facturas, boletas...',
  'navmenu.sales.invociesAndReceipts.bills': 'Facturas',
  'navmenu.sales.invociesAndReceipts.stampingElectronicDocuments':
    'Timbraje de Documentos Electrónicos',
  'navmenu.sales.invociesAndReceipts.checkDtePending': 'Revisar DTE Pendientes',
  'navmenu.sales.charges': 'Cobros',
  'navmenu.sales.charges.charges': 'Cobros',
  'navmenu.sales.charges.bankDepositLocations': 'Bancos - Lugares de depósito',
  'navmenu.sales.pos': 'Cajas',
  'navmenu.sales.pos.pos': 'Cajas',
  'navmenu.sales.pos.postAdjustments': 'Ingresos y Egresos de Caja',
  'navmenu.sales.terms': 'Formas de Pago',
  'navmenu.sales.salesmen': 'Vendedores',
  'navmenu.sales.dealers': 'Distribuidores',
  'navmenu.sales.carriers': 'Despachadores',
  'navmenu.sales.branches': 'Sucursales',
  'navmenu.sales.taxes': 'Impuestos',
  'navmenu.sales.tickets': 'Servicio técnico',
  'navmenu.stock': 'Stock',
  'navmenu.stock.products': 'Productos',
  'navmenu.stock.productsCategories': 'Familias de Productos',
  'navmenu.stock.warehouses': 'Bodegas',
  'navmenu.stock.inventories': 'Inventarios',
  'navmenu.stock.inputs': 'Ingresos a Bodega',
  'navmenu.stock.outputs': 'Salidas de Bodega',
  'navmenu.stock.transfers': 'Traslados entre Bodegas',
  'navmenu.stock.lots': 'Lotes',
  'navmenu.stock.stages': 'Etapas del flujo productivo',
  'navmenu.purchases': 'Compras',
  'navmenu.purchases.purchaseOrders': 'Órdenes de Compra',
  'navmenu.purchases.suppliers': 'Proveedores',
  'navmenu.purchases.costCenters': 'Centros de Costos',
  'navmenu.purchases.currencies': 'Monedas',
  'navmenu.purchases.purchaseInvoices': 'Facturas, Boletas',
  'navmenu.purchases.purchasePayments': 'Pagos',
  'navmenu.purchases.fixedAssets': 'Activos fijos',
  'navmenu.purchases.purchaseWaybills': 'Guías de despacho de compras',

  'navmenu.staff': 'Personal',
  'navmenu.staff.employees': 'Empleados',
  'navmenu.staff.payroll': 'Liquidaciones',
  'navmenu.staff.remunerationConcepts': 'Estructura de Liquidaciones',
  'navmenu.staff.remunerationConceptTypes': 'Tipos de conceptos de sueldos',
  'navmenu.staff.provisionalInstitutions': 'Instituciones Previsionales',
  'navmenu.staff.holidays': 'Días Feriados',
  'navmenu.accounting': 'Contabilidad',
  'navmenu.accounting.accounts': 'Plan de cuentas',
  'navmenu.accounting.accountTypes': 'Cuentas tipo',
  'navmenu.accounting.journalEntries': 'Comprobantes',
  'navmenu.accounting.ledgers': 'Mayor',
  'navmenu.accounting.balanceSheets': 'Estados financieros',
  'navmenu.accounting.bankStatements': 'Cartolas',
  'navmenu.accounting.bankReconciliation': 'Conciliación bancaria',
  'navmenu.accounting.invoicesReconciliation': 'Conciliación de compras y ventas con el SII',
  'navmenu.tools': 'Herramientas',
  'navmenu.tools.statistics': 'Estadísticas',
  'navmenu.tools.dailyMovements': 'Movimiento Diario',
  'navmenu.tools.stockControl': 'Control de Existencias',
  'navmenu.tools.reports': 'Informes',
  'navmenu.menu': 'Menu',
  'navmenu.production.recipes': 'Recetas',
  'navmenu.stock.processes': 'Transformaciones',
  'navmenu.sales.recurringSalesInvoices': 'Facturas Recurrentes',

  // HOTKEY ACTION TEXT
  'hotkey.action.new': '<u>N</u>uevo',
  'hotkey.action.genderFemale.new': '<u>N</u>ueva',
  'hotkey.action.new.dashboard': '<u>N</u>ueva estadística',
  'hotkey.action.erase': 'E<u>l</u>iminar',
  'hotkey.action.search': '<u>B</u>uscar',
  'hotkey.action.edit': '<u>E</u>ditar',
  'hotkey.action.duplicate': 'D<u>u</u>plicar',
  'hotkey.action.cancel': '<u>C</u>ancelar',
  'hotkey.action.save': '<u>G</u>uardar',
  'hotkey.action.import.cafDTE': '<u>I</u>mportar archivo CAF desde su computador',
  'hotkey.action.import.customers': '<u>I</u>mportar clientes',
  'hotkey.action.import.invoicesCsv':
    '<u>I</u>Importar facturas de ventas desde el Registro de Ventas del SII',
  'hotkey.action.import.products': '<u>I</u>mportar productos',
  'hotkey.action.import.salesInvoices': '<u>I</u>mportar facturas',
  'hotkey.alt+a': 'alt+a',
  'hotkey.alt+e': 'alt+e',
  'hotkey.alt+c': 'alt+c',
  'hotkey.alt+u': 'alt+u',
  'hotkey.alt+l': 'alt+l',
  'hotkey.alt+i': 'alt+i',
  'hotkey.alt+n': 'alt+n',
  'hotkey.alt+g': 'alt+g',
  'hotkey.alt+b': 'alt+b',

  // HOLIDAYS
  'holidays.tabTitle': 'Días Feriados',
  'holidays.confirmWindow.title': 'Eliminar Día Festivo',
  'holidays.confirmWindow.subtitle': '¿Desea eliminar este "Día Festivo" definitivamente?',
  'holidays.form.date': 'Fecha',
  'holidays.form.repeating': 'Se repite todos los años',
  'holidays.form.description': 'Descripción',
  'holidays.form.description.placeholder': 'Feriado primero de año',
  'holidays.search.date': 'Fecha',
  'holidays.search.description': 'Descripción',
  'holidays.search.repeating': 'Se repite todos los años',
  'holidays.errorToast.read': 'Ha habido un error intentando leer la días feriado',
  'holidays.error.fallback': 'Ha habido un error al tratar la días feriado',

  // ACCOUNTS
  'accounts.tabTitle': 'Plan de Cuentas',
  'account.modal.accountNumber': 'Numero de Cuenta',
  'account.modal.name': 'Nombre',
  'accounts.errorToast.save': 'Ha habido un error guardando la cuenta',
  'accounts.errorToast.read': 'Ha habido un error intentando leer la cuenta',
  'accounts.errorToast.list': 'Ha habido un error intentando listar las cuentas',
  'accounts.successToast.save': 'Cuenta guardada correctamente',
  'accounts.groupsSearchWindow.title': 'Buscar Grupo',
  'accounts.warning.errorMessage': 'Ha habido un error genérico al guardar la cuenta',
  'accounts.successToast.delete': 'Cuenta borrada correctamente',
  'accounts.errorToast.delete': 'Ha habido un error borrando la cuenta',
  'accounts.treeview.addModalTitle': 'Añadir Cuenta',
  'accounts.treeview.addTitle': 'Añadir Cuenta',
  'accounts.treeview.deleteModalTitle': 'Eliminar Cuenta',
  'accounts.treeview.editModalTitle': 'Editar Cuenta',
  'accounts.treeview.deleteTitle': 'Eliminar Cuenta',
  'accounts.treeview.deleleSubtile': '¿Desea Eliminar esta "Cuenta" definitivamente?',

  // ACCOUNTTYPES
  'accountTypes.errorToast.list': 'Ha habido un error intentando obtener la lista de cuentas tipo',
  'accountTypes.errorToast.read': 'Ha habido un error al leer la cuenta tipo',
  'accountTypes.errorToast.save': 'Ha habido un error guardando la cuenta tipo',
  'accountTypes.successToast.save': 'Cuenta tipo guardada correctamente',
  'accountTypes.tabTitle': 'Cuentas tipo',
  'accountTypes.warning.errorMessage': 'Ha habido un error al tratar la cuenta tipo',
  'accountTypes.grid.description': 'Descripción',
  'accountTypes.grid.account.accountNumber': 'Cuenta',
  'accountTypes.grid.account.name': 'Nombre',

  //BALANCESHEET
  'balanceSheet.accordionButton.btn1': 'Tipo de estado financiero',
  'balanceSheet.accordionButton.btn2': 'Mostrar niveles',
  'balanceSheet.accordionButton.btn3': 'Periodo',
  'balanceSheet.accordionButton.btn4': 'Centro de costos',
  'balanceSheet.accordionButton.btn5': 'Opciones',
  'balanceSheet.action.getResults': 'Obtener Resultados',
  'balanceSheet.checkbox.label1': 'Ver porcentajes',
  'balanceSheet.checkbox.label2': 'Ver balance comparativo',
  'balanceSheet.checkbox.label3': 'Ver balance por C. Costos',
  'balanceSheet.checkbox.label4': 'Ver C. Costos discontinuados',
  'balanceSheet.checkbox.label5': 'Ver cuentas con saldo 0',
  'balanceSheet.checkbox.label6': 'Solo cuentas con movimiento',
  'balanceSheet.dateFrom': 'Fecha desde',
  'balanceSheet.dateTo': 'Fecha hasta',
  'balanceSheet.radio.label1': 'Sumas y saldos',
  'balanceSheet.radio.label2': 'Edo. Situación financiera',
  'balanceSheet.radio.label3': 'Por meses',
  'balanceSheet.radio.label4': 'Por años',
  'balanceSheet.radio.label5': 'Estado de resultados',
  'balanceSheet.radio.label6': 'Tributario',
  'balanceSheet.steps.step1':
    'Configure las opciones de visualización de sus estados financieros en el menú izquierdo.',
  'balanceSheet.steps.step2':
    'Pulse sobre el botón “Obtener resultado” para ver los estados financieros.',

  'banks.accept': 'Aceptar',
  'banks.cancel': 'Cancelar',
  'banks.card.cartolasFormat.title': 'Formato cartolas',
  'banks.card.generalData.title': 'Datos generales',
  'banks.data.cartolasFormat.delimiter': 'Delimitador',
  'banks.data.cartolasFormat.divider': 'Separador',
  'banks.data.cartolasFormat.statementCreditColumn': 'Cargo',
  'banks.data.cartolasFormat.statementDateColumn': 'Fecha',
  'banks.data.cartolasFormat.statementDepositColumn': 'Depósito',
  'banks.data.cartolasFormat.statementDescriptionColumn': 'Descripción',
  'banks.data.cartolasFormat.statementDocumentColumn': 'Nº Documento',
  'banks.data.generalData.account': 'Cuenta contable',
  'banks.data.generalData.creditsReconciliationMethod': 'Conciliar egresos',
  'banks.data.generalData.currency': 'Moneda',
  'banks.data.generalData.debitsReconciliationMethod': 'Conciliar ingresos',
  'banks.data.generalData.name': 'Nombre',
  'banks.delete': 'Eliminar',
  'banks.deleteMessage': 'Está a punto de eliminar este banco definitivamente, ¿está seguro?',
  'bank.errorToast.delete': 'Ha habido un error borrando el banco',
  'bank.errorToast.read': 'Ha habido un error leyendo el banco',
  'bank.errorToast.save': 'Ha habido un error guardando el banco',
  'banks.homeCard.create': 'Crear Banco',
  'banks.homeCard.search': 'Buscar Banco',
  'banks.homeCard.searchWindow': 'Buscar Bancos',
  'bank.successToast.delete': 'El banco ha sido eliminado correctamente',
  'bank.successToast.save': 'El banco ha sido guardado correctamente',
  'banks.tabTitle': 'Banco - Lugares de depósito',
  'banks.warning.errorMessage': 'Ha habido un error genérico en la operación con el banco',
  'banks.warning.title': 'Problemas con operación',
  'banks.errorToast.duplicate': 'Ha habido un error duplicando el banco',
  'banks.errorToast.read': 'Ha habido un error leyendo el banco',
  'bank.confirmWindow.subtitle': '¿Desea eliminar este "Banco" definitivamente?',
  'bank.confirmWindow.title': 'Eliminar Banco',
  'bankReconciliation.action.searchCartola': 'Conciliar otra cartola',
  'bankReconciliation.errorToast.read':
    'Ha habido un error leyendo la información de reconciliación',
  'bankReconciliation.gridCardTitle.bankStatement': 'Cartola',
  'bankReconciliation.gridCardTitle.journal': 'Mayor',
  'bankReconciliation.selectionItemBox.total': 'Total $',
  'bankReconciliation.summary.bank': 'Banco',
  'bankReconciliation.summary.from': 'Desde',
  'bankReconciliation.summary.number': 'Cartola',
  'bankReconciliation.summary.to': 'Hasta',
  'bankReconciliation.tabTitle': 'Conciliación bancaria',
  'bankReconciliation.warning.errorMessage':
    'Ha habido un error genérico en la operación con la reconciliación de cartola',
  'bankReconciliation.warning.selectAction': 'Debe seleccionar una acción para conciliar el cargo',
  'bankReconciliation.withdrawalModal.credit': 'Haber',
  'bankReconciliation.withdrawalModal.dateLabel': 'Fecha',
  'bankReconciliation.withdrawalModal.descriptionLabel': 'Descripción',
  'bankReconciliation.withdrawalModal.documentLabel': 'Documento',
  'bankReconciliation.withdrawalModal.generateJournalEntryText': 'Generar un comprobante contable',
  'bankReconciliation.withdrawalModal.invoiceFoundText':
    'Hemos encontrado una factura coincidente pero sin un pago. Indique la forma de pago y se rellenarán los datos con los datos de la cartola.',
  'bankReconciliation.withdrawalModal.invoiceNotFoundText':
    'No hemos encontrado una factura coincidente. ',
  'bankReconciliation.withdrawalModal.paymentFoundText':
    'Hemos encontrado un pago no coincidente en fecha pero sí en importe. Si selecciona esta opción se igualarán las fechas para que concilie el movimiento.',
  'bankReconciliation.withdrawalModal.paymentNotFoundText':
    'No hemos encontrado un pago no coincidente.',
  'bankReconciliation.withdrawalModal.provider': 'Proveedor/Beneficiario',
  'bankReconciliation.withdrawalModal.selectInvoice': 'Debe seleccionar una factura',
  'bankReconciliation.withdrawalModal.title': 'Conciliar cargo',
  'bankReconciliation.withdrawalModal.type': 'Tipo',
  'bankReconciliation.withdrawalModal.withdrawalLabel': 'Cargo',
  'bankReconciliationCandidateInvoices.errorToast.read':
    'Ha habido un error leyendo facturas candidatas a conciliar',
  'bankReconciliationCandidatePayments.errorToast.read':
    'Ha habido un error leyendo pagos candidatos a conciliar',
  'bankReconciliationCreateJournalEntry.errorToast.save':
    'Ha habido un error creando el comprobante para conciliar el cargo',
  'bankReconciliationCreatePayment.errorToast.save':
    'Ha habido un error creando el pago para conciliar el cargo',
  'bankReconciliationUpdatePayment.errorToast.save':
    'Ha habido un error modificando el pago para conciliar el cargo',
  'bankStatements.action.importLines': 'Importar movimientos',
  'bankStatements.confirmWindow.subtitle': '¿Desea eliminar ls "Cartola" definitivamente?',
  'bankStatements.confirmWindow.title': 'Eliminar Cartola',
  'bankStatements.importContinue.no': 'No',
  'bankStatements.importContinue.message':
    'Se han detectado lineas a importar ya existentes en la cartola.',
  'bankStatements.importContinue.question': '¿Desea realizar la importación de todos modos?',
  'bankStatements.importContinue.yes': 'Sí',
  'bankStatements.importDuplicateLabel.date': 'Fecha',
  'bankStatements.importDuplicateLabel.deposit': 'Deposito',
  'bankStatements.importDuplicateLabel.description': 'Descripción',
  'bankStatements.importDuplicateLabel.document': 'Documento',
  'bankStatements.importDuplicateLabel.withdrawal': 'Cargo',
  'bankStatements.importErrorToast.noBankMessage':
    'Para importar movimientos, antes debe seleccionar un banco',
  'bankStatements.importErrorToast.sourceError': 'Error obteniendo los datos: {errorMessage}',
  'bankStatements.errorToast.list': 'Ha habido un error leyendo la lista de cartolas',
  'bankStatements.errorToast.save': 'Ha habido un error guardando la cartola',
  'bankStatements.generalData.bankName': 'Banco',
  'bankStatements.generalData.number': 'Cartola',
  'bankStatements.generalData.year': 'Año',
  'bankStatements.generalData.dateFrom': 'Desde',
  'bankStatements.generalData.dateTo': 'Hasta',
  'bankStatements.generalData.initialBalance': 'Saldo Inicial',
  'bankStatements.generalData.finalBalance': 'Saldo Final',
  'bankStatements.successToast.delete': 'La cartola se ha borrado correctamente',
  'bankStatements.successToast.save': 'La cartola se ha guardado correctamente',
  'bankStatements.tabTitle': 'Cartolas',
  'bankStatements.warning.errorMessage':
    'Ha habido un error genérico en la operación con la cartola',

  'branches.accept': 'Aceptar',
  'branches.cancel': 'Cancelar',
  'branches.confirmWindow.subtitle': '¿Desea eliminar esta "Sucursal" definitivamente?',
  'branches.confirmWindow.title': 'Eliminar sucursal',
  'branches.data.generalData.address': 'Dirección',
  'branches.data.generalData.city': 'Ciudad',
  'branches.data.generalData.county': 'Comuna',
  'branches.data.generalData.fiscalId': 'Código del SII',
  'branches.data.generalData.schedule': 'Horario',
  'branches.data.generalData.state': 'Región',
  'branches.data.header.name': 'Nombre',
  'branches.data.moreData.ReceiptType': 'Forma de pago POS',
  'branches.data.moreData.costCenter': 'Centro de Costos Asociado',
  'branches.data.moreData.priceList': 'Lista de precios',
  'branches.data.moreData.warehouse': 'Bodega asociada',
  'branches.delete': 'Eliminar',
  'branches.errorToast.list': 'Ha habido un error leyendo la lista de sucursales',
  'branches.errorToast.read': 'Ha habido un error leyendo la sucursal',
  'branches.errorToast.save': 'Ha habido un error guardando la sucursal',
  'branches.errorToast.duplicate': 'Ha habido un error copiando la sucursal',
  'branches.warning.errorMessage': 'Ha habido un error genérico en la operación de la sucursal',
  'branches.generalData.branchId': 'Sucursal',
  'branches.successToast.delete': 'La sucursal ha sido eliminada correctamente',
  'branches.successToast.save': 'La sucursal ha sido guardada correctamente',
  'branches.tabTitle': 'Sucursales',
  'branches.tabsCard.authorizedDocumentsRanges': 'Correlativos asignadados',
  'branches.tabsCard.data': 'Datos',

  'branches.grid.docTypeId': 'Tipo de documento',
  'branches.grid.docNumberFrom': 'Desde',
  'branches.grid.docNumberTo': 'Hasta',
  'branches.grid.authorizationDate': 'Fecha',
  'branches.grid.pos': 'Caja',

  'carriers.tabTitle': 'Despachadores',
  'carriers.successToast.delete': 'El despachador ha sido eliminado correctamente',
  'carriers.successToast.save': 'El despachador ha sido guardado correctamente',
  'carriers.errorToast.delete': 'Ha habido un error eliminando el despachador',
  'carriers.errorToast.save': 'Ha habido un error guardando el despachador',
  'carriers.errorToast.list': 'Ha habido un error leyendo el listado de despachadores',
  'carriers.errorToast.read': 'Ha habido un error leyendo el despachador',
  'carriers.warning.errorMessage': 'Ha habido un error genérico en la operación con el despachador',
  'carriers.confirmWindow.subtitle': '¿Desea eliminar este "Despachador" definitivamente?',
  'carriers.confirmWindow.title': 'Eliminar Despachador',
  'carriers.headerData.name': 'Nombre',
  'carriers.headerData.legalName': 'Razón Social',
  'carriers.headerData.notes': 'Notas',
  'carriers.generalData.rut': 'RUT',
  'carriers.address.title': 'Dirección',
  'carriers.address.address': 'Dirección',
  'carriers.address.county': 'Comuna',
  'carriers.address.city': 'Ciudad',
  'carriers.address.phone': 'Teléfono',
  'carriers.address.email': 'Email',
  'cafdte.action.delete': 'Eliminar Timbraje',
  'cafdte.date': 'Fecha',
  'cafdte.delete': 'Está a punto de Eliminar este timbraje definitivamente, ¿está seguro?',
  'cafdte.errorFile': 'El fichero seleccionado no es un CAF válido',
  'cafdte.from': 'Folio desde',
  'cafdte.tabTitle': 'Timbraje',
  'cafdte.to': 'hasta',
  'cafdte.validation.text': 'CAF válido',
  'cafDte.successToast.save': 'CAF guardado correctamente',
  'cafDte.successToast.delete': 'CAF borrado correctamente',
  'cafDte.errorToast.delete': 'Ha habido un error borrando el CAF',
  'cafDte.warning.errorMessage': 'Ha habido un error genérico en la operación con el CAF',
  'category.customText.colorText': 'haciendo click encima del texto ',
  'category.customText.subTitle': 'ARRASTRAR',
  'category.customText.whiteText': 'Puede modificar la posición de los ítems ',
  'category.customText.whiteText2':
    'del ítem correspondiente y arrastrándolo hasta la posición deseada.',
  'customerCategory.errorToast.list': 'Ha habido un error cargando las familias de clientes.',
  'customerCategory.errorToast.save': 'Ha habido un error guardando la familia de clientes.',
  'customerCategory.errorToast.delete': 'Ha habido un error eliminando la familia de clientes.',
  'category.customerCategory.successToast.save':
    'La familia de clientes ha sido guardada correctamente',
  'category.customerCategoryDelete.error': 'Ha habido un error borrando familia de clientes.',
  'category.customerCategory.successToast.delete':
    'La familia de clientes ha sido borrada correctamente',
  'category.customerCategoryEdit.error': 'Ha habido un error modificando familia de clientes.',
  'category.customerCategoryEdit.success':
    'La familia de clientes ha sido modificada correctamente',
  'category.warning.errorMessage':
    'Ha habido un error genérico en la operación con la familia de clientes',
  'category.modal.label': 'Nombre',
  'productCategory.errorToast.read': 'Ha habido un error leyendo la familia de productos.',
  'productCategory.errorToast.save': 'Ha habido un error guardando familia de productos.',
  'category.productCategory.success': 'La familia de productos ha sido guardada correctamente',
  'productCategory.errorToast.delete': 'Ha habido un error borrando familia de productos.',
  'category.productCategoryDelete.success': 'La familia de productos ha sido borrada correctamente',
  'category.productCategoryEdit.success':
    'La familia de productos ha sido modificada correctamente',
  'category.secondCustomText.colorText': 'haciendo clic sobre el icono',
  'category.secondCustomText.colorText2': 'Guardar',
  'category.secondCustomText.colorText3': 'Cancelar.',
  'category.secondCustomText.subTitle': 'RENOMBRAR',
  'category.secondCustomText.whiteText': 'Puede modificar el nombre de cualquier ítem ',
  'category.secondCustomText.whiteText2':
    ' Una vez que haya escrito el nombre deseado, debe pulsar sobre el botón ',
  'category.secondCustomText.whiteText3':
    ' o en caso contrario, cancelar la modificación pulsando sobre el botón ',
  'category.thirdCustomText.colorText': 'haciendo click sobre el icono',
  'category.thirdCustomText.subTitle': 'BORRAR',
  'category.thirdCustomText.whiteText': 'Puede eliminar cualquier ítem de la familia ',
  'category.title': '¿Cómo usar las familias?',
  // 'codes.errorToast.read': 'Ha habido un error leyendo la lista de códigos',
  // 'codes.warning.errorMessage':
  //   'Ha habido un error genérico en la operación con la lista de códigos',
  'common.accept': 'Aceptar',
  'common.cancel': 'Cancelar',
  'common.customFields.label': 'Personalizado',
  'common.delete': 'Eliminar',
  'common.duplicate': 'Duplicar',
  'common.edit': 'Editar',
  'common.new': 'Nuevo',
  'common.search': 'Buscar',
  'common.saveAndPrint': 'Guardar e Imprimir',
  'common.emptyData': 'No hay datos que mostrar',
  'common.hour': 'Hora',
  'common.select': 'Seleccionar',

  'costCenter.generalData.costCenterId': 'Centro de Costos',
  'costCenter.errorToast.save': 'Hubo un error al guardar el centro de costos',
  'costCenter.errorToast.delete': 'Hubo un error al eliminar el centro de costos',
  'costcenter.modal.code': 'Código de Recaudación Electrónica',
  'costcenter.modal.discontinued': 'Discontinuado',
  'costcenter.modal.name': 'Nombre',
  'costcenter.modal.notes': 'Notas',
  'costCenter.successToast.delete': 'Centro de costos borrado correctamente',
  'costCenter.successToast.save': 'Centro de costos guardado correctamente',
  'costCenter.treeview.addModalTitle': 'Añadir centro de costos',
  'costCenter.treeview.addTitle': 'Añadir centro de costos',
  'costCenter.treeview.deleteModalTitle': 'Eliminar centro de costos',
  'costCenter.treeview.editModalTitle': 'Editar centro de costos',
  'costCenter.treeview.deleteTitle': 'Eliminar centro de costos',
  'costCenter.treeview.deleleSubtile':
    'Está a punto de eliminar este centro de costos definitivamente, ¿está seguro?',
  'costCenters.tabTitle': 'Centros de costos',
  'customer.generalData.customerId': 'Cliente',
  'customerCategory.generalData.customerCategoryId': 'Familia de Clientes',
  'customerCategory.tabTitle': 'Familias de Clientes',
  'customers.confirmWindow.subtitle': '¿Desea eliminar este "Cliente" definitivamente?',
  'customers.confirmWindow.title': 'Eliminar Cliente',
  'customers.SRI.imgInput': 'debug',
  'customers.address.inputData.address': 'Dirección',
  'customers.address.inputData.city': 'Ciudad',
  'customers.address.inputData.country': 'País',
  'customers.address.inputData.county': 'Comuna',
  'customers.address.inputData.cp': 'CP',
  'customers.address.inputData.description': 'Descripción',
  'customers.address.inputData.notes': 'Notas',
  'customers.address.inputData.state': 'Región',
  'customers.address.successToast.deleted': 'Dirección eliminada',
  'customers.address.successToast.save': 'La dirección ha sido guardada correctamente',
  'customers.constacts.successToast.deleted': 'Contacto eliminado',
  'customers.constacts.successToast.save': 'El contacto ha sido guardado correctamente',
  'customers.contacts.inputData.cellPhone': 'Celular',
  'customers.contacts.inputData.department': 'Departamento',
  'customers.contacts.inputData.discontinued': 'Discontinuado',
  'customers.contacts.inputData.email': 'Email',
  'customers.contacts.inputData.invoices': 'Enviar copia de facturas',
  'customers.contacts.inputData.mailings': 'Incluir en los mailings',
  'customers.contacts.inputData.name': 'Nombre',
  'customers.contacts.inputData.notes': 'Notas',
  'customers.contacts.inputData.phone': 'Teléfono',
  'customers.contacts.inputData.position': 'Cargo',
  'customers.contacts.inputData.surname': 'Apellidos',
  'customers.contacts.inputData.VATId': 'RUT',
  'customers.descriptionData.DTEEmail': 'Email DTE',
  'customers.descriptionData.VATId': 'RUT',
  'customers.descriptionData.title': 'Datos generales',
  'customers.descriptionData.account': 'Cuenta contable',
  'customers.descriptionData.activityName': 'Giro',
  'customers.descriptionData.address': 'Dirección',
  'customers.descriptionData.addressBilling': 'Dirección',
  'customers.descriptionData.blocked': 'Bloqueado',
  'customers.descriptionData.blockedIfOverCreditLimit':
    'Bloqueado solo cuando excede el límite de crédito',
  'customers.descriptionData.blockedIfOverdueInvoices':
    'Bloqueado solo cuando tiene facturas morosas',
  'customers.descriptionData.city': 'Ciudad',
  'customers.descriptionData.cityBilling': 'Ciudad',
  'customers.descriptionData.collectWeekday': 'Día de cobro',
  'customers.descriptionData.comercioNetCode': 'ComercioNet',
  'customers.descriptionData.country': 'País',
  'customers.descriptionData.countryBilling': 'País',
  'customers.descriptionData.county': 'Comuna',
  'customers.descriptionData.countyBilling': 'Comuna',
  'customers.descriptionData.creditLimit': 'Límite de crédito',
  'customers.descriptionData.creditLimitCurrency': 'PESOS',
  'customers.descriptionData.daysToExpiration': 'Plazo de pago',
  'customers.descriptionData.discount': 'Descuento',
  'customers.descriptionData.email': 'Email',
  'customers.descriptionData.footerCotizationNotes': 'Pie por defecto cotización',
  'customers.descriptionData.hasCredit': 'El cliente tiene crédito disponible',
  'customers.descriptionData.headerCotizationNotes': 'Encabezado por defecto cotización',
  'customers.descriptionData.legalName': 'Razón social',
  'customers.descriptionData.noAddress':
    'El cliente no tiene establecida una dirección, verifique ese dato en Datos Generales y guarde la información',
  'customers.descriptionData.notHasCredit': 'El cliente no tiene crédito disponible',
  'customers.descriptionData.phone1': 'Fono 1',
  'customers.descriptionData.phone2': 'Fono 2',
  'customers.descriptionData.priceList': 'Lista de precios',
  'customers.descriptionData.dealerList': 'Distribuidor',
  'customers.descriptionData.salesman': 'Vendedor',
  'customers.descriptionData.schedule': 'Horario',
  'customers.descriptionData.state': 'Región',
  'customers.descriptionData.term': 'Forma de pago',
  'customers.descriptionData.totalAllowed': 'Total disponible',
  'customers.descriptionData.totalPending': 'Total pendiente',
  'customers.descriptionData.webPage': 'Web',
  'customers.descriptionData.zipCode': 'CP',
  'customers.descriptionData.zipCodeBilling': 'CP',
  'customers.descriptionData.discountIsAdditive': 'Acumular descuentos',
  'customers.errorToast.save': 'Ha habido un error guardando el cliente',
  'customers.headerData.customerCategory': 'Familia de Cliente',
  'customers.headerData.name': 'Nombre',
  'customers.headerData.notes': 'Notas',
  'customers.successToast.delete': 'El cliente ha sido eliminado correctamente',
  'customers.successToast.save': 'El cliente ha sido guardado correctamente',
  'customers.sri.error': 'Hubo un error al obtener el informe',
  'customers.sri.instructionsText': 'Introduzca el siguiente código',
  'customers.sri.homeBoxInfo.label': '¿Cómo funciona este trámite?',
  'customers.sri.firstCustomText.subTitle': 'PASO 1: INTRODUCE EL CAPTCHA CORRECTAMENTE',
  'customers.sri.customText.whiteText':
    'Para poder la información tribitaria del Servicio de Impuestos Internos, primero deberá introducir correctamente el Código Captcha de validación de identidad que ve en pantalla y pulsar sobre el botón',
  'customers.sri.customText.colorText': '"Obtener informe".',
  'customers.sri.firstCustomText.subTitle2': 'PASO 2: CONSULTAR EL INFORME',
  'customers.sri.secondCustomText.whiteText':
    'Si ha introducido el código correctamente, aparecerá en pantalla su informe. En caso contrario, vuelva a entrar en la página e introduzca un nuevo código.',
  'customers.tab.SiiInfo': 'Información del SII',
  'customers.tab.contactos': 'Contactos',
  'customers.tab.credit': 'Límite de crédito',
  'customers.tab.direcciones': 'Direcciones',
  'customers.tab.generalCard': 'Datos generales',
  'customers.tab.invoices': 'Facturas',
  'customers.tab.mapa': 'Mapa',
  'customers.tab.notasCotizacion': 'Notas de cotización',
  'customers.tab.pendingInvoices': 'Facturas pendientes',
  'customers.tabTitle': 'Clientes',
  'customers.grid.addresses.addAddress': '+ Añadir dirección',
  // 'customers.errorToast.read': 'Ha habido un error leyendo el cliente',
  // 'customers.warning.errorMessage':
  //   'Ha habido un error genérico en la operación con el cliente',
  'customers.errorToast.delete': 'Ha habido un error eliminando el cliente',
  // 'customers.errorToast.list':
  //   'Ha habido un error leyendo la lista de clientes',
  'customerContacts.errorToast.list': 'Ha habido un error leyendo la lista de contactos',
  'customerContacts.errorToast.read': 'Ha habido un error leyendo el contacto',
  'customerContacts.errorToast.save': 'Ha habido un error guardando el contacto',
  'customerContacts.successToast.save': 'El contacto ha sido guardado correctamente',
  'customerContacts.warning.errorMessage':
    'Ha habido un error genérico en la operación con el contacto',
  'customerContacts.successToast.delete': 'El contacto ha sido eliminado correctamente',
  'customerContacts.errorToast.delete': 'Ha habido un error eliminando el contacto',
  'customerAddresses.errorToast.list': 'Ha habido un error leyendo la lista de direcciones',
  'customerAddresses.errorToast.read': 'Ha habido un error leyendo la dirección',
  'customerAddresses.errorToast.save': 'Ha habido un error guardando la dirección',
  'customerAddresses.successToast.save': 'La dirección ha sido guardado correctamente',
  'customerAddresses.warning.errorMessage':
    'Ha habido un error genérico en la operación con la dirección',
  'customerAddresses.successToast.delete': 'La dirección ha sido eliminado correctamente',
  'customerAddresses.errorToast.delete': 'Ha habido un error eliminando la dirección',

  'customerAddresses.grid.description': 'Nombre',
  'customerAddresses.grid.address': 'Dirección',
  'customerAddresses.grid.city': 'Ciudad',
  'customerAddresses.grid.county': 'Comuna',
  'customerAddresses.grid.notes': 'Notas',

  'customerAddresses.grid.modal.title': 'Dirección',
  'customerAddresses.grid.confirm.title': 'Eliminar Dirección',
  'customerAddresses.grid.confirm.text': '¿Desea eliminar esta dirección definitivamente?',

  'customFields.errorToast.list': 'Ha habido un error leyendo el personalizado',
  'customFields.warning.errorMessage':
    'Ha habido un error genérico en la operación con los personalizados',
  'daily.generalData.date': 'Fecha',
  'daily.tabTitle': 'Movimiento Diario',
  'daily.tabs.branch': ' Ventas por sucursal',
  'daily.tabs.categoryProducts': 'Ventas por familia de producto',
  'daily.tabs.invoices': 'Facturas Emitidas',
  'daily.tabs.invoices.details': 'Facturas Emitidas, Detalle',
  'daily.tabs.invoices.overdue': 'Facturas Vencidas',
  'daily.tabs.products': 'Ventas por producto',
  'daily.tabs.salesman': ' Ventas por vendedor',
  'daily.errorToast.read': 'Ha habido un error leyendo el movimiento diario',
  'daily.warning.errorMessage':
    ' Ha habido un error genérico en la operación con el movimiento diario',
  'dashboard.title': 'Título',
  'dashboard.accept': 'Aceptar',
  'dashboard.addDashboard': 'Nuevo dashboard',
  // 'dashboard.addStatistic': 'Nueva estadística',
  'dashboard.addStatisticTitle': 'Añada un título',
  'dashboard.area': 'Área',
  'dashboard.cancel': 'Cancelar',
  'dashboard.column': 'Barras',
  'dashboard.config': 'Configurar estadística',
  'dashboard.delete': 'Eliminar',
  'dashboard.deleteStatistic': 'Eliminar Estadística',
  'dashboard.deleteStatisticMessageFirst': 'Está a punto de Eliminar',
  'dashboard.deleteStatisticMessageSecond': 'definitivamente, ¿está seguro?',
  'dashboard.emptyData': 'No hay datos con las condiciones señaladas',
  'dashboard.error.saveNewDashboard': 'No se ha podido crear el dashboard',
  'dashboard.error.selectAStatistic.subtitle':
    'Seleccione un tipo de estadística en el menú de la izquierda para poder continuar',
  'dashboard.error.selectAStatistic.title': 'Elija el tipo de estadística',
  'dashboard.filter.branchId': 'Sucursal',
  'dashboard.filter.costCenterId': 'Centro de costos',
  'dashboard.filter.customerCategoryId': 'Familia de clientes',
  'dashboard.filter.customerId': 'Cliente',
  'dashboard.filter.gridTitle.customerId': 'Buscar cliente',
  'dashboard.filter.gridTitle.productCategoryId': 'Buscar familia de productos',
  'dashboard.filter.gridTitle.productId': 'Buscar producto',
  'dashboard.filter.period': 'Período',
  'dashboard.filter.posId': 'Caja',
  'dashboard.filter.productCategoryId': 'Familia de productos',
  'dashboard.filter.productId': 'Producto',
  'dashboard.filter.salesmanId': 'Vendedor',
  'dashboard.filter.specialFilters': 'Filtros especializados',
  'dashboard.help.customizable': 'PERSONALIZABLE',
  'dashboard.help.customizableDescription1': `Agregue sus estadísticas preferidas\ncon filtros`,
  'dashboard.help.customizableDescription2': 'a medida',
  'dashboard.help.laudus': ' Laudus®',
  'dashboard.help.options': 'CIENTOS DE OPCIONES',
  'dashboard.help.optionsDescription1': 'Elija entre una',
  'dashboard.help.optionsDescription2': 'variedad de opciones',
  'dashboard.help.optionsDescription3': 'disponibles y personalice su visualización',
  'dashboard.help.realTime': 'A TIEMPO REAL',
  'dashboard.help.realTimeDescription1': 'Podrá',
  'dashboard.help.realTimeDescription2': 'consultar en tiempo real',
  'dashboard.help.realTimeDescription3': 'los datos de su empresa, todo desde una única pantalla',
  'dashboard.help.title': 'Comience a configurar su panel personalizable de',
  'dashboard.help.tooltip1': '¡Comience ahora mismo a agregar',
  'dashboard.help.tooltip2': 'estadísticas',
  'dashboard.help.tooltip3': '!',
  'dashboard.line': 'Líneas',
  'dashboard.listErrorSubtitle': 'Ha habido un error cargando el dashboard',
  'dashboard.listErrorTitle': 'Error cargando el dashboard',
  'dashboard.pie': 'Torta',
  'dashboard.save': 'Guardar',
  'dashboard.seeAs': 'Opciones de visualización',
  'dashboard.select': 'Seleccionar',
  'dashboard.selectPeriod': 'Elegir período',
  'dashboard.selectStatistic': 'Seleccionar estadística',
  'dashboard.selectStatisticType': 'Elegir tipo de estadística',
  'dashboard.successToast.saveNewDashboard': 'El dashboard ha sido creado correctamente',
  'dashboard.table': 'Tabla',
  'dashboard.thisStatistic': 'esta estadística',
  'dashboard.updateAll': 'Actualizar todo',
  'dashboard.warning.errorMessage': 'Ha habido un error genérico en la operación con el dashboard',
  'dashboard.errorToast.list': 'Ha habido un error leyendo la lista de dashboards',
  'dashboard.errorToast.read': 'Ha habido un error leyendo el dashboard',
  'dashboard.errorToast.save': 'Ha habido un error guardando el dashboard',
  'dashboard.successToast.save': 'El dashboard ha sido guardado correctamente',
  // Dashboard period options
  'dashboard.periodOption.all': 'Desde siempre',
  'dashboard.periodOption.C-M': 'Mes actual',
  'dashboard.periodOption.L-30-D': 'Últimos 30 días',
  'dashboard.periodOption.C-Q': 'Trimestre actual',
  'dashboard.periodOption.L-90-D': 'Últimos 90 días',
  'dashboard.periodOption.C-Y': 'Año actual',
  'dashboard.periodOption.L-1-Y': 'Últimos 365 días',
  'day.friday': 'Viernes',
  'day.monday': 'Lunes',
  'day.saturday': 'Sábado',
  'day.sunday': 'Domingo',
  'day.thursday': 'Jueves',
  'day.tuesday': 'Martes',
  'day.wednesday': 'Miércoles',
  'day.friday.short': 'Vie',
  'day.monday.short': 'Lun',
  'day.saturday.short': 'Sáb',
  'day.sunday.short': 'Dom',
  'day.thursday.short': 'Jue',
  'day.tuesday.short': 'Mar',
  'day.wednesday.short': 'Mié',
  'dealers.accept': 'Aceptar',
  'dealers.address.address': 'Dirección',
  'dealers.address.city': 'Ciudad',
  'dealers.address.county': 'Comuna',
  'dealers.address.email': 'Email',
  'dealers.address.phone': 'Teléfono',
  'dealers.address.title': 'Dirección',
  'dealers.cancel': 'Cancelar',
  'dealers.confirmWindow.subtitle': '¿Desea eliminar este "Distribuidor" definitivamente?',
  'dealers.confirmWindow.title': 'Eliminar Distribuidor',
  'dealers.duplicate': 'Duplicar',
  'dealers.edit': 'Editar',
  'dealers.email': 'Email',
  'dealers.erase': 'Eliminar',
  'dealers.errorToast.delete': 'Ha habido un error eliminando el distribuidor',
  'dealers.errorToast.list': 'Ha habido un error leyendo la lista de distribuidores',
  'dealers.errorToast.read': 'Ha habido un error leyendo el distribuidor',
  'dealers.errorToast.save': 'Ha habido un error guardando el distribuidor',
  'dealers.excel': 'Excel',
  'dealers.headerData.discontinued': 'Discontinuado',
  'dealers.headerData.legalName': 'Razón Social',
  'dealers.headerData.name': 'Nombre',
  'dealers.headerData.notes': 'Notas',
  'dealers.headerData.rut': 'RUT',
  'dealers.headerData.salesmanId': 'Vendedor',
  'dealers.new': 'Nuevo',
  'dealers.preview': 'Vista previa',
  'dealers.print': 'Imprimir',
  'dealers.saveAndCreate': 'Guardar y crear nuevo',
  'dealers.search': 'Buscar',
  'dealers.searchDealer': 'Buscar Distribuidor',
  'dealers.successToast.delete': 'El distribuidor ha sido eliminado correctamente',
  'dealers.successToast.save': 'El distribuidor ha sido guardado correctamente',
  'dealers.tabTitle': 'Distribuidores',
  'dealers.warning.errorMessage': 'Ha habido un error genérico en la operación con el distribuidor',
  'dealers.warning.title': 'Problemas con operación',
  'DTEPendings.cofirmation_chunk_1': '¿Desea enviar estos',
  'DTEPendings.cofirmation_chunk_2': 'documentos al SII?',
  'DTEPendings.errorSendDTETOSII': 'Ha habido un error al enviar los DTE al SII',
  'DTEPendings.errorToast.read': 'Ha habido un error leyendo los DTE pendientes',
  'DTEPendings.help.title1': '¿Cómo funciona este trámite?',
  'DTEPendings.noSelected': 'Seleccione al menos un documento a enviar al SII',
  'DTEPendings.help.step1': 'PASO 1: ENVIAR AL SII',
  'DTEPendings.help.step1_1':
    'Para poder enviar sus facturas al Servicio de Impuestos Internos, primero deberá seleccionar una de las opciones de la tabla y pulsar sobre el botón ',
  'DTEPendings.help.step1_2': '“Enviar al SII”',
  'DTEPendings.help.step1_3':
    ' que encontrará en la parte inferior izquierda de la página. En caso de que ya haya realizado el envío de ese Ítem, el sistema no le permitirá volver a enviarlo.',
  'DTEPendings.help.step2': 'PASO 2: COMPROBAR ESTADO',
  'DTEPendings.help.step2_1':
    'Para poder revisar en qué parte del proceso de encuentra su envío, deberá pulsar sobre el botón ',
  'DTEPendings.help.step2_2': '“Comprobar estado”',
  'DTEPendings.help.step2_3':
    ' que encontrará en la parte inferior derecha de la página. Recuerde que los datos aparecerán unas XX horas después de realizar el envío.',
  'DTEPendings.sucessSendedToSII': 'Documentos enviados al SII correctamente',
  'DTEPendings.tabTitle': 'DTE Pendientes',
  'DTEPendings.warning.errorMessage': 'Ha habido un error genérico en la llamada a la API',
  'docTypes.errorToast.read': 'Ha habido un error leyendo los Tipos de documento',
  'docTypes.warning.errorMessage':
    'Ha habido un error genérico en la operación con los tipos de documento',

  'employees.confirmWindow.subtitle': '¿Desea eliminar este "Empleado" definitivamente?',
  'employees.confirmWindow.title': 'Eliminar Empleado',
  'employees.tabTitle': 'Empleados',
  'employees.errorToast.read': 'Ha habido un error leyendo el empleado',
  'employees.errorToast.save': 'Ha habido un error guardando el empleado',
  'employees.warning.errorMessage': 'Ha habido un error genérico en la operación con el empleado',
  'employees.errorToast.delete': 'Ha habido un error eliminando el empleado',
  'employees.tab.generalCard': 'Datos generales',
  'employees.tab.previsionalData': 'Datos previsionales',
  'employees.tab.apv': 'APV',
  'employees.tab.salaryCompositon': 'Composición sueldo',
  'employees.tab.contractTerminations': 'Finiquitos',
  'employees.tab.absences': 'Ausencias',
  'employees.tab.abcensesData': 'Datos',
  'employees.tab.abcensesHolidays': 'Vacaciones',
  'employees.tab.loans': 'Préstamos',
  'employees.tab.history': 'Incidencias',
  'employees.tab.notes': 'Notas',
  'employees.tab.archived': 'Archivos',
  'employees.tab.photo': 'Foto',
  'employees.tab.employeePortal': 'Portal empleados',
  'employees.tab.liquidation': 'Liquidaciones',
  'employees.tab.customFields': 'Personalizado',
  'employees.headerData.firstName': 'Nombre',
  'employees.headerData.lastName1': 'Primer Apellido',
  'employees.headerData.lastName2': 'Segundo Apellido',
  'employees.headerData.rut': 'RUT',
  'employees.descriptionCard.cardLabel': 'Información general',
  'employees.descriptionCard.position': 'Cargo',
  'employees.descriptionCard.workPhone': 'Teléfono',
  'employees.descriptionCard.extension': 'X.',
  'employees.descriptionCard.department': 'Departamento',
  'employees.descriptionCard.mobile': 'Celular',
  'employees.descriptionCard.email': 'Email',
  'employees.descriptionCard.costCenter': 'Centro de costo',
  'employees.descriptionCard.gender': 'Género',
  'employees.descriptionCard.maritalStatus': 'Estado civil',
  'employees.descriptionCard.restrictedAccess': 'Rol Privado',
  'employees.descriptionCard.foreigner': 'Extranjero',
  'employees.descriptionCard.countryOfOrigin': 'Nacionalidad',
  'employees.addressCard.cardLabel': 'Dirección particular',
  'employees.descriptionCard.address': 'Dirección',
  'employees.descriptionCard.county': 'Comuna',
  'employees.descriptionCard.zipCode': 'CP',
  'employees.descriptionCard.city': 'Ciudad',
  'employees.descriptionCard.homePhone': 'Teléfono',
  'employees.wayToPayCard.cardLabel': 'Forma de pago',
  'employees.wayToPayCard.paymentType': 'Pagar con',
  'employees.wayToPayCard.ePayment': 'Código pagos electrónicos',
  'employees.wayToPayCard.bank': 'Banco del empleado',
  'employees.wayToPayCard.accountNumber': 'Cuenta corriente',
  'employees.qualificationCard.cardLabel': 'Formación y cualificación',
  'employees.qualificationCard.profession': 'Profesión',
  'employees.qualificationCard.degree': 'Título',
  'employees.qualificationCard.qualification': 'Nivel',
  'employees.qualificationCard.degreeDate': 'Fecha titulación',
  'employees.APVICard.cardLabel': 'Ahorro Voluntario Individual (APVI)',
  'employees.APVICard.savingPlan': 'Depósitos cuenta de ahorro',
  'employees.APVICard.savingPlan.planType': 'Plan',
  'employees.APVICard.savingPlan.planAmount': 'Cantidad',
  'employees.APVICard.savingPlanAPV1': 'Ahorro voluntario: Cotización voluntaria 1',
  'employees.APVICard.savingPlanAPV1.planType': 'Plan',
  'employees.APVICard.savingPlanAPV1.planAmount': 'Cantidad',
  'employees.APVICard.savingPlanAPV2': 'Ahorro voluntario: Cotización voluntaria 2',
  'employees.APVICard.savingPlanAPV2.planType': 'Plan',
  'employees.APVICard.savingPlanAPV2.planAmount': 'Cantidad',
  'employees.APVICard.savingPlanAgreedDeposits': 'Ahorro voluntario: Departamentos convenidos',
  'employees.APVICard.savingPlanAgreedDeposits.planType': 'Plan',
  'employees.APVICard.savingPlanAgreedDeposits.planAmount': 'Cantidad',
  'employees.APVCCard.cardLabel': 'Ahorro Voluntario Colectivo (APVC)',
  'employees.APVCCard.savingPlanGroup': 'Administradora APVC',
  'employees.APVCCard.savingPlanGroup.planType': 'Plan',
  'employees.APVCCard.savingPlanGroup.contractNumber': 'Número de contrato',
  'employees.APVCCard.savingPlanGroup.companyAmount': 'Empleador',
  'employees.APVCCard.savingPlanGroup.employeeAmount': 'Trabajador',
  'employees.VoluntaryAffiliateCard.cardLabel': 'Afiliado voluntario',
  'employees.VoluntaryAffiliateCard.checkbox': 'Afiliado voluntario',
  'employees.VoluntaryAffiliateCard.rut': 'RUT',
  'employees.VoluntaryAffiliateCard.firstName': 'Nombre',
  'employees.VoluntaryAffiliateCard.lastName': 'Apellidos',
  'employees.VoluntaryAffiliateCard.AFP': 'AFP',
  'employees.VoluntaryAffiliateCard.deposit': 'Depósitos cuenta ahorro',
  'employees.previsionalDataCard.generalData.birthDate': 'Fecha de nacimiento',
  'employees.previsionalDataCard.generalData.employeeType': 'Tipo trabajador',
  'employees.previsionalDataCard.generalData.contractDate': 'Fecha de contratación',
  'employees.previsionalDataCard.generalData.contractType': 'Tipo de contrato',
  'employees.previsionalDataCard.workingDays.workingDaysPerWeek': 'Días semanales',
  'employees.previsionalDataCard.workingDays.hoursPerWeek': 'Horas semanales',
  'employees.previsionalDataCard.workingDays.workingDaysPerMonth': 'Días al mes',
  'employees.previsionalDataCard.workingDays.workingDayType': 'Tipo de jornada',
  'employees.previsionalDataCard.generalData.contractTerminationDate': 'Fecha de finiquito',
  'employees.previsionalDataCard.generalData.contractTerminationType': 'Causal finiquito',
  'employees.dependentsCard.cardLabel': 'Cargas familiares',
  'employees.dependentsCard.dependentsStandard': 'Simples',
  'employees.dependentsCard.dependentsPregnancy': 'Maternales',
  'employees.dependentsCard.dependentsWithDisabilities': 'Invalidez',
  'employees.dependentsCard.amountPerDependent': 'Tramo',
  'employees.workingDays.cardLabel': 'Jornada',

  'employees.hardWorkCard.cardLabel': 'Trabajo pesado',
  'employees.hardWorkCard.heavyWorkRate': 'Tasa',
  'employees.hardWorkCard.heavyWorkDescription': 'Descripción',
  'employees.legalPrevisionCard.cardLabel': 'Previsión legal',
  'employees.legalPrevisionCard.isapre': 'Isapre',
  'employees.legalPrevisionCard.planType': 'Plan',
  'employees.legalPrevisionCard.planAmountUF': 'UF',
  'employees.legalPrevisionCard.contractNumber': 'Número Contrato',
  'employees.legalPrevisionCard.planAmount': 'Pesos',
  'employees.legalPrevisionCard.AFP': 'AFP',
  'employees.legalPrevisionCard.exCaja': 'Ex Caja',
  'employees.holidaysCard.cardLabel': 'Vacaciones',
  'employees.holidaysCard.holidaysPerYear': 'Días legales devengados anuales',
  'employees.othersCard.cardLabel': 'Otros',
  'employees.othersCard.percentagedl889': 'Porcentaje de asignación de zona para aplicación DL 889',
  'employees.othersCard.disabilityType': 'Discapacidad',
  'employees.othersCard.sector': 'Sector de actividad',
  'employees.othersCard.union': 'Sindicato',
  'employees.othersCard.youngEmployeeBenefit': 'Sueldo trabajador joven',
  'employees.othersCard.youngEmployeeBenefitDateTo': 'hasta',

  'employeesAbsences.errorToast.list': 'Ha habido un error leyendo la lista',
  'employeesAbsences.errorToast.delete': 'Ha habido un error eliminando la ausencia',
  'employeesAbsences.warning.errorMessage': 'Ha habido un error genérico en la operación',
  'employeesAbsences.successToast.delete': 'Ausencia borrada correctamente',
  'employeesAbsences.successToast.save': 'La ausencia ha sido guardada correctamente',
  'employeesAbsences.error': 'Hubo un error al guardar la ausencia',
  'employeesAbsences.summaryOfVacations.label': 'Resumen de vacaciones',
  'employeesAbsences.detailsOfVacationsUsed.label': 'Detalle vacaciones utilizadas',
  'employeesAbsences.progressiveVacation.label': 'Vacaciones progresivas',
  'employeesAbsences.simulationOfVacation.label':
    'Simulación vacaciones a pagar en caso de finiquito',
  'employeesAbsences.progressiveVacations.years.title':
    'Años reconocidos en empleadores anteriores',
  'employeesAbsences.progressiveVacations.date.title':
    'Fecha de comienzo de reconocimiento de días',
  'employeesAbsences.simulationOfVacation.datePayroll': 'Fecha finiquito',
  'employeesAbsences.simulationOfVacation.pendingVacations': 'Vacaciones pendientes',
  'employeesAbsences.simulationOfVacation.daysToPay': 'Días a pagar',
  'employeesAbsences.simulationOfVacation.halfPay': 'Media remunerada a 3 meses',
  'employeesAbsences.simulationOfVacation.totalToPay': 'Total a cancelar',
  'employeesAbsences.cardModal.type': 'Tipo',
  'employeesAbsences.cardModal.medicalLeave': 'Licencia',
  'employeesAbsences.cardModal.dateFrom': 'Del',
  'employeesAbsences.cardModal.dateTo': 'Al',
  'employeesAbsences.cardModal.notes': 'Notas',
  'employeesAbsences.cardModal.editDetailLabel': 'Editar Ausencia',
  'employeesAbsences.cardModal.createDetailLabel': 'Crear Ausencia',

  'employeesAbsences.grid.type': 'Tipo',
  'employeesAbsences.grid.medicalLeave': 'Licencia',
  'employeesAbsences.grid.dateFrom': 'Del',
  'employeesAbsences.grid.dateTo': 'Al',
  'employeesAbsences.grid.notes': 'Notas',

  'employessAbsences.summary.grid.year': 'Año',
  'employessAbsences.summary.grid.daysDueNormal': 'Días normales',
  'employessAbsences.summary.grid.daysDueProgressive': 'Días progresivos',
  'employessAbsences.summary.grid.totalDays': 'Días totales',
  'employessAbsences.summary.grid.usedDays': 'Días usados',
  'employessAbsences.summary.grid.availableDays': 'Días disponibles',

  'employeesLoans.errorToast.save': 'Ha habido un error guardando el préstamo',
  'employeesLoans.successToast.save': 'El préstamo ha sido guardado correctamente',
  'employeesLoans.errorToast.list': 'Ha habido un error leyendo la lista',
  'employeesLoans.warning.errorMessage': 'Ha habido un error genérico en la operación',
  'employeesLoans.successToast.delete': 'Préstamo borrado correctamente',
  'employeesLoans.errorToast.read': 'Ha habido un error leyendo el préstamo',
  'employeesLoans.error': 'Hubo un error al guardar el préstamo',
  'employeeLoans.cardModal.description': 'Descripción',
  'employeeLoans.cardModal.dateFrom': 'Comienzo',
  'employeeLoans.cardModal.dateTo': 'Término',
  'employeeLoans.cardModal.monthlyAmount': 'Monto mensual',
  'employeeLoans.cardModal.showInSlip': 'Imprimir en liquidaciones',
  'employeeLoans.cardModal.editLabel': 'Editar Préstamo',
  'employeeLoans.cardModal.createLabel': 'Crear Préstamo',
  'employeeLoans.grid.description': 'Descripción',
  'employeeLoans.grid.dateFrom': 'Comienzo',
  'employeeLoans.grid.dateTo': 'Término',
  'employeeLoans.grid.monthlyAmount': 'Monto mensual',
  'employeeLoans.grid.payments': 'Cuotas',
  'employeeLoans.grid.outstandingPayments': 'Cuotas por vencer',
  'employeeLoans.grid.total': 'Total',
  'employeeLoans.grid.pendingPayment': 'Pendientes de pago',
  'employeeLoans.grid.showInSlip': 'Imprimir en liquidaciones',

  'employeeContractTerminations.infoCard.text':
    'Recuerde que un trabajador puede tener varios finiquitos, si se le finiquita y se le vuelve a contratar de nuevo. El estado de si está finiquitado o no depende de la fecha y status en la pestaña de “Datos previsionales”. Por eso, recuerde tener esa información actualizada.',
  'employeeContractTerminations.labelTitle.contractTermination': 'Finiquitos',
  'employeeContractTerminations.labelTitle.detailContractTermination': 'Detalle del Finiquito',
  'employeeContractTerminations.cardTitle.detailContractTermination':
    'Seleccione los conceptos de finiquito a incluir',
  'employeeContractTerminations.grid.addRow': 'Selecciona los conceptos de finiquito',
  'employeeContractTerminations.grid.title': 'Selecciona los conceptos de finiquito',
  'employeeContractTerminations.errorToast.save': 'Ha habido un error guardando el finiquito',
  'employeeContractTerminations.successToast.save': 'El finiquito ha sido guardado correctamente',
  'employeeContractTerminations.errorToast.list': 'Ha habido un error leyendo la lista',
  'employeeContractTerminations.warning.errorMessage':
    'Ha habido un error genérico en la operación',
  'employeeContractTerminations.successToast.delete': 'Finiquito borrado correctamente',
  'employeeContractTerminations.errorToast.read': 'Ha habido un error leyendo el finiquito',
  'employeeContractTerminations.error': 'Hubo un error al guardar el finiquito',
  'employeeContractTerminations.detailCardModal.quantity': 'Cantidad',
  'employeeContractTerminations.detailCardModal.amount': 'Total',
  'employeeContractTerminations.cardModal.editDetailLabel': 'Editar detalle del finiquito',
  'employeeContractTerminations.cardModal.createDetailLabel': 'Crear detalle del finiquito',
  'employeeContractTerminations.cardModal.editLabel': 'Editar el finiquito',
  'employeeContractTerminations.cardModal.createLabel': 'Crear el finiquito',
  'employeeContractTerminations.cardModal.date': 'Fecha',
  'employeeContractTerminations.cardModal.informedBy.cause': 'Causa',
  'employeeContractTerminations.headerContractTerminationConceptGrid.text': 'Concepto',
  'employeeContractTerminations.table.date': 'Fecha',
  'employeeContractTerminations.table.cause.description': 'Causa',
  'employeeContractTerminations.table.totalAmount': 'Total',
  'employeeDetailsContractTerminations.table.concept.description': 'Concepto',
  'employeeDetailsContractTerminations.table.quantity': 'Cantidad',
  'employeeDetailsContractTerminations.table.amount': 'Total',

  'employeeHistory.errorToast.save': 'Ha habido un error guardando la incidencia',
  'employeeHistory.successToast.save': 'La incidencia ha sido guardado correctamente',
  'employeeHistory.errorToast.list': 'Ha habido un error leyendo la lista',
  'employeeHistory.errorToast.delete': 'Ha habido un error borrando la incidencia',
  'employeeHistory.warning.errorMessage': 'Ha habido un error genérico en la operación',
  'employeeHistory.successToast.delete': 'Incidencia borrado correctamente',
  'employeeHistory.errorToast.read': 'Ha habido un error leyendo la incidencia',
  'employeeHistory.error': 'Hubo un error al guardar la incidencia',
  'employeeHistory.cardModal.editLabel': 'Editar Incidencia',
  'employeeHistory.cardModal.createLabel': 'Crear Incidencia',
  'employeeHistory.cardModal.informedAt': 'Fecha',
  'employeeHistory.cardModal.informedBy.name': 'Usuario',
  'employeeHistory.cardModal.notes': 'Notas',
  'employeeHistory.grid.informedAt': 'Fecha',
  'employeeHistory.grid.name': 'Usuario',
  'employeeHistory.grid.notes': 'Notas',

  'employeeNotes.headerData.notes': 'Notas',

  'employeeImage.headerCard.label': 'Imagen del Empleado',
  'employeeImage.drawer.label': 'Subir imagen',

  'fileDropzoneSelectFile.drawer.text': ' Arrastre aquí su imagen',
  'fileDropzoneSelectFile.drawer.span': ' o',
  'fileDropzoneSelectFile.drawer.inputText': 'Seleccionar archivo',

  // Fixed assets
  'fixedAssets.tabTitle': 'Activos fijos',
  'fixedAssets.fixedAssetId': 'Número',
  'fixedAssets.name': 'Nombre',
  'fixedAssets.location': 'Ubicación',
  'fixedAssets.dateAcquisition': 'Fecha de adquisición',
  'fixedAssets.manufacturer': 'Fabricante',
  'fixedAssets.model': 'Modelo',
  'fixedAssets.serialNumber': 'Número de serie',
  'fixedAssets.supplier': 'Proveedor',
  'fixedAssets.invoiceNumber': 'Factura',
  'fixedAssets.account': 'Cuenta contable de Inmobilizado',
  'fixedAssets.accountDepreciation': 'Cuenta contable de Depreciación',
  'fixedAssets.accountAccumulatedDepreciation': 'Cuenta contable de Depreciación Acumulada',
  'fixedAssets.notes': 'Notas',
  'fixedAssets.acquisitionCost': 'Valor de adquisición',
  'fixedAssets.usefulLife': 'Vida útil en años',
  'fixedAssets.realizableValue': 'Valor residual',
  'fixedAssets.dateRetirement': 'Fecha de baja',
  'fixedAssets.depreciationType': 'Tipo de Depreciación',
  'fixedAssets.depreciationType.LI': 'Lineal',
  'fixedAssets.depreciationType.DD': 'Dígitos decrecientes',
  'fixedAssets.depreciationType.DC': 'Dígitos crecientes',
  'fixedAssets.depreciationType.TD': 'Tanto fijo sobre base amortizable decreciente',
  'fixedAssets.depreciationType.NO': 'No Depreciar',
  'fixedAssets.taxDeductionPercentage': 'Créd. fiscal en %',
  'fixedAssets.depreciate': 'Depreciación',
  'fixedAssets.depreciateFromMonthOfAcquisition': 'Tener en cuenta mes de adquisición',
  'fixedAssets.depreciateWithInflationCorrection': 'Utilizar CM',
  'fixedAssets.calculateDepreciation': 'Calcular depreciación',
  'fixedAssets.depreciation.depreciationId': 'Periodo',
  'fixedAssets.depreciation.year': 'Año',
  'fixedAssets.depreciation.months': 'Meses',
  'fixedAssets.depreciation.amount': 'Depreciación',
  'fixedAssets.depreciation.accumulated': 'Depreciación Acumulada',
  'fixedAssets.depreciation.amountWithInflationCorrection': 'Depreciación con CM',
  'fixedAssets.depreciation.accumulatedWithInflationCorrection': 'Dep. Acum. con CM',
  'fixedAssets.errorToast.delete': 'Ha habido un error eliminando el activo fijo',
  'fixedAssets.errorToast.read': 'Ha habido un error leyendo el activo fijo',
  'fixedAssets.errorToast.list': 'Ha habido un error leyendo el listado de activos fijos',
  'fixedAssets.errorToast.save': 'Ha habido un error guardando el activo fijo',
  'fixedAssets.confirmWindow.title': 'Eliminar Activo Fijo',
  'fixedAssets.confirmWindow.subtitle': '¿Desea eliminar este "Activo Fijo" definitivamente?',

  'home.homeCard.fixedAssets.search': 'Buscar Activo Fijo',
  'home.homeCard.fixedAssets.create': 'Crear Activo Fijo',
  'home.homeCard.fixedAssets.searchWindow': 'Buscar Activo Fijo',

  'forms.required': 'Requerido',
  'glAccounts.errorToast.read': 'Ha habido un error leyendo la cuenta global',
  'glAccounts.warning.errorMessage':
    'Ha habido un error genérico en la operación con la cuenta global',
  'glCompanies.errorToast.read': 'Ha habido un error leyendo la empresa global',
  'glCompanies.warning.errorMessage':
    'Ha habido un error genérico en la operación con la empresa global',
  'glUsers.errorToast.delete': 'Ha habido un error eliminando el usuario',
  'glUsers.errorToast.read': 'Ha habido un error leyendo el usuario',
  'glUsers.errorToast.save': 'Ha habido un error guardando el usuario',
  'glUsers.warning.errorMessage': 'Ha habido un error genérico en la operación con el usuario',
  'grid.addAFP': '+ Añadir AFP',
  'grid.addAPV': '+ Añadir APV',
  'grid.addContact': '+ Añadir contacto',
  'grid.addCcaf': '+ Añadir CCAF',
  'grid.addExCaja': '+ Añadir ExCaja',
  'grid.addInvoicesRow': '+ Añadir Fila',
  'grid.addIsapre': '+ Añadir Isapre',
  'grid.addOtherDocumentsRow': '+ Añadir Fila',
  'grid.addRow': '+ Añadir Fila',
  'grid.addProduct': '+ Añadir producto',
  'grid.addProductCategory': '+ Añadir familia de productos',
  'grid.addSupplier': '+ Añadir Proveedor',
  'grid.editRow': 'Editar Fila',
  'grid.addUnion': '+ Añadir Sindicato',
  'grid.addWorkerCompensationCompany': '+ Añadir Mutual de Seguridad',
  'groups.accept': 'Aceptar',
  'groups.cancel': 'Cancelar',
  'groups.completeName': 'Nombre completo',
  'groups.delete': 'Eliminar',
  'groups.deleteMessage': 'Está a punto de eliminar este grupo definitivamente, ¿está seguro?',
  'groups.errorToast.delete': 'Ha habido un error eliminando el grupo',
  'groups.errorToast.read': 'Ha habido un error leyendo el grupo',
  'groups.errorToast.list': 'Ha habido un error leyendo el listado de grupos',
  'groups.errorToast.save': 'Ha habido un error guardando el grupo',
  'groups.group': 'Grupo',
  'groups.headerData.group': 'Grupo',
  'groups.headerData.notes': 'Notas',
  'groups.notes': 'Notas',
  'groups.rights': 'Permisos',
  'groups.rights.create': 'Agregar',
  'groups.rights.delete': 'Borrado',
  'groups.rights.read': 'Lectura',
  'groups.rights.write': 'Modificar',
  'groups.search': 'Buscar grupo',
  'groups.successToast.delete': 'El grupo ha sido eliminado correctamente',
  'groups.successToast.save': 'El grupo ha sido guardado correctamente',
  'groups.tabTitle': 'Grupos',
  'groups.user': 'Usuario',
  'groups.users': 'Usuarios',
  'groups.warning.errorMessage': 'Ha habido un error genérico en la operación con el grupo',
  'groups.confirmWindow.subtitle': '¿Desea eliminar este "Grupo" definitivamente?',
  'groups.confirmWindow.title': 'Eliminar Grupo',
  'groups.table.loginName': 'Usuario',
  'groups.table.name': 'Nombre completo',

  'home.createCardModal.pricesList.createWindow':
    'Seleccione qué tipo de lista de precios desea utilizar',
  'home.createCardModal.pricesList.families': 'Por familia',
  'home.createCardModal.pricesList.products': 'Por productos',
  'home.homeCard.bank.create': 'Crear Banco',
  'home.homeCard.bank.search': '<u>B</u>uscar Banco',
  'home.homeCard.bank.searchWindow': 'Buscar Banco',
  'home.homeCard.bankStatements.create': 'Crear cartola',
  'home.homeCard.bankStatements.search': '<u>B</u>uscar cartola',
  'home.homeCard.bankStatements.searchWindow': 'Buscar cartola',
  'home.homeCard.branches.create': 'Crear Sucursal',
  'home.homeCard.branches.search': '<u>B</u>uscar Sucursal',
  'home.homeCard.branches.searchWindow': 'Buscar Sucursal',
  'home.homeCard.carriers.create': 'Crear Despachador',
  'home.homeCard.carriers.search': '<u>B</u>uscar Despachador',
  'home.homeCard.carriers.searchWindow': 'Buscar Despachador',
  'home.homeCard.costCenters.search': '<u>B</u>uscar Centro de Costos',
  'home.homeCard.costCenters.searchWindow': 'Buscar Centro de Costos',
  'home.homeCard.customers.create': 'Crear Cliente',
  'home.homeCard.customers.import': '<u>I</u>mportar Clientes',
  'home.homeCard.customers.search': '<u>B</u>uscar Cliente',
  'home.homeCard.customers.searchWindow': 'Buscar Cliente',
  'home.homeCard.currencies.create': 'Crear Moneda',
  'home.homeCard.currencies.search': '<u>B</u>uscar Moneda',
  'home.homeCard.currencies.searchWindow': 'Buscar Moneda',
  'home.homeCard.dealers.create': 'Crear Distribuidor',
  'home.homeCard.dealers.search': '<u>B</u>uscar Distribuidor',
  'home.homeCard.dealers.searchWindow': 'Buscar distribuidor',
  'home.homeCard.employees.create': 'Crear Empleado',
  'home.homeCard.employees.search': '<u>B</u>uscar Empleado',
  'home.homeCard.employees.searchWindow': 'Buscar Empleado',
  'home.homeCard.groups.create': 'Crear Grupo',
  'home.homeCard.groups.search': '<u>B</u>uscar Grupo',
  'home.homeCard.groups.searchWindow': 'Buscar Grupo',
  'home.homeCard.holidays.create': 'Crear a Días feriados',
  'home.homeCard.holidays.search': '<u>B</u>uscar a Días feriados',
  'home.homeCard.holidays.searchWindow': 'Buscar a Días Feriados',
  'home.homeCard.input.create': 'Crear ingreso a Bodega',
  'home.homeCard.input.search': '<u>B</u>uscar ingreso a Bodega',
  'home.homeCard.input.searchWindow': 'Buscar Ingreso a Bodega',
  'home.homeCard.inventory.create': 'Crear Inventario',
  'home.homeCard.inventory.search': '<u>B</u>uscar Inventario',
  'home.homeCard.inventory.searchWindow': 'Buscar Inventario',
  'home.homeCard.journalEntries.create': 'Crear comprobante',
  'home.homeCard.journalEntries.search': '<u>B</u>uscar comprobante',
  'home.homeCard.journalEntries.searchWindow': 'Buscar comprobante',
  'home.homeCard.lot.create': 'Crear Lote',
  'home.homeCard.lot.search': '<u>B</u>uscar Lote',
  'home.homeCard.lot.searchWindow': 'Buscar Lote',
  'home.homeCard.output.create': 'Crear Salida de Bodega',
  'home.homeCard.output.search': '<u>B</u>uscar Salida de Bodega',
  'home.homeCard.outputs.searchWindow': 'Buscar Salida de Bodega',
  'home.homeCard.payroll.create': 'Crear Liquidación',
  'home.homeCard.payroll.search': '<u>B</u>uscar Liquidación',
  'home.homeCard.payroll.searchWindow': 'Buscar Liquidación',
  'home.homeCard.pos.create': 'Crear Caja',
  'home.homeCard.pos.search': '<u>B</u>uscar Caja',
  'home.homeCard.pos.searchWindow': 'Buscar Caja',
  'home.homeCard.posAdjustment.create': 'Crear Ingresos y Egresos de Caja',
  'home.homeCard.posAdjustment.search': '<u>B</u>uscar Ingresos y Egresos de Caja',
  'home.homeCard.posAdjustment.searchWindow': 'Buscar Ingresos y Egresos de Caja',
  'home.homeCard.priceLists.create': 'Crear Lista de precios',
  'home.homeCard.priceLists.search': '<u>B</u>uscar Lista de precios',
  'home.homeCard.priceLists.searchWindow': 'Buscar Lista de precios',
  'home.homeCard.products.create': 'Crear Producto',
  'home.homeCard.products.import': '<u>I</u>mportar Productos',
  'home.homeCard.products.search': '<u>B</u>uscar Producto',
  'home.homeCard.products.searchWindow': 'Buscar Producto',
  'home.homeCard.purchaseOrders.create': 'Crear Órden de Compra',
  'home.homeCard.purchaseOrders.search': '<u>B</u>uscar Órden de Compra',
  'home.homeCard.purchasesPayments.create': 'Crear Pago',
  'home.homeCard.purchasesPayments.search': 'Buscar Pago',
  'home.homeCard.purchasesPayments.searchWindow': 'Buscar Pago',
  'home.homeCard.purchaseOrders.searchWindow': 'Buscar Órden de Compra',
  'home.homeCard.receipts.create': 'Crear Cobros',
  'home.homeCard.receipts.search': '<u>B</u>uscar Cobros',
  'home.homeCard.receipts.searchWindow': 'Buscar Cobros',
  'home.homeCard.salesmen.create': 'Crear Vendedor',
  'home.homeCard.salesmen.search': '<u>B</u>uscar Vendedor',
  'home.homeCard.salesmen.searchWindow': 'Buscar Vendedor',
  'home.homeCard.salesInvoices.create': 'Crear factura',
  'home.homeCard.salesInvoices.searchWindow': 'Buscar factura',
  'home.homeCard.salesInvoices.import': '<u>I</u>mportar facturas',
  'home.homeCard.salesInvoices.delete.description':
    'Está a punto de eliminar esta factura definitivamente, ¿está seguro?',
  'home.homeCard.salesInvoices.delete.title': 'Eliminar factura',
  'home.homeCard.salesInvoices.search': '<u>B</u>uscar factura',
  'home.homeCard.salesOrders.create': 'Crear Pedido',
  'home.homeCard.salesOrders.searchWindow': 'Buscar Pedido',
  'home.homeCard.salesOrders.delete.description':
    'Está a punto de eliminar este pedido definitivamente, ¿está seguro?',
  'home.homeCard.salesOrders.delete.title': 'Eliminar Pedido',
  'home.homeCard.salesOrders.search': '<u>B</u>uscar Pedido',
  'home.homeCard.salesQuotes.create': 'Crear Cotización',
  'home.homeCard.salesQuotes.searchWindow': 'Buscar Cotización',
  'home.homeCard.salesQuotes.delete.description':
    'Está a punto de eliminar esta cotización definitivamente, ¿está seguro?',
  'home.homeCard.salesQuotes.delete.title': 'Eliminar Cotización',
  'home.homeCard.salesQuotes.search': '<u>B</u>uscar Cotizaciones',
  'home.homeCard.salesWaybills.create': 'Crear Guía de Despacho',
  'home.homeCard.salesWaybills.search': '<u>B</u>uscar Guía de Despacho',
  'home.homeCard.salesWaybills.searchWindow': 'Buscar Guía de Despacho',
  'home.homeCard.salesWaybills.delete.description':
    'Está a punto de eliminar esta guía de despacho definitivamente, ¿está seguro?',
  'home.homeCard.salesWaybills.delete.title': 'Eliminar Guía de Despacho',
  'home.homeCard.suppliers.search': '<u>B</u>uscar proveedor',
  'home.homeCard.supplier.searchWindow': 'Buscar Proveedor',
  'home.homeCard.suppliers.create': 'Crear Proveedor',
  'home.homeCard.suppliers.import': '<u>I</u>mportar Proveedores',
  'home.homeCard.salesTaxes.create': 'Crear Impuesto',
  'home.homeCard.salesTaxes.search': '<u>B</u>uscar Impuesto',
  'home.homeCard.salesTaxes.searchWindow': 'Buscar Impuesto',
  'home.homeCard.stockTransfer.create': 'Crear Traslado',
  'home.homeCard.stockTransfer.search': '<u>B</u>uscar Traslado',
  'home.homeCard.stockTransfer.searchWindow': 'Buscar Traslado',
  'home.homeCard.term.create': 'Crear Forma de Pago',
  'home.homeCard.term.search': '<u>B</u>uscar Forma de Pago',
  'home.homeCard.term.searchWindow': 'Buscar Forma de Pago',
  'home.homeCard.users.create': 'Crear Usuario',
  'home.homeCard.users.search': '<u>B</u>uscar Usuario',
  'home.homeCard.users.searchWindow': 'Buscar Usuario',
  'home.homeCard.warehouse.create': 'Crear Bodega',
  'home.homeCard.warehouse.search': '<u>B</u>uscar Bodega',
  'home.homeCard.warehouse.searchWindow': 'Buscar Bodega',
  'home.homeCard.purchaseInvoices.create': 'Crear Gasto',
  'home.homeCard.purchaseInvoices.search': '<u>B</u>uscar Gasto',
  'home.homeCard.purchaseInvoices.searchWindow': 'Buscar Gasto',
  'home.homeCard.purchaseWaybills.create': 'Crear Guía de despacho',
  'home.homeCard.purchaseWaybills.search': '<u>B</u>uscar Guía de despacho',
  'home.homeCard.purchaseWaybills.searchWindow': 'Buscar Guía de despacho de compras',
  'home.homeCard.salesTickets.create': 'Crear Ticket',
  'home.homeCard.salesTickets.search': '<u>B</u>uscar Ticket',
  'home.homeCard.salesTickets.searchWindow': 'Buscar Ticket',
  'home.homeInfo.secondText': 'Si no encuentra lo que busca, contacte con nosotros:',
  'home.homeInfo.text':
    'Si no está familiarizado con el funcionamiento de Laudus, haga click en la imagen de arriba para ver un video de introducción a las opciones principales de Laudus, cómo navegar por el interface y realizar las tareas principales.',
  'home.homeInfo.title': '¿Cómo usar Laudus?',
  'home.homeInfo.titleButton': 'soporte@laudus.cl',
  'home.homeInfo.titleSecondButton': '22 687 0100',
  'home.title': '¿Qué desea hacer?',
  'import.bankStatements.importWindow.title': 'Importar cartola',
  'import.bankStatements.columnError': 'Problemas en la columna {column} en linea {line}',
  'import.bankStatements.dateError': 'La fecha {date} está fuera del rango de fechas de la cartola',
  'import.bankStatements.noColumnError': 'No se ha definido la columna para el campo "{column}"',
  'import.cafDTE.importWindow.result': 'CAF importado correctamente',
  'import.cafDTE.importWindow.title': 'Importar archivo CAF desde su computador',
  'import.cafDTE.importWindow.title2': 'Revise contenido',
  'import.cafDTE.importWindow.title3': 'Resultado de la importación',
  'import.customerCsv.error': 'El cliente no se ha creado correctamente.',
  'import.customerCsv.notExist': 'El cliente no existe',
  'import.customers.importWindow.title': 'Importar clientes',
  'import.headerCheckGrid.text':
    'Revise las facturas antes de importarlas a Laudus. Al pulsar Siguiente se importarán las facturas.',
  'import.headerGrid.text':
    'Configure los datos para importar. Asigne a las diferentes columnas de datos el concepto que corresponde mediante el desplegable de la primera fila.',
  'import.headerResultsGrid.text':
    'Revise el resultado de la importación de facturas. Se muestran posibles errores al agregar cada una de ellas a Laudus',
  'import.importWindow.textAcceptButton': 'Finalizar',
  'import.importWindow.textCancelButton': 'Cancelar',
  'import.importWindow.textNextButton': 'Siguiente',
  'import.importWindow.textPreviousButton': 'Atrás',
  'import.invoiceCsv.error': 'Error de importación',
  'import.invoiceCsv.processing': 'En proceso',
  'import.invoiceCsv.success': 'Archivo importado correctamente',
  'import.invoicesCsv.importWindow.title':
    'Importar facturas de ventas desde el Registro de Ventas del SII',
  'import.invoicesCsv.importWindow.title2': 'Revise las facturas',
  'import.invoicesCsv.importWindow.title3': 'Resultado de la importación',
  'import.onboard.step1.text': 'Escoja el archivo de Excel que contiene los datos a importar',
  'import.onboard.step2.text':
    '  Marque la casilla si la primera fila de su Excel contiene los nombres de las columnas. Estos nombres deben coincidir exactamente con el nombre del campo a importar',
  'import.onboard.step3.text':
    '  A continuación, pulse Siguiente para revisar los datos y realizar la importación final.',
  'import.onboardCsv.step1.text':
    'Escoja el archivo en formato CSV (texto separado por comas) de donde se van a importar los datos o abra el archivo con Excel y copie el contenido en el portapapeles.',
  'import.onboardCsv.step2.text': ' Configure las opciones disponibles.',
  'import.options1Csv.text': 'Utilizar el producto genérico',
  'import.options2Csv.text': 'Crear cliente si no existe en Laudus',
  'import.products.importWindow.title': 'Importar productos',
  'import.progressBar': 'Importando',
  'import.salesInvoices.importWindow.title': 'Importar facturas',
  'import.toast.error': 'Ha habido un error importando el archivo',
  'import.toast.success': 'El archivo se ha importado correctamente',
  'import.warning.subtitle': 'Para poder continuar, debe primero seleccionar un archivo.',
  'import.warning.title': 'Atención',
  'import.warning.titleButton': 'Aceptar',
  'import.warningCsv.subtitle': 'Para poder continuar, importe un archivo CSV.',
  'import.warningHeaderCsv.subtitle': 'Error en cabeceras del archivo CSV.',
  'import.warningImportInvoiceList.subtitle':
    'Para poder continuar, seleccione al menos una factura.',
  'import.warningProduct.subtitle': 'Para poder continuar, seleccione un producto genérico.',
  'imports.options1.text': 'La primera fila son los títulos de las columnas',
  'imports.options2.text': 'Delimitador de caracteres',
  'imports.options3.text': 'Carácter para los miles',
  'imports.options4.text': 'Carácter de decimales',
  'imports.radio1.text': 'Portapapeles',
  'imports.radio2.text': 'Archivo:',
  'input.errorToast.list': 'Ha habido un error leyendo el listado de ingresos',
  'input.errorToast.delete': 'Ha habido un error eliminando el ingreso a bodega',
  'input.errorToast.read': 'Ha habido un error leyendo el ingreso a bodega',
  'input.errorToast.save': 'Ha habido un error guardando el ingreso a bodega',
  'input.generalData.costCenter': 'Centro de Costos',
  'input.generalData.date': 'Fecha',
  'input.generalData.id': 'Número',
  'input.generalData.invoiceNumber': 'Factura de compras',
  'input.generalData.notes': 'Notas',
  'input.generalData.supplier': 'Proveedor',
  'input.generalData.warehouse': 'Bodega',
  'input.hidePassword': 'Ocultar contraseña',
  'input.message.delete':
    'Está a punto de eliminar este ingreso a bodega definitivamente, ¿está seguro?',
  'input.message.search': 'Buscar ingreso a bodega',
  'input.showPassword': 'Mostrar contraseña',
  'input.successToast.delete': 'El ingreso a bodega ha sido eliminado correctamente',
  'input.successToast.save': 'El ingreso a bodega ha sido guardado correctamente',
  'input.table.product': 'Producto',
  'input.table.description': 'Descripción',
  'input.table.quantity': 'Cantidad',
  'input.table.unitCost': 'Costo unitario',
  'input.table.unitOfMeasure': 'Unidades',
  'input.table.lot': 'Lote',
  'input.tabTitle': 'Ingresos a bodega',
  'input.warning.errorMessage':
    'Ha habido un error genérico en la operación con el ingreso a bodega',
  'input.confirmWindow.title': 'Eliminar Ingreso a Bodega',
  'input.confirmWindow.subtitle': '¿Desea eliminar este "Ingreso a Bodega" definitivamente?',

  'inventory.errorToast.list': 'Ha habido un error leyendo el listado de inventarios',
  'inventory.table.product': 'Producto',
  'inventory.table.description': 'Descripción',
  'inventory.table.quantity': 'Cantidad',
  'inventory.table.unitOfMeasure': 'Unidades',
  'inventory.table.lot': 'Lote',
  'inventory.errorToast.delete': 'Ha habido un error borrando el inventario',
  'inventory.errorToast.read': 'Ha habido un error leyendo el inventario',
  'inventory.errorToast.save': 'Ha habido un error guardando el inventario',
  'inventory.generalData.costCenter': 'Centro de Costos',
  'inventory.generalData.date': 'Fecha',
  'inventory.generalData.id': 'Número',
  'inventory.generalData.notes': 'Notas',
  'inventory.generalData.warehouse': 'Bodega',
  'inventory.message.delete':
    'Está a punto de eliminar este inventario definitivamente, ¿está seguro?',
  'inventory.message.search': 'Buscar inventario',
  'inventory.successToast.delete': 'El inventario ha sido eliminado correctamente',
  'inventory.successToast.save': 'El inventario ha sido guardado correctamente',
  'inventory.tabTitle': 'Inventarios',
  'inventory.confirmWindow.title': 'Eliminar Inventario',
  'inventory.confirmWindow.subtitle': '¿Desea eliminar este "Inventario" definitivamente?',
  'inventory.warning.errorMessage': 'Ha habido un error genérico en la llamada a la API',

  'invoices.accountingAndReceiptsTab.account': 'Cuenta',
  'invoices.accountingAndReceiptsTab.generateAccounting': 'Generar contabilidad',
  'invoices.accountingAndReceiptsTab.journalEntry': 'Comprobante',
  'invoices.accountingAndReceiptsTab.costCenter': 'Centro Costo',
  'invoices.DTEInfoTab.SIIStatus': 'Estado SII',
  'invoices.DTEInfoTab.SIITitle': 'Envío al SII',
  'invoices.DTEInfoTab.accepted/rejectedDTE': 'DTE Aceptado/Rechazado',
  'invoices.DTEInfoTab.answerNeeded': 'Se precisa respuesta',
  'invoices.DTEInfoTab.answerTitle': 'Respuesta recibida del cliente',
  'invoices.DTEInfoTab.assignee': 'Cesionario',
  'invoices.DTEInfoTab.deliveryNumber': 'Nº Envío',
  'invoices.DTEInfoTab.emailDate': 'Fecha',
  'invoices.DTEInfoTab.emailTitle': 'Envío al cliente por email',
  'invoices.DTEInfoTab.factoringDeliveryNumber': 'Nº Envío',
  'invoices.DTEInfoTab.factoringSIIStatus': 'Estado SII',
  'invoices.DTEInfoTab.factoringTitle': 'Envío electrónico Factoring',
  'invoices.accept': 'Sí',
  'invoices.cancel': 'No',
  'invoices.clientBlockedMessage': 'Mensaje a mostrar si el cliente está bloqueado',
  'invoices.clientBlockedTitle1': 'El cliente',
  'invoices.clientBlockedTitle2': 'está bloqueado',
  'invoices.createInvoice': 'Crear factura',
  'invoices.createInvoiceFromSalesOrder': 'Crear factura a partir de un Pedido de ventas',
  'invoices.createInvoiceFromSalesQuote': 'Crear factura a partir de una Cotización',
  'invoices.createInvoiceFromSalesWaybill': 'Crear factura a partir de una Guía de Despacho',
  'invoices.customer.error': 'Ha habido un error actualizando los datos del cliente',
  'invoices.changePriceListOnCustomerChange.subtitle':
    'El cliente seleccionado posee otra lista de precios. Si acepta, los precios de todos los productos serán actualizados',
  'invoices.changePriceListOnCustomerChange.title':
    '¿Desea actualizar los precios de los productos?',
  'invoices.delete': 'Eliminar',
  'invoices.delete.message': 'Está a punto de eliminar esta factura definitivamente, ¿está seguro?',
  'invoices.deleteInvoice': 'Eliminar factura',
  'invoices.deliveryTab.address': 'Dirección',
  'invoices.deliveryTab.carrier': 'Despachador',
  'invoices.deliveryTab.cost': 'Costo',
  'invoices.deliveryTab.deliveryNotes': 'Notas para la entrega',
  'invoices.deliveryTab.deliveryVehiclePlate': 'Patente',
  'invoices.deliveryTab.rejected': 'Rechazada',
  'invoices.deliveryTab.warehouse': 'Bodega',
  'invoices.deliveryTab.branch': 'Sucursal',
  'invoices.download': 'Descargar',
  'invoices.sendByEmail': 'Enviar por e-mail',
  'invoices.duplicate': 'Duplicar',
  'invoices.edit': 'Editar',
  'invoices.error.maxItems.message1':
    'No se puede guardar la factura ya que se ha alcanzado el límite máximo de',
  'invoices.error.maxItems.message2': 'items por factura',
  'invoices.error.maxItems.title': 'Cantidad máxima de items alcanzada',
  'invoices.error.noCustomer.message': 'Seleccione un cliente para poder guardar la factura',
  'invoices.error.noCustomer.title': 'Factura sin cliente',
  'invoices.errorToast.delete': 'Ha habido un error eliminando la factura',
  'invoices.errorToast.read': 'Ha habido un error leyendo la factura',
  'invoices.errorToast.readReceiptList': 'Ha habido un error leyendo la lista de cobros',
  'invoices.errorToast.save': 'Ha habido un error guardando la factura',
  'invoices.exportDataTab.customsInformation': 'Información Aduanas',
  'invoices.exportDataTab.goodsServices': 'Mercaderías / Servicios',
  'invoices.exportDataTab.portOfDestination': 'Puerto de destino',
  'invoices.exportDataTab.portOfShipment': 'Puerto de embarque',
  'invoices.exportDataTab.saleClause': 'Cláusula de venta',
  'invoices.exportDataTab.salesMode': 'Modalidad de venta',
  'invoices.exportDataTab.searchPort': 'Buscar Puerto',
  'invoices.exportDataTab.term': 'Forma de pago',
  'invoices.exportDataTab.totalSaleClause': 'Total Cláusula de venta',
  'invoices.exportDataTab.transport': 'Transporte',
  'invoices.exportDataTab.transportRoute': 'Vía de transporte',
  'invoices.footerData.VAT': 'IVA',
  'invoices.footerData.net': 'Neto',
  'invoices.footerData.salesman': 'Vendedor',
  'invoices.footerData.term': 'Forma de pago',
  'invoices.footerData.total': 'Total',
  'invoices.generalData.customer': 'Cliente',
  'invoices.generalData.dueDate': 'Vencimiento',
  'invoices.generalData.invoiceNumber': 'Número',
  'invoices.generalData.invoiceType': 'Tipo de Documento',
  'invoices.generalData.issuedDate': 'Emisión',
  'invoices.generalData.searchCustomer': 'Buscar Cliente',
  'invoices.generalData.searchInvoice': 'Buscar Factura',
  'invoices.generalData.showLess': 'Menos datos',
  'invoices.generalData.showMore': 'Más datos',
  'invoices.generalDataTab.SIITransactionType': 'Tipo trans SII',
  'invoices.generalDataTab.canceled': 'Cancelado',
  'invoices.generalDataTab.commission': 'Comisión',
  'invoices.generalDataTab.contact': 'Contacto',
  'invoices.generalDataTab.markAsNull': 'Nula',
  'invoices.generalDataTab.pending': 'Pendiente',
  'invoices.generalDataTab.purchaseOrderNumber': 'O/C',
  'invoices.generalDataTab.referenceType': 'Motivo',
  'invoices.generalDataTab.salesman': 'Vendedor',
  'invoices.generalDataTab.source': 'Origen',
  'invoices.generalDataTab.sourceorderId': 'Nº',
  'invoices.generalDataTab.warehouse': 'Bodega',
  'invoices.generalDataTab.dealer': 'Distribuidor',
  'invoices.generalDataTab.terms': 'Forma de pago',
  'invoices.generalDataTab.items.noteOfCreditType': 'Tipo N de C',
  'invoices.generalDataTab.items.noteOfCreditType.discount': 'Descuento',
  'invoices.generalDataTab.items.noteOfCreditType.return': 'Devolución',
  'invoices.infoToast.createFromSalesOrder.itemsAlreadyDelivered':
    'Algunos items del pedido ya han sido despachados. Se tomarán solo aquellos items pendientes',
  'invoices.infoToast.createFromSalesQuote.itemsAlreadyDelivered':
    'Algunos items de la cotización ya han sido despachados. Se tomarán solo aquellos items pendientes',
  'invoices.importInvoice': 'Importar facturas',
  'invoices.new': 'Nueva',
  'invoices.preview': 'Previsualizar',
  'invoices.print': 'Imprimir',
  'invoices.referenceTypeModal.title': 'Motivo de referencia',
  'invoices.referenceTypeModal.subtitle': 'Seleccione qué motivo de referencia desea utilizar',
  'invoices.errorToast.createFromSalesOrder':
    'Error al intentar crear una factura a través de un pedido de ventas',
  'invoices.errorToast.createFromSalesQuote':
    'Error al intentar crear una factura a través de una cotización',
  'invoices.errorToast.createFromSalesWaybill':
    'Error al intentar crear una factura a través de una guía de despacho',
  'invoices.search': 'Buscar',
  'invoices.searchInvoice': 'Buscar factura',
  'invoices.sendToSII.askConfirmation':
    '¿Desea enviar ahora la factura al SII (ya no se podrá modificar)?',
  'invoices.sendToSII.cancel': 'Cancelar',
  'invoices.sendToSII.confirm': 'Enviar al SII',
  'invoices.sendEmail.fail': 'Error al enviar la factura por correo electrónico',
  'invoices.sendToSII.fail': 'Error al enviar la factura al SII',
  'invoices.sendToSII.sucess': 'Factura enviada al SII correctamente',
  'invoices.successToast.delete': 'La factura ha sido eliminada correctamente',
  'invoices.successToast.save': 'La factura ha sido guardada correctamente',
  'invoices.tab.DTEInfo': 'DTE info',
  'invoices.tab.accounting': 'Contabilidad y cobro',
  'invoices.tab.delivery': 'Entrega',
  'invoices.tab.traces': 'Trazado',
  'invoices.tab.exportData': 'Datos exportación',
  'invoices.tab.generalData': 'Datos generales',
  'invoices.tab.otherReferences': 'Otras referencias',
  'invoices.tabTitle': 'Facturas',
  'invoices.tab.traces.addQuote': '+ Añadir Cotización',
  'invoices.tab.traces.addOrder': '+ Añadir Pedido',
  'invoices.tab.traces.addWaybill': '+ Añadir Guía',
  'invoices.tab.traces.invoice': '+ Añadir Factura',
  'invoices.validationError': 'Error al validar los datos',
  'invoices.viewStockOf': 'Ver stock de',
  'invoices.warning.errorMessage': 'Ha habido un error genérico en la operación con la factura',
  'invoicesReconciliation.action.compare': 'Comparar',
  'invoicesReconciliation.errorToast.read':
    'Ha habido un error leyendo la información de conciliación',
  'invoicesReconciliation.warning.errorMessage':
    'Ha habido un error genérico en la operación de conciliación de facturas',
  'invoicesReconciliation.label.month': 'Mes',
  'invoicesReconciliation.label.year': 'Año',
  'invoicesReconciliation.optionLabel.purchases': 'Compras',
  'invoicesReconciliation.optionLabel.invoices': 'Ventas',
  'invoicesReconciliation.optionLabel.tickets': 'Ventas Boleta Electrónica',
  'journalEntries.confirmWindow.subtitle': '¿Desea eliminar el "Comprobante" definitivamente?',
  'journalEntries.confirmWindow.title': 'Eliminar Comprobante',
  'journalEntries.accept': 'Aceptar',
  'journalEntries.cancel': 'Cancelar',
  'journalEntries.createJournalEntry': 'Crear comprobante',
  'journalEntries.delete': 'Eliminar',
  'journalEntries.delete.message': '¿Desea eliminar el comprobante definitivamente?',
  'journalEntries.deleteJournalEntry': 'Eliminar comprobante',
  'journalEntries.duplicate': 'Duplicar',
  'journalEntries.edit': 'Editar',
  'journalEntries.errorToast.delete': 'Ha habido un error eliminando el comprobante',
  'journalEntries.errorToast.read': 'Ha habido un error leyendo el comprobante',
  'journalEntries.errorToast.save': 'Ha habido un error guardando el comprobante',
  'journalEntries.generalData.approved': 'Aprobado',
  'journalEntries.generalData.approvedBy.name': 'Por',
  'journalEntries.generalData.bookId': 'IFRS',
  'journalEntries.generalData.credit': 'Haber',
  'journalEntries.generalData.date': 'Fecha',
  'journalEntries.generalData.debit': 'Debe',
  'journalEntries.generalData.journalEntryNumber': 'Número',
  'journalEntries.generalData.notes': 'Notas',
  'journalEntries.generalData.type': 'Tipo',
  'journalEntries.generalData.typeNumber': '',
  'journalEntries.new': 'Nuevo',
  'journalEntries.preview': 'Previsualizar',
  'journalEntries.print': 'Imprimir',
  'journalEntries.search': 'Buscar',
  'journalEntries.searchJournalEntry': 'Buscar comprobante',
  'journalEntries.successToast.delete': 'El comprobante ha sido eliminado correctamente',
  'journalEntries.successToast.save': 'El comprobante ha sido guardado correctamente',
  'journalEntries.tabTitle': 'Comprobantes',
  'journalEntries.warning.errorMessage':
    'Ha habido un error genérico en la operación con el comprobante',
  //LEDGER
  'ledger.dateFrom': 'Fecha desde',
  'ledger.dateTo': 'Fecha hasta',
  'ledger.chartName': 'Mayor',
  'ledger.filter.period': 'Período',
  'ledger.filter.groupBy': 'Agrupar',
  'ledger.successToast.error': 'Ha habido un error leyendo el mayor.',
  'ledger.errorToast.read': 'Ha habido un error leyendo el mayor.',
  'ledger.tabTitle': 'Mayor',
  'ledger.warning.subtitle':
    'Para poder utilizar los filtros disponibles, debe seleccionar primero una cuenta.',
  'ledger.warning.title': 'Problemas con operación',
  'ledger.warning.titleButton': 'Aceptar',
  'login.cantEnter': '¿No puedes entrar?',
  'login.enter': 'Entrar',
  'login.error': 'Usuario o contraseña incorrectas',
  'login.error.title': 'Error de autenticación',
  'login.errorToast.read': 'El Usuario o la Contraseña no son válidos, por favor ingrese de nuevo',
  'login.errorToast.save': 'Error de autenticación',
  'login.forgotPassword': '¿Has olvidado la contraseña?',
  'login.notAuthorised.description':
    'Por favor, ingrese de nuevo. O bien las credenciales no son correctas, o la sesión ha expirado',
  'login.warning.errorMessage':
    'Por favor, ingrese de nuevo. O bien las credenciales no son correctas, o la sesión ha expirado',
  'login.notAuthorised.title': 'No se puede acceder a la empresa',
  'login.password': 'Contraseña',
  'login.recoverPassword': 'Recuperar contraseña',
  'login.session.expired': 'Su sesión ha expirado. Por favor, conéctese de nuevo.',
  'login.userName': 'Nombre de usuario',
  'lot.createLot': 'Crear lote',
  'lot.searchLot': 'Buscar lote',
  'lot.cancel': 'Cancelar',
  'lot.accept': 'Aceptar',
  'lot.tabTitle': 'Lotes',
  'lot.errorToast.read': 'Ha habido un error leyendo el lote',
  'lot.errorToast.list': 'Ha habido un error leyendo el listado de lotes',
  'lot.warning.errorMessage': 'Ha habido un error genérico en la operación con el lote',
  'lot.successToast.save': 'El lote ha sido guardado correctamente',
  'lot.errorToast.save': 'Ha habido un error guardando el lote',
  'lot.successToast.delete': 'El lote ha sido eliminado correctamente',
  'lot.errorToast.delete': 'Ha habido un error eliminando el lote',
  'lot.message.search': 'Buscar lote',
  'lot.message.delete': 'Está a punto de eliminar este lote definitivamente, ¿está seguro?',
  'lot.headerData.lotNumber': 'Lote número',
  'lot.headerData.expiration': 'Vencimiento',
  'lot.headerData.notes': 'Notas',
  'lot.personalizedData.label': 'Personalizado',
  'lot.confirmWindow.title': 'Eliminar Lote',
  'lot.confirmWindow.subtitle': '¿Desea Eliminar este "Lote" definitivamente?',
  'menu.accountSettings': 'Ajustes de cuenta',
  'menu.companySettings': 'Configuración de la empresa',
  'menu.groups': 'Grupos de usuarios',
  'menu.help': 'Centro de ayuda',
  'menu.logout': 'Cerrar sesión',
  'menu.newCompany': 'Abrir nueva empresa',
  'menu.openedTabs': 'PESTAÑAS ABIERTAS',
  'menu.profile': 'Mi perfil',
  'menu.users': 'Usuarios',
  'month.april': 'Abril',
  'month.august': 'Agosto',
  'month.december': 'Diciembre',
  'month.february': 'Febrero',
  'month.january': 'Enero',
  'month.july': 'Julio',
  'month.june': 'Junio',
  'month.march': 'Marzo',
  'month.may': 'Mayo',
  'month.november': 'Noviembre',
  'month.october': 'Octubre',
  'month.september': 'Septiembre',
  'navigationMenu.title': 'Menú',
  'options.action.cancel': 'Cancelar',
  'options.action.save': 'Guardar',
  'options.errorToast.list': 'Error al cargar la configuración de la empresa',
  'options.errorToast.save': 'Ha habido un error guardando la nueva configuración',
  'options.errorWindow.getList': 'Ha habido un error cargando la configuración de la empresa',
  'options.errorWindow.save': 'Ha habido un error guardando la nueva configuración',
  'options.import.clipboard': 'Portapapeles, separado por',
  'options.import.file': 'Seleccionar archivo',
  'options.optionLabel.fecha': 'Fecha',
  'options.optionLabel.monthlyPosition': 'Nº de orden',
  'options.successToast.save': 'La nueva configuración ha sido guardada correctamente',
  'options.tabTitle': 'Opciones',
  'options.tabs.accounting': 'Contables',
  'options.tabs.accounting.accounting_assignVATIdToExpenditures': 'Centralizar gastos con RUT',
  'options.tabs.accounting.accounting_assignVATIdToIncomes': 'Centralizar ingresos con RUT',
  'options.tabs.accounting.allowWithoutAccountingCardTitle': 'Permitir sin contabilidad',
  'options.tabs.accounting.automaticCentralizationCardTitle': 'Centralización automática',
  'options.tabs.accounting.bankStatementsCardTitle': 'Cartolas',
  'options.tabs.accounting.chargeableAccountLevel': 'Dígitos de cuenta contable',
  'options.tabs.accounting.chargesCardTitle': 'Cobros por defecto',
  'options.tabs.accounting.companyHeaderHeight': 'Encabez. hojas foliadas (cm)',
  'options.tabs.accounting.costCenterRequired': 'Centros de Costos Obligatorios',
  'options.tabs.accounting.createAccountingExchangeRateDifferences': 'Diferencias tipo de cambio',
  'options.tabs.accounting.createAccountingFromPayments': 'Pagos',
  'options.tabs.accounting.createAccountingFromPurchases': 'Compras',
  'options.tabs.accounting.createAccountingFromReceipts': 'Cobros',
  'options.tabs.accounting.createAccountingFromSales': 'Ventas',
  'options.tabs.accounting.createAccountingFromSales_POS': 'Ventas TPV',
  'options.tabs.accounting.currencyCardTitle': 'Moneda',
  'options.tabs.accounting.defaultBank': 'Banco',
  'options.tabs.accounting.defaultBankForCash': 'Caja',
  'options.tabs.accounting.defaultBankForCreditCard': 'Tarjeta de Crédito',
  'options.tabs.accounting.defaultBankForDebitCard': 'Tarjeta de Débito',
  'options.tabs.accounting.ignoreListingsOlderLastClosingDate':
    'Ignorar cartolas anteriores al cierre',
  'options.tabs.accounting.mainCurrency': 'Moneda principal',
  'options.tabs.accounting.multiCurrencyAccounting': 'Contabilidad multimoneda',
  'options.tabs.accounting.othersCardTitle': 'Otros',
  'options.tabs.accounting.payments_allowWithoutAccounting': 'Pagos',
  'options.tabs.accounting.PPMPercentage': 'Porcentaje PPM',
  'options.tabs.accounting.purchases_allowWithoutAccounting': 'Compras',
  'options.tabs.accounting.receipts_allowWithoutAccounting': 'Cobros',
  'options.tabs.accounting.sales_allowWithoutAccounting': 'Ventas',
  'options.tabs.accounting.updatePaymentsWithBankListings': 'Actualizar los Pagos con las Cartolas',
  'options.tabs.accounting.updatePaymentsWithBankListings_matchDate': 'Hacer las fechas iguales',
  'options.tabs.books': 'Libros',
  'options.tabs.books.blockingDate': 'Bloquear hasta',
  'options.tabs.books.freelancersFeesBlockingDateTitle': 'Libro de Honorarios',
  'options.tabs.books.foreignSupplierGenericValidVATId': 'RUT',
  'options.tabs.books.foreignSupplierGenericValidVATIdLabel':
    'Sustituir RUT de Proveedores Extranjeros por',
  'options.tabs.books.foreignSupplierGenericValidVATIdNote':
    'Es el RUT que se asignará a los proveedores extranjeros. Si no se indica nada es el RUT de la Tesorería, 60.805.000-0.',
  'options.tabs.books.freelancersFeesBlockingDateNote':
    'Indique el mes hasta el que se va a bloquear el Libro de Honorarios. Una vez bloqueado, no se podrán ingresar Honorarios recibidos de proveedores que nos emiten Boletas de Honorarios (en el menú de Compras), ni hacer cambios sobre los mismos.',
  'options.tabs.books.purchasesBlockingDateNote':
    'Indique el mes hasta el que se va a bloquear el Libro de Compras. Una vez bloqueado no se podrán agregar Facturas de Proveedores, ni realizar cambios en ellas.',
  'options.tabs.books.purchasesBookCardTitle': 'Libro de Compras',
  'options.tabs.books.purchasesBookOrder': 'Ordenación',
  'options.tabs.books.purchasesBookOrderNote':
    'Es el orden en el que se imprimirán los documentos de compras en el Libro de Compras, según su fecha de emisión, o por el correlativo mensual.',
  'options.tabs.books.salesBlockingDateNote':
    'Indique el mes hasta el que se va a bloquear el Libro de Ventas. Una vez bloqueado, no se podrán emitir Facturas ni otros documentos tributarios de ventas (en el menú de Ventas\\Facturas), ni hacer cambios sobre los mismos.',
  'options.tabs.books.salesBlockingDateTitle': 'Libro de Ventas',
  'options.tabs.books.wagesBlockingDateNote':
    'Indique el mes hasta el que se va a bloquear el Libro de Remuneraciones. Una vez bloqueado, no se podrán realizar cambios ni ingresar nuevas Liquidaciones (en el menú de Personal).',
  'options.tabs.books.wagesBlockingDateTitle': 'Libro de Remuneraciones',
  'options.tabs.barCodes': 'Códigos de barras',
  'options.tabs.barCodes.prefix.title': 'Prefijos en Pistola',
  'options.tabs.barCodes.prefix.barCodeScannerPrefix': 'Prefijo Pistola Código de Barras',
  'options.tabs.barCodes.prefix.barCodeScannerPrefix.description':
    'Indicar solo en caso que el lector de código de barras utilice un prefijo en lugar de un STX. Este debe estar indicado en el manual del lector que adquirió, y suele ser un carácter de poco uso para evitar entrar en conflicto con aquellos que se digitan en el computador, por ejemplo [ ] { } #',
  'options.tabs.barCodes.prefix.barCodeScanner_allowSTX': 'Soporta STX en Cód. Barras',
  'options.tabs.barCodes.prefix.barCodeScanner_allowSTX.description':
    'STX significa Start of TeXt, es un prefijo estándar de la industria de las pistolas de Códigos de Barras, con el fin de evitar entrar en conflicto con los caracteres de su teclado. De acuerdo con las características de cada lector, se habilita una de las dos, “Prefijo o STX”, pero en ningún caso ambas.',
  'options.tabs.barCodes.position.title': 'Posiciones en códigos de barras de balanza',
  'options.tabs.barCodes.position.description':
    '<strong>Utilizar códigos de barras de balanza:</strong> Habilite solo si va a utilizar Códigos de Barras de balanzas.<br/><br/><strong>Prefijo utilizado para códigos de balanza:</strong> Es el prefijo que se utiliza para que la aplicación reconozca que se trata de un Código de Barras de balanza. La balanza se debe configurar para que los primeros dígitos impriman una secuencia conocida, este puede ser; un asterisco (ya que ningún producto comienza por asterisco), una secuencia numérica que no vaya en los Códigos de Barras oficiales de Chile, por ejemplo, un 32, o lo que usted estime conveniente.<br/><br/><strong>Código de producto desde la posición:</strong> Desde qué posición irá el código de producto. Si el prefijo tiene 1 caracteres/dígitos, entonces el código del producto comenzará en la posición 2. También hay que indicar la “Longitud” del código del producto, por ejemplo, que ocupará 5 caracteres/dígitos.<br/><br/><strong>Cantidad desde la posición:</strong> Para la cantidad, en qué posición comenzará. Siguiendo el ejemplo anterior, si el prefijo son 1 caracteres, y el código del producto son 5, la cantidad comenzará a partir de la posición 7, ya que las 6 primeras son prefijo+código. También hay que indicar para “Longitud” el número de dígitos que tendrá la cantidad.<br/><br/><strong>Decimales en las cantidades:</strong> Especifique cuántos decimales se deben consideran en las cantidades.<br/><br/><strong>Recuerde:</strong> Si utiliza Código de barras EAN 12, la sumatoria entre Prefijo Código de producto+Cantidad (enteros decimales), no deber ser superior a 12 caracteres.',
  'options.tabs.barCodes.position.barCode_useScaleCode': 'Utilizar códigos de barras de balanzas',
  'options.tabs.barCodes.position.barCode_scaleCodePrefix':
    'Prefijo utilizado para códigos de Balanza',
  'options.tabs.barCodes.position.barCode_product_fromChar': 'Código de producto desde la posición',
  'options.tabs.barCodes.position.barCode_product_len': 'Longitud',
  'options.tabs.barCodes.position.barCode_quantity_fromChar': 'Cantidad desde la posición',
  'options.tabs.barCodes.position.barCode_quantity_len': 'Longitud',
  'options.tabs.barCodes.position.barCode_quantity_decimals': 'Decimales en las cantidades',

  'options.tabs.company': 'Empresa',
  'options.tabs.general': 'General',
  'options.tabs.general.address': 'Dirección',
  'options.tabs.general.city': 'Ciudad',
  'options.tabs.general.codeActivity': 'Código de actividad',
  'options.tabs.general.comuna': 'Comuna',
  'options.tabs.general.email': 'Email',
  'options.tabs.general.giro': 'Giro',
  'options.tabs.general.name': 'Nombre',
  'options.tabs.general.region': 'Región',
  'options.tabs.general.social': 'Razón social',
  'options.tabs.general.telephone': 'Teléfono',
  'options.tabs.general.title': 'Datos generales',
  'options.tabs.general.VATId': 'RUT',
  'options.tabs.representant.name': 'Nombre',
  'options.tabs.representant.title': 'Representante legal',
  'options.tabs.representant.VATId': 'RUT',
  'options.tabs.sale.email': 'Email',
  'options.tabs.sales': 'Ventas',
  'options.tabs.sales.TC.label': 'Obtener T/C',
  'options.tabs.sales.TC.text':
    'Al marcar esta casilla se obtendrá el Tipo de Cambio automáticamente del maestro de monedas en los movimientos multimoneda. Tenga en cuenta que previamente un usuario ha tenido que actualizar el Tipo de Cambio del día en el maestro.',
  'options.tabs.sales.allowNonContinousDates': 'Permitir fechas saltadas',
  'options.tabs.sales.allowOverCreditLimit': 'Permitir sobre límite de crédito',
  'options.tabs.sales.allowUnderCost': 'Permitir por debajo del costo',
  'options.tabs.sales.allowUserChangePrices': 'Permitir modificar precios',
  'options.tabs.sales.allowWithOverdueInvoices': 'Facturas morosas  |   Permitir',
  'options.tabs.sales.allowWithoutStock': 'Permitir sin stock',
  'options.tabs.sales.blockDiscounts.label': 'Bloquear descuentos',
  'options.tabs.sales.blockDiscounts.text':
    'Marque esta casilla si no desea que los usuarios puedan modificar los descuentos en las pantallas de ventas.',
  'options.tabs.sales.creditLimit.nonDeposited':
    'Cobros no depositados reducen el monto cancelado de la factura',
  'options.tabs.sales.creditLimit.pendingOfInvoices': 'Incluir documentos no facturados',
  'options.tabs.sales.creditLimit.title': 'Límite de Crédito',
  'options.tabs.sales.customersAllowDuplicatesVATId.label': 'Permitir clientes con el mismo RUT',
  'options.tabs.sales.customersAllowDuplicatesVATId.text':
    'Si se permite que dos o más clientes tengan el mismo RUT (por ejemplo en el caso de ser varios locales de un mismo cliente). Si no se marca esta casilla, no se permitirá que dos clientes tengan el mismo RUT.',
  'options.tabs.sales.decimals.amount': 'Decimales cantidades',
  'options.tabs.sales.decimals.parity': 'Decimales tipo de cambio',
  'options.tabs.sales.decimals.prices': 'Decimales precios',
  'options.tabs.sales.decimals.traced': 'Preguntar en trazado cuando haya',
  'options.tabs.sales.decimals.traced2': 'o más productos',
  'options.tabs.sales.defaultConditions.creditLimit': 'Límite de crédito',
  'options.tabs.sales.defaultConditions.expiration': 'Plazo de pago',
  'options.tabs.sales.defaultConditions.priceList': 'Lista de precios',
  'options.tabs.sales.defaultConditions.terms': 'Forma de pago',
  'options.tabs.sales.defaultConditions.title': 'Condiciones comerciales por defecto',
  'options.tabs.sales.invoicesMultipleCurrencies': 'DEV: multimoneda en facturas',
  'options.tabs.sales.items.limitToSinglePage': 'Limitar a 1 página',
  'options.tabs.sales.items.maxItemsPerInvoice': 'Máximos ítems en facturas',
  'options.tabs.sales.items.maxItemsPerWaybill': 'Máximos ítems en guías',
  'options.tabs.sales.items.text':
    'Indique cuántos productos (items) se pueden agregar a una Factura o Guía. Este límite depende de su formato y tamaño de hoja utilizados. El máximo del SII son 60 items. Las Boletas no se ven afectadas por este valor. También puede limitar que las Facturas y Guías a una página impresa, que es la configuración por defecto.',
  'options.tabs.sales.items.title': 'Máximos Ítems en documentos',
  'options.tabs.sales.receipts.blockReceipts': 'Bloquear cobros pasados',
  'options.tabs.sales.receipts.days': 'días',
  'options.tabs.sales.receipts.multipleCurrencies': 'Cobros multimoneda',
  'options.tabs.sales.receipts.splitInvoices': 'Separar facturas en cuotas',
  'options.tabs.sales.receipts.title': 'Cobros',
  'options.tabs.sales.stock.compromise': 'Sin comprometer (incluye pedidos no entregados)',
  'options.tabs.sales.stock.normal': 'Stock normal',
  'options.tabs.sales.stock.title': 'El stock se refiere a:',
  'options.tabs.sales.warehouse.checkboxLabel': 'Forzar a ingresar bodegas',
  'options.tabs.sales.warehouse.description':
    'Si se obliga al usuario a ingresar una Bodega en cada movimiento de mercaderías. Cuando se marca esta opción, no se podrá ingresar ningún movimiento de stock (facturas, inventarios, salidas de bodega, etc) si no se indica una bodega.',
  'options.tabs.sales.warehouse.label': 'Bodega por defecto',
  'options.tabs.sales.warehouse.text':
    'Es la Bodega que se utilizará por defecto al agregar algún tipo de datos que implique movimientos de mercadería (por ejemplo Facturas, Ingresos a Bodega, Inventarios, etc)',
  'options.tabs.pos': 'Punto de venta',
  'options.warning.errorMessage':
    'Ha habido un error genérico en la operación de configuración de la empresa',
  'outputs.errorToast.list': 'Ha habido un error leyendo el listado de salida de bodegas',
  'outputs.errorToast.delete': 'Ha habido un error borrando la salida de bodega',
  'outputs.errorToast.read': 'Ha habido un error leyendo la salida de bodega',
  'outputs.errorToast.save': 'Ha habido un error guardando la salida de bodega',
  'output.generalData.costCenter': 'Centro de Costos',
  'output.generalData.date': 'Fecha',
  'output.generalData.id': 'Número',
  'output.generalData.notes': 'Notas',
  'output.generalData.warehouse': 'Bodega',
  'outputs.message.delete':
    'Está a punto de eliminar esta salida de bodega definitivamente, ¿está seguro?',
  'output.message.search': 'Buscar salida de bodega',
  'output.successToast.delete': 'La salida de bodega ha sido eliminada correctamente',
  'output.successToast.save': 'La salida de bodega ha sido guardada correctamente',
  'output.table.product': 'Producto',
  'output.table.description': 'Descripción',
  'output.table.quantity': 'Cantidad',
  'output.table.unitOfMeasure': 'Unidades',
  'output.table.lot': 'Lote',
  'output.tabTitle': 'Salidas de bodega',
  'output.warning.errorMessage':
    'Ha habido un error genérico en la operación con la salida de bodega',
  'outputs.confirmWindow.title': 'Eliminar Salida a Bodega',
  'outputs.confirmWindow.subtitle': '¿Desea eliminar esta "Salida a Bodega" definitivamente?',
  'payroll.action.addEmployee': 'Añadir empleado',
  'payroll.confirmWindow.title': 'Borrar Liquidacion',
  'payroll.confirmWindow.subtitle': '¿Desea borrar esta "Liquidación" definitivamente?',
  'payroll.safeConfirmWindow.subtitle':
    'Ha hecho click para borrar una liquidación completa. Tenga cuidado, porque se borrará toda la liquidación del mes, INCLUYENDO TODOS LOS EMPLEADOS. ¿Está seguro?',
  'payroll.companyParametersGrid.remunerationConceptName': 'Concepto',
  'payroll.companyParametersGrid.amount': 'Valor',
  'payroll.costCenterAllocationGrid.employeeName': 'Empleado',
  'payroll.costCenterAllocationGrid.addition': 'Suma',
  'payroll.employeesRemunerationGrid.employeeName': 'Empleado',
  'payroll.employeesRemunerationGrid.costCenterName': 'Centro costo',
  'payroll.employeesRemunerationGrid.amountToBePaid': 'Líquido a pagar',
  'payroll.deleteActiveEmployee.title': 'Borrar empleado de liquidación',
  'payroll.deleteActiveEmployee.subtitle':
    '¿Realmente desea eliminar un usuario activo de la liquidación',
  'payroll.error.activeEmployee': 'El empleado no está activo en la empresa actualmente',
  'payroll.error.alreadyExistingEmployee': 'El empleado ya está en la liquidación',
  'payroll.errorToast.calculation': 'Ha habido un error calculando la liquidación.',
  'payroll.errorToast.delete': 'Ha habido un error eliminando la liquidación',
  'payroll.errorToast.list': 'Ha habido un error leyendo la lista de liquidaciones.',
  'payroll.errorToast.read': 'Ha habido un error leyendo la liquidación',
  'payroll.errorToast.save': 'Ha habido un error guardando la liquidación',
  'payroll.headerData.createAccounting': 'Genera contabilidad',
  'payroll.headerData.date': 'Fecha',
  'payroll.headerData.journalEntry': 'Comprobante',
  'payroll.headerData.notes': 'Notas',
  'payroll.successToast.delete': 'Liquidación borrada correctamente',
  'payroll.tab.employeesRemunerationData': 'Datos empleados',
  'payroll.tab.cashDetail': 'Detalle efectivo',
  'payroll.tab.companyParameters': 'Parametros empresa',
  'payroll.tab.costCenterAllocation': 'Asignación C. Costos',
  'payroll.tabTitle': 'Liquidaciones',
  'payroll.validationError.costCenterAllocation':
    'La asignación a centros de costo de uno o más empleados supera el 100%',
  'payroll.wizard.errorRequired': 'Introduzca parámetro',
  'payroll.wizard.label.date': 'Fecha',
  'payroll.wizard.label.SIS': 'SIS',
  'payroll.wizard.label.salarioMinimo': 'Salario Mínimo',
  'payroll.wizard.label.UF': 'UF',
  'payroll.wizard.label.topeImponibleAFC': 'Tope Imp. AFC',
  'payroll.wizard.label.UTM': 'UTM',
  'payroll.wizard.label.topeImponibleAFP': 'Tope Imp. AFP',
  'payroll.wizard.label.UFmesAnterior': 'UF mes anterior',
  'payroll.wizard.label.topeImponibleIPS': 'Tope Imp. IPS',
  'payroll.wizard.layout.step1':
    'Introduzca la fecha para la que desea visualizar la liquidación y pulse Siguiente',
  'payroll.wizard.layout.step2': 'Introduzca los datos de la liquidación correspondientes',
  'payroll.wizard.layout.step3':
    'A continuación, pulse Finalizar para visualizar los datos de la liquidación',
  'payroll.wizard.title': 'Nueva liquidación',
  'payroll.warning.errorMessage': 'Ha habido un error genérico en la operación de liquidación',
  'pdfViewer.notFound': 'Archivo no encontrado',
  'pos.confirmWindow.title': 'Eliminar Caja',
  'pos.confirmWindow.subtitle': '¿Desea Eliminar esta "Caja" definitivamente?',
  'pos.tabTitle': 'Cajas',
  'pos.errorToast.save': 'Ha habido un error guardando la caja',
  'pos.successToast.delete': 'La caja ha sido eliminada correctamente',
  'pos.tabs.generalData': 'Datos Generales',
  'pos.tabs.documentTypes': 'Tipos documentos',
  'pos.tabs.posOpenAndClose': 'Aperturas y cierres',
  'pos.headerCard.name': 'Nombre',
  'pos.branchCard.title': 'Sucursal',
  'pos.ticketsCard.title': 'Boletas',
  'pos.ticketsCard.label': 'Imprimir automáticamente después de guardar',
  'pos.invoicesCard.title': 'Facturas',
  'pos.invoicesCard.label': 'Imprimir automáticamente después de guardar',
  'pos.creditCardCard.title': 'Venta de tarjetas',
  'pos.creditCardCard.textField.label': 'Número del Terminal de Tarjetas',
  'pos.creditCardCard.checkboxField.label': 'Imprimir automáticamente después de guardar',
  'pos.infoCard.text':
    'Agregue los tipos de documentos de venta que desea que pueda manejar esta Caja en la pantalla de Facturación Simplificada. Si no especifica ninguno, la Caja podrá manejar los tipos por defecto de Laudus®',

  'pos.documentTypes.grid.addRow': 'Añadir Tipo de Documento',
  'posAdjustment.errorToast.read': 'Ha habido un error leyendo el Ingreso - Egreso de la caja',
  'posAdjustment.errorToast.save': 'Ha habido un error guardando el Ingreso - Egreso de la caja',
  'posAdjustment.successToast.delete':
    'El Ingreso - Egreso de la caja ha sido eliminada correctamente',
  'posAdjustment.tabTitle': 'Ingresos y Egresos de Caja',
  'posAdjustment.warning.errorMessage': 'Ha habido un error genérico en la llamada a la API',
  'posAdjustment.generalData.title': 'Datos generales',
  'posAdjustment.generalData.posName': 'Caja',
  'posAdjustment.generalData.type': 'Ingreso - Egreso',
  'posAdjustment.detailsData.title': 'Detalles',
  'posAdjustment.detailsData.date': 'Fecha',
  'posAdjustment.detailsData.amount': 'Importe',
  'posAdjustment.detailsData.concept': 'Concepto',
  'posAdjustment.notesData.title': 'Notas',
  'posAdjustment.confirmWindow.title': 'Eliminar Ingreso - Egreso de Cajas',
  'posAdjustment.confirmWindow.subtitle':
    '¿Desea eliminar este "Ingreso - Egreso de Cajas" definitivamente?',
  //previsionalInstitution
  'previsionalInstitution.afp.inputData.electronicBookId': 'Código LRE',
  'previsionalInstitution.afp.inputData.legalName': 'Razón social',
  'previsionalInstitution.afp.inputData.legalNameFund': 'Razón social fondo',
  'previsionalInstitution.afp.inputData.name': 'Nombre',
  'previsionalInstitution.afp.inputData.notes': 'Notas',
  'previsionalInstitution.afp.inputData.percentage': 'Porcentaje',
  'previsionalInstitution.afp.inputData.previredId': 'Código previred',
  'previsionalInstitution.afp.inputData.VATId': 'RUT',

  'previsionalInstitution.afp.grid.name': 'Nombre',
  'previsionalInstitution.afp.grid.legalNameFund': 'Razón social fondo',
  'previsionalInstitution.afp.grid.legalName': 'Razón social AFP',
  'previsionalInstitution.afp.grid.percentage': 'Porcentaje',
  'previsionalInstitution.afp.grid.previredId': 'Cod. previred',
  'previsionalInstitution.afp.grid.electronicBookId': 'Cod. LRE',
  'previsionalInstitution.afp.grid.VATId': 'RUT',
  'previsionalInstitution.afp.grid.notes': 'Notas',

  'previsionalInstitution.ccaf.inputData.electronicBookId': 'Código LRE',
  'previsionalInstitution.ccaf.inputData.name': 'Nombre',
  'previsionalInstitution.ccaf.inputData.notes': 'Notas',
  'previsionalInstitution.ccaf.inputData.previredId': 'Código previred',

  'previsionalInstitution.ccas.grid.name': 'Nombre',
  'previsionalInstitution.ccas.grid.previredId': 'Cod. previred',
  'previsionalInstitution.ccas.grid.electronicBookId': 'Cod. LRE',
  'previsionalInstitution.ccas.grid.notes': 'Notas',

  'previsionalInstitution.exCaja.inputData.electronicBookId': 'Código LRE',
  'previsionalInstitution.exCaja.inputData.IPSId': 'IPS',
  'previsionalInstitution.exCaja.inputData.regimeIPS': 'Régimen IPS',
  'previsionalInstitution.exCaja.inputData.name': 'Nombre',
  'previsionalInstitution.exCaja.inputData.notes': 'Notas',
  'previsionalInstitution.exCaja.inputData.percentage': 'Porcentaje',
  'previsionalInstitution.exCaja.inputData.previredId': 'Código previred',

  'previsionalInstitution.exCaja.grid.name': 'Nombre',
  'previsionalInstitution.exCaja.grid.electronicBookId': 'Código LRE',
  'previsionalInstitution.exCaja.grid.IPSId': 'IPS',
  'previsionalInstitution.exCaja.grid.regimeIPS': 'Régimen IPS',
  'previsionalInstitution.exCaja.grid.notes': 'Notas',
  'previsionalInstitution.exCaja.grid.percentage': 'Porcentaje',
  'previsionalInstitution.exCaja.grid.previredId': 'Código previred',

  'previsionalInstitution.isapre.inputData.electronicBookId': 'Código LRE',
  'previsionalInstitution.isapre.inputData.legalName': 'Razón social',
  'previsionalInstitution.isapre.inputData.name': 'Nombre',
  'previsionalInstitution.isapre.inputData.notes': 'Notas',
  'previsionalInstitution.isapre.inputData.previredId': 'Código previred',
  'previsionalInstitution.isapre.inputData.VATId': 'RUT',

  'previsionalInstitution.isapre.grid.name': 'Nombre',
  'previsionalInstitution.isapre.grid.legalName': 'Razón social',
  'previsionalInstitution.isapre.grid.VATId': 'RUT',
  'previsionalInstitution.isapre.grid.previredId': 'Código previred',
  'previsionalInstitution.isapre.grid.electronicBookId': 'Código LRE',
  'previsionalInstitution.isapre.grid.notes': 'Notas',

  'previsionalInstitution.savingsPlansProvider.inputData.electronicBookId': 'Código LRE',
  'previsionalInstitution.savingsPlansProvider.inputData.legalName': 'Razón social',
  'previsionalInstitution.savingsPlansProvider.inputData.name': 'Nombre',
  'previsionalInstitution.savingsPlansProvider.inputData.notes': 'Notas',
  'previsionalInstitution.savingsPlansProvider.inputData.previredId': 'Código previred',

  'previsionalInstitution.savingsPlansProvider.grid.name': 'Nombre',
  'previsionalInstitution.savingsPlansProvider.grid.legalName': 'Razón social',
  'previsionalInstitution.savingsPlansProvider.grid.previredId': 'Cod. previred',
  'previsionalInstitution.savingsPlansProvider.grid.notes': 'Notas',

  'previsionalInstitution.union.inputData.name': 'Nombre',
  'previsionalInstitution.union.inputData.VATId': 'RUT',

  'previsionalInstitution.union.grid.name': 'Nombre',
  'previsionalInstitution.union.grid.VATId': 'RUT',

  'previsionalInstitution.workersCompensationInsurance.inputData.electronicBookId': 'Código LRE',
  'previsionalInstitution.workersCompensationInsurance.inputData.name': 'Nombre',
  'previsionalInstitution.workersCompensationInsurance.inputData.notes': 'Notas',
  'previsionalInstitution.workersCompensationInsurance.inputData.previredId': 'Código previred',
  'previsionalInstitution.workersCompensationInsurance.inputData.VATId': 'RUT',

  'previsionalInstitution.workersCompensationInsurance.grid.name': 'Nombre',
  'previsionalInstitution.workersCompensationInsurance.grid.VATId': 'RUT',
  'previsionalInstitution.workersCompensationInsurance.grid.previredId': 'Código previred',
  'previsionalInstitution.workersCompensationInsurance.grid.electronicBookId': 'Código LRE',
  'previsionalInstitution.workersCompensationInsurance.grid.notes': 'Notas',

  'previsionalInstitutions.tab.AFP': 'AFPs',
  'previsionalInstitutions.tab.APV': 'APV',
  'previsionalInstitutions.tab.CCAF': 'CCAF',
  'previsionalInstitutions.tab.excajas': 'Ex Cajas',
  'previsionalInstitutions.tab.isapres': 'Isapres',
  'previsionalInstitutions.tab.mutuales': 'Mutual de seguridad',
  'previsionalInstitutions.tab.sindicates': 'Sindicatos',
  'previsionalInstitutions.errorToast.list': 'Ha habido un error leyendo la lista',
  'previsionalInstitutions.warning.errorMessage': 'Ha habido un error genérico en la operación',
  'previsionalInstitutions.successToast.save': 'Institución previsional guardada correctamente',
  'previsionalInstitutions.errorToast.save':
    'Ha habido un error guardando la institución previsional',
  'previsionalInstitutions.successToast.delete': 'Institución previsional eliminada',
  'previsionalInstitutions.errorToast.delete':
    'Ha habido un error eliminando la institución previsional',
  'previsionalInstitutions.delete':
    'Está a punto de eliminar esta institución previsional definitivamente, ¿está seguro?',
  'previsionalInstitutions.action.delete': 'Eliminar institución previsional',
  'pricesList.warning.itemAlreadyExists': 'El item seleccionado ya existe en la lista de precios',
  'priceLists.confirmWindow.title': 'Eliminar Lista de Precios',
  'pricesList.errorToast.delete': 'Ha habido un error eliminando la lista de precios',
  'pricesList.errorToast.read': 'Ha habido un error leyendo la lista de precios',
  'pricesList.errorToast.save': 'Ha habido un error guardando la lista de precios',
  'priceLists.confirmWindow.subtitle': '¿Desea eliminar esta "Lista de Precios" definitivamente?',
  'pricesList.generalData.currencyCode': 'Moneda',
  'pricesList.generalData.name': 'Nombre',
  'pricesList.generalData.notes': 'Notas',
  'pricesList.generalData.percentage': '% Precio base',
  'pricesList.generalData.warehouse': 'Bodega',
  'pricesList.message.delete':
    'Está a punto de eliminar esta lista de precios definitivamente, ¿está seguro?',
  'pricesList.message.search': 'Buscar listas de precios',
  'priceLists.successToast.delete': 'La lista de precios ha sido eliminado correctamente',
  'priceLists.successToast.save': 'La lista de precios ha sido guardado correctamente',
  'pricesList.tabTitle': 'Listas de Precios',
  'pricesList.validationError': 'Error al validar los datos',
  'pricesList.warning.errorMessage':
    'Ha habido un error genérico en la operación con la lista de precios',
  'pricesList.modalTitle.typePriceList': 'Tipo de lista de precios',
  'pricesList.grid.addRow': '+ Añadir una Lista de Precios',
  'pricesList.grid.baseUnitPrice': 'Precio base',
  'pricesList.grid.baseUnitPriceWithTaxes': 'P.Base + IVA',
  'pricesList.grid.description': 'Descripción',
  'pricesList.grid.discontinued': 'Discontinuado',
  'pricesList.grid.percentage': '% Precio base',
  'pricesList.grid.productCategoryName': 'Familia',
  'pricesList.grid.sku': 'Producto',
  'pricesList.grid.unitPrice': 'Valor',
  'pricesList.grid.unitPriceWithTaxes': 'Valor boletas',
  'product.generalData.costCenterId': 'Centro de costos',
  'product.generalData.productId': 'Producto',
  'productCategory.generalData.productCategoryId': 'Familia de Productos',
  'productCategory.tabTitle': 'Familias de Productos',
  'products.confirmWindow.subtitle': '¿Desea eliminar este "Producto" definitivamente?',
  'products.confirmWindow.title': 'Eliminar Producto',
  'products.errorToast.delete': 'Ha habido un error eliminando el producto',
  // 'products.errorToast.list':
  //   'Ha habido un error leyendo el listado de productos',
  'products.errorToast.read': 'Ha habido un error leyendo el producto',
  'products.errorToast.save': 'Ha habido un error guardando el producto',
  'products.generalData.accountability.label': 'Contabilidad',
  'products.generalData.accountability.buyingAccount': 'Cuenta compras',
  'products.generalData.accountability.costsAccount': 'Cuenta costos',
  'products.generalData.accountability.sellingAccount': 'Cuenta ventas',
  'products.generalData.searchWindow.buyingAccount': 'Buscar Cuenta Contable',
  'products.generalData.searchWindow.costsAccount': 'Buscar Cuenta Costos',
  'products.generalData.searchWindow.sellingAccount': 'Buscar Cuenta Ventas',
  'products.generalData.description.label': 'Descripción',
  'products.generalData.description.barCode': 'Código de barras',
  'products.generalData.description.catalogue': 'Catálogo',
  'products.generalData.description.class': 'Clase',
  'products.generalData.description.discontinued': 'Discontinuado',
  'products.generalData.description.family': 'Familia',
  'products.generalData.description.generalIla': 'General ILA',
  'products.generalData.description.itsBought': 'Se compra',
  'products.generalData.description.itsSold': 'Se vende',
  'products.generalData.description.vatGeneralApply': 'Aplicar IVA general',
  'products.generalData.description.vatRate': 'IVA',
  'products.generalData.meassureUnit.label': 'Unidades de medida',
  'products.generalData.meassureUnit.alternative': 'Alternativa',
  'products.generalData.meassureUnit.factor': 'Factor',
  'products.generalData.meassureUnit.main': 'Principal',
  'products.generalData.prices.label': 'Precios',
  'products.generalData.prices.buyCost': 'Costo compra',
  'products.generalData.prices.currency': 'Moneda',
  'products.generalData.prices.maxDiscount': 'Descuento máximo',
  'products.generalData.prices.modifyPrices': 'Permitir modificar precios',
  'products.generalData.prices.priceWithVat': 'Precio con IVA',
  'products.generalData.prices.purchaseCurrency': 'Moneda compra',
  'products.generalData.prices.unitPrice': 'Precio unitario',
  'products.generalData.stock.label': 'Stock',
  'products.generalData.stock.maxStock': 'Stock máximo',
  'products.generalData.stock.minStock': 'Stock mínimo',
  'products.generalData.stock.stockAffect': 'Afecto a stock',
  'products.headerData.allowFreeDescription': 'Permite descripciones libres',
  'products.headerData.codeName': 'Código/nombre',
  'products.headerData.description': 'Descripción',
  'products.notInvoiceable.notInvoiceable': 'No facturable',
  'products.showStock.noWarehouse': 'Sin bodega',
  'products.searchGrid.description': 'Descripción',
  'products.searchGrid.productCategoryName': 'Familia',
  'products.searchGrid.sku': 'Código',
  'products.searchGrid.unitPrice': 'Precio unitario',
  'products.searchGrid.unitPriceWithTaxes': 'Precio un. con IVA',
  'products.successToast.delete': 'El producto ha sido eliminado correctamente',
  'products.successToast.save': 'El producto ha sido guardado correctamente',
  'products.tab.alternatives': 'Alternativas',
  'products.tab.discounts': 'Dtos por volumen',
  'products.tab.generalCard': 'Datos generales',
  'products.tab.images': 'Imágenes',
  'products.tab.image': 'Imagen',
  'products.tab.notes': 'Notas',
  'products.tab.notInvoizable': 'No facturable',
  'products.tab.stock': 'Stock',
  'products.tab.suppliers': 'Proveedores',
  'products.tab.taxes': 'Impuestos',
  'products.tabTitle': 'Productos',
  'products.imagesTab.label': 'Imagen del producto',
  'products.tab.relatedProducts': 'Alternativas',
  'products.relatedProducts.description':
    'Puede agregar a la cuadrícula los productos alternativos, es decir, aquellos que se pueden utilizar como opción si no hay stock de este producto.',

  'productSupplierGrid.supplier': 'Proveedor',
  'productSupplierGrid.supplierSku': 'Cód. Proveedor',
  'productSupplierGrid.description': 'Descripción',
  'productSupplierGrid.unitCost': 'Costo unitario',
  'productSupplierGrid.discount': 'Descuento',
  'productSupplierGrid.finalCost': 'Costo final',

  'productVolumeDiscount.grid.lowerLimit': 'Desde',
  'productVolumeDiscount.grid.upperLimit': 'Hasta',
  'productVolumeDiscount.grid.percentage': 'Descuento %',
  'productVolumeDiscount.grid.unitPrice': 'Valor',
  'productVolumeDiscount.grid.unitPriceWithTaxes': 'Valor boletas',
  'productVolumeDiscount.grid.priceList': 'Lista de precios',

  'purchaseOrders.tabTitle': 'Órdenes de compra',
  'purchaseOrders.searchPurchaseOrder': 'Buscar Orden de Compra a proveedor',
  'purchaseOrders.errorToast.read': 'Ha habido un error leyendo la Orden de compra a proveedores',
  'purchaseOrders.warning.errorMessage':
    'Ha habido un error genérico en la operación con la Orden de compra a proveedores',
  'purchaseOrders.errorToast.save': 'Ha habido un error guardando la orden de compra a proveedores',
  'purchaseOrders.errorToast.delete':
    'Ha habido un error eliminando la orden de compra a proveedores',
  'purchaseOrders.errorToast.duplicate':
    'Ha habido un error duplicando la orden de compra a proveedores',
  'purchaseOrders.headerData.purchaseOrder': 'Número',
  'purchaseOrders.headerData.markAsNull': 'Nula',
  'purchaseOrders.headerData.supplier': 'Proveedor',
  'purchaseOrders.headerData.issuedDate': 'Fecha',
  'purchaseOrders.tab.generalCard': 'Datos generales',
  'purchaseOrders.tab.traces': 'Trazado',
  'purchaseOrders.tab.notes': 'Notas',
  'purchaseOrders.generalDataTab.contact': 'Contacto',
  'purchaseOrders.generalDataTab.terms': 'Forma de pago',
  'purchaseOrders.generalDataTab.approvedAtLevel1': 'Aprobación 1',
  'purchaseOrders.generalDataTab.costCenter': 'Centro de costos',
  'purchaseOrders.generalDataTab.archived': 'Archivar',
  'purchaseOrders.generalDataTab.approvedAtLevel2': 'Aprobación 2',
  'purchaseOrders.generalDataTab.warehouse': 'Entregar en',
  'purchaseOrders.generalDataTab.dueDate': 'Previsto',
  'purchaseOrders.generalDataTab.approvedAtLevel3': 'Aprobación 3',
  'purchaseOrders.generalDataTab.by': 'Por',
  'purchaseOrders.generalDataTab.date': 'Fecha',
  'purchaseOrders.notesDataTab.notes': 'Notas internas',
  'purchaseOrders.notesDataTab.notesForSupplier': 'Notas para el proveedor',
  'purchaseOrders.tracesTab.aadQuote': '+ Añadir Cotización',
  'purchaseOrders.tracesTab.aadQuote.title': 'Cotización',
  'purchaseOrders.tracesTab.aadOrder': '+ Añadir Pedido',
  'purchaseOrders.tracesTab.aadOrder.title': 'Pedidos',
  'purchaseOrders.tracesTab.aadWaybills': '+ Añadir Guía',
  'purchaseOrders.tracesTab.aadWaybills.title': 'Guía de despacho',
  'purchaseOrders.tracesTab.aadInvoice': '+ Añadir Factura',
  'purchaseOrders.tracesTab.aadInvoice.title': 'Factura',
  'purchaseOrders.totalBox.net': 'Neto',
  'purchaseOrders.confirmWindow.subtitle':
    '¿Desea eliminar esta "Orden de Compra a Proveedores" definitivamente?',
  'purchaseOrders.confirmWindow.title': 'Eliminar Orden de Compra a Proveedores',
  'purchaseOrdersItems.table.product': 'Producto',
  'purchaseOrdersItems.table.description': 'Descripción',
  'purchaseOrdersItems.table.quantity': 'Cantidad',
  'purchaseOrdersItems.table.productUnitCost': 'Costo Unidad',
  'purchaseOrdersItems.table.net': 'Neto',
  'purchaseOrdersItems.table.originalUnitCost': 'Costo Un. Orig.',
  'purchaseOrdersItems.table.costCenter': 'C. Costo',
  'purchaseInvoices.errorToast.save': 'Ha habido un error guardando el gasto',
  'purchaseInvoices.errorToast.read': 'Ha habido un error intentando leer el gasto',
  'purchaseInvoices.errorToast.list': 'Ha habido un error intentando listar los gastos',
  'purchaseInvoices.successToast.save': 'Gasto guardado correctamente',
  'purchaseInvoices.warning.errorMessage': 'Ha habido un error genérico al guardar el gasto',
  'purchaseInvoices.successToast.delete': 'Gasto borrado correctamente',
  'purchaseInvoices.errorToast.delete': 'Ha habido un error borrando el gasto',
  'purchaseInvoices.tabTitle': 'Gastos',
  'purchaseInvoices.confirmWindow.title': 'Eliminar Gasto',
  'purchaseInvoices.confirmWindow.subtitle': '¿Desea eliminar este "Gasto" definitivamente?',
  'purchaseInvoices.generalDataTab.account': 'Cuenta de gasto',
  'purchaseInvoices.headerData.supplier': 'Proveedor',
  'purchaseInvoices.generalDataTab.description': 'Concepto',
  'purchaseInvoices.generalDataTab.fixedAsset': 'Activo fijo',
  'purchaseInvoices.generalDataTab.supermarket': 'Compra de supermecado',
  'purchaseInvoices.generalDataTab.costCenter': 'Centro de Costo',
  'purchaseInvoices.generalDataTab.warehouse': 'Bodega',
  'purchaseInvoices.generalDataTab.branch': 'Sucursal',
  'purchaseInvoices.generalDataTab.import': 'Import.',
  'purchaseInvoices.generalDataTab.importData.importFolder': 'Carpeta',
  'purchaseInvoices.generalDataTab.importData.proformaInvoice': 'Factura proforma',
  'purchaseInvoices.generalDataTab.VATDeduction.deductible': 'Recupera IVA',
  'purchaseInvoices.generalDataTab.VATDeduction.type': 'Utilización del IVA',
  'purchaseInvoices.generalDataTab.createAccounting': 'Genera contabilidad',
  'purchaseInvoices.generalDataTab.totalsVATWithheldAccount': 'Cuenta otros impuestos',
  'purchaseInvoices.generalDataTab.fiscalPeriod': 'Mes libros',
  'purchaseInvoices.generalDataTab.monthlyPosition': 'Corr. mes',
  'purchaseInvoices.generalDataTab.journalEntry.journalEntryNumber': 'Comprobante',
  'purchaseInvoices.generalDataTab.totals.exempt': 'Exento',
  'purchaseInvoices.generalDataTab.totals.net': 'Neto sin Arancel',
  'purchaseInvoices.generalDataTab.totals.tariff': 'Arancel',
  'purchaseInvoices.generalDataTab.totals.VAT': 'IVA',
  'purchaseInvoices.generalDataTab.totals.currencyCode': 'Moneda',
  'purchaseInvoices.generalDataTab.totals.parity': 'T/C',
  'purchaseInvoices.generalDataTab.totals.total': 'Total',
  'purchaseInvoices.generalDataTab.totals.canceled': 'Cancelado',
  'purchaseInvoices.generalDataTab.totals.pending': 'Pendiente',
  'purchaseInvoicesItems.table.product': 'Producto',
  'purchaseInvoicesItems.table.description': 'Descripción',
  'purchaseInvoicesItems.table.quantity': 'Cantidad',
  'purchaseInvoicesItems.table.productUnitCost': 'Costo Unidad',
  'purchaseInvoicesItems.table.net': 'Neto',
  'purchaseInvoicesItems.table.originalUnitCost': 'Costo Un. Orig.',
  'purchaseInvoicesItems.table.costCenter': 'C. Costo',
  'purchasesPayments.tabTitle': 'Pagos',
  'purchasesPayments.confirmWindow.title': 'Eliminar Pago',
  'purchasesPayments.confirmWindow.subtitle': '¿Desea eliminar esta "Pago" definitivamente?',
  'purchasesPayments.errorToast.read': 'Ha habido un error leyendo el pago',
  'purchasesPayments.warning.errorMessage': 'Ha habido un error genérico en la llamada a la API',
  'purchasesPayments.errorToast.save': 'Ha habido un error guardando el pago',
  'purchasesPayments.paymentData.issuedDate': 'Fecha de Emisión',
  'purchasesPayments.paymentData.receiptType': 'Forma de pago',
  'purchasesPayments.paymentData.numberDocument': 'Nº de documento',
  'purchasesPayments.contabilityCard.createAccounting': 'Genera contabilidad',
  'purchasesPayments.contabilityCard.journalEntryIssued': 'Comprobante emisión',
  'purchasesPayments.contabilityCard.journalEntryDeposited': 'Comprobante depósito',
  'purchasesPayments.notesData.notes': 'Notas',
  'purchasesPayments.window.search.title': 'Buscar Pago',
  'purchasesPayments.window.search.titleButton': 'Cancelar',
  'purchasesPayments.window.search.titleButton2': 'Aceptar',
  'purchasesPayments.editButtons.addOthers': '+ Añadir otro documento',
  'purchasesPayments.editButtons.addPayment': '+ Añadir un pago',
  'purchasesPayments.descriptionData.payments': 'Buscar pago',
  'purchasesPayments.otherDocumentsDataFormModal.originalAmount': 'Importe',
  'purchasesPayments.otherDocumentsDataFormModal.currencyCode': 'Moneda',
  'purchasesPayments.otherDocumentsDataFormModal.parityToMainCurrency': 'T/C',
  'purchasesPayments.otherDocumentsDataFormModal.amount': 'Importe en $',
  'purchasesPayments.otherDocumentsDataFormModal.type': 'Tipo',
  'purchasesPayments.otherDocumentsDataFormModal.description': 'Concepto',
  'purchasesPayments.otherDocumentsDataFormModal.detailPayment': 'Detalle del pago',
  'purchasesPayments.otherDocumentsDataFormModal.relatedTo.gridTitle': 'Buscar Proveedor',
  'purchasesPayments.otherDocumentsDataFormModal.relatedTo': 'Proveedor',
  'purchasesPayments.otherDocumentsDataFormModal.account': 'Cuenta',
  'purchasesPayments.otherDocumentsDataFormModal.costCenter': 'C. Costo',
  'purchasesPayments.purchaseInvoicesDataFormModal.originalAmount': 'Importe',
  'purchasesPayments.purchaseInvoicesDataFormModal.currencyCode': 'Moneda',
  'purchasesPayments.purchaseInvoicesDataFormModal.parityToMainCurrency': 'T/C',
  'purchasesPayments.purchaseInvoicesDataFormModal.amount': 'Importe en $',
  'purchasesPayments.purchaseInvoicesDataFormModal.type': 'Tipo',
  'purchasesPayments.purchaseInvoicesDataFormModal.description': 'Concepto',
  'purchasesPayments.otherDocumentsDataFormModal.codes': 'Tipos de cobros sin documento',
  'purchasesPayments.grid.docTypeName': 'Tipo',
  'purchasesPayments.grid.number': 'Número',
  'purchasesPayments.grid.relatedToName': 'Proveedor/Beneficiario',
  'purchasesPayments.grid.originalAmount': 'Imp. Or.',
  'purchasesPayments.grid.currencyCode': 'Moneda',
  'purchasesPayments.grid.parityToMainCurrency': 'T/C con CLP',
  'purchasesPayments.grid.amount': 'Importe CLP',
  'purchasesPayments.grid.description': 'Descripción',
  'purchasesPayments.grid.costCenterId': 'C. Costo',
  'purchasesPayments.grid.relatedToVatId': 'RUT',
  'purchasesPayments.footerData.total': 'Total',
  'purchaseWaybills.errorToast.save': 'Ha habido un error guardando la guía de despacho de compra',
  'purchaseWaybills.errorToast.read':
    'Ha habido un error intentando leer la guía de despacho de compra',
  'purchaseWaybills.errorToast.list':
    'Ha habido un error intentando listar las guías de dspacho de compras',
  'purchaseWaybills.successToast.save': 'Guía de despacho de compra guardada correctamente',
  'purchaseWaybills.warning.errorMessage':
    'Ha habido un error genérico al guardar la guía de despacho de compra',
  'purchaseWaybills.successToast.delete': 'Guía de despacho de compra borrada correctamente',
  'purchaseWaybills.errorToast.delete': 'Ha habido un error borrando la guía de despacho de compra',
  'purchaseWaybills.tabTitle': 'Guías de despacho de compras',
  'purchaseWaybills.confirmWindow.title': 'Eliminar Guía de despacho de compra',
  'purchaseWaybills.confirmWindow.subtitle':
    '¿Desea eliminar esta "Guía de despacho de compra" definitivamente?',
  'purchaseWaybills.docNumber': 'Número',
  'purchaseWaybills.issuedDate': 'Fecha',
  'purchaseWaybills.purchaseOrder': 'Orden de compra',
  'purchaseWaybills.isSale': 'Es compra',
  'purchaseWaybills.isElectronic': 'Electrónica',
  'purchaseWaybills.supplier': 'Proveedor',
  'purchaseWaybills.description': 'Concepto',
  'purchaseWaybills.warehouse': 'Bodega',
  'purchaseWaybills.costCenter': 'Centro Costo',
  'purchaseWaybills.notes': 'Notas',

  'purchaseWaybillsItems.table.product': 'Producto',
  'purchaseWaybillsItems.table.description': 'Descripción',
  'purchaseWaybillsItems.table.quantity': 'Cantidad',
  'purchaseWaybillsItems.table.unitCost': 'Costo Ud.',
  'purchaseWaybillsItems.table.net': 'Neto',
  'purchaseWaybillsItems.table.costCenter': 'Centro Costo',
  'receipts.depositData.bankOfDeposit': 'Depositado en',
  'receipts.depositData.deposited': 'Depositado',
  'receipts.depositData.dueDate': 'Depositar el',
  'receipts.depositData.issuedDate': 'Fecha emisión',
  'receipts.editButtons.addOthers': '+ Añadir otros documentos',
  'receipts.editButtons.addSale': '+ Añadir una factura ',
  'receipts.errorToast.delete': 'Ha habido un error eliminando el cobro',
  'receipts.errorToast.duplicate': 'Ha habido un error duplicando el cobro',
  'receipts.errorToast.save': 'Ha habido un error guardando el cobro',
  'receipts.errorToast.read': 'Ha habido un error leyendo el cobro',
  'receipts.errorToast.save.additionalMessage':
    'Ha habido un error generando el cobro de la factura/boleta. La factura aparece como no pagada, regularice la situación después de imprimir la factura',
  'receipts.warning.errorMessage': 'Ha habido un error genérico en la llamada a la API',
  'receipts.footerData.total': 'Total',
  'receipts.inputGroupCardModal.amount': 'Importe',
  'receipts.inputGroupCardModal.costCenter': 'Centro de costos',
  'receipts.inputGroupCardModal.customer': 'Cliente',
  'receipts.inputGroupCardModal.description': 'Concepto',
  'receipts.inputGroupCardModal.receiptType': 'Tipo',
  'receipts.inputGroupCardModal.receiptTypePlaceholder': 'Elegir un tipo',
  'receipts.notesData.notes': 'Notas',
  'receipts.paymentData.bankOfDocument': 'Banco',
  'receipts.paymentData.document': 'Nº de documento',
  'receipts.paymentData.generateAccounting': 'Generar contabilidad',
  'receipts.paymentData.receiptDeposit': 'Comprobante Depósito',
  'receipts.paymentData.receiptEmission': 'Comprobante Emisión',
  'receipts.paymentData.receiptType': 'Forma de pago',
  'receipts.successToast.delete': 'El cobro ha sido eliminado correctamente',
  'receipts.successToast.duplicate': 'El cobro ha sido duplicado correctamente',
  'receipts.successToast.save': 'El cobro ha sido guardado correctamente',
  'receipts.tabTitle': 'Cobros',
  'receipts.confirmWindow.subtitle': '¿Desea eliminar este "Cobro" definitivamente?',
  'receipts.confirmWindow.title': 'Eliminar Cobro',
  'receipts.window.delete.titleButton': 'Cancelar',
  'receipts.window.delete.titleButton2': 'Eliminar',
  'receipts.window.modal.acceptText': 'Aceptar',
  'receipts.window.modal.cancelText': 'Cancelar',
  'receipts.window.search.title': 'Buscar Cobro',
  'receipts.window.search.titleButton': 'Cancelar',
  'receipts.window.search.titleButton2': 'Aceptar',

  'receipts.grid.typeName': 'Tipo',
  'receipts.grid.docNumber': 'Número',
  'receipts.grid.customerName': 'Cliente/Beneficiario',
  'receipts.grid.amount': 'Importe',
  'receipts.grid.currencyCode': 'Moneda',
  'receipts.grid.parityToMainCurrency': 'T/C con CLP',
  'receipts.grid.originalUnitPrice': 'Precio/Ud Ad. ?',
  'receipts.grid.description': 'Descripción',
  'receipts.grid.rut': 'RUT',

  // Reports
  'reports.errorToast.read': 'Error al obtener el informe',
  'reports.warning.errorMessage': 'Ha habido un error al intentar obtener el informe',
  'reports.steps.1': 'Elija de entre todos los tipos de informe de la lista.',
  'reports.steps.2': 'Si lo desea, puede aplicar todos los filtros que necesite.',
  'reports.steps.3': 'Pulse sobre el botón “Obtener resultado” para ver el resultado del informe.',
  'reports.section.title.ledger': 'Libro Mayor',
  'reports.section.title.accounting': 'Contables',
  'reports.section.title.fixedAssets': 'Activos fijos',
  'reports.section.title.balanceSheets': 'Estados Financieros',
  'reports.section.title.dailyBook': 'Libro Diario',
  'reports.section.title.salesInvoices': 'Facturas de Ventas',

  'reports.report.title.mayorSinAgrupar': 'Libro Mayor',
  'reports.report.title.mayorSinAgrupar.compact': 'Libro Mayor Compacto',
  'reports.report.title.mayorSinAgrupar.costCenter': 'Libro Mayor por Centro de Costos',
  'reports.report.title.mayorSinAgrupar.fullInfo': 'Libro Mayor, con detalle de referencia',
  'reports.report.title.f29Ta': 'Simulación F29',
  'reports.report.title.numberedSheets': 'Hojas numeradas',
  'reports.report.title.feesCertificates': 'Certificados de honorarios',
  'reports.report.title.feesBook': 'Libro de honorarios',
  'reports.report.title.exchangeRateDifferencesInPurchases':
    'Diferencias por tipo de Cambio en Compras',
  'reports.report.title.exchangeRateDifferencesInSales': 'Diferencias por tipo de Cambio en Ventas',
  'reports.report.title.summaryOfExpensesAndIncomeByCostCenter':
    'Resumen de Gastos e Ingresos por Centro de Costo',
  'reports.report.title.booksOfBillsOfExchange': 'Libro de Letras de Cambio',
  'reports.report.title.fixedAssetsCards': 'Fichas de Activos Fijos',
  'reports.report.title.annualReportByAccount.withCM': 'Resumen anual por cuenta contable, con CM',
  'reports.report.title.annualReportByAccount.withoutCM':
    'Resumen anual por cuenta contable, sin CM',
  'reports.report.title.classifiedBalance': 'Balance Clasificado',
  'reports.report.title.classifiedBalanceModel2': 'Balance Clasificado, Modelo 2',
  'reports.report.title.sumBalance': 'Balance de Sumas y Saldos',
  'reports.report.title.8ColumnTaxBalance': 'Balance Tributario de 8 columnas',
  'reports.report.title.incomeStatement': 'Cuenta de Resultados',
  'reports.report.title.2ColumnIncomeStatement': 'Cuenta de Resultados, 2 columnas',
  'reports.report.title.dailyBook': 'Libro Diario',
  'reports.report.title.compactDailyBook': 'Libro Diario Compacto',
  'reports.report.title.compactColumnDailyBook': 'Libro Diario Compacto en columnas',
  'reports.report.title.customersAdvancesPendingApplication':
    'Anticipos de clientes pendientes de aplicar',
  'reports.report.title.withOutstandingBalance.sortedByCustomer':
    'Con saldo pendiente (ordenadas por cliente)',
  'reports.report.title.withOutstandingBalance.sortedByVATId':
    'Con saldo pendiente (ordenadas por RUT)',
  'reports.report.title.withOutstandingBalance.sortedByDate':
    'Con saldo pendiente (ordenadas por fecha)',
  'reports.report.title.pendingWithNotDeposited':
    'Con saldo pendiente, incluyendo cobros no depositados',
  'reports.report.title.overdue.sortedByCustomer': 'Morosas (ordenadas por cliente)',
  'reports.report.title.overdue.sortedByVATId': 'Morosas (ordenadas por RUT)',
  'reports.report.title.overdue.sortedByDate': 'Morosas (ordenadas por fecha)',
  'reports.report.title.overdue.summaryByCustomer': 'Morosidad de clientes por tramos de plazo',
  'reports.report.title.overdue.summaryByCustomer.withDetails':
    'Morosidad de clientes por tramos, con detalle',
  'reports.report.title.dailyCashIncomeSummary': 'Resumen de ingresos en caja diarios',

  'reports.report.header.foiled': 'Encabezado Foliado',

  'reports.action.getResults': 'Obtener Resultados',
  'reports.action.openFilters': 'Abrir filtros',
  'reports.action.closeFilters': 'Cerrar filtros',

  'reports.filters.account.label': 'Nº cuenta',
  'reports.filters.account.search': 'Buscar nº de cuenta',
  'reports.filters.accountFrom.label': 'Nº cuenta desde',
  'reports.filters.accountFrom.search': 'Buscar nº de cuenta',
  'reports.filters.accountTo.label': 'Nº cuenta hasta',
  'reports.filters.accountTo.search': 'Buscar nº de cuenta',
  'reports.filters.customer.label': 'Cliente',
  'reports.filters.customer.search': 'Buscar cliente',
  'reports.filters.year': 'Año',
  'reports.filters.month': 'Mes',
  'reports.filters.startPage': 'Nº de hoja inicial',
  'reports.filters.endPage': 'Nº de hoja final',
  'reports.filters.printHeader.leaveSpaceForHeader': 'Dejar espacio en blanco',
  'reports.filters.printHeader.printLegalHeader': 'Imprimir encabezado',
  'reports.filters.date': 'Fecha',
  'reports.filters.dateFrom': 'Fecha desde',
  'reports.filters.dateTo': 'Fecha hasta',
  'reports.filters.costCenter.label': 'Centro de costo',
  'reports.filters.costCenter.search': 'Buscar centro de costo',
  'reports.filters.location': 'Ubicación',
  'reports.filters.bookId': 'IFRS',
  'reports.filters.showAccountsWithZeroBalance': 'Mostrar cuentas con saldo cero',
  'reports.filters.showOnlyAccountsWithActivity': 'Sólo cuentas con movimientos',
  'reports.filters.showLevels': 'Mostrar niveles',
  'reports.filters.journalEntryFrom.label': 'Comprobante desde',
  'reports.filters.journalEntryTo.label': 'Comprobante hasta',
  'reports.filters.journalEntry.search': 'Buscar comprobante',
  'reports.filters.printJournalSums': 'Imprimir las sumas de cada comprobante',
  'reports.filters.salesman.label': 'Vendedor',
  'reports.filters.salesman.search': 'Buscar vendedor',
  'reports.filters.branch.label': 'Sucursal',
  'reports.filters.branch.search': 'Buscar sucursal',
  'reports.filters.currencyType': 'Moneda',

  'reports.filters.bookId.info.IFRS':
    'IFRS: todos los movimientos IFRS, exclusivos IFRS + comunes PCGA-IFRS.',
  'reports.filters.bookId.info.PCGA':
    'PCGA: todos los movimientos PCGA, exclusivos PCGA + comunes PCGA-IFRS.',
  'reports.filters.bookId.info.IFRS.alone': 'IFRS sólo: sólo los movimientos exclusivos IFRS.',
  'reports.filters.bookId.info.PCGA.alone': 'PCGA sólo: sólo los movimientos exclusivos PCGA.',
  'reports.filters.bookId.info.PCGAIFRS': 'PCGA-IFRS: movimientos comunes PCGA-IFRS.',

  'remunerationConcepts.errorToast.list':
    "'Ha habido un error leyendo los conceptos de remuneración'",
  'remunerationConcepts.warning.errorMessage':
    'Ha habido un error genérico en la operación de concepto de remuneración',
  'rights.errorToast.read': 'Ha habido un error leyendo permisos de usuario',
  'rights.warning.errorMessage':
    'Ha habido un error genérico en la operación con los permisos de usuario',
  'safeConfirmation.title': 'Confirma la operación',
  'safeConfirmation.subtitle': 'Ingrese en la casilla el código que se muestra a continuación',
  // REMUNERATION CONCEPTS
  'remunerationConcept.tabTitle': 'Estructura de la liquidación',
  'remunerationConcept.headerData.name': 'Nombre',
  'remunerationConcept.headerData.order': 'Orden',
  'remunerationConcept.headerData.dataType': 'Tipo',
  'remunerationConcept.headerData.decimals': 'Decimales',
  'remunerationConcept.headerData.account': 'Cuenta',
  'remunerationConcept.headerData.accountCounterpart': 'Contrapartida',
  'remunerationConcept.headerData.prorateForAbsenceDays': 'Se prorratea por Días de Ausencia',
  'remunerationConcept.headerData.printInRemunerationBook': 'Imprimir en Libro de Remuneraciones',
  'remunerationConcept.headerData.prorateForMedicalLeaveDays': 'Se prorratea por Días de Licencia',
  'remunerationConcept.headerData.printInPaySlip': 'Imprimir en Liquidaciones',
  'remunerationConcept.headerData.blockEmployeeOverride': 'Bloquear comportamiento predeterminado',
  'remunerationConcept.headerData.printIfEmpty': 'Imprimir si es 0',
  'remunerationConcept.headerData.discontinued': 'Discontinuado',
  'remunerationConcept.headerData.accountByEmployee': 'Centralizar por RUT',
  'remunerationConcept.headerData.expression': 'Fórmula de cálculo',
  'remunerationConcept.headerData.notes': 'Notas',
  'remunerationConcept.headerData.electronicBookId': 'Código LRE',
  'remunerationConcept.headerData.contractTerminationHolidaysCalculationType':
    'Incluir cálculo vacac. finiquito',
  'remunerationConcept.confirmWindow.title': 'Eliminar estructura de la liquidación',
  'remunerationConcept.confirmWindow.subtitle':
    '¿Desea eliminar esta "Estructura de la Liquidación" definitivamente?',
  'remunerationConcept.errorToast.save':
    'Ha habido un error guardando la Estructura de la Liquidación',
  'remunerationConcept.errorToast.read':
    'Ha habido un error leyendo la Estructura de la Liquidación',
  'remunerationConcept.errorToast.list':
    'Ha habido un error leyendo las Estructuras de la Liquidación',
  'remunerationConcept.warningCreate.selectedParentNeeded':
    'Es necesario seleccionar una estructura padre donde poder crear una nueva estructura',
  'remunerationConcept.treeview.title': 'Añadir item',

  // Sales
  'sales.maxItems': 'Cantidad máxima de items alcanzada',
  'sales.maxItemsWarning1':
    'No se puede agregar el item seleccionado ya que se ha alcanzado el límite máximo de',
  'sales.maxItemsWarning2': 'items por factura',
  'sales.searchProduct': 'Buscar Producto',
  'sales.document.cannotBeNull': 'El documento no puede ser nulo',
  'sales.document.quoteMustBeApproved': 'La cotización tiene que estar aprobada',

  // Sales Items
  'salesItems.table.product': 'Producto',
  'salesItems.table.description': 'Descripción',
  'salesItems.table.quantity': 'Cantidad',
  'salesItems.table.originalUnitPrice': 'Precio/Ud',
  'salesItems.table.currencyCode': 'Moneda',
  'salesItems.table.discountPercentage': 'Dto.',
  'salesItems.table.net': 'Neto',
  'salesItems.table.parityToMainCurrency': 'T/C con CLP',
  'salesItems.table.unitPrice': 'Precio/Ud {mainCurrencyCode}',
  'salesItems.table.error.discountPercentage.title': 'Ha alcanzado el descuento máximo',
  'salesItems.table.error.discountPercentage.description':
    'El descuento de {value} es mayor al máximo permitido de {maxDiscount}%',
  'salesItems.table.error.net.title': 'Ha alcanzado el descuento máximo',
  'salesItems.table.error.net.description':
    'El descuento de {value} es mayor al máximo permitido de {maxDiscount}%',

  // Sales Orders
  'salesOrders.table.archived': 'Archivar',
  'salesOrders.createOrderFromSalesQuote': 'Crear pedido a partir de una Cotización',
  'salesOrders.createOrderErrorSubtitle':
    'Ha habido un error mientras se creaba el pedido de ventas',
  'salesOrders.createOrderErrorTitle': 'Error creando el pedido de ventas',
  'salesOrders.deliveryTab.address': 'Dirección',
  'salesOrders.deliveryTab.deliveryDate': 'Entrega',
  'salesOrders.deliveryTab.deliveryCost': 'Costo',
  'salesOrders.deliveryTab.deliveryTimeFrame': 'Horario',
  'salesOrders.deliveryTab.deliveryNotes': 'Notas de la entrega',
  'salesOrders.warning.errorMessage': 'Se produjo un error genérico al crear el pedido.',
  'salesOrders.errorToast.createFromSalesQuote':
    'Error al intentar crear un pedido a través de una cotización',
  'salesOrders.errorToast.createFromInvoiceItems':
    'Error al intentar crear un pedido a través de una factura',
  'salesOrders.errorToast.delete': 'Ha habido un error eliminando el pedido',
  'salesOrders.errorToast.list': 'Ha habido un error leyendo el listado de pedidos',
  'salesOrders.errorToast.read': 'Ha habido un error leyendo el pedido',
  'salesOrders.errorToast.save': 'Ha habido un error guardando el pedido',
  'salesInvoices.confirmWindow.title': 'Eliminar Factura',
  'salesInvoices.confirmWindow.subtitle': '¿Desea eliminar esta "Factura" definitivamente?',
  // 'salesInvoices.errorToast.save': 'Ha habido un error guardando la factura',
  'salesInvoices.validationError.emptyDocType':
    'El tipo de documento no puede estar vacío. Por favor, selecciona uno y vuelve a intentarlo',
  'salesOrders.generalData.issuedDate': 'Fecha',
  'salesOrders.generalData.orderNumber': 'Número',
  'salesOrders.generalDataTab.salesman': 'Vendedor',
  'salesOrders.generalDataTab.contact': 'Contacto',
  'salesOrders.generalDataTab.source': 'Origen',
  'salesOrders.generalDataTab.sourceOrderId': 'Nº',
  'salesOrders.generalDataTab.guide': 'Guía',
  'salesOrders.generalData.nullDoc': 'Nulo',
  'salesOrders.generalData.dueDate': 'Previsto',
  'salesOrders.generalDataTab.invoice': 'Factura',
  'salesOrders.generalData.archived': 'Archivar',
  'salesOrders.generalData.locked': 'Bloqueado',
  'salesOrders.genericErrorSubtitle':
    'Ha habido un error mientras se realizaba la acción relacionado con pedidos de ventas',
  'salesOrders.genericErrorTitle': 'Error durante la operación con el pedido de ventas',
  'salesOrders.header.costCenter': 'Centro de costos',
  'salesOrders.header.customer.name': 'Cliente',
  'salesOrders.header.deliveryCost': 'Costo',
  'salesOrders.header.deliverySIIType': 'Tipo',
  'salesOrders.header.deliverySchedule': 'Horario',
  'salesOrders.header.electronic': 'Guía electrónica',
  'salesOrders.header.notes': 'Notas',
  'salesOrders.header.order': 'Pedido',
  'salesOrders.header.pricesList': 'Lista de precios',
  'salesOrders.header.purchaseOrderNumber': 'O/C',
  'salesOrders.header.terms': 'Forma de pago',
  'salesOrders.header.warehouse': 'Bodega',
  'salesOrders.infoToast.createFromSalesQuote.itemsAlreadyDelivered':
    'Algunos items de la cotización ya han sido despachados. Se tomarán solo aquellos items pendientes',
  'salesOrders.successToast.delete': 'El pedido ha sido eliminado correctamente',
  'salesOrders.successToast.save': 'El pedido ha sido guardado correctamente',
  'salesOrders.tab.customer': 'Cliente',
  'salesOrders.tab.delivery': 'Entrega',
  'salesOrders.tab.traces': 'Trazado',
  'salesOrders.tab.generalData': 'Datos generales',
  'salesOrders.tab.CustomFields': 'Personalizados',
  'salesOrders.confirmWindow.title': 'Eliminar Pedido',
  'salesOrders.confirmWindow.subtitle': '¿Desea eliminar este "Pedido" definitivamente?',
  'salesOrders.tabTitle': 'Pedidos',
  'salesOrders.updateOrderErrorSubtitle':
    'Ha habido un error mientras se actualizaba el pedido de ventas',
  'salesman.generalData.salesmanId': 'Vendedor',
  'salesOrders.updateOrderErrorTitle': 'Error actualizando el pedido de ventas',
  'salesOrders.accept': 'Aceptar',
  'salesOrders.cancel': 'Cancelar',
  'salesOrders.notFound.byId':
    'No se ha conseguido encontrar un pedido de ventas con el id {salesOrderId}',
  // Sales Quotes
  'salesQuotes.errorToast.delete': 'Ha habido un error eliminando la cotización',
  'salesQuotes.errorToast.read': 'Ha habido un error leyendo la cotización',
  'salesQuotes.errorToast.save': 'Ha habido un error guardando la cotización',
  'salesQuotes.generalData.issuedDate': 'Fecha',
  'salesQuotes.generalData.nullDoc': 'Nula',
  'salesQuotes.generalData.quoteNumber': 'Número',
  'salesQuotes.generalDataTab.contact': 'Contacto',
  'salesQuotes.generalDataTab.daysToExpiration': 'Validez (días)',
  'salesQuotes.generalDataTab.deliveryNumberOfDays': 'Plazo entrega (días)',
  'salesQuotes.generalDataTab.salesman': 'Vendedor',
  'salesQuotes.groupsTab.grid.addRow': '+ Añadir grupo',
  'salesQuotes.header.costCenter': 'Centro de costos',
  'salesQuotes.header.customer.name': 'Cliente',
  'salesQuotes.header.deliveryCost': 'Costo',
  'salesQuotes.header.deliverySchedule': 'Horario',
  'salesQuotes.header.deliverySIIType': 'Tipo',
  'salesQuotes.header.electronic': 'Guía electrónica',
  'salesQuotes.header.notes': 'Notas',
  'salesQuotes.header.order': 'Pedido',
  'salesQuotes.header.pricesList': 'Lista de precios',
  'salesQuotes.header.purchaseOrderNumber': 'O/C',
  'salesQuotes.header.terms': 'Forma de pago',
  'salesQuotes.header.warehouse': 'Bodega',
  'salesQuotes.successToast.delete': 'La cotización ha sido eliminada correctamente',
  'salesQuotes.successToast.save': 'La cotización ha sido guardada correctamente',
  'salesQuotes.tab.footer': 'Pie',
  'salesQuotes.tab.generalData': 'Datos generales',
  'salesQuotes.tab.groups': 'Grupos',
  'salesQuotes.tab.header': 'Encabezado',
  'salesQuotes.tabTitle': 'Cotizaciones',
  'salesQuotes.confirmWindow.title': 'Eliminar Cotización',
  'salesQuotes.confirmWindow.subtitle': '¿Desea de eliminar esta "Cotización" definitivamente?',
  'salesQuotes.filePDFName': 'Cotización',
  'salesQuotes.cancel': 'Cancelar',
  'salesQuotes.accept': 'Aceptar',
  'salesQuotes.notFound.byId':
    'No se ha conseguido encontrar una cotización de ventas con el id {salesQuoteId}',
  'salesQuotes.groups.grid.groupName': 'Número',
  'salesQuotes.groups.grid.header': 'Encabezado del grupo',
  'salesQuotes.groups.grid.footer': 'Pie del grupo',

  // Sales Tickets
  'salesTickets.tabTitle': 'Servicio técnico',
  'salesTickets.ticketId': 'Incidencia',
  'salesTickets.subject': 'Descripción',
  'salesTickets.category': 'Categoría',
  'salesTickets.status': 'Estado',
  'salesTickets.issuedDate': 'Comienzo',
  'salesTickets.customer': 'Cliente',
  'salesTickets.description': 'Problema',
  'salesTickets.solution': 'Solución',
  'salesTickets.assignedTo': 'Asignado a',
  'salesTickets.createdBy': 'Creado por',
  'salesTickets.dueDate': 'Término',
  'salesTickets.completitionDate': 'Previsto',
  'salesTickets.product': 'Producto',
  'salesTickets.serialNumber': 'S/N',
  'salesTickets.submittedBy': 'Informado por',
  'salesTickets.submittedByContactInfo': 'Fono/Email',
  'salesTickets.itemOnRepair.product': 'Nombre del producto',
  'salesTickets.itemOnRepair.description': 'Descripción',
  'salesTickets.itemOnRepair.dueDate': 'Previsto',
  'salesTickets.itemOnRepair.deliveryDate': 'Entregado',
  'salesTickets.itemOnRepair.reception': 'Recepción',
  'salesTickets.itemOnRepair.reception.receivedAt': 'Recibido el',
  'salesTickets.itemOnRepair.reception.receivedBy': 'Por',
  'salesTickets.itemOnRepair.reception.description': 'Estado',
  'salesTickets.itemOnRepair.reparation': 'Reparación',
  'salesTickets.itemOnRepair.reparation.repairedAt': 'Reparado el',
  'salesTickets.itemOnRepair.reparation.repairedBy': 'Por',
  'salesTickets.itemOnRepair.reparation.description': 'Notas',
  'salesTickets.itemOnRepair.spares.warehouse': 'Bodega',
  'salesTickets.itemOnRepair.spares.spare.sku': 'Repuesto',
  'salesTickets.itemOnRepair.spares.spare.description': 'Descripción',
  'salesTickets.itemOnRepair.spares.quantity': 'Cantidad',
  'salesTickets.itemOnRepair.spares.unitPrice': 'Precio Ud',
  'salesTickets.itemOnRepair.spares.net': 'Neto',
  'salesTickets.itemOnRepair.spares.discount': 'Dto.',
  'salesTickets.itemOnRepair.spares.availableAt': 'Disponible',
  'salesTickets.itemOnRepair.spares.costCenter': 'C. Costo',
  'salesTickets.activities.grid.subject': 'Asunto',
  'salesTickets.activities.grid.issuedDate': 'Inicio',
  'salesTickets.activities.grid.dueDate': 'Fin',
  'salesTickets.activities.grid.assignedTo.name': 'Asginado a',
  'salesTickets.activities.grid.status': 'Estado',
  'salesTickets.activities.subject': 'Asunto',
  'salesTickets.activities.description': 'Descripción',
  'salesTickets.activities.contact': 'Contacto',
  'salesTickets.activities.assignedTo': 'Asignado a',
  'salesTickets.activities.issuedDate': 'Fecha de inicio',
  'salesTickets.activities.dueDate': 'Fecha fin',
  'salesTickets.activities.status': 'Estado',
  'salesTickets.activities.status.inProcess': 'En Proceso',
  'salesTickets.activities.status.finished': 'Terminada',
  'salesTickets.activities.status.canceled': 'Cancelada',
  'salesTickets.status.open': 'Abierto',
  'salesTickets.status.waiting': 'En trámite',
  'salesTickets.status.closed': 'Cerrado',
  'salesTickets.tab.generalCard': 'Datos',
  'salesTickets.tab.activities': 'Historial',
  'salesTickets.tab.itemOnRepair': 'Producto en reparación',
  'salesTickets.tab.spares': 'Repuestos',
  'salesTickets.errorToast.list': 'Ha habido un error leyendo el listado de tickets',
  'salesTickets.errorToast.read': 'Ha habido un error leyendo el ticket',
  'salesTickets.errorToast.save': 'Ha habido un error guardando el ticket',
  'salesTickets.warning.errorMessage': 'Ha habido un error genérico en la operación con el ticket',
  'salesTickets.confirmWindow.title': 'Eliminar Ticket',
  'salesTickets.confirmWindow.subtitle': '¿Desea de eliminar este "Ticket" definitivamente?',

  // Sales Waybills
  'salesWaybills.errorToast.delete': 'Ha habido un error eliminando la guía de despacho',
  'salesWaybills.errorToast.read': 'Ha habido un error leyendo la guía de despacho',
  'salesWaybills.errorToast.save': 'Ha habido un error guardando la guía de despacho',
  'salesWaybills.errorToast.createFromSalesOrder':
    'Error al intentar crear una guía de despacho a través de un pedido de ventas',
  'salesWaybills.errorToast.createFromSalesQuote':
    'Error al intentar crear una guía de despacho a través de una cotización',
  'salesWaybills.header.electronic': 'Guía electrónica',
  'salesWaybills.header.costCenter': 'Centro de costos',
  'salesWaybills.header.deliveryCost': 'Costo',
  'salesWaybills.header.deliverySchedule': 'Horario',
  'salesWaybills.header.deliverySIIType': 'Tipo',
  'salesWaybills.header.isSale': 'Es venta',
  'salesWaybills.header.notes': 'Notas',
  'salesWaybills.header.order': 'Pedido',
  'salesWaybills.header.pricesList': 'Lista de precios',
  'salesWaybills.header.purchaseOrderNumber': 'O/C',
  'salesWaybills.header.terms': 'Forma de pago',
  'salesWaybills.header.warehouse': 'Bodega',
  'salesWaybills.header.contact': 'Contacto',
  'salesWaybills.header.cause': 'Motivo',
  'salesWaybills.successToast.delete': 'La guía de despacho ha sido eliminada correctamente',
  'salesWaybills.successToast.save': 'La guía de despacho ha sido guardada correctamente',
  'salesWaybills.tabTitle': 'Guías de Despacho',
  'salesWaybills.confirmWindow.title': 'Eliminar Guía de Despacho',
  'salesWaybills.confirmWindow.subtitle':
    '¿Desea eliminar esta "Guía de Despacho" definitivamente?',
  'salesWaybills.filePDFName': 'Guía de Despacho',
  'salesWaybills.sendToSII.fail': 'Error al enviar la guía de despacho al SII',
  'salesWaybills.sendToSII.sucess': 'Guía de despacho enviada al SII correctamente',
  'salesWaybills.infoToast.createFromSalesOrder.itemsAlreadyDelivered':
    'Algunos items del pedido ya han sido despachados. Se tomarán solo aquellos items pendientes',
  'salesWaybills.createSalesWaybillFromSalesOrder':
    'Crear Guía de Despacho a partir de un Pedido de ventas',
  'salesWaybills.createSalesWaybillFromSalesQuote':
    'Crear Guía de Despacho a partir de una Cotización',

  // Salesmen
  'salesmen.accept': 'Aceptar',
  'salesmen.address.address': 'Dirección',
  'salesmen.address.city': 'Ciudad',
  'salesmen.address.county': 'Comuna',
  'salesmen.address.email': 'Email',
  'salesmen.address.phone': 'Teléfono',
  'salesmen.address.title': 'Dirección',
  'salesmen.cancel': 'Cancelar',
  'salesmen.deleteMessage': 'Está a punto de eliminar este vendedor definitivamente, ¿está seguro?',
  'salesmen.deleteSalesman': 'Eliminar Vendedor',
  'salesmen.duplicate': 'Duplicar',
  'salesmen.edit': 'Editar',
  'salesmen.email': 'Email',
  'salesmen.erase': 'Eliminar',
  'salesmen.errorToast.delete': 'Ha habido un error eliminando el vendedor',
  'salesmen.errorToast.save': 'Ha habido un error guardando el vendedor',
  // 'salesmen.errorToast.list':
  //   'Ha habido un error leyendo el listado de vendedores',
  'salesmen.errorToast.read': 'Ha habido un error leyendo el vendedor',
  'salesmen.excel': 'Excel',
  'salesmen.generalData.discontinued': 'Discontinuado',
  'salesmen.generalData.restrictToBranch': 'Restringir a la Sucursal',
  'salesmen.generalData.legalName': 'Razón Social',
  'salesmen.generalData.rut': 'RUT',
  'salesmen.generalData.salesmanId': 'Vendedor',
  'salesmen.generalData.title': 'Datos generales',
  'salesmen.headerData.name': 'Nombre',
  'salesmen.headerData.notes': 'Notas',
  'salesmen.new': 'Nuevo',
  'salesmen.preview': 'Vista previa',
  'salesmen.print': 'Imprimir',
  'salesmen.saveAndCreate': 'Guardar y crear nuevo',
  'salesmen.search': 'Buscar',
  'salesmen.searchSalesman': 'Buscar Vendedor',
  'salesmen.successToast.delete': 'El vendedor ha sido eliminado correctamente',
  'salesmen.successToast.save': 'El vendedor ha sido guardado correctamente',
  'salesmen.tabTitle': 'Vendedores',
  // 'salesmen.warning.errorMessage':
  //   'Ha habido un error genérico en la operación con el vendedor',
  'salesmen.confirmWindow.subtitle': '¿Desea eliminar este "Vendedor" definitivamente?',
  'salesmen.confirmWindow.title': 'Eliminar Vendedor',
  // SETTINGS
  'settings.allowNonContinuousDates': 'Permitir fechas saltadas',
  'settings.allowOverCreditLimit': 'Permitir sobre límite de crédito',
  'settings.allowUnderCost': 'Permitir por debajo del costo',
  'settings.allowUserChangePrices': 'Permitir modificar precios',
  'settings.allowWithoutStock': 'Permitir sin stock',
  'settings.allowWithOverdueInvoices': 'Facturas morosas  |   Permitir',
  'settings.multipleCurrencies': 'Multimoneda en facturas',
  // BUTTONS
  'searchWindow.titleButton.accept': 'Aceptar',
  'searchWindow.titleButton.cancel': 'Cancelar',
  'searchWindow.titleButton.no': 'No',
  'searchWindow.titleButton.delete': 'Eliminar',
  // 'select.noOptions': 'No hay opciones para mostrar',
  'selectCompany.acceptText': 'Aceptar',
  'selectCompany.cancelText': 'Cancelar',
  'selectCompany.title': 'Seleccione una empresa',
  'selectCompany.errorTitle': 'Ha habido un error leyendo las empresas',
  'selectCompany.VATId.error': 'El RUT de la empresa no puede estar vacío',
  'selectCompany.noCompanies': 'No hay empresas disponibles',
  'statistics.all': 'Desde siempre',
  'statistics.dateFrom': 'Fecha desde',
  'statistics.dateTo': 'Fecha hasta',
  'statistics.period': 'Elegir período',
  'statistics.searchClient': 'Buscar Cliente',
  'statistics.searchCostCenter': 'Buscar Centro de costos',
  'statistics.searchProduct': 'Buscar Producto',
  'statistics.searchProductsCategory': 'Buscar Familia de Productos',
  'statistics.errorToast.read': 'Ha habido un error leyendo la estadística.',
  'statistics.onboarding.step1': 'Elija de entre todos los tipos de estadística de la lista.',
  'statistics.onboarding.step2': 'Si lo desea, puede aplicar todos los filtros que necesite.',
  'statistics.onboarding.step3':
    'Pulse sobre el botón “Obtener resultado” para ver la estadística.',
  'statistics.tabTitle': 'Estadísticas',
  'statistics.value.false': 'No',
  'statistics.value.true': 'Sí',
  'statistics.warning.errorMessage':
    'Ha habido un error genérico obteniendo los datos de las estadísticas.',
  'statistics.warning.subtitle':
    'Para poder utilizar los filtros disponibles, debe seleccionar primero un tipo de estadística.',
  'statistics.warning.title': '¡Atención!',
  'statistics.warning.titleButton': 'Aceptar',
  'statistics.leftMenuDrawer.header': 'Filtros',
  'statistics.button.filter': 'Filtrar',
  'statistics.button.hideFilters': 'Cerrar filtros',
  'statistics.button.showFilters': 'Abrir filtros',
  'statistics.button.results': 'Obtener resultados',
  // Statistics categories
  'statistics.category.customerSales': 'Clientes',
  'statistics.category.salesOrders': 'Pedidos',
  'statistics.category.salesQuotes': 'Cotizaciones',
  'statistics.category.sales': 'Ventas',
  'statistics.category.stock': 'Stock',
  'statistics.category.opportunitiesRoot': 'Oportunidades',
  'statistics.category.salesTickets': 'Servicio Técnico',
  // Statistics fields
  'statistics.field.VAT': 'VAT',
  'statistics.field.activeDays': 'Días activo',
  'statistics.field.averageTicket': 'Media',
  'statistics.field.branch_name': 'Sucursal',
  'statistics.field.branch': 'Sucursal',
  'statistics.field.comissions': 'Comisión',
  'statistics.field.customer_name': 'Cliente',
  'statistics.field.customerCategoryName': 'Familia de clientes',
  'statistics.field.customerName': 'Cliente',
  'statistics.field.customer_VATId': 'RUT Cliente',
  'statistics.field.dayOfMonth': 'Día del mes',
  'statistics.field.dayOfWeek': 'Día de la semana',
  'statistics.field.daysSinceLastSale': 'Días desde la última venta',
  'statistics.field.dealer_name': 'Distribuidor',
  'statistics.field.dealerName': 'Distribuidor',
  'statistics.field.description': 'Descripción',
  'statistics.field.discounts': 'Descuentos',
  'statistics.field.discountsPercentage': 'Descuentos %',
  'statistics.field.docNumber': 'Número',
  'statistics.field.docType_name': 'Tipo',
  'statistics.field.dueDate': 'Vencimiento',
  'statistics.field.firstSale': 'Primera venta',
  'statistics.field.issuedDate': 'Emisión',
  'statistics.field.items_costCenter_name': 'C. costo',
  'statistics.field.items_discountPercentage': 'Descuento %',
  'statistics.field.items_product_description': 'Descripción',
  'statistics.field.items_product_productCategory_name': 'Familia',
  'statistics.field.items_product_sku': 'Producto',
  'statistics.field.items_quantity': 'Cantidad',
  'statistics.field.items_unitPrice': 'Precio unitario',
  'statistics.field.lastSale': 'Última venta',
  'statistics.field.margin': 'Margen',
  'statistics.field.marginChangeYoYPercentage': 'Margen Variación %',
  'statistics.field.marginPercentage': 'Margen %',
  'statistics.field.month': 'Mes',
  'statistics.field.net': 'Neto',
  'statistics.field.netChangeYoYPercentage': 'Variación %',
  'statistics.field.netPercentage': 'Neto %',
  'statistics.field.numberOfDocuments': 'Nº Documantos',
  'statistics.field.numberOfInvoices': 'Número de facturas',
  'statistics.field.percentage': 'Porcentaje',
  'statistics.field.productCategoryName': 'Familia de productos',
  'statistics.field.productDescription': 'Descripción',
  'statistics.field.productSKU': 'SKU',
  'statistics.field.quantity': 'Cantidad',
  'statistics.field.quantityAlt': 'Cantidad alternativa',
  'statistics.field.returns': 'Devolucion',
  'statistics.field.returnsPercentage': 'Devolucion %',
  'statistics.field.returnspercentage': 'Devolucion %',
  'statistics.field.salesman_name': 'Vendedor',
  'statistics.field.sku': 'SKU',
  'statistics.field.sourceOrderId': 'Pedido',
  'statistics.field.salesOrderId': 'Número',
  'statistics.field.stock': 'Stock',
  'statistics.field.total': 'Venta',
  'statistics.field.totals_total': 'Total',
  'statistics.field.totalsOriginalCurrency_currencyCode': 'Moneda original',
  'statistics.field.totalsOriginalCurrency_net': 'Neto (moneda original)',
  'statistics.field.unitPrice': 'Precio Un',
  'statistics.field.units': 'Unidades',
  'statistics.field.unitOfMeasure': 'Unidad de medida',
  'statistics.field.unitOfMeasureAlt': 'Unidad de medida alternativa',
  'statistics.field.year': 'Año',
  'statistics.field.purchaseOrderNumber': 'Orden compra',
  'statistics.field.cotizaciones': 'Cotizaciones',
  'statistics.field.pedidos': 'Pedidos',
  'statistics.field.guias': 'Guias',
  'statistics.field.facturas': 'Facturas',
  'statistics.field.boletas': 'Boletas',
  'statistics.field.n_de_crédito': 'Nº de crédito',
  'statistics.field.n_de_débito': 'Nº de débito',
  'statistics.field.otros_ventas': 'Otros ventas',
  'statistics.field.venta': 'Venta',
  'statistics.field.devolución': 'Devolución',
  'statistics.field.county': 'Comuna',
  'statistics.field.state': 'Región',
  'statistics.field.salesmanName': 'Vendedor',
  // Statistics filters
  'statistics.filters.collapsedTooltip': 'Abrir filtros',
  'statistics.filters.expandedTooltip': 'Cerrar filtros',
  'statistics.filters.header': 'Filtros',
  'statistics.filterLabel.branch': 'Sucursal',
  'statistics.filterLabel.costCenter': 'Centro de costos',
  'statistics.filterLabel.customerCategory': 'Familia de Clientes',
  'statistics.filterLabel.customer': 'Cliente',
  'statistics.filterLabel.dateFrom': 'Fecha desde',
  'statistics.filterLabel.dateTo': 'Fecha hasta',
  'statistics.filterLabel.period': 'Período',
  'statistics.filterLabel.productCategory': 'Familia de Productos',
  'statistics.filterLabel.product': 'Producto',
  'statistics.filterLabel.salesman': 'Vendedor',
  'statistics.filterLabel.warehouse': 'Bodega',
  'statistics.filterLabel.dealer': 'Distribuidor',
  // Statistics period options
  'statistics.periodOption.all': 'Desde siempre',
  'statistics.periodOption.yesterday': 'Ayer',
  'statistics.periodOption.today': 'Hoy',
  'statistics.periodOption.L-30-D': 'Últimos 30 días',
  'statistics.periodOption.C-T': 'Trimestre actual',
  'statistics.periodOption.L-90-D': 'Últimos 90 días',
  'statistics.periodOption.L-1-Y': 'Últimos 365 días',
  // Statistics titles
  'statistics.title.customersActivity': 'Actividad clientes',
  'statistics.title.salesQuotesNotConverted': 'No convertidas en venta',
  'statistics.title.salesInvoicesByBranch': 'Por sucursal',
  'statistics.title.salesInvoicesByCounty': 'Por comuna',
  'statistics.title.salesInvoicesByCustomer': 'Por cliente',
  'statistics.title.salesInvoicesByCustomerCategory': 'Por familia de clientes',
  'statistics.title.salesInvoicesByDayOfMonth': 'Por día del mes',
  'statistics.title.salesInvoicesByDayOfWeek': 'Por día de la semana',
  'statistics.title.salesInvoicesByMonth': 'Por meses',
  'statistics.title.salesInvoicesByProduct': 'Por producto',
  'statistics.title.salesInvoicesByProductCategory': 'Por familia de productos',
  'statistics.title.salesInvoicesBySalesman': 'Por vendedor',
  'statistics.title.salesInvoicesByState': 'Por región',
  'statistics.title.salesInvoicesByYear': 'Por años',
  'statistics.title.salesInvoicesWithDetails': 'Facturas con detalle',
  'statistics.title.salesInvoicesWithoutDetails': 'Facturas sin detalle',
  'statistics.title.stockByProduct': 'Por producto',
  'statistics.title.salesOrdersWithDetails': 'Pedidos con detalle',
  'statistics.title.salesOrdersWithoutDetails': 'Pedidos sin detalle',
  'statistics.title.opportunities': 'Oportunidades',
  'statistics.title.opportunitiesOnGoing': 'En Curso',
  'statistics.title.opportunitiesNotConvertedAndNotInSale': 'No convertidas en venta',
  'statistics.title.opportunitiesConvertedAndNotInSale': 'Convertidas en venta',
  'statistics.title.SellerMonthPercentage': 'Porcentaje cierre vendedor-mes',
  'statistics.title.opportunitiesActivitiesStartDate': 'Actividades por fecha inicio',
  'statistics.title.opportunitiesActivitiesEndDate': 'Actividades por fecha fin',
  'statistics.title.opportunitiesActivitiesInProgress': 'Actividades en proceso',
  'statistics.title.salesNumberOfDocuments': 'Número de documentos',
  'statistics.title.salesPerHour': 'Por horas',
  'statistics.title.salesPerDay': 'Por días',
  'statistics.title.salesByDealer': 'Por distribuidor',
  'statistics.title.salesByCarriers': 'Por despachador',
  'statistics.title.salesByCostCenter': 'Por centro de costos',
  'statistics.title.salesTicketsWithDetails': 'Servicio Técnico con detalle',
  'statistics.title.salesTicketsOpen': 'Servicio Técnico abiertos',
  'statistics.title.salesTicketsHolding': 'Servicio Técnico en espera',
  'statistics.title.salesTicketsDelayed': 'Servicio Técnico con retraso',

  'stockControl.chartName': 'Control de existencias',
  'stockControl.errorToast.read': 'Ha habido un error intentando leer el stock',
  'stockControl.generalData.calculateTransformations': 'Calcular transformaciones',
  'stockControl.generalData.valorationMethod': 'Valoración',
  'stockControl.tabTitle': 'Control de Existencias',
  'stockControl.warning.errorMessage': 'Ha habido un error genérico al leer el stock',
  'suppliers.tabTitle': 'Proveedores',
  'supplier.errorToast.read': 'Ha habido un error leyendo el proveedor',
  'supplier.successToast.save': 'El proveedor ha sido guardado correctamente',
  'supplier.errorToast.save': 'Ha habido un error guardando el proveedor',
  'supplier.successToast.delete': 'El proveedor ha sido eliminado correctamente',
  'supplier.errorToast.delete': 'Ha habido un error eliminando el proveedor',
  'supplier.errorToast.list': 'Ha habido un error obteniendo información del proveedor',
  'supplier.confirmWindow.subtitle': '¿Desea eliminar este "Proveedor" definitivamente?',
  'supplier.confirmWindow.title': 'Eliminar Proveedor',
  'supplierContacts.errorToast.list': 'Ha habido un error leyendo la lista de contactos',
  'supplierContacts.errorToast.read': 'Ha habido un error leyendo el contacto',
  'supplierContacts.errorToast.save': 'Ha habido un error guardando el contacto',
  'supplierContacts.successToast.save': 'El contacto ha sido guardado correctamente',
  'supplierContacts.warning.errorMessage':
    'Ha habido un error genérico en la operación con el contacto',
  'supplierContacts.successToast.delete': 'El contacto ha sido eliminado correctamente',
  'supplierContacts.errorToast.delete': 'Ha habido un error eliminando el contacto',
  'supplierContacts.grid.confirm.title': 'Eliminar contacto',
  'supplierContacts.grid.confirm.text': '¿Desea eliminar este contacto definitivamente?',
  'supplierContacts.grid.fullName': 'Contacto',
  'supplierContacts.grid.position': 'Cargo',
  'supplierContacts.grid.department': 'Departamento',
  'supplierContacts.grid.phone': 'Teléfono',
  'supplierContacts.grid.email': 'Email',
  'supplierContacts.grid.cellPhone': 'Celular',
  'supplierContacts.grid.sendInvoices': 'Enviar fra',
  'supplierContacts.grid.notes': 'Notas',
  'suppliers.headerData.name': 'Nombre',
  'suppliers.headerData.notes': 'Notas',
  'suppliers.generalData.header': 'Datos generales',
  'suppliers.generalData.legalName': 'Razón Social',
  'suppliers.generalData.VATId': 'RUT',
  'suppliers.generalData.address': 'Dirección',
  'suppliers.generalData.activityName': 'Giro',
  'suppliers.generalData.county': 'Comuna',
  'suppliers.generalData.city': 'Ciudad',
  'suppliers.generalData.zipCode': 'CP',
  'suppliers.generalData.country': 'País',
  'suppliers.generalData.schedule': 'Horario',
  'suppliers.generalData.phone': 'Teléfono',
  'suppliers.generalData.email': 'Email',
  'suppliers.generalData.DTEEmail': 'Email DTE',
  'suppliers.generalData.terms': 'Forma de pago',
  'suppliers.generalData.paymentDeadline': 'Plazo de pago',
  'suppliers.contabilityData.header': 'Contabilidad',
  'suppliers.contabilityData.account': 'Cuenta',
  'suppliers.contabilityData.accountExpenditure': 'Cuenta de gasto',
  'suppliers.contabilityData.accountAdvance': 'Cuenta de anticipos',
  'suppliers.contabilityData.expenditureDescription': 'Concepto de gastos',
  'suppliers.contabilityData.isSupermarket': 'Es un supermercado',
  'suppliers.EPaymentData.header': 'Pago electrónico',
  'suppliers.EPaymentData.bank': 'Banco',
  'suppliers.EPaymentData.ePaymentCode': 'Código',
  'suppliers.EPaymentData.bankAccount': 'Cuenta corriente',
  'suppliers.generalData': 'Datos generales',
  'suppliers.contacts': 'Contactos',
  'suppliers.map': 'Mapa',
  'suppliers.configurationOC': 'Configuración O/C',
  'suppliers.invoices': 'Facturas',
  'suppliers.invoicesDetail': 'Facturas detalle',
  'suppliers.pendingInvoices': 'Pendientes',
  'suppliers.pay': 'Pago',
  'suppliers.bankAccount': 'Cuenta corriente',
  'suppliers.purchaseOrders': 'Órdenes de compra',
  'suppliers.configuration.checkbox': 'Para este proveedor la Orden de Compra nunca es obligatoria',
  'suppliers.configuration.notes': 'Notas por defecto en la Orden de Compra',
  'suppliers.descriptionData.noAddress':
    'El proveedor no tiene establecida una dirección, verifique ese dato en Datos Generales y guarde la información',
  'supplier.warning.errorMessage': 'Ha habido un error genérico en la operación',
  //SALESTAXES
  'salesTaxes.behaviourData.addToVatinF29': 'Afecta IVA en F29',
  'salesTaxes.behaviourData.applyToTickets': 'Se aplica a Boletas también',
  'salesTaxes.behaviourData.correspondsTo': 'Corresponde a',
  'salesTaxes.behaviourData.title': 'Comportamiento',
  'salesTaxes.cancel': 'Cancelar',
  'salesTaxes.characteristicData.account': 'Cuenta',
  'salesTaxes.characteristicData.amountPerUnit': 'Valor por unidad',
  'salesTaxes.characteristicData.rate': 'Tasa',
  'salesTaxes.characteristicData.title': 'Características',
  'salesTaxes.characteristicData.wayToApply': 'Forma de aplicarlo',
  'salesTaxes.delete': 'Eliminar',
  'salesTaxes.delete.title': 'Eliminar impuesto',
  'salesTaxes.deleteMessage':
    'Está a punto de eliminar este impuesto definitivamente, ¿está seguro?',
  'salesTaxes.errorToast.delete': 'Ha habido un error borrando el impuesto',
  'salesTaxes.errorToast.read': 'Ha habido un error leyendo el impuesto',
  'salesTaxes.errorToast.save': 'Ha habido un error guardando el impuesto',
  'salesTaxes.generalData.taxName': 'Nombre',
  'salesTaxes.generalData.title': 'Datos generales',
  'salesTaxes.homeCard.searchWindow': 'Buscar Impuestos',
  'salesTaxes.successToast.delete': 'El impuesto ha sido eliminada correctamente',
  'salesTaxes.successToast.save': 'El impuesto ha sido guardado correctamente',
  'salesTaxes.tabTitle': 'Impuestos',
  'salesTaxes.warning.errorMessage': 'Ha habido un error genérico en la operación con el impuesto',
  'salesTaxes.errorToast.list': 'Ha habido un problema leyendo el listado de impuestos',
  'salesTaxes.confirmWindow.title': 'Eliminar Impuesto',
  'salesTaxes.confirmWindow.subtitle': '¿Desea eliminar este "Impuesto" definitivamente?',
  // STOCK TRANSFERS
  'stockTransfer.tabTitle': 'Traslados entre Bodegas',
  'stockTransfer.headerData.number': 'Número',
  'stockTransfer.headerData.warehouseFrom': 'Bodega desde',
  'stockTransfer.headerData.warehouseTo': 'Bodega hacia',
  'stockTransfer.headerData.date': 'Fecha',
  'stockTransfer.headerData.notes': 'Notas',
  'stockTransfer.confirmWindow.title': 'Eliminar traslado',
  'stockTransfer.confirmWindow.subtitle': '¿Desea eliminar este "Traslado" definitivamente?',
  'stockTransfer.errorToast.save': 'Ha habido un error guardando el traslado de stock',
  'stockTransfer.errorToast.read': 'Ha habido un error leyendo el traslado de stock',
  'stockTransfer.table.product': 'Producto',
  'stockTransfer.table.description': 'Descripción',
  'stockTransfer.table.quantity': 'Cantidad',
  'stockTransfer.table.lot': 'Lote',

  // TERMS
  'term.errorToast.list': 'Ha habido un error leyendo la lista de formas de fago',
  'term.errorToast.read': 'Ha habido un error leyendo la forma de pago',
  'term.errorToast.save': 'Ha habido un error guardando la forma de pago',
  'term.errorToast.duplicate': 'Ha habido un error copiando la forma de pago',
  'term.warning.errorMessage': 'Ha habido un error genérico en la operación de formas de pago',
  'terms.generalData.branchId': 'Sucursal',
  'term.successToast.delete': 'Forma de Pago ha sido eliminada correctamente',
  'term.successToast.save': 'Forma de pago ha sido guardada correctamente',
  'terms.homeCard.create': 'Crear Forma de Pago',
  'terms.homeCard.search': 'Buscar Forma de Pago',
  'terms.homeCard.searchWindow': 'Buscar Forma de Pago',
  'term.confirmWindow.subtitle': '¿Desea eliminar esta "Forma de Pago" definitivamente?',
  'term.confirmWindow.title': 'Eliminar Forma de Pago',
  'terms.tabTitle': 'Formas de Pago',
  'terms.data.generalData.name': 'Nombre',
  'terms.data.generalData.documentType': 'Documento utilizado',
  'terms.data.generalData.daysToExpiration': 'Días al Vencimiento',
  'terms.data.generalData.SIIType': 'Forma de pago SII',
  'terms.data.generalData.notes': 'Notas',
  'terms.grid.daysToExpiration': 'Dias al vencimiento',
  'terms.grid.percentage': 'Porcentaje de la deuda',

  'treeview.delete.customers':
    'Está a punto de eliminar esta familia de clientes definitivamente, ¿está seguro?',
  'treeview.delete.products':
    'Está a punto de eliminar esta familia de productos definitivamente, ¿está seguro?',
  'treeview.addModalTitle': 'Añadir familia',
  'treeview.addTitle': 'Añadir familia',
  'treeview.deleteModalTitle': 'Eliminar familia',
  'treeview.editModalTitle': 'Editar familia',
  'treeview.delete.remunerationConcepts':
    'Está a punto de eliminar esta estructura de la liquidación, ¿está seguro?',
  'users.accept': 'Aceptar',
  'users.cancel': 'Cancelar',
  'users.completeName': 'Nombre completo',
  'users.delete': 'Eliminar usuario',
  'users.deleteMessage': 'Está a punto de eliminar este usuario definitivamente, ¿está seguro?',
  'users.duplicate': 'Duplicar',
  'users.edit': 'Editar',
  'users.electronicInvoice': 'Factura electrónica',
  'users.email': 'Email',
  'users.erase': 'Eliminar',
  'users.errorToast.delete': 'Ha habido un error eliminando el usuario',
  'users.errorToast.fetch.loginName':
    'No se ha podido cargar los datos del usuario porque el login es inválido',
  'users.errorToast.fetch.userId':
    'No se ha podido cargar los datos del usuario porque no se ha recibido un userId',
  'users.errorToast.read': 'Ha habido un error leyendo el usuario',
  'users.errorToast.save': 'Ha habido un error guardando el usuario',
  'users.excel': 'Excel',
  'users.generalData': 'Datos generales',
  'users.generalData.completeName': 'Nombre completo',
  'users.generalData.discontinued': 'Discontinuado',
  'users.generalData.group': 'Grupo',
  'users.generalData.notes': 'Notas',
  'users.generalData.user': 'Usuario',
  'users.generalData.restrictToSalesman': 'Restringir al Vendedor',
  'users.generalData.restrictToBranch': 'Restringir a la Sucursal',
  'users.generalData.restrictToCostCenter': 'Restringir al C. Costo',
  'users.group': 'Grupo',
  'users.headerData.group': 'Usuario',
  'users.headerData.notes': 'Notas',
  'users.invoice.PFXCertificate': 'Certificado PFX',
  'users.invoice.PFXCertificateTooltip':
    'El Certificado PFX es el certificado del usuario en un formato que incluye la clave dentro del mismo.',
  'users.invoice.X500Certificate': 'Certificado X. 509',
  'users.invoice.X500CertificateTooltip': 'Certificado formato X. 509',
  'users.invoice.certificate': 'Certificado digital',
  'users.invoice.email': 'Email',
  'users.invoice.expirationDate': 'Fecha de caducidad',
  'users.invoice.invalidRutMessage': 'Por favor, ingresa un número de RUT válido',
  'users.invoice.keepInMind': 'Tenga en cuenta que',
  'users.invoice.keepInMindMessage':
    'Para editar estos valores (salvo el RUT), debe estar habilitada la empresa para emisión de Facturas Electrónicas en Laudus.',
  'users.invoice.whatIsPFX': '¿Qué es el certificado PFX?',
  'users.invoice.whatIsPFXMessage':
    'El Certificado Digital es un archivo que permite identificar a una persona por medios electrónicos. Lo emite una <link>Autoridad Certificadora</link>, a quien debe solicitarlo. Laudus utiliza el Certificado para firmar digitalmente las Facturas Electrónicas, asegurando su integridad, requisito previo al envío al SII.',
  'users.invoice.rut': 'RUT',
  'users.invoice.showContent': 'Mostrar contenido',
  'users.invoice.updateCertificate': 'Actualizar certificado',
  'users.invoice.watchVideo': 'Ver video',
  'users.invoice.title': 'Certificado PFX',
  'users.invoice.password': 'Password',
  'users.invoice.expiration': 'El certificado expira el:',
  'users.invoice.issuedAt': 'Emitido el:',
  'users.invoice.subject': 'Asunto:',
  'users.invoice.passTitle': 'Ingrese la Contraseña del Certificado',
  'users.new': 'Nuevo',
  'users.notes': 'Notas',
  'users.preview': 'Vista previa',
  'users.print': 'Imprimir',
  'users.saveAndCreate': 'Guardar y crear nuevo',
  'users.search': 'Buscar',
  'users.searchUser': 'Buscar usuario',
  'users.security.blockedAccount': 'Cuenta bloqueada',
  'users.security.blocking': 'Bloqueo',
  'users.security.minLength': 'Longitud mínima',
  'users.security.numberOfAttempts': 'Nº de intentos antes de bloqueo',
  'users.security.password': 'Contraseña',
  'users.security.save': 'Usuario',
  'users.security.verifyPassword': 'Verificar contraseña',
  'users.securityAndPassword': 'Seguridad y contras.',
  'users.successToast.delete': 'El usuario ha sido eliminado correctamente',
  'users.successToast.save': 'El usuario ha sido guardado correctamente',
  'users.warning.errorMessage': 'Ha habido un error genérico en la operación con los usuarios',
  'users.tabTitle': 'Usuarios',
  'users.user': 'Usuario',
  'users.confirmWindow.subtitle': '¿Desea eliminar este "Usuario" definitivamente?',
  'users.confirmWindow.title': 'Eliminar Usuario',
  //VIDEO MODAL
  'videoModal.title.viewVideo': 'Cómo usar Laudus',
  'videoModal.cancel': 'Cancelar',
  'videoModal.close': 'Cerrar',
  'wagesParameters.errorToast.list':
    'Ha habido un error leyendo la lista de parámetros salariales.',
  'wagesParameters.warning.errorMessage':
    'Ha habido un error genérico en la operación de parámetros salariales',
  'remunerationConceptTypes.errorToast.list':
    'Ha habido un error leyendo la lista de tipos de conceptos de sueldo.',
  'remunerationConceptTypes.errorToast.save':
    'Ha habido un error actualizando el tipo de concepto de sueldo.',
  'remunerationConceptType.infoMessage':
    'En la <em>columna de la izquierda</em> están los <strong>tipos de conceptos definidos internamente</strong>, y en la <em>columna de la derecha</em> usted tiene que definir a qué <strong>concepto de sueldo suyo</strong> corresponde (estos los podrá encontrar definidos en la pantalla "',
  'remunerationConceptType.linkText': 'Estructura de la liquidación',
  'remunerationConceptTypes.warning.errorMessage':
    'Ha habido un error genérico en la operación de tipos de conceptos de sueldo',
  //WAREHOUSE
  'warehouse.address.address': 'Dirección',
  'warehouse.address.city': 'Ciudad',
  'warehouse.address.county': 'Comuna',
  'warehouse.address.state': 'Región',
  'warehouse.address.title': 'Dirección',
  'warehouse.errorToast.delete': 'Ha habido un error eliminando la bodega',
  'warehouse.errorToast.read': 'Ha habido un error leyendo la bodega',
  'warehouse.errorToast.save': 'Ha habido un error guardando la bodega',
  'warehouse.errorToast.list': 'Ha habido un problema leyendo el listado de bodegas',
  'warehouse.warning.errorMessage': 'Ha habido un error genérico en la operación con la bodega',
  'warehouse.generalData.name': 'Nombre',
  'warehouse.generalData.title': 'Datos generales',
  'warehouse.notes.title': 'Notas',
  'warehouse.successToast.delete': 'La bodega ha sido eliminada correctamente',
  'warehouse.successToast.save': 'La bodega ha sido guardada correctamente',
  'warehouse.schedule.schedule': 'Horario',
  'warehouse.schedule.title': 'Horario',
  'warehouse.tabTitle': 'Bodegas',
  'warehouse.confirmWindow.title': 'Eliminar Bodega',
  'warehouse.confirmWindow.subtitle': '¿Desea eliminar esta "Bodega" definitivamente?',
  // 'form.error.required': 'Este campo es obligatorio'
  'sidebar.colormode': 'MODO DE COLOR',

  // Shifts
  'shifts.tabTitle': 'Turnos',
  'shifts.headerTable.date': 'Fecha',
  'shifts.headerTable.open': 'Apertura',
  'shifts.headerTable.close': 'Cierre',
  'shifts.headerTable.all.sellers': 'Vendedor/a',
  'shifts.headerTable.start.balance': 'Saldo inicial',
  'shifts.headerTable.end.balance': 'Saldo final',
  'shifts.headerTable.notes': 'Notas',
  'shifts.today': 'Hoy {date}',
  'shifts.dateFrom': 'Fecha desde: {dateFrom} {time}',
  'shifts.dateTo': 'Fecha hasta: {dateTo} {time}',
  'shifts.receiptType': 'Forma de pago',
  'shifts.initialCashBalance': 'Efectivo al abrir',
  'shifts.sales': 'Ingresos ventas',
  'shifts.withdrawals': 'Egresos caja',
  'shifts.incomes': 'Ingresos caja',
  'shifts.theoricalBalance': 'Saldo teórico',
  'shifts.closingCashBalance': 'Efectivo al cierre',
  'shifts.difference': 'Diferencia',
  'shifts.dataEmpty': 'No hay datos para mostrar',
  'shifts.totals': 'Totales',

  // BARCODE
  'barcode.error.notFound': 'No se ha encontrado el código de barras: {barcode}',

  // TRANSBANK
  'sidebar.transbank.settings': 'Operaciones Terminal Transbank',
  'settings.transbank.title': 'Operaciones Terminal Transbank',
  'transbank.agent.connection.error': 'No se ha podido conectar con el agente',
  'transbank.agent.connection.lost': 'Se ha perdido la conexión con el dispositivo de Transbank',
  'transbank.agent.not.opened': 'El Agente de Transbank no está abierto',
  'transbank.device.connecting': 'Intentando establer la conexión con el dispositivo...',
  'transbank.device.connecting.title': 'Conectando',
  'transbank.device.not.connected': 'No se ha podido conectar al POS',
  'transbank.device.operated': 'Solicite al cliente que opere el dispositivo de Transbank',
  'transbank.transaction.ok': 'La transacción ha sido aprobada.',
  'transbank.transaction.error': 'La transacción no ha sido aprobada. Puede reintentar el pago.',
  'transbank.transaction.error.generic':
    'No existe comunicación con dispositivo de Transbank, por favor compruebe que está conectado y encendido',
  'transbank.modal.continue': 'Continuar',

  'transbank.agent.status': 'Estado del agente:',
  'transbank.status.connected': 'Conectado',
  'transbank.status.disconnected': 'No conectado',

  'transbank.device.status': 'Estado del dispositivo:',

  'transbank.dailysales.title': 'Ventas del día',
  'transbank.dailysales.datetime': 'FECHA Y HORA',
  'transbank.dailysales.amount': 'IMPORTE',
  'transbank.dailysales.card.numbers': '4 ÚLTIMOS DÍGITOS TARJETA',
  'transbank.dailysales.card.type': 'TIPO TARJETA',
  'transbank.dailysales.card.authorization.code': 'CÓDIGO AUTORIZACIÓN',
  'transbank.dailysales.card.operation.code': 'N˚ OPERACIÓN',
  'transbank.dailysales.card.brand': 'MARCA TARJETA',
  'transbank.dailysales.total': 'Total',

  'transbank.action.completed': 'Completada',
  'transbank.action.error': 'Error',
  'transbank.action.reading': 'Leyendo',
  'transbank.action.no': 'NO',
  'transbank.action.yes': 'SI',
  'transbank.action.nodata': 'Sin datos',

  // TRANSBANK CLOSEDAY
  'transbank.closeday.title': 'Cerrar día',
  'transbank.closeday.description':
    'Marque el día de hoy como finalizado. Podrá imprimir un resumen del día si lo desea.',
  'transbank.closeday.message.loading': 'Confirme en el Terminal si desea imprimir el resumen.',
  'transbank.closeday.message.success': 'El resumen del día se ha impreso correctamente.',
  'transbank.closeday.message.error': 'Ha ocurrido un error al imprimir el resumen del día.',

  // TRANSBANK DAILYSALES
  'transbank.dailySales.title': 'Obtener ventas del día',
  'transbank.dailySales.description':
    'Visualice todos los datos de ventas del día de hoy. Podrá imprimirlos si lo desea.',
  'transbank.dailySales.message.success':
    'Se han impresos los datos de ventas del día correctamente.',
  'transbank.dailySales.message.error':
    'Ha ocurrido un error al imprimir los datos de ventas del día.',
  'transbank.dailySales.message.loading': 'Obteniendo ventas del día desde Transbank POS...',
  'transbank.dailySales.empty.data': 'No existen datos de ventas del día.',

  // TRANSBANK LASTSALE
  'transbank.lastSale.title': 'Recuperar última venta',
  'transbank.lastSale.description':
    'Imprima el comprobante de la última venta que haya realizado en el día de hoy.',
  'transbank.lastSale.message.success': 'La última venta ha sido impresa correctamente.',
  'transbank.lastSale.message.error': 'No se ha podido imprimir la última venta.',
  'transbank.lastSale.message.loading': 'Imprimiendo última venta...',

  // TRANSBANK TOTALS
  'transbank.totals.title': 'Obtener totales',
  'transbank.totals.description':
    'Revise y/o Imprima un resumen con todos los datos totales que tenga registrados.',
  'transbank.totals.message.success': 'Los totales han sido impresos correctamente.',
  'transbank.totals.message.error': 'No se han podido imprimir los totales.',
  'transbank.totals.message.loading': 'Imprimiendo totales...',

  // TRANSBANK LOADKEYS
  'transbank.loadKeys.title': 'Cargar llaves',
  'transbank.loadKeys.description':
    'Vuelva a cargar los certificados y compruebe que se han registrado correctamente.',
  'transbank.loadKeys.message.success': 'Los certificados se han cargado correctamente.',
  'transbank.loadKeys.message.error': 'Los certificados no se han cargado.',
  'transbank.loadKeys.message.loading': 'Cargando certificados...',

  // TRANSBANK POLL
  'transbank.poll.title': 'Comprobar estado',
  'transbank.poll.description':
    'Compruebe que la conexión con el dispositivo está establecida correctamente.',
  'transbank.poll.message.success': 'La conexión está establecida correctamente.',
  'transbank.poll.message.error': 'La conexión no está establecida.',

  // TRANSBANK REFUND
  'transbank.refund.title': 'Anular operación',
  'transbank.refund.description': 'Podrá anular fácilmente cualquier venta que haya realizado.',
  'transbank.refund.message.success': 'La operación se ha anulado correctamente.',
  'transbank.refund.message.error': 'Ha ocurrido un error intentando anular la operación.',
  'transbank.refund.message.loading': 'Opere el dispositivo POS para anular la operación.',
  'transbank.refund.form.message': 'Introduzca el Código de la operación que desea anular.',
  'transbank.refund.input.placeholder': 'Número de Operación',

  // TRANSBANK SET NORMAL MODE
  'transbank.setNormalMode.title': 'Salir del modo integrado',
  'transbank.setNormalMode.description':
    'Desconecte el terminal Transbank de la app y pase a configurar desde ahí.',
  'transbank.setNormalMode.message.success': 'Se ha cambiado al modo stand alone correctamente.',
  'transbank.setNormalMode.message.error':
    'Ha ocurrido un error intentando cambiar al modo stand alone.',
  'transbank.setNormalMode.message.confirmation':
    '¿Está seguro de que desea desconectar el terminal Transbank de la aplicación?',

  // TRANSBANK MESSAGES
  'transbank.message.approved': 'Aprobado',
  'transbank.message.rejected': 'La operación fue rechazada, intente nuevamente',
  'transbank.message.host.not.response': 'El Host no Responde, intente nuevamente',
  'transbank.message.connection.failed': 'La Conexión Falló, intente nuevamente',
  'transbank.message.transaction.already.cancelled': 'La Transacción ya fue Anulada',
  'transbank.message.transaction.not.found': 'El número de la transacción no existe, no se anulará',
  'transbank.message.card.not.supported': 'La Tarjeta no es soportada',
  'transbank.message.transaction.cancelled.from.pos':
    'Transacción Cancelada desde el POS. Reintente transacción',
  'transbank.message.debit.transaction.cannot.be.cancelled':
    'No puede anular transacción realizada con Tarjeta de Débito',
  'transbank.message.card.reading.error': 'Error de lectura de tarjeta',
  'transbank.message.amount.lower.than.minimum.allowed': 'Monto menor al mínimo permitido',
  'transbank.message.sale.not.found': 'No existe venta',
  'transbank.message.transaction.not.supported': 'Transacción no soportada',
  'transbank.message.must.execute.closing': 'Debe ejecutar cierre',
  'transbank.message.no.tone': 'No hay tono',
  'transbank.message.bitmap.dat.file.not.found':
    'Archivo BITMAP.DAT no encontrado. Por favor carguelo',
  'transbank.message.host.response.format.error': 'Error formato de respuesta del HOST',
  'transbank.message.last.4.digits.error': 'Error en los 4 últimos dígitos',
  'transbank.message.invalid.menu': 'Menú inválido',
  'transbank.message.error_tarj_dist': 'ERROR_TARJ_DIST',
  'transbank.message.invalid.card': 'Tarjeta inválida',
  'transbank.message.cancellation.not.permitted': 'La Anulación no fue permitida',
  'transbank.message.timeout': 'TIMEOUT',
  'transbank.message.printer.out.of.paper': 'Impresora sin papel',
  'transbank.message.invalid.date': 'Fecha inválida',
  'transbank.message.must.load.keys': 'Debe cargar llaves',
  'transbank.message.must.update': 'Debe actualizar',
  'transbank.message.number.of.installments.error': 'Error en Número de Cuotas',
  'transbank.message.request.building.error': 'Error en Armado de Solicitud',
  'transbank.message.internal.pinpad.problem': 'Problema con el Pinpad interno',
  'transbank.message.host.response.processing.error': 'Error al Procesar la Respuesta del Host',
  'transbank.message.exceeded.maximum.number.of.sales':
    'Superó Número Máximo de Ventas, Debe Ejecutar Cierre',
  'transbank.message.generic.error': 'Error Genérico, Falla al Ingresar Montos',
  'transbank.message.receipt.field.format.error': 'Error de formato Campo de Boleta MAX 6',
  'transbank.message.print.field.length.error': 'Error de Largo Campo de Impresión',
  'transbank.message.sale.amount.error': 'Error de Monto Venta, Debe ser Mayor que 0',
  'transbank.message.terminal.id.not.configured': 'Terminal ID no configurado',
  'transbank.message.merchant.has.no.cards.configured': 'Comercio no tiene Tarjetas Configuradas',
  'transbank.message.waiting.for.card.reading': 'Esperando Leer Tarjeta',
  'transbank.message.confirming.amount': 'Solicitando Confirmar Monto',
  'transbank.message.requesting.pin.entry': 'Solicitando Ingreso de Clave',
  'transbank.message.sending.transaction.to.host': 'Enviando transacción al Host',
  'transbank.message.installment.amount.error': 'Error Cantidad Cuotas',
  'transbank.message.declined': 'Declinada',
  'transbank.message.response.processing.error': 'Error al Procesar Respuesta',
  'transbank.message.print.rate.error': 'Error al Imprimir TASA',
  'transbank.message.requesting.dues': 'Seleccione la cantidad de cuotas',
  'transbank.title.requesting.dues': 'Cuotas',
  'transbank.title.requesting.pin.card': 'PIN',
  'transbank.title.sending.transaction': 'Enviando',
  'transbank.connection.error': 'Error de conexión',
  'transbank.connection.error.message':
    'Ha ocurrido un error inesperado intentando conectarse con el dispositivo de Transbank. Por favor, intente nuevamente.',
  'transbank.connection.listPorts': 'Listar Puertos',
  'transbank.connection.discoverAndConnect': 'Descubrir y Conectar',
  'transbank.device.closingDay.title': 'Cierre de día',
  'transbank.device.closingDay.text':
    'Utilice el dispositivo de Transbank para realizar el cierre de día.',

  // catalog
  'nav.save': 'Guardar',
  'catalog.title': 'Editar catálogo',

  // sale order template
  'saleOrder.template.order.detail': 'DETALLE DEL PEDIDO',
  'saleOrder.template.customer': 'Cliente',
  'saleOrder.template.VATId': 'RUT',
  'saleOrder.template.legalName': 'Razón Social',
  'saleOrder.template.code': 'Código',
  'saleOrder.template.product': 'Producto',
  'saleOrder.template.discount': 'Descuento',
  'saleOrder.template.quantity': 'Cantidad',
  'saleOrder.template.unitPrice': 'Precio UD',
  'saleOrder.template.order': 'Pedido',
  'saleOrder.template.total': 'Total',
  'saleOrder.template.net': 'Neto',
  'saleOrder.template.VAT': 'IVA',
  'saleOrder.template.order.number': 'PEDIDO Nº {orderNumber}',
  'saleOrder.template.dto': 'Dto: {itemDiscount}',

  // statistics headers
  'firstSale': 'Primera venta',
  'lastSale': 'Última venta',
  'daysSinceLastSale': 'Días desde la última venta',
  'activeDays': 'Días activos',
  'numberOfInvoices': 'Número de facturas',
  'dealerName': 'Vendedor',

  // recipes
  'recipes.tabTitle': 'Recetas',
  'recipes.errorToast.list': 'Ha habido un error leyendo la lista de recetas',
  'recipes.warning.errorMessage': 'Ha habido un error genérico al obtener la lista de recetas',
  'recipes.errorToast.read': 'Ha habido un error leyendo la receta',
  'home.homeCard.recipes.search': 'Buscar Receta',
  'home.homeCard.recipes.create': 'Crear Receta',
  'home.homeCard.recipes.searchWindow': 'Buscar Receta',
  'recipes.generalData.name': 'Nombre',
  'recipes.generalData.description': 'Descripción',
  'recipes.generalData.discontinued': 'Discontinuado',
  'recipes.generalData.notes': 'Notas',
  'recipes.table.product': 'Producto',
  'recipes.table.description': 'Descripción',
  'recipes.table.type': 'Tipo',
  'recipes.table.costPercentage': '% Costo',
  'recipes.errorToast.save': 'Ha ocurrido un error intentando crear la Receta',
  'recipes.confirmWindow.title': 'Eliminar Receta',
  'recipes.confirmWindow.subtitle': '¿Desea eliminar esta "Receta" definitivamente?',
  'recipes.errorToast.delete': 'Ha ocurrido un error intentando eliminar la Receta',
  'recipes.searchGrid.recipeId': 'Número',
  'recipes.searchGrid.name': 'Nombre',
  'recipes.searchGrid.description': 'Descripción',
  'recipes.grid.product': 'Producto',
  'recipes.grid.description': 'Descripción',
  'recipes.grid.type': 'Tipo',
  'recipes.grid.costPercentage': '% Costo',
  'recipes.grid.quantity': 'Cantidad',
  'recipes.selectField.input': 'Entrada',
  'recipes.selectField.output': 'Salida',

  // recurringSalesInvoices
  'recurringSalesInvoices.tabTitle': 'Facturas recurrentes',
  'recurringSalesInvoices.errorToast.list':
    'Ha habido un error leyendo la lista de facturas recurrentes',
  'recurringSalesInvoices.warning.errorMessage':
    'Ha habido un error genérico relacionado con las facturas recurrentes',
  'recurringSalesInvoices.errorToast.read': 'Ha habido un error leyendo la factura recurrente',
  'recurringSalesInvoices.errorToast.save':
    'Ha ocurrido un error intentando crear la Factura Recurrente',
  'recurringSalesInvoices.errorToast.delete':
    'Ha ocurrido un error intentando eliminar la Factura Recurrente',

  'home.homeCard.recurringSalesInvoices.search': 'Buscar Factura Recurrente',
  'home.homeCard.recurringSalesInvoices.create': 'Crear Factura Recurrente',
  'home.homeCard.recurringSalesInvoices.searchWindow': 'Buscar Factura Recurrente',

  'recurringSalesInvoices.description': 'Descripción',
  'recurringSalesInvoices.category': 'Categoría',
  'recurringSalesInvoices.discontinued': 'Discontinuada',
  'recurringSalesInvoices.customer': 'Cliente',
  'recurringSalesInvoices.contact': 'Contacto',
  'recurringSalesInvoices.salesman': 'Vendedor',
  'recurringSalesInvoices.dealer': 'Distribuidor',
  'recurringSalesInvoices.term': 'Forma de pago',
  'recurringSalesInvoices.costCenter': 'Centro de Costo',
  'recurringSalesInvoices.warehouse': 'Bodega',
  'recurringSalesInvoices.account': 'Cuenta',
  'recurringSalesInvoices.branch': 'Sucursal',
  'recurringSalesInvoices.notes': 'Notas',
  'recurringSalesInvoices.dateFrom': 'Comenzar en la fecha',
  'recurringSalesInvoices.repetition.pattern.type': 'Aplicar con frecuencia',
  'recurringSalesInvoices.repetition.pattern.type.weekly': 'Semanal',
  'recurringSalesInvoices.repetition.pattern.type.monthly': 'Mensual',
  'recurringSalesInvoices.repetition.pattern.type.yearly': 'Anual',
  'recurringSalesInvoices.repetition.pattern.to': 'Hasta',
  'recurringSalesInvoices.repetition.pattern.to.always': 'Siempre',
  'recurringSalesInvoices.repetition.pattern.to.date': 'La fecha',
  'recurringSalesInvoices.repetition.pattern.to.times': 'Un nº de veces',
  'recurringSalesInvoices.repetition.pattern.interval': 'Cada',
  'recurringSalesInvoices.repetition.pattern.interval.W': 'semanas',
  'recurringSalesInvoices.repetition.pattern.interval.M': 'meses',
  'recurringSalesInvoices.repetition.pattern.interval.Y': 'años',
  'recurringSalesInvoices.repetition.pattern.values.W': 'Los días',
  'recurringSalesInvoices.repetition.pattern.values.M': 'Los días',
  'recurringSalesInvoices.repetition.pattern.values.Y': 'El día',
  'recurringSalesInvoices.repetition.pattern.values.lastDayOfMonth': 'o último día del mes',
  'recurringSalesInvoices.repetition.pattern.values.yearly.of': 'de',

  'recurringSalesInvoices.confirmWindow.title': 'Eliminar Factura Recurrente',
  'recurringSalesInvoices.confirmWindow.subtitle':
    '¿Desea eliminar esta "Factura Recurrente" definitivamente?',
  'recurringSalesInvoices.searchGrid.recurringSalesInvoiceId': 'Número',
  'recurringSalesInvoices.searchGrid.description': 'Descripción',
  'recurringSalesInvoices.searchGrid.customer.name': 'Cliente',

  // recurringSalesInvoicesItems
  'recurringSalesInvoicesItems.table.product': 'Producto',
  'recurringSalesInvoicesItems.table.description': 'Descripción',
  'recurringSalesInvoicesItems.table.quantity': 'Cantidad',
  'recurringSalesInvoicesItems.table.originalUnitPrice': 'Precio/Ud',
  'recurringSalesInvoicesItems.table.currencyCode': 'Moneda',
  'recurringSalesInvoicesItems.table.discountPercentage': 'Dto.',
  'recurringSalesInvoicesItems.table.total': 'Total',
  'recurringSalesInvoicesItems.table.costCenter': 'C. Costo',
  'recurringSalesInvoicesItems.table.account': 'Cuenta',

  // stages
  'stages.tabTitle': 'Etapas del flujo productivo',
  'stages.errorToast.list': 'Ha habido un error leyendo la lista de Etapas del flujo productivo',
  'stages.warning.errorMessage':
    'Ha habido un error genérico al obtener la lista de Etapas del flujo productivo',
  'stages.errorToast.read': 'Ha habido un error leyendo la Etapa del flujo productivo',
  'home.homeCard.stages.search': 'Buscar Etapa del flujo productivo',
  'home.homeCard.stages.create': 'Crear Etapa del flujo productivo',
  'home.homeCard.stages.searchWindow': 'Buscar Etapa del flujo productivo',
  'stages.errorToast.save': 'Ha ocurrido un error intentando crear la Etapa del flujo productivo',
  'stages.confirmWindow.title': 'Eliminar Etapa del flujo productivo',
  'stages.confirmWindow.subtitle':
    '¿Desea eliminar esta "Etapa del flujo productivo" definitivamente?',
  'stages.errorToast.delete':
    'Ha ocurrido un error intentando eliminar la Etapa del flujo productivo',
  'stages.searchGrid.name': 'Nombre',
  'stages.searchGrid.description': 'Descripción',
  'stages.generalData.title': 'Datos generales',
  'stages.generalData.name': 'Nombre',
  'stages.generalData.description': 'Descripción',
  'stages.generalData.order': 'Orden',
  'stages.generalData.process': 'Receta',

  // processes
  'processes.tabTitle': 'Transformaciones',
  'processes.errorToast.list': 'Ha habido un error leyendo la lista de Transformaciones',
  'processes.warning.errorMessage':
    'Ha habido un error genérico al obtener la lista de Transformaciones',
  'processes.errorToast.read': 'Ha habido un error leyendo la Transformación',
  'home.homeCard.processes.search': 'Buscar Transformaciones',
  'home.homeCard.processes.create': 'Crear Transformación',
  'home.homeCard.processes.searchWindow': 'Buscar Transformación',
  'processes.errorToast.save': 'Ha ocurrido un error intentando crear la Transformación',
  'processes.confirmWindow.title': 'Eliminar Transformación',
  'processes.confirmWindow.subtitle': '¿Desea eliminar esta "Transformación" definitivamente?',
  'processes.errorToast.delete': 'Ha ocurrido un error intentando eliminar la Transformación',
  'processes.searchGrid.id': 'Número',
  'processes.searchGrid.createdAt': 'Fecha',
  'processes.searchGrid.process': 'Proceso',
  'processes.searchGrid.notes': 'Notas',
  'processes.headerData.number': 'Número',
  'processes.headerData.salesOrder': 'Pedido',
  'processes.headerData.process': 'Proceso',
  'processes.headerData.recipe': 'Receta',
  'processes.headerData.manHours': 'Horas / Hom.',
  'processes.headerData.notes': 'Notas',
  'processes.tab.generalCard': 'Datos generales',
  'processes.tab.stages': 'Flujo',
  'processes.generalData.input': 'Entradas al proceso',
  'processes.generalData.output': 'Salidas del proceso',
  'processes.date': 'Fecha',
  'processes.warehouse': 'Bodega',
  'processes.io.product': 'Producto',
  'processes.io.description': 'Descripción',
  'processes.io.quantity': 'Cantidad',
  'processes.io.unity': 'Uds.',
  'processes.io.unitCost': 'Costo Unitario',
  'processes.io.cost': 'Costo',
  'processes.io.lot': 'Lote',

  'processes.stages.grid.productionStageName': 'Etapa',
  'processes.stages.grid.startDate': 'Comienzo',
  'processes.stages.grid.endDate': 'Término',
  'processes.stages.grid.dueDate': 'Previsto',
  'processes.stages.grid.realDuration': 'Duración real',
  'lib.days': `{days, plural,
    =0 {}
    one {1 día}
    other {{days} días}
  }`,

  // mixed payment
  'ticketMixedPayment.title': 'Pago mixto',
  'ticketMixedPayment.pay': 'Pagar',
  'ticketMixedPayment.cash.placeholder': 'Cantidad a pagar en efectivo',
  'ticketMixedPayment.required': 'Debe ingresar al menos un metodo de pago',
  'ticketMixedPayment.validate.check': 'Numero de cheque o monto no puede estar vacio',
  'ticketMixedPayment.error': 'Error en Pago mixto',
  'ticketMixedPayment.validate.only.one.added':
    'Solo se ha agregado un metodo de pago, debe agregar al menos dos. De lo contrario, use el metodo de pago simple',
  'ticketPayTerms.cash.rest': 'Resto',
  'ticketPayTerms.check.number': 'N° de cheque',

  // opportunities
  'opportunities.tabTitle': 'Oportunidades',
  'opportunities.errorToast.list': 'Ha habido un error leyendo la lista de Oportunidades',
  'opportunities.warning.errorMessage':
    'Ha habido un error genérico al obtener la lista de Oportunidades',
  'opportunities.errorToast.read': 'Ha habido un error leyendo la Oportunidad',
  'home.homeCard.opportunities.search': 'Buscar Oportunidades',
  'home.homeCard.opportunities.create': 'Crear Oportunidad',
  'home.homeCard.opportunities.searchWindow': 'Buscar Oportunidad',
  'opportunities.errorToast.save': 'Ha ocurrido un error intentando crear la Oportunidad',
  'opportunities.confirmWindow.title': 'Eliminar Oportunidad',
  'opportunities.confirmWindow.subtitle': '¿Desea eliminar esta "Oportunidad" definitivamente?',
  'opportunities.errorToast.delete': 'Ha ocurrido un error intentando eliminar la Oportunidad',
  'opportunities.searchGrid.id': 'Número',
  'opportunities.searchGrid.description': 'Nombre',
  'opportunities.searchGrid.date': 'Fecha',
  'opportunities.searchGrid.notes': 'Notas',
  'opportunities.searchGrid.stageDescription': 'Estado',
  'opportunities.searchGrid.customerName': 'Cliente',
  'opportunities.searchGrid.salesmanName': 'Vendedor',

  'opportunities.tab.generalCard': 'Datos generales',
  'opportunities.tab.activities': 'Actividades',
  'opportunities.tab.products': 'Productos',
  'opportunities.tab.map': 'Mapa',

  'opportunities.headerData.number': 'Número',
  'opportunities.headerData.name': 'Nombre',

  'opportunities.generalData.customer': 'Cliente',
  'opportunities.generalData.salesman': 'Vendedor',
  'opportunities.generalData.source': 'Origen',

  'opportunities.generalData.contact': 'Contacto',
  'opportunities.generalData.dealer': 'Distribuidor',
  'opportunities.generalData.stage': 'Estado',

  'opportunities.generalData.date': 'Fecha',
  'opportunities.generalData.closingDate': 'Cierre estimado',
  'opportunities.generalData.probability': 'Probabilidad (%)',

  'opportunities.generalData.description': 'Descripción',
  'opportunities.generalData.originalAmount': 'Importe',
  'opportunities.generalData.currencyCode': 'Moneda',
  'opportunities.generalData.amount': 'Importe en $',
  'opportunities.generalData.parityToMainCurrency': 'T/C',
  'opportunities.generalData.nextAction.title': 'Próxima acción',
  'opportunities.generalData.notes': 'Notas',

  'opportunities.contacts.searchGrid.id': 'Número',
  'opportunities.contacts.searchGrid.name': 'Nombre y Apellido',
  'opportunities.contacts.searchGrid.email': 'Email',

  'opportunities.activities.grid.subject': 'Asunto',
  'opportunities.activities.grid.issuedDate': 'Inicio',
  'opportunities.activities.grid.dueDate': 'Fin',
  'opportunities.activities.grid.assignedTo.name': 'Asginado a',
  'opportunities.activities.grid.status': 'Estado',
  'opportunities.activities.subject': 'Asunto',
  'opportunities.activities.contact': 'Contacto',
  'opportunities.activities.assignedTo': 'Asignado a',
  'opportunities.activities.issuedDate': 'Fecha de inicio',
  'opportunities.activities.dueDate': 'Fecha fin',
  'opportunities.activities.status': 'Estado',

  'opportunities.products.grid.salesQuotesId': 'N° de Cotización',
  'opportunities.products.grid.date': 'Fecha',
  'opportunities.products.grid.searchQuote': 'Buscar cotización',

  // settings payroll
  'options.tabs.payroll': 'Liquidaciones',
  // workersCompensationInsuranceCompany
  'options.tabs.workersCompensationInsuranceCompany.title': 'Mutual',
  'options.workersCompensationInsuranceCompany': 'Mutual',
  'options.workersCompensationInsuranceCompany.number': 'N° Adherente',
  'options.workersCompensationInsuranceCompany.rate': 'Tasa',
  'options.workersCompensationInsuranceCompany.description':
    'Escoja una de las instituciones privadas creadas en “Personal / Instituciones Previsionales / Mutual de Seguridad”, de cotizar en el ISL deje el campo en blanco. Independiente de lo anterior, debe digitar la tasa total a pagar por Seguro contra accidentes del trabajo y Ley SANNA.',
  // CCAF
  'options.tabs.ccaf.title': 'CCAF',
  'options.ccaf': 'CCAF',
  'options.ccaf.description':
    'Escoja un concepto solo en caso que la empresa este adherida a alguna institución. De quedar en blanco el respectivo porcentaje es recaudado por el IPS.',
  // covidInsuranceCompany
  'options.tabs.covidInsuranceCompany.title': 'Seguro Covid',
  'options.tabs.covidInsuranceCompany.options': 'Seguro Covid',
  'options.covidInsuranceCompany.description':
    'Seguro con cargo al empleador, y que se escoge solo en caso que se cancele dentro de las cotizaciones previsionales mensuales. Las instituciones en el desplegable son las que se pueden cancelar a través de la plataforma de Previred.',
  // payrollAccounting
  'options.tabs.PayrollAccountingCard.title': 'Centralización',
  'options.tabs.payrollAccountingDoNotSplitCreditsByCostCenter':
    'No separar Descuentos por Centro de Costo',
  'options.tabs.payrollAccountingDoNotSplitCreditsByCostCenter.description':
    'Si al generar el comprobante de centralización de remuneraciones desea separar los descuentos por centro de costos.',
  'options.tabs.payrollAccountingSplitNetToPayByEmployee': 'Separar Líquido por RUT',
  'options.tabs.payrollAccountingSplitNetToPayByEmployee.description':
    'Por defecto Laudus centraliza los líquidos a pago en un gran total, si habilita esta función se abrirá para indicar el total a pagar por cada trabajador con su respectivo RUT, nombre y período, útil para efectos de análisis de cuenta.',
  // payrollOrderScreen
  'options.tabs.order.title': 'Orden',
  'options.tabs.order.display': 'Pantalla',
  'options.tabs.order.display.description':
    'Escoja el orden en el que se muestran los trabajadores en la pantalla liquidaciones.',
  'options.tabs.order.printed': 'Impresas',
  'options.tabs.order.printed.description':
    'Escoja el orden en el que desea imprimir las liquidaciones de sueldo.',
  'options.payrollOrderScreen.firstName': 'Nombre',
  'options.payrollOrderScreen.lastName': 'Apellido',
  'options.payrollOrderScreen.costCenter': 'Centro de Costos',
  'options.payrollOrderPrinter.nombre': 'Nombres',
  'options.payrollOrderPrinter.apellido1': 'Apellido',
  'options.payrollOrderPrinter.RUT': 'RUT',
  'options.payrollOrderPrinter.cargo': 'Cargo',
  'options.payrollOrderPrinter.idCCoste': 'Centro de Costo',
  'options.payrollOrderPrinter.departamento': 'Departamento',

  // Vacaciones
  'options.tabs.holidays.title': 'Vacaciones',
  'options.tabs.holidays.days': 'Días legales devengados anuales',
  'options.tabs.holidays.days.description':
    'Son los días hábiles normales devengados al año por concepto de vacaciones que afectan a toda la empresa, y que dependiendo de la Región donde se presta servicios pueden ser 15 o 20 días. De existir trabajadores que se excluyen de esta condición puede establecer la cantidad de días en su respectiva ficha en “Personal / Empleados / Datos previsionales”',

  //WorkingDays
  'options.tabs.workingDays.title': 'Jornada',
  'options.tabs.workingDays.hoursPerWeek': 'Días legales devengados anuales',
  'options.tabs.workingDays.hoursPerWeek.description':
    'Son las horas semanales que se consideran como jornada completa en la empresa, y que afectan a toda la empresa.',

  // Seguridad
  'options.tabs.security.title': 'Seguridad',
  'options.tabs.payroll.allowModifyOldPayroll': 'Permitir modificar Liquidaciones pasadas',
  'options.tabs.security.allowModifyOldPayroll.description':
    'Permite modificar liquidaciones de sueldos anteriores al ultimo mes registrado en Laudus, siempre que el período no se encuentre bloqueado en las opciones de “Libros / Libro de Remuneraciones”',
  'options.tabs.security.allowDuplicatedEmployees':
    'Permitir trabajadores duplicados (doble contrato)',
  'options.tabs.security.allowDuplicatedEmployees.description':
    'Habilite solo en caso que desee agregar a un trabajador más de una vez en una liquidación. Situación que puede darse para trabajadores con doble contrato o situaciones especiales, por ejemplo, contratos de trabajo por obra donde en un mismo mes se finiquita al trabajador en una obra y se contrata en otra.',
  // Previred
  'options.tabs.previred.title': 'Previred',
  'options.tabs.previred.include.inCostCenter': 'Incluir el Centro de Costo en archivo',
  'options.tabs.previred.include.inCostCenter.description':
    'Útil en caso que necesite informar en el archivo de Previred el centro de costo de cada trabajador, por ejemplo, empresas constructoras que requieren incluir la obra en la que presta servicios el trabajador. De habilitarse debe crear o dar de alta cada centro de costo en la plataforma de Previred, y a posterior en Laudus indicar el respectivo “Código Previred” en “Compras / Centros de costos”.',
  // FUN Isapres
  'options.tabs.isapres.title': 'FUN Isapres',
  'options.tabs.isapres.xData': 'Número de Convenio con X-Data',
  'options.tabs.isapres.xData.description':
    'Es el número que entrega la empresa X-Data, una vez suscrito el respectivo convenio (gratuito), este permite revisar en línea desde “Personal / FUN Isapres” los cambios en los planes de salud de sus trabajadores, actualizándose automáticamente la ficha del trabajador y evitando errores al momento de realizar el cálculo de liquidaciones. ',

  'more.info.on.our.channel': 'Más información en nuestro canal',
  'more.info.on.our.blog': 'Más información en nuestro blog',

  // Purchases settings
  'options.tabs.purchases': 'Compras',
  // purchasesCheckbox
  'options.tabs.purchasesCheckbox.title': 'Compras',
  'options.tabs.purchasesCheckbox.table.oc': 'O/C',
  'options.tabs.purchasesCheckbox.table.salesWaybills': 'Guías de Despacho',
  'options.tabs.purchasesCheckbox.table.salesInvoices': 'Facturas',
  'options.tabs.salesInvoicesCard.title': 'Facturas',
  'options.tabs.salesInvoicesCard.needTo': 'Paso Obligado',
  'options.tabs.salesInvoicesCard.showStock': 'Mostrar Stock',
  'options.tabs.salesInvoicesCard.warnOnOverDueInvoices': 'Facturas morosas: Avisar',
  'options.tabs.salesInvoicesCard.multipleCurrencies': 'Multiples monedas',
  'options.tabs.salesInvoicesCard.askForProductUnitCostUpdate': 'Preguntar si se actualiza costo',
  // generalCard
  'options.tabs.generalCard.title': 'General',
  'options.tabs.generalCard.decimals.quantity.purchases': 'Decimales cantidades',
  'options.tabs.generalCard.decimals.quantity.purchases.description':
    'Permite especificar cuantos decimales se van a mostrar en las cantidades de los documentos de compras',
  'options.tabs.generalCard.decimals.unitPrice.purchases': 'Decimales costos',
  'options.tabs.generalCard.decimals.unitPrice.purchases.description':
    'Cantidad de decimales que utilizará para registrar el costo de compra de los productos, sea en órdenes de compra, guías de despacho o facturas.',
  // salesOrderCard
  'options.tabs.salesOrderCard.title': 'Órdenes de Compra',
  'options.tabs.salesOrderCard.orders.numberOfApprovals': 'Número de aprobaciones',
  'options.tabs.salesOrderCard.orders.numberOfApprovals.description':
    'Especifique el número de aprobaciones que se requieren en una orden de compra (con un máximo de 3)',

  'options.tabs.salesOrderCard.purchases_orders_showDeliveryByProduct':
    'Fecha de entrega por cada producto',
  'options.tabs.salesOrderCard.purchases_orders_showDeliveryByProduct.description':
    'Si bien la orden de compra en su conjunto está asociada a una fecha de entrega en datos generales, la función permite mostrar esta información (fecha, bodega y notas de entrega) por cada línea en el detalle.',

  'options.tabs.salesOrderCard.purchases_orders_approvalsMustBeInOrder': 'Deben aprobarse en orden',
  'options.tabs.salesOrderCard.purchases_orders_approvalsMustBeInOrder.description':
    'De existir mas de una aprobación, no será posible aprobar sin haberse autorizado el nivel que le antecede.',

  'options.tabs.salesOrderCard.purchases_orders_modifyOnlyWhoApproves':
    'Solo puede modificarla quien la aprueba',
  'options.tabs.salesOrderCard.purchases_orders_modifyOnlyWhoApproves.description': ' ',

  // salesInvoiceCard
  'options.tabs.salesInvoiceCard.fullCostOfImports': 'Costear importación por valor total',
  'options.tabs.salesInvoiceCard.fullCostOfImports.description':
    'Al habilitar esta función se considera como costo total del producto el valor de la importación más los gastos en que se incurrió hasta que el producto llega a nuestras bodegas. Para que esta función tenga efecto se hace necesario indicar un numero de “carpeta de importación” tanto al registrar la DIN (con el detalle de productos importados), como en las facturas de gastos asociados a la importación.',

  'options.tabs.salesInvoiceCard.useOnlyInputsInStockWhenCostCalculation':
    'Utilizar sólo unidades en stock en el costeo',
  'options.tabs.salesInvoiceCard.useOnlyInputsInStockWhenCostCalculation.description':
    'Esta opción permite indicar que es lo que se debe hacer para valorar los productos cuando existan roturas de stock, es decir, cuando la salida de bodega es mayor al stock disponible. Si esta función se mantiene desactivada y existen stocks en negativo, el costo del producto se regularizará en las entradas de mercaderías que se produzcan a futuro, donde se suple el faltante para efectos de costeo. Si por el contrario decide activarla el costo se calculará solo según lo que había en stock, lo que podría provocar inconsistencias en el costeo cuando se produzcan stocks en negativo, en este caso es recomendable habilitar las configuraciones en ventas y producción que permiten restringir la salida de productos cuando no existe stock disponible en bodega.',

  'options.tabs.salesInvoiceCard.payments_multipleCurrencies': 'Pagos Multimoneda',
  'options.tabs.salesInvoiceCard.payments_multipleCurrencies.description':
    'Útil para realizar pagos en una moneda diferente al CLP. Al habilitarse esta función notará que en el menú “Compras / Facturas” se agregan la casilla de selección “Pagos en moneda original”, además de los campos “Moneda y Tipo de Cambio”, estas dos últimos también se agregan como columnas en la pantalla “Compras / Pagos”. ',

  'options.tabs.salesInvoiceCard.purchases_showVATDeductionType': 'Mostrar Utilización de IVA',
  'options.tabs.salesInvoiceCard.purchases_showVATDeductionType.description':
    'Se habilita solo cuando se generan Ventas afectas y exentas, pues en ese caso deberá indicar en cada factura de compra el tipo de Utilización de IVA, a través de un desplegable deberá indicar como se va a deducir el IVA Crédito Fiscal (Todo el crédito, Nada del Crédito, Uso común).',

  'options.tabs.salesInvoiceCard.payments_checkInvoiceIsFactored':
    'Comprobar si la factura está cedida al pagarla',
  'options.tabs.salesInvoiceCard.payments_checkInvoiceIsFactored.description':
    'Permite comprobar cuando una factura de compra ha sido cedida a Factoring. Solo para clientes que facturan electrónicamente a través de Laudus.',

  //Options electronic invoices
  'options.tabs.electronicInvoices': 'Factura electrónica',
  'options.tabs.electronicInvoice.importDTE.title': 'Importación de DTE',
  'options.tabs.electronicInvoices.DTEDefaultProductWhenImporting.label': 'Producto por defecto',
  'options.tabs.electronicInvoices.DTEImportIgnoreWaybills.label':
    'No importar Guías de Despacho Electrónicas de proveedores',
  'options.tabs.electronicInvoices.DTEImportIgnoreWaybills.description':
    'habilite si al momento de importar prefiere ignorar las guías de despacho electrónicas de sus proveedores.',
  'options.tabs.electronicInvoices.DTEImportIgnoreTickets.label':
    'No importar Boletas Electrónicas de proveedores',
  'options.tabs.electronicInvoices.DTEImportIgnoreTickets.description':
    'Habilite en caso que no desee importar las facturas electrónicas de proveedores que llegan a la casilla de correo de intercambio.',
  'options.tabs.electronicInvoices.DTEDefaultProductWhenImporting.description':
    'Si al momento de realizar la importación de facturas de compra no existe información del código del proveedor en la ficha del producto (apartado “Proveedores), el detalle se completará automáticamente con el código que aquí escoja. De no encontrar información en la ficha o en esta casilla, las facturas se importarán solo con los datos generales y totales sin detalle de productos.',

  'options.tabs.electronicInvoice.factoringContact.title':
    'Contacto para aclarar dudas en Facturas cedidas (Factoring)',
  'options.tabs.electronicInvoice.DTEFactoringContactName.label': 'Nombre',
  'options.tabs.electronicInvoice.DTEFactoringContactPhone.label': 'Teléfono',
  'options.tabs.electronicInvoice.DTEFactoringContactEmail.label': 'Email',
  'options.tabs.electronicInvoice.factoringContact.description':
    'Datos de la persona dentro de su empresa que se encargará de aclarar dudas sobre facturas cedidas a Factoring. Esta información va incluida en el archivo XML que se genera y envía al cesionario, si este último tiene dudas podrá dirigirlas a la persona correcta.',

  'options.tabs.electronicInvoice.DTEFactoringEmailAnswerSII.title':
    'Casilla donde irán los mensajes de confirmación de Factoring del SII',
  'options.tabs.electronicInvoice.DTEFactoringEmailAnswerSII.label': 'Email',
  'options.tabs.electronicInvoice.DTEFactoringEmailAnswerSII.description':
    'Correo donde desea recibir las notificaciones de aceptación o rechazo del Servicio de Impuestos Internos con respecto a las facturas cedidas a factoring.',

  'options.tabs.electronicInvoice.walmartCompanyCode.title':
    'Código de Proveedor de Walmart (Vendor Number)',
  'options.tabs.electronicInvoice.DTEWalmartCompanyCode.label': 'Código',
  'options.tabs.electronicInvoice.walmartCompanyCode.description':
    'Código asignado por Walmart a cada proveedor, el que está compuesto de 9 dígitos. Adicional a este campo le aconsejamos ingresar en la ficha del cliente y completar el campo “ComercioNet” con el código GNL correspondiente al local de Walmart (de 13 dígitos), mientras para los productos que forman parte de la factura debe ingresar en su ficha (“Stock/Productos”) para digitar el código de barras estándar EAN13 de 13 dígitos, o bien los códigos internos de Walmart de 9 dígitos. Estas configuraciones permiten generar correctamente la factura electrónica, evitando rechazos por parte de Walmart.',

  'options.tabs.electronicInvoice.officialEmail.title': 'Email de Intercambio',
  'options.tabs.electronicInvoice.DTEOfficialEmail.label': 'Casilla',
  'options.tabs.electronicInvoice.DTEOfficialEmailPOPUserName.label': 'Usuario',
  'options.tabs.electronicInvoice.DTEOfficialEmailPOPPassword.label': 'Contraseña',
  'options.tabs.electronicInvoice.officialEmail.description':
    'Corresponde aquella casilla informada en la plataforma del SII como “Mail contacto empresas”, se utiliza exclusivamente para recibir las facturas de proveedores como las respuestas que envían los clientes sobre nuestras facturas de ventas. Desde aquí también se enviarán a nuestros clientes las facturas electrónicas emitidas.',

  'options.tabs.electronicInvoice.emailInput.title': 'Recepción de Mensajes',
  'options.tabs.emailInput.DTEOfficialEmailPOPServer.label': 'Servidor POP',
  'options.tabs.emailInput.DTEOfficialEmailPOPSSL.label': 'Conexión Segura',
  'options.tabs.electronicInvoice.emailInput.test': 'Comprobar Recepción',
  'options.tabs.electronicInvoice.emailInput.description':
    'Esta configuración es necesaria para recibir; las respuestas de sus clientes a las facturas electrónicas emitidas, y facturas de compras de sus proveedores. Indique Servidor de correo entrante (servidor POP, no IMAP). En cuanto a la casilla “Conexión Segura” de habilitarse se utilizará el puerto 995.',

  'options.tabs.electronicInvoice.emailOutput.title': 'Envío de Mensajes',
  'options.tabs.emailOutput.DTEOfficialEmailSMTPServer.label': 'Servidor SMTP',
  'options.tabs.emailOutput.DTEOfficialEmailSMTPSecureConnection.label': 'Conexión Segura',
  'options.tabs.emailOutput.DTEOfficialEmailSMTPRequiresAuthentication.label':
    'Requiere autenticación',
  'options.tabs.electronicInvoice.emailOutput.test': 'Comprobar Envío',
  'options.tabs.electronicInvoice.emailOutput.description':
    'Configuración necesaria para el correcto envío de las facturas electrónicas de ventas. Indique los datos del servidor de correo saliente.',

  'options.tabs.emailOutput.DTESendCopyTo.label': 'Enviar una copia de mis DTE a',
  'options.tabs.emailOutput.DTESendPDFwithEmail.label': 'Enviar el PDF además del XML',
  'options.tabs.electronicInvoice.emailOutput.sendCopyTo.description':
    'Se habilita en caso que al momento de enviar sus facturas de ventas a sus clientes en formato XML, desea adjuntar también el PDF del documento.',

  'options.tabs.electronicInvoice.tickets.title': 'Boleta Electrónica',
  'options.tabs.tickets.DteAllowTickets.label': 'Boleta Electrónica',
  'options.tabs.tickets.DteAllowTickets.description':
    'Su habilitación se realiza por personal de Laudus cuando el cliente adquiere este servicio.',
  'options.tabs.tickets.DteRcfRUT.label': 'RUT',
  'options.tabs.tickets.DteRcfRUT.description':
    'Certificado y RUT del usuario con permisos para firmar documentos electrónicos, se utilizará para generar el envío de las boletas electrónicas y resumen de ventas diario al Servicio de Impuestos Internos.',

  'options.tabs.electronicInvoice.DteRcfCertificatePFX.label': 'Certificado Digital',

  'DTECertificate.success.create': 'Carga de certificiado',
  'DTECertificate.success.message': 'El certificado PFX se ha cargado correctamente',
  'DTECertificate.errorToast.read': 'Error al leer certificado',
  'DTECertificate.warning.errorMessage':
    'Ha habido un error al intentar leer el certificado PFX de Factura electrónica',
  'DTECertificate.update.button': 'Actualizar certificado',
  'DTECertificate.password.title': 'Ingrese la Contraseña del Certificado',

  // 'Accounts'
  'account.section.title': 'Administrar cuenta',
  'accounts.tabs.general': 'Datos generales',
  'accounts.tabs.users': 'Usuarios',
  'accounts.tabs.companies': 'Mis Empresas',

  //Global account form
  'accounts.global.account.title': 'Datos generales',
  'accounts.global.account.name': 'Razón social',
  'accounts.global.account.VAT': 'RUT',
  'accounts.global.account.address': 'Dirección',
  'accounts.global.account.county': 'Comuna',
  'accounts.global.account.city': 'Ciudad',
  'accounts.global.account.region': 'Región',
  'accounts.global.account.telephone': 'Teléfono',
  'accounts.global.account.email': 'Email',

  //Global user form
  'accounts.global.user.title': 'Datos generales',
  'accounts.global.user.email': 'Email',
  'accounts.global.user.VAT': 'RUT',
  'accounts.global.user.edit': 'Editar datos',
  'accounts.global.user.management': 'Gestión de usuarios',
  'accounts.global.user.userName': 'Nombre de Usuario',
  'accounts.global.companies.title': 'Empresas',
  'accounts.global.user.search.company': 'Buscar empresa',
  'accounts.global.user.add.company': 'Vincular empresa',
  'accounts.global.user.no.companies.assigned': 'El usuario no tiene empresas asignadas',

  //Global users grid
  'accounts.users.grid.loginName': 'Nombre',
  'accounts.users.grid.email': 'Email',
  'accounts.users.grid.companies': 'Empresas',
  'accounts.users.grid.discontinued': 'Discontinuado',
  'accounts.users.grid.delete': 'Borrar',

  //Global companies form
  'accounts.global.companies.managment': 'Gestión de empresas',
  'accounts.global.companies.existingUserError':
    'El usuario existes en la empresa y está asociado a otro glId',

  //Global companies grid
  'accounts.companies.grid.name': 'Razón social',
  'accounts.companies.grid.VAT': 'RUT',
  'accounts.companies.grid.POS': 'POS',

  // Table export filename
  'holidays.export.filename': 'vacaciones_tabla',
  'accountTypes.export.filename': 'cuentas_tipo_tabla',
  'bankStatements.export.filename': 'cartolas_tabla',
  'employeesAbsences.export.filename': 'ausencias_tabla',
  'groups.export.filename': 'grupos_tabla',
  'inventory.export.filename': 'inventarios_tabla',
  'address.export.filename': 'direcciones_tabla',
  'afp.export.filename': 'afp_tabla',
  'apv.export.filename': 'apv_tabla',
  'branches.export.filename': 'sucursales_tabla',
  'ccaf.export.filename': 'ccaf_tabla',
  'contacts.export.filename': 'contactos_tabla',
  'suppliers.export.filename': 'proveedores_tabla',
  'employeeContractTerminations.export.filename': 'finiquitos_tabla',
  'currencies.export.filename': 'monedas_tabla',
  'documentTypes.export.filename': 'tipos_documentos_tabla',
  'exCajas.export.filename': 'ex_cajas_tabla',
  'globalCompanies.export.filename': 'empresas_tabla',
  'globalUsers.export.filename': 'usuarios_tabla',
  'bankReconciliation.export.filename': 'reconciliaciones_bancarias_tabla',
  'employeeHistory.export.filename': 'incidencias_tabla',
  'customers.export.filename': 'clientes_tabla',
  'products.export.filename': 'productos_tabla',
  'input.export.filename': 'ingresos_a_bodega_tabla',
  'isapre.export.filename': 'isapre_tabla',
  'journalEntry.export.filename': 'comprobantes_tabla',
  'employeeLoans.export.filename': 'préstamos_tabla',
  'opportunityActivity.export.filename': 'actividades_de_oportunidad_tabla',
  'opportunitySalesQuotes.export.filename': 'cotizaciones_de_oportunidad_tabla',
  'output.export.filename': 'salidas_de_bodega_tabla',
  'payrollCompanyParameters.export.filename': 'liquidaciones_parámetros_de_la_empresa_tabla',
  'payrollCostCenterAllocation.export.filename': 'liquidaciones_asignación_centro_de_costos_tabla',
  'payrollEmployeesRemuneration.export.filename': 'liquidaciones_conceptos_de_remuneración_tabla',
  'priceLists.export.filename': 'listas_de_precios_tabla',
  'processesInputItems.export.filename': 'transformaciones_elementos_de_entrada_tabla',
  'processesOutputItems.export.filename': 'transformaciones_elementos_de_salida_tabla',
  'productStock.export.filename': 'stock_de_productos_tabla',
  'productSupplier.export.filename': 'proveedores_de_producto_tabla',
  'purchaseInvoices.export.filename': 'gastos_tabla',
  'purchaseOrders.export.filename': 'ordenes_de_compra_tabla',
  'purchasePayments.export.filename': 'pagos_tabla',
  'receipts.export.filename': 'cobros_tabla',
  'receiptsInvoices.export.filename': 'recibos_facturas_tabla',
  'recipes.export.filename': 'recetas_tabla',
  'recurringSalesInvoices.export.filename': 'facturas_recurrentes_tabla',
  'referenceInvoices.export.filename': 'facturas_de_referencia_tabla',
  'remunerationConceptTypes.export.filename': 'tipos_de_conceptos_de_sueldos_tabla',
  'salaryComposition.export.filename': 'composición_sueldo_tabla',
  'salaryComposition.grid.cocept.name': 'Concepto',
  'salaryComposition.grid.expression': 'Fórmula del empleado',
  'salaryComposition.grid.accountId': 'Cuenta',
  'salaryComposition.grid.costCenter': 'C. Costo',
  'salesInvoices.export.filename': 'facturas_tabla',
  'salesOrders.export.filename': 'pedidos_tabla',
  'salesWaybills.export.filename': 'guías_de_despacho_tabla',
  'salesQuotes.export.filename': 'cotizaciones_tabla',
  'salesQuotesGroup.export.filename': 'grupos_de_cotizaciones_de_venta_tabla',
  'salesTicketsActivities.export.filename': 'actividades_de_venta_de_boletos_tabla',
  'salesTicketItems.export.filename': 'artículos_de_boletos_de_venta_tabla',
  'stages.export.filename': 'etapas_del_flujo_productivo_tabla',
  'stockTransfers.export.filename': 'traslados_entre_bodegas_tabla',
  'terms.export.filename': 'formas_de_pago_tabla',
  'unions.export.filename': 'sindicatos_tabla',
  'volumeDiscount.export.filename': 'descuentos_por_volumen_tabla',
  'workersCompensationInsurance.export.filename': 'mutuas_de_trabajadores_tabla',
  'customerInvoices.export.filename': 'facturas_del_cliente_tabla',
  'pendingInvoices.export.filename': 'facturas_pendientes_tabla',
  'dashboard.export.filename': 'estadísticas_del_panel_tabla',
  'supplierInvoices.export.filename': 'facturas_de_proveedores_tabla',
  'supplierPurchaseOrders.export.filename': 'órdenes_de_compra_de_proveedores_tabla',
  'balanceSheets.export.filename': 'estados_financieros_tabla',
  'cafDte.export.filename': 'caf_dte_tabla',
  'daily.export.filename': 'movimiento_diario_tabla',
  'dtePending.export.filename': 'dte_pendientes_tabla',
  'invoices.export.filename': 'facturas_de_venta_tabla',
  'invoicesReconciliation.export.filename': 'reconciliación_de_facturas_tabla',
  'ledger.export.filename': 'mayor_tabla',
  'payrollCash.export.filename': 'efectivos_de_nómina_tabla',
  'posShift.export.filename': 'cierres_de_caja_tabla',
  'statistics.export.filename': 'estadísticas_tabla',
  'warehouseStock.export.filename': 'stock_de_bodega_tabla',
  'stockControl.export.filename': 'control_de_stock_tabla',
  'historyCreditNode.export.filename': 'notas_de_crédito_histórico_tabla',
  'default.export.sheetName': 'Libro1',

  // printer
  'printer.error.title': 'Error de impresión',
  'printer.error.template.source.message':
    'El origen de la plantilla de impresión no está definido en las opciones del sistema.',
  // ticket item edit description
  'ticketItemDescription.edit': 'Editar descripción',
  'ticketItemDescription.description': 'Descripción',
  'ticketItemDescription.lot': 'Lote',

  // settings templates
  'options.tabs.templates': 'Plantillas',
  'options.tabs.templates.ticketsAndInvoices.title': 'Facturas y boletas',
  'options.tabs.templates.modified': 'Modificado',
  'options.tabs.templates.modal.title': 'Configurar plantilla de {docType}',
  'options.tabs.templates.header': 'Encabezado',
  'options.tabs.templates.numberOfCopies': 'Nº de copias',

  'options.tabs.templates.salesInvoice_39.title': 'Boleta electrónica',
  'options.tabs.templates.others.title': 'Otros',
  'options.tabs.templates.salesOrder': 'Orden de pedido',

  // References Invoices grid
  'referencesInvoices.grid.docType': 'Tipo documento',
  'referencesInvoices.grid.code': 'Código',
  'referencesInvoices.grid.document': 'Nº Documento',
  'referencesInvoices.grid.date': 'Fecha',
  'referencesInvoices.grid.description': 'Razón Referencia',

  // Related Products grid
  'relatedProducts.grid.sku': 'Código',
  'relatedProducts.grid.description': 'Descripción',
  'relatedProducts.grid.addRow': 'Añadir Alternativa',

  // Journal Entry
  'journalEntry.grid.accountNumber': 'Cuenta',
  'journalEntry.grid.accountName': 'Nombre',
  'journalEntry.grid.description': 'Concepto',
  'journalEntry.grid.debit': 'Debe',
  'journalEntry.grid.credit': 'Haber',
  'journalEntry.grid.costCenter': 'Centros de Costos',
  'journalEntry.grid.currencyCode': 'Moneda',
  'journalEntry.grid.parityToMainCurrency': 'T/C',
  'journalEntry.grid.originalDebit': 'Debe original',
  'journalEntry.grid.originalCredit': 'Haber original',
  'journalEntry.grid.relatedToVATId': 'RUT',
  'journalEntry.grid.relatedToName': 'Nombre',
  'journalEntry.grid.document': 'Documento',
  'journalEntry.grid.addRow': '+ Añadir una fila',
  'journalEntry.grid.relatedToSearchTitle': 'Seleccione a quien corresponde el VATID',

  // Productions settings
  'options.tabs.stocks': 'Producción',
  'options.tabs.stocks.title': 'Producción',

  'options.tabs.stocks.defaultValuationMethod.label': 'Método de valorización de existencias',
  'options.tabs.stocks.defaultValuationMethod.text':
    'Seleccione el método de valorización de existencias que se aplicará por defecto en la producción.',

  'options.tabs.stocks.showSerialNumbers': 'Mostrar números de serie',
  'options.tabs.stocks.showSerialNumbers.description':
    'Habilite si desea registrar los números de serie de los productos en la producción.',

  'options.tabs.stocks.showLots': 'Mostrar lotes',
  'options.tabs.stocks.showLots.description':
    'Habilite si desea registrar los lotes de los productos en la producción.',

  'options.tabs.stocks.allowWithoutStock': 'Permitir sin stock',
  'options.tabs.stocks.allowWithoutStock.description':
    'Habilite si desea permitir producir sin stock.',

  'options.tabs.stocks.decimalsQuantityProduction.label': 'Decimales cantidades',
  'options.tabs.stocks.decimalsQuantityProduction.description':
    'Permite especificar cuantos decimales se van a mostrar en las cantidades de los documentos de producción.',
  //PDF Send Email
  'pdfSendEmail.title': 'Enviar {docType}',
  'pdfSendEmail.email': 'Email de destino',
  'pdfSendEmail.cc': 'CC',
  'pdfSendEmail.subject': 'Asunto',
  'pdfSendEmail.salesInvoices.defaultMessage': '<p>Mensaje por <b>defecto</b> sales invoices</p>',
  'pdfSendEmail.salesInvoices.defaultSubject.salesInvoices': 'Factura N° {invoiceNumber}',
};
