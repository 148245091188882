import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  IPrintSalesInvoiceAfterSave,
  IReceiptDocumentTableData,
  IReceipts,
  IReceiptsOtherDocument,
  IReceiptsSalesInvoice,
  IReceiptType,
  IRedirectTo,
  ISalesInvoice,
  IStartEditingParams,
} from '@laudus/types';

import { AppState, AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { resetCustomer } from '../customers';
import { endRequest, startRequest } from '../global';
import { getPosCurrent } from '../pos';
import { navigateTo } from '../router';
import {
  addRoundingProduct,
  clearSalesInvoice,
  clearSalesInvoiceDraft,
  printSalesInvoicePDF,
  removeRoundingProduct,
} from '../salesInvoices';
import { addTab } from '../tabs';

import { ReceiptTypesCodes, ReceiptTypesLabels } from './constants';
import { getMixedPaymentReceiptList, getReceiptDraft } from './selectors';

// Receips Tab action creators
export const addHomeReceiptTab = () =>
  addTab({
    tab: {
      id: 'receipts',
      title: intl.formatMessage({ id: 'receipts.tabTitle' }),
      path: 'pages/Receipts/Receipts',
      isRemovable: true,
    },
  });

export const startEditingReceipt = createAsyncThunk<
  void,
  IStartEditingParams | undefined,
  AppThunkConfig
>('RECEIPTS/START_EDITING', async (params, ThunkAPI) => {
  const { isNew } = params ?? {};
  const { dispatch } = ThunkAPI;
  dispatch(setReceiptEditing(true));
  dispatch(
    addTab({
      tab: {
        id: 'receipts',
        title: intl.formatMessage({ id: 'receipts.tabTitle' }),
        path: isNew ? 'pages/Receipts/ReceiptsNew' : 'pages/Receipts/ReceiptsEdit',
        isRemovable: true,
      },
    }),
  );
});

export const startEditingReceiptNew = createAsyncThunk<void, void, AppThunkConfig>(
  'RECEIPTS/START_EDITING_NEW',
  async (_, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(startEditingReceipt({ isNew: true }));
  },
);

export const stopEditingReceipt = createAsyncThunk<void, number | undefined, AppThunkConfig>(
  'RECEIPTS/STOP_EDITING',
  async (id, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(setReceiptEditing(false));
    dispatch(
      addTab({
        tab: {
          id: 'receipts',
          title: intl.formatMessage({ id: 'receipts.tabTitle' }),
          path: 'pages/Receipts/ReceiptsView',
          props: { id },
          isRemovable: true,
        },
      }),
    );
  },
);

// Simple actions
export const clearReceipt = createAction('RECEIPTS/CLEAR');

export const clearReceiptDraft = createAction('RECEIPTS/CLEAR_DRAFT');

export const setReceipt = createAction<IReceipts>('RECEIPTS/SET_RECEIPT');

export const setReceiptDraft = createAction<IReceipts>('RECEIPTS/SET_RECEIPT_DRAFT');

export const setReceiptDraftValues = createAction<Partial<IReceipts>>(
  'RECEIPTS/SET_RECEIPT_DRAFT_VALUE',
);

export const clearReceiptOtherDocumentDraft = createAction('RECEIPTS/CLEAR_OTHER_DOCUMENT_DRAFT');

export const clearMixedPaymentReceipt = createAction('RECEIPTS/CLEAR_MIXED_PAYMENT_RECEIPT');

export const setReceiptOtherDocumentDraft = createAction<IReceiptsOtherDocument>(
  'RECEIPTS/SET_OTHER_DOCUMENT_DRAFT',
);

export const setReceiptOtherDocumentDraftValues = createAction<Partial<IReceiptsOtherDocument>>(
  'RECEIPTS/SET_OTHER_DOCUMENT_DRAFT_VALUE',
);

export const setReceiptsList = createAction<IReceipts[]>('RECEIPTS/SET_LIST');

export const updateReceiptsList = createAction<IReceipts>('RECEIPTS/UPDATE_LIST');

export const removeReceiptFromList = createAction<number>('RECEIPTS/REMOVE_FROM_LIST');

export const duplicateReceipt = createAction<IReceipts>('RECEIPTS/DUPLICATE');

export const setMixedPaymentReceiptsList = createAction<IReceipts[]>(
  'RECEIPTS/SET_MIXED_PAYMENT_RECEIPT_LIST',
);

export const setReceiptEditing = createAction<boolean>('RECEIPTS/SET_EDITING');

//Complex actions
export const fetchReceiptList = createAsyncThunk<void, void, AppThunkConfig>(
  'RECEIPTS/FETCH_RECEIPTS_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('receipts'));
    try {
      const { data } = await api.receipts.fetchReceiptListAPI();
      dispatch(setReceiptsList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'receipts',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('receipts'));
    }
  },
);

export const fetchReceipt = createAsyncThunk<void, number, AppThunkConfig>(
  'RECEIPTS/FETCH_RECEIPT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('receipts'));
    try {
      const { data } = await api.receipts.fetchReceiptAPI(id);
      dispatch(setReceipt(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'receipts',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('receipts'));
    }
  },
);

export const createReceiptFromSalesInvoice = createAsyncThunk<
  void,
  ISalesInvoice & IRedirectTo & IPrintSalesInvoiceAfterSave,
  AppThunkConfig
>(
  'RECEIPTS/CREATE_RECEIPT_FROM_SALE_INVOICE',
  async ({ redirectToSuccessPath, printAfterSave, ...salesInvoice }, ThunkAPI) => {
    const { extra, dispatch, getState } = ThunkAPI;
    const { api } = extra;
    const state = getState() as AppState;
    const receipt = getReceiptDraft(state);
    const pos = getPosCurrent(state);
    const mixedPaymentReceiptList = getMixedPaymentReceiptList(state);
    const isReceiptMixedPayment = mixedPaymentReceiptList?.length > 0;

    const receiptType = isReceiptMixedPayment
      ? {
          code: ReceiptTypesCodes.OTHERS, // We don't have a specific code for "Pago mixto", so let's use "Otros"
          description: ReceiptTypesLabels.OTHERS,
        }
      : receipt.receiptType;

    const amount =
      (salesInvoice?.totals?.total ?? 0) + (salesInvoice?.totals?.notInvoiceable_total ?? 0);
    const formattedReceipt: IReceipts = {
      ...receipt,
      pos: pos.posId
        ? {
            posId: pos.posId,
            name: pos.name,
          }
        : undefined,
      issuedDate: salesInvoice.issuedDate,
      dueDate: salesInvoice.dueDate,
      salesInvoices: [
        {
          salesInvoiceId: salesInvoice.salesInvoiceId ?? '',
          docType: salesInvoice.docType,
          docNumber: salesInvoice.docNumber ?? 0,
          customer: salesInvoice.customer,
          originalAmount: amount,
          currencyCode: 'CLP',
          parityToMainCurrency: 1,
          amount: amount,
          costCenter: salesInvoice.costCenter,
        },
      ],
      otherDocuments: [],
      receiptType,
    };

    dispatch(startRequest('receipts'));

    try {
      // If the receipt is a mixed payment, we'll create a receipt for each payment method
      if (isReceiptMixedPayment) {
        await Promise.all(
          mixedPaymentReceiptList.map(async (receipt) => {
            await api.receipts.createReceiptAPI({
              ...formattedReceipt,
              ...receipt,
              salesInvoices: [
                {
                  ...formattedReceipt.salesInvoices[0],
                  amount: receipt.amount ?? 0,
                  originalAmount: receipt.amount ?? 0,
                },
              ],
            });
          }),
        );
      } else {
        // If the receipt is not a mixed payment, we'll create a single receipt
        await api.receipts.createReceiptAPI(formattedReceipt);
      }
    } catch (error) {
      // TODO: Look into error handling
      dispatch(
        showErrorAlert({
          error,
          prefix: 'receipts',
          action: 'save',
          additionalMessage: intl.formatMessage({
            id: 'receipts.errorToast.save.additionalMessage',
          }),
        }),
      );
    }

    // Update receipt type in current receipt
    dispatch(setReceipt({ ...receipt, receiptType }));

    if (redirectToSuccessPath) {
      dispatch(navigateTo(redirectToSuccessPath));
    }

    dispatch(
      printSalesInvoicePDF({
        salesInvoice,
        printAfterSave,
      }),
    );

    // If printAfterSave option is enabled, we'll print the invoice PDF and clear all the data
    // to continue with a new sale
    if (printAfterSave) {
      dispatch(resetCustomer());
      dispatch(clearSalesInvoice());
      dispatch(clearSalesInvoiceDraft());
      dispatch(clearReceiptDraft());
      dispatch(clearReceipt());
      dispatch(clearMixedPaymentReceipt());
    }

    dispatch(endRequest('receipts'));
  },
);

export const updateReceiptType = createAsyncThunk<void, IReceiptType, AppThunkConfig>(
  'RECEIPTS/UPDATE_RECEIPT_TYPE',
  async (receiptType, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    const state = ThunkAPI.getState() as AppState;
    const receipt = getReceiptDraft(state);

    // Clear document if receipt type is not cheque
    if (receipt.document !== '' && receiptType.code !== ReceiptTypesCodes.CHECK) {
      dispatch(setReceiptDraftValues({ document: undefined }));
    }

    // If receipt type is efectivo, add rounding product
    // If not, remove it
    if (receiptType.code === ReceiptTypesCodes.CASH) {
      dispatch(addRoundingProduct());
    } else {
      dispatch(removeRoundingProduct());
    }

    dispatch(
      setReceiptDraftValues({
        receiptType,
      }),
    );
  },
);

// This action update the amount of a sales invoice or other document in the receipt draft
// using the data from the receipt grid
export const setReceiptDocuments = createAsyncThunk<
  void,
  IReceiptDocumentTableData,
  AppThunkConfig
>('RECEIPTS/SET_RECEIPT_DOCUMENTS', async (document, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;
  const state = getState();
  const receiptDraft = getReceiptDraft(state);

  if (document.type === 'salesInvoice') {
    const nextReceiptSalesInvoices = [...receiptDraft.salesInvoices];
    const salesInvoiceToEditIndex = nextReceiptSalesInvoices?.findIndex(
      (si) => si.itemId === document.itemId,
    );

    if (salesInvoiceToEditIndex === -1) {
      return;
    }

    const editedReceiptSalesInvoice: IReceiptsSalesInvoice = {
      ...nextReceiptSalesInvoices[salesInvoiceToEditIndex],
      amount: document.amount,
      originalAmount: document.originalUnitPrice,
      parityToMainCurrency: document.parityToMainCurrency,
    };

    nextReceiptSalesInvoices.splice(salesInvoiceToEditIndex, 1, editedReceiptSalesInvoice);
    dispatch(setReceiptDraftValues({ salesInvoices: nextReceiptSalesInvoices }));
  }

  if (document.type === 'otherDocument') {
    const nextReceiptOtherDocuments = [...receiptDraft.otherDocuments];
    const otherDocumentToEditIndex = nextReceiptOtherDocuments?.findIndex(
      (si) => si.itemId === document.itemId,
    );

    if (otherDocumentToEditIndex === -1) {
      return;
    }

    const editedOtherDocument: IReceiptsOtherDocument = {
      ...nextReceiptOtherDocuments[otherDocumentToEditIndex],
      amount: document.amount,
      originalAmount: document.originalUnitPrice,
      parityToMainCurrency: document.parityToMainCurrency,
    };

    nextReceiptOtherDocuments.splice(otherDocumentToEditIndex, 1, editedOtherDocument);
    dispatch(setReceiptDraftValues({ otherDocuments: nextReceiptOtherDocuments }));
  }
});

export const removeReceiptDocument = createAsyncThunk<
  void,
  IReceiptDocumentTableData,
  AppThunkConfig
>('RECEIPTS/REMOVE_DOCUMENT', async (documentToRemove, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;
  const state = getState();
  const receiptDraft = getReceiptDraft(state);

  if (documentToRemove.type === 'salesInvoice') {
    const nextTableData = receiptDraft.salesInvoices.filter(
      (item) => item.itemId !== documentToRemove.itemId,
    );
    dispatch(setReceiptDraftValues({ salesInvoices: nextTableData }));
  }

  if (documentToRemove.type === 'otherDocument') {
    const nextTableData = receiptDraft.otherDocuments.filter(
      (item) => item.itemId !== documentToRemove.itemId,
    );
    dispatch(setReceiptDraftValues({ otherDocuments: nextTableData }));
  }
});

export const addReceiptSalesInvoicesDocument = createAsyncThunk<
  void,
  IReceiptsSalesInvoice,
  AppThunkConfig
>('RECEIPTS/ADD_RECEIPT_SALES_INVOICES_DOCUMENT', async (receiptSalesInvoice, ThunkAPI) => {
  const { extra, dispatch, getState } = ThunkAPI;
  const { api } = extra;
  const state = getState();
  const receiptDraft = getReceiptDraft(state);

  const { salesInvoiceId } = receiptSalesInvoice;

  if (!salesInvoiceId) {
    return;
  }

  try {
    dispatch(startRequest('receipts-sales-invoices'));
    const { data } = await api.salesInvoices.fetchSalesInvoiceFromAPI(salesInvoiceId);

    // Transforming data: ISalesInvoice -> IReceiptsSalesInvoice
    const newReceiptSalesInvoice: IReceiptsSalesInvoice = {
      amount: data.totals?.total ?? 0,
      costCenter: data.costCenter ?? null,
      currencyCode: 'CLP',
      customer: data.customer,
      docNumber: data.docNumber ?? 0,
      docType: data.docType,
      originalAmount: data.totals?.total ?? 0,
      parityToMainCurrency: 1,
      salesInvoiceId: salesInvoiceId,
      itemId: getNegativeUniqueNumericId(),
    };

    const nextTableData = [...receiptDraft.salesInvoices];
    nextTableData.push(newReceiptSalesInvoice);
    dispatch(setReceiptDraftValues({ salesInvoices: nextTableData }));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'receipts',
        action: 'read',
      }),
    );
  } finally {
    dispatch(endRequest('receipts-sales-invoices'));
  }
});
