import React, { ReactNode } from 'react';

import { useBreakpointValue } from '@chakra-ui/react';

import { DeviceTypes } from '@laudus/types';

import { ModalDrawer } from './ModalDrawer';
import { ModalWindow } from './ModalWindow';
import { ScopedModalWindow } from './ScopedModalWindow';
import { ModalType, ModalVariant } from './types';

interface IModalProps {
  acceptIcon?: React.ReactElement;
  acceptButtonVariant?: string;
  cancelButtonVariant?: string;
  acceptText?: string;
  cancelIcon?: React.ReactElement;
  cancelText?: string;
  children?: JSX.Element | JSX.Element[] | string;
  closeIcon?: boolean;
  isOpen: boolean;
  title?: string;
  titlePosition?: string;
  header?: ReactNode;
  type?: ModalType;
  nextIcon?: React.ReactElement;
  fontSize?: string;
  backgroundColor?: string;
  variant?: ModalVariant;
  widthAlertButton?: string;
  heightAlertButton?: string;
  drawerBorderTopRadius?: number | string | { base?: string; md?: string; lg?: string };
  drawerBorderBottomRadius?: number | string | { base?: string; md?: string; lg?: string };
  drawerPlacement?: 'top' | 'right' | 'bottom' | 'left';
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  size?: 'sm' | 'md' | 'lg' | 'unset';
  resizable?: boolean;
  scopedRef?: boolean;
  scoped?: boolean;
  disabled?: boolean;
  onAccept?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export const Modal = ({
  acceptIcon,
  acceptText,
  children,
  cancelIcon,
  cancelText,
  closeIcon = true,
  acceptButtonVariant = 'primary',
  cancelButtonVariant = 'outline',
  isOpen,
  title,
  titlePosition,
  header,
  type,
  nextIcon,
  fontSize,
  backgroundColor: customBackgroundColor,
  variant = 'modal',
  widthAlertButton,
  heightAlertButton,
  drawerBorderTopRadius,
  drawerBorderBottomRadius,
  drawerPlacement,
  width,
  minWidth,
  maxWidth,
  height,
  minHeight,
  maxHeight,
  disabled,
  size = 'md',
  resizable,
  scoped,
  onAccept,
  onCancel,
  onClose = onCancel,
}: IModalProps) => {
  const deviceType = useBreakpointValue(
    { base: DeviceTypes.Mobile, md: DeviceTypes.Desktop },
    { ssr: false },
  );
  let modalType;
  if (type) {
    modalType = type;
  } else if (deviceType === DeviceTypes.Mobile) {
    modalType = ModalType.Drawer;
  } else {
    modalType = ModalType.Window;
  }

  if (modalType === ModalType.Drawer) {
    return (
      <ModalDrawer
        acceptIcon={acceptIcon}
        acceptText={acceptText}
        cancelIcon={cancelIcon}
        cancelText={cancelText}
        closeIcon={closeIcon}
        acceptButtonVariant={acceptButtonVariant}
        cancelButtonVariant={cancelButtonVariant}
        drawerBorderBottomRadius={drawerBorderBottomRadius}
        drawerBorderTopRadius={drawerBorderTopRadius}
        drawerPlacement={drawerPlacement}
        heightAlertButton={heightAlertButton}
        isOpen={isOpen}
        title={title}
        widthAlertButton={widthAlertButton}
        minHeight={minHeight}
        maxHeight={maxHeight}
        height={height}
        size={size}
        onAccept={onAccept}
        onCancel={onCancel}
        onClose={onClose}
      >
        {children}
      </ModalDrawer>
    );
  }

  if (scoped) {
    return (
      <ScopedModalWindow
        acceptIcon={acceptIcon}
        acceptText={acceptText}
        cancelIcon={cancelIcon}
        backgroundColor={customBackgroundColor}
        cancelText={cancelText}
        closeIcon={closeIcon}
        cancelButtonVariant={cancelButtonVariant}
        acceptButtonVariant={acceptButtonVariant}
        fontSize={fontSize}
        heightAlertButton={heightAlertButton}
        widthAlertButton={widthAlertButton}
        resizable={resizable}
        minHeight={minHeight}
        maxHeight={maxHeight}
        height={height}
        minWidth={minWidth}
        maxWidth={maxWidth}
        width={width}
        isOpen={isOpen}
        nextIcon={nextIcon}
        title={title}
        titlePosition={titlePosition}
        header={header}
        variant={variant}
        size={size}
        disabled={disabled}
        onAccept={onAccept}
        onCancel={onCancel}
        onClose={onClose}
      >
        {children}
      </ScopedModalWindow>
    );
  }

  return (
    <ModalWindow
      acceptIcon={acceptIcon}
      acceptText={acceptText}
      cancelIcon={cancelIcon}
      cancelText={cancelText}
      closeIcon={closeIcon}
      cancelButtonVariant={cancelButtonVariant}
      acceptButtonVariant={acceptButtonVariant}
      backgroundColor={customBackgroundColor}
      fontSize={fontSize}
      heightAlertButton={heightAlertButton}
      isOpen={isOpen}
      nextIcon={nextIcon}
      title={title}
      titlePosition={titlePosition}
      header={header}
      variant={variant}
      widthAlertButton={widthAlertButton}
      // TODO does not work
      // resizable={resizable}
      minHeight={minHeight}
      maxHeight={maxHeight}
      height={height}
      minWidth={minWidth}
      maxWidth={maxWidth}
      width={width}
      size={size}
      disabled={disabled}
      onAccept={onAccept}
      onCancel={onCancel}
      onClose={onClose}
    >
      {children}
    </ModalWindow>
  );
};
