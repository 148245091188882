export const USER_RIGHTS = {
  FILE: {
    OPEN_COMPANY: '04F',
    CONFIGURE_PAGE: '049',
    SEND_TO: '045',
    SAVE_AS: '044',
    IMPORT: '05G',
    PRINT: '04J',
    NEW_COMPANY: '04D',
    PREVIEW: '04K',
  },
  SUPPORT: {
    CHECK_UPDATES: '063',
  },
  PURCHASES: {
    FIXED_ASSETS: '033',
    COST_CENTERS: '034',
    INVOICES: '017',
    WAYBILLS: '04X',
    CURRENCIES: '053',
    PURCHASE_ORDERS: {
      GENERAL: '05H',
      APPROVE: '05I',
      PURCHASE_ORDERS: '04T',
    },
    PAYMENTS: '032',
    SUPPLIERS: '010',
  },
  ACCOUNTING: {
    RECEIPTS: '048',
    BANK_RECONCILIATION: '050',
    TYPE_ACCOUNTS: '026',
    ACCOUNTING_JOURNAL: '015',
    BALANCE_SHEETS: '012',
    SENIOR_ACCOUNTANT: '019',
    ACCOUNTING_PLAN: '020',
    BUDGETS: '06F',
    UTILITIES: {
      GENERAL: '047',
      ADVANCE_CORRELATIVE: '05P',
      CLOSING_OF_THE_YEAR: '05O',
      GENERATE_ACCOUNTING: '05N',
      REMUNERATE_VOUCHERS: '05Q',
    },
  },
  TOOLS: {
    ALERTS: '056',
    SALES_ANALYSIS: '06C',
    CONTROL_OF_FIXED_ASSETS: '040',
    STOCK_CONTROL: '003',
    INVOICES_CONTROL: '005',
    OPPORTUNITIES_CONTROL: '067',
    SALES_ORDERS_CONTROL: '04I',
    BUDGETS_CONTROL: '06G',
    PROCESSES_CONTROL: '029',
    TREASURY_CONTROL: '039',
    DEFINING_PERSONALIZED_CONCEPTS: '04Q',
    DEFINING_PRINTS: '04B',
    SEND_MESSAGES: '04R',
    STATISTICS: '004',
    REPORTS: '046',
    LISTS: '04S',
    MAINTENANCE: '021',
    DAILY_MOVEMENT: '006',
    OPTIONS: {
      GENERAL: '04H',
      COMPANY_CONFIGURATION: '043',
    },
    MONTHLY_SUMMARY: '062',
    SECURITY: {
      GENERAL: '041',
      CHANGE_PASSWORD: '042',
      CONTROL_OF_ACCESS_TO_USERS: '013',
      USERS_GROUPS: '018',
      USERS: '027',
    },
    BATCH_TRACING: '05T',
    SERIAL_NUMBER_TRACING: '05R',
  },
  STAFF: {
    APV_ADMINISTRATORS: '04Z',
    AFPs: '037',
    CCAF: '04M',
    HOLIDAYS: '06A',
    EMPLOYEES: {
      GENERAL: '036',
      DOCUMENTS: '05V',
      SALARY_COMPOSITION: '05U',
    },
    REMUNERATION_CONCEPTS: '04C',
    EX_BOXES: '04Y',
    ISAPRES: '038',
    PAYROLL: '04E',
    SECURITY_MUTUALS: '04L',
    PRIVATE_ROLE: '06H',
    TYPES_OF_SALARY_CONCEPT: '04P',
  },
  PRODUCTION: {
    WAREHOUSES: '031',
    DEFINITION_OF_PROCESSES: '028',
    STAGES_OF_THE_PRODUCTIVE_FLOW: '060',
    PRODUCT_FAMILIES: '025',
    WAREHOUSE_REPLENISHES: '008',
    INVENTORIES: '007',
    LOTS: '05X',
    PRODUCTS: '009',
    PROJECTS: '06D',
    RECIPES: '028',
    WAREHOUSE_OUTPUTS: '05E',
    PROJECTS_TYPES: '06E',
    TRANSFORMATIONS: '011',
    STOCK_TRANSFERS_BETWEEN_WAREHOUSES: '06B',
    STAGES: '060',
    PROCESSES: '011',
  },
  SALES: {
    CASHIERS: {
      GENERAL: '06L',
      OPEN_CLOSE: '06O',
      CASH: '06I',
      CASH_STATUS: '06K',
      INCOME_AND_EXPENSES_OF_THE_CASH: '06J',
    },
    CUSTOMERS: '002',
    CHARGES: {
      GENERAL: '030',
      BANKS: '035',
      CHARGES: '05C',
      CHARGES_INVOICES_CUSTOMERS_SUPPLIERS: '05F',
      MULTIPLE_DEPOSIT: '05Y',
      CHARGE_MANAGEMENT: '05S',
    },
    QUOTES: '04N',
    DISPATCHERS: '014',
    DISTRIBUTORS: '05B',
    INVOICES: {
      GENERAL: '001',
      PERIODIC_INVOICES: '068',
      DAILY_TICKET_SUMMARY_INCOME: '061',
      FULL_SCREEN: '055',
      SIMPLIFIED_SCREEN: '054',
      STAMPING_OF_ELECTRONIC_DOCUMENTS: '05Z',
    },
    CUSTOMERS_FAMILIES: '024',
    PAYMENT_METHODS: '04O',
    WAYBILLS: '04V',
    TAXES: '05M',
    PRICE_LISTS: '04W',
    REJECTION_REASONS: '022',
    OPPORTUNITIES: '066',
    ORDERS: '04G',
    TECHNICAL_SERVICE: '05L',
    BRANCH_OFFICES: '051',
    SELLERS: '016',
  },
  OTHERS: {
    APPROVE: {
      PAYMENTS_PROOF: '052',
      QUOTES: '05J',
      PURCHASE_ORDERS: {
        GENERAL: '04U',
        LEVEL_2: '06M',
        LEVEL_3: '06N',
      },
    },
    STORE: {
      PURCHASE_ORDERS: '05A',
      SALES_ORDERS: '059',
    },
    SYSTEM_CODES: '058',
    CUSTOMER_COMMERCIAL_CONDITIONS: '057',
    CONTACTS: '05K',
    UNBLOCK_CLIENTS: '065',
    EDIT_BLOCKED_SALES_ORDERS: '05W',
    EXAMINE_BUSINESS_REPORTS: '064',
    ALLOW_PRINT_DUPLICATES_FOR_SALE: '06P',
  },
};

export const ENTITY_BASED_USER_RIGHTS = {
  BANK_RECONCILIATION: '050',
  BANK: '035',
  BRANCH: '051',
  CARRIER: '014',
  CURRENCIES: '053',
  CUSTOMER: '002',
  DEALER: '05B',
  EMPLOYEE: '036',
  GROUP: '018',
  HOLIDAYS: '06A',
  INPUT: '008',
  INVENTORY: '007',
  JOURNAL_ENTRIES: '006',
  LOT: '05X',
  OPPORTUNITIES: '066',
  OUTPUT: '05E',
  PAYROLL: '04E',
  POS_ADJUSTMENT: '06J',
  POS: '06I',
  PRICE_LIST: '04W',
  PROCESSES: '011',
  PRODUCT: '009',
  PURCHASE_INVOICES: '017',
  PURCHASE_ORDERS: '05H',
  PURCHASE_PAYMENTS: '032',
  PURCHASE_WAYBILLS: '04X',
  RECEIPTS: '030',
  RECIPES: '028',
  RECURRING_SALES_INVOICES: '068',
  REMUNERATION_CONCEPT: '04C',
  SALES_INVOICE: '001',
  SALES_ORDER: '04G',
  SALES_QUOTE: '04N',
  SALES_TAXES: '05M',
  SALES_TICKET: '05L',
  SALES_WAYBILL: '04V',
  SALESMEN: '016',
  STAGES: '060',
  STOCK_TRANSFER: '06B',
  SUPPLIER: '010',
  TERM: '04O',
  USER: '027',
  WAREHOUSE: '031',
  FIXED_ASSET: '033',
};
