import { AxiosResponse } from 'axios';

import { removeNegativeItemIdsFromEntity } from '@laudus/shared-utils';
import { IInput } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchInputsListAPI = (): Promise<AxiosResponse<IInput[]>> =>
  axios.post(
    `${VITE_API_URL}/production/inputs/list`,
    {
      fields: ['inputId', 'warehouse.name', 'notes'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchInputAPI = (inputId: number): Promise<AxiosResponse<IInput>> =>
  axios.get(`${VITE_API_URL}/production/inputs/${inputId}`);

export const createInputAPI = (input: IInput): Promise<AxiosResponse<IInput>> =>
  axios.post(`${VITE_API_URL}/production/inputs`, removeNegativeItemIdsFromEntity(input));

export const updateInputAPI = (input: IInput): Promise<AxiosResponse<IInput>> =>
  axios.put(
    `${VITE_API_URL}/production/inputs/${input.inputId}`,
    removeNegativeItemIdsFromEntity(input),
  );

export const deleteInputAPI = (inputId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/production/inputs/${inputId}`);
