import { createSelector } from '@reduxjs/toolkit';

import { AppState, IPriceListsState } from '../..';

export const getPriceListsSlice = (state: AppState): IPriceListsState => state.priceLists;

export const getPriceListList = createSelector([getPriceListsSlice], (state) => state.list);

export const getPriceList = createSelector([getPriceListsSlice], (state) => state.current);

export const getPriceListDraft = createSelector([getPriceListsSlice], (state) => state.draft);
