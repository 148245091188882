import { intl } from '@laudus/intl';
import { IReportsLeftMenuItem, ReportsLeftMenuItemTypes } from '@laudus/types';

export const ACCOUNTING_JOURNAL_ENTRIES_REPORTS: IReportsLeftMenuItem[] = [
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.dailyBook' }),
    config: {
      name: 'diario',
      type: 'pdf',
      filters: [
        'dateFrom',
        'dateTo',
        'journalEntryFrom',
        'journalEntryTo',
        'accountFrom',
        'accountTo',
        'printJournalSums',
        'bookId',
        'showOnlyAccountsWithActivity',
        {
          label: intl.formatMessage({ id: 'reports.report.header.foiled' }),
          filters: ['startPage', 'printHeader'],
        },
      ],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.compactDailyBook' }),
    config: {
      name: 'diario, compacto',
      type: 'pdf',
      filters: [
        'dateFrom',
        'dateTo',
        'journalEntryFrom',
        'journalEntryTo',
        'accountFrom',
        'accountTo',
        'printJournalSums',
        'bookId',
        'showOnlyAccountsWithActivity',
        {
          label: intl.formatMessage({ id: 'reports.report.header.foiled' }),
          filters: ['startPage', 'printHeader'],
        },
      ],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.compactColumnDailyBook' }),
    config: {
      name: 'diario, compacto, columnas',
      type: 'pdf',
      filters: [
        'dateFrom',
        'dateTo',
        'journalEntryFrom',
        'journalEntryTo',
        'accountFrom',
        'accountTo',
        'printJournalSums',
        'bookId',
        'showOnlyAccountsWithActivity',
        {
          label: intl.formatMessage({ id: 'reports.report.header.foiled' }),
          filters: ['startPage', 'printHeader'],
        },
      ],
    },
  },
];
