import cloneDeep from 'lodash/cloneDeep';

import { dateToLocalISOString } from '@laudus/shared-utils';
import {
  IInvoiceReferences,
  ISalesDocument,
  ISalesInvoice,
  ISalesInvoicesItem,
  ISalesItem,
  ISalesOrder,
  ISalesQuote,
  ISalesWaybill,
  SalesDocumentsType,
  TypeOfProperty,
} from '@laudus/types';

import { INVOICES, QUOTES, WAYBILLS } from '../constants/docTypes';
import { DTEDocumentStatusList } from '../constants/dteDocumentStatus';
import { referencesDocTypes } from '../constants/referencesDocTypes';

type SupportedSalesDocument = ISalesWaybill | ISalesInvoice | ISalesOrder | ISalesQuote;

export const cleanSalesDocument = <UnknownSalesDocument extends SupportedSalesDocument>(
  originalSalesDocument: UnknownSalesDocument,
  salesDocumentType: SalesDocumentsType,
  mainCurrency = 'CLP',
  // isMultiCurrency = false,
): UnknownSalesDocument => {
  const newSalesDocument: UnknownSalesDocument = {
    ...originalSalesDocument,
    items: originalSalesDocument.items ?? [],
  };

  const fieldsToDelete = [
    'journalEntry',
    'DTE',
    'createdAt',
    'createdBy',
    'modifiedAt',
    'modifiedBy',
  ];

  // Remove fields to delete common for all sales document
  for (let i = 0; i < fieldsToDelete.length; i++) {
    const field = fieldsToDelete[i] as keyof UnknownSalesDocument;
    // eslint-disable-next-line no-prototype-builtins
    if (newSalesDocument.hasOwnProperty(fieldsToDelete[i])) {
      delete newSalesDocument[field];
    }
  }

  // Transformations for invoices
  if (salesDocumentType === INVOICES) {
    if ((originalSalesDocument as ISalesInvoice).totals?.currencyCode === mainCurrency) {
      delete (newSalesDocument as ISalesInvoice).totalsOriginalCurrency;
    }

    if (!(newSalesDocument as ISalesInvoice).source) {
      (newSalesDocument as ISalesInvoice).source = null;
    }

    // Fix id in docType object
    const references: IInvoiceReferences[] =
      (newSalesDocument as ISalesInvoice).references?.map((reference) => {
        // If we find a reference docType by name, use that docTypeId. Otherwise keep the existing one
        const referenceDocTypeIdByName = referencesDocTypes.find(
          (doc) => doc.name === reference.docType?.name,
        );

        if (!referenceDocTypeIdByName) {
          return reference;
        }

        return {
          ...reference,
          docType: {
            ...reference.docType,
            docTypeId: referenceDocTypeIdByName.docTypeId,
          },
        };
      }) ?? [];

    (newSalesDocument as ISalesInvoice).references = references;

    // Fix sales invoices items
    const salesInvoiceItems = newSalesDocument.items as ISalesInvoicesItem[];
    const salesInvoiceCostCenter = (newSalesDocument as ISalesInvoice).costCenter;
    const salesInvoiceAccount = (newSalesDocument as ISalesInvoice).account;

    const salesInvoiceItemsWithComputedProperties: ISalesInvoicesItem[] = salesInvoiceItems.map(
      (item) => ({
        ...item,
        costCenter: salesInvoiceCostCenter || null,
        account: salesInvoiceAccount || undefined,
      }),
    );

    (newSalesDocument as ISalesInvoice).items = salesInvoiceItemsWithComputedProperties;
  }

  // Transformations for quotes or waybills
  if (salesDocumentType === QUOTES || salesDocumentType === WAYBILLS) {
    // JBG branches are required but there is no branches selector, uncomment when branches selector

    if (newSalesDocument.branch === null) {
      newSalesDocument.branch = { branchId: 0 };
    }
  }

  /* Common transformations for all sales documents */

  // Fix address
  if (newSalesDocument.deliveryAddress?.address === '') {
    newSalesDocument.deliveryAddress = null;
  }

  // Fix dates
  if (!newSalesDocument.issuedDate) {
    newSalesDocument.issuedDate = dateToLocalISOString(new Date());
  }

  if (!newSalesDocument.dueDate) {
    newSalesDocument.dueDate = dateToLocalISOString(new Date());
  }

  // Fix items
  const items = fixSalesItemsOrder(newSalesDocument.items);

  // Return clean document
  return { ...newSalesDocument, items };
};

export function removeNetFromSalesItems(salesDocument: ISalesDocument): ISalesDocument {
  return {
    ...salesDocument,
    items: salesDocument.items.map((item) => ({ ...item, net: undefined })),
  };
}

function fixSalesItemsOrder(salesItems: ISalesItem[]): ISalesItem[] {
  return salesItems.map((item, index) => {
    const newItemOrder = index + 1;

    return { ...item, itemOrder: newItemOrder };
  });
}

function fixSalesItemsDescription(salesItems: ISalesItem[]): ISalesItem[] {
  return salesItems.map((item) => {
    const { product, itemDescription } = item;

    const productAllowsFreeDescription = Boolean(product?.allowFreeDescription);
    const itemDescriptionIsFilled = Boolean(itemDescription);

    /**
     * Fill itemDescription with product.description if the product doesn't allow for
     * free description, or it does but the itemDescription is empty
     */
    if (!productAllowsFreeDescription || !itemDescriptionIsFilled) {
      return { ...item, itemDescription: product?.description ?? '' };
    }

    return { ...item, itemDescription };
  });
}

export function mapSalesItemToGridRepresentation({
  salesItems,
}: {
  salesItems: ISalesItem[];
}): ISalesItem[] {
  const clonedItems = cloneDeep(salesItems);
  const itemsWithFixedDescription = fixSalesItemsDescription(clonedItems);

  return itemsWithFixedDescription;
}

export function translateDTEStatus({
  salesDocument,
}: {
  salesDocument: ISalesDocument;
}): TypeOfProperty<ISalesDocument, 'DTE'> {
  const { DTE } = salesDocument;

  if (!DTE) {
    return DTE;
  }

  const statusTranslated = DTEDocumentStatusList.find((s) => s.value === DTE.documentStatus);

  return {
    ...DTE,
    documentStatusTranslated: statusTranslated?.label ?? '',
  };
}
