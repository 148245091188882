import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IBank, IBankStatement, IBankStatementListItem } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

export const BANK_STATEMENTS_TAB_ID = 'bankStatements';

// BankStatements Tab action creators
export const addHomeBankStatementsTab = () =>
  addTab({
    tab: {
      id: BANK_STATEMENTS_TAB_ID,
      title: intl.formatMessage({ id: 'bankStatements.tabTitle' }),
      path: 'pages/BankStatements/BankStatements',
      isRemovable: true,
    },
  });

export const addViewBankStatementsTab = (id?: number) =>
  addTab({
    tab: {
      id: BANK_STATEMENTS_TAB_ID,
      title: intl.formatMessage({ id: 'bankStatements.tabTitle' }),
      path: 'pages/BankStatements/BankStatementsView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewBankStatementsTab = () =>
  addTab({
    tab: {
      id: BANK_STATEMENTS_TAB_ID,
      title: intl.formatMessage({ id: 'bankStatements.tabTitle' }),
      path: 'pages/BankStatements/BankStatementsNew',
      isRemovable: true,
    },
  });

export const addEditBankStatementsTab = () =>
  addTab({
    tab: {
      id: BANK_STATEMENTS_TAB_ID,
      title: intl.formatMessage({ id: 'bankStatements.tabTitle' }),
      path: 'pages/BankStatements/BankStatementsEdit',
      isRemovable: true,
    },
  });

// BankStatements action creators
export const clearBankStatement = createAction(`BANK_STATEMENTS/CLEAR`);

export const clearBankStatementDraft = createAction(`BANK_STATEMENTS/CLEAR_DRAFT`);

export const setBankStatement = createAction<IBankStatement>(`BANK_STATEMENTS/SET`);

export const setBankStatementDraft = createAction<IBankStatement>(`BANK_STATEMENTS/SET_DRAFT`);

export const setBankStatementDraftValues = createAction<Partial<IBankStatement>>(
  `BANK_STATEMENTS/SET_DRAFT_VALUES`,
);

export const setBankStatementList =
  createAction<IBankStatementListItem[]>(`BANK_STATEMENTS/SET_LIST`);

export const updateBankStatementList = createAction<IBankStatement>(`BANK_STATEMENTS/UPDATE_LIST`);

export const removeBankStatementFromList = createAction<number>(`BANK_STATEMENTS/REMOVE_FROM_LIST`);

export const duplicateBankStatement = createAction<IBankStatement>(`BANK_STATEMENTS/DUPLICATE`);

export const fetchBankStatementList = createAsyncThunk<void, void, AppThunkConfig>(
  'BANK_STATEMENTS/FETCH_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('bank-statements'));
    try {
      const { data } = await api.bankStatements.fetchBankStatementListFromAPI();
      dispatch(setBankStatementList(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'bankStatements',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('bank-statements'));
    }
  },
);

export const fetchBankStatement = createAsyncThunk<void, number, AppThunkConfig>(
  'BANK_STATEMENTS/FETCH',
  async (bankStatementId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('bank-statements'));
    try {
      const { data } = await api.bankStatements.fetchBankStatementFromAPI(bankStatementId);
      dispatch(setBankStatement(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'bankStatements',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('bank-statements'));
    }
  },
);

// IMPORT

export const clearImportBank = createAction('BANK_STATEMENTS/CLEAR_IMPORT_BANK');

export const setImportBank = createAction<IBank>('BANK_STATEMENTS/SET_IMPORT_BANK');

export const fetchImportBank = createAsyncThunk<void, string, AppThunkConfig>(
  'BANK_STATEMENTS/FETCH_IMPORT_BANK',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('bank-statements'));
    try {
      const { data } = await api.banks.fetchBankAPI(id);
      dispatch(setImportBank(data));

      // return data;
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'banks',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('bank-statements'));
    }
  },
);

export const setBankStatementsImportList = createAction<Record<string, string>[]>(
  'BANK_STATEMENTS/SET_IMPORT_LIST',
);

interface IRenameImportListColumnParams {
  oldColumnName: string;
  newColumnName: string;
}

export const renameBankStatementsImportListColumn = createAction<IRenameImportListColumnParams>(
  'BANK_STATEMENTS/RENAME_IMPORT_LIST_COLUMN',
);
