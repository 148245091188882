import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, ISalesTicket, IStartEditingParams } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const SALES_TICKETS_TAB_ID = 'salesTickets';

// SalesTickets Tab action creators
export const addHomeSalesTicketTab = () =>
  addTab({
    tab: {
      id: SALES_TICKETS_TAB_ID,
      title: intl.formatMessage({ id: 'salesTickets.tabTitle' }),
      path: 'pages/SalesTickets/SalesTickets',
      isRemovable: true,
    },
  });

export const startEditingSalesTicket = createAsyncThunk<
  void,
  IStartEditingParams | undefined,
  AppThunkConfig
>('PROCESSES_START_EDITING', async (params, ThunkAPI) => {
  const { isNew } = params ?? {};
  const { dispatch } = ThunkAPI;
  dispatch(setSalesTicketEditing(true));
  dispatch(
    addTab({
      tab: {
        id: SALES_TICKETS_TAB_ID,
        title: intl.formatMessage({ id: 'salesTickets.tabTitle' }),
        path: isNew ? 'pages/SalesTickets/SalesTicketsNew' : 'pages/SalesTickets/SalesTicketsEdit',
        isRemovable: true,
      },
    }),
  );
});

export const startEditingSalesTicketNew = createAsyncThunk<void, void, AppThunkConfig>(
  'PROCESSES/START_EDITING_NEW',
  async (_, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(startEditingSalesTicket({ isNew: true }));
  },
);

export const stopEditingSalesTicket = createAsyncThunk<void, number | undefined, AppThunkConfig>(
  'PROCESSES_STOP_EDITING',
  async (id, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(setSalesTicketEditing(false));
    dispatch(
      addTab({
        tab: {
          id: 'processes',
          title: intl.formatMessage({ id: 'salesTickets.tabTitle' }),
          path: 'pages/SalesTickets/SalesTicketsView',
          props: { id },
          isRemovable: true,
        },
      }),
    );
  },
);

// Simple actions
export const clearSalesTicket = createAction('SALES_TICKET/CLEAR');

export const clearSalesTicketDraft = createAction('SALES_TICKET/CLEAR_DRAFT');

export const setSalesTicket = createAction<ISalesTicket>('SALES_TICKET/SET_SALES_TICKET');

export const setSalesTicketDraft = createAction<ISalesTicket>(
  'SALES_TICKET/SET_SALES_TICKET_DRAFT',
);

export const setSalesTicketDraftValues = createAction<Partial<ISalesTicket>>(
  'SALES_TICKET/SET_SALES_TICKET_DRAFT_VALUE',
);

export const setSalesTicketList = createAction<ISalesTicket[]>('SALES_TICKET/SET_LIST');

export const updateSalesTicketList = createAction<ISalesTicket>('SALES_TICKET/UPDATE_LIST');

export const removeSalesTicketFromList = createAction<number>('SALES_TICKET/REMOVE_FROM_LIST');

export const duplicateSalesTicket = createAction<ISalesTicket>('SALES_TICKET/DUPLICATE');

export const setSalesTicketEditing = createAction<boolean>('SALES_TICKET/SET_EDITING');

// Complex actions
export const fetchSalesTicketList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'SALES_TICKET/FETCH_SALES_TICKET_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    if (!silent) {
      dispatch(startRequest('sales-tickets'));
    }

    try {
      const { data } = await api.salesTickets.fetchSalesTicketListFromAPI();
      dispatch(
        setSalesTicketList(
          Array.isArray(data) ? data.map(api.salesTickets.mapFromAPIListItem) : [],
        ),
      );
      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesTickets',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('sales-tickets'));
      }
    }
  },
);

export const fetchSalesTicket = createAsyncThunk<void, number, AppThunkConfig>(
  'SALES_TICKET/FETCH_SALES_TICKET',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('sales-tickets'));

    try {
      const { data } = await api.salesTickets.fetchSalesTicketFromAPI(id);
      dispatch(setSalesTicket(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesTickets',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('sales-tickets'));
    }
  },
);
