import { AxiosResponse } from 'axios';

import { removeNegativeItemIdsFromEntity } from '@laudus/shared-utils';
import { IOutput } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchOutputListAPI = (): Promise<AxiosResponse<IOutput[]>> =>
  axios.post(
    `${VITE_API_URL}/production/outputs/list`,
    {
      fields: ['outputId', 'warehouse.name', 'notes'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchOutputAPI = (outputId: number): Promise<AxiosResponse<IOutput>> =>
  axios.get(`${VITE_API_URL}/production/outputs/${outputId}`);

export const createOutputAPI = (output: IOutput): Promise<AxiosResponse<IOutput>> =>
  axios.post(`${VITE_API_URL}/production/outputs`, removeNegativeItemIdsFromEntity(output));

export const updateOutputAPI = (output: IOutput): Promise<AxiosResponse<IOutput>> =>
  axios.put(
    `${VITE_API_URL}/production/outputs/${output.outputId}`,
    removeNegativeItemIdsFromEntity(output),
  );

export const deleteOutputAPI = (outputId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/production/outputs/${outputId}`);
