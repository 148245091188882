import { createSelector } from '@reduxjs/toolkit';

import { IBranch } from '@laudus/types';

import { AppState } from '../../store';

import { ISalesmenState } from './reducer';

export const getSalesmenSlice = (state: AppState): ISalesmenState => state.salesmen;

export const getSalesmenList = createSelector([getSalesmenSlice], (state) => {
  const list = state.list;

  return [...list].sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
});

export const getSalesman = createSelector([getSalesmenSlice], (state) => state.current);

export const getSalesmanDraft = createSelector([getSalesmenSlice], (state) => state.draft);

// Return all salespersons who:
// - Are not restricted to a branch
// - Are restricted to the selected branch
// - Return all branches if no branch is selected
export function getSalesmenListFilteredByBranch(branch: IBranch | null) {
  return createSelector([getSalesmenList], (list) => {
    if (!branch) {
      return list;
    }

    const listFilteredByBranch = list.filter(
      (salesman) =>
        !salesman.restrictToBranch || salesman.restrictToBranch.branchId === branch.branchId,
    );

    return listFilteredByBranch;
  });
}
