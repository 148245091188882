import { AxiosResponse } from 'axios';

import { IFixedAsset, IFixedAssetListItem } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchFixedAssetListFromAPI = (): Promise<AxiosResponse<IFixedAssetListItem[]>> =>
  axios.post(
    `${VITE_API_URL}/purchases/fixedAssets/list`,
    {
      fields: ['fixedAssetId', 'name', 'location', 'dateAcquisition'],
      orderBy: [
        {
          field: 'fixedAssetId',
          direction: 'DESC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchFixedAssetFromAPI = (
  fixedAssetId: number,
): Promise<AxiosResponse<IFixedAsset>> => {
  return axios.get(`${VITE_API_URL}/purchases/fixedAssets/${fixedAssetId}`, {
    headers: {
      Accept: 'application/json',
    },
  });
};

export const calculateFixedAssetDepreciationFromAPI = ({
  acquisitionCost,
  dateAcquisition,
  dateRetirement,
  taxDeductionPercentage,
  dateTo,
  depreciateFromMonthOfAcquisition,
}: {
  acquisitionCost: number;
  dateAcquisition: string;
  dateRetirement: string;
  taxDeductionPercentage?: number; // optional
  dateTo?: string; // optional
  depreciateFromMonthOfAcquisition: boolean;
}): Promise<AxiosResponse<Partial<IFixedAsset>>> => {
  const queryParams: Record<string, any> = {
    acquisitionCost,
    dateAcquisition,
    dateRetirement,
    depreciateFromMonthOfAcquisition,
  };

  if (taxDeductionPercentage !== undefined) {
    queryParams.taxDeductionPercentage = taxDeductionPercentage;
  }

  if (dateTo !== undefined) {
    queryParams.dateTo = dateTo;
  }

  const queryString = new URLSearchParams(queryParams).toString();

  return axios.get(`${VITE_API_URL}/purchases/fixedAssets/depreciation?${queryString}`, {
    headers: {
      Accept: 'application/json',
    },
  });
};

export const createFixedAssetFromAPI = (
  fixedAsset: IFixedAsset,
): Promise<AxiosResponse<IFixedAsset>> =>
  axios.post(`${VITE_API_URL}/purchases/fixedAssets`, fixedAsset);

export const updateFixedAssetFromAPI = (
  fixedAsset: IFixedAsset,
): Promise<AxiosResponse<IFixedAsset>> =>
  axios.put(`${VITE_API_URL}/purchases/fixedAssets/${fixedAsset.fixedAssetId}`, fixedAsset);

export const deleteFixedAssetFromAPI = async (fixedAssetId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/purchases/fixedAssets/${fixedAssetId}`);
