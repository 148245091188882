import { AxiosResponse } from 'axios';

import { ICustomerCategory } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchCustomerCategoryListFromAPI = (): Promise<AxiosResponse<ICustomerCategory[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/customers/categories/list`,
    {
      fields: ['name', 'customerCategoryId', 'fullPath', 'parentId'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchCustomerCategoryFromAPI = (
  customerCategoryId: string,
): Promise<AxiosResponse<ICustomerCategory>> =>
  axios.get(`${VITE_API_URL}/sales/customers/categories/${customerCategoryId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createCustomerCategoryFromAPI = (
  customerCategory: ICustomerCategory,
): Promise<AxiosResponse<ICustomerCategory>> =>
  axios.post(`${VITE_API_URL}/sales/customers/categories`, customerCategory);

export const updateCustomerCategoryFromAPI = (
  customerCategory: ICustomerCategory,
): Promise<AxiosResponse<ICustomerCategory>> =>
  axios.put(
    `${VITE_API_URL}/sales/customers/categories/${customerCategory.customerCategoryId}`,
    customerCategory,
  );

export const deleteCustomerCategoryFromAPI = (customerCategoryId: string): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/customers/categories/${customerCategoryId}`);
