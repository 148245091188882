import { AxiosResponse } from 'axios';

import { IRecurringSalesInvoice, IRecurringSalesInvoiceListItem } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchRecurringSalesInvoiceListFromAPI = (): Promise<
  AxiosResponse<IRecurringSalesInvoiceListItem[]>
> =>
  axios.post(
    `${VITE_API_URL}/sales/invoices/recurring/list`,
    {
      fields: ['recurringSalesInvoiceId', 'description', 'customer.name'],
      orderBy: [
        {
          field: 'recurringSalesInvoiceId',
          direction: 'DESC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchRecurringSalesInvoiceFromAPI = (
  recurringSalesInvoiceId: number,
): Promise<AxiosResponse<IRecurringSalesInvoice>> => {
  return axios.get(`${VITE_API_URL}/sales/invoices/recurring/${recurringSalesInvoiceId}`, {
    headers: {
      Accept: 'application/json',
    },
  });
};

export const createRecurringSalesInvoiceFromAPI = (
  recurringSalesInvoice: IRecurringSalesInvoice,
): Promise<AxiosResponse<IRecurringSalesInvoice>> =>
  axios.post(
    `${VITE_API_URL}/sales/invoices/recurring`,
    clearTemporaryFields(recurringSalesInvoice),
  );

export const updateRecurringSalesInvoiceFromAPI = (
  recurringSalesInvoice: IRecurringSalesInvoice,
): Promise<AxiosResponse<IRecurringSalesInvoice>> =>
  axios.put(
    `${VITE_API_URL}/sales/invoices/recurring/${recurringSalesInvoice.recurringSalesInvoiceId}`,
    clearTemporaryFields(recurringSalesInvoice),
  );

export const deleteRecurringSalesInvoiceFromAPI = async (
  recurringSalesInvoiceId: number,
): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/invoices/recurring/${recurringSalesInvoiceId}`);

function clearTemporaryFields(recurringSalesInvoice: IRecurringSalesInvoice) {
  return { ...recurringSalesInvoice, costCenter: undefined, account: undefined };
}
