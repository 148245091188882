import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString, getNegativeUniqueNumericId } from '@laudus/shared-utils';
import { IInventory } from '@laudus/types';

import {
  clearInventory,
  clearInventoryDraft,
  duplicateInventory,
  removeInventoryFromList,
  setInventory,
  setInventoryDraft,
  setInventoryDraftValues,
  setInventoryList,
  updateInventoryList,
} from './actions';

export const INVENTORIES_TAB_ID = 'inventory';

export interface IInventoryState {
  current: IInventory;
  draft: IInventory;
  list: IInventory[];
}

export const INVENTORY_EMPTY: IInventory = {
  createdAt: '',
  createdBy: {},
  date: dateToLocalISOString(new Date()),
  inventoryId: 0,
  items: [],
  modifiedAt: '',
  modifiedBy: {},
  notes: '',
  warehouse: {},
};

export const initialInventoriesState: IInventoryState = {
  current: INVENTORY_EMPTY,
  draft: INVENTORY_EMPTY,
  list: [],
};

export const inventoriesReducer = createReducer(initialInventoriesState, (builder) => {
  builder
    .addCase(clearInventory, (state) => {
      return { ...state, current: INVENTORY_EMPTY };
    })
    .addCase(clearInventoryDraft, (state) => {
      return { ...state, draft: INVENTORY_EMPTY };
    })
    .addCase(setInventory, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setInventoryDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setInventoryDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setInventoryList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateInventoryList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.inventoryId !== action.payload.inventoryId),
          action.payload,
        ],
      };
    })
    .addCase(removeInventoryFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.inventoryId !== action.payload),
      };
    })
    .addCase(duplicateInventory, (state, action) => {
      const { inventoryId, ...duplicatedInventory } = action.payload;
      const duplicatedItems = [...duplicatedInventory.items].map((item) => {
        return { ...item, itemId: getNegativeUniqueNumericId() };
      });
      return {
        ...state,
        draft: { ...duplicatedInventory, items: [...duplicatedItems] },
      };
    })
    .addDefaultCase((state) => state);
});
