import { createReducer } from '@reduxjs/toolkit';

import {
  GLOBAL_USER_ACCOUNT,
  IAuthAccessToken,
  IAuthError,
  IFormState,
  IGlobalCompany,
  IGlobalUser,
} from '@laudus/types';

import {
  addAccessToken,
  clearAccessError,
  clearAuth,
  clearAuthError,
  invalidateAuth,
  removeAccessToken,
  resetAuthSubmit,
  setAccessError,
  setAuthCompanyList,
  setAuthError,
  setAuthLoginName,
  setAuthSubmitError,
  setAuthSubmitSuccess,
  setAuthSubmitting,
  setAuthToken,
  setGlobalUser,
  setLastVatId,
  setPasswordResetSent,
} from './actions';

const initialFormState = {
  submitting: false,
  submitError: false,
  submitSuccess: false,
};

export const EMPTY_GLOBAL_USER: IGlobalUser = {
  glUserId: 0,
  loginName: '',
  accountType: GLOBAL_USER_ACCOUNT.BASIC,
  email: '',
  VATId: '',
  glAccountId: 0,
  discontinued: false,
  createdAt: '',
  isAdmin: false,
};

export interface IAuthState {
  authError: IAuthError | null;
  authToken: string | null;
  accessError: IAuthError | null;
  companyList: IGlobalCompany[];
  accessTokens: IAuthAccessToken[];
  expirationDate: string | null;
  lastVatId: string | null;
  loginName: string | null;
  passwordResetSent: boolean;
  globalUser: IGlobalUser;
  formState: IFormState;
}

export const initialAuthState: IAuthState = {
  authError: null,
  authToken: null,
  companyList: [],
  accessError: null,
  accessTokens: [],
  expirationDate: null,
  lastVatId: null,
  loginName: null,
  passwordResetSent: false,
  globalUser: EMPTY_GLOBAL_USER,
  formState: initialFormState,
};

export const authReducer = createReducer(initialAuthState, (builder) => {
  builder
    .addCase(clearAuth, (state) => {
      return {
        ...state,
        authError: null,
        authToken: null,
        accessError: null,
        accessTokens: [],
        expirationDate: null,
      };
    })
    .addCase(invalidateAuth, (state, action) => {
      if (JSON.stringify(initialAuthState) !== JSON.stringify(state)) {
        return { ...initialAuthState, authError: action.payload };
      }
      return state;
    })
    .addCase(setAuthError, (state, action) => {
      return { ...state, authError: action.payload };
    })
    .addCase(clearAuthError, (state) => {
      return { ...state, authError: null };
    })
    .addCase(setAuthToken, (state, action) => {
      return {
        ...state,
        authToken: action.payload.token,
        expirationDate: action.payload.expiration,
      };
    })
    .addCase(setAuthLoginName, (state, action) => {
      return { ...state, loginName: action.payload };
    })
    .addCase(addAccessToken, (state, action) => {
      return {
        ...state,
        accessError: null,
        accessTokens: [...state.accessTokens, action.payload],
        lastVatId: action.payload.VATId,
      };
    })
    .addCase(removeAccessToken, (state, action) => {
      const hasAccessToken = state.accessTokens.some(
        (token) => token.VATId === action.payload.VATId,
      );
      if (!hasAccessToken) {
        return state;
      }

      return {
        ...state,
        accessError: action.payload.error ?? null,
        accessTokens: state.accessTokens.filter((t) => t.VATId !== action.payload.VATId),
      };
    })
    .addCase(setAccessError, (state, action) => {
      return { ...state, accessError: action.payload };
    })
    .addCase(clearAccessError, (state) => {
      return { ...state, accessError: null };
    })
    .addCase(setGlobalUser, (state, action) => {
      return { ...state, globalUser: action.payload };
    })
    .addCase(setAuthCompanyList, (state, action) => {
      return {
        ...state,
        companyList: action.payload,
      };
    })
    .addCase(resetAuthSubmit, (state) => {
      return { ...state, formState: initialFormState };
    })
    .addCase(setAuthSubmitting, (state) => {
      return { ...state, formState: { ...initialFormState, submitting: true } };
    })
    .addCase(setAuthSubmitError, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitError: true },
      };
    })
    .addCase(setAuthSubmitSuccess, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitSuccess: true },
      };
    })
    .addCase(setLastVatId, (state, action) => {
      return { ...state, lastVatId: action.payload };
    })
    .addCase(setPasswordResetSent, (state, action) => {
      return { ...state, passwordResetSent: action.payload };
    })
    .addDefaultCase((state) => state);
});
