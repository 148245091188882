import { Center } from '@chakra-ui/react';

import { LaudusImages } from '@laudus/assets';

export interface ILaudusLoaderProps {
  loading?: boolean;
}

export const LaudusLoader = ({ loading = true }: ILaudusLoaderProps) => {
  return loading ? (
    <Center height="100vh" left="0" position="fixed" top="0" width="100vw" zIndex="2000">
      <div
        className="backdrop"
        style={{
          position: 'absolute',
          width: '100%',
          height: '100%',
          background: 'rgba(255, 255, 255, 0.5)',
        }}
      />
      <div className="ellipsis-loader div" style={{ zIndex: '2001' }}>
        <img
          alt="Ellipsis LaudusLoader"
          src={LaudusImages.logoLoader}
          style={{ width: '5rem', height: '5rem' }}
        />
      </div>
    </Center>
  ) : null;
};
