import { ISalesOrder } from '@laudus/types';

import { calculateSalesDocumentNet } from './calculateSalesDocumentNet';

export const calculateOrderTotals = (
  order: ISalesOrder,
  mainCurrencyCode = 'CLP',
  mainCurrencyDecimals = 0,
) => {
  const orderNet = calculateSalesDocumentNet(order.items, mainCurrencyDecimals);

  const result = {
    totals: {
      net: orderNet,
      total: orderNet,
      currencyCode: mainCurrencyCode,
    },
    totalsOriginalCurrency: {
      net: orderNet,
      total: orderNet,
      currencyCode: mainCurrencyCode,
    },
  };

  return result;
};
