import { AxiosResponse } from 'axios';

import { ILedgerFormattedFilters, ILedgerItems } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchLedgerAPI = (
  filter: ILedgerFormattedFilters,
): Promise<AxiosResponse<ILedgerItems>> => {
  return axios.get(`${VITE_API_URL}/accounting/ledger`, { params: filter });
};

export const fetchGroupedLedgerAPI = (
  filter: ILedgerFormattedFilters,
): Promise<AxiosResponse<ILedgerItems>> => {
  return axios.get(`${VITE_API_URL}/accounting/ledger/grouped`, {
    params: filter,
  });
};
