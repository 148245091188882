/* eslint-disable @typescript-eslint/no-use-before-define */
import { roundDecimals } from '@laudus/shared-utils';
import {
  ICurrency,
  IInvoiceTax,
  ISalesInvoice,
  ISalesInvoicesItem,
  ISalesInvoiceTotals,
  ISalesTaxes,
} from '@laudus/types';

import { invoicesDocTypes as docTypesList } from '../constants/invoicesDocTypes';

import { calculateSalesItemNet } from './calculateSalesDocumentNet';

interface ITax {
  taxId?: number | null;
  taxName?: string | null;
  amount?: number;
}

// NOTE: mainCurrency, mainCurrencyDecimals, mainVatRate and salesInvoicesOtherDocumentsIncludeTaxes are values from company options

//// ****** FORMULAS ****** ////
// general net formula => unitPrice * discountPercentage * quantity

export const calculateInvoiceTotals = (
  originalInvoice: ISalesInvoice,
  currenciesList: ICurrency[] = [],
  allSalesTaxes: ISalesTaxes[] = [],
  mainCurrency = 'CLP',
  mainCurrencyDecimals = 0,
  mainVatRate = 19,
  salesInvoicesOtherDocumentsIncludeTaxes = false,
) => {
  const invoice: ISalesInvoice = {
    ...originalInvoice,
    items: originalInvoice.items ? [...originalInvoice.items] : [],
  };
  const invoiceTaxes: ITax[] = [];

  //// ****** CALCULATE INVOICE TOTALS ****** ////
  let net = 0;
  let VAT = 0;
  let ILA = 0;
  let originalNet = 0;
  let originalVAT = 0;
  let originalILA = 0;
  let total = 0;
  // let initialTotal = 0;
  let originalTotal = 0;
  let notInvoiceableTotal = 0;
  let notInvoiceableOriginalTotal = 0;
  let notInvoiceableIncome = 0;
  let notInvoiceableOriginalIncome = 0;

  const docTypeIdsWithoutVAT = [
    32, 34, 38, 41, 101, 102, 104, 106, 110, 111, 112, 190, 191, 192, 901,
  ];
  const shouldOmitVAT =
    docTypeIdsWithoutVAT.includes(invoice.docType?.docTypeId ?? 0) ||
    (invoice.docType?.docTypeId === 0 && !salesInvoicesOtherDocumentsIncludeTaxes);

  if (shouldOmitVAT) {
    (originalInvoice.items ?? []).forEach((item) => {
      item.VAT = 0;
      item.VATRate = 0;
    });
  }

  // Sum all items VAT, net and ILA
  for (let index = 0; index < invoice.items.length; index++) {
    const item = invoice.items[index];
    const {
      discountPercentage,
      quantity,
      notInvoiceable,
      notInvoiceable_isIncome: notInvoiceableIsIncome,
      originalUnitPrice,
      // VAT: itemVat,
      ILA: itemIla,
      currencyCode,
      parityToMainCurrency,
    } = item;

    const itemNet = calculateSalesItemNet(item, mainCurrencyDecimals);
    const itemVat = shouldOmitVAT ? 0 : calculateInvoiceItemVat(item, mainCurrencyDecimals);

    const itemCurrency = currenciesList.filter((currency) => currency.code === currencyCode);
    const itemCurrencyDecimals = itemCurrency.length ? itemCurrency[0].decimals || 0 : 0;
    const itemOriginalNet = roundDecimals(
      ((originalUnitPrice * (100 - discountPercentage)) / 100) * quantity,
      itemCurrencyDecimals,
    );
    if (!notInvoiceable) {
      net += itemNet;
      VAT += itemVat ?? 0;
      ILA += itemIla || 0;
      originalNet += itemOriginalNet; // Net in original currency
      originalVAT += roundDecimals(
        itemVat / (parityToMainCurrency !== 0 ? parityToMainCurrency : 1),
        itemCurrencyDecimals,
      );
      originalILA += roundDecimals(
        (itemIla || 0) / (parityToMainCurrency !== 0 ? parityToMainCurrency : 1),
        itemCurrencyDecimals,
      );
    } else {
      if (notInvoiceableIsIncome) {
        notInvoiceableIncome += itemNet;
        notInvoiceableOriginalIncome += itemOriginalNet;
      }
      notInvoiceableTotal += itemNet;
      notInvoiceableOriginalTotal += itemOriginalNet;
    }
  }

  //// ****** CHECK IF CURRENCY IS DIFFERENT FROM MAIN ****** ////
  // check if all lines are in the same currency and that currency is different from the mainCurrency
  let newHeaderCurrency: string | null | undefined = invoice.items[0]
    ? invoice.items[0].currencyCode
    : 'CLP';
  let newHeaderParity = 1;
  if (invoice.items[0] && invoice.items[0].parityToMainCurrency === 0) {
    newHeaderParity = 1;
  } else if (invoice.items[0]) {
    newHeaderParity = invoice.items[0].parityToMainCurrency;
  } else {
    newHeaderParity = 1;
  }
  for (let i = 1; i < invoice?.items?.length; i++) {
    if (!invoice.items[i].notInvoiceable && invoice.items[i].currencyCode !== newHeaderCurrency) {
      newHeaderCurrency = mainCurrency;
      break;
    }
  }

  //// ****** UPDATE VALUES WITH NEW CURRENCY ****** ////
  if (newHeaderCurrency === mainCurrency) {
    newHeaderParity = 1.0;
    originalNet = net;
    originalVAT = VAT;
    originalILA = ILA;
    notInvoiceableOriginalTotal = notInvoiceableTotal;
    notInvoiceableOriginalIncome = notInvoiceableIncome;
  }

  // initialTotal = invoice.totals?.total || 0;
  total = net + VAT + ILA;
  originalTotal = originalNet + originalVAT + originalILA;

  // TODO: Agregar retenci'on de facturas propias

  //// ****** CALCULATE SALES TAXES ****** ////
  // invoice docType
  const docType = docTypesList.find((type) => type.iddoctype === invoice.docType?.docTypeId);

  // Preguntar a Javier
  const exemptTax = ['X', 'Y', 'Z', 'T', 'W', 'N', 'O', 'Q', 'K', '1', '2', '5', '7', '9']; // docTypes codes exempt from taxes

  //// ****** CALCULATE SALES TAXES PER LINE ****** ////
  for (let itemIndex = 0; itemIndex < invoice.items.length; itemIndex++) {
    const invoiceItem = { ...invoice.items[itemIndex] };
    const unitPrice = invoiceItem.unitPrice || 0;
    const quantity = invoiceItem.quantity || 0;
    const discount = invoiceItem.discountPercentage || 0;
    const vatRate = invoiceItem.VATRate || 0;
    const itemNet = roundDecimals(
      unitPrice * quantity * (1 - discount / 100),
      mainCurrencyDecimals,
    );
    const itemTaxes = invoiceItem.taxes || [];
    // if docType.behavior === S just turn quantities to negatives and break loop.
    if (docType?.behavior === 'S') {
      if (invoiceItem.quantity) {
        invoiceItem.quantity = -Math.abs(invoiceItem.quantity || 0);
      }
      continue;
    }

    // if docType.behavior equals to some of the exemptTax options put VAT and VATRate to zero
    if (
      exemptTax.includes(docType?.behavior ?? '') ||
      (docType?.behavior === '0' && !salesInvoicesOtherDocumentsIncludeTaxes)
    ) {
      invoiceItem.VAT = 0;
      invoiceItem.VATRate = 0;
      continue;
    }
    // Any other cases
    // calculate VAT for each line

    const vatResult = shouldOmitVAT
      ? 0
      : unitPrice * quantity * (1 - discount / 100) * (vatRate / 100);
    invoiceItem.VAT = roundDecimals(vatResult, mainCurrencyDecimals);
    // calculate custom sales taxes
    // Iterate allSalesTaxes array
    for (let salesTaxesIndex = 0; salesTaxesIndex < allSalesTaxes.length; salesTaxesIndex++) {
      // Don´t apply sales tax to tickets if we don´t have to
      // Replace this taxId amount for all lines with 0
      if (docType?.subfamily === 'T' && !allSalesTaxes[salesTaxesIndex].applyToTickets) {
        if (invoiceItem.taxes) {
          invoiceItem.taxes = itemTaxes.map((tax: IInvoiceTax) => {
            if (tax.taxId === allSalesTaxes[salesTaxesIndex].taxId) {
              return {
                taxId: tax.taxId,
                taxName: tax.taxName,
                amount: 0,
              };
            } else {
              return tax;
            }
          });
        }

        if (invoiceItem.notInvoiceable) {
          continue;
        }

        // taxesReferences shouldn't exist inside sales invoice items
        // const productTaxReference =
        //   invoiceItem.taxesReferences?.filter(
        //     (taxRef) => taxRef.taxId === allSalesTaxes[salesTaxesIndex].taxId,
        //   )[0] || undefined;

        /**
         * RRC: All of this logic should be reviewed with Javier because it depends from a non-existing property (taxesReferences)
         * Also the implementation is difficult to understand
         */

        // if (allSalesTaxes[salesTaxesIndex].type === 'R' && productTaxReference) {
        //   let itemSalesTaxRate = 0;
        //   if (productTaxReference && productTaxReference.applyGeneralRule) {
        //     itemSalesTaxRate = allSalesTaxes[salesTaxesIndex].rate;
        //   } else {
        //     itemSalesTaxRate = productTaxReference.rate;
        //   }

        //   invoiceItem.taxes = itemTaxes.map((tax: IItemTaxes) => {
        //     const itemTax = roundDecimals(itemNet * itemSalesTaxRate, mainCurrencyDecimals);
        //     if (tax.taxId === allSalesTaxes[salesTaxesIndex].taxId) {
        //       return {
        //         taxId: tax.taxId,
        //         taxName: tax.taxName,
        //         amount: itemTax,
        //       };
        //     } else {
        //       return tax;
        //     }
        //   });
        // }

        // if (allSalesTaxes[salesTaxesIndex].type === 'V' && productTaxReference) {
        //   let itemSalesTaxAmountPerUnit = 0;
        //   if (productTaxReference && productTaxReference.applyGeneralRule) {
        //     itemSalesTaxAmountPerUnit = allSalesTaxes[salesTaxesIndex].amountPerUnit;
        //   } else {
        //     itemSalesTaxAmountPerUnit = productTaxReference.amountPerUnit;
        //   }

        //   invoiceItem.taxes = itemTaxes.map((tax: IItemTaxes) => {
        //     const itemTax = roundDecimals(
        //       quantity * itemSalesTaxAmountPerUnit,
        //       mainCurrencyDecimals,
        //     );
        //     if (tax.taxId === allSalesTaxes[salesTaxesIndex].taxId) {
        //       return {
        //         taxId: tax.taxId,
        //         taxName: tax.taxName,
        //         amount: itemTax,
        //       };
        //     } else {
        //       return tax;
        //     }
        //   });
        // }
      }
    }
    // If it is not a Ticket
    if (docType?.subfamily !== 'T') {
      // Calcualte the total VAT without product by product rounding.
      // Only do this procedure if none product has the VAT modified
      const totalInvoiceNet = invoice.items.reduce((previousValue, currentItem) => {
        if (currentItem.VAT !== 0 && !currentItem.notInvoiceable) {
          return itemNet + previousValue;
        } else {
          return previousValue;
        }
      }, 0);

      const totalInvoiceVat = roundDecimals(
        totalInvoiceNet * (mainVatRate / 100),
        mainCurrencyDecimals,
      );

      const totalVatPerItem = invoice.items.reduce(
        (previousValue, currentItem) => previousValue + (currentItem.VAT || 0),
        0,
      );

      if (totalInvoiceVat !== totalVatPerItem) {
        let vatDifference = totalInvoiceVat - totalVatPerItem;
        let breakLineLoop = false;

        for (let lineIndex = 0; lineIndex < invoice.items.length && !breakLineLoop; lineIndex++) {
          if (invoice.items[lineIndex].notInvoiceable) {
            continue;
          }
          if (invoice.items[lineIndex].VAT && invoice.items[lineIndex].VAT !== 0) {
            const unit = 1 / (10 ^ mainCurrencyDecimals);
            const lineVat: number = invoiceItem.VAT || 0;
            invoiceItem.VAT = lineVat + (unit * vatDifference > 0 ? 1 : -1);
            vatDifference = vatDifference - unit * (vatDifference > 0 ? 1 : -1);
          }
          if (vatDifference === 0) {
            breakLineLoop = true;
          }
        }
      }
    }

    // Finally overwrite the item in the main object
    invoice.items[itemIndex] = invoiceItem;
  }

  //// ****** REPLACE INVOICE TAXES WITH SUM OF LINES TAXES ****** ////
  const allItemsTaxes: ITax[] = [];
  for (let lineIndex = 0; lineIndex < invoice.items.length; lineIndex++) {
    if (!invoice.items[lineIndex].notInvoiceable) {
      for (let taxIndex = 0; taxIndex < invoice.items[lineIndex].taxes?.length || 0; taxIndex++) {
        if (invoice.items[lineIndex].taxes[taxIndex]) {
          allItemsTaxes.push(invoice.items[lineIndex].taxes[taxIndex]);
          // TODO: consultar con javier si esta condición es válida,
          // ya que sumamos al total el net, VAt e ILA en la línea 125 y
          // sin esta condición, se está sumando dos veces el mismo VAT en el total
          // Si está ok, debería apllicarse también en el cálculo de originalTotal
          if (
            invoice.items[lineIndex].taxes[taxIndex].taxName !== 'IVA' &&
            invoice.items[lineIndex].taxes[taxIndex].taxName !== 'ILA'
          ) {
            total += invoice.items[lineIndex].taxes[taxIndex].amount || 0;
          }
          if (newHeaderCurrency === mainCurrency) {
            originalTotal += invoice.items[lineIndex].taxes[taxIndex].amount || 0;
          } else {
            originalTotal +=
              (invoice.items[lineIndex].taxes[taxIndex].amount || 0) / newHeaderParity;
          }
        }
      }
    }
  }

  for (let taxesIndex = 0; taxesIndex < allItemsTaxes.length; taxesIndex++) {
    const currentItemTax: ITax = allItemsTaxes[taxesIndex];
    const invoiceTaxIndex = invoiceTaxes.findIndex((tax) => tax.taxId === currentItemTax.taxId);
    if (invoiceTaxIndex >= 0) {
      invoiceTaxes[invoiceTaxIndex] = {
        ...invoiceTaxes[invoiceTaxIndex],
        amount: (invoiceTaxes[invoiceTaxIndex].amount || 0) + (currentItemTax.amount || 0),
      };
    }
  }

  const totals: ISalesInvoiceTotals = {
    net: roundDecimals(net, mainCurrencyDecimals), // net !notInvoiceable
    VAT: roundDecimals(VAT, mainCurrencyDecimals), // suma de los vat de cada línea
    // Field ila does not to appears anywhere in API calls
    // ila: roundDecimals(ILA, mainCurrencyDecimals),
    taxes: invoiceTaxes,
    VATWithheld: 0,
    // net notInvoiceable  + notInvoiceable_isIncome
    notInvoiceable_income: roundDecimals(notInvoiceableIncome, mainCurrencyDecimals),
    // net notInvoiceable
    notInvoiceable_total: roundDecimals(notInvoiceableTotal, mainCurrencyDecimals),
    // total: 0, // sum  net, notInvoiceable_income, notInvoiceable_total , taxes and vatWithHeld
    total: roundDecimals(total, mainCurrencyDecimals),
    currencyCode: newHeaderCurrency ?? undefined,
    parity: newHeaderParity,
  };

  const totalsOriginalCurrency: ISalesInvoiceTotals = {
    net: roundDecimals(originalNet, mainCurrencyDecimals),
    VAT: roundDecimals(originalVAT, mainCurrencyDecimals),
    // Field
    // ila: roundDecimals(originalILA, mainCurrencyDecimals),
    // TODO: qué ponemos en taxes
    taxes: [],
    VATWithheld: 0,
    notInvoiceable_income: roundDecimals(notInvoiceableOriginalIncome, mainCurrencyDecimals),
    notInvoiceable_total: roundDecimals(notInvoiceableOriginalTotal, mainCurrencyDecimals),
    total: roundDecimals(originalTotal, mainCurrencyDecimals),
    currencyCode: newHeaderCurrency ?? undefined,
    parity: newHeaderParity,
  };

  return { totals, totalsOriginalCurrency };
};

export const calculateInvoiceItemVat = (
  item: ISalesInvoicesItem,
  mainCurrencyDecimals = 0,
): number => {
  const { unitPrice, discountPercentage, quantity, VATRate } = item;

  return calculateVAT({
    mainCurrencyDecimals,
    unitPrice,
    discountPercentage,
    quantity,
    VATRate,
  });
};

export const calculateVAT = ({
  mainCurrencyDecimals,
  unitPrice,
  discountPercentage,
  quantity,
  VATRate = 0,
}: {
  mainCurrencyDecimals: number;
  unitPrice: number;
  discountPercentage: number;
  quantity: number;
  VATRate?: number;
}): number => {
  const VAT = roundDecimals(
    unitPrice * quantity * (1 - discountPercentage / 100) * (VATRate / 100),
    mainCurrencyDecimals,
  );

  return VAT;
};
