import { createSelector } from '@reduxjs/toolkit';

import { AppState, ITermsState } from '../..';

export const getTermsSlice = (state: AppState): ITermsState => state.terms;

export const getTermsList = createSelector([getTermsSlice], (state) => state.list);

//Items is comming null when empty, and we want a empty array to iterate.
export const getTerm = createSelector([getTermsSlice], (state) => ({
  ...state.current,
  items: state.current.items ?? [],
}));

export const getTermDraft = createSelector([getTermsSlice], (state) => state.draft);
