import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const Drawer: ComponentMultiStyleConfig = {
  parts: ['body', 'closeButton'],
  sizes: { '2.5xl': { dialog: { width: '100%', maxWidth: '600px' } } },

  defaultProps: {
    size: '2.5xl',
  },
  baseStyle: {
    body: {
      padding: 0,
    },
    closeButton: {
      fontSize: '1.2rem',
      width: '32px',
      height: '32px',
      top: '0.5rem',
      right: '0.75rem',
    },
  },
};
