import { createReducer } from '@reduxjs/toolkit';

import { SALES_WAYBILL_EMPTY } from '@laudus/sales-utils';
import {
  addDaysToDate,
  calculateDatesDifference,
  dateToLocalISOString,
  getNegativeUniqueNumericId,
} from '@laudus/shared-utils';
import { ISalesWaybill, ISalesWaybillItem, ISalesWaybillList, ITraceFrom } from '@laudus/types';

import {
  clearSalesWaybill,
  clearSalesWaybillDraft,
  clearSalesWaybillPDFUrl,
  duplicateSalesWaybill,
  removeSalesWaybillFromList,
  setSalesWaybill,
  setSalesWaybillDraft,
  setSalesWaybillDraftValues,
  setSalesWaybillList,
  setSalesWaybillPDFUrl,
  setSalesWaybillTraces,
  updateSalesWaybillList,
} from './actions';

export interface ISalesWaybillState {
  current: ISalesWaybill;
  draft: ISalesWaybill;
  list: ISalesWaybillList;
  pdfURL: string | undefined;
  traces: ITraceFrom[];
}

export const initialSalesWaybillsState: ISalesWaybillState = {
  current: SALES_WAYBILL_EMPTY,
  draft: SALES_WAYBILL_EMPTY,
  list: [],
  pdfURL: undefined,
  traces: [],
};

export const salesWaybillsReducer = createReducer(initialSalesWaybillsState, (builder) => {
  builder
    .addCase(clearSalesWaybill, (state) => {
      return { ...state, current: SALES_WAYBILL_EMPTY };
    })
    .addCase(clearSalesWaybillDraft, (state) => {
      return { ...state, draft: SALES_WAYBILL_EMPTY };
    })
    .addCase(clearSalesWaybillPDFUrl, (state) => {
      return { ...state, pdfURL: undefined };
    })
    .addCase(setSalesWaybill, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setSalesWaybillDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setSalesWaybillDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setSalesWaybillPDFUrl, (state, action) => {
      return { ...state, pdfURL: action.payload };
    })
    .addCase(setSalesWaybillList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(setSalesWaybillTraces, (state, action) => {
      return { ...state, traces: action.payload };
    })
    .addCase(updateSalesWaybillList, (state, action) => {
      const { salesWaybillId, customer, issuedDate, docType, docNumber } = action.payload;

      if (!state.list.some((d) => d.salesWaybillId === salesWaybillId)) {
        return state;
      }

      return {
        ...state,
        list: [
          ...state.list.filter((d) => d.salesWaybillId !== salesWaybillId),
          {
            salesWaybillId: salesWaybillId ?? 0,
            customer_name: customer?.name ?? '',
            docType_name: docType?.name ?? '',
            docNumber: docNumber ?? 0,
            issuedDate: issuedDate ?? '',
          },
        ],
      };
    })
    .addCase(removeSalesWaybillFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.salesWaybillId !== action.payload),
      };
    })
    .addCase(duplicateSalesWaybill, (state, action) => {
      const {
        salesWaybillId: salesWaybillIdTmp,
        docNumber,
        purchaseOrderNumber,
        items,
        ...duplicatedSalesWaybill
      } = action.payload;

      duplicatedSalesWaybill.DTE = null;

      // issued and due dates
      const datesDifference = calculateDatesDifference(
        duplicatedSalesWaybill.issuedDate,
        duplicatedSalesWaybill.dueDate,
      );
      duplicatedSalesWaybill.issuedDate = dateToLocalISOString(new Date());
      duplicatedSalesWaybill.dueDate = addDaysToDate(new Date(), datesDifference).toISOString();

      // Other properties to overwrite
      duplicatedSalesWaybill.bypassCreditLimit = false;

      // TODO: check fields to reset
      // idAsiento ? no viene en la respuesta
      // source_id ? pendiente
      // source_idOrder ? no viene en la respuesta

      // Reset all itemIds
      const newItems: ISalesWaybillItem[] = items.map((item) => ({
        ...item,
        itemId: getNegativeUniqueNumericId(),
        traceFrom: null,
      }));

      // Build new draft and store the changes
      const newSalesWaybill: ISalesWaybill = {
        ...duplicatedSalesWaybill,
        items: newItems,
      };

      return { ...state, draft: newSalesWaybill };
    })
    .addDefaultCase((state) => state);
});
