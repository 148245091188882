import { createReducer } from '@reduxjs/toolkit';

import { IEmployeeLoan } from '@laudus/types';

import {
  removeEmployeeLoanFromList,
  setEmployeeLoanDraft,
  setEmployeeLoanDraftValues,
  setEmployeeLoansList,
  updateEmployeeLoansList,
} from '.';

export const EMPLOYEESLOANS_TAB_ID = 'employeesLoans';

export interface IEmployeeLoansState {
  list: IEmployeeLoan[];
  draft: IEmployeeLoan;
  loanListDraft: IEmployeeLoan[];
}

export const EMPLOYEE_LOAN_EMPTY: IEmployeeLoan = {
  employeeLoanId: 0,
  description: '',
  dateFrom: '',
  dateTo: '',
  monthlyAmount: 0,
  showInSlip: false,
};

export const initialEmployeeLoansState: IEmployeeLoansState = {
  list: [],
  draft: EMPLOYEE_LOAN_EMPTY,
  loanListDraft: [],
};

export const employeeLoansReducer = createReducer(initialEmployeeLoansState, (builder) => {
  builder
    .addCase(setEmployeeLoanDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setEmployeeLoanDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setEmployeeLoansList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateEmployeeLoansList, (state, action) => {
      const updatedLoan = action.payload;
      return {
        ...state,
        list: [
          ...state.list.filter((l) => l.employeeLoanId !== updatedLoan.employeeLoanId),
          updatedLoan,
        ],
      };
    })
    .addCase(removeEmployeeLoanFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.employeeLoanId !== action.payload),
      };
    })
    .addDefaultCase((state) => state);
});
