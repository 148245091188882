import { createReducer } from '@reduxjs/toolkit';

import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import { ICurrencyRate } from '@laudus/types';

import { CURRENCY_EMPTY } from '../currencies';

import {
  clearCurrencyRate,
  clearCurrencyRateDraft,
  duplicateCurrencyRate,
  removeCurrencyRateFromList,
  setCurrencyRate,
  setCurrencyRateDraft,
  setCurrencyRateDraftValues,
  setCurrencyRateList,
  updateCurrencyRateList,
} from './actions';

export interface ICurrencyRatesState {
  list: ICurrencyRate[];
  draft: ICurrencyRate;
  current: ICurrencyRate;
}

export const CURRENCY_RATE_EMPTY: ICurrencyRate = {
  currencyId: CURRENCY_EMPTY.currencyId,
  currency: CURRENCY_EMPTY,
  parities: [],
};

export const initialCurrencyRatesState: ICurrencyRatesState = {
  list: [],
  current: CURRENCY_RATE_EMPTY,
  draft: CURRENCY_RATE_EMPTY,
};

export const currencyRatesReducer = createReducer(initialCurrencyRatesState, (builder) => {
  builder
    .addCase(clearCurrencyRate, (state) => {
      return { ...state, current: CURRENCY_RATE_EMPTY };
    })
    .addCase(clearCurrencyRateDraft, (state) => {
      return { ...state, draft: CURRENCY_RATE_EMPTY };
    })
    .addCase(setCurrencyRate, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setCurrencyRateDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setCurrencyRateDraftValues, (state, action) => {
      return {
        ...state,
        draft: {
          ...state.draft,
          ...action.payload,
        },
      };
    })
    .addCase(setCurrencyRateList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateCurrencyRateList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((b) => b.currencyId !== action.payload.currencyId),
          {
            ...action.payload,
          },
        ],
      };
    })
    .addCase(removeCurrencyRateFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((b) => b.currencyId !== action.payload),
      };
    })
    .addCase(duplicateCurrencyRate, (state, action) => {
      return {
        ...state,
        draft: {
          ...action.payload,
          currencyId: '',
          currency: { ...action.payload.currency, currencyId: '' },
          parities: action.payload.parities.map((parity) => ({
            ...parity,
            parityId: getNegativeUniqueNumericId(),
            currencyId: '',
          })),
        },
      };
    })
    .addDefaultCase((state) => state);
});
