import { intl } from '@laudus/intl';
import { IReportsLeftMenuItemWithReport, ReportsLeftMenuItemTypes } from '@laudus/types';

export const SALES_INVOICES_REPORTS_WITHOUT_SECTION: IReportsLeftMenuItemWithReport[] = [
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.customersAdvancesPendingApplication' }),
    config: {
      name: 'anticipos a clientes pendientes_ta',
      type: 'pdf',
      filters: ['customer'],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({
      id: 'reports.report.title.withOutstandingBalance.sortedByCustomer',
    }),
    config: {
      name: 'facturaspendientesporcliente_ta',
      type: 'pdf',
      filters: ['date', 'customer', 'salesman', 'branch', 'currencyType'],
      fixedFilters: {
        order: 'name',
        type: 'pending',
      },
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.withOutstandingBalance.sortedByVATId' }),
    config: {
      name: 'facturaspendientesporcliente_ta',
      type: 'pdf',
      filters: ['date', 'customer', 'salesman', 'branch', 'currencyType'],
      fixedFilters: {
        order: 'VATId',
        type: 'pending',
      },
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.withOutstandingBalance.sortedByDate' }),
    config: {
      name: 'facturaspendientesporcliente_ta',
      type: 'pdf',
      filters: ['date', 'customer', 'salesman', 'branch', 'currencyType'],
      fixedFilters: {
        order: 'date',
        type: 'pending',
      },
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.pendingWithNotDeposited' }),
    config: {
      name: 'facturaspendientesporcliente_ta',
      type: 'pdf',
      filters: ['date', 'customer', 'salesman', 'branch', 'currencyType'],
      fixedFilters: {
        order: 'name',
        type: 'pendingWithNotDeposited',
      },
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.overdue.sortedByCustomer' }),
    config: {
      name: 'facturaspendientesporcliente_ta',
      type: 'pdf',
      filters: ['date', 'customer', 'salesman', 'branch', 'currencyType'],
      fixedFilters: {
        order: 'name',
        type: 'overdue',
      },
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.overdue.sortedByVATId' }),
    config: {
      name: 'facturaspendientesporcliente_ta',
      type: 'pdf',
      filters: ['date', 'customer', 'salesman', 'branch', 'currencyType'],
      fixedFilters: {
        order: 'VATId',
        type: 'overdue',
      },
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.overdue.sortedByDate' }),
    config: {
      name: 'facturaspendientesporcliente_ta',
      type: 'pdf',
      filters: ['date', 'customer', 'salesman', 'branch', 'currencyType'],
      fixedFilters: {
        order: 'date',
        type: 'overdue',
      },
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.overdue.summaryByCustomer' }),
    config: {
      name: 'facturas vencidas, resumen por cliente_ta',
      type: 'pdf',
      filters: ['date', 'salesman'],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.overdue.summaryByCustomer.withDetails' }),
    config: {
      name: 'facturas vencidas, resumen por cliente con detalle_ta',
      type: 'pdf',
      filters: ['date', 'salesman'],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.dailyCashIncomeSummary' }),
    config: {
      name: 'resumen de caja_ta',
      type: 'pdf',
      filters: ['dateFrom', 'dateTo', 'salesman', 'branch'],
    },
  },
];
