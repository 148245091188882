import { AxiosResponse } from 'axios';

import { IDealer } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchDealerListAPI = (): Promise<AxiosResponse<IDealer[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/dealers/list`,
    {
      fields: ['name', 'dealerId', 'legalName', 'address'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchDealerAPI = (dealerId: number): Promise<AxiosResponse<IDealer>> =>
  axios.get(`${VITE_API_URL}/sales/dealers/${dealerId}`);

export const createDealerAPI = (dealers: IDealer): Promise<AxiosResponse<IDealer>> =>
  axios.post(`${VITE_API_URL}/sales/dealers`, dealers);

export const updateDealerAPI = (dealers: IDealer): Promise<AxiosResponse<IDealer>> =>
  axios.put(`${VITE_API_URL}/sales/dealers/${dealers.dealerId}`, dealers);

export const deleteDealerAPI = (dealerId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/dealers/${dealerId}`);
