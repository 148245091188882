import { createSelector } from '@reduxjs/toolkit';

import { IRecurringSalesInvoice, IRecurringSalesInvoicesItemTableRow } from '@laudus/types';

import { AppState } from '../../store';

import { IRecurringSalesInvoicesState } from './reducer';

export const getRecurringSalesInvoicesSlice = (state: AppState): IRecurringSalesInvoicesState =>
  state.recurringSalesInvoices;

export const getRecurringSalesInvoicesList = createSelector(
  [getRecurringSalesInvoicesSlice],
  (state) => state.list,
);

export const getRecurringSalesInvoice = createSelector([getRecurringSalesInvoicesSlice], (state) =>
  setTemporaryFields(state.current),
);

export const getRecurringSalesInvoiceDraft = createSelector(
  [getRecurringSalesInvoicesSlice],
  (state) => setTemporaryFields(state.draft),
);

export function getRecurringSalesInvoiceTableData(invoice: IRecurringSalesInvoice) {
  return createSelector([getRecurringSalesInvoicesSlice], () => {
    const items: IRecurringSalesInvoicesItemTableRow[] = invoice.items.map((item) => ({
      ...item,
      itemDescription: item.itemDescription ?? item.product?.description ?? '',
      total: item.quantity * item.originalUnitPrice * (1 - item.discountPercentage / 100),
    }));

    return items;
  });
}

// The root should use the cost center of the items
// The root should use the account of the items
function setTemporaryFields(recurringSalesInvoice: IRecurringSalesInvoice): IRecurringSalesInvoice {
  const items = recurringSalesInvoice.items;
  let newCostCenter = recurringSalesInvoice.costCenter;
  let newAccount = recurringSalesInvoice.account;

  // Should use the cost center of the items
  if (items.length > 0 && items[0].costCenter) {
    newCostCenter = items[0].costCenter;
  }

  // Should use the account of the items
  if (items.length > 0 && items[0].account) {
    newAccount = items[0].account;
  }

  return {
    ...recurringSalesInvoice,
    costCenter: newCostCenter,
    account: newAccount,
  };
}
