export function formattedDateAndHour(value: string) {
  const splittedDate = value?.split('T');
  if (!splittedDate) {
    return value;
  }
  const formattedDate = splittedDate[0]?.split('-')?.reverse().join('-');
  const formattedHour = splittedDate[1]?.split(':')?.slice(0, 2).join(':');
  return value ? `${formattedDate} ${formattedHour}` : value;
}
export function formattedDate(value: string): string {
  const formattedDate = value?.split('T')[0]?.split('-')?.reverse().join('-');
  return value ? `${formattedDate}` : value;
}

export function transformDateTimeToDateZeroHour(value: string) {
  return value ? `${value.split('T')[0]}T00:00:00` : value;
}

export function transformDateTimeToDateLastHour(value: string) {
  return value ? `${value.split('T')[0]}T23:59:59` : value;
}
