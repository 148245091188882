import { IBankStatementListItem } from './bankStatements';
import { IPurchaseInvoiceReconciliationCandidate } from './purchaseInvoices';
import { IPurchasePaymentReconciliationCandidate } from './purchasePayments';

export interface IBankReconciliation<
  T extends IBankStatementsReconciliationItem | IJournalReconciliationItem,
> {
  bankStatementId: number;
  includeOtherBankStatements: IncludeOtherBankStatementsTypeEnum;
  data: T[];
}

export interface IBankStatementsReconciliationItem {
  bankStatementId: number;
  lineId: number;
  date: string;
  description: string;
  document: string;
  deposit: number;
  withdrawal: number;
  errorCode: string;
}

export interface IJournalReconciliationItem {
  bankStatementId: number;
  lineId: number;
  date: string;
  description: string;
  document: string;
  debit: number;
  credit: number;
  errorCode: string;
}

export interface IBankReconciliationFilter {
  bankStatement: IBankStatementListItem | null;
  includeOtherBankStatements: IncludeOtherBankStatementsTypeEnum;
}

export enum IncludeOtherBankStatementsTypeEnum {
  None = 'None',
  All = 'All',
  Previous = 'Previous',
}

export interface IWithdrawalReconciliation {
  withdrawal: IBankStatementsReconciliationItem | null;
  candidatePurchaseInvoices: IPurchaseInvoiceReconciliationCandidate[];
  candidatePayments: IPurchasePaymentReconciliationCandidate[];
}
