import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import {
  IAuthAccessToken,
  IAuthError,
  IGlobalCompany,
  IGlobalUser,
  ILoginCompanyParams,
  ILoginParams,
  ILoginResponse,
  IPasswordResetRequestParams,
  IPasswordUpdateParams,
  IRedirectTo,
  isAPIError,
} from '@laudus/types';

import { GlobalThunkConfig } from '../../store';
import { showAlert, showErrorAlert, showToastAlert } from '../alerts';
import { navigateTo } from '../router/actions';

export const clearAuth = createAction('AUTH/CLEAR_AUTH');

export const invalidateAuth = createAction<IAuthError | null>('AUTH/INVALIDATE_AUTH');

export const setAuthError = createAction<IAuthError | null>('AUTH/SET_AUTH_ERROR');

export const clearAuthError = createAction('AUTH/CLEAR_AUTH_ERROR');

export const setAuthToken = createAction<ILoginResponse>('AUTH/SET_AUTH_TOKEN');

export const setAuthLoginName = createAction<string>('AUTH/SET_AUTH_LOGIN_NAME');

export const setGlobalUser = createAction<IGlobalUser>('AUTH/SET_AUTH_GLOBAL_USER');

export const addAccessToken = createAction<IAuthAccessToken>('AUTH/ADD_ACCESS_TOKEN');

export const setAccessError = createAction<IAuthError | null>('AUTH/SET_ACCESS_TOKEN_ERROR');

export const clearAccessError = createAction('AUTH/CLEAR_ACCESS_ERROR');

export const removeAccessToken = createAction<Partial<{ error: IAuthError } & IAuthAccessToken>>(
  'AUTH/REMOVE_ACCESS_TOKEN',
);

export const resetAuthSubmit = createAction('AUTH/RESET_AUTH_SUBMIT');

export const setAuthSubmitting = createAction('AUTH/SET_AUTH_SUBMITTING');

export const setAuthSubmitError = createAction('AUTH/SET_AUTH_SUBMIT_ERROR');

export const setAuthSubmitSuccess = createAction('AUTH/SET_AUTH_SUBMIT_SUCCESS');

export const setAuthCompanyList = createAction<IGlobalCompany[]>('AUTH/SET_AUTH_COMPANIES_LIST');

export const setLastVatId = createAction<string>('AUTH/SET_LAST_VAT_ID');

export const login = createAsyncThunk<
  ILoginResponse | undefined,
  ILoginParams & IRedirectTo,
  GlobalThunkConfig
>('AUTH/LOGIN_REQUEST', async ({ redirectToSuccessPath, ...params }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  try {
    // set form submitting
    dispatch(setAuthSubmitting());

    const { data } = await api.auth.login(params);

    dispatch(setAuthToken(data));
    dispatch(setAuthLoginName(params.loginName));

    // Get global user
    const { data: globalUser } = await api.globalUsers.getGlobalUserByLoginNameAPI(
      params.loginName,
    );
    dispatch(setGlobalUser(globalUser));

    // fetch allowed companies for the logged in user
    const { data: companies } = await api.globalCompanies.getCompaniesListByUserAPI(
      globalUser.glUserId,
    );
    if (!companies?.length) {
      dispatch(invalidateAuth(null));
      dispatch(
        showAlert({
          type: 'error',
          title: intl.formatMessage({ id: 'login.error.notAuthorized' }),
        }),
      );
      return;
    }
    dispatch(setAuthCompanyList(companies));

    dispatch(setAuthSubmitSuccess());
    if (redirectToSuccessPath) {
      dispatch(navigateTo(redirectToSuccessPath));
    }

    return data;
  } catch (error) {
    dispatch(
      setAuthError({
        title: intl.formatMessage({ id: 'login.error.title' }),
        message: intl.formatMessage({ id: 'login.error' }),
      }),
    );
    dispatch(setAuthSubmitError());
  }
});

export const loginCompany = createAsyncThunk<
  void,
  ILoginCompanyParams & IRedirectTo,
  GlobalThunkConfig
>(
  'AUTH/LOGIN_COMPANY',
  async function handleLoginCompany({ VATId, redirectToSuccessPath }, ThunkAPI) {
    const { extra, dispatch } = ThunkAPI;
    const { api } = extra;

    try {
      if (!VATId) {
        throw new Error('Invalid company VATId');
      }

      // set form submitting
      dispatch(setAuthSubmitting());

      // login to company account (i.e. VATId) to retrieve access token
      const { data: accountData } = await api.auth.loginWithToken({
        companyVatId: VATId,
      });

      // set access token, it will be used in future http requests
      dispatch(
        addAccessToken({
          token: accountData.token,
          tokenMqtt: accountData.tokenMsg,
          VATId,
        }),
      );

      dispatch(setAuthSubmitSuccess());
      if (redirectToSuccessPath) {
        dispatch(navigateTo(redirectToSuccessPath));
      }
    } catch (error) {
      dispatch(setAuthSubmitError());
    }
  },
);

// Reset Password

export const setPasswordResetSent = createAction<boolean>('AUTH/SET_PASSWORD_RESET_SENT');

export const requestPasswordReset = createAsyncThunk<
  void,
  IPasswordResetRequestParams & IRedirectTo,
  GlobalThunkConfig
>('AUTH/REQUEST_PASSWORD_RESET', async ({ redirectToSuccessPath, ...params }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  try {
    dispatch(setAuthSubmitting());

    await api.auth.requestPasswordReset(params);
    dispatch(setPasswordResetSent(true));

    dispatch(setAuthSubmitSuccess());
    if (redirectToSuccessPath) {
      dispatch(navigateTo(redirectToSuccessPath));
    }
  } catch (error) {
    dispatch(setAuthError({ title: intl.formatMessage({ id: 'resetPassword.error' }) }));
  }
});

export const updatePassword = createAsyncThunk<
  void,
  IPasswordUpdateParams & IRedirectTo,
  GlobalThunkConfig
>('AUTH/UPDATE_PASSWORD', async ({ redirectToSuccessPath, ...params }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  try {
    dispatch(setAuthSubmitting());

    await api.auth.updatePassword(params);

    dispatch(
      showToastAlert({
        type: 'success',
        title: '',
        message: intl.formatMessage({ id: 'resetPassword.toast.success' }),
      }),
    );

    dispatch(setAuthSubmitSuccess());
    if (redirectToSuccessPath) {
      dispatch(navigateTo(redirectToSuccessPath));
    }
  } catch (error) {
    // If not an auth error show alert.
    if (isAPIError(error)) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'login',
          action: 'save',
        }),
      );
    }
  }
});
