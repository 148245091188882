import { AxiosResponse } from 'axios';

import {
  IPurchaseInvoice,
  IPurchaseInvoiceReconciliationCandidate,
  IPurchaseInvoicesReconciliationInfo,
} from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

type IPurchaseInvoicesFilters = {
  orderBy: { field: string; direction: 'ASC' | 'DESC' }[];
  fields: string[];
};

const defaultFilters: IPurchaseInvoicesFilters = {
  orderBy: [
    {
      field: 'issuedDate',
      direction: 'DESC',
    },
  ],
  fields: [
    'purchaseInvoiceId',
    'supplier.name',
    'supplier.supplierId',
    'docType.name',
    'docNumber',
    'issuedDate',
    'totals.total',
  ],
};

export const fetchPurchaseInvoiceListFromAPI = (
  filters: Partial<IPurchaseInvoicesFilters>,
): Promise<AxiosResponse<IPurchaseInvoice[]>> =>
  axios.post(
    `${VITE_API_URL}/purchases/invoices/list`,
    { ...defaultFilters, ...filters },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchPurchaseInvoiceFromAPI = (
  purchaseInvoiceId: number,
): Promise<AxiosResponse<IPurchaseInvoice>> =>
  axios.get(`${VITE_API_URL}/purchases/invoices/${purchaseInvoiceId}`);

export const fetchPurchaseInvoicesReconciliationFromAPI = (
  year: number,
  month: number,
): Promise<AxiosResponse<IPurchaseInvoicesReconciliationInfo>> =>
  axios.get(`${VITE_API_URL}/purchases/invoices/reconciliation?year=${year}&month=${month}`);

export const fetchBankReconciliationWithdrawalCandidateInvoicesFromAPI = (
  total: number,
): Promise<AxiosResponse<IPurchaseInvoiceReconciliationCandidate[]>> =>
  axios.post(
    `${VITE_API_URL}/purchases/invoices/list`,
    {
      orderBy: [
        {
          field: 'issuedDate',
          direction: 'DESC',
        },
      ],
      fields: [
        'purchaseInvoiceId',
        'supplier.name',
        'docType.docTypeId',
        'docType.name',
        'docNumber',
        'issuedDate',
        'totals.total',
        'totals.currencyCode',
      ],
      filterBy: [
        { field: 'totals.total', operator: '=', value: total },
        {
          field: 'notin_paymentsDetail',
          operator: '=',
          value: 'true',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const createPurchaseInvoiceFromAPI = (
  invoice: IPurchaseInvoice,
): Promise<AxiosResponse<IPurchaseInvoice>> =>
  axios.post(`${VITE_API_URL}/purchases/invoices`, invoice);

export const updatePurchaseInvoiceFromAPI = (
  invoice: IPurchaseInvoice,
): Promise<AxiosResponse<IPurchaseInvoice>> =>
  axios.put(`${VITE_API_URL}/purchases/invoices/${invoice.purchaseInvoiceId}`, invoice);

export const deletePurchaseInvoiceFromAPI = (purchaseInvoiceId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/purchases/invoices/${purchaseInvoiceId}`);
