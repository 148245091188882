import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ICellEditorParams } from '@ag-grid-community/core';
import { useColorMode } from '@chakra-ui/react';

import {
  colors,
  CustomNumberInput,
  KEY_BACKSPACE,
  KEY_DELETE,
  KEY_DOWN,
  KEY_ENTER,
  KEY_TAB,
  KEY_UP,
} from '@laudus/shared-ui';

interface IPayrollLineAmountEditorComponentParams extends ICellEditorParams {
  decimals?: number;
  max?: number;
  min?: number;
  name: string;
}

const PayrollLineAmountEditorComponent: React.ForwardRefRenderFunction<
  any,
  IPayrollLineAmountEditorComponentParams
> = (props, ref) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<number>(props.value);
  const [discardFixedValue, setDiscardFixedValue] = useState(false);

  const { colorMode } = useColorMode();

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        if (discardFixedValue) {
          return null;
        } else if (isNaN(Number(value))) {
          return props.value;
        }
        return value;
      },
    };
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value));
  };

  const handleKeyDown = (event: any) => {
    // Any of these keys represent the users intent to movement away from the current cell and
    // therefore we must commit the value so that it's persisted to state
    if (
      event.key === KEY_ENTER ||
      event.key === KEY_TAB ||
      event.key === KEY_UP ||
      event.key === KEY_DOWN
    ) {
      event.preventDefault();
      setValue(value);
      props.context.handleKeyPress(event);
      return;
    }

    // Special keys to discard a 'fixed by user' value in Payroll employees remuneration grid.
    if ((event.key === KEY_DELETE || event.key === KEY_BACKSPACE) && event.altKey) {
      event.preventDefault();
      event.stopPropagation();
      setDiscardFixedValue(true);
    }
  };

  const handleRowDataUpdated = () => {
    const remunerationConceptId = props.column.getColId().split('.')[1];
    const newValue = props.node.data.values[remunerationConceptId]?.value;
    if (newValue && newValue !== props.value) {
      setValue(newValue);
    }
  };

  useEffect(() => {
    inputRef?.current?.focus();

    inputRef?.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      inputRef?.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [value]);

  useEffect(() => {
    props.api.addEventListener('rowDataUpdated', handleRowDataUpdated);

    return () => {
      props.api.removeEventListener('rowDataUpdated', handleRowDataUpdated);
    };
  }, []);

  useEffect(() => {
    if (discardFixedValue) {
      props.stopEditing(true);
    }
  }, [discardFixedValue]);

  return (
    <CustomNumberInput
      max={props.max}
      min={props.min}
      name={props.name}
      onChange={handleChange}
      precision={props.decimals}
      ref={inputRef}
      // Prevent arrow keys increments and decrements in the first / last grid row.
      step={0}
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        textAlign: 'right',
        padding: '0 1rem',
        color: colorMode === 'dark' ? colors.white : colors.black,
        background: colorMode === 'dark' ? colors.darkGrey500 : colors.white,
      }}
      value={value}
    />
  );
};

PayrollLineAmountEditorComponent.displayName = 'PayrollLineAmountEditor';

export const PayrollLineAmountEditor = forwardRef(PayrollLineAmountEditorComponent);
