import { createReducer } from '@reduxjs/toolkit';

import { IExCaja } from '@laudus/types';

import {
  clearExCaja,
  clearExCajaDraft,
  removeExCajaFromList,
  setExCaja,
  setExCajaDraft,
  setExCajaDraftValues,
  setExCajasList,
  updateExCajasList,
} from '.';

export const EXCAJAS_TAB_ID = 'exCajas';

export interface IExCajasState {
  current: IExCaja;
  draft: IExCaja;
  list: IExCaja[];
}

export const EXCAJA_EMPTY: IExCaja = {
  exCajaId: 0,
  name: '',
  percentage: 0,
  previredId: '',
  electronicBookId: '',
  IPSId: '',
  regimeIPS: '',
  notes: '',
};

export const initialExCajasState: IExCajasState = {
  current: EXCAJA_EMPTY,
  draft: EXCAJA_EMPTY,
  list: [],
};

export const exCajasReducer = createReducer(initialExCajasState, (builder) => {
  builder
    .addCase(clearExCaja, (state) => {
      return { ...state, current: EXCAJA_EMPTY };
    })
    .addCase(clearExCajaDraft, (state) => {
      return { ...state, draft: EXCAJA_EMPTY };
    })
    .addCase(setExCaja, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setExCajaDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setExCajaDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setExCajasList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateExCajasList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.exCajaId !== action.payload.exCajaId), action.payload],
      };
    })
    .addCase(removeExCajaFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.exCajaId !== action.payload),
      };
    })
    .addDefaultCase((state) => state);
});
