import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  IPurchaseInvoice,
  IPurchasePayment,
  IPurchasePaymentsInvoices,
  IPurchasePaymentsOtherDocuments,
  IPurchasePaymentTableData,
  IStartEditingParams,
  ISupplierInfo,
} from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

import { getPurchasePaymentDraft } from './selectors';

// PurchasePayments Tab action creators
export const addHomePurchasePaymentTab = () =>
  addTab({
    tab: {
      id: 'purchasesPayments',
      title: intl.formatMessage({ id: 'purchasesPayments.tabTitle' }),
      path: 'pages/PurchasePayments/PurchasePayments',
      isRemovable: true,
    },
  });

export const addViewPurchasePaymentTab = (id?: number) =>
  addTab({
    tab: {
      id: 'purchasesPayments',
      title: intl.formatMessage({ id: 'purchasesPayments.tabTitle' }),
      path: 'pages/PurchasePayments/PurchasePaymentsView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewPurchasePaymentTab = () =>
  addTab({
    tab: {
      id: 'purchasesPayments',
      title: intl.formatMessage({ id: 'purchasesPayments.tabTitle' }),
      path: 'pages/PurchasePayments/PurchasePaymentsNew',
      isRemovable: true,
    },
  });

export const addEditPurchasePaymentTab = () =>
  addTab({
    tab: {
      id: 'purchasesPayments',
      title: intl.formatMessage({ id: 'purchasesPayments.tabTitle' }),
      path: 'pages/PurchasePayments/PurchasePaymentsEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearPurchasePayment = createAction('PURCHASES_PAYMENTS/CLEAR');

export const clearPurchasePaymentDraft = createAction('PURCHASES_PAYMENTS/CLEAR_DRAFT');

export const setPurchasePayment = createAction<IPurchasePayment>(
  'PURCHASES_PAYMENTS/SET_PURCHASE_PAYMENT',
);

export const setPurchasePaymentDraft = createAction<IPurchasePayment>(
  'PURCHASES_PAYMENTS/SET_PURCHASE_PAYMENT_DRAFT',
);

export const setPurchasePaymentDraftValues = createAction<Partial<IPurchasePayment>>(
  'PURCHASES_PAYMENTS/SET_PURCHASE_PAYMENT_DRAFT_VALUE',
);

export const clearPurchasePaymentOtherDocumentDraft = createAction(
  'PURCHASES_PAYMENTS/CLEAR_OTHER_DOCUMENT_DRAFT',
);

export const setPurchasePaymentOtherDocumentDraft = createAction<IPurchasePaymentsOtherDocuments>(
  'PURCHASES_PAYMENTS/SET_OTHER_DOCUMENT_DRAFT',
);

export const setPurchasePaymentOtherDocumentDraftValues = createAction<
  Partial<IPurchasePaymentsOtherDocuments>
>('PURCHASES_PAYMENTS/SET_OTHER_DOCUMENT_DRAFT_VALUE');

export const clearPurchasePaymentInvoicesDraft = createAction(
  'PURCHASES_PAYMENTS/CLEAR_PURCHASES_INVOICES_DRAFT',
);

export const setPurchasePaymentInvoicesDraft = createAction<IPurchasePaymentsInvoices>(
  'PURCHASES_PAYMENTS/SET_PURCHASES_INVOICES_DRAFT',
);

export const setPurchasePaymentInvoicesDraftValues = createAction<
  Partial<IPurchasePaymentsInvoices>
>('PURCHASES_PAYMENTS/SET_PURCHASES_INVOICES_DRAFT_VALUE');

export const setPurchasePaymentList = createAction<IPurchasePayment[]>(
  'PURCHASES_PAYMENTS/SET_LIST',
);

export const updatePurchasePaymentList = createAction<IPurchasePayment>(
  'PURCHASES_PAYMENTS/UPDATE_LIST',
);

export const removePurchasePaymentFromList = createAction<number>(
  'PURCHASES_PAYMENTS/REMOVE_FROM_LIST',
);

export const duplicatePurchasePayment = createAction<IPurchasePayment>(
  'PURCHASES_PAYMENTS/DUPLICATE',
);

export const setPurchasePaymentIsEditing = createAction<boolean>('PURCHASES_PAYMENTS/SET_EDITING');

//Complex actions
export const fetchPurchasesPaymentsList = createAsyncThunk<void, void, AppThunkConfig>(
  'PURCHASES_PAYMENTS/FETCH_PURCHASES_PAYMENTS_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('purchase-payments'));
    try {
      const { data } = await api.purchasePayments.fetchPurchasesPaymentsListAPI();
      dispatch(setPurchasePaymentList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'purchasesPayments',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('purchase-payments'));
    }
  },
);

export const fetchPurchasePayment = createAsyncThunk<void, number, AppThunkConfig>(
  'PURCHASES_PAYMENTS/FETCH_PURCHASE_PAYMENT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('purchase-payments'));
    try {
      const { data } = await api.purchasePayments.fetchPurchasePaymentAPI(id);
      dispatch(setPurchasePayment(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'purchasesPayments',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('purchase-payments'));
    }
  },
);

export const setOtherDocumentsField = createAsyncThunk<
  void,
  Partial<IPurchasePaymentsOtherDocuments>,
  AppThunkConfig
>('PURCHASES_PAYMENTS/UPDATE_OTHER_DOCUMENTS_FIELD', (entity, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;
  const { purchasePayments } = getState();

  if (entity.originalAmount) {
    dispatch(
      setPurchasePaymentOtherDocumentDraftValues({
        originalAmount: entity.originalAmount,
        amount: entity.originalAmount * purchasePayments.otherDocumentDraft.parityToMainCurrency,
      }),
    );
  } else if (entity.amount) {
    dispatch(
      setPurchasePaymentOtherDocumentDraftValues({
        amount: entity.amount,
        originalAmount: entity.amount / purchasePayments.otherDocumentDraft.parityToMainCurrency,
      }),
    );
  } else if (entity.parityToMainCurrency) {
    dispatch(
      setPurchasePaymentOtherDocumentDraftValues({
        parityToMainCurrency: entity.parityToMainCurrency,
        amount: entity.parityToMainCurrency * purchasePayments.otherDocumentDraft.originalAmount,
      }),
    );
  } else if (entity.relatedTo) {
    const { supplierId, name, VATId } = entity.relatedTo as ISupplierInfo;
    dispatch(
      setPurchasePaymentOtherDocumentDraftValues({
        relatedTo: {
          relatedId: supplierId ?? 0,
          name: name ?? '',
          VATId: VATId?.toString() ?? '',
        },
      }),
    );
  } else {
    dispatch(setPurchasePaymentOtherDocumentDraftValues(entity));
  }
});

export const setOtherDocumentsRelatedToField = createAsyncThunk<
  void,
  ISupplierInfo,
  AppThunkConfig
>('PURCHASES_PAYMENTS/UPDATE_OTHER_DOCUMENTS_SUPPLIER_FIELD', (entity, ThunkAPI) => {
  const { dispatch } = ThunkAPI;

  const { supplierId, name, VATId } = entity;

  dispatch(
    setPurchasePaymentOtherDocumentDraftValues({
      relatedTo: {
        relatedId: supplierId ?? 0,
        name: name ?? '',
        VATId: VATId ?? '',
      },
    }),
  );
});

export const startEditingPurchasePayment = createAsyncThunk<
  void,
  IStartEditingParams | undefined,
  AppThunkConfig
>('PURCHASES_PAYMENTS/START_EDITING', async (params, ThunkAPI) => {
  const { isNew } = params ?? {};
  const { dispatch } = ThunkAPI;
  dispatch(setPurchasePaymentIsEditing(true));

  if (isNew) {
    dispatch(addNewPurchasePaymentTab());
  } else {
    dispatch(addEditPurchasePaymentTab());
  }
});

export const startEditingNewPurchasePayment = createAsyncThunk<void, void, AppThunkConfig>(
  'PURCHASES_PAYMENTS/START_EDITING_NEW',
  async (_, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(startEditingPurchasePayment({ isNew: true }));
  },
);

export const stopEditingPurchasePayment = createAsyncThunk<
  void,
  number | undefined,
  AppThunkConfig
>('PURCHASES_PAYMENTS/STOP_EDITING', async (id, ThunkAPI) => {
  const { dispatch } = ThunkAPI;
  dispatch(setPurchasePaymentIsEditing(false));
  dispatch(addViewPurchasePaymentTab(id));
});

export const updatePurchasePaymentDocument = createAsyncThunk<
  void,
  IPurchasePaymentTableData,
  AppThunkConfig
>('PURCHASES_PAYMENTS/UPDATE_DOCUMENT', async (document, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;
  const state = getState();
  const draft = getPurchasePaymentDraft(state);

  if (document.type === 'purchaseInvoice') {
    const nextPurchasePaymentPurchaseInvoices = [...(draft.purchaseInvoices ?? [])];
    const purchaseInvoiceToEditIndex = nextPurchasePaymentPurchaseInvoices.findIndex(
      (item) => item.itemId === document.itemId,
    );

    if (purchaseInvoiceToEditIndex === -1) {
      return;
    }

    const editedReceiptSalesInvoice: IPurchasePaymentsInvoices = {
      ...nextPurchasePaymentPurchaseInvoices[purchaseInvoiceToEditIndex],
      amount: document.amount,
      originalAmount: document.originalAmount,
      parityToMainCurrency: document.parityToMainCurrency,
      currencyCode: document.currencyCode,
      costCenter: document.costCenter,
    };

    nextPurchasePaymentPurchaseInvoices.splice(
      purchaseInvoiceToEditIndex,
      1,
      editedReceiptSalesInvoice,
    );
    dispatch(
      setPurchasePaymentDraftValues({ purchaseInvoices: nextPurchasePaymentPurchaseInvoices }),
    );
  }

  if (document.type === 'otherDocument') {
    const nextPurchasePaymentOtherDocuments = [...(draft?.otherDocuments ?? [])];
    const otherDocumentToEditIndex = nextPurchasePaymentOtherDocuments?.findIndex(
      (si) => si.itemId === document.itemId,
    );

    if (otherDocumentToEditIndex === -1) {
      return;
    }

    const editedOtherDocument: IPurchasePaymentsOtherDocuments = {
      ...nextPurchasePaymentOtherDocuments[otherDocumentToEditIndex],
      amount: document.amount,
      originalAmount: document.originalAmount,
      parityToMainCurrency: document.parityToMainCurrency,
      currencyCode: document.currencyCode,
      costCenter: document.costCenter,
    };

    nextPurchasePaymentOtherDocuments.splice(otherDocumentToEditIndex, 1, editedOtherDocument);
    dispatch(setPurchasePaymentDraftValues({ otherDocuments: nextPurchasePaymentOtherDocuments }));
  }
});

export const removePurchasePaymentDocument = createAsyncThunk<
  void,
  IPurchasePaymentTableData,
  AppThunkConfig
>('PURCHASES_PAYMENTS/REMOVE_DOCUMENT', async (documentToRemove, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;
  const state = getState();
  const draft = getPurchasePaymentDraft(state);

  if (documentToRemove.type === 'purchaseInvoice') {
    const nextTableData = (draft.purchaseInvoices ?? []).filter(
      (item) => item.itemId !== documentToRemove.itemId,
    );
    dispatch(setPurchasePaymentDraftValues({ purchaseInvoices: nextTableData }));
  }

  if (documentToRemove.type === 'otherDocument') {
    const nextTableData = (draft.otherDocuments ?? []).filter(
      (item) => item.itemId !== documentToRemove.itemId,
    );
    dispatch(setPurchasePaymentDraftValues({ otherDocuments: nextTableData }));
  }
});

export const addPurchasePaymentPurchaseInvoiceDocument = createAsyncThunk<
  void,
  IPurchaseInvoice,
  AppThunkConfig
>('PURCHASES_PAYMENTS/ADD_PURCHASE_INVOICE_DOCUMENT', async (purchaseInvoice, ThunkAPI) => {
  const { extra, dispatch, getState } = ThunkAPI;
  const { api } = extra;
  const state = getState();
  const draft = getPurchasePaymentDraft(state);

  const { purchaseInvoiceId } = purchaseInvoice;

  if (!purchaseInvoiceId) {
    return;
  }

  try {
    dispatch(startRequest('purchasesPayments'));
    const { data } = await api.purchaseInvoices.fetchPurchaseInvoiceFromAPI(purchaseInvoiceId);

    const newPurchasePaymentPurchaseInvoice: IPurchasePaymentsInvoices = {
      itemId: getNegativeUniqueNumericId(),
      docType: data.docType,
      docNumber: data.docNumber,
      supplier: data.supplier,
      originalAmount: data.totalsOriginalCurrency?.total ?? 0,
      currencyCode: data.totalsOriginalCurrency?.currencyCode ?? 'CLP',
      parityToMainCurrency: data.totalsOriginalCurrency?.parity ?? 1,
      amount: data.totals?.total ?? 0,
      costCenter: data.costCenter ?? null,
    };

    const nextPurchaseInvoices = [...(draft.purchaseInvoices ?? [])];
    nextPurchaseInvoices.push(newPurchasePaymentPurchaseInvoice);
    dispatch(setPurchasePaymentDraftValues({ purchaseInvoices: nextPurchaseInvoices }));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'purchasesPayments',
        action: 'read',
      }),
    );
  } finally {
    dispatch(endRequest('purchasesPayments'));
  }
});

export const addPurchasePaymentOtherDocument = createAsyncThunk<
  void,
  IPurchasePaymentsOtherDocuments,
  AppThunkConfig
>('PURCHASES_PAYMENTS/ADD_OTHER_DOCUMENT', async (otherDocument, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;
  const state = getState();
  const draft = getPurchasePaymentDraft(state);

  const newPurchasePaymentOtherDocument: IPurchasePaymentsOtherDocuments = {
    ...otherDocument,
    itemId: getNegativeUniqueNumericId(),
  };

  const newOtherDocuments = [...(draft.otherDocuments ?? [])];
  newOtherDocuments.push(newPurchasePaymentOtherDocument);
  dispatch(setPurchasePaymentDraftValues({ otherDocuments: newOtherDocuments }));
});
