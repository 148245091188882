import { ReactNode } from 'react';

import { locale, messages } from '../messages';
import { IntlProvider as ReactIntlProvider } from '../react-intl';

export interface IIntlProviderProps {
  children: ReactNode;
  defaultLocale?: string;
}

export function IntlProvider({ children, defaultLocale = 'es' }: IIntlProviderProps) {
  return (
    // @ts-expect-error todo fix - 'IntlProvider' cannot be used as a JSX component.
    <ReactIntlProvider defaultLocale={defaultLocale} locale={locale} messages={messages}>
      {/* @ts-expect-error todo ? */}
      {children}
    </ReactIntlProvider>
  );
}
