/* eslint-disable @typescript-eslint/no-use-before-define */
import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  ICustomField,
  ISalesOrder,
  ISalesOrderItem,
  ISalesQuote,
  IUser,
  QuantityDelivered,
  TRACE_FROM_STEP_ORIGIN,
} from '@laudus/types';

import { SALES_ORDER_EMPTY } from '../../constants/salesOrders';

import {
  copyCustomFieldsFromOneEntityToAnother,
  copyNotesFromSalesDocumentToAnother,
  ensureSalesDocumentCustomerMatch,
  ensureSalesDocumentIsNotNulled,
  ensureSalesDocumentMatchTheUserBranchRestriction,
  ensureSalesQuoteIsApproved,
  getQuantityDeliveredOfSalesItem,
  getQuantityToCopyToSalesItem,
} from './genericFunctions';

export async function createSalesOrderFromSalesQuote({
  initialOrder = { ...SALES_ORDER_EMPTY },
  salesQuote,
  user,
  customFieldsForSalesOrders = [],
  customFieldsForSalesOrdersItems = [],
  customFieldsForSalesQuotes = [],
  customFieldsForSalesQuotesItems = [],
  shouldCopyNotesToOrder = false,
  quantityDeliveredFromQuote = [],
  doesSalesQuoteHaveToBeApproved = false,
  doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts = false,
}: {
  initialOrder?: ISalesOrder;
  salesQuote: ISalesQuote;
  user: IUser;
  customFieldsForSalesOrders?: ICustomField[];
  customFieldsForSalesOrdersItems?: ICustomField[];
  customFieldsForSalesQuotes?: ICustomField[];
  customFieldsForSalesQuotesItems?: ICustomField[];
  shouldCopyNotesToOrder?: boolean;
  quantityDeliveredFromQuote?: QuantityDelivered;
  doesSalesQuoteHaveToBeApproved?: boolean;
  doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts?: boolean;
}): Promise<ISalesOrder> {
  // Those can throw an error
  ensureSalesDocumentIsNotNulled({ salesDocument: salesQuote });
  ensureSalesQuoteIsApproved({
    salesQuote,
    doesSalesQuoteHaveToBeApproved,
    doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts,
  });
  ensureSalesDocumentMatchTheUserBranchRestriction({
    user,
    salesDocument: salesQuote,
  });
  ensureSalesDocumentCustomerMatch({
    firstSalesDocument: initialOrder,
    secondSalesDocument: salesQuote,
  });

  // Start filling the order
  const order = { ...initialOrder };

  // Fill the order fields from the sales quote fields
  copyHeaderFieldsFromQuoteToOrder({ salesQuote, order });
  copyCustomFieldsFromOneEntityToAnother({
    entityToGetCustomFieldsFrom: salesQuote,
    customFieldsForEntityToGetCustomFieldsFrom: customFieldsForSalesQuotes,
    entityToCopyCustomFieldsInto: order,
    customFieldsForEntityToCopyCustomFieldsInto: customFieldsForSalesOrders,
  });

  if (shouldCopyNotesToOrder) {
    copyNotesFromSalesDocumentToAnother({
      originSalesDocument: salesQuote,
      newSalesDocument: order,
    });
  }

  assignDocTypeToOrder({ order });

  // Fill the order items from the sales quote items
  await copyItemsFromQuoteToOrder({
    salesQuote,
    order,
    quantityDelivered: quantityDeliveredFromQuote,
    customFieldsForSalesOrdersItems,
    customFieldsForSalesQuotesItems,
  });

  // Return the filled order
  return order;
}

function copyHeaderFieldsFromQuoteToOrder({
  salesQuote,
  order,
}: {
  salesQuote: ISalesQuote;
  order: ISalesOrder;
}) {
  const fieldsToCopy: (keyof ISalesQuote & keyof ISalesOrder)[] = [
    'customer',
    'contact',
    'salesman',
    'dealer',
    'term',
    'priceList',
    'branch',
    'deliveryCost',
    'deliveryNotes',
    'carrier',
    'deliveryAddress',
  ];

  fieldsToCopy.forEach((salesQuoteProperty) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (order as any)[salesQuoteProperty] = salesQuote[salesQuoteProperty];
  });
}

function assignDocTypeToOrder({ order }: { order: ISalesOrder }) {
  // We don't assign docType to orders
  order.docType = {
    docTypeId: null,
    name: '',
  };
}

async function copyItemsFromQuoteToOrder({
  salesQuote,
  order,
  quantityDelivered,
  customFieldsForSalesQuotesItems,
  customFieldsForSalesOrdersItems,
}: {
  salesQuote: ISalesQuote;
  order: ISalesOrder;
  quantityDelivered: QuantityDelivered;
  customFieldsForSalesQuotesItems: ICustomField[];
  customFieldsForSalesOrdersItems: ICustomField[];
}) {
  const salesOrderItems: ISalesOrderItem[] = [];
  const salesQuoteItems = salesQuote.items ?? [];

  // Get only salesQuoteItems that haven't been completely delivered
  const salesQuoteItemsThatHaventBeenCompletelyDelivered = salesQuoteItems.filter(
    (salesQuoteItem) => {
      const itemQuantityDelivered = getQuantityDeliveredOfSalesItem({
        quantityDelivered,
        salesItem: salesQuoteItem,
      });

      const hasItemBeenCompletelyDelivered =
        !!itemQuantityDelivered && itemQuantityDelivered.delivered >= salesQuoteItem.quantity;

      return !hasItemBeenCompletelyDelivered;
    },
  );

  // Copy each item
  for (const salesQuoteItem of salesQuoteItemsThatHaventBeenCompletelyDelivered) {
    const quantityToCopy = getQuantityToCopyToSalesItem({
      quantityDelivered,
      salesItem: salesQuoteItem,
    });

    // Create a new item and fill its information
    const newOrderItem: ISalesOrderItem = {
      ...salesQuoteItem,
      quantity: quantityToCopy,
      itemId: getNegativeUniqueNumericId(),
      traceFrom: [
        {
          fromId: salesQuoteItem.itemId,
          fromStep: TRACE_FROM_STEP_ORIGIN.QUOTES,
        },
      ],
    };

    copyCustomFieldsFromOneEntityToAnother({
      entityToGetCustomFieldsFrom: salesQuoteItem,
      customFieldsForEntityToGetCustomFieldsFrom: customFieldsForSalesQuotesItems,
      entityToCopyCustomFieldsInto: newOrderItem,
      customFieldsForEntityToCopyCustomFieldsInto: customFieldsForSalesOrdersItems,
    });

    salesOrderItems.push(newOrderItem);
  }

  // Add new items
  const newOrderItems = salesOrderItems.concat(order.items ?? []);

  order.items = newOrderItems;
}
