export const en: Record<string, string> = {
  'auth.login': 'Login',
  'forms.required': 'Required',
  'login.userName': 'Username',
  'login.password': 'Password',
  'login.error': 'There was an error on the server. Please try again later.',
  'login.session.expired': 'Your session has expired. Please log in again.',
  'statistics.tabTitle': 'Statistics',
  'charges.tabTitle': 'Charges',
};
