import { createReducer } from '@reduxjs/toolkit';

import {
  IEmployee,
  IInitialCompanyParameters,
  IPayroll,
  IPayrollListItem,
  IWagesParameters,
} from '@laudus/types';

import {
  clearInitialCompanyParameters,
  clearPayroll,
  clearPayrollDraft,
  clearRemovingEmployee,
  duplicatePayroll,
  removePayrollFromList,
  setInitialCompanyParameters,
  setPayroll,
  setPayrollDraft,
  setPayrollDraftValues,
  setPayrollEditing,
  setPayrollList,
  setRemovingEmployee,
  setWagesParameters,
  updatePayrollDraftLines,
  updatePayrollList,
} from './actions';
import { COMPANY_PARAMETERS_EMPTY } from './constants';

export interface IPayrollState {
  list: IPayrollListItem[];
  current: IPayroll;
  draft: IPayroll;
  editing: boolean;
  initialCompanyParameters: IInitialCompanyParameters;
  wagesParameters: IWagesParameters | null;
  removingEmployee: IEmployee | null;
}

export const PAYROLL_EMPTY: IPayroll = {
  payrollId: '',
  date: '',
  createAccounting: true,
  journalEntry: {
    journalEntryId: 0,
    journalEntryNumber: 0,
    type: '',
  },
  notes: '',
  createdBy: {
    userId: '',
    name: '',
  },
  createdAt: '',
  modifiedBy: {
    userId: '',
    name: '',
  },
  modifiedAt: '',
  lines: [],
};

export const initialPayrollState: IPayrollState = {
  list: [],
  current: PAYROLL_EMPTY,
  draft: PAYROLL_EMPTY,
  editing: false,
  initialCompanyParameters: COMPANY_PARAMETERS_EMPTY,
  wagesParameters: null,
  removingEmployee: null,
};

export const payrollReducer = createReducer(initialPayrollState, (builder) => {
  builder
    .addCase(clearPayroll, (state) => {
      return { ...state, current: PAYROLL_EMPTY };
    })
    .addCase(clearPayrollDraft, (state) => {
      return {
        ...state,
        draft: PAYROLL_EMPTY,
        initialCompanyParameters: COMPANY_PARAMETERS_EMPTY,
        activeEmployeeList: [],
      };
    })
    .addCase(setPayroll, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setPayrollDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setPayrollDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(updatePayrollDraftLines, (state, action) => {
      const updatedEmployees = action.payload.map((line) => line.employee.employeeId);
      return {
        ...state,
        draft: {
          ...state.draft,
          lines: [
            ...state.draft.lines.filter(
              (line) => !updatedEmployees.includes(line.employee.employeeId),
            ),
            ...action.payload,
          ],
        },
      };
    })
    .addCase(setPayrollList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updatePayrollList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((b) => b.payrollId !== action.payload.payrollId),
          action.payload,
        ],
      };
    })
    .addCase(removePayrollFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((b) => b.payrollId !== action.payload),
      };
    })
    .addCase(duplicatePayroll, (state) => {
      // There is no sense on duplicating a payroll but at this moment this action
      // is needed for the CRUD context, so we clear the payroll instead.
      return { ...state, draft: PAYROLL_EMPTY };
    })
    .addCase(clearInitialCompanyParameters, (state) => {
      return { ...state, initialCompanyParameters: COMPANY_PARAMETERS_EMPTY };
    })
    .addCase(setInitialCompanyParameters, (state, action) => {
      return {
        ...state,
        initialCompanyParameters: action.payload,
      };
    })
    .addCase(setWagesParameters, (state, action) => {
      return { ...state, wagesParameters: action.payload };
    })
    .addCase(clearRemovingEmployee, (state) => {
      return { ...state, removingEmployee: null };
    })
    .addCase(setRemovingEmployee, (state, action) => {
      return { ...state, removingEmployee: action.payload };
    })
    .addCase(setPayrollEditing, (state, action) => {
      return { ...state, editing: action.payload };
    })
    .addDefaultCase((state) => state);
});
