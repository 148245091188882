import { forwardRef } from 'react';

import { getCostCenterList, useAppSelector } from '@laudus/redux-app';

import { SelectEditor } from './SelectEditor';

const SelectCostCenterEditorComponent: React.ForwardRefRenderFunction<any, any> = (props, ref) => {
  const costCenterList = useAppSelector(getCostCenterList);

  const options = costCenterList.map((costCenter) => ({
    label: costCenter.name as string,
    value: costCenter.costCenterId as string,
  }));

  return <SelectEditor {...props} options={options} ref={ref} />;
};

SelectCostCenterEditorComponent.displayName = 'SelectCostCenterEditor';

export const SelectCostCenterEditor = forwardRef(SelectCostCenterEditorComponent);
