import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString } from '@laudus/shared-utils';
import { ILot } from '@laudus/types';

import {
  clearLot,
  clearLotDraft,
  duplicateLot,
  removeLotFromList,
  setLot,
  setLotDraft,
  setLotDraftValues,
  setLotsList,
  updateLotsList,
} from './actions';

export const LOT_TAB_ID = 'lot';

export interface ILotState {
  current: ILot;
  draft: ILot;
  list: ILot[];
}

export const LOT_EMPTY: ILot = {
  lotId: 0,
  lot: undefined,
  expiration: dateToLocalISOString(new Date()),
  notes: undefined,
};
export const initialLotState: ILotState = {
  current: LOT_EMPTY,
  draft: LOT_EMPTY,
  list: [],
};

export const lotReducer = createReducer(initialLotState, (builder) => {
  builder
    .addCase(clearLot, (state) => {
      return { ...state, current: LOT_EMPTY };
    })
    .addCase(clearLotDraft, (state) => {
      return { ...state, draft: LOT_EMPTY };
    })
    .addCase(setLot, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setLotDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setLotDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setLotsList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateLotsList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.lotId !== action.payload.lotId), action.payload],
      };
    })
    .addCase(removeLotFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.lotId !== action.payload),
      };
    })
    .addCase(duplicateLot, (state, action) => {
      const { lotId, ...duplicatedLot } = action.payload;
      return { ...state, draft: duplicatedLot };
    })
    .addDefaultCase((state) => state);
});
