import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString } from '@laudus/shared-utils';
import { IProcess } from '@laudus/types';

import {
  clearProcess,
  clearProcessDraft,
  duplicateProcess,
  removeProcessFromList,
  setProcess,
  setProcessDraft,
  setProcessDraftValues,
  setProcessEditing,
  setProcessesList,
} from './actions';

export const PROCESSES_TAB_ID = 'processes';

export interface IProcessesState {
  current: IProcess;
  draft: IProcess;
  list: IProcess[];
  editing: boolean;
}

export const PROCESS_EMPTY: IProcess = {
  processId: 0,
  recipe: { recipeId: 0, name: '' },
  manHours: 0,
  nullDoc: false,
  salesOrderId: 0,
  notes: '',
  createdBy: {},
  createdAt: '',
  modifiedBy: {},
  modifiedAt: '',
  customFields: {
    additionalProp1: '',
    additionalProp2: '',
    additionalProp3: '',
  },
  stages: [],
  input: {
    createdAt: '',
    createdBy: {},
    date: dateToLocalISOString(new Date()),
    inputId: 0,
    invoiceNumber: '',
    items: [],
    modifiedAt: '',
    modifiedBy: {},
    notes: '',
    supplier: {},
    warehouse: {},
  },
  output: {
    createdAt: '',
    createdBy: {},
    date: dateToLocalISOString(new Date()),
    items: [],
    modifiedAt: '',
    modifiedBy: {},
    notes: '',
    outputId: 0,
    warehouse: {},
  },
};

export const initialProcessesState: IProcessesState = {
  current: PROCESS_EMPTY,
  draft: PROCESS_EMPTY,
  list: [],
  editing: false,
};

export const processesReducer = createReducer(initialProcessesState, (builder) => {
  builder
    .addCase(clearProcess, (state) => {
      return { ...state, current: PROCESS_EMPTY };
    })
    .addCase(clearProcessDraft, (state) => {
      return { ...state, draft: PROCESS_EMPTY };
    })
    .addCase(setProcess, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setProcessDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setProcessDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setProcessesList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(removeProcessFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.processId !== action.payload),
      };
    })
    .addCase(duplicateProcess, (state, action) => {
      const { processId, ...duplicatedProcess } = action.payload;
      return { ...state, draft: duplicatedProcess };
    })
    .addCase(setProcessEditing, (state, action) => {
      return { ...state, editing: action.payload };
    })
    .addDefaultCase((state) => state);
});
