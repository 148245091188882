import { createSelector } from '@reduxjs/toolkit';

import { IReceiptDocumentTableData } from '@laudus/types';

import { AppState } from '../../store';
import { getSalesInvoicesTotalFromItems } from '../salesInvoices';

import { ReceiptTypesCodes } from './constants';
import { IReceiptsState } from './reducer';

export const getReceiptsSlice = (state: AppState): IReceiptsState => state.receipts;

export const getReceiptsList = createSelector([getReceiptsSlice], (state) => state.list);

export const getReceipt = createSelector([getReceiptsSlice], (state) => state.current);

export const getReceiptDraft = createSelector([getReceiptsSlice], (state) => state.draft);

export const getOtherDocumentDraft = createSelector(
  [getReceiptsSlice],
  (state) => state.otherDocumentDraft,
);

export const getMixedPaymentReceiptList = createSelector(
  [getReceiptsSlice],
  (state) => state.mixedPaymentReceipt,
);

export const getReceiptTypeValueFromMixedPaymentList = (receiptType: string) =>
  createSelector([getMixedPaymentReceiptList], (list) =>
    list?.find((receipt) => receipt?.receiptType?.code === receiptType),
  );

export const getTotalOfMixedPaymentReceipts = createSelector(
  [getMixedPaymentReceiptList],
  (list) => {
    return list?.reduce((acc, receipt) => {
      return acc + (receipt?.amount ?? 0);
    }, 0);
  },
);

export const getRestOfMixedPaymentReceipts = createSelector(
  [getSalesInvoicesTotalFromItems, getTotalOfMixedPaymentReceipts],
  (totalInvoice, total) => {
    const rest = totalInvoice - total;
    return rest;
  },
);

export const getToReturnValueOfMixedPaymentReceipts = createSelector(
  [
    getSalesInvoicesTotalFromItems,
    getTotalOfMixedPaymentReceipts,
    getReceiptTypeValueFromMixedPaymentList(ReceiptTypesCodes.CASH),
  ],
  (totalInvoice, totalMixedPayment, cash) => {
    const rest = totalInvoice - totalMixedPayment;
    const cashValue = cash?.amount ?? 0;
    return rest < 0 && cashValue > 0 && cashValue > Math.abs(rest) ? Math.abs(rest) : 0;
  },
);

export const getReceiptIsEditing = createSelector([getReceiptsSlice], (state) => state.editing);

export const getReceiptEntity = createSelector(
  [getReceiptDraft, getReceipt, getReceiptIsEditing],
  (draft, current, editing) => {
    return editing ? draft : current;
  },
);

export const getReceiptsTableData = createSelector([getReceiptEntity], (entity) => {
  const receiptSalesInvoiceItems: IReceiptDocumentTableData[] =
    entity?.salesInvoices?.map((item) => ({
      VATId: item.customer?.VATId ?? '',
      amount: item.amount,
      currencyCode: item.currencyCode,
      customerName: item.customer?.name ?? '',
      description: 'Factura',
      docNumber: item.docNumber,
      itemId: item.itemId ?? 0,
      originalUnitPrice: item.originalAmount,
      parityToMainCurrency: item.parityToMainCurrency,
      type: 'salesInvoice',
      typeName: item.docType?.name ?? '',
    })) ?? [];

  const receiptOtherDocumentsItems: IReceiptDocumentTableData[] =
    entity?.otherDocuments?.map((item) => ({
      VATId: item.relatedTo?.VATId ?? '',
      amount: item.amount,
      currencyCode: item.currencyCode,
      customerName: item.relatedTo?.name ?? '',
      description: item.description,
      docNumber: '---',
      itemId: item.itemId,
      originalUnitPrice: item.originalAmount,
      parityToMainCurrency: item.parityToMainCurrency,
      type: 'otherDocument',
      typeName: item.category?.description ?? '',
    })) ?? [];

  return [...receiptSalesInvoiceItems, ...receiptOtherDocumentsItems];
});

export const getIsAmountGreaterThanTotalInvoice = createSelector(
  [getMixedPaymentReceiptList, getSalesInvoicesTotalFromItems],
  (mixedPaymentReceipts, totalInvoice) => {
    const totalMixedPayment = mixedPaymentReceipts.reduce((acc, receipt) => {
      return acc + (receipt?.amount ?? 0);
    }, 0);
    return totalMixedPayment > totalInvoice;
  },
);
