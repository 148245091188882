import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, IRequest, ISalesmen } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const addHomeSalesmenTab = () =>
  addTab({
    tab: {
      id: 'salesmen',
      title: intl.formatMessage({ id: 'salesmen.tabTitle' }),
      path: 'pages/Salesmen/Salesmen',
      isRemovable: true,
    },
  });

export const addViewSalesmenTab = (id?: number) =>
  addTab({
    tab: {
      id: 'salesmen',
      title: intl.formatMessage({ id: 'salesmen.tabTitle' }),
      path: 'pages/Salesmen/SalesmenView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewSalesmenTab = () =>
  addTab({
    tab: {
      id: 'salesmen',
      title: intl.formatMessage({ id: 'salesmen.tabTitle' }),
      path: 'pages/Salesmen/SalesmenNew',
      isRemovable: true,
    },
  });

export const addEditSalesmenTab = () =>
  addTab({
    tab: {
      id: 'salesmen',
      title: intl.formatMessage({ id: 'salesmen.tabTitle' }),
      path: 'pages/Salesmen/SalesmenEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearSalesmen = createAction('SALESMEN/CLEAR');

export const clearSalesmenDraft = createAction('SALESMEN/CLEAR_DRAFT');

export const setSalesman = createAction<ISalesmen>('SALESMEN/SET_SALESMAN');

export const setSalesmanDraft = createAction<ISalesmen>('SALESMEN/SET_SALESMAN_DRAFT');

export const setSalesmenList = createAction<ISalesmen[]>('SALESMEN/SET_LIST');

export const updateSalesmenList = createAction<ISalesmen>('SALESMEN/UPDATE_LIST');

export const removeSalesmanFromList = createAction<number>('SALESMEN/REMOVE_FROM_LIST');

export const duplicateSalesman = createAction<ISalesmen>('SALESMEN/DUPLICATE');

export const setSalesmanDraftValues = createAction<Partial<ISalesmen>>(
  'SALESMEN/SET_SALESMAN_DRAFT_VALUE',
);

// Complex actions
export const fetchSalesmenList = createAsyncThunk<
  void,
  Partial<IRequest> & IFetchWithEtagParams,
  AppThunkConfig
>('SALESMEN/FETCH_SALESMEN_LIST', async ({ eTag, silent, ...filters }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  if (!silent) {
    dispatch(startRequest('salesmen'));
  }

  try {
    const { data } = await api.salesmen.fetchSalesmenListAPI(filters);
    dispatch(setSalesmenList(Array.isArray(data) ? data : []));

    dispatch(setEtagsCurrentEtag(eTag));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'salesmen',
        action: 'list',
      }),
    );
  } finally {
    if (!silent) {
      dispatch(endRequest('salesmen'));
    }
  }
});

export const fetchSalesman = createAsyncThunk<void, number, AppThunkConfig>(
  'SALESMEN/FETCH_SALESMAN',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('salesmen'));
    try {
      const { data } = await api.salesmen.fetchSalesmenAPI(id);
      dispatch(setSalesman(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesmen',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('salesmen'));
    }
  },
);
