import { createReducer } from '@reduxjs/toolkit';

import { ICustomerCategory, IFormState } from '@laudus/types';

import {
  clearCustomerCategory,
  clearCustomerCategoryDraft,
  duplicateCustomerCategory,
  removeCustomerCategoryFromList,
  resetCustomerCategorySubmit,
  setCustomerCategory,
  setCustomerCategoryDraft,
  setCustomerCategoryDraftValues,
  setCustomerCategoryList,
  setCustomerCategorySubmitError,
  setCustomerCategorySubmitSuccess,
  setCustomerCategorySubmitting,
  updateCustomerCategoryList,
} from './actions';

const initialFormState = {
  submitting: false,
  submitError: false,
  submitSuccess: false,
};

export interface ICustomerCategoryState {
  current: ICustomerCategory;
  draft: ICustomerCategory;
  list: ICustomerCategory[];
  formState: IFormState;
}

export const CUSTOMERCATEGORY_EMPTY: ICustomerCategory = {
  fullPath: '',
  name: '',
  parentId: '',
  customerCategoryId: '',
};

export const initialCustomerCategoriesState: ICustomerCategoryState = {
  current: CUSTOMERCATEGORY_EMPTY,
  draft: CUSTOMERCATEGORY_EMPTY,
  formState: initialFormState,
  list: [],
};

export const customerCategoriesReducer = createReducer(
  initialCustomerCategoriesState,
  (builder) => {
    builder
      .addCase(clearCustomerCategory, (state) => {
        return { ...state, current: CUSTOMERCATEGORY_EMPTY };
      })
      .addCase(setCustomerCategory, (state, action) => {
        return { ...state, current: action.payload };
      })
      .addCase(setCustomerCategoryList, (state, action) => {
        return { ...state, list: action.payload };
      })
      .addCase(clearCustomerCategoryDraft, (state) => {
        return { ...state, draft: CUSTOMERCATEGORY_EMPTY };
      })
      .addCase(setCustomerCategoryDraft, (state, action) => {
        return { ...state, draft: action.payload };
      })
      .addCase(setCustomerCategoryDraftValues, (state, action) => {
        return { ...state, draft: { ...state.draft, ...action.payload } };
      })
      .addCase(updateCustomerCategoryList, (state, action) => {
        return {
          ...state,
          list: [
            ...state.list.filter((d) => d.customerCategoryId !== action.payload.customerCategoryId),
            action.payload,
          ],
        };
      })
      .addCase(removeCustomerCategoryFromList, (state, action) => {
        return {
          ...state,
          list: state.list.filter((d) => d.customerCategoryId !== action.payload),
        };
      })
      .addCase(duplicateCustomerCategory, (state, action) => {
        const { customerCategoryId, ...duplicatedCustomerCategory } = action.payload;
        return { ...state, draft: duplicatedCustomerCategory };
      })
      .addCase(resetCustomerCategorySubmit, (state) => {
        return { ...state, formState: initialFormState };
      })
      .addCase(setCustomerCategorySubmitting, (state) => {
        return {
          ...state,
          formState: { ...initialFormState, submitting: true },
        };
      })
      .addCase(setCustomerCategorySubmitError, (state) => {
        return {
          ...state,
          formState: { ...initialFormState, submitError: true },
        };
      })
      .addCase(setCustomerCategorySubmitSuccess, (state) => {
        return {
          ...state,
          formState: { ...initialFormState, submitSuccess: true },
        };
      })
      .addDefaultCase((state) => state);
  },
);
