import { createSelector } from '@reduxjs/toolkit';

import {
  cleanSalesDocument,
  translateDTEStatus,
  updateSalesItemsPricing,
  WAYBILLS,
} from '@laudus/sales-utils';
import { TRACE_FROM_STEP_ORIGIN } from '@laudus/types';

import { AppState, ISalesWaybillState } from '../..';
import { getMainCurrency } from '../currencies';

export const getSalesWaybillsSlice = (state: AppState): ISalesWaybillState => state.salesWaybills;

export const getSalesWaybillList = createSelector([getSalesWaybillsSlice], (state) => state.list);

export const getSalesWaybill = createSelector(
  [getSalesWaybillsSlice, (appState) => getMainCurrency(appState)],
  (state, { mainCurrencyDecimals }) => {
    const waybillWithPricingUpdated = updateSalesItemsPricing(state.current, mainCurrencyDecimals);

    const DTEWithStatusTranslated = translateDTEStatus({
      salesDocument: waybillWithPricingUpdated,
    });

    return { ...waybillWithPricingUpdated, DTE: DTEWithStatusTranslated };
  },
);

export const getSalesWaybillDraft = createSelector(
  [getSalesWaybillsSlice, (appState) => getMainCurrency(appState)],
  (state, { mainCurrencyDecimals }) => {
    // This prepare the sales document to be sent to the backend once the draft has been completed
    const clearDraft = cleanSalesDocument(state.draft, WAYBILLS);

    const waybillWithPricingUpdated = updateSalesItemsPricing(clearDraft, mainCurrencyDecimals);

    const DTEWithStatusTranslated = translateDTEStatus({
      salesDocument: waybillWithPricingUpdated,
    });

    return { ...waybillWithPricingUpdated, DTE: DTEWithStatusTranslated };
  },
);

export const getSalesWaybillPDFUrl = createSelector(
  [getSalesWaybillsSlice],
  (state) => state.pdfURL,
);

export const getSalesWaybillTraces = createSelector(
  [getSalesWaybillsSlice],
  (state) => state.traces,
);

export const getSalesWaybillTracesByOrigin = createSelector([getSalesWaybillsSlice], (state) => {
  const traces = state.traces;

  const tracesFromQuotes = traces.filter(
    (trace) => trace.fromStep === TRACE_FROM_STEP_ORIGIN.QUOTES,
  );
  const tracesFromOrders = traces.filter(
    (trace) => trace.fromStep === TRACE_FROM_STEP_ORIGIN.ORDERS,
  );
  const tracesFromInvoices = traces.filter(
    (trace) => trace.fromStep === TRACE_FROM_STEP_ORIGIN.INVOICES,
  );

  return {
    tracesFromQuotes,
    tracesFromOrders,
    tracesFromInvoices,
  };
});
