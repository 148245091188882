import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { mapSalesInvoiceItemsPricing } from '@laudus/sales-utils';
import { ICustomerInvoice, ICustomerPendingInvoice, ISalesInvoicesItem } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

const entityPrefix = 'CUSTOMER_STATISTICS';

export const clearCustomerStatistics = createAction(`${entityPrefix}/CLEAR`);

export const setCustomerStatistics = createAction<Record<number, ICustomerInvoice[]>>(
  `${entityPrefix}/SET_LIST`,
);

export const setCustomerPendingInvoices = createAction<Record<number, ICustomerPendingInvoice[]>>(
  `${entityPrefix}/SET_PENDING_LIST`,
);

export interface ISetCustomerPendingInvoiceDetailsParams {
  customerId: number;
  salesInvoiceId: string;
  items: ISalesInvoicesItem[];
}
export const setCustomerPendingInvoicesDetails =
  createAction<ISetCustomerPendingInvoiceDetailsParams>(`${entityPrefix}/SET_PENDING_DETAIL`);

export const fetchCustomerInvoicesList = createAsyncThunk<void, number, AppThunkConfig>(
  `${entityPrefix}/FETCH_LIST`,
  async (customerId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('customer-invoices'));
    try {
      const { data } = await api.customers.fetchCustomersInvoicesItemsListFromAPI(customerId);

      const customerInvoices: Record<string, ICustomerInvoice> = {};
      for (const customerInvoiceItem of data) {
        const invoiceKey = customerInvoiceItem.docType_name + '-' + customerInvoiceItem.docNumber;
        const {
          items_itemId,
          items_itemDescription,
          items_product_SKU,
          items_product_description,
          items_quantity,
          items_unitPrice,
          items_discountPercentage,
          ...customerInvoice
        } = customerInvoiceItem;
        if (!customerInvoices[invoiceKey]) {
          customerInvoices[invoiceKey] = customerInvoice;
          customerInvoices[invoiceKey]._details = [];
        }
        customerInvoices[invoiceKey]._details?.push({
          items_itemId,
          items_itemDescription,
          items_product_SKU,
          items_product_description,
          items_quantity,
          items_unitPrice,
          items_discountPercentage,
        });
      }
      dispatch(
        setCustomerStatistics({
          [customerId]:
            Object.keys(customerInvoices).length !== 0 ? Object.values(customerInvoices) : [],
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'customerInvoices',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('customer-invoices'));
    }
  },
);

export const fetchCustomerPendingInvoices = createAsyncThunk<void, number, AppThunkConfig>(
  `${entityPrefix}/FETCH_PENDING_LIST`,
  async (customerId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('customer-invoices'));
    try {
      const { data } = await api.customers.fetchCustomerPendingInvoicesFromAPI(customerId);
      dispatch(
        setCustomerPendingInvoices({
          [customerId]: Array.isArray(data) ? data : [],
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'customerInvoices',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('customer-invoices'));
    }
  },
);

export interface IFetchCustomerInvoiceParams {
  customerId: number;
  salesInvoiceId: string;
  mainCurrencyDecimals: number;
}
export const fetchCustomerPendingInvoice = createAsyncThunk<
  void,
  IFetchCustomerInvoiceParams,
  AppThunkConfig
>(
  `${entityPrefix}/FETCH_PENDING_DETAILS`,
  async ({ customerId, salesInvoiceId, mainCurrencyDecimals }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('customer-invoices'));
    try {
      const { data } = await api.salesInvoices.fetchSalesInvoiceFromAPI(salesInvoiceId);
      dispatch(
        setCustomerPendingInvoicesDetails({
          customerId,
          salesInvoiceId,
          items: mapSalesInvoiceItemsPricing({
            items: data.items || [],
            mainCurrencyDecimals,
          }),
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'customerInvoices',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('customer-invoices'));
    }
  },
);
