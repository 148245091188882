import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';
import { getCodesListByCategoryName } from '../codes';

import { IEmployeeContractTerminationState } from './reducer';

export const getEmployeeContractTerminationSlice = (
  state: AppState,
): IEmployeeContractTerminationState => state.employeeContractTerminations;

export const getEmployeesContractTerminationsList = createSelector(
  [getEmployeeContractTerminationSlice],
  (state) => state.list,
);

export const getEmployeeContractTermination = createSelector(
  [getEmployeeContractTerminationSlice],
  (state) => state.current,
);

export const getEmployeeContractTerminationDraft = createSelector(
  [getEmployeeContractTerminationSlice],
  (state) => state.draft,
);

export const getEmployeeContractTerminationConcepts = (category: string) => {
  return createSelector([(state) => getCodesListByCategoryName(state, category)], (codes) =>
    codes.map((code) => ({
      code: code.code,
      description: code.description,
    })),
  );
};

export const getEmployeesContractTerminationsTableData = createSelector(
  [getEmployeesContractTerminationsList],
  (list) =>
    list.map((contract) => {
      // Calculates the sum of 'amount' of all lines
      const totalAmount = contract.lines?.reduce((accumulator, line) => {
        return accumulator + (line?.amount ?? 0);
      }, 0);

      return {
        ...contract,
        totalAmount: totalAmount ?? 0,
      };
    }),
);
