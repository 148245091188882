import { createSelector } from '@reduxjs/toolkit';

import { AppState, ISavingsPlansProvidersState } from '../..';

export const getSavingsPlansProvidersSlice = (state: AppState): ISavingsPlansProvidersState =>
  state.savingsPlansProviders;

export const getSavingsPlansProvidersList = createSelector(
  [getSavingsPlansProvidersSlice],
  (state) => state.list,
);

export const getSavingsPlansProvider = createSelector(
  [getSavingsPlansProvidersSlice],
  (state) => state.current,
);

export const getSavingsPlansProviderDraft = createSelector(
  [getSavingsPlansProvidersSlice],
  (state) => state.draft,
);
