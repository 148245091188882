/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSelector } from '@reduxjs/toolkit';

import { ISupplierInvoice } from '@laudus/types';

import { AppState, ISupplierInvoicesState } from '../..';

export const getSuppliersInvoicesSlice = (state: AppState): ISupplierInvoicesState =>
  state.supplierStatistics;

export const getSuppliersInvoices = createSelector(
  [getSuppliersInvoicesSlice],
  (state) => state.list,
);

export const getInvoicesBySupplier = (supplierId: number) =>
  createSelector(
    [getSuppliersInvoices],
    (suppliersInvoices) => suppliersInvoices[supplierId] || [],
  );

export const getSortedInvoicesBySupplier = (supplierId: number) =>
  createSelector([getInvoicesBySupplier(supplierId)], (invoicesFromSupplier) =>
    sortSupplierInvoices(invoicesFromSupplier),
  );

// UTILITY FUNCTIONS FOR SELECTORS

/**
 * Returns a copy of the array of supplier invoices, sorted in this order:
 *
 * 1) By issuedDate desc
 * 2) By docType_name asc
 * 3) By docNumber desc
 *
 */
function sortSupplierInvoices(invoices: ISupplierInvoice[]): ISupplierInvoice[] {
  return [...invoices].sort((a, b) => {
    // Convert issuedDate to Date objects so it can be sortered
    const aDate = new Date(a.issuedDate);
    const bDate = new Date(b.issuedDate);

    // Sort by issuedDate desc
    if (aDate < bDate) {
      return 1;
    }

    if (aDate > bDate) {
      return -1;
    }

    // If issuedDate are the same, sort by docType_name asc
    if (a.docType_name < b.docType_name) {
      return -1;
    }

    if (a.docType_name > b.docType_name) {
      return 1;
    }

    // If issuedDate and docType_name are the same, sort by docNumber desc
    if (a.docNumber < b.docNumber) {
      return 1;
    }

    if (a.docNumber > b.docNumber) {
      return -1;
    }

    return 0;
  });
}
