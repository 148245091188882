import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const Textarea: ComponentSingleStyleConfig = {
  variants: {
    'default': {
      border: '1px solid',
      borderColor: 'neutral500',
      borderRadius: '0.4rem',
      color: 'black',
      bg: 'white',
      fontSize: '1.5rem',
      resize: 'none',
      _disabled: {
        opacity: '1',
        bg: 'white',
        color: 'black',
        borderColor: 'neutral500',
      },
      _focus: {
        borderColor: 'primary500',
        borderWidth: '2px',
      },
    },
    'grid-input': {
      bg: 'white',
      borderWidth: '0px',
      borderColor: 'neutral500',
      borderRadius: '0px',
      color: 'black',
      fontSize: '1.5rem',
      display: 'flex',
      height: '35px',
      _disabled: {
        bg: 'white',
        color: 'neutral500',
        borderWidth: '1px 1px 1px 1px',
        borderColor: 'neutral500',
      },
      _focus: {
        'borderColor': 'primary500',
        'borderWidth': '0px',
        '+.chakra-select__icon-wrapper': {
          color: 'error.100',
        },
      },
      _invalid: {
        'bg': 'none',
        'borderColor': 'error.100',
        '+.chakra-select__icon-wrapper': {
          color: 'error.100',
        },
        '_disabled': {
          'bg': 'neutral.10',
          'borderColor': 'error.100',
          '+.chakra-select__icon-wrapper': {
            color: 'error.100',
          },
        },
      },
    },
  },
  defaultProps: {
    variant: 'default',
  },
};
