import { createReducer } from '@reduxjs/toolkit';

import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import { IPriceList } from '@laudus/types';

import {
  clearPriceList,
  clearPriceListDraft,
  duplicatePriceList,
  removePriceListFromList,
  setPriceList,
  setPriceListDraft,
  setPriceListDraftValues,
  setPriceListList,
  updatePriceListList,
} from '.';

export const PRICELISTS_TAB_ID = 'priceLists';

export const PRICE_LIST_EMPTY: IPriceList = {
  currencyCode: '',
  name: '',
  notes: '',
  percentage: 100,
  priceListId: 0,
  items: [],
};

export interface IPriceListsState {
  current: IPriceList;
  draft: IPriceList;
  list: IPriceList[];
}

export const initialPriceListsState: IPriceListsState = {
  current: PRICE_LIST_EMPTY,
  draft: PRICE_LIST_EMPTY,
  list: [],
};

export const priceListsReducer = createReducer(initialPriceListsState, (builder) => {
  builder
    .addCase(clearPriceList, (state) => {
      return { ...state, current: PRICE_LIST_EMPTY };
    })
    .addCase(clearPriceListDraft, (state) => {
      return { ...state, draft: PRICE_LIST_EMPTY };
    })
    .addCase(setPriceList, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setPriceListDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setPriceListDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setPriceListList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updatePriceListList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.priceListId !== action.payload.priceListId),
          action.payload,
        ],
      };
    })
    .addCase(removePriceListFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.priceListId !== action.payload),
      };
    })
    .addCase(duplicatePriceList, (state, action) => {
      const { priceListId, ...duplicatedPriceList } = action.payload;
      const duplicatedItems = [...duplicatedPriceList.items].map((item) => {
        return { ...item, itemId: getNegativeUniqueNumericId() };
      });
      return {
        ...state,
        draft: { ...duplicatedPriceList, items: [...duplicatedItems] },
      };
    })
    .addDefaultCase((state) => state);
});
