import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IBankReconciliationState } from './reducer';

export const getBankReconciliationSlice = (state: AppState): IBankReconciliationState =>
  state.bankReconciliation;

export const getBankReconciliationFilter = createSelector(
  [getBankReconciliationSlice],
  (state) => state.filter,
);

export const getBankReconciliationBankStatementNotInJournal = createSelector(
  [getBankReconciliationSlice],
  (state) => state.bankStatementNotInJournal,
);

export const getBankReconciliationJournalNotInBankStatement = createSelector(
  [getBankReconciliationSlice],
  (state) => state.journalNotInBankStatement,
);

export const getBankReconciliationWithdrawalInfo = createSelector(
  [getBankReconciliationSlice],
  (state) => state.withdrawalReconciliation,
);

export const getGenericSuppliersAccount = createSelector(
  [getBankReconciliationSlice],
  (state) => state.genericSuppliersAccount,
);
