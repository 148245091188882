export const allowedTypes = [
  { label: 'Vacaciones', value: 'H' },
  { label: 'Enfermedad', value: 'I' },
  { label: 'Pre-Post Natal', value: 'N' },
  { label: 'Retraso', value: 'L' },
  { label: 'Accidente', value: 'A' },
  { label: 'Permiso sin goce de sueldo', value: 'P' },
  { label: 'Ausencia', value: 'S' },
  { label: 'Otros', value: 'O' },
  { label: 'LPE: Suspensión acto autoridad', value: '3' },
  { label: 'LPE: Suspensión pactada', value: '4' },
  { label: 'LPE: Reducción jornada laboral', value: '5' },
];
