import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IGroup, IRight, IUser } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

import { getGroupsList, getRightList } from './selectors';

export const addHomeGroupsTab = () =>
  addTab({
    tab: {
      id: 'groups',
      title: intl.formatMessage({ id: 'groups.tabTitle' }),
      path: 'pages/Groups/Groups',
      isRemovable: true,
    },
  });

export const addViewGroupsTab = (id?: string) =>
  addTab({
    tab: {
      id: 'groups',
      title: intl.formatMessage({ id: 'groups.tabTitle' }),
      path: 'pages/Groups/GroupsView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewGroupsTab = () =>
  addTab({
    tab: {
      id: 'groups',
      title: intl.formatMessage({ id: 'groups.tabTitle' }),
      path: 'pages/Groups/GroupsNew',
      isRemovable: true,
    },
  });

export const addEditGroupsTab = () =>
  addTab({
    tab: {
      id: 'groups',
      title: intl.formatMessage({ id: 'groups.tabTitle' }),
      path: 'pages/Groups/GroupsEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearGroup = createAction('GROUPS/CLEAR');

export const clearGroupDraft = createAction('GROUPS/CLEAR_DRAFT');

export const setGroup = createAction<IGroup>('GROUPS/SET_GROUP');

export const setGroupDraft = createAction<IGroup>('GROUPS/SET_GROUP_DRAFT');

export const setGroupDraftValues = createAction<Partial<IGroup>>('GROUPS/SET_GROUP_DRAFT_VALUE');

export const setGroupsList = createAction<IGroup[]>('GROUPS/SET_LIST');

export const setGroupsRightList = createAction<IRight[]>('GROUPS/SET_RIGHT_LIST');

export const setGroupUserList = createAction<IUser[]>('GROUPS/SET_USER_LIST');

export const updateGroupsList = createAction<IGroup>('GROUPS/UPDATE_LIST');

export const removeGroupFromList = createAction<string>('GROUPS/REMOVE_FROM_LIST');

export const duplicateGroup = createAction<IGroup>('GROUPS/DUPLICATE');

// Complex actions
export interface IFetchGroupListParams {
  force: boolean;
}
export const fetchGroupList = createAsyncThunk<void, IFetchGroupListParams, AppThunkConfig>(
  'GROUPS/FETCH_GROUPS_LIST',
  async ({ force }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    const groupList = getGroupsList(ThunkAPI.getState());
    if (!groupList.length || force) {
      try {
        dispatch(startRequest('groups'));
        const { data } = await api.groups.fetchGroupListFromAPI();
        dispatch(setGroupsList(data));
      } catch (error) {
        dispatch(
          showErrorAlert({
            error,
            prefix: 'groups',
            action: 'list',
          }),
        );
      } finally {
        dispatch(endRequest('groups'));
      }
    }
  },
);

export const fetchGroup = createAsyncThunk<void, string, AppThunkConfig>(
  'GROUPS/FETCH_GROUP',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('groups'));
    try {
      const { data } = await api.groups.fetchGroupFromAPI(id);
      dispatch(setGroup(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'groups',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('groups'));
    }
  },
);

export interface IFetchRightListParams {
  force: boolean;
}

export const fetchRightList = createAsyncThunk<void, IFetchRightListParams, AppThunkConfig>(
  'GROUPS/FETCH_GROUP_RIGHT_LIST',
  async ({ force }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    const rights = getRightList(ThunkAPI.getState());

    if (!rights.length || force) {
      try {
        dispatch(startRequest('groups'));
        const { data } = await api.groups.fetchRightListFromAPI();
        dispatch(setGroupsRightList(data));
      } catch (error) {
        dispatch(
          showErrorAlert({
            error,
            prefix: 'rights',
            action: 'read',
          }),
        );
      } finally {
        dispatch(endRequest('groups'));
      }
    }
  },
);

export const fetchGroupUserList = createAsyncThunk<void, string, AppThunkConfig>(
  'GROUPS/FETCH_GROUP_USER_LIST',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      const { data } = await api.groups.fetchGroupUserListFromAPI(id);
      dispatch(setGroupUserList(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'groups',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('groups'));
    }
  },
);
