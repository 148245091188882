import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { getSalesInvoicesFilters } from '@laudus/shared-utils';
import {
  IStatisticsDataItem,
  IStatisticsDataMapper,
  IStatisticsFilterItems,
  IStatisticsFormattedFilterItems,
  IStatisticsRemoteFieldConfiguration,
} from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { getMainCurrency } from '../currencies';
import { getCustomFieldsList } from '../customFields';
import { endDashboardCardFetching, startDashboardCardFetching } from '../dashboards/actions';
import { endRequestInTab, startRequestInTab } from '../global';
import { getProductsList } from '../products';

export const STATISTICS_TAB_ID = 'statistics';

// Simple actions
export const clearStatistics = createAction(`STATISTICS/CLEAR_STATISTICS`);

export const clearStatisticsDashboardCardsData = createAction(
  `STATISTICS/CLEAR_DASHBOARD_CARDS_DATA`,
);

export const clearStatisticsDashboardCardInfo = createAction<number>(
  'STATISTICS/CLEAR_DASHBOARD_CARD_INFO',
);

export const setStatisticsSelectedId = createAction<string>(`STATISTICS/SET_SELECTED_ID`);

export const setStatisticsFilters = createAction<IStatisticsFormattedFilterItems>(
  `STATISTICS/SET_STATISTIC_FILTER`,
);

export const clearStatisticsFilterValue = createAction<keyof IStatisticsFormattedFilterItems>(
  'STATISTICS/CLEAR_STATISTICS_FILTER_VALUE',
);

export interface ISetStatisticsDataPayload {
  data: IStatisticsDataItem[];
  cardId: number | undefined;
}
export const setStatisticsData = createAction<ISetStatisticsDataPayload>('STATISTICS/SET_DATA');

export interface ISetStatisticsFieldsPayload {
  fields: IStatisticsRemoteFieldConfiguration[];
  cardId: number | undefined;
}
export const setStatisticsFields =
  createAction<ISetStatisticsFieldsPayload>('STATISTICS/SET_FIELDS');

export interface IFetchStatisticsDataParams {
  endpoint: string;
  filters: IStatisticsFilterItems;
  mapper: IStatisticsDataMapper;
  cardId?: number;
}

export const startStatisticsRequest = createAsyncThunk<void, number | undefined, AppThunkConfig>(
  'STATISTICS/START_STATISTICS_REQUEST',
  async (cardId, ThunkAPI) => {
    const { dispatch } = ThunkAPI;

    if (cardId) {
      dispatch(startDashboardCardFetching(cardId));
    } else {
      dispatch(startRequestInTab(STATISTICS_TAB_ID));
    }
  },
);

export const endStatisticsRequest = createAsyncThunk<void, number | undefined, AppThunkConfig>(
  'STATISTICS/START_STATISTICS_REQUEST',
  async (cardId, ThunkAPI) => {
    const { dispatch } = ThunkAPI;

    if (cardId) {
      dispatch(endDashboardCardFetching(cardId));
    } else {
      dispatch(endRequestInTab(STATISTICS_TAB_ID));
    }
  },
);

export const fetchStatisticsFromReportsSalesEndpoint = createAsyncThunk<
  void,
  IFetchStatisticsDataParams,
  AppThunkConfig
>(
  'STATISTICS/FETCH_REPORTS_SALES_ENDPOINT',
  async ({ endpoint, filters, mapper, cardId }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startStatisticsRequest(cardId));

      const { data } = await api.statistics.fetchStatisticsFromReportsEndpointAPI(
        endpoint,
        filters,
      );
      dispatch(setStatisticsData({ data: mapper(data), cardId }));
    } catch (error) {
      dispatch(setStatisticsData({ data: [], cardId }));
      dispatch(
        showErrorAlert({
          error,
          prefix: 'statistics',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endStatisticsRequest(cardId));
    }
  },
);

export const fetchStatisticsFromInternalReportsEndpoint = createAsyncThunk<
  void,
  IFetchStatisticsDataParams,
  AppThunkConfig
>(
  'STATISTICS/FETCH_INTERNALS_REPORTS_ENDPOINT',
  async ({ endpoint, filters, mapper, cardId }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    const endpointHasQueryParams = endpoint.includes('?');
    if (endpointHasQueryParams) {
      const originalEndpoint = endpoint.split('?')[0];
      filters = {
        ...filters,
        ...endpoint
          .split('?')[1]
          .split('&')
          .reduce((acc, item) => {
            const [key, value] = item.split('=');
            return { ...acc, [key]: value };
          }, {}),
      };
      endpoint = originalEndpoint;
    }

    try {
      dispatch(startStatisticsRequest(cardId));

      const {
        data: { data, fields },
      } = await api.statistics.fetchStatisticsFromInternalReportsEndpointAPI(endpoint, filters);

      dispatch(setStatisticsFields({ fields: fields || [], cardId }));
      dispatch(setStatisticsData({ data: mapper(data || []), cardId }));
    } catch (error) {
      dispatch(setStatisticsData({ data: [], cardId }));
      dispatch(
        showErrorAlert({
          error,
          prefix: 'statistics',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endStatisticsRequest(cardId));
    }
  },
);

export const fetchStatisticsFromProductsStockEndpoint = createAsyncThunk<
  void,
  IFetchStatisticsDataParams,
  AppThunkConfig
>(
  'STATISTICS/FETCH_PRODUCTION_PRODUCTS_ENDPOINT',
  async ({ endpoint, filters, mapper, cardId }, ThunkAPI) => {
    const { dispatch, extra, getState } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startStatisticsRequest(cardId));

      const productList = getProductsList(getState());

      const { data } = await api.statistics.fetchStatisticsFromProductsStockEndpointAPI(
        endpoint,
        filters,
      );
      dispatch(setStatisticsData({ data: mapper(data.products, { productList }), cardId }));
    } catch (error) {
      dispatch(setStatisticsData({ data: [], cardId }));
      dispatch(
        showErrorAlert({
          error,
          prefix: 'statistics',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endStatisticsRequest(cardId));
    }
  },
);

export const fetchStatisticsFromSalesInvoicesEndpoint = createAsyncThunk<
  void,
  IFetchStatisticsDataParams,
  AppThunkConfig
>(
  'STATISTICS/FETCH_SALES_INVOICES_ENDPOINT',
  async ({ endpoint, filters, mapper, cardId }, ThunkAPI) => {
    const { dispatch, extra, getState } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startStatisticsRequest(cardId));

      const customFields = getCustomFieldsList(getState());
      const customFieldsForInvoices = customFields.filter(
        (customField) => customField.entity === 'salesInvoices',
      );
      const { mainCurrencyDecimals } = getMainCurrency(getState());
      const salesFilters = getSalesInvoicesFilters(filters);

      const extraFields: string[] = [];
      switch (endpoint) {
        case '#withoutDetails':
          extraFields.push(
            ...[
              'net',
              'VAT',
              'totals.total',
              'totalsOriginalCurrency.net',
              'totalsOriginalCurrency.currencyCode',
            ],
          );
          break;
        case '#withDetails':
          extraFields.push(
            ...[
              'items.product.sku',
              'items.product.productCategory.name',
              'items.product.description',
              'items.quantity',
              'items.unitPrice',
              'items.discountPercentage',
              'items.costCenter.name',
            ],
          );
          break;
      }

      const { data } = await api.salesInvoices.fetchSalesInvoiceListFromAPI({
        fields: [
          'docType.name',
          'docNumber',
          'issuedDate',
          'dueDate',
          'customer.name',
          'customer.VATId',
          'sourceOrderId',
          'salesman.name',
          'dealer.name',
          'branch.name',
          ...extraFields,
          ...customFieldsForInvoices.map((customField) => `customFields.${customField.name}`),
        ],
        filterBy: salesFilters,
        options: { limit: 1_000_000 },
      });
      dispatch(
        setStatisticsData({
          data: mapper(data as unknown as IStatisticsDataItem[], { mainCurrencyDecimals }),
          cardId,
        }),
      );
    } catch (error) {
      dispatch(setStatisticsData({ data: [], cardId }));
      dispatch(
        showErrorAlert({
          error,
          prefix: 'statistics',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endStatisticsRequest(cardId));
    }
  },
);

export const fetchStatisticsFromSalesOrdersEndpoint = createAsyncThunk<
  void,
  IFetchStatisticsDataParams,
  AppThunkConfig
>(
  'STATISTICS/FETCH_SALES_ORDERS_ENDPOINT',
  async ({ endpoint, filters, mapper, cardId }, ThunkAPI) => {
    const { dispatch, extra, getState } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startStatisticsRequest(cardId));

      const { mainCurrencyDecimals } = getMainCurrency(getState());
      const salesFilters = getSalesInvoicesFilters(filters);

      const extraFields: string[] = [];
      switch (endpoint) {
        case '#withoutDetails':
          extraFields.push(...['net']);
          break;
        case '#withDetails':
          extraFields.push(
            ...[
              'items.product.sku',
              'items.product.productCategory.name',
              'items.product.description',
              'items.quantity',
              'items.unitPrice',
              'items.discountPercentage',
              'items.costCenter.name',
            ],
          );
          break;
      }

      const { data } = await api.salesOrders.fetchSalesOrderListFromAPI({
        fields: [
          'salesOrderId',
          'issuedDate',
          'dueDate',
          'customer.name',
          'customer.VATId',
          'purchaseOrderNumber',
          'salesman.name',
          ...extraFields,
          'customFields.*',
        ],
        filterBy: salesFilters,
        options: { limit: 1_000_000 },
      });
      dispatch(
        setStatisticsData({
          data: mapper(data as unknown as IStatisticsDataItem[], { mainCurrencyDecimals }),
          cardId,
        }),
      );
    } catch (error) {
      dispatch(setStatisticsData({ data: [], cardId }));
      dispatch(
        showErrorAlert({
          error,
          prefix: 'statistics',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endStatisticsRequest(cardId));
    }
  },
);
