import { AxiosResponse } from 'axios';

import { intl } from '@laudus/intl';
import { cleanSalesDocument, INVOICES } from '@laudus/sales-utils';
import { dateToLocalISOString, getMessageFromError } from '@laudus/shared-utils';
import {
  IDTEDoc,
  IInvoiceReceipt,
  ISalesInvoice,
  ISalesInvoiceList,
  ISalesInvoicesFilters,
  ISalesInvoicesReconciliationInfo,
  ITraceFrom,
  SendDTEEmail,
} from '@laudus/types';

import { client as axios } from '../utils/axios';

import { postDTESendEmailAPI, postDTEToSIIAPI } from './dte';

const { VITE_API_URL } = import.meta.env;

export const defaultFilters: ISalesInvoicesFilters = {
  // options: { limit: 200 },
  orderBy: [
    {
      field: 'issuedDate',
      direction: 'DESC',
    },
  ],
  fields: [
    'salesInvoiceId',
    'customer.name',
    'customer.customerId',
    'docType.name',
    'docNumber',
    'issuedDate',
    'totals.total',
    'posId',
    'customFields.*',
  ],
};

export const fetchSalesInvoiceListFromAPI = (
  filters: Partial<ISalesInvoicesFilters> = defaultFilters,
): Promise<AxiosResponse<ISalesInvoiceList>> =>
  axios.post(
    `${VITE_API_URL}/sales/invoices/list`,
    { ...defaultFilters, ...filters },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchSalesInvoiceListByCustomerFromAPI = (
  customerId: number,
): Promise<AxiosResponse<ISalesInvoice[]>> => {
  const filterBy =
    customerId && customerId > 0 ? [{ field: 'customerId', operator: '=', value: customerId }] : [];
  return axios.post(
    `${VITE_API_URL}/sales/invoices/list`,
    {
      fields: [
        'salesInvoiceId',
        'customer.name',
        'docType.docTypeId',
        'docType.name',
        'docNumber',
        'issuedDate',
        'totals.total',
      ],
      orderBy: [
        {
          field: 'issuedDate',
          direction: 'DESC',
        },
      ],
      filterBy: filterBy,
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
};

export const fetchSalesInvoiceFromAPI = (
  salesInvoiceId: string,
): Promise<AxiosResponse<ISalesInvoice>> =>
  axios.get(`${VITE_API_URL}/sales/invoices/${salesInvoiceId}`);

export const fetchSalesInvoicesReceiptListFromAPI = (
  salesInvoiceId: string,
): Promise<AxiosResponse<IInvoiceReceipt[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/receipts/list`,
    {
      filterBy: [
        {
          field: 'salesInvoices.salesInvoiceId',
          operator: '=',
          value: salesInvoiceId,
        },
      ],
      // filterBy: [{ field: 'salesInvoices.salesInvoiceId', operator: '=', value: 'E00072072' }],
      orderBy: [{ field: 'dueDate', direction: 'ASC' }],
      fields: [
        'receiptId',
        'issuedDate',
        'dueDate',
        'salesInvoices.salesInvoiceId',
        'deposited',
        'bankOfDocument',
        'salesInvoices.amount',
        'salesInvoices.docNumber',
        'receiptType.code',
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const createSalesInvoiceFromAPI = (
  invoice: ISalesInvoice,
): Promise<AxiosResponse<ISalesInvoice>> =>
  axios.post(`${VITE_API_URL}/sales/invoices`, cleanSalesDocument(invoice, INVOICES));

export const updateSalesInvoiceFromAPI = (
  invoice: ISalesInvoice,
): Promise<AxiosResponse<ISalesInvoice>> =>
  axios.put(
    `${VITE_API_URL}/sales/invoices/${invoice.salesInvoiceId}`,
    cleanSalesDocument(invoice, INVOICES),
  );

export const deleteSalesInvoiceFromAPI = (salesInvoiceId: string): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/invoices/${salesInvoiceId}`);

export const fetchSalesInvoicePDFFromAPI = async (
  salesInvoiceId: string,
  numberOfCopies: number,
  // download = false,
): Promise<AxiosResponse> =>
  axios.get(
    `${VITE_API_URL}/sales/invoices/${salesInvoiceId}/pdf?contentDisposition=attachment&numberOfCopies=${numberOfCopies}`,
    // TODO: check if necesary
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
      },
    },
  );

export const fetchSalesInvoiceTracesFromAPI = (
  salesInvoiceId: string,
): Promise<AxiosResponse<ITraceFrom[]>> =>
  axios.get(`${VITE_API_URL}/sales/invoices/${salesInvoiceId}/traceFrom`);

export async function fetchSalesInvoicePDFUrl({
  salesInvoice,
  numberOfCopies,
}: {
  salesInvoice: ISalesInvoice;
  numberOfCopies: number;
}): Promise<string> {
  const { data } = await fetchSalesInvoicePDFFromAPI(salesInvoice.salesInvoiceId!, numberOfCopies);
  const blob = new Blob([new Uint8Array(data)], { type: 'application/pdf' });
  const urlBlob = URL.createObjectURL(blob);

  return urlBlob;
}

export const fetchSalesInvoicesReconciliationFromAPI = (
  type: string,
  year: number,
  month: number,
): Promise<AxiosResponse<ISalesInvoicesReconciliationInfo>> =>
  axios.get(
    `${VITE_API_URL}/sales/invoices/reconciliation?type=${type}&year=${year}&month=${month}`,
  );

export async function sendSalesInvoiceToSII(salesInvoice: ISalesInvoice): Promise<ISalesInvoice> {
  const { docNumber, docType } = salesInvoice;

  if (!docNumber || !docType) {
    console.error(
      `Invoice doesn't have the necessary information to be sent to SII: ${salesInvoice}`,
    );

    throw new Error(intl.formatMessage({ id: 'invoices.sendToSII.fail' }));
  }

  const { docTypeId } = docType;

  if (!docTypeId) {
    console.error(`Invoice need to have a docTypeId to be sent to SII: ${docTypeId}`);

    throw new Error(intl.formatMessage({ id: 'invoices.sendToSII.fail' }));
  }

  const documentToSend: IDTEDoc = {
    docNumber: docNumber,
    docTypeId: docTypeId,
  };
  const requestSendSII = { docs: [documentToSend] };

  const requestSendEmail: SendDTEEmail = {
    ...documentToSend,
    sendPDF: true,
  };

  // DTE/sendToSII has to be finished before calling to DTE/sendEmail
  // This is because we have to have the trackId in the database before we can send the PDF to the customer.
  // If we do not have the trackId, the electronic barCode is not going to get printed in the PDF
  const responseFromSII = await postDTEToSIIAPI(requestSendSII);

  let sentToCustomerAt: string | undefined = undefined;
  try {
    await postDTESendEmailAPI(requestSendEmail);
    sentToCustomerAt = dateToLocalISOString(new Date());
  } catch (error) {
    console.error(
      `${intl.formatMessage({
        id: 'invoices.sendEmail.fail',
      })}: ${getMessageFromError({
        error,
      })}`,
    );
  }

  const { data } = responseFromSII;
  const { trackId } = data;

  const updatedInvoice = {
    ...salesInvoice,
    DTE: {
      trackId: trackId ?? null,
      documentStatus: salesInvoice.DTE?.documentStatus ?? null,
      uploadStatus: salesInvoice.DTE?.uploadStatus ?? null,
      sentToCustomerAt: sentToCustomerAt ?? undefined,
    },
  };

  return updatedInvoice;
}
