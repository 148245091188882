import { KeyboardEvent, ReactNode } from 'react';

import {
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
} from '@chakra-ui/react';

import { IconCancel } from '@laudus/icons';

import { KEY_ENTER, KEY_ESC } from '../../constants/keyboard';

import { ModalButton } from './ModalButton';
import { ModalSize, ModalVariant } from './types';

interface IModalWindowProps {
  acceptIcon?: React.ReactElement;
  acceptButtonVariant?: string;
  cancelButtonVariant?: string;
  acceptText?: string;
  backdrop?: string;
  backgroundColor?: string;
  cancelIcon?: React.ReactElement;
  cancelText?: string;
  children?: JSX.Element | JSX.Element[] | string;
  closeIcon?: boolean;
  cancelModalDeleteText?: string;
  isOpen: boolean;
  fontSize?: string;
  title?: string;
  titlePosition?: string;
  header?: ReactNode;
  nextIcon?: React.ReactElement;
  variant?: ModalVariant;
  widthAlertButton?: string;
  heightAlertButton?: string;
  overflow?: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  resizable?: boolean;
  size?: ModalSize;
  disabled?: boolean;
  onAccept?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export const ModalWindow = ({
  acceptIcon,
  acceptButtonVariant = 'primary',
  cancelButtonVariant = 'outline',
  acceptText,
  backdrop,
  backgroundColor,
  cancelIcon,
  cancelText,
  children,
  closeIcon = true,
  isOpen,
  fontSize,
  title,
  titlePosition = 'center',
  header,
  nextIcon,
  variant,
  widthAlertButton,
  heightAlertButton,
  width: _width,
  minWidth,
  maxWidth,
  height: _height,
  minHeight,
  maxHeight,
  resizable,
  size = 'lg',
  disabled,
  onAccept,
  onCancel,
  onClose,
}: IModalWindowProps) => {
  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === KEY_ENTER) {
      onAccept?.();
    } else if (e.key === KEY_ESC) {
      onCancel?.();
    }
  };

  let height = _height;
  let width = _width;
  let margin = '1.6rem';
  if (size === 'sm') {
    height = '32rem';
    width = '32rem';
    margin = '1.6rem';
  } else if (size === 'md') {
    margin = '3.2rem';
  } else if (size === 'lg') {
    height = '64rem';
    width = '80%';
    margin = '3.2rem';
  }

  return (
    <Modal
      closeOnOverlayClick={false}
      isCentered
      isOpen={isOpen}
      motionPreset="slideInRight"
      onClose={() => onClose?.()}
      variant={variant}
    >
      <ModalOverlay backgroundColor={backdrop ?? 'modalBackdrop'} />
      <ModalContent
        backgroundColor={backgroundColor ?? 'modalBackground'}
        borderRadius="0.7rem"
        boxSizing="border-box"
        filter="grayscale(26%)"
        // TODO does not work
        // resize={resizable ? 'both' : 'none'}
        minHeight={resizable && !minHeight ? '30%' : minHeight}
        maxHeight={resizable && !maxHeight ? '100%' : maxHeight}
        height={resizable && !height ? 'unset' : height}
        minWidth={resizable && !minWidth ? '40%' : minWidth}
        maxWidth={resizable && !maxWidth ? '100%' : maxWidth}
        width={resizable && !width ? 'unset' : width}
        onKeyDown={handleKeyDown}
      >
        <Flex
          _focus={{ outline: 'none' }}
          alignItems="center"
          direction="column"
          display="flex"
          height="100%"
          margin={`0 ${margin}`}
          tabIndex={0}
          textAlign="center"
          overflow="hidden"
        >
          {closeIcon && <ModalCloseButton color="modalClose" />}
          {title || header ? (
            <ModalHeader
              display="flex"
              justifyContent={titlePosition}
              padding={`${margin} 0 0`}
              fontSize={fontSize ? fontSize : '2.4rem'}
              fontWeight="600"
              width="100%"
              color="textPrimary"
            >
              {header ?? title}
            </ModalHeader>
          ) : undefined}
          <ModalBody width="100%" margin={margin} padding="0" fontSize="1.4rem" overflowY="auto">
            {children}
          </ModalBody>
          {(onCancel && cancelText) || (onAccept && acceptText) ? (
            <ModalFooter
              width="100%"
              alignItems="center"
              display="flex"
              gridGap="2rem"
              padding={`0 0 ${margin}`}
              justifyContent={!onCancel ? 'center' : 'space-between'}
            >
              {onCancel ? (
                <ModalButton
                  leftIcon={cancelIcon ?? <IconCancel />}
                  text={cancelText}
                  variant={cancelButtonVariant}
                  backgroundColor="secondaryButtonBackground"
                  onClick={onCancel}
                />
              ) : undefined}
              {onAccept ? (
                <ModalButton
                  heightAlertButton={heightAlertButton}
                  leftIcon={acceptIcon ? acceptIcon : undefined}
                  rightIcon={nextIcon ? nextIcon : undefined}
                  text={acceptText}
                  variant={acceptButtonVariant ?? nextIcon}
                  widthAlertButton={widthAlertButton}
                  disabled={disabled}
                  onClick={onAccept}
                />
              ) : undefined}
            </ModalFooter>
          ) : undefined}
        </Flex>
      </ModalContent>
    </Modal>
  );
};
