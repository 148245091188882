import { AxiosResponse } from 'axios';

import { isNegativeNumber } from '@laudus/shared-utils';
import { ISalesTicket, SalesTicketStatus } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export interface ISalesTicketListItem {
  ticketId: number;
  subject: string;
  category_code: string | null;
  category_description: string | null;
  status: SalesTicketStatus;
  issuedDate: string | null;
  customer_name: string | null;
}

export const mapFromAPIListItem = (apiListItem: ISalesTicketListItem): ISalesTicket => {
  return {
    ticketId: apiListItem.ticketId,
    subject: apiListItem.subject,
    description: '',
    solution: '',
    category: apiListItem.category_description
      ? {
          code: apiListItem.category_code ?? '',
          description: apiListItem.category_description,
        }
      : null,
    status: apiListItem.status,
    assignedTo: null,
    issuedDate: apiListItem.issuedDate,
    completitionDate: null,
    dueDate: null,
    customer: apiListItem.customer_name
      ? {
          customerId: null,
          name: apiListItem.customer_name,
          email: null,
          VATId: null,
          legalName: null,
        }
      : null,
    submittedBy: '',
    submittedByContactInfo: '',
    createdBy: null,
    createdAt: '',
    modifiedBy: null,
    modifiedAt: '',
    activities: [],
    itemOnRepair: {
      itemId: undefined,
      product: {
        sku: '',
        description: undefined,
        productId: undefined,
      },
      description: '',
      reception: {
        description: '',
        receivedAt: null,
        receivedBy: null,
      },
      reparation: {
        description: '',
        repairedAt: null,
        repairedBy: null,
      },
      dueDate: null,
      deliveryDate: null,
      serialNumber: null,
      spares: [],
    },
  };
};

export const fetchSalesTicketListFromAPI = (): Promise<AxiosResponse<ISalesTicketListItem[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/tickets/list`,
    {
      fields: [
        'ticketId',
        'subject',
        'category.code',
        'category.description',
        'status',
        'issuedDate',
        'customer.name',
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchSalesTicketFromAPI = (ticketId: number): Promise<AxiosResponse<ISalesTicket>> =>
  axios.get(`${VITE_API_URL}/sales/tickets/${ticketId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createSalesTicketFromAPI = (
  salesTicket: ISalesTicket,
): Promise<AxiosResponse<ISalesTicket>> =>
  axios.post(`${VITE_API_URL}/sales/tickets`, removeNegativeItemIdsFromSalesTicket(salesTicket));

export const updateSalesTicketFromAPI = (
  salesTicket: ISalesTicket,
): Promise<AxiosResponse<ISalesTicket>> =>
  axios.put(
    `${VITE_API_URL}/sales/tickets/${salesTicket.ticketId}`,
    removeNegativeItemIdsFromSalesTicket(salesTicket),
  );

export const deleteSalesTicketFromAPI = (ticketId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/tickets/${ticketId}`);

function removeNegativeItemIdsFromSalesTicket(entity: ISalesTicket): ISalesTicket {
  const clearedSpares =
    entity.itemOnRepair?.spares?.map((spare) => {
      const { itemId } = spare;

      if (!itemId || isNegativeNumber(itemId)) {
        return { ...spare, itemId: undefined };
      }

      return spare;
    }) ?? [];

  const clearedActivities =
    entity.activities?.map((activity) => {
      const { activityId } = activity;

      if (!activityId || isNegativeNumber(activityId)) {
        return { ...activity, activityId: undefined };
      }

      return activity;
    }) ?? [];

  return {
    ...entity,
    activities: clearedActivities,
    itemOnRepair: entity.itemOnRepair ? { ...entity.itemOnRepair, spares: clearedSpares } : null,
  };
}
