import React from 'react';
import { Navigate } from 'react-router-dom';

import { getAuthState, getLastVatId, useGlobalSelector } from '@laudus/redux-global';

interface IPublicRouteProps {
  component: React.ComponentType;
}

export const PublicRoute = ({ component: Component }: IPublicRouteProps) => {
  const lastVatId = useGlobalSelector(getLastVatId);
  const { isLoggedIn } = useGlobalSelector(getAuthState);

  if (isLoggedIn && lastVatId) {
    return <Navigate replace to={`/${lastVatId}`} />;
  }

  return <Component />;
};
