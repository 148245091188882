import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, IProductCategory } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const PRODUCTCATEGORY_TAB_ID = 'productCategory';
export const productCategoryPrefix = 'PRODUCT_CATEGORY';

// Product Categories Tab action creators
interface IProductCategoriesTabParams {
  title?: string;
}

export const addHomeProductCategoriesTab = (productCategoriesTab?: IProductCategoriesTabParams) =>
  addTab({
    tab: {
      id: PRODUCTCATEGORY_TAB_ID,
      title: productCategoriesTab?.title ?? intl.formatMessage({ id: 'productCategory.tabTitle' }),
      path: 'pages/ProductCategory/ProductCategory',
      isRemovable: true,
    },
  });

// Simple actions
export const clearProductCategory = createAction('PRODUCT_CATEGORY/CLEAR');

export const setProductCategory = createAction<IProductCategory>('PRODUCT_CATEGORY/SET');

export const setProductCategoryList = createAction<IProductCategory[]>('PRODUCT_CATEGORY/SET_LIST');

export const updateProductCategoryList = createAction<IProductCategory>(
  'PRODUCT_CATEGORY/UPDATE_LIST',
);

export const removeProductCategoryFromList = createAction<string>(
  'PRODUCT_CATEGORY/REMOVE_FROM_LIST',
);

export const duplicateProductCategory = createAction<IProductCategory>(
  'PRODUCT_CATEGORY/DUPLICATE',
);

export const clearProductCategoryDraft = createAction('PRODUCT_CATEGORY/CLEAR_DRAFT');

export const setProductCategoryDraft = createAction<IProductCategory>('PRODUCT_CATEGORY/SET_DRAFT');

export const setProductCategoryDraftValues = createAction<Partial<IProductCategory>>(
  'PRODUCT_CATEGORY/SET_DRAFT_VALUE',
);

export const resetProductCategorySubmit = createAction('PRODUCT_CATEGORY/RESET_SUBMIT');

export const setProductCategorySubmitting = createAction('PRODUCT_CATEGORY/SET_SUBMITTING');

export const setProductCategorySubmitError = createAction('PRODUCT_CATEGORY/SET_SUBMIT_ERROR');

export const setProductCategorySubmitSuccess = createAction('PRODUCT_CATEGORY/SET_SUBMIT_SUCCESS');

// Complex actions

export const fetchProductCategoryList = createAsyncThunk<
  void,
  IFetchWithEtagParams,
  AppThunkConfig
>('PRODUCT_CATEGORY/FETCH_LIST', async ({ eTag, silent }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  try {
    if (!silent) {
      dispatch(startRequest('product-categories'));
    }

    const { data } = await api.productCategories.fetchProductCategoryListFromAPI();
    dispatch(setProductCategoryList(Array.isArray(data) ? data : []));

    dispatch(setEtagsCurrentEtag(eTag));
  } catch (error) {
    // todo
  } finally {
    if (!silent) {
      dispatch(endRequest('product-categories'));
    }
  }
});

export const createProductCategory = createAsyncThunk<void, IProductCategory, AppThunkConfig>(
  'PRODUCT_CATEGORY/CREATE',
  async (productCategory, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('product-categories'));
    dispatch(setProductCategorySubmitting());
    try {
      const { data } = await api.productCategories.createProductCategoryFromAPI(productCategory);

      dispatch(setProductCategory(data));
      dispatch(setProductCategoryDraft(data));
      dispatch(updateProductCategoryList(data));
      dispatch(setProductCategorySubmitSuccess());

      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'category.productCategory.success' }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'productCategory',
          action: 'read',
        }),
      );
      dispatch(setProductCategorySubmitError());
    } finally {
      dispatch(endRequest('product-categories'));
    }
  },
);

export const updateProductCategory = createAsyncThunk<void, IProductCategory, AppThunkConfig>(
  'PRODUCT_CATEGORY/UPDATE',
  async (productCategory, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('product-categories'));
    dispatch(setProductCategorySubmitting());
    try {
      const { data } = await api.productCategories.updateProductCategoryFromAPI(productCategory);

      dispatch(setProductCategory(data));
      dispatch(setProductCategoryDraft(data));
      dispatch(updateProductCategoryList(data));
      dispatch(setProductCategorySubmitSuccess());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'category.productCategory.success' }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'productCategory',
          action: 'read',
        }),
      );
      dispatch(setProductCategorySubmitError());
    } finally {
      dispatch(endRequest('product-categories'));
    }
  },
);

export const deleteProductCategory = createAsyncThunk<void, string, AppThunkConfig>(
  'PRODUCT_CATEGORY/DELETE',
  async (productCategoryId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('product-categories'));
    try {
      await api.productCategories.deleteProductCategoryFromAPI(productCategoryId);

      dispatch(clearProductCategory());
      dispatch(removeProductCategoryFromList(productCategoryId));

      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'category.productCategoryDelete.error',
          }),
          message: '',
          type: 'success',
        }),
      );
      dispatch(addHomeProductCategoriesTab());
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'productCategory',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('product-categories'));
    }
  },
);
