import { createSelector } from '@reduxjs/toolkit';

import { AppState, IGlobalState } from '../..';

export const getGlobalSlice = (state: AppState): IGlobalState => state.global;

export const getMenuTabs = createSelector([getGlobalSlice], (state) => state.tab);

export const getAccordion = createSelector([getGlobalSlice], (state) => state.accordion);

export const getInProgress = createSelector([getGlobalSlice], (state) => state.inProgress);

export const getLoading = createSelector([getGlobalSlice], (state) => !!state.loading.length);

export const getLoadingTabs = createSelector([getGlobalSlice], (state) => state.loadingTabs);

export const getIsTabLoading = (tabId: string) =>
  createSelector([getLoadingTabs], (loadingTabs) => loadingTabs[tabId] === true);

export const getTableState = (tableId: string) =>
  createSelector([getGlobalSlice], (state) => state.table?.[tableId]);
