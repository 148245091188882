import { createReducer } from '@reduxjs/toolkit';

import { ICustomerInvoice, ICustomerPendingInvoice } from '@laudus/types';

import {
  setCustomerPendingInvoices,
  setCustomerPendingInvoicesDetails,
  setCustomerStatistics,
} from './actions';

export interface ICustomerStatisticsState {
  invoicesList: Record<number, ICustomerInvoice[]>;
  pendingInvoicesList: Record<number, ICustomerPendingInvoice[]>;
}

export const initialCustomerStatisticsState: ICustomerStatisticsState = {
  invoicesList: {},
  pendingInvoicesList: {},
};

export const customerStatisticsReducer = createReducer(
  initialCustomerStatisticsState,
  (builder) => {
    builder
      .addCase(setCustomerStatistics, (state, action) => {
        return {
          ...state,
          invoicesList: { ...state.invoicesList, ...action.payload },
        };
      })
      .addCase(setCustomerPendingInvoices, (state, action) => {
        return {
          ...state,
          pendingInvoicesList: {
            ...state.pendingInvoicesList,
            ...action.payload,
          },
        };
      })
      .addCase(setCustomerPendingInvoicesDetails, (state, action) => {
        const updatingInvoice = state.pendingInvoicesList[action.payload.customerId].find(
          (invoice) => invoice.salesInvoiceId === action.payload.salesInvoiceId,
        );
        if (updatingInvoice) {
          updatingInvoice['_details'] = action.payload.items;
        }
      })
      .addDefaultCase((state) => state);
  },
);
