import { createSelector } from '@reduxjs/toolkit';

import { cleanSalesDocument, QUOTES, updateSalesItemsPricing } from '@laudus/sales-utils';

import { AppState } from '../../store';
import { getMainCurrency } from '../currencies';

import { ISalesQuotesState } from './reducer';

export const getSalesQuotesSlice = (state: AppState): ISalesQuotesState => state.salesQuotes;

export const getSalesQuotesList = createSelector([getSalesQuotesSlice], (state) => state.list);

export const getSalesQuote = createSelector(
  [getSalesQuotesSlice, (appState) => getMainCurrency(appState)],
  (state, { mainCurrencyDecimals }) => updateSalesItemsPricing(state.current, mainCurrencyDecimals),
);

export const getSalesQuoteDraft = createSelector(
  [getSalesQuotesSlice, (appState) => getMainCurrency(appState)],
  (state, { mainCurrencyDecimals }) => {
    // This prepare the sales document to be sent to the backend once the draft has been completed
    const clearDraft = cleanSalesDocument(state.draft, QUOTES);

    return updateSalesItemsPricing(clearDraft, mainCurrencyDecimals);
  },
);

export const getSalesQuotesPDFUrl = createSelector([getSalesQuotesSlice], (state) => state.pdfURL);
