import { forwardRef, useImperativeHandle } from 'react';

import { ICellEditorParams } from '@ag-grid-community/core';

import { formatNumber } from '@laudus/shared-utils';

const TotalEditorComponent: React.ForwardRefRenderFunction<any, ICellEditorParams> = (
  props,
  ref,
) => {
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return props.value;
      },
    };
  });

  return (
    <div
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        textAlign: 'right',
        padding: '1px 1.2rem',
      }}
    >
      {props.value ? formatNumber(props.value, 2) : ''}
    </div>
  );
};

TotalEditorComponent.displayName = 'TotalEditor';

export const TotalEditor = forwardRef(TotalEditorComponent);
