import { AxiosResponse } from 'axios';

import { IPos, IPosListItem } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchPosListAPI = async (): Promise<AxiosResponse<IPos[]>> => {
  const response = await axios.post<IPosListItem[]>(
    `${VITE_API_URL}/sales/pos/list`,
    {
      fields: ['posId', 'name', 'docType.name', 'branch.branchId'],
      orderBy: [
        {
          field: 'name',
          direction: 'DESC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

  return {
    ...response,
    data: response.data.map((pos) => {
      if (pos.branch_branchId) {
        return {
          ...pos,
          branch: {
            branchId: pos.branch_branchId,
            name: '',
          },
          branch_branchId: undefined,
        } as unknown as IPos;
      }

      return { ...pos, branch_branchId: undefined } as unknown as IPos;
    }),
  };
};

export const fetchPosAPI = (posId: number): Promise<AxiosResponse<IPos>> =>
  axios.get(`${VITE_API_URL}/sales/pos/${posId}`);

export const createPosAPI = (pos: IPos): Promise<AxiosResponse<IPos>> =>
  axios.post(`${VITE_API_URL}/sales/pos`, pos);

export const updatePosAPI = (pos: IPos): Promise<AxiosResponse<IPos>> =>
  axios.put(`${VITE_API_URL}/sales/pos/${pos.posId}`, pos);

export const deletePosAPI = (posId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/pos/${posId}`);
