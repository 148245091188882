import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IGroup, IUser } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert } from '../alerts';
import { clearDashboardList } from '../dashboards';
import { endRequest, startRequest } from '../global/actions';

import { getLoggedUser } from './selectors';

// Simple actions
export const clearLoggedUser = createAction('LOGGED_USER/CLEAR');

export const setLoggedUser = createAction<IUser>('LOGGED_USER/SET');

export const setLoggedUserGroup = createAction<IGroup>('LOGGED_USER/SET_GROUP');

// Complex actions
export const fetchLoggedUser = createAsyncThunk<void, number, AppThunkConfig>(
  'LOGGED_USER/FETCH',
  async (glUserId, ThunkAPI) => {
    const { dispatch, extra, getState } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('logged-user'));
      const state = getState();
      const previousUserId = getLoggedUser(state)?.userId;

      const { data } = await api.users.fetchUserByGlUserIdFromAPI({ glUserId });
      const userId = data?.userId;

      if (!userId) {
        dispatch(clearLoggedUser());
        dispatch(
          showAlert({
            type: 'error',
            title: intl.formatMessage({ id: 'users.errorToast.read' }),
            message: intl.formatMessage({ id: 'users.errorToast.fetch.userId' }),
          }),
        );
        return;
      }
      dispatch(setLoggedUser(data));
      if (previousUserId !== userId) {
        dispatch(clearDashboardList());
      }

      const { data: group } = await api.groups.fetchGroupFromAPI(data.group.groupId);
      dispatch(setLoggedUserGroup(group));
    } catch (error) {
      dispatch(clearLoggedUser());
      dispatch(
        showErrorAlert({
          error,
          prefix: 'users',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('logged-user'));
    }
  },
);
