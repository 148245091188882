import { ComponentType, useEffect } from 'react';

import { useToast } from '@chakra-ui/react';

import {
  IconBaseProps,
  IconError,
  IconInfo,
  IconSuccess,
  IconType,
  IconWarning,
} from '@laudus/icons';
import { IAlert, IAlertType } from '@laudus/types';

import { Toast } from './Toast';

function getIconByAlertType(alertType: IAlertType) {
  const iconByAlertType: Record<IAlertType, IconType> = {
    info: IconInfo,
    warning: IconWarning,
    success: IconSuccess,
    error: IconError,
  };

  return iconByAlertType[alertType];
}

export interface IAlertPromptProps {
  children?: JSX.Element | JSX.Element[] | string;
  AlertIcon?: ComponentType<IconBaseProps> | null;
  isOpen: boolean;
  title?: string;
  type?: IAlertType;
}

export interface AlertsProviderProps {
  alert: IAlert;
  clearAlert: (alert: IAlert) => void;
  AlertPrompt: ComponentType<IAlertPromptProps>;
}

export const AlertsProvider = ({ alert, clearAlert, AlertPrompt }: AlertsProviderProps) => {
  const toast = useToast({
    duration: 4000,
    isClosable: true,
    position: 'top-right',
  });

  useEffect(() => {
    if (!alert?.isToast) {
      return;
    }
    const { id, title, message, type } = alert;
    const toastText = message ? `${title} ${message}` : title;

    toast({
      id,
      title,
      description: message,
      status: type,
      render: ({ onClose }) => (
        <Toast onClose={onClose} text={toastText} variant={`${type}-toast`} />
      ),
    });
    clearAlert(alert);
  }, [alert]);

  return (
    <>
      {alert && !alert.isToast && (
        <AlertPrompt
          AlertIcon={getIconByAlertType(alert.type)}
          isOpen={!!alert}
          title={alert.title}
          type={alert.type}
        >
          {alert.message}
        </AlertPrompt>
      )}
    </>
  );
};
