import { AxiosResponse } from 'axios';

import { ICustomerContact } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const getCustomerContactsListAPI = (
  customerId: number,
): Promise<AxiosResponse<ICustomerContact[]>> =>
  axios.get(`${VITE_API_URL}/sales/customers/${customerId}/contacts`);

export const getCustomerContactAPI = (
  customerId: number,
  contactId: number,
): Promise<AxiosResponse<ICustomerContact>> =>
  axios.get(`${VITE_API_URL}/sales/customers/${customerId}/contacts/${contactId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createCustomerContactAPI = (
  customerId: number,
  contact: ICustomerContact,
): Promise<AxiosResponse<ICustomerContact>> =>
  axios.post(`${VITE_API_URL}/sales/customers/${customerId}/contacts`, contact);

export const updateCustomerContactAPI = (
  customerId: number,
  contact: ICustomerContact,
): Promise<AxiosResponse<ICustomerContact>> =>
  axios.put(`${VITE_API_URL}/sales/customers/${customerId}/contacts/${contact.contactId}`, contact);

export const deleteCustomerContactAPI = (
  customerId: number,
  contactId: number,
): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/customers/${customerId}/contacts/${contactId}`);
