export interface IPurchaseInvoicesReconciliationInfo {
  year: number;
  month: number;
  type: string;
  unconciliated: IPurchaseInvoicesUnconciliatedItem[];
}

export interface IPurchaseInvoicesUnconciliatedItem {
  docTypeId: number;
  docTypeName: string;
  docNumber: number;
  issuedDate: string;
  total: number;
  supplierId: number;
  supplierName: string;
  supplierVATId: string;
  errorMessage: string;
  dataSource: string;
}

export interface ISalesInvoicesReconciliationInfo {
  year: number;
  month: number;
  type: string;
  unconciliated: ISalesInvoicesUnconciliatedItem[];
}

export interface ISalesInvoicesUnconciliatedItem {
  docTypeId: number;
  docTypeName: string;
  docNumber: number;
  issuedDate: string;
  total: number;
  customerId: number;
  customerName: string;
  customerVATId: string;
  errorMessage: string;
  dataSource: string;
}

export type InvoicesReconciliationInfo =
  | IPurchaseInvoicesReconciliationInfo
  | ISalesInvoicesReconciliationInfo;

export interface IInvoicesReconciliationFilter {
  year: number;
  month: number;
  type: InvoicesReconciliationTypeEnum;
}

export enum InvoicesReconciliationTypeEnum {
  PurchasesInvoices = 'purchases',
  SalesInvoices = 'invoices',
  SalesTickets = 'tickets',
}
