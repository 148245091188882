import { createSelector } from '@reduxjs/toolkit';

import { IEmployee } from '@laudus/types';

import { AppState, IEmployeeState } from '../..';

export const getEmployeesSlice = (state: AppState): IEmployeeState => state.employees;

export const getEmployeesList = createSelector([getEmployeesSlice], (state) => state.list);

export const getEmployee = createSelector([getEmployeesSlice], (state) => state.current);

export const getEmployeeDraft = createSelector([getEmployeesSlice], (state) => state.draft);

export const getActiveEmployeeList = createSelector([getEmployeesSlice], (state) =>
  state.list.filter((employee) => employee.contractTerminationDate === null),
);

export const getCashPaidEmployeeList = createSelector([getActiveEmployeeList], (state) =>
  state.filter((employee) => employee.paymentType?.code === 'E'),
);

export const getEmployeeListForEmployeeSearchGrid = ({
  showTerminated,
}: {
  showTerminated: boolean;
}) =>
  createSelector([getEmployeesList], (list) =>
    list.filter((employee) => {
      const matchTerminated = doesEmployeeMatchTerminatedFilter({
        employee: employee as IEmployee, // The employees inside the list contains contractTerminationDate, so it doesn't make sense to deal with Partial
        showTerminated,
      });

      return matchTerminated;
    }),
  );

function doesEmployeeMatchTerminatedFilter({
  employee,
  showTerminated,
}: {
  employee: IEmployee;
  showTerminated: boolean;
}): boolean {
  if (showTerminated) {
    // Show both terminated and non-terminated employees
    return true;
  }

  // Show only non-terminated employees => actual working employees
  const isEmployeeStillWorking = employee.contractTerminationDate === null;

  return isEmployeeStillWorking;
}
