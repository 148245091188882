import { forwardRef } from 'react';

import { getPosList, useAppSelector } from '@laudus/redux-app';

import { SelectEditor } from './SelectEditor';

const SelectPOSEditorComponent: React.ForwardRefRenderFunction<any, any> = (props, ref) => {
  const posList = useAppSelector(getPosList);

  const options = posList.map((pos) => ({
    label: pos.name as string,
    value: pos.posId as number,
  }));

  return <SelectEditor {...props} options={options} ref={ref} />;
};

SelectPOSEditorComponent.displayName = 'SelectDocTypesEditor';

export const SelectPOSEditor = forwardRef(SelectPOSEditorComponent);
