import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IInventory } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

export const addHomeInventoriesTab = () =>
  addTab({
    tab: {
      id: 'inventory',
      title: intl.formatMessage({ id: 'inventory.tabTitle' }),
      path: 'pages/Inventory/Inventory',
      isRemovable: true,
    },
  });

export const addViewInventoriesTab = (id?: number) =>
  addTab({
    tab: {
      id: 'inventory',
      title: intl.formatMessage({ id: 'inventory.tabTitle' }),
      path: 'pages/Inventory/InventoryView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewInventoriesTab = () =>
  addTab({
    tab: {
      id: 'inventory',
      title: intl.formatMessage({ id: 'inventory.tabTitle' }),
      path: 'pages/Inventory/InventoryNew',
      isRemovable: true,
    },
  });

export const addEditInventoriesTab = () =>
  addTab({
    tab: {
      id: 'inventory',
      title: intl.formatMessage({ id: 'inventory.tabTitle' }),
      path: 'pages/Inventory/InventoryEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearInventory = createAction('INVENTORIES/CLEAR');

export const clearInventoryDraft = createAction('INVENTORIES/CLEAR_DRAFT');

export const setInventory = createAction<IInventory>('INVENTORIES/SET_INVENTORY');

export const setInventoryDraft = createAction<IInventory>('INVENTORIES/SET_INVENTORY_DRAFT');

export const setInventoryDraftValues = createAction<Partial<IInventory>>(
  'INVENTORIES/SET_INVENTORY_DRAFT_VALUE',
);

export const setInventoryList = createAction<IInventory[]>('INVENTORIES/SET_LIST');

export const updateInventoryList = createAction<IInventory>('INVENTORIES/UPDATE_LIST');

export const removeInventoryFromList = createAction<number>('INVENTORIES/REMOVE_FROM_LIST');

export const duplicateInventory = createAction<IInventory>('INVENTORIES/DUPLICATE');

//Complex actions
export const fetchInventoryList = createAsyncThunk<void, void, AppThunkConfig>(
  'INVENTORIES/FETCH_INVENTORIES_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('inventories'));
    try {
      const { data } = await api.inventories.fetchInventoryListAPI();
      dispatch(setInventoryList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'inventory',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('inventories'));
    }
  },
);

export const fetchInventory = createAsyncThunk<void, number, AppThunkConfig>(
  'INVENTORIES/FETCH_INVENTORY',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('inventories'));
    try {
      const { data } = await api.inventories.fetchInventoryAPI(id);
      dispatch(setInventory(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'inventory',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('inventories'));
    }
  },
);
