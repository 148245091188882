import { forwardRef, useImperativeHandle } from 'react';

import { Box, Flex, IconButton } from '@chakra-ui/react';
import { MenuItem } from '@szhsin/react-menu';

import { IconExcel, IconFind, IconMailAlt, IconPrint, IconRemoveCircle } from '@laudus/icons';

import { RowActionMenu } from '../RowActionMenu';

const DeleteAndActionButtonRendererComponent: React.ForwardRefRenderFunction<any, any> = (
  props,
  ref,
) => {
  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return undefined;
      },
    };
  });

  const handleRemove = () => {
    props.context.onRemoveRow(props.rowIndex, props.node.data);
  };

  return (
    <Flex width="4rem">
      <IconButton
        aria-label="delete button"
        color="black"
        fontSize="17px"
        icon={<IconRemoveCircle className="delete-icon" />}
        mb="3px"
        ml="1px"
        mt="3px"
        onClick={handleRemove}
        variant="secondary-link"
      />
      {props.actionMenu && (
        <RowActionMenu dark={true} marginLeft="0px">
          <Box>
            <MenuItem>
              <i>
                <IconFind />
              </i>
              Vista previa
            </MenuItem>
            <MenuItem>
              <i>
                <IconPrint />
              </i>
              Imprimir
            </MenuItem>
            <MenuItem>
              <i>
                <IconExcel />
              </i>
              Excel
            </MenuItem>
            <MenuItem>
              <i>
                <IconMailAlt />
              </i>
              Email
            </MenuItem>
          </Box>
        </RowActionMenu>
      )}
    </Flex>
  );
};

DeleteAndActionButtonRendererComponent.displayName = 'DeleteAndActionButtonRendererComponent';

export const DeleteAndActionButtonRenderer = forwardRef(DeleteAndActionButtonRendererComponent);
