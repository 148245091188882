import { AxiosResponse } from 'axios';

import { IWorkersCompensationInsurance } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchWorkersCompensationInsurancesListFromAPI = (): Promise<
  AxiosResponse<IWorkersCompensationInsurance[]>
> =>
  axios.post(
    `${VITE_API_URL}/HR/workersCompensationInsuranceCompanies/list`,
    {
      fields: [
        'workersCompensationInsuranceCompanyId',
        'name',
        'VATId',
        'previredId',
        'electronicBookId',
        'notes',
      ],
      orderBy: [{ field: 'name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchWorkersCompensationInsuranceFromAPI = (
  workersCompensationInsuranceCompanyId: string,
): Promise<AxiosResponse<IWorkersCompensationInsurance>> =>
  axios.get(
    `${VITE_API_URL}/HR/workersCompensationInsuranceCompanies/${workersCompensationInsuranceCompanyId}`,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const createWorkersCompensationInsuranceFromAPI = (
  workersCompensationInsuranceCompany: IWorkersCompensationInsurance,
): Promise<AxiosResponse<IWorkersCompensationInsurance>> =>
  axios.post(
    `${VITE_API_URL}/HR/workersCompensationInsuranceCompanies`,
    workersCompensationInsuranceCompany,
  );

export const updateWorkersCompensationInsuranceFromAPI = (
  workersCompensationInsuranceCompany: IWorkersCompensationInsurance,
): Promise<AxiosResponse<IWorkersCompensationInsurance>> =>
  axios.put(
    `${VITE_API_URL}/HR/workersCompensationInsuranceCompanies/${workersCompensationInsuranceCompany.workersCompensationInsuranceCompanyId}`,
    workersCompensationInsuranceCompany,
  );

export const deleteWorkersCompensationInsuranceFromAPI = (
  workersCompensationInsuranceCompanyId: string,
): Promise<AxiosResponse> =>
  axios.delete(
    `${VITE_API_URL}/HR/workersCompensationInsuranceCompanies/${workersCompensationInsuranceCompanyId}`,
  );
