import { createSelector } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import {
  ISalesTicketItemOnRepairSpareTableRow,
  SalesTicketActivityStatusReadableValue,
} from '@laudus/types';

import { AppState } from '../../store';

import { ISalesTicketsState } from './reducer';

export const getSalesTicketsSlice = (state: AppState): ISalesTicketsState => state.salesTickets;

export const getSalesTicketList = createSelector([getSalesTicketsSlice], (state) => state.list);

export const getSalesTicket = createSelector([getSalesTicketsSlice], (state) => state.current);

export const getSalesTicketDraft = createSelector([getSalesTicketsSlice], (state) => state.draft);

export const getSalesTicketEditing = createSelector(
  [getSalesTicketsSlice],
  (state) => state.editing,
);

export const getSalesTicketEntity = createSelector(
  [getSalesTicket, getSalesTicketDraft, getSalesTicketEditing],
  (current, draft, editing) => {
    return editing ? draft : current;
  },
);

export const getSalesTicketActivitiesTableData = createSelector([getSalesTicketEntity], (entity) =>
  entity?.activities?.map((activity) => ({
    ...activity,
    statusDescription: intl.formatMessage({
      id: `salesTickets.activities.status.${SalesTicketActivityStatusReadableValue[activity.status]}`,
    }),
  })),
);

export const getSalesTicketItemInRepairSpareTableData = createSelector(
  [getSalesTicketEntity],
  (entity) => {
    const tableData: ISalesTicketItemOnRepairSpareTableRow[] = (
      entity.itemOnRepair?.spares ?? []
    ).map((spare) => ({
      ...spare,
      net: spare.quantity * spare.unitPrice * (1 - spare.discount / 100),
    }));

    return tableData;
  },
);
