import { createReducer } from '@reduxjs/toolkit';

import { ISupplierContact } from '@laudus/types';

import {
  clearSupplierContact,
  clearSupplierContactDraft,
  removeSupplierContactFromList,
  setSupplierContact,
  setSupplierContactDraft,
  setSupplierContactDraftValues,
  setSupplierContactsList,
  updateSupplierContactList,
} from './actions';

export interface ISupplierContactsState {
  current: ISupplierContact;
  draft: ISupplierContact;
  list: Record<number, ISupplierContact[]>;
}

export const SUPPLIER_CONTACT_EMPTY: ISupplierContact = {
  contactId: 0,
  title: '',
  firstName: '',
  lastName: '',
  VATId: '',
  position: '',
  department: '',
  discontinued: false,
  phone: '',
  cellPhone: '',
  email: '',
  notes: '',
  sendInvoices: false,
  sendMailings: false,
};

export const initialSupplierContactsState: ISupplierContactsState = {
  current: SUPPLIER_CONTACT_EMPTY,
  draft: SUPPLIER_CONTACT_EMPTY,
  list: {},
};

export const supplierContactsReducer = createReducer(initialSupplierContactsState, (builder) => {
  builder
    .addCase(clearSupplierContact, (state) => {
      return { ...state, current: SUPPLIER_CONTACT_EMPTY };
    })
    .addCase(clearSupplierContactDraft, (state) => {
      return { ...state, draft: SUPPLIER_CONTACT_EMPTY };
    })
    .addCase(setSupplierContact, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setSupplierContactDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setSupplierContactDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setSupplierContactsList, (state, action) => {
      return { ...state, list: { ...state.list, ...action.payload } };
    })
    .addCase(updateSupplierContactList, (state, action) => {
      return {
        ...state,
        list: { ...state.list, ...action.payload },
      };
    })
    .addCase(removeSupplierContactFromList, (state, action) => {
      return {
        ...state,
        list: Object.assign(
          {},
          ...Object.keys(state.list).map((supplierId: number | string) => {
            return {
              [supplierId as number]: state.list[supplierId as number].filter(
                (contact) => contact.contactId !== action.payload,
              ),
            };
          }),
        ),
      };
    })
    .addDefaultCase((state) => state);
});
