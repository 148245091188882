import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import {
  IInvoicesReconciliationFilter,
  InvoicesReconciliationInfo,
  InvoicesReconciliationTypeEnum,
} from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';

export const INVOICES_RECONCILIATION_TAB_ID = 'invoicesReconciliation';

// Payroll action creators
export const clearInvoicesReconciliationFilter = createAction(
  'INVOICES_RECONCILIATION/CLEAR_FILTER',
);

export const setInvoicesReconciliationFilterValues = createAction<
  Partial<IInvoicesReconciliationFilter>
>('INVOICES_RECONCILIATION/SET_FILTER_VALUES');

export const setInvoicesReconciliationInfo = createAction<InvoicesReconciliationInfo>(
  'INVOICES_RECONCILIATION/SET_INFO',
);

export const fetchInvoicesReconciliationInfo = createAsyncThunk<
  void,
  IInvoicesReconciliationFilter,
  AppThunkConfig
>('INVOICES_RECONCILIATION/FETCH_INFO', async (params, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('invoices-reconcilation'));
  try {
    let response: AxiosResponse<InvoicesReconciliationInfo>;
    if (params.type === InvoicesReconciliationTypeEnum.PurchasesInvoices) {
      response = await api.purchaseInvoices.fetchPurchaseInvoicesReconciliationFromAPI(
        params.year,
        params.month,
      );
    } else {
      response = await api.salesInvoices.fetchSalesInvoicesReconciliationFromAPI(
        params.type,
        params.year,
        params.month,
      );
    }
    dispatch(setInvoicesReconciliationInfo(response.data));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'invoicesReconciliation',
        action: 'read',
      }),
    );
  } finally {
    dispatch(endRequest('invoices-reconcilation'));
  }
});
