import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { ICurrencyRatesState } from './reducer';

export const getCurrencyRatesSlice = (state: AppState): ICurrencyRatesState => state.currencyRates;

export const getCurrencyRatesList = createSelector([getCurrencyRatesSlice], (state) => state.list);

export const getCurrencyRate = createSelector([getCurrencyRatesSlice], (state) => state.current);

export const getCurrencyRateDraft = createSelector([getCurrencyRatesSlice], (state) => state.draft);
