import { AxiosResponse } from 'axios';

import { ISupplierContact } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const getSupplierContactsListAPI = (
  supplierId: number,
): Promise<AxiosResponse<ISupplierContact[]>> =>
  axios.get(`${VITE_API_URL}/purchases/suppliers/${supplierId}/contacts`);

export const getSupplierContactAPI = (
  supplierId: number,
  contactId: number,
): Promise<AxiosResponse<ISupplierContact>> =>
  axios.get(`${VITE_API_URL}/purchases/suppliers/${supplierId}/contacts/${contactId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createSupplierContactAPI = (
  supplierId: number,
  contact: ISupplierContact,
): Promise<AxiosResponse<ISupplierContact>> =>
  axios.post(`${VITE_API_URL}/purchases/suppliers/${supplierId}/contacts`, contact);

export const updateSupplierContactAPI = (
  supplierId: number,
  contact: ISupplierContact,
): Promise<AxiosResponse<ISupplierContact>> =>
  axios.put(
    `${VITE_API_URL}/purchases/suppliers/${supplierId}/contacts/${contact.contactId}`,
    contact,
  );

export const deleteSupplierContactAPI = (
  supplierId: number,
  contactId: number,
): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/purchases/suppliers/${supplierId}/contacts/${contactId}`);
