import { createReducer } from '@reduxjs/toolkit';

import { SALES_INVOICE_EMPTY } from '@laudus/sales-utils';
import { dateToLocalISOString, getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  IInvoiceReceipt,
  ISalesInvoice,
  ISalesInvoiceList,
  ISalesInvoiceSavedDraft,
  ISalesInvoicesItem,
  ITraceFrom,
} from '@laudus/types';

import {
  addItemToSalesInvoiceDraft,
  clearAllItemsFromCreditNote,
  clearHistoryAdvancedSearch,
  clearSalesInvoice,
  clearSalesInvoiceDraft,
  clearSalesInvoiceDraftList,
  clearSalesInvoicePDFUrl,
  deleteItemFromSalesInvoiceDraft,
  fetchHistoryAdvancedSearch,
  removeSalesInvoiceDraft,
  removeSalesInvoiceFromList,
  restoreSalesInvoiceDraft,
  saveSalesInvoiceDraft,
  setSalesInvoice,
  setSalesInvoiceDraft,
  setSalesInvoiceDraftValues,
  setSalesInvoiceList,
  setSalesInvoicePDFLoading,
  setSalesInvoicePDFUrl,
  setSalesInvoicesReceiptList,
  setSalesInvoiceTraces,
  updateAllItemsDiscount,
  updateItemFromSalesInvoiceDraft,
  updateSalesInvoiceList,
} from './actions';

export interface ISalesInvoicesState {
  current: ISalesInvoice;
  draft: ISalesInvoice;
  list: ISalesInvoiceList;
  receiptList: IInvoiceReceipt[];
  pdfURL: string | undefined;

  draftList: ISalesInvoiceSavedDraft[];
  loadingPDF: boolean;

  historyAdvancedSearchResult: ISalesInvoiceList;
  traces: ITraceFrom[];
}

export const initialSalesInvoicesState: ISalesInvoicesState = {
  current: SALES_INVOICE_EMPTY,
  draft: SALES_INVOICE_EMPTY,
  list: [],
  receiptList: [],
  pdfURL: undefined,

  draftList: [],
  loadingPDF: false,

  historyAdvancedSearchResult: [],
  traces: [],
};

export const salesInvoicesReducer = createReducer(initialSalesInvoicesState, (builder) => {
  builder
    .addCase(clearSalesInvoice, (state) => {
      return { ...state, current: SALES_INVOICE_EMPTY };
    })
    .addCase(clearSalesInvoiceDraft, (state) => {
      return { ...state, draft: SALES_INVOICE_EMPTY };
    })
    .addCase(clearSalesInvoicePDFUrl, (state) => {
      return { ...state, pdfURL: undefined };
    })
    .addCase(setSalesInvoice, (state, action) => {
      return {
        ...state,
        current: computeSalesInvoicePropertiesFromItems(action.payload),
      };
    })
    .addCase(setSalesInvoiceDraft, (state, action) => {
      return {
        ...state,
        draft: computeSalesInvoicePropertiesFromItems(action.payload),
      };
    })
    .addCase(setSalesInvoiceDraftValues, (state, action) => {
      return {
        ...state,
        draft: computeSalesInvoicePropertiesFromItems({
          ...state.draft,
          ...action.payload,
        }),
      };
    })
    .addCase(setSalesInvoicePDFUrl, (state, action) => {
      return { ...state, pdfURL: action.payload };
    })
    .addCase(setSalesInvoiceList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(setSalesInvoiceTraces, (state, action) => {
      return { ...state, traces: action.payload };
    })
    .addCase(updateSalesInvoiceList, (state, action) => {
      const { salesInvoiceId, customer, issuedDate, docType, docNumber, totals } = action.payload;

      if (!state.list.some((d) => d.salesInvoiceId === salesInvoiceId)) {
        return state;
      }

      return {
        ...state,
        list: [
          ...state.list.filter((d) => d.salesInvoiceId !== salesInvoiceId),
          {
            salesInvoiceId: salesInvoiceId ?? '',
            customer_name: customer?.name ?? '',
            docType_name: docType?.name ?? '',
            docNumber: docNumber ?? 0,
            issuedDate: issuedDate ?? '',
            totals_total: totals?.total ?? 0,
          },
        ],
      };
    })
    .addCase(removeSalesInvoiceFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.salesInvoiceId !== action.payload),
      };
    })
    .addCase(setSalesInvoicesReceiptList, (state, action) => {
      return { ...state, receiptList: action.payload };
    })
    // POS
    .addCase(clearSalesInvoiceDraftList, (state) => {
      return { ...state, draftList: [] };
    })
    .addCase(saveSalesInvoiceDraft, (state) => {
      const lastSavedDraft = state.draftList.at(-1);
      if (
        !lastSavedDraft ||
        JSON.stringify(lastSavedDraft.draft.items) !== JSON.stringify(state.draft.items)
      ) {
        // No saved draft or not draft items modification
        state.draftList.push({
          savedDraftId: getNegativeUniqueNumericId(),
          dateSaved: dateToLocalISOString(new Date()),
          draft: { ...state.draft },
        });
      } else {
        // No modification in item list update saved date
        lastSavedDraft.dateSaved = dateToLocalISOString(new Date());
      }
    })
    .addCase(removeSalesInvoiceDraft, (state, action) => {
      return {
        ...state,
        draftList: state.draftList.filter(
          (savedDraft) => savedDraft.savedDraftId !== action.payload,
        ),
      };
    })
    .addCase(restoreSalesInvoiceDraft, (state, action) => {
      const newDraft = state.draftList.find(
        (savedDraft) => savedDraft.savedDraftId === action.payload,
      );
      return {
        ...state,
        draft: newDraft ? newDraft.draft : state.draft,
      };
    })
    .addCase(addItemToSalesInvoiceDraft, (state, action) => {
      state.draft.items.push(action.payload);
    })
    .addCase(deleteItemFromSalesInvoiceDraft, (state, action) => {
      state.draft.items = state.draft.items.filter((item: ISalesInvoicesItem) => {
        return item?.product?.productId !== action.payload.product?.productId;
      });
    })
    .addCase(updateItemFromSalesInvoiceDraft, (state, action) => {
      const itemIndex = state.draft.items.findIndex(
        (i) => i?.product?.productId === action.payload.product?.productId,
      );
      state.draft.items[itemIndex] = action.payload;
    })
    .addCase(setSalesInvoicePDFLoading, (state, action) => {
      return { ...state, loading: action.payload };
    })
    .addCase(clearAllItemsFromCreditNote, (state) => {
      state.draft.items = [];
    })
    .addCase(updateAllItemsDiscount, (state, action) => {
      state.draft.items = state.draft.items.map((item) => {
        return { ...item, discountPercentage: action.payload };
      });
    })
    // END POS
    .addCase(clearHistoryAdvancedSearch, (state) => {
      return { ...state, historyAdvancedSearchResult: [] };
    })

    .addCase(fetchHistoryAdvancedSearch.fulfilled, (state, action) => {
      return { ...state, historyAdvancedSearchResult: action.payload };
    })
    .addDefaultCase((state) => state);
});

/**
 * Compute some properties from items and put them into invoice so they can be directly edited in a single input
 *
 */
function computeSalesInvoicePropertiesFromItems(salesInvoice: ISalesInvoice): ISalesInvoice {
  const costCenterFromItems = salesInvoice.items.find(
    (item) => Boolean(item.costCenter) && Boolean(item.costCenter?.costCenterId),
  )?.costCenter;

  const accountFromItems = salesInvoice.items.find(
    (item) => Boolean(item.account) && Boolean(item.account?.accountId),
  )?.account;

  return {
    ...salesInvoice,
    costCenter: salesInvoice.costCenter ?? costCenterFromItems,
    account: salesInvoice.account ?? accountFromItems,
  };
}
