import { AxiosResponse } from 'axios';

import { IReportConfig, IReportPrimitiveFiltersItems } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

interface IFetchReportProps {
  reportConfig: IReportConfig;
  reportFilters: IReportPrimitiveFiltersItems;
}

export const fetchReportPDFFromAPI = ({
  reportConfig,
  reportFilters,
}: IFetchReportProps): Promise<AxiosResponse<any>> =>
  axios.post(
    `${VITE_API_URL}/internalReports/report`,
    {
      report: reportConfig.name,
      ContentDisposition: 'inline',
      parameters: { ...reportFilters, ...(reportConfig.fixedFilters ?? {}) },
    },
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
      },
    },
  );

export const fetchReportPDFUrlFromAPI = async ({
  reportConfig,
  reportFilters,
}: IFetchReportProps): Promise<string | null> => {
  const { data } = await fetchReportPDFFromAPI({ reportConfig, reportFilters });
  const isDataFilled = isArrayBuffer(data) && isArrayBufferFilled(data);

  if (!isDataFilled) {
    return null;
  }

  const blob = new Blob([new Uint8Array(data)], { type: 'application/pdf' });
  const urlBlob = URL.createObjectURL(blob);

  return urlBlob;
};

function isArrayBuffer(value: any): value is ArrayBuffer {
  return value instanceof ArrayBuffer;
}

function isArrayBufferFilled(buffer: ArrayBuffer): boolean {
  return buffer.byteLength > 0;
}
