import { AxiosResponse } from 'axios';

import { IPriceList, IPriceListProducts } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchPriceListListFromAPI = (): Promise<AxiosResponse<IPriceList[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/pricesLists/list`,
    {
      fields: ['priceListId', 'name'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchPriceListFromAPI = (priceListId: number): Promise<AxiosResponse<IPriceList>> =>
  axios.get(`${VITE_API_URL}/sales/priceslists/${priceListId}`);

export const createPriceListFromAPI = (
  pricesLists: IPriceList,
): Promise<AxiosResponse<IPriceList>> =>
  axios.post(`${VITE_API_URL}/sales/priceslists`, pricesLists);

export const updatePriceListFromAPI = (
  pricesLists: IPriceList,
): Promise<AxiosResponse<IPriceList>> =>
  axios.put(`${VITE_API_URL}/sales/priceslists/${pricesLists.priceListId}`, pricesLists);

export const deletePriceListFromAPI = (priceListId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/priceslists/${priceListId}`);

export const fetchProductsOfPriceListFromAPI = (
  priceListId: number,
): Promise<AxiosResponse<IPriceListProducts>> =>
  axios.get(`${VITE_API_URL}/sales/priceslists/${priceListId}/products`);
