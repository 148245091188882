import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IDealer, IFetchWithEtagParams } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const addHomeDealersTab = () =>
  addTab({
    tab: {
      id: 'dealers',
      title: intl.formatMessage({ id: 'dealers.tabTitle' }),
      path: 'pages/Dealers/Dealers',
      isRemovable: true,
    },
  });

export const addViewDealersTab = (id?: number) =>
  addTab({
    tab: {
      id: 'dealers',
      title: intl.formatMessage({ id: 'dealers.tabTitle' }),
      path: 'pages/Dealers/DealersView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewDealersTab = () =>
  addTab({
    tab: {
      id: 'dealers',
      title: intl.formatMessage({ id: 'dealers.tabTitle' }),
      path: 'pages/Dealers/DealersNew',
      isRemovable: true,
    },
  });

export const addEditDealersTab = () =>
  addTab({
    tab: {
      id: 'dealers',
      title: intl.formatMessage({ id: 'dealers.tabTitle' }),
      path: 'pages/Dealers/DealersEdit',
      isRemovable: true,
    },
  });

export const clearDealers = createAction('DEALERS/CLEAR');
export const clearDealersDraft = createAction('DEALERS/CLEAR_DRAFT');

export const setDealer = createAction<IDealer>('DEALERS/SET_DEALER');
export const setDealerDraft = createAction<IDealer>('DEALERS/SET_DEALER_DRAFT');

export const setDealersList = createAction<IDealer[]>('DEALERS/SET_LIST');

export const updateDealersList = createAction<IDealer>('DEALERS/UPDATE_LIST');

export const removeDealerFromList = createAction<number>('DEALERS/REMOVE_FROM_LIST');

export const duplicateDealer = createAction<IDealer>('DEALERS/DUPLICATE');

export const setDealerDraftValues = createAction<Partial<IDealer>>(
  'DEALERS/SET_DEALER_DRAFT_VALUE',
);

export const fetchDealerList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'DEALERS/FETCH_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(startRequest('dealers'));
      }

      const { data } = await api.dealers.fetchDealerListAPI();
      dispatch(setDealersList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'dealers',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('dealers'));
      }
    }
  },
);

export interface IFetchDealerParams {
  id: number;
}

export const fetchDealer = createAsyncThunk<void, number, AppThunkConfig>(
  'DEALERS/FETCH',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('dealers'));
    try {
      const { data } = await api.dealers.fetchDealerAPI(id);
      dispatch(setDealer(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'dealers',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('dealers'));
    }
  },
);
