import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, IStockTransfer } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const STOCK_TRANSFERS_TAB_ID = 'stockTransfers';

// StockTransfers Tab action creators
export const addHomeStockTransferTab = () =>
  addTab({
    tab: {
      id: STOCK_TRANSFERS_TAB_ID,
      title: intl.formatMessage({ id: 'stockTransfer.tabTitle' }),
      path: 'pages/StockTransfer/StockTransfer',
      isRemovable: true,
    },
  });

export const addViewStockTransferTab = (id?: number) =>
  addTab({
    tab: {
      id: STOCK_TRANSFERS_TAB_ID,
      title: intl.formatMessage({ id: 'stockTransfer.tabTitle' }),
      path: 'pages/StockTransfer/StockTransferView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewStockTransferTab = () =>
  addTab({
    tab: {
      id: STOCK_TRANSFERS_TAB_ID,
      title: intl.formatMessage({ id: 'stockTransfer.tabTitle' }),
      path: 'pages/StockTransfer/StockTransferNew',
      isRemovable: true,
    },
  });

export const addEditStockTransferTab = () =>
  addTab({
    tab: {
      id: STOCK_TRANSFERS_TAB_ID,
      title: intl.formatMessage({ id: 'stockTransfer.tabTitle' }),
      path: 'pages/StockTransfer/StockTransferEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearStockTransfer = createAction('STOCK_TRANSFER/CLEAR');

export const clearStockTransferDraft = createAction('STOCK_TRANSFER/CLEAR_DRAFT');

export const setStockTransfer = createAction<IStockTransfer>('STOCK_TRANSFER/SET_STOCK_TRANSFER');

export const setStockTransferDraft = createAction<IStockTransfer>(
  'STOCK_TRANSFER/SET_STOCK_TRANSFER_DRAFT',
);

export const setStockTransferList = createAction<IStockTransfer[]>('STOCK_TRANSFER/SET_LIST');

export const updateStockTransferList = createAction<IStockTransfer>('STOCK_TRANSFER/UPDATE_LIST');

export const removeStockTransferFromList = createAction<number>('STOCK_TRANSFER/REMOVE_FROM_LIST');

export const duplicateStockTransfer = createAction<IStockTransfer>('STOCK_TRANSFER/DUPLICATE');

export const setStockTransferDraftValues = createAction<Partial<IStockTransfer>>(
  'STOCK_TRANSFER/SET_STOCK_TRANSFER_DRAFT_VALUE',
);

// Complex actions
export const fetchStockTransferList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'STOCK_TRANSFER/FETCH_STOCK_TRANSFER_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    if (!silent) {
      dispatch(startRequest('stock-transfers'));
    }

    try {
      const { data } = await api.stockTransfers.fetchStockTransferListFromAPI();
      dispatch(
        setStockTransferList(
          Array.isArray(data) ? data.map(api.stockTransfers.mapFromAPIListItem) : [],
        ),
      );
      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'stockTransfer',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('stock-transfers'));
      }
    }
  },
);

export const fetchStockTransfer = createAsyncThunk<void, number, AppThunkConfig>(
  'STOCK_TRANSFER/FETCH_STOCK_TRANSFER',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('stock-transfers'));

    try {
      const { data } = await api.stockTransfers.fetchStockTransferFromAPI(id);
      dispatch(setStockTransfer(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'stockTransfer',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('stock-transfers'));
    }
  },
);
