import { createSelector } from '@reduxjs/toolkit';

import { AppState, ICafDTEState } from '../..';

export const getCafDTEListSlice = (state: AppState): ICafDTEState => state.cafDte;

export const getCafDTEList = createSelector([getCafDTEListSlice], (state) => state.list);

export const getCafDTE = createSelector([getCafDTEListSlice], (state) => state.current);

export const getCafDTEDraft = createSelector([getCafDTEListSlice], (state) => state.draft);
