import { forwardRef } from 'react';

import { getCurrencyList, useAppSelector } from '@laudus/redux-app';

const SelectCurrencyRendererComponent: React.ForwardRefRenderFunction<any, any> = (props, ref) => {
  const currencies = useAppSelector(getCurrencyList);

  const options = currencies.map((currency) => ({
    label: currency.code,
    value: currency.code,
  }));

  return <span>{options?.find((o) => o.value == props.value)?.label}</span>;
};

SelectCurrencyRendererComponent.displayName = 'SelectCurrencyRendererComponent';

export const SelectCurrencyRenderer = forwardRef(SelectCurrencyRendererComponent);
