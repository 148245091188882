import { AxiosResponse } from 'axios';

import { IProductSalesTax, ISalesTaxes } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchSalesTaxListFromAPI = (): Promise<AxiosResponse<ISalesTaxes[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/taxes/list`,
    {
      fields: [
        'taxId',
        'taxName',
        'rate',
        'amountPerUnit',
        'account.accountId',
        'account.accountNumber',
        'account.name',
        'type',
        'addToVATinF29',
        'SIICode',
        'applyToTickets',
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchSalesTaxFromAPI = (taxId: number): Promise<AxiosResponse<ISalesTaxes>> => {
  return axios.get(`${VITE_API_URL}/sales/taxes/${taxId}`, {
    headers: {
      Accept: 'application/json',
    },
  });
};

export const createSalesTaxFromAPI = (
  salesTaxes: ISalesTaxes,
): Promise<AxiosResponse<ISalesTaxes>> => axios.post(`${VITE_API_URL}/sales/taxes`, salesTaxes);

export const updateSalesTaxFromAPI = (
  salesTaxes: ISalesTaxes,
): Promise<AxiosResponse<ISalesTaxes>> =>
  axios.put(`${VITE_API_URL}/sales/taxes/${salesTaxes.taxId}`, salesTaxes);

export const deleteSalesTaxFromAPI = (taxId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/taxes/${taxId}`);

export const fetchProductSalesTaxListFromAPI = (): Promise<AxiosResponse<IProductSalesTax[]>> => {
  return axios.get(`${VITE_API_URL}/production/products/sales/taxes`, {
    headers: {
      Accept: 'application/json',
    },
  });
};
