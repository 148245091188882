import { createReducer } from '@reduxjs/toolkit';

import { IGlobalCompany, IGlobalUser, IGroup } from '@laudus/types';

import {
  clearAddingCompanyInfo,
  clearCompaniesOfGlobalUser,
  clearGlobalUser,
  clearGlobalUserDraft,
  removeGlobalUserFromList,
  setAddingCompanyAccessToken,
  setAddingCompanyGroups,
  setCompaniesOfGlobalUser,
  setGlobalUser,
  setGlobalUserDraft,
  setGlobalUserDraftValues,
  setGlobalUsersList,
  updateGlobalUsersList,
} from './actions';

export interface IGlobalUsersState {
  current: IGlobalUser;
  draft: IGlobalUser;
  list: IGlobalUser[];
  companies: IGlobalCompany[];
  addingCompanyAccessToken: string;
  addingCompanyGroups: IGroup[];
}

export const GLOBAL_USER_EMPTY: IGlobalUser = {
  glUserId: 0,
  loginName: '',
  accountType: 'B',
  email: '',
  VATId: '',
  glAccountId: 0,
  discontinued: false,
  createdAt: '',
  isAdmin: false,
};

export const initialGlobalUsersState: IGlobalUsersState = {
  current: GLOBAL_USER_EMPTY,
  draft: GLOBAL_USER_EMPTY,
  list: [],
  companies: [],
  addingCompanyAccessToken: '',
  addingCompanyGroups: [],
};

export const globalUsersReducer = createReducer(initialGlobalUsersState, (builder) => {
  builder
    .addCase(clearGlobalUser, (state) => {
      return { ...state, current: GLOBAL_USER_EMPTY };
    })
    .addCase(clearGlobalUserDraft, (state) => {
      return { ...state, draft: GLOBAL_USER_EMPTY };
    })
    .addCase(clearCompaniesOfGlobalUser, (state) => {
      return { ...state, companies: [] };
    })
    .addCase(setGlobalUser, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setGlobalUserDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setCompaniesOfGlobalUser, (state, action) => {
      return { ...state, companies: action.payload };
    })
    .addCase(setGlobalUserDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setGlobalUsersList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateGlobalUsersList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.glUserId !== action.payload.glUserId), action.payload],
      };
    })
    .addCase(removeGlobalUserFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.glUserId !== action.payload),
      };
    })
    .addCase(clearAddingCompanyInfo, (state) => {
      return { ...state, addingCompanyAccessToken: '', addingCompanyGroups: [] };
    })
    .addCase(setAddingCompanyAccessToken, (state, action) => {
      return { ...state, addingCompanyAccessToken: action.payload };
    })
    .addCase(setAddingCompanyGroups, (state, action) => {
      return { ...state, addingCompanyGroups: action.payload };
    })
    .addDefaultCase((state) => state);
});
