import { ChangeEvent, useState } from 'react';

import {
  Box,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
} from '@chakra-ui/react';

import { IconEye, IconEyeOff, IconLock } from '@laudus/icons';
import { useIntl } from '@laudus/intl';
import { colors } from '@laudus/shared-ui';

interface IPasswordInputProps {
  id: string;
  label: string;
  FormLabelColor?: { base: string; md: string };
  IconsColors?: { base: string; md: string };
  ShowHideIconColor?: { base: string; md: string };
  formError: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: string;
  handleIconClick?: () => void;
  showPassword?: boolean;
  disabledShowButton?: boolean;
}

// Input password component with show/hide password functionality
// the show/hide password functionality is optional and can be
// controlled externally or independently without passing
// handleIconClick and showPassword props
export const PasswordInput = ({
  id,
  FormLabelColor = { base: colors.secondary200, md: colors.neutral700 },
  label,
  IconsColors = { base: colors.secondary200, md: colors.primary500 },
  ShowHideIconColor = { base: colors.secondary200, md: colors.neutral500 },
  formError,
  onChange,
  error,
  handleIconClick,
  showPassword,
  disabledShowButton = false,
}: IPasswordInputProps) => {
  const intl = useIntl();

  const [show, setShow] = useState(false);
  const handleClick = () => setShow(!show);

  const labelText =
    showPassword || show
      ? intl.formatMessage({ id: 'input.hidePassword' })
      : intl.formatMessage({ id: 'input.showPassword' });
  return (
    <FormControl id={id} isInvalid={!!error} mb="1.6rem">
      <FormLabel color={FormLabelColor} variant="small" _dark={{ color: colors.secondary100 }}>
        {label}
      </FormLabel>
      <InputGroup>
        <InputRightElement pr="2rem">
          {!disabledShowButton && (
            <Tooltip aria-label={labelText} label={labelText}>
              <Box color={ShowHideIconColor} mr="0.4rem" onClick={handleIconClick || handleClick}>
                {showPassword || show ? <IconEyeOff size={18} /> : <IconEye size={18} />}
              </Box>
            </Tooltip>
          )}
          <Box color={IconsColors}>
            <IconLock size={18} />
          </Box>
        </InputRightElement>
        <Input
          _dark={{
            bg: colors.darkGrey500,
            border: `0.05rem solid ${colors.darkGrey200}`,
            color: colors.white,
            _selected: {
              border: `0.05rem solid ${colors.primary500}`,
            },
            _focus: {
              border: `0.05rem solid ${colors.primary500}`,
            },
            _disabled: { color: colors.secondary100 },
          }}
          bg={{ base: colors.secondary600, md: colors.white }}
          borderColor={{
            base: formError ? colors.danger400 : colors.secondary200,
            md: formError ? colors.danger400 : colors.neutral500,
          }}
          color={{ base: colors.white, md: colors.black }}
          onChange={onChange}
          type={showPassword || show ? 'text' : 'password'}
        />
      </InputGroup>
      {!!error && <FormErrorMessage fontSize="1.5rem">{error}</FormErrorMessage>}
    </FormControl>
  );
};
