import { createReducer } from '@reduxjs/toolkit';

import { IInvoiceTaxes, IProductSalesTax, ISalesTaxes } from '@laudus/types';

import {
  clearSalesTaxes,
  clearSalesTaxesDraft,
  duplicateSalesTax,
  removeSalesTaxFromList,
  setProductSalesTaxesList,
  setSalesTax,
  setSalesTaxDraft,
  setSalesTaxDraftValues,
  setSalesTaxesList,
  updateSalesTaxesList,
} from './actions';

export const SALES_TAXES_TAB_ID = 'salesTaxes';

export interface ISalesTaxesState {
  current: ISalesTaxes;
  draft: ISalesTaxes;
  invoicesTaxList: IInvoiceTaxes[];
  productSalesTaxesList: IProductSalesTax[];
  list: ISalesTaxes[];
}

export const SALES_TAX_EMPTY: ISalesTaxes = {
  taxId: 0,
  taxName: null,
  rate: 0,
  amountPerUnit: 0,
  account: {
    accountId: 0,
    accountNumber: null,
    name: '',
  },
  type: '',
  addToVATinF29: false,
  SIICode: 0,
  applyToTickets: false,
};

export const initialSalesTaxesState: ISalesTaxesState = {
  current: SALES_TAX_EMPTY,
  draft: SALES_TAX_EMPTY,
  invoicesTaxList: [],
  productSalesTaxesList: [],
  list: [],
};

export const salesTaxesReducer = createReducer(initialSalesTaxesState, (builder) => {
  builder
    .addCase(clearSalesTaxes, (state) => {
      return { ...state, current: SALES_TAX_EMPTY };
    })
    .addCase(clearSalesTaxesDraft, (state) => {
      return { ...state, draft: SALES_TAX_EMPTY };
    })
    .addCase(setSalesTax, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setSalesTaxDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setSalesTaxDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setSalesTaxesList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateSalesTaxesList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((d) => d.taxId !== action.payload.taxId), action.payload],
      };
    })
    .addCase(removeSalesTaxFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.taxId !== action.payload),
      };
    })
    .addCase(setProductSalesTaxesList, (state, action) => {
      return { ...state, productSalesTaxesList: action.payload };
    })
    .addCase(duplicateSalesTax, (state, action) => {
      const { taxId, ...duplicatedSalesTaxes } = action.payload;
      return { ...state, draft: duplicatedSalesTaxes };
    })
    .addDefaultCase((state) => state);
});
