import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IShowErrorParams } from '@laudus/redux-global';
import {
  createSalesInvoiceFromSalesOrder,
  createSalesInvoiceFromSalesQuote,
  createSalesInvoiceFromSalesWaybill,
  updateDraftSalesItems,
} from '@laudus/sales-utils';
import {
  addDaysToDate,
  calculateDatesDifference,
  dateToLocalISOString,
  debounce,
  EmailInvoicesTemplate,
  getMessageFromError,
  getMessageFromSpecialAPIErrorCode,
  getNegativeUniqueNumericId,
  printPDF,
} from '@laudus/shared-utils';
import { IPrintTemplateSettings, templateIndex } from '@laudus/templates-pdf';
import {
  IAPIError,
  IEmail,
  IInvoiceReceipt,
  IPrintSalesInvoiceAfterSave,
  IProductListItem,
  IReceipts,
  IRedirectTo,
  ISalesInvoice,
  ISalesInvoiceList,
  ISalesInvoicesFilters,
  ISalesInvoicesItem,
  ITraceFrom,
} from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert } from '../alerts';
import { getBranch } from '../branches';
import { getMainCurrency } from '../currencies';
import { getCustomer } from '../customers';
import { getCustomFieldsByEntity } from '../customFields';
import { getDocTypesInvoices, getIsAnElectronicDocument } from '../docTypes/selectors';
import { endRequest, startRequest } from '../global/actions';
import { getPosCurrent } from '../pos';
import {
  getProductByBarcode,
  getProductById,
  getProductsList,
  getProductWithPriceOverridesById,
} from '../products';
import { createReceiptFromSalesInvoice } from '../receipts';
import { navigateTo } from '../router';
import { getPrintTemplateSettings, getSettings, getSettingsByName } from '../settings';
import { createEmail } from '../systems';
import { addTab } from '../tabs';
import { getUser } from '../users';

import {
  getNumberOfCopiesToPrintSalesInvoice,
  getSalesInvoiceDraft,
  getSalesInvoicesDraftComputedItems,
  getSalesInvoicesDraftItems,
  getSalesInvoicesItems,
  getSalesInvoicesTotalFromItems,
  getShouldSalesInvoiceBeSentToSII,
} from './selectors';

export const SALESINVOICES_TAB_ID = 'salesInvoices';
export const salesInvoicesPrefix = 'SALES_INVOICES';

export const addHomeSalesInvoicesTab = () =>
  addTab({
    tab: {
      id: SALESINVOICES_TAB_ID,
      title: intl.formatMessage({ id: 'invoices.tabTitle' }),
      path: 'pages/SalesInvoice/Invoices',
      isRemovable: true,
    },
  });

export const addViewSalesInvoicesTab = (id?: string) =>
  addTab({
    tab: {
      id: SALESINVOICES_TAB_ID,
      title: intl.formatMessage({ id: 'invoices.tabTitle' }),
      path: 'pages/SalesInvoice/InvoicesView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewSalesInvoicesTab = () =>
  addTab({
    tab: {
      id: SALESINVOICES_TAB_ID,
      title: intl.formatMessage({ id: 'invoices.tabTitle' }),
      path: 'pages/SalesInvoice/InvoicesNew',
      isRemovable: true,
    },
  });

export const addEditSalesInvoicesTab = (id?: string) =>
  addTab({
    tab: {
      id: SALESINVOICES_TAB_ID,
      title: intl.formatMessage({ id: 'invoices.tabTitle' }),
      path: 'pages/SalesInvoice/InvoicesEdit',
      props: { id },
      isRemovable: true,
    },
  });

// Simple actions
export const clearSalesInvoice = createAction(`${salesInvoicesPrefix}/CLEAR`);
export const clearSalesInvoiceDraft = createAction(`${salesInvoicesPrefix}/CLEAR_DRAFT`);
export const clearSalesInvoiceDraftList = createAction('SALES_INVOICES/CLEAR_DRAFT_LIST');
export const clearSalesInvoicePDFUrl = createAction(`${salesInvoicesPrefix}/CLEAR_PDF_URL`);

export const setSalesInvoice = createAction<ISalesInvoice>(`${salesInvoicesPrefix}/SET`);
export const setSalesInvoiceDraft = createAction<ISalesInvoice>(`${salesInvoicesPrefix}/SET_DRAFT`);
export const setSalesInvoiceDraftValues = createAction<Partial<ISalesInvoice>>(
  `${salesInvoicesPrefix}/SET_DRAFT_VALUES`,
);
export const saveSalesInvoiceDraft = createAction('SALES_INVOICES/SAVE_DRAFT');
export const removeSalesInvoiceDraft = createAction<number>('SALES_INVOICES/REMOVE_DRAFT');
export const restoreSalesInvoiceDraft = createAction<number>('SALES_INVOICES/RESTORE_DRAFT');
export const addItemToSalesInvoiceDraft = createAction<ISalesInvoicesItem>(
  'SALES_INVOICES/ADD_ITEM_TO_SALES_INVOICE_DRAFT',
);
export const deleteItemFromSalesInvoiceDraft = createAction<ISalesInvoicesItem>(
  'SALES_INVOICES/DELETE_ITEM_FROM_SALES_INVOICE_DRAFT',
);
export const updateItemFromSalesInvoiceDraft = createAction<ISalesInvoicesItem>(
  'SALES_INVOICES/UPDATE_ITEM_FROM_SALES_INVOICE_DRAFT',
);
export const updateAllItemsDiscount = createAction<number>(
  'SALES_INVOICES/UPDATE_ALL_ITEMS_DISCOUNT',
);

export const setSalesInvoicePDFUrl = createAction<string>(`${salesInvoicesPrefix}/SET_PDF_URL`);
export const setSalesInvoiceList = createAction<ISalesInvoiceList>(
  `${salesInvoicesPrefix}/SET_LIST`,
);
export const setSalesInvoiceTraces = createAction<ITraceFrom[]>('SALES_INVOICES/SET_TRACES');
export const updateSalesInvoiceList = createAction<ISalesInvoice>(
  `${salesInvoicesPrefix}/UPDATE_LIST`,
);
export const removeSalesInvoiceFromList = createAction<string>(
  `${salesInvoicesPrefix}/REMOVE_FROM_LIST`,
);
export const setSalesInvoicesReceiptList = createAction<IInvoiceReceipt[]>(
  `${salesInvoicesPrefix}/SET_RECEIPT_LIST`,
);

export const setSalesInvoicePDFLoading = createAction<boolean>('SALES_INVOICES_PDF/LOADING');

export const clearAllItemsFromCreditNote = createAction(
  'SALES_INVOICES/CLEAR_ALL_ITEMS_FROM_CREDIT_NOTE',
);

export const clearHistoryAdvancedSearch = createAction(
  'SALES_INVOICES/CLEAR_HISTORY_ADVANCED_SEARCH',
);

// Complex actions
export const duplicateSalesInvoice = createAsyncThunk<void, ISalesInvoice, AppThunkConfig>(
  'SALES_INVOICES/DUPLICATE',
  async (salesInvoice: ISalesInvoice, ThunkAPI) => {
    const { dispatch } = ThunkAPI;

    const {
      salesInvoiceId: _salesInvoiceIdTmp,
      docNumber: _docNumber,
      purchaseOrderNumber: _purchaseOrderNumber,
      journalEntry: _journalEntry,
      items,
      ...duplicatedSalesInvoice
    } = salesInvoice;

    duplicatedSalesInvoice.DTE = null;

    // issued and due dates
    const datesDifference = calculateDatesDifference(
      duplicatedSalesInvoice.issuedDate,
      duplicatedSalesInvoice.dueDate,
    );
    duplicatedSalesInvoice.issuedDate = dateToLocalISOString(new Date());
    duplicatedSalesInvoice.dueDate = addDaysToDate(new Date(), datesDifference).toISOString();

    // Other properties to overwrite
    duplicatedSalesInvoice.bypassCreditLimit = false;
    duplicatedSalesInvoice.references = null;
    duplicatedSalesInvoice.source = null;
    duplicatedSalesInvoice.sourceOrderId = null;
    // TODO: check fields to reset
    // idAsiento ? no viene en la respuesta
    // source_id ? pendiente
    // source_idOrder ? no viene en la respuesta

    // Reset all itemIds
    const newItems: ISalesInvoicesItem[] = items.map((item) => ({
      ...item,
      itemId: getNegativeUniqueNumericId(),
      traceFrom: null,
    }));

    // Build new draft and store the changes
    const newSalesInvoice: ISalesInvoice = {
      ...duplicatedSalesInvoice,
      items: newItems,
    };

    dispatch(setSalesInvoiceDraft(newSalesInvoice));
  },
);

export const reverseSalesInvoiceIntoDraft = createAsyncThunk<void, ISalesInvoice, AppThunkConfig>(
  'SALES_INVOICES/REVERSE_SALES_INVOICE_INTO_DRAFT',
  async (referencedInvoice: ISalesInvoice, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;
    const draft = getSalesInvoiceDraft(getState());

    // Header
    const { salesman, dealer, branch, pos, warehouse, totals, totalsOriginalCurrency } =
      referencedInvoice;

    // Totals with negative values
    const negativeTotals = {
      ...totals,
      net: (totals?.net ?? 0) * -1,
      VAT: (totals?.VAT ?? 0) * -1,
      notInvoiceable_income: (totals?.notInvoiceable_income ?? 0) * -1,
      notInvoiceable_total: (totals?.notInvoiceable_total ?? 0) * -1,
      total: (totals?.total ?? 0) * -1,
      taxes: totals?.taxes?.map((tax) => ({
        ...tax,
        amount: (tax?.amount ?? 0) * -1,
      })),
    };

    const negativeTotalsOriginalCurrency = {
      ...totalsOriginalCurrency,
      net: (totalsOriginalCurrency?.net ?? 0) * -1,
      VAT: (totalsOriginalCurrency?.VAT ?? 0) * -1,
      notInvoiceable_income: (totalsOriginalCurrency?.notInvoiceable_income ?? 0) * -1,
      notInvoiceable_total: (totalsOriginalCurrency?.notInvoiceable_total ?? 0) * -1,
      total: (totalsOriginalCurrency?.total ?? 0) * -1,
      taxes: totalsOriginalCurrency?.taxes?.map((tax) => ({
        ...tax,
        amount: (tax?.amount ?? 0) * -1,
      })),
    };

    // Items
    const reverseItems: ISalesInvoicesItem[] = referencedInvoice.items.map((item) => {
      const {
        quantity,
        unitPrice,
        originalUnitPrice,
        VAT,
        VATRate,
        taxes,
        parityToMainCurrency,
        currencyCode,
        lot,
        salesmanCommission,
        dealerCommission,
        account,
        costCenter,
        customFields,
        itemDescription,
        product,
        discountPercentage,
      } = item;

      const copiedItem: ISalesInvoicesItem = {
        // Item id must be a new one
        itemId: getNegativeUniqueNumericId(),

        // Not of credit should be "R" by default
        noteOfCreditType: 'R',

        // Copied properties
        unitPrice,
        originalUnitPrice,
        VATRate,
        parityToMainCurrency,
        lot,
        salesmanCommission,
        dealerCommission,
        account,
        costCenter,
        customFields,
        itemDescription,
        currencyCode,
        discountPercentage,
        product,

        // Copied and reversed properties
        quantity: quantity * -1,
        VAT: VAT * -1,
        taxes: taxes.map((tax) => ({
          ...tax,
          amount: (tax?.amount ?? 0) * -1,
        })),

        // Required but not copied properties
        notInvoiceable: false,

        // Optional and not copied properties
        traceFrom: undefined,
      };

      return copiedItem;
    });

    // Dispatch the setSalesInvoiceDraft action with the new draft
    dispatch(
      setSalesInvoiceDraft({
        ...draft,
        salesman,
        dealer,
        branch,
        pos,
        warehouse,
        totals: negativeTotals,
        totalsOriginalCurrency: negativeTotalsOriginalCurrency,
        items: reverseItems,
      }),
    );
  },
);

export const fetchSalesInvoiceList = createAsyncThunk<void, void, AppThunkConfig>(
  `${salesInvoicesPrefix}/FETCH_LIST`,
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('fetch-sales-invoices-list'));

    try {
      const { data } = await api.salesInvoices.fetchSalesInvoiceListFromAPI();
      dispatch(setSalesInvoiceList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'invoices',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('fetch-sales-invoices-list'));
    }
  },
);

export const fetchSalesInvoice = createAsyncThunk<void, string, AppThunkConfig>(
  `${salesInvoicesPrefix}/FETCH`,
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('fetch-sales-invoices'));

      const { data } = await api.salesInvoices.fetchSalesInvoiceFromAPI(id);

      if (!data.items) {
        data.items = [];
      }

      dispatch(setSalesInvoice(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'invoices',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('fetch-sales-invoices'));
    }
  },
);

export const fetchSalesInvoicesReceiptList = createAsyncThunk<void, string, AppThunkConfig>(
  `${salesInvoicesPrefix}/FETCH_RECEIPT_LIST`,
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('fetch-sales-invoices-receipt-list'));

    try {
      const { data } = await api.salesInvoices.fetchSalesInvoicesReceiptListFromAPI(id);
      dispatch(setSalesInvoicesReceiptList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'invoices',
          action: 'readReceiptList' as IShowErrorParams['action'],
        }),
      );
    } finally {
      dispatch(endRequest('fetch-sales-invoices-receipt-list'));
    }
  },
);

export const updateSalesInvoiceDraftItem = createAsyncThunk<
  void,
  ISalesInvoicesItem,
  AppThunkConfig
>('SALES_ORDERS/UPDATE_SALES_INVOICE_DRAFT_ITEM', async (updatedItem, ThunkAPI) => {
  const { dispatch, getState, extra } = ThunkAPI;
  const { api } = extra;

  const { mainCurrencyDecimals } = getMainCurrency(getState());

  const draft = getSalesInvoiceDraft(getState());
  const updateDraftItems = await updateDraftSalesItems(
    updatedItem,
    draft,
    'invoices',
    mainCurrencyDecimals,
    api.products.fetchProductSalesPriceAPI,
  );

  dispatch(setSalesInvoiceDraftValues({ items: updateDraftItems as ISalesInvoicesItem[] }));
});

// Auxiliar action for createSalesInvoiceAndPrint and updateSalesInvoiceAndPrint
const fetchSalesInvoicePDF = createAsyncThunk<void, ISalesInvoice, AppThunkConfig>(
  `${salesInvoicesPrefix}/FETCH_PDF`,
  async (salesInvoice, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;
    const getState = ThunkAPI.getState;

    const numberOfCopies = getNumberOfCopiesToPrintSalesInvoice(getState());
    const shouldSalesInvoiceBeSentToSII = getShouldSalesInvoiceBeSentToSII(getState());

    try {
      dispatch(startRequest('fetch-sales-invoices-pdf'));

      if (shouldSalesInvoiceBeSentToSII) {
        // We need to wait for the SII response before ask for the PDF
        const sentInvoice = await api.salesInvoices.sendSalesInvoiceToSII(salesInvoice);

        dispatch(setSalesInvoice(sentInvoice));
        dispatch(
          showAlert({
            type: 'success',
            title: intl.formatMessage({ id: 'invoices.sendToSII.sucess' }),
            message: '',
          }),
        );
      }

      // Fetch sales invoice PDF and store its url
      const pdfURL = await api.salesInvoices.fetchSalesInvoicePDFUrl({
        salesInvoice,
        numberOfCopies,
      });
      dispatch(setSalesInvoicePDFUrl(pdfURL));
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: intl.formatMessage({ id: 'pdfViewer.notFound' }),
          message: getMessageFromError({ error }),
        }),
      );
    } finally {
      // Redirect to view sales invoice
      dispatch(addViewSalesInvoicesTab());

      dispatch(endRequest('fetch-sales-invoices-pdf'));
    }
  },
);

export const createSalesInvoiceAndPrint = createAsyncThunk<void, ISalesInvoice, AppThunkConfig>(
  `${salesInvoicesPrefix}/CREATE_AND_PRINT`,
  async (salesInvoice, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('create-sales-invoices-and-print'));

    try {
      const { data: newSalesInvoice } =
        await api.salesInvoices.createSalesInvoiceFromAPI(salesInvoice);
      dispatch(setSalesInvoice(newSalesInvoice));
      dispatch(updateSalesInvoiceList(newSalesInvoice));
      dispatch(fetchSalesInvoicePDF(newSalesInvoice));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesInvoices',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('create-sales-invoices-and-print'));
    }
  },
);

export const updateSalesInvoiceAndPrint = createAsyncThunk<void, ISalesInvoice, AppThunkConfig>(
  `${salesInvoicesPrefix}/UPDATE_AND_PRINT`,
  async (salesInvoice, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('update-sales-invoices-and-print'));

    try {
      const { data: updatedSalesInvoice } =
        await api.salesInvoices.updateSalesInvoiceFromAPI(salesInvoice);
      dispatch(setSalesInvoice(updatedSalesInvoice));
      dispatch(updateSalesInvoiceList(updatedSalesInvoice));
      dispatch(fetchSalesInvoicePDF(updatedSalesInvoice));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesInvoices',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('update-sales-invoices-and-print'));
    }
  },
);

export const setDraftAsNoteOfCreditFromReferencedSalesInvoice = createAsyncThunk<
  void,
  { referencedSalesInvoiceId: string; selectedReferenceType: string },
  AppThunkConfig
>(
  `${salesInvoicesPrefix}/SET_DRAFT_AS_NOTE_OF_CREDIT`,
  async ({ referencedSalesInvoiceId, selectedReferenceType }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('set-draft-as-note-of-credit-from-sales-invoices'));

    try {
      // At this point we have the referenced invoice.
      const { data: referencedInvoice } =
        await api.salesInvoices.fetchSalesInvoiceFromAPI(referencedSalesInvoiceId);

      // Before everything, we must set the same customer
      dispatch(setSalesInvoiceDraftValues({ customer: referencedInvoice.customer }));

      // Now we can perform differents operations based on the selected reference type
      // For example, reverse its quantity if the type is 1, 2 or 3
      // See DTE_REFERENCED_CODES constant for all the possible reference types
      const referenceTypesWichInvolveReversingTheData = ['1', '2', '3'];

      if (referenceTypesWichInvolveReversingTheData.includes(selectedReferenceType)) {
        dispatch(reverseSalesInvoiceIntoDraft(referencedInvoice));
      }
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: '',
          message: getMessageFromError({ error }),
        }),
      );
    } finally {
      dispatch(endRequest('set-draft-as-note-of-credit-from-sales-invoices'));
    }
  },
);

// CREATE INVOICE FROM OTHER SALES DOCUMENTS
export const convertSalesOrderIntoSalesInvoiceDraft = createAsyncThunk<
  void,
  {
    initialInvoice: ISalesInvoice;
    salesOrderId: number;
    shouldRedirectToNewView: boolean;
    onSucessfullyConverted?: (convertedSalesInvoice: ISalesInvoice) => void;
  },
  AppThunkConfig
>(
  `${salesInvoicesPrefix}/CREATE_SALES_INVOICE_FROM_SALES_ORDER`,
  async (
    { initialInvoice, salesOrderId, shouldRedirectToNewView, onSucessfullyConverted },
    ThunkAPI,
  ) => {
    const { dispatch, getState, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('conver-sales-order-into-sales-invoice-draft'));

    // State from Redux
    const state = getState();

    const user = getUser(state);
    const settings = getSettings(state);
    const { mainCurrencyDecimals } = getMainCurrency(state);
    const productList = getProductsList(state);

    const customFieldsForSalesInvoices = getCustomFieldsByEntity('salesInvoices')(state);
    const customFieldsForSalesInvoicesItems = getCustomFieldsByEntity('salesInvoicesItems')(state);
    const customFieldsForSalesOrders = getCustomFieldsByEntity('salesOrders')(state);
    const customFieldsForSalesOrdersItems = getCustomFieldsByEntity('salesOrdersItems')(state);

    try {
      // Derived state
      const shouldCopyNotesToInvoice = settings.sales_invoices_copyNotes?.keyValue === true;
      const groupItemsWithTheSameProduct = settings.sales_groupWhenInvoicing?.keyValue === true;
      const maxItemsInInvoices = settings.maxItemsInInvoices?.keyValue
        ? (settings.maxItemsInInvoices.keyValue as number)
        : undefined;

      const generalVATRate = settings.VATRate?.keyValue
        ? (settings.VATRate.keyValue as number)
        : 19;
      const allowDTE = settings.DTE_allow?.keyValue === true;
      const allowDTETickets = settings.DTE_allowTickets?.keyValue === true;

      // Get the sales order
      const { data: salesOrder } = await api.salesOrders.fetchSalesOrderFromAPI(salesOrderId);

      if (!salesOrder) {
        throw new Error(
          `No se ha conseguido encontrar un pedido de ventas con el id ${salesOrderId}`,
        );
      }

      // Get how much is delivered in this order sales
      const { data: quantityDeliveredResponse } =
        await api.salesOrders.getQuantityDeliveredBySalesOrderFromAPI(salesOrderId);

      const quantityDeliveredFromOrder =
        !!quantityDeliveredResponse && Array.isArray(quantityDeliveredResponse)
          ? quantityDeliveredResponse
          : [];

      // Create the invoice from the order
      const invoiceFromOrder = await createSalesInvoiceFromSalesOrder({
        initialInvoice,
        salesOrder,
        user,
        customFieldsForSalesInvoices,
        customFieldsForSalesInvoicesItems,
        customFieldsForSalesOrders,
        customFieldsForSalesOrdersItems,
        shouldCopyNotesToInvoice,
        quantityDeliveredFromOrder,
        groupItemsWithTheSameProduct,
        maxItemsInInvoices,
        generalVATRate,
        mainCurrencyDecimals,
        allowDTE,
        allowDTETickets,
        productList,
      });

      // Update Redux
      dispatch(setSalesInvoiceDraft(invoiceFromOrder));

      // Alert about some items being already delivered
      const isSomeOrderItemAlreadyDelivered = quantityDeliveredFromOrder.some(
        (quantityDelivered) => quantityDelivered.delivered > 0,
      );

      if (isSomeOrderItemAlreadyDelivered) {
        dispatch(
          showAlert({
            type: 'info',
            title: intl.formatMessage({
              id: 'invoices.infoToast.createFromSalesOrder.itemsAlreadyDelivered',
            }),
            message: '',
          }),
        );
      }

      // Redirect if neccesary
      if (shouldRedirectToNewView) {
        dispatch(addNewSalesInvoicesTab());
      }

      if (onSucessfullyConverted) {
        onSucessfullyConverted(invoiceFromOrder);
      }
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'invoices.errorToast.createFromSalesOrder',
          }),
          // Try to get message by looking for special code
          message:
            getMessageFromSpecialAPIErrorCode(error as IAPIError) ?? getMessageFromError({ error }),
        }),
      );
    } finally {
      dispatch(endRequest('conver-sales-order-into-sales-invoice-draft'));
    }
  },
);

export const convertSalesQuoteIntoSalesInvoiceDraft = createAsyncThunk<
  void,
  {
    initialInvoice: ISalesInvoice;
    salesQuoteId: number;
    shouldRedirectToNewView: boolean;
  },
  AppThunkConfig
>(
  `${salesInvoicesPrefix}/CREATE_SALES_INVOICE_FROM_SALES_QUOTE`,
  async ({ initialInvoice, salesQuoteId, shouldRedirectToNewView }, ThunkAPI) => {
    const { dispatch, getState, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('convert-sales-quote-into-sales-invoice-draft'));

    // State from Redux
    const state = getState();

    const user = getUser(state);
    const settings = getSettings(state);
    const { mainCurrencyDecimals } = getMainCurrency(state);
    const productList = getProductsList(state);

    const customFieldsForSalesInvoices = getCustomFieldsByEntity('salesInvoices')(state);
    const customFieldsForSalesInvoicesItems = getCustomFieldsByEntity('salesInvoicesItems')(state);
    const customFieldsForSalesQuotes = getCustomFieldsByEntity('salesQuotes')(state);
    const customFieldsForSalesQuotesItems = getCustomFieldsByEntity('salesQuotesItems')(state);

    try {
      // Derived state
      const shouldCopyNotesToInvoice = settings.sales_invoices_copyNotes?.keyValue === true;
      const groupItemsWithTheSameProduct = settings.sales_groupWhenInvoicing?.keyValue === true;
      const maxItemsInInvoices = settings.maxItemsInInvoices?.keyValue
        ? (settings.maxItemsInInvoices.keyValue as number)
        : undefined;

      const generalVATRate = settings.VATRate?.keyValue
        ? (settings.VATRate.keyValue as number)
        : 19;
      const allowDTE = settings.DTE_allow?.keyValue === true;
      const doesSalesQuoteHaveToBeApproved =
        settings.sales_quotes_haveToBeApproved?.keyValue === true;
      const doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts =
        settings.sales_quotes_haveToBeApprovedOnlyWhenDiscounts?.keyValue === true;

      // Get the sales quote
      const { data: salesQuote } = await api.salesQuotes.fetchSalesQuoteFromAPI(salesQuoteId);

      if (!salesQuote) {
        throw new Error(
          `No se ha conseguido encontrar una cotización de ventas con el id ${salesQuoteId}`,
        );
      }

      // Get how much is delivered in this sales quote
      const { data: quantityDeliveredResponse } =
        await api.salesQuotes.getQuantityDeliveredBySalesQuoteFromAPI(salesQuoteId);

      const quantityDeliveredFromQuote =
        !!quantityDeliveredResponse && Array.isArray(quantityDeliveredResponse)
          ? quantityDeliveredResponse
          : [];

      // Create the invoice from the quote
      const invoiceFromQuote = await createSalesInvoiceFromSalesQuote({
        initialInvoice,
        salesQuote,
        user,
        customFieldsForSalesInvoices,
        customFieldsForSalesInvoicesItems,
        customFieldsForSalesQuotes,
        customFieldsForSalesQuotesItems,
        shouldCopyNotesToInvoice,
        quantityDeliveredFromQuote,
        groupItemsWithTheSameProduct,
        maxItemsInInvoices,
        generalVATRate,
        mainCurrencyDecimals,
        allowDTE,
        doesSalesQuoteHaveToBeApproved,
        doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts,
        productList,
      });

      // Update Redux
      dispatch(setSalesInvoiceDraft(invoiceFromQuote));

      // Alert about some items being already delivered
      const isSomeOrderItemAlreadyDelivered = quantityDeliveredFromQuote.some(
        (quantityDelivered) => quantityDelivered.delivered > 0,
      );

      if (isSomeOrderItemAlreadyDelivered) {
        dispatch(
          showAlert({
            type: 'info',
            title: intl.formatMessage({
              id: 'invoices.infoToast.createFromSalesQuote.itemsAlreadyDelivered',
            }),
            message: '',
          }),
        );
      }

      // Redirect if neccesary
      if (shouldRedirectToNewView) {
        dispatch(addNewSalesInvoicesTab());
      }
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'invoices.errorToast.createFromSalesQuote',
          }),
          // Try to get message by looking for special code
          message:
            getMessageFromSpecialAPIErrorCode(error as IAPIError) ?? getMessageFromError({ error }),
        }),
      );
    } finally {
      dispatch(endRequest('convert-sales-quote-into-sales-invoice-draft'));
    }
  },
);

export const convertSalesWaybillIntoSalesInvoiceDraft = createAsyncThunk<
  void,
  {
    initialInvoice: ISalesInvoice;
    salesWaybillId: number;
    shouldRedirectToNewView: boolean;
    onSucessfullyConverted?: (convertedSalesInvoice: ISalesInvoice) => void;
  },
  AppThunkConfig
>(
  `${salesInvoicesPrefix}/CREATE_SALES_INVOICE_FROM_SALES_WAYBILL`,
  async (
    { initialInvoice, salesWaybillId, shouldRedirectToNewView, onSucessfullyConverted },
    ThunkAPI,
  ) => {
    const { dispatch, getState, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('conver-sales-waybill-into-sales-invoice-draft'));

    // State from Redux
    const state = getState();

    const user = getUser(state);
    const settings = getSettings(state);
    const { mainCurrencyDecimals } = getMainCurrency(state);
    const productList = getProductsList(state);

    const customFieldsForSalesInvoices = getCustomFieldsByEntity('salesInvoices')(state);
    const customFieldsForSalesInvoicesItems = getCustomFieldsByEntity('salesInvoicesItems')(state);
    const customFieldsForSalesWaybills = getCustomFieldsByEntity('salesWaybills')(state);
    const customFieldsForSalesWaybillsItems = getCustomFieldsByEntity('salesWaybillsItems')(state);

    try {
      // Derived state
      const shouldCopyNotesToInvoice = settings.sales_invoices_copyNotes?.keyValue === true;
      const groupItemsWithTheSameProduct = settings.sales_groupWhenInvoicing?.keyValue === true;
      const maxItemsInInvoices = settings.maxItemsInInvoices?.keyValue
        ? (settings.maxItemsInInvoices.keyValue as number)
        : undefined;

      const generalVATRate = settings.VATRate?.keyValue
        ? (settings.VATRate.keyValue as number)
        : 19;
      const allowDTE = settings.DTE_allow?.keyValue === true;
      const allowDTETickets = settings.DTE_allowTickets?.keyValue === true;

      // Get the sales waybill
      const { data: salesWaybill } =
        await api.salesWaybills.fetchSalesWaybillFromAPI(salesWaybillId);

      if (!salesWaybill) {
        throw new Error(
          `No se ha conseguido encontrar un pedido de ventas con el id ${salesWaybillId}`,
        );
      }

      if (!salesWaybill.isSale) {
        throw new Error(`La Guía de Despacho ${salesWaybill.docNumber} no constituye venta.`);
      }

      // Get how much is delivered in this sales waybill
      const { data: quantityDeliveredResponse } =
        await api.salesWaybills.getQuantityDeliveredBySalesWaybillFromAPI(salesWaybillId);

      const quantityDeliveredFromWaybill =
        !!quantityDeliveredResponse && Array.isArray(quantityDeliveredResponse)
          ? quantityDeliveredResponse
          : [];

      // Create the invoice from the waybill
      const invoiceFromWaybill = await createSalesInvoiceFromSalesWaybill({
        initialInvoice,
        salesWaybill,
        user,
        customFieldsForSalesInvoices,
        customFieldsForSalesInvoicesItems,
        customFieldsForSalesWaybills,
        customFieldsForSalesWaybillsItems,
        shouldCopyNotesToInvoice,
        quantityDeliveredFromWaybill,
        groupItemsWithTheSameProduct,
        maxItemsInInvoices,
        generalVATRate,
        mainCurrencyDecimals,
        allowDTE,
        allowDTETickets,
        productList,
      });

      // Update Redux
      dispatch(setSalesInvoiceDraft(invoiceFromWaybill));

      // Alert about some items being already delivered
      const isSomeWaybillItemAlreadyDelivered = quantityDeliveredFromWaybill.some(
        (quantityDelivered) => quantityDelivered.delivered > 0,
      );

      if (isSomeWaybillItemAlreadyDelivered) {
        dispatch(
          showAlert({
            type: 'info',
            title: intl.formatMessage({
              id: 'invoices.infoToast.createFromSalesWaybill.itemsAlreadyDelivered',
            }),
            message: '',
          }),
        );
      }

      // Redirect if neccesary
      if (shouldRedirectToNewView) {
        dispatch(addNewSalesInvoicesTab());
      }

      if (onSucessfullyConverted) {
        onSucessfullyConverted(invoiceFromWaybill);
      }
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'invoices.errorToast.createFromSalesWaybill',
          }),
          // Try to get message by looking for special code
          message:
            getMessageFromSpecialAPIErrorCode(error as IAPIError) ?? getMessageFromError({ error }),
        }),
      );
    } finally {
      dispatch(endRequest('conver-sales-waybill-into-sales-invoice-draft'));
    }
  },
);

interface IAddProductToSalesInvoiceParams {
  product: IProductListItem;
  quantity?: number;
}
export const addProductToSalesInvoiceWithoutCallingBackendForProductData = createAsyncThunk<
  void,
  IAddProductToSalesInvoiceParams,
  AppThunkConfig
>('SALES_INVOICES/ADD_PRODUCT_TO_SALES_INVOICE', async ({ product, quantity }, ThunkAPI) => {
  const { getState, dispatch } = ThunkAPI;
  const salesInvoiceDraft = getSalesInvoiceDraft(getState());
  const itemAlreadyAdded = salesInvoiceDraft.items.some(
    (i) => i?.product?.productId === product.productId,
  );

  const item: ISalesInvoicesItem = {
    unitPrice: product.unitPrice,
    VATRate: product.VATRate,
    // This VAT decimal is rounded without decimals because the POS will be only in CLP
    VAT: Math.round((product.unitPrice * (product?.VATRate ?? 0)) / 100),
    quantity: quantity ?? 1,
    product: {
      productId: product.productId,
      sku: product.sku,
      description: product.description,
      applyGeneralVATRate: product.applyGeneralVATRate,
      allowFreeDescription: product.allowFreeDescription,
      hasVolumeDiscounts: product.hasVolumeDiscounts,
    },
    itemDescription: '',
    originalUnitPrice: product.unitPrice,
    currencyCode: 'CLP',
    parityToMainCurrency: 1,
    taxes: [],
    discountPercentage: salesInvoiceDraft.generalDiscount ?? 0,
    salesmanCommission: 0,
    dealerCommission: 0,
    noteOfCreditType: '',
    notInvoiceable: product.notInvoiceable ?? false,
    notInvoiceable_isIncome: false,
    lot: { lot: '', expiration: '' },
    costCenter: { costCenterId: '', name: '' },
    account: { accountId: 0, accountNumber: '', name: '' },
    traceFrom: [],
  };

  if (itemAlreadyAdded) {
    dispatch(deleteItemFromSalesInvoiceDraft(item));
    return;
  }

  dispatch(addItemToSalesInvoiceDraft(item));
  dispatch(updateSalesInvoicesDraftItemsPrice(item));
});

export const addProductFromBarcodeToSalesInvoice = createAsyncThunk<void, string, AppThunkConfig>(
  'SALES_INVOICES/ADD_PRODUCT_FROM_BARCODE_TO_SALES_INVOICE',
  async (barcode, ThunkAPI) => {
    const { getState, dispatch } = ThunkAPI;
    const state = getState();

    // Get barcode settings
    const doesScaleBarcodeUse = getSettingsByName('barCode_useScaleCode')(state);
    const scaleBarcodePrefix = getSettingsByName<string>('barCode_scaleCodePrefix')(state);
    const scaleBarcodeProductFromChar = getSettingsByName<number>('barCode_product_fromChar')(
      state,
    );
    const scaleBarcodeProductLength = getSettingsByName<number>('barCode_product_len')(state);
    const scaleBarcodeQuantityFromChar = getSettingsByName<number>('barCode_quantity_fromChar')(
      state,
    );
    const scaleBarcodeQuantityLength = getSettingsByName<number>('barCode_quantity_len')(state);
    const scaleBarcodeQuantityDecimalLength = getSettingsByName<number>(
      'barCode_quantity_decimals',
    )(state);

    let productQtyFromScaleBarcode = 0;
    let productCodeFromScaleBarcode = null;
    const hasScaleBarcodePrefix = scaleBarcodePrefix && barcode.startsWith(scaleBarcodePrefix);
    if (doesScaleBarcodeUse && hasScaleBarcodePrefix) {
      const productFromChar = scaleBarcodeProductFromChar - 1;
      const quantityFromChar = scaleBarcodeQuantityFromChar - 1;

      productCodeFromScaleBarcode = barcode
        .substring(productFromChar, productFromChar + scaleBarcodeProductLength)
        .trim();

      productQtyFromScaleBarcode = parseFloat(
        barcode.substring(quantityFromChar, quantityFromChar + scaleBarcodeQuantityLength),
      );

      if (scaleBarcodeQuantityDecimalLength > 0) {
        productQtyFromScaleBarcode =
          productQtyFromScaleBarcode / Math.pow(10, scaleBarcodeQuantityDecimalLength); // 10^scaleBarcodeQuantityDecimalLength;
      }
    }

    // Add the product, trying to add it to an existing one if it is not a scale bar code
    let product = null;
    if (productCodeFromScaleBarcode) {
      product = getProductByBarcode(productCodeFromScaleBarcode)(state);
    } else if (barcode) {
      product = getProductByBarcode(barcode)(state);
    }

    if (!product) {
      dispatch(
        showAlert({
          type: 'error',
          title: intl.formatMessage({ id: 'invoices.errorToast.read' }),
          message: intl.formatMessage({ id: 'barcode.error.notFound' }, { barcode }),
        }),
      );
      return;
    }

    const draftInvoice = getSalesInvoiceDraft(getState());
    const item = draftInvoice.items.find((item) => item?.product?.productId === product.productId);

    const doesProductCodeFromScaleBarcodeWithQtyExist =
      productCodeFromScaleBarcode && productQtyFromScaleBarcode > 0;

    // If the product is already in the invoice, increase the quantity
    if (item) {
      let quantity = item.quantity;
      if (doesProductCodeFromScaleBarcodeWithQtyExist) {
        quantity += productQtyFromScaleBarcode;
      } else {
        quantity += 1;
      }

      dispatch(updateItemFromSalesInvoiceDraft({ ...item, quantity }));

      // TODO: This is a workaround to avoid the API call to update the price in every scan
      // The API is called when the user stops scanning for 800ms
      // This should be improved in the future
      const waitInterval = 800;
      await new Promise((resolve) =>
        debounce(() => {
          dispatch(
            updateSalesInvoicesDraftItemsPrice({
              ...item,
              quantity,
            }),
          );
          resolve('Success!');
        }, waitInterval),
      );
      return;
    }

    // If the product is not in the invoice, add it
    dispatch(
      addProductToSalesInvoiceWithoutCallingBackendForProductData({
        product,
        quantity: doesProductCodeFromScaleBarcodeWithQtyExist ? productQtyFromScaleBarcode : 1,
      }),
    );
  },
);

export const createSalesInvoice = createAsyncThunk<
  void,
  ISalesInvoice & IRedirectTo & IPrintSalesInvoiceAfterSave,
  AppThunkConfig
>(
  'SALES_INVOICES/CREATE',
  async ({ redirectToSuccessPath, printAfterSave, ...salesInvoice }, ThunkAPI) => {
    const { extra, dispatch } = ThunkAPI;
    const { api } = extra;
    dispatch(startRequest('create-sales-invoices'));
    try {
      let { data } = await api.salesInvoices.createSalesInvoiceFromAPI(salesInvoice);

      // 33 is electronic invoice, and 61 is electronic note of credit
      const shouldInvoiceBeSentToSII = [33, 61].includes(data.docType?.docTypeId ?? 0);

      if (shouldInvoiceBeSentToSII) {
        data = await api.salesInvoices.sendSalesInvoiceToSII(data);
      }

      dispatch(
        createReceiptFromSalesInvoice({
          ...data,
          redirectToSuccessPath,
          printAfterSave,
        }),
      );

      dispatch(setSalesInvoice(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesInvoices',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('create-sales-invoices'));
    }
  },
);

// PDF
interface IPrintDefaultSalesInvoicePDF {
  salesInvoice: ISalesInvoice;
  printAfterSave: boolean;
}
export const printDefaultSalesInvoicePDF = createAsyncThunk<
  void,
  IPrintDefaultSalesInvoicePDF,
  AppThunkConfig
>('SALES_ORDER/POS_PRINT_DEFAULT_PDF', async ({ salesInvoice, printAfterSave }, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;
  const state = getState();

  // If there is not template defined in setting, we use the default one generated in the front
  const companyName = getSettingsByName<string>('name')(state);
  const companyAddress = getSettingsByName<string>('address')(state);
  const companyVATId = getSettingsByName<string>('VATId')(state);
  const DEFAULT_TEMPLATE: IPrintTemplateSettings = {
    source: 'front',
    template: `salesInvoice_${salesInvoice.docType?.docTypeId}_1`,
    options: {
      logo: '',
      header: btoa(
        `<table><tr><td>${companyName}</td></tr><tr><td>${companyAddress}</td></tr><tr><td>${companyVATId}</td></tr></table>`,
      ),
      numberOfCopies: 1,
    },
  };
  dispatch(
    createSalesInvoicePOSPDFInFrontEnd({
      salesInvoice,
      printAfterSave,
      printTemplateSettings: DEFAULT_TEMPLATE,
    }),
  );
});

interface IPrintSalesInvoicePDFParams {
  salesInvoice: ISalesInvoice;
  printAfterSave: boolean;
}
export const printSalesInvoicePDF = createAsyncThunk<
  void,
  IPrintSalesInvoicePDFParams,
  AppThunkConfig
>('SALES_INVOICES/PRINT_PDF', async ({ salesInvoice, printAfterSave }, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;
  const state = getState();

  const docTypeId = salesInvoice?.docType?.docTypeId ?? 0;
  const printTemplateSettings = getPrintTemplateSettings(`salesInvoice_${docTypeId}`)(state);

  if (!printTemplateSettings) {
    // If there is not template defined in setting, we use the default one generated in the front
    dispatch(printDefaultSalesInvoicePDF({ salesInvoice, printAfterSave }));
  } else if (typeof printTemplateSettings === 'string') {
    // If the template in settings is defined for the original Desktop EPR (as a string), we fetch the PDF from the back
    dispatch(
      fetchSalesInvoicePOSPDFFromBackEnd({
        salesInvoice,
        printAfterSave,
      }),
    );
  } else if (printTemplateSettings?.source === 'front') {
    // If the template in settings is defined as an object and the source is 'front', we render the PDF in the front
    dispatch(
      createSalesInvoicePOSPDFInFrontEnd({
        salesInvoice,
        printTemplateSettings,
        printAfterSave,
      }),
    );
  } else if (printTemplateSettings?.source === 'back') {
    // If the template in settings is defined as an object and the source is 'back', we fetch the PDF from the back
    dispatch(
      fetchSalesInvoicePOSPDFFromBackEnd({
        salesInvoice,
        printTemplateSettings,
        printAfterSave,
      }),
    );
  } else {
    dispatch(
      showAlert({
        title: intl.formatMessage({ id: 'printer.error.title' }),
        message: intl.formatMessage({ id: 'printer.error.template.source.message' }),
        type: 'error',
      }),
    );
  }
});

interface IfetchSalesInvoicePOSPDFFromBackEndParams {
  salesInvoice: ISalesInvoice;
  printTemplateSettings?: IPrintTemplateSettings;
  printAfterSave: boolean;
}
export const fetchSalesInvoicePOSPDFFromBackEnd = createAsyncThunk<
  void,
  IfetchSalesInvoicePOSPDFFromBackEndParams,
  AppThunkConfig
>(
  'SALES_INVOICES/FETCH_PDF_FROM_BACKEND',
  async ({ salesInvoice, printTemplateSettings, printAfterSave }, ThunkAPI) => {
    const { extra, dispatch } = ThunkAPI;
    const { api } = extra;
    // TODO: Implemente this when the API accepts the template name
    // const templateName = printTemplateSettings?.template;

    dispatch(startRequest('fetch-sales-invoices-pdf-from-backend'));
    try {
      const salesInvoiceId = salesInvoice.salesInvoiceId ?? '';
      const { data: pdfInvoice } = await api.salesInvoicesPos.fetchSalesInvoicePDFFromAPI(
        salesInvoiceId,
        printTemplateSettings?.options.numberOfCopies ?? 1,
      );
      const blob = new Blob([new Uint8Array(pdfInvoice)], {
        type: 'application/pdf',
      });
      const url = URL.createObjectURL(blob);

      if (printAfterSave) {
        // Print the PDF without a preview
        printPDF(url);
      } else {
        // Set the URL to render the PDF in the preview
        dispatch(setSalesInvoicePDFUrl(url));
      }
    } catch (error) {
      // TODO: Look into error handling
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesInvoices',
          action: 'readPDF' as IShowErrorParams['action'],
        }),
      );
    } finally {
      dispatch(endRequest('fetch-sales-invoices-pdf-from-backend'));
    }
  },
);

interface IcreateSalesInvoicePOSPDFInFrontEndParams {
  salesInvoice: ISalesInvoice;
  printTemplateSettings: IPrintTemplateSettings;
  printAfterSave: boolean;
}
export const createSalesInvoicePOSPDFInFrontEnd = createAsyncThunk<
  void,
  IcreateSalesInvoicePOSPDFInFrontEndParams,
  AppThunkConfig
>(
  'SALES_INVOICES/CREATE_PDF_IN_FRONTEND',
  async ({ salesInvoice, printTemplateSettings, printAfterSave }, ThunkAPI) => {
    const { extra, dispatch, getState } = ThunkAPI;
    const { api } = extra;
    const state = getState();

    const settingCompanyVATId = getSettingsByName<string>('VATId')(state);
    const VATRateFromSettings = getSettingsByName<number>('VATRate')(state);
    const { mainCurrencyDecimals } = getMainCurrency(state);
    const isAnElectronicDocument = getIsAnElectronicDocument(salesInvoice.docType?.docTypeId ?? 0)(
      state,
    );

    dispatch(startRequest('fetch-sales-invoices-pdf-from-frontend'));
    try {
      // Fetch 2d barcode PDF417 from API if the document is electronic
      let PDF417Base64 = null;
      if (isAnElectronicDocument) {
        const response = await api.dte.fetchDTEPDF417FromAPI(
          salesInvoice.docNumber ?? 0,
          salesInvoice.docType?.docTypeId ?? 0,
        );
        const blob = new Blob([response.data], { type: 'image/bmp' });
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        PDF417Base64 = await new Promise<string>((resolve) => {
          reader.onloadend = () => {
            resolve(reader.result?.toString() ?? '');
          };
        });
      }

      const { renderPDF } = await templateIndex[printTemplateSettings.template]();
      const url = await renderPDF({
        pageWidth: printTemplateSettings.options.pageWidth,
        pageHeight: printTemplateSettings.options.pageHeight,
        logo: printTemplateSettings.options.logo,
        header: atob(printTemplateSettings.options.header),
        data: salesInvoice,
        settingCompanyVATId,
        PDF417Base64: PDF417Base64 ?? '',
        mainCurrencyDecimals,
        VATRateFromSettings,
      });

      if (printAfterSave) {
        // Print the PDF without a preview
        printPDF(url);
      } else {
        // Set the URL to render the PDF in the preview
        dispatch(setSalesInvoicePDFUrl(url));
      }
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesInvoices',
          action: 'readPDF' as IShowErrorParams['action'],
        }),
      );
    } finally {
      dispatch(endRequest('fetch-sales-invoices-pdf-from-frontend'));
    }
  },
);

interface ISalesInvoicPDFEmailParams {
  toAddress: string;
  PDFBlob: Blob | undefined;
  invoice: ISalesInvoice;
  receipt: IReceipts;
  companyName: string;
}

export const sendSalesInvoicePDFbyEmail = createAsyncThunk<
  void,
  ISalesInvoicPDFEmailParams,
  AppThunkConfig
>(
  'SALES_INVOICES/SEND_BY_EMAIL',
  async ({ toAddress, PDFBlob, invoice, receipt, companyName }, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(setSalesInvoicePDFLoading(true));

    try {
      const reader = new FileReader();
      if (!PDFBlob) {
        throw new Error(intl.formatMessage({ id: 'invoices.errorToast.sendByEmail' }));
      }
      reader.readAsDataURL(PDFBlob);
      reader.onloadend = () => {
        if (reader.result) {
          const base64data = reader.result.toString().replace('data:', '').replace(/^.+,/, '');

          const invoiceNumber = `${invoice.docType?.name} ${invoice.docNumber}`;
          const fileName = `${invoiceNumber}.pdf`;

          const ticketType = [35, 39, 41];
          const ticketDocTypes = (docTypeId: number) => {
            return ticketType.includes(docTypeId) ? 'Boleta' : 'Factura';
          };
          const emailBody = EmailInvoicesTemplate({
            invoice,
            receipt,
            companyName,
            ticketDocTypes,
            intl,
          });

          const emailData: IEmail = {
            from: {
              address: 'info@laudus.cl',
              displayName: 'Laudus',
            },
            to: [
              {
                address: toAddress,
                displayName: toAddress,
              },
            ],
            CC: [],
            BCC: [],
            subject: `${invoiceNumber} de ${companyName}`,
            body: window.btoa(emailBody),
            attachments: [
              {
                fileName: fileName,
                fileContents: base64data,
              },
            ],
          };

          dispatch(createEmail(emailData));
        }
      };
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesInvoices',
          action: 'sendByEmail' as IShowErrorParams['action'],
        }),
      );
    } finally {
      dispatch(setSalesInvoicePDFLoading(false));
    }
  },
);

export const fetchSalesInvoiceListByPOS = createAsyncThunk<
  void,
  Partial<ISalesInvoicesFilters> | undefined,
  AppThunkConfig
>('SALES_INVOICES/FETCH_LIST', async (filters, ThunkAPI) => {
  const { dispatch, getState, extra } = ThunkAPI;
  const { api } = extra;

  try {
    dispatch(startRequest('fetch-sales-invoices-list-by-pos'));
    const pos = getPosCurrent(getState());

    const filtersForList = filters ?? {
      filterBy: [{ field: 'pos.posId', operator: '=', value: pos.posId }],
    };

    const { data } = await api.salesInvoices.fetchSalesInvoiceListFromAPI({
      ...filtersForList,
      options: { limit: 200 },
    });
    dispatch(setSalesInvoiceList(Array.isArray(data) ? data : []));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'salesInvoices',
        action: 'list',
      }),
    );
  } finally {
    dispatch(endRequest('fetch-sales-invoices-list-by-pos'));
  }
});

export const addItemToCreditNote = createAsyncThunk<void, ISalesInvoicesItem, AppThunkConfig>(
  'SALES_INVOICES/ADD_ITEM_TO_CREDIT_NOTE',
  async (item, ThunkAPI) => {
    const { getState, dispatch } = ThunkAPI;
    const salesInvoiceDraft = getSalesInvoiceDraft(getState());

    const itemAlreadyAdded = salesInvoiceDraft.items.some((i) => i.itemId === item.itemId);
    if (itemAlreadyAdded) {
      dispatch(deleteItemFromSalesInvoiceDraft(item));
      return;
    }
    const itemToCreditNote = {
      ...item,
      quantity: -Math.abs(item.quantity),
    } as ISalesInvoicesItem;

    dispatch(addItemToSalesInvoiceDraft(itemToCreditNote));
  },
);

export const addAllItemsToCreditNote = createAsyncThunk<void, void, AppThunkConfig>(
  'SALES_INVOICES/ADD_ITEM_TO_CREDIT_NOTE',
  async (_, ThunkAPI) => {
    const { getState, dispatch } = ThunkAPI;
    const salesInvoiceItems = getSalesInvoicesItems(getState());

    dispatch(clearAllItemsFromCreditNote());
    salesInvoiceItems.forEach((item) => {
      const itemToCreditNote = {
        ...item,
        quantity: -Math.abs(item.quantity),
      } as ISalesInvoicesItem;

      dispatch(addItemToSalesInvoiceDraft(itemToCreditNote));
    });
  },
);

export const createCreditNote = createAsyncThunk<void, ISalesInvoice & IRedirectTo, AppThunkConfig>(
  'SALES_INVOICES/CREATE_CREDIT_NOTE',
  async ({ redirectToSuccessPath, ...salesInvoice }, ThunkAPI) => {
    const { extra, dispatch } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('create-credit-note'));
      const { data } = await api.salesInvoices.createSalesInvoiceFromAPI(salesInvoice);

      // 61 is electronic note of credit
      const shouldNoteOfCreditBeSentToSII = data.docType?.docTypeId === 61;

      if (shouldNoteOfCreditBeSentToSII) {
        await api.salesInvoices.sendSalesInvoiceToSII(data);
      }

      dispatch(setSalesInvoice(data));
      if (redirectToSuccessPath) {
        dispatch(navigateTo(redirectToSuccessPath));
      }
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesInvoices',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('create-credit-note'));
    }
  },
);

export const updateSalesInvoiceDraftItemPrices = createAsyncThunk<
  void,
  ISalesInvoicesItem | undefined,
  AppThunkConfig
>('SALES_INVOICES/UPDATE_ITEMS_UNIT_PRICE', async (item, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;
  const state = getState();

  const items = item ? [item] : getSalesInvoicesDraftComputedItems(state);

  const updated = items?.reduce((acc, item) => {
    const product = getProductWithPriceOverridesById(item.product?.productId ?? 0)(state);

    let updated = false;
    if (
      item.unitPrice !== (product?.unitPrice ?? 0) ||
      item.VATRate !== (product?.VATRate ?? 0) ||
      item.originalUnitPrice !== (product?.unitPrice ?? 0)
    ) {
      dispatch(
        updateItemFromSalesInvoiceDraft({
          ...item,
          unitPrice: product?.unitPrice ?? 0,
          VATRate: product?.VATRate ?? 0,
          originalUnitPrice: product?.unitPrice ?? 0,
          discountPercentage: item?.discountPercentage ?? 0,
        }),
      );
      updated = true;
    }

    return updated || acc;
  }, false);

  if (updated) {
    dispatch(
      showAlert({
        type: 'error',
        title: intl.formatMessage({ id: 'salesInvoices.priceUpdated' }),
      }),
    );
  }
});

/**
 * This action can be use to update all items or just one item
 * - All items price are updated when the customer is changed
 * - One item price is updated when the product is added to the invoice ticket
 */
export const updateSalesInvoicesDraftItemsPrice = createAsyncThunk<
  void,
  ISalesInvoicesItem | undefined,
  AppThunkConfig
>('SALES_INVOICES/UPDATE_ITEMS_UNIT_PRICE', async (salesInvoiceItem, ThunkAPI) => {
  const { extra, dispatch, getState } = ThunkAPI;
  const { api } = extra;
  const state = getState();

  // Check if the current.customer is the sames as the default customer for tickets
  // TODO: review this block of code in the future since the branch and customer price list are retrived from the API
  const branch = getBranch(state);
  const hasBranchPriceList = Boolean(branch?.priceList?.priceListId);

  const customer = getCustomer(state);
  const defaultCustomerIdForTickets = getSettingsByName<number>('defaultCustomerForTickets')(state);
  const isDefaultCustomerForTicketsSelected = customer?.customerId === defaultCustomerIdForTickets;
  const hasCustomerPriceList = Boolean(customer?.priceList?.priceListId);

  const salesInvoiceDraftItems = salesInvoiceItem
    ? [salesInvoiceItem] //This the item that has been added to the invoice ticket
    : getSalesInvoicesDraftItems(state); // already items added in the invoice ticket

  salesInvoiceDraftItems.forEach(async (item) => {
    if (item?.hasCustomUnitPrice) {
      return;
    }

    // View doc "ADD-PRODUCT-TO-SALES-INVOICE.md" for more information
    let priceListId = null;
    // Check if the product has volume discounts
    if (!item?.product?.hasVolumeDiscounts) {
      // Determine if the original price should be restored
      const shouldRestoreOriginalPrice =
        (isDefaultCustomerForTicketsSelected || !hasCustomerPriceList) && !hasBranchPriceList;

      if (shouldRestoreOriginalPrice) {
        dispatch(updateSalesInvoiceDraftItemPrices(item));
        return;
      }
      return;
    }

    // if the product has volume discounts, assign the priceListId based on the customer and branch
    if (hasCustomerPriceList) {
      priceListId = customer?.priceList?.priceListId;
    } else if (hasBranchPriceList) {
      priceListId = branch?.priceList?.priceListId;
    }

    // Preparing data to call the API that will return the price for the product
    try {
      const queryParams = {
        quantity: item?.quantity,
        customerId: customer?.customerId,
        date: dateToLocalISOString(new Date()),
        priceListId: priceListId ?? undefined,
      };
      const { data } = await api.products.fetchProductSalesPriceAPI(
        (item?.product?.productId ?? '') as string,
        queryParams,
      );

      dispatch(
        updateItemFromSalesInvoiceDraft({
          ...item,
          unitPrice: data.unitPrice,
          VATRate: data.VATRate,
          originalUnitPrice: data.originalUnitPrice,
          discountPercentage: data.discount,
        }),
      );
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: intl.formatMessage(
            {
              id: 'salesInvoices.updateSalesInvoicesDraftItemsPrice.error',
            },
            { productDescription: item?.product?.description },
          ),
          message: getMessageFromError({
            error,
            fallbackMessage: intl.formatMessage(
              {
                id: 'salesInvoices.updateSalesInvoicesDraftItemsPrice.error.message',
              },
              { productDescription: item?.product?.description },
            ),
          }),
        }),
      );
      //Remove item when the price is not updated because of an error
      dispatch(deleteItemFromSalesInvoiceDraft(item));
    }
  });
});

export const addRoundingProduct = createAsyncThunk<void, void, AppThunkConfig>(
  'SALES_INVOICES/ADD_ROUNDING_PRODUCT_TO_SALES_INVOICE',
  async (_, ThunkAPI) => {
    const { getState, dispatch } = ThunkAPI;
    const state = getState();

    try {
      const roundingProductId = Number(getSettingsByName('roundingProductId')(state));

      if (!roundingProductId) {
        throw new Error(
          intl.formatMessage({
            id: 'settings.roundingProduct.notFoundInSettings',
          }),
        );
      }

      const roundingProduct = getProductById(roundingProductId)(state);

      if (!roundingProduct) {
        throw new Error(
          intl.formatMessage({
            id: 'settings.roundingProduct.notFoundInProductList',
          }),
        );
      }

      // TODO: Check if the rounding product is not invoiceable
      const total = Math.round(getSalesInvoicesTotalFromItems(state));

      const lastDigitOfTotal = total % 10;
      if (lastDigitOfTotal === 0) {
        return;
      }

      let priceToAddToRound = 0;
      if (lastDigitOfTotal <= 5) {
        priceToAddToRound = -lastDigitOfTotal;
      } else if (lastDigitOfTotal >= 6) {
        priceToAddToRound = 10 - lastDigitOfTotal;
      }

      const item = {
        unitPrice: priceToAddToRound,
        VATRate: 0,
        VAT: 0,
        quantity: 1,
        product: { ...roundingProduct },
        itemDescription: '',
        originalUnitPrice: priceToAddToRound,
        currencyCode: 'CLP',
        parityToMainCurrency: 1,
        taxes: [],
        discountPercentage: 0,
        salesmanCommission: 0,
        dealerCommission: 0,
        noteOfCreditType: '',
        notInvoiceable: roundingProduct.notInvoiceable ?? false,
        notInvoiceable_isIncome: false,
        lot: { lot: '', expiration: '' },
        costCenter: { costCenterId: '', name: '' },
        account: { accountId: 0, accountNumber: '', name: '' },
        traceFrom: [],
      };

      dispatch(addItemToSalesInvoiceDraft(item));
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'settings.rounding.error',
          }),
          message: getMessageFromError({
            error,
          }),
        }),
      );
    }
  },
);

export const removeRoundingProduct = createAsyncThunk<void, void, AppThunkConfig>(
  'SALES_INVOICES/REMOVE_ROUNDING_PRODUCT_TO_SALES_INVOICE',
  async (_, ThunkAPI) => {
    const { getState, dispatch } = ThunkAPI;
    const state = getState();
    const roundingProductId = Number(getSettingsByName('roundingProductId')(state));
    const roundingProduct = getProductById(roundingProductId)(state);

    if (!roundingProduct) {
      return;
    }

    const item = {
      product: { ...(roundingProduct as ISalesInvoicesItem['product']) },
    } as ISalesInvoicesItem;

    dispatch(deleteItemFromSalesInvoiceDraft(item));
  },
);

export const fetchHistoryAdvancedSearch = createAsyncThunk<ISalesInvoiceList, void, AppThunkConfig>(
  'SALES_INVOICES/FETCH_HISTORY_ADVANCED_SEARCH',
  async (_, ThunkAPI) => {
    const { dispatch, getState, extra } = ThunkAPI;
    const { api } = extra;

    const pos = getPosCurrent(getState());

    try {
      const filtersForList = {
        filterBy: [{ field: 'pos.posId', operator: '=', value: pos.posId }],
      };

      const { data } = await api.salesInvoices.fetchSalesInvoiceListFromAPI({
        ...filtersForList,
      });

      return Array.isArray(data) ? data : [];
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'customerInvoices',
          action: 'list',
        }),
      );
      return [];
    }
  },
);

export const fetchSalesInvoiceTraces = createAsyncThunk<void, string, AppThunkConfig>(
  `SALES_INVOICES/FETCH_TRACES`,
  async (salesInvoiceId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('fetch-sales-invoices-traces'));
      const { data } = await api.salesInvoices.fetchSalesInvoiceTracesFromAPI(salesInvoiceId);

      const traces = Array.isArray(data) ? data : [];
      dispatch(setSalesInvoiceTraces(traces));
    } catch (error) {
      dispatch(setSalesInvoiceTraces([]));
    } finally {
      dispatch(endRequest('fetch-sales-invoices-traces'));
    }
  },
);

export const setDefaultSalesInvoiceDocType = createAsyncThunk<void, number[], AppThunkConfig>(
  'SALES_INVOICES/SET_DEFAULT_DOC_TYPE',
  async (allowedSalesInvoicesDocTypes, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;
    const state = getState();

    const index = 0;
    const docTytesInvoices = getDocTypesInvoices(allowedSalesInvoicesDocTypes)(state);
    dispatch(
      setSalesInvoiceDraftValues({
        docType: {
          docTypeId: docTytesInvoices?.[index]?.docTypeId,
          name: docTytesInvoices?.[index]?.name,
        },
      }),
    );
  },
);
