import { createReducer } from '@reduxjs/toolkit';

import {
  generateBinaryStringFromArrayOfFilledPositions,
  getNegativeUniqueNumericId,
} from '@laudus/shared-utils';
import {
  IRecurringSalesInvoice,
  IRecurringSalesInvoiceDayString,
  IRecurringSalesInvoiceListItem,
  IRecurringSalesInvoiceRepetitionPatternType,
  IRecurringSalesInvoiceRepetitionToType,
  IRecurringSalesInvoicesItem,
  RepeatString,
} from '@laudus/types';

import {
  clearRecurringSalesInvoice,
  clearRecurringSalesInvoiceDraft,
  duplicateRecurringSalesInvoice,
  removeRecurringSalesInvoiceFromList,
  setRecurringSalesInvoice,
  setRecurringSalesInvoiceDraft,
  setRecurringSalesInvoiceDraftRepetitionMonthlyPattern,
  setRecurringSalesInvoiceDraftRepetitionToAlways,
  setRecurringSalesInvoiceDraftRepetitionToDate,
  setRecurringSalesInvoiceDraftRepetitionToTimes,
  setRecurringSalesInvoiceDraftRepetitionWeeklyPattern,
  setRecurringSalesInvoiceDraftRepetitionYearlyPattern,
  setRecurringSalesInvoiceDraftValues,
  setRecurringSalesInvoiceList,
  updateRecurringSalesInvoiceList,
} from './actions';

export interface IRecurringSalesInvoicesState {
  current: IRecurringSalesInvoice;
  draft: IRecurringSalesInvoice;
  list: IRecurringSalesInvoiceListItem[];
}

export const RECURRING_SALES_INVOICE_EMPTY: IRecurringSalesInvoice = {
  items: [],
  description: '',
  category: null,
  customer: null,
  branch: null,
  warehouse: null,
  salesman: null,
  dealer: null,
  term: null,
  contact: null,
  discontinued: false,
  repetition: {
    pattern: {
      type: IRecurringSalesInvoiceRepetitionPatternType.WEEKLY,
      interval: 0,
      weekDays: '0000000',
      monthDays: null,
      yearMonth: null,
      yearDayOfMonth: null,
    },
    dateFrom: null,
    to: {
      type: IRecurringSalesInvoiceRepetitionToType.ALWAYS,
    },
  },
  notes: null,
};

export const initialRecurringSalesInvoicesState: IRecurringSalesInvoicesState = {
  current: RECURRING_SALES_INVOICE_EMPTY,
  draft: RECURRING_SALES_INVOICE_EMPTY,
  list: [],
};

export const recurringSalesInvoicesReducer = createReducer(
  initialRecurringSalesInvoicesState,
  (builder) => {
    builder
      .addCase(clearRecurringSalesInvoice, (state) => {
        return { ...state, current: RECURRING_SALES_INVOICE_EMPTY };
      })
      .addCase(clearRecurringSalesInvoiceDraft, (state) => {
        return { ...state, draft: RECURRING_SALES_INVOICE_EMPTY };
      })
      .addCase(setRecurringSalesInvoice, (state, action) => {
        return { ...state, current: action.payload };
      })
      .addCase(setRecurringSalesInvoiceDraft, (state, action) => {
        return { ...state, draft: action.payload };
      })
      .addCase(setRecurringSalesInvoiceDraftValues, (state, action) => {
        return { ...state, draft: { ...state.draft, ...action.payload } };
      })
      .addCase(setRecurringSalesInvoiceDraftRepetitionWeeklyPattern, (state, action) => {
        return {
          ...state,
          draft: {
            ...state.draft,
            repetition: {
              ...state.draft.repetition,
              pattern: {
                interval: state.draft.repetition.pattern.interval,
                type: IRecurringSalesInvoiceRepetitionPatternType.WEEKLY,
                weekDays: generateBinaryStringFromArrayOfFilledPositions({
                  stringSize: 7,
                  filledPositions: action.payload,
                }) as RepeatString<IRecurringSalesInvoiceDayString, 7>,
                monthDays: null,
                yearMonth: null,
                yearDayOfMonth: null,
              },
            },
          },
        };
      })
      .addCase(setRecurringSalesInvoiceDraftRepetitionMonthlyPattern, (state, action) => {
        return {
          ...state,
          draft: {
            ...state.draft,
            repetition: {
              ...state.draft.repetition,
              pattern: {
                interval: state.draft.repetition.pattern.interval,
                type: IRecurringSalesInvoiceRepetitionPatternType.MONTHLY,
                monthDays: generateBinaryStringFromArrayOfFilledPositions({
                  stringSize: 31,
                  filledPositions: action.payload,
                }),
                weekDays: null,
                yearMonth: null,
                yearDayOfMonth: null,
              },
            },
          },
        };
      })
      .addCase(setRecurringSalesInvoiceDraftRepetitionYearlyPattern, (state, action) => {
        return {
          ...state,
          draft: {
            ...state.draft,
            repetition: {
              ...state.draft.repetition,
              pattern: {
                interval: state.draft.repetition.pattern.interval,
                type: IRecurringSalesInvoiceRepetitionPatternType.YEARLY,
                yearMonth: action.payload.yearMonth,
                yearDayOfMonth: action.payload.yearDayOfMonth,
                weekDays: null,
                monthDays: null,
              },
            },
          },
        };
      })
      .addCase(setRecurringSalesInvoiceDraftRepetitionToAlways, (state) => {
        return {
          ...state,
          draft: {
            ...state.draft,
            repetition: {
              ...state.draft.repetition,
              to: {
                type: IRecurringSalesInvoiceRepetitionToType.ALWAYS,
                times: null,
                dateTo: null,
              },
            },
          },
        };
      })
      .addCase(setRecurringSalesInvoiceDraftRepetitionToDate, (state, action) => {
        return {
          ...state,
          draft: {
            ...state.draft,
            repetition: {
              ...state.draft.repetition,
              to: {
                type: IRecurringSalesInvoiceRepetitionToType.DATE,
                times: null,
                dateTo: action.payload,
              },
            },
          },
        };
      })
      .addCase(setRecurringSalesInvoiceDraftRepetitionToTimes, (state, action) => {
        return {
          ...state,
          draft: {
            ...state.draft,
            repetition: {
              ...state.draft.repetition,
              to: {
                type: IRecurringSalesInvoiceRepetitionToType.TIMES,
                times: action.payload,
                dateTo: null,
              },
            },
          },
        };
      })
      .addCase(setRecurringSalesInvoiceList, (state, action) => {
        return { ...state, list: action.payload };
      })
      .addCase(updateRecurringSalesInvoiceList, (state, action) => {
        const { recurringSalesInvoiceId, description, customer } = action.payload;

        if (!state.list.some((d) => d.recurringSalesInvoiceId === recurringSalesInvoiceId)) {
          return state;
        }

        return {
          ...state,
          list: [
            ...state.list.filter(
              (p) => p.recurringSalesInvoiceId !== action.payload.recurringSalesInvoiceId,
            ),
            {
              recurringSalesInvoiceId: recurringSalesInvoiceId ?? 0,
              description,
              customer_name: customer?.name ?? '',
            },
          ],
        };
      })
      .addCase(removeRecurringSalesInvoiceFromList, (state, action) => {
        return {
          ...state,
          list: state.list.filter((d) => d.recurringSalesInvoiceId !== action.payload),
        };
      })
      .addCase(duplicateRecurringSalesInvoice, (state, action) => {
        const { recurringSalesInvoiceId, items, ...duplicatedRecurringSalesInvoice } =
          action.payload;

        const newItems: IRecurringSalesInvoicesItem[] = items.map((item) => ({
          ...item,
          itemId: getNegativeUniqueNumericId(),
        }));

        const newRecurringSalesInvoice: IRecurringSalesInvoice = {
          ...duplicatedRecurringSalesInvoice,
          items: newItems,
        };

        return { ...state, draft: newRecurringSalesInvoice };
      })
      .addDefaultCase((state) => state);
  },
);
