import { AxiosResponse } from 'axios';

import { IStage } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchStagesListFromAPI = (): Promise<AxiosResponse<IStage[]>> =>
  axios.post(
    `${VITE_API_URL}/production/stages/list`,
    {
      fields: ['productionStageId', 'name', 'description'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchStageFromAPI = (productionStageId: number): Promise<AxiosResponse<IStage>> => {
  return axios.get(`${VITE_API_URL}/production/stages/${productionStageId}`, {
    headers: {
      Accept: 'application/json',
    },
  });
};

export const createStageFromAPI = (stage: IStage): Promise<AxiosResponse<IStage>> =>
  axios.post(`${VITE_API_URL}/production/stages`, stage);

export const updateStageFromAPI = (stage: IStage): Promise<AxiosResponse<IStage>> =>
  axios.put(`${VITE_API_URL}/production/stages/${stage.productionStageId}`, stage);

export const deleteStageFromAPI = (productionStageId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/production/stages/${productionStageId}`);
