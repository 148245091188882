import { createReducer } from '@reduxjs/toolkit';

import { ICcaf } from '@laudus/types';

import {
  clearCcaf,
  clearCcafDraft,
  removeCcafFromList,
  setCcaf,
  setCcafDraft,
  setCcafDraftValues,
  setCcafsList,
  updateCcafsList,
} from '.';

export const CCAFS_TAB_ID = 'ccafs';

export interface ICcafsState {
  current: ICcaf;
  draft: ICcaf;
  list: ICcaf[];
}

export const CCAF_EMPTY: ICcaf = {
  CCAFId: '',
  name: '',
  previredId: '',
  electronicBookId: '',
  notes: '',
};

export const initialCcafsState: ICcafsState = {
  current: CCAF_EMPTY,
  draft: CCAF_EMPTY,
  list: [],
};

export const ccafsReducer = createReducer(initialCcafsState, (builder) => {
  builder
    .addCase(clearCcaf, (state) => {
      return { ...state, current: CCAF_EMPTY };
    })
    .addCase(clearCcafDraft, (state) => {
      return { ...state, draft: CCAF_EMPTY };
    })
    .addCase(setCcaf, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setCcafDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setCcafDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setCcafsList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateCcafsList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.CCAFId !== action.payload.CCAFId), action.payload],
      };
    })
    .addCase(removeCcafFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.CCAFId !== action.payload),
      };
    })
    .addDefaultCase((state) => state);
});
