import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import {
  IReportConfig,
  IReportFiltersItems,
  IReportPrimitiveFiltersItems,
  IReportResult,
} from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequestInTab, startRequestInTab } from '../global/actions';

import { getReportConfig, getSelectedReportPrimitiveFilledFilters } from './selectors';

export const REPORTS_TAB_ID = 'reports';

// Simple actions
export const clearReportFilters = createAction('REPORTS/CLEAR_FILTERS');
export const setReportFilters = createAction<IReportFiltersItems>('REPORTS/SET_FILTERS');
export const setReportFiltersValues = createAction<Partial<IReportFiltersItems>>(
  'REPORTS/SET_FILTERS_VALUES',
);
export const setReportConfig = createAction<IReportConfig>('REPORTS/SET_CONFIG');
export const setReportResult = createAction<IReportResult>('REPORTS/SET_RESULT');
export const resetReportResult = createAction('REPORTS/RESET_RESULT');
export const setReportError = createAction<unknown>('REPORTS/SET_ERROR');

// Complex actions
export interface IFetchReportByNameParams {
  reportConfig: IReportConfig;
  reportFilters: IReportPrimitiveFiltersItems;
}

export const fetchReportResult = createAsyncThunk<void, IFetchReportByNameParams, AppThunkConfig>(
  'REPORTS/FETCH',
  async ({ reportConfig, reportFilters }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequestInTab(REPORTS_TAB_ID));

      let reportResult: IReportResult | null = null;

      if (reportConfig.type === 'pdf') {
        const pdfURL = await api.reports.fetchReportPDFUrlFromAPI({ reportConfig, reportFilters });

        reportResult = pdfURL;
      }

      if (reportResult) {
        dispatch(setReportResult(reportResult));
      } else {
        dispatch(setReportError(new Error('No report result found')));
      }
    } catch (error) {
      dispatch(setReportError(error));
      dispatch(
        showErrorAlert({
          error,
          prefix: 'reports',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequestInTab(REPORTS_TAB_ID));
    }
  },
);

export const fetchResultsOfSelectedReport = createAsyncThunk<void, void, AppThunkConfig>(
  'REPORTS/FETCH_SELECTED',
  async (_, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;
    const state = getState();

    const reportConfig = getReportConfig(state);
    const reportPrimitiveFilters = getSelectedReportPrimitiveFilledFilters(state);

    if (reportConfig) {
      dispatch(
        fetchReportResult({
          reportConfig,
          reportFilters: reportPrimitiveFilters,
        }),
      );
    }
  },
);

interface SelectReportProps {
  reportConfig: IReportConfig;
  fetchResults?: boolean;
}

export const selectReport = createAsyncThunk<void, SelectReportProps, AppThunkConfig>(
  'REPORTS/SELECT',
  async ({ reportConfig, fetchResults }, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;
    const current = getReportConfig(getState());

    const isSameConfig = current?.name === reportConfig.name;
    if (!isSameConfig) {
      // Reset old data from previously selected report config
      dispatch(setReportError(null));

      // Select new report config
      dispatch(setReportConfig(reportConfig));
    }

    if (fetchResults) {
      dispatch(fetchResultsOfSelectedReport());
    }
  },
);
