import { createSelector } from '@reduxjs/toolkit';

import {
  calculateInvoiceTotals,
  DOC_TYPES_ID,
  FORMATTED_DOCTYPESLIST_INVOICES,
  translateDTEStatus,
  updateSalesInvoiceItemsPricing,
  updateSalesInvoiceItemsTaxes,
} from '@laudus/sales-utils';
import { currency, dateToLocalISOString } from '@laudus/shared-utils';
import {
  IInvoiceTax,
  ISalesInvoice,
  ISalesInvoicesItem,
  TRACE_FROM_STEP_ORIGIN,
} from '@laudus/types';

import { AppState } from '../../store';
import { getBranch, getBranchesList } from '../branches';
import { getCurrencyList, getMainCurrency } from '../currencies';
import { getCustomer } from '../customers';
import { getDocTypeFromDocTypeId, getDocTypeList } from '../docTypes';
import { getPosCurrent, getPosList } from '../pos';
import { getProductsList } from '../products';
import { getReceiptDraft, ReceiptTypesCodes } from '../receipts';
import { getSalesman, getSalesmenList } from '../salesmen';
import { getProductSalesTaxesList, getSalesTaxesList } from '../salesTaxes';
import { getSettingsByName } from '../settings';
import { getTerm } from '../terms';

import { ISalesInvoicesState } from './reducer';

export const getSalesInvoicesSlice = (state: AppState): ISalesInvoicesState => state.salesInvoices;

export const getSalesInvoiceList = createSelector([getSalesInvoicesSlice], (state) => state.list);

export const getSalesInvoice = createSelector(
  [getSalesInvoicesSlice, (appState) => getMainCurrency(appState)],
  (state, { mainCurrencyDecimals }) => {
    const invoiceWithPricingUpdated = updateSalesInvoiceItemsPricing({
      salesInvoice: state.current,
      mainCurrencyDecimals,
    });

    const DTEWithStatusTranslated = translateDTEStatus({
      salesDocument: invoiceWithPricingUpdated,
    });

    return { ...invoiceWithPricingUpdated, DTE: DTEWithStatusTranslated };
  },
);

export function getTotalsFromSalesInvoice(invoice: ISalesInvoice) {
  return createSelector(
    [
      (appState) => getMainCurrency(appState),
      (appState) => getCurrencyList(appState),
      (appState) => getSalesTaxesList(appState),
      getSettingsByName<boolean>('sales_invoices_otherDocumentsIncludeTaxes'),
    ],
    (
      { mainCurrencyCode, mainCurrencyDecimals },
      currencies,
      salesTaxesList,
      salesInvoicesOtherDocumentsIncludeTaxes,
    ) => {
      // Value defined in calculateInvoiceTotals as the default main rate. Needed here to be able to pass salesInvoicesOtherDocumentsIncludeTaxes
      const defaultMainRate = 19;

      return calculateInvoiceTotals(
        invoice,
        currencies,
        salesTaxesList,
        mainCurrencyCode,
        mainCurrencyDecimals,
        defaultMainRate,
        salesInvoicesOtherDocumentsIncludeTaxes,
      );
    },
  );
}

export const getSalesInvoiceDraft = createSelector(
  [getSalesInvoicesSlice, (appState) => getMainCurrency(appState), (appState) => appState],
  (state, { mainCurrencyDecimals }, appState) => {
    const draft = state.draft;

    // Get the doc type object from the draft
    const draftDocType = getDocTypeFromDocTypeId(draft.docType?.docTypeId)(appState);

    // Items pricing updated (unit price, net and VAT)
    const draftWithPricingUpdated = updateSalesInvoiceItemsPricing({
      salesInvoice: draft,
      mainCurrencyDecimals,
      shouldQuantityAlwaysBeNegative: draftDocType?.behavior === 'S',
    });

    const DTEWithStatusTranslated = translateDTEStatus({
      salesDocument: draftWithPricingUpdated,
    });

    const itemsWithTaxes = getSalesInvoiceItemsWithTaxes(draftWithPricingUpdated.items)(appState);
    const recalculatedDraft = { ...draftWithPricingUpdated, items: itemsWithTaxes };

    const { totals, totalsOriginalCurrency } =
      getTotalsFromSalesInvoice(recalculatedDraft)(appState);

    return {
      ...recalculatedDraft,
      DTE: DTEWithStatusTranslated,
      totals,
      totalsOriginalCurrency,
    };
  },
);

export const getSalesInvoicesReceiptList = createSelector(
  [getSalesInvoicesSlice],
  (state) => state.receiptList,
);

export const getSalesInvoicesPDFUrl = createSelector(
  [getSalesInvoicesSlice],
  (state) => state.pdfURL,
);

export const getShouldSalesInvoiceBeSentToSII = createSelector(
  [getSalesInvoice],
  (salesInvoice) => {
    const docTypeUsedInSalesInvoice = FORMATTED_DOCTYPESLIST_INVOICES.find(
      (formattedDocType: any) => formattedDocType.docTypeId === salesInvoice.docType?.docTypeId,
    );
    const isSalesInvoiceElectronicDocument = docTypeUsedInSalesInvoice?.electronic === true;
    const wasSalesInvoiceSentToSII = Boolean(salesInvoice.DTE?.trackId);

    return isSalesInvoiceElectronicDocument && !wasSalesInvoiceSentToSII;
  },
);

export const getNumberOfCopiesToPrintSalesInvoice = createSelector(
  [getSalesInvoice, (appState) => getDocTypeList(appState)],
  (salesInvoice, docTypes) => {
    const isDocTypeIdInFamily = (docTypeId: number | null | undefined, subFamily: string) => {
      return !!docTypes.find(
        (docType) => docType.docTypeId === docTypeId && docType.subFamily === subFamily,
      );
    };

    const numberOfCopies =
      isDocTypeIdInFamily(salesInvoice.docType?.docTypeId, 'T') ||
      isDocTypeIdInFamily(salesInvoice.docType?.docTypeId, 'C') ||
      isDocTypeIdInFamily(salesInvoice.docType?.docTypeId, 'D')
        ? 1
        : 2;

    return numberOfCopies;
  },
);

// Sum here the taxes inside each item
const itemUnitPriceWithTaxes = (item: ISalesInvoicesItem, mainCurrencyDecimals: number) => {
  const net = item.unitPrice * (1 + (item.VATRate ?? 0) / 100);
  const taxesAmount = item.taxes.reduce((acc, tax) => acc + (tax.amount ?? 0), 0);
  const taxesAmountPerUnit = +taxesAmount.toFixed(mainCurrencyDecimals) / item.quantity;
  const priceWithTaxes = +(net + taxesAmountPerUnit).toFixed(mainCurrencyDecimals);
  return priceWithTaxes;
};

const itemsSubtotal = (items: ISalesInvoicesItem[], mainCurrencyDecimals: number) =>
  items.reduce(
    (acc, item) => acc + item.quantity * itemUnitPriceWithTaxes(item, mainCurrencyDecimals),
    0,
  );

export const itemsTotal = (items: ISalesInvoicesItem[], mainCurrencyDecimals: number) =>
  items.reduce(
    (acc, item) =>
      acc +
      +(
        item.quantity *
        itemUnitPriceWithTaxes(item, mainCurrencyDecimals) *
        (1 - item.discountPercentage / 100)
      ).toFixed(mainCurrencyDecimals),
    0,
  );

const itemsNet = (items: ISalesInvoicesItem[]) =>
  items
    .filter((i) => i.notInvoiceable === false)
    .reduce(
      (acc, item) => acc + item.quantity * item.unitPrice * (1 - item.discountPercentage / 100),
      0,
    );

const itemsNotInvoiceableNet = (items: ISalesInvoicesItem[]) =>
  items
    .filter((i) => i.notInvoiceable === true)
    .reduce(
      (acc, item) => acc + item.quantity * item.unitPrice * (1 - item.discountPercentage / 100),
      0,
    );

const computedItems = (items: ISalesInvoicesItem[], mainCurrencyDecimals: number) =>
  items.map((item) => {
    return {
      ...item,
      unitPriceWithTaxes: itemUnitPriceWithTaxes(item, mainCurrencyDecimals),
      subtotal:
        item.quantity *
        itemUnitPriceWithTaxes(item, mainCurrencyDecimals) *
        (1 - item.discountPercentage / 100),
    };
  });

export const getSalesInvoicesList = createSelector([getSalesInvoicesSlice], (state) => state.list);

export const getSalesInvoiceWithoutCalculations = createSelector(
  [getSalesInvoicesSlice],
  (state) => state.current,
);

export const getSalesInvoiceDraftWithoutCalculations = createSelector(
  [getSalesInvoicesSlice],
  (state) => state.draft,
);

export const getSalesInvoicesDraftItems = createSelector(
  [getSalesInvoiceDraft],
  (state) => state.items,
);

export const getSalesInvoicesDraftComputedItems = createSelector(
  [getSalesInvoicesDraftItems, getMainCurrency, (state) => state],
  (items, { mainCurrencyDecimals }, state) =>
    computedItems(getSalesInvoiceItemsWithTaxes(items)(state), mainCurrencyDecimals),
);

export const getSalesInvoicesItems = createSelector([getSalesInvoice], (state) => state.items);

export const getSalesInvoicesComputedItems = createSelector(
  [getSalesInvoicesItems, getMainCurrency, (state) => state],
  (items, { mainCurrencyDecimals }, state) =>
    computedItems(getSalesInvoiceItemsWithTaxes(items)(state), mainCurrencyDecimals),
);

// Saved sales invoices
export const getSalesInvoicesDraftList = createSelector(
  [getSalesInvoicesSlice, getMainCurrency, (state) => state],
  (invoicesSlice, { mainCurrencyDecimals }, state) =>
    invoicesSlice.draftList?.map((savedDraft) => ({
      ...savedDraft,
      itemsCount: savedDraft.draft.items.length,
      itemsTotal: itemsTotal(
        getSalesInvoiceItemsWithTaxes(savedDraft.draft.items)(state),
        mainCurrencyDecimals,
      ),
    })),
);
export const getSalesInvoicesSubTotalFromItems = createSelector(
  [getSalesInvoicesDraftItems, getMainCurrency, (state) => state],
  (items, { mainCurrencyDecimals }, state) =>
    itemsSubtotal(getSalesInvoiceItemsWithTaxes(items)(state), mainCurrencyDecimals),
);

export const getSalesInvoicesNetFromItems = createSelector([getSalesInvoicesDraftItems], (items) =>
  itemsNet(items),
);

export const getSalesInvoicesNetFromNotInvoiceablesItems = createSelector(
  [getSalesInvoicesDraftItems],
  (items) => itemsNotInvoiceableNet(items),
);

export const getGeneralDiscountValue = createSelector(
  [getSalesInvoicesDraftItems, getMainCurrency],
  (items, { mainCurrencyDecimals }) =>
    items.reduce(
      (acc, item) =>
        acc +
        item.quantity *
          itemUnitPriceWithTaxes(item, mainCurrencyDecimals) *
          (item.discountPercentage / 100),
      0,
    ),
);

export const getCreditNotesTaxValue = createSelector(
  [getSalesInvoicesItems, getMainCurrency],
  (items, { mainCurrencyDecimals }) =>
    items.reduce(
      (acc, item) => acc + item.quantity * itemUnitPriceWithTaxes(item, mainCurrencyDecimals),
      0,
    ),
);

export const getSalesInvoicesTaxValue = createSelector([getSalesInvoicesDraftItems], (items) =>
  items.reduce(
    (acc, item) => acc + (item.quantity * item.unitPrice * (item?.VATRate ?? 0)) / 100,
    0,
  ),
);

export const getSalesInvoicesTotalFromItems = createSelector(
  [getSalesInvoicesDraftItems, getMainCurrency, (state) => state],
  (items, { mainCurrencyDecimals }, state) =>
    itemsTotal(getSalesInvoiceItemsWithTaxes(items)(state), mainCurrencyDecimals),
);

export const getSalesInvoiceTaxesFromItems = createSelector(
  [getSalesInvoicesDraftItems, (state) => state],
  (items, state) => {
    const itemsWithTaxes = getSalesInvoiceItemsWithTaxes(items)(state);

    const invoiceTaxes: IInvoiceTax[] = [];

    for (const item of itemsWithTaxes) {
      const itemTaxes = item.taxes;

      for (const itemTax of itemTaxes) {
        const invoiceTaxIndex = invoiceTaxes.findIndex((tax) => tax.taxId === itemTax.taxId);

        if (invoiceTaxIndex >= 0) {
          invoiceTaxes[invoiceTaxIndex] = {
            ...invoiceTaxes[invoiceTaxIndex],
            amount: (invoiceTaxes[invoiceTaxIndex].amount ?? 0) + (itemTax.amount ?? 0),
          };
        } else {
          invoiceTaxes.push({ ...itemTax });
        }
      }
    }

    return invoiceTaxes;
  },
);

export const getProductsInSalesInvoiceDraft = createSelector(
  [getSalesInvoiceDraft],
  (salesInvoice) => salesInvoice.items.map((item) => item.product),
);

export const getIsProductAddedToSalesInvoiceDraft = (productId: number) =>
  createSelector([getProductsInSalesInvoiceDraft], (products) =>
    products.some((product) => product?.productId === productId),
  );

export const getSalesInvoiceReadyToSend = createSelector(
  [
    getSalesInvoiceDraft,
    getCustomer,
    getPosCurrent,
    getSalesman,
    getBranch,
    getSettingsByName<string>('defaultWarehouseId'),
    getSalesInvoicesTotalFromItems,
    getSalesInvoiceTaxesFromItems,
    getSalesInvoicesTaxValue,
    getSalesInvoicesNetFromItems,
    getSalesInvoicesNetFromNotInvoiceablesItems,
    getMainCurrency,
    getTerm,
    getSettingsByName<string>('sales_use48WhenCreditCard'),
    getDocTypeList,
    (state) => state,
  ],
  (
    salesInvoiceDraft,
    customer,
    pos,
    salesman,
    branch,
    defaultWarehouseId,
    total,
    taxes,
    vat,
    net,
    notInvoiceableNet,
    { mainCurrencyDecimals },
    term,
    settingUse48onCreditCard,
    docTypesList,
    state,
  ) => {
    const totals = {
      net: +net.toFixed(mainCurrencyDecimals),
      vat: +vat.toFixed(mainCurrencyDecimals),
      taxes: taxes.map((tax) => ({
        ...tax,
        amount: +(tax.amount ?? 0).toFixed(mainCurrencyDecimals),
      })),
      vatWithheld: 0,
      notInvoiceable_income: 0,
      notInvoiceable_total: Math.abs(notInvoiceableNet),
      total: +total.toFixed(mainCurrencyDecimals),
      currencyCode: 'CLP',
      parity: 1,
    };

    let priceList = null;
    if (customer.priceList?.priceListId) {
      priceList = {
        priceListId: customer.priceList?.priceListId ?? null,
        name: customer.priceList?.name ?? null,
      };
    } else if (branch.priceList?.priceListId) {
      priceList = {
        priceListId: branch.priceList?.priceListId ?? null,
        name: branch.priceList?.name ?? null,
      };
    }

    const salesInvoice: ISalesInvoice = {
      ...salesInvoiceDraft,
      pos: {
        posId: pos.posId ?? null,
        name: pos.name,
      },
      salesman: {
        salesmanId: salesman.salesmanId ?? null,
        name: salesman.name ?? null,
      },
      branch: branch.branchId
        ? {
            branchId: branch.branchId ?? null,
            name: branch.name,
          }
        : undefined,
      warehouse:
        branch.warehouse?.warehouseId || defaultWarehouseId
          ? {
              warehouseId: branch.warehouse?.warehouseId ?? defaultWarehouseId,
              name: branch.warehouse?.name ?? null,
            }
          : null,
      term: term.termId
        ? {
            termId: term.termId,
            name: term.name,
          }
        : null,
      exportData: null,
      costCenter: branch.costCenter?.costCenterId
        ? {
            costCenterId: branch.costCenter?.costCenterId,
            name: branch.costCenter?.name ?? '',
          }
        : null,
      priceList: priceList,
      issuedDate: dateToLocalISOString(new Date()),
      dueDate: dateToLocalISOString(new Date()),

      totals,
      totalsOriginalCurrency: totals,
      customer: {
        customerId: customer.customerId ?? 0,
        name: customer.name || '',
        legalName: customer.legalName || '',
        VATId: customer.VATId || '',
        email: customer.email || '',
      },
      items: getSalesInvoiceItemsWithTaxes(salesInvoiceDraft.items)(state).map((item) => {
        return {
          ...item,
          lot: item.lot?.lot ? { lot: item.lot.lot, expiration: item.lot.expiration } : null,
          taxes: item.taxes.map((tax) => ({
            ...tax,
            amount: +(tax.amount ?? 0).toFixed(mainCurrencyDecimals),
          })),
          VAT: +(
            item.quantity *
            item.unitPrice *
            (1 - item.discountPercentage / 100) *
            ((item.VATRate ?? 0) / 100)
          ).toFixed(mainCurrencyDecimals),
          costCenter: branch.costCenter?.costCenterId
            ? {
                costCenterId: branch.costCenter?.costCenterId ?? '',
                name: branch.costCenter?.name ?? '',
              }
            : null,
        };
      }),
      ...(settingUse48onCreditCard &&
        (getReceiptDraft(state)?.receiptType?.code === ReceiptTypesCodes.CREDIT_CARD ||
          getReceiptDraft(state)?.receiptType?.code === ReceiptTypesCodes.DEBIT_CARD) &&
        (salesInvoiceDraft.docType?.docTypeId === DOC_TYPES_ID.EXEMPT_ELECTRONIC_TICKET ||
          salesInvoiceDraft.docType?.docTypeId === DOC_TYPES_ID.ELECTRONIC_TICKET) && {
          docType: docTypesList.find(
            (doc) => doc.docTypeId === DOC_TYPES_ID.ELECTRONIC_PAYMENT_VOUCHERS_TRANSBANK,
          ),
        }),
    };

    return salesInvoice;
  },
);

export const getSalesInvoicesListGroupedByDate = createSelector([getSalesInvoicesList], (list) => {
  return list.reduce((acc, currentValue) => {
    const date = new Date(currentValue.issuedDate);
    const dateWithoutTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    ).toString();

    acc[dateWithoutTime] = acc[dateWithoutTime] || [];
    acc[dateWithoutTime].push(currentValue);
    return acc;
  }, Object.create(null));
});

export const getCustomerFromSalesInvoice = createSelector(
  [getSalesInvoice],
  (state) => state.customer,
);

export const getCreditNoteItems = createSelector(
  [
    getSalesInvoicesComputedItems,
    getSalesInvoicesDraftItems,
    (appState) => getMainCurrency(appState),
  ],
  (itemsInCurrentSlice, itemsInDraftSlice, { mainCurrency, mainCurrencyDecimals }) =>
    itemsInCurrentSlice.map((itemInCurrentSlice) => ({
      ...itemInCurrentSlice,
      selected: itemsInDraftSlice.some(
        (itemInDraftSlice) => itemInDraftSlice.itemId === itemInCurrentSlice.itemId,
      ),
      discountText: `${itemInCurrentSlice.discountPercentage}%`,
      precioUD: `${itemInCurrentSlice.quantity} x ${currency({
        value: itemInCurrentSlice.unitPriceWithTaxes,
        mainCurrency,
        mainCurrencyDecimals,
      })}`,
      total: currency({
        value: itemInCurrentSlice.subtotal,
        mainCurrency,
        mainCurrencyDecimals,
      }),
    })),
);

export const getCreditNoteReadyToSend = createSelector(
  [
    getSalesInvoice,
    getSalesInvoiceDraft,
    getSalesInvoicesTotalFromItems,
    getCreditNotesTaxValue,
    getSalesInvoicesNetFromItems,
    getSettingsByName<boolean>('DTE_allow'),
    getSalesInvoicesNetFromNotInvoiceablesItems,
    getPosCurrent,
    getSalesman,
    getBranch,
    getMainCurrency,
  ],
  (
    salesInvoiceCurrent,
    salesInvoiceDraft,
    total,
    vat,
    net,
    isDTEAllow,
    notInvoiceableNet,
    pos,
    salesman,
    branch,
    { mainCurrencyDecimals },
  ) => {
    const totals = {
      net: +net.toFixed(mainCurrencyDecimals),
      vat: +(-Math.abs(vat)).toFixed(mainCurrencyDecimals),
      taxes: [],
      vatWithheld: 0,
      notInvoiceable_income: 0,
      notInvoiceable_total: Math.abs(notInvoiceableNet),
      total: +total.toFixed(mainCurrencyDecimals),
      currencyCode: 'CLP',
      parity: 1,
    };
    //Evaluate if the reference type is 1 (same totals) or 3 (different totals)
    const referenceType =
      Math.abs(totals.total) === Math.abs(salesInvoiceCurrent?.totals?.total ?? 0) &&
      Math.abs(totals.net) === Math.abs(salesInvoiceCurrent?.totals?.net ?? 0) &&
      Math.abs(totals.vat) === Math.abs(salesInvoiceCurrent?.totals?.VAT ?? 0)
        ? '1'
        : '3';

    const newCreditNoteReadyToSend: ISalesInvoice = {
      ...salesInvoiceDraft,
      docType: isDTEAllow
        ? {
            name: 'Nota de Crédito Electrónica',
            docTypeId: 61,
          }
        : {
            name: 'Nota de Crédito',
            docTypeId: 60,
          },
      references: [
        {
          date: salesInvoiceCurrent.issuedDate,
          docType: salesInvoiceCurrent.docType ?? undefined,
          document: salesInvoiceCurrent.docNumber ? `${salesInvoiceCurrent.docNumber}` : null,
        },
      ],
      items: salesInvoiceDraft.items.map((item) => ({
        ...item,
        itemId: undefined,
        traceFrom: null,
        net: item.quantity * item.unitPrice * (1 - item.discountPercentage / 100),
        VAT: +(-Math.abs(
          item.quantity *
            item.unitPrice *
            (1 - item.discountPercentage / 100) *
            ((item.VATRate ?? 0) / 100),
        )).toFixed(mainCurrencyDecimals),
      })),
      issuedDate: dateToLocalISOString(new Date()),
      dueDate: dateToLocalISOString(new Date()),
      warehouse: salesInvoiceCurrent.warehouse,
      // referenceType
      // Anula Documento de Referencia = 1
      // Corrige Textos = 2
      // Corrige Montos = 3
      referenceType: referenceType,
      totals,
      totalsOriginalCurrency: totals,
      customer: salesInvoiceCurrent.customer,
      pos: {
        posId: pos.posId ?? null,
        name: pos.name,
      },
      salesman: {
        salesmanId: salesman.salesmanId ?? null,
        name: salesman.name ?? null,
      },
      branch: branch.branchId
        ? {
            branchId: branch.branchId ?? null,
            name: branch.name,
          }
        : undefined,
    };

    return newCreditNoteReadyToSend;
  },
);

export const getSalesInvoicesHistoryAdvancedSearchResult = createSelector(
  [getSalesInvoicesSlice],
  (state) => state.historyAdvancedSearchResult,
);

export const getSalesInvoiceTraces = createSelector(
  [getSalesInvoicesSlice],
  (state) => state.traces,
);

export const getSalesInvoiceTracesByOrigin = createSelector([getSalesInvoicesSlice], (state) => {
  const traces = state.traces;

  const tracesFromQuotes = traces.filter(
    (trace) => trace.fromStep === TRACE_FROM_STEP_ORIGIN.QUOTES,
  );
  const tracesFromOrders = traces.filter(
    (trace) => trace.fromStep === TRACE_FROM_STEP_ORIGIN.ORDERS,
  );
  const tracesFromWaybills = traces.filter(
    (trace) => trace.fromStep === TRACE_FROM_STEP_ORIGIN.WAYBILLS,
  );

  return {
    tracesFromQuotes,
    tracesFromOrders,
    tracesFromWaybills,
  };
});

export function getSalesInvoiceItemsWithTaxes(
  salesInvoiceItems: ISalesInvoicesItem[],
): (state: AppState) => ISalesInvoicesItem[] {
  return createSelector(
    [getMainCurrency, getSalesTaxesList, getProductSalesTaxesList],
    ({ mainCurrencyDecimals }, allSalesTaxes, productSalesTaxes) => {
      return updateSalesInvoiceItemsTaxes({
        salesInvoiceItems,
        mainCurrencyDecimals,
        allSalesTaxes,
        productSalesTaxes,
      });
    },
  );
}

export const getFakeSalesInvoice = createSelector(
  [
    getProductsList,
    getSalesmenList,
    getBranchesList,
    getPosList,
    getMainCurrency,
    (state) => state,
  ],
  (products, salesMen, branchesList, posList, { mainCurrencyDecimals }, state) => {
    const branchIndex = Math.floor(Math.random() * branchesList.length);
    const branch = branchesList[branchIndex] ?? null;
    const posIndex = Math.floor(Math.random() * posList.length);
    const pos = posList[posIndex] ?? null;
    const salesManIndex = Math.floor(Math.random() * salesMen.length);
    const salesMan = salesMen[salesManIndex] ?? null;

    let totalVAT = 0;

    // Make a random number of items
    const maxItemQuantity = 7;
    const items: ISalesInvoicesItem[] = Array.from({ length: maxItemQuantity }, (_, i) => {
      const quantity = Math.floor(Math.random() * 12) + 1;
      const productIndex = Math.floor(Math.random() * products.length);
      const product = products[productIndex] ?? null;
      const VAT = (product?.unitPrice * (product?.VATRate ?? 0)) / 100;
      totalVAT += +(VAT * quantity).toFixed(mainCurrencyDecimals);
      return {
        itemId: i,
        itemOrder: i + 1,
        product,
        unitPrice: product?.unitPrice ?? 0,
        VATRate: product?.VATRate ?? 0,
        VAT,
        quantity,
        originalUnitPrice: product?.unitPrice ?? 0,
        itemDescription: '',
        currencyCode: 'CLP',
        parityToMainCurrency: 1,
        discountPercentage: 0,
        costCenter: null,
        lot: null,
        taxes: [],
        notInvoiceable: false,
      };
    });

    // Return a fake sales invoice
    return {
      salesInvoiceId: 'V0000XXXX',
      docType: {
        docTypeId: 39,
        name: 'Boleta Electrónica',
      },
      docNumber: 9000099,
      salesman: {
        salesmanId: salesMan?.salesmanId ?? 0,
        name: salesMan?.name ?? 'Juan Perez Gomez',
      },
      branch: {
        branchId: branch?.branchId ?? '0',
        name: branch?.name ?? 'Sucursal Test',
      },
      pos: {
        POSId: pos?.posId ?? 0,
        name: pos?.name ?? 'POS Test',
      },
      issuedDate: new Date().toISOString(),
      dueDate: new Date().toISOString(),
      totals: {
        net: itemsNet(items),
        VAT: totalVAT,
        taxes: [
          {
            taxId: 1000,
            taxName: 'IVA',
            amount: totalVAT,
          },
        ],
        total: itemsTotal(items, mainCurrencyDecimals),
      },
      items: computedItems(getSalesInvoiceItemsWithTaxes(items)(state), mainCurrencyDecimals),
    } as unknown as ISalesInvoice;
  },
);

export function getIsSalesInvoiceNoteOfCredit(
  salesInvoice: ISalesInvoice,
): (state: AppState) => boolean {
  return createSelector([(appState) => appState], (appState) => {
    const docType = getDocTypeFromDocTypeId(salesInvoice.docType?.docTypeId)(appState);
    return docType?.subFamily === 'C';
  });
}
