import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  ICustomField,
  IProductList,
  ISalesInvoice,
  ISalesInvoicesItem,
  ISalesQuote,
  IUser,
  QuantityDelivered,
  TRACE_FROM_STEP_ORIGIN,
} from '@laudus/types';

import { SALES_INVOICE_EMPTY } from '../../constants/salesInvoices';
import { calculateInvoiceItemVat } from '../calculateInvoiceTotals';

import {
  copyCustomFieldsFromOneEntityToAnother,
  copyNotesFromSalesDocumentToAnother,
  ensureSalesDocumentCustomerMatch,
  ensureSalesDocumentIsNotNulled,
  ensureSalesDocumentIsNotSentToSII,
  ensureSalesDocumentMatchTheUserBranchRestriction,
  ensureSalesQuoteIsApproved,
  getLineWithTheSameProduct,
  getQuantityDeliveredOfSalesItem,
  getQuantityToCopyToSalesItem,
  getVATRateForProduct,
} from './genericFunctions';

export async function createSalesInvoiceFromSalesQuote({
  initialInvoice = { ...SALES_INVOICE_EMPTY, SII_transactionType: '1' },
  salesQuote,
  user,
  customFieldsForSalesInvoices = [],
  customFieldsForSalesInvoicesItems = [],
  customFieldsForSalesQuotes = [],
  customFieldsForSalesQuotesItems = [],
  shouldCopyNotesToInvoice = false,
  quantityDeliveredFromQuote = [],
  groupItemsWithTheSameProduct = false,
  maxItemsInInvoices,
  generalVATRate = 19,
  mainCurrencyDecimals = 0,
  allowDTE = false,
  doesSalesQuoteHaveToBeApproved = false,
  doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts = false,
  productList,
}: {
  initialInvoice?: ISalesInvoice;
  salesQuote: ISalesQuote;
  user: IUser;
  customFieldsForSalesInvoices?: ICustomField[];
  customFieldsForSalesInvoicesItems?: ICustomField[];
  customFieldsForSalesQuotes?: ICustomField[];
  customFieldsForSalesQuotesItems?: ICustomField[];
  shouldCopyNotesToInvoice?: boolean;
  quantityDeliveredFromQuote?: QuantityDelivered;
  groupItemsWithTheSameProduct?: boolean;
  maxItemsInInvoices?: number;
  generalVATRate?: number;
  mainCurrencyDecimals?: number;
  allowDTE?: boolean;
  doesSalesQuoteHaveToBeApproved?: boolean;
  doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts?: boolean;
  productList: IProductList;
}): Promise<ISalesInvoice> {
  // Those can throw an error
  ensureSalesDocumentIsNotNulled({ salesDocument: salesQuote });
  ensureSalesQuoteIsApproved({
    salesQuote,
    doesSalesQuoteHaveToBeApproved,
    doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts,
  });
  ensureSalesDocumentIsNotSentToSII({ salesDocument: initialInvoice });
  ensureSalesDocumentMatchTheUserBranchRestriction({
    user,
    salesDocument: salesQuote,
  });
  ensureSalesDocumentCustomerMatch({
    firstSalesDocument: initialInvoice,
    secondSalesDocument: salesQuote,
  });

  // Start filling the invoice
  const invoice = { ...initialInvoice };

  // Fill the invoice fields from the sales quote fields
  copyHeaderFieldsFromQuoteToInvoice({ salesQuote, invoice });

  copyCustomFieldsFromOneEntityToAnother({
    entityToGetCustomFieldsFrom: salesQuote,
    customFieldsForEntityToGetCustomFieldsFrom: customFieldsForSalesQuotes,
    entityToCopyCustomFieldsInto: invoice,
    customFieldsForEntityToCopyCustomFieldsInto: customFieldsForSalesInvoices,
  });

  if (shouldCopyNotesToInvoice) {
    copyNotesFromSalesDocumentToAnother({
      originSalesDocument: salesQuote,
      newSalesDocument: invoice,
    });
  }

  assignDocTypeToInvoice({
    invoice,
    allowDTE,
  });

  // Fill the invoice items from the sales quote items
  await copyItemsFromQuoteToInvoice({
    salesQuote,
    invoice,
    quantityDelivered: quantityDeliveredFromQuote,
    groupItemsWithTheSameProduct,
    maxItemsInInvoices,
    customFieldsForSalesInvoicesItems,
    customFieldsForSalesQuotesItems,
    generalVATRate,
    mainCurrencyDecimals,
    productList,
  });

  // Return the filled invoice
  return invoice;
}

function copyHeaderFieldsFromQuoteToInvoice({
  salesQuote,
  invoice,
}: {
  salesQuote: ISalesQuote;
  invoice: ISalesInvoice;
}) {
  const fieldsToCopy: (keyof ISalesQuote & keyof ISalesInvoice)[] = [
    'customer',
    'contact',
    'salesman',
    'dealer',
    'term',
    'priceList',
    'branch',
    'deliveryCost',
    'deliveryNotes',
    'carrier',
    'deliveryAddress',
  ];

  fieldsToCopy.forEach((salesQuoteProperty) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (invoice as any)[salesQuoteProperty] = salesQuote[salesQuoteProperty];
  });
}

function assignDocTypeToInvoice({
  invoice,
  allowDTE,
}: {
  invoice: ISalesInvoice;
  allowDTE: boolean;
}) {
  // Use salesQuote doctype, or default to standard invoice
  invoice.docType = {
    docTypeId: 30,
    name: '',
  };

  // Convert from standard to electronic types if the company is allowed to issue electronic tickets
  if (allowDTE) {
    invoice.docType.docTypeId = 33;
  }
}

async function copyItemsFromQuoteToInvoice({
  salesQuote,
  invoice,
  quantityDelivered,
  groupItemsWithTheSameProduct,
  maxItemsInInvoices,
  customFieldsForSalesQuotesItems,
  customFieldsForSalesInvoicesItems,
  generalVATRate,
  mainCurrencyDecimals,
  productList,
}: {
  salesQuote: ISalesQuote;
  invoice: ISalesInvoice;
  quantityDelivered: QuantityDelivered;
  groupItemsWithTheSameProduct?: boolean;
  maxItemsInInvoices?: number;
  customFieldsForSalesQuotesItems: ICustomField[];
  customFieldsForSalesInvoicesItems: ICustomField[];
  generalVATRate: number;
  mainCurrencyDecimals: number;
  productList: IProductList;
}) {
  const salesInvoiceItems: ISalesInvoicesItem[] = [];
  const salesQuoteItems = salesQuote.items ?? [];

  // Get only salesQuoteItems that haven't been completely delivered
  const salesQuoteItemsThatHaventBeenCompletelyDelivered = salesQuoteItems.filter(
    (salesQuoteItem) => {
      const itemQuantityDelivered = getQuantityDeliveredOfSalesItem({
        quantityDelivered,
        salesItem: salesQuoteItem,
      });

      const hasItemBeenCompletelyDelivered =
        !!itemQuantityDelivered && itemQuantityDelivered.delivered >= salesQuoteItem.quantity;

      return !hasItemBeenCompletelyDelivered;
    },
  );

  // Copy each item
  for (const salesQuoteItem of salesQuoteItemsThatHaventBeenCompletelyDelivered) {
    const quantityToCopy = getQuantityToCopyToSalesItem({
      quantityDelivered,
      salesItem: salesQuoteItem,
    });

    // Lets try to find a line with the same product and add the quantity
    if (groupItemsWithTheSameProduct) {
      const lineWithTheSameProduct = getLineWithTheSameProduct({
        salesItems: salesInvoiceItems,
        salesItem: salesQuoteItem,
      });

      if (lineWithTheSameProduct) {
        lineWithTheSameProduct.quantity += quantityToCopy;
        continue;
      }
    }

    // Get VAT rate
    const productId = salesQuoteItem.product?.productId as number;
    const VATRate = getVATRateForProduct({
      productId,
      generalVATRate,
      productList,
    });

    // Create a new item and fill its information
    const newInvoiceItem: ISalesInvoicesItem = {
      ...salesQuoteItem,
      VATRate,
      quantity: quantityToCopy,
      itemId: getNegativeUniqueNumericId(),
      VAT: 0,
      taxes: [],
      notInvoiceable: false,
      traceFrom: [
        {
          fromId: salesQuoteItem.itemId,
          fromStep: TRACE_FROM_STEP_ORIGIN.QUOTES,
        },
      ],
    };

    newInvoiceItem.VAT = calculateInvoiceItemVat(newInvoiceItem, mainCurrencyDecimals);

    copyCustomFieldsFromOneEntityToAnother({
      entityToGetCustomFieldsFrom: salesQuoteItem,
      customFieldsForEntityToGetCustomFieldsFrom: customFieldsForSalesQuotesItems,
      entityToCopyCustomFieldsInto: newInvoiceItem,
      customFieldsForEntityToCopyCustomFieldsInto: customFieldsForSalesInvoicesItems,
    });

    salesInvoiceItems.push(newInvoiceItem);
  }

  // Add new items
  const newInvoiceItems = salesInvoiceItems.concat(invoice.items ?? []);

  if (maxItemsInInvoices && newInvoiceItems.length > maxItemsInInvoices) {
    throw new Error(
      `La factura contiene más items (${newInvoiceItems.length}) que la cantidad máxima permitida (${maxItemsInInvoices})`,
    );
  }

  invoice.items = newInvoiceItems;
}
