import { AxiosResponse } from 'axios';

import { IEmployeeLoan } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchEmployeesLoansListFromAPI = (
  employeeId: number,
): Promise<AxiosResponse<IEmployeeLoan[]>> =>
  axios.get(`${VITE_API_URL}/HR/employees/${employeeId}/loans`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const fetchEmployeeLoanFromAPI = (
  employeeId: number,
  loanId: number,
): Promise<AxiosResponse<IEmployeeLoan>> =>
  axios.get(`${VITE_API_URL}/HR/employees/${employeeId}/loans/${loanId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createEmployeeLoanFromAPI = (
  employeeId: number,
  loan: IEmployeeLoan,
): Promise<AxiosResponse<IEmployeeLoan>> =>
  axios.post(`${VITE_API_URL}/HR/employees/${employeeId}/loans`, loan);

export const updateEmployeeLoanFromAPI = (
  employeeId: number,
  loan: IEmployeeLoan,
): Promise<AxiosResponse<IEmployeeLoan>> => {
  return axios.put(`${VITE_API_URL}/HR/employees/${employeeId}/loans/${loan.employeeLoanId}`, loan);
};

export const deleteEmployeeLoanFromAPI = (
  employeeId: number,
  loanId: number,
): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/HR/employees/${employeeId}/loans/${loanId}`);
