import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IRecipesState } from './reducer';

export const getRecipesSlice = (state: AppState): IRecipesState => state.recipes;

export const getRecipesList = createSelector([getRecipesSlice], (state) => state.list);

export const getRecipe = createSelector([getRecipesSlice], (state) => state.current);

export const getRecipeDraft = createSelector([getRecipesSlice], (state) => state.draft);

export const getRecipesListOrderedAlphabetically = createSelector([getRecipesList], (list) => {
  return [...list].sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
});
