import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IEmployeeContractTermination } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Simple actions
export const clearEmployeeContractTermination = createAction(
  'EMPLOYEES_CONTRACT_TERMINATIONS/CLEAR',
);

export const clearEmployeeContractTerminationDraft = createAction(
  'EMPLOYEES_CONTRACT_TERMINATIONS/CLEAR_DRAFT',
);

export const setEmployeeContractTermination = createAction<IEmployeeContractTermination>(
  'EMPLOYEES_CONTRACT_TERMINATIONS/SET',
);

export const setEmployeeContractTerminationDraft = createAction<IEmployeeContractTermination>(
  'EMPLOYEES_CONTRACT_TERMINATIONS/SET_DRAFT',
);

export const setEmployeeContractTerminationDraftValues = createAction<
  Partial<IEmployeeContractTermination>
>('EMPLOYEES_CONTRACT_TERMINATIONS/SET_DRAFT_VALUE');

export const setEmployeeContractTerminationsList = createAction<IEmployeeContractTermination[]>(
  'EMPLOYEES_CONTRACT_TERMINATIONS/SET_LIST',
);

export const updateEmployeeContractTerminationsList = createAction<IEmployeeContractTermination>(
  'EMPLOYEES_CONTRACT_TERMINATIONS/UPDATE_LIST',
);

export const removeEmployeeContractTerminationFromList = createAction<number>(
  'EMPLOYEES_CONTRACT_TERMINATIONS/REMOVE_FROM_LIST',
);

// Complex actions
export interface IFetchEmployeeContractTerminationParams {
  employeeId: number;
  contractTerminationId: number;
}

export const fetchEmployeeContractTermination = createAsyncThunk<
  void,
  IFetchEmployeeContractTerminationParams,
  AppThunkConfig
>(
  'EMPLOYEES_CONTRACT_TERMINATIONS/FETCH',
  async ({ employeeId, contractTerminationId }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('employee-contract-terminations'));
    try {
      const { data } =
        await api.employeeContractTerminations.fetchEmployeeContractTerminationFromAPI(
          employeeId,
          contractTerminationId,
        );
      dispatch(setEmployeeContractTermination(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeeContractTerminations',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('employee-contract-terminations'));
    }
  },
);

export const fetchEmployeeContractTerminationsList = createAsyncThunk<void, number, AppThunkConfig>(
  'EMPLOYEES_CONTRACT_TERMINATIONS/FETCH_EMPLOYEES_CONTRACT_TERMINATIONS_LIST',
  async (employeeId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;
    dispatch(startRequest('employee-contract-terminations'));
    try {
      const { data } =
        await api.employeeContractTerminations.fetchEmployeesContractTerminationsListFromAPI(
          employeeId,
        );
      dispatch(setEmployeeContractTerminationsList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeeContractTerminations',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('employee-contract-terminations'));
    }
  },
);

export interface ICreateEmployeeContractTerminationParams {
  employeeId: number;
  contractTermination: IEmployeeContractTermination;
}

export const createEmployeeContractTermination = createAsyncThunk<
  void,
  ICreateEmployeeContractTerminationParams,
  AppThunkConfig
>(
  'EMPLOYEES_CONTRACT_TERMINATIONS/CREATE_EMPLOYEE_CONTRACT_TERMINATION',
  async ({ employeeId, contractTermination }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;
    dispatch(startRequest('employee-contract-terminations'));
    try {
      const idToZeroContract = {
        ...contractTermination,
        employeeContractTerminationId: 0,
      };
      const { data } =
        await api.employeeContractTerminations.createEmployeeContractTerminationFromAPI(
          employeeId,
          idToZeroContract,
        );
      dispatch(updateEmployeeContractTerminationsList(data));

      dispatch(
        showAlert({
          title: intl.formatMessage({
            id: 'employeeContractTerminations.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeeContractTerminations',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('employee-contract-terminations'));
    }
  },
);

export interface IUpdateEmployeeContractTerminationParams {
  employeeId: number;
  contractTermination: IEmployeeContractTermination;
}

export const updateEmployeeContractTermination = createAsyncThunk<
  void,
  IUpdateEmployeeContractTerminationParams,
  AppThunkConfig
>(
  'EMPLOYEES_CONTRACT_TERMINATIONS/UPDATE_EMPLOYEE_CONTRACT_TERMINATION',
  async ({ employeeId, contractTermination }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('employee-contract-terminations'));

    try {
      const { data } =
        await api.employeeContractTerminations.updateEmployeeContractTerminationFromAPI(
          employeeId,
          contractTermination,
        );
      dispatch(setEmployeeContractTermination(data));
      dispatch(updateEmployeeContractTerminationsList(data));

      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'employeeContractTerminations.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeeContractTerminations',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('employee-contract-terminations'));
    }
  },
);

export interface IDeleteEmployeeContractTerminationParams {
  employeeId: number;
  contractTerminationId: number;
}

export const deleteEmployeeContractTermination = createAsyncThunk<
  void,
  IDeleteEmployeeContractTerminationParams,
  AppThunkConfig
>(
  'EMPLOYEES_CONTRACT_TERMINATIONS/DELETE_EMPLOYEE_CONTRACT_TERMINATION',
  async ({ employeeId, contractTerminationId }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('employee-contract-terminations'));
    try {
      await api.employeeContractTerminations.deleteEmployeeContractTerminationFromAPI(
        employeeId,
        contractTerminationId,
      );
      dispatch(removeEmployeeContractTerminationFromList(contractTerminationId));

      dispatch(
        showAlert({
          title: intl.formatMessage({
            id: 'employeeContractTerminations.successToast.delete',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeeContractTerminations',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('employee-contract-terminations'));
    }
  },
);
