import { createSelector } from '@reduxjs/toolkit';

import { AppState, IOutputsState } from '../..';

export const getOutputsSlice = (state: AppState): IOutputsState => state.outputs;

export const getOutputsList = createSelector([getOutputsSlice], (state) => state.list);

export const getOutput = createSelector([getOutputsSlice], (state) => state.current);

export const getOutputDraft = createSelector([getOutputsSlice], (state) => state.draft);
