import { Link as RouterLink } from 'react-router-dom';

import { Flex, Link, Text } from '@chakra-ui/react';

import { useIntl } from '@laudus/intl';
import { colors } from '@laudus/shared-ui';

import { NewPasswordForm } from './Forms/NewPasswordForm';

interface IPasswordResetUpdateProps {
  formError: string;
  loginNameParam: string;
  tokenParam: string;
}

export const PasswordResetUpdate = ({
  formError,
  loginNameParam,
  tokenParam,
}: IPasswordResetUpdateProps) => {
  const intl = useIntl();

  return (
    <>
      <Flex
        alignItems={{ base: 'center', md: 'center' }}
        flexDirection="column"
        justifyContent={{ base: 'center', md: 'flex-start' }}
        mb="2rem"
        position="relative"
        w="100%"
      >
        {loginNameParam && (
          <Text
            align="center"
            color={{ base: colors.white, md: colors.black }}
            fontSize="1.5rem"
            fontWeight="500"
            w="100%"
          >
            {loginNameParam}
          </Text>
        )}
        <Text
          align="center"
          color={{ base: colors.white, md: colors.black }}
          fontSize="2rem"
          fontWeight="700"
          w="100%"
        >
          {intl.formatMessage({ id: 'resetPassword.title.newPassword' })}
        </Text>
      </Flex>
      <Flex flexDirection="column" maxW="33rem" w={{ base: '90%', md: '100%' }}>
        <NewPasswordForm
          formError={formError}
          loginNameParam={loginNameParam}
          tokenParam={tokenParam}
        />
        <Link
          color={{ base: colors.neutral500, md: colors.black }}
          fontSize={{ base: '1.5rem', md: '1.3rem', lg: '1.3rem' }}
          fontWeight="600"
          mb="1.2rem"
          mt="2rem"
          textAlign="center"
          w="100%"
          whiteSpace="nowrap"
        >
          <RouterLink to="/login">
            <Text whiteSpace="nowrap">
              {`< ${intl.formatMessage({
                id: 'resetPassword.button.backToLogin',
              })}`}
            </Text>
          </RouterLink>
        </Link>
      </Flex>
    </>
  );
};
