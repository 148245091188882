import { AxiosResponse } from 'axios';

import { IOpportunity } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchOpportunitiesListAPI = (): Promise<AxiosResponse<IOpportunity[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/opportunities/list`,
    {
      fields: [
        'opportunityId',
        'description',
        'date',
        'stage.description',
        'customer.name',
        'salesman.name',
        'notes',
        'customFields.*',
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchOpportunityAPI = (opportunityId: number): Promise<AxiosResponse<IOpportunity>> =>
  axios.get(`${VITE_API_URL}/sales/opportunities/${opportunityId}`);

export const createOpportunityAPI = (
  opportunity: IOpportunity,
): Promise<AxiosResponse<IOpportunity>> =>
  axios.post(`${VITE_API_URL}/sales/opportunities`, opportunity);

export const updateOppportunityAPI = (
  opportunity: IOpportunity,
): Promise<AxiosResponse<IOpportunity>> =>
  axios.put(`${VITE_API_URL}/sales/opportunities/${opportunity.opportunityId}`, opportunity);

export const deleteOpportunityAPI = (opportunityId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/opportunities/${opportunityId}`);
