import { createSelector } from '@reduxjs/toolkit';

import { AppState, IGroupsState } from '../..';

export const getGroupsSlice = (state: AppState): IGroupsState => state.groups;

export const getGroupsList = createSelector([getGroupsSlice], (state) => state.list);

export const getGroup = createSelector([getGroupsSlice], (state) => state.current);

export const getGroupDraft = createSelector([getGroupsSlice], (state) => state.draft);

export const getRightList = createSelector([getGroupsSlice], (state) => state.rights);

export const getGroupUserList = createSelector([getGroupsSlice], (state) => state.groupUserList);
