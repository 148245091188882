import { createReducer } from '@reduxjs/toolkit';

import { ICarrier } from '@laudus/types';

import {
  clearCarriers,
  clearCarriersDraft,
  duplicateCarrier,
  removeCarriersFromList,
  setCarrier,
  setCarrierDraft,
  setCarriersDraftValues,
  setCarriersList,
  updateCarriersList,
} from './actions';

export const CARRIERS_TAB_ID = 'carriers';
export interface ICarrierState {
  current: ICarrier;
  draft: ICarrier;
  list: ICarrier[];
}

export const CARRIERS_EMPTY: ICarrier = {
  carrierId: '',
  name: '',
  legalName: '',
  address: '',
  county: '',
  phone: '',
  notes: '',
  createdAt: '',
  createdBy: {
    userId: '',
    name: '',
  },
  modifiedAt: '',
  modifiedBy: {
    userId: '',
    name: '',
  },
};

export const initialCarrierState: ICarrierState = {
  current: CARRIERS_EMPTY,
  draft: CARRIERS_EMPTY,
  list: [],
};

export const carriersReducer = createReducer(initialCarrierState, (builder) => {
  builder
    .addCase(clearCarriers, (state) => {
      return { ...state, current: CARRIERS_EMPTY };
    })
    .addCase(clearCarriersDraft, (state) => {
      return { ...state, draft: CARRIERS_EMPTY };
    })
    .addCase(setCarrier, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setCarrierDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setCarriersDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setCarriersList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateCarriersList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.carrierId !== action.payload.carrierId),
          action.payload,
        ],
      };
    })
    .addCase(removeCarriersFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.carrierId !== action.payload),
      };
    })
    .addCase(duplicateCarrier, (state, action) => {
      const { carrierId, ...duplicatedCarrier } = action.payload;
      return { ...state, draft: duplicatedCarrier };
    })
    .addDefaultCase((state) => state);
});
