import { AxiosResponse } from 'axios';

import {
  ISalesInvoicesFilters,
  ISalesOrder,
  ISalesOrderList,
  ITraceFrom,
  QuantityDelivered,
} from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const defaultFilters: ISalesInvoicesFilters = {
  orderBy: [
    {
      field: 'salesOrderId',
      direction: 'DESC',
    },
  ],
  fields: [
    'salesOrderId',
    'customer.name',
    'issuedDate',
    'deliveryDate',
    'purchaseOrderNumber',
    'nullDoc',
  ],
};

export const fetchSalesOrderListFromAPI = (
  filters: Partial<ISalesInvoicesFilters> = defaultFilters,
): Promise<AxiosResponse<ISalesOrderList>> =>
  axios.post(
    `${VITE_API_URL}/sales/orders/list`,
    { ...defaultFilters, ...filters },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchSalesOrderFromAPI = async (
  salesOrderId: number,
): Promise<AxiosResponse<ISalesOrder>> => {
  const response = await axios.get<ISalesOrder>(`${VITE_API_URL}/sales/orders/${salesOrderId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

  if (!response.data) {
    return response;
  }

  // API is returning items as null when salesOrder isNull.
  // Lets keep the consistency of the typing by returning empty array when items is null
  return { ...response, data: { ...response.data, items: response.data.items ?? [] } };
};

export const createSalesOrderFromAPI = (
  salesOrder: ISalesOrder,
): Promise<AxiosResponse<ISalesOrder>> => axios.post(`${VITE_API_URL}/sales/orders`, salesOrder);

export const updateSalesOrderFromAPI = (
  salesOrder: ISalesOrder,
): Promise<AxiosResponse<ISalesOrder>> =>
  axios.put(`${VITE_API_URL}/sales/orders/${salesOrder.salesOrderId}`, salesOrder);

export const deleteSalesOrderFromAPI = async (salesOrderId: number): Promise<AxiosResponse> => {
  // return axios.delete(`${VITE_API_URL}/sales/orders/${salesOrderId}`);

  // Temporary solution to cancel sales order
  const { data: order } = await fetchSalesOrderFromAPI(salesOrderId);

  const duplicatedOrder = { ...order };
  duplicatedOrder.nullDoc = true;

  return updateSalesOrderFromAPI(duplicatedOrder);
};

export const getQuantityDeliveredBySalesOrderFromAPI = (
  salesOrderId: number,
): Promise<AxiosResponse<QuantityDelivered>> => {
  return axios.get(`${VITE_API_URL}/sales/orders/${salesOrderId}/quantityDelivered`, {
    headers: {
      Accept: 'application/json',
    },
  });
};

export const fetchSalesOrderTracesFromAPI = (
  salesOrderId: number,
): Promise<AxiosResponse<ITraceFrom[]>> =>
  axios.get(`${VITE_API_URL}/sales/orders/${salesOrderId}/traceFrom`);

export const fetchSalesOrderPDFFromAPI = async (salesOrderId: number): Promise<string> => {
  const { data } = await axios.get(
    `${VITE_API_URL}/sales/orders/${salesOrderId}/pdf?contentDisposition=attachment`,
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
      },
    },
  );

  const blob = new Blob([new Uint8Array(data)], { type: 'application/pdf' });
  const urlBlob = URL.createObjectURL(blob);

  return urlBlob;
};
