import { AxiosResponse } from 'axios';

import {
  IStatisticsDataItem,
  IStatisticsFilterItems,
  IStatisticsInternalReportResponse,
  IStatisticsItems,
  IStatisticsProductStockData,
} from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

// TODO [STATISTICS_REFACTOR]: This function is used in the old statistics slice, we need to remove it
export const fetchStatisticsFromAPI = (
  url: string,
  statParams?: IStatisticsFilterItems,
): Promise<AxiosResponse<IStatisticsItems[]>> =>
  axios.get(`${VITE_API_URL}${url}`, { params: statParams });

export const fetchStatisticsFromReportsEndpointAPI = (
  url: string,
  statParams?: IStatisticsFilterItems,
): Promise<AxiosResponse<IStatisticsDataItem[]>> =>
  axios.get(`${VITE_API_URL}${url}`, { params: statParams });

export const fetchStatisticsFromInternalReportsEndpointAPI = (
  statisticName: string,
  statParams?: IStatisticsFilterItems,
): Promise<AxiosResponse<IStatisticsInternalReportResponse>> =>
  axios.post(
    `${VITE_API_URL}/internalReports/statistic`,
    {
      statistic: statisticName,
      parameters: statParams,
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchStatisticsFromProductsStockEndpointAPI = (
  url: string,
  statParams?: IStatisticsFilterItems,
): Promise<AxiosResponse<IStatisticsProductStockData>> =>
  axios.get(`${VITE_API_URL}${url}`, { params: statParams });
