import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  ICustomField,
  ISalesQuote,
  ISalesWaybill,
  ISalesWaybillItem,
  IUser,
  QuantityDelivered,
  TRACE_FROM_STEP_ORIGIN,
} from '@laudus/types';

import { SALES_WAYBILL_EMPTY } from '../../constants/salesWaybills';

import {
  copyCustomFieldsFromOneEntityToAnother,
  copyNotesFromSalesDocumentToAnother,
  ensureSalesDocumentCustomerMatch,
  ensureSalesDocumentIsNotNulled,
  ensureSalesDocumentIsNotSentToSII,
  ensureSalesDocumentMatchTheUserBranchRestriction,
  ensureSalesQuoteIsApproved,
  getLineWithTheSameProduct,
  getQuantityDeliveredOfSalesItem,
  getQuantityToCopyToSalesItem,
} from './genericFunctions';

export async function createSalesWaybillFromSalesQuote({
  initialSalesWaybill = { ...SALES_WAYBILL_EMPTY },
  salesQuote,
  user,
  customFieldsForSalesWaybills = [],
  customFieldsForSalesWaybillsItems = [],
  customFieldsForSalesQuotes = [],
  customFieldsForSalesQuotesItems = [],
  shouldCopyNotesToSalesWaybill = false,
  quantityDeliveredFromQuote = [],
  groupItemsWithTheSameProduct = false,
  maxItemsInSalesWaybills,
  allowDTE = false,
  doesSalesQuoteHaveToBeApproved = false,
  doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts = false,
}: {
  initialSalesWaybill?: ISalesWaybill;
  salesQuote: ISalesQuote;
  user: IUser;
  customFieldsForSalesWaybills?: ICustomField[];
  customFieldsForSalesWaybillsItems?: ICustomField[];
  customFieldsForSalesQuotes?: ICustomField[];
  customFieldsForSalesQuotesItems?: ICustomField[];
  shouldCopyNotesToSalesWaybill?: boolean;
  quantityDeliveredFromQuote?: QuantityDelivered;
  groupItemsWithTheSameProduct?: boolean;
  maxItemsInSalesWaybills?: number;
  allowDTE?: boolean;
  doesSalesQuoteHaveToBeApproved?: boolean;
  doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts?: boolean;
}): Promise<ISalesWaybill> {
  // Those can throw an error
  ensureSalesDocumentIsNotNulled({ salesDocument: salesQuote });
  ensureSalesQuoteIsApproved({
    salesQuote,
    doesSalesQuoteHaveToBeApproved,
    doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts,
  });
  ensureSalesDocumentIsNotSentToSII({ salesDocument: initialSalesWaybill });
  ensureSalesDocumentMatchTheUserBranchRestriction({
    user,
    salesDocument: salesQuote,
  });
  ensureSalesDocumentCustomerMatch({
    firstSalesDocument: initialSalesWaybill,
    secondSalesDocument: salesQuote,
  });

  // Start filling the sales waybill
  const salesWaybill = { ...initialSalesWaybill };

  // Fill the sales waybill fields from the sales quote fields
  copyHeaderFieldsFromQuoteToWaybill({ salesQuote, salesWaybill });

  copyCustomFieldsFromOneEntityToAnother({
    entityToGetCustomFieldsFrom: salesQuote,
    customFieldsForEntityToGetCustomFieldsFrom: customFieldsForSalesQuotes,
    entityToCopyCustomFieldsInto: salesWaybill,
    customFieldsForEntityToCopyCustomFieldsInto: customFieldsForSalesWaybills,
  });

  if (shouldCopyNotesToSalesWaybill) {
    copyNotesFromSalesDocumentToAnother({
      originSalesDocument: salesQuote,
      newSalesDocument: salesWaybill,
    });
  }

  assignDocTypeToSalesWaybill({
    salesWaybill: salesWaybill,
    allowDTE,
  });

  // Fill the sales waybill items from the sales quote items
  await copyItemsFromQuoteToWaybill({
    salesQuote,
    salesWaybill: salesWaybill,
    quantityDelivered: quantityDeliveredFromQuote,
    groupItemsWithTheSameProduct,
    maxItemsInSalesWaybills,
    customFieldsForSalesWaybillsItems: customFieldsForSalesWaybillsItems,
    customFieldsForSalesQuotesItems,
  });

  // Return the filled sales waybill
  return salesWaybill;
}

function copyHeaderFieldsFromQuoteToWaybill({
  salesQuote,
  salesWaybill,
}: {
  salesQuote: ISalesQuote;
  salesWaybill: ISalesWaybill;
}) {
  const fieldsToCopy: (keyof ISalesQuote & keyof ISalesWaybill)[] = [
    'customer',
    'contact',
    'salesman',
    'dealer',
    'term',
    'priceList',
    'branch',
    'deliveryCost',
    'deliveryNotes',
    'carrier',
    'deliveryAddress',
  ];

  fieldsToCopy.forEach((salesQuoteProperty) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (salesWaybill as any)[salesQuoteProperty] = salesQuote[salesQuoteProperty];
  });
}

function assignDocTypeToSalesWaybill({
  salesWaybill,
  allowDTE,
}: {
  salesWaybill: ISalesWaybill;
  allowDTE: boolean;
}) {
  // Default to standard waybill
  salesWaybill.docType = {
    docTypeId: 50,
    name: '',
  };

  // Convert from standard to electronic types if possible
  if (allowDTE) {
    salesWaybill.docType.docTypeId = 52;
  }
}

async function copyItemsFromQuoteToWaybill({
  salesQuote,
  salesWaybill,
  quantityDelivered,
  groupItemsWithTheSameProduct,
  maxItemsInSalesWaybills,
  customFieldsForSalesQuotesItems,
  customFieldsForSalesWaybillsItems,
}: {
  salesQuote: ISalesQuote;
  salesWaybill: ISalesWaybill;
  quantityDelivered: QuantityDelivered;
  groupItemsWithTheSameProduct?: boolean;
  maxItemsInSalesWaybills?: number;
  customFieldsForSalesQuotesItems: ICustomField[];
  customFieldsForSalesWaybillsItems: ICustomField[];
}) {
  const salesWaybillItems: ISalesWaybillItem[] = [];
  const salesQuoteItems = salesQuote.items ?? [];

  // Get only salesQuoteItems that haven't been completely delivered
  const salesQuoteItemsThatHaventBeenCompletelyDelivered = salesQuoteItems.filter(
    (salesQuoteItem) => {
      const itemQuantityDelivered = getQuantityDeliveredOfSalesItem({
        quantityDelivered,
        salesItem: salesQuoteItem,
      });

      const hasItemBeenCompletelyDelivered =
        !!itemQuantityDelivered && itemQuantityDelivered.delivered >= salesQuoteItem.quantity;

      return !hasItemBeenCompletelyDelivered;
    },
  );

  // Copy each item
  for (const salesQuoteItem of salesQuoteItemsThatHaventBeenCompletelyDelivered) {
    const quantityToCopy = getQuantityToCopyToSalesItem({
      quantityDelivered,
      salesItem: salesQuoteItem,
    });

    // Lets try to find a line with the same product and add the quantity
    if (groupItemsWithTheSameProduct) {
      const lineWithTheSameProduct = getLineWithTheSameProduct({
        salesItems: salesWaybillItems,
        salesItem: salesQuoteItem,
      });

      if (lineWithTheSameProduct) {
        lineWithTheSameProduct.quantity += quantityToCopy;
        continue;
      }
    }

    // Create a new item and fill its information
    const newSalesWaybillItem: ISalesWaybillItem = {
      ...salesQuoteItem,
      quantity: quantityToCopy,
      itemId: getNegativeUniqueNumericId(),
      traceFrom: [
        {
          fromId: salesQuoteItem.itemId,
          fromStep: TRACE_FROM_STEP_ORIGIN.QUOTES,
        },
      ],
    };

    copyCustomFieldsFromOneEntityToAnother({
      entityToGetCustomFieldsFrom: salesQuoteItem,
      customFieldsForEntityToGetCustomFieldsFrom: customFieldsForSalesQuotesItems,
      entityToCopyCustomFieldsInto: newSalesWaybillItem,
      customFieldsForEntityToCopyCustomFieldsInto: customFieldsForSalesWaybillsItems,
    });

    salesWaybillItems.push(newSalesWaybillItem);
  }

  // Add new items
  const newSalesWaybillItems = salesWaybillItems.concat(salesWaybill.items ?? []);

  if (maxItemsInSalesWaybills && newSalesWaybillItems.length > maxItemsInSalesWaybills) {
    throw new Error(
      `La guía de despacho contiene más items (${newSalesWaybillItems.length}) que la cantidad máxima permitida (${maxItemsInSalesWaybills})`,
    );
  }

  salesWaybill.items = newSalesWaybillItems;
}
