export const LaudusImages = {
  createThree: '/images/create-home-three.png',
  createThreeDark: '/images/darkMode/create-home-three.png',
  dashboardArea: '/images/dashboard/dashboard-area.png',
  dashboardColumn: '/images/dashboard/dashboard-column.png',
  dashboardEmpty: '/images/dashboard/dashboard-empty.png',
  dashboardLine: '/images/dashboard/dashboard-line.png',
  dashboardPie: '/images/dashboard/dashboard-pie.png',
  dtePendingsStep1: '/images/dtependings/DTEPendings1.png',
  dtePendingsStep2: '/images/dtependings/DTEPendings2.png',
  import1: '/images/import1.png',
  import2: '/images/import2.png',
  importThree: '/images/import-home-three.png',
  importThreeDark: '/images/darkMode/import-home-three.png',
  searchThree: '/images/search-home-three.png',
  searchThreeDark: '/images/darkMode/search-home-three.png',
  videoImage: '/images/videoImage_v3.jpg',
  logoPosWhite: '/images/logo-laudus-white.png',
  logoPosColor: '/images/logo-laudus-pos-color.png',
  logoPosColor2: '/images/logo-laudus-pos-color-version2.png',
  logoWhite: '/images/logo-laudus-white.png',
  rightLayoutImage: '/images/right-layout-image-1.webp',
  logoLoader: '/images/laudus_version3.gif',
  logoVisa: '/images/logo-visa.svg',
  logoMastercard: '/images/logo-mastercard.svg',
  logoAmex: '/images/logo-amex.svg',
  logoVisaDarkMode: '/images/logo-visa-white.svg',
  logoAmexDarkMode: '/images/logo-amex-white.svg',
  hamburgerMenu: '/images/hamburger-menu.svg',
  logo: '/images/logo.png',
  logoGreen: '/images/logoGreen.png',
  loginLogoWhite: '/images/login-logo-white.svg',
  loginLogoV2: '/images/login-logo_v2.svg',
  login2LogoDark: '/images/login2LogoDark.svg',
  login2LogoWhite: '/images/login2LogoWhite.svg',
  resetPasswordEnvelope: '/images/resetPassword/envelope-color.svg',
  payrollNewWizardDateLogo: 'images/payrollNewWizard/date.svg',
  payrollNewWizardStep1: 'images/payrollNewWizard/step1.svg',
  payrollNewWizardStep2: 'images/payrollNewWizard/step2.svg',
  posAccountBackground: '/images/products-bg.webp',
  settingsImageFitCover: '/images/settings/image-fit-cover.png',
  settingsImageFitContain: '/images/settings/image-fit-contain.png',
  defaultImage: '/images/default-image.png',
  globalUserBackground: '/images/selected-global-user-background.png',
  templates: {
    ticket1: '/images/templates/templates_ticket1.png',
    ticket2: '/images/templates/templates_ticket2.png',
    ticket3: '/images/templates/templates_ticket3.png',
    dummyPDF417: '/images/templates/dummyPDF417.bmp',
    salesOrder1: '/images/templates/templates_salesOrder1.png',
  },
};
