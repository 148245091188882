/** This function formats a date or string with local date format as
 *  a string in ISO8601 local date time format (i.e without zone offset).
 *
 *  For more info about date processing and conventions please read docs/DATE-TIME.md
 */
export const dateToLocalISOString = (sourceDate: Date | string) => {
  const date = typeof sourceDate === 'string' ? new Date(sourceDate) : sourceDate;

  const zeroPad = (numToFormat: number, lenghtOfZeros: number) => {
    return numToFormat.toString().padStart(lenghtOfZeros, '0');
  };

  return (
    zeroPad(date.getFullYear(), 4) +
    '-' +
    zeroPad(date.getMonth() + 1, 2) +
    '-' +
    zeroPad(date.getDate(), 2) +
    'T' +
    zeroPad(date.getHours(), 2) +
    ':' +
    zeroPad(date.getMinutes(), 2) +
    ':' +
    zeroPad(date.getSeconds(), 2)
  );
};

/** This is an alternative implementations from previous function using date-fns library */
// export const dateToLocalISOString = (sourceDate: Date | string) => {
//   const date = typeof sourceDate === 'string' ? new Date(sourceDate) : sourceDate;

//   return format(date, "yyyy-MM-dd'T'HH:mm:ss");
// };
