import { IInvoiceTax, IProductSalesTax, ISalesInvoicesItem, ISalesTaxes } from '@laudus/types';

export function updateSalesInvoiceItemsTaxes({
  salesInvoiceItems,
  mainCurrencyDecimals,
  productSalesTaxes,
  allSalesTaxes,
}: {
  salesInvoiceItems: ISalesInvoicesItem[];
  mainCurrencyDecimals: number;
  productSalesTaxes: IProductSalesTax[];
  allSalesTaxes: ISalesTaxes[];
}): ISalesInvoicesItem[] {
  // Iterate each sales invoice item
  const updatedItemsWithTaxes: ISalesInvoicesItem[] = salesInvoiceItems.map((item) => {
    if (item.notInvoiceable) {
      return item;
    }

    // Calculate the net of the item. It will be the same for every sales tax
    const lnLineNet = item.unitPrice * item.quantity * (1 - item.discountPercentage / 100);

    // Get all sales taxes that apply for the current item.product
    const salesTaxes = productSalesTaxes.filter(
      (productSalesTax) => productSalesTax.productId === item.product?.productId,
    );

    // Iterate all sales taxes that apply for the current item.product
    const salesItemTaxes = salesTaxes.map((salesTax) => {
      let lnLineTax = 0;

      // Calculate the amount of the tax based on the type and the value offered by "/production/products/sales/taxes"
      switch (salesTax.type) {
        // R: "Rate" Sales tax is a rate (percentage) of the net
        case 'R': {
          const lnSalesTaxRate = salesTax.value;
          lnLineTax = lnLineNet * lnSalesTaxRate;
          break;
        }
        // V: "Volume" Sales tax is an amount per unit
        case 'V': {
          const lnAmountPerUnit = salesTax.value;
          lnLineTax = item.quantity * lnAmountPerUnit;
          break;
        }
      }

      lnLineTax = isNaN(lnLineTax) ? 0 : lnLineTax;

      // Replace the amount of the tax if it exists inside item.taxes, or push the new tax to item.taxes
      const existingTaxIndex = item.taxes.findIndex((tax) => tax.taxId === salesTax.taxId);

      if (existingTaxIndex >= 0) {
        const taxToUpdate = item.taxes[existingTaxIndex];

        const updatedTax: IInvoiceTax = {
          taxId: taxToUpdate.taxId,
          taxName: taxToUpdate?.taxName,
          amount: lnLineTax,
        };

        return updatedTax;
      } else {
        const fullDomainSalesTax = allSalesTaxes.find((s) => s.taxId === salesTax.taxId);

        const newTax: IInvoiceTax = {
          taxId: salesTax.taxId,
          taxName: fullDomainSalesTax?.taxName,
          amount: lnLineTax,
        };

        return newTax;
      }
    });

    return { ...item, taxes: salesItemTaxes };
  });

  // Return sales invoice items with all the taxes filled
  return updatedItemsWithTaxes;
}

/**
 *
 * This is the original code used in the backend, but we make use of the "/production/products/sales/taxes" endpoint
 * to make the calculations easier. Before changing the code above, first ask Javier about it
 *
 */

//  // Calculate custom sales taxes
//  for each salesTax {
//   let lcSalesTaxFieldName = TAX_FIELDS_PREFIX + salesTax.salesTaxId

//   // Do not apply sales tax to tickets if we do not have to (according to sales tax definition)
//   if ((docTypeId == 35, 38, 39, 41) && !salesTax.applyToTickets) {
//     replace in all items the tax "lcSalesTaxFieldName" with 0
//     continue
//   }

//   for each item in invoice {
//     if (item.notInvoiceable) {
//       continue
//     }

//     let lnLineNet = round((item.precioUnitario * item.cantidad) * (1 - (item.descuento/100)), settings.mainCurrencyDecimals)
//     switch {
//       // Sales tax is a rate
//       case salesTax.type == 'R'
//         // If we apply general rate, do so. If not, apply rate relative to this product
//         if (item.product.taxes.thisSalesTax.applyGeneralRule) {
//           let lnSalesTaxRate = salesTax.rate
//         } else {
//           let lnSalesTaxRate = item.product.taxes.thisSalesTax.rate
//         }
//         lnLineTax = round(lnLineNet * lnSalesTaxRate, settings.mainCurrencyDecimals)

//       // Sales tax is an amount per unit
//       case salesTax.type == 'V'
//         // If we apply general rate, do so. If not, apply value relative to this product
//         if (item.product.taxes.thisSalesTax.applyGeneralRule) {
//           lnAmountPerUnit = salesTax.amountPerUnit
//                   } else {
//           lnAmountPerUnit = item.product.taxes.thisSalesTax.rate
//         }
//         lnLineTax = round(cantidad * lnAmountPerUnit, settings.mainCurrencyDecimals)
//       }

//     lnLineTax = isNull(lnLineTax) ? 0 : lnLineTax
//     replace the item lcSalesTaxFieldName with lnLineTax
//   }
// }
