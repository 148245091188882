export function generateBinaryStringFromArrayOfFilledPositions({
  filledPositions,
  stringSize,
}: {
  filledPositions: number[];
  stringSize: number;
}) {
  const result: string[] = Array(stringSize).fill('0');

  filledPositions.forEach((num) => {
    result[num - 1] = '1';
  });

  return result.join('');
}

export function isBinaryStringPositionFilled(binaryString: string, position: number) {
  // Check if the position is within the string length
  if (position >= 0 && position < binaryString.length) {
    // Check if the character at the given position is '1'
    return binaryString.charAt(position) === '1';
  } else {
    // If the position is out of bounds, return false
    return false;
  }
}
