import { ISalesInvoicesFilters, IStatisticsFilterItems } from '@laudus/types';

export const getSalesInvoicesFilters = (
  statisticsFilters: IStatisticsFilterItems,
): ISalesInvoicesFilters['filterBy'] => {
  const filters: ISalesInvoicesFilters['filterBy'] = [];

  // Dates
  if (statisticsFilters.dateFrom) {
    filters.push({
      field: 'issuedDate',
      operator: '>=',
      value: statisticsFilters.dateFrom,
    });
  }

  if (statisticsFilters.dateTo) {
    filters.push({
      field: 'issuedDate',
      operator: '<=',
      value: statisticsFilters.dateTo,
    });
  }

  // Customer
  if (statisticsFilters.customerId) {
    filters.push({
      field: 'customer.customerId',
      operator: '=',
      value: statisticsFilters.customerId,
    });
  }

  // Customer category
  if (statisticsFilters.customerCategoryId) {
    filters.push({
      field: 'customer.customerCategory.customerCategoryId',
      operator: '=',
      value: statisticsFilters.customerCategoryId,
    });
  }

  // Product
  if (statisticsFilters.productId) {
    filters.push({
      field: 'items.product.productId',
      operator: '=',
      value: statisticsFilters.productId,
    });
  }

  // Product category
  if (statisticsFilters.productCategoryId) {
    filters.push({
      field: 'items.product.productCategory.productCategoryId',
      operator: '=',
      value: statisticsFilters.productCategoryId,
    });
  }

  // Salesperson
  if (statisticsFilters.salesmanId) {
    filters.push({
      field: 'salesman.salesmanId',
      operator: '=',
      value: statisticsFilters.salesmanId,
    });
  }

  // Dealer
  // We don't support dealer in the statistics --> Why??????
  if (statisticsFilters.dealerId) {
    filters.push({
      field: 'dealer.dealerId',
      operator: '=',
      value: statisticsFilters.dealerId,
    });
  }

  // Branch
  if (statisticsFilters.branchId) {
    filters.push({
      field: 'branch.branchId',
      operator: '=',
      value: statisticsFilters.branchId,
    });
  }

  // Cost center
  if (statisticsFilters.costCenterId) {
    filters.push({
      field: 'costCenter.costCenterId',
      operator: '=',
      value: statisticsFilters.costCenterId,
    });
  }

  return filters;
};
