// Validates that the input string is a valid date formatted as dd-mm-yyyy
export const isValidDate = (dateString?: string) => {
  if (!dateString) {
    return false;
  }
  // Check pattern
  // eslint-disable-next-line no-useless-escape
  if (!/^\d{1,2}\-\d{1,2}\-\d{4}$/.test(dateString)) {
    return false;
  }
  // Divide date in day, month and year
  const parts = dateString.split('-');
  const day = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const year = parseInt(parts[2], 10);
  // Check the ranges of month and year
  if (year < 1000 || year > 3000 || month == 0 || month > 12) {
    return false;
  }
  const monthsLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

  // Adjust february for leap years
  if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0)) {
    monthsLength[1] = 29;
  }
  // Check the range of the day
  return day > 0 && day <= monthsLength[month - 1];
};
