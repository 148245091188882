import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IDailyItems } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

export const addHomeDailyTab = () =>
  addTab({
    tab: {
      id: 'daily',
      title: intl.formatMessage({ id: 'daily.tabTitle' }),
      path: 'pages/Branch/Branches',
      isRemovable: true,
    },
  });

interface IPropsView {
  disabled?: boolean;
  dailyActions?: {
    setSelectedDaily: (dailyId: number) => void;
    setSelectedDate: (date: Date) => void;
  };
  initialValues: IDailyItems[];
}

export const addViewDailyTab = (props: IPropsView) =>
  addTab({
    tab: {
      id: 'daily',
      title: intl.formatMessage({ id: 'daily.tabTitle' }),
      path: 'pages/Daily/DailyView',
      props: { ...props },
      isRemovable: true,
    },
  });

export const clearDaily = createAction('DAILY/CLEAR_LIST');
export const setDaily = createAction<IDailyItems[]>('DAILY/SET');
export const resetDailySubmit = createAction(`DAILY/RESET_SUBMIT`);

export const setDailySubmitting = createAction(`DAILY/SET_SUBMITTING`);

export const setDailySubmitSuccess = createAction(`DAILY/SET_SUBMIT_SUCCESS`);

interface IDateParams {
  dateFrom: Date;
  dateTo: Date;
}

export const fetchInvoicesByBranch = createAsyncThunk<void, IDateParams, AppThunkConfig>(
  'DAILY/FETCH',
  async ({ dateFrom, dateTo }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('daily'));
    try {
      const { data } = await api.daily.fetchInvoicesByBranchAPI(dateFrom, dateTo);
      dispatch(setDaily(Array.isArray(data) ? data : []));

      // return data;
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'daily',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('daily'));
    }
  },
);

export const fetchDueInvoices = createAsyncThunk<void, IDateParams, AppThunkConfig>(
  'DAILY/FETCH',
  async ({ dateFrom, dateTo }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('daily'));
    try {
      const { data } = await api.daily.fetchDueInvoicesAPI(dateFrom, dateTo);
      dispatch(setDaily(Array.isArray(data) ? data : []));

      // return data;
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'daily',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('daily'));
    }
  },
);

export const fetchInvoicesByProductCategory = createAsyncThunk<void, IDateParams, AppThunkConfig>(
  'DAILY/FETCH',
  async ({ dateFrom, dateTo }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('daily'));
    try {
      const { data } = await api.daily.fetchInvoicesByProductCategoryAPI(dateFrom, dateTo);
      dispatch(setDaily(Array.isArray(data) ? data : []));

      // return data;
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'daily',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('daily'));
    }
  },
);

export const fetchInvoicesByProducts = createAsyncThunk<void, IDateParams, AppThunkConfig>(
  'DAILY/FETCH',
  async ({ dateFrom, dateTo }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('daily'));
    try {
      const { data } = await api.daily.fetchInvoicesByProductsAPI(dateFrom, dateTo);
      dispatch(setDaily(Array.isArray(data) ? data : []));

      // return data;
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'daily',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('daily'));
    }
  },
);

export const fetchInvoicesBySalesman = createAsyncThunk<void, IDateParams, AppThunkConfig>(
  'DAILY/FETCH',
  async ({ dateFrom, dateTo }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('daily'));
    try {
      const { data } = await api.daily.fetchInvoicesBySalesmanAPI(dateFrom, dateTo);
      dispatch(setDaily(Array.isArray(data) ? data : []));

      // return data;
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'daily',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('daily'));
    }
  },
);

export const fetchIssuedInvoices = createAsyncThunk<void, IDateParams, AppThunkConfig>(
  'DAILY/FETCH',
  async ({ dateFrom, dateTo }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('daily'));
    try {
      const { data } = await api.daily.fetchIssuedInvoicesAPI(dateFrom, dateTo);
      dispatch(setDaily(Array.isArray(data) ? data : []));

      // return data;
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'daily',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('daily'));
    }
  },
);

export const fetchIssuedInvoicesDetail = createAsyncThunk<void, IDateParams, AppThunkConfig>(
  'DAILY/FETCH',
  async ({ dateFrom, dateTo }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('daily'));
    try {
      const { data } = await api.daily.fetchIssuedInvoicesDetailAPI(dateFrom, dateTo);
      dispatch(setDaily(Array.isArray(data) ? data : []));

      // return data;
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'daily',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('daily'));
    }
  },
);
