import { createSelector } from '@reduxjs/toolkit';

import { AppState, ICustomerContactsState } from '../..';

export const getCustomerContactsSlice = (state: AppState): ICustomerContactsState =>
  state.customerContacts;

export const getCustomerContactsList = createSelector(
  [getCustomerContactsSlice],
  (state) => state.list,
);

export const getCustomerContactsListById = (customerId?: number) =>
  createSelector([getCustomerContactsSlice], (state) => {
    return state.list && customerId ? state.list[customerId] : undefined;
  });

export const getCustomerContact = createSelector(
  [getCustomerContactsSlice],
  (state) => state.current,
);

export const getCustomerContactDraft = createSelector(
  [getCustomerContactsSlice],
  (state) => state.draft,
);
