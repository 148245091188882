import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  IProductListItem,
  IPurchaseWaybill,
  IPurchaseWaybillItem,
  IStartEditingParams,
} from '@laudus/types';

import { AppState, AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { getMainCurrency } from '../currencies';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

import { getPurchaseWaybillDraft } from './selectors';

export const PURCHASES_WAYBILLS_TAB_ID = 'purchaseWaybills';

export const addHomePurchaseWaybillsTab = () =>
  addTab({
    tab: {
      id: PURCHASES_WAYBILLS_TAB_ID,
      title: intl.formatMessage({ id: 'purchaseWaybills.tabTitle' }),
      path: 'pages/PurchaseWaybills/PurchaseWaybills',
      isRemovable: true,
    },
  });

export const addViewPurchaseWaybillsTab = (id?: number) =>
  addTab({
    tab: {
      id: PURCHASES_WAYBILLS_TAB_ID,
      title: intl.formatMessage({ id: 'purchaseWaybills.tabTitle' }),
      path: 'pages/PurchaseWaybills/PurchaseWaybillView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewPurchaseWaybillsTab = () =>
  addTab({
    tab: {
      id: PURCHASES_WAYBILLS_TAB_ID,
      title: intl.formatMessage({ id: 'purchaseWaybills.tabTitle' }),
      path: 'pages/PurchaseWaybills/PurchaseWaybillNew',
      isRemovable: true,
    },
  });

export const addEditPurchaseWaybillsTab = (id?: number) =>
  addTab({
    tab: {
      id: PURCHASES_WAYBILLS_TAB_ID,
      title: intl.formatMessage({ id: 'purchaseWaybills.tabTitle' }),
      path: 'pages/PurchaseWaybills/PurchaseWaybillEdit',
      props: { id },
      isRemovable: true,
    },
  });

// Simple actions
export const clearPurchaseWaybill = createAction(`PURCHASES_WAYBILLS/CLEAR`);
export const clearPurchaseWaybillDraft = createAction(`PURCHASES_WAYBILLS/CLEAR_DRAFT`);

export const setPurchaseWaybill = createAction<IPurchaseWaybill>(`PURCHASES_WAYBILLS/SET`);

export const setPurchaseWaybillDraft = createAction<IPurchaseWaybill>(
  `PURCHASES_WAYBILLS/SET_DRAFT`,
);

export const setPurchaseWaybillDraftValues = createAction<Partial<IPurchaseWaybill>>(
  `PURCHASES_WAYBILLS/SET_DRAFT_VALUES`,
);

export const setPurchaseWaybillList = createAction<IPurchaseWaybill[]>(
  `PURCHASES_WAYBILLS/SET_LIST`,
);

export const updatePurchaseWaybillList = createAction<IPurchaseWaybill>(
  `PURCHASES_WAYBILLS/UPDATE_LIST`,
);

export const removePurchaseWaybillFromList = createAction<number>(
  `PURCHASES_WAYBILLS/REMOVE_FROM_LIST`,
);

export const duplicatePurchaseWaybill = createAction<IPurchaseWaybill>(
  `PURCHASES_WAYBILLS/DUPLICATE`,
);

export const setPurchaseWaybillFetchingOne = createAction<boolean>(
  'PURCHASES_WAYBILLS/LOADING_ONE',
);

export const setPurchaseWaybillIsEditing = createAction<boolean>('PURCHASES_WAYBILLS/SET_EDITING');

//Complex actions
export const fetchPurchaseWaybillList = createAsyncThunk<void, void, AppThunkConfig>(
  `PURCHASES_WAYBILLS/FETCH_LIST`,
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('purchase-waybills'));

    try {
      const { data } = await api.purchaseWaybills.fetchPurchaseWaybillListFromAPI({});
      dispatch(setPurchaseWaybillList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'purchaseWaybills',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('purchase-waybills'));
    }
  },
);

export const fetchPurchaseWaybill = createAsyncThunk<void, number, AppThunkConfig>(
  `PURCHASES_WAYBILLS/FETCH`,
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('purchase-waybills'));
      dispatch(setPurchaseWaybillFetchingOne(true));

      const { data } = await api.purchaseWaybills.fetchPurchaseWaybillFromAPI(id);

      if (!data.items) {
        data.items = [];
      }

      dispatch(setPurchaseWaybill(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'purchaseWaybills',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('purchase-waybills'));
      dispatch(setPurchaseWaybillFetchingOne(false));
    }
  },
);

export const addNewItemToPurchaseWaybillDraft = createAsyncThunk<
  void,
  IProductListItem,
  AppThunkConfig
>(`PURCHASES_WAYBILLS_ITEMS/ADD`, async (product, ThunkAPI) => {
  const { dispatch, getState, extra } = ThunkAPI;
  const state = getState() as AppState;

  const entity = getPurchaseWaybillDraft(state);
  const { mainCurrencyCode } = getMainCurrency(state);

  // Create a new item
  const newRow: IPurchaseWaybillItem = {
    itemId: getNegativeUniqueNumericId(),
    product: {
      description: product.description,
      productId: product.productId,
      sku: product.sku,
      unitOfMeasure: product.unitOfMeasure,
    },
    itemDescription: product.description,
    quantity: 1,
    originalUnitCost: product.unitCost,
    unitCost: product.unitCost,
    lot: null,
    parityToMainCurrency: 1,
    currencyCode: mainCurrencyCode,
    costCenter: entity.costCenter ?? null,
  };

  // Add the new item
  dispatch(setPurchaseWaybillDraftValues({ items: [...entity.items, newRow] }));
});

export const startEditingPurchaseWaybill = createAsyncThunk<
  void,
  IStartEditingParams | undefined,
  AppThunkConfig
>('PURCHASES_WAYBILLS_START_EDITING', async (params, ThunkAPI) => {
  const { isNew } = params ?? {};
  const { dispatch } = ThunkAPI;
  dispatch(setPurchaseWaybillIsEditing(true));

  if (isNew) {
    dispatch(addNewPurchaseWaybillsTab());
  } else {
    dispatch(addEditPurchaseWaybillsTab());
  }
});

export const startEditingNewPurchaseWaybill = createAsyncThunk<void, void, AppThunkConfig>(
  'PURCHASES_WAYBILLS_START_EDITING_NEW',
  async (_, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(startEditingPurchaseWaybill({ isNew: true }));
  },
);

export const stopEditingPurchaseWaybill = createAsyncThunk<
  void,
  number | undefined,
  AppThunkConfig
>('PURCHASES_WAYBILLS_STOP_EDITING', async (id, ThunkAPI) => {
  const { dispatch } = ThunkAPI;
  dispatch(setPurchaseWaybillIsEditing(false));
  dispatch(addViewPurchaseWaybillsTab(id));
});
