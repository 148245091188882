import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IDTEPendingsItem } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequestInTab, startRequestInTab } from '../global';

export const DTEPENDINGS_TAB_ID = 'dtePendings';

export const dtePendingsPrefix = 'DTEPENDINGS';

// Simple actions
export const clearDTEPendings = createAction(`${dtePendingsPrefix}/CLEAR`);

export const setDTEPendingList = createAction<IDTEPendingsItem[]>(`${dtePendingsPrefix}/SET_LIST`);

// Complex actions
export const fetchDTEPendingList = createAsyncThunk<void, void, AppThunkConfig>(
  `${dtePendingsPrefix}/FETCH_LIST`,
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequestInTab(DTEPENDINGS_TAB_ID));
      const { data } = await api.dte.fetchDTEPendingListFromAPI();
      dispatch(setDTEPendingList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'DTEPendings',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequestInTab(DTEPENDINGS_TAB_ID));
    }
  },
);
