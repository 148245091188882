import { ColorMode } from '@chakra-ui/react';

import { camelize } from '@laudus/shared-utils';

function getColorPalette(styleSheets: CSSStyleSheet[]) {
  const colorPalette: Record<string, string> = {};
  styleSheets.forEach((sheet) => {
    try {
      if (sheet.href && !sheet.href.startsWith(window.location.origin)) {
        return;
      }
      const cssRules = Array.from(sheet.cssRules);
      cssRules.forEach((rule) => {
        if (rule instanceof CSSStyleRule && rule.selectorText === ':root') {
          Array.from(rule.style).forEach((style) => {
            const value = rule.style.getPropertyValue(style).trim();
            if (
              value.startsWith('#') ||
              value.startsWith('rgb(') ||
              value.startsWith('rgba(') ||
              value.startsWith('hsl(')
            ) {
              colorPalette[style] = value;
            }
          });
        }
      });
    } catch (e) {
      console.warn(e);
    }
  });

  return colorPalette;
}

export interface GetThemVariablesParams {
  colorMode: ColorMode;
  variablePrefix: string;
}

export function getThemeVariables({ colorMode, variablePrefix }: GetThemVariablesParams) {
  const styleSheets = Array.from(document.styleSheets);
  const colorPalette = getColorPalette(styleSheets);

  const colorVariables: Record<string, string> = Object.keys(colorPalette).reduce(
    (acc, key) => ({ ...acc, [camelize(key.replace('--color-', ''))]: colorPalette[key] }),
    {} as Record<string, string>,
  );

  const laudusVariables: Record<string, string> = colorVariables;

  styleSheets.forEach((sheet) => {
    try {
      if (sheet.href && !sheet.href.startsWith(window.location.origin)) {
        return;
      }
      Array.from(sheet.cssRules).forEach((rule) => {
        if (
          rule instanceof CSSStyleRule &&
          (rule.selectorText === ':root' ||
            (colorMode === 'dark' && rule.selectorText === '[data-theme="dark"]'))
        ) {
          Array.from(rule.style).forEach((style) => {
            if (style.startsWith(variablePrefix)) {
              const key = camelize(style.replace(variablePrefix, ''));
              const value = rule.style.getPropertyValue(style).trim();
              if (value.startsWith('var(')) {
                laudusVariables[key] = colorPalette[value.slice(4, -1).trim()] || value;
              } else {
                laudusVariables[key] = value;
              }
            }
          });
        }
      });
    } catch (e) {
      console.warn(e);
    }
  });

  return laudusVariables;
}
