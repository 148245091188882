import { createSelector } from '@reduxjs/toolkit';

import { AppState, IAccountTypesState } from '../..';

export const getAccountTypesSlice = (state: AppState): IAccountTypesState => state.accountTypes;

export const getAccountTypesList = createSelector([getAccountTypesSlice], (state) => state.list);

export const getAccountType = createSelector([getAccountTypesSlice], (state) => state.current);

export const getAccountTypeDraft = createSelector([getAccountTypesSlice], (state) => state.draft);

export const getAccountTypesListDraft = createSelector(
  [getAccountTypesSlice],
  (state) => state.listDraft,
);

export const getAccountTypeFormState = createSelector(
  [getAccountTypesSlice],
  (state) => state.formState,
);
