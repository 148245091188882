import { createSelector } from '@reduxjs/toolkit';

import { AppState, ICcafsState } from '../..';

export const getCcafsSlice = (state: AppState): ICcafsState => state.ccafs;

export const getCcafsList = createSelector([getCcafsSlice], (state) => state.list);

export const getCcaf = createSelector([getCcafsSlice], (state) => state.current);

export const getCcafDraft = createSelector([getCcafsSlice], (state) => state.draft);
