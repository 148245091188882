export const formatNumber = (value: number | string, numberOfDecimals?: number) => {
  const options =
    typeof numberOfDecimals === 'number' && numberOfDecimals >= 0
      ? {
          maximumFractionDigits: numberOfDecimals,
          minimumFractionDigits: numberOfDecimals,
        }
      : {
          maximumSignificantDigits: 21,
        };

  if (typeof value === 'string' && !isNaN(parseFloat(value))) {
    return `${new Intl.NumberFormat('de-DE', options).format(parseFloat(value))}`;
  } else if (typeof value === 'number') {
    return `${new Intl.NumberFormat('de-DE', options).format(value)}`;
  } else {
    return value;
  }
};
