import { createContext, ReactNode, useContext } from 'react';

interface WhiteLabelContextProps {
  whiteLabel: boolean;
}

const WhiteLabelContext = createContext<WhiteLabelContextProps>({ whiteLabel: false });

export const useWhiteLabel = () => useContext(WhiteLabelContext);

interface WhiteLabelProviderProps {
  children: ReactNode;
  whiteLabel?: boolean;
}

export const WhiteLabelProvider = ({ children, whiteLabel = false }: WhiteLabelProviderProps) => {
  return <WhiteLabelContext.Provider value={{ whiteLabel }}>{children}</WhiteLabelContext.Provider>;
};
