import { Context, createContext } from 'react';
import { createDispatchHook, createSelectorHook, ReactReduxContextValue } from 'react-redux';

import { configureStore, Reducer } from '@reduxjs/toolkit';
import { combineReducers, UnknownAction } from 'redux';
import { getPersistConfig } from 'redux-deep-persist';
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  PersistorOptions,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import indexeddbStorage from 'redux-persist-indexeddb-storage';

import { InfraServices, services } from '@laudus/services';

import { migrations } from './migrations';
import { initialGlobalRootReducers } from './reducers';

const globalPersistedReducer = (key: string, reducers: Record<string, Reducer>) =>
  persistReducer(
    getPersistConfig({
      key: 'global',
      storage: indexeddbStorage('globalDB'),
      migrate: createMigrate(migrations, { debug: false }),
      version: 14,
      blacklist: ['alerts.stack', 'auth.formState'],
      rootReducer: combineReducers(reducers),
    }),
    combineReducers(reducers),
  );

export const globalStore = configureStore({
  reducer: globalPersistedReducer('global', initialGlobalRootReducers),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      thunk: { extraArgument: services },
    }),
  devTools: import.meta.env.VITE_ENV !== 'production',
});

let reducers = initialGlobalRootReducers;
export const getGlobalStore = (key = 'global', newReducers: [string, Reducer][]) => {
  reducers = {
    ...reducers,
    ...newReducers.reduce((acc, [key, reducer]) => ({ ...acc, [key]: reducer }), {}),
  };
  globalStore.replaceReducer(globalPersistedReducer(key, reducers));
  globalPersistor.persist();
  return globalStore;
};

export const globalPersistor = persistStore(globalStore, {
  manualPersist: true,
} as PersistorOptions);

export type GlobalStore = typeof globalStore;

export type GlobalState = ReturnType<typeof globalStore.getState>;

export type GlobalDispatch = typeof globalStore.dispatch;

export type GlobalThunkConfig = {
  dispatch: GlobalDispatch;
  state: GlobalState;
  extra: InfraServices;
};

export const globalStoreContext = createContext<ReactReduxContextValue>(
  {} as ReactReduxContextValue,
) as Context<ReactReduxContextValue<any, UnknownAction> | null>;

export const useGlobalDispatch: () => GlobalDispatch = createDispatchHook(globalStoreContext);

export const useGlobalSelector = createSelectorHook(globalStoreContext);
