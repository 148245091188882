import { createReducer } from '@reduxjs/toolkit';

import { IOpportunity, IOpportunityActivity, ISalesQuote } from '@laudus/types';

import { SALES_QUOTE_EMPTY } from '../salesQuotes';

import {
  clearOpportunity,
  clearOpportunityActivityDraft,
  clearOpportunityDraft,
  duplicateOpportunity,
  removeOpportunityFromList,
  setOpportunitiesList,
  setOpportunity,
  setOpportunityActivityDraft,
  setOpportunityActivityDraftValues,
  setOpportunityDraft,
  setOpportunityDraftValues,
  setOpportunityEditing,
  setSelectedQuote,
} from './actions';

export const OPPORTUNITIES_TAB_ID = 'opportunities';

export interface IOpportunitiesState {
  current: IOpportunity;
  draft: IOpportunity;
  list: IOpportunity[];
  activityDraft: IOpportunityActivity;
  selectedQuote: ISalesQuote;
  editing: boolean;
}

export const OPPORTUNITY_EMPTY: IOpportunity = {
  opportunityId: 0,
  customer: {
    customerId: 0,
    name: '',
    legalName: '',
    VATId: '',
    email: '',
  },
  contact: {
    contactId: 0,
    firstName: '',
    lastName: '',
    VATId: '',
    email: '',
  },
  salesman: {
    salesmanId: 0,
    name: '',
  },
  dealer: {
    dealerId: 0,
    name: '',
  },
  description: '',
  notes: '',
  probability: 0,
  stage: {
    code: '',
    description: '',
  },
  date: '',
  closingDate: '',
  originalAmount: 0,
  currencyCode: '',
  parityToMainCurrency: 0,
  amount: 0,
  source: {
    code: '',
    description: '',
  },
  nextAction: '',
  nextActionDate: '',
  createdBy: {},
  createdAt: '',
  modifiedBy: {},
  modifiedAt: '',
  customFields: {
    additionalProp1: '',
    additionalProp2: '',
    additionalProp3: '',
  },
  activities: [],
  quotes: [],
};

const OPPORTUNITY_ACTIVITY_EMPTY: IOpportunityActivity = {
  activityId: 0,
  subject: '',
  description: '',
  issuedDate: '',
  dueDate: '',
  status: '',
  assignedTo: {
    userId: '',
    name: '',
  },
  contact: {
    contactId: 0,
    firstName: '',
    lastName: '',
    VATId: '',
    email: '',
  },
};

export const initialOpportunitiesState: IOpportunitiesState = {
  current: OPPORTUNITY_EMPTY,
  draft: OPPORTUNITY_EMPTY,
  list: [],
  activityDraft: OPPORTUNITY_ACTIVITY_EMPTY,
  selectedQuote: SALES_QUOTE_EMPTY,
  editing: false,
};

export const opportunitiesReducer = createReducer(initialOpportunitiesState, (builder) => {
  builder
    .addCase(clearOpportunity, (state) => {
      return { ...state, current: OPPORTUNITY_EMPTY };
    })
    .addCase(clearOpportunityDraft, (state) => {
      return { ...state, draft: OPPORTUNITY_EMPTY };
    })
    .addCase(setOpportunity, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setOpportunityDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setOpportunityDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setOpportunitiesList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(removeOpportunityFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.opportunityId !== action.payload),
      };
    })
    .addCase(duplicateOpportunity, (state, action) => {
      const { opportunityId, ...duplicatedOpportunity } = action.payload;
      return { ...state, draft: duplicatedOpportunity };
    })
    .addCase(setOpportunityActivityDraft, (state, action) => {
      state.activityDraft = action.payload;
    })
    .addCase(setOpportunityActivityDraftValues, (state, action) => {
      state.draft.activities = state.draft.activities.map((activity) =>
        activity.activityId === action.payload.activityId
          ? { ...activity, ...action.payload }
          : activity,
      );
      state.activityDraft = action.payload as IOpportunityActivity;
    })
    .addCase(clearOpportunityActivityDraft, (state) => {
      state.activityDraft = OPPORTUNITY_ACTIVITY_EMPTY;
    })
    .addCase(setSelectedQuote, (state, action) => {
      return { ...state, selectedQuote: action.payload };
    })
    .addCase(setOpportunityEditing, (state, action) => {
      return { ...state, editing: action.payload };
    })
    .addDefaultCase((state) => state);
});
