import { createReducer } from '@reduxjs/toolkit';

import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import { ISalesQuote, ISalesQuoteGroup, ISalesQuoteItem, ISalesQuoteList } from '@laudus/types';

import {
  clearSalesQuote,
  clearSalesQuoteDraft,
  clearSalesQuotePDFUrl,
  duplicateSalesQuote,
  removeSalesQuoteFromList,
  setSalesQuote,
  setSalesQuoteDraft,
  setSalesQuoteDraftValues,
  setSalesQuoteList,
  setSalesQuotePDFUrl,
  updateSalesQuoteList,
} from './actions';

export interface ISalesQuotesState {
  current: ISalesQuote;
  draft: ISalesQuote;
  list: ISalesQuoteList;
  pdfURL: string | undefined;
}

export const SALES_QUOTE_EMPTY: ISalesQuote = {
  items: [],
  docType: null,
  customer: null,
  contact: null,
  salesman: null,
  dealer: null,
  carrier: null,
  priceList: null,
  term: null,
  branch: null,
  warehouse: null,
  issuedDate: undefined,
  dueDate: undefined,
  nullDoc: false,
  deliveryAddress: null,
  deliveryCost: 0,
  deliveryNotes: null,
  deliveryVehiclePlate: null,
  bypassCreditLimit: false,
  notes: null,
  salesQuoteId: undefined,
  opportunityId: 0,
  approved: false,
  approvedBy: '',
  deliveryNumberOfDays: 0,
  daysToExpiration: 0,
  header: '',
  footer: '',
  groups: [],
};

export const initialSalesQuotesState: ISalesQuotesState = {
  current: SALES_QUOTE_EMPTY,
  draft: SALES_QUOTE_EMPTY,
  list: [],
  pdfURL: undefined,
};

export const salesQuotesReducer = createReducer(initialSalesQuotesState, (builder) => {
  builder
    .addCase(clearSalesQuote, (state) => {
      return { ...state, current: SALES_QUOTE_EMPTY };
    })
    .addCase(clearSalesQuoteDraft, (state) => {
      return { ...state, draft: SALES_QUOTE_EMPTY };
    })
    .addCase(clearSalesQuotePDFUrl, (state) => {
      return { ...state, pdfURL: undefined };
    })
    .addCase(setSalesQuote, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setSalesQuoteDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setSalesQuoteDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setSalesQuotePDFUrl, (state, action) => {
      return { ...state, pdfURL: action.payload };
    })
    .addCase(setSalesQuoteList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateSalesQuoteList, (state, action) => {
      const { salesQuoteId, customer, nullDoc, issuedDate } = action.payload;

      if (!state.list.some((d) => d.salesQuoteId === salesQuoteId)) {
        return state;
      }

      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.salesQuoteId !== action.payload.salesQuoteId),
          {
            salesQuoteId: salesQuoteId ?? 0,
            issuedDate: issuedDate ?? '',
            nullDoc,
            customer_name: customer?.name ?? '',
          },
        ],
      };
    })
    .addCase(removeSalesQuoteFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.salesQuoteId !== action.payload),
      };
    })
    .addCase(duplicateSalesQuote, (state, action) => {
      const { salesQuoteId, items, groups, ...duplicatedSalesQuote } = action.payload;

      const newItems: ISalesQuoteItem[] = items.map((item) => ({
        ...item,
        itemId: getNegativeUniqueNumericId(),
        traceFrom: null,
      }));
      const newGroups: ISalesQuoteGroup[] =
        groups?.map((group) => ({
          ...group,
          groupId: getNegativeUniqueNumericId(),
        })) ?? [];

      const newSalesQuote: ISalesQuote = {
        ...duplicatedSalesQuote,
        items: newItems,
        groups: newGroups,
      };

      return { ...state, draft: newSalesQuote };
    })
    .addDefaultCase((state) => state);
});
