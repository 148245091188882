import { createSelector } from '@reduxjs/toolkit';

import { AppState, IAFPsState } from '../..';

export const getAFPsSlice = (state: AppState): IAFPsState => state.afps;

export const getAFPsList = createSelector([getAFPsSlice], (state) => state.list);

export const getAFP = createSelector([getAFPsSlice], (state) => state.current);

export const getAFPDraft = createSelector([getAFPsSlice], (state) => state.draft);
