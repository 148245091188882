import { createReducer } from '@reduxjs/toolkit';

import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import { IRecipe } from '@laudus/types';

import {
  clearRecipe,
  clearRecipeDraft,
  duplicateRecipe,
  removeRecipeFromList,
  setRecipe,
  setRecipeDraft,
  setRecipeDraftValues,
  setRecipesList,
  updateRecipesList,
} from './actions';

export const RECIPES_TAB_ID = 'recipes';

export interface IRecipesState {
  current: IRecipe;
  draft: IRecipe;
  list: IRecipe[];
}

export const RECIPE_EMPTY: IRecipe = {
  recipeId: 0,
  name: '',
  description: '',
  discontinued: false,
  notes: '',
  items: [],
  createdAt: '',
  createdBy: {},
  modifiedAt: '',
  modifiedBy: {},
};

export const initialRecipesState: IRecipesState = {
  current: RECIPE_EMPTY,
  draft: RECIPE_EMPTY,
  list: [],
};

export const recipesReducer = createReducer(initialRecipesState, (builder) => {
  builder
    .addCase(clearRecipe, (state) => {
      return { ...state, current: RECIPE_EMPTY };
    })
    .addCase(clearRecipeDraft, (state) => {
      return { ...state, draft: RECIPE_EMPTY };
    })
    .addCase(setRecipe, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setRecipeDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setRecipeDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setRecipesList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateRecipesList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.recipeId !== action.payload.recipeId), action.payload],
      };
    })
    .addCase(removeRecipeFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.recipeId !== action.payload),
      };
    })
    .addCase(duplicateRecipe, (state, action) => {
      const { recipeId, ...duplicatedRecipes } = action.payload;
      const duplicatedItems = [...duplicatedRecipes.items].map((item) => {
        return { ...item, itemId: getNegativeUniqueNumericId() };
      });
      return {
        ...state,
        draft: { ...duplicatedRecipes, items: [...duplicatedItems] },
      };
    })
    .addDefaultCase((state) => state);
});
