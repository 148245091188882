import { createSelector } from '@reduxjs/toolkit';

import { AppState, IExCajasState } from '../..';

export const getExCajasSlice = (state: AppState): IExCajasState => state.exCajas;

export const getExCajasList = createSelector([getExCajasSlice], (state) => state.list);

export const getExCaja = createSelector([getExCajasSlice], (state) => state.current);

export const getExCajaDraft = createSelector([getExCajasSlice], (state) => state.draft);
