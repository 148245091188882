import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IHoliday } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const HOLIDAYS_TAB_ID = 'holidays';

export const addHomeHolidaysTab = () =>
  addTab({
    tab: {
      id: HOLIDAYS_TAB_ID,
      title: intl.formatMessage({ id: 'holidays.tabTitle' }),
      path: 'pages/Holidays/Holidays',
      isRemovable: true,
    },
  });

export const addNewHolidaysTab = () =>
  addTab({
    tab: {
      id: HOLIDAYS_TAB_ID,
      title: intl.formatMessage({ id: 'holidays.tabTitle' }),
      path: 'pages/Holidays/HolidaysNew',
      isRemovable: true,
    },
  });

export const addEditHolidaysTab = () =>
  addTab({
    tab: {
      id: HOLIDAYS_TAB_ID,
      title: intl.formatMessage({ id: 'holidays.tabTitle' }),
      path: 'pages/Holidays/HolidaysEdit',
      isRemovable: true,
    },
  });

export const addViewHolidaysTab = (id?: number) =>
  addTab({
    tab: {
      id: HOLIDAYS_TAB_ID,
      title: intl.formatMessage({ id: 'holidays.tabTitle' }),
      path: 'pages/Holidays/HolidaysView',
      props: { id },
      isRemovable: true,
    },
  });

// Simple actions
export const clearHoliday = createAction('HOLIDAYS/CLEAR');

export const setHoliday = createAction<IHoliday>('HOLIDAYS/SET');

export const setHolidaysList = createAction<IHoliday[]>('HOLIDAYS/SET_LIST');

export const updateHolidaysList = createAction<IHoliday>('HOLIDAYS/UPDATE_LIST');

export const removeHolidayFromList = createAction<number>('HOLIDAYS/REMOVE_FROM_LIST');

export const duplicateHoliday = createAction<IHoliday>('HOLIDAYS/DUPLICATE');

export const clearHolidayDraft = createAction('HOLIDAYS/CLEAR_DRAFT');

export const setHolidayDraft = createAction<IHoliday>('HOLIDAYS/SET_DRAFT');

export const setHolidayDraftValues = createAction<Partial<IHoliday>>('HOLIDAYS/SET_DRAFT_VALUE');

// Complex actions
export const fetchHolidaysList = createAsyncThunk<void, void, AppThunkConfig>(
  'HOLIDAYS/FETCH_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('holidays'));

      const { data } = await api.holidays.fetchHolidaysListFromAPI();
      dispatch(setHolidaysList(Array.isArray(data) ? data : []));
    } catch (error) {
      // todo
    } finally {
      dispatch(endRequest('holidays'));
    }
  },
);

export const fetchHoliday = createAsyncThunk<void, number, AppThunkConfig>(
  'HOLIDAYS/FETCH_HOLIDAY',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('holidays'));

    try {
      const { data } = await api.holidays.fetchHolidayFromAPI(id);
      dispatch(setHoliday(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'holidays',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('holidays'));
    }
  },
);
