import { ComponentType } from 'react';

import { LicenseManager } from '@ag-grid-enterprise/core';

const licenseKey = import.meta.env.VITE_AG_GRID_LICENSE_KEY;

export interface SetLicenseKeyProps {
  component: ComponentType<any>;
}

function SetLicenseKey({ component: Component, ...props }: SetLicenseKeyProps) {
  LicenseManager.setLicenseKey(licenseKey);

  return <Component {...props} />;
}

export function withEnterpriseLicense(component: ComponentType<any>) {
  return function registerModules(props: any) {
    return <SetLicenseKey component={component} {...props} />;
  };
}
