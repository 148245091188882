import { createReducer } from '@reduxjs/toolkit';

import { IGlobalAccount } from '@laudus/types';

import {
  clearGlobalAccount,
  clearGlobalAccountDraft,
  setGlobalAccount,
  setGlobalAccountDraft,
  setGlobalAccountDraftValues,
} from './actions';

export interface IGlobalAccountsState {
  current: IGlobalAccount;
  draft: IGlobalAccount;
}

export const GLOBAL_ACCOUNT_EMPTY: IGlobalAccount = {
  glAccountId: 0,
  name: '',
  VATId: '',
  createdAt: '',
};

export const initialGlobalAccountsState: IGlobalAccountsState = {
  current: GLOBAL_ACCOUNT_EMPTY,
  draft: GLOBAL_ACCOUNT_EMPTY,
};

export const globalAccountsReducer = createReducer(initialGlobalAccountsState, (builder) => {
  builder
    .addCase(clearGlobalAccount, (state) => {
      return { ...state, current: GLOBAL_ACCOUNT_EMPTY };
    })
    .addCase(setGlobalAccount, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(clearGlobalAccountDraft, (state) => {
      return { ...state, draft: GLOBAL_ACCOUNT_EMPTY };
    })
    .addCase(setGlobalAccountDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setGlobalAccountDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addDefaultCase((state) => state);
});
