import { createReducer } from '@reduxjs/toolkit';

import { IDealer } from '@laudus/types';

import {
  clearDealers,
  clearDealersDraft,
  duplicateDealer,
  removeDealerFromList,
  setDealer,
  setDealerDraft,
  setDealerDraftValues,
  setDealersList,
  updateDealersList,
} from './actions';

export const DEALERS_TAB_ID = 'dealers';
export interface IDealersState {
  current: IDealer;
  draft: IDealer;
  list: IDealer[];
}

export const DEALERS_EMPTY: IDealer = {
  dealerId: 0,
  name: undefined,
  legalName: undefined,
};

export const initialDealersState: IDealersState = {
  current: DEALERS_EMPTY,
  draft: DEALERS_EMPTY,
  list: [],
};

export const dealersReducer = createReducer(initialDealersState, (builder) => {
  builder
    .addCase(clearDealers, (state) => {
      return { ...state, current: DEALERS_EMPTY };
    })
    .addCase(clearDealersDraft, (state) => {
      return { ...state, draft: DEALERS_EMPTY };
    })
    .addCase(setDealer, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setDealerDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setDealerDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setDealersList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateDealersList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((d) => d.dealerId !== action.payload.dealerId), action.payload],
      };
    })
    .addCase(removeDealerFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.dealerId !== action.payload),
      };
    })
    .addCase(duplicateDealer, (state, action) => {
      const { dealerId, ...duplicatedDealer } = action.payload;
      return { ...state, draft: duplicatedDealer };
    })
    .addDefaultCase((state) => state);
});
