import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { updateDraftSalesItems } from '@laudus/sales-utils';
import { ISalesQuote, ISalesQuoteItem, ISalesQuoteList } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { getMainCurrency } from '../currencies';
import { getDocTypeList } from '../docTypes';
import { endRequest, startRequest } from '../global';
import { getSettingsByName } from '../settings';
import { addTab } from '../tabs';

import { getSalesQuoteDraft } from './selectors';

export const SALES_QUOTES_TAB_ID = 'salesQuotes';

export const addHomeSalesQuoteTab = () =>
  addTab({
    tab: {
      id: SALES_QUOTES_TAB_ID,
      title: intl.formatMessage({ id: 'salesQuotes.tabTitle' }),
      path: 'pages/SalesQuotes/SalesQuotes',
      isRemovable: true,
    },
  });

export const addViewSalesQuoteTab = (id?: number) =>
  addTab({
    tab: {
      id: SALES_QUOTES_TAB_ID,
      title: intl.formatMessage({ id: 'salesQuotes.tabTitle' }),
      path: 'pages/SalesQuotes/SalesQuoteView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewSalesQuoteTab = () =>
  addTab({
    tab: {
      id: SALES_QUOTES_TAB_ID,
      title: intl.formatMessage({ id: 'salesQuotes.tabTitle' }),
      path: 'pages/SalesQuotes/SalesQuoteNew',
      isRemovable: true,
    },
  });

export const addEditSalesQuoteTab = () =>
  addTab({
    tab: {
      id: SALES_QUOTES_TAB_ID,
      title: intl.formatMessage({ id: 'salesQuotes.tabTitle' }),
      path: 'pages/SalesQuotes/SalesQuoteEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearSalesQuote = createAction('SALES_QUOTES/CLEAR');
export const clearSalesQuoteDraft = createAction('SALES_QUOTES/CLEAR_DRAFT');
export const clearSalesQuotePDFUrl = createAction('SALES_QUOTES/CLEAR_PDF_URL');

export const setSalesQuote = createAction<ISalesQuote>('SALES_QUOTES/SET');
export const setSalesQuoteDraft = createAction<ISalesQuote>('SALES_QUOTES/SET_DRAFT');
export const setSalesQuoteDraftValues = createAction<Partial<ISalesQuote>>(
  'SALES_QUOTES/SET_DRAFT_VALUES',
);
export const setSalesQuotePDFUrl = createAction<string>('SALES_QUOTES/SET_PDF_URL');

export const setSalesQuoteList = createAction<ISalesQuoteList>('SALES_QUOTES/SET_LIST');
export const updateSalesQuoteList = createAction<ISalesQuote>('SALES_QUOTES/UPDATE_LIST');
export const removeSalesQuoteFromList = createAction<number>('SALES_QUOTES/REMOVE_FROM_LIST');

export const duplicateSalesQuote = createAction<ISalesQuote>('SALES_QUOTES/DUPLICATE');

// Complex actions
export const fetchSalesQuotesList = createAsyncThunk<void, void, AppThunkConfig>(
  'SALES_QUOTES/FETCH_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('sales-quotes'));
    try {
      const { data } = await api.salesQuotes.fetchSalesQuotesListFromAPI();
      const salesQuotesList = Array.isArray(data) ? data : [];
      const nonNullSalesQuotes = salesQuotesList.filter(
        (salesQuote) => salesQuote.nullDoc !== true,
      );
      dispatch(setSalesQuoteList(nonNullSalesQuotes));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesQuotes',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('sales-quotes'));
    }
  },
);

export const fetchSalesQuote = createAsyncThunk<void, number, AppThunkConfig>(
  'SALES_QUOTES/FETCH',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('sales-quotes'));
    try {
      const { data } = await api.salesQuotes.fetchSalesQuoteFromAPI(id);
      dispatch(setSalesQuote(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesQuotes',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('sales-quotes'));
    }
  },
);

export const updateSalesQuoteDraftItem = createAsyncThunk<void, ISalesQuoteItem, AppThunkConfig>(
  'SALES_ORDERS/UPDATE_SALES_QUOTE_DRAFT_ITEM',
  async (updatedItem, ThunkAPI) => {
    const { dispatch, getState, extra } = ThunkAPI;
    const { api } = extra;

    const { mainCurrencyDecimals } = getMainCurrency(getState());

    const draft = getSalesQuoteDraft(getState());
    const updateDraftItems = await updateDraftSalesItems(
      updatedItem,
      draft,
      'quotes',
      mainCurrencyDecimals,
      api.products.fetchProductSalesPriceAPI,
    );

    dispatch(setSalesQuoteDraftValues({ items: updateDraftItems }));
  },
);

export const createSalesQuoteAndPrint = createAsyncThunk<void, ISalesQuote, AppThunkConfig>(
  'SALES_QUOTES/CREATE_AND_PRINT',
  async (salesQuote, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;
    const getState = ThunkAPI.getState;

    const docTypes = getDocTypeList(getState());

    const isDocTypeIdInFamily = (docTypeId: number | null | undefined, subFamily: string) => {
      return !!docTypes.find(
        (docType) => docType.docTypeId === docTypeId && docType.subFamily === subFamily,
      );
    };

    dispatch(startRequest('sales-quotes'));

    try {
      // Create sales quote
      const { data: newSalesQuote } = await api.salesQuotes.createSalesQuoteFromAPI(salesQuote);
      dispatch(setSalesQuote(newSalesQuote));
      dispatch(updateSalesQuoteList(newSalesQuote));

      // Fetch sales quote PDF and store its url
      const numberOfCopies =
        isDocTypeIdInFamily(newSalesQuote.docType?.docTypeId, 'T') ||
        isDocTypeIdInFamily(newSalesQuote.docType?.docTypeId, 'C') ||
        isDocTypeIdInFamily(newSalesQuote.docType?.docTypeId, 'D')
          ? 1
          : 2;

      const pdfURL = await api.salesQuotes.fetchSalesQuotePDFUrl({
        salesQuote: newSalesQuote,
        numberOfCopies,
      });
      dispatch(setSalesQuotePDFUrl(pdfURL));

      // Redirect to view sales quote
      dispatch(addViewSalesQuoteTab());
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesQuotes',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('sales-quotes'));
    }
  },
);

export const updateSalesQuoteAndPrint = createAsyncThunk<void, ISalesQuote, AppThunkConfig>(
  'SALES_QUOTES/UPDATE_AND_PRINT',
  async (salesQuote, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;
    const getState = ThunkAPI.getState;

    const docTypes = getDocTypeList(getState());

    const isDocTypeIdInFamily = (docTypeId: number | null | undefined, subFamily: string) => {
      return !!docTypes.find(
        (docType) => docType.docTypeId === docTypeId && docType.subFamily === subFamily,
      );
    };

    dispatch(startRequest('sales-quotes'));

    try {
      // Update sales quote
      const { data: updatedSalesQuote } = await api.salesQuotes.updateSalesQuoteFromAPI(salesQuote);
      dispatch(setSalesQuote(updatedSalesQuote));
      dispatch(updateSalesQuoteList(updatedSalesQuote));

      // Fetch sales quote PDF and store its url
      const numberOfCopies =
        isDocTypeIdInFamily(updatedSalesQuote.docType?.docTypeId, 'T') ||
        isDocTypeIdInFamily(updatedSalesQuote.docType?.docTypeId, 'C') ||
        isDocTypeIdInFamily(updatedSalesQuote.docType?.docTypeId, 'D')
          ? 1
          : 2;

      const pdfURL = await api.salesQuotes.fetchSalesQuotePDFUrl({
        salesQuote: updatedSalesQuote,
        numberOfCopies,
      });
      dispatch(setSalesQuotePDFUrl(pdfURL));

      // Redirect to view sales quote
      dispatch(addViewSalesQuoteTab());
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesQuotes',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('sales-quotes'));
    }
  },
);

export const newSalesQuoteDraft = createAsyncThunk<void, void, AppThunkConfig>(
  'SALES_QUOTES/SET_SALESQUOTE_DRAFT_INITIAL_VALUES',
  async (_, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;
    const state = getState();

    const header = getSettingsByName<string>('sales_quotes_header')(state);
    const footer = getSettingsByName<string>('sales_quotes_footer')(state);
    const daysToExpiration = getSettingsByName<number>('sales_quotes_terms')(state);

    const initialValue: ISalesQuote = {
      approved: false,
      approvedBy: '',
      bypassCreditLimit: false,
      carrier: null,
      contact: null,
      customer: null,
      daysToExpiration: daysToExpiration,
      dealer: null,
      deliveryAddress: null,
      deliveryCost: 0,
      deliveryNotes: null,
      deliveryNumberOfDays: 0,
      deliveryVehiclePlate: null,
      docType: null,
      footer: footer,
      groups: null,
      header: header,
      items: [],
      notes: null,
      nullDoc: false,
      opportunityId: 0,
      priceList: null,
      salesman: null,
      term: null,
      warehouse: null,
    };

    dispatch(setSalesQuoteDraft(initialValue));
  },
);
