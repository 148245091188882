import { createSelector } from '@reduxjs/toolkit';

import { translateWarehouseIdToWarehouseNameFromArray } from '@laudus/shared-utils';
import { IProductListGridItem, IProductListItem } from '@laudus/types';

import { AppState } from '../../store';
import { getBranchProductsWithPriceOverride } from '../branches';
import { getMainCurrency } from '../currencies';
import { getCustomerProductsWithPriceOverride } from '../customers';
import { getCustomFieldsByEntity } from '../customFields';
import { getSettingsByName, getSettingsByNameInDraftList } from '../settings';
import { getWarehouseList } from '../warehouses';

import { PRODUCT_EMPTY } from './constants';
import { IProductsState } from './reducer';
import {
  doesProductMatchCanBeSoldOrPurchasedFilter,
  doesProductMatchDiscontinuedFilter,
  getMergedProductsWithPriceOverride,
} from './utils';

export const getProductsSlice = (state: AppState): IProductsState => state.products;

export const getProductsList = createSelector([getProductsSlice], (state) => state.list);

export const getProductsListPriceOverrides = createSelector(
  [getProductsList, getBranchProductsWithPriceOverride, getCustomerProductsWithPriceOverride],
  (productList, branchProductsPriceOverride, customerProductsPriceOverride) => {
    const priceMap = getMergedProductsWithPriceOverride(
      branchProductsPriceOverride,
      customerProductsPriceOverride,
    );

    return productList.map((item) => {
      const priceListItem = priceMap.get(item.productId);
      if (priceListItem) {
        return {
          ...item,
          unitPrice: priceListItem.unitPrice ?? 0,
          unitPriceWithTaxes: priceListItem.unitPriceWithTaxes ?? 0,
        };
      }
      return item;
    });
  },
);

export const getProduct = createSelector([getProductsSlice], (state) => state.current);

export const getProductDraft = createSelector([getProductsSlice], (state) => state.draft);

export const getProductIsEditing = createSelector([getProductsSlice], (state) => state.editing);

export const getProductsImportSucceeded = createSelector(
  [getProductsSlice],
  (state) => state.importSucceeded,
);

export const getProductsImportList = createSelector(
  [getProductsSlice],
  (state) => state.importList,
);

export const getProductStockList = createSelector([getProductsSlice], (state) => state.stockList);

export const getProductStock = (productId: number) =>
  createSelector([getProductStockList], (stockList) =>
    stockList.find((productStock) => productStock.productId === productId),
  );

export const getProductStockWithWarehousesName = (productId: number) =>
  createSelector([getProductStockList, getWarehouseList], (stockList, warehouseList) =>
    translateWarehouseIdToWarehouseNameFromArray(
      stockList.find((productStock) => productStock.productId === productId),
      warehouseList,
    ),
  );

export type ProductCanBeSoldOrCanBePurchased = 'both' | 'canBeSold' | 'canBePurchased';

export const getProductsListForProductSearchGrid = ({
  showDiscontinued,
  showCanBeSoldOrCanBePurchased,
}: {
  showDiscontinued: boolean;
  showCanBeSoldOrCanBePurchased: ProductCanBeSoldOrCanBePurchased;
}) =>
  createSelector([getProductsListPriceOverrides], (list) => {
    return list.reduce((acc, product) => {
      const matchDiscontinued = doesProductMatchDiscontinuedFilter({
        product,
        showDiscontinued,
      });
      const matchCanBeSoldOrPurchased = doesProductMatchCanBeSoldOrPurchasedFilter({
        product,
        showCanBeSoldOrCanBePurchased,
      });

      if (matchDiscontinued && matchCanBeSoldOrPurchased) {
        acc.push(product);
      }
      return acc;
    }, [] as IProductListItem[]);
  });

export const getProductsBestSellingList = createSelector(
  [getProductsSlice],
  (state) => state.productsBestSellingList,
);

export const getProductsGridList = createSelector(
  [getProductsSlice],
  (state) => state.productsGridList,
);

export const getProductsGridListPriceOverrides = createSelector(
  [getProductsGridList, getBranchProductsWithPriceOverride, getCustomerProductsWithPriceOverride],
  (productsGridList, branchPriceList, customerPriceList): IProductListGridItem[] => {
    const priceMap = getMergedProductsWithPriceOverride(branchPriceList, customerPriceList);

    return productsGridList.map((item) => {
      const priceListItem = priceMap.get(item.productId);
      if (priceListItem) {
        return {
          ...item,
          unitPrice: priceListItem.unitPrice ?? 0,
          unitPriceWithTaxes: priceListItem.unitPriceWithTaxes ?? 0,
        };
      }
      return item;
    });
  },
);

export const getProductByBarcode = (barcode: string) =>
  createSelector([getProductsListPriceOverrides], (list) =>
    list.find((item) => item.barCode === barcode || item.sku === barcode),
  );

export const getProductById = (productId: number) =>
  createSelector([getProductsListPriceOverrides], (list) =>
    list.find((p) => p.productId === productId),
  );

export const getProductWithPriceOverridesById = (productId: number) => {
  return createSelector([getProductsListPriceOverrides], (list) =>
    list.find((p) => p.productId === productId),
  );
};

export const getProductsAdvancedSearchFilters = createSelector(
  [getProductsSlice],
  (state) => state.advancedSearchFilters,
);

export const getProductsListForAdvancedSearch = createSelector(
  [getProductsListPriceOverrides, getProductsAdvancedSearchFilters],
  (list, filters) => {
    return list.reduce((acc, product) => {
      if (filters.showDiscontinued) {
        // Show both discontinued and non-discontinued products
        acc.push(product);
      }

      // Show only non-discontinued products
      if (product.discontinued === false) {
        acc.push(product);
      }

      return acc;
    }, [] as IProductListItem[]);
  },
);

export const getSearchProductsListBySearchPhrase = (searchPhrase: string | undefined) =>
  createSelector([getProductsGridListPriceOverrides], (list) => {
    if (!searchPhrase || searchPhrase.length < 3) {
      return [];
    }

    return list.reduce((acc, product) => {
      if (
        product.description
          ?.toLocaleLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            searchPhrase
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, ''),
          ) ||
        product.sku?.toLocaleLowerCase().includes(searchPhrase.toLocaleLowerCase())
      ) {
        acc.push(product);
      }
      return acc;
    }, [] as IProductListGridItem[]);
  });

export const getProductsDraftList = createSelector([getProductsSlice], (state) => state.draftList);

export const getUpdatedProductsList = createSelector(
  [getProductsGridList, getProductsDraftList],
  (list, draftList) => {
    return list.map((product) => {
      const draftProduct = draftList.find(
        (draftProduct) => draftProduct.productId === product.productId,
      );
      if (draftProduct) {
        return { ...product, ...draftProduct };
      }
      return product;
    });
  },
);

export const getUpdatedProductsListBySearchPhrase = (searchPhrase: string) =>
  createSelector([getUpdatedProductsList], (list) => {
    return list.reduce((acc, product) => {
      if (
        product.description
          ?.toLocaleLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(
            searchPhrase
              .toLowerCase()
              .normalize('NFD')
              .replace(/[\u0300-\u036f]/g, ''),
          ) ||
        product.sku?.toLocaleLowerCase().includes(searchPhrase.toLocaleLowerCase())
      ) {
        acc.push(product);
      }
      return acc;
    }, [] as IProductListGridItem[]);
  });

export const getRoundingProductValue = createSelector(
  [
    getProductsList,
    getSettingsByNameInDraftList<string>('roundingProductId'),
    getSettingsByName<string>('roundingProductId'),
  ],
  (productsList, draftIdRoundingProduct, currentIdRoundingProduct) => {
    const roundingProductIdValue = Number(draftIdRoundingProduct ?? currentIdRoundingProduct);
    return productsList.find((pl) => pl.productId === roundingProductIdValue)?.description;
  },
);

export const getProductSearchGridColumnDefsParams = createSelector(
  [
    getCustomFieldsByEntity('products'),
    getMainCurrency,
    getSettingsByName<number>('decimals_unitPrice_sales'),
  ],
  (customFields, { mainCurrencyDecimals }, decimalsUnitPriceSales) => ({
    customFields,
    mainCurrencyDecimals,
    decimalsUnitPriceSales,
  }),
);

export const getProductEntity = createSelector(
  [getProductDraft, getProduct, getProductIsEditing],
  (draft, current, editing) => {
    return editing ? draft : current;
  },
);

export const getProductSupplierTableData = createSelector([getProductEntity], (entity) => {
  return entity?.suppliers?.map((supplier) => ({
    ...supplier,
    finalCost: supplier.unitCost * (1 - supplier.discount / 100),
  }));
});

export const getNewProductDraft = createSelector(
  [getMainCurrency, getSettingsByName<number>('VATRate')],
  ({ mainCurrency }, VATRate) => {
    return {
      ...PRODUCT_EMPTY,
      incomeCurrencyCode: mainCurrency?.code,
      purchaseCurrencyCode: mainCurrency?.code,
      VATRate,
    };
  },
);
