import { AxiosResponse } from 'axios';

import {
  ILoginParams,
  ILoginResponse,
  IPasswordResetRequestParams,
  IPasswordUpdateParams,
} from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const login = ({
  loginName,
  password,
}: ILoginParams): Promise<AxiosResponse<ILoginResponse>> =>
  axios.post(`${VITE_API_URL}/accounts/security/login`, {
    loginName,
    password,
  });

export interface ILoginWithTokenParams {
  companyVatId: string;
}

export const loginWithToken = ({ companyVatId }: ILoginWithTokenParams): Promise<AxiosResponse> =>
  axios.post(`${VITE_API_URL}/accounts/security/loginWithToken?companyVATId=${companyVatId}`);

export const checkToken = (): Promise<AxiosResponse<void>> =>
  axios.get(`${VITE_API_URL}/security/validateToken`);

// Request Password Reset API call
export const requestPasswordReset = ({
  loginName,
}: IPasswordResetRequestParams): Promise<AxiosResponse> =>
  axios.post(`${VITE_API_URL}/accounts/security/requestPasswordReset/?loginName=${loginName}`);

// Update Password API call
export const updatePassword = ({
  loginName,
  resetToken,
  newPassword,
}: IPasswordUpdateParams): Promise<AxiosResponse> =>
  axios.post(`${VITE_API_URL}/accounts/security/passwordReset/`, {
    loginName,
    resetToken,
    newPassword,
  });
