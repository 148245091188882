import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString, getNegativeUniqueNumericId } from '@laudus/shared-utils';
import { IOutput } from '@laudus/types';

import {
  clearOutput,
  clearOutputDraft,
  duplicateOutput,
  removeOutputFromList,
  setOutput,
  setOutputDraft,
  setOutputDraftValues,
  setOutputsList,
  updateOutputsList,
} from '.';

export const OUTPUTS_TAB_ID = 'output';

export interface IOutputsState {
  current: IOutput;
  draft: IOutput;
  list: IOutput[];
}

export const OUTPUT_EMPTY: IOutput = {
  createdAt: '',
  createdBy: {},
  date: dateToLocalISOString(new Date()),
  items: [],
  modifiedAt: '',
  modifiedBy: {},
  notes: '',
  outputId: 0,
  warehouse: {},
};

export const initialOutputsState: IOutputsState = {
  current: OUTPUT_EMPTY,
  draft: OUTPUT_EMPTY,
  list: [],
};

export const outputsReducer = createReducer(initialOutputsState, (builder) => {
  builder
    .addCase(clearOutput, (state) => {
      return { ...state, current: OUTPUT_EMPTY };
    })
    .addCase(clearOutputDraft, (state) => {
      return { ...state, draft: OUTPUT_EMPTY };
    })
    .addCase(setOutput, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setOutputDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setOutputDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setOutputsList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateOutputsList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.outputId !== action.payload.outputId), action.payload],
      };
    })
    .addCase(removeOutputFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.outputId !== action.payload),
      };
    })
    .addCase(duplicateOutput, (state, action) => {
      const { outputId, ...duplicatedOutput } = action.payload;
      const duplicatedItems = [...duplicatedOutput.items].map((item) => {
        return { ...item, itemId: getNegativeUniqueNumericId() };
      });
      return {
        ...state,
        draft: { ...duplicatedOutput, items: [...duplicatedItems] },
      };
    })
    .addDefaultCase((state) => state);
});
