import { IBranchInfo } from './entities/branches';
import { ICarrierInfo } from './entities/carriers';
import { ICostCenterInfo } from './entities/costCenters';
import { IDeliveryAddressInfo } from './entities/customerAddresses';
import { IContactInfo } from './entities/customerContacts';
import { ICustomerInfo } from './entities/customers';
import { ICustomFields } from './entities/customFields';
import { IDealerInfo } from './entities/dealers';
import { ILotInfo } from './entities/lots';
import { IPriceListInfo } from './entities/priceLists';
import { ISalesmanInfo } from './entities/salesmen';
import { ITermInfo } from './entities/terms';
import { IUserInfo } from './entities/users';
import { IWarehouseInfo } from './entities/warehouses';
import { ObjectValues } from './utilities';

export type SalesDocumentsType = 'quotes' | 'orders' | 'waybills' | 'invoices';

export interface IDocTypeInfo {
  docTypeId: number | null;
  name: string | null;
}

export interface IDTEData {
  trackId: string | null;
  documentStatus: string | null;
  documentStatusTranslated?: string; // COMPUTED PROPERTY
  uploadStatus: string | null;
  sentToCustomerAt?: string;
}

interface ISalesItemProduct {
  productId?: number;
  sku?: string;
  description?: string;
  unitOfMeasure?: string;
  allowFreeDescription?: boolean;
  allowUserChangePrices?: boolean;
  maxDiscount?: number;
  hasVolumeDiscounts?: boolean;
  unitPrice?: number;
  unitPriceWithTaxes?: number;
  applyGeneralVATRate?: boolean;
}

export interface ISalesItem {
  itemId?: number;
  itemOrder?: number;
  product?: ISalesItemProduct;
  itemDescription: string | null;
  quantity: number;
  net?: number; // THIS PROP SHOULD BE ERASE BEFORE SENDING TO BACKEND
  originalUnitPrice: number;
  currencyCode: string | null;
  parityToMainCurrency: number;
  unitPrice: number;
  discountPercentage: number;
  costCenter: ICostCenterInfo | null; //
  lot: ILotInfo | null; //
  customFields?: ICustomFields;
  traceFrom?: ITraceFrom[] | null;
}

export interface ISalesDocument {
  docType: IDocTypeInfo | null;
  docNumber?: number;
  customer: ICustomerInfo | null;
  contact: IContactInfo | null;
  salesman: ISalesmanInfo | null;
  dealer: IDealerInfo | null;
  carrier: ICarrierInfo | null;
  priceList: IPriceListInfo | null;
  term: ITermInfo | null;
  branch?: IBranchInfo | null;
  warehouse: IWarehouseInfo | null;
  issuedDate?: string;
  dueDate?: string;
  nullDoc: boolean;
  purchaseOrderNumber?: string | null;
  deliveryAddress: IDeliveryAddressInfo | null;
  deliveryCost: number;
  deliveryNotes: string | null;
  deliveryVehiclePlate: string | null;
  bypassCreditLimit: boolean;
  notes: string | null;
  DTE?: IDTEData | null;
  createdBy?: IUserInfo | null;
  createdAt?: string | null;
  modifiedBy?: IUserInfo | null;
  modifiedAt?: string | null;
  customFields?: ICustomFields;
  items: ISalesItem[];
}

export const TRACE_FROM_STEP_ORIGIN = {
  QUOTES: 'Q',
  ORDERS: 'O',
  WAYBILLS: 'W',
  INVOICES: 'I',
} as const;

export type TraceFromStepOriginType = ObjectValues<typeof TRACE_FROM_STEP_ORIGIN>;
export interface ITraceFrom {
  traceId?: number;
  fromDocNumber?: number;
  fromId?: number;
  fromStep?: TraceFromStepOriginType;
}

export interface QuantityDeliveredPerItem {
  itemId: number;
  delivered: number;
}

export type QuantityDelivered = QuantityDeliveredPerItem[];

export interface IProductSalesPrice {
  currencyCode: string;
  customerId?: null;
  date: string | null;
  discount: number;
  hasVolumeDiscounts: boolean;
  message: string;
  originalUnitPrice: number;
  parityToMainCurrency: number;
  priceListId: null;
  productId: number;
  quantity: number | null;
  unitPrice: number;
  unitPriceWithTaxes: number;
  VATRate: number;
}
