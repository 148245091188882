import { cssVar, defineStyleConfig } from '@chakra-ui/react';

import { colors } from '@laudus/shared-ui';

// The next code is a workaround to be able to assign
// a color to the arrow of the tooltip inside the theme. See:
// https://github.com/chakra-ui/chakra-ui/issues/4695#issuecomment-991023319
const popperArrowBg = cssVar('popper-arrow-bg');
export const Tooltip = defineStyleConfig({
  baseStyle: {
    color: 'black',
    backgroundColor: colors.neutral200,
    [popperArrowBg.variable]: colors.neutral200,
    paddingX: '2rem',
    paddingY: '0.5rem',
    border: '0.1rem solid #009bae',
    borderRadius: 'lg',
    fontSize: '1.4rem',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '2rem',
  },
});
