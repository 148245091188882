import { intl } from '@laudus/intl';
import {
  ICustomField,
  ICustomFields,
  IProductList,
  ISalesDocument,
  ISalesItem,
  ISalesQuote,
  IUser,
  QuantityDelivered,
} from '@laudus/types';

export function getVATRateForProduct({
  productId,
  productList,
  generalVATRate,
}: {
  productId: number;
  productList: IProductList;
  generalVATRate: number;
}): number {
  const product = productList.find((p) => p.productId === productId);

  if (!product) {
    return generalVATRate;
  }

  const { applyGeneralVATRate, VATRate } = product;

  if (applyGeneralVATRate) {
    return generalVATRate;
  }

  return VATRate;
}

export function getLineWithTheSameProduct({
  salesItem,
  salesItems,
}: {
  salesItem: ISalesItem;
  salesItems: ISalesItem[];
}) {
  const lineWithTheSameProduct = salesItems.find((invoiceItem) => {
    const isProductIdTheSame = invoiceItem.product?.productId === salesItem.product?.productId;
    const isUnitPriceTheSame = invoiceItem.unitPrice === salesItem.unitPrice;
    const isDiscountTheSame = invoiceItem.discountPercentage === salesItem.discountPercentage;
    const isDescriptionTheSame = invoiceItem.itemDescription === salesItem.itemDescription;
    const isLotTheSame = invoiceItem.lot === salesItem.lot;

    return (
      isProductIdTheSame &&
      isUnitPriceTheSame &&
      isDiscountTheSame &&
      isDescriptionTheSame &&
      isLotTheSame
    );
  });

  return lineWithTheSameProduct;
}

export function getQuantityDeliveredOfSalesItem({
  salesItem,
  quantityDelivered,
}: {
  salesItem: ISalesItem;
  quantityDelivered: QuantityDelivered;
}) {
  return quantityDelivered.find((quantity) => quantity.itemId === salesItem.itemId);
}

export function getQuantityToCopyToSalesItem({
  salesItem,
  quantityDelivered,
}: {
  salesItem: ISalesItem;
  quantityDelivered: QuantityDelivered;
}) {
  const itemQuantityDelivered = getQuantityDeliveredOfSalesItem({
    quantityDelivered,
    salesItem,
  });

  const quantityToDecrease = itemQuantityDelivered ? itemQuantityDelivered.delivered : 0;
  return salesItem.quantity - quantityToDecrease;
}

export function copyNotesFromSalesDocumentToAnother({
  originSalesDocument,
  newSalesDocument,
}: {
  originSalesDocument: ISalesDocument;
  newSalesDocument: ISalesDocument;
}) {
  newSalesDocument.notes = originSalesDocument.notes;
}

export function ensureSalesDocumentIsNotSentToSII({
  salesDocument,
}: {
  salesDocument: ISalesDocument;
}) {
  const { DTE } = salesDocument;

  // Cannot modify a sales document that has been sent to SII
  if (DTE?.trackId) {
    throw new Error(intl.formatMessage({ id: 'salesDocument.cannotEditIfSentToSII' }));
  }
}

export function ensureSalesDocumentCustomerMatch({
  firstSalesDocument,
  secondSalesDocument,
}: {
  firstSalesDocument: ISalesDocument;
  secondSalesDocument: ISalesDocument;
}) {
  const { customer: firstCustomer } = firstSalesDocument;
  const { customer: secondCustomer } = secondSalesDocument;

  if (!firstCustomer?.customerId) {
    return;
  }

  if (!secondCustomer?.customerId) {
    return;
  }

  const areCustomersTheSame = firstCustomer.customerId === secondCustomer.customerId;

  if (!areCustomersTheSame) {
    throw new Error('El cliente debe ser el mismo');
  }
}

interface EntityWithCustomFields {
  customFields?: ICustomFields;
}

export function copyCustomFieldsFromOneEntityToAnother<
  IEntityToGetCustomFieldsFrom extends EntityWithCustomFields,
  IEntityToCopyCustomFieldsInto extends EntityWithCustomFields,
>({
  entityToGetCustomFieldsFrom,
  entityToCopyCustomFieldsInto,
  customFieldsForEntityToGetCustomFieldsFrom,
  customFieldsForEntityToCopyCustomFieldsInto,
}: {
  entityToGetCustomFieldsFrom: IEntityToGetCustomFieldsFrom;
  entityToCopyCustomFieldsInto: IEntityToCopyCustomFieldsInto;
  customFieldsForEntityToGetCustomFieldsFrom: ICustomField[];
  customFieldsForEntityToCopyCustomFieldsInto: ICustomField[];
}) {
  const { customFields: entityToGetCustomFieldsFromCustomFields } = entityToGetCustomFieldsFrom;
  const doesEntityToGetCustomFieldsFromHaveAnyCustomFields = Boolean(
    entityToGetCustomFieldsFromCustomFields,
  );

  // Nothing to copy here
  if (!doesEntityToGetCustomFieldsFromHaveAnyCustomFields) {
    return;
  }

  // Ids may be different, so we must check by name
  const customFieldsThatMatch = customFieldsForEntityToGetCustomFieldsFrom.filter(
    (customFieldEntityToGetCustomFieldsFrom) =>
      customFieldsForEntityToCopyCustomFieldsInto.some(
        ({ name }) => customFieldEntityToGetCustomFieldsFrom.name === name,
      ),
  );

  if (customFieldsThatMatch.length === 0) {
    return;
  }

  const customFieldsToCopy = {};

  customFieldsThatMatch.forEach((customField) => {
    const { name } = customField;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (customFieldsToCopy as any)[name] = (entityToGetCustomFieldsFromCustomFields as any)[name];
  });

  entityToCopyCustomFieldsInto.customFields = customFieldsToCopy;
}

export function ensureSalesDocumentIsNotNulled({
  salesDocument,
}: {
  salesDocument: ISalesDocument;
}) {
  const { nullDoc } = salesDocument;

  if (nullDoc) {
    throw new Error(intl.formatMessage({ id: 'sales.document.cannotBeNull' }));
  }
}

export function ensureSalesQuoteIsApproved({
  salesQuote,
  doesSalesQuoteHaveToBeApproved,
  doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts,
}: {
  salesQuote: ISalesQuote;
  doesSalesQuoteHaveToBeApproved: boolean;
  doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts: boolean;
}) {
  const { items, approved } = salesQuote;

  if (!doesSalesQuoteHaveToBeApproved) {
    return true;
  }

  let isApproved = approved;

  if (doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts) {
    const doesSalesQuoteHaveSomeDiscounts = items.some((item) => item.discountPercentage > 0);

    if (!doesSalesQuoteHaveSomeDiscounts) {
      isApproved = true;
    }
  }

  if (!isApproved) {
    throw new Error(intl.formatMessage({ id: 'sales.document.quoteMustBeApproved' }));
  }
}

function doesSalesDocumentMatchTheUserBranchRestriction({
  user,
  salesDocument,
}: {
  user: IUser;
  salesDocument: ISalesDocument;
}): boolean {
  const { restrictToBranch } = user;
  const isUserRestrictedToABranch = Boolean(restrictToBranch);

  if (!isUserRestrictedToABranch) {
    return true;
  }

  const { branch } = salesDocument;
  const doesSalesQuoteHaveTheSameBranchThanUser = restrictToBranch?.branchId === branch?.branchId;

  return doesSalesQuoteHaveTheSameBranchThanUser;
}

export function ensureSalesDocumentMatchTheUserBranchRestriction({
  user,
  salesDocument,
}: {
  user: IUser;
  salesDocument: ISalesDocument;
}) {
  const { userId, restrictToBranch: branchFromUser } = user;
  const { branch: branchFromSalesDocument } = salesDocument;

  if (!doesSalesDocumentMatchTheUserBranchRestriction({ user, salesDocument })) {
    if (branchFromSalesDocument?.branchId) {
      throw new Error(
        `El usuario "${userId}" está restringido a la sucursal "${branchFromUser?.branchId}" pero el documento tiene la sucursal "${branchFromSalesDocument?.branchId}"`,
      );
    }

    throw new Error(
      `El usuario "${userId}" está restringido a la sucursal "${branchFromUser?.branchId}" pero el documento no tiene sucursal`,
    );
  }
}
