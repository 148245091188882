import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IGlobalAccount } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Simple actions
export const clearGlobalAccount = createAction('GLOBAL_ACCOUNTS/CLEAR');

export const setGlobalAccount = createAction<IGlobalAccount>('GLOBAL_ACCOUNTS/SET');

export const clearGlobalAccountDraft = createAction(`GLOBAL_ACCOUNTS/CLEAR_DRAFT`);

export const setGlobalAccountDraft = createAction<IGlobalAccount>('GLOBAL_ACCOUNTS/SET_DRAFT');

export const setGlobalAccountDraftValues = createAction<Partial<IGlobalAccount>>(
  'GLOBAL_ACCOUNTS/SET_DRAFT_VALUE',
);

//Complex actions
export const fetchGlobalAccount = createAsyncThunk<void, number, AppThunkConfig>(
  'GLOBAL_ACCOUNTS/FETCH',
  async (globalAccountId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('global-accounts-read'));
    try {
      const { data } = await api.glAccounts.fetchGlAccountFromAPI(globalAccountId);

      dispatch(setGlobalAccount(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'glAccounts',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('global-accounts-read'));
    }
  },
);

// TODO: Add missing actions when the API will be done
