import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IEtagsState } from './reducer';

export const getEtagsSlice = (state: AppState): IEtagsState => state.etags;

export const getEtagsCurrent = createSelector([getEtagsSlice], (state) => state.current);

export const getEtagsNext = createSelector([getEtagsSlice], (state) => state.next);
