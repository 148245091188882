import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFixedAsset, IFixedAssetListItem } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

export const FIXED_ASSETS_TAB_ID = 'fixedAsssets';

export const addHomeFixedAssetTab = () =>
  addTab({
    tab: {
      id: FIXED_ASSETS_TAB_ID,
      title: intl.formatMessage({ id: 'fixedAssets.tabTitle' }),
      path: 'pages/FixedAssets/FixedAssets',
      isRemovable: true,
    },
  });

export const addViewFixedAssetTab = (id?: number) =>
  addTab({
    tab: {
      id: FIXED_ASSETS_TAB_ID,
      title: intl.formatMessage({ id: 'fixedAssets.tabTitle' }),
      path: 'pages/FixedAssets/FixedAssetView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewFixedAssetTab = () =>
  addTab({
    tab: {
      id: FIXED_ASSETS_TAB_ID,
      title: intl.formatMessage({ id: 'fixedAssets.tabTitle' }),
      path: 'pages/FixedAssets/FixedAssetNew',
      isRemovable: true,
    },
  });

export const addEditFixedAssetTab = () =>
  addTab({
    tab: {
      id: FIXED_ASSETS_TAB_ID,
      title: intl.formatMessage({ id: 'fixedAssets.tabTitle' }),
      path: 'pages/FixedAssets/FixedAssetEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearFixedAsset = createAction(`FIXED_ASSETS/CLEAR`);

export const clearFixedAssetDraft = createAction(`FIXED_ASSETS/CLEAR_DRAFT`);

export const setFixedAsset = createAction<IFixedAsset>(`FIXED_ASSETS/SET`);

export const setFixedAssetDraft = createAction<IFixedAsset>(`FIXED_ASSETS/SET_DRAFT`);

export const setFixedAssetDraftValues = createAction<Partial<IFixedAsset>>(
  `FIXED_ASSETS/SET_DRAFT_VALUES`,
);

export const setFixedAssetList = createAction<IFixedAssetListItem[]>(`FIXED_ASSETS/SET_LIST`);

export const updateFixedAssetList = createAction<IFixedAsset>(`FIXED_ASSETS/UPDATE_LIST`);

export const removeFixedAssetFromList = createAction<number>(`FIXED_ASSETS/REMOVE_FROM_LIST`);

export const duplicateFixedAsset = createAction<IFixedAsset>(`FIXED_ASSETS/DUPLICATE`);

// Complex actions
export const fetchFixedAssetsList = createAsyncThunk<void, void, AppThunkConfig>(
  `FIXED_ASSETS/FETCH_LIST`,
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('fixed-assets'));
    try {
      const { data } = await api.fixedAssets.fetchFixedAssetListFromAPI();
      dispatch(setFixedAssetList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'fixedAssets',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('fixed-assets'));
    }
  },
);

export const fetchFixedAsset = createAsyncThunk<void, number, AppThunkConfig>(
  `FIXED_ASSETS/FETCH`,
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('fixed-assets'));
    try {
      const { data } = await api.fixedAssets.fetchFixedAssetFromAPI(id);
      dispatch(setFixedAsset(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'fixedAssets',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('fixed-assets'));
    }
  },
);

export const calculateDraftFixedAssetsDepreciation = createAsyncThunk<
  void,
  IFixedAsset,
  AppThunkConfig
>(`FIXED_ASSETS/CALCULATE_DRAFT_DEPRECIATION`, async (fixedAsset, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('fixed-assets'));
  try {
    const { data } = await api.fixedAssets.calculateFixedAssetDepreciationFromAPI({
      acquisitionCost: fixedAsset.acquisitionCost,
      dateAcquisition: fixedAsset.dateAcquisition as string, // API will throw error if required param is undefined
      dateRetirement: fixedAsset.dateRetirement as string, // API will throw error if required param is undefined
      taxDeductionPercentage: fixedAsset.taxDeductionPercentage,
      depreciateFromMonthOfAcquisition: fixedAsset.depreciateFromMonthOfAcquisition,
    });

    dispatch(
      setFixedAssetDraftValues({
        depreciation: Array.isArray(data.depreciation) ? data.depreciation : [],
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'fixedAssets',
        action: 'read',
      }),
    );
  } finally {
    dispatch(endRequest('fixed-assets'));
  }
});
