import { createReducer } from '@reduxjs/toolkit';

import { IUnion } from '@laudus/types';

import {
  clearUnion,
  clearUnionDraft,
  removeUnionFromList,
  setUnion,
  setUnionDraft,
  setUnionDraftValues,
  setUnionsList,
  updateUnionsList,
} from '.';

export const UNIONS_TAB_ID = 'unions';

export interface IUnionsState {
  current: IUnion;
  draft: IUnion;
  list: IUnion[];
}

export const UNION_EMPTY: IUnion = {
  unionId: 0,
  name: '',
  VATId: '',
};

export const initialUnionsState: IUnionsState = {
  current: UNION_EMPTY,
  draft: UNION_EMPTY,
  list: [],
};

export const unionsReducer = createReducer(initialUnionsState, (builder) => {
  builder
    .addCase(clearUnion, (state) => {
      return { ...state, current: UNION_EMPTY };
    })
    .addCase(clearUnionDraft, (state) => {
      return { ...state, draft: UNION_EMPTY };
    })
    .addCase(setUnion, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setUnionDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setUnionDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setUnionsList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateUnionsList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.unionId !== action.payload.unionId), action.payload],
      };
    })
    .addCase(removeUnionFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.unionId !== action.payload),
      };
    })
    .addDefaultCase((state) => state);
});
