import axios, { AxiosError } from 'axios';

import { intl } from '@laudus/intl';
import { IAPIError, IAPIErrorBody } from '@laudus/types';

// When the content type of a request is a kind of binary then the
// error.response.data isn't parsed but is an ArrayBuffer son to access
// to error details we need to parse this ArrayBuffer
const getParsedResponseBody = (error: AxiosError<IAPIErrorBody>) => {
  if (error.response?.data instanceof ArrayBuffer) {
    try {
      return {
        ...error.response,
        data: JSON.parse(new TextDecoder().decode(error.response?.data)),
      };
      // eslint-disable-next-line no-empty
    } catch (e) {}
  }
  return error.response;
};

export const getMessageFromError = ({
  error,
  fallbackMessage = '',
}: {
  error: unknown;
  fallbackMessage?: string;
}): string => {
  const isAxiosError = axios.isAxiosError(error);

  if (!isAxiosError) {
    return (error as Error)?.message ?? fallbackMessage;
  }

  const errorFromAPI = error as AxiosError<IAPIErrorBody>;

  const is403APIError = errorFromAPI?.response?.data?.status === 403;
  if (is403APIError) {
    return intl.formatMessage({ id: 'accessDenied' });
  }

  const response = getParsedResponseBody(errorFromAPI);

  return response?.data?.message || response?.statusText || fallbackMessage;
};

export const getTitleFromError = ({
  error,
  fallbackTitle = '',
}: {
  error: unknown;
  fallbackTitle?: string;
}): string => {
  const isAxiosError = axios.isAxiosError(error);

  if (!isAxiosError) {
    return (error as Error)?.name ?? fallbackTitle;
  }

  const response = getParsedResponseBody(error as AxiosError<IAPIErrorBody>);

  return response?.data?.code || response?.status?.toString() || error.name || fallbackTitle;
};

export function getMessageFromSpecialAPIErrorCode(error: IAPIError): string | null {
  const errorMappings: { [status: number]: { [code: string]: string } } = {
    401: {
      T1: intl.formatMessage({ id: 'api.error.alert.T1' }),
      T4: intl.formatMessage({ id: 'api.error.alert.T4' }),
      T5: intl.formatMessage({ id: 'api.error.alert.T5' }),
      T51: intl.formatMessage({ id: 'api.error.alert.T51' }),
      T6: intl.formatMessage({ id: 'api.error.alert.T6' }),
      T7: intl.formatMessage({ id: 'api.error.alert.T7' }),
      TA1: intl.formatMessage({ id: 'api.error.alert.TA1' }),
      TA4: intl.formatMessage({ id: 'api.error.alert.TA4' }),
      TA5: intl.formatMessage({ id: 'api.error.alert.TA5' }),
      TA6: intl.formatMessage({ id: 'api.error.alert.TA6' }),
    },
  };

  const { status, data } = error.response || {};
  const code = data?.code;

  if (!status || !code || !(status in errorMappings)) {
    // Return null if it's not our API error, or if we don't have a mapping for the HTTP status code
    return null;
  }

  // This will contain all special codes inside the HTTP status code
  const errorMapping = errorMappings[status];

  for (const errorCode in errorMapping) {
    if (code === errorCode) {
      // If the special code (like T4) is found inside the error code, return the message in the mapping object
      const textToDisplay = errorMapping[errorCode];

      return textToDisplay;
    }
  }

  // Return null if special code is not found
  return null;
}
