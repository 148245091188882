export interface IAPIErrorBody {
  code?: string;
  message?: string;
  type?: string;
  status?: number;
}

export interface IAPIError {
  response?: {
    status?: number;
    data?: IAPIErrorBody;
  };
}

export const isAPIError = (value: unknown): value is IAPIError =>
  typeof value === 'object' && !!value && 'response' in value;
