import { createReducer } from '@reduxjs/toolkit';

import { IDTEPendingsItem } from '@laudus/types';

import { clearDTEPendings, setDTEPendingList } from './actions';

export interface IDTEPendingsState {
  list: IDTEPendingsItem[];
}

export const initialDTEPendingsState: IDTEPendingsState = {
  list: [],
};

export const DTEPendingsReducer = createReducer(initialDTEPendingsState, (builder) => {
  builder
    .addCase(clearDTEPendings, (state) => {
      return { ...state, list: [] };
    })
    .addCase(setDTEPendingList, (state, action) => {
      return { ...state, list: action.payload };
    });
});
