import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IEmployee } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { fetchEmployeeAbsencesList } from '../employeesAbsences';
import {
  clearEmployeeContractTermination,
  fetchEmployeeContractTerminationsList,
} from '../employeesContractTerminations';
import { fetchEmployeesHistoryList } from '../employeesHistory';
import { fetchEmployeeLoansList } from '../employeesLoans';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const EMPLOYEE_TAB_ID = 'employees';
const employeesPrefix = 'EMPLOYEES';

// Employees Tab action creators
export const addHomeEmployeeTab = () =>
  addTab({
    tab: {
      id: EMPLOYEE_TAB_ID,
      title: intl.formatMessage({ id: 'employees.tabTitle' }),
      path: 'pages/Employees/Employee',
      isRemovable: true,
    },
  });

export const addViewEmployeeTab = (id?: number) =>
  addTab({
    tab: {
      id: EMPLOYEE_TAB_ID,
      title: intl.formatMessage({ id: 'employees.tabTitle' }),
      path: 'pages/Employees/EmployeeView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewEmployeeTab = () =>
  addTab({
    tab: {
      id: EMPLOYEE_TAB_ID,
      title: intl.formatMessage({ id: 'employees.tabTitle' }),
      path: 'pages/Employees/EmployeeNew',
      isRemovable: true,
    },
  });

export const addEditEmployeeTab = () =>
  addTab({
    tab: {
      id: EMPLOYEE_TAB_ID,
      title: intl.formatMessage({ id: 'employees.tabTitle' }),
      path: 'pages/Employees/EmployeeEdit',
      isRemovable: true,
    },
  });

// Employees action creators
export const clearEmployee = createAction(`${employeesPrefix}/CLEAR`);

export const clearEmployeeDraft = createAction(`${employeesPrefix}/CLEAR_DRAFT`);

export const setEmployee = createAction<IEmployee>(`${employeesPrefix}/SET`);

export const setEmployeeDraft = createAction<IEmployee>(`${employeesPrefix}/SET_DRAFT`);

export const setEmployeeDraftValues = createAction<Partial<IEmployee>>(
  `${employeesPrefix}/SET_EMPLOYEE_DRAFT_VALUE`,
);

export const setEmployeesList = createAction<Partial<IEmployee>[]>(`${employeesPrefix}/SET_LIST`);

export const updateEmployeesList = createAction<Partial<IEmployee>>(
  `${employeesPrefix}/UPDATE_LIST`,
);

export const removeEmployeeFromList = createAction<number>(`${employeesPrefix}/REMOVE_FROM_LIST`);

export const duplicateEmployee = createAction<IEmployee>(`${employeesPrefix}/DUPLICATE`);

export const fetchEmployeesList = createAsyncThunk<void, void, AppThunkConfig>(
  `${employeesPrefix}/FETCH_LIST`,
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('employees'));
    try {
      const { data } = await api.employees.fetchEmployeesListFromAPI();

      dispatch(
        setEmployeesList(
          data.map(({ paymentType_code, paymentType_description, ...employeeData }) => ({
            ...employeeData,
            paymentType: {
              code: paymentType_code,
              description: paymentType_description,
            },
          })),
        ),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employees',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('employees'));
    }
  },
);

export const fetchEmployee = createAsyncThunk<void, number, AppThunkConfig>(
  `${employeesPrefix}/FETCH`,
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('employees'));
    try {
      const { data } = await api.employees.fetchEmployeeFromAPI(id);
      dispatch(setEmployee(data));
      dispatch(clearEmployeeContractTermination());

      const employeeId = data.employeeId;

      if (employeeId) {
        dispatch(fetchEmployeeLoansList(employeeId));
        dispatch(fetchEmployeeContractTerminationsList(employeeId));
        dispatch(fetchEmployeesHistoryList(employeeId));
        dispatch(fetchEmployeeAbsencesList(employeeId));
      }
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employees',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('employees'));
    }
  },
);
