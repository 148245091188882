import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  ICustomField,
  IProductList,
  ISalesInvoice,
  ISalesInvoicesItem,
  ISalesWaybill,
  IUser,
  QuantityDelivered,
  TRACE_FROM_STEP_ORIGIN,
} from '@laudus/types';

import { SALES_INVOICE_EMPTY } from '../../constants/salesInvoices';
import { calculateInvoiceItemVat } from '../calculateInvoiceTotals';

import {
  copyCustomFieldsFromOneEntityToAnother,
  copyNotesFromSalesDocumentToAnother,
  ensureSalesDocumentCustomerMatch,
  ensureSalesDocumentIsNotNulled,
  ensureSalesDocumentIsNotSentToSII,
  ensureSalesDocumentMatchTheUserBranchRestriction,
  getLineWithTheSameProduct,
  getQuantityDeliveredOfSalesItem,
  getQuantityToCopyToSalesItem,
  getVATRateForProduct,
} from './genericFunctions';

export async function createSalesInvoiceFromSalesWaybill({
  initialInvoice = { ...SALES_INVOICE_EMPTY, SII_transactionType: '1' },
  salesWaybill,
  user,
  customFieldsForSalesInvoices = [],
  customFieldsForSalesInvoicesItems = [],
  customFieldsForSalesWaybills = [],
  customFieldsForSalesWaybillsItems = [],
  shouldCopyNotesToInvoice = false,
  quantityDeliveredFromWaybill = [],
  groupItemsWithTheSameProduct = false,
  maxItemsInInvoices,
  generalVATRate = 19,
  mainCurrencyDecimals = 0,
  allowDTE = false,
  allowDTETickets = false,
  productList,
}: {
  initialInvoice?: ISalesInvoice;
  salesWaybill: ISalesWaybill;
  user: IUser;
  customFieldsForSalesInvoices?: ICustomField[];
  customFieldsForSalesInvoicesItems?: ICustomField[];
  customFieldsForSalesWaybills?: ICustomField[];
  customFieldsForSalesWaybillsItems?: ICustomField[];
  shouldCopyNotesToInvoice?: boolean;
  quantityDeliveredFromWaybill?: QuantityDelivered;
  groupItemsWithTheSameProduct?: boolean;
  maxItemsInInvoices?: number;
  generalVATRate?: number;
  mainCurrencyDecimals?: number;
  allowDTE?: boolean;
  allowDTETickets?: boolean;
  productList: IProductList;
}): Promise<ISalesInvoice> {
  // Those can throw an error
  ensureSalesDocumentIsNotNulled({ salesDocument: salesWaybill });
  ensureWaybillWasNotAlreadyAddedToInvoice({
    salesInvoice: initialInvoice,
    salesWaybill,
  });
  ensureSalesDocumentIsNotSentToSII({ salesDocument: initialInvoice });
  ensureSalesDocumentMatchTheUserBranchRestriction({
    user,
    salesDocument: salesWaybill,
  });
  ensureSalesDocumentCustomerMatch({
    secondSalesDocument: salesWaybill,
    firstSalesDocument: initialInvoice,
  });

  // Start filling the invoice
  const invoice = { ...initialInvoice };

  // Fill the invoice fields from the sales waybill fields
  copyHeaderFieldsFromWaybillToInvoice({ salesWaybill, invoice });
  copyCustomFieldsFromOneEntityToAnother({
    entityToGetCustomFieldsFrom: salesWaybill,
    customFieldsForEntityToGetCustomFieldsFrom: customFieldsForSalesWaybills,
    entityToCopyCustomFieldsInto: invoice,
    customFieldsForEntityToCopyCustomFieldsInto: customFieldsForSalesInvoices,
  });

  if (shouldCopyNotesToInvoice) {
    copyNotesFromSalesDocumentToAnother({
      originSalesDocument: salesWaybill,
      newSalesDocument: invoice,
    });
  }

  assignDocTypeToInvoice({
    salesWaybill,
    invoice,
    allowDTE,
    allowDTETickets,
  });

  // Fill the invoice items from the sales waybill items
  await copyItemsFromWaybillToInvoice({
    salesWaybill,
    invoice,
    quantityDelivered: quantityDeliveredFromWaybill,
    groupItemsWithTheSameProduct,
    maxItemsInInvoices,
    customFieldsForSalesInvoicesItems,
    customFieldsForSalesWaybillsItems,
    generalVATRate,
    mainCurrencyDecimals,
    productList,
  });

  // Return the filled invoice
  return invoice;
}

function copyHeaderFieldsFromWaybillToInvoice({
  salesWaybill,
  invoice,
}: {
  salesWaybill: ISalesWaybill;
  invoice: ISalesInvoice;
}) {
  const fieldsToCopy: (keyof ISalesWaybill & keyof ISalesInvoice)[] = [
    'customer',
    'contact',
    'warehouse',
    'deliveryAddress',
    'salesman',
    'dealer',
    'term',
    'priceList',
    'purchaseOrderNumber',
    'deliveryCost',
    'deliveryVehiclePlate',
    'deliveryNotes',
    'carrier',
    'bypassCreditLimit',
  ];

  fieldsToCopy.forEach((salesWaybillProperty) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (invoice as any)[salesWaybillProperty] = salesWaybill[salesWaybillProperty];
  });
}

function assignDocTypeToInvoice({
  salesWaybill,
  invoice,
  allowDTE,
  allowDTETickets,
}: {
  salesWaybill: ISalesWaybill;
  invoice: ISalesInvoice;
  allowDTE: boolean;
  allowDTETickets: boolean;
}) {
  // Use salesWaybill doctype, or default to standard invoice
  if (salesWaybill.docType?.docTypeId) {
    invoice.docType = salesWaybill.docType;
  } else {
    invoice.docType = {
      docTypeId: 30,
      name: '',
    };
  }

  // Convert from standard to electronic types if the company is allowed to issue electronic tickets
  if (invoice.docType.docTypeId === 35 && allowDTE && allowDTETickets) {
    invoice.docType.docTypeId = 39;
  }

  if (invoice.docType.docTypeId === 38 && allowDTE && allowDTETickets) {
    invoice.docType.docTypeId = 41;
  }

  if (invoice.docType.docTypeId === 30 && allowDTE) {
    invoice.docType.docTypeId = 33;
  }

  if (invoice.docType.docTypeId === 32 && allowDTE) {
    invoice.docType.docTypeId = 34;
  }
}

async function copyItemsFromWaybillToInvoice({
  salesWaybill,
  invoice,
  quantityDelivered,
  groupItemsWithTheSameProduct,
  maxItemsInInvoices,
  customFieldsForSalesWaybillsItems,
  customFieldsForSalesInvoicesItems,
  generalVATRate,
  mainCurrencyDecimals,
  productList,
}: {
  salesWaybill: ISalesWaybill;
  invoice: ISalesInvoice;
  quantityDelivered: QuantityDelivered;
  groupItemsWithTheSameProduct?: boolean;
  maxItemsInInvoices?: number;
  customFieldsForSalesWaybillsItems: ICustomField[];
  customFieldsForSalesInvoicesItems: ICustomField[];
  generalVATRate: number;
  mainCurrencyDecimals: number;
  productList: IProductList;
}) {
  const salesInvoiceItems: ISalesInvoicesItem[] = [];
  const salesWaybillItems = salesWaybill.items ?? [];

  // Get only salesWaybillItems that haven't been completely delivered
  const salesWaybillItemsThatHaventBeenCompletelyDelivered = salesWaybillItems.filter(
    (salesWaybillItem) => {
      const itemQuantityDelivered = getQuantityDeliveredOfSalesItem({
        quantityDelivered,
        salesItem: salesWaybillItem,
      });

      const hasItemBeenCompletelyDelivered =
        !!itemQuantityDelivered && itemQuantityDelivered.delivered >= salesWaybillItem.quantity;

      return !hasItemBeenCompletelyDelivered;
    },
  );

  // Copy each item
  for (const salesWaybillItem of salesWaybillItemsThatHaventBeenCompletelyDelivered) {
    const quantityToCopy = getQuantityToCopyToSalesItem({
      quantityDelivered,
      salesItem: salesWaybillItem,
    });

    // Lets try to find a line with the same product and add the quantity
    if (groupItemsWithTheSameProduct) {
      const lineWithTheSameProduct = getLineWithTheSameProduct({
        salesItems: salesInvoiceItems,
        salesItem: salesWaybillItem,
      });

      if (lineWithTheSameProduct) {
        lineWithTheSameProduct.quantity += quantityToCopy;
        continue;
      }
    }

    // Get VAT rate
    const productId = salesWaybillItem.product?.productId as number;
    const VATRate = getVATRateForProduct({
      productId,
      generalVATRate,
      productList,
    });

    // Create a new item and fill its information
    const { ...sharedPropertiesBetweenWaybillItemAndInvoiceItem } = salesWaybillItem;

    const newInvoiceItem: ISalesInvoicesItem = {
      ...sharedPropertiesBetweenWaybillItemAndInvoiceItem,
      VATRate,
      quantity: quantityToCopy,
      itemId: getNegativeUniqueNumericId(),
      VAT: 0,
      taxes: [],
      notInvoiceable: false,
      traceFrom: [
        {
          fromId: salesWaybillItem.itemId,
          fromStep: TRACE_FROM_STEP_ORIGIN.WAYBILLS,
        },
      ],
    };

    newInvoiceItem.VAT = calculateInvoiceItemVat(newInvoiceItem, mainCurrencyDecimals);

    copyCustomFieldsFromOneEntityToAnother({
      entityToGetCustomFieldsFrom: salesWaybillItem,
      customFieldsForEntityToGetCustomFieldsFrom: customFieldsForSalesWaybillsItems,
      entityToCopyCustomFieldsInto: newInvoiceItem,
      customFieldsForEntityToCopyCustomFieldsInto: customFieldsForSalesInvoicesItems,
    });

    salesInvoiceItems.push(newInvoiceItem);
  }

  // Add new items
  const newInvoiceItems = salesInvoiceItems.concat(invoice.items ?? []);

  if (maxItemsInInvoices && newInvoiceItems.length > maxItemsInInvoices) {
    throw new Error(
      `La factura contiene más items (${newInvoiceItems.length}) que la cantidad máxima permitida (${maxItemsInInvoices})`,
    );
  }

  invoice.items = newInvoiceItems;
}

function ensureWaybillWasNotAlreadyAddedToInvoice({
  salesInvoice,
  salesWaybill,
}: {
  salesInvoice: ISalesInvoice;
  salesWaybill: ISalesWaybill;
}) {
  const salesWaybillItemsIds = salesWaybill.items.map(
    (salesWaybillItem) => salesWaybillItem.itemId,
  );
  const wasWaybillAlreadyAdded = salesInvoice.items.some((salesInvoiceItem) =>
    salesInvoiceItem.traceFrom?.some(
      (trace) =>
        trace.fromStep === TRACE_FROM_STEP_ORIGIN.WAYBILLS &&
        salesWaybillItemsIds.includes(trace.fromId),
    ),
  );

  if (wasWaybillAlreadyAdded) {
    throw new Error('Una guía de despacho no se puede usar múltiples veces en la misma factura');
  }
}
