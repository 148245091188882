import { createReducer } from '@reduxjs/toolkit';

import {
  addDaysToDate,
  calculateDatesDifference,
  dateToLocalISOString,
  getNegativeUniqueNumericId,
} from '@laudus/shared-utils';
import { ISalesOrder, ISalesOrderItem, ISalesOrderList, ITraceFrom } from '@laudus/types';

import {
  clearSalesOrder,
  clearSalesOrderDraft,
  clearSalesOrderPDFUrl,
  duplicateSalesOrder,
  removeSalesOrderFromList,
  setSalesOrder,
  setSalesOrderDraft,
  setSalesOrderDraftValues,
  setSalesOrderList,
  setSalesOrderPDFUrl,
  setSalesOrderTraces,
  updateSalesOrderList,
} from './actions';

export interface ISalesOrderState {
  current: ISalesOrder;
  draft: ISalesOrder;
  list: ISalesOrderList;
  pdfURL: string | undefined;
  traces: ITraceFrom[];
}

export const SALES_ORDER_EMPTY: ISalesOrder = {
  items: [],
  salesOrderId: undefined,
  docType: null,
  customer: null,
  contact: null,
  salesman: null,
  dealer: null,
  carrier: null,
  priceList: null,
  term: null,
  branch: null,
  warehouse: null,
  issuedDate: undefined,
  dueDate: undefined,
  nullDoc: false,
  deliveryAddress: null,
  deliveryCost: 0,
  deliveryNotes: null,
  deliveryVehiclePlate: null,
  bypassCreditLimit: false,
  notes: null,
  deliveryDate: undefined,
  sourceOrderId: null,
  archived: false,
  locked: false,
  approved: false,
  approvedBy: undefined,
  deliveryTimeFrame: '',
  source: undefined,
  amountPaid: 0,
  amountPaidCurrencyCode: '',
  invoiceDocType: undefined,
};

export const initialSalesOrdersState: ISalesOrderState = {
  current: SALES_ORDER_EMPTY,
  draft: SALES_ORDER_EMPTY,
  list: [],
  pdfURL: '',
  traces: [],
};

export const salesOrdersReducer = createReducer(initialSalesOrdersState, (builder) => {
  builder
    .addCase(clearSalesOrder, (state) => {
      return { ...state, current: SALES_ORDER_EMPTY };
    })
    .addCase(clearSalesOrderDraft, (state) => {
      return { ...state, draft: SALES_ORDER_EMPTY };
    })
    .addCase(clearSalesOrderPDFUrl, (state) => {
      return { ...state, pdfURL: undefined };
    })
    .addCase(setSalesOrder, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setSalesOrderDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setSalesOrderDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setSalesOrderPDFUrl, (state, action) => {
      return { ...state, pdfURL: action.payload };
    })
    .addCase(setSalesOrderList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(setSalesOrderTraces, (state, action) => {
      return { ...state, traces: action.payload };
    })
    .addCase(updateSalesOrderList, (state, action) => {
      const { salesOrderId, customer, issuedDate, deliveryDate, purchaseOrderNumber, nullDoc } =
        action.payload;

      if (!state.list.some((d) => d.salesOrderId === salesOrderId)) {
        return state;
      }

      return {
        ...state,
        list: [
          ...state.list.filter((d) => d.salesOrderId !== action.payload.salesOrderId),
          {
            salesOrderId: salesOrderId ?? 0,
            customer_name: customer?.name ?? '',
            issuedDate: issuedDate ?? '',
            deliveryDate: deliveryDate ?? '',
            purchaseOrderNumber: purchaseOrderNumber ?? '',
            nullDoc,
          },
        ],
      };
    })
    .addCase(removeSalesOrderFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.salesOrderId !== action.payload),
      };
    })
    .addCase(duplicateSalesOrder, (state, action) => {
      const { salesOrderId, items, ...duplicatedSalesOrder } = action.payload;
      const newItems: ISalesOrderItem[] = items.map((item) => ({
        ...item,
        itemId: getNegativeUniqueNumericId(),
        traceFrom: null,
        archived: undefined,
      }));

      const newSalesOrder: ISalesOrder = {
        ...duplicatedSalesOrder,
        items: newItems,
      };

      // issued and due dates
      const datesDifference = calculateDatesDifference(
        newSalesOrder.issuedDate,
        newSalesOrder.dueDate,
      );

      const now = new Date();
      newSalesOrder.issuedDate = dateToLocalISOString(now);
      newSalesOrder.dueDate = dateToLocalISOString(addDaysToDate(now, datesDifference));

      newSalesOrder.purchaseOrderNumber = null;
      newSalesOrder.deliveryDate = undefined;
      newSalesOrder.deliveryTimeFrame = '';
      newSalesOrder.source = undefined;
      newSalesOrder.sourceOrderId = null;
      newSalesOrder.amountPaid = 0;
      newSalesOrder.amountPaidCurrencyCode = '';
      newSalesOrder.createdAt = null;
      newSalesOrder.createdBy = null;
      newSalesOrder.modifiedAt = null;
      newSalesOrder.modifiedBy = null;
      newSalesOrder.approved = false;
      newSalesOrder.approvedBy = undefined;

      return { ...state, draft: { ...newSalesOrder } };
    })
    .addDefaultCase((state) => state);
});
