import { forwardRef } from 'react';

import { Box, Flex, IconButton } from '@chakra-ui/react';
import { MenuItem } from '@szhsin/react-menu';

import { IconRemoveCircle } from '@laudus/icons';

import { RowActionMenu } from '../RowActionMenu';

const DeleteAndCustomActionRowButtonComponent: React.ForwardRefRenderFunction<any, any> = (
  props,
  ref,
) => {
  const handleOnDeleteButtonClick = () => {
    if (props.menuItemsList.hasAParent) {
      const currentIndexData = props.node.data;
      props.api?.applyTransaction({ remove: [currentIndexData] });
      const rowData: any[] = props.context.values[props.parent].filter(
        (node: any) => node.itemId !== props.data.itemId,
      );
      props.context.setFieldValue(props.parent, rowData);
    } else {
      const customDeleteAction = props.menuItemsList.customDeleteAction;
      customDeleteAction(props);
    }
  };

  const handleAction = (e: any, itemLabel: string) => {
    const thisItemAction = props.menuItemsList.customItems.find(function (item: any) {
      return item.label === itemLabel;
    });
    return thisItemAction.action(props);
  };

  return (
    <Flex width="4rem">
      {props.menuItemsList.commonItems.deleteItem === true && (
        <IconButton
          aria-label="delete button"
          color="black"
          fontSize="17px"
          icon={<IconRemoveCircle className="delete-icon" />}
          mb="3px"
          ml="1px"
          mt="3px"
          onClick={handleOnDeleteButtonClick}
          variant="secondary-link"
        />
      )}
      {props.actionMenu && (
        <RowActionMenu dark={true} marginLeft="0px">
          <Box>
            {props.menuItemsList.customItems.map((menuItem: any) => (
              <MenuItem
                key={menuItem.label}
                onClick={(event) => handleAction(event, menuItem.label)}
              >
                <i>{menuItem.icon()}</i>
                {menuItem.label}
              </MenuItem>
            ))}
          </Box>
        </RowActionMenu>
      )}
    </Flex>
  );
};

DeleteAndCustomActionRowButtonComponent.displayName = 'DeleteAndCustomActionRowButton';

export const DeleteAndCustomActionRowButtonRenderer = forwardRef(
  DeleteAndCustomActionRowButtonComponent,
);
