import { forwardRef, useImperativeHandle } from 'react';

import { ICellRendererParams } from '@ag-grid-community/core';
import { Box } from '@chakra-ui/react';

import { colors } from '@laudus/shared-ui';

interface ICheckboxRendererProps extends ICellRendererParams {
  data: boolean;
  field: string;
  label: string;
  name: string;
  disabled?: boolean;
}

const CheckboxRendererComponent: React.ForwardRefRenderFunction<
  HTMLInputElement,
  ICheckboxRendererProps
> = (props, ref) => {
  useImperativeHandle(ref, (): any => {
    return {
      getValue() {
        return props.value;
      },
    };
  });

  return (
    <Box
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        textAlign: 'right',
        paddingRight: '1rem',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <input
        readOnly={true}
        checked={props.value}
        name={props.name}
        ref={ref}
        style={{ accentColor: colors.primary500, width: '1.6rem', height: '1.6rem' }}
        type="checkbox"
        disabled={props.disabled}
      />
    </Box>
  );
};

CheckboxRendererComponent.displayName = 'CheckboxRenderer';

export const CheckboxRenderer = forwardRef(CheckboxRendererComponent);
