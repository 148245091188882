import { AxiosResponse } from 'axios';

import { ICode } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchCodesListAPI = (): Promise<AxiosResponse<ICode[]>> =>
  axios.post(
    `${VITE_API_URL}/system/codes/list`,
    {
      fields: [
        'codeId',
        'code',
        'description',
        'category.codeCategoryId',
        'category.description',
        'allowChanges',
      ],
      orderBy: [
        {
          field: 'codeId',
          direction: 'ASC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

/**
 * This request must not be called as data returned by it is constant.
 * If you need a list of code categories it is preloaded in the redux state,
 * into codeCategories slice
 */
// export const getCodesCategoriesListAPI = (): Promise<AxiosResponse<ICodesCategoriesList[]>> =>
//   axios.post(
//     `${VITE_API_URL}/system/codes/categories/list`,
//     {
//       fields: ['codeCategoryId', 'description'],
//       orderBy: [
//         {
//           field: 'codeCategoryId',
//           direction: 'ASC',
//         },
//       ],
//     },
//     {
//       headers: {
//         Accept: 'application/json',
//       },
//     },
//   );
