import { Button } from '@chakra-ui/react';

interface IModalButtonProps {
  backgroundColor?: string;
  leftIcon?: React.ReactElement;
  rightIcon?: React.ReactElement;
  text?: string;
  variant: string;
  onClick: () => void;
  widthAlertButton?: string;
  heightAlertButton?: string;
  type?: 'button' | 'submit' | 'reset';
  isOutline?: boolean;
  disabled?: boolean;
  isLoading?: boolean;
}

export const ModalButton = ({
  text,
  leftIcon,
  rightIcon,
  backgroundColor,
  onClick = () => undefined,
  widthAlertButton,
  heightAlertButton,
  type,
  disabled,
  isLoading,
  variant,
}: IModalButtonProps) => {
  return (
    <Button
      backgroundColor={backgroundColor}
      isDisabled={disabled}
      flexBasis="1 1"
      variant={variant}
      height={heightAlertButton}
      isLoading={isLoading}
      leftIcon={leftIcon}
      padding="0 2rem"
      rightIcon={rightIcon}
      type={type}
      minWidth="12rem"
      width={widthAlertButton}
      onClick={onClick}
    >
      {text}
    </Button>
  );
};
