import { createReducer } from '@reduxjs/toolkit';

import { ICostCenter, IFormState } from '@laudus/types';

import {
  clearCostCenter,
  clearCostCenterDraft,
  duplicateCostCenter,
  removeCostCenterFromList,
  resetCostCenterSubmit,
  setCostCenter,
  setCostCenterDraft,
  setCostCenterDraftValues,
  setCostCenterList,
  setCostCenterSubmitError,
  setCostCenterSubmitSuccess,
  setCostCenterSubmitting,
  updateCostCenterList,
} from './actions';

const initialFormState = {
  submitting: false,
  submitError: false,
  submitSuccess: false,
};

export interface ICostCenterState {
  current: ICostCenter;
  draft: ICostCenter;
  list: ICostCenter[];
  formState: IFormState;
}

export const COSTCENTER_EMPTY: ICostCenter = {
  fullPath: '',
  name: '',
  parentId: '',
  costCenterId: '',
  code: '',
  discontinued: false,
  notes: '',
};

export const initialCostCentersState: ICostCenterState = {
  current: COSTCENTER_EMPTY,
  draft: COSTCENTER_EMPTY,
  formState: {
    submitting: false,
    submitError: false,
    submitSuccess: false,
  },
  list: [],
};

export const costCentersReducer = createReducer(initialCostCentersState, (builder) => {
  builder
    .addCase(clearCostCenter, (state) => {
      return { ...state, current: COSTCENTER_EMPTY };
    })
    .addCase(setCostCenter, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setCostCenterList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(clearCostCenterDraft, (state) => {
      return { ...state, draft: COSTCENTER_EMPTY };
    })
    .addCase(setCostCenterDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setCostCenterDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(updateCostCenterList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((d) => d.costCenterId !== action.payload.costCenterId),
          action.payload,
        ],
      };
    })
    .addCase(removeCostCenterFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.costCenterId !== action.payload),
      };
    })
    .addCase(duplicateCostCenter, (state, action) => {
      const { costCenterId, ...duplicatedCostCenter } = action.payload;
      return { ...state, draft: duplicatedCostCenter };
    })
    .addCase(resetCostCenterSubmit, (state) => {
      return { ...state, formState: initialFormState };
    })
    .addCase(setCostCenterSubmitting, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitting: true },
      };
    })
    .addCase(setCostCenterSubmitError, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitError: true },
      };
    })
    .addCase(setCostCenterSubmitSuccess, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitSuccess: true },
      };
    })
    .addDefaultCase((state) => state);
});
