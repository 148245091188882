import { IntlShape } from '@laudus/intl';
import { IReceipts, ISalesInvoice } from '@laudus/types';

import { formattedDate, formattedDateAndHour } from '../format/dates/formattedDateAndHour';
import { formatDotNumber } from '../format/numbers/formatDotNumber';
import { replaceAccents } from '../format/strings/replaceAccent';
import { replaceRegisteredTrademark } from '../format/strings/replaceSpecialCharacter';

interface EmailBodyTemplateProps {
  invoice: ISalesInvoice;
  receipt: IReceipts;
  companyName: string;
  ticketDocTypes: (docTypeId: any) => string;
  intl: IntlShape;
}

export const EmailInvoicesTemplate = ({
  invoice,
  receipt,
  companyName,
  ticketDocTypes,
  intl,
}: EmailBodyTemplateProps) => {
  const formatDate = (date: string | undefined): string => {
    return date ? formattedDateAndHour(date) : '';
  };
  const formatDateWithoutHour = (date: string | undefined): string => {
    return date ? formattedDate(date) : '';
  };
  const docTypeName = invoice.docType?.name ? replaceAccents(invoice.docType.name) : '';
  const paymentMethod = receipt.receiptType?.description
    ? replaceAccents(receipt.receiptType?.description)
    : '';
  return `
<html>

<head>
  <meta http-equiv="Content-Type" content="text/html; charset=UTF-8" />
  <meta name="viewport; initial-scale=1.0" />
</head>

<body style="font-family: Open Sans, sans-serif">
  <table>
    <tr>
      <td
        style="background: #416BA9; text-align: center; padding-bottom: 19px; width: 462px; height: 80px; border-radius: 2rem 2rem 0px 0px;">
        <h1
          style="color: #ffffff; font-size: 2rem; font-style: normal; font-weight: 600; line-height: 36px; padding-top: 15px;">
          ${replaceAccents(
            intl.formatMessage({
              id: 'system.email.emailTemplate.headerTitle',
            }),
          )} ${ticketDocTypes(invoice.docType?.docTypeId)}
        </h1>
      </td>
    </tr>
    <tr>

      <td
        style="border-radius: 0px 0px 38px 38px; background-color: #ffffff; border: 1px solid #E9ECF0; text-align: center; border-top: none;">
        <table
          style="color: #000000; font-size: 15px; font-style: normal; font-weight: 400; line-height: 22px; width: 432px; margin: 0 auto; text-align: left; margin-bottom: 5px; margin-top: 0px;">
          <tbody>
            <tr>
              <td>
                <p style="margin-top: 1rem; margin-bottom: 0px;">
                  ${intl.formatMessage({
                    id: 'system.email.emailTemplate.greeting',
                  })}${invoice.customer?.name}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin-top: 0px; margin-bottom: 0px">
                  ${replaceAccents(
                    intl.formatMessage({
                      id: 'system.email.emailTemplate.description',
                    }),
                  )}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin-top: 0px; margin-bottom: 0px">
                  ${intl.formatMessage({
                    id: 'system.email.emailTemplate.regards',
                  })}
                </p>
              </td>
            </tr>
            <tr>
              <td>
                <p style="margin: 0">${companyName}</p>
              </td>
            </tr>
          </tbody>
        </table>
        <table
          style="list-style: none; font-size: 15px; direction: column; padding-left: 18px; padding-top: 1rem; padding-bottom: 1.2rem; padding-right: 2rem; background: #F7F7F9; border-radius: 0.8rem; border: 1px solid #E9ECF0; margin: 0 auto; width: 427px; height: 11rem;">
          <tr>
            <td style="font-weight: 400; text-align: left; padding-left: 1rem">
              <span>${intl.formatMessage({
                id: 'system.email.emailTemplate.docType',
              })}</span>
            </td>
            <td style="font-weight: 600; text-align: left; padding-left: 0px">
              <span> ${docTypeName}</span>
            </td>
          </tr>
          <tr>
            <td style="font-weight: 400; text-align: left; padding-left: 1rem">
              <span>
                ${intl.formatMessage({
                  id: 'system.email.emailTemplate.folio',
                })}</span>
            </td>
            <td style="font-weight: 600; text-align: left; padding-left: 0px">
              <span> ${formatDotNumber(invoice.docNumber)}</span>
            </td>
          </tr>
          <tr>
            <td style="font-weight: 400; text-align: left; padding-left: 1rem">
              <span>
                ${replaceAccents(
                  intl.formatMessage({
                    id: 'system.email.emailTemplate.issueDate',
                  }),
                )}</span>
            </td>
            <td style="font-weight: 600; text-align: left; padding-left: 0px">
              <span> ${formatDate(invoice.issuedDate)}</span>
            </td>
          </tr>
          <tr>
            <td style="font-weight: 400; text-align: left; padding-left: 1rem">
              <span>${intl.formatMessage({
                id: 'system.email.emailTemplate.dueDate',
              })}</span>
            </td>
            <td style="font-weight: 600; text-align: left; padding-left: 0px">
              <span> ${formatDateWithoutHour(invoice.dueDate)}</span>
            </td>
          </tr>
          <tr>
            <td style="font-weight: 400; text-align: left; padding-left: 1rem">
              <span>${replaceAccents(
                intl.formatMessage({
                  id: 'system.email.emailTemplate.paymentMethod',
                }),
              )}</span>
            </td>
            <td style="font-weight: 600; text-align: left; padding-left: 0px">
              <span> ${paymentMethod}</span>
            </td>
          </tr>
        </table>
        <div style="align-items: center">
          <table style="border-spacing: 1rem; width: 446px; height: 48px; margin: 0 auto; padding-top: 0.4rem">
            <tr>
              <td
                style="border-radius: 0.8rem; border: 1px solid #e9ecf0; background: #ffffff; width: 135px; height: 47px;">
                <p
                  style="text-align: right; padding-right: 1rem; margin-top: 1rem; color: #666666; font-family: Open Sans, sans-serif; font-size: 1rem; font-style: normal; font-weight: 400; line-height: 17px; margin: 0;">
                  ${intl.formatMessage({
                    id: 'system.email.emailTemplate.net',
                  })}
                </p>
                <p
                  style="font-family: Open Sans, sans-serif; font-size: 2rem; font-style: normal; font-weight: 400; line-height: 29px; text-align: right; padding-right: 1rem; margin-top: 0.7rem; margin: 0;">
                  ${formatDotNumber(invoice.totals?.net)}
                </p>
              </td>
              <td
                style="border-radius: 0.8rem; border: 1px solid #e9ecf0; background: #ffffff; width: 135px; height: 47px;">
                <p
                  style="text-align: right; padding-right: 1rem; margin-top: 1rem; color: #666666; font-family: Open Sans, sans-serif; font-size: 1rem; font-style: normal; font-weight: 400; line-height: 17px; margin: 0;">
                  ${intl.formatMessage({
                    id: 'system.email.emailTemplate.vat',
                  })}
                </p>
                <p
                  style="font-family: Open Sans, sans-serif; font-size: 2rem; font-style: normal; font-weight: 400; line-height: 29px; text-align: right; padding-right: 1rem; margin-top: 0.7rem; margin: 0;">
                  ${formatDotNumber(invoice.totals?.VAT)}
                </p>
              </td>
              <td
                style="border-radius: 0.8rem; border: 1px solid #ffeacc; background: #ffeacc; margin-right: 5px; width: 135px; height: 47px;">
                <p
                  style="text-align: right; padding-right: 1rem; margin-top: 1rem; color: #000000; font-family: Open Sans, sans-serif; font-size: 1rem; font-style: normal; font-weight: 400; line-height: 17px; margin: 0;">
                  ${intl.formatMessage({
                    id: 'system.email.emailTemplate.total',
                  })}
                </p>
                <p
                  style="font-family: Open Sans, sans-serif; font-size: 2rem; font-style: normal; font-weight: 400; line-height: 29px; text-align: right; padding-right: 1rem; margin-top: 0.7rem; margin: 0;">
                  ${formatDotNumber(invoice.totals?.total)}
                </p>
              </td>
            </tr>
          </table>
        </div>
        <p style="color: #00000; text-align: center; font-size: 15px; width: 427px; padding-left: 15px;">
          ${intl.formatMessage({
            id: 'system.email.emailTemplate.attachmentInfo',
          })}
        </p>
      </td>
    </tr>
  </table>
  <table>
    <tr>
      <td style="text-align: center; width: 427px; position: relative; top: -35px; padding-left: 15px;">
        <p style="font-size: 13px; color: #aaadb3">
          ${replaceRegisteredTrademark(
            replaceAccents(
              intl.formatMessage({
                id: 'system.email.emailTemplate.footer.description',
              }),
            ),
          )}
        </p>
      </td>
    </tr>
  </table>
</body>

</html>
`;
};
