import { forwardRef, ForwardRefRenderFunction, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';

import { FormControl, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import es from 'date-fns/locale/es';
import { Field, FieldProps } from 'formik';

import { IconCalendarToday } from '@laudus/icons';
import { useThemeContext } from '@laudus/shared-ui';
import { dateToLocalISOString, debounce, isValidDate } from '@laudus/shared-utils';

import { FormLabel } from './FormLabel';

import 'react-datepicker/dist/react-datepicker.css';

registerLocale('es', es);

export const CustomInputWithRef: ForwardRefRenderFunction<any, any> = (
  { value: dateValue, onClick, onChange, disabled },
  ref,
) => {
  const { colors = {} } = useThemeContext();
  const [validDate, setValidDate] = useState('');
  const [isFocused, setIsFocused] = useState(false);

  const handleOnChange = (e: any) => {
    setValidDate(e.target.value);
    if (isValidDate(e.target.value)) {
      if (onChange) {
        debounce(() => onChange(e), 3000);
      }
    }
  };

  useEffect(() => {
    setValidDate(dateValue);
  }, [dateValue]);

  const handleOnFocus = () => {
    setIsFocused(true);
    if (!disabled) {
      onClick();
    }
  };

  const handleOnBlur = () => setIsFocused(false);

  const { inputBackground, inputBorder } = colors;

  return (
    <InputGroup ref={ref}>
      <Input
        cursor="pointer"
        disabled={disabled}
        fontSize="1.5rem"
        height="3.5rem"
        onBlur={handleOnBlur}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        value={validDate}
        backgroundColor={inputBackground}
        border={`0.05rem solid ${inputBorder}`}
      />
      <InputRightElement className="icon-input" onClick={handleOnFocus}>
        <IconCalendarToday
          color={isFocused ? 'primary500' : 'neutral500'}
          cursor="pointer"
          onClick={() => (!disabled ? onClick : undefined)}
        />
      </InputRightElement>
    </InputGroup>
  );
};

const CustomInput = forwardRef(CustomInputWithRef);

type DateLabelVariant = 'balanceSheetLeftMenuLabel';

interface IDateFieldProps {
  disabled?: boolean;
  label?: string;
  labelVariant?: DateLabelVariant;
  name: string;
  defaultValueOnEmtpyValue?: Date | null;
  showMonthYear?: boolean;
  showTime?: boolean;
  withPortal?: boolean;
  tooltipText?: string;
  readOnly?: boolean;
  setFieldValue?: (field: string, value: string | null) => void;
}

export const DateField = ({
  disabled,
  label,
  labelVariant = undefined,
  name,
  showMonthYear = false,
  showTime = true,
  defaultValueOnEmtpyValue = new Date(),
  withPortal = false,
  tooltipText,
  readOnly = false,
  setFieldValue = () => undefined,
}: IDateFieldProps) => {
  const handleChange = (date: Date | null) => {
    setFieldValue(name, date ? dateToLocalISOString(new Date(date)) : null);
  };

  let dateFormat = showMonthYear ? 'MM/yyyy' : 'dd-MM-yyyy';
  if (showTime) {
    dateFormat += '|HH:mm';
  }

  return (
    <Field disabled={disabled} name={name}>
      {({ field: { value: formikValue, ...restOfField } }: FieldProps) => {
        const value = formikValue ? new Date(formikValue) : defaultValueOnEmtpyValue;
        return (
          <FormControl>
            {label && <FormLabel label={label} tooltipText={tooltipText} variant={labelVariant} />}
            <div
              className="input-container"
              id={withPortal ? 'portal-id' : undefined}
              style={{ position: 'relative' }}
            >
              {/* @ts-expect-error todo fix - 'DatePicker' cannot be used as a JSX component. */}
              <DatePicker
                {...restOfField}
                customInput={<CustomInput disabled={disabled} />}
                dateFormat={dateFormat}
                disabled={disabled}
                calendarClassName="react-datepicker"
                dropdownMode="select"
                fixedHeight={false}
                readOnly={readOnly}
                locale="es"
                onChange={handleChange}
                peekNextMonth
                placeholderText="Seleccionar"
                portalId={withPortal ? 'portal-id' : 'body'}
                selected={value}
                showMonthDropdown
                showMonthYearPicker={showMonthYear}
                showTimeSelect={showTime}
                showYearDropdown
                timeCaption="Hora"
                timeFormat="HH:mm"
                withPortal={withPortal}
              />
            </div>
          </FormControl>
        );
      }}
    </Field>
  );
};
