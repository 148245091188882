import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const Radio: ComponentMultiStyleConfig = {
  parts: ['control', 'label', 'container'],
  baseStyle: {
    label: {
      fontSize: '1.4rem',
    },
    control: {
      bg: 'none',
      border: '1px solid',
      borderColor: 'black',
      borderRadius: '100%',
      width: '14px',
      height: '14px',
      _checked: {
        bg: 'primary500',
        borderColor: 'primary500',
        boxShadow: 'inset 0 0 0 2px white',
        _before: {
          bg: 'white',
          borderRadius: '100%',
          content: '""',
          display: 'none',
          height: '1.2rem',
          width: '1.2rem',
          boxShadow: 'inset 0 0 0 2px white',
        },
        _focus: {
          bg: 'primary500',
          borderColor: 'primary500',
          boxShadow: 'inset 0 0 0 2px white',
        },
        _hover: {
          bg: 'secondary100',
          borderColor: 'secondary100',
        },
      },
      _disabled: {
        bg: 'none',
        color: 'neutral500',
        borderColor: 'neutral200',
        _checked: {
          bg: 'none',
          borderColor: 'neutral200',
          _before: {
            bg: 'neutral.20',
            borderRadius: 0,
            content: '""',
            display: 'block',
            height: '1.2rem',
            width: '1.2rem',
          },
        },
        _hover: {
          borderColor: 'neutral.20',
        },
      },
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        borderColor: 'secondary100',
      },
      _invalid: {
        bg: 'none',
        borderColor: 'error.100',
        _checked: {
          bg: 'none',
          borderColor: 'error.100',
          _before: {
            bg: 'error.100',
            borderRadius: 0,
            content: '""',
            display: 'block',
            height: '1.2rem',
            width: '1.2rem',
          },
          _disabled: {
            borderColor: 'error.100',
          },
        },
        _disabled: {
          borderColor: 'neutral.20',
        },
      },
    },
  },
  variants: {
    'settingsImageFitVariant': {
      container: {
        'flex': '1 1 30rem',
        '& img': {
          objectFit: 'contain',
        },
      },
      control: {
        position: 'absolute',
        top: '1.2rem',
        left: '1.2rem',
        width: '1.2rem',
        height: '1.2rem',
        borderColor: 'black',
        _dark: {
          borderColor: 'white',
        },
        borderWidth: '0.1rem',
        _checked: {
          background: 'primary500',
          borderColor: 'primary500',
          color: 'white',
          _hover: {
            background: 'primary500',
            borderColor: 'primary500',
          },
        },
      },
      label: {
        margin: 0,
        width: '100%',
        height: '100%',
        border: '0.1rem solid',
        borderColor: 'neutral400',
        borderRadius: '0.7rem',
        p: '2.5rem',
        _checked: {
          backgroundColor: 'primary100',
          borderColor: 'primary500',
          _dark: {
            backgroundColor: 'secondary800',
          },
        },
        _hover: {
          backgroundColor: 'neutral25',
          _dark: {
            backgroundColor: 'primary400',
          },
        },
      },
    },
    'full-width': {
      label: {
        width: '100%',
      },
    },
    'dark-background': {
      control: {
        borderColor: 'neutral100',
        _checked: {
          boxShadow: 'inset 0 0 0 2px var(--chakra-colors-secondary500)',
          _before: {
            boxShadow: 'inset 0 0 0 2px var(--chakra-colors-secondary500)',
          },
          _focus: {
            bg: 'primary500',
            borderColor: 'primary500',
            boxShadow: 'inset 0 0 0 2px var(--chakra-colors-secondary500)',
          },
        },
      },
    },
    'withdrawal-reconciliation': {
      container: {
        width: '100%',
      },
      label: {
        width: '100%',
      },
      control: {
        alignSelf: 'stretch',
        marginTop: '0.4rem',
        _disabled: {
          _checked: {
            _before: {
              borderRadius: '100%',
            },
          },
        },
      },
    },
  },
};
