import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString, getNegativeUniqueNumericId } from '@laudus/shared-utils';
import { IReceipts, IReceiptsOtherDocument } from '@laudus/types';

import {
  clearMixedPaymentReceipt,
  clearReceipt,
  clearReceiptDraft,
  clearReceiptOtherDocumentDraft,
  duplicateReceipt,
  removeReceiptFromList,
  setMixedPaymentReceiptsList,
  setReceipt,
  setReceiptDraft,
  setReceiptDraftValues,
  setReceiptEditing,
  setReceiptOtherDocumentDraft,
  setReceiptOtherDocumentDraftValues,
  setReceiptsList,
  updateReceiptsList,
} from './actions';

export const RECEIPTS_TAB_ID = 'receipts';

export interface IReceiptsState {
  current: IReceipts;
  draft: IReceipts;
  list: IReceipts[];
  otherDocumentDraft: IReceiptsOtherDocument;
  mixedPaymentReceipt: IReceipts[];
  editing: boolean;
}

export const RECEIPTS_LIST_EMPTY = [];

export const RECEIPTS_EMPTY: IReceipts = {
  receiptId: 0,
  issuedDate: dateToLocalISOString(new Date()),
  dueDate: dateToLocalISOString(new Date()),
  document: '',
  bankOfDocument: '',
  deposited: false,
  createAccounting: true,
  notes: '',
  createdAt: '',
  modifiedAt: '',
  receiptType: {
    code: '',
    description: '',
  },
  bankOfDeposit: {
    bankId: '',
    name: '',
  },
  pos: {
    posId: 0,
    name: '',
  },
  noteOfCredit: null,

  accountOther: null,
  purchaseInvoice: null,
  journalEntryIssued: null,
  journalEntryDeposited: null,
  createdBy: {
    userId: '',
    name: '',
  },
  modifiedBy: {
    userId: '',
    name: '',
  },
  salesInvoices: [],
  otherDocuments: [],
};

const OTHER_DOCUMENT_EMPTY = {
  itemId: 0,
  document: '',
  description: '',
  relatedTo: {
    relatedId: 0,
    type: '',
    name: '',
    VATId: '',
  },
  account: null,
  category: {
    code: '',
    description: '',
  },
  originalAmount: 0,
  currencyCode: 'CLP',
  parityToMainCurrency: 1,
  amount: 0,
  costCenter: null,
};

export const initialReceiptsState: IReceiptsState = {
  current: RECEIPTS_EMPTY,
  draft: RECEIPTS_EMPTY,
  list: [],
  otherDocumentDraft: OTHER_DOCUMENT_EMPTY,
  mixedPaymentReceipt: [],
  editing: false,
};

export const receiptsReducer = createReducer(initialReceiptsState, (builder) => {
  builder
    .addCase(clearReceipt, (state) => {
      return { ...state, current: RECEIPTS_EMPTY };
    })
    .addCase(clearReceiptDraft, (state) => {
      return { ...state, draft: RECEIPTS_EMPTY };
    })
    .addCase(clearReceiptOtherDocumentDraft, (state) => {
      return { ...state, otherDocumentDraft: OTHER_DOCUMENT_EMPTY };
    })
    .addCase(clearMixedPaymentReceipt, (state) => {
      return { ...state, mixedPaymentReceipt: [] };
    })
    .addCase(setReceipt, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setReceiptDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setReceiptDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setReceiptOtherDocumentDraft, (state, action) => {
      return { ...state, otherDocumentDraft: action.payload };
    })
    .addCase(setReceiptOtherDocumentDraftValues, (state, action) => {
      return {
        ...state,
        otherDocumentDraft: {
          ...state.otherDocumentDraft,
          ...action.payload,
        },
      };
    })
    .addCase(setReceiptsList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateReceiptsList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.receiptId !== action.payload.receiptId),
          action.payload,
        ],
      };
    })
    .addCase(removeReceiptFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.receiptId !== action.payload),
      };
    })
    .addCase(duplicateReceipt, (state, action) => {
      const { receiptId, ...duplicatedReceipt } = action.payload;
      const duplicatedSalesInvoices = duplicatedReceipt.salesInvoices
        ? [...duplicatedReceipt.salesInvoices].map((salesInvoices) => {
            return { ...salesInvoices, itemId: getNegativeUniqueNumericId() };
          })
        : [];
      const duplicatedOtherDocuments = duplicatedReceipt.otherDocuments
        ? [...duplicatedReceipt.otherDocuments].map((otherDocuments) => {
            return {
              ...otherDocuments,
              itemId: getNegativeUniqueNumericId(),
            };
          })
        : [];
      return {
        ...state,
        draft: {
          ...duplicatedReceipt,
          salesInvoices: [...duplicatedSalesInvoices],
          otherDocuments: [...duplicatedOtherDocuments],
        },
      };
    })
    .addCase(setMixedPaymentReceiptsList, (state, action) => {
      return { ...state, mixedPaymentReceipt: action.payload };
    })
    .addCase(setReceiptEditing, (state, action) => {
      return { ...state, editing: action.payload };
    })
    .addDefaultCase((state) => state);
});
