import { createReducer } from '@reduxjs/toolkit';

import { ICurrency } from '@laudus/types';

import {
  clearCurrency,
  clearCurrencyDraft,
  duplicateCurrency,
  removeCurrencyFromList,
  setCurrency,
  setCurrencyDraft,
  setCurrencyDraftValues,
  setCurrencyList,
  updateCurrencyList,
} from './actions';

export interface ICurrenciesState {
  list: ICurrency[];
  draft: ICurrency;
  current: ICurrency;
}

export const CURRENCY_EMPTY: ICurrency = {
  currencyId: '',
  code: '',
  name: '',
  symbol: '',
  decimals: 0,
  codeDTE: '',
  nameDTE: '',
  createdBy: {
    userId: '',
    name: '',
  },
  createdAt: '',
  modifiedBy: {
    userId: '',
    name: '',
  },
  modifiedAt: '',
};

export const initialCurrenciesState: ICurrenciesState = {
  list: [],
  current: CURRENCY_EMPTY,
  draft: CURRENCY_EMPTY,
};

export const currenciesReducer = createReducer(initialCurrenciesState, (builder) => {
  builder
    .addCase(clearCurrency, (state) => {
      return { ...state, current: CURRENCY_EMPTY };
    })
    .addCase(clearCurrencyDraft, (state) => {
      return { ...state, draft: CURRENCY_EMPTY };
    })
    .addCase(setCurrency, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setCurrencyDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setCurrencyDraftValues, (state, action) => {
      return {
        ...state,
        draft: {
          ...state.draft,
          ...action.payload,
        },
      };
    })
    .addCase(setCurrencyList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateCurrencyList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((b) => b.currencyId !== action.payload.currencyId),
          {
            ...action.payload,
          },
        ],
      };
    })
    .addCase(removeCurrencyFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((b) => b.currencyId !== action.payload),
      };
    })
    .addCase(duplicateCurrency, (state, action) => {
      return {
        ...state,
        draft: {
          ...action.payload,
          currencyId: '',
        },
      };
    })
    .addDefaultCase((state) => state);
});
