import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { merge } from 'lodash';

import { intl } from '@laudus/intl';
import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  IFetchWithEtagParams,
  IPriceList,
  IPriceListItem,
  IProductCategory,
  IProductListItem,
} from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { getProductsListForProductSearchGrid } from '../products';
import { addTab } from '../tabs';

import { getPriceListDraft } from './selectors';

export const priceListsPrefix = 'PRICE_LISTS';

export const addHomePriceListsTab = () =>
  addTab({
    tab: {
      id: 'priceLists',
      title: intl.formatMessage({ id: 'pricesList.tabTitle' }),
      path: 'pages/PricesLists/PricesLists',
      isRemovable: true,
    },
  });

export const addViewPriceListsTab = (id?: number) =>
  addTab({
    tab: {
      id: 'priceLists',
      title: intl.formatMessage({ id: 'pricesList.tabTitle' }),
      path: 'pages/PricesLists/PricesListsView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewPriceListsTab = () =>
  addTab({
    tab: {
      id: 'priceLists',
      title: intl.formatMessage({ id: 'pricesList.tabTitle' }),
      path: 'pages/PricesLists/PricesListsNew',
      isRemovable: true,
    },
  });

export const addEditPriceListsTab = () =>
  addTab({
    tab: {
      id: 'priceLists',
      title: intl.formatMessage({ id: 'pricesList.tabTitle' }),
      path: 'pages/PricesLists/PricesListsEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearPriceList = createAction('PRICE_LISTS/CLEAR');

export const clearPriceListDraft = createAction('PRICE_LISTS/CLEAR_DRAFT');

export const setPriceList = createAction<IPriceList>('PRICE_LISTS/SET_PRICE_LIST');

export const setPriceListDraft = createAction<IPriceList>('PRICE_LISTS/SET_PRICE_LIST_DRAFT');

export const setPriceListDraftValues = createAction<Partial<IPriceList>>(
  'PRICE_LISTS/SET_PRICE_LIST_DRAFT_VALUE',
);

export const setPriceListList = createAction<IPriceList[]>('PRICE_LISTS/SET_LIST');

export const updatePriceListList = createAction<IPriceList>('PRICE_LISTS/UPDATE_LIST');

export const removePriceListFromList = createAction<number>('PRICE_LISTS/REMOVE_FROM_LIST');

export const duplicatePriceList = createAction<IPriceList>('PRICE_LISTS/DUPLICATE');

// Complex actions

export interface ISetPriceListsItemColumnParams {
  item: IPriceListItem;
  itemIndex: number;
  columnId: string;
}

export const setPriceListsItemColumn = createAsyncThunk<
  void,
  ISetPriceListsItemColumnParams,
  AppThunkConfig
>('PRICE_LISTS/SET_ITEM_COLUMN', async ({ item, itemIndex, columnId }, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;

  const state = getState();

  let newItem: IPriceListItem;
  switch (columnId) {
    case 'percentage':
      newItem = {
        ...item,
        unitPrice: 0,
        unitPriceWithTaxes: 0,
      };
      break;
    case 'unitPrice':
      newItem = {
        ...item,
        percentage: 0,
        unitPriceWithTaxes: (item?.unitPrice ?? 0) * (1 + 19 / 100),
      };
      break;
    case 'unitPriceWithTaxes':
      newItem = {
        ...item,
        percentage: 0,
        unitPrice: (item?.unitPriceWithTaxes ?? 0) / (1 + 19 / 100),
      };
      break;
    default:
      newItem = {
        ...item,
      };
  }
  const nextItems = [...getPriceListDraft(state).items];
  nextItems.splice(itemIndex, 1, newItem);
  dispatch(setPriceListDraftValues({ items: nextItems }));
});

export interface IAddPriceListItemFromProductParams {
  product: IProductListItem;
}

export const addPriceListItemFromProduct = createAsyncThunk<
  void,
  IAddPriceListItemFromProductParams,
  AppThunkConfig
>('PRICE_LISTS/ADD_PRICE_LIST_ITEM_FROM_PRODUCT', async ({ product }, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;

  const state = getState();
  const items = getPriceListDraft(state).items ?? [];

  const isProductInGrid = items.some((item) => item.product?.productId === product.productId);
  if (isProductInGrid) {
    dispatch(
      showAlert({
        type: 'warning',
        title: intl.formatMessage({
          id: 'pricesList.warning.itemAlreadyExists',
        }),
      }),
    );
    return;
  }

  dispatch(
    setPriceListDraftValues({
      items: [
        ...items,
        {
          itemId: getNegativeUniqueNumericId(),
          product: {
            description: product.description,
            productId: product.productId,
            sku: product.sku,
            unitPrice: product.unitPrice,
            unitPriceWithTaxes: product.unitPriceWithTaxes,
            discontinued: product.discontinued,
          },
          productCategory: null,
          percentage: 100,
          unitPrice: 0,
          unitPriceWithTaxes: 0,
        },
      ],
    }),
  );
});

export interface IAddProductFromBarcodeParams {
  barcode: string;
}

export const addProductFromBarcode = createAsyncThunk<
  void,
  IAddProductFromBarcodeParams,
  AppThunkConfig
>('PRICE_LISTS/ADD_PRODUCT', async ({ barcode }, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;

  const state = getState();
  const products = getProductsListForProductSearchGrid({
    showDiscontinued: false,
    showCanBeSoldOrCanBePurchased: 'both',
  })(state);

  const product = products?.find((item) => item.barCode === barcode);
  if (!product) {
    dispatch(
      showAlert({
        type: 'error',
        title: '',
        message: intl.formatMessage({ id: 'barcode.error.notFound' }, { barcode }),
      }),
    );
    return;
  }
  dispatch(addPriceListItemFromProduct({ product }));
});

export interface IAddPastedPriceListItemsParams {
  items: IPriceListItem[];
}

export const addPastedPriceListItems = createAsyncThunk<
  void,
  IAddPastedPriceListItemsParams,
  AppThunkConfig
>('PRICE_LISTS/ADD_PASTED_PRICE_LIST_ITEMS', async ({ items }, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;

  const state = getState();
  const products = getProductsListForProductSearchGrid({
    showDiscontinued: false,
    showCanBeSoldOrCanBePurchased: 'both',
  })(state);

  const pastedItems: IPriceListItem[] = items?.reduce((acc, row): IPriceListItem[] => {
    const product = products?.find((product) => product.sku === row?.product?.sku);
    if (!product) {
      return acc;
    }

    const isProductInGrid = acc.some((item) => item.product?.productId === product.productId);
    if (isProductInGrid) {
      return acc;
    }

    acc.push(
      merge(
        {},
        {
          itemId: getNegativeUniqueNumericId(),
          product: {
            description: product.description,
            productId: product.productId,
            sku: product.sku,
            unitPrice: product.unitPrice,
            unitPriceWithTaxes: product.unitPriceWithTaxes,
            discontinued: product.discontinued,
          },
          productCategory: null,
          percentage: 100,
          unitPrice: 0,
          unitPriceWithTaxes: 0,
        },
        row,
      ),
    );
    return acc;
  }, [] as IPriceListItem[]);

  dispatch(setPriceListDraftValues({ items: pastedItems }));
});

export interface IAddProductCategoryToPriceListItemsParams {
  productCategory: IProductCategory;
}

export const addProductCategoryToPriceListItems = createAsyncThunk<
  void,
  IAddProductCategoryToPriceListItemsParams,
  AppThunkConfig
>('PRICE_LISTS/ADD_PRODUCT_CATEGORY', async ({ productCategory }, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;

  const state = getState();
  const items = getPriceListDraft(state).items ?? [];

  const isCategoryInGrid = items.some(
    (item) => item.productCategory?.productCategoryId === productCategory.productCategoryId,
  );
  if (isCategoryInGrid) {
    dispatch(
      showAlert({
        type: 'warning',
        title: intl.formatMessage({
          id: 'pricesList.warning.itemAlreadyExists',
        }),
      }),
    );
    return;
  }

  dispatch(
    setPriceListDraftValues({
      items: [
        ...items,
        {
          itemId: getNegativeUniqueNumericId(),
          product: null,
          productCategory: {
            productCategoryId: productCategory.productCategoryId ?? '',
            name: productCategory.name,
            fullPath: productCategory.fullPath,
          },
          percentage: 100,
          unitPrice: 0,
          unitPriceWithTaxes: 0,
        },
      ],
    }),
  );
});

export const fetchPriceListList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'PRICE_LISTS/FETCH_PRICE_LISTS_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(startRequest('price-lists'));
      }

      const { data } = await api.priceLists.fetchPriceListListFromAPI();
      dispatch(setPriceListList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'pricesList',
          action: 'read',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('price-lists'));
      }
    }
  },
);

export interface IFetchPriceListParams {
  priceListId: number;
}

export const fetchPriceList = createAsyncThunk<void, number, AppThunkConfig>(
  'PRICE_LISTS/FETCH_PRICE_LIST',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('price-lists'));
    try {
      const { data } = await api.priceLists.fetchPriceListFromAPI(id);
      dispatch(setPriceList(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'pricesList',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('price-lists'));
    }
  },
);
