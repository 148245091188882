import { switchAnatomy as part } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/react';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(part.keys);

const colorModeVariant = definePartsStyle((props) => ({
  container: {
    '--switch-track-width': '4.4rem',
    '--switch-track-height': 'var(--chakra-sizes-9)',
    'padding': '0.2rem',
  },
  thumb: {
    bg: 'white',
  },
  track: {
    padding: '0.2rem',
    bg: 'secondary600',
    _checked: {
      bg: 'primary500',
      outline: 'none!important',
    },
  },
}));

const settingsVariant = definePartsStyle((props) => ({
  container: {
    '--switch-track-width': '4.4rem',
    '--switch-track-height': 'var(--chakra-sizes-9)',
    'padding': '0.2rem',
  },
  thumb: {
    bg: 'white',
    _dark: {
      bg: 'darkGrey800',
    },
    _checked: {
      bg: 'success500',
      _dark: {
        bg: 'success500',
      },
    },
  },
  track: {
    padding: '0rem',
    bg: 'neutral300',
    _dark: {
      bg: 'secondary200',
    },
    _checked: {
      bg: 'success400',
      _dark: {
        bg: 'success400',
      },
      outline: 'none!important',
    },
  },
}));
export const Switch = defineMultiStyleConfig({
  variants: {
    colorModeVariant,
    settingsVariant,
  },
});
