import { AxiosResponse } from 'axios';

import { IPosAdjustment, IPosAdjustmentListItem } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchPosAdjustmentsListAPI = async (
  posId?: number,
  sortByDate?: boolean,
): Promise<AxiosResponse<IPosAdjustment[]>> => {
  const res = await axios.post(
    `${VITE_API_URL}/sales/pos/adjustments/list`,
    {
      fields: [
        'adjustmentId',
        'date',
        'direction',
        'amount',
        'type',
        'notes',
        'category.code',
        'category.description',
        'pos.posId',
        'pos.name',
      ],

      ...(posId !== undefined
        ? { filterBy: [{ field: 'pos.posId', operator: '=', value: posId }] }
        : {}),

      orderBy: [
        {
          field: sortByDate ? 'date' : 'adjustmentId',
          direction: 'DESC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

  return {
    ...res,
    data: res.data.map((item: IPosAdjustmentListItem) => ({
      adjustmentId: item.adjustmentId,
      pos: {
        pos: item.pos_posId,
        name: item.pos_name,
      },
      date: item.date,
      direction: item.direction,
      amount: item.amount,
      type: item.type,
      category: {
        code: item.category_code,
        description: item.category_description,
      },
      notes: item.notes,
    })),
  };
};

export const fetchPosAdjustmentAPI = (
  adjustmentId: number,
): Promise<AxiosResponse<IPosAdjustment>> =>
  axios.get(`${VITE_API_URL}/sales/pos/adjustments/${adjustmentId}`);

export const createPosAdjustmentAPI = (
  posAdjustment: IPosAdjustment,
): Promise<AxiosResponse<IPosAdjustment>> =>
  axios.post(`${VITE_API_URL}/sales/pos/adjustments`, posAdjustment);

export const updatePosAdjustmentAPI = (
  posAdjustment: IPosAdjustment,
): Promise<AxiosResponse<IPosAdjustment>> =>
  axios.put(`${VITE_API_URL}/sales/pos/adjustments/${posAdjustment.adjustmentId}`, posAdjustment);

export const deletePosAdjustmentAPI = (adjustmentId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/pos/adjustments/${adjustmentId}`);
