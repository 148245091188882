import { createReducer } from '@reduxjs/toolkit';

import { IAccountType, IAccountTypeList, IFormState } from '@laudus/types';

import {
  clearAccountType,
  clearAccountTypeDraft,
  clearAccountTypesListDraft,
  resetAccountTypeSubmit,
  setAccountType,
  setAccountTypeDraft,
  setAccountTypeDraftValues,
  setAccountTypesList,
  setAccountTypesListDraft,
  setAccountTypesListDraftValues,
  setAccountTypeSubmitError,
  setAccountTypeSubmitSuccess,
  setAccountTypeSubmitting,
} from '.';

const initialFormState = {
  submitting: false,
  submitError: false,
  submitSuccess: false,
};

export interface IAccountTypesState {
  current: IAccountType;
  draft: IAccountType;
  list: IAccountTypeList;
  listDraft: IAccountTypeList;
  formState: IFormState;
}

export const ACCOUNTTYPE_EMPTY: IAccountType = {
  accountTypeId: '',
  description: '',
  account: null,
  isChargeableAccount: false,
  notes: '',
};

export const initialAccountTypesState: IAccountTypesState = {
  current: ACCOUNTTYPE_EMPTY,
  draft: ACCOUNTTYPE_EMPTY,
  list: [],
  listDraft: [],
  formState: initialFormState,
};

export const accountTypesReducer = createReducer(initialAccountTypesState, (builder) => {
  builder
    .addCase(clearAccountType, (state) => {
      return { ...state, current: ACCOUNTTYPE_EMPTY };
    })
    .addCase(setAccountType, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setAccountTypesList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(clearAccountTypeDraft, (state) => {
      return { ...state, draft: ACCOUNTTYPE_EMPTY };
    })
    .addCase(setAccountTypeDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setAccountTypeDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(clearAccountTypesListDraft, (state) => {
      return { ...state, listDraft: [] };
    })
    .addCase(setAccountTypesListDraft, (state, action) => {
      return { ...state, listDraft: [...action.payload] };
    })
    .addCase(setAccountTypesListDraftValues, (state, action) => {
      return {
        ...state,
        listDraft: { ...state.listDraft, ...action.payload },
      };
    })
    .addCase(resetAccountTypeSubmit, (state) => {
      return { ...state, formState: initialFormState };
    })
    .addCase(setAccountTypeSubmitting, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitting: true },
      };
    })
    .addCase(setAccountTypeSubmitError, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitError: true },
      };
    })
    .addCase(setAccountTypeSubmitSuccess, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitSuccess: true },
      };
    })
    .addDefaultCase((state) => state);
});
