import { AxiosResponse } from 'axios';

import { IPurchaseOrder } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchPurchasesOrdersListAPI = (): Promise<AxiosResponse<IPurchaseOrder[]>> =>
  axios.post(
    `${VITE_API_URL}/purchases/orders/list`,
    {
      fields: [
        'purchaseOrderId',
        'supplier.supplierId',
        'supplier.name',
        'issuedDate',
        'contact.contactId',
        'contact.firstName',
        'nullDoc',
        'archived',
        'term.termId',
        'term.name',
      ],
      orderBy: [
        {
          field: 'purchaseOrderId',
          direction: 'DESC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchPurchaseOrderAPI = (
  purchaseOrderId: number,
): Promise<AxiosResponse<IPurchaseOrder>> =>
  axios.get(`${VITE_API_URL}/purchases/orders/${purchaseOrderId}`);

export const createPurchaseOrderAPI = (
  purchaseOrder: IPurchaseOrder,
): Promise<AxiosResponse<IPurchaseOrder>> =>
  axios.post(`${VITE_API_URL}/purchases/orders`, purchaseOrder);

export const updatePurchaseOrderAPI = (
  purchaseOrder: IPurchaseOrder,
): Promise<AxiosResponse<IPurchaseOrder>> =>
  axios.put(`${VITE_API_URL}/purchases/orders/${purchaseOrder.purchaseOrderId}`, purchaseOrder);

export const deletePurchaseOrderAPI = (purchaseOrderId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/purchases/orders/${purchaseOrderId}`);
