import { createIntl, createIntlCache } from '../react-intl';

import { en } from './en';
import { es } from './es';

export const langs = { en, es };

export const locale = 'es';
export const messages = langs[locale];

// This is optional but highly recommended
// since it prevents memory leaks
const cache = createIntlCache();

export const intl = createIntl(
  {
    locale,
    messages,
  },
  cache,
);
