import { AxiosResponse } from 'axios';

import { IInventory } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchInventoryListAPI = (): Promise<AxiosResponse<IInventory[]>> =>
  axios.post(
    `${VITE_API_URL}/production/inventories/list`,
    {
      fields: ['inventoryId', 'warehouse.name', 'notes', 'date'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchInventoryAPI = (inventoryId: number): Promise<AxiosResponse<IInventory>> =>
  axios.get(`${VITE_API_URL}/production/inventories/${inventoryId}`);

export const createInventoryAPI = (inventory: IInventory): Promise<AxiosResponse<IInventory>> =>
  axios.post(`${VITE_API_URL}/production/inventories`, inventory);

export const updateInventoryAPI = (inventory: IInventory): Promise<AxiosResponse<IInventory>> =>
  axios.put(`${VITE_API_URL}/production/inventories/${inventory.inventoryId}`, inventory);

export const deleteInventoryAPI = (inventoryId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/production/inventories/${inventoryId}`);
