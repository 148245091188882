import { AxiosResponse } from 'axios';

import { ICurrency, ICurrencyParity } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchCurrencyParitiesListByCurrencyIdFromAPI = (
  currencyId: string,
): Promise<AxiosResponse<ICurrencyParity[]>> =>
  axios.post(
    `${VITE_API_URL}/accounting/currencies/parities/list`,
    {
      fields: ['currencyId', 'currencyCode', 'parityId', 'date', 'parity'],
      filterBy: [{ field: 'currencyId', operator: '=', value: currencyId }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchCurrencyParityFromAPI = (
  currencyId: string,
  currencyParityId: number,
): Promise<AxiosResponse<ICurrencyParity>> =>
  axios.get(`${VITE_API_URL}/accounting/currencies/${currencyId}/parities/${currencyParityId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createCurrencyParityFromAPI = (
  currency: ICurrency,
  currencyParity: ICurrencyParity,
): Promise<AxiosResponse<ICurrencyParity>> =>
  axios.post(`${VITE_API_URL}/accounting/currencies/${currency.currencyId}/parities`, {
    ...currencyParity,
    currencyId: currency.currencyId,
    currencyCode: currency.code,
    parityId: 0,
  });

export const updateCurrencyParityFromAPI = (
  currency: ICurrency,
  currencyParity: ICurrencyParity,
): Promise<AxiosResponse<ICurrencyParity>> =>
  axios.put(
    `${VITE_API_URL}/accounting/currencies/${currency.currencyId}/parities/${currencyParity.parityId}`,
    {
      ...currencyParity,
      currencyId: currency.currencyId,
      currencyCode: currency.code,
    },
  );

export const deleteCurrencyParityFromAPI = (
  currencyId: string,
  currencyParityId: number,
): Promise<AxiosResponse<ICurrencyParity>> =>
  axios.delete(`${VITE_API_URL}/accounting/currencies/${currencyId}/parities/${currencyParityId}`);
