export const invoicesDocTypes = [
  {
    behavior: 'A',
    electronic: false,
    expimp: false,
    family: 'S',
    iddoctype: 30,
    lettercode: 'F',
    nombre: 'Factura',
    subfamily: 'I',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: false,
    family: 'S',
    iddoctype: 32,
    lettercode: 'T',
    nombre: 'Factura Exenta',
    subfamily: 'I',
  },
  {
    behavior: 'A',
    electronic: true,
    expimp: false,
    family: 'S',
    iddoctype: 33,
    lettercode: 'E',
    nombre: 'Factura Electrónica',
    subfamily: 'I',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: false,
    family: 'S',
    iddoctype: 35,
    lettercode: 'B',
    nombre: 'Boleta',
    subfamily: 'T',
  },
  {
    behavior: 'S',
    electronic: false,
    expimp: false,
    family: 'S',
    iddoctype: 60,
    lettercode: 'C',
    nombre: 'Nota de Crédito',
    subfamily: 'C',
  },
  {
    behavior: 'S',
    electronic: true,
    expimp: false,
    family: 'S',
    iddoctype: 61,
    lettercode: 'I',
    nombre: 'Nota de Crédito Electrónica',
    subfamily: 'C',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: false,
    family: 'S',
    iddoctype: 55,
    lettercode: 'D',
    nombre: 'Nota de Débito',
    subfamily: 'D',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'G',
    iddoctype: 50,
    lettercode: '',
    nombre: 'Guía de Despacho',
    subfamily: 'G',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: false,
    family: 'S',
    iddoctype: 45,
    lettercode: 'P',
    nombre: 'Factura Propia de Compras',
    subfamily: 'I',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: true,
    family: 'S',
    iddoctype: 101,
    lettercode: 'X',
    nombre: 'Factura de Exportación',
    subfamily: 'I',
  },
  {
    behavior: 'A',
    electronic: true,
    expimp: false,
    family: 'S',
    iddoctype: 56,
    lettercode: 'J',
    nombre: 'Nota de Débito Electrónica',
    subfamily: 'D',
  },
  {
    behavior: 'S',
    electronic: false,
    expimp: true,
    family: 'S',
    iddoctype: 106,
    lettercode: 'Y',
    nombre: 'Nota de Crédito de Exportación',
    subfamily: 'C',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: true,
    family: 'S',
    iddoctype: 104,
    lettercode: 'Z',
    nombre: 'Nota de Débito de Exportación',
    subfamily: 'D',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: false,
    family: 'S',
    iddoctype: 40,
    lettercode: 'L',
    nombre: 'Liquidación de Factura',
    subfamily: 'I',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: true,
    family: 'S',
    iddoctype: 190,
    lettercode: 'N',
    nombre: 'Factura de Mercaderías no Nacionalizadas',
    subfamily: 'I',
  },
  {
    behavior: 'S',
    electronic: false,
    expimp: true,
    family: 'S',
    iddoctype: 191,
    lettercode: 'O',
    nombre: 'N de Cr Mercaderías no Nacionalizadas',
    subfamily: 'C',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: true,
    family: 'S',
    iddoctype: 192,
    lettercode: 'Q',
    nombre: 'N de Déb Mercaderías no Nacionalizadas',
    subfamily: 'I',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: false,
    family: 'S',
    iddoctype: 38,
    lettercode: 'K',
    nombre: 'Boleta Exenta',
    subfamily: 'T',
  },
  {
    behavior: 'A',
    electronic: true,
    expimp: true,
    family: 'S',
    iddoctype: 110,
    lettercode: '1',
    nombre: 'Factura de Exportación Electrónica',
    subfamily: 'I',
  },
  {
    behavior: 'A',
    electronic: true,
    expimp: false,
    family: 'S',
    iddoctype: 34,
    lettercode: '2',
    nombre: 'Factura Exenta Electrónica',
    subfamily: 'I',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: false,
    family: 'S',
    iddoctype: 0,
    lettercode: '0',
    nombre: 'Otros Documentos de Ventas',
    subfamily: '',
  },
  {
    behavior: 'A',
    electronic: true,
    expimp: false,
    family: 'S',
    iddoctype: 46,
    lettercode: '3',
    nombre: 'Factura Propia de Compras Electrónica',
    subfamily: 'I',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: true,
    family: 'P',
    iddoctype: 914,
    lettercode: '4',
    nombre: 'Declaración de Ingreso (DIN)',
    subfamily: 'I',
  },
  {
    behavior: '',
    electronic: true,
    expimp: false,
    family: 'G',
    iddoctype: 52,
    lettercode: '',
    nombre: 'Guía de Despacho Electrónica',
    subfamily: 'G',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: false,
    family: 'S',
    iddoctype: 102,
    lettercode: '5',
    nombre: 'Factura Exenta a Zona Franca Primaria',
    subfamily: 'I',
  },
  {
    behavior: 'A',
    electronic: true,
    expimp: false,
    family: 'S',
    iddoctype: 39,
    lettercode: 'V',
    nombre: 'Boleta Electrónica',
    subfamily: 'T',
  },
  {
    behavior: 'A',
    electronic: true,
    expimp: false,
    family: 'S',
    iddoctype: 41,
    lettercode: 'W',
    nombre: 'Boleta Exenta Electrónica',
    subfamily: 'T',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: false,
    family: 'S',
    iddoctype: 36,
    lettercode: 'U',
    nombre: 'Rollos Máquinas Registradoras',
    subfamily: 'T',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 801,
    lettercode: '',
    nombre: 'Orden de Compra',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 802,
    lettercode: '',
    nombre: 'Nota de Pedido',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 803,
    lettercode: '',
    nombre: 'Contrato',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 804,
    lettercode: '',
    nombre: 'Resolución',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 801801,
    lettercode: '',
    nombre: 'HES',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 801701,
    lettercode: '',
    nombre: 'SEP (Solicitud Electrónica de Pago)',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 801902,
    lettercode: '',
    nombre: 'Conformidad',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 801901,
    lettercode: '',
    nombre: 'Centro de Costo (Enersis)',
    subfamily: '',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: false,
    family: 'S',
    iddoctype: 48,
    lettercode: 'A',
    nombre: 'Vales Compr. Pago Electr (Transbank)',
    subfamily: 'T',
  },
  {
    behavior: 'A',
    electronic: true,
    expimp: true,
    family: 'S',
    iddoctype: 111,
    lettercode: '7',
    nombre: 'Nota de Déb de Exportación Electrónica',
    subfamily: 'D',
  },
  {
    behavior: 'S',
    electronic: true,
    expimp: true,
    family: 'S',
    iddoctype: 112,
    lettercode: '6',
    nombre: 'Nota de Créd de Exportación Electrónica',
    subfamily: 'C',
  },
  {
    behavior: 'A',
    electronic: true,
    expimp: false,
    family: 'S',
    iddoctype: 43,
    lettercode: '8',
    nombre: 'Liquidación de Factura Electrónica',
    subfamily: 'I',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 801601,
    lettercode: '',
    nombre: 'MIGO',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 801501,
    lettercode: '',
    nombre: 'Entrada de Mercadería (AES Gener)',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 801401,
    lettercode: '',
    nombre: 'EP (Estado de Pago)',
    subfamily: '',
  },
  {
    behavior: 'A',
    electronic: false,
    expimp: false,
    family: 'S',
    iddoctype: 901,
    lettercode: '9',
    nombre: 'Factura Exenta Ley 18.392',
    subfamily: 'I',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 801802,
    lettercode: '',
    nombre: 'OV',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 801803,
    lettercode: '',
    nombre: 'Unidad de Pago (MOP)',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 801804,
    lettercode: '',
    nombre: 'MLE (Modalidad Libre Elección FONASA)',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 801999,
    lettercode: '',
    nombre: 'Otras Referencias',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 805,
    lettercode: '',
    nombre: 'Proceso ChileCompra',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 806,
    lettercode: '',
    nombre: 'Ficha ChileCompra',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 820,
    lettercode: '',
    nombre: 'Cód Reg Acuerdos Pago Excepcional',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 807,
    lettercode: '',
    nombre: 'DUS',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 808,
    lettercode: '',
    nombre: 'B/L (Conocimiento de embarque)',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 809,
    lettercode: '',
    nombre: 'AWB (Air Waybill)',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 810,
    lettercode: '',
    nombre: 'MIC/DTA',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 811,
    lettercode: '',
    nombre: 'Carta de Porte',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 812,
    lettercode: '',
    nombre: 'Resolución del SNA Servicios Exportación',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 821,
    lettercode: '',
    nombre: 'Geo Referencia Predio',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 822,
    lettercode: '',
    nombre: 'ROL Avalúa Predio',
    subfamily: '',
  },
  {
    behavior: '',
    electronic: false,
    expimp: false,
    family: 'R',
    iddoctype: 823,
    lettercode: '',
    nombre: 'Plan Manejo – CONAF',
    subfamily: '',
  },
];
