import { createSelector } from '@reduxjs/toolkit';

import { AppState, ICustomerAddressesState } from '../..';

export const getCustomerAddressesSlice = (state: AppState): ICustomerAddressesState =>
  state.customerAddresses;

export const getCustomerAddressesList = createSelector(
  [getCustomerAddressesSlice],
  (state) => state.list,
);

export const getCustomerAddress = createSelector(
  [getCustomerAddressesSlice],
  (state) => state.current,
);

export const getCustomerAddressDraft = createSelector(
  [getCustomerAddressesSlice],
  (state) => state.draft,
);
