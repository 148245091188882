import { createReducer } from '@reduxjs/toolkit';

import { IBalanceSheet } from '@laudus/types';

import { resetBalanceSheet, setBalanceSheet } from './actions';

export interface IBalanceSheetState {
  current: IBalanceSheet[];
}

export const initialBalanceSheetState: IBalanceSheetState = {
  current: [],
};

export const balanceSheetsReducer = createReducer(initialBalanceSheetState, (builder) => {
  builder
    .addCase(setBalanceSheet, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(resetBalanceSheet, (state) => {
      return { ...state, current: [] };
    })
    .addDefaultCase((state) => state);
});
