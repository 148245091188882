import { ISalesDocument, ISalesInvoice, ISalesInvoicesItem, TypeOfProperty } from '@laudus/types';

import { calculateInvoiceItemVat } from './calculateInvoiceTotals';
import { calculateSalesItemNet } from './calculateSalesDocumentNet';

export function updateSalesItemsPricing<GenericSalesDocument extends ISalesDocument>(
  salesDocument: GenericSalesDocument,
  mainCurrencyDecimals: number,
): GenericSalesDocument {
  const newItems: TypeOfProperty<GenericSalesDocument, 'items'> = salesDocument?.items?.map(
    (item) => {
      const unitPrice = item.originalUnitPrice * item.parityToMainCurrency;
      const net = calculateSalesItemNet({ ...item, unitPrice }, mainCurrencyDecimals);

      return { ...item, unitPrice, net };
    },
  );

  return { ...salesDocument, items: newItems };
}

export function updateSalesInvoiceItemsPricing({
  salesInvoice,
  mainCurrencyDecimals,
  shouldQuantityAlwaysBeNegative = false,
}: {
  salesInvoice: ISalesInvoice;
  mainCurrencyDecimals: number;
  shouldQuantityAlwaysBeNegative?: boolean;
}): ISalesInvoice {
  const newItems = mapSalesInvoiceItemsPricing({
    items: salesInvoice.items,
    mainCurrencyDecimals,
    shouldQuantityAlwaysBeNegative,
  });

  return { ...salesInvoice, items: newItems };
}

export function mapSalesInvoiceItemsPricing({
  items,
  mainCurrencyDecimals,
  shouldQuantityAlwaysBeNegative = false,
}: {
  items: ISalesInvoicesItem[];
  mainCurrencyDecimals: number;
  shouldQuantityAlwaysBeNegative?: boolean;
}): ISalesInvoicesItem[] {
  const newItems: ISalesInvoicesItem[] = items.map((item) => {
    const quantity = shouldQuantityAlwaysBeNegative ? -Math.abs(item.quantity) : item.quantity;

    const unitPrice = item.originalUnitPrice * item.parityToMainCurrency;
    const net = calculateSalesItemNet({ ...item, unitPrice, quantity }, mainCurrencyDecimals);
    const VAT = calculateInvoiceItemVat({ ...item, unitPrice, quantity }, mainCurrencyDecimals);

    return { ...item, unitPrice, net, VAT, quantity };
  });

  return newItems;
}
