import { formatGridDate, formatNumber } from '@laudus/shared-utils';

export interface IMainColumnsGridRendererParams {
  value: number | string;
  rowIndex: number;
  colDef: { field: string };
}

export const mainColumnsGridRenderer = (params: IMainColumnsGridRendererParams) => {
  const convertionType = typeof params.value;
  if (convertionType === 'string') {
    // eslint-disable-next-line no-useless-escape
    const isDate = /^\d{4}\-\d{1,2}\-\d{1,2}$/.test(params.value.toString().substring(0, 10));
    // if (isDate) {
    // }
    return isDate && params.value
      ? `${formatGridDate(params.value.toString() || '')}`
      : params.value;
  } else {
    return convertionType === 'number'
      ? `${formatNumber(Number(params.value ? params.value : 0))}`
      : '';
  }
};
