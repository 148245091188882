import { formatGridDate } from './formatGridDate';

export const formatGridText = (value: string | number) => {
  // eslint-disable-next-line no-useless-escape
  const isDate =
    typeof value === 'string' ? /^\d{4}-\d{1,2}-\d{1,2}$/.test(value.substring(0, 10)) : false;

  return !isDate
    ? value
        // If we replace spaces with '' omar matches francisco martinez
        .toString()
        .toLowerCase()
        .replace(/\s+/g, ' ')
        .replace(/[àáâãäå]/gi, 'a')
        .replace(/ç/gi, 'c')
        .replace(/[èéêë]/gi, 'e')
        .replace(/[ìíîï]/gi, 'i')
        .replace(/[òóôõö]/gi, 'o')
        .replace(/[ùúûü]/gi, 'u')
        .replace(/[ýÿ]/gi, 'y')
    : formatGridDate(value.toString());
};
