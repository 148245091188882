import { AxiosResponse } from 'axios';

import { ISerializedDashboard } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchDashboardListAPI = (): Promise<AxiosResponse<ISerializedDashboard[]>> =>
  axios.post(
    `${VITE_API_URL}/system/dashboards/list`,
    {
      fields: ['dashboardId', 'name'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchDashboardAPI = (
  dashboardId: number,
): Promise<AxiosResponse<ISerializedDashboard>> =>
  axios.get(`${VITE_API_URL}/system/dashboards/${dashboardId}`);

export const createDashboardAPI = (
  dashboard: ISerializedDashboard,
): Promise<AxiosResponse<ISerializedDashboard>> =>
  axios.post(`${VITE_API_URL}/system/dashboards`, dashboard);

export const updateDashboardAPI = (
  dashboardId: number,
  dashboard: ISerializedDashboard,
): Promise<AxiosResponse<ISerializedDashboard>> =>
  axios.put(`${VITE_API_URL}/system/dashboards/${dashboardId}`, dashboard);

export const deleteDashboardAPI = (dashboardId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/system/dashboards/${dashboardId}`);
