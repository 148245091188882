import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString } from '@laudus/shared-utils';
import { IBranch, IPriceListProducts } from '@laudus/types';

import {
  clearBranch,
  clearBranchDraft,
  duplicateBranch,
  removeBranchFromList,
  setBranch,
  setBranchDraft,
  setBranchDraftValues,
  setBranchesList,
  setBranchesLoading,
  setBranchProductsWithPricesOverride,
  updateBranchesList,
} from './actions';

export interface IBranchesState {
  loading: boolean;
  current: IBranch;
  draft: IBranch;
  list: IBranch[];
  productsWithPricesOverride?: IPriceListProducts;
}

export const BRANCH_EMPTY: IBranch = {
  branchId: 0,
  name: '',
  notes: '',
  address: '',
  city: '',
  county: '',
  zipCode: '',
  state: '',
  email: '',
  schedule: '',
  fiscalId: '',
  defaultReceiptType: {
    code: '',
    description: '',
  },
  authorizedDocumentsRanges: [
    {
      authorizedDocumentsRangeId: 0,
      docType: {
        docTypeId: 0,
        name: '',
      },
      docNumberFrom: 0,
      docNumberTo: 0,
      authorizationDate: dateToLocalISOString(new Date()),
      CAF: '',
      pos: {
        POSId: 0,
        name: '',
      },
    },
  ],
};

export const initialBranchesState: IBranchesState = {
  loading: false,
  current: BRANCH_EMPTY,
  draft: BRANCH_EMPTY,
  list: [],
  productsWithPricesOverride: undefined,
};

export const BRANCHES_TAB_ID = 'branches';

export const branchesReducer = createReducer(initialBranchesState, (builder) => {
  builder
    .addCase(setBranchesLoading, (state, action) => {
      return { ...state, loading: action.payload };
    })
    .addCase(clearBranch, (state) => {
      return { ...state, current: BRANCH_EMPTY, productsWithPricesOverride: undefined };
    })
    .addCase(clearBranchDraft, (state) => {
      return { ...state, draft: BRANCH_EMPTY };
    })
    .addCase(setBranchDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setBranch, (state, action) => {
      return { ...state, current: action.payload, productsWithPricesOverride: undefined };
    })
    .addCase(setBranchProductsWithPricesOverride, (state, action) => {
      return {
        ...state,
        current: action.payload.branch,
        productsWithPricesOverride: action.payload.productsWithPricesOverride,
      };
    })
    .addCase(setBranchDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setBranchesList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateBranchesList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((b) => b.branchId !== action.payload.branchId), action.payload],
      };
    })
    .addCase(removeBranchFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((b) => b.branchId !== action.payload),
      };
    })
    .addCase(duplicateBranch, (state, action) => {
      const { branchId, ...duplicatedBranch } = action.payload;
      const duplicatedAuthorizedDocuments = duplicatedBranch.authorizedDocumentsRanges
        ? [...duplicatedBranch.authorizedDocumentsRanges].map((authorizedDocuments) => {
            return {
              ...authorizedDocuments,
              authorizedDocumentsRangeId: 0,
            };
          })
        : [];
      return {
        ...state,
        draft: {
          ...duplicatedBranch,
          authorizedDocumentsRanges: [...duplicatedAuthorizedDocuments],
        },
      };
    })
    .addDefaultCase((state) => state);
});
