import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, IPos } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { clearBranch, fetchBranchWithPriceList } from '../branches';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

// Pos Tab action creators
export const addHomePosTab = () =>
  addTab({
    tab: {
      id: 'pos',
      title: intl.formatMessage({ id: 'pos.tabTitle' }),
      path: 'pages/Pos/Pos',
      isRemovable: true,
    },
  });

export const addViewPosTab = (id?: number) =>
  addTab({
    tab: {
      id: 'pos',
      title: intl.formatMessage({ id: 'pos.tabTitle' }),
      path: 'pages/Pos/PosView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewPosTab = () =>
  addTab({
    tab: {
      id: 'pos',
      title: intl.formatMessage({ id: 'pos.tabTitle' }),
      path: 'pages/Pos/PosNew',
      isRemovable: true,
    },
  });

export const addEditPosTab = () =>
  addTab({
    tab: {
      id: 'pos',
      title: intl.formatMessage({ id: 'pos.tabTitle' }),
      path: 'pages/Pos/PosEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearPos = createAction('POS/CLEAR');

export const clearPosDraft = createAction('POS/CLEAR_DRAFT');

export const setPosCurrent = createAction<IPos>('POS/SET_POS');

export const setPosDraft = createAction<IPos>('POS/SET_POS_DRAFT');

export const setPosDraftValues = createAction<Partial<IPos>>('POS/SET_POS_DRAFT_VALUE');

export const setPosList = createAction<IPos[]>('POS/SET_LIST');

export const updatePosList = createAction<IPos>('POS/UPDATE_LIST');

export const removePosFromList = createAction<number>('POS/REMOVE_FROM_LIST');

export const duplicatePos = createAction<IPos>('POS/DUPLICATE');

//Complex actions
export const fetchPosList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'POS/FETCH_POS_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    if (!silent) {
      dispatch(startRequest('pos'));
    }
    try {
      const { data } = await api.pos.fetchPosListAPI();
      dispatch(setPosList(Array.isArray(data) ? data : []));
      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'pos',
          action: 'read',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('pos'));
      }
    }
  },
);

export interface IFetchPosCurrentParams {
  posId: number;
}

export const fetchPosCurrent = createAsyncThunk<void, IFetchPosCurrentParams, AppThunkConfig>(
  'POS/FETCH_POS',
  async ({ posId }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('pos'));
    try {
      const { data } = await api.pos.fetchPosAPI(posId);
      dispatch(setPosCurrent(data));

      if (data.branch?.branchId) {
        dispatch(fetchBranchWithPriceList(data.branch.branchId));
      } else {
        dispatch(clearBranch());
      }
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'pos',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('pos'));
    }
  },
);

export const updatePos = createAsyncThunk<void, IPos, AppThunkConfig>(
  'POS/UPDATE_POS',
  async (pos, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('pos'));
    try {
      const { data } = await api.pos.updatePosAPI(pos);
      dispatch(setPosCurrent(data));
      dispatch(clearPosDraft());
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'pos',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('pos'));
    }
  },
);
