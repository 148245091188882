import { ISalesOrder } from '@laudus/types';

export const SALES_ORDER_EMPTY: ISalesOrder = {
  items: [],
  salesOrderId: undefined,
  docType: null,
  customer: null,
  contact: null,
  salesman: null,
  dealer: null,
  carrier: null,
  priceList: null,
  term: null,
  branch: null,
  warehouse: null,
  issuedDate: undefined,
  dueDate: undefined,
  nullDoc: false,
  deliveryAddress: null,
  deliveryCost: 0,
  deliveryNotes: null,
  deliveryVehiclePlate: null,
  bypassCreditLimit: false,
  notes: null,
  deliveryDate: undefined,
  sourceOrderId: null,
  archived: false,
  locked: false,
  approved: false,
  approvedBy: undefined,
  deliveryTimeFrame: '',
  source: undefined,
  amountPaid: 0,
  amountPaidCurrencyCode: '',
  invoiceDocType: undefined,
};
