import { createReducer } from '@reduxjs/toolkit';

export interface IDocType {
  docTypeId: number;
  name: string;
  family?: string;
  subFamily?: string;
  behavior?: string;
  electronic?: boolean;
  letterCode?: string;
  expImp?: boolean;
}

export interface IDocTypesState {
  list: IDocType[];
}

export const initialDocTypesState: IDocTypesState = {
  list: [
    {
      name: 'Factura',
      docTypeId: 30,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: false,
      letterCode: 'F',
      expImp: false,
    },
    {
      name: 'Factura Exenta',
      docTypeId: 32,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: false,
      letterCode: 'T',
      expImp: false,
    },
    {
      name: 'Factura Electrónica',
      docTypeId: 33,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: true,
      letterCode: 'E',
      expImp: false,
    },
    {
      name: 'Boleta',
      docTypeId: 35,
      family: 'S',
      subFamily: 'T',
      behavior: 'A',
      electronic: false,
      letterCode: 'B',
      expImp: false,
    },
    {
      name: 'Nota de Crédito',
      docTypeId: 60,
      family: 'S',
      subFamily: 'C',
      behavior: 'S',
      electronic: false,
      letterCode: 'C',
      expImp: false,
    },
    {
      name: 'Nota de Crédito Electrónica',
      docTypeId: 61,
      family: 'S',
      subFamily: 'C',
      behavior: 'S',
      electronic: true,
      letterCode: 'I',
      expImp: false,
    },
    {
      name: 'Nota de Débito',
      docTypeId: 55,
      family: 'S',
      subFamily: 'D',
      behavior: 'A',
      electronic: false,
      letterCode: 'D',
      expImp: false,
    },
    {
      name: 'GuÌa de Despacho',
      docTypeId: 50,
      family: 'G',
      subFamily: 'G',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Factura Propia de Compras',
      docTypeId: 45,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: false,
      letterCode: 'P',
      expImp: false,
    },
    {
      name: 'Factura de Exportación',
      docTypeId: 101,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: false,
      letterCode: 'X',
      expImp: true,
    },
    {
      name: 'Nota de Débito Electrónica',
      docTypeId: 56,
      family: 'S',
      subFamily: 'D',
      behavior: 'A',
      electronic: true,
      letterCode: 'J',
      expImp: false,
    },
    {
      name: 'Nota de Crédito de Exportación',
      docTypeId: 106,
      family: 'S',
      subFamily: 'C',
      behavior: 'S',
      electronic: false,
      letterCode: 'Y',
      expImp: true,
    },
    {
      name: 'Nota de Débito de Exportación',
      docTypeId: 104,
      family: 'S',
      subFamily: 'D',
      behavior: 'A',
      electronic: false,
      letterCode: 'Z',
      expImp: true,
    },
    {
      name: 'Liquidación de Factura',
      docTypeId: 40,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: false,
      letterCode: 'L',
      expImp: false,
    },
    {
      name: 'Factura de MercaderÌas no Nacionalizadas',
      docTypeId: 190,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: false,
      letterCode: 'N',
      expImp: true,
    },
    {
      name: 'N de Cr MercaderÌas no Nacionalizadas',
      docTypeId: 191,
      family: 'S',
      subFamily: 'C',
      behavior: 'S',
      electronic: false,
      letterCode: 'O',
      expImp: true,
    },
    {
      name: 'N de Déb MercaderÌas no Nacionalizadas',
      docTypeId: 192,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: false,
      letterCode: 'Q',
      expImp: true,
    },
    {
      name: 'Boleta Exenta',
      docTypeId: 38,
      family: 'S',
      subFamily: 'T',
      behavior: 'A',
      electronic: false,
      letterCode: 'K',
      expImp: false,
    },
    {
      name: 'Factura de Exportación Electrónica',
      docTypeId: 110,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: true,
      letterCode: '1',
      expImp: true,
    },
    {
      name: 'Factura Exenta Electrónica',
      docTypeId: 34,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: true,
      letterCode: '2',
      expImp: false,
    },
    {
      name: 'Otros', //Otros Documentos de Ventas
      docTypeId: 0,
      family: 'S',
      subFamily: ' ',
      behavior: 'A',
      electronic: false,
      letterCode: '0',
      expImp: false,
    },
    {
      name: 'Factura Propia de Compras Electrónica',
      docTypeId: 46,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: true,
      letterCode: '3',
      expImp: false,
    },
    {
      name: 'Declaración de Ingreso (DIN)',
      docTypeId: 914,
      family: 'P',
      subFamily: 'I',
      behavior: 'A',
      electronic: false,
      letterCode: '4',
      expImp: true,
    },
    {
      name: 'GuÌa de Despacho Electrónica',
      docTypeId: 52,
      family: 'G',
      subFamily: 'G',
      behavior: ' ',
      electronic: true,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Factura Exenta a Zona Franca Primaria',
      docTypeId: 102,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: false,
      letterCode: '5',
      expImp: false,
    },
    {
      name: 'Boleta Electrónica',
      docTypeId: 39,
      family: 'S',
      subFamily: 'T',
      behavior: 'A',
      electronic: true,
      letterCode: 'V',
      expImp: false,
    },
    {
      name: 'Boleta Exenta Electrónica',
      docTypeId: 41,
      family: 'S',
      subFamily: 'T',
      behavior: 'A',
      electronic: true,
      letterCode: 'W',
      expImp: false,
    },
    {
      name: 'Rollos M·quinas Registradoras',
      docTypeId: 36,
      family: 'S',
      subFamily: 'T',
      behavior: 'A',
      electronic: false,
      letterCode: 'U',
      expImp: false,
    },
    {
      name: 'Orden de Compra',
      docTypeId: 801,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Nota de Pedido',
      docTypeId: 802,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Contrato',
      docTypeId: 803,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Resolución',
      docTypeId: 804,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'HES',
      docTypeId: 801801,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'SEP (Solicitud Electrónica de Pago)',
      docTypeId: 801701,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Conformidad',
      docTypeId: 801902,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Centro de Costo (Enersis)',
      docTypeId: 801901,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Vales Compr. Pago Electr (Transbank)',
      docTypeId: 48,
      family: 'S',
      subFamily: 'T',
      behavior: 'A',
      electronic: false,
      letterCode: 'A',
      expImp: false,
    },
    {
      name: 'Nota de Déb de Exportación Electrónica',
      docTypeId: 111,
      family: 'S',
      subFamily: 'D',
      behavior: 'A',
      electronic: true,
      letterCode: '7',
      expImp: true,
    },
    {
      name: 'Nota de Créd de Exportación Electrónica',
      docTypeId: 112,
      family: 'S',
      subFamily: 'C',
      behavior: 'S',
      electronic: true,
      letterCode: '6',
      expImp: true,
    },
    {
      name: 'Liquidación de Factura Electrónica',
      docTypeId: 43,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: true,
      letterCode: '8',
      expImp: false,
    },
    {
      name: 'MIGO',
      docTypeId: 801601,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Entrada de MercaderÌa (AES Gener)',
      docTypeId: 801501,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'EP (Estado de Pago)',
      docTypeId: 801401,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Factura Exenta Ley 18.392',
      docTypeId: 901,
      family: 'S',
      subFamily: 'I',
      behavior: 'A',
      electronic: false,
      letterCode: '9',
      expImp: false,
    },
    {
      name: 'OV',
      docTypeId: 801802,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Unidad de Pago (MOP)',
      docTypeId: 801803,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'MLE (Modalidad Libre Elección FONASA)',
      docTypeId: 801804,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Otras Referencias',
      docTypeId: 801999,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Proceso ChileCompra',
      docTypeId: 805,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Ficha ChileCompra',
      docTypeId: 806,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Cód Reg Acuerdos Pago Excepcional',
      docTypeId: 820,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'DUS',
      docTypeId: 807,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'B/L (Conocimiento de embarque)',
      docTypeId: 808,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'AWB (Air Waybill)',
      docTypeId: 809,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'MIC/DTA',
      docTypeId: 810,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Carta de Porte',
      docTypeId: 811,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
    {
      name: 'Resolución del SNA Servicios Exportación',
      docTypeId: 812,
      family: 'R',
      subFamily: ' ',
      behavior: ' ',
      electronic: false,
      letterCode: ' ',
      expImp: false,
    },
  ],
};

export const docTypesReducer = createReducer(initialDocTypesState, (builder) => {
  builder.addDefaultCase((state) => state);
});
