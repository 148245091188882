import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, IUser } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

// Users Tab action creators
export const addHomeUsersTab = () =>
  addTab({
    tab: {
      id: 'users',
      title: intl.formatMessage({ id: 'users.tabTitle' }),
      path: 'pages/User/Users',
      isRemovable: true,
    },
  });

export const addViewUsersTab = (id?: string) =>
  addTab({
    tab: {
      id: 'users',
      title: intl.formatMessage({ id: 'users.tabTitle' }),
      path: 'pages/User/UserView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewUsersTab = () =>
  addTab({
    tab: {
      id: 'users',
      title: intl.formatMessage({ id: 'users.tabTitle' }),
      path: 'pages/User/UserNew',
      isRemovable: true,
    },
  });

export const addEditUsersTab = () =>
  addTab({
    tab: {
      id: 'users',
      title: intl.formatMessage({ id: 'users.tabTitle' }),
      path: 'pages/User/UserEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearUser = createAction('USERS/CLEAR');

export const clearUserDraft = createAction('USERS/CLEAR_DRAFT');

export const setUser = createAction<IUser>('USERS/SET_USER');

export const setUserDraft = createAction<IUser>('USERS/SET_USER_DRAFT');

export const setUserDraftValues = createAction<Partial<IUser>>('USERS/SET_USER_DRAFT_VALUE');

export const setUsersList = createAction<IUser[]>('USERS/SET_LIST');

export const updateUsersList = createAction<IUser>('USERS/UPDATE_LIST');

export const removeUserFromList = createAction<string>('USERS/REMOVE_FROM_LIST');

export const duplicateUser = createAction<IUser>('USERS/DUPLICATE');

// Complex actions

export const fetchUsersList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'USERS/FETCH_USER_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(startRequest('users'));
      }

      const { data } = await api.users.fetchUsersListFromAPI();
      dispatch(setUsersList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'users',
          action: 'read',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('users'));
      }
    }
  },
);

export const fetchUser = createAsyncThunk<void, string, AppThunkConfig>(
  'USERS/FETCH_USER',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('users'));
    try {
      const { data } = await api.users.fetchUserFromAPI(id);
      dispatch(setUser(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'users',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('users'));
    }
  },
);
