export const CodeCategories = {
  SERVICE_INCIDENT_CATEGORIES: { id: 1, name: 'Categorías de Incidencias de Servicio Técnico' },
  WAYBILLS_REASONS: { id: 2, name: 'Motivos de las Guías de Despacho' },
  GOODS_RECEIPT_VENUE_LAW_19983: { id: 3, name: 'Recinto de Recepción de Mercaderías ley 19.983' },
  UNDOCUMENTED_CHARGES_TYPES: { id: 4, name: 'Tipos de cobros sin documento' },
  EMPLOYEE_POSITIONS: { id: 5, name: 'Cargos de los Empleados' },
  CHARGES_TYPES: { id: 6, name: 'Tipos de cobros' },
  CLIENT_HISTORY_LABELS: {
    id: 7,
    name: 'Etiquetas para señalar las líneas del Historial de Clientes',
  },
  BUSINESS_OPPORTUNITY_STATUS: { id: 8, name: 'El estado de la oportunidad de negocio' },
  BUSINESS_OPPORTUNITY_SOURCE: { id: 9, name: 'Cómo llegó la oportunidad a la empresa' },
  EMPLOYEE_PROFESSIONS: { id: 10, name: 'La profesión de los Empleados' },
  EMPLOYEE_TITLES: { id: 11, name: 'El título de los Empleados' },
  EMPLOYEE_QUALIFICATION_GRADES: { id: 12, name: 'El grado de cualificación de los Empleados' },
  PROJECT_STATUS: { id: 13, name: 'Estado del Proyecto' },
  CONCEPTS_TO_INCLUDE_IN_EMPLOYEE_SETTLEMENT: {
    id: 14,
    name: 'Conceptos a incluir en el Finiquito de los Empleados',
  },
  EMPLOYEE_PAYMENT_METHODS: { id: 15, name: 'Formas de pago a los empleados' },
  UNDOCUMENTED_PAYMENTS_TYPES: { id: 16, name: 'Tipos de pagos sin documento' },
  RECURRING_INVOICE_CATEGORY: { id: 17, name: 'Categoría de Factura Recurrente' },
  CASH_INCOME_EXPENSE_TYPES: { id: 18, name: 'Tipos de salidas o ingresos a Cajas' },
  FACTORING_OPERATION_STATUS: { id: 19, name: 'Estados de una operación de factoring' },
  TERMINATION_CAUSES: { id: 20, name: 'Causal de finiquito' },
  AUTHORIZATION_ACTION_CODES: { id: 21, name: 'Códigos para las acciones de autorización' },
  SALES_ORDER_ORIGINS: { id: 22, name: 'Origen de los pedidos de ventas' },
  PAYMENT_TYPES: { id: 23, name: 'Tipos de pagos' },
} as const;
