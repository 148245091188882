import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString } from '@laudus/shared-utils';
import { ILedgerFilterTags, ILedgerFormattedFilters } from '@laudus/types';

import {
  clearLedgerFilter,
  setLedgerFilter,
  setLedgerFilterValues,
  setLedgerTagsFilter,
} from './actions';

export interface ILedgerFilterState {
  ledgerFilter: ILedgerFormattedFilters;
  ledgerTagsFilter: ILedgerFilterTags[];
}

export const LEDGERS_FILTER_EMPTY: ILedgerFormattedFilters = {
  accountNumberFrom: '',
  costCenterId: undefined,
  dateFrom: '1990-01-01T00:00:00',
  dateTo: dateToLocalISOString(new Date()),
  period: 'all',
  groupBy: undefined,
  IFRSType: undefined,
};

export const initialLedgerFilterState: ILedgerFilterState = {
  ledgerFilter: LEDGERS_FILTER_EMPTY,
  ledgerTagsFilter: [],
};

export const ledgerFiltersReducer = createReducer(initialLedgerFilterState, (builder) => {
  builder
    .addCase(clearLedgerFilter, (state) => {
      return { ...state, ledgerFilter: LEDGERS_FILTER_EMPTY };
    })
    .addCase(setLedgerFilter, (state, action) => {
      return { ...state, ledgerFilter: action.payload };
    })
    .addCase(setLedgerFilterValues, (state, action) => {
      return {
        ...state,
        ledgerFilter: { ...state.ledgerFilter, ...action.payload },
      };
    })
    .addCase(setLedgerTagsFilter, (state, action) => {
      return { ...state, ledgerTagsFilter: action.payload };
    })
    .addDefaultCase((state) => state);
});
