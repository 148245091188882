import { IconInfoCircle } from '@laudus/icons';

import { Tooltip } from './Tooltip';

interface IInfoTooltipProps {
  tooltipText: string | JSX.Element;
}

export const InfoTooltip = ({ tooltipText }: IInfoTooltipProps) => {
  return (
    <Tooltip text={tooltipText}>
      <IconInfoCircle color="#bbb4ad" fontSize="14px" />
    </Tooltip>
  );
};
