import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IEtag, ISupplier } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

// Suppliers Tab action creators

export const addHomeSupplierTab = () =>
  addTab({
    tab: {
      id: 'supplier',
      title: intl.formatMessage({ id: 'suppliers.tabTitle' }),
      path: 'pages/Suppliers/Suppliers',
      isRemovable: true,
    },
  });

export const addViewSupplierTab = (id?: number) =>
  addTab({
    tab: {
      id: 'supplier',
      title: intl.formatMessage({ id: 'suppliers.tabTitle' }),
      path: 'pages/Suppliers/SuppliersView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewSupplierTab = () =>
  addTab({
    tab: {
      id: 'supplier',
      title: intl.formatMessage({ id: 'suppliers.tabTitle' }),
      path: 'pages/Suppliers/SuppliersNew',
      isRemovable: true,
    },
  });

export const addEditSupplierTab = () =>
  addTab({
    tab: {
      id: 'supplier',
      title: intl.formatMessage({ id: 'suppliers.tabTitle' }),
      path: 'pages/Suppliers/SuppliersEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearSupplier = createAction('SUPPLIERS/CLEAR');

export const clearSupplierDraft = createAction('SUPPLIERS/CLEAR_DRAFT');

export const setSupplier = createAction<ISupplier>('SUPPLIERS/SET_SUPPLIER');

export const setSupplierDraft = createAction<ISupplier>('SUPPLIERS/SET_SUPPLIER_DRAFT');

export const setSupplierDraftValues = createAction<Partial<ISupplier>>(
  'SUPPLIERS/SET_SUPPLIER_DRAFT_VALUE',
);

export const setSuppliersList = createAction<ISupplier[]>('SUPPLIERS/SET_LIST');

export const updateSuppliersList = createAction<ISupplier>('SUPPLIERS/UPDATE_LIST');

export const removeSupplierFromList = createAction<number>('SUPPLIERS/REMOVE_FROM_LIST');

export const duplicateSupplier = createAction<ISupplier>('SUPPLIERS/DUPLICATE');

// Complex actions
export interface IFetchSuppliersListParams {
  eTag?: IEtag;
}

export const fetchSuppliersList = createAsyncThunk<void, IFetchSuppliersListParams, AppThunkConfig>(
  'SUPPLIERS/FETCH_SUPPLIERS_LIST',
  async ({ eTag }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('suppliers'));
    try {
      const { data } = await api.suppliers.fetchSuppliersListAPI();
      dispatch(setSuppliersList(Array.isArray(data) ? data : []));
      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'suppliers',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('suppliers'));
    }
  },
);

export interface IFetchSuppliersParams {
  supplierId: number;
}

export const fetchSupplier = createAsyncThunk<void, number, AppThunkConfig>(
  'SUPPLIERS/FETCH_SUPPLIER',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('suppliers'));
    try {
      const { data } = await api.suppliers.fetchSupplierAPI(id);
      dispatch(setSupplier(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'suppliers',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('suppliers'));
    }
  },
);
