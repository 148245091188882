import { AxiosResponse } from 'axios';

import { IEtag } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchEtagsAPI = (entities: string[] = []): Promise<AxiosResponse<IEtag[]>> =>
  axios.post(
    `${VITE_API_URL}/tools/etags`,
    {
      entities,
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
