import {
  IEmployee,
  IInitialCompanyParameters,
  IPayrollCalculationNumericValue,
  IPayrollCalculationTextValue,
  IPayrollCalculationValue,
  IPayrollEmployeeCalculationValues,
  IPayrollLine,
  IRemunerationConcept,
  IRemunerationConceptTypeListItem,
} from '@laudus/types';

/**
 * Correspondence between wagesParameters root properties and remunerationConceptTypeId used to pass the initial company parameters
 * in payroll calculation.
 */
const REMUNERATION_CONCEPT_TYPE_ID_TO_COMPANY_PARAMETER_MAP: Record<
  string,
  keyof IInitialCompanyParameters
> = {
  // TODO JPOL: Review this '': SIS,
  UF: 'UF',
  UF_MESANTERIOR: 'UFmesAnterior',
  UTM: 'UTM',
  SALARIOMÍNIMO: 'salarioMinimo',
  TOPEIMPONIBLEAFC: 'topeImponibleAFC',
  TOPEIMPONIBLEAFP: 'topeImponibleAFP',
  TOPEIMPONIBLEIPS: 'topeImponibleIPS',
};

type IEmployeeWithId = Exclude<IEmployee, 'employeeId'> & Required<Pick<IEmployee, 'employeeId'>>;

const isEmployeeWithId = (employee: Partial<IEmployee>): employee is IEmployeeWithId =>
  !!employee.employeeId;

const isPayrollCalculationNumericValue = (
  value: IPayrollCalculationValue,
): value is IPayrollCalculationNumericValue => 'amount' in value;
const isPayrollCalculationTextValue = (
  value: IPayrollCalculationValue,
): value is IPayrollCalculationTextValue => 'text' in value;

export const getRemunerationConceptIdsFromCompanyParameters = (
  remunerationConceptTypeList: IRemunerationConceptTypeListItem[],
) =>
  remunerationConceptTypeList.reduce(
    (acc, conceptType) => {
      const companyParameter =
        REMUNERATION_CONCEPT_TYPE_ID_TO_COMPANY_PARAMETER_MAP[
          conceptType.remunerationConceptTypeId
        ];
      if (companyParameter && conceptType.remunerationConcept_remunerationConceptId) {
        acc[companyParameter] = conceptType.remunerationConcept_remunerationConceptId;
      }
      return acc;
    },
    {} as Record<keyof IInitialCompanyParameters, string>,
  );

export const mapCompanyParametersToRemunerationConcepts = (
  mapType: 'name' | 'id',
  remunerationConceptTypeList: IRemunerationConceptTypeListItem[],
) =>
  remunerationConceptTypeList.reduce(
    (acc, conceptType) => {
      const companyParameter =
        REMUNERATION_CONCEPT_TYPE_ID_TO_COMPANY_PARAMETER_MAP[
          conceptType.remunerationConceptTypeId
        ];
      if (
        companyParameter &&
        mapType === 'id' &&
        conceptType.remunerationConcept_remunerationConceptId
      ) {
        acc[companyParameter] = conceptType.remunerationConcept_remunerationConceptId;
      } else if (companyParameter && mapType === 'name' && conceptType.remunerationConcept_name) {
        acc[companyParameter] = conceptType.remunerationConcept_name;
      }
      return acc;
    },
    {} as Record<keyof IInitialCompanyParameters, string>,
  );

export const getInitialPayrollCalculationValues = (
  companyParameters: IInitialCompanyParameters,
  remunerationConceptTypeList: IRemunerationConceptTypeListItem[],
): IPayrollCalculationValue[] => {
  const companyParametersMap = mapCompanyParametersToRemunerationConcepts(
    'id',
    remunerationConceptTypeList,
  );

  return Object.entries(companyParametersMap).map(([companyParameter, remunerationConceptId]) => ({
    remunerationConceptId,
    amount: companyParameters[companyParameter as keyof IInitialCompanyParameters],
  }));
};

export const getCompanyParametersCalculationValuesFromPayrollLine = (
  line: IPayrollLine,
  companyParameterRemunerationConceptList: IRemunerationConcept[],
  includeUnfixed?: boolean,
): IPayrollCalculationValue[] => {
  const companyParametersConceptIds = companyParameterRemunerationConceptList.map(
    ({ remunerationConceptId }) => remunerationConceptId,
  );

  return line.remunerationAmounts
    .filter(
      ({ remunerationConcept: { remunerationConceptId }, fixedByUser }) =>
        companyParametersConceptIds.includes(remunerationConceptId) &&
        (fixedByUser || includeUnfixed),
    )
    .map(({ remunerationConcept: { remunerationConceptId }, amount }) => ({
      remunerationConceptId,
      amount,
    }));
};

export const generateEmployeeCalculationValuesList = (
  activeEmployeeList: Partial<IEmployee>[],
  initialFixedByUserValues: IPayrollCalculationValue[],
): IPayrollEmployeeCalculationValues[] => {
  return activeEmployeeList.filter(isEmployeeWithId).map(({ employeeId }) => ({
    employeeId,
    fixedAmounts: [
      ...(initialFixedByUserValues.filter((value) =>
        isPayrollCalculationNumericValue(value),
      ) as IPayrollCalculationNumericValue[]),
    ],
    fixedTexts: [
      ...(initialFixedByUserValues.filter((value) =>
        isPayrollCalculationTextValue(value),
      ) as IPayrollCalculationTextValue[]),
    ],
  }));
};

export const updateEmployeeCalculationValuesList = (
  lines: IPayrollLine[],
  fixedByUserValues: IPayrollCalculationValue[],
  includeFixedValuesInLine: boolean,
): IPayrollEmployeeCalculationValues[] => {
  const indexedValues: Record<string, IPayrollCalculationValue> = {};
  for (const value of fixedByUserValues) {
    indexedValues[value.remunerationConceptId] = value;
  }

  return lines.map((line) => ({
    employeeId: line.employee.employeeId,
    fixedAmounts: [
      ...(includeFixedValuesInLine
        ? line.remunerationAmounts
            .filter(
              (remunerationAmount) =>
                remunerationAmount.fixedByUser &&
                !(remunerationAmount.remunerationConcept.remunerationConceptId in indexedValues),
            )
            .map((remunerationAmount) => ({
              remunerationConceptId: remunerationAmount.remunerationConcept.remunerationConceptId,
              amount: remunerationAmount.amount,
            }))
        : []),
      ...(fixedByUserValues.filter((value) =>
        isPayrollCalculationNumericValue(value),
      ) as IPayrollCalculationNumericValue[]),
    ],
    fixedTexts: [
      ...line.remunerationTexts
        .filter(
          (remunerationText) =>
            remunerationText.fixedByUser &&
            !(remunerationText.remunerationConcept.remunerationConceptId in indexedValues),
        )
        .map((remunerationText) => ({
          remunerationConceptId: remunerationText.remunerationConcept.remunerationConceptId,
          text: remunerationText.text,
        })),
      ...(fixedByUserValues.filter((value) =>
        isPayrollCalculationTextValue(value),
      ) as IPayrollCalculationTextValue[]),
    ],
  }));
};

export const calculatePayrollLineTotal = (
  lines: IPayrollLine[],
  valuationFactorsForRemunerationConcepts: Record<string, number>,
): IPayrollLine[] =>
  lines.map((line) => {
    let amountToBePaid = 0;

    for (const {
      amount,
      remunerationConcept: { remunerationConceptId },
    } of line.remunerationAmounts) {
      if (valuationFactorsForRemunerationConcepts[remunerationConceptId]) {
        amountToBePaid += amount * valuationFactorsForRemunerationConcepts[remunerationConceptId];
      }
    }
    return {
      ...line,
      amountToBePaid,
    };
  });
