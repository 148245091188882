import { getMessageFromError } from './getMessageFromError';

export class ErrorSerializer {
  static toString(error: unknown): string {
    return getMessageFromError({ error });
  }

  static fromString(errorMessage: string): Error {
    return new Error(errorMessage);
  }

  static fromStringOrUndefined(errorMessage: string | undefined): Error | undefined {
    if (!errorMessage) {
      return undefined;
    }

    return ErrorSerializer.fromString(errorMessage);
  }
}
