import { useMemo, useRef, useState } from 'react';

import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  CellFocusedEvent,
  ColDef,
  ColumnApi,
  ColumnPinnedType,
  NavigateToNextCellParams,
  RowDoubleClickedEvent,
  SelectionChangedEvent,
  TabToNextCellParams,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { Box, useColorMode } from '@chakra-ui/react';

import { KEY_LEFT, KEY_RIGHT, useIsVisibleOnScreen } from '@laudus/shared-ui';
import { formatGridText } from '@laudus/shared-utils';

import '@ag-grid-enterprise/core';

import { useSizeColumnsToFit } from '../hooks/useSizeColumnsToFit';
import { mainColumnsGridRenderer } from '../utils/mainColumnsGridRenderer';

import { CustomTextFloatingFilter } from './floatingFilters/CustomTextFloatingFilter';
import { withEnterpriseLicense } from './withLicenseManager';
import { withModuleRegistry } from './withModuleRegistry';

const focusFloatingFilterInput = (index = 0) => {
  const filterInputList = document.getElementsByClassName(
    'ag-input-field-input ag-text-field-input',
  );
  if (filterInputList.length && index >= 0 && index < filterInputList.length) {
    (filterInputList[index] as HTMLInputElement).focus();
  }
};

const getFloatingFilterIndex = (columnApi: ColumnApi, colId: string) => {
  let index = 0;
  let found = false;
  const columns = columnApi.getAllGridColumns();
  for (let i = 0; i < columns.length && !found; i++) {
    if (columns[i].getColId() === colId) {
      found = true;
    } else if (columns[i].isVisible()) {
      index++;
    }
  }
  return found ? index : -1;
};

interface IGenericSearchGridProps<RowDataValue extends Record<string, unknown>> {
  rowData: RowDataValue[];
  rowDataIdentifier?: keyof RowDataValue;
  columnDefs: ColDef[];
  onSelectionChanged: (event: SelectionChangedEvent) => void;
  onSelectionDoubleClicked: (event: RowDoubleClickedEvent) => void;
  getRowClass?: () => string;
  rowMultiSelectWithClick?: boolean; // MULTI-SELECTED CKECKBOX
  className?: string;
  brownHeader?: boolean;
  autoColumnWidth?: boolean;
  sizeColumnsToFit?: boolean;
}

interface ICellParams {
  value: number | string;
  rowIndex: number;
  colDef: { field: string };
}

const GenericSearchGridComponent = ({
  rowData,
  rowDataIdentifier,
  columnDefs: _columnDefs,
  onSelectionChanged,
  onSelectionDoubleClicked,
  getRowClass,
  rowMultiSelectWithClick = false,
  className,
  brownHeader = false,
  autoColumnWidth = false,
  sizeColumnsToFit = true,
}: IGenericSearchGridProps<Record<string, unknown>>) => {
  const gridRef = useRef<AgGridReact>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const isVisible = useIsVisibleOnScreen(containerRef);

  const { colorMode } = useColorMode();

  const [ready, setReady] = useState(false);
  const handleGridReady = () => {
    setReady(true);
  };

  let themeClassName = className;
  if (!themeClassName) {
    themeClassName =
      colorMode === 'dark' ? 'ag-header-brown ag-theme-alpine-dark' : 'ag-theme-laudus-search';
  }
  const headerClassName = colorMode === 'dark' && brownHeader ? 'ag-header-brown' : '';

  // Controls where stop focusing.
  const disableFocusFilterRef = useRef(false);

  const disableFilterAutofocus = () => {
    if (!disableFocusFilterRef.current) {
      disableFocusFilterRef.current = true;
    }
  };

  const handleCellArrowNavigation = ({
    columnApi,
    key,
    previousCellPosition,
    nextCellPosition,
  }: NavigateToNextCellParams) => {
    disableFilterAutofocus();

    // Focus filter input if we are in the first row.
    if (nextCellPosition?.rowIndex === -1) {
      const colIndex = getFloatingFilterIndex(columnApi, previousCellPosition.column.getColId());
      focusFloatingFilterInput(colIndex);
      return null;
    }
    // Ignore horizontal cell movements
    if (key === KEY_LEFT || key === KEY_RIGHT) {
      return null;
    }
    return nextCellPosition;
  };

  const handleCellTabNavigation = ({
    api,
    backwards,
    columnApi,
    previousCellPosition,
  }: TabToNextCellParams) => {
    // Disable filter auto focus.
    disableFilterAutofocus();
    const lastRowIndex = previousCellPosition.rowIndex;
    let nextRowIndex = backwards ? lastRowIndex - 1 : lastRowIndex + 1;
    const renderedRowCount = api.getModel().getRowCount();
    if (nextRowIndex < 0) {
      const colIndex = getFloatingFilterIndex(columnApi, previousCellPosition.column.getColId());
      focusFloatingFilterInput(colIndex);
      return null;
    }
    if (nextRowIndex >= renderedRowCount) {
      nextRowIndex = renderedRowCount - 1;
    }
    const result = {
      rowIndex: nextRowIndex,
      column: previousCellPosition.column,
      rowPinned: previousCellPosition.rowPinned,
    };
    return result;
  };

  const handlePaginationChanged = () => {
    if (!disableFocusFilterRef.current) {
      focusFloatingFilterInput();
    }
  };

  const handleSelectionChanged = (event: SelectionChangedEvent) => {
    disableFilterAutofocus();
    onSelectionChanged(event);
  };

  const selectRow = ({ api, rowIndex }: CellFocusedEvent) => {
    if (rowIndex !== null) {
      api.getDisplayedRowAtIndex(rowIndex)?.setSelected(true);
    }
  };

  // Add support for resizing columns on window resize
  useSizeColumnsToFit({
    gridApi: ready ? gridRef.current?.api : undefined,
    sizeColumnsToFit,
    containerRef,
  });

  // We need to ensure the table is only resized once when it's first initialised, otherwise the
  // event logic will lead to loops and poor user experience.
  const [isAutoResized, setIsAutoResized] = useState(false);

  // This handle is triggered from a number of table ready / change evenets, the firs time all out
  // conditions are validated we will call the grid autoSizeColumn function.
  const handleAutoSizeColumns = async () => {
    if (
      isVisible &&
      gridRef.current?.columnApi &&
      autoColumnWidth &&
      !isAutoResized &&
      rowData.length
    ) {
      gridRef.current?.columnApi.autoSizeAllColumns();
      setIsAutoResized(true);
    }
  };

  // If no other column is pinned then we pin the first column by default
  const columnDefs = useMemo(() => {
    if (_columnDefs.some((col) => col.pinned)) {
      return _columnDefs;
    }
    return [
      { ..._columnDefs?.[0], pinned: 'left' as ColumnPinnedType },
      ...(_columnDefs?.slice(1) ?? []),
    ];
  }, [_columnDefs]);

  return (
    <Box ref={containerRef} className={`${themeClassName} ${headerClassName}`} height="100%">
      <AgGridReact
        ref={gridRef}
        columnDefs={columnDefs}
        defaultColDef={{
          cellRenderer: (params: ICellParams) => {
            return mainColumnsGridRenderer(params);
          },
          filter: 'agTextColumnFilter',
          filterParams: {
            caseSensitive: false,
            textFormatter: formatGridText,
          },
          floatingFilter: true,
          floatingFilterComponent: CustomTextFloatingFilter,
          floatingFilterComponentParams: {
            suppressFilterButton: true,
          },
          cellStyle: { textAlign: 'left' },
        }}
        getRowId={rowDataIdentifier ? (params) => params.data?.[rowDataIdentifier] : undefined}
        navigateToNextCell={handleCellArrowNavigation}
        onCellFocused={selectRow}
        onFirstDataRendered={handleAutoSizeColumns}
        onFilterChanged={disableFilterAutofocus}
        onGridReady={handleGridReady}
        onPaginationChanged={handlePaginationChanged}
        onRowDoubleClicked={onSelectionDoubleClicked}
        onSelectionChanged={handleSelectionChanged}
        overlayNoRowsTemplate={'<span style="display: none;"></span>'}
        rowData={rowData}
        rowSelection="single"
        scrollbarWidth={8}
        skipHeaderOnAutoSize={true}
        tabToNextCell={handleCellTabNavigation}
        getRowClass={getRowClass}
        suppressDragLeaveHidesColumns={true}
        rowMultiSelectWithClick={rowMultiSelectWithClick}
      />
    </Box>
  );
};

const GenericSearchGridWithEnterpriseLicense = withEnterpriseLicense(GenericSearchGridComponent);

export const GenericSearchGrid = withModuleRegistry([ClientSideRowModelModule])(
  GenericSearchGridWithEnterpriseLicense,
);
