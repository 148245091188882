import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  ICustomField,
  ISalesOrder,
  ISalesWaybill,
  ISalesWaybillItem,
  IUser,
  QuantityDelivered,
  TRACE_FROM_STEP_ORIGIN,
} from '@laudus/types';

import { SALES_WAYBILL_EMPTY } from '../../constants/salesWaybills';

import {
  copyCustomFieldsFromOneEntityToAnother,
  copyNotesFromSalesDocumentToAnother,
  ensureSalesDocumentCustomerMatch,
  ensureSalesDocumentIsNotNulled,
  ensureSalesDocumentIsNotSentToSII,
  ensureSalesDocumentMatchTheUserBranchRestriction,
  getLineWithTheSameProduct,
  getQuantityDeliveredOfSalesItem,
  getQuantityToCopyToSalesItem,
} from './genericFunctions';

export async function createSalesWaybillFromSalesOrder({
  initialSalesWaybill = { ...SALES_WAYBILL_EMPTY },
  salesOrder,
  user,
  customFieldsForSalesWaybills = [],
  customFieldsForSalesWaybillsItems = [],
  customFieldsForSalesOrders = [],
  customFieldsForSalesOrdersItems = [],
  shouldCopyNotesToSalesWaybill = false,
  quantityDeliveredFromOrder = [],
  groupItemsWithTheSameProduct = false,
  maxItemsInSalesWaybills,
  allowDTE = false,
}: {
  initialSalesWaybill?: ISalesWaybill;
  salesOrder: ISalesOrder;
  user: IUser;
  customFieldsForSalesWaybills?: ICustomField[];
  customFieldsForSalesWaybillsItems?: ICustomField[];
  customFieldsForSalesOrders?: ICustomField[];
  customFieldsForSalesOrdersItems?: ICustomField[];
  shouldCopyNotesToSalesWaybill?: boolean;
  quantityDeliveredFromOrder?: QuantityDelivered;
  groupItemsWithTheSameProduct?: boolean;
  maxItemsInSalesWaybills?: number;
  allowDTE?: boolean;
}): Promise<ISalesWaybill> {
  // Those can throw an error
  ensureSalesDocumentIsNotNulled({ salesDocument: salesOrder });
  ensureOrderWasNotAlreadyAddedToWaybill({
    salesWaybill: initialSalesWaybill,
    salesOrder,
  });
  ensureSalesDocumentIsNotSentToSII({ salesDocument: initialSalesWaybill });
  ensureSalesDocumentMatchTheUserBranchRestriction({
    user,
    salesDocument: salesOrder,
  });
  ensureSalesDocumentCustomerMatch({
    firstSalesDocument: initialSalesWaybill,
    secondSalesDocument: salesOrder,
  });

  // Start filling the sales waybill
  const salesWaybill = { ...initialSalesWaybill };

  // Fill the sales waybill fields from the sales order fields
  copyHeaderFieldsFromOrderToWaybill({ salesOrder, salesWaybill });
  copyCustomFieldsFromOneEntityToAnother({
    entityToGetCustomFieldsFrom: salesOrder,
    customFieldsForEntityToGetCustomFieldsFrom: customFieldsForSalesOrders,
    entityToCopyCustomFieldsInto: salesWaybill,
    customFieldsForEntityToCopyCustomFieldsInto: customFieldsForSalesWaybills,
  });

  if (shouldCopyNotesToSalesWaybill) {
    copyNotesFromSalesDocumentToAnother({
      originSalesDocument: salesOrder,
      newSalesDocument: salesWaybill,
    });
  }

  assignDocTypeToSalesWaybill({
    salesWaybill,
    allowDTE,
  });

  // Fill the sales waybill items from the sales order items
  await copyItemsFromOrderToWaybill({
    salesOrder,
    salesWaybill: salesWaybill,
    quantityDelivered: quantityDeliveredFromOrder,
    groupItemsWithTheSameProduct,
    maxItemsInSalesWaybills: maxItemsInSalesWaybills,
    customFieldsForSalesWaybillsItems: customFieldsForSalesWaybillsItems,
    customFieldsForSalesOrdersItems,
  });

  // Return the filled sales waybill
  return salesWaybill;
}

function copyHeaderFieldsFromOrderToWaybill({
  salesOrder,
  salesWaybill,
}: {
  salesOrder: ISalesOrder;
  salesWaybill: ISalesWaybill;
}) {
  const fieldsToCopy: (keyof ISalesOrder & keyof ISalesWaybill)[] = [
    'customer',
    'contact',
    'salesman',
    'dealer',
    'term',
    'priceList',
    'branch',
    'purchaseOrderNumber',
    'deliveryCost',
    'deliveryNotes',
    'carrier',
    'deliveryAddress',
  ];

  fieldsToCopy.forEach((salesOrderProperty) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (salesWaybill as any)[salesOrderProperty] = salesOrder[salesOrderProperty];
  });
}

function assignDocTypeToSalesWaybill({
  salesWaybill,
  allowDTE,
}: {
  salesWaybill: ISalesWaybill;
  allowDTE: boolean;
}) {
  // Default to standard waybill
  salesWaybill.docType = {
    docTypeId: 50,
    name: '',
  };

  // Convert from standard to electronic types if possible
  if (allowDTE) {
    salesWaybill.docType.docTypeId = 52;
  }
}

async function copyItemsFromOrderToWaybill({
  salesOrder,
  salesWaybill,
  quantityDelivered,
  groupItemsWithTheSameProduct,
  maxItemsInSalesWaybills,
  customFieldsForSalesOrdersItems,
  customFieldsForSalesWaybillsItems,
}: {
  salesOrder: ISalesOrder;
  salesWaybill: ISalesWaybill;
  quantityDelivered: QuantityDelivered;
  groupItemsWithTheSameProduct?: boolean;
  maxItemsInSalesWaybills?: number;
  customFieldsForSalesOrdersItems: ICustomField[];
  customFieldsForSalesWaybillsItems: ICustomField[];
}) {
  const salesWaybillItems: ISalesWaybillItem[] = [];
  const salesOrderItems = salesOrder.items ?? [];

  // Get only salesOrderItems that haven't been completely delivered
  const salesOrderItemsThatHaventBeenCompletelyDelivered = salesOrderItems.filter(
    (salesOrderItem) => {
      const itemQuantityDelivered = getQuantityDeliveredOfSalesItem({
        quantityDelivered,
        salesItem: salesOrderItem,
      });

      const hasItemBeenCompletelyDelivered =
        !!itemQuantityDelivered && itemQuantityDelivered.delivered >= salesOrderItem.quantity;

      return !hasItemBeenCompletelyDelivered;
    },
  );

  // Copy each item
  for (const salesOrderItem of salesOrderItemsThatHaventBeenCompletelyDelivered) {
    const quantityToCopy = getQuantityToCopyToSalesItem({
      quantityDelivered,
      salesItem: salesOrderItem,
    });

    // Lets try to find a line with the same product and add the quantity
    if (groupItemsWithTheSameProduct) {
      const lineWithTheSameProduct = getLineWithTheSameProduct({
        salesItems: salesWaybillItems,
        salesItem: salesOrderItem,
      });

      if (lineWithTheSameProduct) {
        lineWithTheSameProduct.quantity += quantityToCopy;
        continue;
      }
    }

    // Create a new item and fill its information
    const { archived, ...sharedPropertiesBetweenOrderItemAndWaybillItem } = salesOrderItem;

    const newSalesWaybillItem: ISalesWaybillItem = {
      ...sharedPropertiesBetweenOrderItemAndWaybillItem,
      quantity: quantityToCopy,
      itemId: getNegativeUniqueNumericId(),
      traceFrom: [
        {
          fromId: salesOrderItem.itemId,
          fromStep: TRACE_FROM_STEP_ORIGIN.ORDERS,
        },
      ],
    };

    copyCustomFieldsFromOneEntityToAnother({
      entityToGetCustomFieldsFrom: salesOrderItem,
      customFieldsForEntityToGetCustomFieldsFrom: customFieldsForSalesOrdersItems,
      entityToCopyCustomFieldsInto: newSalesWaybillItem,
      customFieldsForEntityToCopyCustomFieldsInto: customFieldsForSalesWaybillsItems,
    });

    salesWaybillItems.push(newSalesWaybillItem);
  }

  // Add new items
  const newSalesWaybillsItems = salesWaybillItems.concat(salesWaybill.items ?? []);

  if (maxItemsInSalesWaybills && newSalesWaybillsItems.length > maxItemsInSalesWaybills) {
    throw new Error(
      `La guía de despacho contiene más items (${newSalesWaybillsItems.length}) que la cantidad máxima permitida (${maxItemsInSalesWaybills})`,
    );
  }

  salesWaybill.items = newSalesWaybillsItems;
}

function ensureOrderWasNotAlreadyAddedToWaybill({
  salesWaybill,
  salesOrder,
}: {
  salesWaybill: ISalesWaybill;
  salesOrder: ISalesOrder;
}) {
  const salesOrderItemsIds = salesOrder.items.map((salesOrderItem) => salesOrderItem.itemId);
  const wasOrderAlreadyAdded = salesWaybill.items.some((salesWaybillItem) =>
    salesWaybillItem.traceFrom?.some(
      (trace) =>
        trace.fromStep === TRACE_FROM_STEP_ORIGIN.ORDERS &&
        salesOrderItemsIds.includes(trace.fromId),
    ),
  );

  if (wasOrderAlreadyAdded) {
    throw new Error('Un pedido no se puede usar múltiples veces en la misma cotización');
  }
}
