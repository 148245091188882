import { forwardRef } from 'react';

import { getRemunerationConceptList, useAppSelector } from '@laudus/redux-app';

import { SelectEditor } from './SelectEditor';

const SelectRemunerationConceptsEditorComponent: React.ForwardRefRenderFunction<any, any> = (
  props,
  ref,
) => {
  const remunerationConceptList = useAppSelector(getRemunerationConceptList);

  const options = [
    { label: '', value: '' },
    ...remunerationConceptList
      .map((remunerationConcept) => ({
        label: remunerationConcept.name as string,
        value: remunerationConcept.remunerationConceptId as string,
      }))
      .sort((a, b) => a.label.localeCompare(b.label)),
  ];

  return <SelectEditor {...props} options={options} ref={ref} />;
};

SelectRemunerationConceptsEditorComponent.displayName = 'SelectRemunerationConceptEditorComponent';

export const SelectRemunerationConceptEditor = forwardRef(
  SelectRemunerationConceptsEditorComponent,
);
