import { IPrintTemplateParams } from '../types';

export type IRenderFunction<T extends IPrintTemplateParams> = (params: T) => Promise<string>;

export const templateIndex: Record<
  string,
  () => Promise<{
    // TODO: Review this any type
    renderPDF: IRenderFunction<any>;
  }>
> = {
  salesInvoice_39_1: () => import('./salesInvoice39_1'),
  salesInvoice_39_2: () => import('./salesInvoice39_2'),
  salesInvoice_39_3: () => import('./salesInvoice39_3'),
  salesOrder_1: () => import('./salesOrder_1'),
};
