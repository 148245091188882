import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../..';

import { IDashboardsState } from './reducer';

export const getDashboardSlice = (state: AppState): IDashboardsState => state.dashboards;

export const getDashboardList = createSelector([getDashboardSlice], (state) => state.list);

export const getDashboardById = (dashboardId: number) =>
  createSelector([getDashboardList], (state) => state.find((d) => d.dashboardId === dashboardId));

export const getDashboardListFetchTimestamp = createSelector(
  [getDashboardSlice],
  (state) => state.listFetchTimestamp,
);

export const isDashboardCardLoading = (cardId: number) =>
  createSelector([getDashboardSlice], (state) => state.cardsLoading.includes(cardId));
