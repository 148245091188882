import { AxiosResponse } from 'axios';

import { IBank } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchBanksListAPI = (): Promise<AxiosResponse<IBank[]>> =>
  axios.post(
    `${VITE_API_URL}/accounting/banks/list`,
    {
      fields: ['bankId', 'name', 'currencyCode'],
      orderBy: [
        {
          field: 'bankId',
          direction: 'ASC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchBankAPI = (bankId: string): Promise<AxiosResponse<IBank>> =>
  axios.get(`${VITE_API_URL}/accounting/banks/${bankId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createBankAPI = (bank: IBank): Promise<AxiosResponse<IBank>> =>
  axios.post(`${VITE_API_URL}/accounting/banks`, bank);

export const updateBankAPI = (bank: IBank): Promise<AxiosResponse<IBank>> =>
  axios.put(`${VITE_API_URL}/accounting/banks/${bank.bankId}`, bank);

export const deleteBankAPI = (bankId: string): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/accounting/banks/${bankId}`);
