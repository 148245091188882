import { IProductStock, IProductStockByWarehousesViewModel, IWarehouse } from '@laudus/types';

const translateWarehouses = (warehouseId: string, warehouseList: IWarehouse[]) => {
  const selectedWarehose = warehouseList.filter(
    (warehouse) => warehouse.warehouseId === warehouseId,
  );
  return selectedWarehose.length ? selectedWarehose[0].name : 'Sin bodega';
};

export const translateWarehouseIdToWarehouseNameFromArray = (
  values: IProductStock | undefined,
  warehouseList: IWarehouse[],
) => {
  const newArray: IProductStockByWarehousesViewModel[] = [];

  if (values?.warehouses.length) {
    values.warehouses.map((item) => {
      const thisItem = {
        ...item,
        name: translateWarehouses(item.warehouseId || '', warehouseList),
      };
      newArray.push(thisItem);
    });
  }
  return newArray;
};
