import { AxiosResponse } from 'axios';

import {
  IBankReconciliation,
  IBankStatementsReconciliationItem,
  IJournalReconciliationItem,
  IncludeOtherBankStatementsTypeEnum,
} from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchBankStatementNotInJournalFromAPI = (
  bankStatementId: number,
  includeOtherBankStatements: IncludeOtherBankStatementsTypeEnum,
): Promise<AxiosResponse<IBankReconciliation<IBankStatementsReconciliationItem>>> =>
  axios.get(
    `${VITE_API_URL}/accounting/reconciliation/bankStatementNotInJournal?bankStatementId=${bankStatementId}&includeOtherBankStatements=${includeOtherBankStatements}`,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchJournalNotInBankStatementFromAPI = (
  bankStatementId: number,
  includeOtherBankStatements: IncludeOtherBankStatementsTypeEnum,
): Promise<AxiosResponse<IBankReconciliation<IJournalReconciliationItem>>> =>
  axios.get(
    `${VITE_API_URL}/accounting/reconciliation/journalNotInBankStatement?bankStatementId=${bankStatementId}&includeOtherBankStatements=${includeOtherBankStatements}`,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
