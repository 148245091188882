import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ICellEditorParams } from '@ag-grid-community/core';
import { useColorMode } from '@chakra-ui/react';

import { colors, KEY_TAB } from '@laudus/shared-ui';

interface ITextareaEditorComponentProps extends ICellEditorParams {
  name: string;
  rows: number;
}

const TextareaEditorComponent: React.ForwardRefRenderFunction<
  any,
  ITextareaEditorComponentProps
> = (props, ref) => {
  const inputRef = useRef<HTMLTextAreaElement>(null);

  const [value, setValue] = useState<string>(props.value);

  const { colorMode } = useColorMode();

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event: any) => {
    // Any of these keys represent the users intent to movement away from the current cell and
    // therefore we must commit the value so that it's persisted to state.
    // Requested by Javier: In this case KEY_ENTER has been removed because this key is the
    // natural way of introduce new lines in a text area.
    if (event.key === KEY_TAB) {
      event.preventDefault();
      setValue(value);
      props.context.handleKeyPress(event);
      return;
    }
  };

  useEffect(() => {
    inputRef?.current?.focus();

    inputRef?.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      inputRef?.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [value]);

  return (
    <textarea
      name={props.name}
      onChange={handleChange}
      ref={inputRef}
      rows={props.rows}
      style={{
        // @ts-expect-error actualWidth is a private member, not sure why?
        width: props.column?.actualWidth,
        boxSizing: 'border-box',
        margin: 0,
        color: colorMode === 'dark' ? colors.white : colors.black,
        background: colorMode === 'dark' ? colors.darkGrey500 : colors.white,
      }}
      value={value}
    />
  );
};

TextareaEditorComponent.displayName = 'TextareaEditorComponent';

export const TextareaEditor = forwardRef(TextareaEditorComponent);
