import { createReducer } from '@reduxjs/toolkit';

import { IFormState, IProductCategory } from '@laudus/types';

import {
  clearProductCategory,
  clearProductCategoryDraft,
  duplicateProductCategory,
  removeProductCategoryFromList,
  resetProductCategorySubmit,
  setProductCategory,
  setProductCategoryDraft,
  setProductCategoryDraftValues,
  setProductCategoryList,
  setProductCategorySubmitError,
  setProductCategorySubmitSuccess,
  setProductCategorySubmitting,
  updateProductCategoryList,
} from './actions';

const initialFormState = {
  submitting: false,
  submitError: false,
  submitSuccess: false,
};

export interface IProductCategoryState {
  current: IProductCategory;
  draft: IProductCategory;
  list: IProductCategory[];
  formState: IFormState;
}

export const PRODUCTCATEGORY_EMPTY: IProductCategory = {
  fullPath: '',
  name: '',
  parentId: '',
  productCategoryId: '',
};

export const initialProductCategoriesState: IProductCategoryState = {
  current: PRODUCTCATEGORY_EMPTY,
  draft: PRODUCTCATEGORY_EMPTY,
  formState: initialFormState,
  list: [],
};

export const productCategoriesReducer = createReducer(initialProductCategoriesState, (builder) => {
  builder
    .addCase(clearProductCategory, (state) => {
      return { ...state, current: PRODUCTCATEGORY_EMPTY };
    })
    .addCase(setProductCategory, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setProductCategoryList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(clearProductCategoryDraft, (state) => {
      return { ...state, draft: PRODUCTCATEGORY_EMPTY };
    })
    .addCase(setProductCategoryDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setProductCategoryDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(updateProductCategoryList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((d) => d.productCategoryId !== action.payload.productCategoryId),
          action.payload,
        ],
      };
    })
    .addCase(removeProductCategoryFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.productCategoryId !== action.payload),
      };
    })
    .addCase(duplicateProductCategory, (state, action) => {
      const { productCategoryId, ...duplicatedProductCategory } = action.payload;
      return { ...state, current: duplicatedProductCategory };
    })
    .addCase(resetProductCategorySubmit, (state) => {
      return { ...state, formState: initialFormState };
    })
    .addCase(setProductCategorySubmitting, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitting: true },
      };
    })
    .addCase(setProductCategorySubmitError, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitError: true },
      };
    })
    .addCase(setProductCategorySubmitSuccess, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitSuccess: true },
      };
    })
    .addDefaultCase((state) => state);
});
