import {
  IDashboard,
  IDashboardCard,
  IDashboardCardContent,
  IDashboardCardFilter,
  IDashboardCardPositionObject,
  ISerializedDashboard,
  ISerializedDashboardCard,
  ISerializedDashboardCardFilter,
  IStatisticsFormattedFilterItems,
} from '@laudus/types';

export const parseDashboardCardFilter = (
  filter: ISerializedDashboardCardFilter,
): IDashboardCardFilter => {
  let filterValue: IStatisticsFormattedFilterItems[keyof IStatisticsFormattedFilterItems];
  try {
    filterValue = JSON.parse(filter.filterValue);
  } catch (e) {
    filterValue = filter.filterValue;
  }
  return {
    ...filter,
    filterValue,
  };
};

export const serializeDashboardCardFilter = (
  filter: IDashboardCardFilter,
): ISerializedDashboardCardFilter => ({
  ...filter,
  filterValue:
    filter.filterTag === 'period' || filter.filterTag.endsWith('Id')
      ? (filter.filterValue as string)
      : JSON.stringify(filter.filterValue),
  // TODO Uncomment this after dashboard customer data migration
  // filterValue: JSON.stringify(filter.filterValue),
});

export const parseDashboardCard = (card: ISerializedDashboardCard): IDashboardCard => {
  let position: IDashboardCardPositionObject = {};
  let content: IDashboardCardContent = {};
  if (card.position) {
    position = JSON.parse(card.position);
  }
  if (card.content) {
    content = JSON.parse(card.content);
  }
  const statisticId = card.statisticId ?? 0;
  return {
    ...card,
    position,
    content,
    statisticId,
    filters: card.filters.map(parseDashboardCardFilter),
  };
};

export const serializeDashboardCard = (card: IDashboardCard): ISerializedDashboardCard => ({
  ...card,
  position: JSON.stringify(card.position),
  content: JSON.stringify(card.content),
  filters: card.filters.map(serializeDashboardCardFilter),
});

export const parseDashboard = (dashboard: ISerializedDashboard): IDashboard => {
  return {
    ...dashboard,
    cards: dashboard.cards?.map(parseDashboardCard) ?? [],
  };
};

export const serializeDashboard = (dashboard: IDashboard): ISerializedDashboard => ({
  ...dashboard,
  cards: dashboard.cards.map(serializeDashboardCard),
});
