import { intl } from '@laudus/intl';
import { IReportsLeftMenuItem, ReportsLeftMenuItemTypes } from '@laudus/types';

export const ACCOUNTING_BALANCE_SHEETS_REPORTS: IReportsLeftMenuItem[] = [
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.classifiedBalance' }),
    config: {
      name: 'balance de situación',
      type: 'pdf',
      filters: [
        'dateTo',
        'costCenter',
        'bookId',
        'showAccountsWithZeroBalance',
        'showOnlyAccountsWithActivity',
        'showLevels',
        {
          label: intl.formatMessage({ id: 'reports.report.header.foiled' }),
          filters: ['startPage', 'printHeader'],
        },
      ],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.classifiedBalanceModel2' }),
    config: {
      name: 'balance de situación modelo 2',
      type: 'pdf',
      filters: [
        'dateTo',
        'costCenter',
        'bookId',
        'showAccountsWithZeroBalance',
        'showOnlyAccountsWithActivity',
        'showLevels',
        {
          label: intl.formatMessage({ id: 'reports.report.header.foiled' }),
          filters: ['startPage', 'printHeader'],
        },
      ],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.sumBalance' }),
    config: {
      name: 'balance de sumas y saldos',
      type: 'pdf',
      filters: [
        'dateTo',
        'costCenter',
        'bookId',
        'showAccountsWithZeroBalance',
        'showOnlyAccountsWithActivity',
        'showLevels',
        {
          label: intl.formatMessage({ id: 'reports.report.header.foiled' }),
          filters: ['startPage', 'printHeader'],
        },
      ],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.8ColumnTaxBalance' }),
    config: {
      name: 'balance tributario',
      type: 'pdf',
      filters: [
        'dateTo',
        'costCenter',
        'bookId',
        'showAccountsWithZeroBalance',
        'showOnlyAccountsWithActivity',
        'showLevels',
        {
          label: intl.formatMessage({ id: 'reports.report.header.foiled' }),
          filters: ['startPage', 'printHeader'],
        },
      ],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.incomeStatement' }),
    config: {
      name: 'cuenta de resultados tipo FECU',
      type: 'pdf',
      filters: [
        'dateTo',
        'costCenter',
        'bookId',
        'showAccountsWithZeroBalance',
        'showOnlyAccountsWithActivity',
        'showLevels',
        {
          label: intl.formatMessage({ id: 'reports.report.header.foiled' }),
          filters: ['startPage', 'printHeader'],
        },
      ],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.2ColumnIncomeStatement' }),
    config: {
      name: 'cuenta de resultados',
      type: 'pdf',
      filters: [
        'dateTo',
        'costCenter',
        'bookId',
        'showAccountsWithZeroBalance',
        'showOnlyAccountsWithActivity',
        'showLevels',
        {
          label: intl.formatMessage({ id: 'reports.report.header.foiled' }),
          filters: ['startPage', 'printHeader'],
        },
      ],
    },
  },
];
