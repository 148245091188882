import { createAction } from '@reduxjs/toolkit';

import { IAccordion, IFocusedCell, IGlobalTableState, ITabs } from './reducer';

export const startRequest = createAction<string>('GLOBAL/REQUEST_START');

export const endRequest = createAction<string>('GLOBAL/REQUEST_END');

export const startRequestInTab = createAction<string>('GLOBAL/REQUEST_START_IN_TAB');

export const endRequestInTab = createAction<string>('GLOBAL/REQUEST_END_IN_TAB');

export const clearMenuTab = createAction('GLOBAL/CLEAR_MENU_TAB');

export const setMenuTab = createAction<Partial<ITabs>>('GLOBAL/SET_MENU_TAB');

export const setAccordion = createAction<Partial<IAccordion>>('GLOBAL/SET_ACCORDION');

export const setFocusedCell = createAction<IFocusedCell>('GLOBAL/SET_FOCUSED_CELL');

export const startProgress = createAction('GLOBAL/PROGRESS_START');

export const endProgress = createAction('GLOBAL/PROGRESS_END');

export const setTableState = createAction<{
  tableId: string;
  state: IGlobalTableState;
}>('GLOBAL/SET_TABLE_STATE');
