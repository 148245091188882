import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IPosAdjustment } from '@laudus/types';

import { AppState, AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { getPosCurrent } from '../pos/selectors';
import { addTab } from '../tabs';

// Pos Tab action creators
export const addHomePosAdjustmentTab = () =>
  addTab({
    tab: {
      id: 'posAdjustment',
      title: intl.formatMessage({ id: 'posAdjustment.tabTitle' }),
      path: 'pages/PosAdjustments/PosAdjustment',
      isRemovable: true,
    },
  });

export const addViewPosAdjustmentTab = (id?: number) =>
  addTab({
    tab: {
      id: 'posAdjustment',
      title: intl.formatMessage({ id: 'posAdjustment.tabTitle' }),
      path: 'pages/PosAdjustments/PosAdjustmentView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewPosAdjustmentTab = () =>
  addTab({
    tab: {
      id: 'posAdjustment',
      title: intl.formatMessage({ id: 'posAdjustment.tabTitle' }),
      path: 'pages/PosAdjustments/PosAdjustmentNew',
      isRemovable: true,
    },
  });

export const addEditPosAdjustmentTab = () =>
  addTab({
    tab: {
      id: 'posAdjustment',
      title: intl.formatMessage({ id: 'posAdjustment.tabTitle' }),
      path: 'pages/PosAdjustments/PosAdjustmentEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearPosAdjustment = createAction('POS_ADJUSTMENTS/CLEAR');

export const clearPosAdjustmentDraft = createAction('POS_ADJUSTMENTS/CLEAR_DRAFT');

export const setPosAdjustment = createAction<IPosAdjustment>('POS_ADJUSTMENTS/SET_POS_ADJUSTMENT');

export const setPosAdjustmentDraft = createAction<IPosAdjustment>(
  'POS_ADJUSTMENTS/SET_POS_ADJUSTMENT_DRAFT',
);
export const setPosAdjustmentDraftValues = createAction<Partial<IPosAdjustment>>(
  'POS_ADJUSTMENTS/SET_POS_ADJUSTMENT_DRAFT_VALUE',
);

export const setPosAdjustmentsList = createAction<IPosAdjustment[]>('POS_ADJUSTMENTS/SET_LIST');

export const updatePosAdjustmentsList = createAction<IPosAdjustment>('POS_ADJUSTMENTS/UPDATE_LIST');

export const removePosAdjustmentFromList = createAction<number>('POS_ADJUSTMENTS/REMOVE_FROM_LIST');

export const duplicatePosAdjustment = createAction<IPosAdjustment>('POS_ADJUSTMENTS/DUPLICATE');

export const resetPosAdjustmentSubmit = createAction('POS_ADJUSTMENTS/RESET_SUBMIT');

export const setPosAdjustmentSubmitting = createAction('POS_ADJUSTMENTS/SET_SUBMITTING');

export const setPosAdjustmentSubmitError = createAction('POS_ADJUSTMENTS/SET_SUBMIT_ERROR');

export const setPosAdjustmentSubmitSuccess = createAction('POS_ADJUSTMENTS/SET_SUBMIT_SUCCESS');

//Complex actions
export const fetchPosAdjustmentsList = createAsyncThunk<void, void, AppThunkConfig>(
  'POS_ADJUSTMENTS/FETCH_POS_ADJUSTMENTS_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('pos'));
    try {
      const { data } = await api.posAdjustments.fetchPosAdjustmentsListAPI();
      dispatch(setPosAdjustmentsList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'posAdjustment',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('pos'));
    }
  },
);

export const fetchPosAdjustment = createAsyncThunk<void, number, AppThunkConfig>(
  'POS_ADJUSTMENTS/FETCH_POS_ADJUSTMENT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('pos'));
    try {
      const { data } = await api.posAdjustments.fetchPosAdjustmentAPI(id);
      dispatch(setPosAdjustment(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'posAdjustment',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('pos'));
    }
  },
);

// TODO This usage can probably be replaced with fetchPosAdjustment, need to check pos-app
export const fetchPosAdjustmentsListByPosId = createAsyncThunk<void, void, AppThunkConfig>(
  'POS_ADJUSTMENTS/FETCH_POS_ADJUSTMENTS_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, getState, extra } = ThunkAPI;
    const { api } = extra;
    const state = getState() as AppState;

    dispatch(startRequest('pos-adjustments'));
    try {
      const pos = getPosCurrent(state);
      const { data } = await api.posAdjustments.fetchPosAdjustmentsListAPI(pos.posId, true);
      dispatch(setPosAdjustmentsList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'posAdjustment',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('pos-adjustments'));
    }
  },
);

export const createPostAdjustment = createAsyncThunk<void, IPosAdjustment, AppThunkConfig>(
  `POS_ADJUSTMENTS/CREATE`,
  async (posAdjustment, ThunkAPI) => {
    const { extra, dispatch } = ThunkAPI;
    const { api } = extra;
    dispatch(setPosAdjustmentSubmitting());
    try {
      const { data } = await api.posAdjustments.createPosAdjustmentAPI(posAdjustment);

      dispatch(updatePosAdjustmentsList(data));
      dispatch(setPosAdjustmentSubmitSuccess());
    } catch (error) {
      dispatch(resetPosAdjustmentSubmit());
      dispatch(
        showErrorAlert({
          error,
          prefix: 'posAdjustment',
          action: 'save',
        }),
      );
    }
  },
);
