import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { ICarrier, IFetchWithEtagParams } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const addHomeCarriersTab = () =>
  addTab({
    tab: {
      id: 'carriers',
      title: intl.formatMessage({ id: 'carriers.tabTitle' }),
      path: 'pages/Carriers/Carriers',
      isRemovable: true,
    },
  });

export const addViewCarriersTab = (id?: string) =>
  addTab({
    tab: {
      id: 'carriers',
      title: intl.formatMessage({ id: 'carriers.tabTitle' }),
      path: 'pages/Carriers/CarriersView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewCarriersTab = () =>
  addTab({
    tab: {
      id: 'carriers',
      title: intl.formatMessage({ id: 'carriers.tabTitle' }),
      path: 'pages/Carriers/CarriersNew',
      isRemovable: true,
    },
  });

export const addEditCarriersTab = () =>
  addTab({
    tab: {
      id: 'carriers',
      title: intl.formatMessage({ id: 'carriers.tabTitle' }),
      path: 'pages/Carriers/CarriersEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearCarriers = createAction('CARRIERS/CLEAR');

export const clearCarriersDraft = createAction('CARRIERS/CLEAR_DRAFT');

export const setCarrier = createAction<ICarrier>('CARRIERS/SET_CARRIER');

export const setCarrierDraft = createAction<ICarrier>('CARRIERS/SET_CARRIER_DRAFT');

export const setCarriersList = createAction<ICarrier[]>('CARRIERS/SET_LIST');

export const updateCarriersList = createAction<ICarrier>('CARRIERS/UPDATE_LIST');

export const removeCarriersFromList = createAction<string>('CARRIERS/REMOVE_FROM_LIST');

export const duplicateCarrier = createAction<ICarrier>('CARRIERS/DUPLICATE');

export const setCarriersDraftValues = createAction<Partial<ICarrier>>(
  'CARRIERS/SET_CARRIER_DRAFT_VALUE',
);

// Complex actions

export const fetchCarriersList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'CARRIERS/FETCH_CARRIERS_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(startRequest('carriers'));
      }

      const { data } = await api.carriers.fetchCarriersListAPI();
      dispatch(setCarriersList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'carriers',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('carriers'));
      }
    }
  },
);

export const fetchCarrier = createAsyncThunk<void, string, AppThunkConfig>(
  'CARRIERS/FETCH_CARRIER',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('carriers'));
    try {
      const { data } = await api.carriers.fetchCarrierAPI(id);
      dispatch(setCarrier(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'carriers',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('carriers'));
    }
  },
);
