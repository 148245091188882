import { alertsReducer, initialAlertsState } from './slices/alerts';
import { authReducer, initialAuthState } from './slices/auth/reducer';
import { initialRouterState, routerReducer } from './slices/router/reducer';

export const initialGlobalStoreState = {
  alerts: initialAlertsState,
  auth: initialAuthState,
  router: initialRouterState,
};

export const initialGlobalRootReducers = {
  alerts: alertsReducer,
  auth: authReducer,
  router: routerReducer,
};
