import * as accounts from './accounts';
import * as accountTypes from './accountTypes';
import * as afp from './AFP';
import * as auth from './auth';
import * as balanceSheets from './balanceSheets';
import * as bankReconciliation from './bankReconciliation';
import * as banks from './banks';
import * as bankStatements from './bankStatements';
import * as branches from './branches';
import * as carriers from './carriers';
import * as ccafs from './ccafs';
import * as codes from './codes';
import * as costCenters from './costCenters';
import * as currencies from './currencies';
import * as currencyParities from './currencyParities';
import * as currencyRates from './currencyRates';
import * as customerAddressess from './customerAddressess';
import * as customerCategories from './customerCategories';
import * as customerContacts from './customerContacts';
import * as customers from './customers';
import * as customFields from './customFields';
import * as daily from './daily';
import * as dashboards from './dashboards';
import * as dealers from './dealers';
import * as dte from './dte';
import * as employees from './employees';
import * as employeeAbsences from './employeesAbsences';
import * as employeeContractTerminations from './employeesContractTerminations';
import * as employeesHistory from './employeesHistory';
import * as employeesLoans from './employeesLoans';
import * as etags from './etags';
import * as exCajas from './exCajas';
import * as fixedAssets from './fixedAssets';
import * as glAccounts from './glAccounts';
import * as globalCompanies from './globalCompanies';
import * as globalUsers from './globalUsers';
import * as groups from './groups';
import * as holidays from './holidays';
import * as inputs from './inputs';
import * as inventories from './inventories';
import * as isapres from './isapres';
import * as journalEntries from './journalEntries';
import * as ledgers from './ledgers';
import * as lots from './lots';
import * as opportunities from './opportunities';
import * as orders from './orders';
import * as outputs from './outputs';
import * as payroll from './payroll';
import * as pos from './pos';
import * as posAdjustments from './posAdjustments';
import * as posShifts from './posShifts';
import * as priceLists from './priceLists';
import * as processes from './processes';
import * as productCategories from './productCategories';
import * as products from './products';
import * as purchaseInvoices from './purchaseInvoices';
import * as purchaseOrders from './purchaseOrders';
import * as purchasePayments from './purchasePayments';
import * as purchaseWaybills from './purchaseWaybills';
import * as receipts from './receipts';
import * as recipes from './recipes';
import * as recurringSalesInvoices from './recurringSalesInvoices';
import * as remunerationConcepts from './remunerationConcepts';
import * as remunerationConceptsTypes from './remunerationConceptTypes';
import * as reports from './reports';
import * as salesInvoices from './salesInvoices';
import * as salesInvoicesPos from './salesInvoicesPos';
import * as salesmen from './salesmen';
import * as salesOrders from './salesOrders';
import * as salesQuotes from './salesQuotes';
import * as salesReports from './salesReports';
import * as salesTaxes from './salesTaxes';
import * as salesTickets from './salesTickets';
import * as salesWaybills from './salesWaybills';
import * as savingsPlansProviders from './savingsPlansProviders';
import * as settings from './settings';
import * as stages from './stages';
import * as statistics from './statistics';
import * as stockTransfers from './stockTransfers';
import * as supplierContacts from './supplierContacts';
import * as suppliers from './suppliers';
import * as systems from './systems';
import * as terms from './terms';
import * as unions from './unions';
import * as users from './users';
import * as warehouses from './warehouses';
import * as workersCompensationInsurance from './workersCompensationInsurance';

export const api = {
  accounts,
  accountTypes,
  afp,
  auth,
  balanceSheets,
  bankReconciliation,
  banks,
  bankStatements,
  branches,
  carriers,
  ccafs,
  codes,
  costCenters,
  currencies,
  currencyParities,
  currencyRates,
  customerAddressess,
  customerCategories,
  customerContacts,
  customers,
  customFields,
  daily,
  dashboards,
  dealers,
  dte,
  employeeAbsences,
  employeeContractTerminations,
  employees,
  employeesHistory,
  employeesLoans,
  etags,
  exCajas,
  fixedAssets,
  glAccounts,
  globalCompanies,
  globalUsers,
  groups,
  holidays,
  inputs,
  inventories,
  isapres,
  journalEntries,
  ledgers,
  lots,
  opportunities,
  orders,
  outputs,
  payroll,
  pos,
  posAdjustments,
  posShifts,
  priceLists,
  processes,
  productCategories,
  products,
  purchaseInvoices,
  purchaseOrders,
  purchasePayments,
  purchaseWaybills,
  receipts,
  recipes,
  recurringSalesInvoices,
  remunerationConcepts,
  remunerationConceptsTypes,
  reports,
  salesInvoices,
  salesInvoicesPos,
  salesmen,
  salesOrders,
  salesQuotes,
  salesReports,
  salesTaxes,
  salesTickets,
  salesWaybills,
  savingsPlansProviders,
  settings,
  stages,
  statistics,
  stockTransfers,
  supplierContacts,
  suppliers,
  systems,
  terms,
  unions,
  users,
  warehouses,
  workersCompensationInsurance,
};

export type ApiService = typeof api;
