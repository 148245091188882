import { createReducer } from '@reduxjs/toolkit';

import { ICafDte } from '@laudus/types';

import { clearCafDTE, setCafDTE, setCafDTEDraft, updateCafDTEList } from './actions';
import { removeCafDTEFromList, setCafDTEList } from '.';

export interface ICafDTEState {
  current: ICafDte;
  draft: ICafDte;
  list: ICafDte[];
}

export const CAFDTE_EMPTY: ICafDte = {
  authorizedDocumentsRangeId: 0,
  docType: {
    docTypeId: 0,
    name: '',
  },
  docNumberFrom: 0,
  docNumberTo: 0,
  authorizationDate: '',
  CAF: '',
  branch: {
    branchId: 0,
    name: '',
  },
  POS: {
    posId: 0,
    name: '',
  },
};

export const initialCafDTEState: ICafDTEState = {
  current: CAFDTE_EMPTY,
  draft: CAFDTE_EMPTY,
  list: [],
};

export const cafDTEReducer = createReducer(initialCafDTEState, (builder) => {
  builder
    .addCase(clearCafDTE, (state) => {
      return { ...state, current: CAFDTE_EMPTY };
    })
    .addCase(setCafDTE, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setCafDTEDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setCafDTEList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateCafDTEList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter(
            (b) => b.authorizedDocumentsRangeId !== action.payload.authorizedDocumentsRangeId,
          ),
          action.payload,
        ],
      };
    })
    .addCase(removeCafDTEFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter(
          (b) => b.authorizedDocumentsRangeId !== action.payload.authorizedDocumentsRangeId,
        ),
      };
    })
    .addDefaultCase((state) => state);
});
