import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, ISetting } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { resetCustomer } from '../customers';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';

interface IDraftSettings {
  [key: string]: number | string | boolean;
}

export const clearSettings = createAction('SETTINGS/CLEAR_LIST');

export const clearSettingsDraftList = createAction('SETTINGS/CLEAR_DRAFT');

export const setSettingsList = createAction<ISetting[]>('SETTINGS/SET_LIST');

export const setSettingsDraft = createAction<IDraftSettings>('SETTINGS/SET_DRAFT');

export const setSettingsDraftValues = createAction<Partial<any>>('SETTINGS/SET_DRAFT_VALUES');

export const setSettingsDraftListValues = createAction<ISetting>('SETTINGS/SET_DRAFT_LIST_VALUES');

export const removeSettingsFromDraftListValues = createAction<Partial<ISetting>>(
  'SETTINGS/REMOVE_FROM_DRAFT_LIST_VALUES',
);
export const updateSettingsList = createAction<ISetting>('SETTINGS/UPDATE_LIST');

export const fetchSettingsList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'SETTINGS/FETCH_SETTINGS_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    if (!silent) {
      dispatch(startRequest('settings'));
    }

    try {
      const { data, status } = await api.settings.fetchSettingsListAPI();
      if (status === 304) {
        return;
      }
      dispatch(setSettingsList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'options',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('settings'));
      }
    }
  },
);

export const updateSettings = createAsyncThunk<void, ISetting[], AppThunkConfig>(
  'SETTINGS/UPDATE_SETTING',
  async (settings, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('settings'));
    try {
      for (const setting of settings) {
        const { data } = await api.settings.updateSettingAPI(setting);
        dispatch(updateSettingsList(data));
      }
      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'options.successToast.save' }),
          message: '',
          type: 'success',
        }),
      );
      // If the default customer for tickets is changed, reset the customer
      if (settings.some((s) => s.keyName === 'defaultCustomerForTickets')) {
        dispatch(resetCustomer());
      }
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'options',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('settings'));
    }
  },
);
