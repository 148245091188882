import { AxiosResponse } from 'axios';

import { IBankStatement, IBankStatementListItem } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchBankStatementListFromAPI = (): Promise<AxiosResponse<IBankStatementListItem[]>> =>
  axios.post(
    `${VITE_API_URL}/accounting/bankStatements/list`,
    {
      fields: [
        'bankStatementId',
        'number',
        'year',
        'bank.bankId',
        'bank.name',
        'dateFrom',
        'dateTo',
        'initialBalance',
      ],
      orderBy: [
        { field: 'year', direction: 'DESC' },
        { field: 'number', direction: 'DESC' },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchBankStatementFromAPI = (
  bankStatementId: number,
): Promise<AxiosResponse<IBankStatement>> =>
  axios.get(`${VITE_API_URL}/accounting/bankStatements/${bankStatementId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createBankStatementFromAPI = (
  bankStatement: IBankStatement,
): Promise<AxiosResponse<IBankStatement>> =>
  axios.post(`${VITE_API_URL}/accounting/bankStatements`, bankStatement);

export const updateBankStatementFromAPI = (
  bankStatement: IBankStatement,
): Promise<AxiosResponse<IBankStatement>> =>
  axios.put(
    `${VITE_API_URL}/accounting/bankStatements/${bankStatement.bankStatementId}`,
    bankStatement,
  );

export const deleteBankStatementFromAPI = (
  bankStatementId: number,
): Promise<AxiosResponse<IBankStatement>> =>
  axios.delete(`${VITE_API_URL}/accounting/bankStatements/${bankStatementId}`);
