import { AxiosResponse } from 'axios';

import {
  ICustomer,
  ICustomerCreditLimit,
  ICustomerImportResult,
  ICustomerInvoice,
  ICustomerInvoiceListItem,
  ICustomerPendingInvoice,
  ICustomerSRI,
} from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchCustomersListFromAPI = (): Promise<AxiosResponse<ICustomer[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/customers/list`,
    {
      fields: [
        'name',
        'customerId',
        'legalName',
        'VATId',
        'address',
        'phone1',
        'email',
        'customFields.*',
      ],
      orderBy: [{ field: 'name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchCustomerFromAPI = (customerId: number): Promise<AxiosResponse<ICustomer>> =>
  axios.get(`${VITE_API_URL}/sales/customers/${customerId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createCustomerFromAPI = (customer: ICustomer): Promise<AxiosResponse<ICustomer>> =>
  axios.post(`${VITE_API_URL}/sales/customers`, customer);

export const updateCustomerFromAPI = (customer: ICustomer): Promise<AxiosResponse<ICustomer>> =>
  axios.put(`${VITE_API_URL}/sales/customers/${customer.customerId}`, customer);

export const bulkUpsertCustomersFromAPI = (
  customers: ICustomer[],
): Promise<AxiosResponse<ICustomerImportResult[]>> =>
  axios.post(`${VITE_API_URL}/sales/customers/bulk`, customers);

export const deleteCustomerFromAPI = (customerId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/customers/${customerId}`);

export const fetchCustomerCreditLimitFromAPI = (
  customerId: number,
): Promise<AxiosResponse<ICustomerCreditLimit>> =>
  axios.get(`${VITE_API_URL}/sales/customers/${customerId}/creditLimit`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const fetchCustomersInvoicesListFromAPI = (
  customerId: number,
): Promise<AxiosResponse<ICustomerInvoice[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/invoices/list`,
    {
      fields: [
        'docType.name',
        'docNumber',
        'issuedDate',
        'dueDate',
        'totals.net',
        'totals.vat',
        'totals.total',
        'salesman.name',
      ],
      filterBy: [{ field: 'customerId', operator: '=', value: customerId }],
      orderBy: [{ field: 'docNumber', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchCustomersInvoicesItemsListFromAPI = (
  customerId: number,
): Promise<AxiosResponse<ICustomerInvoiceListItem[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/invoices/list`,
    {
      fields: [
        'docType.name',
        'docNumber',
        'issuedDate',
        'dueDate',
        'totals.net',
        'totals.vat',
        'totals.total',
        'salesman.name',
        'items.itemId',
        'items.itemDescription',
        'items.product.SKU',
        'items.product.description',
        'items.quantity',
        'items.unitPrice',
        'items.discountPercentage',
      ],
      filterBy: [{ field: 'customerId', operator: '=', value: customerId }],
      orderBy: [{ field: 'docNumber', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchCustomerPendingInvoicesFromAPI = (
  customerId: number,
): Promise<AxiosResponse<ICustomerPendingInvoice[]>> =>
  axios.get(`${VITE_API_URL}/sales/customers/${customerId}/pendingInvoices`);

export const fetchCaptchaSeed = (): Promise<AxiosResponse<ICustomerSRI>> =>
  axios.post('https://zeus.sii.cl/cvc_cgi/stc/CViewCaptcha.cgi', 'oper=0', {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
  });

export const fetchCaptchaImage = (seedCaptcha: string): Promise<AxiosResponse<ArrayBuffer>> =>
  axios.post(
    'https://zeus.sii.cl/cvc_cgi/stc/CViewCaptcha.cgi?step2',
    'oper=1&txtCaptcha=' + seedCaptcha,
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      responseType: 'arraybuffer',
    },
  );

export interface IRequestProps {
  RUT?: string;
  DV?: string;
  txt_captcha?: string;
  txt_code?: string;
}

export const fetchSRIReport = (requestProps: IRequestProps): Promise<AxiosResponse<string>> =>
  axios.post(
    'https://zeus.sii.cl/cvc_cgi/stc/getstc',
    'oper=0&jQuery1710735646451947157=1&RUT=' +
      requestProps.RUT +
      '&DV=' +
      requestProps.DV +
      '&txt_captcha=' +
      requestProps.txt_captcha +
      '&txt_code=' +
      requestProps.txt_code +
      '&PRG=STC&OPC=NOR',
    {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
    },
  );

export const fetchCustomersRUTsFromAPI = (
  VATIdList: string[],
): Promise<AxiosResponse<ICustomer[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/customers/list`,
    {
      options: {
        offset: 0,
        limit: 0,
      },
      fields: ['name', 'customerId', 'legalName', 'VATId'],
      filterBy: [
        {
          field: 'VATId',
          operator: '=',
          value: VATIdList,
        },
      ],
      orderBy: [{ field: 'name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
