import { accountsReducer, initialAccountsState } from './slices/accounts';
import { accountTypesReducer, initialAccountTypesState } from './slices/accountTypes';
import { afpsReducer, initialAFPsState } from './slices/afps';
import { balanceSheetsReducer, initialBalanceSheetState } from './slices/balanceSheets/reducer';
import {
  balanceSheetFiltersReducer,
  initialBalanceSheetFilterState,
} from './slices/balanceSheetsFilters/reducer';
import {
  bankReconciliationReducer,
  initialBankReconciliationState,
} from './slices/bankReconciliation';
import { banksReducer, initialBanksState } from './slices/banks';
import { bankStatementsReducer, initialBankStatementsState } from './slices/bankStatements';
import { branchesReducer, initialBranchesState } from './slices/branches';
import { cafDTEReducer, initialCafDTEState } from './slices/CafDte';
import { carriersReducer, initialCarrierState } from './slices/carriers';
import { ccafsReducer, initialCcafsState } from './slices/ccafs';
import { codeCategoriesReducer, initialCodeCategoriesState } from './slices/codeCategories';
import { codesReducer, initialCodesState } from './slices/codes';
import { costCentersReducer, initialCostCentersState } from './slices/costCenters';
import { currenciesReducer, initialCurrenciesState } from './slices/currencies/reducer';
import { currencyRatesReducer, initialCurrencyRatesState } from './slices/currencyRates';
import {
  customerAddressesReducer,
  initialCustomerAddressesState,
} from './slices/customerAddresses';
import {
  customerCategoriesReducer,
  initialCustomerCategoriesState,
} from './slices/customerCategories';
import { customerContactsReducer, initialCustomerContactsState } from './slices/customerContacts';
import { customersReducer, initialCustomersState } from './slices/customers';
import {
  customerStatisticsReducer,
  initialCustomerStatisticsState,
} from './slices/customersInvoices';
import { customFieldsReducer, initialCustomFieldsState } from './slices/customFields';
import { dailyReducer, initialDailyState } from './slices/daily';
import { dashboardsReducer, initialDashboardsState } from './slices/dashboards';
import { dealersReducer, initialDealersState } from './slices/dealers';
import { docTypesReducer, initialDocTypesState } from './slices/docTypes';
import {
  initialSettingsDTECertificateState,
  settingsDTECertificateReducer,
} from './slices/dteCertificate';
import { DTEPendingsReducer, initialDTEPendingsState } from './slices/dtePendings';
import { employeesReducer, initialEmployeesState } from './slices/employees';
import { employeeAbsenceReducer, initialEmployeeAbsenceState } from './slices/employeesAbsences';
import {
  employeeCurrentTerminationReducer,
  initialEmployeeContractTerminationState,
} from './slices/employeesContractTerminations';
import { employeesHistoryReducer, initialEmployeesHistoryState } from './slices/employeesHistory';
import { employeeLoansReducer, initialEmployeeLoansState } from './slices/employeesLoans';
import { etagsReducer, initialEtagsState } from './slices/etags';
import { exCajasReducer, initialExCajasState } from './slices/exCajas';
import { fixedAssetsReducer, initialFixedAssetsState } from './slices/fixedAssets';
import { globalReducer, initialGlobalState } from './slices/global/reducer';
import { globalAccountsReducer, initialGlobalAccountsState } from './slices/globalAccounts';
import { globalCompaniesReducer, initialGlobalCompaniesState } from './slices/globalCompanies';
import { globalUsersReducer, initialGlobalUsersState } from './slices/globalUsers';
import { groupsReducer, initialGroupsState } from './slices/groups';
import { holidaysReducer, initialHolidaysState } from './slices/holidays';
import { initialInputsState, inputsReducer } from './slices/inputs';
import { initialInventoriesState, inventoriesReducer } from './slices/inventories';
import {
  initialInvoicesReconciliationState,
  invoicesReconciliationReducer,
} from './slices/invoicesReconciliation';
import { initialIsapresState, isapresReducer } from './slices/isapres';
import { initialJournalEntriesState, journalEntriesReducer } from './slices/journalEntries';
import { initialLedgerState, ledgerReducer } from './slices/ledgers';
import { initialLedgerFilterState, ledgerFiltersReducer } from './slices/ledgersFilters';
import { initialLoggedUserState, loggedUserReducer } from './slices/loggedUser';
import { initialLotState, lotReducer } from './slices/lots';
import { initialOpportunitiesState, opportunitiesReducer } from './slices/opportunities';
import { initialOutputsState, outputsReducer } from './slices/outputs';
import { initialPayrollState, payrollReducer } from './slices/payroll';
import { initialPosState, posReducer } from './slices/pos';
import { initialPosAdjustmentState, posAdjustmentReducer } from './slices/posAdjustments';
import { initialPosShiftState, posShiftReducer } from './slices/posShifts';
import { initialPriceListsState, priceListsReducer } from './slices/priceLists';
import { initialProcessesState, processesReducer } from './slices/processes';
import {
  initialProductCategoriesState,
  productCategoriesReducer,
} from './slices/productCategories';
import { initialProductsState, productsReducer } from './slices/products';
import { initialPurchaseInvoicesState, purchasesInvoicesReducer } from './slices/purchaseInvoices';
import { initialPurchaseOrderState, purchaseOrderReducer } from './slices/purchaseOrders';
import { initialPurchasePaymentState, purchasePaymentReducer } from './slices/purchasePayments';
import { initialPurchaseWaybillsState, purchasesWaybillsReducer } from './slices/purchaseWaybills';
import { initialReceiptsState, receiptsReducer } from './slices/receipts';
import { initialRecipesState, recipesReducer } from './slices/recipes';
import {
  initialRecurringSalesInvoicesState,
  recurringSalesInvoicesReducer,
} from './slices/recurringSalesInvoices';
import {
  initialRemunerationConceptsState,
  remunerationConceptsReducer,
} from './slices/remunerationConcepts';
import {
  initialRemunerationConceptTypesState,
  remunerationConceptTypesReducer,
} from './slices/remunerationConceptTypes';
import { initialReportsState, reportsReducer } from './slices/reports';
import { initialSalesInvoicesState, salesInvoicesReducer } from './slices/salesInvoices';
import { initialSalesmenState, salesmenReducer } from './slices/salesmen';
import { initialSalesOrdersState, salesOrdersReducer } from './slices/salesOrders';
import { initialSalesQuotesState, salesQuotesReducer } from './slices/salesQuotes';
import { initialSalesTaxesState, salesTaxesReducer } from './slices/salesTaxes';
import { initialSalesTicketsState, salesTicketsReducer } from './slices/salesTickets';
import { initialSalesWaybillsState, salesWaybillsReducer } from './slices/salesWaybills';
import {
  initialSavingsPlansProvidersState,
  savingsPlansProvidersReducer,
} from './slices/savingsPlansProviders';
import { initialSettingsState, settingsReducer } from './slices/settings';
import { initialSIITransactionsState, SIITransactionsReducer } from './slices/SIITransactions';
import { initialStagesState, stagesReducer } from './slices/stages';
import { initialStatisticsState, statisticsReducer } from './slices/statistics';
import { initialStockControlState, stockControlReducer } from './slices/stockControl';
import { initialStockTransfersState, stockTransfersReducer } from './slices/stockTransfers';
import { initialSupplierContactsState, supplierContactsReducer } from './slices/supplierContacts';
import { initialSuppliersState, suppliersReducer } from './slices/suppliers';
import {
  initialSupplierStatisticsState,
  supplierStatisticsReducer,
} from './slices/suppliersInvoices';
import { initialSystemState, systemsReducer } from './slices/systems';
import { initialTabsState, tabsReducer } from './slices/tabs';
import { initialTermsState, termsReducer } from './slices/terms';
import { initialUnionsState, unionsReducer } from './slices/unions';
import { initialUsersState, usersReducer } from './slices/users';
import { initialUsersCertificateState, usersCertificateReducer } from './slices/usersCertificate';
import { initialWarehousesState, warehousesReducer } from './slices/warehouses';
import {
  initialWorkersCompensationInsurancesState,
  workersCompensationInsurancesReducer,
} from './slices/workersCompensationInsurance';

export const initialAppStoreState = {
  accounts: initialAccountsState,
  accountTypes: initialAccountTypesState,
  afps: initialAFPsState,
  balanceSheet: initialBalanceSheetState,
  balanceSheetFilter: initialBalanceSheetFilterState,
  bankReconciliation: initialBankReconciliationState,
  banks: initialBanksState,
  bankStatements: initialBankStatementsState,
  branches: initialBranchesState,
  cafDte: initialCafDTEState,
  carriers: initialCarrierState,
  ccafs: initialCcafsState,
  codeCategories: initialCodeCategoriesState,
  codes: initialCodesState,
  costCenters: initialCostCentersState,
  currencies: initialCurrenciesState,
  currencyRates: initialCurrencyRatesState,
  customerAddresses: initialCustomerAddressesState,
  customerCategories: initialCustomerCategoriesState,
  customerContacts: initialCustomerContactsState,
  customers: initialCustomersState,
  customerStatistics: initialCustomerStatisticsState,
  customFields: initialCustomFieldsState,
  daily: initialDailyState,
  dashboards: initialDashboardsState,
  dealers: initialDealersState,
  docTypes: initialDocTypesState,
  dteCertificates: initialSettingsDTECertificateState,
  dtePendings: initialDTEPendingsState,
  employeeAbsences: initialEmployeeAbsenceState,
  employeeContractTerminations: initialEmployeeContractTerminationState,
  employeeHistory: initialEmployeesHistoryState,
  employeeLoans: initialEmployeeLoansState,
  employees: initialEmployeesState,
  etags: initialEtagsState,
  exCajas: initialExCajasState,
  fixedAssets: initialFixedAssetsState,
  global: initialGlobalState,
  globalAccounts: initialGlobalAccountsState,
  globalCompanies: initialGlobalCompaniesState,
  globalUsers: initialGlobalUsersState,
  groups: initialGroupsState,
  holidays: initialHolidaysState,
  inputs: initialInputsState,
  inventories: initialInventoriesState,
  invoicesReconciliation: initialInvoicesReconciliationState,
  isapres: initialIsapresState,
  journalEntry: initialJournalEntriesState,
  ledger: initialLedgerState,
  ledgerFilter: initialLedgerFilterState,
  loggedUser: initialLoggedUserState,
  lot: initialLotState,
  opportunities: initialOpportunitiesState,
  outputs: initialOutputsState,
  payroll: initialPayrollState,
  pos: initialPosState,
  posAdjustment: initialPosAdjustmentState,
  posShift: initialPosShiftState,
  priceLists: initialPriceListsState,
  processes: initialProcessesState,
  productCategories: initialProductCategoriesState,
  products: initialProductsState,
  purchaseInvoices: initialPurchaseInvoicesState,
  purchaseOrders: initialPurchaseOrderState,
  purchasePayments: initialPurchasePaymentState,
  purchaseWaybills: initialPurchaseWaybillsState,
  receipts: initialReceiptsState,
  recipes: initialRecipesState,
  recurringSalesInvoices: initialRecurringSalesInvoicesState,
  remunerationConcepts: initialRemunerationConceptsState,
  remunerationConceptTypes: initialRemunerationConceptTypesState,
  reports: initialReportsState,
  salesInvoices: initialSalesInvoicesState,
  salesmen: initialSalesmenState,
  salesOrders: initialSalesOrdersState,
  salesQuotes: initialSalesQuotesState,
  salesTaxes: initialSalesTaxesState,
  salesTickets: initialSalesTicketsState,
  salesWaybills: initialSalesWaybillsState,
  savingsPlansProviders: initialSavingsPlansProvidersState,
  settings: initialSettingsState,
  SIITransactions: initialSIITransactionsState,
  stages: initialStagesState,
  statistics: initialStatisticsState,
  stockControl: initialStockControlState,
  stockTransfers: initialStockTransfersState,
  supplierContacts: initialSupplierContactsState,
  suppliers: initialSuppliersState,
  supplierStatistics: initialSupplierStatisticsState,
  systems: initialSystemState,
  tabs: initialTabsState,
  terms: initialTermsState,
  unions: initialUnionsState,
  users: initialUsersState,
  usersCertificate: initialUsersCertificateState,
  warehouses: initialWarehousesState,
  workersCompensationInsurances: initialWorkersCompensationInsurancesState,
};

export const appRootReducers = {
  accounts: accountsReducer,
  accountTypes: accountTypesReducer,
  afps: afpsReducer,
  balanceSheet: balanceSheetsReducer,
  balanceSheetFilter: balanceSheetFiltersReducer,
  bankReconciliation: bankReconciliationReducer,
  banks: banksReducer,
  bankStatements: bankStatementsReducer,
  branches: branchesReducer,
  cafDte: cafDTEReducer,
  carriers: carriersReducer,
  ccafs: ccafsReducer,
  codeCategories: codeCategoriesReducer,
  codes: codesReducer,
  costCenters: costCentersReducer,
  currencies: currenciesReducer,
  currencyRates: currencyRatesReducer,
  customerAddresses: customerAddressesReducer,
  customerCategories: customerCategoriesReducer,
  customerContacts: customerContactsReducer,
  customers: customersReducer,
  customerStatistics: customerStatisticsReducer,
  customFields: customFieldsReducer,
  daily: dailyReducer,
  dashboards: dashboardsReducer,
  dealers: dealersReducer,
  docTypes: docTypesReducer,
  dteCertificates: settingsDTECertificateReducer,
  dtePendings: DTEPendingsReducer,
  employeeAbsences: employeeAbsenceReducer,
  employeeContractTerminations: employeeCurrentTerminationReducer,
  employeeHistory: employeesHistoryReducer,
  employeeLoans: employeeLoansReducer,
  employees: employeesReducer,
  etags: etagsReducer,
  exCajas: exCajasReducer,
  fixedAssets: fixedAssetsReducer,
  global: globalReducer,
  globalAccounts: globalAccountsReducer,
  globalCompanies: globalCompaniesReducer,
  globalUsers: globalUsersReducer,
  groups: groupsReducer,
  holidays: holidaysReducer,
  inputs: inputsReducer,
  inventories: inventoriesReducer,
  invoicesReconciliation: invoicesReconciliationReducer,
  isapres: isapresReducer,
  journalEntry: journalEntriesReducer,
  ledger: ledgerReducer,
  ledgerFilter: ledgerFiltersReducer,
  loggedUser: loggedUserReducer,
  lot: lotReducer,
  opportunities: opportunitiesReducer,
  outputs: outputsReducer,
  payroll: payrollReducer,
  pos: posReducer,
  posAdjustment: posAdjustmentReducer,
  posShift: posShiftReducer,
  priceLists: priceListsReducer,
  processes: processesReducer,
  productCategories: productCategoriesReducer,
  products: productsReducer,
  purchaseInvoices: purchasesInvoicesReducer,
  purchaseOrders: purchaseOrderReducer,
  purchasePayments: purchasePaymentReducer,
  purchaseWaybills: purchasesWaybillsReducer,
  receipts: receiptsReducer,
  recipes: recipesReducer,
  recurringSalesInvoices: recurringSalesInvoicesReducer,
  remunerationConcepts: remunerationConceptsReducer,
  remunerationConceptTypes: remunerationConceptTypesReducer,
  reports: reportsReducer,
  salesInvoices: salesInvoicesReducer,
  salesmen: salesmenReducer,
  salesOrders: salesOrdersReducer,
  salesQuotes: salesQuotesReducer,
  salesTaxes: salesTaxesReducer,
  salesTickets: salesTicketsReducer,
  salesWaybills: salesWaybillsReducer,
  savingsPlansProviders: savingsPlansProvidersReducer,
  settings: settingsReducer,
  SIITransactions: SIITransactionsReducer,
  stages: stagesReducer,
  statistics: statisticsReducer,
  stockControl: stockControlReducer,
  stockTransfers: stockTransfersReducer,
  supplierContacts: supplierContactsReducer,
  suppliers: suppliersReducer,
  supplierStatistics: supplierStatisticsReducer,
  systems: systemsReducer,
  tabs: tabsReducer,
  terms: termsReducer,
  unions: unionsReducer,
  users: usersReducer,
  usersCertificate: usersCertificateReducer,
  warehouses: warehousesReducer,
  workersCompensationInsurances: workersCompensationInsurancesReducer,
};
