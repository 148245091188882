import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { RowDoubleClickedEvent, SelectionChangedEvent } from '@laudus/ag-grid';
import { useIntl } from '@laudus/intl';
import { clearAuth, showAlert, useGlobalDispatch } from '@laudus/redux-global';
import { Modal } from '@laudus/shared-ui';

import { CompanySearchGrid } from '../../shared/components/Grids/CompanySearchGrid';

export interface ILoginCompanyListProps {
  show: boolean;
  onClose: () => void;
}

export const LoginCompanyList = ({ onClose, show }: ILoginCompanyListProps) => {
  const intl = useIntl();
  const navigate = useNavigate();

  const dispatch = useGlobalDispatch();

  const [selectedVatId, setSelectedVatId] = useState<string | null>(null);

  const handleAccept = (VATId: string | null) => {
    navigate(`/${VATId}`, { replace: true });
  };

  const handleCancel = () => {
    dispatch(clearAuth());
    onClose();
  };

  const handleSelectionChanged = (event: SelectionChangedEvent) => {
    const VATId = event.api.getSelectedNodes()?.[0].data.VATId;
    setSelectedVatId(VATId);
  };

  const handleSelectionDoubleClicked = (event: RowDoubleClickedEvent) => {
    const selectVatId = event.node.data.VATId;
    if (!selectVatId) {
      dispatch(
        showAlert({
          type: 'error',
          isCritical: true,
          title: intl.formatMessage({ id: 'selectCompany.errorTitle' }),
          message: intl.formatMessage({ id: 'selectCompany.VATId.error' }),
        }),
      );
      return;
    }
    handleAccept(selectVatId);
  };

  return (
    <Modal
      acceptText={intl.formatMessage({ id: 'selectCompany.acceptText' })}
      cancelText={intl.formatMessage({ id: 'selectCompany.cancelText' })}
      closeIcon={false}
      isOpen={show}
      onAccept={() => handleAccept(selectedVatId)}
      onCancel={handleCancel}
      title={intl.formatMessage({ id: 'selectCompany.title' })}
      titlePosition="flex-start"
      variant="search"
      size="lg"
    >
      {/* Calculated height: to make the grid flexible (expand and contract) according to the modal */}
      {/* To achieve this, add in child component flexGrow="1" */}
      <CompanySearchGrid
        onSelectionChanged={handleSelectionChanged}
        onSelectionDoubleClicked={handleSelectionDoubleClicked}
      />
    </Modal>
  );
};
