import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { ICustomField, IFetchWithEtagParams } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global';

//Simple actions
export const clearCustomFields = createAction('CUSTOM_FIELDS/CLEAR');

export const setCustomFieldsList = createAction<ICustomField[]>('CUSTOM_FIELDS/SET_LIST');

export const fetchCustomFieldsList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'CUSTOM_FIELDS/FETCH_CUSTOM_FIELDS_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(startRequest('custom-fields'));
      }
      const { data, status } = await api.customFields.fetchCustomFieldsListAPI();
      if (status === 304) {
        return;
      }
      dispatch(setCustomFieldsList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'customFields',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('custom-fields'));
      }
    }
  },
);
