import { createAction } from '@reduxjs/toolkit';

import { IBalanceSheetFilterTags, IBalanceSheetFormattedFilters } from '@laudus/types';

export const clearBalanceSheetFilter = createAction(
  'BALANCE_SHEET_FILTERS/CLEAR_BALANCE_SHEET_FILTER',
);
export const setBalanceSheetFilter = createAction<IBalanceSheetFormattedFilters>(
  'BALANCE_SHEET_FILTERS/SET_BALANCE_SHEET_FILTER',
);
export const setBalanceSheetFilterValues = createAction<Partial<IBalanceSheetFormattedFilters>>(
  'BALANCE_SHEET_FILTERS/SET_BALANCE_SHEET_FILTER_VALUE',
);
export const setBalanceSheetTagsFilter = createAction<IBalanceSheetFilterTags[]>(
  'BALANCE_SHEET_FILTERS/SET_BALANCE_SHEET_TAGS_FILTER',
);
export const setBalanceSheetIdFilter = createAction<number>(
  'BALANCE_SHEET_FILTERS/SET_BALANCE_SHEET_ID_FILTER',
);
