import { AxiosResponse } from 'axios';

import { IPosShiftBalance, IPosShiftList, IPosShifts } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchActivePosShiftsListAPI = (id: number): Promise<AxiosResponse<IPosShiftList>> =>
  axios.post(
    `${VITE_API_URL}/sales/pos/shifts/list`,
    {
      fields: [
        'shiftId',
        'dateFrom',
        'dateTo',
        'notes',
        'initialCashBalance',
        'closingCashBalance',
        'salesman.salesmanId',
        'salesman.name',
        'pos.posId',
        'pos.name',
      ],
      options: {
        offset: 0,
        limit: 1,
      },
      filterBy: [
        { field: 'pos.posId', operator: '=', value: id },
        { field: 'dateTo', operator: '=', value: null },
      ],
      orderBy: [
        {
          field: 'shiftId',
          direction: 'DESC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchPosShiftsHistoryAPI = (id: number): Promise<AxiosResponse<IPosShiftList>> =>
  axios.post(
    `${VITE_API_URL}/sales/pos/shifts/list`,
    {
      fields: [
        'shiftId',
        'dateFrom',
        'dateTo',
        'notes',
        'initialCashBalance',
        'closingCashBalance',
        'salesman.salesmanId',
        'salesman.name',
        'pos.posId',
        'pos.name',
      ],
      filterBy: [{ field: 'pos.posId', operator: '=', value: id }],
      orderBy: [
        {
          field: 'dateFrom',
          direction: 'DESC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchPosShiftAPI = (shiftId: number): Promise<AxiosResponse<IPosShifts>> =>
  axios.get(`${VITE_API_URL}/sales/pos/shifts/${shiftId}`);

export const fetchPosShiftBalanceAPI = (
  shiftId: number,
): Promise<AxiosResponse<IPosShiftBalance>> =>
  axios.get(`${VITE_API_URL}/sales/pos/shifts/${shiftId}/balance`);

export const createPosShiftAPI = (posShift: IPosShifts): Promise<AxiosResponse<IPosShifts>> =>
  axios.post(`${VITE_API_URL}/sales/pos/shifts`, posShift);

export const updatePosShiftAPI = (posShift: IPosShifts): Promise<AxiosResponse<IPosShifts>> =>
  axios.put(`${VITE_API_URL}/sales/pos/shifts/${posShift.shiftId}`, posShift);

export const deletePosShiftAPI = (shiftId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/pos/shifts/${shiftId}`);
