import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  IPurchaseInvoice,
  IPurchasePaymentsInvoices,
  IPurchasePaymentsOtherDocuments,
  IPurchasePaymentTableData,
} from '@laudus/types';

export const paymentPurchaseInvoiceItemToGridItem = (
  invoice: IPurchasePaymentsInvoices,
): IPurchasePaymentTableData => {
  return {
    amount: invoice.amount,
    costCenter: invoice.costCenter,
    currencyCode: invoice.currencyCode,
    description: 'Factura',
    docNumber: invoice.docNumber.toString(),
    docTypeName: invoice.docType?.name ?? '',
    itemId: invoice.itemId,
    originalAmount: invoice.originalAmount,
    parityToMainCurrency: invoice.parityToMainCurrency,
    relatedToName: invoice.supplier?.name || '',
    relatedToVATId: invoice.supplier?.VATId || '',
    type: 'purchaseInvoice',
  } satisfies IPurchasePaymentTableData;
};

export const paymentOtherDocumentItemToGridItem = (
  otherDocument: IPurchasePaymentsOtherDocuments,
): IPurchasePaymentTableData => {
  return {
    amount: otherDocument.amount,
    costCenter: otherDocument.costCenter,
    currencyCode: otherDocument.currencyCode,
    description: otherDocument.description,
    docNumber: '---',
    docTypeName: otherDocument.category?.description || '',
    itemId: otherDocument.itemId ?? getNegativeUniqueNumericId(),
    originalAmount: otherDocument.originalAmount,
    parityToMainCurrency: otherDocument.parityToMainCurrency,
    relatedToName: otherDocument.relatedTo?.name || '',
    relatedToVATId: otherDocument.relatedTo?.VATId || '',
    type: 'otherDocument',
  } satisfies IPurchasePaymentTableData;
};

export const purchaseInvoicesToGridItem = (
  invoice: IPurchaseInvoice,
): IPurchasePaymentTableData => {
  return {
    amount: invoice.totals?.total || 0,
    costCenter: invoice.costCenter ?? null,
    currencyCode: invoice.totals?.currencyCode || '',
    description: 'Factura',
    docNumber: invoice.docNumber.toString(),
    docTypeName: invoice.docType?.name || '',
    itemId: getNegativeUniqueNumericId(),
    originalAmount: invoice.totalsOriginalCurrency?.total || 0,
    parityToMainCurrency: invoice.totals?.parity || 0,
    relatedToName: invoice.supplier?.name || '',
    relatedToVATId: invoice.supplier?.VATId || '',
    type: 'purchaseInvoice',
  } satisfies IPurchasePaymentTableData;
};
