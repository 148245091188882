import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IWorkersCompensationInsurance } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Simple actions
export const clearWorkersCompensationInsurance = createAction('WORKERSCOMPENSATIONINSURANCE/CLEAR');

export const clearWorkersCompensationInsuranceDraft = createAction(
  'WORKERSCOMPENSATIONINSURANCE/CLEAR_DRAFT',
);

export const setWorkersCompensationInsurance = createAction<IWorkersCompensationInsurance>(
  'WORKERSCOMPENSATIONINSURANCE/SET_OUTPUT',
);

export const setWorkersCompensationInsuranceDraft = createAction<IWorkersCompensationInsurance>(
  'WORKERSCOMPENSATIONINSURANCE/SET_DRAFT',
);

export const setWorkersCompensationInsuranceDraftValues = createAction<
  Partial<IWorkersCompensationInsurance>
>('WORKERSCOMPENSATIONINSURANCE/SET_DRAFT_VALUE');

export const setWorkersCompensationInsurancesList = createAction<IWorkersCompensationInsurance[]>(
  'WORKERSCOMPENSATIONINSURANCE/SET_LIST',
);

export const updateWorkersCompensationInsurancesList = createAction<IWorkersCompensationInsurance>(
  'WORKERSCOMPENSATIONINSURANCE/UPDATE_LIST',
);

export const removeWorkersCompensationInsuranceFromList = createAction<string>(
  'WORKERSCOMPENSATIONINSURANCE/REMOVE_FROM_LIST',
);

export const duplicateWorkersCompensationInsurance = createAction<IWorkersCompensationInsurance>(
  'WORKERSCOMPENSATIONINSURANCE/DUPLICATE',
);

// Complex actions
export const fetchWorkersCompensationInsurancesList = createAsyncThunk<void, void, AppThunkConfig>(
  'WORKERSCOMPENSATIONINSURANCE/FETCH_WORKERSCOMPENSATIONINSURANCE_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('workers-compensation-insurance'));
    try {
      const { data } =
        await api.workersCompensationInsurance.fetchWorkersCompensationInsurancesListFromAPI();
      dispatch(setWorkersCompensationInsurancesList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('workers-compensation-insurance'));
    }
  },
);

export const fetchWorkersCompensationInsurance = createAsyncThunk<void, string, AppThunkConfig>(
  'WORKERSCOMPENSATIONINSURANCE/FETCH_INPUT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('workers-compensation-insurance'));
    try {
      const { data } =
        await api.workersCompensationInsurance.fetchWorkersCompensationInsuranceFromAPI(id);
      dispatch(setWorkersCompensationInsurance(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('workers-compensation-insurance'));
    }
  },
);

export const createWorkersCompensationInsurance = createAsyncThunk<
  void,
  IWorkersCompensationInsurance,
  AppThunkConfig
>('WORKERSCOMPENSATIONINSURANCE/CREATE', async (workersCompensationInsurance, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('workers-compensation-insurance'));
  try {
    const { data } =
      await api.workersCompensationInsurance.createWorkersCompensationInsuranceFromAPI(
        workersCompensationInsurance,
      );
    dispatch(setWorkersCompensationInsurance(data));
    dispatch(fetchWorkersCompensationInsurancesList());
    dispatch(
      showToastAlert({
        title: intl.formatMessage({
          id: 'previsionalInstitutions.successToast.save',
        }),
        message: '',
        type: 'success',
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'previsionalInstitutions',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('workers-compensation-insurance'));
  }
});

export const updateWorkersCompensationInsurance = createAsyncThunk<
  void,
  IWorkersCompensationInsurance,
  AppThunkConfig
>('WORKERSCOMPENSATIONINSURANCE/FETCH', async (workersCompensationInsurance, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('workers-compensation-insurance'));
  try {
    const { data } =
      await api.workersCompensationInsurance.updateWorkersCompensationInsuranceFromAPI(
        workersCompensationInsurance,
      );
    dispatch(setWorkersCompensationInsurance(data));
    dispatch(fetchWorkersCompensationInsurancesList());
    dispatch(
      showToastAlert({
        title: intl.formatMessage({
          id: 'previsionalInstitutions.successToast.save',
        }),
        message: '',
        type: 'success',
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'previsionalInstitutions',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('workers-compensation-insurance'));
  }
});

export const deleteWorkersCompensationInsurance = createAsyncThunk<void, string, AppThunkConfig>(
  'WORKERSCOMPENSATIONINSURANCE/DELETE_WORKERSCOMPENSATIONINSURANCE',
  async (workersCompensationInsuranceId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('workers-compensation-insurance'));
    try {
      await api.workersCompensationInsurance.deleteWorkersCompensationInsuranceFromAPI(
        workersCompensationInsuranceId,
      );
      dispatch(removeWorkersCompensationInsuranceFromList(workersCompensationInsuranceId));
      dispatch(clearWorkersCompensationInsurance());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.delete',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('workers-compensation-insurance'));
    }
  },
);

export const saveWorkerCompensationInsurance = createAsyncThunk<
  void,
  IWorkersCompensationInsurance,
  AppThunkConfig
>('WORKERSCOMPENSATIONINSURANCE/SAVE', async (workersCompensationInsurance, ThunkAPI) => {
  const { dispatch } = ThunkAPI;

  // if the workerCompensationInsurance has an id, it means it already exists and we should update it
  if (workersCompensationInsurance.workersCompensationInsuranceCompanyId) {
    dispatch(updateWorkersCompensationInsurance(workersCompensationInsurance));
  } else {
    // if the workerCompensationInsurance does not have an id, it means it is a new one and we should create it
    dispatch(createWorkersCompensationInsurance(workersCompensationInsurance));
  }
  dispatch(clearWorkersCompensationInsuranceDraft());
});
