import { createReducer } from '@reduxjs/toolkit';

import { ILedgerItems } from '@laudus/types';

import { resetLedger, setLedger } from './actions';

export interface ILedgerState {
  current: ILedgerItems[];
}

export const initialLedgerState: ILedgerState = {
  current: [],
};

export const ledgerReducer = createReducer(initialLedgerState, (builder) => {
  builder
    .addCase(setLedger, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(resetLedger, (state) => {
      return { ...state, current: [] };
    })
    .addDefaultCase((state) => state);
});
