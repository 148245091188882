import { Box, Flex, IconButton, Text } from '@chakra-ui/react';

import { IconClose, IconDislike, IconInfoCircle, IconLike, IconWarning } from '@laudus/icons';

type ToastVariant = 'success-toast' | 'error-toast' | 'warning-toast' | 'info-toast';

const iconByVariant: Record<ToastVariant, JSX.Element> = {
  'success-toast': <IconLike color="#009BAE" size="1.5rem" />,
  'error-toast': <IconDislike color="#F1617B" size="1.5rem" />,
  'warning-toast': <IconWarning color="#FFB78E" size="1.6rem" />,
  'info-toast': <IconInfoCircle color="#7CC5EF" size="1.6rem" />,
};

export interface IToastProps {
  onClose: () => void;
  size?: string;
  text: string;
  variant: ToastVariant;
}

export const Toast = ({ onClose, size = 'small', text, variant }: IToastProps) => {
  return (
    <Box boxShadow="0px 3px 1.6rem rgba(66, 66, 66, 0.53)">
      <Flex
        alignItems="center"
        bg="neutralBrown200"
        justifyContent="space-between"
        p={size === 'small' ? '2px 15px 2px 2rem' : '0.8rem 15px 0.8rem 2rem'}
        width="100%"
      >
        <Flex alignItems="center">
          <Box mr="1.2rem">{iconByVariant[variant] || ''}</Box>
          <Text color="secondary500" fontSize="1.4rem" fontWeight="600" lineHeight="1.7">
            {text}
          </Text>
        </Flex>
        <IconButton
          aria-label="close toast"
          icon={<IconClose fontWeight="600" size="1.8rem" color="black" />}
          justifySelf="flex-end"
          m="0px"
          ml="13px"
          onClick={onClose}
          p="0px"
          variant={variant}
        />
      </Flex>
    </Box>
  );
};
