import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString } from '@laudus/shared-utils';
import { IPosShiftBalance, IPosShiftListItem, IPosShifts } from '@laudus/types';

import {
  clearPosShift,
  clearPosShiftDraft,
  clearPosShiftList,
  duplicatePosShift,
  removePosShiftFromList,
  setPosShift,
  setPosShiftBalance,
  setPosShiftDraft,
  setPosShiftHistory,
  setPosShiftList,
  updatePosShiftList,
} from './actions';

export interface IPosShiftState {
  current: IPosShifts;
  draft: IPosShifts;
  list: IPosShifts[];
  history: IPosShiftListItem[];
  balance: IPosShiftBalance;
}

export const POS_SHIFT_EMPTY: IPosShifts = {
  shiftId: 0,
  pos: {
    POSId: 0,
    name: '',
  },
  salesman: {
    salesmanId: 0,
    name: '',
  },
  dateFrom: dateToLocalISOString(new Date()),
  dateTo: dateToLocalISOString(new Date()),
  initialCashBalance: 0,
  closingCashBalance: 0,
  notes: '',
};

export const POS_SHIFT_BALANCE_EMPTY: IPosShiftBalance = {
  shiftId: 0,
  pos: {
    POSId: 0,
    name: '',
  },
  salesman: {
    salesmanId: 0,
    name: '',
  },
  dateFrom: dateToLocalISOString(new Date()),
  dateTo: dateToLocalISOString(new Date()),
  initialCashBalance: 0,
  closingCashBalance: 0,
  items: [],
};

export const initialPosShiftState: IPosShiftState = {
  current: POS_SHIFT_EMPTY,
  draft: POS_SHIFT_EMPTY,
  list: [],
  history: [],
  balance: POS_SHIFT_BALANCE_EMPTY,
};

export const posShiftReducer = createReducer(initialPosShiftState, (builder) => {
  builder
    .addCase(clearPosShift, (state) => {
      return { ...state, current: POS_SHIFT_EMPTY };
    })
    .addCase(clearPosShiftDraft, (state) => {
      return { ...state, draft: POS_SHIFT_EMPTY };
    })
    .addCase(clearPosShiftList, (state) => {
      return { ...state, list: [] };
    })
    .addCase(setPosShift, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setPosShiftDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setPosShiftList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updatePosShiftList, (state, action) => {
      const updatedShiftInList: IPosShiftListItem = {
        shiftId: action.payload.shiftId,
        dateFrom: action.payload.dateFrom,
        dateTo: action.payload.dateTo,
        notes: action.payload.notes,
        initialCashBalance: action.payload.initialCashBalance,
        closingCashBalance: action.payload.closingCashBalance,
        salesman_salesmanId: action.payload.salesman?.salesmanId,
        salesman_name: action.payload.salesman?.name,
        pos_posId: action.payload.pos?.POSId,
        pos_name: action.payload.pos?.name,
      };

      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.shiftId !== action.payload.shiftId),
          updatedShiftInList,
        ],
      };
    })
    .addCase(removePosShiftFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.shiftId !== action.payload),
      };
    })
    .addCase(duplicatePosShift, (state, action) => {
      const { shiftId, ...duplicatedPosShift } = action.payload;
      return { ...state, draft: duplicatedPosShift };
    })
    .addCase(setPosShiftHistory, (state, action) => {
      return { ...state, history: action.payload };
    })
    .addCase(setPosShiftBalance, (state, action) => {
      state.balance = action.payload;
    })
    .addDefaultCase((state) => state);
});
