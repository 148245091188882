import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { ICode, IFetchWithEtagParams } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';

export const setCodesList = createAction<ICode[]>('CODES/SET_LIST');

export const fetchCodesList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'CODES/FETCH_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(startRequest('codes'));
      }

      const { data } = await api.codes.fetchCodesListAPI();
      dispatch(setCodesList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'codes',
          action: 'read',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('codes'));
      }
    }
  },
);
