import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { ICustomerContact, IFetchWithEtagParams } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

const entityPrefix = 'CUSTOMER_CONTACS';
// Customers Tab action creators

// Customers action creators
export const clearCustomerContact = createAction(`${entityPrefix}/CLEAR`);

export const setCustomerContact = createAction<ICustomerContact>(`${entityPrefix}/SET`);

export const setCustomerContactDraft = createAction<ICustomerContact>(`${entityPrefix}/SET_DRAFT`);

export const setCustomerContactDraftValues = createAction<Partial<ICustomerContact>>(
  `${entityPrefix}/SET_DRAFT_VALUE`,
);

export const setCustomerContactsList = createAction<Record<number, ICustomerContact[]>>(
  `${entityPrefix}/SET_LIST`,
);

export const updateCustomerContactList = createAction<Record<number, ICustomerContact[]>>(
  `${entityPrefix}/UPDATE_LIST`,
);

export const removeCustomerContactFromList = createAction<string | number>(
  `${entityPrefix}/REMOVE_FROM_LIST`,
);

interface IfetchCustomerContactsList extends IFetchWithEtagParams {
  customerId: number;
}

export const fetchCustomerContactsList = createAsyncThunk<
  void,
  IfetchCustomerContactsList,
  AppThunkConfig
>(`${entityPrefix}/FETCH_LIST`, async ({ customerId, silent }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  if (!silent) {
    dispatch(startRequest('customer-contacts'));
  }
  try {
    const { data } = await api.customerContacts.getCustomerContactsListAPI(customerId);
    dispatch(setCustomerContactsList({ [customerId]: Array.isArray(data) ? data : [] }));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'customerContacts',
        action: 'list',
      }),
    );
  } finally {
    if (!silent) {
      dispatch(endRequest('customer-contacts'));
    }
  }
});

export interface IFetchCustomerContactsParams {
  customerId: number;
  contactId: number;
}

export const fetchCustomerContact = createAsyncThunk<
  void,
  IFetchCustomerContactsParams,
  AppThunkConfig
>(`${entityPrefix}/FETCH`, async ({ customerId, contactId }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('customer-contacts'));
  try {
    const { data } = await api.customerContacts.getCustomerContactAPI(customerId, contactId);
    dispatch(setCustomerContact(data));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'customerContacts',
        action: 'read',
      }),
    );
  } finally {
    dispatch(endRequest('customer-contacts'));
  }
});

export interface IUpdateCustomerContactsParams {
  customerId: number;
  customerContact: ICustomerContact;
}

export const updateCustomerContact = createAsyncThunk<
  void,
  IUpdateCustomerContactsParams,
  AppThunkConfig
>(`${entityPrefix}/FETCH`, async ({ customerId, customerContact }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('customer-contacts'));
  try {
    const { data } = await api.customerContacts.updateCustomerContactAPI(
      customerId,
      customerContact,
    );

    dispatch(setCustomerContact(data));
    //dispatch(updateCustomerContactList({ [customerId]: [data] }));
    //TODO: make updateCustomerContactList works, now overwrites with one value and not keep others records
    //it must update only the item and keep all others
    //this behavior must be here but by the moment i refresh list calling API here better than in form
    dispatch(fetchCustomerContactsList({ customerId }));
    dispatch(
      showToastAlert({
        title: intl.formatMessage({
          id: 'customerContacts.successToast.save',
        }),
        message: '',
        type: 'success',
        isToast: true,
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'customerContacts',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('customer-contacts'));
  }
});

export interface ICreateCustomerContactParams {
  customerId: number;
  customerContact: ICustomerContact;
}

export const createCustomerContact = createAsyncThunk<
  void,
  ICreateCustomerContactParams,
  AppThunkConfig
>(`${entityPrefix}/FETCH`, async ({ customerId, customerContact }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('customer-contacts'));
  try {
    const { data } = await api.customerContacts.createCustomerContactAPI(
      customerId,
      customerContact,
    );
    dispatch(setCustomerContact(data));
    //dispatch(updateCustomerContactList({ [customerId]: [data] }));
    //TODO: make updateCustomerContactList works, now overwrites with one value and not keep others records
    //it must update only the item and keep all others items of a customer
    //this behavior must be here but by the moment i refresh list calling API here better than in form
    dispatch(fetchCustomerContactsList({ customerId }));
    dispatch(
      showAlert({
        title: intl.formatMessage({
          id: 'customerContacts.successToast.save',
        }),
        message: '',
        type: 'success',
        isToast: true,
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'customerContacts',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('customer-contacts'));
  }
});

export interface IDeleteCustomerContactsParams {
  customerId: number;
  contactId: number;
}

export const deleteCustomerContact = createAsyncThunk<
  void,
  IDeleteCustomerContactsParams,
  AppThunkConfig
>(`${entityPrefix}/FETCH`, async ({ customerId, contactId }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('customer-contacts'));
  try {
    await api.customerContacts.deleteCustomerContactAPI(customerId, contactId);
    dispatch(removeCustomerContactFromList(contactId));
    dispatch(clearCustomerContact());
    dispatch(
      showAlert({
        title: intl.formatMessage({
          id: 'customerContacts.successToast.delete',
        }),
        message: '',
        type: 'success',
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'customerContacts',
        action: 'delete',
      }),
    );
  } finally {
    dispatch(endRequest('customer-contacts'));
  }
});
