import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IPosShiftState } from './reducer';

export const getPosShiftSlice = (state: AppState): IPosShiftState => state.posShift;

export const getPosShiftList = createSelector([getPosShiftSlice], (state) => state.list);

export const getPosShiftHistory = createSelector([getPosShiftSlice], (state) => state.history);

export const getPosShiftHistoryGroupedByDate = createSelector([getPosShiftHistory], (list) => {
  return list?.reduce((acc, currentValue) => {
    const date = new Date(currentValue.dateFrom || '');
    const dateWithoutTime = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate(),
    ).toString();

    acc[dateWithoutTime] = acc[dateWithoutTime] || [];
    acc[dateWithoutTime].push(currentValue);
    return acc;
  }, Object.create(null));
});

export const getPosShift = createSelector([getPosShiftSlice], (state) => state.current);

export const getPosShiftDraft = createSelector([getPosShiftSlice], (state) => state.draft);

export const getPOSShiftsBalance = createSelector([getPosShiftSlice], (state) => state.balance);

export const getPosShiftBalanceCalculations = createSelector(
  [getPOSShiftsBalance],
  (POSshiftsBalance) => {
    const totals = {
      initialCashBalanceTotal: 0,
      salesTotal: 0,
      withdrawalsTotal: 0,
      incomeTotal: 0,
      theoreticalBalanceTotal: 0,
      closingCashBalanceTotal: 0,
      differenceTotal: 0,
    };

    const formattedItems = POSshiftsBalance.items?.length
      ? POSshiftsBalance.items.map((item) => {
          const initialCashBalance =
            item.receiptType?.code === 'E' ? +POSshiftsBalance.initialCashBalance : 0;
          const closingCashBalance =
            item.receiptType?.code === 'E' ? +POSshiftsBalance.closingCashBalance : 0;
          const theoreticalBalance =
            initialCashBalance +
            item.sales -
            item.adjustments?.withdrawals +
            item.adjustments?.incomes;
          const difference =
            item.receiptType?.code === 'E' ? theoreticalBalance - closingCashBalance : 0;

          totals.initialCashBalanceTotal += initialCashBalance;
          totals.salesTotal += item.sales;
          totals.withdrawalsTotal += item.adjustments?.withdrawals;
          totals.incomeTotal += item.adjustments?.incomes;
          totals.theoreticalBalanceTotal += theoreticalBalance;
          totals.closingCashBalanceTotal += closingCashBalance;
          totals.differenceTotal += difference;

          return {
            ...item,
            initialCashBalance,
            closingCashBalance,
            theoreticalBalance,
            difference,
          };
        })
      : undefined;

    return {
      ...POSshiftsBalance,
      items: formattedItems,
      totals,
    };
  },
);
