import { forwardRef } from 'react';

import { getDocTypeList, useAppSelector } from '@laudus/redux-app';

import { SelectEditor } from './SelectEditor';

const SelectDocTypesEditorComponent: React.ForwardRefRenderFunction<any, any> = (props, ref) => {
  const docTypesList = useAppSelector(getDocTypeList);

  const options = docTypesList.map((docType) => ({
    label: docType.name as string,
    value: docType.docTypeId as number,
  }));

  return <SelectEditor {...props} options={options} ref={ref} />;
};

SelectDocTypesEditorComponent.displayName = 'SelectDocTypesEditor';

export const SelectDocTypesEditor = forwardRef(SelectDocTypesEditorComponent);
