import { forwardRef } from 'react';

import { getAccountsList, useAppSelector } from '@laudus/redux-app';

import { SelectEditor } from './SelectEditor';

const SelectAccountEditorComponent: React.ForwardRefRenderFunction<any, any> = (props, ref) => {
  const accountList = useAppSelector(getAccountsList);

  const options = accountList.map((account) => ({
    label: account.name as string,
    value: account.accountId,
  }));

  return <SelectEditor {...props} options={options} ref={ref} />;
};

SelectAccountEditorComponent.displayName = 'SelectAccountEditor';

export const SelectAccountEditor = forwardRef(SelectAccountEditorComponent);
