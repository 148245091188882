import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Link, Stack, Text, useBreakpointValue } from '@chakra-ui/react';

import { DeviceTypes } from '@laudus/types';

import { PASSWORD_RESET_PATH } from '../../router/constants';

interface ILoginCheckboxProps {
  linkTitle?: string;
}

export const LoginCheckbox = ({ linkTitle }: ILoginCheckboxProps) => {
  const deviceType = useBreakpointValue(
    { base: DeviceTypes.Mobile, md: DeviceTypes.Desktop },
    { ssr: false },
  );

  if (deviceType !== DeviceTypes.Desktop) {
    return null;
  }

  return (
    <Stack
      direction="row"
      justifyContent={{ base: 'flex-end', md: 'space-between' }}
      pb={{ base: '5rem', md: '2.9rem' }}
      w="100%"
    >
      <Link
        color="neutral500"
        fontSize={{ base: '1.5rem', md: '1.4rem' }}
        fontWeight="400"
        textAlign="center"
        textDecoration="underline"
        whiteSpace="nowrap"
      >
        <RouterLink to={PASSWORD_RESET_PATH}>
          <Text whiteSpace="nowrap">{linkTitle}</Text>
        </RouterLink>
      </Link>
    </Stack>
  );
};
