import { createAction, createAsyncThunk, Dispatch } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { getMessageFromError, getMessageFromSpecialAPIErrorCode } from '@laudus/shared-utils';
import { IAlert, IAPIError } from '@laudus/types';

export const clearAlert = createAction<IAlert>('ALERTS/CLEAR_ALERT');

export const clearAllAlerts = createAction<void>('ALERTS/CLEAR_ALL_ALERTS');

export const showAlert = createAction<IAlert>('ALERTS/SET_ALERT');

export const showToastAlert = createAction<IAlert>('ALERTS/SET_TOAST_ALERT');

export interface IShowErrorParams {
  error: unknown;
  prefix: string;
  action: 'list' | 'read' | 'save' | 'delete';
  additionalMessage?: string;
}

export const showErrorAlert = createAsyncThunk<void, IShowErrorParams, { dispatch: Dispatch }>(
  'ALERTS/SHOW_ERROR',
  async ({ error, prefix, action, additionalMessage }, ThunkAPI) => {
    const { dispatch } = ThunkAPI;

    const message =
      // Try to get message by looking for special code
      getMessageFromSpecialAPIErrorCode(error as IAPIError) ??
      // If no special code is found, try to get message from the error response
      getMessageFromError({
        error,
        // If there's no way to get a message from the error, use fallback message
        fallbackMessage: intl.formatMessage({
          id: `${prefix}.warning.errorMessage`,
        }),
      });

    const messageToShow = additionalMessage ? `${message}.\n ${additionalMessage}` : message;

    dispatch(
      showAlert({
        type: 'error',
        title: intl.formatMessage({ id: `${prefix}.errorToast.${action}` }),
        message: messageToShow,
      }),
    );
  },
);
