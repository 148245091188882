import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IPosShiftBalance, IPosShifts } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Simple actions
export const clearPosShift = createAction('POS_SHIFTS/CLEAR');

export const clearPosShiftDraft = createAction('POS_SHIFTS/CLEAR_DRAFT');

export const clearPosShiftList = createAction('POS_SHIFTS/CLEAR_LIST');

export const setPosShift = createAction<IPosShifts>('POS_SHIFTS/SET_POS_SHIFT');

export const setPosShiftDraft = createAction<IPosShifts>('POS_SHIFTS/SET_POS_SHIFT_DRAFT');

export const setPosShiftList = createAction<IPosShifts[]>('POS_SHIFTS/SET_LIST');

export const updatePosShiftList = createAction<IPosShifts>('POS_SHIFTS/UPDATE_LIST');

export const removePosShiftFromList = createAction<number>('POS_SHIFTS/REMOVE_FROM_LIST');

export const duplicatePosShift = createAction<IPosShifts>('POS_SHIFTS/DUPLICATE');

export const setPosShiftBalance = createAction<IPosShiftBalance>(
  'POS_SHIFTS/SET_POS_SHIFT_BALANCE',
);

//Complex actions
export const fetchActivePosShift = createAsyncThunk<void, number, AppThunkConfig>(
  'POS_SHIFTS/FETCH_POS_SHIFTS_LIST',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('pos-shifts'));
    try {
      const { data } = await api.posShifts.fetchActivePosShiftsListAPI(id);
      const foundShiftInList = data.find((shift) => shift);
      // The actual filtering is currently being made in the API call using filterBy. We just need to take the first one (or undefined if the list is empty)
      const activeShift: IPosShifts = {
        shiftId: foundShiftInList?.shiftId,
        dateFrom: foundShiftInList?.dateFrom,
        dateTo: foundShiftInList?.dateTo,
        notes: foundShiftInList?.notes,
        initialCashBalance: foundShiftInList?.initialCashBalance,
        closingCashBalance: foundShiftInList?.closingCashBalance,
        salesman:
          foundShiftInList?.salesman_salesmanId && foundShiftInList?.salesman_name
            ? {
                salesmanId: foundShiftInList?.salesman_salesmanId,
                name: foundShiftInList?.salesman_name,
              }
            : undefined,
        pos:
          foundShiftInList?.pos_posId && foundShiftInList?.pos_name
            ? {
                POSId: foundShiftInList?.pos_posId,
                name: foundShiftInList?.pos_name,
              }
            : undefined,
      };
      dispatch(setPosShift(activeShift));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'pos',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('pos-shifts'));
    }
  },
);

export const fetchPosShift = createAsyncThunk<void, number, AppThunkConfig>(
  'POS_SHIFTS/FETCH_POS_SHIFT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('pos-shifts'));
    try {
      const { data } = await api.posShifts.fetchPosShiftAPI(id);
      dispatch(setPosShift(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'pos',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('pos-shifts'));
    }
  },
);

export const createPosShift = createAsyncThunk<void, Partial<IPosShifts>, AppThunkConfig>(
  'POS_SHIFTS/CREATE_POS_SHIFT',
  async (params, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('pos-shifts'));
    try {
      const { data } = await api.posShifts.createPosShiftAPI(params);
      dispatch(setPosShift(data));
      dispatch(updatePosShiftList(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'pos',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('pos-shifts'));
    }
  },
);

export const closePosShift = createAsyncThunk<void, Partial<IPosShifts>, AppThunkConfig>(
  'POS_SHIFTS/UPDATE_POS_SHIFT',
  async (params, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('pos-shifts'));
    try {
      await api.posShifts.updatePosShiftAPI(params);

      if (params.pos?.POSId) {
        dispatch(fetchActivePosShift(params.pos?.POSId));
      }

      dispatch(clearPosShift());
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'pos',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('pos-shifts'));
    }
  },
);

export const setPosShiftHistory = createAction<IPosShifts[]>('POS_SHIFTS/SET_HISTORY');

export const fetchPosShiftHistory = createAsyncThunk<void, number, AppThunkConfig>(
  'POS_SHIFTS/FETCH_POS_SHIFT_HISTORY',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('pos-shifts'));
    try {
      const { data } = await api.posShifts.fetchPosShiftsHistoryAPI(id);
      dispatch(setPosShiftHistory(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'pos',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('pos-shifts'));
    }
  },
);

export const fetchPosShiftBalance = createAsyncThunk<void, number, AppThunkConfig>(
  'POS_SHIFTS/FETCH_POS_SHIFT_BALANCE',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('pos-shifts'));
    try {
      const { data } = await api.posShifts.fetchPosShiftBalanceAPI(id);
      dispatch(setPosShiftBalance(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'pos',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('pos-shifts'));
    }
  },
);
