import { createReducer } from '@reduxjs/toolkit';

import { IEmployeeAbsence } from '@laudus/types';

import {
  clearEmployeeAbsence,
  clearEmployeeAbsenceDraft,
  removeEmployeeAbsenceFromList,
  setAbsencesButtonIndex,
  setEmployeeAbsence,
  setEmployeeAbsenceDraft,
  setEmployeeAbsenceDraftValues,
  setEmployeeAbsencesList,
  updateEmployeeAbsencesList,
} from '.';

export const EMPLOYEES_ABSENCES_TAB_ID = 'employeesAbsences';

export interface IEmployeeAbsenceState {
  current: IEmployeeAbsence;
  draft: IEmployeeAbsence;
  list: IEmployeeAbsence[];
  absencesButtonIndex: string;
}

export const EMPLOYEE_ABSENCE_EMPTY: IEmployeeAbsence = {
  employeeAbsenceId: 0,
  type: '',
  dateFrom: '',
  dateTo: '',
  medicalLeave: false,
  notes: '',
};

export const initialEmployeeAbsenceState: IEmployeeAbsenceState = {
  current: EMPLOYEE_ABSENCE_EMPTY,
  draft: EMPLOYEE_ABSENCE_EMPTY,
  list: [],
  absencesButtonIndex: 'Datos',
};

export const employeeAbsenceReducer = createReducer(initialEmployeeAbsenceState, (builder) => {
  builder
    .addCase(clearEmployeeAbsence, (state) => {
      return { ...state, current: EMPLOYEE_ABSENCE_EMPTY };
    })
    .addCase(clearEmployeeAbsenceDraft, (state) => {
      return { ...state, draft: EMPLOYEE_ABSENCE_EMPTY, error: undefined };
    })
    .addCase(setEmployeeAbsence, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setEmployeeAbsenceDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setEmployeeAbsenceDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setEmployeeAbsencesList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateEmployeeAbsencesList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.employeeAbsenceId !== action.payload.employeeAbsenceId),
          action.payload,
        ],
      };
    })

    .addCase(removeEmployeeAbsenceFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.employeeAbsenceId !== action.payload),
      };
    })
    .addCase(setAbsencesButtonIndex, (state, action) => {
      return { ...state, absencesButtonIndex: action.payload };
    })
    .addDefaultCase((state) => state);
});
