import { createReducer } from '@reduxjs/toolkit';

import { ISupplierInvoice } from '@laudus/types';

import { setSupplierStatistics } from './actions';

export interface ISupplierInvoicesState {
  list: Record<number, ISupplierInvoice[]>;
}

export const initialSupplierStatisticsState: ISupplierInvoicesState = {
  list: {},
};

export const supplierStatisticsReducer = createReducer(
  initialSupplierStatisticsState,
  (builder) => {
    builder
      .addCase(setSupplierStatistics, (state, action) => {
        return { ...state, list: { ...state.list, ...action.payload } };
      })
      .addDefaultCase((state) => state);
  },
);
