import { extendTheme, ThemeConfig } from '@chakra-ui/react';

import { colors as DEPRECATED_COLORS } from '@laudus/shared-ui';

import { breakpoints } from './breakpoints';
import { components } from './chakra-ui';
import { styles } from './styles';
import { textStyles } from './textStyles';

const config: ThemeConfig = {
  initialColorMode: 'light',
  useSystemColorMode: false,
  // TODO disabled until erp app dark mode is ready
  // initialColorMode: 'system',
  // useSystemColorMode: true,
  disableTransitionOnChange: false,
};

export const createTheme = (colors: Record<string, string>) =>
  extendTheme({
    config,
    breakpoints,
    colors: {
      ...DEPRECATED_COLORS,
      ...colors,
    },
    components,
    styles,
    textStyles,
  });
