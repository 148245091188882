import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, IWarehouse } from '@laudus/types';

import { AppState, AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { getSettingsByName } from '../settings';
import { addTab } from '../tabs';

import { getWarehouse, getWarehouseList } from './selectors';

// Warehouses Tab action creators

export const addHomeWarehousesTab = () =>
  addTab({
    tab: {
      id: 'warehouse',
      title: intl.formatMessage({ id: 'warehouse.tabTitle' }),
      path: 'pages/Warehouse/Warehouse',
      isRemovable: true,
    },
  });

export const addViewWarehousesTab = (id?: string) =>
  addTab({
    tab: {
      id: 'warehouse',
      title: intl.formatMessage({ id: 'warehouse.tabTitle' }),
      path: 'pages/Warehouse/WarehouseView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewWarehousesTab = () =>
  addTab({
    tab: {
      id: 'warehouse',
      title: intl.formatMessage({ id: 'warehouse.tabTitle' }),
      path: 'pages/Warehouse/WarehouseNew',
      isRemovable: true,
    },
  });

export const addEditWarehousesTab = () =>
  addTab({
    tab: {
      id: 'warehouse',
      title: intl.formatMessage({ id: 'warehouse.tabTitle' }),
      path: 'pages/Warehouse/WarehouseEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearWarehouse = createAction('WAREHOUSE/CLEAR');

export const clearWarehouseDraft = createAction('WAREHOUSE/CLEAR_DRAFT');

export const setWarehouse = createAction<IWarehouse>('WAREHOUSE/SET_WAREHOUSE');

export const setWarehouseDraft = createAction<IWarehouse>('WAREHOUSE/SET_WAREHOUSE_DRAFT');

export const setWarehouseList = createAction<IWarehouse[]>('WAREHOUSE/SET_LIST');

export const updateWarehouseList = createAction<IWarehouse>('WAREHOUSE/UPDATE_LIST');

export const removeWarehouseFromList = createAction<string>('WAREHOUSE/REMOVE_FROM_LIST');

export const duplicateWarehouse = createAction<IWarehouse>('WAREHOUSE/DUPLICATE');

export const setWarehouseDraftValues = createAction<Partial<IWarehouse>>(
  'WAREHOUSE/SET_WAREHOUSE_DRAFT_VALUE',
);

export const fetchWarehouseList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'WAREHOUSE/FETCH_WAREHOUSE_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    if (!silent) {
      dispatch(startRequest('warehouses'));
    }

    try {
      const { data } = await api.warehouses.fetchWarehouseListFromAPI();
      dispatch(setWarehouseList(Array.isArray(data) ? data : []));
      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'warehouse',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(startRequest('warehouses'));
      }

      try {
        const { data } = await api.warehouses.fetchWarehouseListFromAPI();
        dispatch(setWarehouseList(Array.isArray(data) ? data : []));
        dispatch(setEtagsCurrentEtag(eTag));
      } catch (error) {
        dispatch(
          showErrorAlert({
            error,
            prefix: 'warehouse',
            action: 'list',
          }),
        );
      } finally {
        if (!silent) {
          dispatch(endRequest('warehouses'));
        }
      }
    }
  },
);

export const fetchWarehouse = createAsyncThunk<void, string, AppThunkConfig>(
  'WAREHOUSE/FETCH_WAREHOUSE',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('warehouses'));

    try {
      const { data } = await api.warehouses.fetchWarehouseFromAPI(id);
      dispatch(setWarehouse(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'warehouse',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('warehouses'));
    }
  },
);

export const resetWarehouse = createAsyncThunk<void, void, AppThunkConfig>(
  `WAREHOUSE/RESET_WAREHOUSE`,
  async (_, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;
    try {
      const state = getState() as AppState;
      const idDefaultWarehouse = getSettingsByName<string>('defaultWarehouseId')(state);

      const warehouseList = getWarehouseList(state);
      const currentWarehouse = getWarehouse(state);

      // If the current warehouse is not the same as the default warehouse, I'll fetch and set
      if (idDefaultWarehouse && currentWarehouse.warehouseId !== idDefaultWarehouse) {
        if (warehouseList.length > 0) {
          const warehouse = warehouseList.find((c) => c.warehouseId === idDefaultWarehouse);
          if (warehouse) {
            dispatch(setWarehouse(warehouse));
          }
        } else {
          dispatch(fetchWarehouse(idDefaultWarehouse));
        }
      }
    } catch (error) {
      // TODO: What happen if there is an error?
      console.log(error);
    }
  },
);
