import { createReducer } from '@reduxjs/toolkit';

export interface ISIITransactionType {
  SIITransactionId: string;
  name: string;
}

export interface ISIITransactionState {
  SIITransactionTypes: ISIITransactionType[];
}

export const initialSIITransactionsState: ISIITransactionState = {
  SIITransactionTypes: [
    {
      SIITransactionId: '1',
      name: 'Venta del Giro',
    },
    {
      SIITransactionId: '2',
      name: 'Venta de Activo Fijo',
    },
    {
      SIITransactionId: '3',
      name: 'Venta de Bien Raíz',
    },
  ],
};

export const SIITransactionsReducer = createReducer(initialSIITransactionsState, (builder) => {
  builder.addDefaultCase((state) => state);
});
