import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const Select: ComponentMultiStyleConfig = {
  parts: ['field', 'icon'],
  variants: {
    base: {
      field: {
        bg: 'white',
        border: '2px solid',
        borderColor: 'neutral.10',
        borderRadius: 0,
        color: 'black',
        fontSize: '1.4rem',
        height: '48px',
        _disabled: {
          bg: 'neutral.10',
          borderColor: 'neutral.10',
          color: 'neutral500',
        },
        _focus: {
          'borderColor': 'secondary100',
          '+.chakra-select__icon-wrapper': {
            color: 'error.100',
          },
        },
        _invalid: {
          'bg': 'none',
          'borderColor': 'error.100',
          '+.chakra-select__icon-wrapper': {
            color: 'error.100',
          },
          '_disabled': {
            'bg': 'neutral.10',
            'color': 'neutral500',
            'borderColor': 'error.100',
            '+.chakra-select__icon-wrapper': {
              color: 'error.100',
            },
          },
        },
      },
      icon: {
        color: 'neutral.40',
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'base',
  },
};
