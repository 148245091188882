import { createReducer } from '@reduxjs/toolkit';

import { renameKeyPreservingOrder } from '@laudus/shared-utils';
import { ICustomer, IFormState, IPriceList, IPriceListProducts } from '@laudus/types';

import {
  clearCustomer,
  clearCustomerDraft,
  duplicateCustomer,
  removeCustomerFromList,
  renameCustomersImportListColumn,
  resetCustomerSubmit,
  resetSRISubmit,
  setCustomer,
  setCustomerDraft,
  setCustomerDraftValues,
  setCustomerProductsWithPricesOverride,
  setCustomersImportList,
  setCustomersImportSucceeded,
  setCustomersList,
  setCustomerSubmitError,
  setCustomerSubmitSuccess,
  setCustomerSubmitting,
  setSRISubmitSuccess,
  setSRISubmitting,
  updateCustomersList,
} from './actions';

const initialFormState = {
  submitting: false,
  submitError: false,
  submitSuccess: false,
};

export interface ICustomersState {
  current: ICustomer;
  cachedPriceLists?: IPriceList[];
  draft: ICustomer;
  list: ICustomer[];
  importList: any[];
  importSucceeded: boolean;
  formState: IFormState;
  productsWithPricesOverride?: IPriceListProducts;
}

export const CUSTOMER_EMPTY: ICustomer = {
  name: '',
};

export const initialCustomersState: ICustomersState = {
  current: CUSTOMER_EMPTY,
  draft: CUSTOMER_EMPTY,
  list: [],
  importList: [],
  importSucceeded: false,
  formState: initialFormState,
  productsWithPricesOverride: undefined,
};

export const customersReducer = createReducer(initialCustomersState, (builder) => {
  builder
    .addCase(clearCustomer, (state) => {
      return { ...state, current: CUSTOMER_EMPTY, productsWithPricesOverride: undefined };
    })
    .addCase(clearCustomerDraft, (state) => {
      return { ...state, draft: CUSTOMER_EMPTY };
    })
    .addCase(setCustomer, (state, action) => {
      return { ...state, current: action.payload, productsWithPricesOverride: undefined };
    })
    .addCase(setCustomerProductsWithPricesOverride, (state, action) => {
      return {
        ...state,
        current: action.payload.customer,
        productsWithPricesOverride: action.payload.productsWithPricesOverride,
      };
    })
    .addCase(setCustomerDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setCustomerDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setCustomersList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateCustomersList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((b) => b.customerId !== action.payload.customerId),
          action.payload,
        ],
      };
    })
    .addCase(removeCustomerFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((b) => b.customerId !== action.payload),
      };
    })
    .addCase(setCustomersImportList, (state, action) => {
      return { ...state, importList: action.payload };
    })
    .addCase(renameCustomersImportListColumn, (state, action) => {
      const { newColumnName, oldColumnName } = action.payload;
      return {
        ...state,
        importList: state.importList.map((row) =>
          renameKeyPreservingOrder(row, oldColumnName, newColumnName),
        ),
      };
    })
    .addCase(setCustomersImportSucceeded, (state, action) => {
      return { ...state, importSucceeded: action.payload };
    })
    .addCase(duplicateCustomer, (state, action) => {
      const { customerId, ...duplicatedCustomer } = action.payload;
      return { ...state, draft: duplicatedCustomer };
    })
    .addCase(resetSRISubmit, (state) => {
      return { ...state, formState: initialFormState };
    })
    .addCase(setSRISubmitting, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitting: true },
      };
    })
    .addCase(setSRISubmitSuccess, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitSuccess: true },
      };
    })
    .addCase(resetCustomerSubmit, (state) => {
      return { ...state, formState: initialFormState };
    })
    .addCase(setCustomerSubmitting, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitting: true },
      };
    })
    .addCase(setCustomerSubmitError, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitError: true },
      };
    })
    .addCase(setCustomerSubmitSuccess, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitSuccess: true },
      };
    })
    .addDefaultCase((state) => state);
});
