import { IAccountInfo } from './accounts';
import { IPurchaseInvoiceInfo } from './purchaseInvoices';
import { IUserInfo } from './users';

export interface IFixedAsset {
  fixedAssetId?: number;
  name: string;
  dateAcquisition: string | null;
  dateRetirement: string | null;
  acquisitionCost: number;
  usefulLife: number;
  realizableValue: number;
  depreciationType: FixedAssetDepreciationType;
  depreciate: boolean;
  depreciateFromMonthOfAcquisition: boolean;
  depreciateWithInflationCorrection: boolean;
  taxDeductionPercentage: number;
  manufacturer: string;
  model: string;
  serialNumber: string;
  supplier: string;
  invoiceNumber: string;
  location: string;
  purchaseInvoice: IPurchaseInvoiceInfo | null;
  account: IAccountInfo | null;
  accountDepreciation: IAccountInfo | null;
  accountAccumulatedDepreciation: IAccountInfo | null;
  notes: string | null;
  createdBy: IUserInfo | null;
  createdAt: string | null;
  modifiedBy: IUserInfo | null;
  modifiedAt: string | null;
  depreciation: IFixedAssetDepreciation[];
}

export enum FixedAssetDepreciationType {
  LINEAR = 'LI',
  DECREASING_DIGITS = 'DD',
  INCREASING_DIGITS = 'DC',
  FIXED_AMOUNT_ON_DECREASING_AMORTIZABLE_BASIS = 'TD',
  NOT_DEPRECIATE = 'NO',
}

export interface IFixedAssetDepreciation {
  depreciationId?: number;
  year: number;
  firstMonth: number;
  lastMonth: number;
  percentage: number;
  amount: number;
  accumulated: number;
  amountWithInflationCorrection: number;
  accumulatedWithInflationCorrection: number;
}

export interface IFixedAssetListItem {
  fixedAssetId: number;
  name: string;
  location: string;
  dateAcquisition: string | null;
}
