import { createAction, Store } from '@reduxjs/toolkit';
import axios, { AxiosHeaders } from 'axios';

import { intl } from '@laudus/intl';
import { getMessageFromSpecialAPIErrorCode } from '@laudus/shared-utils';
import { IAPIError, IAuthAccessToken, IAuthError } from '@laudus/types';

const GLOBAL_AUTH_PATH = '/accounts/security/';

// Global entities need global token but a 403 status shouldn't invalidate the session
const GLOBAL_ENTITIES = ['glaccounts', 'glcompanies', 'glusers'];
const GLOBAL_ENTITIES_REGEX = new RegExp(
  `${GLOBAL_AUTH_PATH}(?:${GLOBAL_ENTITIES.join('|')})/`,
  'i',
);

let store: Store;
export function setAxios(storeRef: Store) {
  store = storeRef;
}

const { VITE_API_URL } = import.meta.env;

export const client = axios.create({ baseURL: VITE_API_URL });

client.interceptors.request.use((config) => {
  // Only add Laudus headers to Laudus API request
  if (config.url?.startsWith('http') && !config.url.startsWith(VITE_API_URL)) {
    return config;
  }

  const path = window.location.pathname;
  const index = path.indexOf('/', 1);
  const companyVatId = path.substring(1, index > 0 ? index : undefined);

  const state = store.getState();
  const globalAuthtoken = state.auth.authToken;
  const accessToken = state.auth.accessTokens.find(
    (t: IAuthAccessToken) => t.VATId === companyVatId,
  );
  const pathname = config.url?.replace(VITE_API_URL as string, '');
  const token = pathname?.startsWith(GLOBAL_AUTH_PATH) ? globalAuthtoken : accessToken?.token;

  if (config.headers) {
    if (!config.headers.Authorization) {
      config.headers.Authorization = `Bearer ${token}`;
      config.headers['X-Source'] = 'LaudusApp';
    }
  } else {
    config.headers = {
      'Authorization': `Bearer ${token}`,
      'X-Source': 'LaudusApp',
    } as unknown as AxiosHeaders;
    if (!token) {
      delete config.headers.Authorization;
    }
  }

  return config;
});

client.interceptors.response.use(
  (response) => {
    if (response.status === 204) {
      return Promise.resolve({ ...response, data: [] });
    }
    return Promise.resolve(response);
  },
  (error) => {
    const isGlobalTokenError =
      !error?.response || error.response.config.url.indexOf(GLOBAL_AUTH_PATH) >= 0;
    const isGlobalEntityError = GLOBAL_ENTITIES_REGEX.test(error.response.config.url);

    const state = store.getState();
    const authToken = state.auth.authToken;

    // We detected a invalid global auth token, we need to invalidate the user's session.
    // As a result, the user will be redirected to the /login route.
    const hasAuthorizationError = [403, 401].includes(error.response?.status);
    if (hasAuthorizationError && isGlobalTokenError && !isGlobalEntityError && !!authToken) {
      const authError = {
        title: intl.formatMessage({ id: 'login.notAuthorised.title' }),
        message:
          // Try to get message by looking for special code
          getMessageFromSpecialAPIErrorCode(error as IAPIError) ??
          // If no special code is found, show generic auth error
          intl.formatMessage({ id: 'login.error' }),
      };
      store.dispatch(createAction<IAuthError>('AUTH/INVALIDATE_AUTH')(authError));
      return Promise.reject(error);
    }

    // We detected a company level authorisation issue, so we remove the companies current
    // access token. If they have a valid global token they will auto login otherwise they
    // see a "No Access" alert and are then redirected to select a company login step.
    const hasAuthenticationError = error.response?.status === 401;
    if (hasAuthenticationError && !isGlobalTokenError) {
      const accessError = {
        title: intl.formatMessage({ id: 'login.notAuthorised.title' }),
        message:
          // Try to get message by looking for special code
          getMessageFromSpecialAPIErrorCode(error as IAPIError) ??
          // If no special code is found, show generic auth error
          intl.formatMessage({ id: 'login.notAuthorised.description' }),
      };
      store.dispatch(
        createAction<Partial<{ error: IAuthError } & IAuthAccessToken>>('AUTH/REMOVE_ACCESS_TOKEN')(
          {
            error: accessError,
            VATId: window.location.pathname.split('/')[1],
          },
        ),
      );
      return Promise.reject(error);
    }

    return Promise.reject(error);
  },
);
