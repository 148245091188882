import React from 'react';
import { Navigate } from 'react-router-dom';

import { getAuthState, useGlobalSelector } from '@laudus/redux-global';

import { LOGIN_PATH } from './constants';

interface IPrivateRouteProps {
  component: React.ComponentType;
}

export const PrivateRoute = ({ component: Component }: IPrivateRouteProps) => {
  const { isLoggedIn } = useGlobalSelector(getAuthState);
  if (isLoggedIn) {
    return <Component />;
  }

  return <Navigate replace to={LOGIN_PATH} />;
};
