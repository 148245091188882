import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString } from '@laudus/shared-utils';
import {
  IStatisticsDataItem,
  IStatisticsFormattedFilterItems,
  IStatisticsRemoteFieldConfiguration,
} from '@laudus/types';

import {
  clearStatistics,
  clearStatisticsDashboardCardInfo,
  clearStatisticsDashboardCardsData,
  clearStatisticsFilterValue,
  setStatisticsData,
  setStatisticsFields,
  setStatisticsFilters,
  setStatisticsSelectedId,
} from './actions';

export interface IStatisticsState {
  selectedId: string;
  filters: IStatisticsFormattedFilterItems;
  data: IStatisticsDataItem[] | null;
  fields: IStatisticsRemoteFieldConfiguration[] | null;
  dashboardCardsData: {
    [key: number]: {
      data: IStatisticsDataItem[] | null;
      fields: IStatisticsRemoteFieldConfiguration[] | null;
    };
  };
}

export const STATISTICS_FILTERS_EMPTY: IStatisticsFormattedFilterItems = {
  period: 'all',
  dateFrom: '1990-01-01T00:00:00',
  dateTo: dateToLocalISOString(new Date()),
};

export const initialStatisticsState: IStatisticsState = {
  selectedId: '',
  filters: STATISTICS_FILTERS_EMPTY,
  data: null,
  fields: null,
  dashboardCardsData: {},
};

export const statisticsReducer = createReducer(initialStatisticsState, (builder) => {
  builder
    .addCase(clearStatistics, (state) => {
      return {
        ...state,
        selectedId: '',
        filters: STATISTICS_FILTERS_EMPTY,
        data: null,
        fields: null,
      };
    })
    .addCase(clearStatisticsDashboardCardsData, (state) => {
      return { ...state, dashboardCardsData: {} };
    })
    .addCase(clearStatisticsDashboardCardInfo, (state, action) => {
      const { [action.payload]: _, ...rest } = state.dashboardCardsData;
      return { ...state, dashboardCardsData: rest };
    })
    .addCase(setStatisticsSelectedId, (state, action) => {
      return { ...state, selectedId: action.payload, data: null, fields: null };
    })
    .addCase(setStatisticsFilters, (state, action) => {
      return { ...state, filters: action.payload };
    })
    .addCase(clearStatisticsFilterValue, (state, action) => {
      let newFilterValue = undefined;
      if (STATISTICS_FILTERS_EMPTY[action.payload]) {
        newFilterValue = STATISTICS_FILTERS_EMPTY[action.payload];
      }
      return {
        ...state,
        filters: { ...state.filters, [action.payload]: newFilterValue },
      };
    })
    .addCase(setStatisticsData, (state, action) => {
      const { cardId, data } = action.payload;
      if (!cardId) {
        return { ...state, data };
      }
      return {
        ...state,
        dashboardCardsData: {
          ...state.dashboardCardsData,
          [cardId]: {
            data,
            fields: state.dashboardCardsData[cardId]?.fields || null,
          },
        },
      };
    })
    .addCase(setStatisticsFields, (state, action) => {
      const { cardId, fields } = action.payload;
      if (!cardId) {
        return { ...state, fields };
      }
      return {
        ...state,
        dashboardCardsData: {
          ...state.dashboardCardsData,
          [cardId]: {
            fields,
            data: state.dashboardCardsData[cardId]?.data || null,
          },
        },
      };
    })
    .addDefaultCase((state) => state);
});
