import { Image, Link, LinkBox, Stack, Text } from '@chakra-ui/react';

import { getRandomImageIndex } from '@laudus/assets';
import { colors } from '@laudus/shared-ui';

import { COMPANY_IMAGES } from './constants';

const company = COMPANY_IMAGES[getRandomImageIndex(COMPANY_IMAGES.length)];
const image = company.images[getRandomImageIndex(company.images.length)];

export const LoginDiagram = () => {
  return (
    <Stack maxW={{ md: '36rem', lg: '51.2rem' }} w="100%">
      <Image borderRadius="1.6rem" maxWidth={{ md: '60rem', lg: 'full' }} src={image} />
      <LinkBox>
        <Text
          align="left"
          fontSize={{ md: '1.4rem', lg: '2.1rem' }}
          fontWeight="700"
          lineHeight="3.74rem"
          marginTop="3.6rem"
          w="100%"
        >
          {`En ${company.name} usan el software de `}
          <Link color={colors.primary500}>Laudus®</Link>
          {` para la gestión con clientes y proveedores.`}
        </Text>
      </LinkBox>
      <Text align="left" color={colors.primary500} mt="0.6rem" textStyle={{ md: 'h6', lg: 'h5' }}>
        {company.location}
      </Text>
    </Stack>
  );
};
