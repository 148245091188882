import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import {
  ICustomField,
  IProductList,
  ISalesInvoice,
  ISalesInvoicesItem,
  ISalesOrder,
  IUser,
  QuantityDelivered,
  TRACE_FROM_STEP_ORIGIN,
} from '@laudus/types';

import { SALES_INVOICE_EMPTY } from '../../constants/salesInvoices';
import { calculateInvoiceItemVat } from '../calculateInvoiceTotals';

import {
  copyCustomFieldsFromOneEntityToAnother,
  copyNotesFromSalesDocumentToAnother,
  ensureSalesDocumentCustomerMatch,
  ensureSalesDocumentIsNotNulled,
  ensureSalesDocumentIsNotSentToSII,
  ensureSalesDocumentMatchTheUserBranchRestriction,
  getLineWithTheSameProduct,
  getQuantityDeliveredOfSalesItem,
  getQuantityToCopyToSalesItem,
  getVATRateForProduct,
} from './genericFunctions';

export async function createSalesInvoiceFromSalesOrder({
  initialInvoice = { ...SALES_INVOICE_EMPTY, SII_transactionType: '1' },
  salesOrder,
  user,
  customFieldsForSalesInvoices = [],
  customFieldsForSalesInvoicesItems = [],
  customFieldsForSalesOrders = [],
  customFieldsForSalesOrdersItems = [],
  shouldCopyNotesToInvoice = false,
  quantityDeliveredFromOrder = [],
  groupItemsWithTheSameProduct = false,
  maxItemsInInvoices,
  generalVATRate = 19,
  mainCurrencyDecimals = 0,
  allowDTE = false,
  allowDTETickets = false,
  productList,
}: {
  initialInvoice?: ISalesInvoice;
  salesOrder: ISalesOrder;
  user: IUser;
  customFieldsForSalesInvoices?: ICustomField[];
  customFieldsForSalesInvoicesItems?: ICustomField[];
  customFieldsForSalesOrders?: ICustomField[];
  customFieldsForSalesOrdersItems?: ICustomField[];
  shouldCopyNotesToInvoice?: boolean;
  quantityDeliveredFromOrder?: QuantityDelivered;
  groupItemsWithTheSameProduct?: boolean;
  maxItemsInInvoices?: number;
  generalVATRate?: number;
  mainCurrencyDecimals?: number;
  allowDTE?: boolean;
  allowDTETickets?: boolean;
  productList: IProductList;
}): Promise<ISalesInvoice> {
  // Those can throw an error
  ensureSalesDocumentIsNotNulled({ salesDocument: salesOrder });
  ensureOrderWasNotAlreadyAddedToInvoice({
    salesInvoice: initialInvoice,
    salesOrder,
  });
  ensureSalesDocumentIsNotSentToSII({ salesDocument: initialInvoice });
  ensureSalesDocumentMatchTheUserBranchRestriction({
    user,
    salesDocument: salesOrder,
  });
  ensureSalesDocumentCustomerMatch({
    secondSalesDocument: salesOrder,
    firstSalesDocument: initialInvoice,
  });

  // Start filling the invoice
  const invoice = { ...initialInvoice };

  // Fill the invoice fields from the sales order fields
  copyHeaderFieldsFromOrderToInvoice({ salesOrder, invoice });
  copyCustomFieldsFromOneEntityToAnother({
    entityToGetCustomFieldsFrom: salesOrder,
    customFieldsForEntityToGetCustomFieldsFrom: customFieldsForSalesOrders,
    entityToCopyCustomFieldsInto: invoice,
    customFieldsForEntityToCopyCustomFieldsInto: customFieldsForSalesInvoices,
  });

  if (shouldCopyNotesToInvoice) {
    copyNotesFromSalesDocumentToAnother({
      originSalesDocument: salesOrder,
      newSalesDocument: invoice,
    });
  }

  assignDocTypeToInvoice({
    salesOrder,
    invoice,
    allowDTE,
    allowDTETickets,
  });

  // Fill the invoice items from the sales order items
  await copyItemsFromOrderToInvoice({
    salesOrder,
    invoice,
    quantityDelivered: quantityDeliveredFromOrder,
    groupItemsWithTheSameProduct,
    maxItemsInInvoices,
    customFieldsForSalesInvoicesItems,
    customFieldsForSalesOrdersItems,
    generalVATRate,
    mainCurrencyDecimals,
    productList,
  });

  // Return the filled invoice
  return invoice;
}

function copyHeaderFieldsFromOrderToInvoice({
  salesOrder,
  invoice,
}: {
  salesOrder: ISalesOrder;
  invoice: ISalesInvoice;
}) {
  const fieldsToCopy: (keyof ISalesOrder & keyof ISalesInvoice)[] = [
    'customer',
    'contact',
    'salesman',
    'dealer',
    'term',
    'priceList',
    'branch',
    'purchaseOrderNumber',
    'deliveryCost',
    'deliveryNotes',
    'carrier',
    'deliveryAddress',
    'source',
    'sourceOrderId',
  ];

  fieldsToCopy.forEach((salesOrderProperty) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (invoice as any)[salesOrderProperty] = salesOrder[salesOrderProperty];
  });
}

function assignDocTypeToInvoice({
  salesOrder,
  invoice,
  allowDTE,
  allowDTETickets,
}: {
  salesOrder: ISalesOrder;
  invoice: ISalesInvoice;
  allowDTE: boolean;
  allowDTETickets: boolean;
}) {
  // Use salesOrder doctype, or default to standard invoice
  if (salesOrder.invoiceDocType?.docTypeId) {
    invoice.docType = salesOrder.invoiceDocType;
  } else {
    invoice.docType = {
      docTypeId: 30,
      name: '',
    };
  }

  // Convert from standard to electronic types if the company is allowed to issue electronic tickets
  if (invoice.docType.docTypeId === 35 && allowDTE && allowDTETickets) {
    invoice.docType.docTypeId = 39;
  }

  if (invoice.docType.docTypeId === 38 && allowDTE && allowDTETickets) {
    invoice.docType.docTypeId = 41;
  }

  if (invoice.docType.docTypeId === 30 && allowDTE) {
    invoice.docType.docTypeId = 33;
  }

  if (invoice.docType.docTypeId === 32 && allowDTE) {
    invoice.docType.docTypeId = 34;
  }
}

async function copyItemsFromOrderToInvoice({
  salesOrder,
  invoice,
  quantityDelivered,
  groupItemsWithTheSameProduct,
  maxItemsInInvoices,
  customFieldsForSalesOrdersItems,
  customFieldsForSalesInvoicesItems,
  generalVATRate,
  mainCurrencyDecimals,
  productList,
}: {
  salesOrder: ISalesOrder;
  invoice: ISalesInvoice;
  quantityDelivered: QuantityDelivered;
  groupItemsWithTheSameProduct?: boolean;
  maxItemsInInvoices?: number;
  customFieldsForSalesOrdersItems: ICustomField[];
  customFieldsForSalesInvoicesItems: ICustomField[];
  generalVATRate: number;
  mainCurrencyDecimals: number;
  productList: IProductList;
}) {
  const salesInvoiceItems: ISalesInvoicesItem[] = [];
  const salesOrderItems = salesOrder.items ?? [];

  // Get only salesOrderItems that haven't been completely delivered
  const salesOrderItemsThatHaventBeenCompletelyDelivered = salesOrderItems.filter(
    (salesOrderItem) => {
      const itemQuantityDelivered = getQuantityDeliveredOfSalesItem({
        quantityDelivered,
        salesItem: salesOrderItem,
      });

      const hasItemBeenCompletelyDelivered =
        !!itemQuantityDelivered && itemQuantityDelivered.delivered >= salesOrderItem.quantity;

      return !hasItemBeenCompletelyDelivered;
    },
  );

  // Copy each item
  for (const salesOrderItem of salesOrderItemsThatHaventBeenCompletelyDelivered) {
    const quantityToCopy = getQuantityToCopyToSalesItem({
      quantityDelivered,
      salesItem: salesOrderItem,
    });

    // Lets try to find a line with the same product and add the quantity
    if (groupItemsWithTheSameProduct) {
      const lineWithTheSameProduct = getLineWithTheSameProduct({
        salesItems: salesInvoiceItems,
        salesItem: salesOrderItem,
      });

      if (lineWithTheSameProduct) {
        lineWithTheSameProduct.quantity += quantityToCopy;
        continue;
      }
    }

    // Get VAT rate
    const productId = salesOrderItem.product?.productId as number;
    const VATRate = getVATRateForProduct({
      productId,
      generalVATRate,
      productList,
    });

    // Create a new item and fill its information
    const { archived, ...sharedPropertiesBetweenOrderItemAndInvoiceItem } = salesOrderItem;

    const newInvoiceItem: ISalesInvoicesItem = {
      ...sharedPropertiesBetweenOrderItemAndInvoiceItem,
      VATRate,
      quantity: quantityToCopy,
      itemId: getNegativeUniqueNumericId(),
      VAT: 0,
      taxes: [],
      notInvoiceable: false,
      traceFrom: [
        {
          fromId: salesOrderItem.itemId,
          fromStep: TRACE_FROM_STEP_ORIGIN.ORDERS,
        },
      ],
    };

    newInvoiceItem.VAT = calculateInvoiceItemVat(newInvoiceItem, mainCurrencyDecimals);

    copyCustomFieldsFromOneEntityToAnother({
      entityToGetCustomFieldsFrom: salesOrderItem,
      customFieldsForEntityToGetCustomFieldsFrom: customFieldsForSalesOrdersItems,
      entityToCopyCustomFieldsInto: newInvoiceItem,
      customFieldsForEntityToCopyCustomFieldsInto: customFieldsForSalesInvoicesItems,
    });

    salesInvoiceItems.push(newInvoiceItem);
  }

  // Add new items
  const newInvoiceItems = salesInvoiceItems.concat(invoice.items ?? []);

  if (maxItemsInInvoices && newInvoiceItems.length > maxItemsInInvoices) {
    throw new Error(
      `La factura contiene más items (${newInvoiceItems.length}) que la cantidad máxima permitida (${maxItemsInInvoices})`,
    );
  }

  invoice.items = newInvoiceItems;
}

function ensureOrderWasNotAlreadyAddedToInvoice({
  salesInvoice,
  salesOrder,
}: {
  salesInvoice: ISalesInvoice;
  salesOrder: ISalesOrder;
}) {
  const salesOrderItemsIds = salesOrder.items.map((salesOrderItem) => salesOrderItem.itemId);
  const wasOrderAlreadyAdded = salesInvoice.items.some((salesInvoiceItem) =>
    salesInvoiceItem.traceFrom?.some(
      (trace) =>
        trace.fromStep === TRACE_FROM_STEP_ORIGIN.ORDERS &&
        salesOrderItemsIds.includes(trace.fromId),
    ),
  );

  if (wasOrderAlreadyAdded) {
    throw new Error('Un pedido no se puede usar múltiples veces en la misma factura');
  }
}
