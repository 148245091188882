import { Colors } from '@chakra-ui/react';

interface IColors extends Colors {
  [key: string]: string;
}

/**
 * @deprecated This now this is only applicable to the ERP app.
 * You should use css theme based variables primarily, failing that you should
 * use the value of the colors object from the ThemeProvider context.
 */
export const colors: IColors = {
  primary500: '#009BAE', // Primary
  primary400: '#35C7D9', // Primary Light
  primary300: '#66C3CE', // Primary Disabled
  primary200: '#CCEBEF', // Primary Ultralight
  primary100: '#EBF7F9', // Statistics tags (chips) background
  // SECONDARY
  secondary900: '#1A1F2B', // Secondary UltraDark Dark Mode
  secondary800: '#232D3E', // Secondary Dark Mode Dark
  secondary700: '#394559', // Secondary Dark Mode Light
  secondary600: '#081A35', // Secondary Dark
  secondary500: '#0F284E', // Secondary
  secondary400: '#28384F', // Secondary Light
  secondary300: '#22395C', // Secondary Light Medium
  secondary200: '#576883', // Secondary Ultralight
  secondary100: '#8793A6', // Secondary MegaLight
  // TERTIARY
  tertiary500: '#DFBA83', // Cream Dark - Modal arrows
  tertiary400: '#FFEACC', // Cream - Grid Arrows
  // NEUTRAL
  black: '#000000', // Black - Body copy
  white: '#FFFFFF', // White
  neutral1050: '#242e3f', // Grey background when dorpdown the draft
  neutral1000: '#1A202C', // Grey - Second Login Card
  neutral900: '#384559', // Grey Second Login Buttom
  neutral800: '#42424280', // Grey - User Menu (name & VATId)
  neutral700: '#474747', // Grey - Label Field
  neutral600: '#666666', // Grey - Body copy disabled
  neutral500: '#AAADB3', // Grey Medium - Border
  neutral450: '#D4DAE1', // Grey Light - Traces border
  neutral400: '#CCCCCC', // Grey Light - black button disabled
  neutral300: '#E9ECF0', // Grey Light - Grid guides & bar
  neutral200: '#F5F6F8', // Grey Ultralight - Search Button background
  neutral150: '#F0F2F5',
  neutral100: '#F4F4F6', // White Dark
  neutral50: '#F0F0F3', // Grey Ultralight - Background
  neutral25: '#F7F7F9', // Search Table Background

  // BROWNS
  neutralBrown600: '#BC8271', // Brown Red Dark - Modal Header
  neutralBrown500: '#A9A29C', // Grey Brown - Grid Header
  neutralBrown400: '#CBC4BD', // Grey Brown Light - Header Guides
  neutralBrown300: '#E8E5E3', // Grey Brown - MegaLight - Card Border
  neutralBrown200: '#F0F0F0', // Grey Brown Ultralight - Row Background
  // SEMANTIC -- Danger
  danger600: '#BE3E55', // Red Dark
  danger500: '#F1617B', // Red
  danger400: '#F8718A', // Red Light
  danger300: '#FCDFE5', // Red Ultralight
  danger200: '#FF8299',
  // SEMANTIC -- Info
  info500: '#7CC5EF', // Sky-Blue Dark
  info400: '#BADFF3', // Sky-Blue Light
  // SEMANTIC -- Success
  success600: '#2C8A57',
  success400: '#80e2d0a8', // Green
  success500Bright: '#4AC281', // Green Bright
  success500: '#00C5A1', // Green
  success10: 'rgba(0, 197, 161, 0.1)',
  // SEMANTIC -- Warning
  warning500: '#F39159', // Orange Dark
  warning400: '#FFB78E', // Orange light
  warning300: '#FFE2D1', // Orange Ultralight
  // GRADIENTS
  gradientSoft: 'linear-gradient(95.53deg, #007E8D 9.51%, #02B2C8 100%)',
  gradientHeavy: 'linear-gradient(90deg, #39D6EA, #0F284E)',
  gradientHeavyReverse: 'linear-gradient(90deg, #0F284E, #39D6EA)',
  gradientDanger: 'linear-gradient(90deg, #F8A1B0 0%, #F1617B 100%)',
  gradientWarning: 'linear-gradient(90deg, #FFB78E, #FFB78E)',
  // BACKDROP
  blackOpacity50: 'rgba(0, 0, 0, 0.5)', // Backdrop modal
  // OTHERS
  greenApple: '#DEEF73',
  greenGrass: '#6EC566',
  pink: '#E83D7A',
  field: '#2d3748', // Field background
  greenDark: '#056571', // Green for sku in ProductCard darkMode,
  greenExcel: '#016C18', // Export to Excel table button icon
  // ALPHA
  transparentWhite50: 'rgba(255, 255, 255, 0.5)', // Loader background
  transparentWhite20: 'rgba(255, 255, 255, 0.2)', // On Hover Primary menu
  transparentWhite10: 'rgba(255, 255, 255, 0.1)', // On Hover Pages menu item
  transparentGrey40: 'rgba(183, 191, 204, 0.4)', // Menu User Shadow
  transparentGrey48: 'rgba(150, 150, 150, 0.48)',
  transparentGrey70: 'rgba(241, 241, 241, 0.7)', // Menu tabs background
  transparentGrey95: 'rgba(241, 241, 241, 0.95)', // SubMenu tabs background
  transparentGrey98: 'rgba(246, 246, 246, 0.98)', // Menu user background
  borderColorDark: '#ffffff29',

  //---DARK MODE---\\
  // GREYS
  darkGrey900: '#181B28', // Dark Mode principal navbar background card HomeInfo in crudLanding
  darkGrey800: '#1112174D', // Dark Mode Menu background
  darkGrey700: '#212531', // Dark Mode  principal screen background
  darkGrey600: '#292D38', // Dark Mode cards in crudLanding
  darkGrey500: '#343744', // Dark Mode principal title card crudLanding
  darkGrey400: '#3B3F4D', // Dark Mode hover in SelectField
  darkGrey300: '#474A59', // Dark Mode background arrowControls
  darkGrey200: '#4B5461', // Dark Mode color table & grid lines
  darkGrey100: '#515869', // Dark Mode tabs text in second navbar
  darkGrey50: '#959DAA', // Dark Mode Text disabled
  // TRANSPARENTS
  darkTransparentWhite38: '#FFFFFF26', //Dark Mode Iconbuttons hovers in navbar
  darkTransparentWhite97: '#FFFFFF61', // Dark Mode Hover tabs in tabsMenu
};
