import { intl } from '@laudus/intl';
import { IReportsLeftMenuItem, ReportsLeftMenuItemTypes } from '@laudus/types';

import { ACCOUNTING_REPORTS } from './accounting';
import { SALES_INVOICES_REPORTS } from './salesInvoices';

export const REPORTS_LEFT_MENU_CONFIGURATION: IReportsLeftMenuItem[] = [
  {
    type: ReportsLeftMenuItemTypes.Section,
    title: intl.formatMessage({ id: 'reports.section.title.accounting' }),
    items: ACCOUNTING_REPORTS,
    initiallyExpanded: true,
  },
  {
    type: ReportsLeftMenuItemTypes.Section,
    title: intl.formatMessage({ id: 'reports.section.title.salesInvoices' }),
    items: SALES_INVOICES_REPORTS,
    initiallyExpanded: false,
  },
];
