import { createReducer } from '@reduxjs/toolkit';

import { ICode } from '@laudus/types';

import { setCodesList } from './actions';

export interface ICodesState {
  list: ICode[];
}

export const initialCodesState: ICodesState = {
  list: [],
};

export const codesReducer = createReducer(initialCodesState, (builder) => {
  builder
    .addCase(setCodesList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addDefaultCase((state) => state);
});
