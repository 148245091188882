import { AxiosResponse } from 'axios';

import { dateSetTimeToZero } from '@laudus/shared-utils';
import { IDailyItems } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchIssuedInvoicesAPI = (
  dailyDateFrom: Date,
  dailyDateTo: Date,
): Promise<AxiosResponse<IDailyItems[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/invoices/list`,
    {
      fields: [
        'docType.name',
        'docNumber',
        'customer.name',
        'dueDate',
        'totals.net',
        'totals.total',
        'salesman.name',
        'dealer.name',
        'branch.name',
        'term.name',
      ],
      filterBy: [
        {
          field: 'issuedDate',
          operator: '>=',
          value: dateSetTimeToZero(dailyDateFrom),
        },
        {
          field: 'issuedDate',
          operator: '<',
          value: dateSetTimeToZero(dailyDateTo),
        },
      ],
      orderBy: [{ field: 'docNumber', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchIssuedInvoicesDetailAPI = (
  dailyDateFrom: Date,
  dailyDateTo: Date,
): Promise<AxiosResponse<IDailyItems[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/invoices/list`,
    {
      fields: [
        'docType.name',
        'docNumber',
        'customer.name',
        'items.product.SKU',
        'items.product.description',
        'items.quantity',
        'items.unitPrice',
        'items.discountPercentage',
        'totals.net',
        'items.account.name',
      ],
      filterBy: [
        {
          field: 'issuedDate',
          operator: '>=',
          value: dateSetTimeToZero(dailyDateFrom),
        },
        {
          field: 'issuedDate',
          operator: '<',
          value: dateSetTimeToZero(dailyDateTo),
        },
      ],
      orderBy: [
        { field: 'docNumber', direction: 'ASC' },
        { field: 'docType.name', direction: 'ASC' },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchDueInvoicesAPI = (
  dailyDateFrom: Date,
  dailyDateTo: Date,
): Promise<AxiosResponse<IDailyItems[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/invoices/list`,
    {
      fields: [
        'docType.name',
        'docNumber',
        'customer.name',
        'issuedDate',
        'totals.total',
        'totals.net',
        'salesman.name',
        'term.name',
      ],
      filterBy: [
        {
          field: 'issuedDate',
          operator: '>=',
          value: dateSetTimeToZero(dailyDateFrom),
        },
        {
          field: 'issuedDate',
          operator: '<',
          value: dateSetTimeToZero(dailyDateTo),
        },
      ],
      orderBy: [
        { field: 'docNumber', direction: 'ASC' },
        { field: 'docType.name', direction: 'ASC' },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

// 'items.VAT' api bug
export const fetchInvoicesByProductsAPI = (
  dailyDateFrom: Date,
  dailyDateTo: Date,
): Promise<AxiosResponse<IDailyItems[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/invoices/list`,
    {
      fields: [
        'items.product.SKU',
        'items.product.description',
        'items.quantity',
        'items.unitPrice',
        'items.discountPercentage',
      ],
      filterBy: [
        {
          field: 'issuedDate',
          operator: '>=',
          value: dateSetTimeToZero(dailyDateFrom),
        },
        {
          field: 'issuedDate',
          operator: '<',
          value: dateSetTimeToZero(dailyDateTo),
        },
      ],
      orderBy: [
        { field: 'items.product.SKU', direction: 'ASC' },
        { field: 'items.product.description', direction: 'ASC' },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchInvoicesByProductCategoryAPI = (
  dailyDateFrom: Date,
  dailyDateTo: Date,
): Promise<AxiosResponse<IDailyItems[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/invoices/list`,
    {
      fields: [
        'items.product.productCategory.productCategoryId',
        'items.product.productCategory.name',
        'items.product.productCategory.fullPath',
        'items.quantity',
        'items.unitPrice',
        'items.discountPercentage',
      ],
      filterBy: [
        {
          field: 'issuedDate',
          operator: '>=',
          value: dateSetTimeToZero(dailyDateFrom),
        },
        {
          field: 'issuedDate',
          operator: '<',
          value: dateSetTimeToZero(dailyDateTo),
        },
      ],
      orderBy: [
        { field: 'items.product.SKU', direction: 'ASC' },
        { field: 'items.product.description', direction: 'ASC' },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchInvoicesBySalesmanAPI = (
  dailyDateFrom: Date,
  dailyDateTo: Date,
): Promise<AxiosResponse<IDailyItems[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/invoices/list`,
    {
      fields: ['salesman.name', 'items.quantity', 'items.unitPrice', 'items.discountPercentage'],
      filterBy: [
        {
          field: 'issuedDate',
          operator: '>=',
          value: dateSetTimeToZero(dailyDateFrom),
        },
        {
          field: 'issuedDate',
          operator: '<',
          value: dateSetTimeToZero(dailyDateTo),
        },
      ],
      orderBy: [{ field: 'salesman.name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchInvoicesByBranchAPI = (
  dailyDateFrom: Date,
  dailyDateTo: Date,
): Promise<AxiosResponse<IDailyItems[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/invoices/list`,
    {
      fields: ['branch.name', 'items.quantity', 'items.unitPrice', 'items.discountPercentage'],
      filterBy: [
        {
          field: 'issuedDate',
          operator: '>=',
          value: dateSetTimeToZero(dailyDateFrom),
        },
        {
          field: 'issuedDate',
          operator: '<',
          value: dateSetTimeToZero(dailyDateTo),
        },
      ],
      orderBy: [{ field: 'branch.name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
