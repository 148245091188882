import { useState } from 'react';

import { Box } from '@chakra-ui/react';

import { IconCloseCircle } from '..';

export const IconCancel = () => {
  const [isHovered, setIsHovered] = useState(false);

  const iconStyle = {
    color: isHovered ? '#FFFFFF' : '#F8718A',
    background: isHovered ? '#F8718A' : '#FFFFFF',
    borderRadius: '100%',
  };

  return (
    <Box onMouseEnter={() => setIsHovered(true)} onMouseLeave={() => setIsHovered(false)}>
      <IconCloseCircle size={20} style={iconStyle} />
    </Box>
  );
};
