import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IUserCertificate, IUserCertificateInfo } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { endProgress, endRequest, startProgress, startRequest } from '../global/actions';

// Users Tab action creators

// Simple actions
export const clearUserCertificate = createAction('USERS_CERTIFICATE/CLEAR');

export const clearUserCertificateDraft = createAction('USERS_CERTIFICATE/CLEAR_DRAFT');

export const setUserCertificateInfo = createAction<IUserCertificateInfo>(
  'USERS_CERTIFICATE/SET_USER_CERTIFICATE_INFO',
);

export const setUserCertificate = createAction<IUserCertificate>(
  'USERS_CERTIFICATE/SET_USER_CERTIFICATE',
);

// Complex actions

export const fetchUserCertificateInfo = createAsyncThunk<void, string, AppThunkConfig>(
  'USERS_CERTIFICATE/FETCH_USER_CERTIFICATE',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('users-certificates'));
    try {
      const { data } = await api.users.fetchUserCertificateInfoFromAPI(id);
      dispatch(setUserCertificateInfo(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'users',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('users-certificates'));
    }
  },
);
// IMPORT

export const setUserCertificateImport = createAction<any>(
  'USERS_CERTIFICATE/SET_USER_CERTIFICATE_IMPORT',
);

export const createUserCertificate = createAsyncThunk<void, IUserCertificate, AppThunkConfig>(
  'USERS_CERTIFICATE/SET_USER_CERTIFICATE_IMPORT',
  async (uploadCertificate, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    const { userId } = uploadCertificate;
    delete uploadCertificate.userId;
    dispatch(startProgress());
    try {
      const { data } = await api.users.createUserCertificateFromAPI(
        uploadCertificate,
        userId || '',
      );
      dispatch(setUserCertificateImport(data));
      dispatch(fetchUserCertificateInfo(userId ?? ''));

      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'import.toast.success' }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'users',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endProgress());
    }
  },
);
