import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { isNumber } from 'lodash';

import { intl } from '@laudus/intl';
import { ICustomer, IFetchWithEtagParams, IPriceListProducts, IUser } from '@laudus/types';

import { AppState, AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert, showToastAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endProgress, endRequest, startProgress, startRequest } from '../global/actions';
import { getLoggedUser } from '../loggedUser/selectors';
import { getSettingsByName } from '../settings';
import { addTab } from '../tabs';

import { getCustomer } from './selectors';

export const CUSTOMER_TAB_ID = 'customers';

// Customers Tab action creators

export const addHomeCustomerTab = () =>
  addTab({
    tab: {
      id: CUSTOMER_TAB_ID,
      title: intl.formatMessage({ id: 'customers.tabTitle' }),
      path: 'pages/Customer/Customer',
      isRemovable: true,
    },
  });

export const addViewCustomerTab = (id?: number) =>
  addTab({
    tab: {
      id: CUSTOMER_TAB_ID,
      title: intl.formatMessage({ id: 'customers.tabTitle' }),
      path: 'pages/Customer/CustomerView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewCustomerTab = () =>
  addTab({
    tab: {
      id: CUSTOMER_TAB_ID,
      title: intl.formatMessage({ id: 'customers.tabTitle' }),
      path: 'pages/Customer/CustomerNew',
      isRemovable: true,
    },
  });

export const addEditCustomerTab = () =>
  addTab({
    tab: {
      id: CUSTOMER_TAB_ID,
      title: intl.formatMessage({ id: 'customers.tabTitle' }),
      path: 'pages/Customer/CustomerEdit',
      isRemovable: true,
    },
  });

// Customers action creators
export const clearCustomer = createAction('CUSTOMERS/CLEAR');

export const clearCustomerDraft = createAction('CUSTOMERS/CLEAR_DRAFT');

export const setCustomer = createAction<ICustomer>('CUSTOMERS/SET');

export const setCustomerProductsWithPricesOverride = createAction<{
  customer: ICustomer;
  productsWithPricesOverride: IPriceListProducts | undefined;
}>('CUSTOMERS/SET_CUSTOMER_PRICE_LIST');

export const clearCustomerPriceList = createAction('CUSTOMERS/CLEAR_PRICE_LIST');

export const setCustomerDraft = createAction<ICustomer>('CUSTOMERS/SET_DRAFT');

export const setCustomerDraftValues = createAction<Partial<ICustomer>>(
  'CUSTOMERS/SET_CUSTOMER_DRAFT_VALUE',
);

export const resetCustomerSubmit = createAction('CUSTOMERS/RESET_SUBMIT');

export const setCustomerSubmitting = createAction('CUSTOMERS/SET_SUBMITTING');

export const setCustomerSubmitError = createAction('CUSTOMERS/SET_SUBMIT_ERROR');

export const setCustomerSubmitSuccess = createAction('CUSTOMERS/SET_SUBMIT_SUCCESS');

export const setCustomersList = createAction<ICustomer[]>('CUSTOMERS/SET_LIST');

export const updateCustomersList = createAction<ICustomer>('CUSTOMERS/UPDATE_LIST');

export const removeCustomerFromList = createAction<number>('CUSTOMERS/REMOVE_FROM_LIST');

export const duplicateCustomer = createAction<ICustomer>('CUSTOMERS/DUPLICATE');

export const resetSRISubmit = createAction('CUSTOMERS/RESET_SRI_SUBMIT');

export const setSRISubmitting = createAction('CUSTOMERS/SET_SRI_SUBMIT');

export const setSRISubmitSuccess = createAction('CUSTOMERS/SET_SRI_SUBMIT_SUCCESS');

export const fetchCustomersList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'CUSTOMERS/FETCH_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(startRequest('customers'));
      }

      const { data } = await api.customers.fetchCustomersListFromAPI();
      dispatch(setCustomersList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'customers',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('customers'));
      }
    }
  },
);

export const fetchCustomer = createAsyncThunk<void, number, AppThunkConfig>(
  'CUSTOMERS/FETCH',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('customers'));
    try {
      const { data } = await api.customers.fetchCustomerFromAPI(id);
      dispatch(setCustomer(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'customers',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('customers'));
    }
  },
);

export const fetchCustomerWithPriceList = createAsyncThunk<void, number, AppThunkConfig>(
  'CUSTOMERS/FETCH_WITH_PRICE_LIST',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('customers'));
    try {
      const { data: customer } = await api.customers.fetchCustomerFromAPI(id);

      let productsWithPricesOverride;
      if (customer?.priceList?.priceListId) {
        const result = await api.priceLists.fetchProductsOfPriceListFromAPI(
          customer.priceList.priceListId,
        );
        productsWithPricesOverride = result.data;
      }

      dispatch(setCustomerProductsWithPricesOverride({ customer, productsWithPricesOverride }));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'customers',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('customers'));
    }
  },
);

export const createCustomer = createAsyncThunk<void, ICustomer, AppThunkConfig>(
  `CUSTOMERS/CREATE`,
  async (customer, ThunkAPI) => {
    const { extra, dispatch, getState } = ThunkAPI;
    const { api } = extra;
    const state = getState();
    const loggedUser: IUser | undefined = getLoggedUser(state);

    dispatch(setCustomerSubmitting());
    try {
      const customerToCreate: ICustomer = { ...customer };

      if (loggedUser?.restrictToSalesman?.salesmanId) {
        customerToCreate.salesman = {
          salesmanId: loggedUser.restrictToSalesman.salesmanId,
          name: loggedUser.restrictToSalesman.name ?? '',
        };
      }

      const { data } = await api.customers.createCustomerFromAPI(customerToCreate);

      dispatch(setCustomer(data));
      dispatch(updateCustomersList(data));
      dispatch(setCustomerSubmitSuccess());
    } catch (error) {
      dispatch(setCustomerSubmitError());
    }
  },
);

export const updateCustomer = createAsyncThunk<void, ICustomer, AppThunkConfig>(
  `CUSTOMERS/UPDATE`,
  async (customer, ThunkAPI) => {
    const { extra, dispatch } = ThunkAPI;
    const { api } = extra;
    dispatch(setCustomerSubmitting());
    try {
      const { data } = await api.customers.updateCustomerFromAPI(customer);

      dispatch(setCustomer(data));
      dispatch(updateCustomersList(data));
      dispatch(setCustomerSubmitSuccess());
    } catch (error) {
      dispatch(setCustomerSubmitError());
    }
  },
);

export const resetCustomer = createAsyncThunk<void, void, AppThunkConfig>(
  `CUSTOMERS/RESET_CUSTOMER`,
  async (_, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;
    try {
      const state = getState() as AppState;
      const idDefaultCustomer = getSettingsByName<number>('defaultCustomerForTickets')(state);

      const currentCustomer = getCustomer(state);

      // If the current customer is not the same as the default customer, I'll fetch and set
      if (isNumber(idDefaultCustomer) && currentCustomer.customerId !== idDefaultCustomer) {
        dispatch(fetchCustomerWithPriceList(idDefaultCustomer));
      } else if (
        // If the current customer is the same as the default customer, I'll clear the customer
        // and the customer was changed, I'll clear the customer
        !isNumber(idDefaultCustomer) &&
        currentCustomer.customerId !== idDefaultCustomer
      ) {
        dispatch(clearCustomer());
      }
    } catch (error) {
      // TODO: What happen if there is an error?
      console.log(error);
    }
  },
);

// IMPORT
export const setCustomersImportList = createAction<any[]>('CUSTOMERS/SET_IMPORT_LIST');

interface IRenameImportListColumnParams {
  oldColumnName: string;
  newColumnName: string;
}

export const renameCustomersImportListColumn = createAction<IRenameImportListColumnParams>(
  'CUSTOMERS/RENAME_IMPORT_LIST_COLUMN',
);

export const setCustomersImportSucceeded = createAction<boolean>('CUSTOMERS/SET_IMPORT_SUCCEEDED');

export const createCustomersList = createAsyncThunk<void, ICustomer[], AppThunkConfig>(
  'CUSTOMERS/IMPORT_PRODUCTS_LIST',
  async (customers, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startProgress());
    try {
      dispatch(setCustomersImportSucceeded(false));
      const { data } = await api.customers.bulkUpsertCustomersFromAPI(customers);

      const importError = data.find((item) => item.status !== 'Valid');
      if (importError) {
        dispatch(
          showAlert({
            type: 'error',
            title: intl.formatMessage({ id: `customers.errorToast.save` }),
            message: importError.message,
          }),
        );
        return;
      }

      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'import.toast.success' }),
          message: '',
          type: 'success',
        }),
      );
      dispatch(setCustomersImportSucceeded(true));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'customers',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endProgress());
    }
  },
);

export const setCustomerDraftTerm = createAsyncThunk<void, string | undefined, AppThunkConfig>(
  'CUSTOMERS/SET_DRAFT_TERM',
  async (termId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    if (!termId) {
      dispatch(setCustomerDraftValues({ term: undefined, daysToExpiration: 0 }));
      return;
    }

    dispatch(startRequest('customers'));
    try {
      const { data } = await api.terms.fetchTermFromAPI(termId);
      dispatch(
        setCustomerDraftValues({
          term: { termId: data.termId, name: data.name },
          daysToExpiration: data.daysToExpiration,
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'term',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('customers'));
    }
  },
);
