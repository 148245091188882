import { ICreatedBy, IModifiedBy } from '../common';

import { ICostCenterInfo } from './costCenters';
import { IContactInfo } from './customerContacts';
import { ICustomerInfo } from './customers';
import { ICustomFields } from './customFields';
import { IEmployeeInfo } from './employees';
import { IProductInfoMin } from './products';
import { IUserInfo } from './users';
import { IWarehouseInfo } from './warehouses';

export interface ISalesTicket {
  ticketId?: number; // Undefined at creation
  subject: string;
  description: string;
  solution: string;
  category: ISalesTicketCategory | null;
  status: SalesTicketStatus | null;
  assignedTo: IUserInfo | null;
  issuedDate: string | null;
  completitionDate: string | null;
  dueDate: string | null;
  customer: ICustomerInfo | null;
  submittedBy: string;
  submittedByContactInfo: string;
  createdBy: ICreatedBy | null;
  createdAt: string;
  modifiedBy: IModifiedBy | null;
  modifiedAt: string;
  customFields?: ICustomFields;
  activities: ISalesTicketActivity[];
  itemOnRepair: ISalesTicketItemOnRepair | null;
}

export interface ISalesTicketCategory {
  code: string;
  description: string;
}

export enum SalesTicketActivityStatus {
  PROCESSING = 'P',
  FINISHED = 'F',
  CANCELED = 'C',
}

export const SalesTicketActivityStatusReadableValue: Record<SalesTicketActivityStatus, string> = {
  [SalesTicketActivityStatus.PROCESSING]: 'inProcess',
  [SalesTicketActivityStatus.FINISHED]: 'finished',
  [SalesTicketActivityStatus.CANCELED]: 'canceled',
};

export interface ISalesTicketActivity {
  activityId?: number;
  subject: string;
  description: string;
  issuedDate: string | null;
  dueDate: string | null;
  status: SalesTicketActivityStatus;
  assignedTo: IUserInfo | null;
  contact: IContactInfo | null;
}

export interface ISalesTicketActivityTableData extends ISalesTicketActivity {
  statusDescription: string;
}

export interface ISalesTicketItemOnRepair {
  itemId: number | undefined;
  product: IProductInfoMin | null;
  description: string;
  reception: ISalesTicketItemOnRepairReception | null;
  reparation: ISalesTicketItemOnRepairReparation | null;
  dueDate: string | null;
  deliveryDate: string | null;
  serialNumber: ISalesTicketItemOnRepairSerialNumber | null;
  spares: ISalesTicketItemOnRepairSpare[] | null;
}

export interface ISalesTicketItemOnRepairReception {
  receivedAt: string | null;
  receivedBy: IEmployeeInfo | null;
  description: string;
}

export interface ISalesTicketItemOnRepairReparation {
  repairedAt: string | null;
  repairedBy: IEmployeeInfo | null;
  description: string;
}

export interface ISalesTicketItemOnRepairSerialNumber {
  serialNumberId: number;
  serialNumber: string;
}

export interface ISalesTicketItemOnRepairSpare {
  itemId?: number;
  spare: IProductInfoMin;
  quantity: number;
  unitPrice: number;
  discount: number;
  availableAt: string | null;
  costCenter: ICostCenterInfo | null;
  warehouse: IWarehouseInfo | null;
}

export enum SalesTicketStatus {
  OPEN = 'O',
  WAITING = 'H',
  CLOSED = 'C',
}

export const SalesTicketStatusReadableValue: Record<SalesTicketStatus, string> = {
  [SalesTicketStatus.OPEN]: 'open',
  [SalesTicketStatus.WAITING]: 'waiting',
  [SalesTicketStatus.CLOSED]: 'closed',
};

export interface ISalesTicketItemOnRepairSpareTableRow extends ISalesTicketItemOnRepairSpare {
  net: number;
}
