import { AxiosResponse } from 'axios';

import {
  IPayroll,
  IPayrollCalculationResponse,
  IPayrollLinesCalculationInput,
  IPayrollListItem,
  IWagesParameters,
} from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchPayrollListFromAPI = (): Promise<AxiosResponse<IPayrollListItem[]>> =>
  axios.post(
    `${VITE_API_URL}/HR/Payroll/list`,
    {
      fields: ['payrollId', 'date', 'notes'],
      orderBy: [{ field: 'date', direction: 'DESC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchPayrollFromAPI = (payrollId: string): Promise<AxiosResponse<IPayroll>> =>
  axios.get(`${VITE_API_URL}/HR/Payroll/${payrollId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createPayrollFromAPI = (payroll: IPayroll): Promise<AxiosResponse<IPayroll>> =>
  axios.post(`${VITE_API_URL}/HR/Payroll`, payroll);

export const updatePayrollFromAPI = (payroll: IPayroll): Promise<AxiosResponse<IPayroll>> =>
  axios.put(`${VITE_API_URL}/HR/Payroll/${payroll.payrollId}`, payroll);

export const deletePayrollFromAPI = (payrollId: string): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/HR/Payroll/${payrollId}`);

export const fetchWagesParametersFromAPI = (): Promise<AxiosResponse<IWagesParameters>> =>
  axios.get('https://laudus.cl/downloads/wagesParameters.json', {
    headers: {
      Accept: 'application/json',
    },
  });

export const calculatePayrollLinesFromAPI = (
  calculationParams: IPayrollLinesCalculationInput,
): Promise<AxiosResponse<IPayrollCalculationResponse>> =>
  axios.post(`${VITE_API_URL}/HR/Payroll/calculation`, calculationParams);
