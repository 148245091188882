import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IOutput } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

export const addHomeOutputTab = () =>
  addTab({
    tab: {
      id: 'output',
      title: intl.formatMessage({ id: 'output.tabTitle' }),
      path: 'pages/Output/Output',
      isRemovable: true,
    },
  });

export const addViewOutputTab = (id?: number) =>
  addTab({
    tab: {
      id: 'output',
      title: intl.formatMessage({ id: 'output.tabTitle' }),
      path: 'pages/Output/OutputView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewOutputTab = () =>
  addTab({
    tab: {
      id: 'output',
      title: intl.formatMessage({ id: 'output.tabTitle' }),
      path: 'pages/Output/OutputNew',
      isRemovable: true,
    },
  });

export const addEditOutputTab = () =>
  addTab({
    tab: {
      id: 'output',
      title: intl.formatMessage({ id: 'output.tabTitle' }),
      path: 'pages/Output/OutputEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearOutput = createAction('OUTPUTS/CLEAR');

export const clearOutputDraft = createAction('OUTPUTS/CLEAR_DRAFT');

export const setOutput = createAction<IOutput>('OUTPUTS/SET_OUTPUT');

export const setOutputDraft = createAction<IOutput>('OUTPUTS/SET_OUTPUT_DRAFT');

export const setOutputDraftValues = createAction<Partial<IOutput>>(
  'OUTPUTS/SET_OUTPUT_DRAFT_VALUE',
);

export const setOutputsList = createAction<IOutput[]>('OUTPUTS/SET_LIST');

export const updateOutputsList = createAction<IOutput>('OUTPUTS/UPDATE_LIST');

export const removeOutputFromList = createAction<number>('OUTPUTS/REMOVE_FROM_LIST');

export const duplicateOutput = createAction<IOutput>('OUTPUTS/DUPLICATE');

// Complex actions
export const fetchOutputsList = createAsyncThunk<void, void, AppThunkConfig>(
  'OUTPUTS/FETCH_OUTPUTS_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('outputs'));
    try {
      const { data } = await api.outputs.fetchOutputListAPI();
      dispatch(setOutputsList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'outputs',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('outputs'));
    }
  },
);

export const fetchOutput = createAsyncThunk<void, number, AppThunkConfig>(
  'OUTPUTS/FETCH_INPUT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('outputs'));
    try {
      const { data } = await api.outputs.fetchOutputAPI(id);
      dispatch(setOutput(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'outputs',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('outputs'));
    }
  },
);
