import { AxiosResponse } from 'axios';

import { IRequest, ISalesmen } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

const defaultRequest: IRequest = {
  options: {},
  orderBy: [{ field: 'salesmanId', direction: 'ASC' }],
  fields: ['name', 'salesmanId', 'legalName', 'address', 'discontinued'],
};

export const fetchSalesmenListAPI = async (
  filters: Partial<IRequest> = defaultRequest,
): Promise<AxiosResponse<ISalesmen[]>> => {
  const response = await axios.post<any[]>(
    `${VITE_API_URL}/sales/salesmen/list`,
    { ...defaultRequest, ...filters },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

  return {
    ...response,
    data: response.data.map((salesman) => {
      if (salesman.restrictToBranch_branchId) {
        return {
          ...salesman,
          restrictToBranch: {
            branchId: salesman.restrictToBranch_branchId,
          },
          restrictToBranch_branchId: undefined,
        };
      }

      return { ...salesman, restrictToBranch_branchId: undefined };
    }),
  };
};

export const fetchSalesmenAPI = (salesmanId: number): Promise<AxiosResponse<ISalesmen>> =>
  axios.get(`${VITE_API_URL}/sales/salesmen/${salesmanId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createSalesmenAPI = (salesmen: ISalesmen): Promise<AxiosResponse<ISalesmen>> =>
  axios.post(`${VITE_API_URL}/sales/salesmen`, salesmen);

export const updateSalesmenAPI = (salesmen: ISalesmen): Promise<AxiosResponse<ISalesmen>> =>
  axios.put(`${VITE_API_URL}/sales/salesmen/${salesmen.salesmanId}`, salesmen);

export const deleteSalesmenAPI = (salesmanId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/salesmen/${salesmanId}`);
