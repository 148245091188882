import { AxiosResponse } from 'axios';

import { IEmployeeAbsence } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchEmployeesAbsencesListFromAPI = (
  employeeId: number,
): Promise<AxiosResponse<IEmployeeAbsence[]>> =>
  axios.get(`${VITE_API_URL}/HR/employees/${employeeId}/absences`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const fetchEmployeeAbsenceFromAPI = (
  employeeId: number,
  absenceId: number,
): Promise<AxiosResponse<IEmployeeAbsence>> =>
  axios.get(`${VITE_API_URL}/HR/employees/${employeeId}/absences/${absenceId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createEmployeeAbsenceFromAPI = (
  employeeId: number,
  employeeAbsence: IEmployeeAbsence,
): Promise<AxiosResponse<IEmployeeAbsence>> =>
  axios.post(`${VITE_API_URL}/HR/employees/${employeeId}/absences`, employeeAbsence);

export const updateEmployeeAbsenceFromAPI = (
  employeeId: number,
  employeeAbsence: IEmployeeAbsence,
): Promise<AxiosResponse<IEmployeeAbsence>> =>
  axios.put(
    `${VITE_API_URL}/HR/employees/${employeeId}/absences/${employeeAbsence.employeeAbsenceId}`,
    employeeAbsence,
  );

export const deleteEmployeeAbsenceFromAPI = (
  employeeId: number,
  absenceId: number,
): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/HR/employees/${employeeId}/absences/${absenceId}`);
