import { createSelector } from '@reduxjs/toolkit';

import { calculatePayrollLineTotal, dateToLocalISOString } from '@laudus/shared-utils';
import { IRemunerationConceptValue } from '@laudus/types';

import { AppState } from '../../store';
import { getCostCenterList } from '../costCenters';
import {
  getRemunerationConceptListByType,
  getSortedRemunerationConceptList,
  getValuationFactorsForRemunerationConcepts,
} from '../remunerationConcepts/selectors';

import { COMPANY_PARAMETERS_EMPTY } from './constants';
import { IPayrollState } from './reducer';

export const getPayrollSlice = (state: AppState): IPayrollState => state.payroll;

export const getPayrollList = createSelector([getPayrollSlice], (state) => state.list);

export const getPayroll = createSelector([getPayrollSlice], (state) => state.current);

export const getPayrollDraft = createSelector([getPayrollSlice], (state) => state.draft);

export const getWagesParameters = createSelector(
  [getPayrollSlice],
  (state) => state.wagesParameters,
);

export const getWagesParametersByDate = (date: string) => {
  const month = /^\d{4}-\d{2}/.test(date)
    ? date.substring(0, 7)
    : dateToLocalISOString(new Date()).substring(0, 7);
  const prevDate = new Date(month);
  prevDate.setMonth(prevDate.getMonth() - 1);
  const prevMonth = dateToLocalISOString(prevDate).substring(0, 7);

  return createSelector([getWagesParameters], (state) => {
    const parametersValues = { ...COMPANY_PARAMETERS_EMPTY };

    for (const paramName of Object.keys(parametersValues)) {
      if (paramName !== 'UFmesAnterior') {
        parametersValues[paramName as keyof typeof parametersValues] =
          (state?.[paramName].find((paramValue) => paramValue.date.startsWith(month))
            ?.value as number) || 0;
      } else {
        parametersValues.UFmesAnterior =
          (state?.UF.find((paramValue) => paramValue.date.startsWith(prevMonth))
            ?.value as number) || 0;
      }
    }

    return parametersValues;
  });
};

export const getInitialCompanyParameters = createSelector(
  [getPayrollSlice],
  (state) => state.initialCompanyParameters,
);

export const getRemovingEmployee = createSelector(
  [getPayrollSlice],
  (state) => state.removingEmployee,
);

export const getPayrollIsEditing = createSelector([getPayrollSlice], (state) => state.editing);

export const getPayrollEntity = createSelector(
  [getPayroll, getPayrollDraft, getPayrollIsEditing],
  (current, draft, editing) => (editing ? draft : current),
);

export const getPayrollEmployeesRemunerationTableData = createSelector(
  [getPayrollEntity, getSortedRemunerationConceptList, getValuationFactorsForRemunerationConcepts],
  ({ lines }, remunerationConcepts, valuationFactors) => {
    const initialRemunerationConceptValues = remunerationConcepts.reduce(
      (values, concept) => {
        if (
          concept.remunerationConceptId &&
          (concept.dataType === 'N' || concept.dataType === 'C')
        ) {
          values[concept.remunerationConceptId] = {
            value: concept.dataType === 'N' ? 0 : '',
            dataType: concept.dataType,
            fixedByUser: false,
          };
        }
        return values;
      },
      {} as Record<string, IRemunerationConceptValue>,
    );

    return [
      ...new Map(
        calculatePayrollLineTotal(lines, valuationFactors).map(
          ({
            employee: { employeeId, firstName, lastName1, lastName2 },
            amountToBePaid = 0,
            remunerationAmounts,
            remunerationTexts,
            costCenterAllocation,
          }) => [
            employeeId,
            {
              employeeId,
              employeeName: `${firstName} ${lastName1} ${lastName2}`,
              costCenterName: costCenterAllocation?.[0]?.costCenter.name,
              amountToBePaid,
              values: {
                ...initialRemunerationConceptValues,
                ...remunerationAmounts?.reduce(
                  (concepts, amountItem) => {
                    concepts[amountItem.remunerationConcept.remunerationConceptId] = {
                      value: amountItem.amount,
                      dataType: 'N',
                      fixedByUser: amountItem.fixedByUser,
                    };
                    return concepts;
                  },
                  {} as Record<string, IRemunerationConceptValue>,
                ),
                ...remunerationTexts?.reduce(
                  (concepts, textItem) => {
                    concepts[textItem.remunerationConcept.remunerationConceptId] = {
                      value: textItem.text,
                      dataType: 'C',
                      fixedByUser: textItem.fixedByUser,
                    };
                    return concepts;
                  },
                  {} as Record<string, IRemunerationConceptValue>,
                ),
              },
            },
          ],
        ),
      ).values(),
    ];
  },
);

export const getPayrollCompanyParametersTableData = createSelector(
  [getPayrollEntity, getRemunerationConceptListByType('EP')],
  (entity, companyParameterRemunerationConceptList) => {
    if (!entity.lines.length) {
      return [];
    }

    const companyParametersConceptIds = companyParameterRemunerationConceptList.map(
      ({ remunerationConceptId }) => remunerationConceptId,
    );
    return [
      // We use here a Map to remove duplicates in the company parameter list.
      ...new Map(
        entity.lines[0].remunerationAmounts
          .filter(({ remunerationConcept: { remunerationConceptId } }) =>
            companyParametersConceptIds.includes(remunerationConceptId),
          )
          .map(({ remunerationConcept, amount }) => ({
            remunerationConceptId: remunerationConcept.remunerationConceptId,
            remunerationConceptName:
              companyParameterRemunerationConceptList.find(
                (concept) =>
                  concept.remunerationConceptId === remunerationConcept.remunerationConceptId,
              )?.name || '',
            amount,
          }))
          .map((line) => [line.remunerationConceptId, line]),
      ).values(),
    ];
  },
);

export const getPayrollCostCenterAllocationTableData = createSelector(
  [getPayrollEntity, getCostCenterList],
  (entity, costCenterList) => [
    // Some payroll lines may have the same employee, so we use a Map to remove duplicates.
    ...new Map(
      entity.lines.map(
        ({ employee: { employeeId, firstName, lastName1, lastName2 }, costCenterAllocation }) => [
          employeeId,
          {
            employeeId,
            employeeName: `${firstName} ${lastName1} ${lastName2}`,
            addition:
              costCenterAllocation?.reduce(
                (acc, allocation) => (acc += allocation.percentage),
                0,
              ) ?? 0,
            allocations: {
              ...costCenterList?.reduce(
                (allocationsIndex, costCenter) => {
                  if (costCenter.costCenterId) {
                    allocationsIndex[costCenter.costCenterId] =
                      costCenterAllocation?.find(
                        (allocation) =>
                          allocation.costCenter.costCenterId === costCenter.costCenterId,
                      )?.percentage || 0;
                  }
                  return allocationsIndex;
                },
                {} as Record<string, number>,
              ),
            },
          },
        ],
      ),
    ).values(),
  ],
);
