import { AxiosResponse } from 'axios';

import { ICurrencyRate } from '@laudus/types';

import {
  createCurrencyFromAPI,
  deleteCurrencyFromAPI,
  fetchCurrencyFromAPI,
  updateCurrencyFromAPI,
} from './currencies';
import {
  createCurrencyParityFromAPI,
  deleteCurrencyParityFromAPI,
  fetchCurrencyParitiesListByCurrencyIdFromAPI,
} from './currencyParities';

export const fetchCurrencyRateFromAPI = async (
  currencyId: string,
): Promise<AxiosResponse<ICurrencyRate>> => {
  const [currencyResponse, paritiesResponse] = await Promise.all([
    fetchCurrencyFromAPI(currencyId),
    fetchCurrencyParitiesListByCurrencyIdFromAPI(currencyId),
  ]);

  const currency = currencyResponse.data;
  const parities = paritiesResponse.data;

  const currencyRateResponse: AxiosResponse<ICurrencyRate> = {
    data: {
      currencyId: currencyId,
      currency: currency,
      parities: parities,
    },
    status: currencyResponse.status,
    statusText: currencyResponse.statusText,
    headers: currencyResponse.headers,
    config: currencyResponse.config,
  };

  return currencyRateResponse;
};

export const createCurrencyRateFromAPI = async (
  currencyRate: ICurrencyRate,
): Promise<AxiosResponse<ICurrencyRate>> => {
  const newCurrencyResponse = await createCurrencyFromAPI(currencyRate.currency);
  const newCurrency = newCurrencyResponse.data;
  const newCurrencyId = newCurrency.currencyId;

  const newParities = await Promise.all(
    currencyRate.parities.map(async (parity) => {
      const newParityResponse = await createCurrencyParityFromAPI(newCurrency, parity);

      return newParityResponse.data;
    }),
  );

  const newCurrencyRateResponse: AxiosResponse<ICurrencyRate> = {
    data: {
      currencyId: newCurrencyId,
      currency: newCurrency,
      parities: newParities,
    },
    status: newCurrencyResponse.status,
    statusText: newCurrencyResponse.statusText,
    headers: newCurrencyResponse.headers,
    config: newCurrencyResponse.config,
  };

  return newCurrencyRateResponse;
};

export const updateCurrencyRateFromAPI = async (
  currencyRate: ICurrencyRate,
): Promise<AxiosResponse<ICurrencyRate>> => {
  const updatedCurrencyResponse = await updateCurrencyFromAPI(currencyRate.currency);
  const updatedCurrency = updatedCurrencyResponse.data;
  const updatedCurrencyId = updatedCurrency.currencyId;
  const futureParities = currencyRate.parities;

  const currentParitiesResponse =
    await fetchCurrencyParitiesListByCurrencyIdFromAPI(updatedCurrencyId);
  const currentParities = currentParitiesResponse.data;

  // Create parities that didn't exist before but are present in the desired state of the user
  const paritiesToCreate = futureParities.filter(
    (futureParity) =>
      !currentParities.some((currentParity) => currentParity.parityId === futureParity.parityId),
  );

  const newParities = await Promise.all(
    paritiesToCreate.map(async (parity) => {
      const newParityResponse = await createCurrencyParityFromAPI(updatedCurrency, parity);
      return newParityResponse.data;
    }),
  );

  // Delete parities that existed before but are not present in the desired state of the user
  const paritiesToDelete = currentParities.filter(
    (currentParity) =>
      !futureParities.some((futureParity) => futureParity.parityId === currentParity.parityId),
  );
  await Promise.all(
    paritiesToDelete.map(async (parity) => {
      const newParityResponse = await deleteCurrencyParityFromAPI(
        updatedCurrencyId,
        parity.parityId,
      );
      return newParityResponse.data;
    }),
  );

  // Filter out deleted parities from currentParities
  const updatedCurrentParities = currentParities.filter(
    (currentParity) =>
      !paritiesToDelete.some((deletedParity) => deletedParity.parityId === currentParity.parityId),
  );

  const updatedCurrencyRateResponse: AxiosResponse<ICurrencyRate> = {
    data: {
      currencyId: updatedCurrencyId,
      currency: updatedCurrency,
      parities: [...updatedCurrentParities, ...newParities], // Merge existing parities (discarding the delete ones) with newly created parities
    },
    status: updatedCurrencyResponse.status,
    statusText: updatedCurrencyResponse.statusText,
    headers: updatedCurrencyResponse.headers,
    config: updatedCurrencyResponse.config,
  };

  return updatedCurrencyRateResponse;
};

export const deleteCurrencyRateFromAPI = (
  currencyId: string,
): Promise<AxiosResponse<ICurrencyRate>> =>
  deleteCurrencyFromAPI(currencyId) as Promise<AxiosResponse<ICurrencyRate>>;
