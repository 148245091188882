import { createSelector } from '@reduxjs/toolkit';

import { fillEmptyRelatedToInsideLines } from '@laudus/shared-utils';
import { IJournalEntry, IJournalEntryTotals } from '@laudus/types';

import { AppState, IJournalEntriesState, IJournalEntryRelatedToSearchResult } from '../..';

export const getJournalEntriesSlice = (state: AppState): IJournalEntriesState => state.journalEntry;

export const getJournalEntriesList = createSelector(
  [getJournalEntriesSlice],
  (state) => state.list,
);

export const getJournalEntry = createSelector([getJournalEntriesSlice], (state) =>
  fillEmptyRelatedToInsideLines(state.current),
);

export const getJournalEntryDraft = createSelector([getJournalEntriesSlice], (state) =>
  fillEmptyRelatedToInsideLines(state.draft),
);

export const getJournalEntryIsEditing = createSelector(
  [getJournalEntriesSlice],
  (state) => state.editing,
);

export const getJournalEntity = createSelector(
  [getJournalEntryDraft, getJournalEntry, getJournalEntryIsEditing],
  (draft, current, editing) => {
    return editing ? draft : current;
  },
);

export const getJournalEntryTotals = createSelector(
  [getJournalEntity],
  (state: IJournalEntry): IJournalEntryTotals[] => {
    let sumDebit = 0;
    let sumCredit = 0;

    state.lines.forEach((line) => {
      sumDebit += line.debit || 0;
      sumCredit += line.credit || 0;
    });

    return [
      {
        currency: 'CLP',
        highlight: false,
        title: 'journalEntries.generalData.debit',
        value: sumDebit,
      },
      {
        currency: 'CLP',
        highlight: true,
        title: 'journalEntries.generalData.credit',
        value: sumCredit,
      },
    ];
  },
);

export const getJournalEntryTotalDifference = createSelector(
  [getJournalEntity],
  (state: IJournalEntry): number => {
    let sumDebit = 0;
    let sumCredit = 0;
    state.lines.forEach((line) => {
      sumDebit += line.debit || 0;
      sumCredit += line.credit || 0;
    });
    return sumDebit - sumCredit;
  },
);

export const getJournalEntryRelatedToSearchResult = createSelector(
  [getJournalEntriesSlice],
  (state: IJournalEntriesState): IJournalEntryRelatedToSearchResult[] => {
    const data = state.accountRelatedToResult;
    if (!data) {
      return [];
    }

    return [
      ...(data?.customers?.map((oneVAT) => {
        return {
          typeLabel: 'Cliente',
          name: oneVAT.name,
          relatedToId: oneVAT.customerId,
          type: 'C',
          VATId: oneVAT.VATId,
        };
      }) ?? []),
      ...(data?.contacts?.map((oneVAT) => {
        return {
          typeLabel: 'Contacto',
          name: oneVAT.firstName + ' ' + oneVAT.lastName,
          relatedToId: oneVAT.contactId,
          type: 'O',
          VATId: oneVAT.VATId,
        };
      }) ?? []),
      ...(data?.suppliers?.map((oneVAT) => {
        return {
          typeLabel: 'Proveedor',
          name: oneVAT.name,
          relatedToId: oneVAT.supplierId,
          type: 'S',
          VATId: oneVAT.VATId,
        };
      }) ?? []),
      ...(data?.employees?.map((oneVAT) => {
        return {
          typeLabel: 'Empleado',
          name: oneVAT.firstName + ' ' + oneVAT.lastName1 + ' ' + oneVAT.lastName2,
          relatedToId: oneVAT.employeeId,
          type: 'E',
          VATId: oneVAT.VATId,
        };
      }) ?? []),
    ];
  },
);
