import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ICellEditorParams } from '@ag-grid-community/core';
import { useColorMode } from '@chakra-ui/react';

import { colors, CustomNumberInput, KEY_DOWN, KEY_ENTER, KEY_TAB, KEY_UP } from '@laudus/shared-ui';

interface INumberEditorComponentParams extends ICellEditorParams {
  decimals?: number;
  max?: number;
  min?: number;
  name: string;
}

const NumberEditorComponent: React.ForwardRefRenderFunction<any, INumberEditorComponentParams> = (
  props,
  ref,
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<number>(props.value);

  const { colorMode } = useColorMode();

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return isNaN(Number(value)) ? props.value : value;
      },
    };
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(Number(event.target.value));
  };

  const handleKeyDown = (event: any) => {
    // Any of these keys represent the users intent to movement away from the current cell and
    // therefore we must commit the value so that it's persisted to state
    if (
      event.key === KEY_ENTER ||
      event.key === KEY_TAB ||
      event.key === KEY_UP ||
      event.key === KEY_DOWN
    ) {
      event.preventDefault();
      setValue(value);
      props.context.handleKeyPress(event);
      return;
    }
  };

  useEffect(() => {
    inputRef?.current?.focus();

    inputRef?.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      inputRef?.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [value]);

  return (
    <CustomNumberInput
      max={props.max}
      min={props.min}
      name={props.name}
      onChange={handleChange}
      precision={props.decimals}
      ref={inputRef}
      // Prevent arrow keys increments and decrements in the first / last grid row.
      step={0}
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        textAlign: 'right',
        padding: '0 1rem',
        color: colorMode === 'dark' ? colors.white : colors.black,
        background: colorMode === 'dark' ? colors.darkGrey500 : colors.white,
      }}
      value={value}
    />
  );
};

NumberEditorComponent.displayName = 'NumberEditor';

export const NumberEditor = forwardRef(NumberEditorComponent);
