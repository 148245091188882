import { createReducer } from '@reduxjs/toolkit';

import { IStage } from '@laudus/types';

import {
  clearStage,
  clearStageDraft,
  duplicateStage,
  removeStageFromList,
  setStage,
  setStageDraft,
  setStageDraftValues,
  setStagesList,
  updateStageList,
} from './actions';

export const STAGES_TAB_ID = 'stages';

export interface IStagesState {
  current: IStage;
  draft: IStage;
  list: IStage[];
}

export const STAGE_EMPTY: IStage = {
  productionStageId: 0,
  name: '',
  description: '',
  order: 0,
  recipe: {
    recipeId: 0,
    name: '',
  },
};

export const initialStagesState: IStagesState = {
  current: STAGE_EMPTY,
  draft: STAGE_EMPTY,
  list: [],
};

export const stagesReducer = createReducer(initialStagesState, (builder) => {
  builder
    .addCase(clearStage, (state) => {
      return { ...state, current: STAGE_EMPTY };
    })
    .addCase(clearStageDraft, (state) => {
      return { ...state, draft: STAGE_EMPTY };
    })
    .addCase(setStage, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setStageDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setStageDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setStagesList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateStageList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((d) => d.productionStageId !== action.payload.productionStageId),
          action.payload,
        ],
      };
    })
    .addCase(removeStageFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.productionStageId !== action.payload),
      };
    })
    .addCase(duplicateStage, (state, action) => {
      const { productionStageId, ...duplicatedStages } = action.payload;
      return { ...state, draft: duplicatedStages };
    })
    .addDefaultCase((state) => state);
});
