import { Flex, Text } from '@chakra-ui/react';

interface IPropsLogintErrorMessage {
  message: string;
}

export const LoginErrorMessage = ({ message }: IPropsLogintErrorMessage) => {
  return (
    <Flex
      bg={message ? 'danger300' : 'transparent'}
      borderRadius="0.4rem"
      justifyContent="center"
      padding="0.8rem 4rem"
    >
      <Text color="danger500" fontSize="1.4rem">
        {message ? message : ''}
      </Text>
    </Flex>
  );
};
