import { createSelector } from '@reduxjs/toolkit';

import { transformsAndCalculatePurchaseInvoices } from '@laudus/shared-utils';

import { AppState } from '../../store';
import { getMainCurrency } from '../currencies';

import { IPurchaseInvoicesState } from './reducer';

export const getPurchaseInvoicesSlice = (state: AppState): IPurchaseInvoicesState =>
  state.purchaseInvoices;

export const getPurchaseInvoice = createSelector(
  [getPurchaseInvoicesSlice, (appState) => getMainCurrency(appState)],
  (state, { mainCurrencyDecimals }) => {
    // This prepare the purchaseInvoice document to be sent to the backend once the draft has been completed
    const transformedItems = transformsAndCalculatePurchaseInvoices(
      state.current.items,
      mainCurrencyDecimals,
    );

    return {
      ...state.current,
      items: transformedItems,
    };
  },
);

export const getPurchaseInvoiceDraft = createSelector(
  [getPurchaseInvoicesSlice, (appState) => getMainCurrency(appState)],
  (state, { mainCurrencyDecimals }) => {
    // This prepare the purchaseInvoice document to be sent to the backend once the draft has been completed
    const transformedItems = transformsAndCalculatePurchaseInvoices(
      state.draft.items,
      mainCurrencyDecimals,
    );

    return {
      ...state.draft,
      items: transformedItems,
    };
  },
);

export const getPurchaseInvoicesList = createSelector(
  [getPurchaseInvoicesSlice],
  (state) => state.list,
);

export const getPurchaseInvoiceWithoutCalculations = createSelector(
  [getPurchaseInvoicesSlice],
  (state) => state.current,
);

export const getPurchaseInvoiceDraftWithoutCalculations = createSelector(
  [getPurchaseInvoicesSlice],
  (state) => state.draft,
);

export const getPurchaseInvoicesDraftItems = createSelector(
  [getPurchaseInvoiceDraft],
  (state) => state.items,
);

export const getPurchaseInvoicesItems = createSelector(
  [getPurchaseInvoice],
  (state) => state.items,
);

export const getProductsInPurchaseInvoiceDraft = createSelector(
  [getPurchaseInvoiceDraft],
  (purchaseInvoice) => purchaseInvoice.items.map((item) => item.product),
);

export const getIsProductAddedToPurchaseInvoiceDraft = (productId: number) =>
  createSelector([getProductsInPurchaseInvoiceDraft], (products) =>
    products.some((product) => product?.productId === productId),
  );
