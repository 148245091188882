import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IBank, IFetchWithEtagParams } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

// Banks Tab action creators
export const addHomeBankTab = () =>
  addTab({
    tab: {
      id: 'bank',
      title: intl.formatMessage({ id: 'banks.tabTitle' }),
      path: 'pages/Bank/Banks',
      isRemovable: true,
    },
  });

export const addViewBankTab = (id?: string) =>
  addTab({
    tab: {
      id: 'bank',
      title: intl.formatMessage({ id: 'banks.tabTitle' }),
      path: 'pages/Bank/BankView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewBankTab = () =>
  addTab({
    tab: {
      id: 'bank',
      title: intl.formatMessage({ id: 'banks.tabTitle' }),
      path: 'pages/Bank/BankNew',
      isRemovable: true,
    },
  });

export const addEditBankTab = () =>
  addTab({
    tab: {
      id: 'bank',
      title: intl.formatMessage({ id: 'banks.tabTitle' }),
      path: 'pages/Bank/BankEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearBank = createAction('BANKS/CLEAR');

export const clearBankDraft = createAction('BANKS/CLEAR_DRAFT');

export const setBank = createAction<IBank>('BANKS/SET_BANK');

export const setBankDraft = createAction<IBank>('BANKS/SET_BANK_DRAFT');

export const setBankDraftValues = createAction<Partial<IBank>>('BANKS/SET_BANK_DRAFT_VALUE');

export const setBanksList = createAction<IBank[]>('BANKS/SET_LIST');

export const updateBanksList = createAction<IBank>('BANKS/UPDATE_LIST');

export const removeBankFromList = createAction<string>('BANKS/REMOVE_FROM_LIST');

export const duplicateBank = createAction<IBank>('BANKS/DUPLICATE');

export const fetchBanksList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'BANKS/FETCH_BANKS_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(startRequest('banks'));
      }

      const { data } = await api.banks.fetchBanksListAPI();
      dispatch(setBanksList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'banks',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('banks'));
      }
    }
  },
);

export const fetchBank = createAsyncThunk<void, string, AppThunkConfig>(
  'BANKS/FETCH_BANK',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('banks'));
    try {
      const { data } = await api.banks.fetchBankAPI(id);
      dispatch(setBank(data));

      // return data;
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'banks',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('banks'));
    }
  },
);
