import { createAction } from '@reduxjs/toolkit';

import { ILedgerFilterTags, ILedgerFormattedFilters } from '@laudus/types';

export const clearLedgerFilter = createAction('LEDGERS_FILTERS/CLEAR_LEDGER_FILTER');
export const setLedgerFilter = createAction<ILedgerFormattedFilters>(
  'LEDGERS_FILTERS/SET_LEDGER_FILTER',
);
export const setLedgerFilterValues = createAction<Partial<ILedgerFormattedFilters>>(
  'LEDGERS_FILTERS/SET_LEDGER_FILTER_VALUE',
);
export const setLedgerTagsFilter = createAction<ILedgerFilterTags[]>(
  'LEDGERS_FILTERS/SET_LEDGER_TAGS_FILTER',
);
