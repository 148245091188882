import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const Input: ComponentMultiStyleConfig = {
  parts: ['field'],
  variants: {
    'base': {
      field: {
        bg: 'inputBackground',
        borderWidth: '0.1rem 0.1rem 0.1rem 0.1rem',
        borderColor: 'inputBorder',
        borderRadius: '0.4rem',
        color: 'textPrimary',
        fontSize: '1.5rem',
        display: 'flex',
        height: '3.5rem',
        _disabled: {
          opacity: '1',
          bg: 'inputBackground',
          color: 'loadingText',
          borderWidth: '0.1rem 0.1rem 0.1rem 0.1rem',
          borderColor: 'inputBorder',
        },
        _focus: {
          'borderColor': 'inputBorderActive',
          'borderWidth': '0.2rem',
          '+.chakra-select__icon-wrapper': {
            color: 'error.100',
          },
        },
        _invalid: {
          'bg': 'none',
          'borderColor': 'error.100',
          '+.chakra-select__icon-wrapper': {
            color: 'error.100',
          },
          '_disabled': {
            'bg': 'neutral500',
            'color': 'neutral500',
            'borderColor': 'danger500', // TODO check the color
            '+.chakra-select__icon-wrapper': {
              color: 'danger500', // TODO check the color
            },
          },
        },
      },
    },
    'input-error': {
      field: {
        bg: 'white',
        borderWidth: '1px 1px 1px 1px',
        borderColor: 'danger500',
        borderRadius: '0.4rem',
        color: 'black',
        fontSize: '1.5rem',
        display: 'flex',
        height: '35px',
      },
    },
    'grid-input': {
      field: {
        borderRadius: '0px',
        _focus: {
          'borderColor': 'primary.ultraLight',
          'borderWidth': '0px',
          '+.chakra-select__icon-wrapper': {
            color: 'danger500',
          },
        },
      },
    },
    'dashboard-input': {
      field: {
        border: '0px solid transparent',
        borderBottom: '1.5px solid',
        borderColor: 'neutral400',
        borderRadius: '2px',
        bg: 'transparent',
        color: 'black',
        fontSize: '1.5rem',
        paddingTop: '6px',
        paddingBottom: '6px',
        height: '3rem',
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'base',
  },
};
