import { AxiosResponse } from 'axios';

import { IHoliday } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchHolidaysListFromAPI = (): Promise<AxiosResponse<IHoliday[]>> =>
  axios.post(
    `${VITE_API_URL}/HR/holidays/list`,
    {
      fields: ['holidayId', 'date', 'repeating', 'description'],
      orderBy: [{ field: 'date', direction: 'DESC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchHolidayFromAPI = (holidayId: number): Promise<AxiosResponse<IHoliday>> =>
  axios.get(`${VITE_API_URL}/HR/holidays/${holidayId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createHolidayFromAPI = (holiday: IHoliday): Promise<AxiosResponse<IHoliday>> =>
  axios.post(`${VITE_API_URL}/HR/holidays`, holiday);

export const updateHolidayFromAPI = (holiday: IHoliday): Promise<AxiosResponse<IHoliday>> =>
  axios.put(`${VITE_API_URL}/HR/holidays/${holiday.holidayId}`, holiday);

export const deleteHolidayFromAPI = (holidayId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/HR/holidays/${holidayId}`);
