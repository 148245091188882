export const DTEDocumentStatusList = [
  {
    label: 'Aceptado OK',
    value: '0',
  },
  {
    label: 'Aceptado con REPAROS',
    value: '1',
  },
  {
    label: 'RECHAZADO',
    value: '2',
  },
];
