import { createSelector } from '@reduxjs/toolkit';

import { AppState, ILedgerFilterState } from '../..';

export const getLedgersFiltersSlice = (state: AppState): ILedgerFilterState => state.ledgerFilter;

export const getLedgerFilter = createSelector(
  [getLedgersFiltersSlice],
  (state) => state.ledgerFilter,
);

export const getLedgerTagsFilter = createSelector(
  [getLedgersFiltersSlice],
  (state) => state.ledgerTagsFilter,
);
