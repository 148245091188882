import { createReducer } from '@reduxjs/toolkit';

import { ErrorSerializer } from '@laudus/shared-utils';
import { IReportConfig, IReportFiltersItems, IReportResult } from '@laudus/types';

import {
  clearReportFilters,
  resetReportResult,
  setReportConfig,
  setReportError,
  setReportFilters,
  setReportFiltersValues,
  setReportResult,
} from './actions';
import { REPORTS_FILTERS_EMPTY } from './constants';

export interface IReportsState {
  result: IReportResult | null;
  config: IReportConfig | null;
  filters: IReportFiltersItems;
  error?: string;
}

export const initialReportsState: IReportsState = {
  result: null,
  config: null,
  filters: REPORTS_FILTERS_EMPTY,
  error: undefined,
};

export const reportsReducer = createReducer(initialReportsState, (builder) => {
  builder
    .addCase(clearReportFilters, (state) => {
      return { ...state, filters: REPORTS_FILTERS_EMPTY };
    })
    .addCase(setReportFilters, (state, action) => {
      return { ...state, filters: action.payload };
    })
    .addCase(setReportFiltersValues, (state, action) => {
      return { ...state, filters: { ...state.filters, ...action.payload } };
    })
    .addCase(setReportConfig, (state, action) => {
      return { ...state, config: action.payload };
    })
    .addCase(setReportResult, (state, action) => {
      return { ...state, result: action.payload, error: undefined };
    })
    .addCase(resetReportResult, (state) => {
      return {
        ...state,
        result: null,
      };
    })
    .addCase(setReportError, (state, action) => {
      return { ...state, error: ErrorSerializer.toString(action.payload) };
    })
    .addDefaultCase((state) => state);
});
