import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IEmployeeLoan } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Simple actions

export const setEmployeeLoan = createAction<IEmployeeLoan>('EMPLOYEESLOANS/SET_EMPLOYEE_LOAN');
export const setEmployeeLoanDraft = createAction<IEmployeeLoan>('EMPLOYEESLOANS/SET_DRAFT');

export const setEmployeeLoanDraftValues = createAction<Partial<IEmployeeLoan>>(
  'EMPLOYEESLOANS/SET_EMPLOYEE_LOAN_DRAFT_VALUE',
);

export const setEmployeeLoansList = createAction<IEmployeeLoan[]>('EMPLOYEESLOANS/SET_LIST');

export const updateEmployeeLoansList = createAction<IEmployeeLoan>('EMPLOYEESLOANS/UPDATE_LIST');

export const removeEmployeeLoanFromList = createAction<number>('EMPLOYEESLOANS/REMOVE_FROM_LIST');

// Complex actions

export interface IFetchEmployeeLoanParams {
  employeeId: number;
  loanId: number;
}
export const fetchEmployeeLoan = createAsyncThunk<void, IFetchEmployeeLoanParams, AppThunkConfig>(
  'EMPLOYEESLOANS/FETCH',
  async ({ employeeId, loanId }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('employee-loans'));
    try {
      const { data } = await api.employeesLoans.fetchEmployeeLoanFromAPI(employeeId, loanId);
      dispatch(setEmployeeLoan(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeesLoans',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('employee-loans'));
    }
  },
);

export const fetchEmployeeLoansList = createAsyncThunk<void, number, AppThunkConfig>(
  'EMPLOYEESLOANS/FETCH_EMPLOYEES_LOANS_LIST',
  async (employeeId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('employee-loans'));
    try {
      const { data } = await api.employeesLoans.fetchEmployeesLoansListFromAPI(employeeId);
      dispatch(setEmployeeLoansList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeesLoans',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('employee-loans'));
    }
  },
);

export interface ICreateEmployeeLoanParams {
  employeeId: number;
  loan: IEmployeeLoan;
}
export const createEmployeeLoan = createAsyncThunk<void, ICreateEmployeeLoanParams, AppThunkConfig>(
  'EMPLOYEESLOANS/CREATE_EMPLOYEE_LOAN',
  async ({ employeeId, loan }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('employee-loans'));
    try {
      const idToZeroLoan = { ...loan, employeeLoanId: 0 };
      const { data } = await api.employeesLoans.createEmployeeLoanFromAPI(employeeId, idToZeroLoan);
      dispatch(updateEmployeeLoansList(data));
      dispatch(
        showAlert({
          title: intl.formatMessage({
            id: 'employeesLoans.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeesLoans',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('employee-loans'));
    }
  },
);

export interface IUpdateEmployeeLoanParams {
  employeeId: number;
  loan: IEmployeeLoan;
}

export const updateEmployeeLoan = createAsyncThunk<void, IUpdateEmployeeLoanParams, AppThunkConfig>(
  'EMPLOYEESLOANS/UPDATE_EMPLOYEE_LOAN',
  async ({ employeeId, loan }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('employee-loans'));
    try {
      const { data } = await api.employeesLoans.updateEmployeeLoanFromAPI(employeeId, loan);
      dispatch(setEmployeeLoan(data));
      dispatch(updateEmployeeLoansList(data));
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'employeesLoans.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeesLoans',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('employee-loans'));
    }
  },
);
export interface IDeleteEmployeeLoanParams {
  employeeId: number;
  loanId: number;
}

export const deleteEmployeeLoan = createAsyncThunk<void, IDeleteEmployeeLoanParams, AppThunkConfig>(
  'EMPLOYEESLOANS/DELETE_EMPLOYEE_LOAN',
  async ({ employeeId, loanId }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('employee-loans'));
    try {
      await api.employeesLoans.deleteEmployeeLoanFromAPI(employeeId, loanId);
      dispatch(removeEmployeeLoanFromList(loanId));
      dispatch(
        showAlert({
          title: intl.formatMessage({
            id: 'employeesLoans.successToast.delete',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeesLoans',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('employee-loans'));
    }
  },
);
