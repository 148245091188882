import { createSelector } from '@reduxjs/toolkit';

import {
  IPriceList,
  IPriceListItem,
  IPriceListProductPriceOverride,
  IPriceListProducts,
  IProductListItem,
} from '@laudus/types';

import { AppState } from '../../store';

import { IProductsState } from './reducer';
import { ProductCanBeSoldOrCanBePurchased } from './selectors';

export const getProductsSlice = (state: AppState): IProductsState => state.products;

export const getProductsList = createSelector([getProductsSlice], (state) => state.list);

/**
 * getMergedPriceMap
 * Merges multiple prices lists with order of precedence to the right, meaning where
 * multiple item exists in the sequence the latter item will override the former.
 *
 * Example,
 * If you pass two prices lists branch and customer in that order, and both price
 * lists override the sames items.  Then customer price item will take precedence.
 *
 * @param priceLists IPriceList[]
 * @returns Map<number, IPriceListItem>
 */
export function getMergedPriceMap(
  ...priceLists: (IPriceList | undefined)[]
): Map<number, IPriceListItem> {
  return priceLists
    .reduce((acc, list) => {
      // merge price lists items in order of precedence
      if (list?.items?.length) {
        acc.push(...list.items);
      }
      return acc;
    }, [] as IPriceListItem[])
    .reduce((map, item) => {
      // deduplicate items into a map overridding values in order of precedence
      if (item.product?.productId != null) {
        map.set(item.product.productId, item);
      }
      return map;
    }, new Map());
}

/**
 * getMergedProductsWithPriceOverride
 * Merges multiple prices lists of product with order of precedence to the right, meaning where
 * multiple item exists in the sequence the latter item will override the former.
 *
 * Example,
 * If you pass two entiry prices lists of branch and customer in that order, and both price
 * lists override the sames items.  Then customer price item will take precedence.
 *
 * @param productsOverride IPriceListProducts[]
 * @returns Map<number, IPriceListProductPriceOverride>
 */
export function getMergedProductsWithPriceOverride(
  ...productsOverride: (IPriceListProducts | undefined)[]
): Map<number, IPriceListProductPriceOverride> {
  return productsOverride
    .reduce((acc, list) => {
      // merge price lists items in order of precedence
      if (list?.products?.length) {
        acc.push(...list.products);
      }
      return acc;
    }, [] as IPriceListProductPriceOverride[])
    .reduce((map, item) => {
      // deduplicate items into a map overridding values in order of precedence
      if (item.productId != null) {
        map.set(item.productId, item);
      }
      return map;
    }, new Map());
}

/**
 * doesProductMatchDiscontinuedFilter
 *
 * Returns true of false, whether or not to filter discountinued products
 *
 * @param product IProductListItem
 * @param showDiscontinued boolean
 * @returns boolean
 */
export function doesProductMatchDiscontinuedFilter({
  product,
  showDiscontinued,
}: {
  product: IProductListItem;
  showDiscontinued: boolean;
}): boolean {
  if (showDiscontinued) {
    // Show both discontinued and non-discontinued products
    return true;
  }

  // Show only non-discontinued products
  return product.discontinued === false;
}

/**
 * doesProductMatchCanBeSoldOrPurchasedFilter
 *
 * Returns true or false, can a product be sold or purchased.
 *
 * @param product IProductListItem
 * @param showCanBeSoldOrCanBePurchased boolean
 * @returns boolean
 */
export function doesProductMatchCanBeSoldOrPurchasedFilter({
  product,
  showCanBeSoldOrCanBePurchased,
}: {
  product: IProductListItem;
  showCanBeSoldOrCanBePurchased: ProductCanBeSoldOrCanBePurchased;
}): boolean {
  switch (showCanBeSoldOrCanBePurchased) {
    case 'canBeSold':
      return Boolean(product.canBeSold);
    case 'canBePurchased':
      return Boolean(product.canBePurchased);
    case 'both':
    default:
      return true;
  }
}
