import { IntRange, RepeatString } from '../utilities';

import { IAccountInfo } from './accounts';
import { IBranchInfo } from './branches';
import { ICostCenterInfo } from './costCenters';
import { IContactInfo } from './customerContacts';
import { ICustomerInfo } from './customers';
import { ICustomFields } from './customFields';
import { IDealerInfo } from './dealers';
import { ISalesmanInfo } from './salesmen';
import { ITermInfo } from './terms';
import { IUserInfo } from './users';
import { IWarehouseInfo } from './warehouses';

export interface IRecurringSalesInvoiceListItem {
  recurringSalesInvoiceId: number;
  description: string;
  customer_name: string;
}

export interface IRecurringSalesInvoice {
  recurringSalesInvoiceId?: number;
  description: string;
  category: IRecurringSalesInvoiceCategory | null;
  customer: ICustomerInfo | null;
  branch: IBranchInfo | null;
  warehouse: IWarehouseInfo | null;
  salesman: ISalesmanInfo | null;
  dealer: IDealerInfo | null;
  term: ITermInfo | null;
  contact: IContactInfo | null;
  discontinued: boolean;
  repetition: IRecurringSalesInvoiceRepetition;
  notes: string | null;
  createdBy?: IUserInfo | null;
  createdAt?: string | null;
  modifiedBy?: IUserInfo | null;
  modifiedAt?: string | null;
  customFields?: ICustomFields;
  items: IRecurringSalesInvoicesItem[];

  // Temporary fields, they are only used to hold the value that all the items will contain.
  // These should be removed from the root before sending the API request
  costCenter?: ICostCenterInfo | null;
  account?: IAccountInfo | null;
}

export interface IRecurringSalesInvoicesItem {
  itemId?: number;
  product?: IRecurringSalesInvoicesItemProduct;
  itemDescription: string | null;
  quantity: number;
  originalUnitPrice: number;
  currencyCode: string | null;
  discountPercentage: number;
  costCenter: ICostCenterInfo | null;
  account: IAccountInfo | null;
  customFields?: ICustomFields;
}

interface IRecurringSalesInvoicesItemProduct {
  productId?: number;
  sku?: string;
  description?: string;
  unitOfMeasure?: string;
  allowFreeDescription?: boolean;
  allowUserChangePrices?: boolean;
  applyGeneralVATRate?: boolean;
  maxDiscount?: number;
}

export interface IRecurringSalesInvoiceCategory {
  code: string;
  description: string;
}

export enum IRecurringSalesInvoiceRepetitionPatternType {
  WEEKLY = 'W',
  MONTHLY = 'M',
  YEARLY = 'Y',
}

export enum IRecurringSalesInvoiceRepetitionToType {
  ALWAYS = 'E',
  DATE = 'D',
  TIMES = 'T',
}

interface IRecurringSalesInvoiceRepetition {
  pattern: IRecurringSalesInvoiceRepetitionPattern;
  dateFrom: string | null;
  to: IRecurringSalesInvoiceRepetitionTo;
}

export type IRecurringSalesInvoiceRepetitionPattern =
  | {
      type: IRecurringSalesInvoiceRepetitionPatternType.WEEKLY;
      interval: number;
      weekDays: RepeatString<IRecurringSalesInvoiceDayString, 7>;
      monthDays?: null;
      yearMonth?: null;
      yearDayOfMonth?: null;
    }
  | {
      type: IRecurringSalesInvoiceRepetitionPatternType.MONTHLY;
      interval: number;
      // monthDays: RepeatString<IRecurringSalesInvoiceDayString, 31>; // Too expensive for TypeScript compiler
      monthDays: string;
      weekDays?: null;
      yearMonth?: null;
      yearDayOfMonth?: null;
    }
  | {
      type: IRecurringSalesInvoiceRepetitionPatternType.YEARLY;
      interval: number;
      yearMonth: IntRange<1, 12>;
      yearDayOfMonth: IntRange<1, 31>;
      weekDays?: null;
      monthDays?: null;
    };

type IRecurringSalesInvoiceRepetitionTo =
  | { type: IRecurringSalesInvoiceRepetitionToType.ALWAYS; dateTo?: null; times?: null }
  | { type: IRecurringSalesInvoiceRepetitionToType.DATE; dateTo: string; times?: null }
  | { type: IRecurringSalesInvoiceRepetitionToType.TIMES; dateTo?: null; times: number };

export type IRecurringSalesInvoiceDayString = '0' | '1';

export interface IRecurringSalesInvoicesItemTableRow extends IRecurringSalesInvoicesItem {
  total: number;
}
