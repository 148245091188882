import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, ISupplierContact } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Suppliers action creators
export const clearSupplierContact = createAction('SUPPLIER_CONTACS/CLEAR');

export const clearSupplierContactDraft = createAction('SUPPLIER_CONTACS/CLEAR_DRAFT');

export const setSupplierContact = createAction<ISupplierContact>('SUPPLIER_CONTACS/SET');

export const setSupplierContactDraft = createAction<ISupplierContact>('SUPPLIER_CONTACS/SET_DRAFT');

export const setSupplierContactDraftValues = createAction<Partial<ISupplierContact>>(
  'SUPPLIER_CONTACS/SET_DRAFT_VALUE',
);

export const setSupplierContactsList = createAction<Record<number, ISupplierContact[]>>(
  'SUPPLIER_CONTACS/SET_LIST',
);

export const updateSupplierContactList = createAction<Record<number, ISupplierContact[]>>(
  'SUPPLIER_CONTACS/UPDATE_LIST',
);

export const removeSupplierContactFromList = createAction<string | number>(
  'SUPPLIER_CONTACS/REMOVE_FROM_LIST',
);

interface IfetchSupplierContactsList extends IFetchWithEtagParams {
  supplierId: number;
}
export const fetchSupplierContactsList = createAsyncThunk<
  void,
  IfetchSupplierContactsList,
  AppThunkConfig
>('SUPPLIER_CONTACS/FETCH_LIST', async ({ supplierId, silent }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  if (!silent) {
    dispatch(startRequest('supplier-contracts'));
  }
  try {
    const { data } = await api.supplierContacts.getSupplierContactsListAPI(supplierId);
    dispatch(setSupplierContactsList({ [supplierId]: Array.isArray(data) ? data : [] }));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'supplierContacts',
        action: 'list',
      }),
    );
  } finally {
    if (!silent) {
      dispatch(endRequest('supplier-contracts'));
    }
  }
});

export interface IFetchSupplierContactsParams {
  supplierId: number;
  contactId: number;
}

export const fetchSupplierContact = createAsyncThunk<
  void,
  IFetchSupplierContactsParams,
  AppThunkConfig
>('SUPPLIER_CONTACS/FETCH', async ({ supplierId, contactId }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('supplier-contracts'));
  try {
    const { data } = await api.supplierContacts.getSupplierContactAPI(supplierId, contactId);
    dispatch(setSupplierContactDraft(data));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'supplierContacts',
        action: 'read',
      }),
    );
  } finally {
    dispatch(endRequest('supplier-contracts'));
  }
});

export interface IUpdateSupplierContactsParams {
  supplierId: number;
  supplierContact: ISupplierContact;
}

export const updateSupplierContact = createAsyncThunk<
  void,
  IUpdateSupplierContactsParams,
  AppThunkConfig
>('SUPPLIER_CONTACS/FETCH', async ({ supplierId, supplierContact }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('supplier-contracts'));
  try {
    const { data } = await api.supplierContacts.updateSupplierContactAPI(
      supplierId,
      supplierContact,
    );
    dispatch(setSupplierContact(data));
    //dispatch(updateSupplierContactList({ [supplierId]: [data] }));
    //TODO: make updateSupplierContactList works, now overwrites with one value and not keep others records
    //it must update only the item and keep all others
    //this behavior must be here but by the moment i refresh list calling API here better than in form
    dispatch(fetchSupplierContactsList({ supplierId }));
    dispatch(
      showToastAlert({
        title: intl.formatMessage({
          id: 'supplierContacts.successToast.save',
        }),
        message: '',
        type: 'success',
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'supplierContacts',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('supplier-contracts'));
  }
});

export interface ICreateSupplierContactParams {
  supplierId: number;
  supplierContact: ISupplierContact;
}

export const createSupplierContact = createAsyncThunk<
  void,
  ICreateSupplierContactParams,
  AppThunkConfig
>('SUPPLIER_CONTACS/FETCH', async ({ supplierId, supplierContact }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('supplier-contracts'));
  try {
    const { data } = await api.supplierContacts.createSupplierContactAPI(
      supplierId,
      supplierContact,
    );
    dispatch(setSupplierContact(data));
    //dispatch(updateSupplierContactList({ [supplierId]: [data] }));
    //TODO: make updateSupplierContactList works, now overwrites with one value and not keep others records
    //it must update only the item and keep all others items of a supplier
    //this behavior must be here but by the moment i refresh list calling API here better than in form
    dispatch(fetchSupplierContactsList({ supplierId }));
    dispatch(
      showToastAlert({
        title: intl.formatMessage({
          id: 'supplierContacts.successToast.save',
        }),
        message: '',
        type: 'success',
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'supplierContacts',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('supplier-contracts'));
  }
});

export interface IDeleteSupplierContactsParams {
  supplierId: number;
  contactId: number;
}

export const deleteSupplierContact = createAsyncThunk<
  void,
  IDeleteSupplierContactsParams,
  AppThunkConfig
>('SUPPLIER_CONTACS/FETCH', async ({ supplierId, contactId }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('supplier-contracts'));
  try {
    await api.supplierContacts.deleteSupplierContactAPI(supplierId, contactId);
    dispatch(removeSupplierContactFromList(contactId));
    dispatch(clearSupplierContact());
    dispatch(
      showToastAlert({
        title: intl.formatMessage({
          id: 'supplierContacts.successToast.delete',
        }),
        message: '',
        type: 'success',
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'supplierContacts',
        action: 'delete',
      }),
    );
  } finally {
    dispatch(endRequest('supplier-contracts'));
  }
});

interface ISaveSupplierContact {
  supplierContact: ISupplierContact;
  supplierId: number;
}
export const saveSupplierContact = createAsyncThunk<void, ISaveSupplierContact, AppThunkConfig>(
  'CUSTOMER_ADDRESSES/SAVE',
  async ({ supplierContact, supplierId }, ThunkAPI) => {
    const { dispatch } = ThunkAPI;

    // If the supplierId is 0, it means it is a new contact
    if (supplierContact.contactId === 0) {
      dispatch(
        createSupplierContact({
          supplierId,
          supplierContact,
        }),
      );
    } else {
      // If the supplierId is not 0, it's editing an existing contact
      dispatch(
        updateSupplierContact({
          supplierId,
          supplierContact,
        }),
      );
    }
    dispatch(clearSupplierContactDraft());
  },
);
