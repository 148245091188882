import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IAFP } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

export const addHomePrevisionalInstitutionsTab = () =>
  addTab({
    tab: {
      id: 'previsionalInstitutions',
      title: intl.formatMessage({ id: 'previsionalInstitutions.tabTitle' }),
      path: 'pages/PrevisionalInstitutions/PrevisionalInstitutions',
      isRemovable: true,
    },
  });

// Simple actions
export const clearAFP = createAction('AFP/CLEAR');

export const clearAFPDraft = createAction('AFP/CLEAR_DRAFT');

export const setAFP = createAction<IAFP>('AFP/SET_OUTPUT');

export const setAFPDraft = createAction<IAFP>('AFP/SET_DRAFT');

export const setAFPDraftValues = createAction<Partial<IAFP>>('AFP/SET_DRAFT_VALUE');

export const setAFPsList = createAction<IAFP[]>('AFP/SET_LIST');

export const updateAFPsList = createAction<IAFP>('AFP/UPDATE_LIST');

export const removeAFPFromList = createAction<string>('AFP/REMOVE_FROM_LIST');

export const duplicateAFP = createAction<IAFP>('AFP/DUPLICATE');

// Complex actions
export const fetchAFPsList = createAsyncThunk<void, void, AppThunkConfig>(
  'AFP/FETCH_AFP_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('afps'));
    try {
      const { data } = await api.afp.fetchAFPsListFromAPI();
      dispatch(setAFPsList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('afps'));
    }
  },
);

export const fetchAFP = createAsyncThunk<void, string, AppThunkConfig>(
  'AFP/FETCH_INPUT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('afps'));
    try {
      const { data } = await api.afp.fetchAFPFromAPI(id);
      dispatch(setAFP(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('afps'));
    }
  },
);

export const createAFP = createAsyncThunk<void, IAFP, AppThunkConfig>(
  'AFP/CREATE',
  async (afp, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('afps'));
    try {
      const { data } = await api.afp.createAFPFromAPI(afp);
      dispatch(setAFP(data));
      dispatch(fetchAFPsList());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('afps'));
    }
  },
);

export const updateAFP = createAsyncThunk<void, IAFP, AppThunkConfig>(
  'AFP/FETCH',
  async (afp, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('afps'));
    try {
      const { data } = await api.afp.updateAFPFromAPI(afp);
      dispatch(setAFP(data));
      dispatch(fetchAFPsList());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('afps'));
    }
  },
);

export const deleteAFP = createAsyncThunk<void, string, AppThunkConfig>(
  'AFP/DELETE_AFP',
  async (AFPId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('afps'));
    try {
      await api.afp.deleteAFPFromAPI(AFPId);
      dispatch(removeAFPFromList(AFPId));
      dispatch(clearAFP());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.delete',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('afps'));
    }
  },
);

export const saveAFP = createAsyncThunk<void, IAFP, AppThunkConfig>(
  'AFP/SAVE',
  async (afp, ThunkAPI) => {
    const { dispatch } = ThunkAPI;

    // if the AFP has an id, it means it already exists and we should update it
    if (afp.AFPId) {
      dispatch(updateAFP(afp));
    } else {
      // if the AFP does not have an id, it means it is a new AFP and we should create it
      dispatch(createAFP(afp));
    }
    dispatch(clearAFPDraft());
  },
);
