import { IInitialCompanyParameters } from '@laudus/types';

/**
 * NOTE: This constant has been defined here instead of in the reducer as it was
 * originally defined to prevent circular dependencies as it is also used in selectors.ts
 */
export const COMPANY_PARAMETERS_EMPTY: IInitialCompanyParameters = {
  SIS: 0,
  UF: 0,
  UTM: 0,
  UFmesAnterior: 0,
  salarioMinimo: 0,
  topeImponibleAFP: 0,
  topeImponibleAFC: 0,
  topeImponibleIPS: 0,
};
