import { createReducer } from '@reduxjs/toolkit';

import { ICustomerAddress } from '@laudus/types';

import {
  clearCustomerAddress,
  clearCustomerAddressDraft,
  removeCustomerAddressFromList,
  setCustomerAddress,
  setCustomerAddressDraft,
  setCustomerAddressDraftValues,
  setCustomerAddressesList,
  updateCustomerAddressList,
} from './actions';

export interface ICustomerAddressesState {
  current: ICustomerAddress;
  draft: ICustomerAddress;
  list: Record<number, ICustomerAddress[]>;
}

export const ADDRESS_EMPTY: ICustomerAddress = {
  customerId: 0,
  addressId: 0,
  description: '',
  address: '',
  county: '',
  zipCode: '',
  city: '',
  state: '',
  country: '',
  notes: '',
};

export const initialCustomerAddressesState: ICustomerAddressesState = {
  current: ADDRESS_EMPTY,
  draft: ADDRESS_EMPTY,
  list: {},
};

export const customerAddressesReducer = createReducer(initialCustomerAddressesState, (builder) => {
  builder
    .addCase(clearCustomerAddress, (state) => {
      return { ...state, current: ADDRESS_EMPTY };
    })
    .addCase(clearCustomerAddressDraft, (state) => {
      return { ...state, draft: ADDRESS_EMPTY };
    })
    .addCase(setCustomerAddress, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setCustomerAddressDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setCustomerAddressDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setCustomerAddressesList, (state, action) => {
      return { ...state, list: { ...state.list, ...action.payload } };
    })
    .addCase(updateCustomerAddressList, (state, action) => {
      return {
        ...state,
        list: { ...state.list, ...action.payload },
      };
    })
    .addCase(removeCustomerAddressFromList, (state, action) => {
      return {
        ...state,
        list: Object.assign(
          {},
          ...Object.keys(state.list).map((customerId: number | string) => {
            return {
              [customerId as number]: state.list[customerId as number].filter(
                (address) => address.addressId !== action.payload,
              ),
            };
          }),
        ),
      };
    })
    .addDefaultCase((state) => state);
});
