import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IBranch, IFetchWithEtagParams, IPriceListProducts } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const addHomeBranchTab = () =>
  addTab({
    tab: {
      id: 'branches',
      title: intl.formatMessage({ id: 'branches.tabTitle' }),
      path: 'pages/Branch/Branches',
      isRemovable: true,
    },
  });

export const addViewBranchTab = (id?: number) =>
  addTab({
    tab: {
      id: 'branches',
      title: intl.formatMessage({ id: 'branches.tabTitle' }),
      path: 'pages/Branch/BranchView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewBranchTab = () =>
  addTab({
    tab: {
      id: 'branches',
      title: intl.formatMessage({ id: 'branches.tabTitle' }),
      path: 'pages/Branch/BranchNew',
      isRemovable: true,
    },
  });

export const addEditBranchTab = () =>
  addTab({
    tab: {
      id: 'branches',
      title: intl.formatMessage({ id: 'branches.tabTitle' }),
      path: 'pages/Branch/BranchEdit',
      isRemovable: true,
    },
  });

// Branches action creators
export const setBranchesLoading = createAction<boolean>('BRANCHES/SET_LOADING');

export const clearBranch = createAction('BRANCHES/CLEAR');
export const clearBranchDraft = createAction('BRANCHES/CLEAR_DRAFT');

export const setBranch = createAction<IBranch>('BRANCHES/SET');

export const setBranchProductsWithPricesOverride = createAction<{
  branch: IBranch;
  productsWithPricesOverride: IPriceListProducts | undefined;
}>('CUSTOMERS/SET_BRANCH_PRICE_LIST');

export const setBranchDraft = createAction<IBranch>('BRANCHES/SET_BRANCHES_DRAFT');
export const setBranchesList = createAction<IBranch[]>('BRANCHES/SET_LIST');
export const setBranchDraftValues = createAction<Partial<IBranch>>(
  'BRANCHES/SET_BRANCH_DRAFT_VALUE',
);
export const updateBranchesList = createAction<IBranch>('BRANCHES/UPDATE_LIST');
export const removeBranchFromList = createAction<number>('BRANCHES/REMOVE_FROM_LIST');
export const duplicateBranch = createAction<IBranch>('BRANCHES/DUPLICATE');
export interface IRemoveBranchFromListParams {
  branchId: number;
}

export const fetchBranchesList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'BRANCHES/FETCH_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(setBranchesLoading(true));
        dispatch(startRequest('branches'));
      }

      const { data } = await api.branches.fetchBranchesListAPI();
      dispatch(setBranchesList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'branches',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(setBranchesLoading(false));
        dispatch(endRequest('branches'));
      }
    }
  },
);

export const fetchBranch = createAsyncThunk<void, number, AppThunkConfig>(
  'BRANCHES/FETCH',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(setBranchesLoading(true));
    dispatch(startRequest('branches'));
    try {
      const { data } = await api.branches.fetchBranchAPI(id);
      dispatch(setBranch(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'branches',
          action: 'read',
        }),
      );
    } finally {
      dispatch(setBranchesLoading(false));
      dispatch(endRequest('branches'));
    }
  },
);

export const fetchBranchWithPriceList = createAsyncThunk<void, number, AppThunkConfig>(
  'BRANCHES/FETCH_WITH_PRICE_LIST',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(setBranchesLoading(true));
    dispatch(startRequest('branches'));
    try {
      const { data: branch } = await api.branches.fetchBranchAPI(id);

      let productsWithPricesOverride;
      if (branch?.priceList?.priceListId) {
        const result = await api.priceLists.fetchProductsOfPriceListFromAPI(
          branch.priceList.priceListId,
        );
        productsWithPricesOverride = result.data;
      }
      dispatch(setBranchProductsWithPricesOverride({ branch, productsWithPricesOverride }));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'branches',
          action: 'read',
        }),
      );
    } finally {
      dispatch(setBranchesLoading(false));
      dispatch(endRequest('branches'));
    }
  },
);
