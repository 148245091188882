import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const NumberInput: ComponentMultiStyleConfig = {
  parts: ['field', 'icon', 'stepper', 'control'],
  variants: {
    'base': {
      field: {
        bg: 'white',
        borderWidth: '1px 1px 1px 1px',
        borderColor: 'neutral500',
        borderRadius: '0.4rem',
        color: 'black',
        fontSize: '1.5rem',
        display: 'flex',
        height: '35px',
        _disabled: {
          bg: 'white',
          color: 'neutral500',
          borderWidth: '1px 1px 1px 1px',
          borderColor: 'neutral500',
        },
        _focus: {
          'borderWidth': '2px',
          'borderColor': 'primary500',
          'caretColor': 'primary500',
          '+.chakra-select__icon-wrapper': {
            color: 'error.100',
          },
        },
        _invalid: {
          'bg': 'none',
          'borderColor': 'error.100',
          '+.chakra-select__icon-wrapper': {
            color: 'error.100',
          },
          '_disabled': {
            'bg': 'neutral.10',
            'borderColor': 'error.100',
            '+.chakra-select__icon-wrapper': {
              color: 'error.100',
            },
          },
        },
      },
      stepper: {
        height: '1.6rem',
        bg: 'neutral50',
        borderRadius: '0.4rem',
        borderTopRightRadius: '0.4rem !important',
        borderBottomRightRadius: '0.4rem !important',
        borderColor: 'transparent',
        marginBottom: '3px',
        _hover: {
          bg: 'transparentGrey48',
        },
      },
    },
    'disabled': {
      field: {
        bg: 'white',
        borderWidth: '1px 1px 1px 1px',
        borderColor: 'neutral300',
        borderRadius: '0.4rem',
        color: 'black',
        fontSize: '15px',
        display: 'flex',
        height: '35px',
        _disabled: {
          opacity: '1',
        },
        _focus: {
          'borderColor': 'primary500',
          '+.chakra-select__icon-wrapper': {
            color: 'error.100',
          },
        },
        _invalid: {
          'bg': 'none',
          'borderColor': 'error.100',
          '+.chakra-select__icon-wrapper': {
            color: 'error.100',
          },
          '_disabled': {
            'bg': 'neutral.10',
            'color': 'neutral500',
            'borderColor': 'error.100',
            '+.chakra-select__icon-wrapper': {
              color: 'error.100',
            },
          },
        },
      },
      stepper: {
        height: '1.6rem',
        bg: 'neutral50',
        borderRadius: '0.4rem',
        borderTopRightRadius: '0.4rem !important',
        borderBottomRightRadius: '0.4rem !important',
        borderColor: 'transparent',
        marginBottom: '3px',
      },
    },
    'grid-input': {
      field: {
        bg: 'white',
        borderWidth: '0px',
        borderColor: 'neutral500',
        borderRadius: '0px',
        color: 'black',
        fontSize: '1.5rem',
        display: 'flex',
        height: '35px',
        _disabled: {
          bg: 'white',
          color: 'neutral500',
          borderWidth: '1px 1px 1px 1px',
          borderColor: 'neutral500',
        },
        _focus: {
          'borderColor': 'primary500',
          'borderWidth': '0px',
          '+.chakra-select__icon-wrapper': {
            color: 'error.100',
          },
        },
        _invalid: {
          'bg': 'none',
          'borderColor': 'error.100',
          '+.chakra-select__icon-wrapper': {
            color: 'error.100',
          },
          '_disabled': {
            'bg': 'neutral.10',
            'color': 'neutral500',
            'borderColor': 'error.100',
            '+.chakra-select__icon-wrapper': {
              color: 'error.100',
            },
          },
        },
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'base',
  },
};
