export function camelize(str: string): string {
  return (
    str
      // Replace hyphens and underscores with spaces to make splitting easier
      .replace(/[-_]/g, ' ')
      // Split by spaces or capital letters (to handle PascalCase)
      .split(/[\sA-Z]/)
      // Lowercase the first word, and capitalize subsequent words
      .map((word, index) => {
        if (index === 0) {
          return word.toLowerCase(); // First word should be lowercase
        }
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(); // Capitalize first letter of subsequent words
      })
      .join('')
  ); // Join them back into a single string
}
