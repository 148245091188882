import { createReducer } from '@reduxjs/toolkit';

import { IRemunerationConceptTypeListItem } from '@laudus/types';

import { setRemunerationConceptTypeDraftList, setRemunerationConceptTypeList } from './actions';

export interface IRemunerationConceptTypesState {
  list: IRemunerationConceptTypeListItem[];
  draftList: IRemunerationConceptTypeListItem[];
}

export const initialRemunerationConceptTypesState: IRemunerationConceptTypesState = {
  list: [],
  draftList: [],
};

export const remunerationConceptTypesReducer = createReducer(
  initialRemunerationConceptTypesState,
  (builder) => {
    builder
      .addCase(setRemunerationConceptTypeList, (state, action) => {
        return { ...state, list: action.payload };
      })
      .addCase(setRemunerationConceptTypeDraftList, (state, action) => {
        return { ...state, draftList: action.payload };
      })
      .addDefaultCase((state) => state);
  },
);
