import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { ILoggedUserState } from './reducer';

export const getLoggedUserSlice = (state: AppState): ILoggedUserState => state.loggedUser;

export const getLoggedUser = createSelector([getLoggedUserSlice], (state) => state.user);

export const getLoggedUserGroup = createSelector([getLoggedUserSlice], (state) => state.group);

export const getLoggedUserRights = createSelector(
  [getLoggedUserSlice],
  (state) => state?.group?.rights,
);

export const getLoggedUserPermission = (permissionId: string) =>
  createSelector([getLoggedUserRights], (rights) =>
    rights.find((right) => right.Item.itemId === permissionId),
  );

export const getIsLoggedUserAdmin = createSelector(
  [getLoggedUserSlice],
  (state) => state?.group?.groupId === '001',
);

export const hasLoggedUserWithRights = createSelector(
  [getLoggedUserRights],
  (rights) => rights?.every(({ rightId }) => !!rightId) ?? false,
);
