import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, IRemunerationConcept } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const REMUNERATION_CONCEPT_TAB_ID = 'remunerationConcept';

export const addHomeRemunerationConceptsTab = () =>
  addTab({
    tab: {
      id: REMUNERATION_CONCEPT_TAB_ID,
      title: intl.formatMessage({ id: 'remunerationConcept.tabTitle' }),
      path: 'pages/RemunerationConcept/RemunerationConcept',
      isRemovable: true,
    },
  });

export const addNewRemunerationConceptsTab = () =>
  addTab({
    tab: {
      id: REMUNERATION_CONCEPT_TAB_ID,
      title: intl.formatMessage({ id: 'remunerationConcept.tabTitle' }),
      path: 'pages/RemunerationConcept/RemunerationConceptNew',
      isRemovable: true,
    },
  });

export const addEditRemunerationConceptsTab = () =>
  addTab({
    tab: {
      id: REMUNERATION_CONCEPT_TAB_ID,
      title: intl.formatMessage({ id: 'remunerationConcept.tabTitle' }),
      path: 'pages/RemunerationConcept/RemunerationConceptEdit',
      isRemovable: true,
    },
  });

export const addViewRemunerationConceptsTab = (id?: string) =>
  addTab({
    tab: {
      id: REMUNERATION_CONCEPT_TAB_ID,
      title: intl.formatMessage({ id: 'remunerationConcept.tabTitle' }),
      path: 'pages/RemunerationConcept/RemunerationConceptView',
      props: { id },
      isRemovable: true,
    },
  });

// Simple actions
export const clearRemunerationConcept = createAction('REMUNERATION_CONCEPT/CLEAR');

export const setRemunerationConcept = createAction<IRemunerationConcept>(
  'REMUNERATION_CONCEPT/SET',
);

export const setRemunerationConceptList = createAction<IRemunerationConcept[]>(
  'REMUNERATION_CONCEPT/SET_LIST',
);

export const updateRemunerationConceptList = createAction<IRemunerationConcept>(
  'REMUNERATION_CONCEPT/UPDATE_LIST',
);

export const removeRemunerationConceptFromList = createAction<string>(
  'REMUNERATION_CONCEPT/REMOVE_FROM_LIST',
);

export const duplicateRemunerationConcept = createAction<IRemunerationConcept>(
  'REMUNERATION_CONCEPT/DUPLICATE',
);

export const clearRemunerationConceptDraft = createAction('REMUNERATION_CONCEPT/CLEAR_DRAFT');

export const setRemunerationConceptDraft = createAction<IRemunerationConcept>(
  'REMUNERATION_CONCEPT/SET_DRAFT',
);

export const setRemunerationConceptDraftValues = createAction<Partial<IRemunerationConcept>>(
  'REMUNERATION_CONCEPT/SET_DRAFT_VALUE',
);

export const setRemunerationConceptSelectedNode = createAction<number | string | null>(
  'REMUNERATION_CONCEPT/SET_SELECTED_NODE',
);

export const clearRemunerationConceptSelectedNode = createAction(
  'REMUNERATION_CONCEPT/CLEAR_SELECTED_NODE',
);

// Complex actions
export const fetchRemunerationConceptList = createAsyncThunk<
  void,
  IFetchWithEtagParams,
  AppThunkConfig
>('REMUNERATION_CONCEPT/FETCH_LIST', async ({ eTag, silent }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  try {
    if (!silent) {
      dispatch(startRequest('remuneration-concepts'));
    }

    const { data } = await api.remunerationConcepts.fetchRemunerationConceptListFromAPI();
    dispatch(setRemunerationConceptList(Array.isArray(data) ? data : []));

    dispatch(setEtagsCurrentEtag(eTag));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'remunerationConcept',
        action: 'list',
      }),
    );
  } finally {
    if (!silent) {
      dispatch(endRequest('remuneration-concepts'));
    }
  }
});

export const fetchRemunerationConcept = createAsyncThunk<void, string, AppThunkConfig>(
  'REMUNERATION_CONCEPT/FETCH_REMUNERATION_CONCEPT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('remuneration-concepts'));

    try {
      const { data } = await api.remunerationConcepts.fetchRemunerationConceptFromAPI(id);
      dispatch(setRemunerationConcept(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'remunerationConcept',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('remuneration-concepts'));
    }
  },
);
