import { forwardRef } from 'react';

import { getPriceListList, useAppSelector } from '@laudus/redux-app';

import { SelectEditor } from './SelectEditor';

const SelectPriceListsEditorComponent: React.ForwardRefRenderFunction<any, any> = (props, ref) => {
  const priceListsList = useAppSelector(getPriceListList);

  const options = priceListsList.map((pricesList) => ({
    label: pricesList.name as string,
    value: pricesList.priceListId as number,
  }));

  return <SelectEditor {...props} options={options} ref={ref} />;
};

SelectPriceListsEditorComponent.displayName = 'SelectPriceListsEditorComponent';

export const SelectPriceListsEditor = forwardRef(SelectPriceListsEditorComponent);
