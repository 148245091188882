import { IJournalEntry, IJournalEntryLine } from '@laudus/types';

export const fillEmptyRelatedToInsideLines = (entity: IJournalEntry): IJournalEntry => {
  const newLines: IJournalEntryLine[] = entity.lines.map((line) => {
    if (!line.relatedTo) {
      return {
        ...line,
        relatedTo: { name: '', relatedToId: 0, type: '', VATId: '' },
      };
    } else {
      return { ...line };
    }
  });

  return { ...entity, lines: newLines };
};
