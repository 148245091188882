import { IGroupRight, IRequiredUserRights, IUserRightPermissions } from '@laudus/types';

export function hasRequiredUserRights({
  userRights,
  requiredUserRights,
}: {
  userRights: IGroupRight[];
  requiredUserRights: IRequiredUserRights;
}): boolean {
  // Check that the user doesn't miss any required user rights
  const missingRequiredUserRights = getMissingUserRights({ userRights, requiredUserRights });

  return Object.keys(missingRequiredUserRights).length === 0;
}

export function getMissingUserRights({
  userRights,
  requiredUserRights,
}: {
  userRights: IGroupRight[];
  requiredUserRights: IRequiredUserRights;
}): IRequiredUserRights {
  const missingRequiredUserRights: IRequiredUserRights = {};

  // Iterate every required user rights, checking that the user has them & the permissions match
  Object.keys(requiredUserRights).forEach((requiredUserRightId) => {
    const userRight = userRights.find((right) => right.Item.itemId === requiredUserRightId);

    if (!userRight) {
      // User doesn't have the required user right
      missingRequiredUserRights[requiredUserRightId] = requiredUserRights[requiredUserRightId];
    } else {
      // User does have the right, so we are going to check the permissions
      const requiredUserRightPermissions = requiredUserRights[requiredUserRightId];

      const missingPermissions: IUserRightPermissions = {};

      // Iterate every permission inside the requiredUserRight
      Object.keys(requiredUserRightPermissions).forEach((userRightKey) => {
        // This will be the key of the permission: 'read', 'write', etc
        const permissionKey = userRightKey as keyof IUserRightPermissions;

        // Check if the permission value is the same
        const requiredPermission = requiredUserRightPermissions[permissionKey];
        const userPermission = userRight[permissionKey];

        const permissionMatches = requiredPermission === userPermission;
        if (!permissionMatches && requiredPermission !== undefined) {
          missingPermissions[permissionKey] = requiredPermission;
        }
      });

      // Some permissions are missing in this userRight
      if (Object.keys(missingPermissions).length > 0) {
        missingRequiredUserRights[requiredUserRightId] = missingPermissions;
      }
    }
  });

  return missingRequiredUserRights;
}
