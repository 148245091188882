import { AxiosResponse } from 'axios';

import { ICcaf } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchCcafsListFromAPI = (): Promise<AxiosResponse<ICcaf[]>> =>
  axios.post(
    `${VITE_API_URL}/HR/CCAFs/list`,
    {
      fields: ['CCAFId', 'name', 'previredId', 'electronicBookId', 'notes'],
      orderBy: [{ field: 'name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchCcafFromAPI = (CCAFId: string): Promise<AxiosResponse<ICcaf>> =>
  axios.get(`${VITE_API_URL}/HR/CCAFs/${CCAFId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createCcafFromAPI = (ccaf: ICcaf): Promise<AxiosResponse<ICcaf>> =>
  axios.post(`${VITE_API_URL}/HR/CCAFs`, ccaf);

export const updateCcafFromAPI = (ccaf: ICcaf): Promise<AxiosResponse<ICcaf>> =>
  axios.put(`${VITE_API_URL}/HR/CCAFs/${ccaf.CCAFId}`, ccaf);

export const deleteCcafFromAPI = (CCAFId: string): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/HR/CCAFs/${CCAFId}`);
