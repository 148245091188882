import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Box, Center, Flex, Image, Stack, useBreakpointValue } from '@chakra-ui/react';

import { LaudusImages } from '@laudus/assets';
import { useIntl } from '@laudus/intl';
import {
  clearAuthError,
  getAuthError,
  getPasswordResetSent,
  setPasswordResetSent,
  useGlobalDispatch,
  useGlobalSelector,
} from '@laudus/redux-global';
import { colors } from '@laudus/shared-ui';
import { DeviceTypes } from '@laudus/types';

import { LoginErrorMessage } from '../../shared/components/LoginErrorMessage';

import { PasswordRequestSent } from './PasswordRequestSent';
import { CardPasswordReset, PasswordResetRequest, PasswordResetUpdate } from '.';

export const PasswordReset = () => {
  const intl = useIntl();
  const dispatch = useGlobalDispatch();

  const deviceType = useBreakpointValue(
    { base: DeviceTypes.Mobile, md: DeviceTypes.Desktop },
    { ssr: false },
  );

  const [searchParams] = useSearchParams();
  const tokenParam = searchParams.get('resetToken') as string;
  const loginNameParam = searchParams.get('loginName') as string;
  const isParamsAvailable = !!tokenParam && !!loginNameParam;

  const passwordRequestSent = useGlobalSelector(getPasswordResetSent);
  const authError = useGlobalSelector(getAuthError);

  useEffect(() => {
    if (isParamsAvailable && passwordRequestSent) {
      dispatch(setPasswordResetSent(false));
    }

    return () => {
      dispatch(clearAuthError());
    };
  }, [passwordRequestSent, isParamsAvailable, dispatch]);

  return (
    <Stack
      className="background"
      _dark={{ bg: colors.darkGrey700 }}
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      height="100vh"
      maxH="100vh"
      overflowY={{ base: 'auto', md: 'hidden' }}
    >
      <Center h={{ base: '100vh', md: '100vh' }} width="100%">
        <CardPasswordReset>
          <Flex
            alignItems={{ base: 'center', md: 'center' }}
            flex={{ base: 3, md: 3, lg: 1 }}
            flexDirection="column"
            justifyContent={{ base: 'center', md: 'flex-start' }}
            position="relative"
            w="100%"
          >
            {authError && (
              <Box position="absolute" top="-5.5rem">
                <LoginErrorMessage message={authError.message || authError.title} />
              </Box>
            )}
            <Flex flexDirection="column" maxW="53rem" w={{ base: '90%', md: '100%' }}>
              {deviceType === DeviceTypes.Mobile ? (
                <Image
                  h="7.5rem"
                  mb={{ base: '6rem', md: '3.7rem' }}
                  mt={{ base: '3rem', md: '0' }}
                  src={LaudusImages.loginLogoWhite}
                />
              ) : (
                <Image
                  h="7.5rem"
                  mb={{ base: '6rem', md: '2rem' }}
                  mt={{ base: '3rem', md: '0' }}
                  src={LaudusImages.loginLogoV2}
                />
              )}
            </Flex>
            {isParamsAvailable && !passwordRequestSent ? (
              <PasswordResetUpdate
                formError={authError ? authError.message || authError.title : ''}
                loginNameParam={loginNameParam}
                tokenParam={tokenParam}
              />
            ) : undefined}

            {!isParamsAvailable && !passwordRequestSent ? (
              <PasswordResetRequest
                formError={authError ? authError.message || authError.title : ''}
              />
            ) : undefined}

            {passwordRequestSent ? <PasswordRequestSent /> : undefined}
          </Flex>
        </CardPasswordReset>
      </Center>
    </Stack>
  );
};
