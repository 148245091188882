import { createReducer } from '@reduxjs/toolkit';

import { ISetting } from '@laudus/types';

import {
  clearSettings,
  clearSettingsDraftList,
  removeSettingsFromDraftListValues,
  setSettingsDraft,
  setSettingsDraftListValues,
  setSettingsDraftValues,
  setSettingsList,
  updateSettingsList,
} from './actions';

interface IDraftSettings {
  [key: string]: number | string | boolean;
}

export interface ISettingsState {
  list: ISetting[];
  draft: IDraftSettings;
  draftList: ISetting[];
}

export const initialSettingsState: ISettingsState = {
  list: [],
  draft: {},
  draftList: [],
};

export const settingsReducer = createReducer(initialSettingsState, (builder) => {
  builder
    .addCase(clearSettings, () => {
      return { ...initialSettingsState };
    })
    .addCase(setSettingsList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(setSettingsDraft, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setSettingsDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(clearSettingsDraftList, (state) => {
      state.draftList = [];
    })
    .addCase(setSettingsDraftListValues, (state, action) => {
      return {
        ...state,
        draftList: [
          ...state.draftList.filter((s) => s.keyName !== action.payload.keyName),
          action.payload,
        ],
      };
    })
    .addCase(removeSettingsFromDraftListValues, (state, action) => {
      state.draftList = state.draftList.filter((s) => s.keyName !== action.payload.keyName);
    })
    .addCase(updateSettingsList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((s) => s.keyName !== action.payload.keyName), action.payload],
      };
    })
    .addDefaultCase((state) => state);
});
