import { ComponentSingleStyleConfig } from '@chakra-ui/react';

export const FormLabel: ComponentSingleStyleConfig = {
  baseStyle: {
    color: 'neutral700',
    fontWeight: 'bold',
    marginBottom: '0.4rem',
    width: '100%',
  },
  variants: {
    'switch': {
      marginBottom: 0,
      marginLeft: '1rem',
    },
    'hidden': {
      height: 0,
      marginBottom: 0,
    },
    'small': {
      marginBottom: '3px',
      fontSize: '13px',
      color: 'black',
      fontWeight: 'normal',
    },
    'medium': {
      margin: '0px',
      fontSize: '14px',
      color: 'black',
      fontWeight: '400',
    },
    'outline-secondary': {
      bg: 'transparent',
      borderRadius: '0.4rem',
      border: '1px solid',
      borderColor: 'black',
      color: 'black',
      padding: '0.7rem 2.5rem',
      margin: 0,
      boxSizing: 'border-box',
      fontWeight: '600',
      fontSize: '1.4rem',
      cursor: 'pointer',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'primary500',
        color: 'white',
        borderColor: 'primary500',
      },
      _disabled: {
        _hover: {
          cursor: 'not-allowed',
        },
      },
    },
    'outline-secondary-disabled': {
      bg: 'transparent',
      borderRadius: '0.4rem',
      border: '1px solid',
      borderColor: 'black',
      color: 'black',
      padding: '0.7rem 2.5rem',
      margin: 0,
      boxSizing: 'border-box',
      fontWeight: '600',
      fontSize: '1.4rem',
      cursor: 'pointer',
      opacity: '0.5',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'primary500',
        color: 'white',
        borderColor: 'primary500',
      },
    },
    'imports': {
      bg: 'primary500',
      borderRadius: '0.4rem',
      color: 'white',
      padding: '0.7rem 2.5rem',
      margin: 0,
      boxSizing: 'border-box',
      fontWeight: '600',
      fontSize: '1.4rem',
      cursor: 'pointer',
      _focus: {
        boxShadow: 'none',
      },
      _hover: {
        bg: 'primary400',
        color: 'white',
      },
      _disabled: {
        _hover: {
          cursor: 'not-allowed',
        },
      },
    },
    'dark-background': {
      color: 'secondary100',
      fontSize: '1.3rem',
    },
  },
};
