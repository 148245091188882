import { intl } from '@laudus/intl';
import { USER_RIGHTS } from '@laudus/shared-utils';
import { IReportsLeftMenuItem, ReportsLeftMenuItemTypes } from '@laudus/types';

import { ACCOUNTING_BALANCE_SHEETS_REPORTS } from './balanceSheets';
import { ACCOUNTING_FIXED_ASSETS_REPORTS } from './fixedAssets';
import { ACCOUNTING_JOURNAL_ENTRIES_REPORTS } from './journalEntries';
import { ACCOUNTING_LEDGER_REPORTS } from './ledger';
import { ACCOUNTING_REPORTS_WITHOUT_SECTION } from './root';

export const ACCOUNTING_REPORTS: IReportsLeftMenuItem[] = [
  {
    type: ReportsLeftMenuItemTypes.Section,
    title: intl.formatMessage({ id: 'reports.section.title.ledger' }),
    items: ACCOUNTING_LEDGER_REPORTS,
  },
  {
    type: ReportsLeftMenuItemTypes.Section,
    title: intl.formatMessage({ id: 'reports.section.title.fixedAssets' }),
    userRights: {
      [USER_RIGHTS.PURCHASES.FIXED_ASSETS]: {
        read: true,
      },
    },
    items: ACCOUNTING_FIXED_ASSETS_REPORTS,
  },
  {
    type: ReportsLeftMenuItemTypes.Section,
    title: intl.formatMessage({ id: 'reports.section.title.balanceSheets' }),
    userRights: {
      [USER_RIGHTS.ACCOUNTING.BALANCE_SHEETS]: {
        read: true,
      },
    },
    items: ACCOUNTING_BALANCE_SHEETS_REPORTS,
  },
  {
    type: ReportsLeftMenuItemTypes.Section,
    title: intl.formatMessage({ id: 'reports.section.title.dailyBook' }),
    userRights: {
      [USER_RIGHTS.TOOLS.DAILY_MOVEMENT]: {
        read: true,
      },
    },
    items: ACCOUNTING_JOURNAL_ENTRIES_REPORTS,
  },
  ...ACCOUNTING_REPORTS_WITHOUT_SECTION,
];
