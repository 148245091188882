import { createSelector } from '@reduxjs/toolkit';

import { cleanSalesDocument, ORDERS, updateSalesItemsPricing } from '@laudus/sales-utils';
import { ISalesOrder, ISalesOrderItem, TRACE_FROM_STEP_ORIGIN } from '@laudus/types';

import { AppState } from '../../store';
import { getMainCurrency } from '../currencies';
import { getCustomersList } from '../customers/selectors';
import { getProductsList } from '../products/selectors';

import { ISalesOrderState } from './reducer';

export const getSalesOrdersSlice = (state: AppState): ISalesOrderState => state.salesOrders;

export const getSalesOrderList = createSelector([getSalesOrdersSlice], (state) => state.list);

export const getSalesOrder = createSelector(
  [getSalesOrdersSlice, (appState) => getMainCurrency(appState)],
  (state, { mainCurrencyDecimals }) => updateSalesItemsPricing(state.current, mainCurrencyDecimals),
);

export const getSalesOrderDraft = createSelector(
  [getSalesOrdersSlice, (appState) => getMainCurrency(appState)],
  (state, { mainCurrencyDecimals }) => {
    // This prepare the sales document to be sent to the backend once the draft has been completed
    const clearDraft = cleanSalesDocument(state.draft, ORDERS);

    return updateSalesItemsPricing(clearDraft, mainCurrencyDecimals);
  },
);

export const getSalesOrderPDFUrl = createSelector([getSalesOrdersSlice], (state) => state.pdfURL);

export const getSalesOrderTraces = createSelector([getSalesOrdersSlice], (state) => state.traces);

export const getSalesOrderTracesByOrigin = createSelector([getSalesOrdersSlice], (state) => {
  const traces = state.traces;

  const tracesFromQuotes = traces.filter(
    (trace) => trace.fromStep === TRACE_FROM_STEP_ORIGIN.QUOTES,
  );
  const tracesFromWaybills = traces.filter(
    (trace) => trace.fromStep === TRACE_FROM_STEP_ORIGIN.WAYBILLS,
  );
  const tracesFromInvoices = traces.filter(
    (trace) => trace.fromStep === TRACE_FROM_STEP_ORIGIN.INVOICES,
  );

  return {
    tracesFromQuotes,
    tracesFromWaybills,
    tracesFromInvoices,
  };
});

export const getFakeSaleOrder = createSelector(
  [getProductsList, getCustomersList],
  (products, customers) => {
    const customerIndex = Math.floor(Math.random() * products.length);
    const customer = customers[customerIndex] ?? null;
    // Make a random number of items
    const maxItemQuantity = 7;
    const items: ISalesOrderItem[] = Array.from({ length: maxItemQuantity }, (_, i) => {
      const quantity = Math.floor(Math.random() * 12) + 1;
      const productIndex = Math.floor(Math.random() * products.length);
      const product = products[productIndex] ?? null;
      return {
        itemId: i,
        itemOrder: i + 1,
        product,
        unitPrice: product?.unitPrice ?? 0,
        VATRate: product?.VATRate ?? 0,
        quantity,
        originalUnitPrice: product?.unitPrice ?? 0,
        itemDescription: '',
        currencyCode: 'CLP',
        parityToMainCurrency: 1,
        discountPercentage: 0,
        costCenter: null,
        lot: null,
      };
    });

    return {
      items,
      docType: null,
      salesOrderId: '9999999',
      customer: {
        customerId: customer?.customerId ?? 0,
        name: customer?.name ?? 'Cliente boleta',
        legalName: customer?.legalName ?? 'Cliente boleta',
        VATId: customer?.VATId ?? '12312313',
        email: customer?.email ?? '',
      },
      issuedDate: new Date().toISOString(),
      dueDate: new Date().toISOString(),
    } as unknown as ISalesOrder;
  },
);
