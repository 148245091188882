import { ReactNode } from 'react';

import { Flex } from '@chakra-ui/react';

import { colors } from '@laudus/shared-ui';

interface ICardPasswordResetProps {
  children: ReactNode;
}

export const CardPasswordReset = ({ children }: ICardPasswordResetProps) => {
  return (
    <Flex
      bg={{ base: colors.secondary600, md: colors.white }}
      _dark={{ bg: colors.darkGrey500, boxShadow: '0rem 0.2rem 3rem rgba(194, 200, 209, 0.1)' }}
      borderRadius="2rem"
      boxShadow={{
        base: '0  0 1rem #0590AC,  0  0 1rem #4167A7',
        md: '0rem 0.2rem 3rem rgba(194, 200, 209, 0.7)',
      }}
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      h="calc(100% - 3rem)"
      margin={{ base: '0 1.1rem', md: '0 6.5rem' }}
      marginBottom={{ base: '1.5rem', md: '7.5rem' }}
      marginTop={{ base: '1.5rem', md: '7.5rem' }}
      maxHeight={{ base: 'none', md: '55.8rem' }}
      maxWidth="115rem"
      pt="8.3rem"
      w="100%"
    >
      {children}
    </Flex>
  );
};
