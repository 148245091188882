import { createReducer } from '@reduxjs/toolkit';

import {
  IInvoicesReconciliationFilter,
  InvoicesReconciliationInfo,
  InvoicesReconciliationTypeEnum,
} from '@laudus/types';

import {
  clearInvoicesReconciliationFilter,
  setInvoicesReconciliationFilterValues,
  setInvoicesReconciliationInfo,
} from './actions';

export interface IInvoicesReconciliationState {
  filter: IInvoicesReconciliationFilter;
  invoicesReconciliationInfo: InvoicesReconciliationInfo | null;
}

export const INVOICES_RECONCILIATION_FILTER_EMPTY: IInvoicesReconciliationFilter = {
  year: new Date().getFullYear(),
  month: new Date().getMonth(),
  type: InvoicesReconciliationTypeEnum.PurchasesInvoices,
};

export const initialInvoicesReconciliationState: IInvoicesReconciliationState = {
  filter: INVOICES_RECONCILIATION_FILTER_EMPTY,
  invoicesReconciliationInfo: null,
};

export const invoicesReconciliationReducer = createReducer(
  initialInvoicesReconciliationState,
  (builder) => {
    builder
      .addCase(clearInvoicesReconciliationFilter, (state) => {
        return {
          ...state,
          filter: INVOICES_RECONCILIATION_FILTER_EMPTY,
        };
      })
      .addCase(setInvoicesReconciliationFilterValues, (state, action) => {
        const newFilter = {
          ...state.filter,
          ...action.payload,
        };

        return {
          ...state,
          filter: {
            ...state.filter,
            ...action.payload,
          },
          ...(JSON.stringify(state.filter) !== JSON.stringify(newFilter)
            ? { invoicesReconciliationInfo: null }
            : null),
        };
      })
      .addCase(setInvoicesReconciliationInfo, (state, action) => {
        return {
          ...state,
          invoicesReconciliationInfo: action.payload,
        };
      })
      .addDefaultCase((state) => state);
  },
);
