import { IProduct } from '@laudus/types';

export const PRODUCT_EMPTY: IProduct = {
  alcoholTaxRate: 0,
  allowFreeDescription: false,
  allowUserChangePrices: false,
  applyGeneralVATRate: true,
  applyGeneralVATRetentionRate: true,
  canBePurchased: true,
  canBeSold: true,
  conversionFactor: 0,
  description: '',
  discontinued: false,
  maxDiscount: undefined,
  minimumStock: 0,
  notInvoiceable: false,
  notInvoiceableIsIncome: false,
  productId: 0,
  stockable: true,
  subjectToAlcoholTax: false,
  unitCost: 0,
  unitPrice: 0,
  unitPriceWithTaxes: 0,
  VATRate: 19,
  VATRetentionRate: 0,
};
