import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IWarehouseState } from './reducer';

export const getWarehousesSlice = (state: AppState): IWarehouseState => state.warehouses;

export const getWarehouseList = createSelector([getWarehousesSlice], (state) => state.list);

export const getWarehouse = createSelector([getWarehousesSlice], (state) => state.current);

export const getWarehouseDraft = createSelector([getWarehousesSlice], (state) => state.draft);

export const getSortedWarehouseList = createSelector([getWarehouseList], (warehouseList) => {
  return [...warehouseList].sort((a, b) => a.name.localeCompare(b.name));
});
