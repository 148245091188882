import { forwardRef } from 'react';

import { getCostCenterList, useAppSelector } from '@laudus/redux-app';

const SelectCostCenterRendererComponent: React.ForwardRefRenderFunction<any, any> = (props) => {
  const costCenterList = useAppSelector(getCostCenterList);

  const options = Array.isArray(costCenterList)
    ? costCenterList?.map((costCenter) => ({
        label: costCenter.name as string,
        value: costCenter.costCenterId as string,
      }))
    : [];

  return <span>{options?.find((o) => o.value == props.value)?.label}</span>;
};

SelectCostCenterRendererComponent.displayName = 'SelectCostCenterRendererComponent';

export const SelectCostCenterRenderer = forwardRef(SelectCostCenterRendererComponent);
