import { createSelector } from '@reduxjs/toolkit';

import { AppState, ICustomFieldsState } from '../..';

export const getCustomFieldsSlice = (state: AppState): ICustomFieldsState => state.customFields;
export const getCustomFieldsList = createSelector([getCustomFieldsSlice], (state) => state.list);

export const hasCustomFieldsByEntity = (state: AppState, entity: string) =>
  getCustomFieldsList(state).some((f) => f.entity === entity);

export function getCustomFieldsByEntity(entity: string) {
  return createSelector([getCustomFieldsList], (customFields) =>
    customFields.filter((customField) => customField.entity === entity),
  );
}

export const getCustomFieldsIndexedByName = createSelector([getCustomFieldsList], (customFields) =>
  customFields.reduce((acc, customField) => {
    if (!acc.has(customField.name)) {
      acc.set(customField.name, customField);
    }
    return acc;
  }, new Map()),
);
