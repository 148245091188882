import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import {
  createSalesWaybillFromSalesOrder,
  createSalesWaybillFromSalesQuote,
  updateDraftSalesItems,
} from '@laudus/sales-utils';
import { getMessageFromError, getMessageFromSpecialAPIErrorCode } from '@laudus/shared-utils';
import {
  IAPIError,
  ISalesWaybill,
  ISalesWaybillItem,
  ISalesWaybillList,
  ITraceFrom,
} from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert } from '../alerts';
import { getMainCurrency } from '../currencies';
import { getCustomFieldsByEntity } from '../customFields';
import { endRequest, startRequest } from '../global';
import { getSettings } from '../settings';
import { addTab } from '../tabs';
import { getUser } from '../users';

import { getSalesWaybillDraft } from './selectors';

export const SALESWAYBILLS_TAB_ID = 'salesWaybills';
const entityPrefix = 'SALES_WAYBILLS';

export const addHomeSalesWaybillsTab = () =>
  addTab({
    tab: {
      id: SALESWAYBILLS_TAB_ID,
      title: intl.formatMessage({ id: 'salesWaybills.tabTitle' }),
      path: 'pages/SalesWaybills/SalesWaybills',
      isRemovable: true,
    },
  });

export const addViewSalesWaybillsTab = (id?: number) =>
  addTab({
    tab: {
      id: SALESWAYBILLS_TAB_ID,
      title: intl.formatMessage({ id: 'salesWaybills.tabTitle' }),
      path: 'pages/SalesWaybills/SalesWaybillsView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewSalesWaybillsTab = () =>
  addTab({
    tab: {
      id: SALESWAYBILLS_TAB_ID,
      title: intl.formatMessage({ id: 'salesWaybills.tabTitle' }),
      path: 'pages/SalesWaybills/SalesWaybillsNew',
      isRemovable: true,
    },
  });

export const addEditSalesWaybillsTab = (id?: number) =>
  addTab({
    tab: {
      id: SALESWAYBILLS_TAB_ID,
      title: intl.formatMessage({ id: 'salesWaybills.tabTitle' }),
      path: 'pages/SalesWaybills/SalesWaybillsEdit',
      props: { id },
      isRemovable: true,
    },
  });

// Simple actions
export const clearSalesWaybill = createAction(`${entityPrefix}/CLEAR`);
export const clearSalesWaybillDraft = createAction(`${entityPrefix}/CLEAR_DRAFT`);
export const clearSalesWaybillPDFUrl = createAction(`${entityPrefix}/CLEAR_PDF_URL`);

export const setSalesWaybill = createAction<ISalesWaybill>(`${entityPrefix}/SET`);
export const setSalesWaybillDraft = createAction<ISalesWaybill>(`${entityPrefix}/SET_DRAFT`);
export const setSalesWaybillDraftValues = createAction<Partial<ISalesWaybill>>(
  `${entityPrefix}/SET_DRAFT_VALUES`,
);

export const setSalesWaybillPDFUrl = createAction<string>(`${entityPrefix}/SET_PDF_URL`);
export const setSalesWaybillList = createAction<ISalesWaybillList>(`${entityPrefix}/SET_LIST`);
export const setSalesWaybillTraces = createAction<ITraceFrom[]>('SALES_WAYBILLS/SET_TRACES');
export const updateSalesWaybillList = createAction<ISalesWaybill>(`${entityPrefix}/UPDATE_LIST`);
export const removeSalesWaybillFromList = createAction<number>(`${entityPrefix}/REMOVE_FROM_LIST`);
export const duplicateSalesWaybill = createAction<ISalesWaybill>(`${entityPrefix}/DUPLICATE`);

//Complex actions
export const fetchSalesWaybillsList = createAsyncThunk<void, void, AppThunkConfig>(
  `${entityPrefix}/FETCH_LIST`,
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('sales-waybills'));

    try {
      const { data } = await api.salesWaybills.fetchSalesWaybillsListFromAPI();
      dispatch(setSalesWaybillList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesWaybills',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('sales-waybills'));
    }
  },
);

export const fetchSalesWaybill = createAsyncThunk<void, number, AppThunkConfig>(
  `${entityPrefix}/FETCH`,
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('sales-waybills'));
      const { data } = await api.salesWaybills.fetchSalesWaybillFromAPI(id);

      dispatch(setSalesWaybill(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesWaybills',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('sales-waybills'));
    }
  },
);

export const fetchSalesWaybillPDF = createAsyncThunk<
  void,
  { salesWaybill: ISalesWaybill; numberOfCopies: number; onGetURL: (url: string) => void },
  AppThunkConfig
>(`${entityPrefix}/FETCH_PDF`, async ({ salesWaybill, numberOfCopies, onGetURL }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  try {
    dispatch(startRequest('sales-waybills'));
    const urlBlob = await api.salesWaybills.fetchSalesWaybillPDFUrl({
      salesWaybill,
      numberOfCopies,
    });

    dispatch(setSalesWaybillPDFUrl(urlBlob));

    onGetURL(urlBlob);
  } catch (error) {
    dispatch(
      showAlert({
        type: 'error',
        title: intl.formatMessage({ id: 'pdfViewer.notFound' }),
        message: getMessageFromError({ error }),
      }),
    );
  } finally {
    dispatch(endRequest('sales-waybills'));
  }
});

export const updateSalesWaybillDraftItem = createAsyncThunk<
  void,
  ISalesWaybillItem,
  AppThunkConfig
>('SALES_ORDERS/UPDATE_SALES_WAYBILL_DRAFT_ITEM', async (updatedItem, ThunkAPI) => {
  const { dispatch, getState, extra } = ThunkAPI;
  const { api } = extra;

  const { mainCurrencyDecimals } = getMainCurrency(getState());

  const draft = getSalesWaybillDraft(getState());
  const updateDraftItems = await updateDraftSalesItems(
    updatedItem,
    draft,
    'waybills',
    mainCurrencyDecimals,
    api.products.fetchProductSalesPriceAPI,
  );

  dispatch(setSalesWaybillDraftValues({ items: updateDraftItems }));
});

export const createSalesWaybillAndPrint = createAsyncThunk<void, ISalesWaybill, AppThunkConfig>(
  `SALES_WAYBILLS/CREATE_AND_PRINT`,
  async (salesWaybill, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('sales-waybills'));

    try {
      const { data: newSalesWaybill } =
        await api.salesWaybills.createSalesWaybillFromAPI(salesWaybill);
      dispatch(setSalesWaybill(newSalesWaybill));
      dispatch(updateSalesWaybillList(newSalesWaybill));
      dispatch(
        fetchSalesWaybillPDF({
          salesWaybill: newSalesWaybill,
          numberOfCopies: 1,
          onGetURL: () => {
            dispatch(addViewSalesWaybillsTab());
          },
        }),
      );
    } catch (error) {
      dispatch(endRequest('sales-waybills'));
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesWaybills',
          action: 'save',
        }),
      );
    }
  },
);

export const updateSalesWaybillAndPrint = createAsyncThunk<void, ISalesWaybill, AppThunkConfig>(
  `SALES_WAYBILLS/UPDATE_AND_PRINT`,
  async (salesWaybill, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('sales-waybills'));

    try {
      const { data: updatedSalesWaybill } =
        await api.salesWaybills.updateSalesWaybillFromAPI(salesWaybill);
      dispatch(setSalesWaybill(updatedSalesWaybill));
      dispatch(updateSalesWaybillList(updatedSalesWaybill));
      dispatch(
        fetchSalesWaybillPDF({
          salesWaybill: updatedSalesWaybill,
          numberOfCopies: 1,
          onGetURL: () => {
            dispatch(addViewSalesWaybillsTab());
          },
        }),
      );
    } catch (error) {
      dispatch(endRequest('sales-waybills'));
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesWaybills',
          action: 'save',
        }),
      );
    }
  },
);

export const sendSalesWaybillToSII = createAsyncThunk<void, ISalesWaybill, AppThunkConfig>(
  `SALES_WAYBILLS/SEND_TO_SII`,
  async (salesWaybill, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('sales-waybills'));

    try {
      const sentSalesWaybill = await api.salesWaybills.sendSalesWaybillToSII(salesWaybill);
      dispatch(setSalesWaybill(sentSalesWaybill));

      dispatch(
        showAlert({
          type: 'success',
          title: intl.formatMessage({ id: 'salesWaybills.sendToSII.sucess' }),
          message: '',
        }),
      );
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: intl.formatMessage({ id: 'salesWaybills.sendToSII.fail' }),
          message: getMessageFromError({ error }),
        }),
      );
    } finally {
      dispatch(endRequest('sales-waybills'));
    }
  },
);

export const fetchSalesWaybillTraces = createAsyncThunk<void, number, AppThunkConfig>(
  `SALES_WAYBILLS/FETCH_TRACES`,
  async (salesWaybillId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('sales-waybills'));
      const { data } = await api.salesWaybills.fetchSalesWaybillsTracesFromAPI(salesWaybillId);

      const traces = Array.isArray(data) ? data : [];
      dispatch(setSalesWaybillTraces(traces));
    } catch (error) {
      dispatch(setSalesWaybillTraces([]));
    } finally {
      dispatch(endRequest('sales-waybills'));
    }
  },
);

export const convertSalesOrderIntoSalesWaybillDraft = createAsyncThunk<
  void,
  {
    initialSalesWaybill: ISalesWaybill;
    salesOrderId: number;
    shouldRedirectToNewView: boolean;
    onSucessfullyConverted?: (convertedSalesWaybill: ISalesWaybill) => void;
  },
  AppThunkConfig
>(
  `SALES_WAYBILLS/CREATE_SALES_WAYBILL_FROM_SALES_ORDER`,
  async (
    { initialSalesWaybill, salesOrderId, shouldRedirectToNewView, onSucessfullyConverted },
    ThunkAPI,
  ) => {
    const { dispatch, getState, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('conver-sales-order-into-sales-waybill-draft'));

    // State from Redux
    const state = getState();

    const user = getUser(state);
    const settings = getSettings(state);

    const customFieldsForSalesWaybills = getCustomFieldsByEntity('salesWaybills')(state);
    const customFieldsForSalesWaybillsItems = getCustomFieldsByEntity('salesWaybillsItems')(state);
    const customFieldsForSalesOrders = getCustomFieldsByEntity('salesOrders')(state);
    const customFieldsForSalesOrdersItems = getCustomFieldsByEntity('salesOrdersItems')(state);

    try {
      // Derived state
      const shouldCopyNotesToSalesWaybill = settings.sales_waybills_copyNotes?.keyValue === true;
      const groupItemsWithTheSameProduct = settings.sales_groupWhenInvoicing?.keyValue === true;
      const maxItemsInSalesWaybills = settings.maxItemsInWaybills?.keyValue
        ? (settings.maxItemsInWaybills.keyValue as number)
        : undefined;

      const allowDTE = settings.DTE_allow?.keyValue === true;

      // Get the sales order
      const { data: salesOrder } = await api.salesOrders.fetchSalesOrderFromAPI(salesOrderId);

      if (!salesOrder) {
        throw new Error(intl.formatMessage({ id: 'salesOrders.notFound.byId' }, { salesOrderId }));
      }

      // Get how much is delivered in this order sales
      const { data: quantityDeliveredResponse } =
        await api.salesOrders.getQuantityDeliveredBySalesOrderFromAPI(salesOrderId);

      const quantityDeliveredFromOrder =
        !!quantityDeliveredResponse && Array.isArray(quantityDeliveredResponse)
          ? quantityDeliveredResponse
          : [];

      // Create the sales waybill from the order
      const salesWaybillFromOrder = await createSalesWaybillFromSalesOrder({
        initialSalesWaybill,
        salesOrder,
        user,
        customFieldsForSalesWaybills,
        customFieldsForSalesWaybillsItems,
        customFieldsForSalesOrders,
        customFieldsForSalesOrdersItems,
        shouldCopyNotesToSalesWaybill,
        quantityDeliveredFromOrder,
        groupItemsWithTheSameProduct,
        maxItemsInSalesWaybills,
        allowDTE,
      });

      // Update Redux
      dispatch(setSalesWaybillDraft(salesWaybillFromOrder));

      // Alert about some items being already delivered
      const isSomeOrderItemAlreadyDelivered = quantityDeliveredFromOrder.some(
        (quantityDelivered) => quantityDelivered.delivered > 0,
      );

      if (isSomeOrderItemAlreadyDelivered) {
        dispatch(
          showAlert({
            type: 'info',
            title: intl.formatMessage({
              id: 'salesWaybills.infoToast.createFromSalesOrder.itemsAlreadyDelivered',
            }),
            message: '',
          }),
        );
      }

      // Redirect if neccesary
      if (shouldRedirectToNewView) {
        dispatch(addNewSalesWaybillsTab());
      }

      if (onSucessfullyConverted) {
        onSucessfullyConverted(salesWaybillFromOrder);
      }
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'salesWaybills.errorToast.createFromSalesOrder',
          }),
          // Try to get message by looking for special code
          message:
            getMessageFromSpecialAPIErrorCode(error as IAPIError) ?? getMessageFromError({ error }),
        }),
      );
    } finally {
      dispatch(endRequest('conver-sales-order-into-sales-waybill-draft'));
    }
  },
);

export const convertSalesQuoteIntoSalesWaybillDraft = createAsyncThunk<
  void,
  {
    initialSalesWaybill: ISalesWaybill;
    salesQuoteId: number;
    shouldRedirectToNewView: boolean;
  },
  AppThunkConfig
>(
  `SALES_WAYBILLS/CREATE_SALES_WAYBILL_FROM_SALES_QUOTE`,
  async ({ initialSalesWaybill, salesQuoteId, shouldRedirectToNewView }, ThunkAPI) => {
    const { dispatch, getState, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('convert-sales-quote-into-sales-waybill-draft'));

    // State from Redux
    const state = getState();

    const user = getUser(state);
    const settings = getSettings(state);

    const customFieldsForSalesWaybills = getCustomFieldsByEntity('salesWaybills')(state);
    const customFieldsForSalesWaybillsItems = getCustomFieldsByEntity('salesWaybillsItems')(state);
    const customFieldsForSalesQuotes = getCustomFieldsByEntity('salesQuotes')(state);
    const customFieldsForSalesQuotesItems = getCustomFieldsByEntity('salesQuotesItems')(state);

    try {
      // Derived state
      const shouldCopyNotesToSalesWaybill = settings.sales_waybills_copyNotes?.keyValue === true;
      const groupItemsWithTheSameProduct = settings.sales_groupWhenInvoicing?.keyValue === true;
      const maxItemsInSalesWaybills = settings.maxItemsInWaybills?.keyValue
        ? (settings.maxItemsInWaybills.keyValue as number)
        : undefined;

      const allowDTE = settings.DTE_allow?.keyValue === true;
      const doesSalesQuoteHaveToBeApproved =
        settings.sales_quotes_haveToBeApproved?.keyValue === true;
      const doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts =
        settings.sales_quotes_haveToBeApprovedOnlyWhenDiscounts?.keyValue === true;

      // Get the sales quote
      const { data: salesQuote } = await api.salesQuotes.fetchSalesQuoteFromAPI(salesQuoteId);

      if (!salesQuote) {
        throw new Error(intl.formatMessage({ id: 'salesQuotes.notFound.byId' }, { salesQuoteId }));
      }

      // Get how much is delivered in this sales quote
      const { data: quantityDeliveredResponse } =
        await api.salesQuotes.getQuantityDeliveredBySalesQuoteFromAPI(salesQuoteId);

      const quantityDeliveredFromQuote =
        !!quantityDeliveredResponse && Array.isArray(quantityDeliveredResponse)
          ? quantityDeliveredResponse
          : [];

      // Create the sales waybill from the quote
      const salesWaybillFromQuote = await createSalesWaybillFromSalesQuote({
        initialSalesWaybill,
        salesQuote,
        user,
        customFieldsForSalesWaybills,
        customFieldsForSalesWaybillsItems,
        customFieldsForSalesQuotes,
        customFieldsForSalesQuotesItems,
        shouldCopyNotesToSalesWaybill,
        quantityDeliveredFromQuote,
        groupItemsWithTheSameProduct,
        maxItemsInSalesWaybills,
        allowDTE,
        doesSalesQuoteHaveToBeApproved,
        doesSalesQuoteHaveToBeApprovedOnlyWhenDiscounts,
      });

      // Update Redux
      dispatch(setSalesWaybillDraft(salesWaybillFromQuote));

      // Alert about some items being already delivered
      const isSomeOrderItemAlreadyDelivered = quantityDeliveredFromQuote.some(
        (quantityDelivered) => quantityDelivered.delivered > 0,
      );

      if (isSomeOrderItemAlreadyDelivered) {
        dispatch(
          showAlert({
            type: 'info',
            title: intl.formatMessage({
              id: 'salesWaybills.infoToast.createFromSalesOrder.itemsAlreadyDelivered',
            }),
            message: '',
          }),
        );
      }

      // Redirect if neccesary
      if (shouldRedirectToNewView) {
        dispatch(addNewSalesWaybillsTab());
      }
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: intl.formatMessage({
            id: 'salesWaybills.errorToast.createFromSalesQuote',
          }),
          // Try to get message by looking for special code
          message:
            getMessageFromSpecialAPIErrorCode(error as IAPIError) ?? getMessageFromError({ error }),
        }),
      );
    } finally {
      dispatch(endRequest('convert-sales-quote-into-sales-waybill-draft'));
    }
  },
);
