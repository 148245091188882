import { createReducer } from '@reduxjs/toolkit';

import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import { IHoliday } from '@laudus/types';

import {
  clearHoliday,
  clearHolidayDraft,
  duplicateHoliday,
  removeHolidayFromList,
  setHoliday,
  setHolidayDraft,
  setHolidayDraftValues,
  setHolidaysList,
  updateHolidaysList,
} from './actions';

export interface IHolidaysState {
  current: IHoliday;
  draft: IHoliday;
  list: IHoliday[];
  selectedNode: number | string | null;
}

export const HOLIDAY_EMPTY: IHoliday = {
  holidayId: 0,
  date: '',
  description: '',
  repeating: false,
};

export const initialHolidaysState: IHolidaysState = {
  current: HOLIDAY_EMPTY,
  draft: HOLIDAY_EMPTY,
  list: [],
  selectedNode: null,
};

export const holidaysReducer = createReducer(initialHolidaysState, (builder) => {
  builder
    .addCase(clearHoliday, (state) => {
      return { ...state, current: HOLIDAY_EMPTY };
    })
    .addCase(setHoliday, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setHolidaysList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(clearHolidayDraft, (state) => {
      return { ...state, draft: HOLIDAY_EMPTY };
    })
    .addCase(setHolidayDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setHolidayDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(updateHolidaysList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((d) => d.holidayId !== action.payload.holidayId),
          action.payload,
        ],
      };
    })
    .addCase(removeHolidayFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.holidayId !== action.payload),
      };
    })
    .addCase(duplicateHoliday, (state, action) => {
      // const { holidayId, ...duplicatedHolidays } = action.payload;

      return {
        ...state,
        current: {
          ...action.payload,
          holidayId: getNegativeUniqueNumericId(),
        },
      };
    })
    .addDefaultCase((state) => state);
});
