import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IEmployeeHistory } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

export const EMPLOYEE_HISTORY_TAB_ID = 'employeesHistory';
const employeesHistoryPrefix = 'EMPLOYEES_HISTORY';

// Employees History action creators
export const clearEmployeeHistory = createAction(`${employeesHistoryPrefix}/CLEAR`);

export const clearEmployeeHistoryDraft = createAction(`${employeesHistoryPrefix}/CLEAR_DRAFT`);

export const setEmployeeHistory = createAction<IEmployeeHistory>(`${employeesHistoryPrefix}/SET`);

export const setEmployeeHistoryDraft = createAction<IEmployeeHistory>(
  `${employeesHistoryPrefix}/SET_DRAFT`,
);

export const setEmployeeHistoryDraftValues = createAction<Partial<IEmployeeHistory>>(
  `${employeesHistoryPrefix}/SET_EMPLOYEE_HISTORY_DRAFT_VALUE`,
);

export const setEmployeesHistoryList = createAction<IEmployeeHistory[]>(
  `${employeesHistoryPrefix}/SET_LIST`,
);

export const updateEmployeesHistoryList = createAction<IEmployeeHistory>(
  `${employeesHistoryPrefix}/UPDATE_LIST`,
);

export const removeEmployeeHistoryFromList = createAction<number>(
  `${employeesHistoryPrefix}/REMOVE_FROM_LIST`,
);

export const duplicateEmployeeHistory = createAction<IEmployeeHistory>(
  `${employeesHistoryPrefix}/DUPLICATE`,
);

export const fetchEmployeesHistoryList = createAsyncThunk<void, number, AppThunkConfig>(
  `${employeesHistoryPrefix}/FETCH_LIST`,
  async (employeeHistoryId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('employees-history'));
    try {
      const { data } =
        await api.employeesHistory.fetchEmployeesHistoryListFromAPI(employeeHistoryId);
      dispatch(setEmployeesHistoryList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeeHistory',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('employees-history'));
    }
  },
);

export interface IFetchEmployeeHistoryParams {
  employeeHistoryId: number;
  historyId: number;
}
export const fetchEmployeeHistory = createAsyncThunk<
  void,
  IFetchEmployeeHistoryParams,
  AppThunkConfig
>(`${employeesHistoryPrefix}/FETCH`, async ({ employeeHistoryId, historyId }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('employees-history'));
  try {
    const { data } = await api.employeesHistory.fetchEmployeeHistoryFromAPI(
      employeeHistoryId,
      historyId,
    );
    dispatch(setEmployeeHistory(data));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'employeeHistory',
        action: 'read',
      }),
    );
  } finally {
    dispatch(endRequest('employees-history'));
  }
});
export interface ICreateEmployeeHistoryParams {
  employeeId: number;
  history: IEmployeeHistory;
}
export const createEmployeeHistory = createAsyncThunk<
  void,
  ICreateEmployeeHistoryParams,
  AppThunkConfig
>(`${employeesHistoryPrefix}/CREATE`, async ({ employeeId, history }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;
  dispatch(startRequest('employees-history'));
  try {
    const idToZeroHistory = { ...history, employeeHistoryId: 0 };
    const { data } = await api.employeesHistory.createEmployeeHistoryFromAPI(
      employeeId,
      idToZeroHistory,
    );
    dispatch(setEmployeeHistory(data));
    dispatch(updateEmployeesHistoryList(data));
    dispatch(
      showAlert({
        title: intl.formatMessage({
          id: 'employeeHistory.successToast.save',
        }),
        message: '',
        type: 'success',
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'employeeHistory',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('employees-history'));
  }
});

export interface IUpdateEmployeeHistoryParams {
  employeeId: number;
  history: IEmployeeHistory;
}
export const updateEmployeeHistory = createAsyncThunk<
  void,
  IUpdateEmployeeHistoryParams,
  AppThunkConfig
>(`${employeesHistoryPrefix}/UPDATE`, async ({ employeeId, history }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('employees-history'));
  try {
    const { data } = await api.employeesHistory.updateEmployeeHistoryFromAPI(employeeId, history);
    dispatch(setEmployeeHistory(data));
    dispatch(updateEmployeesHistoryList(data));
    dispatch(
      showToastAlert({
        title: intl.formatMessage({
          id: 'employeeHistory.successToast.save',
        }),
        message: '',
        type: 'success',
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'employeeHistory',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('employees-history'));
  }
});

export interface IDeleteEmployeeHistoryParams {
  employeeId: number;
  historyId: number;
}

export const deleteEmployeeHistory = createAsyncThunk<
  void,
  IDeleteEmployeeHistoryParams,
  AppThunkConfig
>(`${employeesHistoryPrefix}/DELETE`, async ({ employeeId, historyId }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('employees-history'));
  try {
    await api.employeesHistory.deleteEmployeeHistoryFromAPI(employeeId, historyId);
    dispatch(removeEmployeeHistoryFromList(historyId));
    dispatch(
      showAlert({
        title: intl.formatMessage({
          id: 'employeeHistory.successToast.delete',
        }),
        message: '',
        type: 'success',
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'employeeHistory',
        action: 'delete',
      }),
    );
  } finally {
    dispatch(endRequest('employees-history'));
  }
});
