import { IReportsLeftMenuItem } from '@laudus/types';

import { SALES_INVOICES_REPORTS_WITHOUT_SECTION } from './root';

export const SALES_INVOICES_REPORTS: IReportsLeftMenuItem[] = [
  // {
  //   type: ReportsLeftMenuItemTypes.Section,
  //   title: intl.formatMessage({ id: 'reports.section.title.myNewReportSection' }),
  //   items: ITEMS_FROM_MY_NEW_REPORT_SECTION,
  // },
  ...SALES_INVOICES_REPORTS_WITHOUT_SECTION,
];
