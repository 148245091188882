import { AxiosResponse } from 'axios';

import { IRemunerationConceptType, IRemunerationConceptTypeListItem } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchRemunerationConceptTypeListFromAPI = (): Promise<
  AxiosResponse<IRemunerationConceptTypeListItem[]>
> =>
  axios.post(
    `${VITE_API_URL}/HR/RemunerationConceptTypes/list`,
    {
      fields: [
        'remunerationConceptTypeId',
        'description',
        'remunerationConcept.remunerationConceptId',
        'remunerationConcept.name',
        'required',
        'notes',
        'electronicBookId',
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const updateRemunerationConceptTypeFromAPI = (
  remunerationConceptType: IRemunerationConceptType,
): Promise<AxiosResponse<IRemunerationConceptType>> =>
  axios.put(
    `${VITE_API_URL}/HR/RemunerationConceptTypes/${remunerationConceptType.remunerationConceptTypeId}`,
    remunerationConceptType,
  );
