import { createReducer } from '@reduxjs/toolkit';

import { IWarehouse } from '@laudus/types';

import {
  clearWarehouse,
  duplicateWarehouse,
  removeWarehouseFromList,
  setWarehouse,
  setWarehouseDraft,
  setWarehouseDraftValues,
  setWarehouseList,
  updateWarehouseList,
} from './actions';
import { clearWarehouseDraft } from '.';

export const WAREHOUSE_TAB_ID = 'warehouse';

export interface IWarehouseState {
  current: IWarehouse;
  draft: IWarehouse;
  list: IWarehouse[];
}

export const WAREHOUSE_EMPTY: IWarehouse = {
  warehouseId: '',
  address: '',
  city: '',
  county: '',
  name: '',
  notes: '',
  schedule: '',
  state: '',
};

export const initialWarehousesState: IWarehouseState = {
  current: WAREHOUSE_EMPTY,
  draft: WAREHOUSE_EMPTY,
  list: [],
};

export const warehousesReducer = createReducer(initialWarehousesState, (builder) => {
  builder
    .addCase(clearWarehouse, (state) => {
      return { ...state, current: WAREHOUSE_EMPTY };
    })
    .addCase(clearWarehouseDraft, (state) => {
      return { ...state, draft: WAREHOUSE_EMPTY };
    })
    .addCase(setWarehouse, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setWarehouseDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setWarehouseDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setWarehouseList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateWarehouseList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((d) => d.warehouseId !== action.payload.warehouseId),
          action.payload,
        ],
      };
    })
    .addCase(removeWarehouseFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.warehouseId !== action.payload),
      };
    })
    .addCase(duplicateWarehouse, (state, action) => {
      const { warehouseId, ...duplicatedWarehouse } = action.payload;
      return { ...state, draft: duplicatedWarehouse };
    })
    .addDefaultCase((state) => state);
});
