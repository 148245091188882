import { LoginImagePaths } from '@laudus/assets';

export const COMPANY_IMAGES = [
  {
    name: 'acrilplas',
    location: 'Coquimbo, Chile',
    images: [
      `${LoginImagePaths.erp}0.jpg`,
      `${LoginImagePaths.erp}1.jpg`,
      `${LoginImagePaths.erp}2.jpg`,
      `${LoginImagePaths.erp}3.jpg`,
      `${LoginImagePaths.erp}4.jpg`,
      `${LoginImagePaths.erp}5.jpg`,
    ],
  },
  {
    name: 'everfresh',
    location: 'Coquimbo, Chile',
    images: [
      `${LoginImagePaths.erp}6.jpg`,
      `${LoginImagePaths.erp}7.jpg`,
      `${LoginImagePaths.erp}8.jpg`,
      `${LoginImagePaths.erp}9.jpg`,
      `${LoginImagePaths.erp}10.jpg`,
      `${LoginImagePaths.erp}11.jpg`,
      `${LoginImagePaths.erp}12.jpg`,
    ],
  },
  {
    name: 'PET KITCHEN',
    location: 'Coquimbo, Chile',
    images: [
      `${LoginImagePaths.erp}13.jpg`,
      `${LoginImagePaths.erp}14.jpg`,
      `${LoginImagePaths.erp}15.jpg`,
      `${LoginImagePaths.erp}16.jpg`,
      `${LoginImagePaths.erp}17.jpg`,
      `${LoginImagePaths.erp}18.jpg`,
      `${LoginImagePaths.erp}19.jpg`,
    ],
  },
  {
    name: 'SP Plásticos',
    location: 'Coquimbo, Chile',
    images: [
      `${LoginImagePaths.erp}20.jpg`,
      `${LoginImagePaths.erp}21.jpg`,
      `${LoginImagePaths.erp}22.jpg`,
      `${LoginImagePaths.erp}23.jpg`,
      `${LoginImagePaths.erp}24.jpg`,
      `${LoginImagePaths.erp}25.jpg`,
    ],
  },
  {
    name: 'Vira Vira',
    location: 'Coquimbo, Chile',
    images: [
      `${LoginImagePaths.erp}26.jpg`,
      `${LoginImagePaths.erp}43.jpg`,
      `${LoginImagePaths.erp}64.jpg`,
      `${LoginImagePaths.erp}65.jpg`,
      `${LoginImagePaths.erp}66.jpg`,
      `${LoginImagePaths.erp}67.jpg`,
    ],
  },
  {
    name: 'YOKONO',
    location: 'Coquimbo, Chile',
    images: [
      `${LoginImagePaths.erp}27.jpg`,
      `${LoginImagePaths.erp}28.jpg`,
      `${LoginImagePaths.erp}29.jpg`,
      `${LoginImagePaths.erp}30.jpg`,
      `${LoginImagePaths.erp}31.jpg`,
      `${LoginImagePaths.erp}32.jpg`,
      `${LoginImagePaths.erp}33.jpg`,
    ],
  },
  {
    name: 'MacLean',
    location: 'Coquimbo, Chile',
    images: [
      `${LoginImagePaths.erp}34.jpg`,
      `${LoginImagePaths.erp}35.jpg`,
      `${LoginImagePaths.erp}36.jpg`,
      `${LoginImagePaths.erp}37.jpg`,
      `${LoginImagePaths.erp}38.jpg`,
    ],
  },
  {
    name: 'Huentelauquen',
    location: 'Coquimbo, Chile',
    images: [
      `${LoginImagePaths.erp}39.jpg`,
      `${LoginImagePaths.erp}40.jpg`,
      `${LoginImagePaths.erp}41.jpg`,
      `${LoginImagePaths.erp}44.jpg`,
      `${LoginImagePaths.erp}45.jpg`,
      `${LoginImagePaths.erp}46.jpg`,
    ],
  },
  {
    name: 'Nahuel',
    location: 'Coquimbo, Chile',
    images: [
      `${LoginImagePaths.erp}47.jpg`,
      `${LoginImagePaths.erp}48.jpg`,
      `${LoginImagePaths.erp}49.jpg`,
      `${LoginImagePaths.erp}50.jpg`,
      `${LoginImagePaths.erp}51.jpg`,
    ],
  },
  {
    name: 'CDV FOODS LTDA.',
    location: 'Coquimbo, Chile',
    images: [
      `${LoginImagePaths.erp}52.jpg`,
      `${LoginImagePaths.erp}53.jpg`,
      `${LoginImagePaths.erp}54.jpg`,
      `${LoginImagePaths.erp}55.jpg`,
      `${LoginImagePaths.erp}56.jpg`,
      `${LoginImagePaths.erp}57.jpg`,
      `${LoginImagePaths.erp}58.jpg`,
    ],
  },
  {
    name: 'Balduzzi',
    location: 'Coquimbo, Chile',
    images: [
      `${LoginImagePaths.erp}59.jpg`,
      `${LoginImagePaths.erp}60.jpg`,
      `${LoginImagePaths.erp}61.jpg`,
      `${LoginImagePaths.erp}62.jpg`,
      `${LoginImagePaths.erp}63.jpg`,
    ],
  },
  {
    name: 'congela2',
    location: 'Coquimbo, Chile',
    images: [
      `${LoginImagePaths.erp}68.jpg`,
      `${LoginImagePaths.erp}69.jpg`,
      `${LoginImagePaths.erp}70.jpg`,
      `${LoginImagePaths.erp}71.jpg`,
      `${LoginImagePaths.erp}72.jpg`,
      `${LoginImagePaths.erp}73.jpg`,
      `${LoginImagePaths.erp}74.jpg`,
    ],
  },
  {
    name: 'cespa',
    location: 'Coquimbo, Chile',
    images: [
      `${LoginImagePaths.erp}75.jpg`,
      `${LoginImagePaths.erp}76.jpg`,
      `${LoginImagePaths.erp}77.jpg`,
      `${LoginImagePaths.erp}78.jpg`,
      `${LoginImagePaths.erp}79.jpg`,
      `${LoginImagePaths.erp}80.jpg`,
      `${LoginImagePaths.erp}81.jpg`,
    ],
  },
];
