import { useEffect, useState } from 'react';

import { GenericSearchGrid, RowDoubleClickedEvent, SelectionChangedEvent } from '@laudus/ag-grid';
import { getAuthLoginName, globalStore } from '@laudus/redux-global';
import { services } from '@laudus/services';
import { IGlobalCompany } from '@laudus/types';

import { columnDefs } from './columnDefs';

interface ICompanySearchGridProps {
  onSelectionChanged: (event: SelectionChangedEvent) => void;
  onSelectionDoubleClicked: (event: RowDoubleClickedEvent) => void;
}

export const CompanySearchGrid = ({
  onSelectionChanged,
  onSelectionDoubleClicked,
}: ICompanySearchGridProps) => {
  const loginName = getAuthLoginName(globalStore.getState());
  const [rowData, setRowData] = useState<IGlobalCompany[]>([]);

  const sortedCompanies: IGlobalCompany[] = [...rowData].sort((a, b) =>
    (a.name ?? '').localeCompare(b.name ?? ''),
  );

  useEffect(() => {
    const retrieveCustomersFromUser = async () => {
      try {
        if (loginName) {
          // Before ask for companies we need to get more user info.
          const { data: user } =
            await services.api.globalUsers.getGlobalUserByLoginNameAPI(loginName);

          // Get authorized companies list for logged user.
          const { data } = await services.api.globalCompanies.getCompaniesListByUserAPI(
            user.glUserId,
          );

          setRowData(data);
        }
      } catch (e) {
        setRowData([]);
      }
    };

    retrieveCustomersFromUser();
  }, []);

  return (
    <GenericSearchGrid
      {...{
        columnDefs,
        onSelectionChanged,
        onSelectionDoubleClicked,
        rowData: sortedCompanies,
        rowDataIdentifier: 'VATId',
      }}
    />
  );
};
