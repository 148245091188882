import { createSelector } from '@reduxjs/toolkit';

import { AppState, ISIITransactionState } from '../..';

export const getSIITransactionsSlice = (state: AppState): ISIITransactionState =>
  state.SIITransactions;

export const getSIITransactionTypeList = createSelector(
  [getSIITransactionsSlice],
  (state) => state.SIITransactionTypes,
);
