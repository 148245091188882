import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { IDashboard, IDashboardCard } from '@laudus/types';

import { AppState, AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, endRequestInTab, startRequest, startRequestInTab } from '../global/actions';
import { clearStatisticsDashboardCardsData } from '../statistics';

import { getDashboardById, getDashboardListFetchTimestamp } from './selectors';
import { parseDashboard, serializeDashboard } from './utils';

export const HOME_TAB_ID = 'home';

export const DASHBOARD_LIST_FETCH_INTERVAL = 24 * 3600 * 1000; // 24 hours

export const clearDashboardList = createAction('DASHBOARDS/CLEAR');

export const startDashboardCardFetching = createAction<number>('DASHBOARDS/START_CARD_FETCHING');

export const endDashboardCardFetching = createAction<number>('DASHBOARDS/END_CARD_FETCHING');

export const setDashboardList = createAction<IDashboard[]>('DASHBOARDS/SET_LIST');

export const updateDashboardList = createAction<IDashboard>('DASHBOARDS/UPDATE_LIST');

interface IFetchDashboardListParams {
  forceFetch: boolean;
}
export const fetchDashboardList = createAsyncThunk<void, IFetchDashboardListParams, AppThunkConfig>(
  'DASHBOARDS/FETCH_LIST',
  async ({ forceFetch }, ThunkAPI) => {
    const { dispatch, extra, getState } = ThunkAPI;
    const { api } = extra;
    const state = getState() as AppState;

    const listFetchTimestamp = getDashboardListFetchTimestamp(state);
    if (!forceFetch && Date.now() - listFetchTimestamp < DASHBOARD_LIST_FETCH_INTERVAL) {
      return;
    }

    try {
      dispatch(startRequestInTab(HOME_TAB_ID));
      const { data: dashboardResponse } = await api.dashboards.fetchDashboardListAPI();
      const dashboardIdList = Array.isArray(dashboardResponse) ? dashboardResponse : [];

      const apiResponses = await Promise.all(
        dashboardIdList
          .filter(({ dashboardId }) => !!dashboardId)
          .map(({ dashboardId }) => api.dashboards.fetchDashboardAPI(dashboardId)),
      );

      const dashboardList = apiResponses.map(({ data }) => parseDashboard(data));

      dispatch(setDashboardList(dashboardList));
      dispatch(clearStatisticsDashboardCardsData());
    } catch (error: unknown) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'dashboard',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequestInTab(HOME_TAB_ID));
    }
  },
);

export interface ICreateDashboardParams {
  dashboard: IDashboard;
  newCard: IDashboardCard;
}
export const createDashboard = createAsyncThunk<void, ICreateDashboardParams, AppThunkConfig>(
  'DASHBOARDS/CREATE_DASHBOARD',
  async ({ dashboard, newCard }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('dashboard'));
    try {
      const { data } = await api.dashboards.createDashboardAPI(
        serializeDashboard({
          ...dashboard,
          cards: [newCard],
        }),
      );
      dispatch(updateDashboardList(parseDashboard(data)));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'dashboard',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('dashboard'));
    }
  },
);

export const updateDashboard = createAsyncThunk<void, IDashboard, AppThunkConfig>(
  'DASHBOARDS/UPDATE',
  async (currentDashboard, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      const { data } = await api.dashboards.updateDashboardAPI(
        currentDashboard.dashboardId,
        serializeDashboard(currentDashboard),
      );

      dispatch(updateDashboardList(parseDashboard(data)));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'dashboard',
          action: 'save',
        }),
      );
    }
  },
);

export interface ICreateDashboardCardParams {
  dashboardId: number;
  newCard: IDashboardCard;
}
export const createDashboardCard = createAsyncThunk<
  void,
  ICreateDashboardCardParams,
  AppThunkConfig
>('DASHBOARDS/CREATE_CARD', async ({ dashboardId, newCard }, ThunkAPI) => {
  const { dispatch, extra, getState } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('dashboard'));
  try {
    const state = getState() as AppState;

    const dashboard = getDashboardById(dashboardId)(state);
    if (dashboard) {
      const { data } = await api.dashboards.updateDashboardAPI(
        dashboardId,
        serializeDashboard({
          ...dashboard,
          cards: [...dashboard.cards, newCard],
        }),
      );
      dispatch(updateDashboardList(parseDashboard(data)));
    }
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'dashboard',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('dashboard'));
  }
});

export interface IUpdateDashboardCardParams {
  dashboardId: number;
  updatedCard: IDashboardCard;
}
export const updateDashboardCard = createAsyncThunk<
  void,
  IUpdateDashboardCardParams,
  AppThunkConfig
>('DASHBOARDS/UPDATE_CARD', async ({ dashboardId, updatedCard }, ThunkAPI) => {
  const { dispatch, extra, getState } = ThunkAPI;
  const { api } = extra;

  try {
    dispatch(startDashboardCardFetching(updatedCard.cardId));
    const state = getState() as AppState;

    const dashboard = getDashboardById(dashboardId)(state);
    if (dashboard) {
      const { data } = await api.dashboards.updateDashboardAPI(
        dashboardId,
        serializeDashboard({
          ...dashboard,
          cards: [
            ...dashboard.cards.filter((card) => card.cardId !== updatedCard.cardId),
            updatedCard,
          ],
        }),
      );
      dispatch(updateDashboardList(parseDashboard(data)));
    }
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'dashboard',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endDashboardCardFetching(updatedCard.cardId));
  }
});

export interface IRemoveDashboardCardParams {
  dashboardId: number;
  cardId: number;
}
export const removeDashboardCard = createAsyncThunk<
  void,
  IRemoveDashboardCardParams,
  AppThunkConfig
>('DASHBOARDS/REMOVE_CARD', async ({ dashboardId, cardId }, ThunkAPI) => {
  const { dispatch, extra, getState } = ThunkAPI;
  const { api } = extra;

  try {
    dispatch(startRequest('dashboard'));
    const state = getState() as AppState;

    const dashboard = getDashboardById(dashboardId)(state);
    if (dashboard) {
      const { data } = await api.dashboards.updateDashboardAPI(
        dashboardId,
        serializeDashboard({
          ...dashboard,
          cards: [...dashboard.cards.filter((card) => card.cardId !== cardId)],
        }),
      );
      dispatch(updateDashboardList(parseDashboard(data)));
    }
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'dashboard',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('dashboard'));
  }
});
