import { createReducer } from '@reduxjs/toolkit';

import { FixedAssetDepreciationType, IFixedAsset, IFixedAssetListItem } from '@laudus/types';

import {
  clearFixedAsset,
  clearFixedAssetDraft,
  duplicateFixedAsset,
  removeFixedAssetFromList,
  setFixedAsset,
  setFixedAssetDraft,
  setFixedAssetDraftValues,
  setFixedAssetList,
  updateFixedAssetList,
} from './actions';

export interface IFixedAssetsState {
  current: IFixedAsset;
  draft: IFixedAsset;
  list: IFixedAssetListItem[];
}

export const FIXED_ASSET_EMPTY: IFixedAsset = {
  fixedAssetId: undefined,
  name: '',
  dateAcquisition: null,
  dateRetirement: null,
  acquisitionCost: 0,
  usefulLife: 0,
  realizableValue: 0,
  depreciationType: FixedAssetDepreciationType.LINEAR,
  depreciate: false,
  depreciateFromMonthOfAcquisition: false,
  depreciateWithInflationCorrection: false,
  taxDeductionPercentage: 0,
  manufacturer: '',
  model: '',
  serialNumber: '',
  supplier: '',
  invoiceNumber: '',
  location: '',
  purchaseInvoice: null,
  account: null,
  accountDepreciation: null,
  accountAccumulatedDepreciation: null,
  notes: null,
  createdBy: null,
  createdAt: null,
  modifiedBy: null,
  modifiedAt: null,
  depreciation: [],
};

export const initialFixedAssetsState: IFixedAssetsState = {
  current: FIXED_ASSET_EMPTY,
  draft: FIXED_ASSET_EMPTY,
  list: [],
};

export const fixedAssetsReducer = createReducer(initialFixedAssetsState, (builder) => {
  builder
    .addCase(clearFixedAsset, (state) => {
      return { ...state, current: FIXED_ASSET_EMPTY };
    })
    .addCase(clearFixedAssetDraft, (state) => {
      return { ...state, draft: FIXED_ASSET_EMPTY };
    })
    .addCase(setFixedAsset, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setFixedAssetDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setFixedAssetDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setFixedAssetList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateFixedAssetList, (state, action) => {
      const { fixedAssetId, name, location, dateAcquisition } = action.payload;

      if (!state.list.some((d) => d.fixedAssetId === fixedAssetId)) {
        return state;
      }

      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.fixedAssetId !== action.payload.fixedAssetId),
          {
            fixedAssetId: fixedAssetId ?? 0,
            name,
            location,
            dateAcquisition,
          },
        ],
      };
    })
    .addCase(removeFixedAssetFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.fixedAssetId !== action.payload),
      };
    })
    .addCase(duplicateFixedAsset, (state, action) => {
      const { fixedAssetId, depreciation, ...duplicatedFixedAsset } = action.payload;

      const newDraftFixedAssets: IFixedAsset = { ...duplicatedFixedAsset, depreciation: [] };

      return { ...state, draft: newDraftFixedAssets };
    })
    .addDefaultCase((state) => state);
});
