import { CSSProperties, ReactElement, ReactNode } from 'react';

import { useColorMode } from '@chakra-ui/react';
import { Menu as SzhsinMenu, MenuProps } from '@szhsin/react-menu';

import { colors } from '../../constants/colors';

export interface IMenuProps extends Partial<MenuProps> {
  children: ReactNode;
  menuButton: ReactElement;
  className?: string;
  portal?: boolean;
  menuStyle?: CSSProperties;
  width?: string;
}

export const Menu = ({
  children,
  menuStyle,
  menuButton,
  className,
  portal,
  width,
  ...props
}: IMenuProps) => {
  const { colorMode } = useColorMode();

  const baseStyles: CSSProperties = {
    backgroundColor: colorMode === 'dark' ? colors.darkGrey900 : colors.transparentGrey70,
    boxShadow: '0 0.4rem 1.8rem 0 rgba(7, 19, 37, 0.75)',
    color: colorMode === 'dark' ? colors.white : colors.black,
  };

  const optionalStyles: CSSProperties = {
    ...(menuStyle?.padding && { padding: menuStyle.padding }),
    ...(menuStyle?.right && { right: menuStyle.right }),
    ...(menuStyle?.left && { left: menuStyle.left }),
    ...(width && { width }),
  };

  const defaultStyles: CSSProperties = {
    ...baseStyles,
    ...menuStyle,
    ...optionalStyles,
  };
  return (
    <SzhsinMenu
      menuButton={menuButton}
      portal={portal}
      className={className}
      menuStyle={defaultStyles}
      {...props}
    >
      {children}
    </SzhsinMenu>
  );
};
