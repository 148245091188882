import { AxiosResponse } from 'axios';

import { removeNegativeLineIdsFromEntity } from '@laudus/shared-utils';
import { IEmployeeContractTermination } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchEmployeesContractTerminationsListFromAPI = (
  employeeId: number,
): Promise<AxiosResponse<IEmployeeContractTermination[]>> =>
  axios.get(`${VITE_API_URL}/HR/employees/${employeeId}/contractTerminations`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const fetchEmployeeContractTerminationFromAPI = (
  employeeId: number,
  contractTerminationId: number,
): Promise<AxiosResponse<IEmployeeContractTermination>> =>
  axios.get(
    `${VITE_API_URL}/HR/employees/${employeeId}/contractTerminations/${contractTerminationId}`,
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const createEmployeeContractTerminationFromAPI = (
  employeeId: number,
  contractTermination: IEmployeeContractTermination,
): Promise<AxiosResponse<IEmployeeContractTermination>> =>
  axios.post(
    `${VITE_API_URL}/HR/employees/${employeeId}/contractTerminations`,
    contractTermination,
  );

export const updateEmployeeContractTerminationFromAPI = (
  employeeId: number,
  contractTermination: IEmployeeContractTermination,
): Promise<AxiosResponse<IEmployeeContractTermination>> =>
  axios.put(
    `${VITE_API_URL}/HR/employees/${employeeId}/contractTerminations/${contractTermination.employeeContractTerminationId}`,
    removeNegativeLineIdsFromEntity(contractTermination),
  );

export const deleteEmployeeContractTerminationFromAPI = (
  employeeId: number,
  contractTerminationId: number,
): Promise<AxiosResponse> =>
  axios.delete(
    `${VITE_API_URL}/HR/employees/${employeeId}/contractTerminations/${contractTerminationId}`,
  );
