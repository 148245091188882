import { createReducer } from '@reduxjs/toolkit';

import { IGlobalCompany } from '@laudus/types';

import {
  clearGlobalCompany,
  clearGlobalCompanyDraft,
  removeGlobalCompanyFromList,
  setGlobalCompaniesList,
  setGlobalCompany,
  setGlobalCompanyDraft,
  setGlobalCompanyDraftValues,
  updateGlobalCompaniesList,
} from './actions';

export interface IGlobalCompaniesState {
  current: IGlobalCompany;
  draft: IGlobalCompany;
  list: IGlobalCompany[];
}

export const GLOBAL_COMPANY_EMPTY: IGlobalCompany = {
  glCompanyId: 0,
  name: '',
  VATId: '',
  createdAt: '',
  POS: false,
};

export const initialGlobalCompaniesState: IGlobalCompaniesState = {
  current: GLOBAL_COMPANY_EMPTY,
  draft: GLOBAL_COMPANY_EMPTY,
  list: [],
};

export const globalCompaniesReducer = createReducer(initialGlobalCompaniesState, (builder) => {
  builder
    .addCase(clearGlobalCompany, (state) => {
      return { ...state, current: GLOBAL_COMPANY_EMPTY };
    })
    .addCase(clearGlobalCompanyDraft, (state) => {
      return { ...state, draft: GLOBAL_COMPANY_EMPTY };
    })
    .addCase(setGlobalCompany, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setGlobalCompanyDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setGlobalCompanyDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setGlobalCompaniesList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateGlobalCompaniesList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.glCompanyId !== action.payload.glCompanyId),
          action.payload,
        ],
      };
    })
    .addCase(removeGlobalCompanyFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.glCompanyId !== action.payload),
      };
    })
    .addDefaultCase((state) => state);
});
