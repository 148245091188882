import { AxiosResponse } from 'axios';

import { IGlobalUser } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchGlobalUserFromAPI = (glUserId: number): Promise<AxiosResponse<IGlobalUser>> =>
  axios.get(`${VITE_API_URL}/accounts/security/glusers/${glUserId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const getGlobalUserByLoginNameAPI = (
  loginName: string,
): Promise<AxiosResponse<IGlobalUser>> => {
  return axios.get(`${VITE_API_URL}/accounts/security/glusers?loginName=${loginName}`, {
    headers: {
      Accept: 'application/json',
    },
  });
};

export const fetchGlobalUsersListFromAPI = (): Promise<AxiosResponse<IGlobalUser[]>> => {
  return axios.post(`${VITE_API_URL}/accounts/security/glusers/list`, {
    headers: {
      Accept: 'application/json',
    },
  });
};
