interface IParams {
  [key: string]: string | number | undefined | Date | boolean;
}

export const createQueryParams = (paramsObject: IParams) => {
  let queryParams = '?';
  if (paramsObject) {
    for (const key in paramsObject) {
      if (
        Object.prototype.hasOwnProperty.call(paramsObject, key) &&
        paramsObject[key] !== undefined
      ) {
        queryParams += `${key}=${paramsObject[key]}&`;
      }
    }
  }
  return queryParams.length > 1 ? queryParams.slice(0, -1) : '';
};
