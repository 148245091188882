import { KeyboardEvent, ReactNode, useRef } from 'react';

import { Box, Flex, IconButton, Portal } from '@chakra-ui/react';

import { IconCancel, IconClose } from '@laudus/icons';

import { KEY_ENTER, KEY_ESC } from '../../constants/keyboard';

import { ModalButton } from './ModalButton';
import { useModalProviderContext } from './ModalContext';
import { ModalSize, ModalVariant } from './types';

interface IScopedModalWindowProps {
  acceptIcon?: React.ReactElement;
  acceptButtonVariant?: string;
  cancelButtonVariant?: string;
  acceptText?: string;
  backdrop?: string;
  backgroundColor?: string;
  cancelIcon?: React.ReactElement;
  cancelText?: string;
  children?: JSX.Element | JSX.Element[] | string;
  closeIcon?: boolean;
  isOpen: boolean;
  fontSize?: string;
  title?: string;
  titlePosition?: string;
  header?: ReactNode;
  nextIcon?: React.ReactElement;
  variant?: ModalVariant;
  widthAlertButton?: string;
  heightAlertButton?: string;
  width?: string;
  minWidth?: string;
  maxWidth?: string;
  height?: string;
  minHeight?: string;
  maxHeight?: string;
  resizable?: boolean;
  size?: ModalSize;
  disabled?: boolean;
  onAccept?: () => void;
  onCancel?: () => void;
  onClose?: () => void;
}

export const ScopedModalWindow = ({
  acceptIcon,
  acceptButtonVariant = 'primary',
  cancelButtonVariant = 'outline',
  acceptText,
  cancelText,
  backdrop,
  backgroundColor,
  cancelIcon,
  children,
  closeIcon,
  isOpen,
  fontSize,
  title,
  titlePosition = 'center',
  header,
  nextIcon,
  variant,
  widthAlertButton,
  heightAlertButton,
  width: _width,
  minWidth,
  maxWidth,
  height: _height,
  minHeight,
  maxHeight,
  resizable,
  size = 'lg',
  disabled,
  onAccept,
  onCancel,
  onClose,
}: IScopedModalWindowProps) => {
  const { ref } = useModalProviderContext();

  const modalRef = useRef<HTMLDivElement>(null);
  const offset = useRef({ x: 0, y: 0 });

  const handleMouseDown = (e: React.MouseEvent) => {
    if (!modalRef.current) {
      return;
    }
    offset.current = {
      x: e.clientX - modalRef.current.offsetLeft,
      y: e.clientY - modalRef.current.offsetTop,
    };
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    if (!modalRef.current) {
      return;
    }
    modalRef.current.style.left = `${e.clientX - offset.current.x}px`;
    modalRef.current.style.top = `${e.clientY - offset.current.y}px`;
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLElement>) => {
    if (e.key === KEY_ENTER) {
      onAccept?.();
    } else if (e.key === KEY_ESC) {
      onCancel?.();
    }
  };

  if (!isOpen) {
    return <Box />;
  }

  let height = _height;
  let width = _width;
  let margin = '1.6rem';
  if (size === 'sm') {
    height = '32rem';
    width = '32rem';
    margin = '1.6rem';
  } else if (size === 'md') {
    margin = '3.2rem';
  } else if (size === 'lg') {
    height = '64rem';
    width = '80%';
    margin = '3.2rem';
  }

  return (
    <Portal containerRef={ref}>
      <Box
        position="absolute"
        top={0}
        right={0}
        bottom={0}
        left={0}
        backgroundColor={backdrop ?? 'modalBackdrop'}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        zIndex="9"
        padding="1rem 0"
      >
        <Flex
          ref={modalRef}
          position="absolute"
          flexDirection="column"
          backgroundColor={backgroundColor ?? 'modalBackground'}
          borderRadius="0.8rem"
          overflow="auto"
          resize={resizable ? 'both' : 'none'}
          minHeight={resizable && !minHeight ? '30%' : minHeight}
          maxHeight={resizable && !maxHeight ? '100%' : maxHeight}
          height={resizable && !height ? 'unset' : height}
          minWidth={resizable && !minWidth ? '40%' : minWidth}
          maxWidth={resizable && !maxWidth ? '100%' : maxWidth}
          width={resizable && !width ? 'unset' : width}
          onKeyDown={handleKeyDown}
        >
          {closeIcon && (
            <IconButton
              aria-label="close button"
              position="absolute"
              top=".2rem"
              right="0"
              width="4rem"
              height="4rem"
              background="transparent"
              color="modalClose"
              fontSize="2.4rem"
              icon={<IconClose />}
              onClick={onClose}
              variant={variant}
            />
          )}
          {title || header ? (
            <Box
              display="flex"
              justifyContent={titlePosition}
              padding={`${margin} ${margin} 0`}
              fontSize={fontSize ? fontSize : '2.4rem'}
              fontWeight="600"
              color="textPrimary"
              cursor="pointer"
              onMouseDown={handleMouseDown}
            >
              {header ?? title}
            </Box>
          ) : undefined}
          <Box
            flex={1}
            display="flex"
            flexDirection="column"
            justifyContent="flex-start"
            margin={margin}
            fontSize="1.4rem"
            overflowY="auto"
          >
            {children}
          </Box>
          {true && ((onCancel && cancelText) || (onAccept && acceptText)) ? (
            <Flex
              alignItems="center"
              display="flex"
              gridGap="2rem"
              padding={`0 ${margin} ${margin}`}
              justifyContent={!onCancel ? 'center' : 'space-between'}
            >
              {onCancel ? (
                <ModalButton
                  leftIcon={cancelIcon ?? <IconCancel />}
                  text={cancelText}
                  variant={cancelButtonVariant}
                  onClick={onCancel}
                />
              ) : undefined}
              {onAccept ? (
                <ModalButton
                  heightAlertButton={heightAlertButton}
                  leftIcon={acceptIcon ? acceptIcon : undefined}
                  rightIcon={nextIcon ? nextIcon : undefined}
                  text={acceptText}
                  variant={acceptButtonVariant}
                  widthAlertButton={widthAlertButton}
                  disabled={disabled}
                  onClick={onAccept}
                />
              ) : undefined}
            </Flex>
          ) : undefined}
        </Flex>
      </Box>
    </Portal>
  );
};
