import { AxiosResponse } from 'axios';

import { IBranch } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchBranchesListAPI = (): Promise<AxiosResponse<IBranch[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/branches/list`,
    {
      fields: ['name', 'branchId', 'fiscalId'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchBranchAPI = (branchId: number): Promise<AxiosResponse<IBranch>> =>
  axios.get(`${VITE_API_URL}/sales/branches/${branchId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createBranchAPI = (branch: IBranch): Promise<AxiosResponse<IBranch>> =>
  axios.post(`${VITE_API_URL}/sales/branches`, branch);

export const updateBranchAPI = (branch: IBranch): Promise<AxiosResponse<IBranch>> =>
  axios.put(`${VITE_API_URL}/sales/branches/${branch.branchId}`, branch);

export const deleteBranchAPI = (branchId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/branches/${branchId}`);
