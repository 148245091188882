import { forwardRef, ForwardRefRenderFunction, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { Controller } from 'react-hook-form';

import {
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import es from 'date-fns/locale/es';
import { debounce } from 'lodash';

import { IconCalendarToday } from '@laudus/icons';
import { intl } from '@laudus/intl';
import { useThemeContext } from '@laudus/shared-ui';
import { dateToLocalISOString, isValidDate } from '@laudus/shared-utils';

import { FormFieldProps } from '../types';

import { FormLabel } from './FormLabel';

registerLocale('es', es);

const CustomInputWithRef: ForwardRefRenderFunction<any, any> = (
  { value: dateValue, onClick, onChange, disabled },
  ref,
) => {
  const { colors = {} } = useThemeContext();
  const [validDate, setValidDate] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const handleChange = (e: any) => {
    setValidDate(e.target.value);
    if (isValidDate(e.target.value)) {
      if (onChange) {
        debounce(() => onChange(e), 3000);
      }
    }
  };

  useEffect(() => {
    setValidDate(dateValue);
  }, [dateValue]);

  const handleOnFocus = () => {
    setIsFocused(true);
    if (!disabled) {
      onClick();
    }
  };
  const handleOnBlur = () => setIsFocused(false);

  const { inputBackground, inputBorder } = colors;

  return (
    <InputGroup ref={ref}>
      <Input
        cursor="pointer"
        disabled={disabled}
        onBlur={handleOnBlur}
        onChange={handleChange}
        onFocus={handleOnFocus}
        value={validDate}
        backgroundColor={inputBackground}
        border={`0.05rem solid ${inputBorder}`}
      />
      <InputRightElement className="icon-input" onClick={handleOnFocus}>
        <IconCalendarToday
          color={isFocused ? 'primary500' : 'neutral500'}
          cursor="pointer"
          onClick={() => (!disabled ? onClick : undefined)}
        />
      </InputRightElement>
    </InputGroup>
  );
};

const CustomInput = forwardRef(CustomInputWithRef);

export interface IDateInputField<T> extends FormFieldProps<T> {
  onChange?: (value: string | null) => void;
}

export function DateInputField<T>({
  label,
  disabled,
  autoFocus = false,
  defaultValueOnEmtpyValue,
  showTime = true,
  labelVariant = undefined,
  withPortal = false,
  tooltipText,
  name,
  control,
  rules,
  errors,
  onChange,
}: IDateInputField<T>) {
  return (
    <Controller
      control={control}
      rules={rules}
      name={name}
      render={({ field: { onChange: _onChange, onBlur, value } }) => {
        // TODO Refactor this logic into a component
        // eslint-disable-next-line react-hooks/rules-of-hooks
        useEffect(() => {
          if (!value && defaultValueOnEmtpyValue) {
            handleChange(defaultValueOnEmtpyValue);
          }
        }, []);

        const handleChange = (date: Date | null) => {
          const value = date ? dateToLocalISOString(date) : null;
          _onChange(value);
          onChange?.(value);
        };
        // TODO Refactor this logic into a component

        return (
          <FormControl isInvalid={errors && name ? !!errors[name] : undefined}>
            {label && <FormLabel label={label} tooltipText={tooltipText} variant={labelVariant} />}
            <div className="input-container" id="portal-id" style={{ position: 'relative' }}>
              {/* @ts-expect-error todo fix - 'DatePicker' cannot be used as a JSX component. */}
              <DatePicker
                autoFocus={autoFocus}
                customInput={<CustomInput disabled={disabled} />}
                dateFormat={showTime ? 'dd-MM-yyyy|HH:mm' : 'dd-MM-yyyy'}
                disabled={disabled}
                dropdownMode="select"
                fixedHeight={false}
                calendarClassName="react-datepicker"
                locale="es"
                peekNextMonth
                placeholderText="Seleccionar"
                portalId={withPortal ? 'portal-id' : 'body'}
                selected={value ? new Date(value) : undefined}
                showMonthDropdown
                showTimeSelect={showTime}
                showYearDropdown
                timeCaption="Hora"
                timeFormat="HH:mm"
                withPortal={withPortal}
                onChange={handleChange}
              />
            </div>
            <FormErrorMessage>
              {errors &&
                name &&
                errors[name]?.type === 'required' &&
                intl.formatMessage({ id: 'form.error.required' })}
            </FormErrorMessage>
          </FormControl>
        );
      }}
    />
  );
}
