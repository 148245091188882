import { createSelector } from '@reduxjs/toolkit';

import { AppState, IBalanceSheetFilterState } from '../..';

export const getBalanceSheetFiltersSlice = (state: AppState): IBalanceSheetFilterState =>
  state.balanceSheetFilter;

export const getBalanceSheetFilter = createSelector(
  [getBalanceSheetFiltersSlice],
  (state) => state.balanceSheetFilter,
);

export const getBalanceSheetTagsFilter = createSelector(
  [getBalanceSheetFiltersSlice],
  (state) => state.balanceSheetTagsFilter,
);

export const getBalanceSheetIdFilter = createSelector(
  [getBalanceSheetFiltersSlice],
  (state) => state.balanceSheetIdFilter,
);
