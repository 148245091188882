import { createSelector } from '@reduxjs/toolkit';

import { AppState, IUnionsState } from '../..';

export const getUnionsSlice = (state: AppState): IUnionsState => state.unions;

export const getUnionsList = createSelector([getUnionsSlice], (state) => state.list);

export const getUnion = createSelector([getUnionsSlice], (state) => state.current);

export const getUnionDraft = createSelector([getUnionsSlice], (state) => state.draft);
