import { createReducer } from '@reduxjs/toolkit';

import { CodeCategories } from './constants';

export interface ICodeCategory {
  codeCategoryId?: number;
  description?: string;
}

export interface ICodeCategoriesState {
  list: ICodeCategory[];
}

export const initialCodeCategoriesState: ICodeCategoriesState = {
  list: Object.values(CodeCategories).map((category) => ({
    codeCategoryId: category.id,
    description: category.name,
  })),
};

export const codeCategoriesReducer = createReducer(initialCodeCategoriesState, (builder) => {
  builder.addDefaultCase((state) => state);
});
