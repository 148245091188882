import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { ISupplierInvoice } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

const entityPrefix = 'SUPPLIER_STATISTICS';

export const clearSupplierStatistics = createAction(`${entityPrefix}/CLEAR`);

export const setSupplierStatistics = createAction<Record<number, ISupplierInvoice[]>>(
  `${entityPrefix}/SET_LIST`,
);

export const updateSupplierStatistics = createAction<Record<number, ISupplierInvoice[]>>(
  `${entityPrefix}/UPDATE_LIST`,
);

export const fetchSupplierInvoicesList = createAsyncThunk<void, number, AppThunkConfig>(
  `${entityPrefix}/FETCH_LIST`,
  async (supplierId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('supplier-invoices'));
    try {
      const { data } = await api.suppliers.fetchSuppliersInvoicesListFromAPI(supplierId);
      dispatch(setSupplierStatistics({ [supplierId]: Array.isArray(data) ? data : [] }));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'supplier',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('supplier-invoices'));
    }
  },
);

export const fetchSuppliersInvoicesDetailList = createAsyncThunk<void, number, AppThunkConfig>(
  `${entityPrefix}/FETCH_LIST`,
  async (supplierId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('supplier-invoices'));
    try {
      const { data } = await api.suppliers.fetchSuppliersInvoicesDetailListFromAPI(supplierId);
      dispatch(setSupplierStatistics({ [supplierId]: Array.isArray(data) ? data : [] }));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'supplier',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('supplier-invoices'));
    }
  },
);

export const fetchSupplierPaymentsList = createAsyncThunk<void, number, AppThunkConfig>(
  `${entityPrefix}/FETCH_LIST`,
  async (supplierId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('supplier-invoices'));
    try {
      const { data } = await api.suppliers.fetchSuppliersPaymentsListFromAPI(supplierId);
      dispatch(setSupplierStatistics({ [supplierId]: Array.isArray(data) ? data : [] }));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'supplier',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('supplier-invoices'));
    }
  },
);

// endpoint not ready
export const fetchSupplierPendingInvoicesList = createAsyncThunk<void, number, AppThunkConfig>(
  `${entityPrefix}/FETCH_LIST`,
  async (supplierId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('supplier-invoices'));
    try {
      const { data } = await api.suppliers.fetchSuppliersPendingInvoicesFromAPI(supplierId);
      dispatch(setSupplierStatistics({ [supplierId]: Array.isArray(data) ? data : [] }));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'supplier',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('supplier-invoices'));
    }
  },
);

export const fetchSupplierPurchasesOrdersList = createAsyncThunk<void, number, AppThunkConfig>(
  `${entityPrefix}/FETCH_LIST`,
  async (supplierId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('supplier-invoices'));
    try {
      const { data } = await api.suppliers.fetchSuppliersPurchasesOrdersListFromAPI(supplierId);
      dispatch(setSupplierStatistics({ [supplierId]: Array.isArray(data) ? data : [] }));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'supplier',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('supplier-invoices'));
    }
  },
);
