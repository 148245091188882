import { AxiosResponse } from 'axios';

import { ISalesQuote, ISalesQuoteList, QuantityDelivered } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchSalesQuotesListFromAPI = (): Promise<AxiosResponse<ISalesQuoteList>> =>
  axios.post(
    `${VITE_API_URL}/sales/quotes/list`,
    {
      fields: ['salesQuoteId', 'customer.name', 'issuedDate', 'nullDoc'],
      orderBy: [
        {
          field: 'salesQuoteId',
          direction: 'DESC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchSalesQuoteFromAPI = (
  salesQuoteId: number,
): Promise<AxiosResponse<ISalesQuote>> => {
  return axios.get(`${VITE_API_URL}/sales/quotes/${salesQuoteId}`, {
    headers: {
      Accept: 'application/json',
    },
  });
};

export const createSalesQuoteFromAPI = (
  salesQuote: ISalesQuote,
): Promise<AxiosResponse<ISalesQuote>> => axios.post(`${VITE_API_URL}/sales/quotes`, salesQuote);

export const updateSalesQuoteFromAPI = (
  salesQuote: ISalesQuote,
): Promise<AxiosResponse<ISalesQuote>> =>
  axios.put(`${VITE_API_URL}/sales/quotes/${salesQuote.salesQuoteId}`, salesQuote);

export const deleteSalesQuoteFromAPI = async (salesQuoteId: number): Promise<AxiosResponse> => {
  // return axios.delete(`${VITE_API_URL}/sales/quotes/${salesQuoteId}`);

  // Temporary solution to cancel sales quote
  const { data: quote } = await fetchSalesQuoteFromAPI(salesQuoteId);

  const duplicatedQuote = { ...quote };
  duplicatedQuote.nullDoc = true;

  return updateSalesQuoteFromAPI(duplicatedQuote);
};

export const getQuantityDeliveredBySalesQuoteFromAPI = (
  salesQuoteId: number,
): Promise<AxiosResponse<QuantityDelivered>> => {
  return axios.get(`${VITE_API_URL}/sales/quotes/${salesQuoteId}/quantityDelivered`, {
    headers: {
      Accept: 'application/json',
    },
  });
};

export const fetchSalesQuotePDFFromAPI = async (
  salesQuoteId: number,
  numberOfCopies: number,
  // download = false,
): Promise<AxiosResponse> =>
  axios.get(
    `${VITE_API_URL}/sales/quotes/${salesQuoteId}/pdf?contentDisposition=attachment&numberOfCopies=${numberOfCopies}`,
    // TODO: check if necesary
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
      },
    },
  );

export async function fetchSalesQuotePDFUrl({
  salesQuote,
  numberOfCopies,
}: {
  salesQuote: ISalesQuote;
  numberOfCopies: number;
}): Promise<string> {
  const { data } = await fetchSalesQuotePDFFromAPI(salesQuote.salesQuoteId!, numberOfCopies);
  const blob = new Blob([new Uint8Array(data)], { type: 'application/pdf' });
  const urlBlob = URL.createObjectURL(blob);

  return urlBlob;
}
