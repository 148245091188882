export enum ReceiptTypesCodes {
  CASH = 'E',
  DEBIT_CARD = 'D',
  CREDIT_CARD = 'T',
  TRANSFER = 'I',
  CHECK = 'C',
  OTHERS = 'O',
}

export enum ReceiptTypesLabels {
  CASH = 'Efectivo',
  DEBIT_CARD = 'Tarjeta de débito',
  CREDIT_CARD = 'Tarjeta de crédito',
  TRANSFER = 'Transferencia / Ingreso en cuenta',
  CHECK = 'Cheque',
  OTHERS = 'Otros',
}
