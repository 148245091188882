import { createSelector } from '@reduxjs/toolkit';

import { roundDecimals, transformsAndCalculatePurchasesOrders } from '@laudus/shared-utils';

import { AppState, IPurchaseOrderState } from '../../index';
import { getMainCurrency } from '../currencies';

export const getPurchaseOrderSlice = (state: AppState): IPurchaseOrderState => state.purchaseOrders;

export const getPurchasesOrdersList = createSelector(
  [getPurchaseOrderSlice],
  (state) => state.list,
);

export const getPurchaseOrder = createSelector(
  [getPurchaseOrderSlice, (appState) => getMainCurrency(appState)],
  (state, { mainCurrencyDecimals }) => {
    // This prepare the purchaseOrder document to be sent to the backend once the draft has been completed
    const transformedItems = transformsAndCalculatePurchasesOrders(
      state.current.items,
      mainCurrencyDecimals,
    );
    // Calculate the sumNet
    let sumNet = 0;
    if (state.current.items?.length) {
      state.current.items.forEach((item) => {
        sumNet += roundDecimals(item.unitCost * item.quantity, mainCurrencyDecimals);
      });
    }

    return {
      ...state.current,
      items: transformedItems,
      isApprovedAtLevel1: state.current.approvedAtLevel1 ? true : false,
      isApprovedAtLevel2: state.current.approvedAtLevel2 ? true : false,
      isApprovedAtLevel3: state.current.approvedAtLevel3 ? true : false,
      sumNet: sumNet,
    };
  },
);

export const getPurchaseOrderDraft = createSelector(
  [getPurchaseOrderSlice, (appState) => getMainCurrency(appState)],
  (state, { mainCurrencyDecimals }) => {
    // This prepare the purchaseOrder document to be sent to the backend once the draft has been completed
    const transformedItems = transformsAndCalculatePurchasesOrders(
      state.draft.items,
      mainCurrencyDecimals,
    );
    // Calculate the sumNet
    let sumNet = 0;
    if (state.current.items?.length) {
      state.current.items.forEach((item) => {
        sumNet += roundDecimals(item.unitCost * item.quantity, mainCurrencyDecimals);
      });
    }
    return {
      ...state.draft,
      items: transformedItems,
      sumNet: sumNet,
    };
  },
);
