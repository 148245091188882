import { AxiosResponse } from 'axios';

import { IPurchaseWaybill } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

type IPurchaseWaybillsFilters = {
  orderBy: { field: string; direction: 'ASC' | 'DESC' }[];
  fields: string[];
};

const defaultFilters: IPurchaseWaybillsFilters = {
  orderBy: [
    {
      field: 'issuedDate',
      direction: 'DESC',
    },
  ],
  fields: [
    'purchaseWaybillId',
    'supplier.name',
    'supplier.supplierId',
    'docType.name',
    'docNumber',
    'issuedDate',
  ],
};

export const fetchPurchaseWaybillListFromAPI = (
  filters: Partial<IPurchaseWaybillsFilters>,
): Promise<AxiosResponse<IPurchaseWaybill[]>> =>
  axios.post(
    `${VITE_API_URL}/purchases/waybills/list`,
    { ...defaultFilters, ...filters },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchPurchaseWaybillFromAPI = (
  purchaseWaybillId: number,
): Promise<AxiosResponse<IPurchaseWaybill>> =>
  axios.get(`${VITE_API_URL}/purchases/waybills/${purchaseWaybillId}`);

export const createPurchaseWaybillFromAPI = (
  waybill: IPurchaseWaybill,
): Promise<AxiosResponse<IPurchaseWaybill>> =>
  axios.post(`${VITE_API_URL}/purchases/waybills`, waybill);

export const updatePurchaseWaybillFromAPI = (
  waybill: IPurchaseWaybill,
): Promise<AxiosResponse<IPurchaseWaybill>> =>
  axios.put(`${VITE_API_URL}/purchases/waybills/${waybill.purchaseWaybillId}`, waybill);

export const deletePurchaseWaybillFromAPI = (purchaseWaybillId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/purchases/waybills/${purchaseWaybillId}`);
