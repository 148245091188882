import { createSelector } from '@reduxjs/toolkit';

import { AppState, IInventoryState } from '../..';

export const getInventoriesSlice = (state: AppState): IInventoryState => state.inventories;

export const getInventoryList = createSelector([getInventoriesSlice], (state) => state.list);

export const getInventory = createSelector([getInventoriesSlice], (state) => state.current);

export const getInventoryDraft = createSelector([getInventoriesSlice], (state) => state.draft);
