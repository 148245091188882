import { AxiosResponse } from 'axios';

import { ICurrency } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchCurrencyListFromAPI = (): Promise<AxiosResponse<ICurrency[]>> =>
  axios.post(
    `${VITE_API_URL}/accounting/currencies/list`,
    {
      fields: ['currencyId', 'code', 'name', 'symbol', 'nameDTE', 'codeDTE', 'decimals'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchCurrencyFromAPI = (currencyId: string): Promise<AxiosResponse<ICurrency>> =>
  axios.get(`${VITE_API_URL}/accounting/currencies/${currencyId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createCurrencyFromAPI = (currency: ICurrency): Promise<AxiosResponse<ICurrency>> =>
  axios.post(`${VITE_API_URL}/accounting/currencies`, currency);

export const updateCurrencyFromAPI = (currency: ICurrency): Promise<AxiosResponse<ICurrency>> =>
  axios.put(`${VITE_API_URL}/accounting/currencies/${currency.currencyId}`, currency);

export const deleteCurrencyFromAPI = (currencyId: string): Promise<AxiosResponse<ICurrency>> =>
  axios.delete(`${VITE_API_URL}/accounting/currencies/${currencyId}`);
