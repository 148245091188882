import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { InfraServices } from '@laudus/services';
import { IEmail } from '@laudus/types';

import { showAlert } from '../alerts';

export const setSendEmailLoading = createAction<boolean>('SYSTEM/SET_SEND_EMAIL_LOADING');

export const createEmail = createAsyncThunk<void, IEmail, { extra: InfraServices }>(
  'SYSTEM/CREATE_EMAIL',
  async (email, ThunkAPI) => {
    const { extra, dispatch } = ThunkAPI;
    const { api } = extra;

    dispatch(setSendEmailLoading(true));
    try {
      const { data } = await api.systems.createEmail(email);
      dispatch(
        showAlert({
          type: 'success',
          title: intl.formatMessage({ id: 'system.email.success.create' }),
          message: intl.formatMessage({
            id: 'system.email.success.message',
          }),
        }),
      );
    } catch (error) {
      dispatch(
        showAlert({
          type: 'error',
          title: intl.formatMessage({ id: 'system.email.error.create' }),
          message: intl.formatMessage({
            id: 'system.email.warning.errorMessage',
          }),
        }),
      );
    } finally {
      dispatch(setSendEmailLoading(false));
    }
  },
);
