import { createReducer } from '@reduxjs/toolkit';

import {
  clearMenuTab,
  endProgress,
  endRequest,
  endRequestInTab,
  setAccordion,
  setFocusedCell,
  setMenuTab,
  setTableState,
  startProgress,
  startRequest,
  startRequestInTab,
} from './actions';

export interface ITabs {
  branch?: number;
  customers?: number;
  daily?: number;
  employees?: number;
  groups?: number;
  invoices?: number;
  salesWaybills?: number;
  salesOrders?: number;
  salesQuotes?: number;
  salesTickets?: number;
  suppliers?: number;
  options?: number;
  products?: number;
  user?: number;
  banks?: number;
  balanceSheet?: number;
  payroll: number;
  previsionalInstitutions?: number;
  purchaseOrders?: number;
  purchaseInvoices?: number;
}

export interface IAccordion {
  invoices?: boolean;
  salesWaybills?: boolean;
  salesOrders?: boolean;
  salesQuotes?: boolean;
  purchaseOrders?: boolean;
}

export interface IFocusedCell {
  [key: string]: { rowIndex: number; colKey: string };
}

export interface IGlobalTableColumnSort {
  desc: boolean;
  id: string;
}

export interface IGlobalTableColumnPinningState {
  left: string[] | undefined;
  right: string[] | undefined;
}

export interface IGlobalTableState {
  columnOrder: string[];
  columnPinning: IGlobalTableColumnPinningState;
  columnSizing: Record<string, number>;
  columnSorting: IGlobalTableColumnSort[];
}

export type IGlobalTable = Record<string, IGlobalTableState>;

export interface IGlobalState {
  lastRequestStartedAt: number;
  // loading: boolean;
  loading: string[];
  loadingTabs: Record<string, boolean>;
  tab: ITabs;
  table: IGlobalTable;
  accordion: IAccordion;
  focusedCell: IFocusedCell;
  lastProgress: number;
  isOpen: boolean;
  inProgress: boolean;
}

export const initialGlobalState: IGlobalState = {
  lastRequestStartedAt: 0,
  loading: [],
  // loading: false,
  loadingTabs: {},
  tab: {
    balanceSheet: 0,
    branch: 0,
    customers: 0,
    daily: 0,
    employees: 0,
    groups: 0,
    invoices: 0,
    salesWaybills: 0,
    salesOrders: 0,
    salesQuotes: 0,
    suppliers: 0,
    options: 0,
    products: 0,
    user: 0,
    payroll: 0,
    purchaseOrders: 0,
    purchaseInvoices: 0,
  },
  table: {},
  accordion: {
    invoices: false,
    salesWaybills: false,
    salesOrders: false,
    salesQuotes: false,
    purchaseOrders: false,
  },
  focusedCell: {
    invoices: {
      rowIndex: 0,
      colKey: '',
    },
    purchaseOrders: {
      rowIndex: 0,
      colKey: '',
    },
  },
  lastProgress: 0,
  isOpen: false,
  inProgress: false,
};

export const globalReducer = createReducer(initialGlobalState, (builder) => {
  builder
    // .addCase(endRequest, (state) => {
    //   return { ...state, loading: , lastRequestStartedAt: 0 };
    // })
    // .addCase(startRequest, (state) => {
    //   return { ...state, loading: true, lastRequestStartedAt: Date.now() };
    // })
    .addCase(startRequest, (state, action) => {
      state.loading = [...state.loading, action.payload];
    })
    .addCase(endRequest, (state, action) => {
      state.loading = state.loading.filter((loader) => loader !== action.payload);
    })
    .addCase(startRequestInTab, (state, action) => {
      return {
        ...state,
        loadingTabs: { ...state.loadingTabs, [action.payload]: true },
      };
    })
    .addCase(endRequestInTab, (state, action) => {
      return {
        ...state,
        loadingTabs: { ...state.loadingTabs, [action.payload]: false },
      };
    })
    .addCase(clearMenuTab, (state) => {
      return { ...state, tab: initialGlobalState.tab };
    })
    .addCase(setMenuTab, (state, action) => {
      return { ...state, tab: { ...state.tab, ...action.payload } };
    })
    .addCase(setAccordion, (state, action) => {
      return { ...state, accordion: { ...state.accordion, ...action.payload } };
    })
    .addCase(setFocusedCell, (state, action) => {
      return {
        ...state,
        focusedCell: action.payload,
      };
    })
    .addCase(startProgress, (state) => {
      return {
        ...state,
        isOpen: true,
        inProgress: true,
        lastProgress: Date.now(),
      };
    })
    .addCase(endProgress, (state) => {
      return {
        ...state,
        inProgress: false,
        lastProgress: 0,
      };
    })
    .addCase(setTableState, (state, action) => ({
      ...state,
      table: { ...state.table, [action.payload.tableId]: action.payload.state },
    }))
    .addDefaultCase((state) => state);
});
