import { createSelector } from '@reduxjs/toolkit';

import { AppState, ICustomerCategoryState } from '../..';

export const getCustomerCategoriesSlice = (state: AppState): ICustomerCategoryState =>
  state.customerCategories;

export const getCustomerCategoryList = createSelector(
  [getCustomerCategoriesSlice],
  (state) => state.list,
);

export const getCustomerCategory = createSelector(
  [getCustomerCategoriesSlice],
  (state) => state.current,
);

export const getCustomerCategoryDraft = createSelector(
  [getCustomerCategoriesSlice],
  (state) => state.draft,
);

export const getCustomerCategoryFormState = createSelector(
  [getCustomerCategoriesSlice],
  (state) => state.formState,
);
