import { AxiosResponse } from 'axios';

import { IEmail, ISettingsDTECertificate, ISettingsDTECertificateInfo } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const createEmail = (data: IEmail): Promise<AxiosResponse<any>> =>
  axios.post(`${VITE_API_URL}/system/emails/send`, data, {
    headers: {
      'Accept': 'application/json',
      'Content-Type': `application/json`,
      'X-Source': 'LaudusApp',
    },
  });

export const fetchSettingsDTECertificateFromAPI = (): Promise<
  AxiosResponse<ISettingsDTECertificateInfo>
> =>
  axios.get(`${VITE_API_URL}/system/settings/certificates/DTE/info`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createSettingsDTECertificatesFromAPI = (
  uploadCertificate: ISettingsDTECertificate,
): Promise<AxiosResponse<ISettingsDTECertificateInfo>> =>
  axios.post(`${VITE_API_URL}/system/settings/certificates/DTE`, uploadCertificate);
