import { AxiosResponse } from 'axios';

import { ITerm } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchTermsListFromAPI = (): Promise<AxiosResponse<ITerm[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/terms/list`,
    {
      fields: ['termId', 'name', 'notes'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchTermFromAPI = (termId: string | number): Promise<AxiosResponse<ITerm>> =>
  axios.get(`${VITE_API_URL}/sales/terms/${termId}`);

export const createTermFromAPI = (term: ITerm): Promise<AxiosResponse<ITerm>> =>
  axios.post(`${VITE_API_URL}/sales/terms`, term);

export const updateTermFromAPI = (term: ITerm): Promise<AxiosResponse<ITerm>> =>
  axios.put(`${VITE_API_URL}/sales/terms/${term.termId}`, term);

export const deleteTermFromAPI = (termId: string | number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/terms/${termId}`);
