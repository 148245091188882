import { intl } from '@laudus/intl';

// This function calculates gets two dates and returns the differences in days.
// If one or more params are undefined or null it returns zero
export const calculateDatesDifference = (
  firstDate?: Date | string | null,
  secondDate?: Date | string | null,
) => {
  if (firstDate && firstDate !== null && secondDate && secondDate !== null) {
    return new Date(secondDate).getTime() - new Date(firstDate).getTime() >= 0
      ? (new Date(secondDate).getTime() - new Date(firstDate).getTime()) / (1000 * 3600 * 24)
      : 0;
  }
  return 0;
};

// This function calculates the difference in days between two dates.
// If the second date is not provided it calculates the difference between the first date and the current date.
export const calculateAbsenceDatesDifference = (
  dateFrom: Date | string,
  dateTo?: Date | string | null,
) => {
  if (!dateTo) {
    return Math.floor((new Date().getTime() - new Date(dateFrom).getTime()) / (1000 * 3600 * 24));
  }
  return new Date(dateTo).getTime() - new Date(dateFrom).getTime() >= 0
    ? Math.floor((new Date(dateTo).getTime() - new Date(dateFrom).getTime()) / (1000 * 3600 * 24))
    : 0;
};

// This function giving two dates returns a string with the differences in days with hours and minutes.
// If one or more params are undefined or null it returns an empty string
export const getStringDateDifference = (
  firstDate: Date | string | null,
  secondDate: Date | string | null,
) => {
  if (firstDate && secondDate) {
    const timeDiff = Math.abs(new Date(secondDate).getTime() - new Date(firstDate).getTime());

    const days = Math.floor(timeDiff / (24 * 60 * 60 * 1000));
    const hours = Math.floor((timeDiff % (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));
    const minutes = Math.floor((timeDiff % (60 * 60 * 1000)) / (60 * 1000));

    const dayResult = intl.formatMessage({ id: 'lib.days' }, { days });
    const hourResult = hours.toString().padStart(2, '0');
    const minuteResult = minutes.toString().padStart(2, '0');

    return `${dayResult} ${
      hourResult === '00' && minuteResult === '00' ? '' : `${hourResult}:${minuteResult}`
    }`;
  }
  return '';
};
