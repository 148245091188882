import { createSelector } from '@reduxjs/toolkit';

import { allowedTypes, calculateAbsenceDatesDifference } from '@laudus/shared-utils';

import { AppState, IEmployeeAbsenceState } from '../..';

export const getEmployeeAbsenceSlice = (state: AppState): IEmployeeAbsenceState =>
  state.employeeAbsences;

export const getEmployeesAbsencesList = createSelector(
  [getEmployeeAbsenceSlice],
  (state) => state.list,
);

export const getEmployeeAbsence = createSelector(
  [getEmployeeAbsenceSlice],
  (state) => state.current,
);

export const getEmployeeAbsenceDraft = createSelector(
  [getEmployeeAbsenceSlice],
  (state) => state.draft,
);

export const getAbsencesButtonIndex = createSelector(
  [getEmployeeAbsenceSlice],
  (state) => state.absencesButtonIndex,
);

export const getEmployeeAbsenceTableData = createSelector([getEmployeesAbsencesList], (state) =>
  state.map((absence) => ({
    ...absence,
    typeName: allowedTypes.find((type) => type.value === absence.type)?.label,
  })),
);

export const getAbsencesVacationsTableData = createSelector([getEmployeesAbsencesList], (state) =>
  state.map((absence) => {
    const days = calculateAbsenceDatesDifference(absence.dateFrom, absence.dateTo);
    return {
      ...absence,
      //This TODO Comment was herited from the original code that was in the component
      //TODO: To make this calculation I need to know the holidays of the year of the entity nonWorkingDays
      days,
    };
  }),
);

export const getAbsencesVacationsSummaryTableData = createSelector(
  [getEmployeesAbsencesList],
  (state) =>
    state.map((absence) => ({
      ...absence,
      // TODO: Add the computed values here
      // totalDays,
      // usedDays,
      // availableDays,
    })),
);
