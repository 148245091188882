import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const Accordion: ComponentMultiStyleConfig = {
  parts: ['button', 'panel', 'accordion', 'item'],
  baseStyle: {
    button: {
      color: 'white',
      w: '100%',
      height: '35px',
      display: 'flex',
      border: 'none',
      boxShadow: 'none',
      borderRadius: '0.4rem',
      _disabled: {
        opacity: '1',
        cursor: 'pointer',
      },
      _active: {
        bg: 'primary500',
      },
      _focus: {
        bg: 'primary500',
        boxShadow: 'none',
      },
      _hover: {
        bg: 'secondary300',
        paddingBottom: '5px',
        opacity: '0.5',
      },
    },
  },
  variants: {
    form: {
      button: {
        bg: 'secondary500',
        border: '1px solid',
        borderColor: 'secondary500',
        alignItems: 'center',
        fontSize: '14px',
        fontWeight: '600',
        boxSizing: 'border-box',
        minWidth: '13rem',
        _hover: {
          bg: 'white',
          color: 'secondary500',
        },
        _active: {
          bg: 'white',
        },
        _focus: {
          color: 'white',
        },
      },
    },
    statistics: {
      button: {
        _hover: {
          bg: 'transparentWhite10',
          color: 'white',
        },
      },
    },
  },
  defaultProps: {
    variant: 'statistics',
  },
};
