import { createSelector } from '@reduxjs/toolkit';

import { AppState, IInputsState } from '../..';

export const getInputsSlice = (state: AppState): IInputsState => state.inputs;

export const getInputsList = createSelector([getInputsSlice], (state) => state.list);

export const getInput = createSelector([getInputsSlice], (state) => state.current);

export const getInputDraft = createSelector([getInputsSlice], (state) => state.draft);
