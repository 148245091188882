import { Accordion } from './Accordion';
import { Button } from './Button';
import { Checkbox } from './Checkbox';
import { Drawer } from './Drawer';
import { FormLabel } from './FormLabel';
import { Input } from './Input';
import { InputGroup } from './InputGroup';
import { Modal } from './Modal';
import { NumberInput } from './NumberInput';
import { Popover } from './Popover';
import { Radio } from './Radio';
import { Select } from './Select';
import { Switch } from './Switch';
import { Tabs } from './Tabs';
import { Text } from './Text';
import { Textarea } from './Textarea';
import { Tooltip } from './Tooltip';

export const components = {
  Accordion,
  Button,
  Checkbox,
  Drawer,
  FormLabel,
  Input,
  InputGroup,
  Modal,
  NumberInput,
  Popover,
  Radio,
  Select,
  Switch,
  Tabs,
  Text,
  Textarea,
  Tooltip,
};
