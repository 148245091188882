import { createReducer } from '@reduxjs/toolkit';

import { IBank } from '@laudus/types';

import {
  clearBank,
  clearBankDraft,
  duplicateBank,
  removeBankFromList,
  setBank,
  setBankDraft,
  setBankDraftValues,
  setBanksList,
  updateBanksList,
} from './actions';

export const BANKS_TAB_ID = 'bank';
export interface IBanksState {
  current: IBank;
  draft: IBank;
  list: IBank[];
}

export const BANK_EMPTY: IBank = {
  bankId: undefined,
  name: '',
  account: {
    accountId: 0,
    accountNumber: '',
    name: '',
  },
  currencyCode: '',
  debitsReconciliationMethod: '',
  creditsReconciliationMethod: '',
  lastPrintedCheckNumber: '',
  statement_dateColumn: 0,
  statement_descriptionColumn: 0,
  statement_documentColumn: 0,
  statement_debitColumn: 0,
  statement_creditColumn: 0,
  statement_delimiter: '',
  statement_separator: '',
  notes: '',
  createBy: {
    user: '',
    name: '',
  },
  createdAt: '',
  modifiedBy: {
    userId: '',
    name: '',
  },
  modified: '',
};

export const initialBanksState: IBanksState = {
  current: BANK_EMPTY,
  draft: BANK_EMPTY,
  list: [],
};

export const banksReducer = createReducer(initialBanksState, (builder) => {
  builder
    .addCase(clearBank, (state) => {
      return { ...state, current: BANK_EMPTY };
    })
    .addCase(clearBankDraft, (state) => {
      return { ...state, draft: BANK_EMPTY };
    })
    .addCase(setBank, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setBankDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setBankDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setBanksList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateBanksList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.bankId !== action.payload.bankId), action.payload],
      };
    })
    .addCase(removeBankFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.bankId !== action.payload),
      };
    })
    .addCase(duplicateBank, (state, action) => {
      const { bankId, ...duplicatedBank } = action.payload;
      return { ...state, draft: duplicatedBank };
    })
    .addDefaultCase((state) => state);
});
