import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { ICustomerAddress } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Customers action creators
export const clearCustomerAddress = createAction('CUSTOMER_ADDRESSES/CLEAR');

export const clearCustomerAddressDraft = createAction('CUSTOMER_ADDRESSES/CLEAR_DRAFT');

export const setCustomerAddress = createAction<ICustomerAddress>('CUSTOMER_ADDRESSES/SET');

export const setCustomerAddressDraft = createAction<ICustomerAddress>(
  'CUSTOMER_ADDRESSES/SET_DRAFT',
);

export const setCustomerAddressDraftValues = createAction<Partial<ICustomerAddress>>(
  'CUSTOMER_ADDRESSES/SET_DRAFT_VALUE',
);

export const setCustomerAddressesList = createAction<Record<number, ICustomerAddress[]>>(
  'CUSTOMER_ADDRESSES/SET_LIST',
);

export const updateCustomerAddressList = createAction<Record<number, ICustomerAddress[]>>(
  'CUSTOMER_ADDRESSES/UPDATE_LIST',
);

export const removeCustomerAddressFromList = createAction<string | number>(
  'CUSTOMER_ADDRESSES/REMOVE_FROM_LIST',
);

interface ICustomerAddressesListParams {
  customerId: number;
  includeBillingAndGeneralAddresses?: boolean;
  silent?: boolean;
}

export const fetchCustomerAddressesList = createAsyncThunk<
  void,
  ICustomerAddressesListParams,
  AppThunkConfig
>(
  'CUSTOMER_ADDRESSES/FETCH_LIST',
  async ({ customerId, includeBillingAndGeneralAddresses, silent = false }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    if (!silent) {
      dispatch(startRequest('customer-addressess'));
    }

    try {
      const { data } = await api.customerAddressess.fetchCustomerAddressesListAPI({
        customerId,
        includeBillingAndGeneralAddresses,
      });
      dispatch(
        setCustomerAddressesList({
          [customerId]: Array.isArray(data) ? data : [],
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'customerAddresses',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('customer-addressess'));
      }
    }
  },
);

export interface IFetchCustomerAddressesParams {
  customerId: number;
  addressId: number;
}

export const fetchCustomerAddress = createAsyncThunk<
  void,
  IFetchCustomerAddressesParams,
  AppThunkConfig
>('CUSTOMER_ADDRESSES/FETCH', async ({ customerId, addressId }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('customer-addressess'));
  try {
    const { data } = await api.customerAddressess.fetchCustomerAddressAPI(customerId, addressId);
    dispatch(setCustomerAddressDraft(data));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'customerAddresses',
        action: 'read',
      }),
    );
  } finally {
    dispatch(endRequest('customer-addressess'));
  }
});

export interface IUpdateCustomerAddressesParams {
  customerId: number;
  customerAddress: ICustomerAddress;
}

export const updateCustomerAddress = createAsyncThunk<
  void,
  IUpdateCustomerAddressesParams,
  AppThunkConfig
>('CUSTOMER_ADDRESSES/FETCH', async ({ customerId, customerAddress }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('customer-addressess'));
  try {
    const { data } = await api.customerAddressess.updateCustomerAddressAPI(
      customerId,
      customerAddress,
    );
    dispatch(setCustomerAddress(data));
    //dispatch(updateCustomerAddressList({ [customerId]: [data] }));
    //TODO: make updateCustomerContactList works, now overwrites with one value and not keep others records
    //it must update only the item and keep all others items of a customer
    //this behavior must be here but by the moment i refresh list calling API here better than in form
    dispatch(fetchCustomerAddressesList({ customerId }));
    dispatch(
      showToastAlert({
        title: intl.formatMessage({
          id: 'customerAddresses.successToast.save',
        }),
        message: '',
        type: 'success',
        isToast: true,
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'customerAddresses',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('customer-addressess'));
  }
});

export interface ICreateCustomerAddressParams {
  customerId: number;
  customerAddress: ICustomerAddress;
}

export const createCustomerAddress = createAsyncThunk<
  void,
  ICreateCustomerAddressParams,
  AppThunkConfig
>('CUSTOMER_ADDRESSES/FETCH', async ({ customerId, customerAddress }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('customer-addressess'));
  try {
    const { data } = await api.customerAddressess.createCustomerAddressAPI(
      customerId,
      customerAddress,
    );
    dispatch(setCustomerAddress(data));
    //dispatch(updateCustomerAddressList({ [customerId]: [data] }));
    //TODO: make updateCustomerContactList works, now overwrites with one value and not keep others records
    //it must update only the item and keep all others items of a customer
    //this behavior must be here but by the moment i refresh list calling API here better than in form
    dispatch(fetchCustomerAddressesList({ customerId }));
    dispatch(
      showToastAlert({
        title: intl.formatMessage({
          id: 'customerAddresses.successToast.save',
        }),
        message: '',
        type: 'success',
        isToast: true,
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'customerAddresses',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('customer-addressess'));
  }
});

export interface IDeleteCustomerAddressesParams {
  customerId: number;
  addressId: number;
}

export const deleteCustomerAddress = createAsyncThunk<
  void,
  IDeleteCustomerAddressesParams,
  AppThunkConfig
>('CUSTOMER_ADDRESSES/FETCH', async ({ customerId, addressId }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('customer-addressess'));
  try {
    await api.customerAddressess.deleteCustomerAddressAPI(customerId, addressId);
    dispatch(removeCustomerAddressFromList(addressId));
    dispatch(clearCustomerAddress());
    dispatch(clearCustomerAddressDraft());
    dispatch(
      showToastAlert({
        title: intl.formatMessage({
          id: 'customerAddresses.successToast.delete',
        }),
        message: '',
        type: 'success',
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'customerAddresses',
        action: 'delete',
      }),
    );
  } finally {
    dispatch(endRequest('customer-addressess'));
  }
});

interface ISaveCustomerAddressParams {
  customerAddress: ICustomerAddress;
  customerId: number;
}
export const saveCustomerAddress = createAsyncThunk<
  void,
  ISaveCustomerAddressParams,
  AppThunkConfig
>('CUSTOMER_ADDRESSES/SAVE', async ({ customerAddress, customerId }, ThunkAPI) => {
  const { dispatch } = ThunkAPI;

  // If the addressId is 0, it means it is a new address
  if (customerAddress.addressId === 0) {
    dispatch(
      createCustomerAddress({
        customerId,
        customerAddress: customerAddress,
      }),
    );
  } else {
    // If the addressId is not 0, it's editing an existing address
    dispatch(
      updateCustomerAddress({
        customerId,
        customerAddress: customerAddress,
      }),
    );
  }
  dispatch(clearCustomerAddressDraft());
});
