import { createReducer } from '@reduxjs/toolkit';

import { getNegativeUniqueNumericId } from '@laudus/shared-utils';
import { ITerm } from '@laudus/types';

import {
  clearTerm,
  clearTermDraft,
  duplicateTerm,
  removeTermFromList,
  setTerm,
  setTermDraft,
  setTermDraftValues,
  setTermsList,
  updateTermList,
} from '.';

export const TERMS_TAB_ID = 'term';
export interface ITermsState {
  current: ITerm;
  draft: ITerm;
  list: ITerm[];
}

export const TERM_EMPTY: ITerm = {
  termId: '',
  name: '',
  daysToExpiration: 0,
  documentType: {
    code: '',
    description: '',
  },
  SIIType: '',
  notes: '',
  items: [],
};

export const initialTermsState: ITermsState = {
  current: TERM_EMPTY,
  draft: TERM_EMPTY,
  list: [],
};

export const termsReducer = createReducer(initialTermsState, (builder) => {
  builder
    .addCase(clearTerm, (state) => {
      return { ...state, current: TERM_EMPTY };
    })
    .addCase(clearTermDraft, (state) => {
      return { ...state, draft: TERM_EMPTY };
    })
    .addCase(setTerm, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setTermDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setTermDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setTermsList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateTermList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.termId !== action.payload.termId), action.payload],
      };
    })
    .addCase(removeTermFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.termId !== action.payload),
      };
    })
    .addCase(duplicateTerm, (state, action) => {
      const { termId, ...duplicatedTerm } = action.payload;
      const duplicatedItems = [...duplicatedTerm.items].map((item) => {
        return { ...item, itemId: getNegativeUniqueNumericId() };
      });
      return {
        ...state,
        draft: { ...duplicatedTerm, items: [...duplicatedItems] },
      };
    })
    .addDefaultCase((state) => state);
});
