import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Box, Center, Flex, Image, Link, Stack, Text, useBreakpointValue } from '@chakra-ui/react';

import { LaudusImages } from '@laudus/assets';
import { useIntl } from '@laudus/intl';
import {
  clearAccessError,
  clearAuth,
  getAccessError,
  getAuthError,
  getLastVatId,
  login,
  showToastAlert,
  useGlobalDispatch,
  useGlobalSelector,
} from '@laudus/redux-global';
import { colors } from '@laudus/shared-ui';
import { DeviceTypes, ILoginParams } from '@laudus/types';

import { PASSWORD_RESET_PATH, SELECT_COMPANY_QUERY_PARAM } from '../../router/constants';
import { LoginErrorMessage } from '../../shared/components/LoginErrorMessage';

import { CardLogin, LoginDiagram, LoginForm } from '.';

export const Login = () => {
  const intl = useIntl();

  const dispatch = useGlobalDispatch();
  const authError = useGlobalSelector(getAuthError);
  const accessError = useGlobalSelector(getAccessError);
  const lastVatId = useGlobalSelector(getLastVatId);

  const deviceType = useBreakpointValue(
    { base: DeviceTypes.Mobile, md: DeviceTypes.Desktop },
    { ssr: false },
  );

  const initialValues: ILoginParams = {
    loginName: '',
    password: '',
  };

  useEffect(() => {
    if (accessError) {
      dispatch(
        showToastAlert({
          type: 'warning',
          title: accessError.title ?? intl.formatMessage({ id: 'login.session.expired' }),
        }),
      );
      dispatch(clearAuth());
    }

    return () => {
      dispatch(clearAccessError());
    };
  }, [dispatch, accessError, intl]);

  const handleSubmit = async ({ password, loginName }: ILoginParams) => {
    dispatch(clearAuth());
    return dispatch(
      login({
        password,
        loginName,
        redirectToSuccessPath: `/${lastVatId ?? SELECT_COMPANY_QUERY_PARAM}`,
      }),
    );
  };

  return (
    <Stack
      className="background"
      _dark={{ background: colors.darkGrey700 }}
      display="flex"
      flexDirection={{ base: 'column', md: 'row' }}
      height="100vh"
      maxH="100vh"
      overflowY={{ base: 'auto', md: 'hidden' }}
    >
      <Center h={{ base: '100vh', md: '100vh' }} width="100%">
        <CardLogin>
          <Flex
            align="flex-start"
            display={{ base: 'none', md: 'flex' }}
            flex={{ md: 1, lg: 1.2 }}
            justifyContent="center"
            mb="4rem"
            mx="2rem"
          >
            <LoginDiagram />
          </Flex>
          <Flex
            alignItems={{ base: 'center', md: 'center' }}
            flex={{ base: 3, md: 3, lg: 1 }}
            flexDirection="column"
            justifyContent={{ base: 'center', md: 'flex-start' }}
            position="relative"
            w="100%"
          >
            {authError ? (
              <Box position="absolute" top="-5.5rem">
                <LoginErrorMessage message={authError.message || authError.title} />
              </Box>
            ) : undefined}
            <Flex flexDirection="column" maxW="33rem" w={{ base: '90%', md: '100%' }}>
              {deviceType === DeviceTypes.Mobile ? (
                <Image
                  h="7.5rem"
                  mb={{ base: '6rem', md: '3.7rem' }}
                  mt={{ base: '3rem', md: '0' }}
                  src={LaudusImages.loginLogoWhite}
                />
              ) : (
                <Image
                  h="7.5rem"
                  mb={{ base: '6rem', md: '3rem' }}
                  mt={{ base: '3rem', md: '0' }}
                  src={LaudusImages.loginLogoV2}
                />
              )}
              <LoginForm
                formError={authError ? authError.title : ''}
                initialValues={initialValues}
                onSubmit={handleSubmit}
              />
              {deviceType === DeviceTypes.Mobile ? (
                <Link
                  color={colors.neutral500}
                  fontSize={{ base: '1.5rem', md: '1.3rem', lg: '1.3rem' }}
                  fontWeight="300"
                  mb="3.9rem"
                  mt="1.6rem"
                  textAlign="center"
                  w="100%"
                  whiteSpace="nowrap"
                >
                  <RouterLink to={PASSWORD_RESET_PATH}>
                    <Text whiteSpace="nowrap">{intl.formatMessage({ id: 'login.cantEnter' })}</Text>
                    <Text whiteSpace="nowrap">
                      {intl.formatMessage({ id: 'login.recoverPassword' })}
                    </Text>
                  </RouterLink>
                </Link>
              ) : null}
            </Flex>
          </Flex>
        </CardLogin>
      </Center>
    </Stack>
  );
};
