import { createReducer } from '@reduxjs/toolkit';

import { IGroup, IUser } from '@laudus/types';

import { clearLoggedUser, setLoggedUser, setLoggedUserGroup } from './actions';

export const LOGGED_USER_EMPTY: IUser = {
  userId: '',
  name: '',
  group: {
    groupId: '',
    name: '',
  },
  loginName: '',
  passwordMinimumLength: 0,
  passwordChangeEveryDays: 0,
  locked: false,
  attempts: 0,
  discontinued: false,
  notes: '',
  VATId: '',
  allowElectronicFactoring: false,
  DTEEmail: '',
  email: '',
  smtp: null,
  restrictToCostCenter: null,
  restrictToSalesman: null,
  restrictToBranch: null,
  glUserId: 0,
};

export const LOGGED_USER_GROUP_EMPTY: IGroup = {
  groupId: '',
  name: '',
  notes: '',
  createdBy: {
    userId: '',
    name: '',
  },
  createdAt: '',
  modifiedBy: {
    userId: '',
    name: '',
  },
  modifiedAt: '',
  rights: [
    {
      rightId: 0,
      Item: {
        itemId: '',
        description: '',
      },
      read: false,
      write: false,
      create: false,
      delete: false,
      writeOthers: false,
      print: false,
    },
  ],
};

export interface ILoggedUserState {
  user: IUser;
  group: IGroup;
}

export const initialLoggedUserState: ILoggedUserState = {
  user: LOGGED_USER_EMPTY,
  group: LOGGED_USER_GROUP_EMPTY,
};

export const loggedUserReducer = createReducer(initialLoggedUserState, (builder) => {
  builder
    .addCase(clearLoggedUser, (state) => {
      return {
        ...state,
        user: LOGGED_USER_EMPTY,
        group: LOGGED_USER_GROUP_EMPTY,
      };
    })
    .addCase(setLoggedUser, (state, action) => {
      return { ...state, user: action.payload };
    })
    .addCase(setLoggedUserGroup, (state, action) => {
      return { ...state, group: action.payload };
    })
    .addDefaultCase((state) => state);
});
