import { createReducer } from '@reduxjs/toolkit';

import { IWorkersCompensationInsurance } from '@laudus/types';

import {
  clearWorkersCompensationInsurance,
  clearWorkersCompensationInsuranceDraft,
  removeWorkersCompensationInsuranceFromList,
  setWorkersCompensationInsurance,
  setWorkersCompensationInsuranceDraft,
  setWorkersCompensationInsuranceDraftValues,
  setWorkersCompensationInsurancesList,
  updateWorkersCompensationInsurancesList,
} from '.';

export const WORKERSCOMPENSATIONINSURANCES_TAB_ID = 'workersCompensationInsurances';

export interface IWorkersCompensationInsurancesState {
  current: IWorkersCompensationInsurance;
  draft: IWorkersCompensationInsurance;
  list: IWorkersCompensationInsurance[];
}

export const WORKERSCOMPENSATIONINSURANCE_EMPTY: IWorkersCompensationInsurance = {
  workersCompensationInsuranceCompanyId: '',
  name: '',
  VATId: '',
  previredId: '',
  electronicBookId: '',
  notes: '',
};

export const initialWorkersCompensationInsurancesState: IWorkersCompensationInsurancesState = {
  current: WORKERSCOMPENSATIONINSURANCE_EMPTY,
  draft: WORKERSCOMPENSATIONINSURANCE_EMPTY,
  list: [],
};

export const workersCompensationInsurancesReducer = createReducer(
  initialWorkersCompensationInsurancesState,
  (builder) => {
    builder
      .addCase(clearWorkersCompensationInsurance, (state) => {
        return {
          ...state,
          current: WORKERSCOMPENSATIONINSURANCE_EMPTY,
        };
      })
      .addCase(clearWorkersCompensationInsuranceDraft, (state) => {
        return {
          ...state,
          draft: WORKERSCOMPENSATIONINSURANCE_EMPTY,
        };
      })
      .addCase(setWorkersCompensationInsurance, (state, action) => {
        return { ...state, current: action.payload };
      })
      .addCase(setWorkersCompensationInsuranceDraft, (state, action) => {
        return { ...state, draft: action.payload };
      })
      .addCase(setWorkersCompensationInsuranceDraftValues, (state, action) => {
        return { ...state, draft: { ...state.draft, ...action.payload } };
      })
      .addCase(setWorkersCompensationInsurancesList, (state, action) => {
        return { ...state, list: action.payload };
      })
      .addCase(updateWorkersCompensationInsurancesList, (state, action) => {
        return {
          ...state,
          list: [
            ...state.list.filter(
              (p) =>
                p.workersCompensationInsuranceCompanyId !==
                action.payload.workersCompensationInsuranceCompanyId,
            ),
            action.payload,
          ],
        };
      })
      .addCase(removeWorkersCompensationInsuranceFromList, (state, action) => {
        return {
          ...state,
          list: state.list.filter(
            (d) => d.workersCompensationInsuranceCompanyId !== action.payload,
          ),
        };
      })
      .addDefaultCase((state) => state);
  },
);
