import { ComponentMultiStyleConfig } from '@chakra-ui/react';

import { colors } from '@laudus/shared-ui';

export const Checkbox: ComponentMultiStyleConfig = {
  parts: ['control', 'icon', 'label', 'checkbox'],
  baseStyle: {
    control: {
      backgroundColor: 'transparent',
      border: `0.1rem solid ${colors.neutral500} !important`,
      borderRadius: '0.2rem !important',
      boxShadow: 'none !important',
      height: '1.6rem',
      width: '1.6rem',
      _active: {
        backgroundColor: colors.white,
      },
      _selected: {
        backgroundColor: `${colors.primary500} !important`,
        border: `0.1rem solid ${colors.neutral500}`,
      },
      _disabled: {
        color: colors.white,
        backgroundColor: 'transparent',
        border: `0.1rem solid ${colors.neutral500}`,
        _checked: {
          backgroundColor: `${colors.primary500} !important`,
          border: `0.1rem solid ${colors.neutral500} !important`,
          color: colors.white,
        },
      },
      _focus: {
        border: '0.2rem solid',
        borderColor: colors.primary500,
        boxShadow: 'none',
      },
      _checked: {
        backgroundColor: `${colors.primary500} !important`,
        border: `0.1rem solid ${colors.neutral500} !important`,
        color: colors.white,
      },
      _dark: {
        backgroundColor: 'transparent',
        border: `0.1rem solid ${colors.darkGrey200} !important`,
        _active: {
          backgroundColor: colors.primary500,
        },
        _selected: {
          backgroundColor: `${colors.primary500} !important`,
          border: `0.1rem solid ${colors.darkGrey200}`,
        },
        _disabled: {
          color: colors.white,
          backgroundColor: 'transparent',
          border: `0.1rem solid ${colors.darkGrey200}`,
          _checked: {
            backgroundColor: `${colors.primary500} !important`,
            border: `0.1rem solid ${colors.darkGrey200} !important`,
            color: colors.darkGrey900,
          },
        },
        _focus: {
          border: '0.2rem solid',
          borderColor: colors.primary500,
          boxShadow: 'none',
        },
        _checked: {
          backgroundColor: `${colors.primary500} !important`,
          border: `0.1rem solid ${colors.darkGrey200} !important`,
          color: colors.darkGrey900,
        },
      },
    },
  },
  defaultProps: {
    variant: 'baseStyle',
  },
};
