import { createReducer } from '@reduxjs/toolkit';

import { clearAlert, clearAllAlerts, showAlert, showToastAlert } from './actions';
import { IAlertsState } from './types';

export const initialAlertsState: IAlertsState = {
  stack: [],
};

export const alertsReducer = createReducer(initialAlertsState, (builder) => {
  const isDuplicate = (array: object[], newObj: object) =>
    array.some((obj) => JSON.stringify(obj) === JSON.stringify(newObj));

  builder
    .addCase(clearAlert, (state, action) => {
      return {
        ...state,
        stack: state.stack.filter((alert) => alert.id !== action.payload.id),
      };
    })
    .addCase(clearAllAlerts, () => {
      return initialAlertsState;
    })
    .addCase(showAlert, (state, action) => {
      const alerts = state.stack;
      if (isDuplicate(alerts, action.payload)) {
        return state;
      }
      return {
        ...state,
        stack: [
          ...state.stack,
          {
            ...action.payload,
            id: action.payload.id ?? `${Date.now()}`,
          },
        ],
      };
    })
    .addCase(showToastAlert, (state, action) => {
      const alerts = state.stack;
      if (isDuplicate(alerts, action.payload)) {
        return state;
      }
      return {
        ...state,
        stack: [
          ...state.stack,
          {
            ...action.payload,
            id: `${Date.now()}`,
            isToast: true,
          },
        ],
      };
    })
    .addDefaultCase((state) => state);
});
