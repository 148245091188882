/**
 * Renames a key in an object while preserving the order of the keys.
 * If the new key already exists, it will be renamed with a prefix and an index.
 *
 * @param {Record<string, unknown>} object - The object containing the key to rename.
 * @param {string} oldKey - The key in the object to rename.
 * @param {string} newKey - The new key name.
 * @param {string} [prefix='column'] - The prefix to use if the new key already exists.
 * @returns {Record<string, unknown>} - A new object with the key renamed.
 */
export const renameKeyPreservingOrder = (
  object: Record<string, unknown>,
  oldKey: string,
  newKey: string,
  prefix = 'column',
  formatKeysFn?: (text: string) => string,
): Record<string, unknown> => {
  const formattedOldKey = formatKeysFn ? formatKeysFn(oldKey.trim()) : oldKey.trim();

  const newObject = Object.entries(object).reduce(
    (acc, [key, value], index) => {
      const formattedKey = formatKeysFn ? formatKeysFn(key.trim()) : key.trim();

      if (formattedKey === formattedOldKey) {
        acc[newKey] = value;
      } else if (key === newKey) {
        acc[`${prefix}${index}`] = value;
      } else {
        acc[key] = value;
      }

      return acc;
    },
    {} as Record<string, unknown>,
  );

  return newObject;
};
