import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IDTECertificateState } from './reducer';

export const getDTECertificateSlice = (state: AppState): IDTECertificateState =>
  state.dteCertificates;

export const getDTECertificateInfo = createSelector(
  [getDTECertificateSlice],
  (state) => state.current,
);

export const getDTECertificate = createSelector(
  [getDTECertificateSlice],
  (state) => state.certificate,
);

export const getDTECertificateDraft = createSelector(
  [getDTECertificateSlice],
  (state) => state.draft,
);

export const getDTECertificateImport = createSelector(
  [getDTECertificateSlice],
  (state) => state.importCertificate,
);
