import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IRecipe } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

export const addHomeRecipesTab = () =>
  addTab({
    tab: {
      id: 'recipes',
      title: intl.formatMessage({ id: 'recipes.tabTitle' }),
      path: 'pages/Recipes/Recipes',
      isRemovable: true,
    },
  });

export const addViewRecipesTab = (id?: number) =>
  addTab({
    tab: {
      id: 'recipes',
      title: intl.formatMessage({ id: 'recipes.tabTitle' }),
      path: 'pages/Recipes/RecipesView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewRecipesTab = () =>
  addTab({
    tab: {
      id: 'recipes',
      title: intl.formatMessage({ id: 'recipes.tabTitle' }),
      path: 'pages/Recipes/RecipesNew',
      isRemovable: true,
    },
  });

export const addEditRecipesTab = () =>
  addTab({
    tab: {
      id: 'recipes',
      title: intl.formatMessage({ id: 'recipes.tabTitle' }),
      path: 'pages/Recipes/RecipesEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearRecipe = createAction('RECIPES/CLEAR');

export const clearRecipeDraft = createAction('RECIPES/CLEAR_DRAFT');

export const setRecipe = createAction<IRecipe>('RECIPES/SET_RECIPE');

export const setRecipeDraft = createAction<IRecipe>('RECIPES/SET_RECIPE_DRAFT');

export const setRecipeDraftValues = createAction<Partial<IRecipe>>(
  'RECIPES/SET_RECIPE_DRAFT_VALUE',
);

export const setRecipesList = createAction<IRecipe[]>('RECIPES/SET_LIST');

export const updateRecipesList = createAction<IRecipe>('RECIPES/UPDATE_LIST');

export const removeRecipeFromList = createAction<number>('RECIPES/REMOVE_FROM_LIST');

export const duplicateRecipe = createAction<IRecipe>('RECIPES/DUPLICATE');

//Complex actions
export const fetchRecipesList = createAsyncThunk<void, void, AppThunkConfig>(
  'RECIPES/FETCH_RECIPES_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('recipes'));
    try {
      const { data } = await api.recipes.fetchRecipesListAPI();
      dispatch(setRecipesList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'recipes',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('recipes'));
    }
  },
);

export const fetchRecipe = createAsyncThunk<void, number, AppThunkConfig>(
  'RECIPES/FETCH_RECIPE',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('recipes'));
    try {
      const { data } = await api.recipes.fetchRecipeAPI(id);
      dispatch(setRecipe(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'recipes',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('recipes'));
    }
  },
);
