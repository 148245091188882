import { createSelector } from '@reduxjs/toolkit';

import { AppState, IStockControlState } from '../..';

export const getStockControlSlice = (state: AppState): IStockControlState => state.stockControl;

export const getStockControl = createSelector([getStockControlSlice], (state) => state.list);

export const getStockControlReversed = createSelector([getStockControl], (list) =>
  list.slice().reverse(),
);

export const getStockControlFilters = createSelector(
  [getStockControlSlice],
  (state) => state.filters,
);
