import { useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { Flex, Image, Link, Text } from '@chakra-ui/react';

import { LaudusImages } from '@laudus/assets';
import { useIntl } from '@laudus/intl';
import { setPasswordResetSent, useGlobalDispatch } from '@laudus/redux-global';
import { colors } from '@laudus/shared-ui';

export const PasswordRequestSent = () => {
  const intl = useIntl();
  const dispatch = useGlobalDispatch();

  useEffect(() => {
    return () => {
      // When this component unmounts, it will clear the password reset sent flag
      dispatch(setPasswordResetSent(false));
    };
  }, [dispatch]);

  return (
    <>
      <Flex
        alignItems={{ base: 'center', md: 'center' }}
        flexDirection="column"
        justifyContent={{ base: 'center', md: 'flex-start' }}
        mb="3rem"
        position="relative"
        w="100%"
      >
        <Text
          align="center"
          color={{ base: 'white', md: 'black' }}
          fontSize="2rem"
          fontWeight="700"
          w="100%"
        >
          {intl.formatMessage({ id: 'resetPassword.title.checkEmail' })}
        </Text>
        <Text
          align="center"
          color={colors.primary500}
          mt="0.6rem"
          textStyle={{ md: 'h6', lg: 'h5' }}
        >
          {intl.formatMessage({ id: 'resetPassword.subtitle.stepsToFollow' })}
        </Text>
      </Flex>
      <Flex flexDirection="column" maxW="33rem" w={{ base: '90%', md: '100%' }}>
        <Image h="16rem" src={LaudusImages.resetPasswordEnvelope} />
        <Link
          color={{ base: colors.neutral500, md: colors.black }}
          fontSize={{ base: '1.5rem', md: '1.3rem', lg: '1.3rem' }}
          fontWeight="600"
          href="/login"
          mb="1.2rem"
          mt="3.9rem"
          textAlign="center"
          w="100%"
          whiteSpace="nowrap"
        >
          <RouterLink replace to="/login">
            <Text whiteSpace="nowrap">
              {`< ${intl.formatMessage({
                id: 'resetPassword.button.backToLogin',
              })}`}
            </Text>
          </RouterLink>
        </Link>
      </Flex>
    </>
  );
};
