import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IGlobalAccountsState } from './reducer';

export const getGlobalAccountsSlice = (state: AppState): IGlobalAccountsState =>
  state.globalAccounts;

export const getGlobalAccount = createSelector([getGlobalAccountsSlice], (state) => state.current);

export const getGlobalAccountDraft = createSelector(
  [getGlobalAccountsSlice],
  (state) => state.draft,
);
