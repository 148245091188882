import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IShowErrorParams } from '@laudus/redux-global';
import { updateEmployeeCalculationValuesList } from '@laudus/shared-utils';
import {
  IEmployee,
  IInitialCompanyParameters,
  IPayroll,
  IPayrollCostCenterAllocationTableData,
  IPayrollEmployeesRemunerationTableData,
  IPayrollLine,
  IPayrollLinesCalculationInput,
  IPayrollListItem,
  IStartEditingParams,
  IWagesParameters,
} from '@laudus/types';

import { AppState, AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, endRequestInTab, startRequest, startRequestInTab } from '../global/actions';
import { addTab } from '../tabs';

import { getPayrollDraft } from './selectors';

export const PAYROLL_TAB_ID = 'payroll';

// Payroll Tab action creators
export const addHomePayrollTab = () =>
  addTab({
    tab: {
      id: PAYROLL_TAB_ID,
      title: intl.formatMessage({ id: 'payroll.tabTitle' }),
      path: 'pages/Payroll/Payroll',
      isRemovable: true,
    },
  });

export const startEditingPayroll = createAsyncThunk<
  void,
  IStartEditingParams | undefined,
  AppThunkConfig
>('PAYROLL/START_EDITING', async (params, ThunkAPI) => {
  const { isNew = false } = params || {};
  const { dispatch } = ThunkAPI;
  dispatch(setPayrollEditing(true));
  dispatch(
    addTab({
      tab: {
        id: PAYROLL_TAB_ID,
        title: intl.formatMessage({ id: 'payroll.tabTitle' }),
        path: isNew ? 'pages/Payroll/PayrollNew' : 'pages/Payroll/PayrollEdit',
        isRemovable: true,
      },
    }),
  );
});

export const startEditingPayrollNew = createAsyncThunk<void, void, AppThunkConfig>(
  'PAYROLL/START_EDITING_NEW',
  async (_, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(startEditingPayroll({ isNew: true }));
  },
);

export const stopEditingPayroll = createAsyncThunk<void, string | undefined, AppThunkConfig>(
  'PAYROLL/STOP_EDITING',
  async (id, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(setPayrollEditing(false));
    dispatch(
      addTab({
        tab: {
          id: PAYROLL_TAB_ID,
          title: intl.formatMessage({ id: 'payroll.tabTitle' }),
          path: 'pages/Payroll/PayrollView',
          props: { id },
          isRemovable: true,
        },
      }),
    );
  },
);

// Payroll action creators
export const clearPayroll = createAction(`PAYROLL/CLEAR`);

export const clearPayrollDraft = createAction(`PAYROLL/CLEAR_DRAFT`);

export const setPayroll = createAction<IPayroll>(`PAYROLL/SET`);

export const setPayrollDraft = createAction<IPayroll>(`PAYROLL/SET_DRAFT`);

export const setPayrollDraftValues = createAction<Partial<IPayroll>>(`PAYROLL/SET_DRAFT_VALUES`);

export const updatePayrollDraftLines = createAction<IPayrollLine[]>('PAYROLL/UPDATE_DRAFT_LINES');

export const setPayrollList = createAction<IPayrollListItem[]>(`PAYROLL/SET_LIST`);

export const updatePayrollList = createAction<IPayrollListItem>(`PAYROLL/UPDATE_LIST`);

export const removePayrollFromList = createAction<string>(`PAYROLL/REMOVE_FROM_LIST`);

export const duplicatePayroll = createAction<IPayroll>(`PAYROLL/DUPLICATE`);

export const setWagesParameters = createAction<IWagesParameters>('PAYROLL/SET_WAGE_PARAMETERS');

export const clearInitialCompanyParameters = createAction('PAYROLL/CLEAR_COMPANY_PARAMETERS');

export const setInitialCompanyParameters = createAction<IInitialCompanyParameters>(
  'PAYROLL/SET_COMPANY_PARAMETERS',
);

export const clearRemovingEmployee = createAction('PAYROLL/CLEAR_REMOVING_EMPLOYEE');

export const setRemovingEmployee = createAction<IEmployee>('PAYROLL/SET_REMOVING_EMPLOYEE');

export const setPayrollEditing = createAction<boolean>('PAYROLL/SET_EDITING');

export const fetchPayrollList = createAsyncThunk<void, void, AppThunkConfig>(
  'PAYROLL/FETCH_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('payroll'));
    try {
      const { data } = await api.payroll.fetchPayrollListFromAPI();
      dispatch(setPayrollList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'payroll',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('payroll'));
    }
  },
);

export const fetchPayroll = createAsyncThunk<void, string, AppThunkConfig>(
  'PAYROLL/FETCH',
  async (payrollId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('payroll'));
    try {
      const { data } = await api.payroll.fetchPayrollFromAPI(payrollId);
      dispatch(setPayroll(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'payroll',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('payroll'));
    }
  },
);

export const calculatePayrollLines = createAsyncThunk<
  void,
  IPayrollLinesCalculationInput,
  AppThunkConfig
>('PAYROLL/FETCH_PAYROLL_CALCULATIONS', async (calculationParams, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequestInTab(PAYROLL_TAB_ID));
  try {
    const { data } = await api.payroll.calculatePayrollLinesFromAPI(calculationParams);
    dispatch(updatePayrollDraftLines(data.lines));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'payroll',
        action: 'calculation' as IShowErrorParams['action'],
      }),
    );
  } finally {
    dispatch(endRequestInTab(PAYROLL_TAB_ID));
  }
});

export const fetchWagesParameters = createAsyncThunk<void, void, AppThunkConfig>(
  'PAYROLL/FETCH_WAGES_PARAMETERS',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('payroll'));
    try {
      const { data } = await api.payroll.fetchWagesParametersFromAPI();
      dispatch(setWagesParameters(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'wagesParameters',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('payroll'));
    }
  },
);

export const fetchRemovingEmployee = createAsyncThunk<void, number, AppThunkConfig>(
  'PAYROLL/FETCH_REMOVING_EMPLOYEE',
  async (employeeId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('payroll'));
    try {
      const { data } = await api.employees.fetchEmployeeFromAPI(employeeId);
      dispatch(setRemovingEmployee(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employees',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('payroll'));
    }
  },
);

export interface IUpdateEmployeeRemunerationValuesParams {
  rowData: IPayrollEmployeesRemunerationTableData;
  columnId: string;
}

export const updateEmployeeRemunerationValues = createAsyncThunk<
  void,
  IUpdateEmployeeRemunerationValuesParams,
  AppThunkConfig
>('PAYROLL/UPDATE_EMPLOYEE_REMUNERATION_VALUES', async ({ rowData, columnId }, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;
  const state = getState() as AppState;

  const fixedByUserValues = [
    ...Object.entries(rowData.values)
      .filter(
        ([remunerationConceptId, valueObject]) =>
          remunerationConceptId === columnId ||
          (valueObject.dataType === 'N' && valueObject.fixedByUser),
      )
      .map(([remunerationConceptId, { value }]) => ({
        remunerationConceptId,
        amount: Number(value),
      })),
    ...Object.entries(rowData.values)
      .filter(
        ([remunerationConceptId, valueObject]) =>
          remunerationConceptId === columnId ||
          (valueObject.dataType === 'C' && valueObject.fixedByUser),
      )
      .map(([remunerationConceptId, { value }]) => ({
        remunerationConceptId,
        text: '' + value,
      })),
  ];

  const payrollDraft = getPayrollDraft(state);
  const modifiedEmployeeList = updateEmployeeCalculationValuesList(
    payrollDraft.lines.filter((line) => line.employee.employeeId === rowData.employeeId),
    fixedByUserValues,
    false,
  );
  dispatch(
    calculatePayrollLines({
      date: payrollDraft.date,
      employees: modifiedEmployeeList,
    }),
  );
});

export const updateEmployeeCostCenterAllocation = createAsyncThunk<
  void,
  IPayrollCostCenterAllocationTableData,
  AppThunkConfig
>('PAYROLL/UPDATE_EMPLOYEE_COST_CENTER_ALLOCATION', async (modifiedAllocationLine, ThunkAPI) => {
  const { dispatch, getState } = ThunkAPI;
  const state = getState() as AppState;

  const payrollDraft = getPayrollDraft(state);
  const currentLine = payrollDraft.lines.find(
    (line) => line.employee.employeeId === modifiedAllocationLine.employeeId,
  );

  if (currentLine) {
    dispatch(
      updatePayrollDraftLines([
        {
          ...currentLine,
          costCenterAllocation: Object.entries(modifiedAllocationLine.allocations)
            .filter(([_, percentage]) => percentage !== 0)
            .map(([costCenterId, percentage]) => ({
              costCenter: {
                costCenterId,
                name: '',
              },
              percentage,
            })),
        },
      ]),
    );
  }
});
