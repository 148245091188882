import { createReducer } from '@reduxjs/toolkit';

import { IEmployeeHistory } from '@laudus/types';

import {
  clearEmployeeHistory,
  clearEmployeeHistoryDraft,
  removeEmployeeHistoryFromList,
  setEmployeeHistory,
  setEmployeeHistoryDraft,
  setEmployeeHistoryDraftValues,
  setEmployeesHistoryList,
  updateEmployeesHistoryList,
} from './actions';

export interface IEmployeeHistoryState {
  current: IEmployeeHistory;
  draft: IEmployeeHistory;
  list: IEmployeeHistory[];
}

export const EMPLOYEE_HISTORY_EMPTY: IEmployeeHistory = {
  employeeHistoryId: 0,
  informedAt: '',
  informedBy: {
    userId: '',
    name: '',
  },
  notes: '',
};

export const initialEmployeesHistoryState: IEmployeeHistoryState = {
  current: EMPLOYEE_HISTORY_EMPTY,
  draft: EMPLOYEE_HISTORY_EMPTY,
  list: [],
};

export const employeesHistoryReducer = createReducer(initialEmployeesHistoryState, (builder) => {
  builder
    .addCase(clearEmployeeHistory, (state) => {
      return { ...state, current: EMPLOYEE_HISTORY_EMPTY };
    })
    .addCase(clearEmployeeHistoryDraft, (state) => {
      return { ...state, draft: EMPLOYEE_HISTORY_EMPTY };
    })
    .addCase(setEmployeeHistory, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setEmployeeHistoryDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setEmployeeHistoryDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setEmployeesHistoryList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateEmployeesHistoryList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((b) => b.employeeHistoryId !== action.payload.employeeHistoryId),
          action.payload,
        ],
      };
    })
    .addCase(removeEmployeeHistoryFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((b) => b.employeeHistoryId !== action.payload),
      };
    })
    .addDefaultCase((state) => state);
});
