import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';
import { getGlobalCompaniesList } from '../globalCompanies';

import { IGlobalUsersState } from './reducer';

export const getGlobalUsersSlice = (state: AppState): IGlobalUsersState => state.globalUsers;

export const getGlobalUser = createSelector([getGlobalUsersSlice], (state) => state.current);

export const getGlobalUserDraft = createSelector([getGlobalUsersSlice], (state) => state.draft);

export const getGlobalUsersList = createSelector([getGlobalUsersSlice], (state) => state.list);

export const getCompaniesOfGlobalUser = createSelector([getGlobalUsersSlice], (state) => {
  return [...state.companies].sort((companyA, companyB) =>
    companyA.name.localeCompare(companyB.name),
  );
});

export const getCompaniesNotOfGlobalUser = createSelector(
  [getCompaniesOfGlobalUser, getGlobalCompaniesList],
  (companiesOfGlobalUser, globalCompaniesList) =>
    globalCompaniesList
      .filter(
        (company) =>
          companiesOfGlobalUser.find((c) => c.glCompanyId === company.glCompanyId) === undefined,
      )
      .sort((companyA, companyB) => companyA.name.localeCompare(companyB.name)),
);

export const getAddingCompanyAccessToken = createSelector(
  [getGlobalUsersSlice],
  (state) => state.addingCompanyAccessToken,
);

export const getAddingCompanyGroups = createSelector(
  [getGlobalUsersSlice],
  (state) => state.addingCompanyGroups,
);

export const getSortedGlobalUsersList = createSelector(
  [getGlobalUsersList],
  (getGlobalUsersList) => {
    return [...getGlobalUsersList].sort((userA, userB) =>
      userA.loginName.localeCompare(userB.loginName),
    );
  },
);
