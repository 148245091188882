import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IBankStatementsState } from './reducer';

export const getBankStatementsSlice = (state: AppState): IBankStatementsState =>
  state.bankStatements;

export const getBankStatementList = createSelector([getBankStatementsSlice], (state) => state.list);

export const getBankStatement = createSelector([getBankStatementsSlice], (state) => state.current);

export const getBankStatementDraft = createSelector(
  [getBankStatementsSlice],
  (state) => state.draft,
);

export const getBankStatementsImportList = createSelector(
  [getBankStatementsSlice],
  (state) => state.importList,
);

export const getBankStatementsImportBank = createSelector(
  [getBankStatementsSlice],
  (state) => state.importBank,
);
