import { RefObject, useEffect } from 'react';

import type { GridApi } from '@ag-grid-community/core';

export interface UseSizeColumnsToFitParams {
  sizeColumnsToFit: boolean;
  gridApi: GridApi | undefined;
  containerRef: RefObject<HTMLDivElement>;
}

export function useSizeColumnsToFit({
  gridApi,
  sizeColumnsToFit,
  containerRef,
}: UseSizeColumnsToFitParams) {
  useEffect(() => {
    const handleWindowResize = () => {
      if (sizeColumnsToFit && gridApi) {
        gridApi.sizeColumnsToFit();
      }
    };

    handleWindowResize();

    window.addEventListener('resize', handleWindowResize);

    if (!containerRef?.current) {
      return;
    }
    const resizeObserver = new ResizeObserver(handleWindowResize);
    resizeObserver.observe(containerRef.current);

    return () => {
      window.removeEventListener('resize', handleWindowResize);

      if (resizeObserver) {
        resizeObserver.disconnect();
      }
    };
  }, [containerRef, gridApi, sizeColumnsToFit]);
}
