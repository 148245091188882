import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IBalanceSheet, IBalanceSheetFormattedFilters } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequestInTab, startRequestInTab } from '../global';
import { addTab } from '../tabs';

export const BALANCESHEET_TAB_ID = 'balanceSheet';

interface IBalanceSheetTabCreatorArgs {
  title?: string;
  balanceSheetId?: number;
}

export const addHomeBalanceSheetTab = ({ title }: IBalanceSheetTabCreatorArgs = {}) =>
  addTab({
    tab: {
      id: BALANCESHEET_TAB_ID,
      title: title ?? intl.formatMessage({ id: 'balanceSheet.tabTitle' }),
      path: 'pages/BalanceSheet/BalanceSheet',
      isRemovable: true,
    },
  });

// Simple actions
export const setBalanceSheet = createAction<IBalanceSheet[]>('BALANCE_SHEETS/SET_BALANACE_SHEET');
export const resetBalanceSheet = createAction('BALANCE_SHEETS/RESET_BALANACE_SHEET');

export const fetchBalanceSheetTotal = createAsyncThunk<
  void,
  IBalanceSheetFormattedFilters,
  AppThunkConfig
>('BALANCE_SHEETS/FETCH_BALANCE_SHEET_TOTAL', async (balanceSheetParams, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequestInTab(BALANCESHEET_TAB_ID));
  try {
    const { data } = await api.balanceSheets.fetchBalanceSheetTotalAPI(balanceSheetParams);

    dispatch(setBalanceSheet(Array.isArray(data) ? data : []));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'balanceSheet',
        action: 'read',
      }),
    );
  } finally {
    dispatch(endRequestInTab(BALANCESHEET_TAB_ID));
  }
});

export const fetchBalanceSheetStandard = createAsyncThunk<
  void,
  IBalanceSheetFormattedFilters,
  AppThunkConfig
>('BALANCE_SHEETS/FETCH_BALANCE_SHEET_TOTAL', async (balanceSheetParams, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequestInTab(BALANCESHEET_TAB_ID));
  try {
    const { data } = await api.balanceSheets.fetchBalanceSheetStandardAPI(balanceSheetParams);

    dispatch(setBalanceSheet(Array.isArray(data) ? data : []));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'balanceSheet',
        action: 'read',
      }),
    );
  } finally {
    dispatch(endRequestInTab(BALANCESHEET_TAB_ID));
  }
});

export const fetchBalanceSheet8Columns = createAsyncThunk<
  void,
  IBalanceSheetFormattedFilters,
  AppThunkConfig
>('BALANCE_SHEETS/FETCH_BALANCE_SHEET_TOTAL', async (balanceSheetParams, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequestInTab(BALANCESHEET_TAB_ID));
  try {
    const { data } = await api.balanceSheets.fetchBalanceSheet8ColumnsAPI(balanceSheetParams);

    dispatch(setBalanceSheet(Array.isArray(data) ? data : []));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'balanceSheet',
        action: 'read',
      }),
    );
  } finally {
    dispatch(endRequestInTab(BALANCESHEET_TAB_ID));
  }
});
