import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';

import { intl } from '@laudus/intl';
import {
  IBestSellingProductItem,
  IFetchWithEtagParams,
  IProduct,
  IProductAdvancedSearchFilters,
  IProductList,
  IProductListItem,
  IProductPictureUploadResponse,
  IProductStock,
  IRequest,
  IStartEditingParams,
} from '@laudus/types';

import { AppState, AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert, showToastAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endProgress, endRequest, startProgress, startRequest } from '../global/actions';
import { fetchProductSalesTaxesList } from '../salesTaxes';
import { addTab } from '../tabs';

import { getNewProductDraft, getProductsBestSellingList, getProductsList } from './selectors';

export const addHomeProductTab = () =>
  addTab({
    tab: {
      id: 'products',
      title: intl.formatMessage({ id: 'products.tabTitle' }),
      path: 'pages/Products/Products',
      isRemovable: true,
    },
  });

export const startEditingProduct = createAsyncThunk<
  void,
  IStartEditingParams | undefined,
  AppThunkConfig
>('PRODUCTS/START_EDITING', async (params, ThunkAPI) => {
  const { isNew = false } = params || {};
  const { dispatch } = ThunkAPI;
  dispatch(setProductEditing(true));
  dispatch(
    addTab({
      tab: {
        id: 'products',
        title: intl.formatMessage({ id: 'products.tabTitle' }),
        path: isNew ? 'pages/Products/ProductNew' : 'pages/Products/ProductEdit',
        isRemovable: true,
      },
    }),
  );
});

export const startEditingProductNew = createAsyncThunk<void, void, AppThunkConfig>(
  'PRODUCTS/START_EDITING_NEW',
  async (_, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(startEditingProduct({ isNew: true }));
  },
);

export const stopEditingProduct = createAsyncThunk<void, number | undefined, AppThunkConfig>(
  'PRODUCTS/STOP_EDITING',
  async (id, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(setProductEditing(false));
    dispatch(
      addTab({
        tab: {
          id: 'products',
          title: intl.formatMessage({ id: 'products.tabTitle' }),
          path: 'pages/Products/ProductView',
          props: { id },
          isRemovable: true,
        },
      }),
    );
  },
);

export const clearProduct = createAction('PRODUCTS/CLEAR');

export const clearProductDraft = createAction('PRODUCTS/CLEAR_DRAFT');

export const setProduct = createAction<IProduct>('PRODUCTS/SET_PRODUCT');

export const setProductValues = createAction<Partial<IProduct>>('PRODUCTS/SET_PRODUCT_VALUE');

export const setProductDraft = createAction<IProduct>('PRODUCTS/SET_PRODUCT_DRAFT');

export const setProductDraftValues = createAction<Partial<IProduct>>(
  'PRODUCTS/SET_PRODUCT_DRAFT_VALUE',
);

export const setProductsList = createAction<IProductList>('PRODUCTS/SET_LIST');

export const setProductStock = createAction<IProductStock>('PRODUCTS/SET_PRODUCT_STOCK');

export const updateProductsList = createAction<IProduct>('PRODUCTS/UPDATE_LIST');

export const removeProductFromList = createAction<number>('PRODUCTS/REMOVE_FROM_LIST');

// DRAFT LIST
export const updateProductInDraftList = createAction<Partial<IProductListItem>>(
  'PRODUCTS/UPDATE_DRAFT_LIST',
);

export const removeProductStock = createAction<number>('PRODUCTS/REMOVE_FROM_STOCK');

export const duplicateProduct = createAction<IProduct>('PRODUCTS/DUPLICATE');

export const setProductEditing = createAction<boolean>('PRODUCTS/SET_EDITING');

export const fetchProductsList = createAsyncThunk<
  void,
  Partial<IRequest> & IFetchWithEtagParams,
  AppThunkConfig
>('PRODUCTS/FETCH_PRODUCTS_LIST', async ({ eTag, silent, ...filters }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  if (!silent) {
    dispatch(startRequest('products'));
  }

  try {
    const { data } = await api.products.fetchProductsListAPI(filters);
    dispatch(setProductsList(Array.isArray(data) ? data : []));

    dispatch(setEtagsCurrentEtag(eTag));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'products',
        action: 'list',
      }),
    );
  } finally {
    if (!silent) {
      dispatch(endRequest('products'));
    }
  }
});

export const setProductsAdvancedSearchFilters = createAction<
  Partial<IProductAdvancedSearchFilters>
>('PRODUCTS/SET_ADVANCED_SEARCH_FILTERS');

export const setProductsSalesLists = createAction<{
  productsList: IProductList;
  productsBestSellingList: IBestSellingProductItem[];
}>('PRODUCTS/SET_SALES_LISTS');

export const fetchProductsSalesLists = createAsyncThunk<
  void,
  Partial<IRequest> & IFetchWithEtagParams,
  AppThunkConfig
>('PRODUCTS/FETCH_SALES_LISTS', async ({ eTag, silent, ...filters }, ThunkAPI) => {
  const { extra, dispatch, getState } = ThunkAPI;
  const { api } = extra;
  const state = getState() as AppState;

  if (!silent) {
    dispatch(startRequest('products'));
  }

  try {
    const currentProductsBestSellingList = getProductsBestSellingList(state);

    const promises: (Promise<AxiosResponse> | { data: IBestSellingProductItem[] })[] = [
      api.products.fetchProductsListAPI(filters),
    ];

    if (!currentProductsBestSellingList.length) {
      promises.push(api.salesReports.fetchBestSellingProductsAPI());
    } else {
      promises.push({ data: currentProductsBestSellingList });
    }

    const [{ data: productsList }, { data: productsBestSellingList }] = await Promise.all(promises);

    dispatch(setProductsSalesLists({ productsList, productsBestSellingList }));
    dispatch(setEtagsCurrentEtag(eTag));

    // Load the list of sales taxes everytime the product list is fetched
    dispatch(fetchProductSalesTaxesList({ eTag, silent }));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'productsSales',
        action: 'list',
      }),
    );
  } finally {
    if (!silent) {
      dispatch(endRequest('products'));
    }
  }
});

export const fetchProductsBestSellingList = createAsyncThunk<void, void, AppThunkConfig>(
  'PRODUCTS/FETCH_BESTSELLING_LIST',
  async (_, ThunkAPI) => {
    const { extra, dispatch, getState } = ThunkAPI;
    const { api } = extra;

    try {
      const state = getState() as AppState;
      const productsList = getProductsList(state);

      if (productsList.length) {
        const { data } = await api.salesReports.fetchBestSellingProductsAPI();
        dispatch(setProductsSalesLists({ productsList, productsBestSellingList: data }));
      }
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'productsSales',
          action: 'list',
        }),
      );
    }
  },
);

export const fetchProduct = createAsyncThunk<void, number, AppThunkConfig>(
  'PRODUCTS/FETCH_PRODUCT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('products'));
    try {
      const { data } = await api.products.fetchProductAPI(id);
      dispatch(setProduct(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'products',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('products'));
    }
  },
);

export const fetchProductStock = createAsyncThunk<void, number, AppThunkConfig>(
  'PRODUCTS/FETCH_PRODUCT_STOCK',
  async (productId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('product-stock'));
    try {
      const { data } = await api.products.fetchProductStockByWarehousesAPI(productId);
      dispatch(setProductStock(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'stockControl',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('product-stock'));
    }
  },
);

// IMPORT

export const setProductsImportList = createAction<any[]>('PRODUCTS/SET_PRODUCTS_IMPORT_LIST');

interface IRenameImportListColumnParams {
  oldColumnName: string;
  newColumnName: string;
}

export const renameProductsImportListColumn = createAction<IRenameImportListColumnParams>(
  `PRODUCTS/RENAME_IMPORT_LIST_COLUMN`,
);

export const setProductsImportSucceeded = createAction<boolean>(`PRODUCTS/SET_IMPORT_SUCCEEDED`);

export const createProductsList = createAsyncThunk<void, Partial<IProduct>[], AppThunkConfig>(
  'PRODUCTS/IMPORT_PRODUCTS_LIST',
  async (products, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startProgress());
    try {
      dispatch(setProductsImportSucceeded(false));
      const { data } = await api.products.createProductBulkAPI(products);

      const importError = data.find((item) => item.status !== 'Valid');
      if (importError) {
        dispatch(
          showAlert({
            type: 'error',
            title: intl.formatMessage({ id: `products.errorToast.save` }),
            message: importError.message,
          }),
        );
        return;
      }

      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'import.toast.success' }),
          message: '',
          type: 'success',
        }),
      );
      dispatch(setProductsImportSucceeded(true));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'products',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endProgress());
    }
  },
);

// PICTURES

export const setProductImage = createAction<IProductPictureUploadResponse>(
  'PRODUCTS/SET_PRODUCT_IMAGE',
);

export const uploadCatalogProductPicture = createAsyncThunk<
  void,
  IProductListItem & { picture: File },
  AppThunkConfig
>('PRODUCTS/UPLOAD_PRODUCT_PICTURE', async ({ picture, ...product }, ThunkAPI) => {
  const { extra, dispatch } = ThunkAPI;
  const { api } = extra;

  try {
    if (product.pictures_fileId) {
      await api.products.deleteProductPicture(product.productId, product.pictures_fileId);
    }

    const { data } = await api.products.uploadProductPicture(product.productId, picture);

    dispatch(setProductImage(data));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'products',
        action: 'save',
      }),
    );
  }
});

export const deleteCatalogProductPicture = createAsyncThunk<void, IProductListItem, AppThunkConfig>(
  'PRODUCTS/DELETE_PRODUCT_PICTURE',
  async (product, ThunkAPI) => {
    const { extra, dispatch } = ThunkAPI;
    const { api } = extra;

    try {
      if (!product.pictures_fileId) {
        throw new Error('Invalid picture id');
      }

      await api.products.deleteProductPicture(product.productId, product.pictures_fileId);

      dispatch(
        setProductImage({
          productId: product.productId,
          picture: {
            fileId: 0,
            description: '',
            type: '',
            url: '',
          },
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'products',
          action: 'delete',
        }),
      );
    }
  },
);

export const uploadProductPicture = createAsyncThunk<
  void,
  IProduct & { picture: File },
  AppThunkConfig
>('PRODUCTS/UPLOAD_PRODUCT_PICTURE', async ({ picture, ...product }, ThunkAPI) => {
  const { extra, dispatch } = ThunkAPI;
  const { api } = extra;

  const firstPicture = product.pictures?.[0]; // We only get the first picture https://laudus.atlassian.net/browse/LW-2165
  const productId = product.productId ?? 0;

  try {
    if (firstPicture?.fileId && productId) {
      await api.products.deleteProductPicture(productId, firstPicture.fileId);
    }

    const { data } = await api.products.uploadProductPicture(productId, picture);

    dispatch(setProductDraftValues({ pictures: [data.picture] }));
    dispatch(setProductValues({ pictures: [data.picture] }));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'products',
        action: 'save',
      }),
    );
  }
});

export const deleteProductPicture = createAsyncThunk<void, IProduct, AppThunkConfig>(
  'PRODUCTS/DELETE_PRODUCT_PICTURE',
  async (product, ThunkAPI) => {
    const { extra, dispatch } = ThunkAPI;
    const { api } = extra;
    const firstPicture = product.pictures?.[0]; // We only get the first picture https://laudus.atlassian.net/browse/LW-2165

    try {
      if (!firstPicture?.fileId) {
        throw new Error('Invalid picture id');
      }

      await api.products.deleteProductPicture(product?.productId ?? 0, firstPicture.fileId);

      dispatch(setProductDraftValues({ pictures: [] }));
      dispatch(setProductValues({ pictures: [] }));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'products',
          action: 'delete',
        }),
      );
    }
  },
);

export const clearProductsDraftList = createAction('PRODUCTS/CLEAR_DRAFT_LIST');
export const createProductsCatalog = createAsyncThunk<void, void, AppThunkConfig>(
  'PRODUCTS/UPDATE_PRODUCTS_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra, getState } = ThunkAPI;
    const { api } = extra;
    const state = getState();

    dispatch(startRequest('products'));
    try {
      const products = state.products.draftList;
      // TODO: Test this when the API accepts the partial update
      await api.products.createProductBulkAPI(products);

      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'import.toast.success' }),
          message: '',
          type: 'success',
        }),
      );
      dispatch(clearProductsDraftList());
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'import',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('products'));
    }
  },
);

export const newProductDraft = createAsyncThunk<void, void, AppThunkConfig>(
  'PRODUCTS/SET_PRODUCT_DRAFT_INITIAL_VALUES',
  async (_, ThunkAPI) => {
    const { dispatch, getState } = ThunkAPI;
    const state = getState();

    const initialValue = getNewProductDraft(state);

    dispatch(setProductDraft(initialValue));
  },
);
