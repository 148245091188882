/* eslint-disable @typescript-eslint/no-use-before-define */
import { createSelector } from '@reduxjs/toolkit';

import { ICustomerInvoice, ICustomerPendingInvoice } from '@laudus/types';

import { AppState, ICustomerStatisticsState } from '../..';

export const getCustomersInvoicesSlice = (state: AppState): ICustomerStatisticsState =>
  state.customerStatistics;

export const getCustomersInvoices = createSelector(
  [getCustomersInvoicesSlice],
  (state) => state.invoicesList,
);

export const getCustomersPendingInvoices = createSelector(
  [getCustomersInvoicesSlice],
  (state) => state.pendingInvoicesList,
);

export const getInvoicesByCustomer = (customerId: number) =>
  createSelector(
    [getCustomersInvoices],
    (customersInvoicesDetails) => customersInvoicesDetails[customerId] || [],
  );

export const getPendingInvoicesByCustomer = (customerId: number) =>
  createSelector(
    [getCustomersPendingInvoices],
    (customersInvoicesDetails) => customersInvoicesDetails[customerId] || [],
  );

export const getSortedInvoicesByCustomer = (customerId: number) =>
  createSelector([getInvoicesByCustomer(customerId)], (invoicesFromCustomer) =>
    sortCustomerInvoices(invoicesFromCustomer),
  );

export const getSortedPendingInvoicesByCustomer = (customerId: number) =>
  createSelector([getPendingInvoicesByCustomer(customerId)], (invoicesFromCustomer) =>
    sortCustomerInvoices(invoicesFromCustomer),
  );

// UTILITY FUNCTIONS FOR SELECTORS

const instanceOfCustomerInvoice = (
  object: ICustomerInvoice | ICustomerPendingInvoice,
): object is ICustomerInvoice => 'docType_name' in object;

/**
 * Returns a copy of the array of customer invoices, sorted in this order:
 *
 * 1) By issuedDate desc
 * 2) By docType_name asc
 * 3) By docNumber desc
 *
 */
function sortCustomerInvoices<T extends ICustomerInvoice | ICustomerPendingInvoice>(
  invoices: T[],
): T[] {
  return [...invoices].sort((a, b) => {
    // Convert issuedDate to Date objects so it can be ordered
    const aDate = new Date(a.issuedDate);
    const bDate = new Date(b.issuedDate);

    // Sort by issuedDate desc
    if (aDate < bDate) {
      return 1;
    }

    if (aDate > bDate) {
      return -1;
    }

    if (instanceOfCustomerInvoice(a) && instanceOfCustomerInvoice(b)) {
      // If issuedDate are the same, sort by docType_name asc
      if (a.docType_name < b.docType_name) {
        return -1;
      }

      if (a.docType_name > b.docType_name) {
        return 1;
      }
    } else if (!instanceOfCustomerInvoice(a) && !instanceOfCustomerInvoice(b)) {
      // If issuedDate are the same, sort by docType_name asc
      if (a.docTypeName < b.docTypeName) {
        return -1;
      }

      if (a.docTypeName > b.docTypeName) {
        return 1;
      }
    }

    // If issuedDate and docType_name are the same, sort by docNumber desc
    if (a.docNumber < b.docNumber) {
      return 1;
    }

    if (a.docNumber > b.docNumber) {
      return -1;
    }

    return 0;
  });
}
