import { createReducer } from '@reduxjs/toolkit';

import { dateToStringDDMMYYYY } from '@laudus/shared-utils';
import { IBalanceSheetFilterTags, IBalanceSheetFormattedFilters } from '@laudus/types';

import {
  clearBalanceSheetFilter,
  setBalanceSheetFilter,
  setBalanceSheetFilterValues,
  setBalanceSheetIdFilter,
  setBalanceSheetTagsFilter,
} from './actions';

export interface IBalanceSheetFilterState {
  balanceSheetFilter: IBalanceSheetFormattedFilters;
  balanceSheetTagsFilter: IBalanceSheetFilterTags[];
  balanceSheetIdFilter: number;
}

export const FILTER_EMPTY: IBalanceSheetFormattedFilters = {
  showAccountsWithZeroBalance: false,
  showOnlyAccountsWithActivity: false,
  costCenterId: undefined,
  dateFrom: '1990-01-01T00:00:00',
  dateTo: dateToStringDDMMYYYY(new Date(), ''),
  showLevels: '1111111',
  bookId: '',
};

export const initialBalanceSheetFilterState: IBalanceSheetFilterState = {
  balanceSheetFilter: FILTER_EMPTY,
  balanceSheetTagsFilter: [],
  balanceSheetIdFilter: 0,
};

export const balanceSheetFiltersReducer = createReducer(
  initialBalanceSheetFilterState,
  (builder) => {
    builder
      .addCase(clearBalanceSheetFilter, (state) => {
        return { ...state, balanceSheetFilter: FILTER_EMPTY };
      })
      .addCase(setBalanceSheetFilter, (state, action) => {
        return { ...state, balanceSheetFilter: action.payload };
      })
      .addCase(setBalanceSheetFilterValues, (state, action) => {
        return {
          ...state,
          balanceSheetFilter: {
            ...state.balanceSheetFilter,
            ...action.payload,
          },
        };
      })
      .addCase(setBalanceSheetTagsFilter, (state, action) => {
        return { ...state, ledgerTagsFilter: action.payload };
      })
      .addCase(setBalanceSheetIdFilter, (state, action) => {
        return { ...state, balanceSheetIdFilter: action.payload };
      })
      .addDefaultCase((state) => state);
  },
);
