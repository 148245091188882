import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  clearAlert,
  clearAllAlerts,
  getNextAlert,
  useGlobalDispatch,
  useGlobalSelector,
} from '@laudus/redux-global';
import { AlertsProvider as BaseAlertsProvider } from '@laudus/shared-ui';

import { SELECT_COMPANY_PATH } from '../../../router/constants';

import { AlertWarning } from './AlertWarning';

export const AlertProvider = () => {
  const navigate = useNavigate();
  const intl = useIntl();
  const dispatch = useGlobalDispatch();
  const alert = useGlobalSelector(getNextAlert);

  return (
    <BaseAlertsProvider
      alert={alert}
      clearAlert={(alert) => {
        dispatch(clearAlert(alert));
      }}
      AlertPrompt={(params) => (
        <AlertWarning
          {...params}
          acceptText={intl.formatMessage({ id: 'alerts.accept' })}
          onAccept={() => {
            if (alert.isCritical) {
              dispatch(clearAllAlerts());
              navigate(SELECT_COMPANY_PATH);
              return;
            }
            dispatch(clearAlert(alert));
          }}
        >
          {alert.message}
        </AlertWarning>
      )}
    />
  );
};
