export const ROOT_PATH = '/';

export const LOGIN_PATH = `${ROOT_PATH}login`;

export const COMPANY_PATH = `${ROOT_PATH}:VATId`;

export const SELECT_COMPANY_QUERY_PARAM = 'selectCompany';
export const SELECT_COMPANY_PATH = `${ROOT_PATH}${SELECT_COMPANY_QUERY_PARAM}`;

export const PASSWORD_RESET_PATH = `${ROOT_PATH}passwordReset`;

export const ACCOUNT_MANAGEMENT_PATH = `${COMPANY_PATH}/account`;

export const GLOBAL_AUTH_PATH = '/accounts/security/';
