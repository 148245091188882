import { createReducer } from '@reduxjs/toolkit';

import { dateToLocalISOString, getNegativeUniqueNumericId } from '@laudus/shared-utils';
import { IStockTransfer } from '@laudus/types';

import {
  clearStockTransfer,
  clearStockTransferDraft,
  duplicateStockTransfer,
  removeStockTransferFromList,
  setStockTransfer,
  setStockTransferDraft,
  setStockTransferDraftValues,
  setStockTransferList,
  updateStockTransferList,
} from './actions';

export interface IStockTransfersState {
  current: IStockTransfer;
  draft: IStockTransfer;
  list: IStockTransfer[];
}

export const STOCK_TRANSFER_EMPTY: IStockTransfer = {
  stockTransferId: 0,
  warehouseFrom: {
    name: '',
    warehouseId: '',
  },
  warehouseTo: {
    name: '',
    warehouseId: '',
  },
  items: [],
  date: dateToLocalISOString(new Date()),
};

export const initialStockTransfersState: IStockTransfersState = {
  current: STOCK_TRANSFER_EMPTY,
  draft: STOCK_TRANSFER_EMPTY,
  list: [],
};

export const stockTransfersReducer = createReducer(initialStockTransfersState, (builder) => {
  builder
    .addCase(clearStockTransfer, (state) => {
      return { ...state, current: STOCK_TRANSFER_EMPTY };
    })
    .addCase(clearStockTransferDraft, (state) => {
      return { ...state, draft: STOCK_TRANSFER_EMPTY };
    })
    .addCase(setStockTransfer, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setStockTransferDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setStockTransferDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setStockTransferList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateStockTransferList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((d) => d.stockTransferId !== action.payload.stockTransferId),
          action.payload,
        ],
      };
    })
    .addCase(removeStockTransferFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.stockTransferId !== action.payload),
      };
    })
    .addCase(duplicateStockTransfer, (state, action) => {
      const { stockTransferId, items, ...duplicatedStockTransfer } = action.payload;

      // Reset all itemIds
      const newItems = items.map((item) => ({
        ...item,
        itemId: getNegativeUniqueNumericId(),
      }));
      return {
        ...state,
        draft: { ...duplicatedStockTransfer, items: newItems },
      };
    })
    .addDefaultCase((state) => state);
});
