import { AxiosResponse } from 'axios';

import { IEmployee, IEmployeeListItem } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchEmployeesListFromAPI = (): Promise<AxiosResponse<IEmployeeListItem[]>> =>
  axios.post(
    `${VITE_API_URL}/HR/employees/list`,
    {
      fields: [
        'firstName',
        'employeeId',
        'lastName1',
        'lastName2',
        'VATId',
        'department',
        'email',
        'contractTerminationDate',
        'paymentType.code',
        'paymentType.description',
      ],
      orderBy: [{ field: 'firstName', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchEmployeeFromAPI = (employeeId: number): Promise<AxiosResponse<IEmployee>> =>
  axios.get(`${VITE_API_URL}/HR/employees/${employeeId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createEmployeeFromAPI = (employee: IEmployee): Promise<AxiosResponse<IEmployee>> =>
  axios.post(`${VITE_API_URL}/HR/employees`, employee);

export const updateEmployeeFromAPI = (employee: IEmployee): Promise<AxiosResponse<IEmployee>> =>
  axios.put(`${VITE_API_URL}/HR/employees/${employee.employeeId}`, employee);

export const deleteEmployeeFromAPI = (employeeId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/HR/employees/${employeeId}`);

export const fetchActiveEmployeeListFromAPI = (): Promise<AxiosResponse<IEmployeeListItem[]>> =>
  axios.post(
    `${VITE_API_URL}/HR/employees/list`,
    {
      fields: [
        'firstName',
        'employeeId',
        'lastName1',
        'lastName2',
        'VATId',
        'department',
        'email',
        'contractTerminationDate',
      ],
      filterBy: [
        {
          field: 'contractTerminationDate',
          operator: '=',
          value: null,
        },
      ],
      orderBy: [{ field: 'employeeId', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
