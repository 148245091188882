import { AxiosResponse } from 'axios';

import {
  IAccount,
  IAccountRelatedTo,
  IBalanceSheet,
  IBalanceSheetFormattedFilters,
} from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchAccountsFromAPI = (): Promise<AxiosResponse<IAccount[]>> =>
  axios.post(
    `${VITE_API_URL}/accounting/accounts/list`,
    {
      fields: ['accountId', 'accountNumber', 'name'],
      orderBy: [{ field: 'accountNumber', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchAccountFromAPI = (accountId: number): Promise<AxiosResponse<IAccount>> =>
  axios.get(`${VITE_API_URL}/accounting/accounts/${accountId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createAccountFromAPI = (account: IAccount): Promise<AxiosResponse<IAccount>> =>
  axios.post(`${VITE_API_URL}/accounting/accounts`, account);

export const updateAccountFromAPI = (account: IAccount): Promise<AxiosResponse<IAccount>> =>
  axios.put(`${VITE_API_URL}/accounting/accounts/${account.accountId}`, account);

export const deleteAccountFromAPI = (accountId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/accounting/accounts/${accountId}`);

//BALANCESHEET

export const getBalanceSheetTotalAPI = (
  filter: IBalanceSheetFormattedFilters,
): Promise<AxiosResponse<IBalanceSheet>> => {
  return axios.get(`${VITE_API_URL}/accounting/balanceSheet/totals`, {
    params: filter,
  });
};

export const getBalanceSheetStandardAPI = (
  filter: IBalanceSheetFormattedFilters,
): Promise<AxiosResponse<IBalanceSheet>> => {
  return axios.get(`${VITE_API_URL}/accounting/balanceSheet/standard`, {
    params: filter,
  });
};

export const getBalanceSheet8ColumnsAPI = (
  filter: IBalanceSheetFormattedFilters,
): Promise<AxiosResponse<IBalanceSheet>> => {
  return axios.get(`${VITE_API_URL}/accounting/balanceSheet/8Columns`, {
    params: filter,
  });
};

//RELATED TO

export const getRelatedToAPI = (VATId: string): Promise<AxiosResponse<IAccountRelatedTo>> => {
  return axios.get(`${VITE_API_URL}/accounting/relatedTo`, {
    params: { VATId: VATId },
  });
};
