import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IIsapre } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Simple actions
export const clearIsapre = createAction('ISAPRE/CLEAR');

export const clearIsapreDraft = createAction('ISAPRE/CLEAR_DRAFT');

export const setIsapre = createAction<IIsapre>('ISAPRE/SET_OUTPUT');

export const setIsapreDraft = createAction<IIsapre>('ISAPRE/SET_DRAFT');

export const setIsapreDraftValues = createAction<Partial<IIsapre>>('ISAPRE/SET_DRAFT_VALUE');

export const setIsapresList = createAction<IIsapre[]>('ISAPRE/SET_LIST');

export const updateIsapresList = createAction<IIsapre>('ISAPRE/UPDATE_LIST');

export const removeIsapreFromList = createAction<string>('ISAPRE/REMOVE_FROM_LIST');

export const duplicateIsapre = createAction<IIsapre>('ISAPRE/DUPLICATE');

// Complex actions
export const fetchIsapresList = createAsyncThunk<void, void, AppThunkConfig>(
  'ISAPRE/FETCH_ISAPRE_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('isapres'));
    try {
      const { data } = await api.isapres.fetchIsapresListFromAPI();
      dispatch(setIsapresList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('isapres'));
    }
  },
);

export const fetchIsapre = createAsyncThunk<void, string, AppThunkConfig>(
  'ISAPRE/FETCH_INPUT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('isapres'));
    try {
      const { data } = await api.isapres.fetchIsapreFromAPI(id);
      dispatch(setIsapre(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('isapres'));
    }
  },
);

export const createIsapre = createAsyncThunk<void, IIsapre, AppThunkConfig>(
  'ISAPRE/CREATE',
  async (isapre, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('isapres'));
    try {
      const { data } = await api.isapres.createIsapreFromAPI(isapre);
      dispatch(setIsapre(data));
      dispatch(fetchIsapresList());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('isapres'));
    }
  },
);

export const updateIsapre = createAsyncThunk<void, IIsapre, AppThunkConfig>(
  'ISAPRE/FETCH',
  async (isapre, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('isapres'));
    try {
      const { data } = await api.isapres.updateIsapreFromAPI(isapre);
      dispatch(setIsapre(data));
      dispatch(fetchIsapresList());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('isapres'));
    }
  },
);

export const deleteIsapre = createAsyncThunk<void, string, AppThunkConfig>(
  'ISAPRE/DELETE_ISAPRE',
  async (isapreId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('isapres'));
    try {
      await api.isapres.deleteIsapreFromAPI(isapreId);
      dispatch(removeIsapreFromList(isapreId));
      dispatch(clearIsapre());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.delete',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('isapres'));
    }
  },
);
export const saveIsapre = createAsyncThunk<void, IIsapre, AppThunkConfig>(
  'ISAPRE/SAVE',
  async (isapre, ThunkAPI) => {
    const { dispatch } = ThunkAPI;

    // if the ISAPRE has an id, it means it already exists and we should update it
    if (isapre.isapreId) {
      dispatch(updateIsapre(isapre));
    } else {
      // if the ISAPRE does not have an id, it means it is a new AFP and we should create it
      dispatch(createIsapre(isapre));
    }
    dispatch(clearIsapreDraft());
  },
);
