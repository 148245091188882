import { createSelector } from '@reduxjs/toolkit';

import { AppState, IIsapresState } from '../..';

export const getIsapresSlice = (state: AppState): IIsapresState => state.isapres;

export const getIsapresList = createSelector([getIsapresSlice], (state) => state.list);

export const getIsapre = createSelector([getIsapresSlice], (state) => state.current);

export const getIsapreDraft = createSelector([getIsapresSlice], (state) => state.draft);
