import { createReducer } from '@reduxjs/toolkit';

import { IEtag } from '@laudus/types';

import { clearEtagsError, setEtagsCurrentEtag, setEtagsError, setEtagsNext } from '.';

export interface IEtagsState {
  error: boolean;
  current: IEtag[];
  next: IEtag[];
}

export const initialEtagsState: IEtagsState = {
  error: false,
  current: [],
  next: [],
};

export const etagsReducer = createReducer(initialEtagsState, (builder) => {
  builder
    .addCase(clearEtagsError, (state) => {
      return { ...state, error: false };
    })
    .addCase(setEtagsError, (state, action) => {
      return { ...state, error: action.payload };
    })
    .addCase(setEtagsCurrentEtag, (state, action) => {
      return action.payload
        ? {
            ...state,
            current: [
              ...(state.current?.filter((eTag) => eTag?.entity !== action.payload?.entity) ?? []),
              action.payload,
            ],
          }
        : state;
    })
    .addCase(setEtagsNext, (state, action) => {
      return { ...state, next: action.payload };
    })
    .addDefaultCase((state) => state);
});
