import { createReducer } from '@reduxjs/toolkit';

import { ISupplier } from '@laudus/types';

import {
  clearSupplier,
  clearSupplierDraft,
  duplicateSupplier,
  removeSupplierFromList,
  setSupplier,
  setSupplierDraft,
  setSupplierDraftValues,
  setSuppliersList,
  updateSuppliersList,
} from './actions';

export const SUPPLIER_TAB_ID = 'supplier';

export interface ISuppliersState {
  current: ISupplier;
  draft: ISupplier;
  list: ISupplier[];
}

export const SUPPLIER_EMPTY: ISupplier = {
  name: '',
  legalName: '',
  VATId: '',
};

export const initialSuppliersState: ISuppliersState = {
  current: SUPPLIER_EMPTY,
  draft: SUPPLIER_EMPTY,
  list: [],
};

export const suppliersReducer = createReducer(initialSuppliersState, (builder) => {
  builder
    .addCase(clearSupplier, (state) => {
      return { ...state, current: SUPPLIER_EMPTY };
    })
    .addCase(clearSupplierDraft, (state) => {
      return { ...state, draft: SUPPLIER_EMPTY };
    })
    .addCase(setSupplier, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setSupplierDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setSupplierDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setSuppliersList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateSuppliersList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.supplierId !== action.payload.supplierId),
          action.payload,
        ],
      };
    })
    .addCase(removeSupplierFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.supplierId !== action.payload),
      };
    })
    .addCase(duplicateSupplier, (state, action) => {
      const { supplierId, ...duplicatedSuppliers } = action.payload;
      return { ...state, draft: duplicatedSuppliers };
    })
    .addDefaultCase((state) => state);
});
