import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';
import { ICustomersState } from '../customers';

export const getCustomersSlice = (state: AppState): ICustomersState => state.customers;

export const getCustomersList = createSelector([getCustomersSlice], (state) => state.list);

export const getCustomer = createSelector([getCustomersSlice], (state) => state.current);

export const getCustomerProductsWithPriceOverride = createSelector(
  [getCustomersSlice],
  (state) => state.productsWithPricesOverride,
);

export const getCustomerDraft = createSelector([getCustomersSlice], (state) => state.draft);

export const getCustomersImportSucceeded = createSelector(
  [getCustomersSlice],
  (state) => state.importSucceeded,
);

export const getCustomersImportList = createSelector(
  [getCustomersSlice],
  (state) => state.importList,
);

export const getSRIFormState = createSelector([getCustomersSlice], (state) => state.formState);

export const getCustomerFormState = createSelector([getCustomersSlice], (state) => state.formState);

export const getIsCustomerSelected = createSelector(
  [getCustomer],
  (customer) => Object.keys(customer).length,
);
