import { createSelector } from '@reduxjs/toolkit';

import { IPurchasePayment } from '@laudus/types';

import { AppState } from '../../store';

import {
  paymentOtherDocumentItemToGridItem,
  paymentPurchaseInvoiceItemToGridItem,
} from './mappers';
import { IPurchasePaymentState } from './reducer';

export const getPurchasePaymentSlice = (state: AppState): IPurchasePaymentState =>
  state.purchasePayments;

export const getPurchasePaymentList = createSelector(
  [getPurchasePaymentSlice],
  (state) => state.list,
);

export const getPurchasePayment = createSelector(
  [getPurchasePaymentSlice],
  (state) => state.current,
);

export const getPurchasePaymentDraft = createSelector(
  [getPurchasePaymentSlice],
  (state) => state.draft,
);

export const getPurchasePaymentOtherDocumentDraft = createSelector(
  [getPurchasePaymentSlice],
  (state) => state.otherDocumentDraft,
);

export const getPurchasePaymentInvoiceDraft = createSelector(
  [getPurchasePaymentSlice],
  (state) => state.purchasesInvoicesDraft,
);

export const getPurchasePaymentIsEditing = createSelector(
  [getPurchasePaymentSlice],
  (state) => state.editing,
);

export const getPurchasePaymentEntity = createSelector(
  [getPurchasePaymentDraft, getPurchasePayment, getPurchasePaymentIsEditing],
  (draft, current, editing) => {
    return editing ? draft : current;
  },
);

export const getPurchasePaymentTableData = createSelector(
  [getPurchasePaymentEntity],
  (entity: IPurchasePayment) => {
    const itemsFromPurchaseInvoices = (entity.purchaseInvoices ?? []).map(
      paymentPurchaseInvoiceItemToGridItem,
    );
    const itemsFromOtherDocuments = (entity.otherDocuments ?? []).map(
      paymentOtherDocumentItemToGridItem,
    );

    return [...itemsFromPurchaseInvoices, ...itemsFromOtherDocuments];
  },
);
