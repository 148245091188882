import { AxiosResponse } from 'axios';

import { IProductCategory } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchProductCategoryListFromAPI = (): Promise<AxiosResponse<IProductCategory[]>> =>
  axios.post(
    `${VITE_API_URL}/production/products/categories/list`,
    {
      fields: ['name', 'productCategoryId', 'fullPath', 'parentId'],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchProductCategoryFromAPI = (
  productCategoryId: string,
): Promise<AxiosResponse<IProductCategory>> =>
  axios.get(`${VITE_API_URL}/production/products/categories/${productCategoryId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createProductCategoryFromAPI = (
  productCategory: IProductCategory,
): Promise<AxiosResponse<IProductCategory>> =>
  axios.post(`${VITE_API_URL}/production/products/categories`, productCategory);

export const updateProductCategoryFromAPI = (
  productCategory: IProductCategory,
): Promise<AxiosResponse<IProductCategory>> =>
  axios.put(
    `${VITE_API_URL}/production/products/categories/${productCategory.productCategoryId}`,
    productCategory,
  );

export const deleteProductCategoryFromAPI = (productCategoryId: string): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/production/products/categories/${productCategoryId}`);
