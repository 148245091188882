import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { ICostCenter, IFetchWithEtagParams } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const costCentersPrefix = 'COST_CENTERS';
export const COSTCENTERS_TAB_ID = 'costCenters';
interface ICostCentersTabParams {
  title?: string;
}

export const addHomeCostCentersTab = (costCentersTab?: ICostCentersTabParams) =>
  addTab({
    tab: {
      id: COSTCENTERS_TAB_ID,
      title: costCentersTab?.title ?? intl.formatMessage({ id: 'costCenters.tabTitle' }),
      path: 'pages/CostCenters/CostCenters',
      isRemovable: true,
    },
  });

//Simple actions

export const clearCostCenter = createAction(`${costCentersPrefix}/CLEAR`);
export const setCostCenterError = createAction<unknown>(`${costCentersPrefix}/SET_ERROR`);

export const setCostCenter = createAction<ICostCenter>(`${costCentersPrefix}/SET`);

export const setCostCenterList = createAction<ICostCenter[]>(`${costCentersPrefix}/SET_LIST`);

export const updateCostCenterList = createAction<ICostCenter>(`${costCentersPrefix}/UPDATE_LIST`);

export const removeCostCenterFromList = createAction<string>(
  `${costCentersPrefix}/REMOVE_FROM_LIST`,
);

export const duplicateCostCenter = createAction<ICostCenter>(`${costCentersPrefix}/DUPLICATE`);

export const clearCostCenterDraft = createAction(`${costCentersPrefix}/CLEAR_DRAFT`);

export const setCostCenterDraft = createAction<ICostCenter>(`${costCentersPrefix}/SET_DRAFT`);

export const setCostCenterDraftValues = createAction<Partial<ICostCenter>>(
  `${costCentersPrefix}/SET_DRAFT_VALUE`,
);

export const resetCostCenterSubmit = createAction(`${costCentersPrefix}/RESET_SUBMIT`);

export const setCostCenterSubmitting = createAction(`${costCentersPrefix}/SET_SUBMITTING`);

export const setCostCenterSubmitError = createAction(`${costCentersPrefix}/SET_SUBMIT_ERROR`);

export const setCostCenterSubmitSuccess = createAction(`${costCentersPrefix}/SET_SUBMIT_SUCCESS`);

export const fetchCostCenterList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  `${costCentersPrefix}/FETCH_LIST`,
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(startRequest('cost-centers'));
      }

      const { data } = await api.costCenters.fetchCostCenterListFromAPI();
      dispatch(setCostCenterList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(setCostCenterError(error));
      dispatch(setCostCenterList([]));
    } finally {
      if (!silent) {
        dispatch(endRequest('cost-centers'));
      }
    }
  },
);

export const createCostCenter = createAsyncThunk<void, ICostCenter, AppThunkConfig>(
  `${costCentersPrefix}/CREATE`,
  async (costCenter, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('cost-centers'));
    dispatch(setCostCenterSubmitting());
    try {
      const { data } = await api.costCenters.createCostCenterFromAPI(costCenter);

      dispatch(setCostCenterDraft(data));
      dispatch(updateCostCenterList(data));
      dispatch(setCostCenterSubmitSuccess());

      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'costCenter.successToast.save' }),
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'costCenter',
          action: 'save',
        }),
      );
      dispatch(setCostCenterSubmitError());
    } finally {
      dispatch(endRequest('cost-centers'));
    }
  },
);

export const updateCostCenter = createAsyncThunk<void, ICostCenter, AppThunkConfig>(
  `CUSTOMER_CATEGORY/UPDATE`,
  async (costCenter, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('cost-centers'));
    dispatch(setCostCenterSubmitting());
    try {
      const { data } = await api.costCenters.updateCostCenterFromAPI(costCenter);

      dispatch(setCostCenterDraft(data));
      dispatch(updateCostCenterList(data));
      dispatch(setCostCenterSubmitSuccess());

      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'costCenter.successToast.save' }),
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'costCenter',
          action: 'save',
        }),
      );
      dispatch(setCostCenterSubmitError());
    } finally {
      dispatch(endRequest('cost-centers'));
    }
  },
);

export const deleteCostCenter = createAsyncThunk<void, string, AppThunkConfig>(
  `CUSTOMER_CATEGORY/DELETE`,
  async (costCenterId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('cost-centers'));
    try {
      await api.costCenters.deleteCostCenterFromAPI(costCenterId);

      dispatch(clearCostCenter());
      dispatch(removeCostCenterFromList(costCenterId));

      dispatch(
        showToastAlert({
          title: intl.formatMessage({ id: 'costCenter.successToast.delete' }),
          type: 'success',
        }),
      );
      dispatch(addHomeCostCentersTab());
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'costCenter',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('cost-centers'));
    }
  },
);
