import { createReducer } from '@reduxjs/toolkit';

import { IUserCertificate, IUserCertificateInfo } from '@laudus/types';

import {
  clearUserCertificate,
  clearUserCertificateDraft,
  setUserCertificate,
  setUserCertificateImport,
  setUserCertificateInfo,
} from './actions';

export interface IUsersCertificateState {
  current: IUserCertificateInfo;
  draft: IUserCertificateInfo;
  certificate: IUserCertificate;
  importCertificate: any;
}

export const USER_CERTIFICATE_EMPTY: IUserCertificate = {
  userId: '',
  certificatePFX: '',
  password: '',
};

export const USER_CERTIFICATE_INFO_EMPTY: IUserCertificateInfo = {
  userId: '',
  issuedAt: '',
  expiration: '',
  subject: '',
};

export const initialUsersCertificateState: IUsersCertificateState = {
  current: USER_CERTIFICATE_INFO_EMPTY,
  draft: USER_CERTIFICATE_INFO_EMPTY,
  certificate: USER_CERTIFICATE_EMPTY,
  importCertificate: [],
};

export const usersCertificateReducer = createReducer(initialUsersCertificateState, (builder) => {
  builder
    .addCase(clearUserCertificate, (state) => {
      return {
        ...state,
        current: USER_CERTIFICATE_INFO_EMPTY,
      };
    })
    .addCase(clearUserCertificateDraft, (state) => {
      return {
        ...state,
        draft: USER_CERTIFICATE_INFO_EMPTY,
      };
    })
    .addCase(setUserCertificateInfo, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setUserCertificate, (state, action) => {
      return { ...state, certificate: action.payload };
    })
    .addCase(setUserCertificateImport, (state, action) => {
      return { ...state, importCertificate: action.payload };
    })
    .addDefaultCase((state) => state);
});
