import { createSelector } from '@reduxjs/toolkit';

import { AppState } from '../../store';

import { IHolidaysState } from './reducer';

export const getHolidaysSlice = (state: AppState): IHolidaysState => state.holidays;

export const getHolidaysList = createSelector([getHolidaysSlice], (state) => state.list);

export const getHoliday = createSelector([getHolidaysSlice], (state) => state.current);

export const getHolidayDraft = createSelector([getHolidaysSlice], (state) => state.draft);
