import { createReducer } from '@reduxjs/toolkit';

import { IAFP } from '@laudus/types';

import {
  clearAFP,
  clearAFPDraft,
  removeAFPFromList,
  setAFP,
  setAFPDraft,
  setAFPDraftValues,
  setAFPsList,
  updateAFPsList,
} from '.';

export const PREVISIONALINSTITUTIONS_TAB_ID = 'previsionalInstitutions';

export interface IAFPsState {
  current: IAFP;
  draft: IAFP;
  list: IAFP[];
}

export const AFP_EMPTY: IAFP = {
  AFPId: '',
  name: '',
  legalName: '',
  legalNameFund: '',
  percentage: 0,
  previredId: '',
  electronicBookId: '',
  notes: '',
};

export const initialAFPsState: IAFPsState = {
  current: AFP_EMPTY,
  draft: AFP_EMPTY,
  list: [],
};

export const afpsReducer = createReducer(initialAFPsState, (builder) => {
  builder
    .addCase(clearAFP, (state) => {
      return { ...state, current: AFP_EMPTY };
    })
    .addCase(clearAFPDraft, (state) => {
      return { ...state, draft: AFP_EMPTY };
    })
    .addCase(setAFP, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setAFPDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setAFPDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setAFPsList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateAFPsList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.AFPId !== action.payload.AFPId), action.payload],
      };
    })
    .addCase(removeAFPFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.AFPId !== action.payload),
      };
    })
    .addDefaultCase((state) => state);
});
