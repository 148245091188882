import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { ILedgerFormattedFilters, ILedgerItems } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

export const LEDGER_TAB_ID = 'ledger';

interface ILedgerTabCreatorArgs {
  title?: string;
  ledgerId?: number;
}

export const addHomeLedgerTab = ({ title }: ILedgerTabCreatorArgs = {}) =>
  addTab({
    tab: {
      id: LEDGER_TAB_ID,
      title: title ?? intl.formatMessage({ id: 'ledger.tabTitle' }),
      path: 'pages/Ledger/Ledger',
      isRemovable: true,
    },
  });

// Simple actions
export const setLedger = createAction<ILedgerItems[]>('LEDGERS/SET_LEDGER');
export const resetLedger = createAction('LEDGERS/RESET_LEDGER');

export const fetchLedger = createAsyncThunk<void, ILedgerFormattedFilters, AppThunkConfig>(
  'LEDGERS/FETCH_LEDGER',
  async (ledgerParams, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('ledgers'));
    try {
      const { data } = await api.ledgers.fetchLedgerAPI(ledgerParams);

      dispatch(setLedger(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'ledger',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('ledgers'));
    }
  },
);

export const fetchGroupedLedger = createAsyncThunk<void, ILedgerFormattedFilters, AppThunkConfig>(
  'LEDGERS/FETCH_GROUPED_LEDGER',
  async (ledgerParams, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('ledgers'));
    try {
      const { data } = await api.ledgers.fetchGroupedLedgerAPI(ledgerParams);

      dispatch(setLedger(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'ledger',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('ledgers'));
    }
  },
);
