import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IAccountType, IAccountTypeList } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert } from '../alerts';
import { endRequest, startRequest } from '../global';
import { addTab } from '../tabs';

export const ACCOUNTTYPES_TAB_ID = 'accountTypes';

interface IAccountTypesTabParams {
  title?: string;
}

export const addHomeAccountTypesTab = (accountTypeTab?: IAccountTypesTabParams) =>
  addTab({
    tab: {
      id: ACCOUNTTYPES_TAB_ID,
      title: accountTypeTab?.title ?? intl.formatMessage({ id: 'accountTypes.tabTitle' }),
      path: 'pages/AccountTypes/AccountTypes',
      isRemovable: true,
    },
  });

export const clearAccountType = createAction('ACCOUNTTYPES/CLEAR');

export const setAccountType = createAction<IAccountType>('ACCOUNTTYPES/SET');

export const setAccountTypesList = createAction<IAccountTypeList>('ACCOUNTTYPES/SET_LIST');

export const updateAccountTypesList = createAction<IAccountTypeList>('ACCOUNTTYPES/UPDATE_LIST');

export const updateAccountTypesListDraft = createAction<IAccountTypeList>(
  'ACCOUNTTYPES/UPDATE_LISTDRAFT',
);

export const clearAccountTypeDraft = createAction(`ACCOUNTTYPES/CLEAR_DRAFT`);
export const clearAccountTypesListDraft = createAction(`ACCOUNTTYPES/CLEAR_LISTDRAFT`);

export const setAccountTypeDraft = createAction<IAccountType>('ACCOUNTTYPES/SET_DRAFT');

export const setAccountTypesListDraft = createAction<IAccountTypeList>(
  'ACCOUNTTYPES/SET_LISTDRAFT',
);

export const setAccountTypeDraftValues = createAction<Partial<IAccountType>>(
  'ACCOUNTTYPES/SET_DRAFT_VALUE',
);

export const setAccountTypesListDraftValues = createAction<Partial<IAccountType>>(
  'ACCOUNTTYPES/SET_LISTDRAFT_VALUE',
);

export const resetAccountTypeSubmit = createAction('ACCOUNTTYPES/RESET_SUBMIT');

export const setAccountTypeSubmitting = createAction('ACCOUNTTYPES/SET_SUBMITTING');

export const setAccountTypeSubmitError = createAction('ACCOUNTTYPES/SET_SUBMIT_ERROR');

export const setAccountTypeSubmitSuccess = createAction('ACCOUNTS/SET_SUBMIT_SUCCESS');

export const fetchAccountTypesList = createAsyncThunk<void, void, AppThunkConfig>(
  'ACCOUNTTYPES/FETCH_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('account-types'));

      const { data } = await api.accountTypes.fetchAccountTypesFromAPI();
      dispatch(setAccountTypesList(Array.isArray(data) ? data : []));
      dispatch(setAccountTypesListDraft(Array.isArray(data) ? data : []));

      //dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'accountTypes',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('account-types'));
    }
  },
);

export const fetchAccountType = createAsyncThunk<void, string, AppThunkConfig>(
  'ACCOUNTTYPES/FETCH',
  async (accountTypeId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('account-types'));
    try {
      const { data } = await api.accountTypes.fetchAccountTypeFromAPI(accountTypeId);

      dispatch(setAccountType(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'accountTypes',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('account-types'));
    }
  },
);

export const updateAccountType = createAsyncThunk<void, IAccountType, AppThunkConfig>(
  'ACCOUNTTYPES/UPDATE',
  async (accountType, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('account-types'));
    try {
      const { data } = await api.accountTypes.updateAccountTypeFromAPI(accountType);

      dispatch(setAccountTypeDraft(data));

      dispatch(
        showAlert({
          id: `${Date.now()}`,
          isToast: true,
          title: intl.formatMessage({ id: 'accountTypes.successToast.save' }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'accountTypes',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('account-types'));
    }
  },
);
