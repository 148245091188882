import { intl } from '@laudus/intl';
import { IReportsLeftMenuItem, ReportsLeftMenuItemTypes } from '@laudus/types';

export const ACCOUNTING_FIXED_ASSETS_REPORTS: IReportsLeftMenuItem[] = [
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.fixedAssetsCards' }),
    config: {
      name: 'activos fijos_ta',
      type: 'pdf',
      filters: ['year', 'account', 'location'],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({ id: 'reports.report.title.annualReportByAccount.withCM' }),
    config: {
      name: 'activos fijos, informe por cuenta con cm_ta',
      type: 'pdf',
      filters: ['month', 'year'],
    },
  },
  {
    type: ReportsLeftMenuItemTypes.Report,
    title: intl.formatMessage({
      id: 'reports.report.title.annualReportByAccount.withoutCM',
    }),
    config: {
      name: 'activos fijos, informe por cuenta sin cm_ta',
      type: 'pdf',
      filters: ['month', 'year'],
    },
  },
];
