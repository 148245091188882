import { createReducer } from '@reduxjs/toolkit';

import { IDailyItems, IFormState } from '@laudus/types';

import {
  clearDaily,
  resetDailySubmit,
  setDaily,
  setDailySubmitSuccess,
  setDailySubmitting,
} from './actions';

const initialFormState = {
  submitting: false,
  submitError: false,
  submitSuccess: false,
};

export interface IDailyState {
  list: IDailyItems[];
  formState: IFormState;
}

export const DAILY_EMPTY: IDailyItems[] = [];

export const initialDailyState: IDailyState = {
  list: DAILY_EMPTY,
  formState: {
    submitting: false,
    submitError: false,
    submitSuccess: false,
  },
};

export const DAILY_TAB_ID = 'daily';

export const dailyReducer = createReducer(initialDailyState, (builder) => {
  builder
    .addCase(clearDaily, (state) => {
      return { ...state, list: DAILY_EMPTY };
    })
    .addCase(setDaily, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(resetDailySubmit, (state) => {
      return { ...state, formState: initialFormState };
    })
    .addCase(setDailySubmitting, (state) => {
      return { ...state, formState: { ...initialFormState, submitting: true } };
    })
    .addCase(setDailySubmitSuccess, (state) => {
      return {
        ...state,
        formState: { ...initialFormState, submitSuccess: true },
      };
    })
    .addDefaultCase((state) => state);
});
