import React, { ReactNode } from 'react';

import { Modal } from '@laudus/shared-ui';

interface IAlertProps {
  children?: JSX.Element | JSX.Element[] | string;
  isOpen: boolean;
  header: ReactNode;
  acceptIcon?: React.ReactElement;
  acceptText: string;
  widthAlertButton?: string;
  heightAlertButton?: string;
  onAccept?: () => void;
}

export const Alert = ({
  children,
  isOpen,
  header,
  acceptIcon,
  acceptText,
  widthAlertButton,
  heightAlertButton,
  onAccept,
}: IAlertProps) => {
  return (
    <Modal
      header={header}
      acceptIcon={acceptIcon}
      acceptText={acceptText}
      closeIcon={false}
      isOpen={isOpen}
      heightAlertButton={heightAlertButton}
      widthAlertButton={widthAlertButton}
      variant="message"
      size="sm"
      onAccept={onAccept}
    >
      {children}
    </Modal>
  );
};
