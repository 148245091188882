import { createSelector } from '@reduxjs/toolkit';

import { AppState, ISalesTaxesState } from '../..';

export const getSalesTaxesSlice = (state: AppState): ISalesTaxesState => state.salesTaxes;

export const getSalesTaxesList = createSelector([getSalesTaxesSlice], (state) => state.list);

export const getInvoicesSalesTaxesList = createSelector(
  [getSalesTaxesSlice],
  (state) => state.invoicesTaxList,
);

export const getSalesTax = createSelector([getSalesTaxesSlice], (state) => state.current);

export const getSalesTaxDraft = createSelector([getSalesTaxesSlice], (state) => state.draft);

export const getProductSalesTaxesList = createSelector(
  [getSalesTaxesSlice],
  (state) => state.productSalesTaxesList,
);
