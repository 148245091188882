import { AxiosResponse } from 'axios';

import { intl } from '@laudus/intl';
import { dateToLocalISOString, getMessageFromError } from '@laudus/shared-utils';
import {
  IDTEDoc,
  ISalesWaybill,
  ISalesWaybillList,
  ITraceFrom,
  QuantityDelivered,
  SendDTEEmail,
} from '@laudus/types';

import { client as axios } from '../utils/axios';

import { postDTESendEmailAPI, postDTEToSIIAPI } from './dte';

const { VITE_API_URL } = import.meta.env;

export const fetchSalesWaybillsListFromAPI = (): Promise<AxiosResponse<ISalesWaybillList>> =>
  axios.post(
    `${VITE_API_URL}/sales/waybills/list`,
    {
      fields: ['salesWaybillId', 'customer.name', 'docType.name', 'docNumber', 'issuedDate'],
      orderBy: [
        {
          field: 'issuedDate',
          direction: 'DESC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchSalesWaybillFromAPI = (
  salesWaybillId: number,
): Promise<AxiosResponse<ISalesWaybill>> =>
  axios.get(`${VITE_API_URL}/sales/waybills/${salesWaybillId}`);

export const createSalesWaybillFromAPI = (
  salesWaybill: ISalesWaybill,
): Promise<AxiosResponse<ISalesWaybill>> =>
  axios.post(`${VITE_API_URL}/sales/waybills`, salesWaybill);

export const updateSalesWaybillFromAPI = (
  salesWaybill: ISalesWaybill,
): Promise<AxiosResponse<ISalesWaybill>> =>
  axios.put(`${VITE_API_URL}/sales/waybills/${salesWaybill.salesWaybillId}`, salesWaybill);

export const deleteSalesWaybillFromAPI = (salesWaybillId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/waybills/${salesWaybillId}`);

export const fetchSalesWaybillPDFFromAPI = async (
  salesWaybillId: number,
  numberOfCopies: number,
): Promise<AxiosResponse> =>
  axios.get(
    `${VITE_API_URL}/sales/waybills/${salesWaybillId}/pdf?contentDisposition=attachment&numberOfCopies=${numberOfCopies}`,
    {
      responseType: 'arraybuffer',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/pdf',
      },
    },
  );

export async function fetchSalesWaybillPDFUrl({
  salesWaybill,
  numberOfCopies,
}: {
  salesWaybill: ISalesWaybill;
  numberOfCopies: number;
}): Promise<string> {
  const { data } = await fetchSalesWaybillPDFFromAPI(salesWaybill.salesWaybillId!, numberOfCopies);
  const blob = new Blob([new Uint8Array(data)], { type: 'application/pdf' });
  const urlBlob = URL.createObjectURL(blob);

  return urlBlob;
}

export async function sendSalesWaybillToSII(salesWaybill: ISalesWaybill): Promise<ISalesWaybill> {
  const { docNumber, docType } = salesWaybill;

  if (!docNumber || !docType) {
    console.error(
      `Sales waybill doesn't have the necessary information to be sent to SII: ${salesWaybill}`,
    );

    throw new Error(intl.formatMessage({ id: 'salesWaybills.sendToSII.fail' }));
  }

  const { docTypeId } = docType;

  if (!docTypeId) {
    console.error(`Sales waybill need to have a docTypeId to be sent to SII: ${docTypeId}`);

    throw new Error(intl.formatMessage({ id: 'salesWaybills.sendToSII.fail' }));
  }

  const documentToSend: IDTEDoc = {
    docNumber: docNumber,
    docTypeId: docTypeId,
  };
  const requestSendSII = { docs: [documentToSend] };

  const requestSendEmail: SendDTEEmail = {
    ...documentToSend,
    sendPDF: true,
  };

  // DTE/sendToSII has to be finished before calling to DTE/sendEmail
  // This is because we have to have the trackId in the database before we can send the PDF to the customer.
  // If we do not have the trackId, the electronic barCode is not going to get printed in the PDF
  const responseFromSII = await postDTEToSIIAPI(requestSendSII);

  let sentToCustomerAt: string | undefined = undefined;
  try {
    await postDTESendEmailAPI(requestSendEmail);
    sentToCustomerAt = dateToLocalISOString(new Date());
  } catch (error) {
    console.error(
      `${intl.formatMessage({
        id: 'salesWaybills.sendEmail.fail',
      })}: ${getMessageFromError({
        error,
      })}`,
    );
  }

  const { data } = responseFromSII;
  const { trackId } = data;

  const sentSalesWaybill: ISalesWaybill = {
    ...salesWaybill,
    DTE: {
      trackId: trackId ?? null,
      documentStatus: salesWaybill.DTE?.documentStatus ?? null,
      uploadStatus: salesWaybill.DTE?.uploadStatus ?? null,
      sentToCustomerAt: sentToCustomerAt ?? undefined,
    },
  };

  return sentSalesWaybill;
}

export const fetchSalesWaybillsTracesFromAPI = (
  salesWaybillId: number,
): Promise<AxiosResponse<ITraceFrom[]>> =>
  axios.get(`${VITE_API_URL}/sales/waybills/${salesWaybillId}/traceFrom`);

export const getQuantityDeliveredBySalesWaybillFromAPI = (
  salesWaybillId: number,
): Promise<AxiosResponse<QuantityDelivered>> => {
  return axios.get(`${VITE_API_URL}/sales/waybills/${salesWaybillId}/quantityDelivered`, {
    headers: {
      Accept: 'application/json',
    },
  });
};
