import { AxiosResponse } from 'axios';

import { ILot } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchLotsListAPI = (): Promise<AxiosResponse<ILot[]>> =>
  axios.post(
    `${VITE_API_URL}/production/lots/list`,
    {
      fields: ['lotId', 'lot', 'expiration', 'notes'],
      orderBy: [{ field: 'expiration', direction: 'DESC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchLotAPI = (lotId: number): Promise<AxiosResponse<ILot>> =>
  axios.get(`${VITE_API_URL}/production/lots/${lotId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createLotAPI = (lot: ILot): Promise<AxiosResponse<ILot>> =>
  axios.post(`${VITE_API_URL}/production/lots`, lot);

export const updateLotAPI = (lot: ILot): Promise<AxiosResponse<ILot>> =>
  axios.put(`${VITE_API_URL}/production/lots/${lot.lotId}`, lot);

export const deleteLotAPI = (lotId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/production/lots/${lotId}`);
