import { createReducer } from '@reduxjs/toolkit';

import { IEmployeeContractTermination } from '@laudus/types';

import {
  clearEmployeeContractTermination,
  clearEmployeeContractTerminationDraft,
  removeEmployeeContractTerminationFromList,
  setEmployeeContractTermination,
  setEmployeeContractTerminationDraft,
  setEmployeeContractTerminationDraftValues,
  setEmployeeContractTerminationsList,
  updateEmployeeContractTerminationsList,
} from './actions';

export const EMPLOYEES_CONTRACT_TERMINATIONS_TAB_ID = 'employeesContractTerminations';

export interface IEmployeeContractTerminationState {
  current: IEmployeeContractTermination;
  draft: IEmployeeContractTermination;
  list: IEmployeeContractTermination[];
}

export const EMPLOYEE_CONTRACT_TERMINATION_EMPTY: IEmployeeContractTermination = {
  employeeContractTerminationId: 0,
  date: '',
  cause: {
    code: '',
    description: '',
  },
  lines: [
    {
      lineId: 0,
      concept: {
        code: '',
        description: '',
      },
      quantity: 0,
      amount: 0,
    },
  ],
};

export const initialEmployeeContractTerminationState: IEmployeeContractTerminationState = {
  current: EMPLOYEE_CONTRACT_TERMINATION_EMPTY,
  draft: EMPLOYEE_CONTRACT_TERMINATION_EMPTY,
  list: [],
};

export const employeeCurrentTerminationReducer = createReducer(
  initialEmployeeContractTerminationState,
  (builder) => {
    builder
      .addCase(clearEmployeeContractTermination, (state) => {
        return { ...state, current: EMPLOYEE_CONTRACT_TERMINATION_EMPTY };
      })
      .addCase(clearEmployeeContractTerminationDraft, (state) => {
        return { ...state, draft: EMPLOYEE_CONTRACT_TERMINATION_EMPTY };
      })
      .addCase(setEmployeeContractTermination, (state, action) => {
        return { ...state, current: action.payload };
      })
      .addCase(setEmployeeContractTerminationDraft, (state, action) => {
        return { ...state, draft: action.payload };
      })
      .addCase(setEmployeeContractTerminationDraftValues, (state, action) => {
        return { ...state, draft: { ...state.draft, ...action.payload } };
      })
      .addCase(setEmployeeContractTerminationsList, (state, action) => {
        return { ...state, list: action.payload };
      })
      .addCase(updateEmployeeContractTerminationsList, (state, action) => {
        const updatedContract = action.payload;
        return {
          ...state,
          list: [
            ...state.list.filter(
              (l) =>
                l.employeeContractTerminationId !== updatedContract.employeeContractTerminationId,
            ),
            updatedContract,
          ],
        };
      })
      .addCase(removeEmployeeContractTerminationFromList, (state, action) => {
        return {
          ...state,
          list: state.list.filter((d) => d.employeeContractTerminationId !== action.payload),
        };
      })
      .addDefaultCase((state) => state);
  },
);
