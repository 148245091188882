import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ICellEditorParams } from '@ag-grid-community/core';
import { useColorMode } from '@chakra-ui/react';

import { colors, KEY_DOWN, KEY_END, KEY_ENTER, KEY_HOME, KEY_TAB, KEY_UP } from '@laudus/shared-ui';

interface IDateEditorComponentProps extends ICellEditorParams {
  placeholder?: string;
  name: string;
  showTime?: boolean;
  min: string;
  max: string;
}

const DateEditorComponent: React.ForwardRefRenderFunction<any, IDateEditorComponentProps> = (
  props,
  ref,
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(props.showTime ? props.value : props.value?.split('T')?.[0]);

  const { colorMode } = useColorMode();

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  const handleChange = (event: ChangeEvent<HTMLInputElement> | undefined) => {
    console.log('event = ', event?.currentTarget.value);
    setValue(event?.currentTarget.value);
  };

  const handleKeyDown = (event: any) => {
    if (
      props.context.handleKeyboardArrow &&
      (event.key === KEY_UP ||
        event.key === KEY_DOWN ||
        event.key === KEY_HOME ||
        event.key === KEY_END)
    ) {
      event.preventDefault();
      props.context.handleKeyboardArrow(event.key, props.colDef.field, 'date', props.rowIndex);
    } else if (
      props.context.handleCellKeyPress &&
      (event.key === KEY_ENTER || event.key === KEY_TAB)
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    inputRef?.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      inputRef?.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [value]);

  return (
    <input
      max={props.max}
      min={props.min}
      name={props.name}
      onChange={handleChange}
      ref={inputRef}
      style={{
        color: colorMode === 'dark' ? colors.white : colors.black,
        background: colorMode === 'dark' ? colors.darkGrey500 : colors.white, // @ts-expect-error actualWidth is a private member, not sure why?
        width: props.column?.actualWidth,
        height: '34px',
      }}
      type={props.showTime ? 'datetime-local' : 'date'}
      value={value}
    />
  );
};

DateEditorComponent.displayName = 'DateEditor';

export const DateEditor = forwardRef(DateEditorComponent);
