import { createSelector } from '@reduxjs/toolkit';

import { AppState, ILotState } from '../..';

export const getLotsSlice = (state: AppState): ILotState => state.lot;

export const getLotsList = createSelector([getLotsSlice], (state) => state.list);

export const getLot = createSelector([getLotsSlice], (state) => state.current);

export const getLotDraft = createSelector([getLotsSlice], (state) => state.draft);
