import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const InputGroup: ComponentMultiStyleConfig = {
  parts: ['field'],
  variants: {
    'base': {
      field: {
        bg: 'white',
        borderWidth: '1px 1px 1px 1px',
        borderColor: 'neutral500',
        borderRadius: '0.4rem',
        color: 'black',
        fontSize: '1.5rem',
        display: 'flex',
        height: '35px',
        _disabled: {
          bg: 'white',
          color: 'neutral500',
          borderWidth: '1px 1px 1px 1px',
          borderColor: 'neutral500',
        },
        _focus: {
          'borderColor': 'primary500',
          'borderWidth': '2px',
          '+.chakra-select__icon-wrapper': {
            color: 'error.100',
          },
        },
        _invalid: {
          'bg': 'none',
          'borderColor': 'error.100',
          '+.chakra-select__icon-wrapper': {
            color: 'error.100',
          },
          '_disabled': {
            'bg': 'neutral.10',
            'color': 'neutral500',
            'borderColor': 'error.100',
            '+.chakra-select__icon-wrapper': {
              color: 'error.100',
            },
          },
        },
      },
    },
    'input-error': {
      field: {
        bg: 'white',
        borderWidth: '1px 1px 1px 1px',
        borderColor: 'danger500',
        borderRadius: '0.4rem',
        color: 'black',
        fontSize: '1.5rem',
        display: 'flex',
        height: '35px',
      },
    },
  },
  defaultProps: {
    size: 'sm',
    variant: 'base',
  },
};
