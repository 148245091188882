import { ComponentType } from 'react';

import { Module, ModuleNames, ModuleRegistry } from '@ag-grid-community/core';

export interface RegisterModuleProps {
  component: ComponentType<any>;
  modules: Module[];
}

function RegisterModule({ modules, component: Component, ...props }: RegisterModuleProps) {
  if (modules.some((module) => !ModuleRegistry.isRegistered(module.moduleName as ModuleNames))) {
    ModuleRegistry.registerModules(modules);
  }

  return <Component {...props} />;
}

export function withModuleRegistry(modules: Module[]) {
  return function (component: ComponentType<any>) {
    return function registerModules(props: any) {
      return <RegisterModule component={component} modules={modules} {...props} />;
    };
  };
}
