import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { ISavingsPlansProvider } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Simple actions
export const clearSavingsPlansProvider = createAction('SAVINGSPLANSPROVIDER/CLEAR');

export const clearSavingsPlansProviderDraft = createAction('SAVINGSPLANSPROVIDER/CLEAR_DRAFT');

export const setSavingsPlansProvider = createAction<ISavingsPlansProvider>(
  'SAVINGSPLANSPROVIDER/SET_OUTPUT',
);

export const setSavingsPlansProviderDraft = createAction<ISavingsPlansProvider>(
  'SAVINGSPLANSPROVIDER/SET_DRAFT',
);

export const setSavingsPlansProviderDraftValues = createAction<Partial<ISavingsPlansProvider>>(
  'SAVINGSPLANSPROVIDER/SET_DRAFT_VALUE',
);

export const setSavingsPlansProvidersList = createAction<ISavingsPlansProvider[]>(
  'SAVINGSPLANSPROVIDER/SET_LIST',
);

export const updateSavingsPlansProvidersList = createAction<ISavingsPlansProvider>(
  'SAVINGSPLANSPROVIDER/UPDATE_LIST',
);

export const removeSavingsPlansProviderFromList = createAction<string>(
  'SAVINGSPLANSPROVIDER/REMOVE_FROM_LIST',
);

export const duplicateSavingsPlansProvider = createAction<ISavingsPlansProvider>(
  'SAVINGSPLANSPROVIDER/DUPLICATE',
);

// Complex actions
export const fetchSavingsPlansProvidersList = createAsyncThunk<void, void, AppThunkConfig>(
  'SAVINGSPLANSPROVIDER/FETCH_SAVINGSPLANSPROVIDER_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('savings-plans-providers'));
    try {
      const { data } = await api.savingsPlansProviders.fetchSavingsPlansProvidersListFromAPI();
      dispatch(setSavingsPlansProvidersList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('savings-plans-providers'));
    }
  },
);

export const fetchSavingsPlansProvider = createAsyncThunk<void, string, AppThunkConfig>(
  'SAVINGSPLANSPROVIDER/FETCH_INPUT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('savings-plans-providers'));
    try {
      const { data } = await api.savingsPlansProviders.fetchSavingsPlansProviderFromAPI(id);
      dispatch(setSavingsPlansProvider(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('savings-plans-providers'));
    }
  },
);

export const createSavingsPlansProvider = createAsyncThunk<
  void,
  ISavingsPlansProvider,
  AppThunkConfig
>('SAVINGSPLANSPROVIDER/CREATE', async (savingsPlansProvider, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('savings-plans-providers'));
  try {
    const { data } =
      await api.savingsPlansProviders.createSavingsPlansProviderFromAPI(savingsPlansProvider);
    dispatch(setSavingsPlansProvider(data));
    dispatch(fetchSavingsPlansProvidersList());
    dispatch(
      showToastAlert({
        title: intl.formatMessage({
          id: 'previsionalInstitutions.successToast.save',
        }),
        message: '',
        type: 'success',
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'previsionalInstitutions',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('savings-plans-providers'));
  }
});

export const updateSavingsPlansProvider = createAsyncThunk<
  void,
  ISavingsPlansProvider,
  AppThunkConfig
>('SAVINGSPLANSPROVIDER/FETCH', async (savingsPlansProvider, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('savings-plans-providers'));
  try {
    const { data } =
      await api.savingsPlansProviders.updateSavingsPlansProviderFromAPI(savingsPlansProvider);
    dispatch(setSavingsPlansProvider(data));
    dispatch(fetchSavingsPlansProvidersList());
    dispatch(
      showToastAlert({
        title: intl.formatMessage({
          id: 'previsionalInstitutions.successToast.save',
        }),
        message: '',
        type: 'success',
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'previsionalInstitutions',
        action: 'save',
      }),
    );
  } finally {
    dispatch(endRequest('savings-plans-providers'));
  }
});

export const deleteSavingsPlansProvider = createAsyncThunk<void, string, AppThunkConfig>(
  'SAVINGSPLANSPROVIDER/DELETE_SAVINGSPLANSPROVIDER',
  async (savingsPlansProviderId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('savings-plans-providers'));
    try {
      await api.savingsPlansProviders.deleteSavingsPlansProviderFromAPI(savingsPlansProviderId);
      dispatch(removeSavingsPlansProviderFromList(savingsPlansProviderId));
      dispatch(clearSavingsPlansProvider());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.delete',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('savings-plans-providers'));
    }
  },
);

export const saveSavingsPlansProvider = createAsyncThunk<
  void,
  ISavingsPlansProvider,
  AppThunkConfig
>('SAVINGSPLANSPROVIDER/SAVE', async (savingsPlansProvider, ThunkAPI) => {
  const { dispatch } = ThunkAPI;
  if (savingsPlansProvider.savingsPlansProviderId) {
    dispatch(updateSavingsPlansProvider(savingsPlansProvider));
  } else {
    dispatch(createSavingsPlansProvider(savingsPlansProvider));
  }
  dispatch(clearSavingsPlansProviderDraft());
});
