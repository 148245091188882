import { createReducer } from '@reduxjs/toolkit';

import { clearNavigateTo, navigateTo } from './actions';

export interface IRouterState {
  to: string | undefined;
}

export const initialRouterState: IRouterState = {
  to: undefined,
};

export const routerReducer = createReducer(initialRouterState, (builder) => {
  builder
    .addCase(navigateTo, (state, action) => {
      return { ...state, to: action.payload };
    })
    .addCase(clearNavigateTo, (state) => {
      return { ...state, to: undefined };
    })
    .addDefaultCase((state) => state);
});
