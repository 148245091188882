import { createReducer } from '@reduxjs/toolkit';

import { IIsapre } from '@laudus/types';

import {
  clearIsapre,
  clearIsapreDraft,
  removeIsapreFromList,
  setIsapre,
  setIsapreDraft,
  setIsapreDraftValues,
  setIsapresList,
  updateIsapresList,
} from '.';

export const ISAPRES_TAB_ID = 'isapres';

export interface IIsapresState {
  current: IIsapre;
  draft: IIsapre;
  list: IIsapre[];
}

export const ISAPRE_EMPTY: IIsapre = {
  isapreId: '',
  name: '',
  legalName: '',
  VATId: '',
  previredId: '',
  electronicBookId: '',
  notes: '',
};

export const initialIsapresState: IIsapresState = {
  current: ISAPRE_EMPTY,
  draft: ISAPRE_EMPTY,
  list: [],
};

export const isapresReducer = createReducer(initialIsapresState, (builder) => {
  builder
    .addCase(clearIsapre, (state) => {
      return { ...state, current: ISAPRE_EMPTY };
    })
    .addCase(clearIsapreDraft, (state) => {
      return { ...state, draft: ISAPRE_EMPTY };
    })
    .addCase(setIsapre, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setIsapreDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setIsapreDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setIsapresList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateIsapresList, (state, action) => {
      return {
        ...state,
        list: [...state.list.filter((p) => p.isapreId !== action.payload.isapreId), action.payload],
      };
    })
    .addCase(removeIsapreFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.isapreId !== action.payload),
      };
    })
    .addDefaultCase((state) => state);
});
