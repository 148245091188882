import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IExCaja } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Simple actions
export const clearExCaja = createAction('EXCAJA/CLEAR');

export const clearExCajaDraft = createAction('EXCAJA/CLEAR_DRAFT');

export const setExCaja = createAction<IExCaja>('EXCAJA/SET_OUTPUT');

export const setExCajaDraft = createAction<IExCaja>('EXCAJA/SET_DRAFT');

export const setExCajaDraftValues = createAction<Partial<IExCaja>>('EXCAJA/SET_DRAFT_VALUE');

export const setExCajasList = createAction<IExCaja[]>('EXCAJA/SET_LIST');

export const updateExCajasList = createAction<IExCaja>('EXCAJA/UPDATE_LIST');

export const removeExCajaFromList = createAction<number>('EXCAJA/REMOVE_FROM_LIST');

export const duplicateExCaja = createAction<IExCaja>('EXCAJA/DUPLICATE');

// Complex actions
export const fetchExCajasList = createAsyncThunk<void, void, AppThunkConfig>(
  'EXCAJA/FETCH_EXCAJA_LIST',
  async (_, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('ex-cajas'));
    try {
      const { data } = await api.exCajas.fetchExCajasListFromAPI();
      dispatch(setExCajasList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('ex-cajas'));
    }
  },
);

export const fetchExCaja = createAsyncThunk<void, number, AppThunkConfig>(
  'EXCAJA/FETCH_INPUT',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('ex-cajas'));
    try {
      const { data } = await api.exCajas.fetchExCajaFromAPI(id);
      dispatch(setExCaja(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('ex-cajas'));
    }
  },
);

export const createExCaja = createAsyncThunk<void, IExCaja, AppThunkConfig>(
  'EXCAJA/CREATE',
  async (exCaja, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('ex-cajas'));
    try {
      const { data } = await api.exCajas.createExCajaFromAPI(exCaja);
      dispatch(setExCaja(data));
      dispatch(fetchExCajasList());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('ex-cajas'));
    }
  },
);

export const updateExCaja = createAsyncThunk<void, IExCaja, AppThunkConfig>(
  'EXCAJA/FETCH',
  async (exCaja, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('ex-cajas'));
    try {
      const { data } = await api.exCajas.updateExCajaFromAPI(exCaja);
      dispatch(setExCaja(data));
      dispatch(fetchExCajasList());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('ex-cajas'));
    }
  },
);

export const deleteExCaja = createAsyncThunk<void, number, AppThunkConfig>(
  'EXCAJA/DELETE_EXCAJA',
  async (exCajaId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('ex-cajas'));
    try {
      await api.exCajas.deleteExCajaFromAPI(exCajaId);
      dispatch(removeExCajaFromList(exCajaId));
      dispatch(clearExCaja());
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'previsionalInstitutions.successToast.delete',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'previsionalInstitutions',
          action: 'delete',
        }),
      );
    } finally {
      dispatch(endRequest('ex-cajas'));
    }
  },
);

export const saveExCaja = createAsyncThunk<void, IExCaja, AppThunkConfig>(
  'EXCAJA/SAVE',
  async (exCaja, ThunkAPI) => {
    const { dispatch } = ThunkAPI;

    // if the ExCaja has an id, it means it already exists and we should update it
    if (exCaja.exCajaId) {
      dispatch(updateExCaja(exCaja));
    } else {
      // if the ExCaja does not have an id, it means it is a new ExCaja and we should create it
      dispatch(createExCaja(exCaja));
    }
    dispatch(clearExCajaDraft());
  },
);
