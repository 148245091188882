import { createSelector } from '@reduxjs/toolkit';

import { getStringDateDifference } from '@laudus/shared-utils';

import { AppState } from '../../store';

import { IProcessesState } from './reducer';

export const getProcessesSlice = (state: AppState): IProcessesState => state.processes;

export const getProcessesList = createSelector([getProcessesSlice], (state) => state.list);

export const getProcess = createSelector([getProcessesSlice], (state) => state.current);

export const getProcessDraft = createSelector([getProcessesSlice], (state) => state.draft);

export const getProcessIsEditing = createSelector([getProcessesSlice], (state) => state.editing);

export const getProcessesEntity = createSelector(
  [getProcessDraft, getProcess, getProcessIsEditing],
  (draft, current, editing) => {
    return editing ? draft : current;
  },
);

export const getProcessesInputTableData = createSelector([getProcessesEntity], (entity) => {
  return entity?.input?.items?.map((item) => ({
    ...item,
    finalCost: item.quantity * (item.unitCost ?? 0),
  }));
});

export const getProcessesOutputTableData = createSelector([getProcessesEntity], (entity) => {
  return entity?.output?.items?.map((item) => ({
    ...item,
    finalCost: item.quantity * (item.unitCost ?? 0),
  }));
});

export const getProcessesStagesTableData = createSelector([getProcessesEntity], (entity) => {
  return entity?.stages?.map((item) => ({
    ...item,
    realDuration: getStringDateDifference(item?.startDate, item?.endDate),
  }));
});
