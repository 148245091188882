import { useEffect } from 'react';
import { Navigate, Outlet, Route, Routes, useNavigate } from 'react-router-dom';

import {
  clearNavigateTo,
  getRouterNavigateTo,
  useGlobalDispatch,
  useGlobalSelector,
} from '@laudus/redux-global';

import { Login } from './pages/Login';
import { PasswordReset } from './pages/PasswordReset';
import { COMPANY_PATH, LOGIN_PATH, PASSWORD_RESET_PATH, ROOT_PATH } from './router/constants';
import { PrivateRoute } from './router/PrivateRoute';
import { PublicRoute } from './router/PublicRoute';
import { AppMounter as App } from './AppMounter';

const ConnnectedRouter = () => {
  const navigate = useNavigate();
  const navigateTo = useGlobalSelector(getRouterNavigateTo);
  const dispatch = useGlobalDispatch();

  useEffect(() => {
    if (navigateTo) {
      navigate(navigateTo, { replace: true });
      dispatch(clearNavigateTo());
    }
  }, [dispatch, navigate, navigateTo]);

  return <Outlet />;
};

export const AppRouter = () => {
  /**
   * This code is commented on until Javier makes up his mind about
   * asking or not for confirmation on application reloads
   */
  // useEffect(() => {
  //   const restrictNavEvent = (event: BeforeUnloadEvent) => {
  //     event.preventDefault();
  //     event.returnValue = '';
  //   };

  //   window.addEventListener('beforeunload', restrictNavEvent);

  //   return () => {
  //     window.removeEventListener('beforeunload', restrictNavEvent);
  //   };
  // }, []);

  return (
    <Routes>
      <Route element={<Navigate replace to={LOGIN_PATH} />} path={ROOT_PATH} />
      <Route element={<ConnnectedRouter />} path={ROOT_PATH}>
        <Route element={<PrivateRoute component={App} />} path={`${COMPANY_PATH}/*`} />
        <Route element={<PublicRoute component={Login} />} path={LOGIN_PATH} />
        <Route element={<PasswordReset />} path={PASSWORD_RESET_PATH} />
      </Route>
    </Routes>
  );
};
