import { AxiosResponse } from 'axios';

import { IAFP } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchAFPsListFromAPI = (): Promise<AxiosResponse<IAFP[]>> =>
  axios.post(
    `${VITE_API_URL}/HR/AFPs/list`,
    {
      fields: [
        'AFPId',
        'name',
        'legalName',
        'legalNameFund',
        'percentage',
        'previredId',
        'electronicBookId',
        'notes',
      ],
      orderBy: [{ field: 'name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchAFPFromAPI = (AFPId: string): Promise<AxiosResponse<IAFP>> =>
  axios.get(`${VITE_API_URL}/HR/AFPs/${AFPId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createAFPFromAPI = (afp: IAFP): Promise<AxiosResponse<IAFP>> =>
  axios.post(`${VITE_API_URL}/HR/AFPs`, afp);

export const updateAFPFromAPI = (afp: IAFP): Promise<AxiosResponse<IAFP>> =>
  axios.put(`${VITE_API_URL}/HR/AFPs/${afp.AFPId}`, afp);

export const deleteAFPFromAPI = (AFPId: string): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/HR/AFPs/${AFPId}`);
