import { Box } from '@chakra-ui/react';

interface IUnderlineProps {
  variant?: string;
  margin?: string;
}

export const Underline = ({ margin, variant = 'primary' }: IUnderlineProps) => {
  return (
    <div className="container">
      <Box className={variant} margin={margin} />
    </div>
  );
};
