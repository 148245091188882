import { ComponentMultiStyleConfig } from '@chakra-ui/react';

export const Popover: ComponentMultiStyleConfig = {
  parts: ['popover', 'trigger', 'content', 'header', 'body'],
  variants: {
    base: {
      body: {
        border: '5px solid red',
      },
    },
  },
  defaultProps: {
    size: '2.5xl',
  },
};
