import { createSelector } from '@reduxjs/toolkit';

import { DOC_TYPES_ID } from '@laudus/sales-utils';
import { IBranch, IPos } from '@laudus/types';

import { AppState } from '../../store';
import { getSettingsByName } from '../settings';

import { IPosState } from './reducer';

export const getPosSlice = (state: AppState): IPosState => state.pos;

export const getPosList = createSelector([getPosSlice], (state) => state.list);

export const getPosCurrent = createSelector([getPosSlice], (state) => state.current);

export const getPosDraft = createSelector([getPosSlice], (state) => state.draft);

export const getPosDocumentsType = createSelector([getPosCurrent], (state) => state.documentTypes);

export const getIsDocumentTypeAllowed = (docTypeId: number) =>
  createSelector(
    [
      getPosDocumentsType,
      getSettingsByName<boolean>('DTE_allow'),
      getSettingsByName<boolean>('DTE_allowTickets'),
    ],
    (posDocumentsTypes, isDTEAllow, isDTEAllowTickets) => {
      // is documentsTypes are defined and the posDocumentsTypes have to be in the list
      if (posDocumentsTypes && posDocumentsTypes.length) {
        if (!posDocumentsTypes.find((dc) => dc.docType.docTypeId === docTypeId)) {
          return false;
        }
      }

      // Invoice
      if (
        docTypeId === DOC_TYPES_ID.ELECTRONIC_INVOICE ||
        docTypeId === DOC_TYPES_ID.INVOICE ||
        docTypeId === DOC_TYPES_ID.EXEMPT_INVOICE ||
        docTypeId === DOC_TYPES_ID.EXEMPT_ELECTRONIC_INVOICE
      ) {
        if (isDTEAllow) {
          return true;
        } else {
          return docTypeId === DOC_TYPES_ID.INVOICE || docTypeId === DOC_TYPES_ID.EXEMPT_INVOICE;
        }
      }

      // Ticket
      if (
        docTypeId === DOC_TYPES_ID.ELECTRONIC_TICKET ||
        docTypeId === DOC_TYPES_ID.TICKET ||
        docTypeId === DOC_TYPES_ID.ELECTRONIC_PAYMENT_VOUCHERS_TRANSBANK ||
        docTypeId === DOC_TYPES_ID.EXEMPT_TICKET ||
        docTypeId === DOC_TYPES_ID.EXEMPT_ELECTRONIC_TICKET
      ) {
        if (isDTEAllow && isDTEAllowTickets) {
          return true;
        } else {
          return (
            docTypeId === DOC_TYPES_ID.TICKET ||
            docTypeId === DOC_TYPES_ID.EXEMPT_TICKET ||
            docTypeId === DOC_TYPES_ID.ELECTRONIC_PAYMENT_VOUCHERS_TRANSBANK
          );
        }
      }

      // Note of credits
      if (
        docTypeId === DOC_TYPES_ID.ELECTRONIC_CREDIT_NOTE ||
        docTypeId === DOC_TYPES_ID.CREDIT_NOTE
      ) {
        if (isDTEAllow) {
          return true;
        } else {
          return docTypeId === DOC_TYPES_ID.CREDIT_NOTE;
        }
      }

      if (docTypeId === DOC_TYPES_ID.OTHER_SALES_DOCUMENT) {
        return true;
      }

      return false;
    },
  );

// Return pos list that:
// - Are restricted to the selected branch
// - Return all branches if no branch is selected
export function getPosListFilteredByBranchSortedAlphabetically(branch: IBranch | null) {
  return createSelector([getPosList], (list) => {
    if (!branch) {
      return [...list].sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
    }

    const listFilteredByBranch = list.filter((pos) => pos.branch?.branchId === branch.branchId);

    return [...listFilteredByBranch].sort((a, b) => (a.name ?? '').localeCompare(b.name ?? ''));
  });
}

export const getPosOtherSalesDocumentAllowed = createSelector(
  [getPosCurrent],
  (state: IPos): boolean =>
    state.documentTypes?.some(
      (docType) => docType.docType.docTypeId === DOC_TYPES_ID.OTHER_SALES_DOCUMENT,
    ) ?? false,
);
