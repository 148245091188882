import { createSelector } from '@reduxjs/toolkit';

import { AppState, ISupplierContactsState } from '../..';

export const getSupplierContactsSlice = (state: AppState): ISupplierContactsState =>
  state.supplierContacts;

export const getSupplierContactsList = createSelector(
  [getSupplierContactsSlice],
  (state) => state.list,
);

export const getSupplierContactsListById = (supplierId?: number) =>
  createSelector([getSupplierContactsSlice], (state) => {
    return state.list && supplierId ? state.list[supplierId] : undefined;
  });

export const getSupplierContact = createSelector(
  [getSupplierContactsSlice],
  (state) => state.current,
);

export const getSupplierContactDraft = createSelector(
  [getSupplierContactsSlice],
  (state) => state.draft,
);
