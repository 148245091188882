import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { AppThunkConfig } from '../../store';

import { getSelectedTab, ITab } from '.';

interface AddTabPayload {
  tab: ITab;
  isMenu?: boolean;
}

export const addTab = createAction<AddTabPayload>('TABS/ADD_TAB');

export const removeTab = createAction<Partial<ITab>>('TABS/REMOVE_TAB');

export const removeCurrentTab = createAsyncThunk<void, void, AppThunkConfig>(
  'TABS/REMOVE_CURRENT_TAB',
  async (_, ThunkAPI) => {
    const dispatch = ThunkAPI.dispatch;
    dispatch(removeTab(getSelectedTab(ThunkAPI.getState())));
  },
);

export const selectTab = createAction<Partial<ITab>>('TABS/SELECT_TAB');

export const updateTabs = createAction<ITab[]>('TABS/UPDATE_TABS');
