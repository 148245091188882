import { AxiosResponse } from 'axios';

import { IGlobalAccount } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchGlAccountFromAPI = (
  glAccountId: number,
): Promise<AxiosResponse<IGlobalAccount>> =>
  axios.get(`${VITE_API_URL}/accounts/security/glAccounts/${glAccountId}`, {
    headers: {
      Accept: 'application/json',
    },
  });
