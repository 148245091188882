import { IPurchaseInvoicesItem } from '@laudus/types';

import { roundDecimals } from '../../format/numbers/roundDecimals';

export function transformsAndCalculatePurchaseInvoices(
  purchaseInvoicesItems: IPurchaseInvoicesItem[],
  mainCurrencyDecimals: number,
): IPurchaseInvoicesItem[] {
  return purchaseInvoicesItems.map((item) => {
    const { product, itemDescription } = item;
    const productAllowsFreeDescription = Boolean(product?.allowFreeDescription);
    const itemDescriptionIsFilled = Boolean(itemDescription);

    const itemNet = roundDecimals(item.unitCost * item.quantity, mainCurrencyDecimals);

    if (!productAllowsFreeDescription || !itemDescriptionIsFilled) {
      return {
        ...item,
        itemDescription: product?.description ?? '',
        net: itemNet,
      };
    }

    return { ...item, itemDescription, net: itemNet };
  });
}
