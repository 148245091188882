import { createSelector } from '@reduxjs/toolkit';

import { AppState, IEmployeeHistoryState } from '../..';

export const getEmployeesHistorySlice = (state: AppState): IEmployeeHistoryState =>
  state.employeeHistory;

export const getEmployeesHistoryList = createSelector(
  [getEmployeesHistorySlice],
  (state) => state.list,
);

export const getEmployeeHistory = createSelector(
  [getEmployeesHistorySlice],
  (state) => state.current,
);

export const getEmployeeHistoryDraft = createSelector(
  [getEmployeesHistorySlice],
  (state) => state.draft,
);
