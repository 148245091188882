import { AxiosResponse } from 'axios';

import { IAccountType, IAccountTypeList } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchAccountTypesFromAPI = (): Promise<AxiosResponse<IAccountTypeList>> =>
  axios.post(
    `${VITE_API_URL}/accounting/accountTypes/list`,
    {
      fields: [
        'accountTypeId',
        'description',
        'account.accountId',
        'account.accountNumber',
        'account.name',
        'isChargeableAccount',
        'notes',
      ],
      orderBy: [{ field: 'description', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

//encodeURIComponent because for example id DIFC+ is not valid for url param without encode
export const fetchAccountTypeFromAPI = (
  accountTypeId: string,
): Promise<AxiosResponse<IAccountType>> =>
  axios.get(`${VITE_API_URL}/accounting/accountTypes/${encodeURIComponent(accountTypeId)}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const updateAccountTypeFromAPI = (
  accountType: IAccountType,
): Promise<AxiosResponse<IAccountType>> =>
  axios.put(
    `${VITE_API_URL}/accounting/accountTypes/${encodeURIComponent(accountType.accountTypeId)}`,
    accountType,
  );
