import { AxiosResponse } from 'axios';

import { ICustomerAddress } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

interface IFetchCustomerAddressesListAPIParams {
  customerId: number;
  includeBillingAndGeneralAddresses?: boolean;
}
export const fetchCustomerAddressesListAPI = ({
  customerId,
  includeBillingAndGeneralAddresses = false,
}: IFetchCustomerAddressesListAPIParams): Promise<AxiosResponse<ICustomerAddress[]>> =>
  axios.get(
    `${VITE_API_URL}/sales/customers/${customerId}/addresses?includeBillingAndGeneralAddresses=${includeBillingAndGeneralAddresses}`,
  );

export const fetchCustomerAddressAPI = (
  customerId: number,
  addressId: number,
): Promise<AxiosResponse<ICustomerAddress>> =>
  axios.get(`${VITE_API_URL}/sales/customers/${customerId}/addresses/${addressId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createCustomerAddressAPI = (
  customerId: number,
  address: ICustomerAddress,
): Promise<AxiosResponse<ICustomerAddress>> =>
  axios.post(`${VITE_API_URL}/sales/customers/${customerId}/addresses`, address);

export const updateCustomerAddressAPI = (
  customerId: number,
  address: ICustomerAddress,
): Promise<AxiosResponse<ICustomerAddress>> =>
  axios.put(
    `${VITE_API_URL}/sales/customers/${customerId}/addresses/${address.addressId}`,
    address,
  );

export const deleteCustomerAddressAPI = (
  customerId: number,
  addressId: number,
): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/customers/${customerId}/addresses/${addressId}`);
