import { jsPDF } from 'jspdf';
import autoTable, { RowInput, Table } from 'jspdf-autotable';

import { helveticaNormal } from '@laudus/assets';
import { formatNumber, roundDecimals } from '@laudus/shared-utils';
import { ISalesOrder } from '@laudus/types';

import { calculateOrderTotals } from './calculateOrderTotals';

interface GenerateSalesOrderPDFProps {
  salesOrder: ISalesOrder;
  companyVATId: string;
  companyName: string;
  companyVATRate: number;
  mainCurrencyDecimals: number;
  mainCurrencyCode: string;
  decimalsUnitPrice: number;
}

interface AddCellParams {
  doc: jsPDF;
  label?: string;
  text: string;
  textX?: number;
  x: number;
  y: number;
  cellLength: number;
  cellHeight: number;
  maxTextLength?: number;
  fontWeight?: number;
}

function addCell({
  doc,
  label,
  text,
  textX: customTextX,
  x,
  y,
  cellLength,
  cellHeight,
  maxTextLength = 40,
  fontWeight,
}: AddCellParams) {
  const originalFont = doc.getFont();

  const horizontalPadding = 2;
  const verticalPadding = 3;

  const labelX = x + horizontalPadding;
  const labelY = y + verticalPadding;
  const labelFontSize = 6;

  const textX = customTextX ?? labelX;
  const textY = y + cellHeight / 2 + (label ? verticalPadding : verticalPadding / 2);
  const textFontSize = 10;

  const truncatedText =
    text.length > maxTextLength ? `${text.substring(0, maxTextLength - 3)}...` : text;

  // Create cell
  doc.rect(x, y, cellLength, cellHeight);

  // Add label
  if (label) {
    doc.setFontSize(labelFontSize);
    doc.setTextColor(128, 128, 128); // Set label color
    doc.text(label.toUpperCase(), labelX, labelY);
    doc.setTextColor(0, 0, 0); // Restore color
  }

  // Add text
  doc.setFontSize(textFontSize);
  if (fontWeight) {
    doc.setFont(originalFont.fontName, originalFont.fontStyle, fontWeight);
  }
  doc.text(truncatedText, textX, textY);

  // Reset font
  doc.setFont(originalFont.fontName, originalFont.fontStyle);
}

export function generateSalesOrderPDFWithJSPDF({
  salesOrder,
  companyName,
  companyVATId,
  companyVATRate,
  mainCurrencyDecimals,
  mainCurrencyCode,
  decimalsUnitPrice,
}: GenerateSalesOrderPDFProps) {
  const doc = new jsPDF();

  // Set font to support unicode
  doc.addFileToVFS('helvetica-normal.ttf', helveticaNormal);
  doc.addFont('helvetica-normal.ttf', 'helvetica', 'normal');
  doc.setFont('helvetica', 'normal');

  // Properties
  doc.setDocumentProperties({
    title: `Pedido de ventas ${salesOrder.salesOrderId}`,
    author: 'Laudus SL',
    creator: 'Laudus SL',
    subject: 'Pedido de ventas',
  });

  // Initial config
  const margin = 10;
  let y = margin;
  const originalFont = doc.getFont();
  const cellHeight = 10;

  /* Header */
  // Company name
  doc.setFontSize(12);
  doc.text(companyName, margin, y);

  // Order number
  doc.setFontSize(18);
  doc.setFont(originalFont.fontName, 'bold');
  doc.text(`Pedido ${salesOrder.salesOrderId}`, doc.internal.pageSize.width - margin, y, {
    align: 'right',
  });

  // Reset font
  doc.setFont(originalFont.fontName, originalFont.fontStyle);

  // RUT and DV
  y += 7;
  doc.setFontSize(12);
  doc.text(`RUT: ${companyVATId}`, margin, y);

  // Table for customer and order info
  y += 5;

  // Customer name, customer VATId and issued date
  addCell({
    doc,
    label: 'Cliente',
    text: salesOrder.customer?.name ?? '',
    x: margin,
    y,
    cellHeight,
    cellLength: 90,
  });
  addCell({
    doc,
    label: 'RUT',
    text: salesOrder.customer?.VATId ?? '',
    x: margin + 90,
    y,
    cellHeight,
    cellLength: 60,
  });
  addCell({
    doc,
    label: 'Fecha/Hora',
    text: salesOrder.issuedDate
      ? new Date(salesOrder.issuedDate).toLocaleString(['es'], { hour12: false }).replace(',', '')
      : '',
    x: margin + 90 + 60,
    y,
    cellHeight,
    cellLength: 40,
  });

  y += cellHeight;

  // Customer legal name, contact full name and salesman name
  addCell({
    doc,
    label: 'Razon social',
    text: salesOrder.customer?.legalName ?? '',
    x: margin,
    y,
    cellHeight,
    cellLength: 90,
  });
  addCell({
    doc,
    label: 'Contacto',
    text: `${salesOrder.contact?.firstName ?? ''} ${salesOrder.contact?.lastName ?? ''}`,
    x: margin + 90,
    y,
    cellHeight,
    cellLength: 60,
  });
  addCell({
    doc,
    label: 'Vendedor',
    text: salesOrder.salesman?.name ?? '',
    x: margin + 90 + 60,
    y,
    cellHeight,
    cellLength: 40,
  });

  y += cellHeight;

  // Payment, OC and quote
  addCell({
    doc,
    label: 'Forma de pago',
    text: salesOrder.term?.name ?? '',
    x: margin,
    y,
    cellHeight,
    // cellLength: 90,
    cellLength: 110,
  });
  addCell({
    doc,
    label: 'O/C',
    text: salesOrder.purchaseOrderNumber ?? '',
    // x: margin + 90,
    x: margin + 110,
    y,
    cellHeight,
    // cellLength: 60,
    cellLength: 80,
  });
  // addCell({
  //   doc,
  //   label: 'Cotizacion',
  //   text: '', // How to get quote id? => We would need to call the API for it... let's left it blank
  //   x: margin + 90 + 60,
  //   y,
  //   cellHeight,
  //   cellLength: 40,
  // });

  y += cellHeight;

  addCell({
    doc,
    text: 'Entrega',
    textX: margin + 190 / 2 - margin,
    x: margin,
    y,
    cellHeight: 6,
    cellLength: 190,
    fontWeight: 700,
  });
  y += 6;

  // Deliver info
  addCell({
    doc,
    label: 'Despachador',
    text: salesOrder.carrier?.name ?? '',
    x: margin,
    y,
    cellHeight,
    cellLength: 95,
  });
  addCell({
    doc,
    label: 'Previsto',
    text: salesOrder.dueDate
      ? new Date(salesOrder.dueDate).toLocaleString(['es'], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })
      : '',
    x: margin + 95,
    y,
    cellHeight,
    cellLength: 30,
  });
  addCell({
    doc,
    label: 'Entrega',
    text: salesOrder.deliveryDate
      ? new Date(salesOrder.deliveryDate).toLocaleString(['es'], {
          year: 'numeric',
          month: 'numeric',
          day: 'numeric',
        })
      : '',
    x: margin + 95 + 30,
    y,
    cellHeight,
    cellLength: 30,
  });
  addCell({
    doc,
    label: 'Horario',
    text: salesOrder.deliveryTimeFrame ?? '',
    x: margin + 95 + 30 + 30,
    y,
    cellHeight,
    cellLength: 35,
  });

  y += cellHeight;

  addCell({
    doc,
    label: 'Direccion',
    text: salesOrder.deliveryAddress?.address ?? '',
    x: margin,
    y,
    cellHeight,
    cellLength: 95,
  });
  addCell({
    doc,
    label: 'Comuna',
    text: salesOrder.deliveryAddress?.state ?? '',
    x: margin + 95,
    y,
    cellHeight,
    cellLength: 60,
  });
  addCell({
    doc,
    label: 'CP',
    text: salesOrder.deliveryAddress?.zipCode ?? '',
    x: margin + 95 + 60,
    y,
    cellHeight,
    cellLength: 35,
  });

  y += cellHeight;

  addCell({
    doc,
    label: 'Ciudad',
    text: salesOrder.deliveryAddress?.city ?? '',
    x: margin,
    y,
    cellHeight,
    cellLength: 95,
  });
  addCell({
    doc,
    label: 'Pais',
    text: salesOrder.deliveryAddress?.state ?? '',
    x: margin + 95,
    y,
    cellHeight,
    cellLength: 95,
  });

  y += cellHeight;

  addCell({
    doc,
    label: 'Notas',
    text: salesOrder.deliveryNotes ?? '',
    x: margin,
    y,
    cellHeight,
    cellLength: 190,
    maxTextLength: 80,
  });

  y += cellHeight;

  // Items
  y += 5;
  doc.setFontSize(12);

  const tableData: RowInput[] = salesOrder.items.map((item) => [
    item.product?.sku ?? '',
    item.itemDescription || item.product?.description || '',
    item.quantity ?? 0,
    formatNumber(Number(item.unitPrice ?? 0), decimalsUnitPrice),
    item.discountPercentage ?? 0,
    formatNumber(Number(roundDecimals(item.net ?? 0, mainCurrencyDecimals)), mainCurrencyDecimals),
  ]);

  let tableReference: Table = {} as Table;
  autoTable(doc, {
    didDrawPage: (params) => {
      tableReference = params.table;
    },
    startY: y,
    margin,
    styles: {
      fontSize: 10,
      cellPadding: 3,
      valign: 'middle',
      halign: 'center',
      overflow: 'linebreak',
      lineColor: 'black',
      lineWidth: 0.1,
    },
    head: [
      [
        'Producto',
        'Descripcion',
        'Cantidad pedida',
        `Precio unit. en ${mainCurrencyCode}`,
        'Dto %',
        'Total neto',
      ],
    ],
    headStyles: {
      fontStyle: 'bold',
      textColor: 'black',
      fillColor: 'white',
      overflow: 'linebreak',
      fontSize: 11,
    },
    columnStyles: {
      0: { halign: 'left', cellWidth: 35 },
      1: { halign: 'left' },
      2: { halign: 'right', cellWidth: 25 },
      3: { halign: 'right', cellWidth: 30 },
      4: { halign: 'right' },
      5: { halign: 'right' },
    },
    body: tableData,
    bodyStyles: {},
  });

  const tableFinalY = tableReference?.finalY ?? 0;
  y = tableFinalY + 10;

  // Totals
  const { totals } = calculateOrderTotals(salesOrder, mainCurrencyCode, mainCurrencyDecimals);
  const VAT = (totals.net * companyVATRate) / 100;

  doc.setFontSize(12);
  doc.setFont(originalFont.fontName, 'bold');
  doc.text(
    `Total Neto en ${mainCurrencyCode}: ${formatNumber(
      Number(roundDecimals(totals.net, mainCurrencyDecimals)),
      mainCurrencyDecimals,
    )}`,
    doc.internal.pageSize.width - margin,
    y,
    {
      align: 'right',
    },
  );
  y += 10;
  doc.text(
    `IVA: ${formatNumber(Number(roundDecimals(VAT, mainCurrencyDecimals)), mainCurrencyDecimals)}`,
    doc.internal.pageSize.width - margin,
    y,
    {
      align: 'right',
    },
  );
  y += 10;
  doc.text(
    `Total con IVA: ${formatNumber(
      Number(roundDecimals(totals.net + VAT, mainCurrencyDecimals)),
      mainCurrencyDecimals,
    )}`,
    doc.internal.pageSize.width - margin,
    y,
    {
      align: 'right',
    },
  );

  // Reset font
  doc.setFont(originalFont.fontName, originalFont.fontStyle);

  // Footer
  // y = doc.internal.pageSize.height - 30;

  return new Blob([doc.output()], { type: 'application/pdf' });
}

export function generateSalesOrderPDF(props: GenerateSalesOrderPDFProps): Blob {
  // jsPDF - generate PDF with library
  return generateSalesOrderPDFWithJSPDF(props);

  // jsPDF - generate PDF from React component
  // return generateSalesOrderPDFWithJSPDFFromReactComponent(props);

  // react-pdf
  // return generateSalesOrderPDFWithReactPDF(salesOrder);

  // pdfkit
  // return generateSalesOrderPDFWithPDFKit(salesOrder);

  // pdfmake
  // return generateSalesOrderPDFWithPDFMake(salesOrder);
}
