import { AxiosResponse } from 'axios';

import { ISupplier, ISupplierInvoice } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchSuppliersListAPI = (): Promise<AxiosResponse<ISupplier[]>> =>
  axios.post(
    `${VITE_API_URL}/purchases/suppliers/list`,
    {
      fields: ['name', 'supplierId', 'legalName', 'VATId', 'address'],
      orderBy: [{ field: 'name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchSupplierAPI = (supplierId: number): Promise<AxiosResponse<ISupplier>> =>
  axios.get(`${VITE_API_URL}/purchases/suppliers/${supplierId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createSupplierAPI = (supplier: ISupplier): Promise<AxiosResponse<ISupplier>> =>
  axios.post(`${VITE_API_URL}/purchases/suppliers`, supplier);

export const updateSupplierAPI = (supplier: ISupplier): Promise<AxiosResponse<ISupplier>> =>
  axios.put(`${VITE_API_URL}/purchases/suppliers/${supplier.supplierId}`, supplier);

export const deleteSupplierAPI = (supplierId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/purchases/suppliers/${supplierId}`);

// add this field when API fix it 'totals.currencyCode'
export const fetchSuppliersInvoicesListFromAPI = (
  supplierId: number,
): Promise<AxiosResponse<ISupplierInvoice[]>> =>
  axios.post(
    `${VITE_API_URL}/purchases/invoices/list`,
    {
      fields: [
        'docType.name',
        'docNumber',
        'description',
        'issuedDate',
        'dueDate',
        'totals.net',
        'totals.vat',
        'totals.total',
        'monthlyPosition',
      ],
      filterBy: [
        {
          field: 'supplier.supplierId',
          operator: '=',
          value: Number(supplierId),
        },
      ],
      orderBy: [{ field: 'docNumber', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

// add 'items.unitPrice', when api fix it
export const fetchSuppliersInvoicesDetailListFromAPI = (
  supplierId: number,
): Promise<AxiosResponse<ISupplierInvoice[]>> =>
  axios.post(
    `${VITE_API_URL}/purchases/invoices/list`,
    {
      fields: [
        'docType.name',
        'docNumber',
        'issuedDate',
        'items.product.sku',
        'items.product.description',
        'items.itemDescription',
        'items.quantity',
        'totals.net',
      ],
      filterBy: [
        {
          field: 'supplier.supplierId',
          operator: '=',
          value: Number(supplierId),
        },
      ],
      orderBy: [{ field: 'docNumber', direction: 'DESC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

// THIS ENDPOINT NOT EXISTS NOW
export const fetchSuppliersPendingInvoicesFromAPI = (
  supplierId: number,
): Promise<AxiosResponse<ISupplierInvoice[]>> =>
  axios.get(`${VITE_API_URL}/purchases/suppliers/${supplierId}/pendingInvoices`);

// move this request to /purchases/payments when entity payments
export const fetchSuppliersPaymentsListFromAPI = (
  supplierId: number,
): Promise<AxiosResponse<ISupplierInvoice[]>> =>
  axios.post(
    `${VITE_API_URL}/purchases/payments/list`,
    {
      fields: [
        'purchaseInvoices.docType.name',
        'purchaseInvoices.docNumber',
        'purchaseInvoices.amount',
        'issuedDate',
        'dueDate',
      ],
      filterBy: [
        {
          field: 'purchaseInvoices.supplier.supplierId',
          operator: '=',
          value: Number(supplierId),
        },
      ],
      orderBy: [{ field: 'issuedDate', direction: 'DESC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

// move this request to /purchases/orders when entity
export const fetchSuppliersPurchasesOrdersListFromAPI = (
  supplierId: number,
): Promise<AxiosResponse<ISupplierInvoice[]>> =>
  axios.post(
    `${VITE_API_URL}/purchases/orders/list`,
    {
      fields: [
        'items.product.sku',
        'items.product.description',
        'items.originalUnitCost',
        'items.unitCost',
        'items.parityToMainCurrency',
        'items.quantity',
        'items.currencyCode',
        'items.costCenter.name',
      ],
      filterBy: [
        {
          field: 'supplier.supplierId',
          operator: '=',
          value: Number(supplierId),
        },
      ],
      orderBy: [{ field: 'issuedDate', direction: 'DESC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
