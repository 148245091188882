import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, IProductSalesTax, ISalesTaxes } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

// SalesTaxes Tab action creators

export const addHomeSalesTaxesTab = () =>
  addTab({
    tab: {
      id: 'salesTaxes',
      title: intl.formatMessage({ id: 'salesTaxes.tabTitle' }),
      path: 'pages/Taxes/Taxes',
      isRemovable: true,
    },
  });

export const addViewSalesTaxesTab = (id?: number) =>
  addTab({
    tab: {
      id: 'salesTaxes',
      title: intl.formatMessage({ id: 'salesTaxes.tabTitle' }),
      path: 'pages/Taxes/TaxesView',
      props: { id },
      isRemovable: true,
    },
  });

export const addNewSalesTaxesTab = () =>
  addTab({
    tab: {
      id: 'salesTaxes',
      title: intl.formatMessage({ id: 'salesTaxes.tabTitle' }),
      path: 'pages/Taxes/TaxesNew',
      isRemovable: true,
    },
  });

export const addEditSalesTaxesTab = () =>
  addTab({
    tab: {
      id: 'salesTaxes',
      title: intl.formatMessage({ id: 'salesTaxes.tabTitle' }),
      path: 'pages/Taxes/TaxesEdit',
      isRemovable: true,
    },
  });

// Simple actions
export const clearSalesTaxes = createAction('SALES_TAXES/CLEAR');

export const clearSalesTaxesDraft = createAction('SALES_TAXES/CLEAR_DRAFT');

export const setSalesTax = createAction<ISalesTaxes>('SALES_TAXES/SET_SALESTAX');

export const setSalesTaxDraft = createAction<ISalesTaxes>('SALES_TAXES/SET_SALESTAX_DRAFT');

export const setSalesTaxDraftValues = createAction<Partial<ISalesTaxes>>(
  'SALES_TAXES/SET_SALESTAX_DRAFT_VALUE',
);

export const setSalesTaxesList = createAction<ISalesTaxes[]>('SALES_TAXES/SET_LIST');

export const updateSalesTaxesList = createAction<ISalesTaxes>('SALES_TAXES/UPDATE_LIST');

export const removeSalesTaxFromList = createAction<number>('SALES_TAXES/REMOVE_FROM_LIST');

export const setProductSalesTaxesList = createAction<IProductSalesTax[]>(
  'SALES_TAXES/SET_PRODUCT_SALES_TAXES_LIST',
);

export const duplicateSalesTax = createAction<ISalesTaxes>('SALES_TAXES/DUPLICATE');

export const fetchSalesTaxesList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'SALES_TAXES/FETCH_SALESTAXES_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      if (!silent) {
        dispatch(startRequest('sales-taxes'));
      }

      const { data } = await api.salesTaxes.fetchSalesTaxListFromAPI();
      dispatch(setSalesTaxesList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      // Javier doesn't want the user to be notified when this fetch fails
      dispatch(setSalesTaxesList([]));
    } finally {
      if (!silent) {
        dispatch(endRequest('sales-taxes'));
      }
    }
  },
);

export const fetchSalesTax = createAsyncThunk<void, number, AppThunkConfig>(
  'SALES_TAXES/FETCH_SALESTAX',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    try {
      dispatch(startRequest('sales-taxes'));
      const { data } = await api.salesTaxes.fetchSalesTaxFromAPI(id);
      dispatch(setSalesTax(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'salesTaxes',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('sales-taxes'));
    }
  },
);

export const fetchProductSalesTaxesList = createAsyncThunk<
  void,
  IFetchWithEtagParams,
  AppThunkConfig
>('SALES_TAXES/FETCH_PRODUCT_SALES_TAXES_LIST', async ({ eTag, silent }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  try {
    if (!silent) {
      dispatch(startRequest('sales-taxes'));
    }

    const { data } = await api.salesTaxes.fetchProductSalesTaxListFromAPI();
    dispatch(setProductSalesTaxesList(Array.isArray(data) ? data : []));

    dispatch(setEtagsCurrentEtag(eTag));
  } catch (error) {
    // Javier doesn't want the user to be notified when this fetch fails
    dispatch(setProductSalesTaxesList([]));
  } finally {
    if (!silent) {
      dispatch(endRequest('sales-taxes'));
    }
  }
});
