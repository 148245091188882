import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IEmployeeAbsence } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showAlert, showErrorAlert, showToastAlert } from '../alerts';
import { endRequest, startRequest } from '../global/actions';

// Simple actions
export const clearEmployeeAbsence = createAction('EMPLOYEES_ABSENCES/CLEAR');

export const clearEmployeeAbsenceDraft = createAction('EMPLOYEES_ABSENCES/CLEAR_DRAFT');

export const setEmployeeAbsence = createAction<IEmployeeAbsence>(
  'EMPLOYEES_ABSENCES/SET_EMPLOYEE_ABSENCE',
);

export const setEmployeeAbsenceDraft = createAction<IEmployeeAbsence>(
  'EMPLOYEES_ABSENCES/SET_DRAFT',
);

export const setEmployeeAbsenceDraftValues = createAction<Partial<IEmployeeAbsence>>(
  'EMPLOYEES_ABSENCES/SET_DRAFT_VALUE',
);

export const setEmployeeAbsencesList = createAction<IEmployeeAbsence[]>(
  'EMPLOYEES_ABSENCES/SET_LIST',
);

export const updateEmployeeAbsencesList = createAction<IEmployeeAbsence>(
  'EMPLOYEES_ABSENCES/UPDATE_LIST',
);

export const removeEmployeeAbsenceFromList = createAction<number>(
  'EMPLOYEES_ABSENCES/REMOVE_FROM_LIST',
);

export const duplicateEmployeeAbsence = createAction<IEmployeeAbsence>(
  'EMPLOYEES_ABSENCES/DUPLICATE',
);

export const setAbsencesButtonIndex = createAction<string>('EMPLOYEES_ABSENCES/SET_ABSENCES_INDEX');
export interface IFetchEmployeeAbsenceParams {
  employeeId: number;
  absenceId: number;
}
// Complex actions
export const fetchEmployeeAbsence = createAsyncThunk<
  void,
  IFetchEmployeeAbsenceParams,
  AppThunkConfig
>('EMPLOYEES_ABSENCES/FETCH', async ({ employeeId, absenceId }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('employee-absences'));
  try {
    const { data } = await api.employeeAbsences.fetchEmployeeAbsenceFromAPI(employeeId, absenceId);
    dispatch(setEmployeeAbsence(data));
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'employeesAbsences',
        action: 'read',
      }),
    );
  } finally {
    dispatch(endRequest('employee-absences'));
  }
});

export const fetchEmployeeAbsencesList = createAsyncThunk<void, number, AppThunkConfig>(
  'EMPLOYEES_ABSENCES/FETCH_EMPLOYEES_ABSENCES_LIST',
  async (employeeId, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('employee-absences'));
    try {
      const { data } = await api.employeeAbsences.fetchEmployeesAbsencesListFromAPI(employeeId);
      dispatch(setEmployeeAbsencesList(Array.isArray(data) ? data : []));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeesAbsences',
          action: 'list',
        }),
      );
    } finally {
      dispatch(endRequest('employee-absences'));
    }
  },
);

export interface ICreateEmployeeAbsenceParams {
  employeeId: number;
  employeeAbsence: IEmployeeAbsence;
}

export const createEmployeeAbsence = createAsyncThunk<
  void,
  ICreateEmployeeAbsenceParams,
  AppThunkConfig
>(
  'EMPLOYEES_ABSENCES/CREATE_EMPLOYEE_ABSENCE',
  async ({ employeeId, employeeAbsence }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;
    dispatch(startRequest('employee-absences'));
    try {
      const idToZeroAbsence = {
        ...employeeAbsence,
        employeeAbsenceId: 0,
      };
      const { data } = await api.employeeAbsences.createEmployeeAbsenceFromAPI(
        employeeId,
        idToZeroAbsence,
      );
      dispatch(updateEmployeeAbsencesList(data));
      dispatch(
        showAlert({
          title: intl.formatMessage({
            id: 'employeesAbsences.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeesLoans',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('employee-absences'));
    }
  },
);
export interface IUpdateEmployeeAbsenceParams {
  employeeId: number;
  employeeAbsence: IEmployeeAbsence;
}

export const updateEmployeeAbsence = createAsyncThunk<
  void,
  IUpdateEmployeeAbsenceParams,
  AppThunkConfig
>(
  'EMPLOYEES_ABSENCES/UPDATE_EMPLOYEE_ABSENCE',
  async ({ employeeId, employeeAbsence }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('employee-absences'));
    try {
      const { data } = await api.employeeAbsences.updateEmployeeAbsenceFromAPI(
        employeeId,
        employeeAbsence,
      );
      dispatch(updateEmployeeAbsencesList(data));
      dispatch(
        showToastAlert({
          title: intl.formatMessage({
            id: 'employeesAbsences.successToast.save',
          }),
          message: '',
          type: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'employeesLoans',
          action: 'save',
        }),
      );
    } finally {
      dispatch(endRequest('employee-absences'));
    }
  },
);

export interface IDeleteEmployeeAbsenceTerminationParams {
  employeeId: number;
  absenceId: number;
}
export const deleteEmployeeAbsence = createAsyncThunk<
  void,
  IDeleteEmployeeAbsenceTerminationParams,
  AppThunkConfig
>(`EMPLOYEES_ABSENCES/DELETE`, async ({ employeeId, absenceId }, ThunkAPI) => {
  const { dispatch, extra } = ThunkAPI;
  const { api } = extra;

  dispatch(startRequest('employee-absences'));
  try {
    await api.employeeAbsences.deleteEmployeeAbsenceFromAPI(employeeId, absenceId);
    dispatch(removeEmployeeAbsenceFromList(employeeId));

    dispatch(
      showToastAlert({
        title: intl.formatMessage({
          id: 'employeesAbsences.successToast.delete',
        }),
        type: 'success',
      }),
    );
  } catch (error) {
    dispatch(
      showErrorAlert({
        error,
        prefix: 'employeesAbsences',
        action: 'delete',
      }),
    );
  } finally {
    dispatch(endRequest('employee-absences'));
  }
});
