import { AxiosResponse } from 'axios';

import { ICafDte, IDTE, IDTEDocsToSend, IDTEPendingsItem, SendDTEEmail } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const postDTESendEmailAPI = (payload: SendDTEEmail): Promise<AxiosResponse<IDTE>> => {
  return axios.post(`${VITE_API_URL}/dte/sendEmail`, payload);
};

export const postDTEToSIIAPI = (documents: IDTEDocsToSend): Promise<AxiosResponse<IDTE>> => {
  const isDevelop = VITE_API_URL?.includes('dev');
  const sendToSIIEndpoint = isDevelop
    ? `https://apidev.laudus.cl/API/PY/dte/sendToSIIIIIIIII`
    : `${VITE_API_URL}/dte/sendToSII`;

  return axios.post(sendToSIIEndpoint, documents);
};

export const fetchDTEPendingListFromAPI = (): Promise<AxiosResponse<IDTEPendingsItem[]>> =>
  axios.get(`${VITE_API_URL}/dte/pending`);

////////// DTE PDF417 Image
export const fetchDTEPDF417FromAPI = (
  docNumber: number,
  docTypeId: number,
): Promise<AxiosResponse<ArrayBuffer>> =>
  axios.get(`${VITE_API_URL}/dte/ted/pdf417`, {
    params: {
      DocNumber: docNumber,
      DocTypeId: docTypeId,
    },
    responseType: 'arraybuffer',
    headers: {
      Accept: 'image/bmp',
    },
  });

/////////////////////////// CAF DTE
export const fetchCafDTEListFromAPI = (): Promise<AxiosResponse<ICafDte[]>> =>
  axios.post(
    `${VITE_API_URL}/sales/authorizedDocumentsRanges/list`,
    {
      fields: [
        'authorizationDate',
        'authorizedDocumentsRangeId',
        'branch.branchId',
        'branch.name',
        'caf',
        'docNumberFrom',
        'docNumberTo',
        'docType.docTypeId',
        'docType.name',
        'pos.name',
        'pos.posId',
      ],
      orderBy: [{ field: 'authorizationDate', direction: 'DESC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchCafDTEFromAPI = (
  authorizedDocumentsRangeId: number,
): Promise<AxiosResponse<ICafDte>> =>
  axios.get(`${VITE_API_URL}/sales/authorizedDocumentsRanges/${authorizedDocumentsRangeId}`);

export const createCafDTEFromAPI = (cafDte: ICafDte): Promise<AxiosResponse<ICafDte>> =>
  axios.post(`${VITE_API_URL}/sales/authorizedDocumentsRanges`, cafDte);

export const updateCafDTEFromAPI = (cafDte: ICafDte): Promise<AxiosResponse<ICafDte>> =>
  axios.put(
    `${VITE_API_URL}/sales/authorizedDocumentsRanges/${cafDte.authorizedDocumentsRangeId}`,
    cafDte,
  );

export const deleteCafDTEFromAPI = (authorizedDocumentsRangeId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/sales/authorizedDocumentsRanges/${authorizedDocumentsRangeId}`);
