import { createContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useGlobalDispatch, useGlobalSelector } from '@laudus/redux-global';

import { getFeatures, IFeaturesState, initialFeaturesState, setFeatures } from '../redux';

export const FeatureContext = createContext<IFeaturesState>(initialFeaturesState);

export interface FeaturesProviderProps {
  children: React.ReactNode;
}

export function FeaturesProvider({ children }: FeaturesProviderProps) {
  const [seachParams] = useSearchParams();
  const params = Object.fromEntries([...seachParams]);

  const dispatch = useGlobalDispatch();
  const features = useGlobalSelector(getFeatures);

  useEffect(() => {
    if (params.feature) {
      const [flag, value] = params.feature.split(':');
      dispatch(setFeatures({ [flag]: value === undefined || value === 'true' }));
    }
  }, [params?.feature]);

  return <FeatureContext.Provider value={features}>{children}</FeatureContext.Provider>;
}
