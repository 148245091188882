import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ICellEditorParams } from '@ag-grid-community/core';
import { useColorMode } from '@chakra-ui/react';

import { colors, KEY_DOWN, KEY_END, KEY_ENTER, KEY_HOME, KEY_TAB, KEY_UP } from '@laudus/shared-ui';

interface ITextEditorComponentProps extends ICellEditorParams {
  name: string;
}

const TextEditorComponent: React.ForwardRefRenderFunction<any, ITextEditorComponentProps> = (
  props,
  ref,
) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState<string>(props.value);

  const { colorMode } = useColorMode();

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleKeyDown = (event: any) => {
    // Any of these keys represent the users intent to movement away from the current cell and
    // therefore we must commit the value so that it's persisted to state
    if (
      event.key === KEY_ENTER ||
      event.key === KEY_TAB ||
      event.key === KEY_UP ||
      event.key === KEY_DOWN ||
      event.key === KEY_HOME ||
      event.key === KEY_END
    ) {
      event.preventDefault();
      setValue(value);
      props.context.handleKeyPress(event);
      return;
    }
  };

  useEffect(() => {
    inputRef?.current?.focus();

    inputRef?.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      inputRef?.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [value]);

  return (
    <input
      name={props.name}
      onChange={handleChange}
      ref={inputRef}
      style={{
        position: 'absolute',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        textAlign: 'left',
        padding: '0 1rem',
        color: colorMode === 'dark' ? colors.white : colors.black,
        background: colorMode === 'dark' ? colors.darkGrey500 : colors.white,
      }}
      type="text"
      value={value}
    />
  );
};

TextEditorComponent.displayName = 'TextEditor';

export const TextEditor = forwardRef(TextEditorComponent);
