import { createReducer } from '@reduxjs/toolkit';

import { IRemunerationConcept } from '@laudus/types';

import {
  clearRemunerationConcept,
  clearRemunerationConceptDraft,
  clearRemunerationConceptSelectedNode,
  duplicateRemunerationConcept,
  removeRemunerationConceptFromList,
  setRemunerationConcept,
  setRemunerationConceptDraft,
  setRemunerationConceptDraftValues,
  setRemunerationConceptList,
  setRemunerationConceptSelectedNode,
  updateRemunerationConceptList,
} from './actions';

export interface IRemunerationConceptState {
  current: IRemunerationConcept;
  draft: IRemunerationConcept;
  list: IRemunerationConcept[];
  selectedNode: number | string | null;
}

export const REMUNERATION_CONCEPT_EMPTY: IRemunerationConcept = {
  name: '',
};

export const initialRemunerationConceptsState: IRemunerationConceptState = {
  current: REMUNERATION_CONCEPT_EMPTY,
  draft: REMUNERATION_CONCEPT_EMPTY,
  list: [],
  selectedNode: null,
};

export const remunerationConceptsReducer = createReducer(
  initialRemunerationConceptsState,
  (builder) => {
    builder
      .addCase(clearRemunerationConcept, (state) => {
        return { ...state, current: REMUNERATION_CONCEPT_EMPTY };
      })
      .addCase(setRemunerationConcept, (state, action) => {
        return { ...state, current: action.payload };
      })
      .addCase(setRemunerationConceptList, (state, action) => {
        return { ...state, list: action.payload };
      })
      .addCase(clearRemunerationConceptDraft, (state) => {
        return { ...state, draft: REMUNERATION_CONCEPT_EMPTY };
      })
      .addCase(setRemunerationConceptDraft, (state, action) => {
        return { ...state, draft: action.payload };
      })
      .addCase(setRemunerationConceptDraftValues, (state, action) => {
        return { ...state, draft: { ...state.draft, ...action.payload } };
      })
      .addCase(updateRemunerationConceptList, (state, action) => {
        return {
          ...state,
          list: [
            ...state.list.filter(
              (d) => d.remunerationConceptId !== action.payload.remunerationConceptId,
            ),
            action.payload,
          ],
        };
      })
      .addCase(removeRemunerationConceptFromList, (state, action) => {
        return {
          ...state,
          list: state.list.filter((d) => d.remunerationConceptId !== action.payload),
        };
      })
      .addCase(duplicateRemunerationConcept, (state, action) => {
        const { remunerationConceptId, ...duplicatedRemunerationConcept } = action.payload;

        return { ...state, current: duplicatedRemunerationConcept };
      })
      .addCase(setRemunerationConceptSelectedNode, (state, action) => {
        return {
          ...state,
          selectedNode: action.payload,
        };
      })
      .addCase(clearRemunerationConceptSelectedNode, (state) => {
        return {
          ...state,
          selectedNode: null,
        };
      })
      .addDefaultCase((state) => state);
  },
);
