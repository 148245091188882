import { createSelector } from '@reduxjs/toolkit';

import { AppState, IUsersCertificateState } from '../..';

export const getUsersCertificateSlice = (state: AppState): IUsersCertificateState =>
  state.usersCertificate;

export const getUserCertificateInfo = createSelector(
  [getUsersCertificateSlice],
  (state) => state.current,
);

export const getUserCertificate = createSelector(
  [getUsersCertificateSlice],
  (state) => state.certificate,
);

export const getUserCertificateDraft = createSelector(
  [getUsersCertificateSlice],
  (state) => state.draft,
);

export const getCertificateImport = createSelector(
  [getUsersCertificateSlice],
  (state) => state.importCertificate,
);
