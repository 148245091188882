import { createReducer } from '@reduxjs/toolkit';

import { ISalesmen } from '@laudus/types';

import {
  clearSalesmen,
  clearSalesmenDraft,
  duplicateSalesman,
  removeSalesmanFromList,
  setSalesman,
  setSalesmanDraft,
  setSalesmanDraftValues,
  setSalesmenList,
  updateSalesmenList,
} from './actions';

export const SALESMEN_TAB_ID = 'salesmen';
export interface ISalesmenState {
  current: ISalesmen;
  draft: ISalesmen;
  list: ISalesmen[];
}

export const SALESMEN_EMPTY: ISalesmen = {
  name: '',
  legalName: '',
};

export const initialSalesmenState: ISalesmenState = {
  current: SALESMEN_EMPTY,
  draft: SALESMEN_EMPTY,
  list: [],
};

export const salesmenReducer = createReducer(initialSalesmenState, (builder) => {
  builder
    .addCase(clearSalesmen, (state) => {
      return { ...state, current: SALESMEN_EMPTY };
    })
    .addCase(clearSalesmenDraft, (state) => {
      return { ...state, draft: SALESMEN_EMPTY };
    })
    .addCase(setSalesman, (state, action) => {
      return { ...state, current: action.payload };
    })
    .addCase(setSalesmanDraft, (state, action) => {
      return { ...state, draft: action.payload };
    })
    .addCase(setSalesmanDraftValues, (state, action) => {
      return { ...state, draft: { ...state.draft, ...action.payload } };
    })
    .addCase(setSalesmenList, (state, action) => {
      return { ...state, list: action.payload };
    })
    .addCase(updateSalesmenList, (state, action) => {
      return {
        ...state,
        list: [
          ...state.list.filter((p) => p.salesmanId !== action.payload.salesmanId),
          action.payload,
        ],
      };
    })
    .addCase(removeSalesmanFromList, (state, action) => {
      return {
        ...state,
        list: state.list.filter((d) => d.salesmanId !== action.payload),
      };
    })
    .addCase(duplicateSalesman, (state, action) => {
      const { salesmanId, ...duplicatedSalesman } = action.payload;
      return { ...state, draft: duplicatedSalesman };
    })
    .addDefaultCase((state) => state);
});
