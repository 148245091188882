import { AxiosResponse } from 'axios';

import { IUser, IUserCertificate, IUserCertificateInfo } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchUsersListFromAPI = (): Promise<AxiosResponse<IUser[]>> => {
  return axios.post(
    `${VITE_API_URL}/security/users/list`,
    {
      fields: ['name', 'userId', 'loginName', 'group.name', 'discontinued'],
      orderBy: [{ field: 'loginName', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );
};

export interface GetUserByLoginNameAPIParams {
  loginName?: string;
  companyAccessToken?: string;
}

export const fetchUserByLoginNameFromAPI = ({
  loginName,
  companyAccessToken,
}: GetUserByLoginNameAPIParams): Promise<AxiosResponse<IUser>> => {
  return axios.get(`${VITE_API_URL}/security/users?loginName=${loginName}`, {
    headers: {
      Accept: 'application/json',
      ...(companyAccessToken
        ? {
            'Authorization': `Bearer ${companyAccessToken}`,
            'X-Source': 'LaudusApp',
          }
        : {}),
    },
  });
};

export interface GetUserByGlUserIdIdAPIParams {
  glUserId?: number;
}

export const fetchUserByGlUserIdFromAPI = ({
  glUserId,
}: GetUserByGlUserIdIdAPIParams): Promise<AxiosResponse<IUser>> => {
  return axios.get(`${VITE_API_URL}/security/users?glUserId=${glUserId}`, {
    headers: {
      Accept: 'application/json',
    },
  });
};

export const fetchUserFromAPI = (userId: string): Promise<AxiosResponse<IUser>> =>
  axios.get(`${VITE_API_URL}/security/users/${userId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createUserFromAPI = (
  user: IUser,
  companyAccessToken?: string,
): Promise<AxiosResponse<IUser>> =>
  axios.post(`${VITE_API_URL}/security/users`, user, {
    ...(companyAccessToken
      ? {
          headers: {
            'Authorization': `Bearer ${companyAccessToken}`,
            'X-Source': 'LaudusApp',
          },
        }
      : {}),
  });

export const updateUserFromAPI = (
  user: IUser,
  companyAccessToken?: string,
): Promise<AxiosResponse<IUser>> =>
  axios.put(`${VITE_API_URL}/security/users/${user.userId}`, user, {
    ...(companyAccessToken
      ? {
          headers: {
            'Authorization': `Bearer ${companyAccessToken}`,
            'X-Source': 'LaudusApp',
          },
        }
      : {}),
  });

export const deleteUserFromAPI = (userId: string): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/security/users/${userId}`);

export const createUserCertificateFromAPI = (
  uploadCertificate: IUserCertificate,
  userId: string,
): Promise<AxiosResponse<IUserCertificate>> =>
  axios.post(`${VITE_API_URL}/security/users/${userId}/certificate`, uploadCertificate);

export const fetchUserCertificateInfoFromAPI = (
  userId: string,
): Promise<AxiosResponse<IUserCertificateInfo>> =>
  axios.get(`${VITE_API_URL}/security/users/${userId}/certificateInfo`, {
    headers: {
      Accept: 'application/json',
    },
  });
