import { AxiosResponse } from 'axios';

import { ISavingsPlansProvider } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

//carefull with 'savingsPlansProviderId' has 's' in Plans when comes from a list, it is a API reported bug
export const fetchSavingsPlansProvidersListFromAPI = (): Promise<
  AxiosResponse<ISavingsPlansProvider[]>
> =>
  axios.post(
    `${VITE_API_URL}/HR/savingsPlansProviders/list`,
    {
      fields: ['savingsPlansProviderId', 'name', 'legalName', 'previredId', 'notes'],
      orderBy: [{ field: 'name', direction: 'ASC' }],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchSavingsPlansProviderFromAPI = (
  savingsPlansProviderId: string,
): Promise<AxiosResponse<ISavingsPlansProvider>> =>
  axios.get(`${VITE_API_URL}/HR/savingsPlansProviders/${savingsPlansProviderId}`, {
    headers: {
      Accept: 'application/json',
    },
  });

export const createSavingsPlansProviderFromAPI = (
  savingsPlansProvider: ISavingsPlansProvider,
): Promise<AxiosResponse<ISavingsPlansProvider>> =>
  axios.post(`${VITE_API_URL}/HR/savingsPlansProviders`, savingsPlansProvider);

export const updateSavingsPlansProviderFromAPI = (
  savingsPlansProvider: ISavingsPlansProvider,
): Promise<AxiosResponse<ISavingsPlansProvider>> =>
  axios.put(
    `${VITE_API_URL}/HR/savingsPlansProviders/${savingsPlansProvider.savingsPlansProviderId}`,
    savingsPlansProvider,
  );

export const deleteSavingsPlansProviderFromAPI = (
  savingsPlansProviderId: string,
): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/HR/savingsPlansProviders/${savingsPlansProviderId}`);
