import { AiFillDislike, AiFillLike } from 'react-icons/ai';
import { BiDotsVerticalRounded } from 'react-icons/bi';
import { BsFillCameraVideoFill, BsFillExclamationTriangleFill, BsSend } from 'react-icons/bs';
import { CiCreditCard1, CiMobile1 } from 'react-icons/ci';
import { FaFileSignature, FaRegFolder, FaRegFolderOpen, FaTimes, FaWhatsapp } from 'react-icons/fa';
import {
  FiAlertTriangle,
  FiArchive,
  FiCamera,
  FiCameraOff,
  FiCheck,
  FiCheckCircle,
  FiClock,
  FiCornerLeftDown,
  FiCornerUpRight,
  FiCreditCard,
  FiDollarSign,
  FiEdit,
  FiEye,
  FiEyeOff,
  FiFileText,
  FiFolder,
  FiGrid,
  FiHardDrive,
  FiImage,
  FiInfo,
  FiKey,
  FiLock,
  FiLogOut,
  FiMail,
  FiMap,
  FiMapPin,
  FiMenu,
  FiMinusCircle,
  FiMonitor,
  FiMoon,
  FiPlus,
  FiPrinter,
  FiRepeat,
  FiRotateCcw,
  FiSave,
  FiSearch,
  FiSend,
  FiSettings,
  FiShoppingBag,
  FiShoppingCart,
  FiSun,
  FiTag,
  FiTrash,
  FiUpload,
  FiUploadCloud,
  FiUser,
  FiUsers,
  FiVideo,
  FiX,
  FiXCircle,
} from 'react-icons/fi';
import {
  IoIosArrowDown,
  IoIosArrowUp,
  IoMdArrowDropleft,
  IoMdArrowDropright,
  IoMdEye,
  IoMdEyeOff,
  IoMdInformationCircle,
  IoMdRadioButtonOn,
  IoMdSettings,
} from 'react-icons/io';
import {
  IoCheckmarkDoneOutline,
  IoCheckmarkSharp,
  IoClose,
  IoCloseCircle,
  IoInformationOutline,
  IoWarningOutline,
} from 'react-icons/io5';
import { LuArrowUpWideNarrow } from 'react-icons/lu';
import {
  MdAdd,
  MdArrowDropDown,
  MdArrowDropUp,
  MdBrush,
  MdCancel,
  MdCheck,
  MdCompareArrows,
  MdConnectWithoutContact,
  MdContentCopy,
  MdEmail,
  MdEvent,
  MdExpandLess,
  MdExpandMore,
  MdFindReplace,
  MdInventory2,
  MdKeyboardArrowLeft,
  MdKeyboardArrowRight,
  MdLayers,
  MdLeaderboard,
  MdList,
  MdMonetizationOn,
  MdOutlineAccountBalance,
  MdOutlineAdminPanelSettings,
  MdOutlineAutoFixHigh,
  MdOutlineChecklist,
  MdOutlineContacts,
  MdOutlineDocumentScanner,
  MdOutlineDomain,
  MdOutlineDownloading,
  MdOutlineFindInPage,
  MdOutlineGroupWork,
  MdOutlineHandyman,
  MdOutlineLocalMall,
  MdOutlineNumbers,
  MdOutlinePalette,
  MdOutlinePersonAddAlt1,
  MdOutlinePointOfSale,
  MdOutlinePrint,
  MdOutlineReceipt,
  MdOutlineReceiptLong,
  MdOutlineSavings,
  MdOutlineSearch,
  MdOutlineTune,
  MdPassword,
  MdPrint,
  MdRefresh,
  MdRemove,
  MdReport,
  MdSave,
  MdSend,
  MdStyle,
  MdTab,
  MdToday,
  MdUpdate,
} from 'react-icons/md';
import { RiFileExcel2Line, RiLightbulbFill } from 'react-icons/ri';
import { RxCross1 } from 'react-icons/rx';

import { IconCloseDay } from './components/IconCloseDay';
import { IconGetDailySales } from './components/IconGetDailySales';
import { IconGetLastSale } from './components/IconGetLastSale';
import { IconGetTotals } from './components/IconGetTotals';
import { IconLoadKeys } from './components/IconLoadKeys';
import { IconPoll } from './components/IconPoll';
import { IconRefund } from './components/IconRefund';
import { IconSetNormalMode } from './components/IconSetNormalMode';
export { IconCancel } from './components/IconCancel';
export {
  ThinChevronDownIcon,
  ThinChevronDownIconDark,
  ThinChevronUpIcon,
  ThinIconChevronLeft,
} from './components/ThinChevronIcon';

export const IconAccountBalance = MdOutlineAccountBalance;
export const IconAdd = MdAdd;
export const IconAddUser = MdOutlinePersonAddAlt1;
export const IconArchive = FiArchive;
export const IconArrowDown = MdArrowDropDown;
export const IconArrowLeft = IoMdArrowDropleft;
export const IconArrowRight = IoMdArrowDropright;
export const IconArrowUp = MdArrowDropUp;
export const IconAutoFix = MdOutlineAutoFixHigh;
export const IconBarChart = MdLeaderboard;
export const IconBrush = MdBrush;
export const IconCalendarEvent = MdEvent;
export const IconCalendarToday = MdToday;
export const IconCash = FiDollarSign;
export const IconCamera = FiCamera;
export const IconCameraOff = FiCameraOff;
export const IconCheck = IoCheckmarkSharp;
export const IconCheckAlt = MdCheck;
export const IconCheckCircle = FiCheckCircle;
export const IconChevronDown = MdExpandMore;
export const IconChevronLeft = MdKeyboardArrowLeft;
export const IconChevronRight = MdKeyboardArrowRight;
export const IconChevronUp = MdExpandLess;
export const IconClear = MdCancel;
export const IconClearPos = FiX;
export const IconClock = FiClock;
export const IconClose = IoClose;
export const IconCloseCircle = IoCloseCircle;
export const IconCloseCircleAlt = FiXCircle;
export const IconCompany = MdOutlineDomain;
export const IconNewCompany = FiRepeat;
export const IconCopy = MdContentCopy;
export const IconCross = RxCross1;
export const IconError = FiAlertTriangle;
export const IconExcel = RiFileExcel2Line;
export const IconEye = IoMdEye;
export const IconEyeOff = IoMdEyeOff;
export const IconUpload = FiUploadCloud;
export const IconContacts = MdOutlineContacts;
export const IconContactsAlt = FiUsers; // TODO Review
export const IconCreditCard = FiCreditCard;
export const IconDisk = FiHardDrive;
export const IconDislike = AiFillDislike;
export const IconDollar = FiDollarSign;
export const IconDollarCircle = MdMonetizationOn;
export const IconEdit = FiEdit;
export const IconFind = MdOutlineFindInPage;
export const IconGrid = FiGrid;
export const IconFile = FiFileText;
export const IconFileAlt = MdOutlineFindInPage;
export const IconFileSignature = FaFileSignature;
export const IconFilters = MdOutlineTune;
export const IconFolder = FaRegFolder;
export const IconFolderOpen = FaRegFolderOpen;
export const IconGroup = MdOutlineGroupWork;
export const IconImage = FiImage;
export const IconImport = FiUpload;
export const IconInfo = IoInformationOutline;
export const IconInfoCircle = IoMdInformationCircle;
export const IconInventory = MdInventory2;
export const IconInvoice = MdOutlineDocumentScanner;
export const IconHistory = FiRotateCcw;
export const IconKey = FiKey;
export const IconLayers = MdLayers;
export const IconLightBulb = RiLightbulbFill;
export const IconLike = AiFillLike;
export const IconList = MdList;
export const IconLocation = FiMapPin;
export const IconLock = FiLock;
export const IconMap = FiMap;
export const IconMail = FiMail;
export const IconMailAlt = MdEmail; // TODO Review
export const IconMenuBurger = FiMenu;
export const IconMenuKebab = BiDotsVerticalRounded;
export const IconMonitor = FiMonitor;
export const IconPalette = MdOutlinePalette;
export const IconPrint = FiPrinter;
export const IconPrintAlt = MdPrint; // TODO Review
export const IconPrintAlt2 = MdOutlinePrint; // TODO Review
export const IconPurchaseOrder = MdOutlineReceiptLong;
export const IconRadio = IoMdRadioButtonOn;
export const IconReceipt = MdOutlineReceipt;
export const IconRefresh = MdRefresh;
export const IconRemove = MdRemove;
export const IconRemoveCircle = FiMinusCircle;
export const IconRights = MdOutlineAdminPanelSettings;
export const IconSave = FiSave;
export const IconSaveAlt = MdSave;
export const IconSavings = MdOutlineSavings;
export const IconSearch = MdOutlineSearch;
export const IconSearchPos = FiSearch;
export const IconSearchDesktop = FiMonitor;
export const IconSend = MdSend;
export const IconSendAlt = FiSend;
export const IconSettings = FiSettings;
export const IconSettingsAlt = IoMdSettings;
export const IconShoppingCart = FiShoppingCart;
export const IconStepInto = FiCornerLeftDown;
export const IconStepOut = FiCornerUpRight;
export const IconStop = MdReport;
export const IconSuccess = IoCheckmarkDoneOutline;
export const IconSwapHorizontal = MdCompareArrows;
export const IconSwapVertical = LuArrowUpWideNarrow;
export const IconTab = MdTab;
export const IconTag = FiTag;
export const IconTicket = FiShoppingBag;
export const IconTimes = FaTimes;
export const IconTrash = FiTrash;
export const IconUser = FiUser;
export const IconUsers = FiUsers;
export const IconVideo = BsFillCameraVideoFill;
export const IconVideoSettings = FiVideo;
export const IconWarning = IoWarningOutline;
export const IconWarningAlt = BsFillExclamationTriangleFill;
export const IconWhatsApp = FaWhatsapp;
export const IconCreditNote = FiLogOut;
export const IconFiEye = FiEye;
export const IconFiEyeOff = FiEyeOff;
export const IconFiCheck = FiCheck;
export const IconFiSun = FiSun;
export const IconFiMoon = FiMoon;
export const IconPOSallowed = CiCreditCard1;
export const IconTransbankPoll = IconPoll;
export const IconTransbankGetLastSale = IconGetLastSale;
export const IconTransbankLoadKeys = IconLoadKeys;
export const IconTransbankSetNormalMode = IconSetNormalMode;
export const IconTransbankCloseDay = IconCloseDay;
export const IconTransbankGetDailySales = IconGetDailySales;
export const IconTransbankRefund = IconRefund;
export const IconTransbankGetTotals = IconGetTotals;
export const IconDownloadCertificates = MdOutlineDownloading;
export const IconDues = MdOutlineNumbers;
export const IconPinCard = MdPassword;
export const IconSendingTransaction = MdConnectWithoutContact;
export const IconExternalPrinter = MdOutlinePointOfSale;
export const IconInfoRounded = FiInfo;
export const IconMobile = CiMobile1;
export const IconSendEmailReport = BsSend;
export const IconTools = MdOutlineHandyman;
export const IconFindReplace = MdFindReplace;
export const IconArrowChevronDown = IoIosArrowDown;
export const IconArrowChevronUp = IoIosArrowUp;
export const IconClockBack = MdUpdate;
export const IconActivities = MdOutlineChecklist;
export const IconProducts = MdOutlineLocalMall;
export const IconFolderTab = FiFolder;
export const IconPlus = FiPlus;
export const IconCards = MdStyle;

export type { IconBaseProps, IconType } from 'react-icons';

// Dynamically loadable icons
export const loadableIoIcon = async (iconName: string) => {
  const Icon = await import('react-icons/io').then((module) => ({
    default: module[iconName],
  }));
  return Icon;
};
