import { hasRequiredUserRights } from '@laudus/shared-utils';
import {
  IGroupRight,
  IReportsLeftMenuItem,
  IReportsLeftMenuItemWithReport,
  IReportsLeftMenuItemWithSection,
  ReportsLeftMenuItemTypes,
} from '@laudus/types';

export function getUsableReportMenuFromConfiguration({
  menuConfiguration,
  userRights,
}: {
  menuConfiguration: IReportsLeftMenuItem[];
  userRights: IGroupRight[];
}): IReportsLeftMenuItem[] {
  return menuConfiguration
    .filter((reportMenuItem) =>
      canReportMenuItemBeUsedByUser({
        reportMenuItem,
        userRights,
      }),
    )
    .map((reportMenuItem) => {
      if (reportMenuItem.type === ReportsLeftMenuItemTypes.Report) {
        return reportMenuItem;
      }

      const menuItemChildren = reportMenuItem.items;

      if (!menuItemChildren) {
        return reportMenuItem;
      }

      return {
        ...reportMenuItem,
        items: getUsableReportMenuFromConfiguration({
          menuConfiguration: menuItemChildren,
          userRights,
        }),
      };
    });
}

function canReportMenuItemBeUsedByUser({
  reportMenuItem,
  userRights,
}: {
  reportMenuItem: IReportsLeftMenuItem;
  userRights: IGroupRight[];
}) {
  const menuUserRights = reportMenuItem.userRights;

  if (!menuUserRights) {
    return true;
  }

  return hasRequiredUserRights({ userRights, requiredUserRights: menuUserRights });
}

interface ReportTreeData {
  id: string;
  parent: string;
  text: string;
  droppable?: boolean;
  data?: {
    initiallyExpanded?: boolean;
  };
}

export function buildReportsTreeNodeData(menuConfig: IReportsLeftMenuItem[]) {
  let reportsTreeData: ReportTreeData[] = [];

  for (const menuItem of menuConfig) {
    if (menuItem.type === ReportsLeftMenuItemTypes.Report) {
      reportsTreeData.push({
        id: menuItem.title,
        text: menuItem.title,
        parent: '',
      });
    }

    if (menuItem.type === ReportsLeftMenuItemTypes.Section) {
      reportsTreeData.push({
        id: `${menuItem.title}-section`,
        text: menuItem.title,
        parent: '',
        droppable: true,
        data: { initiallyExpanded: menuItem.initiallyExpanded },
      });

      const sortedItems = [...menuItem.items].sort((a, b) => a.title.localeCompare(b.title));

      const childrenNodeData = sortedItems.map((item) => buildReportTreeNodeData(item, menuItem));

      for (const childNodeData of childrenNodeData) {
        reportsTreeData = [...reportsTreeData, ...childNodeData];
      }
    }
  }

  return reportsTreeData;
}

function buildReportTreeNodeData(
  menuItem: IReportsLeftMenuItem,
  directParent: IReportsLeftMenuItemWithSection,
) {
  let reportsTreeData: ReportTreeData[] = [];
  const parentId = `${directParent.title}-section`;

  if (menuItem.type === ReportsLeftMenuItemTypes.Report) {
    reportsTreeData.push({
      id: menuItem.title,
      text: menuItem.title,
      parent: parentId,
    });
  }

  if (menuItem.type === ReportsLeftMenuItemTypes.Section) {
    reportsTreeData.push({
      id: `${menuItem.title}-section`,
      text: menuItem.title,
      parent: parentId,
      droppable: true,
    });

    const childrenNodeData = menuItem.items.map((item) => buildReportTreeNodeData(item, menuItem));

    for (const childNodeData of childrenNodeData) {
      reportsTreeData = [...reportsTreeData, ...childNodeData];
    }
  }

  return reportsTreeData;
}

export function findReportByTitle(menuConfiguration: IReportsLeftMenuItem[], title: string) {
  const allReportConfig = menuConfiguration.map(getConfigFromReportMenu).flat();

  return allReportConfig.find((report) => report.title === title);
}

function getConfigFromReportMenu(
  reportMenu: IReportsLeftMenuItem,
): IReportsLeftMenuItemWithReport[] {
  if (reportMenu.type === ReportsLeftMenuItemTypes.Report) {
    return [reportMenu];
  }

  const items = reportMenu.items;

  return items.map(getConfigFromReportMenu).flat();
}
