import { ComponentSingleStyleConfig } from '@chakra-ui/react';

import { colors } from '@laudus/shared-ui';

export const Text: ComponentSingleStyleConfig = {
  variants: {
    'default': {
      fontSize: '1.4rem',
    },
    'tooltip-text': {
      'color': '#666666',
      'fontSize': '1.3rem',
      'fontWeight': '400',
      'lineHeight': '132%',
      '.bolder': {
        fontWeight: '800',
      },
    },
    'dashboard-tooltip': {
      'color': colors.black,
      'fontSize': '1.4rem',
      'fontWeight': '400',
      'lineHeight': '132%',
      '.bolder': {
        fontWeight: '600',
      },
      '_dark': {
        color: colors.white,
      },
    },
    'dashboard-help-title': {
      'color': colors.black,
      'fontSize': '2.9rem',
      'fontWeight': '700',
      'lineHeight': '136%',
      '.highlight': {
        color: 'primary500',
      },
      '_dark': {
        color: colors.white,
      },
    },
    'dashboard-help-subtitle': {
      'color': colors.black,
      'fontSize': '1.3rem',
      'fontWeight': '600',
      '.bolder': {
        fontWeight: '600',
      },
      '_dark': {
        color: colors.white,
      },
    },
    'dashboard-help-text': {
      'color': colors.black,
      'width': '100%',
      'fontSize': '1.4rem',
      'fontWeight': '400',
      'lineHeight': '170%',
      'whiteSpace': 'pre-wrap',
      '.highlight': {
        color: 'primary500',
        fontWeight: '600',
      },
      '_dark': {
        color: colors.white,
      },
    },
    'options-tooltip': {
      'color': '#666666',
      'fontSize': '1.4rem',
      'fontWeight': '400',
      '_dark': {
        color: colors.white,
      },
      '.bolder': {
        fontWeight: '800',
      },
    },
    'options-title': {
      'color': 'black',
      'fontSize': '1.4rem',
      'fontWeight': '400',
      '.bolder': {
        fontWeight: '800',
      },
    },
    'title-user': {
      'color': 'black',
      'fontSize': '1.8rem',
      'fontWeight': '600',
      'lineHeight': '170%',
      '.bolder': {
        fontWeight: '800',
      },
      '_dark': {
        color: colors.white,
      },
    },
    'subtitle-user': {
      'color': colors.neutral800,
      'fontSize': '1.4rem',
      'whiteSpace': 'wrap',
      'maxWidth': '23rem',
      'fontWeight': '400',
      '.bolder': {
        fontWeight: '800',
      },
      '_dark': {
        color: colors.white,
      },
    },
    'menu-tabs-title': {
      color: 'black',
      fontSize: '1.2rem',
      fontWeight: '700',
      lineHeight: '150%',
    },
    'menu-tabs-number': {
      color: 'black',
      fontSize: '1.4rem',
      fontWeight: '400',
      lineHeight: '150%',
    },
    'box-title': {
      fontSize: '1.4rem',
      margin: '0px',
      textAlign: 'right',
    },
    'box-small-text': {
      fontSize: '1.2rem',
    },
    'form-title': {
      fontSize: '1.5rem',
      fontWeight: '600',
      color: 'black',
      _dark: {
        color: colors.white,
      },
    },
    'tabs-title': {
      fontSize: '1.4rem',
      fontWeight: '300',
      color: 'white',
    },
    'balanceSheetLeftMenu': {
      fontSize: '1.3rem',
      color: 'white',
    },
    'balanceSheetLeftMenuLabel': {
      fontSize: '1.3rem',
      color: 'white',
      fontWeight: '200',
    },
    'settingsVariant': {
      fontSize: '1.4rem',
    },
  },
  defaultProps: {
    variant: 'default',
  },
};
