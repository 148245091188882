import { createSelector } from '@reduxjs/toolkit';

import { updateSalesItemsPricing } from '@laudus/sales-utils';

import { AppState } from '../../store';
import { getMainCurrency } from '../currencies';
import { getSalesQuotesList } from '../salesQuotes';

import { IOpportunitiesState } from './reducer';

export const getOpportunitiesSlice = (state: AppState): IOpportunitiesState => state.opportunities;

export const getOpportunitiesList = createSelector([getOpportunitiesSlice], (state) => state.list);

export const getOpportuniy = createSelector([getOpportunitiesSlice], (state) => {
  return {
    ...state.current,
    contact: {
      ...state.current.contact,
      // add fullName to show in the UI Contact SearchField
      fullName: state.current.contact?.firstName + ' ' + state.current.contact?.lastName,
    },
  };
});

export const getOpportunityDraft = createSelector([getOpportunitiesSlice], (state) => {
  return {
    ...state.draft,
    contact: {
      ...state.draft.contact,
      // add fullName to show in the UI Contact SearchField
      fullName: state.draft.contact?.firstName + ' ' + state.draft.contact?.lastName,
    },
  };
});

export const getOpportunityActivityDraft = createSelector(
  [getOpportunitiesSlice],
  (state) => state.activityDraft,
);

export const getOpportunitySelectedQuote = createSelector(
  [getOpportunitiesSlice, getMainCurrency],
  (state, { mainCurrencyDecimals }) =>
    updateSalesItemsPricing(state.selectedQuote, mainCurrencyDecimals),
);

export const getOpportunityIsEditing = createSelector(
  [getOpportunitiesSlice],
  (state) => state.editing,
);

export const getOpportunityEntity = createSelector(
  [getOpportunityDraft, getOpportuniy, getOpportunityIsEditing],
  (draft, current, editing) => {
    return editing ? draft : current;
  },
);

export const getOpportunitySalesQuoteTableData = createSelector(
  [getOpportunityEntity, getSalesQuotesList],
  (entity, salesQuotesList) => {
    return entity?.quotes?.map((salesQuote) => {
      const foundSalesQuote = salesQuotesList?.find(
        (q) => q.salesQuoteId === salesQuote.salesQuoteId,
      );
      return {
        salesQuoteId: salesQuote?.salesQuoteId,
        issuedDate: foundSalesQuote?.issuedDate ?? '',
      };
    });
  },
);

export const getOpportunityActivitiesTableData = createSelector(
  [getOpportunityEntity],
  (entity) => {
    const STATUS_MAP = {
      P: 'En Proceso',
      F: 'Terminada',
      C: 'Cancelada',
    };
    return entity?.activities?.map((activity) => ({
      ...activity,
      statusDescription: STATUS_MAP[activity.status as keyof typeof STATUS_MAP] ?? '',
    }));
  },
);
