import { Context, createContext } from 'react';
import { createDispatchHook, createSelectorHook, ReactReduxContextValue } from 'react-redux';

import { configureStore, Reducer } from '@reduxjs/toolkit';
import { combineReducers, UnknownAction } from 'redux';
import { getPersistConfig } from 'redux-deep-persist';
import {
  createMigrate,
  FLUSH,
  PAUSE,
  PERSIST,
  PersistorOptions,
  persistReducer,
  persistStore,
  PURGE,
  REGISTER,
  REHYDRATE,
} from 'redux-persist';
import storage from 'redux-persist-indexeddb-storage';

import { InfraServices, services } from '@laudus/services';
import { getVatIdFromUrlPath } from '@laudus/shared-utils';

import { migrations } from './migrations';
import { appRootReducers } from './reducers';

const companyVatId = getVatIdFromUrlPath();

const appPersistedReducer = (key: string, reducers: Record<string, Reducer>) =>
  persistReducer(
    getPersistConfig({
      key: key || 'app',
      version: 111,
      migrate: createMigrate(migrations, {
        debug: false,
      }),
      storage: storage('appDB'),
      blacklist: [
        'accounts.formState',
        'costCenters.formState',
        'customerCategories.formState',
        'customers.formState',
        'dashboard',
        'global.loading',
        'global.loadingTabs',
        'ledger',
        'ledgerFilter',
        'opportunities.selectedQuote',
        'productCategories.formState',
        'receipts.mixedPaymentReceipt',
        'statistics',
        'salesInvoices.historyAdvancedSearch',
        'posAdjustment.formState',
      ],
      rootReducer: combineReducers(reducers),
    }),
    combineReducers(reducers),
  );

export const appStore = configureStore({
  reducer: appPersistedReducer(companyVatId, appRootReducers),
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
      thunk: { extraArgument: services },
    }),
  devTools: import.meta.env.VITE_ENV !== 'production',
});

let reducers = appRootReducers;
export const getAppStore = (key: string, newReducers: [string, Reducer][]) => {
  if (newReducers.length) {
    reducers = {
      ...reducers,
      ...newReducers.reduce((acc, [key, reducer]) => ({ ...acc, [key]: reducer }), {}),
    };
  }
  appStore.replaceReducer(appPersistedReducer(key, reducers));
  appPersistor.persist();
  return appStore;
};

export const appPersistor = persistStore(appStore, {
  manualPersist: true,
} as PersistorOptions);

export type AppStore = typeof appStore;

export type AppState = ReturnType<AppStore['getState']>;

export type AppDispatch = AppStore['dispatch'];

export type AppThunkConfig = {
  dispatch: AppDispatch;
  state: AppState;
  extra: InfraServices;
};

export const appStoreContext = createContext<ReactReduxContextValue>(
  {} as ReactReduxContextValue,
) as Context<ReactReduxContextValue<any, UnknownAction> | null>;

export const useAppDispatch: () => AppDispatch = createDispatchHook(appStoreContext);

export const useAppSelector = createSelectorHook(appStoreContext);
