import {
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';

import { Form, Formik, FormikHelpers, FormikProps } from '@laudus/formik';
import { IconUser } from '@laudus/icons';
import { useIntl } from '@laudus/intl';
import { colors } from '@laudus/shared-ui';
import { ILoginParams } from '@laudus/types';

import { PasswordInput } from '../../shared/components/PasswordInput';

import { LoginCheckbox } from './LoginCheckbox';
import LoginFormSchema from './schemas';

interface ILoginFormProps {
  formError: string;
  initialValues: ILoginParams;
  onSubmit: (values: ILoginParams, actions: FormikHelpers<ILoginParams>) => void;
}

export const LoginForm = ({ formError, initialValues, onSubmit }: ILoginFormProps) => {
  const intl = useIntl();

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validateOnBlur={false}
      validateOnChange={false}
      validationSchema={LoginFormSchema}
    >
      {({ handleChange, errors, isSubmitting }: FormikProps<ILoginParams>) => (
        <Form>
          <FormControl id="loginName" isInvalid={!!errors.loginName} mb="1.2rem">
            <FormLabel
              color={{ base: colors.secondary200, md: colors.neutral700 }}
              _dark={{ color: colors.secondary100 }}
              variant="small"
            >
              {intl.formatMessage({ id: 'login.userName' })}
            </FormLabel>
            <InputGroup>
              <InputRightElement>
                <div className="icon-color">
                  <IconUser size={18} />
                </div>
              </InputRightElement>
              <Input
                _dark={{
                  bg: colors.darkGrey500,
                  border: `0.05rem solid ${colors.darkGrey200}`,
                  color: colors.white,
                  _selected: {
                    border: `0.05rem solid ${colors.primary500}`,
                  },
                  _focus: {
                    border: `0.05rem solid ${colors.primary500}`,
                  },
                  _disabled: { color: colors.secondary100 },
                }}
                bg={{ base: colors.secondary600, md: colors.white }}
                borderColor={{
                  base: formError ? colors.danger400 : colors.secondary200,
                  md: formError ? colors.danger400 : colors.neutral500,
                }}
                color={{ base: colors.white, md: colors.black }}
                onChange={handleChange}
                type="text"
              />
            </InputGroup>
          </FormControl>
          <PasswordInput
            formError={formError}
            id="password"
            label={intl.formatMessage({
              id: 'login.password',
            })}
            onChange={handleChange}
          />
          <FormControl>
            <LoginCheckbox linkTitle={intl.formatMessage({ id: 'login.forgotPassword' })} />
          </FormControl>
          <Button
            isLoading={isSubmitting}
            mb={{ base: '10', md: '1rem' }}
            type="submit"
            variant="login"
          >
            {intl.formatMessage({ id: 'login.enter' })}
          </Button>
        </Form>
      )}
    </Formik>
  );
};
