import { ISalesWaybill } from '@laudus/types';

export const SALES_WAYBILL_EMPTY: ISalesWaybill = {
  cause: { code: '06', description: 'TRASLADO' },
  branch: null,
  docType: { docTypeId: 50, name: '' },
  items: [],
  issuedDate: undefined,
  dueDate: undefined,
  docNumber: undefined,
  customer: {
    customerId: 0,
    name: '',
    legalName: '',
    VATId: '',
    email: '',
  },
  contact: null,
  salesman: null,
  dealer: null,
  carrier: null,
  priceList: null,
  term: null,
  warehouse: null,
  nullDoc: false,
  totals: {
    net: 0,
    total: 0,
  },
  purchaseOrderNumber: '',
  deliveryAddress: null,
  deliveryCost: 0,
  deliveryNotes: '',
  deliveryVehiclePlate: '',
  bypassCreditLimit: false,
  notes: '',
};
