import { createAction, createAsyncThunk } from '@reduxjs/toolkit';

import { intl } from '@laudus/intl';
import { IFetchWithEtagParams, IProcess, IStartEditingParams } from '@laudus/types';

import { AppThunkConfig } from '../../store';
import { showErrorAlert } from '../alerts';
import { setEtagsCurrentEtag } from '../etags';
import { endRequest, startRequest } from '../global/actions';
import { addTab } from '../tabs';

export const addHomeProcessTab = () =>
  addTab({
    tab: {
      id: 'processes',
      title: intl.formatMessage({ id: 'processes.tabTitle' }),
      path: 'pages/Processes/Processes',
      isRemovable: true,
    },
  });

export const startEditingProcess = createAsyncThunk<
  void,
  IStartEditingParams | undefined,
  AppThunkConfig
>('PROCESSES/START_EDITING', async (params, ThunkAPI) => {
  const { isNew } = params ?? {};
  const { dispatch } = ThunkAPI;
  dispatch(setProcessEditing(true));
  dispatch(
    addTab({
      tab: {
        id: 'processes',
        title: intl.formatMessage({ id: 'processes.tabTitle' }),
        path: isNew ? 'pages/Processes/ProcessNew' : 'pages/Processes/ProcessEdit',
        isRemovable: true,
      },
    }),
  );
});

export const startEditingProcessNew = createAsyncThunk<void, void, AppThunkConfig>(
  'PROCESSES/START_EDITING_NEW',
  async (_, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(startEditingProcess({ isNew: true }));
  },
);

export const stopEditingProcess = createAsyncThunk<void, number | undefined, AppThunkConfig>(
  'PROCESSES/STOP_EDITING',
  async (id, ThunkAPI) => {
    const { dispatch } = ThunkAPI;
    dispatch(setProcessEditing(false));
    dispatch(
      addTab({
        tab: {
          id: 'processes',
          title: intl.formatMessage({ id: 'processes.tabTitle' }),
          path: 'pages/Processes/ProcessView',
          props: { id },
          isRemovable: true,
        },
      }),
    );
  },
);

export const clearProcess = createAction('PROCESSES/CLEAR');

export const clearProcessDraft = createAction('PROCESSES/CLEAR_DRAFT');

export const setProcess = createAction<IProcess>('PROCESSES/SET_PROCESS');

export const setProcessDraft = createAction<IProcess>('PROCESSES/SET_PROCESS_DRAFT');

export const setProcessDraftValues = createAction<Partial<IProcess>>(
  'PROCESSES/SET_PROCESS_DRAFT_VALUE',
);

export const setProcessesList = createAction<IProcess[]>('PROCESSES/SET_LIST');

export const updateProcessesList = createAction<IProcess>('PROCESSES/UPDATE_LIST');

export const removeProcessFromList = createAction<number>('PROCESSES/REMOVE_FROM_LIST');

export const duplicateProcess = createAction<IProcess>('PROCESSES/DUPLICATE');

export const setProcessEditing = createAction<boolean>('PROCESSES/SET_EDITING');

export const fetchProcessesList = createAsyncThunk<void, IFetchWithEtagParams, AppThunkConfig>(
  'PROCESSES/FETCH_PROCESSES_LIST',
  async ({ eTag, silent }, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    if (!silent) {
      dispatch(startRequest('processes'));
    }

    try {
      const { data } = await api.processes.fetchProcessesListAPI();
      dispatch(setProcessesList(Array.isArray(data) ? data : []));

      dispatch(setEtagsCurrentEtag(eTag));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'processes',
          action: 'list',
        }),
      );
    } finally {
      if (!silent) {
        dispatch(endRequest('processes'));
      }
    }
  },
);

export const fetchProcess = createAsyncThunk<void, number, AppThunkConfig>(
  'PROCESSES/FETCH_PROCESS',
  async (id, ThunkAPI) => {
    const { dispatch, extra } = ThunkAPI;
    const { api } = extra;

    dispatch(startRequest('processes'));
    try {
      const { data } = await api.processes.fetchProcessAPI(id);
      dispatch(setProcess(data));
    } catch (error) {
      dispatch(
        showErrorAlert({
          error,
          prefix: 'processes',
          action: 'read',
        }),
      );
    } finally {
      dispatch(endRequest('processes'));
    }
  },
);
