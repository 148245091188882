import { IAccountInfo } from './accounts';
import { IBranchInfo } from './branches';
import { ICostCenterInfo } from './costCenters';
import { ICurrencyType } from './currencies';
import { ICustomerInfo } from './customers';
import { IJournalEntryInfo } from './journalEntries';
import { ISalesmanInfo } from './salesmen';
import { IRequiredUserRights } from './userRights';

// For now the API just return an URL, but in the future it may return an object with nested data
export type IReportResult = string; // | Record<string, unknown>;
export type IReportAPIResponseType = 'pdf'; // | 'raw_data';

export interface IReportConfig {
  name: string;
  type: IReportAPIResponseType;
  filters: IReportsFilter[];
  fixedFilters?: Record<string, unknown>;
}

// Put here the key and type that the API expects to receiveç
// e.g. the API needs to receive a costCenterId of type string
export interface IReportPrimitiveFiltersItems {
  costCenterId?: string;
  date?: string;
  dateFrom?: string;
  dateTo?: string;
  accountId?: number;
  accountNumberFrom?: string;
  accountNumberTo?: string;
  year?: number;
  month?: number;
  startPage?: number;
  endPage?: number;
  leaveSpaceForHeader?: boolean;
  printLegalHeader?: boolean;
  location?: string;
  bookId?: string;
  showAccountsWithZeroBalance?: boolean;
  showOnlyAccountsWithActivity?: boolean;
  showLevels?: string;
  journalEntryNumberFrom?: number;
  journalEntryNumberTo?: number;
  printJournalSums?: boolean;
  customerId?: number;
  salesmanId?: number;
  branchId?: number;
  currencyType?: ICurrencyType;
}

// Put here the key that you want to use in the reports configuration, and the type that the input will deal with
// e.g. you want to add a costCenter filter to your reports, and the select input will manage ICostCenterInfo
export interface IReportFiltersItems {
  costCenter?: ICostCenterInfo;
  dateFrom?: string;
  date?: string;
  dateTo?: string;
  account?: IAccountInfo;
  accountFrom?: IAccountInfo;
  accountTo?: IAccountInfo;
  year?: number;
  month?: number;
  startPage?: number;
  endPage?: number;
  printHeader: IReportsFilterPrintHeader;
  location?: string;
  bookId?: string;
  showAccountsWithZeroBalance?: boolean;
  showOnlyAccountsWithActivity?: boolean;
  showLevels?: string;
  journalEntryFrom?: IJournalEntryInfo;
  journalEntryTo?: IJournalEntryInfo;
  printJournalSums?: boolean;
  customer?: ICustomerInfo;
  salesman?: ISalesmanInfo;
  branch?: IBranchInfo;
  currencyType?: ICurrencyType;
}

export interface IReportFilterGroup {
  label: string;
  filters: IReportsFilter[];
}

export type IReportsFilter = keyof IReportFiltersItems | IReportFilterGroup;

export enum ReportsLeftMenuItemTypes {
  Report = 'REPORT',
  Section = 'SECTION',
}

export interface IReportsLeftMenuItemWithReport {
  type: ReportsLeftMenuItemTypes.Report;
  config: IReportConfig;
  title: string;
  userRights?: IRequiredUserRights;
  initiallyExpanded?: boolean;
}

export interface IReportsLeftMenuItemWithSection {
  type: ReportsLeftMenuItemTypes.Section;
  items: (IReportsLeftMenuItemWithReport | IReportsLeftMenuItemWithSection)[];
  title: string;
  userRights?: IRequiredUserRights;
  initiallyExpanded?: boolean;
}

export type IReportsLeftMenuItem = IReportsLeftMenuItemWithReport | IReportsLeftMenuItemWithSection;

export interface IReportsFilterPrintHeader {
  leaveSpaceForHeader?: boolean;
  printLegalHeader?: boolean;
  // TODO: Wait for Javier or Jesus to provide the name of this field
  // numberOfPages: number;
}
