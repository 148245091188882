import { createContext, RefObject, useContext } from 'react';

interface IModalProviderContextValue {
  ref?: RefObject<HTMLDivElement | null>;
}

const initialValue: IModalProviderContextValue = {};

export const ModalProviderContext = createContext(initialValue);

export const useModalProviderContext = () => useContext(ModalProviderContext);
