import { ChangeEvent, forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react';

import { ICellEditorParams } from '@ag-grid-community/core';
import { useColorMode } from '@chakra-ui/react';

import { colors, KEY_ENTER, KEY_LEFT, KEY_RIGHT, KEY_TAB } from '@laudus/shared-ui';

interface ISelectOption {
  value: any;
  label: string;
}

interface ISelectEditorProps extends ICellEditorParams {
  autoFocus?: boolean;
  label?: string;
  disabled?: boolean;
  name: string;
  options: ISelectOption[];
  placeholder?: string;
}

const SelectEditorComponent: React.ForwardRefRenderFunction<any, ISelectEditorProps> = (
  props,
  ref,
) => {
  const inputRef = useRef<HTMLSelectElement>(null);

  const [value, setValue] = useState(props.value);

  const { colorMode } = useColorMode();

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return value;
      },
    };
  });

  const handleChange = (event: ChangeEvent<HTMLSelectElement>) => {
    setValue(event.currentTarget.value);
  };

  const handleKeyDown = (event: any) => {
    // Any of these keys represent the users intent to movement away from the current cell and
    // therefore we must commit the value so that it's persisted to state
    if (event.key === KEY_TAB || event.key === KEY_LEFT || event.key === KEY_RIGHT) {
      event.preventDefault();
      setValue(value);
      props.context.handleKeyPress(event);
      return;
    } else if (event.key === KEY_ENTER) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    inputRef?.current?.focus();

    inputRef?.current?.addEventListener('keydown', handleKeyDown);

    return () => {
      inputRef?.current?.removeEventListener('keydown', handleKeyDown);
    };
  }, [value]);

  const options = [...props.options];
  if (props.placeholder) {
    options.unshift({
      label: props.placeholder ?? '',
      value: null,
    });
  }

  return (
    <select
      onChange={handleChange}
      ref={inputRef}
      style={{
        color: colorMode === 'dark' ? colors.white : colors.black,
        background: colorMode === 'dark' ? colors.darkGrey500 : colors.white,
        // @ts-expect-error actualWidth is a private member, not sure why?
        width: props.column?.actualWidth,
        height: '34px',
      }}
      value={options?.find((o) => o.value === (value?.value ?? value))?.value}
    >
      {options?.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

SelectEditorComponent.displayName = 'SelectEditor';

export const SelectEditor = forwardRef(SelectEditorComponent);
