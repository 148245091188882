import { createSelector } from '@reduxjs/toolkit';

import { IPrintTemplateSettings } from '@laudus/templates-pdf';
import { ISetting } from '@laudus/types';

import { AppState } from '../../store';
import { getCustomer, getCustomersList } from '../customers/selectors';
import { ISettingsState } from '../settings';

export const getSettingsSlice = (state: AppState): ISettingsState => state.settings;

export const getSettingsList = createSelector([getSettingsSlice], (state) => state.list);

export const getSettings = createSelector(
  [getSettingsList],
  (settingsList): Record<string, ISetting> =>
    settingsList.reduce(
      (acc, setting) => ({
        ...acc,
        [setting.keyName]: setting,
      }),
      {},
    ),
);

export const getSettingsDraft = createSelector([getSettingsSlice], (state) => state.draft);

export const getSettingsDraftList = createSelector([getSettingsSlice], (state) => state.draftList);

export function getSettingsByName<T>(name: string) {
  return createSelector(
    [getSettingsList],
    (settingsList) => settingsList.find((setting) => setting.keyName === name)?.keyValue as T,
  );
}

export function getSettingsByNameInDraftList<T>(name: string) {
  return createSelector(
    [getSettingsDraftList],
    (settingsList) => settingsList.find((setting) => setting.keyName === name)?.keyValue as T,
  );
}

export const getDefaultCustomerForTicketsValue = createSelector(
  [
    getCustomersList,
    getCustomer,
    getSettingsByNameInDraftList<string>('defaultCustomerForTickets'),
    getSettingsByName<string>('defaultCustomerForTickets'),
  ],
  (
    customersList,
    currentCustomer,
    draftIdDefaultCustomerForTickets,
    currentIdDefaultCustomerForTickets,
  ) => {
    const defaultIdCustomerForTicketsValue = Number(
      draftIdDefaultCustomerForTickets ?? currentIdDefaultCustomerForTickets,
    );
    if (customersList.length) {
      return customersList.find((cl) => cl.customerId === defaultIdCustomerForTicketsValue)?.name;
    } else if (currentCustomer.customerId === defaultIdCustomerForTicketsValue) {
      return currentCustomer.name;
    } else {
      return '';
    }
  },
);

export const getPrintTemplateSettings = (type: string) =>
  createSelector([getSettingsByName<string>(`printTemplate_${type}`)], (printTemplateSetting) => {
    if (!printTemplateSetting) {
      return null;
    }

    try {
      const printTemplateSettingParsed = JSON.parse(printTemplateSetting);
      return printTemplateSettingParsed as IPrintTemplateSettings;
    } catch (error) {
      // This log is for debugging if the print template settings are set incorrectly for implimentation people
      console.log('Wrong format of print template settings:', printTemplateSetting);
      return printTemplateSetting;
    }
  });

export const getPrintTemplateSettingsDraft = (type: string) =>
  createSelector([getSettingsDraft], (settingsDraft) => {
    try {
      const printTemplateSettingsDraft = settingsDraft?.[`printTemplate_${type}`];
      const printTemplateSettingsDraftParsed = JSON.parse(printTemplateSettingsDraft?.toString());
      return printTemplateSettingsDraftParsed as IPrintTemplateSettings;
    } catch (error) {
      // This log is for debugging if the print template settings are set incorrectly for implimentation people
      console.log('Wrong format of print template settings');
      return null;
    }
  });
