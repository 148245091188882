import { AxiosResponse } from 'axios';

import { IPurchasePayment, IPurchasePaymentReconciliationCandidate } from '@laudus/types';

import { client as axios } from '../utils/axios';

const { VITE_API_URL } = import.meta.env;

export const fetchPurchasesPaymentsListAPI = (): Promise<AxiosResponse<IPurchasePayment[]>> =>
  axios.post(
    `${VITE_API_URL}/purchases/payments/list`,
    {
      fields: [
        'paymentId',
        'paymentType.code',
        'paymentType.description',
        'issuedDate',
        'dueDate',
        'document',
        'deposited',
        'bank.bankId',
        'bank.name',
        'pos.posId',
        'pos.name',
        'purchaseInvoices.purchaseInvoiceId',
        'purchaseInvoices.docNumber',
        'purchaseInvoices.amount',
        /*  'advanceToRenderRecipient.name',
        'advanceToRenderRecipient.type', */
        'journalEntryIssued.journalEntryNumber',
        'journalEntryDeposited.journalEntryNumber',
      ],
      orderBy: [
        {
          field: 'paymentId',
          direction: 'DESC',
        },
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchBankReconciliationWithdrawalCandidatePaymentsFromAPI = (
  startDate: string,
  endDate: string,
  document?: string,
): Promise<AxiosResponse<IPurchasePaymentReconciliationCandidate[]>> =>
  axios.post(
    `${VITE_API_URL}/purchases/payments/list`,
    {
      orderBy: [
        {
          field: 'issuedDate',
          direction: 'DESC',
        },
      ],
      fields: [
        'paymentId',
        'issuedDate',
        'document',
        'paymentType.description',
        'purchaseInvoices.amount',
        'purchaseInvoices.supplier.legalName',
        'otherDocuments.amount',
      ],
      filterBy: [
        {
          field: 'issuedDate',
          operator: '>=',
          value: startDate,
        },
        {
          field: 'issuedDate',
          operator: '<=',
          value: endDate,
        },
        ...(document
          ? [
              {
                field: 'document',
                operator: '=',
                value: document,
              },
            ]
          : []),
      ],
    },
    {
      headers: {
        Accept: 'application/json',
      },
    },
  );

export const fetchPurchasePaymentAPI = (
  paymentId: number,
): Promise<AxiosResponse<IPurchasePayment>> =>
  axios.get(`${VITE_API_URL}/purchases/payments/${paymentId}`);

export const createPurchasePaymentAPI = (
  purchasePayment: IPurchasePayment,
): Promise<AxiosResponse<IPurchasePayment>> =>
  axios.post(`${VITE_API_URL}/purchases/payments`, purchasePayment);

export const updatePurchasePaymentAPI = (
  purchasePayment: IPurchasePayment,
): Promise<AxiosResponse<IPurchasePayment>> =>
  axios.put(`${VITE_API_URL}/purchases/payments/${purchasePayment.paymentId}`, purchasePayment);

export const deletePurchasePaymentAPI = (paymentId: number): Promise<AxiosResponse> =>
  axios.delete(`${VITE_API_URL}/purchases/payments/${paymentId}`);
